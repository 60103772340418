import {graphql} from '@apollo/client/react/hoc';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import CampaignLinkNav from '@analytics/components/nav/CampaignLinkNav';
import useSettings from '@analytics/components/settings/useSettings';
import CampaignLinkQuery from '@analytics/graphql-api/_old_queries/CampaignLinkQuery';
import CampaignLink from './CampaignLink';
import CampaignLinkBuilder from './CampaignLinkBuilder';

const CampaignLinkIndex = ({
  user,
  organization,
  campaign,
  pixel,
  data: {loading, me},
}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const [settings, dispatch] = useSettings();

  const {campaignLink} = me.organization.campaign;

  return (
    <>
      {!settings.hideUi ? (
        <CampaignLinkNav
          organization={organization}
          campaign={campaign}
          campaignLink={campaignLink}
        />
      ) : (
        ''
      )}

      <Switch>
        <PropsRoute
          exact
          path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/links/:campaignLinkId'
          user={user}
          organization={organization}
          campaign={campaign}
          pixel={pixel}
          campaignLink={campaignLink}
          component={CampaignLink}
        />
        <PropsRoute
          exact
          path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/links/:campaignLinkId/build'
          user={user}
          organization={organization}
          campaign={campaign}
          pixel={pixel}
          campaignLink={campaignLink}
          component={CampaignLinkBuilder}
        />
      </Switch>
    </>
  );
};

export default graphql(CampaignLinkQuery, {
  options: ({
    organization,
    campaign,
    match: {
      params: {campaignLinkId},
    },
  }) => {
    return {
      variables: {
        organizationId: organization.id,
        campaignId: campaign.id,
        id: campaignLinkId,
      },
    };
  },
})(CampaignLinkIndex);
