export const pixelEventTypes = [
  {name: 'Landing Page', type: 'lead'},
  {name: 'Sign Up', type: 'lead'},
  {name: 'Start Trial', type: 'lead'},
  {name: 'Contact Form', type: 'lead'},
  {name: '2+ Pageviews', type: 'lead'},
  {name: 'Email Submitted', type: 'lead'},
  {name: 'Online Chat', type: 'lead'},
  {name: 'Quote Requested', type: 'lead'},
  {name: 'Registration', type: 'lead'},
  {name: 'Create Membership', type: 'lead'},
  {name: 'Onboarding Started', type: 'lead'},
  {name: 'Account Created', type: 'lead'},
  {name: 'Phone Submit', type: 'lead'},
  {name: 'Subscription', type: 'lead'},
  {name: 'Free Sign Up', type: 'lead'},
  {name: 'Form Fill', type: 'lead'},
  {name: 'Verified Lead', type: 'lead'},
  {name: 'Ecommerce Visit', type: 'lead'},
  {name: 'Get Started Lead', type: 'lead'},
  {name: 'Add to Wish List', type: 'lead'},
  {name: 'Start Quote', type: 'lead'},
  {name: 'Applied', type: 'lead'},
  {name: 'Get Started CTA', type: 'lead'},
  {name: 'Finish Application', type: 'lead'},
  {name: 'Free Initial Consultation', type: 'lead'},
  {name: 'Demo Request', type: 'lead'},
  {name: 'Appointment', type: 'lead'},
  {name: 'Email Capture', type: 'lead'},
  {name: 'Newsletter Signup', type: 'lead'},
  {name: 'In App Purchase', type: 'purchase'},
  {name: 'Purchase', type: 'purchase'},
  {name: 'Page View', type: 'page_view'},
  {name: 'Mobile Install', type: 'install'},
];

export const pixelInstallationFields: {
  [key: string]: Array<{
    name: string;
    value: string;
    label: string;
    line: number;
  }>;
} = {
  ADDTOCART: [
    {name: 'value', value: 'value', label: 'Value (optional)', line: 18},
    {
      name: 'currency',
      value: 'currency',
      label: 'Currency (optional)',
      line: 19,
    },
    {
      name: 'quantity',
      value: 'quantity',
      label: 'Quantity (optional)',
      line: 20,
    },
    {
      name: 'productId',
      value: 'product_id',
      label: 'Product Id (optional)',
      line: 21,
    },
    {
      name: 'productName',
      value: 'product_name',
      label: 'Product Name (optional)',
      line: 22,
    },
    {
      name: 'productType',
      value: 'product_type',
      label: 'Product Type (optional)',
      line: 23,
    },
    {
      name: 'productVendor',
      value: 'product_vendor',
      label: 'Product Vendor (optional)',
      line: 24,
    },
    {
      name: 'variantId',
      value: 'variant_id',
      label: 'VariantId (optional)',
      line: 25,
    },
    {
      name: 'variantName',
      value: 'variant_name',
      label: 'Variant Name (optional)',
      line: 26,
    },
  ],
  LEAD: [
    {name: 'type', value: 'type', label: 'Type (optional)', line: 19},
    {name: 'value', value: 'value', label: 'Value (optional)', line: 20},
    {
      name: 'currency',
      value: 'currency',
      label: 'Currency (optional)',
      line: 21,
    },
  ],
  PURCHASE: [
    {
      name: 'orderId',
      value: 'order_id',
      label: 'Order ID (optional)',
      line: 18,
    },
    {
      name: 'discountCode',
      value: 'discount_code',
      label: 'Discount Code (optional)',
      line: 19,
    },
    {
      name: 'newCustomer',
      value: 'is_new_customer',
      label: 'New Customer (optional)',
      line: 20,
    },
    {name: 'value', value: 'value', label: 'Value (optional)', line: 21},
    {
      name: 'currency',
      value: 'currency',
      label: 'Currency (optional)',
      line: 22,
    },
  ],
};

export const pixelInstallationTypes = [
  {title: 'JavaScript', value: 'JAVASCRIPT'},
  {title: 'AppsFlyer', value: 'APPSFLYER'},
  {title: 'Branch', value: 'BRANCH'},
  {title: 'Adjust', value: 'ADJUST'},
  {title: 'Kochava', value: 'KOCHAVA'},
];

export const fmtPixelEvent = (str: string) =>
  str
    .split(/[-_]/)
    .map((s) => s[0].toUpperCase() + s.slice(1).toLowerCase())
    .join(' ');

export const getDefaultEvents = (pixel: {installations: string}) => {
  const installations = pixel.installations.split(',');
  const hasWeb = installations.includes('JAVASCRIPT');

  const WEB_DEFAULT = {name: 'Page View', type: 'page_view', isDefault: true};
  const MOBILE_DEFAULT = {
    name: 'Mobile Install',
    type: 'install',
    isDefault: true,
    installation: installations.find((kind) => kind !== 'JAVASCRIPT'),
  };

  return !hasWeb
    ? [MOBILE_DEFAULT]
    : hasWeb && installations.length === 1
    ? [WEB_DEFAULT]
    : [WEB_DEFAULT, MOBILE_DEFAULT];
};

export const getInstallationOptions = (pixel: {installations: string}) => {
  const installations = !!pixel.installations
    ? pixel.installations.split(',')
    : [];

  if (!installations.length) return pixelInstallationTypes;
  return pixelInstallationTypes.filter(({value}) =>
    installations.includes(value)
  );
};
