import React from 'react';
import {createRoot} from 'react-dom/client';
import {Helmet} from 'react-helmet-async';
import App from '@core/app/App';
import Auth from '@core/app/Auth';
import AnalyticsApp from './screens/AnalyticsApp';
import UnAuthed from './screens/UnAuthed';
import favicon16 from './static/favicon-16x16.png';
import favicon32 from './static/favicon-32x32.png';
import favicon96 from './static/favicon-96x96.png';

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App service='analytics'>
      <Helmet>
        <title>Ad Analytics</title>
        <link rel='icon' type='image/png' sizes='96x96' href={favicon96} />
        <link rel='icon' type='image/png' sizes='32x32' href={favicon32} />
        <link rel='icon' type='image/png' sizes='16x16' href={favicon16} />
        <link rel='icon' type='image/png' sizes='96x96' href={favicon96} />
        <link rel='shortcut icon' href={favicon32} type='image/x-icon' />
      </Helmet>
      <Auth service='analytics' UnAuthed={UnAuthed}>
        <AnalyticsApp me={null} />
      </Auth>
    </App>
  </React.StrictMode>
);
