import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import IndustrySelect from '@core/components/IndustrySelect';
import {ImageUploader} from '@core/components/Uploader';
import {IOrganizationCompany} from '@core/types';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {
  Form,
  FormFooter,
  FormGroup,
  Input,
  Label,
  Submit,
  TextArea,
} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {companyURL} from '@analytics/components/nav/ResearchNav';
import {
  FetchedOrganization,
  useUpdateCompanyMutation,
} from '@analytics/graphql-api';
import COMPANY_QUERY from '@analytics/graphql-api/_old_queries/research/CompanyQuery';

const CompanyEdit = ({
  company,
  organization,
}: {
  company: IOrganizationCompany;
  organization: FetchedOrganization;
}) => {
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [updateCompany] = useUpdateCompanyMutation({
    refetchQueries: [COMPANY_QUERY],
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    setError,
    formState: {errors, isDirty},
    watch,
  } = useForm({
    defaultValues: {
      name: company.name || '',
      url: company.url || '',
      industry: company.industry || '',
      description: company.description || '',
      image: '',
    },
  });

  const {image} = watch();

  const onSubmit = async ({name, image, industry, url}) => {
    if (!isDirty) {
      setComplete(true);
      return;
    }

    clearErrors();
    setFormLoading(true);
    try {
      const resp = await updateCompany({
        variables: {
          input: {
            id: company.id,
            slug: company.slug,
            name,
            industry,
            url,
            ...(!!image && {imageId: image}),
          },
        },
      });

      if (resp.data.authed.success) {
        setComplete(true);
      }
    } catch (e) {
      setError(null, e.message || 'Network Error.  Please try again.');
    }
    setFormLoading(false);
  };

  // ? Embedly internal key does not seem to be working
  // const onUrlPaste = ({target: {value}}) => {
  //   if (!description) {
  //     window
  //       .fetch(`http://api.embedly.com/1/oembed?url=${value}&key=internal`)
  //       .then((r) => r.json())
  //       .then((data) => {
  //         setValue('description', data.description);
  //       });
  //   }
  // };

  if (complete) {
    return <Redirect to={companyURL(organization, company)} />;
  }

  return (
    <ResponsiveContent containerSize='large'>
      <CardHeader>Update Brand</CardHeader>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
          <p>
            Make Ad Analytics Research better for everyone by editing our
            dataset.
          </p>
          <div
            css={{
              display: 'grid',
              gap: '2rem',
              gridTemplateColumns: '1fr 1fr',
            }}>
            <div>
              <Label>Image</Label>
              {!!company.image && <img src={company.image.src} />}
              {imageLoading ? (
                <Loading centered />
              ) : (
                // temp until update Image Uploader component with image preview
                <>
                  {!!image && (
                    <ToastMessage
                      alertType='success'
                      rules={() => ({margin: '1rem 1rem 0'})}>
                      Image successfully uploaded
                    </ToastMessage>
                  )}
                  <Controller
                    name='image'
                    control={control}
                    render={({field}) => (
                      <ImageUploader
                        onChange={(action, media) => {
                          if (action === 'added') {
                            setImageLoading(true);
                          } else if (action === 'uploaded') {
                            field.onChange(media.object.id);
                            setImageLoading(false);
                          }
                        }}
                        rules={() => ({flexGrow: 1, margin: 0})}
                      />
                    )}
                  />
                </>
              )}
            </div>
            <div>
              <Label>General Information</Label>
              <FormGroup>
                <Label>Name</Label>
                <Controller
                  name='name'
                  control={control}
                  rules={{required: 'Please provide company name.'}}
                  render={({field}) => (
                    <Input
                      placeholder='Name'
                      invalid={!!errors.name}
                      {...field}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Label>URL</Label>
                <Controller
                  name='url'
                  control={control}
                  render={({field}) => (
                    <Input
                      placeholder='URL'
                      // onPaste={onUrlPaste}
                      {...field}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Label>Industry</Label>
                <Controller
                  name='industry'
                  control={control}
                  rules={{
                    validate: (value) =>
                      !!value ? true : 'Please select an industry.',
                  }}
                  render={({field}) => (
                    <IndustrySelect
                      defaultValue={field.value}
                      invalid={!!errors.industry}
                      onSelect={({industry}) => field.onChange(industry)}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Label>Description</Label>
                <Controller
                  name='description'
                  control={control}
                  render={({field}) => <TextArea {...field} />}
                />
              </FormGroup>
              <FormFooter>
                <Submit disabled={formLoading} loading={formLoading}>
                  Update Brand
                </Submit>
              </FormFooter>
            </div>
          </div>
        </Form>
      </Card>
    </ResponsiveContent>
  );
};

export default CompanyEdit;
