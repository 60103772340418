import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import {useMutation} from '@apollo/client';
import {Element} from '@core/style';
import {ToastMessage} from '@core/ui/Alert';
import Button from '@core/ui/Button/LegacyButton';
import {Card, CardHeader} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import Capability from '@analytics/components/capabilities/Capability';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import SET_ADVISOR_OPTIONS from '@analytics/graphql-api/_old_mutations/SetAdvisorOptions';
import ADVISOR_OPTIONS_CAPABILITIES_QUERY from '@analytics/graphql-api/_old_queries/AdvisorOptionsCapabilitiesQuery';

const AdvisorOptionsCapabilities = ({
  organization,
  capabilities,
  allCapabilities,
  onSave,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedCapabilities, setSelectedCapabilities] = useState<string[]>(
    capabilities.map(({id}) => id)
  );
  const [setAdvisorOptions] = useMutation(SET_ADVISOR_OPTIONS);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await setAdvisorOptions({
        variables: {
          input: {
            organizationId: organization.id,
            capabilityIds: selectedCapabilities,
          },
        },
      });

      onSave();
    } catch (err) {
      setError(error);
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingSpinner centered />;
  } else if (error) {
    return (
      <ToastMessage alertType='warning'>
        Network error. Please try again later.
      </ToastMessage>
    );
  }
  return (
    <>
      {allCapabilities.map((capability) => {
        return (
          <Capability
            key={capability.id}
            capability={capability}
            active={selectedCapabilities.includes(capability.id)}
            onClick={(id) => {
              if (selectedCapabilities.includes(id)) {
                setSelectedCapabilities(
                  selectedCapabilities.filter((i) => i != id)
                );
              } else {
                setSelectedCapabilities([...selectedCapabilities, id]);
              }
            }}
          />
        );
      })}
      <Element
        rules={() => ({
          margin: '2rem 0 1rem',
          display: 'flex',
          justifyContent: 'space-between',
        })}>
        <Button onClick={onCancel} style-default>
          Cancel
        </Button>
        <Button onClick={onSubmit} style-primary>
          Continue ⇾
        </Button>
      </Element>
    </>
  );
};

const AdvisorOptionsCapabilitiesWrapper = ({
  organization,
  onSave,
  onCancel,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  onSave: () => void;
  onCancel: () => void;
}) => {
  const {data, error, loading} = useQuery(ADVISOR_OPTIONS_CAPABILITIES_QUERY, {
    variables: {
      id: organization.id,
    },
  });

  let content: React.ReactNode;

  if (loading) {
    content = <LoadingSpinner centered />;
  } else if (error) {
    content = (
      <ToastMessage alertType='warning'>
        Network error. Please try again later.
      </ToastMessage>
    );
  } else {
    const {capabilities} = data.me.organization;
    const allCapabilities = data.me.organization.advisor.options.capabilities;

    content = (
      <AdvisorOptionsCapabilities
        organization={organization}
        capabilities={capabilities}
        allCapabilities={allCapabilities}
        onSave={onSave}
        onCancel={onCancel}
      />
    );
  }

  return (
    <>
      <CardHeader>Capabilities</CardHeader>
      <Card>
        <p>
          Please select the following capabilities of your network and hosting
          provider that you support, allowing Ad Analytics to point advertisers
          in your direction accurately.
        </p>
        {content}
      </Card>
    </>
  );
};

export default AdvisorOptionsCapabilitiesWrapper;
