import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Card} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import ReportsExportInstancesTable from '@analytics/components/reports/ReportsExportInstancesTable';
import {useExportInstancesQuery} from '@analytics/graphql-api';
import {ReportsRouteProps} from './_routes';

export default function Reports({organization}: ReportsRouteProps) {
  const {data, loading, error} = useExportInstancesQuery({
    variables: {
      id: organization.id,
    },
  });

  if (loading) {
    return <LoadingSpinner centered />;
  }

  if (error) {
    return (
      <Alert severity='error' css={{margin: 'var(--spacing-7)'}}>
        {t('errors.network')}
      </Alert>
    );
  }

  const exportInstances = data?.me?.organization.exports.exportInstances ?? [];

  return (
    <Card>
      <ReportsExportInstancesTable
        exportInstances={exportInstances}
        organization={organization}
      />
    </Card>
  );
}
