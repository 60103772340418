import {gql} from '@apollo/client';

export default gql`
  mutation claimFeed($input: ClaimFeedInput!) {
    authed {
      success
    }
    claimFeed(input: $input) {
      publisher {
        name
        email
      }
    }
  }
`;
