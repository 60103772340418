import {useQuery} from '@apollo/client';
import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import Loading from '@core/ui/Loading';
import CAMPAIGN_PODCAST_QUERY from '@analytics/graphql-api/_old_queries/CampaignPodcastQuery';
import campaignPodcastRoutes from '@analytics/lib/routes/campaignPodcastRoutes';
import {CampaignRouteProps, toCampaignUrl} from '../../_routes';
import CampaignPodcast from './CampaignPodcast';
import CampaignPodcastConsistency from './CampaignPodcastConsistency';
import CampaignPodcastPrefix from './CampaignPodcastPrefix';
import CampaignPodcastUpdate from './CampaignPodcastUpdate';
import CampaignEpisodesIndex from './episodes';
import CampaignFuturesIndex from './futures';

export function CampaignPodcastIndex({
  user,
  organization,
  campaign,
  match: {
    params: {campaignPodcastId},
  },
}: CampaignRouteProps & {
  match: {
    params: {campaignPodcastId: string};
  };
}) {
  const {data, loading, error} = useQuery(CAMPAIGN_PODCAST_QUERY, {
    variables: {
      organizationId: organization.id,
      campaignId: campaign.id,
      id: campaignPodcastId,
    },
  });

  if (loading) {
    return <Loading fixed />;
  }

  if (error) {
    return <Redirect to={toCampaignUrl(organization, campaign)} />;
  }

  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(
      organization,
      `/campaigns/${campaign.slug}/podcasts/${campaignPodcastId}`
    ),
    basePath:
      '/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/podcasts/:campaignPodcastId',
    routes: campaignPodcastRoutes({user}),
  });

  const campaignPodcast = data?.me?.organization?.campaign?.campaignPodcast;

  return (
    <Switch>
      {renderRoutes({
        components: {
          'campaign-podcast': CampaignPodcast,
          consistency: CampaignPodcastConsistency,
          episodes: CampaignEpisodesIndex,
          futures: CampaignFuturesIndex,
          prefix: CampaignPodcastPrefix,
          update: CampaignPodcastUpdate,
        },
        extraProps: {
          user,
          organization,
          campaign,
          campaignPodcast,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
