import {campaignDynamicPodcastURL} from '@analytics/components/nav/CampaignDynamicPodcastNav';
import useSettings from '@analytics/components/settings/useSettings';
import {toModeled} from '@analytics/lib/utils';

const useCampaignDynamicPodcasts = (
  campaignDynamicPodcasts,
  {campaignDynamic, organization, campaign}
) => {
  const [settings] = useSettings();
  const results = [];

  for (const item of campaignDynamicPodcasts) {
    const {
      id,
      cost,
      costToDate,
      feed,
      reachTotal,
      downloadTotal,
      downloads,
      attribution,
      targetDownloadTotal,
      targetReachTotal,
    } = item;

    const dl = downloads && downloads.count ? downloads.count : downloadTotal;

    const _reachTotal = reachTotal;
    let _spend = settings.spendByImpressionsDelivered ? costToDate : cost;

    if (attribution) {
      // Ugh.
      _spend = (_spend / downloadTotal) * dl;
    } else if (campaignDynamic.costToDate >= campaignDynamic.cost) {
      _spend = (dl / campaignDynamic.downloadTotal) * campaignDynamic.cost;
    }

    const userTotal = toModeled(item, 'user', settings.modelled);

    results.push({
      id,
      title: feed ? feed.title : 'Unknown',
      url: campaignDynamicPodcastURL(organization, campaign, campaignDynamic, {
        id,
      }),
      campaignDynamicName: campaignDynamic.name,
      feed,
      costToDate: _spend,
      reachTotal,
      downloadTotal: dl,
      attributionTotal: userTotal,
      purchaseTotal: toModeled(item, 'purchase', settings.modelled),
      leadTotal: toModeled(item, 'lead', settings.modelled),
      valueTotal: toModeled(item, 'value', settings.modelled),
      installedTotal: toModeled(item, 'installed', settings.modelled),
      targetDownloadTotal,
      targetReachTotal,
      cr: _reachTotal ? userTotal / _reachTotal : 0,
      cpm: dl ? (_spend / dl) * 1000 : 0,
      frequency: _reachTotal ? dl / _reachTotal : 0,
      cpv: _reachTotal && userTotal ? _spend / userTotal : 0,
    });
  }

  return results.sort((a, b) => {
    return b.downloadTotal - a.downloadTotal;
  });
};

export default useCampaignDynamicPodcasts;
