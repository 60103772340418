import { OrganizationDocument, useAddAdStudioAdAccountMutation } from '@analytics/graphql-api';
import { createOrgPath } from '@core/lib/organizations';
import { t } from '@core/texts';
import {Card} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import { ButtonPrimary, CardTitle, FormGroup, FormInput, IconExclamationCircle, Snackbar, Text, Type } from '@spotify-internal/encore-web';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

interface IFormValues {
    businessId: string;
  }
  
const AD_STUDIO_BUSINESS_ID_REGEX =
/^[\da-fA-F]{8}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{12}$/i;

export const RejectedPixel = ({
    organization,
    pixel
  }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const toggleSnackbar = () => {
      setShowSnackbar(true);
      setTimeout(() => setShowSnackbar(false), 10000);
    };
    const [addAdStudio] = useAddAdStudioAdAccountMutation({
      refetchQueries: [OrganizationDocument],
      awaitRefetchQueries: true,
    });

    const {
        control,
        handleSubmit,
        formState: {errors},
      } = useForm({
        defaultValues: {
          businessId: '',
        },
      });

    const onSubmit = async ({businessId}: IFormValues) => {
        setLoading(true);
        try {
          const addAdStudioResp = await addAdStudio({
            variables: {
              input: {
                id: organization.id,
                businessId,
              },
            },
          });
          if (addAdStudioResp) {
            history.push(createOrgPath(organization, `manage/pixels/${pixel.key}/ad-studio`));
          }
        } catch (err) {
            toggleSnackbar()
        } finally {
          setLoading(false);
        }
      };

    return (
        <ResponsiveContent>
            <Card>
                <CardTitle variant='balladBold'
                css={`font-size: 24px`}>
                    Something went wrong
                    <IconExclamationCircle style={{marginLeft: '8px'}}/>
                </CardTitle>
                <Text key='textSubdued' as="p" variant='bodyMedium' semanticColor='textSubdued' paddingBottom='1.5em'>
                    Verify the Ad Studio account number you provided is correct. If you’re still experiencing issues, contact us at adstudio@spotify.com.
                </Text>
                <Text as="p" variant="bodySmallBold" paddingBottom="0.5em">
                    Pixel ID
                </Text>
                <Text as="p" variant="bodySmall" paddingBottom='1.5em'>
                    {pixel.key}
                </Text>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label="Ad Studio account number" labelFor='businessId'>
                        <Controller
                        name='businessId'
                        control={control}
                        defaultValue=""
                        rules={{
                            pattern: AD_STUDIO_BUSINESS_ID_REGEX
                        }}
                        render={({field}) => (
                            <FormInput
                            id='businessId'
                            placeholder='(e.g. abf4de9b-799d-4f23-9ef8-9fd0c91aa07a)'
                            {...field}
                            />
                        )}
                        />
                        {errors.businessId && (
                            <Type semanticColor='textNegative'>
                            Provide a valid Ad Studio account number
                            </Type>
                        )}
                    </FormGroup>
                <ButtonPrimary disabled={loading} type="submit">
                    Retry
                </ButtonPrimary>
                </form>
            </Card>
            <Snackbar
                showSnackbar={showSnackbar}
                colorSet="negative">
                Business ID update failed: {t('errors.network')}
            </Snackbar>
        </ResponsiveContent>
    )};