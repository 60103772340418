import {useState} from 'react';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import {Stack} from '@core/ui/Layout';
import Loading from '@core/ui/Loading';
import {Text} from '@core/ui/Text';
import {
  FetchedOrganization,
  FetchedPodcast,
  usePodcastExperianTypesQuery,
} from '@analytics/graphql-api';
import AudienceChartCard from './AudienceChartCard';
import {getExperianTypesData} from './helpers_chartData';
import {AudienceCategory} from './types';

type ExperianInfoCardProps = {
  metrics: {
    variable: string;
    index: number;
    percentage: number;
    descr: string;
    features: string[];
  }[];
  selectedIdx: number;
};

const ExperianTypesInfoCard = ({
  metrics,
  selectedIdx,
}: ExperianInfoCardProps) => {
  const {variable, index, percentage, descr, features} = metrics[selectedIdx];
  return (
    <div
      css={{
        flex: '27%',
        marginLeft: '-15px',
        marginTop: `${selectedIdx * 3.125}rem`,
        maxWidth: '27%',
        position: 'relative',
      }}>
      <div
        css={{
          width: 0,
          height: 0,
          borderTop: '18px solid transparent',
          borderBottom: '18px solid transparent',
          borderRight: '18px solid var(--bg-muted)',
          float: 'left',
          marginLeft: '-12px',
          marginTop: '4rem',
        }}
      />
      <Card
        rules={() => ({
          background: 'var(--bg-muted)',
          border: 'none',
          display: 'grid',
          gridTemplateColumns: 'repeat(4, fit-content)',
          gap: '1rem',
          margin: 0,
        })}>
        <div css={{fontSize: '1.25rem', fontWeight: 600}}>{variable}</div>
        <div css={{color: 'var(--text-muted)', fontSize: '1rem'}}>{descr}</div>
        <div
          css={{
            borderTop: '1px solid var(--border-default)',
            borderBottom: '1px solid var(--border-default)',
            padding: '.675em 0',
          }}>
          <div
            css={{
              color: 'var(--green)',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div>Index</div>
            <div>{index}</div>
          </div>
          <div
            css={{
              color: 'var(--blue-80)',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div>Percentage</div>
            <div>{percentage}%</div>
          </div>
        </div>
        <ul
          css={{
            color: 'var(--text-muted)',
            fontSize: 'var(--font-size-body-1)',
          }}>
          {features.map((el, i) => (
            <li key={i}>{el}</li>
          ))}
        </ul>
      </Card>
    </div>
  );
};

type Props = {
  organization: FetchedOrganization;
  podcast: FetchedPodcast;
  category: AudienceCategory;
  experianApproval?: boolean;
};

export default function AudienceExperianTypes({
  organization,
  podcast,
  category,
  experianApproval,
}: Props) {
  const [selectedIdx, setSelectedIdx] = useState(0);
  const {data, loading} = usePodcastExperianTypesQuery({
    variables: {
      organizationId: organization.id,
      podcastId: podcast.id,
    },
  });

  const onBarClick = (_, idx: number) =>
    selectedIdx !== idx && setSelectedIdx(idx);

  const [index, percentage] = experianApproval
    ? getExperianTypesData(
        data?.me?.organization?.advisor?.podcast?.feed?.mosaicTypes
      )
    : [null, null];

  return (
    <>
      <CardHeader
        css={`
          margin: 0 var(--spacing-6) var(--spacing-5);
        `}>
        Experian Mosaic&reg; Types
      </CardHeader>
      <Card variant='filled'>
        <Stack direction='column' gap='var(--spacing-2)'>
          <Text color='muted' variant='h4'>
            Understanding Experian Mosaic&reg; Types
          </Text>
          <Text color='muted' variant='body2'>
            {category === 'index'
              ? `The indices below express the difference between your podcast's
            audience and the US general population. An index of 100 indicates that
            the audience segment is in line with the US population. An index
            greater than 100 indicates that the audience segment is
            over-represented in your podcast compared to the US general
            population, and an index less than 100 indicates that your podcast's
            audience is under-represented compared to the US general population.`
              : `The percentages below indicate the percentage of your podcast's audience that
            makes up each Mosaic Type. Percentages will add up to 100% as a household will only
            be counted within one Type.`}
          </Text>
        </Stack>
      </Card>
      <div css={{margin: '1.75rem 1.5rem'}}>
        {loading ? (
          <Loading centered />
        ) : index && percentage ? (
          <Stack>
            <div
              css={{
                position: 'relative',
                flex: '75%',
                maxWidth: '75%',
              }}>
              <AudienceChartCard
                category={category}
                extended
                metrics={category === 'index' ? index : percentage}
                onBarClick={onBarClick}
                topic='Experian Mosaic® Types'
              />
            </div>
            <ExperianTypesInfoCard
              metrics={category === 'index' ? index : percentage}
              selectedIdx={selectedIdx}
            />
          </Stack>
        ) : (
          <Card rules={() => ({margin: 0})}>
            <EmptyMessage>
              Experian Mosaic&reg; Types data unavailable.
            </EmptyMessage>
          </Card>
        )}
      </div>
    </>
  );
}
