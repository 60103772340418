import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import Loading from '@core/ui/Loading';
import {useCampaignLiftReportsQuery} from '@analytics/graphql-api';
import {CampaignRouteProps} from '../_routes';
import {CampaignLift} from './CampaignLift';
import {CampaignRequestLift} from './CampaignRequestLift';
import {useCampaignLiftRoutes} from './_routes';
import {CampaignNoisesIndex} from './noises';
import {CampaignLiftReportIndex} from './report';

export function CampaignLiftIndex({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  const {data, error, loading} = useCampaignLiftReportsQuery({
    variables: {
      organizationId: organization.id,
      id: campaign.id,
    },
  });

  if (loading) {
    return <Loading fixed centered />;
  }

  if (error) {
    return (
      <Redirect
        to={createOrgPath(organization, `campaigns/${campaign.slug}`)}
      />
    );
  }

  const {renderRoutes} = useCampaignLiftRoutes({organization, campaign});

  const liftReports =
    data?.me?.organization?.campaign?.liftReports?.filter(
      (report) => !!report
    ) ?? [];

  return (
    <Switch>
      {renderRoutes({
        components: {
          lift: CampaignLift,
          request: CampaignRequestLift,
          report: CampaignLiftReportIndex,
          households: CampaignNoisesIndex,
        },
        extraProps: {
          user,
          organization,
          campaign,
          liftReports,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
