import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';

export type AdvisorRouteProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
};

export const useAdvisorRoutes = ({
  organization,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, '/advisor'),
    basePath: '/:part(b|a|s|p)/:slug/advisor',
    routes: [
      {
        key: 'advisor',
        title: 'Advisor',
        path: '',
        exact: true,
      },
      {
        key: 'podcast',
        path: 'podcast/:podcastId',
      },
      {
        title: '',
        key: 'onboard',
        path: 'onboard',
      },
    ],
  });
};
