import {format} from 'd3-format';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from '@core/lib/dayjs';
import {ChartItem} from '../_types';
import {UsageInterval} from '../_types';
import {UsageTooltip} from './UsageTooltip';

export function UsageChart({
  data = [],
  height = 500,
  interval,
  width = '100%',
}: {
  data: ChartItem[];
  height?: number | string;
  interval: UsageInterval;
  width?: number | string;
}) {
  const isMonthly = interval.includes('month');
  const isCumulative = interval.includes('cumulative');
  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid stroke='var(--border-default)' strokeDasharray='0 0' />
        <XAxis
          axisLine={false}
          dataKey='x'
          tick={{
            fill: 'var(--text-muted)',
            fontSize: 'var(--font-size-body-2)',
          }}
          tickFormatter={(timeStr) =>
            dayjs(timeStr).format(isMonthly ? "MMM 'YY" : 'MMM D, YY')
          }
          tickLine={false}
          type='category'
        />
        <YAxis
          dataKey='y'
          stroke='var(--border-muted)'
          tick={{
            fill: 'var(--text-muted)',
            fontSize: 'var(--font-size-body-2)',
          }}
          tickLine={false}
          type='number'
          tickFormatter={(val) => format(',')(val)}
        />
        <Tooltip
          cursor={false}
          content={
            <UsageTooltip isCumulative={isCumulative} isMonthly={isMonthly} />
          }
        />
        <Bar dataKey='y' fill='var(--green)' radius={[5, 5, 5, 5]} />
      </BarChart>
    </ResponsiveContainer>
  );
}
