import {useCallback} from 'react';
import styled from 'styled-components';
import {createOrgPath} from '@core/lib/organizations';
import useLocalStorage from '@core/lib/useLocalStorage';
import {ButtonIcon} from '@core/ui/Button';
import {DropdownMenu, DropdownMenuItemType} from '@core/ui/DropdownMenu';
import {LoadingSpinner} from '@core/ui/Loading';
import {useOverlay} from '@core/ui/Overlay';
import {Text} from '@core/ui/Text';
import {Tooltip} from '@core/ui/Tooltip';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {ExportsNotification} from './ExportsNotification';
import {IndustryNotificationOverlay} from './IndustryNotificationOverlay';
import {useNotifications} from './helpers';

type NotificationsFeed = DropdownMenuItemType & {id: string};
const notificationsFeeds: NotificationsFeed[] = [];

type Props = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
};

export function Notifications({organization}: Props) {
  const [openedIndustrySelect, toggleIndustrySelect] = useOverlay();
  const [readNotifications, setReadNotifications] = useLocalStorage<{
    [key: string]: boolean;
  }>({
    id: 'read-notifications',
    initialState: {},
  });

  const {
    data: {
      campaignsForReview,
      company,
      completedExports,
      missedEpisodes,
      unconfirmedUrls,
    },
    loading,
  } = useNotifications({organization});

  const notifications = [
    ...(campaignsForReview > 0
      ? [
          {
            title: (
              <span>
                You have <b>{campaignsForReview}</b> campaigns to review
              </span>
            ),
            to: createOrgPath(organization, '/campaigns'),
            description: 'Today',
          },
        ]
      : []),
    ...(missedEpisodes > 0
      ? [
          {
            title: (
              <span>
                You have <b>{missedEpisodes}</b> missed campaign episodes to
                review
              </span>
            ),
            to: createOrgPath(organization, '/campaigns/integrity'),
            description: 'Today',
          },
        ]
      : []),
    ...(unconfirmedUrls > 0
      ? [
          {
            title: (
              <span>
                You have <b>{unconfirmedUrls}</b> campaigns with unconfirmed
                tracking urls to review
              </span>
            ),
            to: createOrgPath(organization, '/campaigns/tracking-urls'),
            description: 'Today',
          },
        ]
      : []),
    ...(company && !company.industry
      ? [
          {
            title: (
              <span>
                Ad Analytics now collects industry data — Please provide your
                brand's industry
              </span>
            ),
            callback: () => toggleIndustrySelect(true),
            description: 'Today',
          },
        ]
      : []),
    ...notificationsFeeds.filter(
      ({id}) => !id || !readNotifications || !readNotifications[id]
    ),
  ];

  const _onSubmit = useCallback(
    (item: NotificationsFeed) =>
      !!item.id &&
      setReadNotifications({...readNotifications, [item.id]: true}),
    [setReadNotifications, readNotifications]
  );

  const hasNotifications = notifications.length > 0;

  return (
    <>
      <DropdownMenu
        items={[
          {
            content: (
              <NotificationHeader>
                <Text variant='body1' color='muted'>
                  {notifications.length} new notifications
                </Text>
                {loading && (
                  <LoadingSpinner
                    size='small'
                    css={{marginLeft: 'var(--spacing-2)'}}
                  />
                )}
              </NotificationHeader>
            ),
          },
          ...notifications,
        ]}
        onSubmit={_onSubmit}
        overlayProps={{verticalOffset: 4, fixed: true}}
        overlayCss={{
          maxWidth: '25rem',
          width: '25rem',
        }}>
        <Tooltip
          description='Notifications'
          overlayProps={{horizontalAlign: 'center', verticalOffset: 3}}>
          <NotificationButton
            aria-label='Notifications'
            icon={hasNotifications ? 'unread-notifications' : 'notifications'}
            hasNotifications
          />
        </Tooltip>
      </DropdownMenu>
      <ExportsNotification
        organization={organization}
        completedExports={completedExports}
      />
      {openedIndustrySelect && company && (
        <IndustryNotificationOverlay
          company={company}
          opened={openedIndustrySelect}
          toggle={toggleIndustrySelect}
        />
      )}
    </>
  );
}

const NotificationButton = styled(ButtonIcon)<{hasNotifications: boolean}>`
  padding: var(--spacing-1);
  margin-bottom: -1px;
  ${(button) =>
    button.hasNotifications && {
      '& .dot': {
        color: 'var(--red-orange)',
      },
    }}
`;

const NotificationHeader = styled.div`
  align-items: center;
  background: var(--bg-overlay);
  display: flex;
  padding: var(--spacing-2) var(--spacing-6) var(--spacing-2);
  position: sticky;
  top: 0;
`;
