"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = maxIndex;

function maxIndex(values, valueof) {
  let max;
  let maxIndex = -1;
  let index = -1;

  if (valueof === undefined) {
    for (const value of values) {
      ++index;

      if (value != null && (max < value || max === undefined && value >= value)) {
        max = value, maxIndex = index;
      }
    }
  } else {
    for (let value of values) {
      if ((value = valueof(value, ++index, values)) != null && (max < value || max === undefined && value >= value)) {
        max = value, maxIndex = index;
      }
    }
  }

  return maxIndex;
}