import {Card} from '@core/ui/Content';
import { ButtonPrimary, CardTitle, Text } from '@spotify-internal/encore-web';

export const AcceptedPixel = ({
    organization,
    pixel
  }) => {

return (
    <Card>
        <CardTitle variant='balladBold'
        css={`font-size: 24px`}>
            Your Spotify Pixel is available in Spotify Ad Studio
        </CardTitle>
        <Text key='textSubdued' as="p" variant='bodyMedium' semanticColor='textSubdued' paddingBottom='1.5em'>
            Go to Ad Studio to add your pixel to your next campaign.
        </Text>
        <Text as="p" variant="bodySmallBold" paddingBottom="0.5em">
            Pixel ID
        </Text>
        <Text as="p" variant="bodySmall" paddingBottom='1.5em'>
            {pixel.key}
        </Text>
        <Text as="p" variant="bodySmallBold" paddingBottom="0.5em">
            Ad Studio account number
        </Text>
        <Text as="p" variant="bodySmall" paddingBottom="0.75em">
            {organization.adStudioBusinessId}
        </Text>
        <ButtonPrimary href={`https://adstudio.spotify.com/account/${organization.adStudioBusinessId}/pixels`} target="_blank" >
            Go to Ad Studio
        </ButtonPrimary>
    </Card>
)};