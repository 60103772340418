var _excluded = ["localAlias"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import { cssifyDeclaration } from 'css-in-js-utils';
import { arrayEach, arrayReduce, arrayFilter } from 'fast-loops';
import isPlainObject from 'isobject';
import { generateDeclarationReference, generateCombinedMediaQuery, generateCSSSelector, isMediaQuery, isNestedSelector, isUndefinedValue, isSupport, normalizeNestedProperty, processStyleWithPlugins, STATIC_TYPE, RULE_TYPE, KEYFRAME_TYPE, FONT_TYPE, CLEAR_TYPE } from 'fela-utils';
import cssifyFontFace from './cssifyFontFace';
import cssifyKeyframe from './cssifyKeyframe';
import cssifyKeyframeRule from './cssifyKeyframeRule';
import cssifyStaticStyle from './cssifyStaticStyle';
import _generateAnimationName from './generateAnimationName';
import _generateClassName from './generateClassName';
import generateFontSource from './generateFontSource';
import generateStaticReference from './generateStaticReference';
import getFontLocals from './getFontLocals';
import isSafeClassName from './isSafeClassName';
import toCSSString from './toCSSString';
import validateSelectorPrefix from './validateSelectorPrefix';
import sortMediaQuery from './sortMediaQuery';
import extractCustomClassName from './extractCustomClassName';
export default function createRenderer() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var renderer = {
    listeners: [],
    keyframePrefixes: config.keyframePrefixes || ['-webkit-', '-moz-'],
    plugins: config.plugins || [],
    sortMediaQuery: config.sortMediaQuery || sortMediaQuery(config.mediaQueryOrder),
    supportQueryOrder: config.supportQueryOrder || [],
    styleNodeAttributes: config.styleNodeAttributes || {},
    propertyPriority: config.propertyPriority || {},
    ruleOrder: [/^:link/, /^:visited/, /^:hover/, /^:focus-within/, /^:focus/, /^:active/],
    selectorPrefix: validateSelectorPrefix(config.selectorPrefix),
    specificityPrefix: config.specificityPrefix || '',
    filterClassName: config.filterClassName || isSafeClassName,
    devMode: config.devMode || false,
    uniqueRuleIdentifier: 0,
    uniqueKeyframeIdentifier: 0,
    nodes: {},
    scoreIndex: {},
    // use a flat cache object with pure string references
    // to achieve maximal lookup performance and memoization speed
    cache: {},
    getNextRuleIdentifier: function getNextRuleIdentifier() {
      return ++renderer.uniqueRuleIdentifier;
    },
    getNextKeyframeIdentifier: function getNextKeyframeIdentifier() {
      return ++renderer.uniqueKeyframeIdentifier;
    },
    renderRule: function renderRule(rule) {
      var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return renderer._renderStyle(rule(props, renderer), props);
    },
    renderKeyframe: function renderKeyframe(keyframe) {
      var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var resolvedKeyframe = keyframe(props, renderer);
      var processedKeyframe = processStyleWithPlugins(renderer, resolvedKeyframe, KEYFRAME_TYPE, props);
      var keyframeReference = cssifyKeyframeRule(processedKeyframe);

      if (!renderer.cache.hasOwnProperty(keyframeReference)) {
        // use another unique identifier to ensure minimal css markup
        var animationName = renderer.selectorPrefix + renderer.generateAnimationName(props);
        var cssKeyframe = cssifyKeyframe(processedKeyframe, animationName, renderer.keyframePrefixes, keyframeReference);
        var change = {
          type: KEYFRAME_TYPE,
          keyframe: cssKeyframe,
          name: animationName
        };
        renderer.cache[keyframeReference] = change;

        renderer._emitChange(change);
      }

      return renderer.cache[keyframeReference].name;
    },
    generateAnimationName: function generateAnimationName(_props) {
      return _generateAnimationName(renderer.getNextKeyframeIdentifier());
    },
    renderFont: function renderFont(family, files) {
      var properties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var localAlias = properties.localAlias,
          otherProperties = _objectWithoutProperties(properties, _excluded);

      var fontReference = family + JSON.stringify(properties);
      var fontLocals = getFontLocals(localAlias);

      if (!renderer.cache.hasOwnProperty(fontReference)) {
        var fontFamily = toCSSString(family);

        var fontFace = _objectSpread(_objectSpread({}, otherProperties), {}, {
          src: generateFontSource(files, fontLocals),
          fontFamily: fontFamily
        });

        var cssFontFace = cssifyFontFace(fontFace);
        var change = {
          type: FONT_TYPE,
          fontFace: cssFontFace,
          fontFamily: fontFamily
        };
        renderer.cache[fontReference] = change;

        renderer._emitChange(change);
      }

      return renderer.cache[fontReference].fontFamily;
    },
    renderStatic: function renderStatic(staticStyle, selector) {
      var props = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var staticReference = generateStaticReference(staticStyle, selector);

      if (!renderer.cache.hasOwnProperty(staticReference)) {
        var cssDeclarations = cssifyStaticStyle(staticStyle, renderer, props);
        var change = {
          type: STATIC_TYPE,
          css: cssDeclarations,
          selector: selector
        };
        renderer.cache[staticReference] = change;

        renderer._emitChange(change);
      }
    },
    subscribe: function subscribe(callback) {
      renderer.listeners.push(callback);
      return {
        unsubscribe: function unsubscribe() {
          return renderer.listeners.splice(renderer.listeners.indexOf(callback), 1);
        }
      };
    },
    clear: function clear() {
      renderer.uniqueRuleIdentifier = 0;
      renderer.uniqueKeyframeIdentifier = 0;
      renderer.cache = {};

      renderer._emitChange({
        type: CLEAR_TYPE
      });
    },
    _renderStyle: function _renderStyle() {
      var style = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var customClassName = extractCustomClassName(style);
      var processedStyle = processStyleWithPlugins(renderer, style, RULE_TYPE, props, renderer.unoptimizedPlugins || renderer.plugins);
      return customClassName + renderer._renderStyleToClassNames(processedStyle).substr(1);
    },
    _renderStyleToClassNames: function _renderStyleToClassNames(style) {
      var pseudo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var media = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      var support = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
      var classNames = '';

      for (var property in style) {
        var value = style[property];

        if (isPlainObject(value)) {
          if (isNestedSelector(property)) {
            classNames += renderer._renderStyleToClassNames(value, pseudo + normalizeNestedProperty(property), media, support);
          } else if (isMediaQuery(property)) {
            var combinedMediaQuery = generateCombinedMediaQuery(media, property.slice(6).trim());
            classNames += renderer._renderStyleToClassNames(value, pseudo, combinedMediaQuery, support);
          } else if (isSupport(property)) {
            var combinedSupport = generateCombinedMediaQuery(support, property.slice(9).trim());
            classNames += renderer._renderStyleToClassNames(value, pseudo, media, combinedSupport);
          } else {
            console.warn("The object key \"".concat(property, "\" is not a valid nested key in Fela.\nMaybe you forgot to add a plugin to resolve it?\nCheck http://fela.js.org/docs/basics/Rules.html#styleobject for more information."));
          }
        } else {
          var declarationReference = generateDeclarationReference(property, value, pseudo, media, support);

          if (renderer.cacheMap) {
            if (!renderer.cacheMap.hasOwnProperty(declarationReference)) {
              var pluginInterface = {
                property: property,
                value: value,
                pseudo: pseudo,
                media: media,
                support: support
              };
              var processed = arrayReduce(renderer.optimizedPlugins, function (processed, plugin) {
                return plugin(processed, renderer);
              }, pluginInterface);
              var cacheReference = generateDeclarationReference(processed.property, processed.value, processed.pseudo, processed.media, processed.support);

              if (!renderer.cache.hasOwnProperty(cacheReference)) {
                renderer._renderStyleToCache(cacheReference, processed.property, processed.value, processed.pseudo, processed.media, processed.support);
              }

              renderer.cacheMap[declarationReference] = cacheReference;
            }

            declarationReference = renderer.cacheMap[declarationReference];
          }

          if (!renderer.cache.hasOwnProperty(declarationReference)) {
            renderer._renderStyleToCache(declarationReference, property, value, pseudo, media, support);
          }

          var cachedClassName = renderer.cache[declarationReference].className; // only append if we got a class cached

          if (cachedClassName) {
            classNames += ' ' + cachedClassName;
          }
        }
      }

      return classNames;
    },
    _renderStyleToCache: function _renderStyleToCache(reference, property, value, pseudo, media, support) {
      // we remove undefined values to enable
      // usage of optional props without side-effects
      if (isUndefinedValue(value)) {
        renderer.cache[reference] = {
          className: ''
        };
        return;
      }

      var className = renderer.selectorPrefix + renderer.generateClassName(property, value, pseudo, media, support);
      var declaration = cssifyDeclaration(property, value);
      var selector = generateCSSSelector(className, pseudo, config.specificityPrefix, renderer.propertyPriority[property]);
      var change = {
        type: RULE_TYPE,
        className: className,
        selector: selector,
        declaration: declaration,
        pseudo: pseudo,
        media: media,
        support: support
      };
      renderer.cache[reference] = change;

      renderer._emitChange(change);
    },
    generateClassName: function generateClassName(property, value, pseudo, media, support) {
      return _generateClassName(renderer.getNextRuleIdentifier, renderer.filterClassName);
    },
    _emitChange: function _emitChange(change) {
      arrayEach(renderer.listeners, function (listener) {
        return listener(change);
      });
    }
  }; // initial setup

  renderer.keyframePrefixes.push('');
  renderer.optimizedPlugins = arrayReduce(renderer.plugins, function (plugins, plugin) {
    if (plugin.optimized) {
      plugins.push(plugin.optimized);
    }

    return plugins;
  }, []); // only enable the cache map if we have optimized plugins

  if (renderer.optimizedPlugins.length > 0) {
    renderer.cacheMap = {};
    renderer.unoptimizedPlugins = arrayFilter(renderer.plugins, function (plugin) {
      return !plugin.optimized;
    });
  }

  if (config.enhancers) {
    arrayEach(config.enhancers, function (enhancer) {
      renderer = enhancer(renderer);
    });
  }

  return renderer;
}