import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {GraphErrors} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import ClaimAddPodcastMutation from '@analytics/graphql-api/_old_mutations/ClaimAddPodcast';
import ClaimVerifyQuery from '@analytics/graphql-api/_old_queries/ClaimVerifyQuery';

class Claim extends React.Component {
  state = {
    loading: false,
    errors: [],
  };

  onSubmit = (evt) => {
    evt.preventDefault();
    this.setState({
      loading: true,
    });

    const {
      claimAddPodcast,
      data: {
        claim: {
          verify: {
            hash,
            href,
            organization: {id},
          },
        },
      },
    } = this.props;

    claimAddPodcast({
      hash,
      href,
      organizationId: id,
    })
      .then(
        ({
          data: {
            claimAddPodcast: {
              organization: {slug},
            },
          },
        }) => {
          this.setState({
            loading: false,
            complete: slug,
          });
        }
      )
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  render() {
    const {user, data} = this.props;

    if (data.loading) {
      return <Loading centered />;
    }

    if (data.error) {
      return (
        <ResponsiveContent>
          <Element>
            <h1
              css={{
                fontSize: '1.5rem',
                display: 'inline-block',
                marginBottom: '1rem',
              }}>
              Invalid Request
            </h1>
            <p>
              We are unable to verify your feed at this time. If you think you
              received this in error, please contact{' '}
              <a href='mailto:support-adanalytics@spotify.com'>
                support-adanalytics@spotify.com
              </a>
            </p>
          </Element>
        </ResponsiveContent>
      );
    }

    const {title, href, email, name, hash, organization} = data.claim.verify;
    const {loading, errors, complete} = this.state;

    if (complete) {
      return <Redirect to={`/p/${complete}`} />;
    }

    return (
      <ResponsiveContent
        containerSize='medium'
        rules={() => ({marginTop: '4rem'})}>
        <CardHeader>{title} - Ad Analytics Ownership</CardHeader>
        <Card>
          <form onSubmit={this.onSubmit}>
            <GraphErrors
              errors={errors}
              onClose={() => {
                this.setState({
                  errors: [],
                });
              }}
            />
            <p>
              When you click confirm, Ad Analytics will add the podcast{' '}
              <b>"{title}"</b> to the organization <b>{organization.name}</b>.
              Doing so will allow the members of <b>{organization.name}</b> to
              add this podcast to campaigns and see download information if you
              install the Ad Analytics analytics prefix or set up a log
              transfer.
            </p>
            <p>
              If you or someone from the organization <b>{organization.name}</b>{' '}
              did not request this confirmation, please hit cancel or close the
              window.
            </p>

            <Element rules={() => ({padding: '20px', textAlign: 'right'})}>
              <Button
                to='/'
                disabled={loading}
                css={`
                  margin-right: 10px;
                `}>
                Cancel
              </Button>

              <Submit disabled={loading}>Confirm</Submit>
            </Element>
          </form>
        </Card>
      </ResponsiveContent>
    );
  }
}

export default compose(
  graphql(ClaimVerifyQuery, {
    options: ({
      match: {
        params: {payload},
      },
    }) => ({
      variables: {
        payload,
      },
    }),
  }),
  graphql(ClaimAddPodcastMutation, {
    props: ({mutate}) => ({
      claimAddPodcast: (input) => mutate({variables: {input}}),
    }),
  })
)(Claim);
