import {createOrgPath} from '@core/lib/organizations';
import {Nav} from '@core/ui/Nav';
import {useOverlay} from '@core/ui/Overlay';
import ExportOverlay from '@analytics/components/ExportOverlay';
import {FetchedOrganization} from '@analytics/graphql-api';
import {usePixelRoutes} from '@analytics/screens/unified/manage/pixels/pixel/_routes';

export default function PixelNav({
  organization,
  pixel,
  user,
}: {
  organization: FetchedOrganization;
  pixel: TSFixMe;
  user: TSFixMe;
}) {
  const [openedExport, toggleExport] = useOverlay();
  const {routes} = usePixelRoutes({organization, key: pixel.key});
  const navTitle = [
    {
      title: 'Pixels',
      to: createOrgPath(organization, `/manage/pixels`),
    },
    {title: pixel.domain},
  ];

  return (
    <>
      <Nav
        title={navTitle}
        items={routes}
        moreDropdownItems={[
          {
            title: 'Export',
            callback: () => toggleExport(true),
          },
        ]}
      />
      <ExportOverlay
        objectType='key'
        kind='pixel_hourly'
        pk={pixel.key}
        organizationId={organization.id}
        opened={openedExport}
        toggle={toggleExport}
      />
    </>
  );
}
