import {useState} from 'react';
import {ButtonPrimary} from '@spotify-internal/encore-web';
import styled from 'styled-components';
import SpotifyBanner from '@analytics/components/onboard/SpotifyBanner';
import BrandSvg from './brand.svg';
import BuySideSvg from './buy-side.svg';
import PublisherSvg from './publisher.svg';
import SellSideSvg from './sell-side.svg';

const ORGANIATION_KINDS = [
  {
    title: 'Brand advertiser',
    value: 'brand',
    description: 'Buys advertisements',
    image: BrandSvg,
  },
  {
    title: 'Publisher',
    value: 'podcast',
    description: 'Makes podcasts and sell advertisements',
    image: PublisherSvg,
  },
  {
    title: 'Sell-side agency',
    value: 'sell-side',
    description: 'Represents podcasts as an advertisement seller',
    image: SellSideSvg,
  },
  {
    title: 'Buy-side agency',
    value: 'buy-side',
    description: 'Buys advertisements on podcasts',
    image: BuySideSvg,
  },
];

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  padding: 64px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Card = styled.div<{selected?: boolean}>`
  display: flex;
  border-radius: 16px;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  gap: 24px;
  cursor: pointer;
  border: ${({selected}) => (selected ? 'none' : '1px solid #D9D9D9')};
  transition: box-shadow 0.3s ease;
  box-shadow: ${({selected}) =>
    selected
      ? '0px 1px 4px rgba(0, 0, 0, 0.25), 0px 3px 20px rgba(0, 0, 0, 0.2)'
      : 'none'};

  &:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25), 0px 3px 20px rgba(0, 0, 0, 0.2);
  }
`;

const CardImage = styled.img`
  width: 150px;
  height: 130px;
`;

const CardTitle = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
`;

const CardDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px;
`;

const OnboardSelector = ({onSubmit}) => {
  const [selectedCard, setSelectedCard] = useState(null);

  const cardOnClick = (value) => {
    setSelectedCard(value);
  };

  const onNextClick = () => {
    onSubmit(selectedCard);
  };

  return (
    <PageContainer>
      <SpotifyBanner advertising progress={19} />
      <ContentContainer>
        <Title>Choose your organization type</Title>
        <CardContainer>
          {ORGANIATION_KINDS.map(({title, value, description, image}) => (
            <Card
              key={value}
              onClick={() => cardOnClick(value)}
              selected={selectedCard === value}>
              <CardImage src={image} />
              <CardTitle>{title}</CardTitle>
              <CardDescription>{description}</CardDescription>
            </Card>
          ))}
        </CardContainer>
        <ButtonPrimary
          onClick={onNextClick}
          disabled={!selectedCard}
          style={{minWidth: 300}}>
          Next
        </ButtonPrimary>
      </ContentContainer>
    </PageContainer>
  );
};

export default OnboardSelector;
