import {gql} from '@apollo/client';

export default gql`
  mutation processFeed($input: ProcessFeedInput!) {
    authed {
      success
    }
    processFeed(input: $input) {
      success
    }
  }
`;
