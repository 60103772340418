import {createOrgPath} from '@core/lib/organizations';
import {EpisodeImage} from '@core/ui/Image';
import {Nav} from '@core/ui/Nav';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {campaignDynamicURL} from './CampaignDynamicNav';
import {campaignDynamicPodcastURL} from './CampaignDynamicPodcastNav';

const campaignDynamicEpisodeURL = (
  organization,
  campaign,
  campaignDynamic,
  campaignDynamicPodcast,
  campaignDynamicEpisode
) =>
  createOrgPath(
    organization,
    `/campaigns/${campaign.slug}/dynamics/${campaignDynamic.id}/podcasts/${campaignDynamicPodcast.id}/episodes/${campaignDynamicEpisode.id}`
  );

const CampaignDynamicEpisodeNav = ({
  organization,
  campaign,
  campaignDynamic,
  campaignDynamicPodcast,
  campaignDynamicEpisode,
}) => {
  const {feed} = campaignDynamicPodcast;
  const {episode} = campaignDynamicEpisode;

  return (
    <Nav
      title={`${episode ? episode.title : 'Unknown'}`}
      description={episode ? episode.description : ''}
      Image={
        episode
          ? () => <EpisodeImage episode={episode} feed={feed} width={150} />
          : undefined
      }
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/measure/campaigns'),
          title: 'Campaigns',
        },
        {
          to: toCampaignUrl(organization, campaign),
          title: `${campaign.name}`,
        },
        {
          to: campaignDynamicURL(organization, campaign, campaignDynamic),
          title: `${campaignDynamic.name}`,
        },
        {
          to: campaignDynamicPodcastURL(
            organization,
            campaign,
            campaignDynamic,
            campaignDynamicPodcast
          ),
          title: `${feed ? feed.title : 'Unknown'}`,
        },
        {
          title: 'Dynamic Ad Podcast Episode',
        },
      ]}
    />
  );
};

export {campaignDynamicEpisodeURL};

export default CampaignDynamicEpisodeNav;
