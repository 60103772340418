import React, {useReducer} from 'react';
import {useMutation} from '@apollo/client';
import striptags from 'striptags';
import DesignPodlinkDomain from '@analytics/graphql-api/_old_mutations/DesignPodlinkDomain';
import {PODLINK_HOST} from '@analytics/lib/podlink';
import DesignContent, {DEFAULT_COLORS} from './DesignContent';

const decodeEntities = (text) => {
  const el = document.createElement('textarea');
  el.innerHTML = text;
  return el.value;
};

const DesignDomain = ({organization, podlinkDomain, onSave, onCancel}) => {
  const [designPodlinkDomain] = useMutation(DesignPodlinkDomain, {
    update: (
      cache,
      {
        data: {
          designPodlinkDomain: {podlinkDomain},
        },
      }
    ) => {
      cache.modify({
        id: cache.identify(podlinkDomain),
        fields: {
          backgroundColor() {
            return podlinkDomain.backgroundColor;
          },
          fontColor() {
            return podlinkDomain.fontColor;
          },
          primaryColor() {
            return podlinkDomain.primaryColor;
          },
          secondaryColor() {
            return podlinkDomain.secondaryColor;
          },
          image() {
            return podlinkDomain.image;
          },
          favicon() {
            return podlinkDomain.favicon;
          },
          title() {
            return podlinkDomain.title;
          },
          description() {
            return podlinkDomain.description;
          },
        },
      });
    },
  });

  // config
  const [config, setConfig] = useReducer(
    (state, newState) => {
      return {...state, ...newState};
    },
    Object.assign(
      {},
      DEFAULT_COLORS,
      {
        favicon: podlinkDomain.favicon,
        image: podlinkDomain.image,
        title: podlinkDomain.title,
        description: decodeEntities(podlinkDomain.description),
      },
      ['backgroundColor', 'fontColor', 'primaryColor', 'secondaryColor'].reduce(
        (a, attr) => {
          if (podlinkDomain[attr]) {
            a[attr] = podlinkDomain[attr];
          }

          return a;
        },
        {}
      )
    )
  );

  const _onSave = async () => {
    const {
      backgroundColor,
      fontColor,
      primaryColor,
      secondaryColor,
      image,
      favicon,
      title,
      description,
    } = config;

    const input = {
      backgroundColor,
      fontColor,
      primaryColor,
      secondaryColor,
      imageId: image ? image.id : null,
      faviconId: favicon ? favicon.id : null,
      title,
      description: striptags(description),
    };

    try {
      await designPodlinkDomain({
        variables: {
          input: {
            id: podlinkDomain.id,
            ...input,
          },
        },
      });
      onSave(input);
    } catch (err) {}
  };

  return (
    <DesignContent
      previewURL={`${PODLINK_HOST}/?subdomain=${podlinkDomain.slug}`}
      kind='domain'
      organization={organization}
      onSave={_onSave}
      config={config}
      setConfig={setConfig}
      onCancel={onCancel}
    />
  );
};

export default DesignDomain;
