import {Reducer, createContext, useContext, useReducer} from 'react';
import {ExportId} from '../../../../globals';

//   ___      _    _ _
//  | _ \_  _| |__| (_)__
//  |  _/ || | '_ \ | / _|
//  |_|  \_,_|_.__/_|_\__|

export const useExportsContext = () => useContext(ExportsContext)!;

export const ExportsProvider = ({children}) => {
  const [pollExports, act] = useReducer(reducer, []);

  return (
    <ExportsContext.Provider
      // eslint-disable-next-line react/no-children-prop
      children={children}
      value={
        {
          pollExports,
          addExport: (id: ExportId) => act({t: 'AddExport', id}),
          removeExport: (id: ExportId) => act({t: 'RemoveExport', id}),
          clearExports: () => act({t: 'ClearExports'}),
        }!
      }
    />
  );
};

//   ___     _          _
//  | _ \_ _(_)_ ____ _| |_ ___
//  |  _/ '_| \ V / _` |  _/ -_)
//  |_| |_| |_|\_/\__,_|\__\___|

const ExportsContext = createContext<
  | {
      pollExports: ExportId[];
      addExport: (_: ExportId) => void;
      removeExport: (_: ExportId) => void;
      clearExports: () => void;
    }
  | undefined
>(undefined);

const notEqual = (id1) => (id2) => id1 !== id2;

type State = ExportId[];

type Action =
  | {t: 'AddExport'; id: ExportId}
  | {t: 'RemoveExport'; id: ExportId}
  | {t: 'ClearExports'};

const reducer: Reducer<State, Action> = (state, action) => {
  if (action.t === 'AddExport') return [...state, action.id];
  if (action.t === 'RemoveExport') return state.filter(notEqual(action.id));
  if (action.t === 'ClearExports') return [] as ExportId[];
  // eslint-disable-next-line no-throw-literal
  throw new Error('Invalid action') as never;
};
