import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignRouteProps} from '../../_routes';
import CampaignPermission from './CampaignPermission';

export function CampaignPermissionIndex({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/campaigns/:campaignSlug/permissions/:accessId'
        user={user}
        organization={organization}
        campaign={campaign}
        component={CampaignPermission}
      />
    </Switch>
  );
}
