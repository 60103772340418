import {BLUE, Element} from '@core/style';

const buttonRule = ({theme}) => ({
  alignItems: 'center',
  background: theme.bgTertiary,
  borderRadius: '.5rem',
  color: theme.textTertiary,
  cursor: 'pointer',
  display: 'inline-flex',
  height: '2.1875rem',
  justifyContent: 'center',
  width: '2.1875rem',
  ':hover': {
    color: BLUE,
  },
});

const AudioSkip = ({onSkip}) => {
  return (
    <Element rules={() => ({display: 'flex'})}>
      <Element rules={buttonRule} onClick={() => onSkip(-10)}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 24 24'>
          <path d='M12.5 3c4.65 0 8.58 3.03 9.97 7.22L20.1 11c-1.05-3.19-4.06-5.5-7.6-5.5-1.96 0-3.73.72-5.12 1.88L10 10H3V3l2.6 2.6C7.45 4 9.85 3 12.5 3M10 12v10H8v-8H6v-2h4m8 2v6c0 1.11-.89 2-2 2h-2a2 2 0 01-2-2v-6a2 2 0 012-2h2a2 2 0 012 2m-4 0v6h2v-6h-2z' />
        </svg>
      </Element>
      <Element
        rules={[buttonRule, () => ({marginLeft: '0.625rem'})]}
        onClick={() => onSkip(10)}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 24 24'>
          <path d='M10 12v10H8v-8H6v-2h4m8 2v6c0 1.11-.89 2-2 2h-2a2 2 0 01-2-2v-6a2 2 0 012-2h2a2 2 0 012 2m-4 0v6h2v-6h-2M11.5 3c2.65 0 5.05 1 6.9 2.6L21 3v7h-7l2.62-2.62C15.23 6.22 13.46 5.5 11.5 5.5c-3.54 0-6.55 2.31-7.6 5.5l-2.37-.78C2.92 6.03 6.85 3 11.5 3z' />
        </svg>
      </Element>
    </Element>
  );
};

export default AudioSkip;
