import {isSelling} from '@core/lib/organizations';
import {Card} from '@core/ui/Content';
import {Text} from '@core/ui/Text';
import {canSetCampaignLive} from '@analytics/lib/campaigns';
import {CampaignDraftProps} from '.';
import {CampaignPathButton, Grid} from './_shared';

export function CampaignDraftModeCard({
  organization,
  campaign,
}: CampaignDraftProps) {
  return (
    <Card css={{margin: 0, border: 0}} variant='filled'>
      <Grid columns='1fr auto'>
        <Text>
          <b>This campaign is in draft mode.</b> Please review the details of
          this campaign. The
          {isSelling(organization) ? ' advertiser' : ' publisher'} also has
          access to view this page.
        </Text>
        {canSetCampaignLive({organization, campaign}) && (
          <CampaignPathButton toCampaignPath='state' variant='filled'>
            Set Campaign Live
          </CampaignPathButton>
        )}
      </Grid>
    </Card>
  );
}
