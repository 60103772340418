import {Controller, useForm} from 'react-hook-form';
import {Form, Label, Submit} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {Link} from '@core/ui/Link';
import Select from '@core/ui/Select';
import {Text} from '@core/ui/Text';
import {CAMPAIGN_TYPE_SELECT} from '../_helpers';
import {CampaignKind} from '../_types';

export function CampaignCreateSelectTypeForm({
  onSubmit,
}: {
  onSubmit?: ({kind}: {kind: CampaignKind}) => void;
}) {
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const _onSubmit = ({kind}) => {
    onSubmit?.({kind});
  };

  return (
    <Form onSubmit={handleSubmit(_onSubmit)} errors={errors}>
      <Label htmlFor='campaignType'>
        What type of campaign are you creating?
      </Label>
      <Controller
        name='kind'
        control={control}
        rules={{
          validate: (value) => !!value || 'Please provide a campaign type.',
        }}
        render={({field, fieldState}) => (
          <Select
            buttonProps={{id: 'campaignType'}}
            placeholder='Select Campaign Type'
            items={CAMPAIGN_TYPE_SELECT}
            onSelect={(item) => field.onChange(item.value)}
            invalid={!!fieldState.error}
          />
        )}
      />
      <Text variant='body3' color='muted' css={{marginTop: 'var(--spacing-3)'}}>
        Unsure of what type of campaign you want to set up? Click{' '}
        <Link
          color='muted'
          href='https://help.podsights.com/types-of-campaigns'
          target='_blank'
          underline='always'>
          here
        </Link>{' '}
        to learn more about campaign types.
      </Text>
      <Stack justifyContent='flex-end' css={{marginTop: 'var(--spacing-8)'}}>
        <Submit>Continue</Submit>
      </Stack>
    </Form>
  );
}
