import {comma, toFixed, toPercent} from '@core/lib/filters';
import {t} from '@core/texts';
import {Card} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import Stat from '@core/ui/Stat';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  useOverviewImpressionsReachQuery,
} from '@analytics/graphql-api';
import {InsightPerformanceSerie} from './insightsPerformanceSeries';

interface InsightsPerformanceStatsProps {
  after: string;
  before: string;
  campaignKind: string;
  data: {[key: string]: any};
  lineItems: any[];
  organization: FetchedOrganization | FetchedBuySideOrganization;
  series: InsightPerformanceSerie[];
}

const InsightsPerformanceStats = ({
  after,
  before,
  campaignKind,
  data,
  lineItems,
  organization,
  series,
}: InsightsPerformanceStatsProps) => {
  const {
    loading,
    error,
    data: respData,
  } = useOverviewImpressionsReachQuery({
    variables: {
      id: organization.id,
      after,
      before,
      campaignIds: lineItems.reduce((acc: any[], item: any) => {
        if (
          acc.indexOf(item.campaignId) < 0 &&
          campaignKind === item.campaignKind
        ) {
          acc.push(item.campaignId);
        }
        return acc;
      }, []),
    },
  });

  const {impressions, reach} = respData?.me?.organization?.overview
    ?.impressions ?? {impressions: 0, reach: 0};

  return (
    <>
      <Card
        rules={() => ({
          display: 'grid',
          gridTemplateRows: 'auto auto',
          marginTop: '1rem',
        })}>
        <div
          css={{
            display: 'grid',
            gridGap: '1rem',
            gridTemplateColumns: 'repeat(3, 1fr)',
            paddingBottom: '1.5rem',
          }}>
          <Stat
            {...t('stats.impressions')}
            value={
              loading ? <LoadingSpinner size='small' /> : comma(impressions)
            }
          />
          <Stat
            {...t('stats.reach')}
            value={loading ? <LoadingSpinner size='small' /> : comma(reach)}
          />
          <Stat
            {...t('stats.frequency')}
            value={
              loading ? (
                <LoadingSpinner size='small' />
              ) : reach > 0 ? (
                toFixed(impressions / reach)
              ) : (
                0
              )
            }
          />
        </div>
        <div
          css={{
            borderTop: '1px solid var(--border-default)',
            display: 'grid',
            gridGap: '1rem',
            gridTemplateColumns: 'repeat(3, 1fr)',
            paddingTop: '1.5rem',
          }}>
          {series
            .filter(
              ({accessor}) =>
                accessor !== 'impressions' &&
                accessor !== 'reach' &&
                accessor !== 'spend' &&
                accessor !== 'cr'
            )
            .map(({title, fmt, accessor, description, longDescription}) => {
              return (
                <Stat
                  title={title}
                  longDescription={longDescription}
                  description={description}
                  value={
                    typeof data[accessor] === 'undefined'
                      ? 'n/a'
                      : fmt(data[accessor])
                  }
                  horizontal
                />
              );
            })}
          {loading ? (
            <LoadingSpinner size='small' />
          ) : (
            <Stat
              {...t('stats.cr')}
              value={toPercent(reach > 0 ? data.households / reach : 0)}
              horizontal
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default InsightsPerformanceStats;
