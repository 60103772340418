import {useState} from 'react';
import {FetchedUser} from '@core/graphql-api';
import Select from '@core/ui/Select';
import CampaignDynamicsTable from '@analytics/components/campaigns/CampaignDynamicsTable';
import CampaignEpisodesTable from '@analytics/components/campaigns/CampaignEpisodesTable';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {LiftRunDynamic, LiftRunEpisode} from './types';

type LiftFilterDynamicsTableProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  dynamicsData: LiftRunDynamic[];
};

export const LiftFilterDynamicsTable = ({
  organization,
  dynamicsData,
}: LiftFilterDynamicsTableProps) => {
  const [selectedCampaign, setSelectedCampaign] = useState(
    dynamicsData[0].campaign.id
  );

  const {nodes, campaign} = dynamicsData.find(
    (d) => d.campaign.id === selectedCampaign
  ) ?? {nodes: [], campaign: dynamicsData[0].campaign};

  return (
    <>
      <Select
        defaultValue={selectedCampaign}
        items={dynamicsData.map((d) => d.campaign)}
        propertyForName='name'
        propertyForValue='id'
        small
        outline
        onSelect={({id}) => setSelectedCampaign(id)}
        rules={() => ({marginBottom: '1rem'})}
      />
      <CampaignDynamicsTable
        organization={organization}
        campaign={campaign}
        campaignDynamics={nodes}
      />
    </>
  );
};

type LiftFilterEpisodesTableProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  episodesData: LiftRunEpisode[];
  user: FetchedUser;
};

export const LiftFilterEpisodesTable = ({
  organization,
  episodesData,
  user,
}: LiftFilterEpisodesTableProps) => {
  const [selectedCampaign, setSelectedCampaign] = useState(
    episodesData[0].campaign.id
  );

  const {nodes, campaign} = episodesData.find(
    (d) => d.campaign.id === selectedCampaign
  ) ?? {nodes: [], campaign: episodesData[0].campaign};

  return (
    <>
      <Select
        defaultValue={selectedCampaign}
        items={episodesData.map((d) => d.campaign)}
        propertyForName='name'
        propertyForValue='id'
        small
        outline
        onSelect={({id}) => setSelectedCampaign(id)}
        rules={() => ({marginBottom: '1rem'})}
      />
      <CampaignEpisodesTable
        organization={organization}
        campaign={campaign}
        campaignEpisodes={nodes}
        user={user}
      />
    </>
  );
};
