export interface ProgressBarProps {
  bgColor?: string;
  max: number;
  progressColor?: string;
  value: number;
}

export function ProgressBar({
  bgColor = 'var(--bg-muted)',
  max,
  progressColor = 'var(--green)',
  value,
  ...props
}: ProgressBarProps) {
  const pct = Math.floor((value * 100) / max);

  return (
    <div
      css={`
        background: ${bgColor};
        height: 0.125rem;
        width: 100%;
      `}
      {...props}>
      <div
        css={{
          background: progressColor,
          borderRadius: 'inherit',
          height: '100%',
          width: `${Math.min(pct, 100)}%`,
        }}
      />
    </div>
  );
}

export default ProgressBar;
