import {gql} from '@apollo/client';

export default gql`
  mutation unknownAd($input: UnknownAdInput!) {
    unknownAd(input: $input) {
      ad {
        id
        createdAt
      }
    }
  }
`;
