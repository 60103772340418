import {useCallback} from 'react';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import {DataTableServer} from '@core/ui/DataTable';
import {
  FetchedOrganization,
  FetchedPodcast,
  PodcastEpisodesDetailDocument,
  PodcastEpisodesDetailQuery,
} from '@analytics/graphql-api';
import {COLUMNS} from './columns';

export function EpisodesTable({
  organization,
  podcast,
}: {
  organization: FetchedOrganization;
  podcast: FetchedPodcast;
}) {
  return (
    <DataTableServer
      columns={COLUMNS}
      emptyMessageText='No episodes found'
      onClickRowPath={({id}) =>
        createOrgPath(organization, `/podcasts/${podcast.id}/episodes/${id}`)
      }
      orderBy='-published'
      toData={(data: PodcastEpisodesDetailQuery) => ({
        data: data?.me?.organization.podcast.feed?.episodes ?? [],
        count: data?.me?.organization.podcast.feed?.episodesCount ?? 0,
      })}
      toVariables={useCallback(
        ({rowsPerPage, rowStart}) => ({
          organizationId: organization.id,
          id: podcast.id,
          algorithm: organization.downloadAlgorithm,
          after: dayjs(podcast.processingStartedAt)
            .subtract(1, 'week')
            .format('YYYY-MM-DD'),
          limit: rowsPerPage,
          offset: rowStart,
        }),
        [organization, podcast]
      )}
      query={PodcastEpisodesDetailDocument}
    />
  );
}
