import React from 'react';
import OSIcon from '@core/components/OSIcon';
import dayjs from '@core/lib/dayjs';
import {formatDate} from '@core/lib/time';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {Dummy, EpisodeImage} from '@core/ui/Image';

const AttributionDownloadsTable = ({downloads}) => {
  const columns = [
    {
      title: 'Episode',
      accessor: 'episode',
      type: 'string',
      Cell: ({data: {episode, feed}}) => (
        <Element rules={() => ({display: 'flex'})}>
          <Element
            rules={() => ({
              flex: '0 0 4.6875rem',
              marginRight: '0.625rem',
            })}>
            {episode ? (
              <EpisodeImage episode={episode} feed={feed} />
            ) : (
              <Dummy />
            )}
          </Element>
          <Element rules={() => ({flex: 1})}>
            <Element rules={() => ({fontWeight: 500})}>
              {episode ? episode.title : 'Unkown Impression'}
            </Element>
            {episode ? <p>{episode.description}</p> : ''}
          </Element>
        </Element>
      ),
    },
    {
      title: 'Device',
      accessor: 'uid',
      type: 'string',
      Cell: ({data: {userAgent}}) => (
        <Element>
          <OSIcon os={userAgent.os} />
        </Element>
      ),
    },
    {
      title: 'Location',
      accessor: 'city',
      type: 'string',
    },
    {
      title: 'Published',
      accessor: 'published',
      type: 'date',
      Cell: ({data: {published}}) => (
        <Element>{published ? formatDate(published, 'MMM, D') : '-'}</Element>
      ),
    },
    {
      title: 'Downloaded',
      accessor: 'time',
      type: 'date',
      fmt: (v) => formatDate(v, 'MMM, D'),
    },
  ];

  const data = downloads.slice(0).map((d) => {
    d.title = d.episode ? d.episode.title : 'Unkown Impression';
    d.published = d.episode ? dayjs(d.episode.published).utc() : null;
    return d;
  });

  return <DataTable columns={columns} data={data} orderBy='-time' />;
};

export {AttributionDownloadsTable};
