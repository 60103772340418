import {useQuery} from '@apollo/client';
import {t} from '@core/texts';
import {ToastMessage} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import ApprovalsTable from '@analytics/components/approvals/ApprovalsTable';
import CAMPAIGN_APPROVALS_QUERY from '@analytics/graphql-api/_old_queries/CampaignApprovalsQuery';
import {CampaignRouteProps, toCampaignUrl} from '../_routes';

export function Approvals({campaign, organization}: CampaignRouteProps) {
  const {loading, error, data} = useQuery(CAMPAIGN_APPROVALS_QUERY, {
    variables: {
      organizationId: organization.id,
      campaignId: campaign.id,
    },
  });

  if (loading) {
    return <Loading centered />;
  }

  if (error) {
    return (
      <ToastMessage alertType='warning'>{t('errors.network')}</ToastMessage>
    );
  }
  const {
    dynamicApprovals = [],
    podcastApprovals = [],
    streamingApprovals = [],
  } = data?.me?.organization?.campaign ?? {};

  return (
    <>
      <ToastMessage alertType='error'>
        This page is only visible to Ad Analytics members
      </ToastMessage>
      <CardHeader
        rightContent={
          <Button
            to={`${toCampaignUrl(organization, campaign)}/approvals/request`}
            color='primary'>
            Request Approvals
          </Button>
        }>
        Requested approvals
      </CardHeader>
      <Card>
        <ApprovalsTable
          campaign={campaign}
          dynamicApprovals={dynamicApprovals}
          podcastApprovals={podcastApprovals}
          streamingApprovals={streamingApprovals}
        />
      </Card>
    </>
  );
}
