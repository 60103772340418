import { advanceGentle, borderRadiusRounded, browserFocusReset, buttonLinkReset, buttonStylesDisabled, controlPaddingBlock, controlSizeFallback, cssBorderWidth, cssColorValue, cssControlSize, cssSpacing, durationShort, focusGapBorder, overflowWrapFlexText, rootStyle } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
import { capitalize } from "../../utils.js";
var chipBorderRadius = borderRadiusRounded;
var backgroundColor = function backgroundColor(variant) {
  return variant === 'contrasting' ? cssColorValue('backgroundBase') : cssColorValue('backgroundTintedBase');
};
var getPadding = function getPadding(size) {
  return size === 'medium' ? cssSpacing('tighter-2', controlPaddingBlock[size]) : cssSpacing('tighter-4', controlPaddingBlock[size]);
};
export var ChipComponent = styled.button.withConfig({
  displayName: "Chip__ChipComponent",
  componentId: "sc-ry3uox-0"
})(["", ""], function (_ref) {
  var $size = _ref.$size,
    $variant = _ref.$variant,
    $useBrowserDefaultFocusStyle = _ref.$useBrowserDefaultFocusStyle,
    $isUsingKeyboard = _ref.$isUsingKeyboard,
    $semanticColorBase = _ref.$semanticColorBase;
  return css(["", " ", " ", " position:relative;display:inline-flex;align-items:center;justify-content:center;vertical-align:middle;min-block-size:", ";min-inline-size:0;max-inline-size:100%;padding:", ";color:", ";background-color:", ";border-radius:", ";border:", ";text-decoration:none;flex-shrink:0;transform:translate(0,0);transition:background-color ", " ", ";", " &:hover{background-color:", ";}&:active{background-color:", ";}&[disabled],&[aria-disabled='true'] &&{", ";}&[disabled]:hover,&[aria-disabled='true']:hover{background-color:", ";}"], rootStyle(), buttonLinkReset($useBrowserDefaultFocusStyle), overflowWrapFlexText(), cssControlSize($size, controlSizeFallback[$size]), $variant === 'bordered' ? "calc(".concat(getPadding($size), " - 1px)") : getPadding($size), cssColorValue("text".concat(capitalize($semanticColorBase))), backgroundColor($variant), chipBorderRadius, $variant === 'bordered' ? "".concat(cssBorderWidth('hairline', '1px'), " solid ").concat(cssColorValue("essential".concat(capitalize($semanticColorBase)))) : 'none', durationShort, advanceGentle, !$useBrowserDefaultFocusStyle && css(["", " ", ";"], browserFocusReset, focusGapBorder(chipBorderRadius, $isUsingKeyboard, false, $variant === 'bordered')), $variant === 'contrasting' ? cssColorValue('backgroundHighlight') : cssColorValue('backgroundTintedHighlight'), $variant === 'contrasting' ? cssColorValue('backgroundPress') : cssColorValue('backgroundTintedPress'), buttonStylesDisabled, backgroundColor($variant));
});