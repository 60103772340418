import {useRef} from 'react';
import {clampValue} from '@core/lib/utils';
import {Element} from '@core/style';
import {Input, InputPrefixContainer} from '@core/ui/FormElements';
import {AdvisorColumn} from '../types';

interface Props {
  columnGroup: AdvisorColumn;
  defaultValues: {id: string; value: string}[];
  onChange: (values: number[]) => void;
}

export default function AdvisorRangeFilter({
  columnGroup,
  defaultValues,
  onChange,
}: Props) {
  const minInputRef = useRef<HTMLInputElement>();
  const maxInputRef = useRef<HTMLInputElement>();
  const {type} = columnGroup;

  const filterMin =
    typeof columnGroup.filterMin === 'number'
      ? columnGroup.filterMin * (type === 'segment' ? 100 : 1)
      : null;
  const filterMax =
    typeof columnGroup.filterMax === 'number'
      ? columnGroup.filterMax * (type === 'segment' ? 100 : 1)
      : null;

  const onBlur = ({target}) => {
    if (typeof filterMin === 'number' || typeof filterMax === 'number') {
      const value = Number(target.value);
      target.value = clampValue(value, filterMin, filterMax);
    }
    onChange([
      Number(minInputRef.current.value) / (type === 'segment' ? 100 : 1),
      Number(maxInputRef.current.value) / (type === 'segment' ? 100 : 1),
    ]);
  };

  const onFocus = (evt) => {
    evt.target.select();
  };

  return (
    <Element
      rules={() => ({
        display: 'grid',
        fontSize: '0.875rem',
        gridGap: '0.625rem',
        gridTemplateColumns: '1fr 1fr',
      })}>
      <InputPrefixContainer prefix='Min'>
        <Input
          domRef={minInputRef}
          type='number'
          min={filterMin}
          defaultValue={
            typeof defaultValues[0] === 'number'
              ? type === 'segment'
                ? defaultValues[0] * 100
                : defaultValues[0]
              : typeof filterMin === 'number'
              ? filterMin
              : 0
          }
          onBlur={onBlur}
          onFocus={onFocus}
          small
          rules={() => ({
            fontSize: 'inherit',
            height: '2.125rem',
            paddingLeft: '2.125rem',
          })}
        />
      </InputPrefixContainer>
      <InputPrefixContainer prefix='Max'>
        <Input
          domRef={maxInputRef}
          type='number'
          defaultValue={
            typeof defaultValues[1] === 'number'
              ? type === 'segment'
                ? defaultValues[1] * 100
                : defaultValues[1]
              : typeof filterMax === 'number'
              ? filterMax
              : null
          }
          max={filterMax}
          onBlur={onBlur}
          onFocus={onFocus}
          small
          rules={() => ({
            fontSize: 'inherit',
            height: '2.125rem',
            paddingLeft: '2.375rem',
          })}
        />
      </InputPrefixContainer>
    </Element>
  );
}
