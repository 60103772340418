import {financial} from '@core/lib/filters';
import {t} from '@core/texts';
import useSettings from '@analytics/components/settings/useSettings';
import {IOrganizationOverviewBrand} from '@analytics/graphql-api/_old_queries/OrganizationOverviewBrandQuery';
import {SERIES} from './insightsPerformanceSeries';

const sumAggregate = (obj, node, modelled) => {
  SERIES.forEach(({accessor, isModeled}) => {
    const key = modelled && isModeled ? `${accessor}Modeled` : accessor;
    if (typeof obj[accessor] !== 'number') {
      obj[accessor] = 0;
    }
    if (typeof node[key] === 'number' && !isNaN(node[key])) {
      obj[accessor] += node[key];
    }
  });
};

const useInsightsPerformanceData = (
  lineItems: IOrganizationOverviewBrand['me']['organization']['overview']['brand']['nodes']
) => {
  const [settings] = useSettings();
  const statsData: {[key: string]: number} = {};
  const tableDataByLineItem = {};
  const chartDataByTime = {};
  const selectedLineItemsNames = lineItems.map((item) =>
    item.campaignDynamicId === 'embedded'
      ? item.episodeTitle
      : item.lineItemName
  );

  for (const item of lineItems) {
    const {
      campaignDynamicId,
      campaignId,
      campaignName,
      lineItemName,
      episodeTitle,
      podcastTitle,
      publisherName,
      time,
    } = item;
    const isEmbedded = campaignDynamicId === 'embedded';
    const tableKey = isEmbedded ? campaignId + episodeTitle : campaignDynamicId;
    const _lineItemName = isEmbedded ? episodeTitle : lineItemName;

    if (
      selectedLineItemsNames.length === 0 ||
      selectedLineItemsNames.indexOf(_lineItemName) > -1
    ) {
      if (!tableDataByLineItem[tableKey]) {
        tableDataByLineItem[tableKey] = {
          lineItemName: _lineItemName,
          campaignDynamicId,
          campaignId,
          campaignName,
          publisherName,
          podcastTitle,
        };
      }

      if (!chartDataByTime[time]) {
        chartDataByTime[time] = {x: new Date(time).getTime()};
      }

      sumAggregate(chartDataByTime[time], item, settings.modelled);
      sumAggregate(statsData, item, settings.modelled);
      sumAggregate(tableDataByLineItem[tableKey], item, settings.modelled);
    }
  }

  const tableData: {[key: string]: any}[] = Object.values(tableDataByLineItem);
  const chartData = Object.values(chartDataByTime).sort((a: any, b: any) => {
    return a.x - b.x;
  });

  const statsDataKeys = Object.keys(statsData);
  const chartSeries = statsDataKeys.length
    ? statsDataKeys
        .map((key) => {
          if (statsData[key]) {
            return SERIES.find(({accessor}) => accessor === key);
          }
        })
        .filter(Boolean)
    : [];

  /* TODO: Make the code below nicer */
  const hasHouseholds = statsData.purchases > 0;
  const hasPurchases = statsData.purchases > 0;
  const hasLeads = statsData.leads > 0;
  const hasAttributedDownloads = statsData.attributedDownloads > 0;
  const statsSeries = [
    ...chartSeries,
    {
      accessor: 'cpm',
      description: 'Cost per 1,000 impressions',
      fmt: financial,
      title: 'CPM',
      type: 'number',
    },
  ];
  statsData.cpm =
    statsData.impressions > 0
      ? (statsData.spend / statsData.impressions) * 1000
      : 0;

  tableData.forEach((item) => {
    item.cpm =
      item.impressions > 0 ? (item.spend / item.impressions) * 1000 : 0;
    if (hasPurchases) {
      item.cac = item.purchases > 0 ? item.spend / item.purchases : 0;
    }
    if (hasHouseholds) {
      item.cr = item.reach > 0 ? item.households / item.reach : 0;
      item.cpv = item.households > 0 ? item.spend / item.households : 0;
    }
    if (hasLeads) {
      item.cpl = item.leads > 0 ? item.spend / item.leads : 0;
    }
    if (hasAttributedDownloads) {
      item.cpd =
        item.attributedDownloads > 0
          ? item.spend / item.attributedDownloads
          : 0;
    }
  });

  if (hasPurchases) {
    statsSeries.push({
      accessor: 'cac',
      title: 'CAC',
      fmt: financial,
      type: 'number',
      ...t('stats.cac'),
    });
    statsData.cac = statsData.spend / statsData.purchases;
  }

  if (hasHouseholds) {
    statsSeries.push({
      accessor: 'cpv',
      title: 'CPV',
      fmt: financial,
      type: 'number',
      ...t('stats.cpv'),
    });
    statsData.cpv = statsData.spend / statsData.households;
  }

  if (hasLeads) {
    statsSeries.push({
      accessor: 'cpl',
      title: 'CPL',
      fmt: financial,
      type: 'number',
      ...t('stats.cpl'),
    });
    statsData.cpl = statsData.spend / statsData.leads;
  }

  if (hasAttributedDownloads) {
    statsSeries.push({
      accessor: 'cpd',
      title: 'CPD',
      fmt: financial,
      type: 'number',
      ...t('stats.cpd'),
    });
    statsData.cpd = statsData.spend / statsData.attributedDownloads;
  }

  return {
    chartData,
    tableData,
    statsData,
    chartSeries,
    statsSeries,
  };
};

export default useInsightsPerformanceData;
