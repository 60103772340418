import {useState} from 'react';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  FetchedReportExportInstances,
  useExportInstanceLazyQuery,
} from '@analytics/graphql-api';
import {ReportsTableButton} from '../shared';

type Props = {
  disabled?: boolean;
  exportInstance: FetchedReportExportInstances[number];
  organization: FetchedOrganization | FetchedBuySideOrganization;
};

export default function DownloadInstanceButton({
  disabled,
  exportInstance,
  organization,
}: Props) {
  const [error, setError] = useState(false);
  const [fetchDownloadUrl, {loading}] = useExportInstanceLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: exportInstance?.id,
      organizationId: organization.id,
    },
    onCompleted: (data) => {
      const downloadUrl =
        data?.me?.organization?.exports?.exportInstance?.downloadUrl;
      if (downloadUrl) window.open(downloadUrl, '_blank');
      else setError(true);
    },
    onError: (e) => {
      console.warn(e.message);
      setError(true);
    },
  });

  return (
    <ReportsTableButton
      error={error}
      loading={loading}
      buttonIconProps={{
        disabled,
        icon: 'reports',
        onClick: () => {
          if (error) setError(false);
          fetchDownloadUrl();
        },
      }}
      tooltipProps={{
        description: 'Download report',
      }}
    />
  );
}
