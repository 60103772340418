import {gql} from '@apollo/client';

export default gql`
  fragment CampaignDynamicEpisode on CampaignDynamicEpisodeObject {
    id
    cost
    costToDate

    downloadTotal
    reachTotal
    attributionTotal
    userTotal
    userTotalModeled
    productTotal
    productTotalModeled
    addtocartTotal
    addtocartTotalModeled
    checkoutTotal
    checkoutTotalModeled
    purchaseTotal
    purchaseTotalModeled
    leadTotal
    leadTotalModeled
    valueTotal
    valueTotalModeled
    installedTotal
    installedTotalModeled

    targetDownloadTotal
    targetReachTotal

    startAt
    endAt

    episode {
      id
      title
      description(words: 25)
      image {
        id
        href
        path
      }
      published
      enclosure {
        id
        length
        duration
        mpegPath
        waveform
      }
    }
  }
`;
