import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import {useTheme} from '@core/style';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import SegmentedControls from '@core/ui/SegmentedControls';
import InsightsDatePicker from '@analytics/components/insights/InsightsDatePicker';
import InsightsOverlapTable from '@analytics/components/insights/InsightsOverlapTable';
import BRAND_ORGANIZATION_CAMPAIGN_OVERLAP_QUERY from '@analytics/graphql-api/_old_queries/BrandOrganizationCampaignOverlapQuery';
import BRAND_ORGANIZATION_EPISODE_OVERLAP_QUERY from '@analytics/graphql-api/_old_queries/BrandOrganizationOverlapQuery';
import BRAND_ORGANIZATION_PUBLISHER_OVERLAP_QUERY from '@analytics/graphql-api/_old_queries/BrandOrganizationPublisherOverlapQuery';
import {MeasureInsightsRouteProps} from '..';

const config: any = {
  publishers: {
    query: BRAND_ORGANIZATION_PUBLISHER_OVERLAP_QUERY,
    fromResponse: (data) => {
      const publishers =
        data?.me?.organization?.overview?.impressions?.overlap?.publishers ??
        [];

      return publishers
        .filter((publisher, idx) => {
          return !publishers.find((p, i) => {
            return (
              i <= idx &&
              publisher.basePublisher.name === p.compPublisher.name &&
              publisher.compPublisher.name === p.basePublisher.name
            );
          });
        })
        .map((publisher) => {
          return {
            ...publisher,
            baseName: publisher.basePublisher.name,
            compName: publisher.compPublisher.name,
            overlapPercentage: publisher.overlap / publisher.combined,
          };
        })
        .sort((a, b) => {
          return b.overlapPercentage - a.overlapPercentage;
        });
    },
  },
  campaigns: {
    query: BRAND_ORGANIZATION_CAMPAIGN_OVERLAP_QUERY,
    fromResponse: (data) => {
      const campaigns =
        data?.me?.organization?.overview?.impressions?.overlap?.campaigns ?? [];
      return campaigns
        .filter((campaign, idx) => {
          return !campaigns.find((c, i) => {
            return (
              i <= idx &&
              campaign.baseCampaign.name === c.compCampaign.name &&
              campaign.compCampaign.name === c.baseCampaign.name
            );
          });
        })
        .map((campaign) => {
          return {
            ...campaign,
            baseName: campaign.baseCampaign.name,
            compName: campaign.compCampaign.name,
            baseDescription: campaign.baseCampaign.organization.name,
            compDescription: campaign.compCampaign.organization.name,
            overlapPercentage: campaign.overlap / campaign.combined,
          };
        })
        .sort((a, b) => {
          return b.overlapPercentage - a.overlapPercentage;
        });
    },
  },
  episodes: {
    query: BRAND_ORGANIZATION_EPISODE_OVERLAP_QUERY,
    fromResponse: (data) => {
      const pairs = data?.me?.organization?.overlap?.pairs ?? [];

      return pairs
        .map((pair) => {
          const combined = pair.baseReach + pair.compReach;

          return {
            ...pair,
            combined,
            overlapPercentage: pair.overlap / combined,
            baseName: pair.baseCampaignDynamic
              ? pair.baseCampaignDynamic.name
              : pair.baseFeed
              ? pair.baseFeed.title
              : 'n/a',
            compName: pair.compCampaignDynamic
              ? pair.compCampaignDynamic.name
              : pair.compFeed
              ? pair.compFeed.title
              : 'n/a',
          };
        })
        .sort((a, b) => {
          return b.overlapPercentage - a.overlapPercentage;
        });
    },
  },
};

export function InsightsOverlapIndex({
  after,
  before,
  organization,
}: MeasureInsightsRouteProps) {
  const theme = useTheme();
  const [selectedType, setSelectedType] = useState<
    'campaigns' | 'publishers' | 'episodes'
  >('campaigns');
  const {query, fromResponse} = config[selectedType];
  const {data, loading, error} = useQuery(query, {
    variables: {
      id: organization.id,
      after,
      before,
    },
  });

  let content: React.ReactNode;

  if (loading) {
    content = (
      <div css={{margin: '.75rem 0'}}>
        <LoadingSpinner centered />
      </div>
    );
  } else if (error) {
    content = (
      <ToastMessage alertType='warning'>
        Error loading data. Please try again later.
      </ToastMessage>
    );
  } else {
    let tableData = [];
    try {
      tableData = fromResponse(data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }

    content = (
      <Card rules={() => ({margin: '1rem 1.5rem 0', padding: '1.25rem'})}>
        <InsightsOverlapTable data={tableData} type={selectedType} />
      </Card>
    );
  }

  return (
    <>
      <CardHeader rules={() => ({margin: '0 1.5rem 1rem'})}>
        Households Overlap
      </CardHeader>
      <Card rules={() => ({background: 'var(--bg-muted)'})}>
        <div
          css={{
            display: 'grid',
            gridGap: '.75rem',
            gridTemplateColumns: `repeat(2, max-content)`,
          }}>
          <InsightsDatePicker
            defaultValue={{
              after,
              before,
            }}
          />
          <SegmentedControls
            items={[
              {
                title: 'Campaigns',
                value: 'campaigns',
              },
              {
                title: 'Publishers',
                value: 'publishers',
              },
              {
                title: 'Episodes',
                value: 'episodes',
              },
            ]}
            defaultValue='campaigns'
            onChange={(item) => setSelectedType(item.value)}
            segmentWidth={100}
            css={`
              ${theme === 'light' &&
              `
              background: 'var(--border-default)';
            `}
            `}
          />
        </div>
        <div
          css={{
            color: 'var(--text-muted)',
            fontSize: '0.875rem',
            margin: '.75rem 0 0',
          }}>
          Ad Analytics only uses household data as impressions from cellular and
          corporate tends to skew overlap numbers. You will notice that the
          impression and reach numbers below are lower than in the campaign as
          these are impressions at households.
        </div>
      </Card>
      {content}
    </>
  );
}
