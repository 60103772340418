import React, {useEffect, useState} from 'react';
import {Element} from '@core/style';
import Button from '@core/ui/Button/LegacyButton';
import Copy from '@core/ui/Copy';
import {Help, Input, Label} from '@core/ui/Form';
import Select from '@core/ui/Select';

const playersList = [
  {title: 'pod.link', value: 'podlink'},
  {title: 'Auto (Apple or Google)', value: 'auto'},
  {value: 'apple', title: 'Apple Podcasts'},
  {value: 'spotify', title: 'Spotify'},
  {value: 'google', title: 'Google Podcasts'},
  {value: 'overcast', title: 'Overcast'},
  {value: 'stitcher', title: 'Stitcher'},
  {value: 'castbox', title: 'Castbox'},
  {value: 'castro', title: 'Castro'},
  {value: 'podcastaddict', title: 'Podcast Addict'},
  {value: 'pocketcasts', title: 'Pocket Casts'},
  {value: 'iheartradio', title: 'iHeartRadio'},
  {value: 'playerfm', title: 'Player FM'},
  {value: 'breaker', title: 'Breaker'},
  {value: 'radiopublic', title: 'RadioPublic'},
  {value: 'rss', title: 'RSS'},
];

const LinkDesignForm = ({
  campaign,
  campaignLink,
  previewURL,
  episodes,
  onSave,
  onCancel,
}) => {
  const [url, setUrl] = useState(previewURL);
  const [player, setPlayer] = useState(playersList[0].value);
  const [episode, setEpisode] = useState(null);

  useEffect(() => {
    if (player !== 'podlink') {
      setUrl(`${previewURL}.${player}`);
    } else {
      setUrl(`${previewURL}`);
    }
  }, [player]);

  const key = campaign.pixels.filter(({role}) => role == 'PRODUCTION')[0].key;

  const params = {
    pdst_key: key,
    pdst_group: campaign.id,
    pdst_label: campaignLink.id,
  };

  const copyUrl = `${url}${
    episode ? `/episode/${episode.episodeId}/` : ''
  }?${new URLSearchParams(params).toString()}`;

  return (
    <Element rules={() => ({display: 'flex', flexDirection: 'column'})}>
      <Element
        rules={({theme}) => ({
          borderBottom: `1px solid ${theme.borderPrimary}`,
          display: 'flex',
          padding: '1rem',
        })}>
        <Button
          style-default
          onClick={() => {
            if (typeof onCancel === 'function') {
              onCancel();
            }
          }}>
          Close
        </Button>
      </Element>
      <Element
        rules={() => ({
          flex: 1,
          height: '100%',
          margin: '1rem 0',
          overflowY: 'scroll',
          padding: '0 1rem',
        })}>
        <Label rules={() => ({marginTop: '0.625rem'})}>URL</Label>
        <Element
          rules={() => ({
            alignItems: 'center',
            display: 'grid',
            gridGap: '0.625rem',
            gridTemplateColumns: '1fr auto',
            marginBottom: '1rem',
          })}>
          <Input
            type='text'
            value={copyUrl.replace('https://', '')}
            onFocus={(evt) => evt.target.select()}
            readOnly
            small
          />
          <Copy text={copyUrl} />
        </Element>
        <Label>Destination</Label>
        <Select
          key={player}
          disabled={!!episode}
          items={playersList}
          defaultValue={player}
          onSelect={(item) => {
            setPlayer(item ? item.value : null);
          }}
          rules={() => ({width: '100%'})}
        />

        <Help>
          Ad Analytics uses pod.link by default for campaign links. pod.link has
          built-in support for our JavaScript SDK to track actions such as view,
          click, redirects, and attributed downloads.
        </Help>
        <Label rules={() => ({marginTop: '1rem'})}>Episode (Optional)</Label>
        <Select
          items={episodes}
          removeOption
          propertyForValue='episodeId'
          propertyForName='title'
          propertyForDescription=''
          searchable
          searchKeys={['title']}
          onSelect={(episode) => {
            setEpisode(episode ? episode : null);
            if (episode) {
              setPlayer(playersList[0].value);
            }
          }}
          rules={() => ({width: '100%'})}
          withMargin
        />
      </Element>
    </Element>
  );
};

export default LinkDesignForm;
