import {SettingsContextValue} from '@analytics/components/settings/SettingsContext';
import {FetchedCampaigns} from '@analytics/graphql-api';
import {impressionsForPlacements} from '@analytics/lib/placements';

type CampaignParam = FetchedCampaigns[number];
type SettingsParam = SettingsContextValue | undefined;

export function toAttributionTotal(
  campaign: CampaignParam,
  settings: SettingsParam = undefined
) {
  const attributionTotal = settings?.modelled
    ? campaign?.userTotalModeled
    : campaign?.userTotal;
  return attributionTotal ?? 0;
}

export function toDownloadTotal(
  campaign: CampaignParam,
  settings: SettingsParam = undefined
) {
  const downloadTotal = campaign?.downloadTotal ?? 0;
  return settings?.impressionsByPlacements
    ? impressionsForPlacements({
        value: downloadTotal,
        campaign,
        campaignPodcast: null,
      })
    : downloadTotal;
}

export function toFrequency(
  campaign: CampaignParam,
  settings: SettingsParam = undefined
) {
  const downloadTotal = toDownloadTotal(campaign, settings);
  return campaign?.reachTotal ? downloadTotal / campaign?.reachTotal : 0;
}

export function toSpend(
  campaign: CampaignParam,
  settings: SettingsParam = undefined
) {
  const spend = settings?.spendByImpressionsDelivered
    ? campaign?.costToDate
    : campaign?.cost;
  return spend ?? 0;
}

export function toCPM(
  campaign: CampaignParam,
  settings: SettingsParam = undefined
) {
  const downloadTotal = toDownloadTotal(campaign, settings);
  const costToDate = campaign?.costToDate ?? 0;
  return (costToDate / (downloadTotal || 1)) * 1000; // don't divide by 0
}

export function toCR(
  campaign: CampaignParam,
  settings: SettingsParam = undefined
) {
  const attributionTotal = toAttributionTotal(campaign, settings);
  return attributionTotal / (campaign?.reachTotal || 1); // don't divide by 0
}

export function toRevenue(
  campaign: CampaignParam,
  settings: SettingsParam = undefined
) {
  const revenue = settings?.modelled
    ? campaign?.valueTotalModeled
    : campaign?.valueTotal;
  return revenue ?? 0;
}
