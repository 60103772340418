import React from 'react';
import EmptyMessage from '@core/ui/EmptyMessage';

const CampaignDynamicPodcastUnknown = ({campaignDynamic}) => {
  return (
    <EmptyMessage title={`${campaignDynamic.name} Impressions`}>
      Ad Analytics does not always get podcast and episode data from the hosting
      provider. We still want to capture this data, so Ad Analytics creates
      "Unknown" buckets by line item to place this data.
    </EmptyMessage>
  );
};

export {CampaignDynamicPodcastUnknown};
