import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {FormError, Help, Input, Label} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import Select from '@core/ui/Select';
import AddFeed from '@analytics/graphql-api/_old_mutations/_AddFeed';

class FeedAdd extends React.Component {
  state = {
    loading: false,
    redirectTo: null,
    href: '',
    measure: null,
    errors: [],
  };

  onChange = ({target: {value}}) => {
    this.setState({
      href: value,
      errors: [],
    });
  };

  onSubmit = (evt) => {
    evt.preventDefault();
    const {addFeed} = this.props;
    const {href, measure} = this.state;

    if (!href) {
      return;
    }

    if (measure == null) {
      this.setState({
        errors: ['Please indicate if this podcast has advertising.'],
      });
      return;
    }

    this.setState({
      loading: true,
    });

    addFeed({href, measure})
      .then(({data: {addFeed}}) => {
        this.setState({
          loading: false,
          redirectTo: addFeed.feed.id,
        });
      })
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors.map((e) => e.message),
        });
      });
  };

  render() {
    const {loading, redirectTo, href, measure, errors} = this.state;

    if (redirectTo) {
      return <Redirect to={`/podcasts/${redirectTo}`} />;
    }

    return (
      <ResponsiveContent containerSize='medium'>
        <CardHeader>Add Podcast</CardHeader>
        <Card>
          <p>
            Ad Analytics research finds podcasts that are in the top 200 of
            iTunes rankings. If you would like us to index a podcast, we can add
            it below.
          </p>
          <form onSubmit={this.onSubmit}>
            {errors.map((msg, n) => {
              return (
                <FormError
                  key={n}
                  onClose={() => {
                    this.setState({
                      errors: [],
                    });
                  }}>
                  {msg}
                </FormError>
              );
            })}
            <Input
              label='Podcast URL (iTunes URL or RSS Feed URL.)'
              value={href}
              disabled={loading}
              onChange={this.onChange}
            />
            <Help>We prefer the iTunes URL of the Podcast.</Help>
            <Label rules={() => ({marginTop: '1rem'})}>
              Does this podcast have ads?
            </Label>
            <Select
              items={[
                {title: 'Have ads', value: true},

                {
                  title: 'Does not have ads',
                  value: false,
                },
              ]}
              onSelect={({title, value}) => {
                this.setState({
                  errors: [],
                  measure: value,
                });
              }}
            />
            <Element
              rules={() => ({
                padding: '0.375rem 0 1rem 0',
                textAlign: 'right',
              })}>
              <Submit disabled={loading}>Add Podcast</Submit>
            </Element>
          </form>
        </Card>
      </ResponsiveContent>
    );
  }
}

export default graphql(AddFeed, {
  props: ({ownProps: {episode}, mutate}) => ({
    addFeed: (input) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
  }),
})(FeedAdd);
