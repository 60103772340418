import {useMemo, useRef, useState} from 'react';
import {Element} from '@core/style';
import Button, {IconButton} from '@core/ui/Button/LegacyButton';
import DataTableFilterButton from '@core/ui/DataTable/misc/DataTableFilterButton';
import {Icon} from '@core/ui/Icon';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import Select from '@core/ui/Select';
import {OrganizationObject} from '@analytics/graphql-api';
import AdvisorContentMaturityFilter from './filters/AdvisorContentMaturityFilter';
import AdvisorPublisherFilter from './filters/AdvisorPublisherFilter';
import AdvisorRangeFilter from './filters/AdvisorRangeFilter';
import AdvisorTagFilter from './filters/AdvisorTagFilter';
import {AdvisorColumn, AdvisorFilterItem} from './types';

interface IAdvisorFilterDropdownProps {
  activeColumns: AdvisorColumn[];
  defaultFilters: AdvisorFilterItem[];
  onChange: (_: {
    filters: AdvisorFilterItem[];
    fromUserAction?: boolean;
  }) => void;
  organization: OrganizationObject;
}

const FILTERS_CONFIG = {
  publisher: {
    Component: AdvisorPublisherFilter,
    inline: false,
  },
  tags: {
    Component: AdvisorTagFilter,
    inline: false,
  },
  contentMaturity: {
    Component: AdvisorContentMaturityFilter,
    inline: false,
  },
  range: {
    Component: AdvisorRangeFilter,
    inline: false,
  },
};

const getFilterConfig = (columnGroup: any) => {
  if (!columnGroup) return null;

  const filterTypes: Record<any, string | undefined> = {
    publisher: 'publisher',
    tags: 'tags',
    contentMaturity: 'contentMaturity',
  };

  const filterType = filterTypes[columnGroup.id] ?? columnGroup.filterType;

  return FILTERS_CONFIG[filterType] || null;
};

const convertMiniutesOrSeconds = (
  range: number[],
  to: 'seconds' | 'minutes'
) => {
  if (to === 'seconds') {
    return [range[0] * 60, range[1] * 60];
  }
  return [range[0] / 60, range[1] / 60];
};

const AdvisorFilterDropdownContent = ({
  activeColumns,
  defaultFilters,
  onChange,
  organization,
  toggle,
}: IAdvisorFilterDropdownProps & {
  toggle: (to?: boolean) => void;
}): JSX.Element => {
  const oldDefaultFilters = useRef<AdvisorFilterItem[]>(
    JSON.parse(JSON.stringify(defaultFilters))
  );
  const [activeFilters, setActiveFilters] = useState<AdvisorFilterItem[]>(
    defaultFilters ? defaultFilters.slice(0) : []
  );

  const onClickSave = () => {
    onChange({
      filters: activeFilters.filter(({columnGroup}) => !!columnGroup),
      fromUserAction: true,
    });
    toggle(false);
  };

  const onClickClose = () => {
    onChange({filters: oldDefaultFilters.current});
    toggle(false);
  };

  const onColumnSelect = ({item, col}) => {
    item.columnGroup = col;
    item.values = [];
    setActiveFilters(activeFilters.slice(0));
  };

  const onAddField = () => {
    activeFilters.push({columnGroup: null, values: []});
    setActiveFilters(activeFilters.slice(0));
  };

  const onRemoveField = (item) => {
    activeFilters.splice(activeFilters.indexOf(item), 1);
    setActiveFilters(activeFilters.slice(0));
  };

  const columnGroups = useMemo(
    () =>
      activeColumns
        .filter((columnGroup) => {
          return !!getFilterConfig(columnGroup);
        })
        .map((item) =>
          item.id === 'averageEpisodeLength'
            ? {
                ...item,
                title: 'Average Episode Length (By minutes)',
              }
            : {
                ...item,
              }
        ),
    [activeColumns]
  );

  return (
    <>
      <Element rules={() => ({fontSize: '0.875rem', padding: '1rem'})}>
        <Element rules={() => ({minWidth: '23.125rem'})}>
          {activeFilters.map((item, idx) => {
            const filterConfig = getFilterConfig(item.columnGroup);
            const selectItems = columnGroups.filter(
              (columnGroup) =>
                (item.columnGroup && item.columnGroup.id === columnGroup.id) ||
                !activeFilters.find(
                  (_item) =>
                    _item.columnGroup && _item.columnGroup.id === columnGroup.id
                )
            );

            return (
              <Element
                key={`${item.columnGroup ? item.columnGroup.id : ''}${idx}`}
                rules={() => ({
                  alignItems: 'center',
                  display: 'grid',
                  gridGap: '0.625rem',
                  marginBottom: '0.625rem',
                  maxWidth: '32.875rem',
                  ...(filterConfig
                    ? filterConfig.inline
                      ? {
                          gridTemplateColumns: '1.5rem 3.5rem 1fr 1fr',
                        }
                      : {
                          gridTemplateAreas: '". . ." ". . bottom"',
                          gridTemplateColumns: '1.5rem 3.5rem 1fr',
                          gridTemplateRows: 'auto auto',
                        }
                    : {
                        gridTemplateColumns: '1.5rem 3.5rem 1fr',
                      }),
                })}>
                <IconButton
                  onClick={() => onRemoveField(item)}
                  rules={({theme}) => ({
                    padding: 0,
                    color: theme.iconSecondary,
                  })}>
                  <Icon icon='remove-circle-outline' />
                </IconButton>
                <div>{idx === 0 ? 'Filter by' : 'and then'}</div>
                <Select
                  key={selectItems.map(({id}) => id).join(',')}
                  items={selectItems}
                  propertyForName='title'
                  propertyForValue='id'
                  propertyForDescription=''
                  defaultValue={item.columnGroup ? item.columnGroup.id : ''}
                  onSelect={(col) => onColumnSelect({item, col})}
                  outline
                  small
                  rules={() => ({width: '100%'})}
                />
                {filterConfig ? (
                  <Element
                    rules={() => ({
                      gridArea:
                        filterConfig && filterConfig.inline
                          ? undefined
                          : 'bottom',
                    })}>
                    <filterConfig.Component
                      organization={organization}
                      columnGroup={item.columnGroup}
                      defaultValues={
                        item.columnGroup.id === 'averageEpisodeLength'
                          ? convertMiniutesOrSeconds(item.values, 'minutes')
                          : item.values
                      }
                      onChange={(values) =>
                        item.columnGroup.id === 'averageEpisodeLength'
                          ? (item.values = convertMiniutesOrSeconds(
                              values,
                              'seconds'
                            ))
                          : (item.values = values)
                      }
                    />
                  </Element>
                ) : null}
              </Element>
            );
          })}
        </Element>
        <IconButton
          onClick={onAddField}
          rules={() => ({
            padding: '0.25rem 0 0 0',
          })}>
          <Icon
            icon='add-circle-outline'
            rules={({theme}) => ({
              marginRight: '0.375rem',
              color: theme.iconSecondary,
            })}
          />
          Add field
        </IconButton>
      </Element>
      <Element
        rules={({theme}) => ({
          borderTop: `2px solid ${theme.bgTertiary}`,
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0.75rem 1rem',
        })}>
        <Button
          style-small
          onClick={onClickClose}
          rules={() => ({marginRight: '0.625rem'})}>
          Cancel
        </Button>
        <Button style-small style-secondary onClick={onClickSave}>
          Save
        </Button>
      </Element>
    </>
  );
};

const AdvisorFilterDropdown = ({
  activeColumns,
  defaultFilters,
  onChange,
  organization,
}: IAdvisorFilterDropdownProps): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [opened, toggle] = useOverlay(false);

  return (
    <>
      <DataTableFilterButton
        domRef={buttonRef}
        onClick={() => toggle()}
        active={defaultFilters.length > 0}
        noArrowIcon
        rules={({theme}) => ({
          background: theme.bgPrimary,
          padding: '0 0.9375rem 0 .75rem',
        })}>
        <Icon
          icon='filter'
          rules={({theme}) => ({
            color: theme.iconSecondary,
            height: '1.25rem',
            margin: '0 0.5rem 0 0',
            width: '1.25rem',
          })}
        />
        Filters{defaultFilters.length > 0 ? ` (${defaultFilters.length})` : ''}
      </DataTableFilterButton>
      {opened ? (
        <Overlay
          opened={opened}
          toggle={toggle}
          positionTarget={buttonRef.current}
          horizontalAlign='right'
          verticalOffset={2}
          withBackdrop
          transparentBackdrop
          withShadow
          animation='opacity'
          css={`
            max-width: 33rem;
          `}>
          <AdvisorFilterDropdownContent
            activeColumns={activeColumns}
            defaultFilters={defaultFilters}
            onChange={onChange}
            organization={organization}
            toggle={toggle}
          />
        </Overlay>
      ) : null}
    </>
  );
};

export default AdvisorFilterDropdown;
