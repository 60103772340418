import { zIndexPopover } from "../../styles/index.js";
import styled from "../../styled-components.js";
export var OverlayPortalContainer = styled.div.withConfig({
  displayName: "Container__OverlayPortalContainer",
  componentId: "sc-1vwgkyp-0"
})(["position:fixed;top:", "px;left:", "px;width:", "px;height:", "px;pointer-events:none;z-index:", ";"], function (props) {
  return props.overlayPosition.top;
}, function (props) {
  return props.overlayPosition.left;
}, function (props) {
  return props.overlayPosition.width;
}, function (props) {
  return props.overlayPosition.height;
}, zIndexPopover);