import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {InputCopy} from '@core/ui/Copy';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {FeedNav} from '@analytics/components/nav/ResearchNav';

const FeedExport = ({organization, feed}) => {
  const {id} = feed;

  return (
    <>
      <FeedNav organization={organization} feed={feed} />

      <ResponsiveContent containerSize='medium'>
        <CardHeader>Share</CardHeader>
        <Card>
          <p>
            Get a sharable URL for <b>{feed.title}</b> across Ad Analytics
            organizations.
          </p>
          <Element rules={() => ({textAlign: 'right', padding: '0.625rem 0'})}>
            <InputCopy
              text={`https://dash.podsights.com/research/podcasts/${id}`}
            />
          </Element>
        </Card>
      </ResponsiveContent>
    </>
  );
};

export default FeedExport;
