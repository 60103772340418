import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import EpisodeIndex from './episode';

const EpisodesIndex = ({user, organization, feed}) => {
  return (
    <Switch>
      <PropsRoute
        path='/:part(a|s|b|p)/:slug/research/podcasts/:feedId/episodes/:episodeId'
        user={user}
        organization={organization}
        feed={feed}
        component={EpisodeIndex}
      />
    </Switch>
  );
};

export default EpisodesIndex;
