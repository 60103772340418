import { cssBorderWidth, cssColorValue, cssSpacing, focusBorderWidth, overlay, semanticColors } from "../../styles/index.js";
import { TypeList as ListComponent } from "../TypeList/index.js";
import styled, { css } from "../../styled-components.js";
/* Show up to 5.5 items, then resort to scrolling the rest.
 * We show a "half" item so that it is obvious to the user that there is more below the fold.
 * 48px (item height) * 5.5 + 8px (padding height) + 1px (border height) = 273px
 * */

var focusStyles = function focusStyles($isUsingKeyboard) {
  return css(["outline:none;border:", " solid ", ";", ""], cssBorderWidth('hairline', '1px'), cssColorValue(semanticColors.essentialBase), $isUsingKeyboard && css(["border:", "px solid ", ";"], focusBorderWidth, cssColorValue(semanticColors.essentialBase)));
};
export var List = styled(ListComponent).attrs({
  condensedAll: true,
  listStyleReset: true
}).withConfig({
  displayName: "List",
  componentId: "sc-1sxdnaw-0"
})(["", ";display:block;pointer-events:all;inline-size:100%;padding-block-start:", ";padding-block-end:", ";max-block-size:273px;overflow:auto;&:focus{", ";}", ";"], overlay({
  maxWidth: 'none'
}), cssSpacing('tighter-2', '8px'), cssSpacing('tighter-2', '8px'), function (props) {
  return !props.$useBrowserDefaultFocusStyle && focusStyles(props.$isUsingKeyboard);
}, function (props) {
  return props.$isFocus && !props.$useBrowserDefaultFocusStyle && focusStyles(props.$isUsingKeyboard);
});