import {
  FetchedCampaigns,
  FetchedPublisherReviewCampaigns,
  PublisherApproveCampaignInput,
  PublisherApproveCampaignMutationFn,
  PublisherRejectCampaignInput,
  PublisherRejectCampaignMutationFn,
} from '@analytics/graphql-api';
import {Campaign} from '../_types';

export const doApproveCampaign = async ({
  approveCampaign,
  campaign,
  input,
}: {
  approveCampaign: PublisherApproveCampaignMutationFn;
  campaign:
    | Campaign
    | FetchedCampaigns[number]
    | FetchedPublisherReviewCampaigns[number];
  input: PublisherApproveCampaignInput;
}) =>
  await approveCampaign({
    variables: {
      input,
    },
    update: (cache, {data}) => {
      const success = data?.publisherApproveCampaign?.success;

      if (success) {
        cache.modify({
          id: cache.identify(campaign!),
          fields: {
            isPublisherApproved() {
              return true;
            },
          },
        });
      }
    },
  });

export const doRejectCampaign = async ({
  campaign,
  input,
  rejectCampaign,
}: {
  campaign:
    | Campaign
    | FetchedCampaigns[number]
    | FetchedPublisherReviewCampaigns[number];
  input: PublisherRejectCampaignInput;
  rejectCampaign: PublisherRejectCampaignMutationFn;
}) =>
  await rejectCampaign({
    variables: {
      input,
    },
    update: (cache, {data}) => {
      const success = data?.publisherRejectCampaign?.success;
      if (success) {
        cache.modify({
          id: cache.identify(campaign!),
          fields: {
            isPublisherApproved() {
              return false;
            },
            publisherDenyReason() {
              return input.publisherDenyReason;
            },
          },
        });
      }
    },
  });
