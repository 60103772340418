import {useState} from 'react';
import {Type, semanticColors} from '@spotify-internal/encore-web';
import styled from 'styled-components';
import {Card} from '@core/ui/Content';
import {shouldHideBreakdown} from '../helpers';
import {LiftMessageCategory, SpotifyBrandLiftMessageResult} from '../types';
import {LiftMessageGroup} from './LiftMessageGroup';
import {LiftMessageNav} from './LiftMessageNav';

export function BrandLiftMessageCard({
  message,
  ...props
}: {
  message: SpotifyBrandLiftMessageResult;
}) {
  const [category, setCategory] = useState<LiftMessageCategory>(
    LiftMessageCategory.age
  );

  return (
    <MessageCard {...props}>
      <Type as='h1' variant='celloCanon'>
        {message.messageTypeDisplay}
      </Type>
      <Type semanticColor={semanticColors.textSubdued}>
        {message.messageContents[0].question}
      </Type>
      <LiftMessageNav
        activeCategory={category}
        onClick={setCategory}
        hideBreakdown={shouldHideBreakdown(message)}
      />
      <LiftMessageGroup category={category} message={message} />
    </MessageCard>
  );
}

const MessageCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
`;
