import {Stack} from '@core/ui/Layout';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {PublisherApprovalAlert} from '../publisher-review';
import {CampaignDraftAdsCard} from './CampaignDraftAdsCard';
import {CampaignDraftDetailsCard} from './CampaignDraftDetailsCard';
import {CampaignDraftModeCard} from './CampaignDraftModeCard';
import {Grid} from './_shared';

export type CampaignDraftProps = {
  campaign: FetchedCampaign;
  organization: FetchedOrganization | FetchedBuySideOrganization;
};

export function CampaignDraft({organization, campaign}: CampaignDraftProps) {
  return (
    <Stack
      direction='column'
      gap='var(--spacing-6)'
      css={{margin: '0 var(--spacing-6)'}}>
      <PublisherApprovalAlert organization={organization} campaign={campaign} />
      <CampaignDraftModeCard organization={organization} campaign={campaign} />
      <Grid alignItems='start' columns='1fr minmax(22rem, 0.5fr)'>
        <CampaignDraftAdsCard organization={organization} campaign={campaign} />
        <CampaignDraftDetailsCard
          organization={organization}
          campaign={campaign}
        />
      </Grid>
    </Stack>
  );
}
