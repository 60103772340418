import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import IndustrySelect from '@core/components/IndustrySelect';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import Button from '@core/ui/Button';
import {ModalHeader} from '@core/ui/Content';
import {Form, Submit} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {Overlay, OverlayProps} from '@core/ui/Overlay';
import {
  CompanyIndustryDocument,
  UpdateCompanyInput,
  useUpdateCompanyMutation,
} from '@analytics/graphql-api';

type FormData = {
  industry: string;
};

interface Props extends OverlayProps {
  company: {
    id: string;
    slug: string;
    name: string;
    industry?: string | null;
  };
}

export function IndustryNotificationOverlay({
  company,
  opened,
  toggle,
  ...props
}: Props) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateCompany] = useUpdateCompanyMutation({
    refetchQueries: [CompanyIndustryDocument],
    awaitRefetchQueries: true,
  });
  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    setError(false);
    try {
      await updateCompany({
        variables: {
          input: {
            id: company.id,
            slug: company.slug,
            name: company.name,
            industry: data.industry,
          } as UpdateCompanyInput,
        },
      });

      toggle?.(false);
    } catch (e) {
      console.warn(e);
      setError(true);
    }
    setLoading(false);
  };

  return (
    <Overlay
      centered
      closeOnEscKey={false}
      closeOnOutsideClick={false}
      opened={opened}
      toggle={toggle}
      withBackdrop
      withShadow
      css={`
        overflow: initial;
        min-width: 32.5rem;
        padding: 2rem;
      `}
      {...props}>
      <ModalHeader toggle={toggle} withCloseIcon>
        Select Industry
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
        {error && (
          <Alert severity='error' css={{margin: '1.5rem 0'}}>
            {t('errors.network')}
          </Alert>
        )}
        <Stack direction='column' gap='1.5rem'>
          <span>
            Please select the industry that best aligns with your organization
            below.
          </span>
          <Controller
            name='industry'
            control={control}
            rules={{
              validate: (value) => value || 'Please select an industry.',
            }}
            render={({field}) => (
              <IndustrySelect
                invalid={!!errors.industry}
                onSelect={({industry}) => {
                  field.onChange(industry);
                }}
              />
            )}
          />
          <Stack gap='1rem' justifyContent='flex-end'>
            <Button onClick={() => toggle?.(false)} type='button'>
              Cancel
            </Button>
            <Submit loading={loading}>Submit</Submit>
          </Stack>
        </Stack>
      </Form>
    </Overlay>
  );
}
