import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {PushPin, PushPinOutlined} from '@mui/icons-material';
import {useHistory} from 'react-router-dom';
import {IconButton} from '@core/ui/Button/LegacyButton';
import {Icon} from '@core/ui/Icon';
import {DeleteOverlay, useOverlay} from '@core/ui/Overlay';
import {Tooltip} from '@core/ui/Tooltip';
import {
  DeleteAdvisorSavedSearchDocument,
  useUpdateAdvisorSavedSearchMutation,
} from '@analytics/graphql-api';
import ADVISOR_SAVED_SEARCHES_QUERY from '@analytics/graphql-api/_old_queries/advisor/AdvisorSavedSearchesQuery';
import {advisorURL} from '@analytics/lib/routes/useAdvisorRoutes';
import {AdvisorWorkspace} from './AdvisorTool';

type Workspace = {
  id: string;
  data: string;
  name: string;
  organizationId?: string;
  version: number;
  orgDefault?: boolean;
};

const EditInput = ({
  workspace,
  onBlur,
  ...props
}: {
  workspace: Workspace;
  onBlur: (_?: string) => void;
}): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(workspace.name);

  const [updateSavedSearch] = useUpdateAdvisorSavedSearchMutation({
    refetchQueries: [ADVISOR_SAVED_SEARCHES_QUERY],
  });

  useEffect(() => {
    requestAnimationFrame(() => inputRef.current?.focus());
  }, [inputRef]);

  async function _onBlur() {
    if (value === workspace.name || !value) {
      onBlur();
      return;
    }
    try {
      await updateSavedSearch({
        variables: {
          input: {
            data: workspace.data,
            id: workspace.id,
            orgDefault: null,
            organizationId: workspace.organizationId ?? null,
            version: workspace.version,
            name: value,
          },
        },
      });
      onBlur(value);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
    }
  }

  useEffect(() => {
    const onDocumentKeydown = (evt: KeyboardEvent) => {
      if (evt.key === 'Enter' || evt.key === 'Escape') {
        inputRef.current?.blur();
      }
    };

    document.addEventListener('keydown', onDocumentKeydown);

    return () => document.removeEventListener('keydown', onDocumentKeydown);
  }, []);

  return (
    <input
      ref={inputRef}
      onClick={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
      }}
      onBlur={_onBlur}
      onChange={(evt) => {
        setValue(evt.target.value.trim());
      }}
      defaultValue={workspace.name}
      placeholder='Workspace name'
      css={{
        background: 'var(--bg-default)',
        border: '.0625rem solid var(--border-default)',
        borderRadius: '.375rem',
        color: 'var(--text-default)',
        display: 'block',
        fontSize: '0.875rem',
        outline: 0,
        padding: '.0125rem .425rem',
        userSelect: 'none',
        width: '100%',
        '[disabled]': {
          color: 'var(--text-disabled)',
        },
        ':focus': {
          borderColor: !value ? 'var(--red-orange)' : 'var(--blue)',
          boxShadow: `0 0 0 0.5px ${
            !value ? 'var(--red-orange)' : 'var(--blue)'
          }`,
        },
        '::placeholder': {
          color: 'var(--text-muted)',
          fontSize: '0.825rem',
        },
      }}
      {...props}
    />
  );
};

const AdvisorSavedSearchDropdownItem = ({
  isActive,
  onItemClick,
  onDelete,
  withEditOption = false,
  withPersonalIcon = false,
  workspace,
}: {
  isActive: boolean;
  onItemClick: (_: string) => void;
  onDelete: (_: string) => void;
  withEditOption?: boolean;
  withPersonalIcon?: boolean;
  workspace: AdvisorWorkspace;
}): JSX.Element => {
  const history = useHistory();
  const {id, data, name, organization, version, orgDefault} = workspace ?? {};
  const itemRef = useRef<HTMLDivElement>(null);
  const moreItemsRef = useRef<HTMLDivElement>(null);
  const [displayName, setDisplayName] = useState(name);
  const [editingName, toggleEditingName] = useState(false);
  const [hover, setHover] = useState(false);
  const [hoverMoreItems, setHoverMoreItems] = useState(false);
  const [openedDeleteOverlay, toggleDeleteOverlay] = useOverlay();
  const [overlayPosition, setOverlayPosition] = useState<{
    top: number;
    left: number;
  }>({top: 0, left: 0});

  useLayoutEffect(() => {
    if (hoverMoreItems && moreItemsRef.current) {
      const {top, left, height, width} =
        moreItemsRef.current.getBoundingClientRect();
      setOverlayPosition({top: top + height, left: left - width});
    }
  }, [hoverMoreItems]);

  useLayoutEffect(() => {
    if (isActive && itemRef.current) {
      itemRef.current.scrollIntoView();
    }
  }, [isActive]);

  const [updateSavedSearch] = useUpdateAdvisorSavedSearchMutation({
    refetchQueries: [ADVISOR_SAVED_SEARCHES_QUERY],
  });

  return (
    <>
      <div
        ref={itemRef}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => onItemClick(id)}
        css={{
          alignItems: 'center',
          borderRadius: '0.5rem',
          background: isActive
            ? 'var(--bg-muted)'
            : hover || openedDeleteOverlay
            ? 'var(--bg-subtle-hover)'
            : 'transparent',
          color: isActive ? 'var(--blue)' : 'inherit',
          cursor: 'pointer',
          display: 'grid',
          gridGap: '0.625rem',
          gridTemplateColumns: '1fr 1.25rem',
          padding: '0.375rem 0.75rem',
          transition: 'all 0.2s',
          ':hover': {
            background: isActive ? 'var(--bg-muted)' : 'var(--bg-subtle-hover)',
            color: 'var(--blue)',
          },
        }}>
        <div css={{display: 'flex'}}>
          {editingName ? (
            <EditInput
              workspace={{
                id,
                data,
                name,
                version,
                ...(organization && {
                  organizationId: organization.id ?? undefined,
                }),
              }}
              onBlur={(value?: string) => {
                if (value) setDisplayName(value);
                setHover(false);
                toggleEditingName(false);
              }}
            />
          ) : (
            <div>{displayName}</div>
          )}
          {withPersonalIcon && withEditOption && (
            <Tooltip
              description='Owner'
              overlayProps={{
                verticalAlign: 'bottom',
                verticalOffset: 6,
              }}
              rules={() => ({alignItems: 'center', display: 'flex'})}>
              <Icon
                icon='user'
                rules={() => ({
                  height: '1rem',
                  width: '1rem',
                  marginLeft: '0.375rem',
                })}
              />
            </Tooltip>
          )}
        </div>
        {withEditOption && (hover || editingName || openedDeleteOverlay) && (
          <div
            ref={moreItemsRef}
            onClick={(evt) => evt.stopPropagation()}
            onMouseEnter={() => setHoverMoreItems(true)}
            onMouseLeave={() => setHoverMoreItems(false)}
            css={{
              alignItems: 'center',
              borderRadius: '0.5rem',
              color: hoverMoreItems ? 'var(--blue)' : 'var(--icon-subtle)',
              display: 'flex',
              justifyContent: 'center',
              ':hover': {
                color: 'var(--blue)',
              },
            }}>
            <Icon
              icon='more'
              rules={() => ({
                height: '1.25rem',
                transform: 'rotate(90deg)',
                width: '1.25rem',
              })}
            />
            {hoverMoreItems && (
              <div
                css={{
                  background: 'var(--bg-overlay)',
                  border: '0.0625rem solid var(--border-default)',
                  borderRadius: '.5rem',
                  boxShadow: 'var(--shadow-xl)',
                  display: 'flex',
                  padding: '0.125rem',
                  position: 'fixed',
                  left: `${overlayPosition.left}px`,
                  top: `${overlayPosition.top}px`,
                  zIndex: 2,
                }}>
                <Tooltip
                  description='Rename'
                  overlayProps={{
                    verticalAlign: 'bottom',
                    verticalOffset: 6,
                  }}>
                  <IconButton
                    onClick={() => {
                      toggleEditingName(true);
                      setHoverMoreItems(false);
                    }}
                    rules={({theme}) => ({
                      alignItems: 'center',
                      borderRadius: '0.375rem',
                      display: 'flex',
                      height: '2rem',
                      justifyContent: 'center',
                      width: '2rem',
                      ':hover': {background: theme.bgTertiary},
                      ':hover svg': {color: theme.iconPrimary},
                    })}>
                    <Icon icon='pencil' />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title='Default Workspace'
                  description='Only one per organization.'
                  overlayProps={{
                    verticalAlign: 'bottom',
                    verticalOffset: 6,
                  }}
                  rules={() => ({alignItems: 'center', display: 'flex'})}>
                  <IconButton
                    onClick={() => {
                      if (!workspace.organization) return;

                      updateSavedSearch({
                        variables: {
                          input: {
                            data: workspace.data,
                            id: workspace.id,
                            version: workspace.version,
                            name: workspace.name,
                            organizationId: workspace.organization.id ?? null,
                            orgDefault: true,
                          },
                        },
                      })
                        .then((d) => {
                          const id =
                            d?.data?.updateAdvisorSavedSearch
                              ?.advisorSavedSearch?.id;

                          if (id) onItemClick(id);
                          else if (workspace.organization)
                            history.push(advisorURL(workspace.organization));
                        })
                        .catch((e) =>
                          console.error(
                            'Error updating default organization workspace:',
                            e
                          )
                        );
                    }}
                    rules={({theme}) => ({
                      cursor: orgDefault ? 'default' : 'pointer',
                      alignItems: 'center',
                      borderRadius: '0.375rem',
                      display: 'flex',
                      height: '2rem',
                      justifyContent: 'center',
                      width: '2rem',
                      ':hover': {background: theme.bgTertiary},
                      ':hover svg': {color: 'var(--blue)'},
                    })}>
                    {orgDefault ? (
                      <PushPin
                        sx={{
                          width: '1rem',
                          height: '1rem',
                          color: 'var(--blue)',
                        }}
                      />
                    ) : (
                      <PushPinOutlined sx={{width: '1rem', height: '1rem'}} />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip
                  description='Delete'
                  overlayProps={{
                    verticalAlign: 'bottom',
                    verticalOffset: 6,
                  }}>
                  <IconButton
                    onClick={toggleDeleteOverlay}
                    rules={({theme}) => ({
                      alignItems: 'center',
                      borderRadius: '0.375rem',
                      display: 'flex',
                      height: '2rem',
                      justifyContent: 'center',
                      width: '2rem',
                      ':hover': {background: theme.bgTertiary},
                      ':hover svg': {color: theme.iconPrimary},
                    })}>
                    <Icon icon='bin' />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </div>
      <DeleteOverlay
        opened={openedDeleteOverlay}
        onDelete={() => onDelete(id)}
        toggle={toggleDeleteOverlay}
        title='Delete workspace'
        mutation={DeleteAdvisorSavedSearchDocument}
        refetchQueries={[ADVISOR_SAVED_SEARCHES_QUERY]}
        submitLabel='Delete'
        toVariables={() => ({
          input: {id},
        })}>
        <p>Are you sure you want to delete "{name}"?</p>
      </DeleteOverlay>
    </>
  );
};

export default AdvisorSavedSearchDropdownItem;
