import {createOrgPath} from '@core/lib/organizations';
import {Alert} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';

export function NoActivePixelWarning({
  organization,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) {
  if ('hasActivePixel' in organization && organization.hasActivePixel) {
    return null;
  }

  return (
    <Alert
      severity='error'
      css={`
        margin: 0 var(--spacing-6);
      `}>
      <h4>Ad Analytics Pixel</h4>
      <p
        css={`
          margin-bottom: var(--spacing-3);
        `}>
        Ad Analytics isn’t receiving data from your Ad Analytics Pixel yet. You
        must install the Ad Analytics Pixel before you can access Advisor.
      </p>
      <Button
        to={createOrgPath(organization, `manage/pixels`)}
        variant='outlined'
        color='error'>
        Continue Pixel Setup
      </Button>
    </Alert>
  );
}
