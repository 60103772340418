import {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {withApollo} from '@apollo/client/react/hoc';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {BLUE, Element} from '@core/style';
import {ToastMessage} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {FORM_ERRORS, Form, Submit} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import Select from '@core/ui/Select';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignDocument, useCampaignStateQuery} from '@analytics/graphql-api';
import UPDATE_CAMPAIGN_STATE_MUTATION from '@analytics/graphql-api/_old_mutations/UpdateCampaignState';
import ME_APPROVALS_QUERY from '@analytics/graphql-api/_old_queries/MeApprovalsQuery';
import {getApprovalsByState} from '@analytics/lib/approvals';

const validateCampaign = ({
  campaign,
  managers,
  members,
  pixel,
  rejected,
  requested,
}) => {
  const errors = [];
  const hasData = pixel && pixel.hasData;
  const isAttribution = campaign.kind.toLowerCase() === 'attribution';

  if (isAttribution && !pixel) {
    errors.push(
      <>
        The brand, "<b>{campaign.advertiser.name}</b>" has not created a pixel.
        Please remind the brand to install the Ad Analytics pixel.{' '}
      </>
    );
  }

  if (isAttribution && !hasData) {
    errors.push(
      <>
        The brand, "<b>{campaign.advertiser.name}</b>" has a pixel but we have
        no events. Please remind the brand to install the Ad Analytics pixel.{' '}
      </>
    );
  }

  if (members.length === 0 && managers.length === 0) {
    errors.push(
      <>
        The brand, "<b>{campaign.advertiser.name}</b>" has no members. Please
        contact us at{' '}
        <Element
          tag='a'
          href='mailto:support-adanalytics@spotify.com'
          rules={() => ({color: BLUE})}>
          support-adanalytics@spotify.com
        </Element>{' '}
        so we can help you to fix this.
      </>
    );
  }

  if (rejected.length > 0 || requested.length > 0) {
    errors.push(
      <>
        Uh oh, you still have pending approvals on this campaign. Please review
        accordingly.
      </>
    );
  }

  return errors;
};

const CampaignState = withApollo(({user, organization, campaign, client}) => {
  const history = useHistory();
  const [updateCampaignState] = useMutation(UPDATE_CAMPAIGN_STATE_MUTATION);
  const stateQuery = useCampaignStateQuery({
    variables: {
      organizationId: organization.id,
      id: campaign.id,
    },
  });
  const approvalsQuery = useQuery(ME_APPROVALS_QUERY, {
    variables: {
      campaignId: campaign.id,
    },
  });
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();

  const updateCampaignCache = (resp) => {
    const query = {
      query: CampaignDocument,
      variables: {
        organizationId: organization.id,
        slug: campaign.slug,
      },
    };

    query.data = client.readQuery(query);
    query.data.me.organization.campaign.state =
      resp.data.updateCampaignState.campaign.state;
    client.writeQuery(query);
  };

  const onSubmit = async ({state}) => {
    setLoading(true);
    try {
      const resp = await updateCampaignState({
        variables: {
          input: {
            id: campaign.id,
            state: state || 'live',
          },
        },
      });
      updateCampaignCache(resp);
      history.push(createOrgPath(organization, `/campaigns/${campaign.slug}`));
    } catch (err) {
      setError(null, FORM_ERRORS.network);
    }
    setLoading(false);
  };

  if (stateQuery.loading || approvalsQuery.loading) {
    return <Loading fixed />;
  }

  const stateOverview =
    stateQuery?.data?.me?.organization?.campaign?.stateOverview;

  const pixel = stateOverview?.pixel;
  const members = stateOverview?.members ?? [];
  const managers = stateOverview?.managers ?? [];

  const {requested, rejected} = getApprovalsByState({
    campaign,
    ...approvalsQuery?.data?.me,
  });

  const invalidList = validateCampaign({
    campaign,
    managers,
    members,
    pixel,
    rejected,
    requested,
  });

  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <ResponsiveContent containerSize='medium'>
        <CardHeader
          backPath={createOrgPath(organization, `/campaigns/${campaign.slug}`)}>
          Set Campaign Live
        </CardHeader>
        <Card>
          {invalidList.length > 0 ? (
            <>
              {invalidList.map((content) => (
                <ToastMessage
                  alertType='error'
                  large
                  rules={() => ({
                    margin: '0 0 0.75rem 0',
                    justifyContent: 'left',
                  })}>
                  {content}
                </ToastMessage>
              ))}
              <Element rules={() => ({textAlign: 'right', marginTop: '1rem'})}>
                <Button
                  to={createOrgPath(
                    organization,
                    `/campaigns/${campaign.slug}`
                  )}
                  color='secondary'>
                  Back to Overview
                </Button>
              </Element>
            </>
          ) : (
            <>
              <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
                <p>
                  This campaign is currently a draft. By setting it live, you
                  will be allowing the advertiser
                  {managers.length > 0
                    ? ` and the agency ${managers
                        .map((m) => m?.name)
                        .join(', ')}`
                    : ''}{' '}
                  to see this campaign.
                </p>
                {managers.length > 0 ? (
                  <Controller
                    name='state'
                    control={control}
                    render={({field}) => (
                      <Select
                        items={[
                          {
                            title: 'Set Live for The Agency',
                            value: 'agency',
                          },
                          {
                            title: 'Set Live for The Agency and The Brand',
                            value: 'live',
                          },
                        ]}
                        onSelect={(item) => {
                          field.onChange(item ? item.value : null);
                        }}
                        {...field}
                      />
                    )}
                  />
                ) : null}

                <Element
                  rules={() => ({padding: '1rem 0 0', textAlign: 'right'})}>
                  <Submit disabled={loading}>
                    Set Campaign Live and Grant Access
                  </Submit>
                </Element>
              </Form>
            </>
          )}
        </Card>
      </ResponsiveContent>
    </>
  );
});

export default CampaignState;
