import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {focusVisibleCss} from '@core/style';
import {Link} from '../Link';

export interface SecondaryNavProps {
  items: Array<{
    exact?: boolean;
    isActive?: (_prop: {path: string}) => boolean;
    key?: string;
    title?: string;
    to: string;
  }>;
  preserveQueryParams?: boolean;
}

export const SecondaryNav = ({
  items,
  preserveQueryParams,
  ...props
}: SecondaryNavProps) => {
  const {search} = useLocation();

  return (
    <_SecondaryNav role='menu' aria-orientation='vertical' {...props}>
      {items.map(({key, title, to, isActive}, idx) => {
        const path = window.location.pathname.replace(/\/$/, '');
        const active =
          typeof isActive === 'function' ? isActive({path}) : path === to;

        return (
          <_SecondaryNavItem
            role='menuitem'
            key={key}
            as={Link}
            to={preserveQueryParams ? to + search : to}
            $active={active}
            underline='none'
            css={`
              ${idx === items.length - 1 ? 'border-bottom: 0;' : ''}
            `}>
            {title}
          </_SecondaryNavItem>
        );
      })}
    </_SecondaryNav>
  );
};

const _SecondaryNav = styled.nav`
  background: var(--bg-surface);
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--border-default);
  display: flex;
  flex-direction: column;
`;

const _SecondaryNavItem = styled.div<{$active: boolean}>`
  border-bottom: 0.0625rem solid var(--border-default);
  color: ${(p) => (p.$active ? 'var(--text-default)' : 'var(--text-muted)')};
  cursor: pointer;
  font-weight: ${(p) => (p.$active ? 500 : 400)};
  padding: 0.625rem 1rem;
  position: relative;
  text-decoration: none;
  :hover {
    color: var(--text-default);
  }
  ${focusVisibleCss}
  ${(p) =>
    p.$active
      ? `
       &::after {
        background: var(--blue);
        border-radius: 0.375rem;
        content: "";
        height: 1.5rem;
        left: -0.0625rem;
        position: absolute;
        top: calc(50% - 12px);
        width: 0.25rem;
      }
    `
      : ''}
`;
