import {gql} from '@apollo/client';

export default gql`
  query getBrandCampaignDynamicOmniChart(
    $organizationId: ID!
    $campaignId: ID!
    $id: ID!
    $after: String
    $before: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          campaignDynamic(id: $id) {
            id

            attribution(after: $after, before: $before) {
              users {
                hourlyVisitors {
                  count
                  hour
                }
              }
            }

            downloads(after: $after, before: $before) {
              hourly {
                hour
                downloadClass
                count
              }
            }
          }
        }
      }
    }
  }
`;
