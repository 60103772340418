import {gql} from '@apollo/client';
import CompanyFragment from '@analytics/graphql-api/_old_queries/fragments/CompanyFragment';

export default gql`
  query getResearchCompany($organizationId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          company(id: $id) {
            ...Company
          }
        }
      }
    }
  }
  ${CompanyFragment}
`;
