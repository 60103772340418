import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {DocumentNode, useLazyQuery} from '@apollo/client';
import styled, {CSSProp} from 'styled-components';
import {isDescendant} from '@core/lib/utils';
import {CustomTheme} from '@core/style';
import {ButtonIcon} from '../Button';
import {Input} from '../FormElements';
import {IInputProps} from '../FormElements/Input';
import {Icon} from '../Icon';
import {LoadingSpinner} from '../Loading';
import {Overlay, OverlayProps} from '../Overlay';

const isInputValueInResults = ({
  inputValue,
  propertyForName,
  results,
}: {
  inputValue: string;
  propertyForName: string;
  results: any[];
}): boolean => {
  if (results.length) {
    const value = inputValue.toLowerCase().trim();

    return !!results.find(
      (item) => item[propertyForName].toLowerCase().trim() === value
    );
  }
  return false;
};

export interface DataSelectorProps {
  autofocus?: boolean;
  dataQuery: DocumentNode;
  defaultValue?: string;
  fetchPolicy?: any;
  fromResponse: (_data: any) => any[];
  inputProps?: IInputProps;
  invalid?: boolean;
  onSelect?: (_item: any) => void;
  overlayProps?: OverlayProps;
  overlayCss?: CSSProp<CustomTheme> | undefined;
  placeholder?: string;
  propertyForName?: string;
  queryVariables?: any;
  renderItem?: (_item: any) => React.ComponentType<any> | JSX.Element;
  selectableInputValue?: boolean;
  selectableInputValuePlaceholder?: string;
  small?: boolean;
  requireOrganization?: boolean;
}

export function DataSelector({
  autofocus = false,
  dataQuery,
  defaultValue = '',
  fetchPolicy = 'network-only',
  fromResponse,
  inputProps = {},
  invalid = false,
  onSelect,
  overlayProps,
  overlayCss,
  placeholder = 'Search',
  propertyForName = 'name',
  queryVariables,
  renderItem,
  selectableInputValue = false,
  selectableInputValuePlaceholder = 'Select new',
  small = true,
  requireOrganization = false,
  ...props
}: DataSelectorProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const selectedOptionRef = useRef<any>(null);
  const [opened, setOpened] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [results, setResults] = useState<any[]>([]);
  const [executeQuery, {loading}] = useLazyQuery(dataQuery, {
    variables: {
      query: inputValue,
      requireOrganization,
      ...queryVariables,
    },
    fetchPolicy,
    onCompleted: (data) => {
      if (data) {
        setResults(
          typeof fromResponse === 'function' ? fromResponse(data) : data
        );
      }
    },
  });

  const onInputChange = useCallback(
    ({target}: ChangeEvent<HTMLInputElement>) => {
      const value = target.value.trim();
      const hasValue = value.length > 0;

      selectedOptionRef.current = null;
      setInputValue(value);
      setOpened(hasValue);

      if (hasValue) {
        executeQuery();
      }
    },
    [executeQuery]
  );

  const onItemClick = useCallback(
    (item: any) => {
      selectedOptionRef.current = item;
      onSelect?.(item);
      if (inputRef.current !== null) {
        inputRef.current.value = item[propertyForName];
      }
      setOpened(false);
    },
    [setOpened, onSelect, propertyForName]
  );

  useEffect(() => {
    const onDocumentClick = ({target}) => {
      if (
        !opened ||
        isDescendant(overlayRef.current, target) ||
        isDescendant(inputRef.current, target)
      ) {
        // Do nothing
      } else {
        setOpened(false);
      }
    };

    if (!opened) {
      // Reset on close
      if (!selectedOptionRef.current && inputRef.current !== null) {
        inputRef.current.value = '';
      }
      selectedOptionRef.current = null;
      setInputValue('');
      setResults([]);
    }

    document.addEventListener('click', onDocumentClick);

    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [opened]);

  useEffect(() => {
    if (autofocus) {
      requestAnimationFrame(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      });
    }
  }, [autofocus]);

  useEffect(() => {
    if (defaultValue && inputRef.current !== null) {
      inputRef.current.value = defaultValue;
    }
  }, []);

  return (
    <>
      <div
        css={`
          position: relative;
        `}
        {...props}>
        {loading && inputValue ? (
          <LoadingSpinner
            size='small'
            css={`
              top: 0.875rem;
              left: 0.6875rem;
              position: absolute;
            `}
          />
        ) : (
          <Icon
            icon='magnify'
            css={`
              bottom: 0;
              color: ${invalid ? 'var(--red-orange)' : 'var(--icon-3)'};
              height: 1.25rem;
              left: 0.625rem;
              margin: auto;
              position: absolute;
              top: 0;
            `}
          />
        )}
        <Input
          domRef={inputRef}
          invalid={invalid}
          onChange={onInputChange}
          placeholder={placeholder}
          style={{paddingLeft: '2.5rem'}}
          small={small}
          {...inputProps}
        />
        {opened && (
          <ButtonIcon
            icon='close'
            onClick={() => setOpened(false)}
            css={`
              bottom: 0;
              color: var(--icon-muted);
              display: flex;
              margin: auto;
              position: absolute;
              right: 0.5rem;
              top: 0;
            `}
          />
        )}
      </div>
      {!loading && (results.length > 0 || !!selectableInputValue) && (
        <Overlay
          ref={overlayRef}
          opened={opened}
          toggle={setOpened}
          positionTarget={inputRef.current}
          verticalAlign='bottom'
          verticalOffset={2}
          withShadow
          {...overlayProps}
          css={`
            padding: 0.5rem;
            min-width: ${inputRef.current
              ? `${inputRef.current.getBoundingClientRect().width}px`
              : 0};
            ${overlayCss}
          `}>
          <div
            css={`
              height: 100%;
              max-height: 25rem;
              min-height: 0;
              overflow-y: auto;
            `}>
            {selectableInputValue &&
            !isInputValueInResults({results, inputValue, propertyForName}) ? (
              <_Option
                onClick={() =>
                  onItemClick({
                    [propertyForName]: inputValue,
                    isNew: true,
                  })
                }>
                {inputValue}
                <span
                  css={`
                    color: var(--text-muted);
                  `}>
                  <span
                    css={`
                      margin: 0 var(--spacing-2);
                    `}>
                    -
                  </span>
                  {selectableInputValuePlaceholder}
                </span>
              </_Option>
            ) : null}
            {results.map((item) => (
              <_Option
                key={item[propertyForName]}
                onClick={() => onItemClick(item)}>
                {typeof renderItem === 'function'
                  ? renderItem(item)
                  : item[propertyForName]}
              </_Option>
            ))}
          </div>
        </Overlay>
      )}
    </>
  );
}

const _Option = styled.div`
  border-radius: 0.375rem;
  cursor: pointer;
  padding: 0.375rem 1rem;
  user-select: none;
  :hover {
    background: var(--bg-default-hover);
    color: var(--black);
  }
`;
