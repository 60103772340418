import {gql} from '@apollo/client';

export default gql`
  query getRankings($id: ID!, $limit: Int) {
    me {
      id
      organization(id: $id) {
        id
        research {
          rankings(provider: "itunes", category: "top-200", limit: $limit) {
            id
            num
            createdAt
            episodesCount
            placementsCount

            feed {
              id
              title
              subtitle
              summary
              image {
                id
                href
                path
              }
            }
          }
        }
      }
    }
  }
`;
