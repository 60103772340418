import {Card, CardHeader} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {Text} from '@core/ui/Text';
import {ExportButton} from '@analytics/components/ExportOverlay';
import {
  EpisodeObject,
  FeedObject,
  OrganizationObject,
} from '@analytics/graphql-api';

type Props = {
  feed: FeedObject;
  episode: EpisodeObject;
  organization: OrganizationObject;
};

export default function EpisodeExport({feed, episode, organization}: Props) {
  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader>Export</CardHeader>
      <Card>
        <Stack direction='column' spacing='var(--spacing-4)'>
          <Text>
            Export a CSV of all the ad placements in the episode{' '}
            <b>{episode.title}</b> from <b>{feed.title}</b>
          </Text>
          <ExportButton
            objectType='episode'
            kind='placements'
            pk={episode.id}
            organizationId={organization.id}>
            Export
          </ExportButton>
        </Stack>
      </Card>
    </ResponsiveContent>
  );
}
