import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styled from 'styled-components';
import {Button} from '@core/ui/Button';
import {FORM_ERRORS, Form, Submit} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {Overlay, OverlayProps} from '@core/ui/Overlay';
import {Switch} from '@core/ui/Switch';
import {Text} from '@core/ui/Text';
import {
  FetchedSpotifyLiftReport,
  usePublishSpotifyBrandLiftReportMutation,
} from '@analytics/graphql-api';

export function BrandLiftPublishOverlay({
  liftReport,
  opened,
  toggle,
  ...props
}: OverlayProps & {
  liftReport: FetchedSpotifyLiftReport;
}) {
  const [loading, setLoading] = useState(false);
  const [publishReport] = usePublishSpotifyBrandLiftReportMutation();
  const {
    control,
    handleSubmit,
    setError,
    formState: {errors},
    watch,
  } = useForm({
    defaultValues: {
      brandAccess: true,
      isHiddenFromAgency: false,
      networkError: false,
    },
  });

  const onSubmit = async ({isHiddenFromAgency}) => {
    setLoading(true);
    try {
      await publishReport({
        variables: {
          input: {
            id: liftReport.id,
            isHiddenFromAgency,
          },
        },
        update: (cache, {data}) => {
          const sucess = data?.publishSpotifyBrandLiftReport?.success;
          if (sucess) {
            cache.modify({
              id: cache.identify(liftReport),
              fields: {
                isPublished() {
                  return true;
                },
              },
            });
          }
        },
      });

      toggle?.(false);
    } catch (e) {
      setError('networkError', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  const brandAccess = watch('brandAccess');

  return (
    <Overlay
      centered
      closeOnEscKey={false}
      closeOnOutsideClick={false}
      opened={opened}
      toggle={toggle}
      withBackdrop
      withShadow
      css={{
        padding: 'var(--spacing-6)',
        width: '35rem',
      }}
      {...props}>
      <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
        <Stack direction='column' gap='var(--spacing-6)'>
          <Text variant='h3'>Publish Report</Text>
          <Controller
            name='brandAccess'
            control={control}
            render={({field}) => (
              <Stack direction='column' gap='var(--spacing-1)'>
                <Label htmlFor='brandAccess'>
                  Advertiser Access
                  <Switch
                    id='brandAccess'
                    checked={field.value}
                    onChange={field.onChange}
                  />
                </Label>
                <Text variant='body2'>
                  By publishing this brand lift report, you will grant the
                  advertiser full access to the report details.
                </Text>
              </Stack>
            )}
          />
          <Controller
            name='isHiddenFromAgency'
            control={control}
            render={({field}) => (
              <Stack direction='column' gap='var(--spacing-1)'>
                <Label htmlFor='isHiddenFromAgency'>
                  Agency Access
                  <Switch
                    id='isHiddenFromAgency'
                    disabled={!brandAccess}
                    checked={!field.value}
                    onChange={(e) => field.onChange(!e.target.checked)}
                  />
                </Label>
                <Text variant='body2'>
                  By default, the brand's agency will also be granted access to
                  view this lift report. Toggle this switch off if you do not
                  want to grant the agency access.
                </Text>
              </Stack>
            )}
          />
          <Stack
            alignItems='center'
            justifyContent='flex-end'
            gap='var(--spacing-2)'
            css={{margin: 'var(--spacing-2) 0 0'}}>
            <Button disabled={loading} onClick={() => toggle?.(false)}>
              Cancel
            </Button>
            <Submit color='primary' disabled={!brandAccess} loading={loading}>
              Publish
            </Submit>
          </Stack>
        </Stack>
      </Form>
    </Overlay>
  );
}

export const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 0.9375rem;
  font-weight: var(--font-weight-medium);
  gap: var(--spacing-2);
`;
