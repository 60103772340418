import {isNone} from '@core/lib/utils';
import {campaignStreamingURL} from '@analytics/components/nav/CampaignStreamingNav';
import useSettings from '@analytics/components/settings/useSettings';
import {toModeled} from '@analytics/lib/utils';

export const getCampaignStreamings = (
  campaignStreamings,
  {campaign, organization}
) => {
  const [settings] = useSettings();
  // TODO: kill when marketing campaigns are dead.
  const multiplier =
    typeof campaign.multiplier === 'number' ? campaign.multiplier : 1;
  const results = [];

  for (const item of campaignStreamings) {
    const {
      id,
      goal,
      cost,
      costToDate,
      name,
      reachTotal,
      downloadTotal,
      downloads,
      targetDownloadTotal,
      targetReachTotal,
      campaignStreamingStreams,
    } = item;
    const _downloadTotal =
      downloads && !isNone(downloads.count) ? downloads.count : downloadTotal;
    const frequency = reachTotal ? downloadTotal / reachTotal : 1;
    // deal with attribution.
    const _userTotal = toModeled(item, 'user', settings.modelled);
    const _reachTotal = _downloadTotal / frequency;
    let _costToDate = costToDate;
    let _targetDownloadTotal = targetDownloadTotal;
    let _targetReachTotal = targetReachTotal;

    _costToDate = settings.spendByImpressionsDelivered ? _costToDate : cost;

    // TODO: kill when marketing campaigns are dead.
    if (settings.modelled && multiplier) {
      _targetDownloadTotal = _targetDownloadTotal * multiplier;
      _targetReachTotal = _targetReachTotal * multiplier;
    }

    const cr =
      campaign.kind.toLowerCase() == 'marketing'
        ? _targetDownloadTotal / _reachTotal
        : _reachTotal
        ? _userTotal / _reachTotal
        : 0;
    const isPpa = campaign?.isPpa;
    const cpm = isPpa
      ? 1
      : _downloadTotal
      ? (_costToDate / _downloadTotal) * 1000
      : 0;
    const cpv = _reachTotal && _userTotal ? _costToDate / _userTotal : 0;

    results.push({
      ...item,
      id,
      name,
      url: campaignStreamingURL(organization, campaign, {id}),
      goal,
      cost,
      costToDate: _costToDate,
      campaignStreamingStreams,
      reachTotal: _reachTotal,
      attributionTotal: _userTotal,
      purchaseTotal: toModeled(item, 'purchase', settings.modelled),
      valueTotal: toModeled(item, 'value', settings.modelled),
      installedTotal: toModeled(item, 'installed', settings.modelled),
      leadTotal: toModeled(item, 'lead', settings.modelled),
      targetDownloadTotal: _targetDownloadTotal,
      targetReachTotal: _targetReachTotal,
      cr: isNaN(cr) ? 0 : cr,
      cpm,
      frequency,
      cpv,
      format: 'streaming',
    });
  }

  return results.sort((a, b) => {
    return b.downloadTotal - a.downloadTotal;
  });
};
