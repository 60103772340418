import {useQuery} from '@apollo/client';
import {ToastMessage} from '@core/ui/Alert';
import {Card} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import InsightsFrequency from '@analytics/components/insights/InsightsFrequency';
import {withInsightsImpressionsQuery} from '@analytics/components/insights/InsightsImpressionsQueryWrapper';
import BRAND_ORGANIZATION_FREQUENCY_QUERY from '@analytics/graphql-api/_old_queries/BrandOrganizationFrequencyQuery';
import {MeasureInsightsRouteProps} from '..';

const InsightsFrequencyIndex = ({
  after,
  before,
  campaigns,
  publishers,
  organization,
}: MeasureInsightsRouteProps & {
  campaigns: any[];
  publishers: any[];
}) => {
  const {data, loading, error} = useQuery(BRAND_ORGANIZATION_FREQUENCY_QUERY, {
    variables: {
      id: organization.id,
      after,
      before,
      publisherIds: publishers.map(({id}) => id),
      campaignIds: campaigns.map(({id}) => id),
    },
  });

  if (loading) {
    return (
      <div css={{margin: '.75rem 0'}}>
        <LoadingSpinner centered />
      </div>
    );
  }

  if (error) {
    return (
      <Card rules={() => ({margin: '1.25rem 1.5rem 0'})}>
        <ToastMessage alertType='error' rules={() => ({margin: 0})}>
          Error loading data. Please try again later.
        </ToastMessage>
      </Card>
    );
  }

  const impressionsOverview =
    data?.me?.organization?.overview?.impressions ?? {};
  const {frequency, impressions, reach} = impressionsOverview;

  return (
    <InsightsFrequency
      impressions={impressions}
      reach={reach}
      data={frequency}
    />
  );
};

export default withInsightsImpressionsQuery(InsightsFrequencyIndex, {
  withCampaigns: true,
  withPublishers: true,
});
