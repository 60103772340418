import {graphql} from '@apollo/client/react/hoc';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import {
  FetchedCampaignTarget,
  GetCampaignTargetQuery,
} from '@analytics/graphql-api';
import CampaignTargetQuery from '@analytics/graphql-api/_old_queries/CampaignTargetQuery';
import {CampaignRouteProps} from '../../_routes';
import {CampaignTarget} from './CampaignTarget';

export type CampaignTargetRouteProps = CampaignRouteProps & {
  campaignTarget: FetchedCampaignTarget;
};

const CampaignTargetIndex = ({
  user,
  organization,
  campaign,
  data: {loading, me},
}: CampaignRouteProps & {
  data: {
    loading: boolean;
    me: GetCampaignTargetQuery['me'];
  };
}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const campaignTarget = me?.organization.campaign.campaignTarget;

  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/targets/:campaignTargetId'
        user={user}
        organization={organization}
        campaign={campaign}
        campaignTarget={campaignTarget}
        component={CampaignTarget}
      />
    </Switch>
  );
};

export default graphql(CampaignTargetQuery, {
  options: ({
    organization,
    campaign,
    match: {
      params: {campaignTargetId},
    },
  }) => {
    return {
      variables: {
        organizationId: organization.id,
        campaignId: campaign.id,
        id: campaignTargetId,
      },
    };
  },
})(CampaignTargetIndex);
