import {useState} from 'react';
import {Card} from '@core/ui/Content';
import {FetchedIncrementalLiftReport} from '@analytics/graphql-api';
import {
  IncrementalCampaignLiftGroup,
  IncrementalLiftGroup,
  LiftGroupSerie,
} from '../types';
import {LiftGroupChart} from './LiftGroupChart';
import {LiftGroupStats} from './LiftGroupStats';
import {LiftGroupTable} from './LiftGroupTable';
import {LiftGroupTitle} from './LiftGroupTitle';

export function LiftGroup({
  liftReport,
  group,
}: {
  liftReport: FetchedIncrementalLiftReport;
  group: IncrementalLiftGroup | IncrementalCampaignLiftGroup;
}) {
  const [activeSerie, setActiveSerie] = useState<LiftGroupSerie>('views');

  return (
    <>
      <LiftGroupTitle group={group} />
      <Card
        css={{color: 'var(--text-subtle)', marginBottom: 'var(--spacing-12)'}}>
        <LiftGroupStats liftReport={liftReport} group={group} />
        <LiftGroupChart
          weekData={group.weekData}
          activeSerie={activeSerie}
          setActiveSerie={setActiveSerie}
        />
        <div css={{margin: '2rem 0 1rem'}}>
          <LiftGroupTable {...group} />
        </div>
      </Card>
    </>
  );
}
