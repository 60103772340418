import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import {t} from '@core/texts';
import {Button} from '@core/ui/Button';
import {Card} from '@core/ui/Content';
import {DateRangeInput} from '@core/ui/DatePicker';
import {
  FORM_ERRORS,
  Form,
  LabelInput,
  LabelMultipleSelect,
  Submit,
} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {LoadingSpinner} from '@core/ui/Loading';
import {
  CampaignLiftReportsDocument,
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
  LiftReportsDocument,
  useCampaignsQuery,
  useCreateLiftReportMutation,
} from '@analytics/graphql-api';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {
  canCampaignRequestLift,
  liftReportHardDates,
  validateLiftDates,
} from './helpers';

type FormData = {
  name: string;
  campaigns: FetchedCampaign[];
  dateRange: {
    startDate: dayjs.Dayjs | undefined;
    endDate: dayjs.Dayjs | undefined;
  };
  networkError: boolean;
};

type Props = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign?: FetchedCampaign;
  onCancel?: () => void;
};

export function LiftReportForm({organization, campaign, onCancel}: Props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [createLiftReport] = useCreateLiftReportMutation();
  const {
    loading: loadingCampaigns,
    data: campaignsData,
    error: _errorLoadingCampaigns,
  } = useCampaignsQuery({
    variables: {
      organizationId: organization.id,
      isMarketing: false,
    },
  });
  const {
    control,
    clearErrors,
    formState: {errors},
    handleSubmit,
    setError,
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      campaigns: campaign ? [campaign] : [],
      dateRange: {},
      networkError: false,
    },
  });

  const {campaigns: selectedCampaigns, dateRange} = watch();

  const onSubmit = async (formData: FormData) => {
    setLoading(true);
    clearErrors('networkError');

    const campaignIds = formData.campaigns.map(({id}) => id);

    try {
      await createLiftReport({
        variables: {
          input: {
            campaignIds,
            organizationId: organization.id,
            name: formData.name,
            impressionsStartAt:
              formData.dateRange.startDate?.format('YYYY-MM-DD'),
            impressionsEndAt: formData.dateRange.endDate?.format('YYYY-MM-DD'),
          },
        },
        refetchQueries: [CampaignLiftReportsDocument, LiftReportsDocument],
      });
      history.push(
        campaign
          ? `${toCampaignUrl(organization, campaign)}/lift`
          : createOrgPath(organization, 'measure/lift')
      );
    } catch (err) {
      setError('networkError', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  const {hardStart, hardEnd} = liftReportHardDates(selectedCampaigns);

  return (
    <Card rules={() => ({margin: 0})}>
      <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
        <p>
          To qualify for a lift report, the Campaign line items must have at
          least 4-weeks' worth of data and at least 250k impressions based in:
          US, Mexico, Canada, Australia, and Japan.
        </p>

        <_FormBody>
          <Controller
            name='name'
            control={control}
            rules={{required: 'Please provide a report name.'}}
            render={({field}) => (
              <LabelInput
                label='Report Name'
                invalid={!!errors.name}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name='campaigns'
            control={control}
            rules={{
              validate: (campaigns) =>
                !campaigns.length ? 'Please select at least 1 campaign.' : true,
            }}
            render={({field}) => {
              if (loadingCampaigns)
                return (
                  <_LoadingContainer invalid={!!errors.campaigns}>
                    <LoadingSpinner size='small' />
                  </_LoadingContainer>
                );

              const availableCampaigns =
                campaignsData?.me?.organization?.campaigns?.results?.filter(
                  canCampaignRequestLift
                ) || [];

              return (
                <LabelMultipleSelect
                  key={availableCampaigns.length}
                  label='Campaigns'
                  defaultValue={selectedCampaigns.map(({id}) => id)}
                  getSelectLabel={(items) => {
                    if (items.length === 1) {
                      return items[0].name;
                    }
                    return `Selected: ${items.length}`;
                  }}
                  items={availableCampaigns}
                  invalid={!!errors.campaigns}
                  onSelect={(campaigns) => {
                    field.onChange(campaigns);
                    const {hardStart, hardEnd} = liftReportHardDates(campaigns);
                    setValue('dateRange', {
                      startDate: hardStart,
                      endDate: hardEnd,
                    });
                  }}
                  propertyForName='name'
                  propertyForValue='id'
                  searchable
                  searchKeys={['name']}
                />
              );
            }}
          />
          <Controller
            name='dateRange'
            control={control}
            rules={{
              validate: ({startDate, endDate}) =>
                validateLiftDates({startDate, endDate})
                  ? true
                  : t('lift.requestForm.invalidDateRange.longDescription'),
            }}
            render={({field}) => (
              <DateRangeInput
                key={selectedCampaigns.length}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                disabled={!selectedCampaigns.length}
                onDatesChange={field.onChange}
                isOutsideRange={(date) => {
                  if (selectedCampaigns.length) {
                    return !(
                      date.isSameOrAfter(hardStart) &&
                      date.isSameOrBefore(hardEnd)
                    );
                  }
                  return true;
                }}
                invalid={!!errors.dateRange}
                inputRules={() => ({
                  padding: '1.125rem 1rem',
                  width: '100%',
                })}
                placeholder='Date Range'
              />
            )}
          />
        </_FormBody>
        <Stack
          justifyContent='flex-end'
          gap={4}
          css={`
            margin-top: var(--spacing-6);
          `}>
          {!!onCancel && <Button onClick={onCancel}>Cancel</Button>}
          <Submit color='secondary' loading={loading}>
            {t('lift.requestForm.requestButton.title')}
          </Submit>
        </Stack>
      </Form>
    </Card>
  );
}

const _LoadingContainer = styled.div<{invalid?: boolean}>`
  align-items: center;
  background: var(--bg-muted);
  border: 0.0625rem solid;
  border-color: ${(div) =>
    div.invalid ? 'var(--red-orange)' : 'var(--border-default)'};
  border-radius: 0.5rem;
  cursor: not-allowed;
  display: inline-flex;
  height: 3.875rem;
  justify-content: center;
  padding: 1rem;
  position: relative;
`;

const _FormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
