import {ToggleButton, ToggleButtonGroup} from '@core/ui/ToggleButton';
import {Question, _QuestionHeader} from '../../shared';
import {IQuestionProps} from '../types';

export const QuestionContentMaturity = ({
  quizData: {contentMaturity},
  updateQuizData,
}: IQuestionProps) => (
  <Question>
    <_QuestionHeader>
      What Content Maturity are you comfortable with?
    </_QuestionHeader>
    <ToggleButtonGroup
      value={contentMaturity}
      onChange={(_e, v) => updateQuizData('contentMaturity', v)}
      size='small'>
      <ToggleButton value='g'>G</ToggleButton>
      <ToggleButton value='pg'>PG</ToggleButton>
      <ToggleButton value='pg13'>PG-13</ToggleButton>
      <ToggleButton value='r'>R</ToggleButton>
    </ToggleButtonGroup>
  </Question>
);
