import {createOrgPath} from '@core/lib/organizations';
import {DropdownMenuDivider, DropdownMenuTitle} from '@core/ui/DropdownMenu';
import {Nav} from '@core/ui/Nav';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {CampaignDynamicRouteProps} from '@analytics/screens/unified/campaigns/campaign/dynamics/dynamic/_routes';

const campaignDynamicURL = (
  organization: CampaignDynamicRouteProps['organization'],
  campaign: CampaignDynamicRouteProps['campaign'],
  campaignDynamic: CampaignDynamicRouteProps['campaignDynamic']
) =>
  createOrgPath(
    organization,
    `/campaigns/${campaign.slug}/dynamics/${campaignDynamic?.id}`
  );

const CampaignDynamicNav = ({
  user,
  organization,
  campaign,
  campaignDynamic,
}: CampaignDynamicRouteProps) => {
  const url = campaignDynamicURL(organization, campaign, campaignDynamic);

  return (
    <Nav
      title={campaignDynamic?.name}
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/measure/campaigns'),
          title: 'Campaigns',
        },
        {
          to: toCampaignUrl(organization, campaign),
          title: `${campaign.name}`,
        },
        {
          title: 'Dynamic Ad',
        },
      ]}
      moreDropdownItems={
        user && user.isAdmin
          ? [
              {content: <DropdownMenuDivider />},
              {
                content: (
                  <DropdownMenuTitle>
                    Only visible to Ad Analytics members:
                  </DropdownMenuTitle>
                ),
              },
              {to: `${url}/consistency`, title: 'Data Consistency'},
            ]
          : []
      }
      moreDropdownOverlayProps={{
        horizontalAlign: 'right',
      }}
    />
  );
};

export {campaignDynamicURL};

export default CampaignDynamicNav;
