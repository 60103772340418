import {graphql} from '@apollo/client/react/hoc';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {FeedImage} from '@core/ui/Image';
import Loading from '@core/ui/Loading';
import Stat from '@core/ui/Stat';
import EpisodesTable from '@analytics/components/research/EpisodesTable';
import CompanyFeedQuery from '@analytics/graphql-api/_old_queries/research/CompanyFeedQuery';

const CompanyFeed = ({user, organization, company, data: {loading, me}}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const {feed} = me.organization.research;

  return (
    <>
      <Card>
        <Element
          rules={() => ({
            display: 'grid',
            gridTemplateColumns: '6.25rem 1fr auto',
            gridGap: '1rem',
          })}>
          <FeedImage feed={feed} width={200} />
          <Element>
            <Element tag='h4' rules={() => ({flex: 1})}>
              {feed.title}
            </Element>

            <Element
              rules={({theme}) => ({
                fontSize: '14px',
                color: theme.textTertiary,
              })}>
              {feed.description}
            </Element>
          </Element>
          <Stat
            title={`Episode${feed.episodes.length > 1 ? 's' : ''}`}
            value={feed.episodes.length}
            rules={() => ({padding: '0 2rem'})}
          />
        </Element>
      </Card>

      <Card>
        <CardHeader>Episodes with ads for {company.name} </CardHeader>
        <EpisodesTable
          organization={organization}
          feed={feed}
          episodes={feed.episodes}
        />
      </Card>
    </>
  );
};

export default graphql(CompanyFeedQuery, {
  options: ({
    organization,
    company,
    match: {
      params: {feedId},
    },
  }) => ({
    variables: {
      organizationId: organization.id,
      companyId: company.id,
      id: feedId,
    },
  }),
})(CompanyFeed);
