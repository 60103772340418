import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {createOrgPath, isOrgBrand} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {FORM_ERRORS, Form, FormFooter, Submit} from '@core/ui/FormElements';
import {
  CreateCampaignInput,
  useCreateCampaignMutation,
} from '@analytics/graphql-api';
import {CampaignKind} from '../_types';
import {CampaignFormFields} from './CampaignFormFields';
import {getCampaignFormInput} from './_helpers';
import {useCampaignForm, useScrollToTopOnErrors} from './_hooks';
import {CampaignFormBaseProps, CampaignFormData, PubOrg} from './_types';

export function CampaignCreateForm({
  companyId,
  kind,
  onCancel,
  onComplete,
  organization,
  pubOrg,
}: CampaignFormBaseProps & {
  companyId: string;
  kind: CampaignKind;
  /* Use pubOrg for publisher details if the org creating the campaign is NOT the publisher */
  pubOrg?: PubOrg;
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [createCampaign] = useCreateCampaignMutation();

  const {
    clearErrors,
    control,
    handleSubmit,
    setError,
    formState: {errors},
  } = useCampaignForm({kind, payerId: pubOrg?.id ?? organization.id});

  const onSubmit = async (data: CampaignFormData) => {
    setLoading(true);
    if (errors.networkError) clearErrors('networkError');

    try {
      const input = getCampaignFormInput({data});
      const resp = await createCampaign({
        variables: {
          organizationId: organization.id,
          input: {
            organizationId: pubOrg?.id ?? organization.id,
            companyId,
            isCreatedByBrand: isOrgBrand(organization),
            ...input,
          } as CreateCampaignInput,
        },
      });

      const new_campaign = resp?.data?.createCampaign?.campaign;

      if (new_campaign) {
        onComplete?.(new_campaign);
        /* go to new campaign page */
        history.push(
          createOrgPath(organization, `/campaigns/${new_campaign.slug}`)
        );
      }
    } catch (e) {
      setError('networkError', FORM_ERRORS.network);
    }

    setLoading(false);
  };

  const _onCancel = () => {
    onCancel?.();
    /* go to campaigns page */
    history.push(createOrgPath(organization, '/measure'));
  };

  useScrollToTopOnErrors(errors);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
      <CampaignFormFields
        control={control}
        companyId={companyId}
        organizationId={organization.id}
        pubOrg={pubOrg ?? {id: organization.id, name: organization.name}}
      />
      <FormFooter>
        {onCancel && <Button onClick={_onCancel}>Cancel</Button>}
        <Submit loading={loading}>Create Campaign</Submit>
      </FormFooter>
    </Form>
  );
}
