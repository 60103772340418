import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  CONTROL,
  DEFAULT_CHART_HEIGHT,
  DEFAULT_CHART_WIDTH,
  DEFAULT_LABEL_SIZE,
  EXPOSED,
} from '../helpers';
import {BarLiftData} from '../types';
import {BrandLiftBarChartLegend} from './BrandLiftBarChartLegend';
import {BrandLiftBarChartTooltip} from './BrandLiftBarChartTooltip';
import {BrandLiftCustomBar} from './BrandLiftCustomBar';

const RADIUS = 5;

export function BrandLiftBarChart({
  data,
  height = DEFAULT_CHART_HEIGHT,
  width = DEFAULT_CHART_WIDTH,
  ...props
}: {
  data: BarLiftData[];
  height?: number;
  width?: string | number;
}) {
  return (
    <ResponsiveContainer height={height} width={width} {...props}>
      <BarChart barSize={65} data={data}>
        <YAxis
          tick={{
            fill: 'var(--text-muted)',
            fontSize: DEFAULT_LABEL_SIZE,
          }}
          tickFormatter={(v) => `${v}%`}
          tickLine={false}
          stroke='var(--border-default)'
        />
        <XAxis
          dataKey='group'
          tick={{
            fill: 'var(--text-muted)',
            fontSize: DEFAULT_LABEL_SIZE,
          }}
          tickLine={false}
          stroke='var(--border-default)'
        />
        <CartesianGrid
          stroke='var(--border-default)'
          strokeDasharray='0 0'
          vertical={false}
        />
        <Legend verticalAlign='bottom' content={BrandLiftBarChartLegend} />
        <Tooltip content={BrandLiftBarChartTooltip} cursor={false} />
        <Bar
          dataKey='controlPercentage'
          fill={CONTROL}
          radius={[RADIUS, RADIUS, RADIUS, RADIUS]}
        />
        <Bar
          dataKey='exposedPercentage'
          fill={EXPOSED}
          radius={[RADIUS, RADIUS, RADIUS, RADIUS]}
          rx={RADIUS}
          shape={BrandLiftCustomBar}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
