import {gql} from '@apollo/client';

export default gql`
  query getCampaignAttributionPurchaseValueByDay(
    $organizationId: ID!
    $id: ID!
    $after: String
    $before: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $id) {
          id
          attribution(after: $after, before: $before) {
            events {
              purchaseValueByDay {
                day
                value
              }
            }
          }
        }
      }
    }
  }
`;
