import {MiniPlayer} from '@core/components/AudioMiniPlayer';
import EpisodePlayer, {addColor} from '@core/components/EpisodePlayer';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import {toTimeString} from '@core/lib/time';
import {Element} from '@core/style';
import {BLUE} from '@core/style';
import {Button} from '@core/ui/Button';
import {Card, CardHeader, CardHelp} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import {Link} from '@core/ui/Link';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import AdCopy from '@analytics/components/ads/AdCopy';
import {
  episodeURL,
  podcastURL,
  reviewersURL,
} from '@analytics/components/nav/ResearchNav';

const Placements = ({organization, placements}) => {
  return (
    <div>
      {placements.map((placement, idx) => {
        const ad = placement.ad;

        return (
          <Element
            key={placement.id}
            rules={() => ({
              marginBottom: idx === placements.length - 1 ? '' : '2rem',
            })}>
            <Element
              rules={() => ({
                alignItems: 'center',
                display: 'grid',
                fontSize: '0.9375rem',
                gridGap: '1rem',
                gridTemplateColumns: 'max-content 1fr max-content',
                marginBottom: '1rem',
              })}>
              <Element
                rules={({theme}) => ({
                  color: theme.textTertiary,
                  fontSize: '0.875rem',
                })}>
                ({toTimeString(placement.startTime)} -{' '}
                {toTimeString(placement.endTime)}) / {toTimeString(ad.duration)}
              </Element>
              {ad.company ? (
                <Element
                  rules={() => ({
                    display: 'flex',
                    justifyContent: 'space-between',
                  })}>
                  <Link
                    to={createOrgPath(
                      organization,
                      `/research/brands/${ad.company.id}`
                    )}
                    css={`
                      font-weight: 500;
                      :hover {
                        color: var(--blue);
                      }
                    `}>
                    {ad.company.name}
                  </Link>
                  <Link
                    to={createOrgPath(
                      organization,
                      `/research/brands/${ad.company.id}/ads/${ad.id}`
                    )}
                    css={`
                      :hover {
                        color: var(--blue);
                      }
                    `}>
                    Details
                  </Link>
                </Element>
              ) : (
                <Element rules={({theme}) => ({color: theme.textTertiary})}>
                  Ad pending review
                </Element>
              )}
            </Element>
            <MiniPlayer enclosure={ad} color={placement.color} />
            <AdCopy words={ad.words} />
          </Element>
        );
      })}
    </div>
  );
};

const Episode = ({user, organization, company, feed, episode}) => {
  const placements = addColor(episode.placements);
  const {processed, reviewed, hasNoAds} = episode;
  const {processing} = feed;

  const needsAdReview =
    placements.filter(({ad: {company}}) => !company).length > 0;

  if (!processed) {
    return (
      <ResponsiveContent containerSize='medium'>
        <CardHeader>Episode</CardHeader>
        <Card>
          <p>
            Ad Analytics has not processed this episode, which means we have not
            ingested the mp3 file or generated a waveform. You can ask the back
            end to do so here.
          </p>

          <Element rules={() => ({textAlign: 'right'})}>
            {!processing ? (
              <Button
                to={`${podcastURL(organization, feed)}/process`}
                color='secondary'>
                Process the entire Podcast
              </Button>
            ) : (
              ''
            )}

            <Button
              to={`${episodeURL(organization, feed, episode)}/process`}
              color='secondary'>
              Process the Epsiode
            </Button>
          </Element>
        </Card>
      </ResponsiveContent>
    );
  }

  const reviewButton = (
    <Element>
      <Button
        to={`${episodeURL(organization, feed, episode)}/review`}
        color={needsAdReview ? 'default' : 'secondary'}>
        Review Episode
      </Button>

      {needsAdReview ? (
        <Button
          to={`${reviewersURL(organization)}/ads/feed/${feed.id}`}
          color='primary'
          css={`
            margin-left: var(--spacing-2);
          `}>
          Review Unclassified Ads
        </Button>
      ) : (
        ''
      )}
    </Element>
  );

  return (
    <>
      <CardHeader>Episode</CardHeader>
      <CardHelp>
        Published {dayjs(episode.published).format('dddd, MMMM Do YYYY')}
      </CardHelp>
      <Card>
        <EpisodePlayer episode={episode} placements={placements} />
      </Card>
      <CardHeader rightContent={user.reviewer ? reviewButton : null}>
        Episode Ads ({episode.placementsCount})
      </CardHeader>
      <Card>
        {reviewed ? (
          hasNoAds ? (
            <EmptyMessage>This episode has no ads.</EmptyMessage>
          ) : (
            <Placements organization={organization} placements={placements} />
          )
        ) : (
          <EmptyMessage>This episode is pending review.</EmptyMessage>
        )}
      </Card>
    </>
  );
};

export default Episode;
