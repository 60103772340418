import {useState} from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import ChartLegend from '@core/components/ChartLegend';
import {ChartTimeTooltip} from '@core/components/ChartTimeTooltip';
import dayjs from '@core/lib/dayjs';
import {gtag} from '@core/lib/gtag';
import {computedCSSVariable} from '@core/lib/utils';
import {darken} from '@core/style';
import EmptyMessage from '@core/ui/EmptyMessage';

const getDomain = ({data, interval}: any) => {
  const minDate = dayjs(data[0].x);
  const maxDate = dayjs(data[data.length - 1].x);

  return interval === 'week'
    ? [
        minDate.subtract(4, 'days').toDate().getTime(),
        maxDate.add(4, 'days').toDate().getTime(),
      ]
    : [
        minDate.subtract(1, 'days').toDate().getTime(),
        maxDate.add(1, 'days').toDate().getTime(),
      ];
};
type Props = {
  data: any[];
  interval: 'day' | 'week';
  series: any[];
};

const InsightsPerformanceChart = ({data, interval, series}: Props) => {
  const [selectedSerie, setSelectedSerie] = useState(series[0]);
  const [focusBar, setFocusBar] = useState<number>();

  if (data.length > 0) {
    const domain = getDomain({data, interval});
    return (
      <div
        css={{
          display: 'grid',
          fontSize: 'var(--font-size-body-3)',
          gridGap: '1rem',
          gridTemplateColumns: '1fr 14rem',
        }}>
        <div onMouseLeave={() => setFocusBar(undefined)}>
          <ResponsiveContainer width='100%' height={350}>
            <BarChart
              data={data}
              margin={{top: 0, right: 0, left: 10, bottom: 0}}
              onMouseMove={(state) => {
                if (state.isTooltipActive) {
                  setFocusBar(state?.activeTooltipIndex);
                } else {
                  setFocusBar(undefined);
                }
              }}>
              <CartesianGrid
                strokeDasharray='0 0'
                stroke='var(--border-default)'
                vertical={false}
              />
              <XAxis
                dataKey='x'
                domain={domain}
                type='number'
                scale='time'
                tickFormatter={(date) => dayjs(date).utc().format('MMM DD')}
              />
              <YAxis
                tickFormatter={(value) => selectedSerie.fmt(value.toFixed(0))}
              />
              <Tooltip
                content={({
                  payload,
                  ...props
                }: TooltipProps<number, string>) => (
                  <ChartTimeTooltip
                    dateFormat='dddd, MMM DD'
                    payload={payload}
                    {...props}
                  />
                )}
                cursor={{fill: 'transparent'}}
              />
              <Bar dataKey={selectedSerie.accessor} fill={selectedSerie.color}>
                {data.map((_entry, idx) => (
                  <Cell
                    key={idx}
                    name={selectedSerie.accessor}
                    fill={
                      focusBar === idx
                        ? darken(computedCSSVariable(selectedSerie.color), 0.4)
                        : selectedSerie.color
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <ChartLegend
          series={series}
          selectedSeries={[selectedSerie]}
          setSelectedSeries={(_series: any) => {
            setSelectedSerie(_series[0]);
            gtag('event', _series[0].accessor, {
              event_category: 'measure_overview',
            });
          }}
          propertyForName='title'
        />
      </div>
    );
  }
  return <EmptyMessage>No data found.</EmptyMessage>;
};

export default InsightsPerformanceChart;
