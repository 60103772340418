import {Redirect, Route, Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import ManageNav from '@analytics/components/nav/ManageNav';
import {FetchedOrganization} from '@analytics/graphql-api';
import Affinity from './Affinity';

export type ManageAffinityRouteProps = {
  organization: FetchedOrganization;
  user: FetchedUser;
};

export function ManageAffinityIndex({user, organization}) {
  if (!user.isAdmin) {
    return <Redirect to={createOrgPath(organization, '/manage')} />;
  }

  return (
    <Switch>
      {[
        {
          exact: true,
          path: '/:part(a|s|b|p)/:slug/manage/affinity',
          Component: Affinity,
        },
      ].map(({exact = false, path, Component}) => (
        <Route
          key={path}
          exact={exact}
          path={path}
          render={(routeProps) => (
            <>
              {exact && <ManageNav user={user} organization={organization} />}
              <Component
                user={user}
                organization={organization}
                {...routeProps}
              />
            </>
          )}
        />
      ))}
    </Switch>
  );
}
