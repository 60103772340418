import {gql} from '@apollo/client';

export default gql`
  mutation createGenericTagRelationship(
    $input: CreateGenericTagRelationshipInput!
  ) {
    authed {
      success
    }
    createGenericTagRelationship(input: $input) {
      genericTagRelationship {
        genericTag {
          id
        }
        id
        objectPk
      }
    }
  }
`;
