import {useState} from 'react';
import styled from 'styled-components';
import {capitalize} from '@core/lib/filters';
import {t} from '@core/texts';
import {ToastMessage} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {ModalHeader} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import {Submit} from '@core/ui/FormElements';
import {Overlay} from '@core/ui/Overlay';
import {CampaignTrackingData} from '@analytics/components/tracking-urls/helpers';
import {
  CampaignTrackingUrlsDocument,
  useCreateCampaignDynamicDeliveryMutation,
  useCreateCampaignStreamingDeliveryMutation,
  useDeleteCampaignDynamicDeliveryMutation,
  useDeleteCampaignStreamingDeliveryMutation,
} from '@analytics/graphql-api';

type DeliveriesProps = {
  rows: CampaignTrackingData[];
  onError: () => void;
  toggleClose: () => void;
};

const CreateDeliveries = ({rows, onError, toggleClose}: DeliveriesProps) => {
  const [loading, setLoading] = useState(false);
  const [createDynamicDelivery] = useCreateCampaignDynamicDeliveryMutation();
  const [createStreamingDelivery] =
    useCreateCampaignStreamingDeliveryMutation();

  const onSubmit = async () => {
    setLoading(true);
    try {
      for (const item of rows) {
        if (!item.deliveries.length) {
          if (item.format === 'dynamic') {
            await createDynamicDelivery({
              variables: {
                input: {
                  campaignDynamicId: item.id,
                },
              },
              refetchQueries: [CampaignTrackingUrlsDocument],
            });
          } else {
            await createStreamingDelivery({
              variables: {
                input: {
                  campaignStreamingId: item.id,
                },
              },
              refetchQueries: [CampaignTrackingUrlsDocument],
            });
          }
        }
      }

      toggleClose();
    } catch (e) {
      onError();
    }
    setLoading(false);
  };

  return (
    <_Footer>
      <Button onClick={toggleClose}>Cancel</Button>
      <Submit loading={loading} onClick={onSubmit} color='secondary'>
        Confirm Selected
      </Submit>
    </_Footer>
  );
};

const DeleteDeliveries = ({rows, onError, toggleClose}: DeliveriesProps) => {
  const [loading, setLoading] = useState(false);
  const [deleteDynamicDelivery] = useDeleteCampaignDynamicDeliveryMutation();
  const [deleteStreamingDelivery] =
    useDeleteCampaignStreamingDeliveryMutation();

  const onSubmit = async () => {
    setLoading(true);
    try {
      for (const item of rows) {
        const delivery = item.deliveries.length ? item.deliveries[0].id : null;
        if (delivery) {
          if (item.format === 'dynamic') {
            await deleteDynamicDelivery({
              variables: {
                input: {
                  id: item.deliveries[0].id,
                },
              },
              refetchQueries: [CampaignTrackingUrlsDocument],
            });
          } else {
            await deleteStreamingDelivery({
              variables: {
                input: {
                  id: item.deliveries[0].id,
                },
              },
              refetchQueries: [CampaignTrackingUrlsDocument],
            });
          }
        }
      }
      toggleClose();
    } catch (e) {
      onError();
    }
    setLoading(false);
  };

  return (
    <_Footer>
      <Button onClick={toggleClose}>Cancel</Button>
      <Submit loading={loading} onClick={onSubmit} color='error'>
        Unconfirm Selected
      </Submit>
    </_Footer>
  );
};

type Action = {
  actionName: 'confirm' | 'unconfirm' | null;
  rows: CampaignTrackingData[];
};

type Props = {
  action: Action;
  toggleClose: () => void;
};

export default function TrackingUrlsTableBulkOverlay({
  action,
  toggleClose,
}: Props) {
  const [error, setError] = useState(false);

  return (
    <Overlay
      opened
      centered
      withShadow
      withBackdrop
      closeOnOutsideClick={false}
      rules={() => ({padding: '1.25rem', minWidth: '37.5rem'})}>
      <ModalHeader>
        {capitalize(action.actionName ?? '')} Tracking URLs
      </ModalHeader>
      {error && (
        <ToastMessage alertType='error' rules={() => ({margin: '1rem 0'})}>
          {t('errors.network')}
        </ToastMessage>
      )}
      {action.actionName === 'confirm' &&
        'By confirming here, you are confirming with the advertiser that you have installed the tracking pixels.'}
      <DataTable
        data={action.rows}
        columns={[
          {title: 'Name', accessor: 'name'},
          {
            title: 'Provider',
            accessor: 'providerName',
            fmt: (v) => capitalize(v ?? ''),
          },
        ]}
        paginationRowsPerPage={6}
        paginationRowsPerPageOptions={[6, 20, 40]}
        rules={() => ({margin: '1rem 0 2rem'})}
      />
      {action.actionName === 'confirm' ? (
        <CreateDeliveries
          onError={() => setError(true)}
          rows={action.rows}
          toggleClose={toggleClose}
        />
      ) : (
        <DeleteDeliveries
          onError={() => setError(true)}
          rows={action.rows}
          toggleClose={toggleClose}
        />
      )}
    </Overlay>
  );
}

const _Footer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;
