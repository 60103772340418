import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Element} from '@core/style';
import {BLUE} from '@core/style';
import {Icon} from '@core/ui/Icon';
import {LoadingSpinner} from '@core/ui/Loading';
import CREATE_GENERIC_TAG_RELATIONSHIP from '@analytics/graphql-api/_old_mutations/CreateGenericTagRelationship';
import DELETE_GENERIC_TAG_RELATIONSHIP from '@analytics/graphql-api/_old_mutations/DeleteGenericTagRelationship';

const TagsOverlayTagList = ({
  instance,
  instanceTags,
  onAddRelationship,
  onClickEditOrganizationTag,
  onRemoveRelationship,
  organizationTags,
  setTitle,
}) => {
  setTitle('Tags');
  const [loadingIds, setLoadingIds] = useState([]);
  const [error, setError] = useState(null);

  const [createGenericTagRelationship] = useMutation(
    CREATE_GENERIC_TAG_RELATIONSHIP
  );
  const [deleteGenericTagRelationship] = useMutation(
    DELETE_GENERIC_TAG_RELATIONSHIP
  );

  const toggleLoading = (id, to) => {
    setLoadingIds((prev) => {
      const arr = prev.slice(0);
      if (to) {
        arr.push(id);
      } else {
        const idx = arr.indexOf(id);

        if (idx > -1) {
          arr.splice(idx, 1);
        }
      }
      return arr;
    });
  };

  const addRelationship = async (tag) => {
    toggleLoading(tag.id, true);
    try {
      const resp = await createGenericTagRelationship({
        variables: {
          input: {
            genericTagId: tag.id,
            objectPk: instance.id,
            contentType: 'campaigns.campaign',
          },
        },
      });
      onAddRelationship({
        tagRelationShip:
          resp.data.createGenericTagRelationship.genericTagRelationship,
        genericTag: tag,
      });
    } catch (err) {
      console.warn(err);
      setError(err);
    }
    toggleLoading(tag.id, false);
  };

  const removeRelationship = async (tag) => {
    const instanceTag = instanceTags.find(({id}) => id === tag.id);
    const genericTagRelationship = instanceTag
      ? instanceTag.genericTagRelationships.find(
          ({objectPk}) => objectPk === instance.id
        )
      : null;

    if (genericTagRelationship) {
      toggleLoading(tag.id, true);
      try {
        await deleteGenericTagRelationship({
          variables: {
            input: {
              id: genericTagRelationship.id,
            },
          },
        });
        onRemoveRelationship(genericTagRelationship.id);
      } catch (err) {
        console.warn(err);
        setError(err);
      }
      toggleLoading(tag.id, false);
    }
  };

  return (
    <>
      {organizationTags && organizationTags.length
        ? organizationTags.map((genericTag, idx) => {
            const loading = loadingIds.indexOf(genericTag.id) > -1;
            const active = !!instanceTags.find(({id}) => genericTag.id === id);

            return (
              <Element
                key={genericTag.title + genericTag.color}
                rules={() => ({
                  alignItems: 'center',
                  display: 'grid',
                  gridGap: '0.625rem',
                  gridTemplateColumns: '1fr max-content',
                  justifyContent: 'space-between',
                  padding: '0 0.75rem 0 1rem',
                  marginBottom:
                    idx !== organizationTags.length - 1 ? '0.25rem' : '0',
                })}>
                <Element
                  onClick={() => {
                    if (!loading) {
                      if (active) {
                        removeRelationship(genericTag);
                      } else {
                        addRelationship(genericTag);
                      }
                    }
                  }}
                  rules={({theme}) => ({
                    alignItems: 'center',
                    background: genericTag.color,
                    borderRadius: '0.1875rem',
                    color: 'white',
                    cursor: 'pointer',
                    display: 'grid',
                    gridGap: '1rem',
                    gridTemplateColumns: '1fr max-content',
                    height: '2rem',
                    padding: '0 0.625rem',
                    transition: 'transform 0.1s linear',
                    ':hover': {
                      boxShadow: `inset 0 0 0 2px ${
                        theme.name === 'light' ? theme.black : theme.white
                      }`,
                      transform: 'scale(1.02)',
                    },
                  })}>
                  <Element
                    rules={() => ({
                      minWidth: 0,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    })}>
                    {genericTag.title}
                  </Element>
                  {loading ? (
                    <LoadingSpinner
                      color='#fff'
                      size='small'
                      css={`
                        flex-shrink: 0;
                        margin-right: 0.0625rem;
                      `}
                    />
                  ) : active ? (
                    <Icon
                      icon='checkbox-marked-circle'
                      rules={() => ({
                        color: '#fff',
                        flexShrink: 0,
                        height: '1.25rem',
                        marginLeft: 'auto',
                        width: '1.25rem',
                      })}
                    />
                  ) : null}
                </Element>
                <Element
                  onClick={() => onClickEditOrganizationTag(genericTag)}
                  rules={({theme}) => ({
                    color: theme.textTertiary,
                    cursor: 'pointer',
                    display: 'flex',
                    padding: '0.125rem',
                    ':hover': {
                      color: BLUE,
                    },
                  })}>
                  <Icon
                    icon='pencil'
                    rules={() => ({
                      height: '1.125rem',
                      width: '1.125rem',
                    })}
                  />
                </Element>
              </Element>
            );
          })
        : null}
    </>
  );
};

export default TagsOverlayTagList;
