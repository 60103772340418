import React, {useEffect} from 'react';
import AudioPlayButton from '@core/components/AudioPlayButton';
import {toAudioPath} from '@core/lib/audio';
import {Element} from '@core/style';
import {GREEN} from '@core/style';
import AudioSplit from './AudioSplit';
import AudioWaveform from './AudioWaveform';

// Simplified player.

const AudioPlayer = ({
  flacPath,
  mpegPath,
  waveform,
  currentTime,
  playing,
  duration,
  togglePlay,
  onReady,
  onTimeUpdate,
  splitting,
  splitSeconds,
  onSplitUpdate,
  setCurrentTime,
}) => {
  const trackRef = React.useRef(null);
  const audioRef = React.useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      if (playing) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [audioRef.current, playing]);

  const onClick = (evt) => {
    const rect = trackRef.current.getBoundingClientRect();
    setCurrentTime(((evt.pageX - rect.x) / rect.width) * duration);
  };

  return (
    <Element rules={() => ({display: 'flex'})}>
      <Element
        rules={() => ({
          flex: '0 0 75px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        })}>
        <AudioPlayButton
          playing={playing}
          onClick={togglePlay}
          rules={() => ({width: '50px', cursor: 'pointer'})}
        />
      </Element>
      <audio
        onCanPlayThrough={onReady}
        onTimeUpdate={onTimeUpdate}
        ref={audioRef}>
        <source src={toAudioPath(flacPath)} type='audio/flac' />
        <source src={toAudioPath(mpegPath)} type='audio/flac' />
      </audio>
      <Element
        domRef={trackRef}
        onClick={onClick}
        rules={() => ({
          cursor: 'pointer',
          flex: 1,
          position: 'relative',
        })}>
        <AudioWaveform
          trackWidth={600}
          height={100}
          waveform={waveform}
          color={GREEN}
          pretty
        />
        {duration ? (
          <Element
            style-offset={currentTime / duration}
            rules={({offset}) => ({
              background: '#212121',
              bottom: 0,
              marginLeft: `${(offset * 100).toFixed(5)}%`,
              position: 'absolute',
              top: 0,
              width: '2px',
            })}
          />
        ) : (
          ''
        )}
        {splitting ? (
          <AudioSplit
            onSplitUpdate={onSplitUpdate}
            duration={duration}
            splitSeconds={splitSeconds}
          />
        ) : (
          ''
        )}
      </Element>
    </Element>
  );
};

class SimpleAdPlayer extends React.Component {
  state = {
    canplay: false,
    playing: false,
    currentTime: 0,
    duration: null,
  };

  onTimeUpdate = ({target: {currentTime}}) => {
    this.setState({currentTime});
  };

  setCurrentTime = (value) => {
    this.audio.currentTime = value;
  };

  onReady = ({target}) => {
    this.audio = target;
    this.setState({
      canplay: true,
      duration: target.duration,
    });

    this.audio.addEventListener('ended', () => {
      this.setState({
        playing: false,
      });
    });
  };

  togglePlay = () => {
    this.setState({
      playing: !this.state.playing,
    });
  };

  render() {
    const {ad} = this.props;
    const {playing, currentTime, duration} = this.state;

    return (
      <AudioPlayer
        flacPath={ad.flacPath}
        mpegPath={ad.mpegPath}
        waveform={ad.waveform}
        onReady={this.onReady}
        togglePlay={this.togglePlay}
        onTimeUpdate={this.onTimeUpdate}
        playing={playing}
        currentTime={currentTime}
        setCurrentTime={this.setCurrentTime}
        duration={duration}
      />
    );
  }
}

export {SimpleAdPlayer};

export default AudioPlayer;
