import {gql} from '@apollo/client';
import CampaignEpisodeFragment from './fragments/CampaignEpisodeFragment';
import CampaignPodcastFragment from './fragments/CampaignPodcastFragment';

export default gql`
  query getCampaignPodcast($organizationId: ID!, $campaignId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          campaignPodcast(id: $id) {
            ...CampaignPodcast

            attribution {
              users {
                sum {
                  users
                  usersModeled
                  pages
                  pagesModeled
                  sessions
                  sessionsModeled
                  products
                  productsModeled
                  addtocarts
                  addtocartsModeled
                  checkouts
                  checkoutsModeled
                  purchases
                  purchasesModeled
                  leads
                  leadsModeled
                  value
                  valueModeled
                }
              }
            }

            campaignEpisodes {
              ...CampaignEpisode
            }
          }
        }
      }
    }
  }
  ${CampaignPodcastFragment}
  ${CampaignEpisodeFragment}
`;
