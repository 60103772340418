import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from '@spotify-internal/encore-web';
import {capitalize} from 'lodash';
import {DEFAULT_METRIC, METRIC_CATEGORY_TITLES} from '../constants';
import {AudienceInsights, InsightsMetric} from '../types';
import {parseInsightsCategoryData} from '../utils';
import {InsightsEmptyState} from './InsightsEmptyState';

type Props = {
  audience: AudienceInsights;
  categoryTitle: string;
  metric?: InsightsMetric;
};

export function InsightsCategoryTable({
  audience,
  categoryTitle,
  metric = DEFAULT_METRIC,
}: Props) {
  const data = parseInsightsCategoryData(audience);

  if (!data.length) {
    return <InsightsEmptyState />;
  }

  return (
    <Table>
      <thead>
        <TableRow>
          <TableHeaderCell>{categoryTitle}</TableHeaderCell>
          <TableHeaderCell>{METRIC_CATEGORY_TITLES[metric]}</TableHeaderCell>
        </TableRow>
      </thead>
      <tbody>
        {data.map((row) => (
          <TableRow key={row.segment}>
            <TableCell>{capitalize(row.segment)}</TableCell>
            <TableCell>{row[metric]}</TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
}
