import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {FetchedCampaignLiftReports} from '@analytics/graphql-api';
import {CampaignRouteProps} from '../_routes';

export type CampaignLiftRouteProps = CampaignRouteProps & {
  liftReports: FetchedCampaignLiftReports;
};

export const campaignLiftURL = (
  organization: CampaignRouteProps['organization'],
  campaign: CampaignRouteProps['campaign']
) => createOrgPath(organization, `/campaigns/${campaign.slug}/lift`);

export const useCampaignLiftRoutes = ({
  organization,
  campaign,
}: Pick<CampaignRouteProps, 'organization' | 'campaign'>) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, `/campaigns/${campaign.slug}/lift`),
    basePath: '/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/lift',
    routes: [
      {key: 'lift', exact: true, path: ''},
      {key: 'request', exact: true},
      {key: 'report', path: 'reports/:liftReportId'},
      {key: 'households'},
    ],
  });
};
