import {gql} from '@apollo/client';

export default gql`
  query FeedDayRankings($organizationId: ID!, $id: ID!, $after: String!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          feed(id: $id) {
            id
            dayRankings(
              category: "top-200"
              provider: "itunes"
              after: $after
            ) {
              day
              rank
            }
          }
        }
      }
    }
  }
`;
