import {gql} from '@apollo/client';
import PodcastApprovalFragment from '@analytics/graphql-api/_old_queries/fragments/PodcastApprovalFragment';
import DynamicApprovalFragment from './fragments/DynamicApprovalFragment';
import StreamingApprovalFragment from './fragments/StreamingApprovalFragment';

export default gql`
  query getCampaignApprovals($organizationId: ID!, $campaignId: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          dynamicApprovals {
            ...DynamicApproval
          }
          podcastApprovals {
            ...PodcastApproval
          }
          streamingApprovals {
            ...StreamingApproval
          }
        }
      }
    }
  }
  ${DynamicApprovalFragment}
  ${PodcastApprovalFragment}
  ${StreamingApprovalFragment}
`;
