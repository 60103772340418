import {Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import ManageNav from '@analytics/components/nav/ManageNav';
import {FetchedOrganization} from '@analytics/graphql-api';
import AddAdInsertionProvider from './AddAdInsertionProvider';

export type ManageAdInsertionProviderRouteProps = {
  organization: FetchedOrganization;
  user: FetchedUser;
};

export function ManageAdInsertionProviderIndex({user, organization}) {
  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, 'manage/ad-providers'),
    basePath: '/:part(a|s|b|p)/:slug/manage/ad-providers',
    routes: [
      {
        key: 'ad-providers',
        path: '',
        exact: true,
      },
    ],
  });

  return (
    <>
      <ManageNav user={user} organization={organization} />
      <Switch>
        {renderRoutes({
          components: {
            'ad-providers': AddAdInsertionProvider,
          },
          extraProps: {
            user,
            organization,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
}
