import {useCallback, useState} from 'react';
import dayjs from 'dayjs';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {comma} from '@core/lib/filters';
import {TRules, css, getColor} from '@core/style';
import SegmentedControls from '@core/ui/SegmentedControls';
import {ChartTimeTooltip} from '../ChartTimeTooltip';

type TRangeInterval = 'hourly' | 'daily';

interface IHistoricalEvent {
  name: string;
  eventCounts: {
    name: string;
    value: number;
  }[];
}

interface IHistorical {
  hour: string;
  js: IHistoricalEvent[];
  mobile: IHistoricalEvent[];
  img: IHistoricalEvent[];
}

const serializeChartEvent = (
  values: IHistoricalEvent[],
  seriesName: string,
  seriesSet: Set<string>
) => {
  const keys: {[k: string]: number} = {};
  values.forEach((v) => {
    v.eventCounts.forEach((e) => {
      const eventName = e.name === 'pdst-all' ? '' : `_${e.name}`;
      const name = `${seriesName}_${v.name}${eventName}`;
      const value = e.value || 0;

      seriesSet.add(name);
      keys[name] ? (keys[name] += value) : (keys[name] = value);
    });
  });

  return keys;
};

const useChartData = ({
  historical,
  rangeInterval,
}: {
  historical: IHistorical[];
  rangeInterval: TRangeInterval;
}) => {
  const seriesSet = new Set<string>();

  let data = historical.map(({hour, js, mobile, img}) => {
    const x = new Date(hour).getTime();
    return {
      x,
      ...serializeChartEvent(js, 'js', seriesSet),
      ...serializeChartEvent(mobile, 'mobile', seriesSet),
      ...serializeChartEvent(img, 'img', seriesSet),
    };
  });

  // fill in 0 values & get line colors
  data = data.map((d) => {
    const hourly = {...d};
    [...seriesSet].forEach((serie) => {
      if (!hourly[serie]) hourly[serie] = 0;
    });

    return hourly;
  });

  if (rangeInterval === 'daily') {
    data = Object.values(
      data.reduce((r, acc) => {
        const dateStr = new Date(acc.x).toISOString().split('T')[0];

        if (r[dateStr]) {
          [...seriesSet].forEach((serie) => {
            r[dateStr][serie] += acc[serie];
          });
        } else {
          r[dateStr] = acc;
        }
        return r;
      }, {})
    );
  }

  const lines = [...seriesSet].map((serie, idx) => ({
    name: serie,
    color: getColor(idx),
  }));

  return {data, lines};
};

interface IPixelEventsChartProps {
  height?: number;
  hideRangeMenu?: boolean;
  historical: IHistorical[];
  rules?: TRules;
}

const PixelEventsChart = ({
  height = 300,
  hideRangeMenu = false,
  historical,
  rules,
}: IPixelEventsChartProps): JSX.Element => {
  const [rangeInterval, setRangeInterval] = useState<TRangeInterval>('hourly');
  const {data, lines} = useChartData({historical, rangeInterval});

  const onChangeInterval = useCallback(({value}) => {
    setRangeInterval(value);
  }, []);

  return (
    <div {...css([() => ({fontSize: '0.8125rem'}), rules])}>
      {!hideRangeMenu && (
        <SegmentedControls
          propertyForValue='title'
          items={[
            {
              title: 'Hourly',
              value: 'hourly',
            },
            {
              title: 'Daily',
              value: 'daily',
            },
          ]}
          onChange={onChangeInterval}
          segmentWidth={75}
          css={`
            margin-bottom: 0.625rem;
          `}
        />
      )}
      <ResponsiveContainer width='100%' height={height}>
        <LineChart data={data}>
          <CartesianGrid
            stroke='var(--border-default)'
            strokeDasharray='0 0'
            vertical={false}
          />
          <XAxis
            dataKey='x'
            domain={['auto', 'auto']}
            type='number'
            scale='time'
            tick={{fill: 'var(--text-muted)'}}
            tickLine={false}
            tickFormatter={(timeStr) => dayjs(timeStr).utc().format('MMM DD')}
            axisLine={false}
          />
          <YAxis
            tickFormatter={(v) => comma(v)}
            tick={{fill: 'var(--text-muted)'}}
            tickLine={false}
            axisLine={false}
          />
          <Tooltip
            content={({payload, label}) => (
              <ChartTimeTooltip
                dateFormat={
                  rangeInterval === 'daily'
                    ? 'ddd, MMM DD'
                    : 'ddd, MMM DD - HH:00'
                }
                label={label}
                payload={payload}
              />
            )}
          />
          {lines.map(({name, color}) => (
            <Line
              key={name}
              type='linear'
              strokeWidth={2}
              dataKey={name}
              stroke={color}
              fill={color}
              dot={false}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PixelEventsChart;
