import {Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {FetchedCampaign, FetchedOrganization} from '@analytics/graphql-api';
import CampaignFutureIndex from './future';

const CampaignFuturesIndex = ({
  user,
  organization,
  campaign,
  campaignPodcast,
  match: {
    params: {campaignEpisodeId},
  },
}: {
  user: FetchedUser;
  organization: FetchedOrganization;
  campaign: FetchedCampaign;
  campaignPodcast: TSFixMe;
  match: any;
}) => {
  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(
      organization,
      `/campaigns/${campaign.slug}/podcasts/${campaignPodcast.id}/futures/${campaignEpisodeId}`
    ),
    basePath:
      '/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/podcasts/:campaignPodcastId/futures/:campaignEpisodeId',
    routes: [{key: 'future', path: ''}],
  });

  return (
    <Switch>
      {renderRoutes({
        components: {
          future: CampaignFutureIndex,
        },
        extraProps: {
          user,
          organization,
          campaign,
          campaignPodcast,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
};

export default CampaignFuturesIndex;
