import React, {useState} from 'react';
import {createOrgPath} from '@core/lib/organizations';
import {getRoles} from '@core/lib/roles';
import {Element} from '@core/style';
import {ButtonContainer} from '@core/ui/Button/LegacyButton';
import {Card, CardHeader} from '@core/ui/Content';
import {Form, Input, Label} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import Select from '@core/ui/Select';

const MembershipForm = ({
  errors = [],
  loading = false,
  membership,
  onSubmit,
  organization,
  updateMode = false,
}) => {
  const [state, setState] = useState(() => {
    if (membership) {
      const {
        user: {firstName, lastName, email},
        role,
      } = membership;
      return {
        firstName,
        lastName,
        email,
        role: role.toLowerCase(),
      };
    }

    return {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
    };
  });

  const onChange = ({target: {name, value}}) => {
    const update = {};
    update[name] = value;
    setState({...state, ...update});
  };

  return (
    <>
      <CardHeader backPath={createOrgPath(organization, `/manage/members`)}>
        {updateMode ? 'Update Member' : 'Invite Member'}{' '}
      </CardHeader>
      <Card>
        <Form
          loading={loading}
          onSubmit={() => {
            onSubmit(state);
          }}
          errors={errors}>
          <Element
            rules={() => ({
              display: 'grid',
              gridGap: '1rem',
              gridTemplateColumns: '1fr 1fr',
              marginBottom: '1rem',
            })}>
            <Input
              defaultValue={state.firstName}
              disabled={loading || updateMode}
              label='First Name'
              name='firstName'
              onChange={onChange}
            />

            <Input
              label='Last Name'
              name='lastName'
              defaultValue={state.lastName}
              onChange={onChange}
              disabled={loading || updateMode}
            />
          </Element>
          <Input
            label='Email'
            name='email'
            defaultValue={state.email}
            onChange={onChange}
            disabled={loading || updateMode}
            withMargin
          />
          <Label>Role</Label>
          <Select
            defaultValue={state.role}
            placeholder='Please select a role'
            items={getRoles(organization.kind)}
            onSelect={({value}) => {
              setState({...state, ...{role: value}});
            }}
            rules={() => ({width: '100%'})}
          />
          <ButtonContainer style-marginTop>
            <Submit disabled={loading}>
              {updateMode ? 'Save' : 'Send Invite'}
            </Submit>
          </ButtonContainer>
        </Form>
      </Card>
    </>
  );
};

export default MembershipForm;
