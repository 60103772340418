import {gql} from '@apollo/client';

export default gql`
  mutation updateCampaignBrandAgencies(
    $input: UpdateCampaignBrandAgenciesInput!
  ) {
    authed {
      success
    }
    updateCampaignBrandAgencies(input: $input) {
      success

      campaigns {
        id
        name
        organization {
          id
          name
        }
        buySideAgencies {
          organizationId
          organizationName
        }
      }
    }
  }
`;
