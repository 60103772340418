import {useCallback, useState} from 'react';
import {TRules} from '@core/style';
import {Element} from '@core/style';
import {IconButton} from '../Button/LegacyButton';
import {Icon} from '../Icon';
import {Input} from './';

type TPropertyItem = {key: string; value: string};
type TKeyMap = {[x: string]: string};

interface IKeyMapInputProps {
  customKeyPlaceholders?: string[];
  defaultValue?: TPropertyItem[];
  inputLimit?: number;
  onChange?: ({
    keyMap,
    keyMapList,
  }: {
    keyMap: TKeyMap;
    keyMapList: TPropertyItem[];
  }) => void;
  rules?: TRules;
}

const toKeyMap = (arr: TPropertyItem[]): TKeyMap => {
  return arr.reduce((acc, item) => {
    acc[item.key] = String(item.value);
    return acc;
  }, {});
};

const KeyMapInput = ({
  customKeyPlaceholders = undefined,
  defaultValue,
  inputLimit = undefined,
  onChange,
  rules,
}: IKeyMapInputProps): JSX.Element => {
  const [keyMapList, setKeyMapList] = useState<TPropertyItem[]>(
    defaultValue || []
  );

  const dispatchChange = useCallback(
    (keyMapList: TPropertyItem[]): void => {
      if (onChange) {
        const validKeyMapList = keyMapList.filter(
          ({key, value}) => key && value
        );
        const keyMap = toKeyMap(validKeyMapList);
        onChange({keyMap, keyMapList: validKeyMapList});
      }
    },
    [onChange]
  );

  const updateItem = (item: TPropertyItem, {key, value}: TKeyMap): void => {
    item[key] = String(value).trim();
    const _keyMapList = keyMapList.slice(0);
    setKeyMapList(_keyMapList);
    dispatchChange(_keyMapList);
  };

  const removeItem = (item: TPropertyItem): void => {
    keyMapList.splice(keyMapList.indexOf(item), 1);
    const _keyMapList = keyMapList.slice(0);
    setKeyMapList(_keyMapList);
    dispatchChange(_keyMapList);
  };

  const addNewItem = (): void => {
    setKeyMapList(keyMapList.concat({key: '', value: ''}));
  };

  return (
    <Element rules={rules}>
      {keyMapList.map((item, idx) => (
        <Element
          key={idx}
          rules={() => ({
            alignItems: 'center',
            display: 'grid',
            gridGap: '1rem',
            gridTemplateColumns: '1fr 1fr max-content',
            marginBottom: keyMapList.length - 1 !== idx ? '0.625rem' : 0,
          })}>
          <Input
            name='key'
            placeholder={customKeyPlaceholders?.[idx] || ''}
            defaultValue={item.key}
            onInput={({target}) =>
              updateItem(item, {key: 'key', value: target.value})
            }
            small
          />
          <Input
            name='value'
            defaultValue={item.value}
            onInput={({target}) =>
              updateItem(item, {key: 'value', value: target.value})
            }
            small
          />
          <IconButton type='button' onClick={() => removeItem(item)}>
            <Icon icon='minus' />
          </IconButton>
        </Element>
      ))}
      {(!inputLimit || keyMapList.length < inputLimit) && (
        <IconButton
          type='button'
          onClick={addNewItem}
          rules={() => ({marginTop: '1rem'})}>
          <Icon icon='add-circle-outline' />
        </IconButton>
      )}
    </Element>
  );
};

export default KeyMapInput;
