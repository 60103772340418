import { ballad, cssBorderRadius, cssSpacing, formControlBase, formControlTheme, mesto, rootStyle } from "../../styles/index.js";
import styled from "../../styled-components.js";
export var Textarea = styled.textarea.withConfig({
  displayName: "Textarea",
  componentId: "sc-7bfmzb-0"
})(["", ";", ";margin-block-start:0;margin-block-end:0;border-radius:", ";padding:", ";", " ", ";"], function (props) {
  return formControlBase(props.$useBrowserDefaultFocusStyle);
}, rootStyle(), cssBorderRadius('base', '4px'), function (props) {
  return props.$size === 'small' ? cssSpacing('tighter-2', '8px') : "".concat(cssSpacing('tighter', '12px'), " ").concat(cssSpacing('base', '16px'));
}, function (props) {
  return props.$size === 'small' ? mesto() : ballad();
}, function (props) {
  return formControlTheme({
    isUsingKeyboard: props.$isUsingKeyboard,
    isHover: props.$isHover,
    isFocus: props.$isFocus,
    error: props.$error,
    useBrowserDefaultFocusStyle: props.$useBrowserDefaultFocusStyle
  });
});