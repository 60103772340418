import {Control} from 'react-hook-form';
import {FormSeparator} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {FetchedCampaign} from '@analytics/graphql-api';
import {CampaignFormData, PubOrg} from '../_types';
import {CampaignAgencyAccessField} from './CampaignAgencyAccessField';
import {CampaignCostField} from './CampaignCostField';
import {CampaignDatesField} from './CampaignDatesField';
import {CampaignGoLiveDateField} from './CampaignGoLiveDateField';
import {CampaignNameField} from './CampaignNameField';
import {CampaignTypeField} from './CampaignTypeField';

export function CampaignFormFields({
  campaign,
  ...props
}: {
  campaign?: FetchedCampaign | undefined;
  companyId: string;
  control: Control<CampaignFormData>;
  organizationId: string;
  pubOrg: PubOrg;
}) {
  return (
    <Stack direction='column' gap='var(--spacing-4)'>
      <CampaignNameField {...props} />
      {campaign && <CampaignTypeField {...props} />}
      <CampaignDatesField {...props} />
      <CampaignGoLiveDateField {...props} />
      <CampaignCostField {...props} />
      <FormSeparator />
      <CampaignAgencyAccessField {...props} />
    </Stack>
  );
}
