import styled from 'styled-components';

export const NoPageViewEventsWarning = () => {
  return (
    <Container>
      <p>
        It appears that the Page View event has been removed from your site. To
        reinstate it, please follow these steps:
      </p>
      <ol>
        <li>
          Click on <b>"Add New Event."</b>
        </li>
        <li>
          In the <b>"Event Name"</b> field, search for <b>"Page View."</b>
        </li>
        <li>
          Ensure that <b>"Event Action"</b> is set to <b>"Page View."</b>
        </li>
        <li>
          Finally, click <b>"Save Event"</b> to re-add the Page View event to
          your Pixel.
        </li>
      </ol>
      <p>Happy tracking!</p>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 1.5rem;
`;
