import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {gtag} from '@core/lib/gtag';
import {DataTableFilterProps} from '@core/ui/DataTable';
import {MultipleSelect} from '@core/ui/Select';

interface IAudienceMultipleSelectFilterProps extends DataTableFilterProps {
  accessor: string;
  items?: any[];
  searchable?: boolean;
}

const AudienceMultipleSelectFilter = forwardRef(
  (
    {
      column,
      data,
      accessor,
      items,
      onChange,
      searchable,
      ...props
    }: IAudienceMultipleSelectFilterProps,
    ref
  ) => {
    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    useImperativeHandle(ref, () => ({
      filter: (filteredData) => {
        if (selectedItems.length) {
          return filteredData.filter((d) => {
            return selectedItems.some((item) => {
              if (d[accessor || column.accessor]) {
                return d[accessor || column.accessor].includes(item.value);
              }
              return false;
            });
          });
        }
        return filteredData;
      },
      clear: () => {
        setSelectedItems([]);
      },
    }));

    useEffect(() => {
      onChange();
    }, [selectedItems]);

    return (
      <MultipleSelect
        items={items}
        placeholder={column.title}
        onSelect={(items) => {
          gtag(
            'event',
            accessor.replace('Idx', '').toLowerCase().replace(/ /g, '_'),
            {
              event_category: 'podcasts_audience_intel',
            }
          );
          setSelectedItems(items);
        }}
        searchable={searchable}
        searchKeys={['value']}
        outline
        small
        {...props}
      />
    );
  }
);

export default AudienceMultipleSelectFilter;
