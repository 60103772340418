import styled from 'styled-components';
import {Card} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {Text, TextProps} from '@core/ui/Text';
import {Tooltip} from '@core/ui/Tooltip';
import {Significance} from '@analytics/graphql-api';
import {
  CONTROL,
  EXPOSED,
  SIGNIFICANCE_MAP,
  getSignificanceDisplay,
  toNumString,
} from './helpers';
import {GroupType} from './types';

const BaseIcon = styled.div`
  height: 0.475rem;
  width: 0.475rem;
`;

export const GroupIcon = styled(BaseIcon)<{group: GroupType}>`
  background: ${({group}) => (group === 'control' ? CONTROL : EXPOSED)};
  border-radius: 0.125rem;
`;

export const SignificanceIcon = styled(BaseIcon)<{significance?: Significance}>`
  background: ${({significance}) =>
    SIGNIFICANCE_MAP[significance ?? Significance.NotDetected]};
  border-radius: 50%;
`;

export const PieLegenIcon = styled(BaseIcon)<{fill?: string}>`
  background: ${({fill}) => fill};
  border-radius: 50%;
`;

export const CardGrid = styled(Card)<{columns: string}>`
  display: grid;
  gap: var(--spacing-14);
  grid-template-columns: ${({columns}) => columns};
`;

export const Grid = styled.div<{columns: string; gap?: string}>`
  align-items: center;
  display: grid;
  grid-template-columns: ${({columns}) => columns};
  gap: ${({gap}) => gap ?? 'var(--spacing-1)'};
`;

export const Stat = ({
  title,
  value,
  children,
}: {
  title: string;
  value: string | React.ReactNode;
  children?: React.ReactNode;
}) => (
  <Stack direction='column' gap='var(--spacing-1)'>
    <Text variant='subtitle1'>{title}</Text>
    <Text variant='h3'>{value}</Text>
    {children}
  </Stack>
);

export const LabelItem = ({
  icon,
  label,
  textProps = {},
  ...props
}: {
  icon: GroupType | Significance;
  label: string | React.ReactNode;
  textProps?: TextProps;
}) => (
  <Stack alignItems='center' gap='var(--spacing-1)' {...props}>
    {icon === 'control' || icon === 'exposed' ? (
      <GroupIcon group={icon} />
    ) : (
      <SignificanceIcon significance={icon} />
    )}
    <Text color='muted' variant='body3' {...textProps}>
      {label}
    </Text>
  </Stack>
);

export const SignificanceLegend = ({...props}) => (
  <Stack alignItems='center' gap='var(--spacing-4)' {...props}>
    {Object.values(Significance)
      .sort((a, b) => toNumString(b).localeCompare(toNumString(a)))
      .map((significance) => {
        const {label, description} = getSignificanceDisplay(significance);
        return (
          <Tooltip key={significance} description={description}>
            <LabelItem icon={significance} label={label} />
          </Tooltip>
        );
      })}
  </Stack>
);

const TEXT_COLOR = 'tooltip';

type TooltipBaseProps = {
  label: string;
  labelValue?: string;
  valueByGroup?: {
    control: {
      label: string;
      value: string;
    };
    exposed: {
      label: string;
      value: string;
    };
  };
};

export const BrandLiftChartTooltip = ({
  label,
  labelItem,
  labelValue,
  valueByGroup,
}:
  | (TooltipBaseProps & {
      labelItem: {
        label: string;
        significance: Significance;
      };
    })
  | (TooltipBaseProps & {
      labelItem?: undefined;
    })) => (
  <TooltipContainer>
    <Grid
      columns='1fr 1fr'
      css={
        valueByGroup && {
          borderBottom: `1px solid var(--text-${TEXT_COLOR})`,
          paddingBottom: 'var(--spacing-1)',
          marginBottom: 'var(--spacing-1)',
        }
      }>
      <Text color={TEXT_COLOR} variant='subtitle1'>
        {label}
      </Text>
      {labelItem && (
        <LabelItem
          css={{justifySelf: 'end'}}
          icon={labelItem.significance}
          label={labelItem.label}
          textProps={{color: TEXT_COLOR, variant: 'subtitle2'}}
        />
      )}
      {labelValue && (
        <Text color={TEXT_COLOR} variant='subtitle2' css={{justifySelf: 'end'}}>
          {labelValue}
        </Text>
      )}
    </Grid>
    {valueByGroup &&
      (['control', 'exposed'] as const).map((group) => (
        <Grid key={group} columns='1fr max-content' gap='var(--spacing-4)'>
          <LabelItem
            icon={group}
            label={valueByGroup[group].label}
            textProps={{color: TEXT_COLOR}}
          />
          <Text css={{justifySelf: 'end'}} color={TEXT_COLOR} variant='body3'>
            {valueByGroup[group].value}
          </Text>
        </Grid>
      ))}
  </TooltipContainer>
);

const TooltipContainer = styled(Stack).attrs({
  direction: 'column',
  gap: 'var(--spacing-1)',
})`
  background: var(--bg-tooltip);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-lg);
  color: var(--text-tooltip);
  font-size: var(--font-size-body-2);
  max-width: 13.75rem;
  min-width: 10rem;
  padding: var(--spacing-3);
`;
