import {CampaignAdPlacementsTable} from '@analytics/components/campaigns/CampaignAdPlacementsTable';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignRouteProps} from '../_routes';

export function CampaignPlacements({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  const campaignDynamics = campaign.campaignDynamics ?? [];
  const campaignStreamings = campaign.campaignStreamings ?? [];
  const campaignPodcasts = campaign.campaignPodcasts ?? [];

  const activeDynamics = campaignDynamics.filter(
    (cd) => !cd.isBaseline && (cd.downloadTotal ?? 0) > 0
  );
  const activeStreamings = campaignStreamings.filter(
    (cs) => !cs.isBaseline && (cs.downloadTotal ?? 0) > 0
  );
  const activeCampaignPodcasts = campaignPodcasts.filter(
    (cp) => (cp?.downloadTotal ?? 0) > 0
  );

  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <CampaignAdPlacementsTable
        organization={organization}
        campaign={campaign}
        campaignPodcasts={activeCampaignPodcasts}
        campaignDynamics={activeDynamics}
        campaignStreamings={activeStreamings}
        displayIngestDynamicAdsButton
      />
    </>
  );
}
