import {createOrgPath} from '@core/lib/organizations';
import {Nav} from '@core/ui/Nav';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';

const campaignLinkURL = (organization, campaign, campaignLink) =>
  createOrgPath(
    organization,
    `/campaigns/${campaign.slug}/links/${campaignLink.id}`
  );

const CampaignLinkNav = ({organization, campaign, campaignLink}) => {
  return (
    <Nav
      title={campaignLink.name}
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/measure/campaigns'),
          title: 'Campaigns',
        },
        {
          to: toCampaignUrl(organization, campaign),
          title: campaign.name,
        },
        {
          title: 'pod.link',
        },
      ]}
    />
  );
};

export {campaignLinkURL};

export default CampaignLinkNav;
