import React from 'react';
import {Element} from '@core/style';

const colors = {
  dotcomslash: ['0, 0, 255'],
  'brought-to-you-by': ['255, 0 ,0'],
  brought_to_you_by: ['0, 0, 255'],
  dot_com_slash: ['0, 0, 255'],
  sponsor: ['0, 0, 255'],
  sponsored: ['0, 0, 255'],
};

const getKeywordColor = (keyword) => {
  if (colors.hasOwnProperty(keyword)) {
    return `rgba(${colors[keyword]}, 0.2)`;
  }
  return `rgba(255, 0, 0, 0.2)`;
};

class Bar extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      keyword,
      timestamp,
      confidence,
      height,
      duration,
      keywordSliceWindow,
    } = this.props;

    const barHeight = height * 1;
    const barMargin = height - barHeight;

    // TODO need to make this like an arrow pointing at the timeline
    // or something, since it's just a single point in time not a range
    const start = timestamp - keywordSliceWindow;
    const end = timestamp + keywordSliceWindow;

    const left = (start / duration) * 100;
    const width = ((end - start) / duration) * 100;

    const style = {
      position: 'absolute',
      height: `${barHeight}px`,
      width: `${width}%`,
      left: `${left}%`,
      marginTop: `${barMargin}px`,
      background: getKeywordColor(keyword),
    };

    return <div style={style} />;
  }
}

class AudioKeywords extends React.Component {
  render() {
    const {height, keywords, duration, keywordSliceWindow} = this.props;

    if (!keywords || !keywords.length) {
      return '';
    }

    return (
      <Element
        rules={() => ({position: 'relative', background: '#fff'})}
        domRef={(elem) => {
          this.elem = elem;
        }}>
        <Element>
          {keywords.map(({id, keyword, timestamp, confidence, version}) => {
            return (
              <Bar
                keyword={keyword}
                timestamp={timestamp}
                confidence={confidence}
                height={height}
                duration={duration}
                key={id}
                keywordSliceWindow={keywordSliceWindow}
              />
            );
          })}
        </Element>
      </Element>
    );
  }
}

export default AudioKeywords;
