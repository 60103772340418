import React, {FC} from 'react';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';

interface InsightsPerformanceTableProps {
  data: any[];
  series: any[];
}

const InsightsPerformanceTable: FC<InsightsPerformanceTableProps> = ({
  data,
  series,
}): JSX.Element => {
  const columns: DataTableColumn[] = [
    {
      title: 'Campaign',
      accessor: 'campaignName',
      type: 'string',
      rule: () => ({minWidth: '12rem'}),
    },
    {
      title: 'Line Item',
      accessor: 'lineItemName',
      type: 'string',
      rule: () => ({minWidth: '12rem', wordBreak: 'break-word'}),
      Cell: ({d: {campaignDynamicId, lineItemName, podcastTitle}}) => {
        if (campaignDynamicId === 'embedded') {
          return (
            <>
              <div>{lineItemName}</div>
              <div css={{color: 'var(--text-muted)', fontSize: '0.8125rem'}}>
                {podcastTitle}
              </div>
            </>
          );
        }
        return <>{lineItemName}</>;
      },
    },
    {
      title: 'Publisher',
      accessor: 'publisherName',
      type: 'String',
    },
    ...series.map((obj) =>
      Object.assign({rule: () => ({whiteSpace: 'nowrap'})}, obj)
    ),
  ];

  return (
    <DataTable
      data={data}
      columns={columns}
      searchKeys={[
        'campaignName',
        'publisherName',
        'lineItemName',
        'podcastTitle',
      ]}
      searchPlaceholder='Search'
      orderBy='-impressions'
      emptyMessageText='No data found.'
    />
  );
};

export default InsightsPerformanceTable;
