import {gql} from '@apollo/client';
import CampaignStreamingStreamFragment from './CampaignStreamingStreamFragment';

export default gql`
  fragment CampaignStreamingOld on CampaignStreamingObject {
    id
    name
    isActive

    source

    discountCode
    discountUrl
    effectiveUrl

    duration
    goal
    cost
    costToDate

    verifiedReach

    downloadTotal
    reachTotal
    attributionTotal
    userTotal
    userTotalModeled
    productTotal
    productTotalModeled
    addtocartTotal
    addtocartTotalModeled
    checkoutTotal
    checkoutTotalModeled
    purchaseTotal
    purchaseTotalModeled
    leadTotal
    leadTotalModeled
    valueTotal
    valueTotalModeled
    installedTotal
    installedTotalModeled

    targetDownloadTotal
    targetReachTotal

    startAt
    endAt
    providerName
    providerTrackingUrl

    deliveries {
      id
      viewedBy {
        id
        firstName
        lastName
        email
      }
      viewedAt
    }

    isBaseline

    campaignStreamingStreams {
      ...CampaignStreamingStream
    }

    createdAt
    updatedAt
  }
  ${CampaignStreamingStreamFragment}
`;
