import Badge from '@core/ui/Badge';

const BADGE_PROPS_MAP = {
  REQUESTED: {
    color: 'default',
    variant: 'outlined',
  },
  RUNNING: {
    color: 'warning',
    variant: 'outlined',
  },
  DRAFT: {
    color: 'warning',
    variant: 'filled',
  },
  LIVE: {
    showLiveDot: true,
    color: 'success',
    variant: 'filled',
  },
  DEFAULT: {
    color: 'default',
    variant: 'outlined',
  },
};

const LiftStateBadge = ({state}: {state: string}) => (
  <Badge {...(BADGE_PROPS_MAP[state] || BADGE_PROPS_MAP.DEFAULT)}>
    {state}
  </Badge>
);

export default LiftStateBadge;
