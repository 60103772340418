import dayjs from '@core/lib/dayjs';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Link} from '@core/ui/Link';
import {
  CampaignNoiseObject,
  CampaignObject,
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {campaignLiftURL} from '@analytics/screens/unified/campaigns/campaign/lift/_routes';

type CampaignNoisesTableProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign?: CampaignObject;
  campaignNoises: CampaignNoiseObject[];
};

export default function CampaignNoisesTable({
  organization,
  campaign,
  campaignNoises,
}: CampaignNoisesTableProps) {
  const columns: DataTableColumn<CampaignNoiseObject>[] = [
    {
      title: 'Noise',
      accessor: 'name',
      type: 'string',
      Cell: ({d}) => (
        <Link
          to={`${campaignLiftURL(
            organization,
            campaign ?? d.campaign
          )}/households/${d.id}`}>
          {d.name
            ? d.name
            : `${dayjs(d.startAt).format('YYYY-MM-DD')} -
                  ${dayjs(d.endAt).format('YYYY-MM-DD')}`}
        </Link>
      ),
    },
    !campaign
      ? {
          title: 'Campaign',
          accessor: 'campaign',
          type: 'string',
          getValue: ({d}) => d.campaign.name ?? '',
        }
      : {},
    {
      title: 'Start Date',
      accessor: 'startAt',
      type: 'date',
      fmt: (startAt) => dayjs(startAt).format('MMM DD, YYYY'),
    },
    {
      title: 'End Date',
      accessor: 'endAt',
      type: 'date',
      fmt: (endAt) => dayjs(endAt).format('MMM DD, YYYY'),
    },
    {
      title: 'Target Impressions',
      accessor: 'targetImpressions',
      type: 'number',
    },
  ];

  return (
    <DataTable columns={columns} data={campaignNoises} orderBy='-runDate' />
  );
}
