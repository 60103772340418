import {exists} from '@core/lib/utils';
import {SortableList} from '@core/ui/SortableList';
import {AdvisorColumnsDropdownColumnsListProps} from '.';
import {AdvisorColumn} from '../types';
import {ListSortIcon} from './ListSortIcon';
import {SelectedListItem} from './SelectedListItem';
import {UnselectedListItem} from './UnselectedListItem';

interface Props {
  columnOptions: AdvisorColumn[];
  selections: AdvisorColumn[];
  setSelections: React.Dispatch<React.SetStateAction<AdvisorColumn[]>>;
  onChange: AdvisorColumnsDropdownColumnsListProps['onChange'];
  sortable: boolean;
  searchValue: string;
}
export function ColumnOptionsList({
  columnOptions,
  selections,
  setSelections,
  onChange,
  sortable,
  searchValue,
}: Props) {
  function selection(item: {id: string}) {
    return selections.find(({id}) => id === item.id);
  }

  function updateSelections(sels: AdvisorColumn[]) {
    setSelections(sels);
    onChange(sels);
  }

  return (
    <SortableList
      active={!!sortable && !searchValue}
      onSort={(ids) =>
        updateSelections(ids.map((id) => selection({id: id})).filter(exists))
      }
      css={`
        margin: 0 0.375rem;
      `}>
      {columnOptions.map((col) => {
        const selected = selection(col);

        return (
          <div
            data-sortable-item
            data-id={col.id}
            key={col.id}
            css={{
              alignItems: 'center',
              backgroundColor: 'var(--bg-overlay)',
              borderRadius: '0.375rem',
              display: 'grid',
              gridGap: '0.5rem',
              gridTemplateColumns: sortable
                ? 'max-content 1fr max-content'
                : '1fr max-content',
              padding: '0.375rem 0.75rem',
              ':hover': {
                background: 'var(--bg-default-hover)',
              },
            }}>
            {sortable && <ListSortIcon searchValue={searchValue} />}
            <div css={{lineHeight: 1.5}}>{col.title}</div>
            {selected ? (
              <SelectedListItem
                item={col}
                onRemoveColumn={() =>
                  updateSelections(
                    selections.filter(
                      (selection) => selection.id !== selected.id
                    )
                  )
                }
              />
            ) : (
              <UnselectedListItem
                item={col}
                onAddColumn={() => updateSelections([...selections, col])}
              />
            )}
          </div>
        );
      })}
    </SortableList>
  );
}
