import {gql} from '@apollo/client';

export default gql`
  fragment UserAccess on UserAccessObject {
    id
    permission
    permissionDisplay
    objectPk
    appLabel
    model
  }
`;
