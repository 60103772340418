import {useQuery} from '@apollo/client';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import ReviewersEpisodesTable from '@analytics/components/research/ReviewersEpisodesTable';
import ReviewerTranscribableQuery from '@analytics/graphql-api/_old_queries/reviewers/ReviewerTranscribableQuery';

const ReviewersTranscribable = ({
  organization,
  match: {
    params: {after, before},
  },
}) => {
  const {loading, error, data} = useQuery(ReviewerTranscribableQuery, {
    variables: {
      organizationId: organization.id,
      after,
      before,
    },
  });

  if (loading) {
    return <Loading centered />;
  }

  if (error) {
    console.error(error);
  }

  const {transcribable} = data.me.organization.research.reviewing;

  return (
    <Card>
      {transcribable.length ? (
        <CardHeader>Episodes to review ({transcribable.length})</CardHeader>
      ) : null}
      <ReviewersEpisodesTable
        episodes={transcribable}
        organization={organization}
        emptyMessageText='No episodes to review.'
      />
    </Card>
  );
};

export default ReviewersTranscribable;
