import {FormHelpText} from '@spotify-internal/encore-web';
import {Control} from 'react-hook-form';
import {FormHelp, Label} from '@core/ui/FormElements';
import {SwitchControlled} from '@core/ui/Switch/SwitchControlled';
import {CampaignFormData} from '../_types';
import {FormGroup} from './_shared';

export function CampaignAgencyAccessField({
  control,
}: {
  control: Control<CampaignFormData>;
}) {
  return (
    <FormGroup>
      <Label htmlFor='agencyAccess'>Hidden From Agency</Label>
      <FormHelpText>
        If a brand works with an agency, the agency has access to every campaign
        by default. Toggle this switch on if you do not want the agency to have
        access to view this campaign.
      </FormHelpText>
      <div
        css={`
          margin: 10px 0 0 8px;
        `}>
        <SwitchControlled
          name='isHiddenFromAgency'
          id='agencyAccess'
          control={control}
          label={undefined}
        />
      </div>
    </FormGroup>
  );
}
