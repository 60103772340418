import {Dispatch, SetStateAction} from 'react';
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {comma} from '@core/lib/filters';
import {NavPills} from '@core/ui/NavPills';
import {LiftGroupSerie, LiftWeekData} from '../../types';
import {LiftGroupLegend} from './LiftGroupLegend';
import {LiftGroupTooltip} from './LiftGroupTooltip';

const series = [
  {label: 'Views', value: 'views', color: 'var(--green)'},
  {label: 'Purchases', value: 'purchases', color: 'var(--blue)'},
  {label: 'Leads', value: 'leads', color: 'var(--pink)'},
  {label: 'Installs', value: 'installs', color: 'var(--red-orange)'},
] as const;

export function LiftGroupChart({
  weekData,
  activeSerie,
  setActiveSerie,
}: {
  weekData: LiftWeekData[];
  activeSerie: LiftGroupSerie;
  setActiveSerie: Dispatch<SetStateAction<LiftGroupSerie>>;
}) {
  return (
    <div
      css={{
        fontSize: '.875rem',
      }}>
      <NavPills
        options={series}
        onChange={setActiveSerie}
        css={`
          padding: var(--spacing-4) var(--spacing-2);
        `}
      />
      <ResponsiveContainer width='100%' height={250}>
        <ComposedChart data={weekData}>
          <XAxis dataKey='week' scale='band' />
          <YAxis tickFormatter={(v) => comma(v, 2)} />
          <Tooltip content={<LiftGroupTooltip activeSerie={activeSerie} />} />
          <Legend content={LiftGroupLegend} />
          {activeSerie === 'views' && [
            <Bar
              key='baselineInit'
              dataKey='baseline_initValue'
              fill='var(--green-40)'
              name='Baseline Views Per Week'
            />,
            <Bar
              key='exposedInit'
              dataKey='exposed_initValue'
              fill='var(--green)'
              name='Exposed Views Per Week'
            />,
            <Line
              key='averageInit'
              dataKey='baseline_initValue'
              name='View Baseline'
              strokeWidth={2}
              stroke='var(--gray-6)'
              strokeDasharray='4 4'
              dot={false}
            />,
          ]}
          {activeSerie === 'purchases' && [
            <Bar
              key='baselinePurchase'
              dataKey='baseline_purchaseValue'
              fill='var(--blue-40)'
              name='Baseline Purchases Per Week'
            />,
            <Bar
              key='exposedPurchase'
              dataKey='exposed_purchaseValue'
              fill='var(--blue)'
              name='Exposed Purchases Per Week'
            />,
            <Line
              key='averagePurchase'
              dataKey='baseline_purchaseValue'
              name='Purchase Baseline'
              strokeWidth={2}
              stroke='var(--gray-6)'
              strokeDasharray='4 4'
              dot={false}
            />,
          ]}
          {activeSerie === 'leads' && [
            <Bar
              key='baselineLeads'
              dataKey='baseline_leadValue'
              fill='var(--pink-40)'
              name='Baseline Leads Per Week'
            />,
            <Bar
              key='exposedLeads'
              dataKey='exposed_leadValue'
              fill='var(--pink)'
              name='Exposed Leads Per Week'
            />,
            <Line
              key='averageLeads'
              dataKey='baseline_leadValue'
              name='Lead Baseline'
              strokeWidth={2}
              stroke='var(--gray-6)'
              strokeDasharray='4 4'
              dot={false}
            />,
          ]}
          {activeSerie === 'installs' && [
            <Bar
              key='baselineInstalled'
              dataKey='baseline_installedValue'
              fill='var(--red-orange-40)'
              name='Baseline Installs Per Week'
            />,
            <Bar
              key='exposedInstalled'
              dataKey='exposed_installedValue'
              fill='var(--red-orange)'
              name='Exposed Installs Per Week'
            />,
            <Line
              key='averageInstalled'
              dataKey='baseline_installedValue'
              name='Installed Baseline'
              strokeWidth={2}
              stroke='var(--gray-6)'
              strokeDasharray='4 4'
              dot={false}
            />,
          ]}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
