import React from 'react';
import {MiniPlayer} from '@core/components/AudioMiniPlayer';
import {createOrgPath} from '@core/lib/organizations';
import {toTimeString} from '@core/lib/time';
import {Element} from '@core/style';
import {DropdownMenu} from '@core/ui/DropdownMenu';
import {Icon} from '@core/ui/Icon';
import AdCopy from '@analytics/components/ads/AdCopy';

const Placement = ({
  organization,
  feed,
  episode,
  placement: {startTime, endTime, ad},
}) => {
  const {company} = ad;

  return (
    <>
      {company ? (
        <Element
          rules={() => ({
            display: 'flex',
            padding: '0 10px',
            fontSize: '14px',
            alignItems: 'center',
          })}>
          <Element
            tag='b'
            rules={({theme}) => ({flex: 1, color: theme.textSecondary})}>
            {company.name}
          </Element>
          <DropdownMenu
            items={[
              {
                to: createOrgPath(
                  organization,
                  `/research/brands/${company.id}/ads/${ad.id}/download`
                ),
                title: `Download Ad`,
              },
              {
                to: createOrgPath(
                  organization,
                  `/research/brands/${company.id}`
                ),
                title: `View ${company.name} in Research`,
              },
              {
                to: createOrgPath(
                  organization,
                  `/research/podcasts/${feed.id}/episodes/${episode.id}`
                ),
                title: `View Episode in Research`,
              },
            ]}
            overlayProps={{
              horizontalAlign: 'left',
            }}
            css={`
              margin-left: auto;
              color: var(--text-muted);
            `}>
            <Icon icon='more' />
          </DropdownMenu>
        </Element>
      ) : (
        ''
      )}
      <Element
        rules={({theme}) => ({
          alignItems: 'center',
          color: theme.textTertiary,
          display: 'grid',
          fontSize: '0.8125rem',
          fontWeight: 500,
          gridGap: '1rem',
          gridTemplateColumns: 'max-content 1fr max-content',
          margin: '1rem 0',
        })}>
        <div>
          {toTimeString(startTime)} - {toTimeString(endTime)}
        </div>
        <MiniPlayer enclosure={ad} />
        <div>{toTimeString(ad.duration)}</div>
      </Element>
      <AdCopy text={ad.text} />
    </>
  );
};

export default Placement;
