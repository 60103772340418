import {Switch} from 'react-router-dom';
import useRoutes from '@core/lib/useRoutes';
import {CampaignRouteProps} from '../_routes';
import {CampaignPlacements} from './CampaignPlacements';

export function CampaignPlacementsIndex({
  user,
  organization,
  campaign,
  match: {url},
}: CampaignRouteProps & {
  match: {url: string};
}) {
  const {renderRoutes} = useRoutes({
    baseUrl: url,
    basePath: '/:part(b|a|s|p)/:slug/campaigns/:campaignSlug',
    routes: [{key: 'placements', path: 'placements', exact: true}],
  });

  return (
    <Switch>
      {renderRoutes({
        components: {
          placements: CampaignPlacements,
        },
        extraProps: {
          user,
          organization,
          campaign,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
