import {gql} from '@apollo/client';

export default gql`
  query getEpisode($organizationId: ID!, $feedId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          feed(id: $feedId) {
            id
            title
            subtitle
            summary
            description(words: 50)
            image {
              id
              href
              path
            }

            episode(id: $id) {
              id
              title
              subtitle
              summary
              description(words: 50)
              published
              processed
              reviewed
              hasNoAds
              image {
                id
                href
                path
              }
              feed {
                id
                title
                image {
                  id
                  href
                  path
                }
              }

              enclosure {
                id
                episodeId
                href
                type
                duration
                length
                mpegPath
                waveform
              }

              placementsCount
              placements {
                id
                startTime
                endTime
                ad {
                  id
                  mpegPath
                  flacPath
                  waveform
                  words
                  transcript
                  duration
                  state

                  company {
                    id
                    name
                    slug
                  }
                  product {
                    id
                    name
                  }

                  sponsor {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
