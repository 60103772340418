import { TypeListItem } from "../TypeListItem/index.js";
import styled from "../../styled-components.js";
export var Item = styled(TypeListItem).attrs({
  variant: 'viola',
  semanticColor: 'textSubdued',
  condensed: true,
  listStyleReset: true
}).withConfig({
  displayName: "Item",
  componentId: "sc-qngghw-0"
})(["display:block;margin-block-end:0;"]);