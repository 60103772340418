import {useMemo} from 'react';
import {BLUE, GREEN, HIGHLIGHTER_40} from '@core/style';
import {IconButton} from '@core/ui/Button/LegacyButton';
import DataTable from '@core/ui/DataTable';
import {Icon} from '@core/ui/Icon';
import Toggle from '@core/ui/Toggle';
import {findEditionEpisode, formatDate} from './helpers';

const BulkEpisodesAddedTable = ({
  campaignEpisodes,
  edition,
  onRemoveEpisode,
  onUpdateEpisode,
}) => {
  const columns = useMemo(
    () => [
      {
        title: 'Baseline',
        accessor: 'isBaseline',
        type: 'boolean',
        rule: () => ({minWidth: '7rem'}),
        Cell: ({data: episode}) => {
          const match = findEditionEpisode(episode, edition);
          const isBaseline =
            match && typeof match.isBaseline === 'boolean'
              ? match.isBaseline
              : episode.isBaseline;

          return (
            <Toggle
              checkedColor={BLUE}
              uncheckedColor={GREEN}
              defaultChecked={isBaseline}
              height={12}
              onChecked={(value) =>
                onUpdateEpisode({episode, isBaseline: value})
              }
              style={{fontWeight: 500}}>
              {isBaseline ? 'Baseline' : 'Exposed'}
            </Toggle>
          );
        },
      },
      {
        title: 'Episode',
        accessor: 'title',
        type: 'string',
      },
      {
        title: 'Date',
        accessor: 'published',
        type: 'date',
        fmt: formatDate,
      },
      {
        Cell: ({data: episode}) => (
          <IconButton onClick={() => onRemoveEpisode({episode})}>
            <Icon
              icon='close-circle-outline'
              rules={() => ({
                height: '1.5rem',
                width: '1.5rem',
              })}
            />
          </IconButton>
        ),
      },
    ],
    [onRemoveEpisode, onUpdateEpisode, edition]
  );
  // I need to know that the episode is not

  const data = useMemo(
    () =>
      campaignEpisodes
        // Remove removes
        .filter((episode) => !findEditionEpisode(episode, edition, ['removes']))
        // Add creates
        .concat(edition.creates.map(({episode}) => episode))
        // UpcomingEpisodes have expectedPublished instead of published
        .map((episode) => {
          episode.published = episode.published || episode.expectedPublished;
          return episode;
        }),
    [edition, campaignEpisodes]
  );

  return (
    <DataTable
      data={data}
      columns={columns}
      pagination={false}
      emptyMessageText='No added episodes.'
      rowRuleFn={({data: episode, theme}) => {
        const match = findEditionEpisode(episode, edition);

        if (match) {
          return {
            background: HIGHLIGHTER_40,
            color: theme.name === 'dark' ? theme.gray7 : 'inherit',
            ':hover': {
              background: HIGHLIGHTER_40,
            },
          };
        }
        return {};
      }}
    />
  );
};

export default BulkEpisodesAddedTable;
