import {toPercent} from '@core/lib/filters';
import DataTable from '@core/ui/DataTable/DataTable';
import {PublisherAffinityScoreObject} from '@analytics/graphql-api';

export const PublisherAffinityTable = ({
  affinityData,
}: {
  affinityData: PublisherAffinityScoreObject[];
}) => {
  return (
    <DataTable
      orderBy='rank'
      columns={[
        {
          title: 'Rank',
          accessor: 'rank',
          type: 'number',
        },
        {
          title: 'Industry',
          accessor: 'industry',
          type: 'string',
          longDescription:
            'This is an ordered list of the industries your podcast performs best against',
        },
        {
          title: 'Percentile',
          accessor: 'percentile',
          longDescription:
            'This is the percentile your show ranks in when compared to all other podcasts across all networks',
          fmt: (val) => toPercent(val / 100, 2),
          type: 'number',
        },
      ]}
      paginationRowsPerPage={5}
      data={affinityData}
    />
  );
};
