import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Redirect} from 'react-router-dom';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import PixelEventForm from '@analytics/components/pixels/PixelEventForm';
import CREATE_PIXEL_EVENT_RECORD from '@analytics/graphql-api/_old_mutations/CreatePixelEventRecord';
import ORGANIZATION_KEY_QUERY from '@analytics/graphql-api/_old_queries/OrganizationKeyQuery';
import {ManagePixelsPixelEventsRouteProps} from '.';

export function PixelAddEvent({
  backPath,
  pixel,
}: ManagePixelsPixelEventsRouteProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [complete, setCompleted] = useState(false);
  const [createPixelEventRecord] = useMutation(CREATE_PIXEL_EVENT_RECORD, {
    refetchQueries: [ORGANIZATION_KEY_QUERY],
    awaitRefetchQueries: true,
  });

  const onSubmit = async ({
    name,
    eventType,
    installation,
    type,
    currency,
    discountCode,
    newCustomer,
    orderId,
    value,

    quantity,
    productId,
    productName,
    productType,
    productVendor,
    variantId,
    variantName,
  }) => {
    setError(false);
    setLoading(true);
    try {
      const data = await createPixelEventRecord({
        variables: {
          input: {
            key: pixel.key,
            name,
            eventType,
            installation,
            type,
            currency,
            discountCode,
            newCustomer,
            orderId,
            value,
            category: eventType === 'LEAD' ? name : '',

            quantity,
            productId,
            productName,
            productType,
            productVendor,
            variantId,
            variantName,
          },
        },
      });

      if (data) {
        setCompleted(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const onCancel = () => {
    setError(false);
    setCompleted(true);
  };

  if (complete && !error) {
    return <Redirect to={backPath} />;
  }

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={backPath}>Add New Event</CardHeader>
      <Card>
        {error && (
          <ToastMessage
            rules={() => ({margin: '0 0 1.5rem'})}
            alertType='error'>
            Network Error. Please Try again.
          </ToastMessage>
        )}
        <PixelEventForm
          pixel={pixel}
          onCancel={onCancel}
          onSubmit={onSubmit}
          loading={loading}
        />
      </Card>
    </ResponsiveContent>
  );
}
