import {gql} from '@apollo/client';
import PodlinkDomainFragment from '../_old_queries/fragments/PodlinkDomainFragment';

export default gql`
  mutation updatePodlinkDomain($input: UpdatePodlinkDomainInput!) {
    authed {
      success
    }
    updatePodlinkDomain(input: $input) {
      podlinkDomain {
        ...PodlinkDomain
      }
    }
  }
  ${PodlinkDomainFragment}
`;
