import {useState} from 'react';
import {gtag} from '@core/lib/gtag';
import DataTable from '@core/ui/DataTable';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import Select from '@core/ui/Select';
import {
  FetchedOrganization,
  usePodcastsAudienceQuery,
} from '@analytics/graphql-api';
import getColumns from './AudienceTableColumns';
import getAudienceTableData from './helpers_tableData';

type Props = {
  organization: FetchedOrganization;
};

export default function PodcastsAudienceTable({organization}: Props) {
  const [dataType, setDataType] = useState<'nielsen' | 'experian'>('nielsen');
  const {data, loading, error} = usePodcastsAudienceQuery({
    variables: {
      id: organization.id,
    },
  });

  if (loading) return <Loading centered />;
  if (error) return <EmptyMessage>Podcasts unavailable.</EmptyMessage>;

  const [experianData, nielsenData] = getAudienceTableData(
    data?.me?.organization.podcasts,
    organization
  );

  return (
    <>
      <div
        css={{
          position: 'absolute',
          right: '1.375rem',
          top: '4.25rem',
          zIndex: 10000,
        }}>
        <Select
          small
          outline
          defaultValue='nielsen'
          items={[
            {title: 'Experian', value: 'experian'},
            {title: 'Nielsen', value: 'nielsen'},
          ]}
          onSelect={({value}) => {
            setDataType(value);
            gtag('event', value, {
              event_category: 'podcasts_audience_intel',
            });
          }}
        />
      </div>
      <DataTable
        searchKeys={['title']}
        columns={getColumns(dataType)}
        orderBy='-AgeMax'
        data={dataType === 'experian' ? experianData : nielsenData}
        searchPlaceholder='Search podcast'
        emptyMessageText='No podcasts found.'
        noHeader
        withSortFilter
        paginationRowsPerPage={10}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        onSortByChange={(column) => {
          gtag('event', 'audience_intel_sort_by', {
            event_category: 'podcasts_audience_intel',
            event_label: column,
          });
        }}
      />
    </>
  );
}
