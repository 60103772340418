import {graphql} from '@apollo/client/react/hoc';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import {
  FetchedCampaignDynamicEpisode,
  GetCampaignDynamicEpisodeQuery,
} from '@analytics/graphql-api';
import CampaignDynamicEpisodeQuery from '@analytics/graphql-api/_old_queries/CampaignDynamicEpisodeQuery';
import {CampaignDynamicPodcastRouteProps} from '../..';
import {CampaignDynamicEpisode} from './CampaignDynamicEpisode';

export type CampaignDynamicPodcastEpisodeRouteProps =
  CampaignDynamicPodcastRouteProps & {
    campaignDynamicEpisode: FetchedCampaignDynamicEpisode;
  };

const CampaignDynamicEpisodeIndex = ({
  user,
  organization,
  campaign,
  campaignDynamic,
  campaignDynamicPodcast,
  data: {loading, me},
}: CampaignDynamicPodcastRouteProps & {
  data: {
    loading: boolean;
    me: GetCampaignDynamicEpisodeQuery['me'];
  };
}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const campaignDynamicEpisode =
    me?.organization.campaign.campaignDynamic?.campaignDynamicPodcast
      ?.campaignDynamicEpisode;

  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/dynamics/:campaignDynamicId/podcasts/:campaignDynamicPodcastId/episodes/:campaignDynamicEpisodeId'
        campaign={campaign}
        user={user}
        organization={organization}
        campaignDynamic={campaignDynamic}
        campaignDynamicPodcast={campaignDynamicPodcast}
        campaignDynamicEpisode={campaignDynamicEpisode}
        component={CampaignDynamicEpisode}
      />
    </Switch>
  );
};

export default graphql(CampaignDynamicEpisodeQuery, {
  options: ({
    organization,
    campaign,
    campaignDynamic,
    campaignDynamicPodcast,
    match: {
      params: {campaignDynamicEpisodeId},
    },
  }) => {
    return {
      variables: {
        organizationId: organization.id,
        campaignId: campaign.id,
        campaignDynamicId: campaignDynamic.id,
        campaignDynamicPodcastId: campaignDynamicPodcast.id,
        id: campaignDynamicEpisodeId,
      },
    };
  },
})(CampaignDynamicEpisodeIndex);
