import {useMemo} from 'react';
import {createOrgPath} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Stack} from '@core/ui/Layout';
import {Link} from '@core/ui/Link';
import {PodlinkTable} from '@analytics/components/podlink/PodlinkTable';
import {ManagePodlinkRouteProps} from '.';

export function Podlink({
  organization,
  podlinkDomains,
  podcasts,
  match: {url},
}: ManagePodlinkRouteProps & {
  match: {
    url: string;
  };
}) {
  const columns: DataTableColumn[] = useMemo(
    () => [
      {
        title: 'Title',
        accessor: 'title',
        type: 'string',
      },
      {
        title: 'Podlink URL',
        accessor: 'slug',
        type: 'string',
        Cell: ({d: {url}}) => (
          <Link href={url} target='_blank'>
            {url}
          </Link>
        ),
      },
      {
        accessor: 'id',
        type: 'string',
        Cell: ({d: {slug}}) => (
          <Stack alignItems='center' gap='var(--spacing-2)'>
            <Button
              buttonSize='sm'
              to={createOrgPath(organization, `manage/podlink/${slug}`)}
              variant='outlined'>
              Manage
            </Button>
            <Button
              buttonSize='sm'
              to={createOrgPath(organization, `manage/podlink/${slug}/design`)}
              variant='outlined'>
              Customize
            </Button>
          </Stack>
        ),
      },
    ],
    [organization]
  );

  const data = podlinkDomains.map(({id, slug, title}) => ({
    id,
    title,
    slug,
    url: `https://${slug}.pod.link`,
  }));

  return (
    <>
      <Card>
        <DataTable
          searchKeys={['title']}
          columns={columns}
          orderBy='-title'
          data={data}
          searchPlaceholder='Search Subdomain'
          emptyMessageText='No Subdomains found.'
          headerRenderer={({filtersContent, searchContent}) => (
            <Stack direction='column' gap={2}>
              <Stack justifyContent='space-between' alignItems='center'>
                <CardHeader
                  css={`
                    margin: 0;
                  `}>
                  Podlink
                </CardHeader>
                <Button color='secondary' to={`${url}/create`}>
                  Add a Subdomain
                </Button>
              </Stack>
              <Stack alignItems='center'>
                {searchContent}
                {filtersContent}
              </Stack>
            </Stack>
          )}
        />
      </Card>
      <Card>
        <CardHeader>Podcasts</CardHeader>
        <PodlinkTable organization={organization} podcasts={podcasts} />
      </Card>
    </>
  );
}
