import {gql} from '@apollo/client';
import UserAccessFragment from '@analytics/graphql-api/_old_queries/fragments/UserAccessFragment';

export default gql`
  query getUserAccessDetail($organizationId: ID!, $membershipId: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id

        member(membershipId: $membershipId) {
          id
          firstName
          lastName
          email
        }

        memberAccess(membershipId: $membershipId) {
          ...UserAccess
        }

        campaigns {
          results {
            id
            name
            advertiser {
              id
              name
            }
          }
        }
      }
    }
  }
  ${UserAccessFragment}
`;
