import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignRouteProps} from '../_routes';
import {CampaignLinks} from './CampaignLinks';
import {CampaignCreateLinkIndex} from './create';
import CampaignLinkIndex from './link';

export function CampaignLinksIndex({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/links'
        user={user}
        organization={organization}
        campaign={campaign}
        component={CampaignLinks}
      />
      <PropsRoute
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/links/create'
        user={user}
        organization={organization}
        campaign={campaign}
        component={CampaignCreateLinkIndex}
      />
      <PropsRoute
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/links/:campaignLinkId'
        user={user}
        organization={organization}
        campaign={campaign}
        component={CampaignLinkIndex}
      />
    </Switch>
  );
}
