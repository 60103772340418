import {LegendProps} from 'recharts';
import {Stack} from '@core/ui/Layout';

export function LiftGroupLegend({payload}: LegendProps) {
  return (
    <div
      css={{
        fontWeight: 'var(--font-weight-medium)',
        textAlign: 'center',
      }}>
      Week from Ad exposure
      <Stack
        alignItems='center'
        justifyContent='center'
        css={{marginTop: '0.625rem'}}>
        {payload?.map(({value, color}) => (
          <Stack
            key={value}
            alignItems='center'
            css={{
              color,
              fontWeight: 'var(--font-weight-regular)',
              margin: '0 0.625rem',
            }}>
            {value.toLowerCase().indexOf('baseline') > 1 ? (
              <svg
                className='recharts-surface'
                width='14'
                height='14'
                viewBox='0 0 32 32'
                version='1.1'>
                <path
                  strokeWidth='4'
                  fill='none'
                  stroke='#585858'
                  d='M0,16h10.666666666666666A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16H32M21.333333333333332,16A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16'
                />
              </svg>
            ) : (
              <div
                css={{
                  background: color,
                  borderRadius: '0.125rem',
                  height: '0.75rem',
                  width: '0.75rem',
                }}
              />
            )}
            <div css={{marginLeft: '0.375rem'}}>{value}</div>
          </Stack>
        ))}
      </Stack>
    </div>
  );
}
