import {createOrgPath, isSelling} from '@core/lib/organizations';
import {
  DropdownMenuDivider,
  DropdownMenuItemType,
  DropdownMenuTitle,
} from '@core/ui/DropdownMenu';
import {Nav, NavProps} from '@core/ui/Nav';
import {useOverlay} from '@core/ui/Overlay';
import ExportOverlay from '@analytics/components/ExportOverlay';
import {
  FetchedBuySideOrganization,
  FetchedIncrementalLiftReports,
  FetchedOrganization,
} from '@analytics/graphql-api';

export const measureLiftReportUrl = (
  organization: FetchedOrganization | FetchedBuySideOrganization,
  liftReport: FetchedIncrementalLiftReports[number]
) =>
  createOrgPath(
    organization,
    `measure/lift/incremental/reports/${liftReport.id}`
  );

interface Props extends NavProps {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  liftReport: FetchedIncrementalLiftReports[number];
  isAdmin: boolean;
}

export const MeasureLiftReportNav = ({
  organization,
  liftReport,
  isAdmin,
  ...props
}: Props) => {
  const [openedExport, toggleExport] = useOverlay();
  const url = measureLiftReportUrl(organization, liftReport);
  return (
    <>
      <Nav
        title={liftReport.name ?? liftReport.campaign?.name ?? 'Lift Report'}
        description={liftReport.clampUrl ? liftReport.clampUrl : ''}
        breadcrumbs={[
          {
            to: createOrgPath(organization, 'measure/lift'),
            title: 'Lift Reports',
          },
          {
            title: 'Lift Report',
          },
        ]}
        moreDropdownItems={[
          {
            to: `${url}/delete`,
            title: 'Delete',
            isVisible: () => isSelling(organization) || isAdmin,
          },
          ...(isAdmin
            ? ([
                {content: <DropdownMenuDivider />},
                {content: <DropdownMenuTitle>Admin Only:</DropdownMenuTitle>},
                {
                  to: `${url}/update`,
                  title: 'Update Report',
                },
                {
                  to: `${url}/run`,
                  title: 'Run Report',
                },
                {
                  title: 'Export',
                  callback: () => toggleExport(true),
                  isVisible: () => liftReport.state !== 'REQUESTED',
                },
              ] satisfies DropdownMenuItemType[])
            : []),
        ]}
        {...props}
      />
      <ExportOverlay
        opened={openedExport}
        toggle={toggleExport}
        organizationId={organization.id}
        pk={liftReport.id}
        kind='report'
        objectType='lift'
        params={{
          after: 0,
          before: liftReport.delta,
        }}
      />
    </>
  );
};
