import {gql} from '@apollo/client';

export default gql`
  query getFutureEpisodes($organizationId: ID!, $podcastId: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        podcast(id: $podcastId) {
          id
          futureEpisodes {
            id
            title
            subtitle
            expectedPublished
          }
        }
      }
    }
  }
`;
