import {useMemo} from 'react';
import dayjs from '@core/lib/dayjs';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {
  FetchedBuySideOrganization,
  FetchedExportTypes,
  FetchedOrganization,
  FetchedSavedExports,
} from '@analytics/graphql-api';
import {fmtExportType, isExportDisabled} from '../helpers';
import {ReportStatusCell} from './ReportStatusCell';

type Props = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  savedExports: FetchedSavedExports;
  supportedTypes: FetchedExportTypes;
};

export function ReportsSavedExportsTable({
  organization,
  savedExports,
  supportedTypes,
}: Props) {
  const columns = useMemo<DataTableColumn<FetchedSavedExports[number]>[]>(
    () => [
      {
        accessor: 'name',
        title: 'Name',
        type: 'string',
      },
      {
        accessor: 'exportType',
        title: 'Type',
        type: 'string',
        fmt: fmtExportType,
      },
      {
        accessor: 'createdBy',
        title: 'Created By',
        type: 'string',
        getValue: ({d}) =>
          `${d?.createdBy?.firstName} ${d?.createdBy?.lastName}`,
      },
      {
        accessor: 'updatedAt',
        title: 'Last Run On',
        type: 'string',
        fmt: (v) => dayjs(v).format('MMMM DD, YYYY h:mm A'),
        getValue: ({d}) =>
          dayjs(d?.lastExportInstance?.updatedAt).toISOString(),
      },
      {
        accessor: 'id',
        title: 'Export Status',
        Cell: ({d}) => {
          const disableExport = isExportDisabled(d, supportedTypes);
          return (
            <ReportStatusCell
              organization={organization}
              savedExport={d}
              disableExport={disableExport}
            />
          );
        },
      },
    ],
    [organization, supportedTypes]
  );

  return (
    <DataTable
      columns={columns}
      data={savedExports}
      emptyMessageText='No reports found.'
      orderBy='-updatedAt'
      searchKeys={['name']}
      searchPlaceholder='Search'
    />
  );
}
