import styled from 'styled-components';
import {ButtonIcon} from '@core/ui/Button';
import {AdvisorColumn} from '../types';

export function UnselectedListItem({
  item,
  onAddColumn,
}: {
  item: AdvisorColumn;
  onAddColumn: (item: AdvisorColumn) => void;
}) {
  return (
    <UnselectedIcon
      onClick={() => onAddColumn(item)}
      icon='add-circle-outline'
    />
  );
}

const UnselectedIcon = styled(ButtonIcon)`
  padding: 0;
  :focus svg {
    border-radius: 50%;
    outline: 2px solid var(--blue-80);
  }
`;
