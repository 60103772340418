import {createOrgPath} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import CampaignDynamicsTable from '@analytics/components/campaigns/CampaignDynamicsTable';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignRouteProps} from '../_routes';

export function CampaignDynamics({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  const {campaignDynamics, campaignPodcasts} = campaign;
  const exposedDynamics = campaignDynamics.filter((cd) => !cd.isBaseline);
  const baselineDynamics = campaignDynamics.filter((cd) => cd.isBaseline);

  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <div
        css={`
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin: 2rem 1.5rem 1rem;
        `}>
        <CardHeader
          css={`
            margin: 0;
          `}>
          Dynamic Ads
        </CardHeader>
        {(campaignPodcasts ?? []).length === 0 && (
          <Button
            to={createOrgPath(
              organization,
              `/campaigns/${campaign.slug}/ingest`
            )}
            color='secondary'
            buttonSize='sm'>
            Ingest Dynamic Ads
          </Button>
        )}
      </div>
      <Card>
        <CampaignDynamicsTable
          organization={organization}
          campaign={campaign}
          campaignDynamics={exposedDynamics}
        />
      </Card>
      {baselineDynamics.length > 0 && (
        <>
          <CardHeader>Baseline Dynamic Ads</CardHeader>
          <Card>
            <CampaignDynamicsTable
              organization={organization}
              campaign={campaign}
              campaignDynamics={baselineDynamics}
            />
          </Card>
        </>
      )}
    </>
  );
}
