import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useGetMeQuery} from '@core/graphql-api';
import {Overlay} from '@core/ui/Overlay';
import {
  useOrganizationQuery,
  useSetHasSeenAdAnalyticsWelcomeMutation,
} from '@analytics/graphql-api';
import {OnboardingButton} from '@analytics/screens/account/AccountLayout';
import SpotifyHeader from '@analytics/screens/account/SpotifyHeader';
import IntroModalContent from './IntroModalContent';

const Header = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height */
  margin-bottom: 32px;
  margin-top: 12px;

  text-align: center;
`;

const PreHeader = styled.h2`
  font-family: 'Circular Sp';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #6a6a6a;
`;

const ContentContainer = styled.div`
  background: #fff;
  width: 500px;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const Body = styled.div`
  padding: 50px 30px 50px 30px;
  flex-direction: column;
  display: flex;
  text-align: left;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
`;

const header = 'Introducing Spotify Ad Analytics';
const subheader = 'Drumroll please...';
const ctaLabel = 'Continue';

const IntroModal = ({kind, slug}) => {
  const [hasSeenWelcomeScreen, setHasSeenWelcomeScreen] = useState(false);
  const {data: userData} = useGetMeQuery();
  const {refetch} = useOrganizationQuery({
    variables: {
      slug,
    },
  });

  const [setHasSeenAdAnalyticsWelcome] =
    useSetHasSeenAdAnalyticsWelcomeMutation({
      variables: {
        input: {
          status: true,
        },
      },
    });

  const welcomeScreenCTAOnClick = async () => {
    setHasSeenWelcomeScreen(true); // Optimistically hide the welcome screen, don't have to wait for network
    await setHasSeenAdAnalyticsWelcome();
    await refetch();
  };

  const opened =
    !hasSeenWelcomeScreen && userData?.me?.hasSeenAdAnalyticsWelcome === false;

  if (!opened) {
    return null;
  }

  return (
    <Overlay
      onClick={welcomeScreenCTAOnClick}
      opened={opened}
      withBackdrop
      animation='move-up'
      css={`
        align-items: center;
        display: flex;
        inset: 0;
        justify-content: center;
        background: none;
        position: fixed;
        border-radius: 8px;
      `}>
      <ContentContainer>
        <SpotifyHeader size={'small'} />
        <Body>
          <PreHeader>{subheader}</PreHeader>
          <Header>{header}</Header>
          <IntroModalContent kind={kind} slug={slug} />
          <Footer>
            <OnboardingButton onClick={welcomeScreenCTAOnClick}>
              {ctaLabel}
            </OnboardingButton>
          </Footer>
        </Body>
      </ContentContainer>
    </Overlay>
  );
};

export default IntroModal;
