import {gql} from '@apollo/client';

export default gql`
  query claimVerify($payload: String!) {
    claim {
      verify(payload: $payload) {
        href
        hash
        title
        email
        name
        organization {
          id
          name
          slug
        }
      }
    }
  }
`;
