import {useHistory} from 'react-router-dom';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {BrandInviteForm} from '@analytics/components/forms/BrandInviteForm';
import {toInviteUrl} from '../_routes';
import {ManageInviteCompanyRouteProps, toInvitePixelUrl} from './_routes';

export function InviteMembers({
  user,
  organization,
  company,
}: ManageInviteCompanyRouteProps) {
  const history = useHistory();
  const companyMembers = company.organization?.members ?? [];

  const onComplete = ({organizationId}: {organizationId: string}) => {
    history.push(
      toInvitePixelUrl({
        organization,
        company,
        inviteeOrganizationId: organizationId,
      })
    );
  };

  if (companyMembers.length) {
    return (
      <ResponsiveContent containerSize='large'>
        <CardHeader backPath={toInviteUrl(organization)}>
          Invite {company.name} to Ad Analytics
        </CardHeader>
        <Card>
          <p>
            <b>{company.name}</b> is already a member of Ad Analytics. For
            security reasons, you cannot add members to this organization.
          </p>
          <p>
            Ad Analytics will notify the following team when the campaign goes
            live:{' '}
          </p>
          <ul>
            {companyMembers.map(({firstName, lastName}, n) => {
              return (
                <li key={n}>
                  {firstName} {lastName}
                </li>
              );
            })}
          </ul>
          <p>You may use this brand when creating campaigns.</p>
          <div
            css={`
              text-align: right;
            `}>
            <Button color='primary' to={toInviteUrl(organization)}>
              Back
            </Button>
          </div>
        </Card>
      </ResponsiveContent>
    );
  }
  return (
    <ResponsiveContent containerSize='large'>
      <CardHeader backPath={toInviteUrl(organization)}>
        Invite {company.name} to Ad Analytics
      </CardHeader>
      <Card>
        <p>
          <b>{company.name}</b> is not a member of Ad Analytics. Let's invite
          them to join.
        </p>
        <BrandInviteForm
          user={user}
          organization={organization}
          companyId={company.id}
          onComplete={onComplete}
        />
      </Card>
    </ResponsiveContent>
  );
}
