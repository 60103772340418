import {gql} from '@apollo/client';

export default gql`
  mutation createGenericTag($input: CreateGenericTagInput!) {
    authed {
      success
    }
    createGenericTag(input: $input) {
      genericTag {
        organization {
          id
        }
        id
        title
        color
      }
    }
  }
`;
