import { screenSmMin } from '@spotify-internal/encore-foundation';
import { borderRadiusRounded, buttonLinkReset, cssSpacing, focusGapBorder, rootStyle } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
export var chipBorderRadius = borderRadiusRounded;
export var LegacyChipComponent = styled.button.withConfig({
  displayName: "LegacyChip__LegacyChipComponent",
  componentId: "sc-tzfq94-0"
})(["", ""], function (_ref) {
  var $isUsingKeyboard = _ref.$isUsingKeyboard,
    $useBrowserDefaultFocusStyle = _ref.$useBrowserDefaultFocusStyle,
    $secondary = _ref.$secondary,
    $size = _ref.$size,
    $selected = _ref.$selected;
  return css(["", ";", ";", ";border-radius:", ";display:inline-flex;vertical-align:middle;max-inline-size:100%;margin-block-end:", ";text-decoration:none;overflow-wrap:break-word;position:relative;flex-shrink:0;", " ", " &:not(:last-child){margin-inline-end:", ";}"], rootStyle(), buttonLinkReset($useBrowserDefaultFocusStyle), !$useBrowserDefaultFocusStyle && focusGapBorder(chipBorderRadius, $isUsingKeyboard), chipBorderRadius, cssSpacing('tighter-2', '8px'), $selected && css(["z-index:1;"]), $secondary && css(["&:not(:first-child){margin-inline-start:", ";z-index:0;@media (min-width:", "){margin-inline-start:", ";}", "}"], $size === 'sm' ? '-32px' : '-40px', screenSmMin, $size === 'sm' ? '-48px' : '-64px', $isUsingKeyboard && css(["&:focus{z-index:1;margin-inline-start:calc(-1 * ", ");@media (min-width:", "){margin-inline-start:calc(-1 * ", ");}}"], cssSpacing('tighter', '12px'), screenSmMin, cssSpacing('base', '16px'))), cssSpacing('tighter-2', '8px'));
});