function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { FONT_TYPE, STATIC_TYPE, KEYFRAME_TYPE, RULE_TYPE } from 'fela-utils';
export default function getNodeSibling(nodes, _ref, sortMediaQuery) {
  var type = _ref.type,
      media = _ref.media,
      support = _ref.support;

  switch (type) {
    case FONT_TYPE:
    case STATIC_TYPE:
    case KEYFRAME_TYPE:
      return nodes[0];

    case RULE_TYPE:
      var mediaNodes = nodes.map(function (node) {
        return node.media;
      });
      var filteredNodes = mediaNodes.filter(function (m) {
        return m.length !== 0;
      });

      if (media) {
        var sorted = [].concat(_toConsumableArray(filteredNodes), [media]).sort(sortMediaQuery);
        var index = sorted.indexOf(media) + 1;
        var insertMedia = sorted[index];

        if (insertMedia) {
          if (insertMedia === media && support) {
            // support
            return nodes.find(function (el) {
              return el.media === sorted[sorted.indexOf(media) + 2];
            });
          }

          return nodes.find(function (el) {
            return el.media === insertMedia;
          });
        }
      } else {
        var _sorted = filteredNodes.sort(sortMediaQuery);

        var _insertMedia = _sorted[0];

        if (!support) {
          // if we insert the plain RULE node while there's already a support RULE node
          // make sure to insert before
          var supportNode = nodes.find(function (el) {
            return el.getAttribute('data-fela-support') !== undefined && el.media === '' && el.getAttribute('data-fela-type') === 'RULE';
          });

          if (supportNode) {
            return supportNode;
          }
        }

        if (_insertMedia) {
          return nodes.find(function (el) {
            return el.media === _insertMedia;
          });
        }
      }

  }
}