import {Redirect, Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {measureRoutes} from '@analytics/lib/routes/measureRoutes';
import {MeasureCampaignsIndex} from './campaigns';
import InsightsIndex from './insights';
import {LiftIndex} from './lift';

export type MeasureRouteProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
};

export function MeasureIndex({user, organization}: MeasureRouteProps) {
  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, '/measure/campaigns'),
    basePath: '/:part(b|a|s|p)/:slug/measure',
    routes: measureRoutes({organization}),
  });

  return (
    <Switch>
      {renderRoutes({
        components: {
          measure: () => (
            <Redirect to={createOrgPath(organization, '/measure/campaigns')} />
          ),
          campaigns: MeasureCampaignsIndex,
          lift: LiftIndex,
          overview: InsightsIndex,
        },
        extraProps: {
          organization,
          user,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
