import {gql} from '@apollo/client';

export default gql`
  query getReviewerEpisode(
    $organizationId: ID!
    $feedId: ID!
    $id: ID!
    $proxiedEnclosureId: ID
  ) {
    me {
      id
      organization(id: $organizationId) {
        research {
          feed(id: $feedId) {
            episode(id: $id) {
              id
              title
              subtitle
              summary
              image {
                href
                path
              }

              feed {
                id
                title
                image {
                  href
                  path
                }
              }

              enclosure {
                episodeId
                href
                type
                length
                duration
                mpegPath
                waveform
                proxiedEnclosure(proxiedEnclosureId: $proxiedEnclosureId) {
                  id
                  proxyName
                  href
                  type
                  length
                  mpegPath
                  waveform
                }
              }

              placements(proxiedEnclosureId: $proxiedEnclosureId) {
                id
                startTime
                endTime
                ad {
                  mpegPath
                  waveform
                  transcript

                  state
                  company {
                    id
                    name
                  }
                  product {
                    id
                    name
                  }

                  sponsor {
                    id
                    name
                  }

                  reviews {
                    reviewer {
                      id
                      email
                    }
                  }
                }
              }

              asr {
                transcript {
                  created
                  duration
                  confidence
                  transcript
                  speakers {
                    id
                    ranges {
                      start
                      end
                    }
                  }
                  words {
                    word
                    start
                    end
                    confidence
                    speaker
                    punctuatedWord
                  }
                }
                labels {
                  start
                  end
                }
              }

              keywords {
                id
                timestamp
                confidence
                version
              }

              campaignEpisodes {
                id
                campaignPodcast {
                  id
                  campaign {
                    id
                    advertiser {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
