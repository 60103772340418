import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import searchFilter from '@core/lib/searchFilter';
import {isDescendant} from '@core/lib/utils';
import {Input} from '@core/ui/FormElements';
import {Icon} from '@core/ui/Icon';
import {Overlay} from '@core/ui/Overlay';
import {pixelEventTypes} from './helpers';

const optionRule = {
  borderRadius: '0.375rem',
  cursor: 'pointer',
  display: 'flex',
  gap: '0.375rem',
  justifyContent: 'space-between',
  padding: '0.375rem 1rem',
  userSelect: 'none',
  ':hover': {
    background: 'var(--bg-subtle)',
    color: 'var(--black)',
  },
};

type TItem = {
  name: string;
  type?: string;
  isNew?: boolean;
};
interface IPixelEventSelectProps {
  defaultValue?: string;
  label?: string;
  invalid?: boolean;
  onSelect?: (_item: TItem) => void;
  onClearInput?: () => void;
  items?: {name: string; type: string}[];
  renderItem?: (_item: TItem) => React.ComponentType<any>;
}

const isInputValueInResults = ({
  inputValue,
  results,
}: {
  inputValue: string;
  results: TItem[];
}): boolean => {
  if (results.length) {
    const value = inputValue.toLowerCase().trim();
    return !!results.find((item) => item.name.toLowerCase().trim() === value);
  }
  return false;
};

const PixelEventSelect = ({
  defaultValue,
  label = 'Event Name',
  invalid = false,
  onClearInput,
  onSelect,
  items = pixelEventTypes,
  renderItem,
  ...inputProps
}: IPixelEventSelectProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>();
  const overlayRef = useRef<HTMLDivElement>();
  const selectedOptionRef = useRef<TItem>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [opened, setOpened] = useState<boolean>(false);
  const [results, setResults] = useState<TItem[]>(items);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.trim();
    const hasValue = query.length > 0;

    selectedOptionRef.current = null;

    setOpened(hasValue);
    if (!hasValue && onClearInput) onClearInput();

    setInputValue(query);
    setResults(searchFilter({data: items, searchKeys: ['name'], query}));
  };

  const onItemClick = useCallback(
    (item: TItem) => {
      selectedOptionRef.current = item;
      inputRef.current.value = item.name;
      setOpened(false);

      if (!item.isNew) {
        setInputValue('');
        setResults(items);
      }

      if (onSelect) onSelect(item);
    },
    [setOpened, onSelect]
  );

  useEffect(() => {
    const onDocumentClick = ({target}: {target: EventTarget}) => {
      if (isDescendant(inputRef.current, target)) setFocused(true);
      else setFocused(false);

      if (
        !opened ||
        isDescendant(overlayRef.current, target) ||
        isDescendant(inputRef.current, target)
      ) {
        // Do nothing
      } else {
        setOpened(false);
      }
    };

    if (!opened) {
      // Reset on close
      if (!selectedOptionRef.current) {
        inputRef.current.value = '';
      }
      selectedOptionRef.current = null;
      setInputValue('');
      setResults(items);
    }

    document.addEventListener('click', onDocumentClick);

    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [opened]);

  useEffect(() => {
    if (defaultValue) {
      inputRef.current.value = defaultValue;
    }
  }, []);

  return (
    <>
      <div css={{position: 'relative'}}>
        <Icon
          icon='magnify'
          rules={() => ({
            bottom: 0,
            color: invalid ? 'var(--red-orange)' : 'var(--icon-muted)',
            left:
              inputRef.current?.value || defaultValue || focused
                ? '0.825rem'
                : '0.725rem',
            position: 'absolute',
            height: '1.25rem',
            margin: 'auto',
            top: 0,
            width:
              (inputRef.current?.value || defaultValue) && !focused
                ? 0
                : 'auto',
            transform:
              inputRef.current?.value || defaultValue || focused
                ? 'scale(.75) translateY(12px)'
                : null,
            transition:
              'transform 150ms cubic-bezier(0.4,0,0.2,1), width 300ms cubic-bezier(0.4,0,0.2,1)',
          })}
        />
        <div
          css={{
            borderRadius: '.25rem',
            bottom: '1.0625rem',
            color: invalid ? 'var(--red-orange)' : 'var(--text-muted)',
            margin: 'auto',
            paddingLeft:
              inputRef.current?.value || defaultValue || focused
                ? '1.25rem'
                : '2.5rem',
            pointerEvents: 'none',
            position: 'absolute',
            transform:
              inputRef.current?.value || defaultValue || focused
                ? 'scale(.75) translateY(-15px)'
                : null,
            transformOrigin: 'left',
            transition:
              'transform 150ms cubic-bezier(0.4,0,0.2,1), padding-left 150ms cubic-bezier(0.4,0,0.2,1)',
          }}>
          {label}
        </div>
        <Input
          domRef={inputRef}
          invalid={invalid}
          maxLength={50}
          onChange={onInputChange}
          placeholder={null}
          rules={() => ({
            padding: `1.625rem 1rem .5rem ${
              (inputRef.current?.value || defaultValue) && !focused
                ? '1rem'
                : '2.25rem'
            }`,
            transition: 'padding 300ms cubic-bezier(0.4,0,0.2,1)',
          })}
          {...inputProps}
        />
      </div>
      {opened && (
        <Overlay
          domRef={overlayRef}
          opened={opened}
          toggle={setOpened}
          positionTarget={inputRef.current}
          verticalAlign='bottom'
          verticalOffset={2}
          withShadow
          css={`
            padding: 0.5rem;
            minwidth: ${inputRef.current
              ? inputRef.current.getBoundingClientRect().width
              : 0}px;
          `}>
          <div
            css={{
              height: '100%',
              maxHeight: '25rem',
              minHeight: 0,
              overflowY: 'auto',
            }}>
            {!isInputValueInResults({
              results,
              inputValue,
            }) &&
              !!inputValue.length && (
                <div
                  onClick={() =>
                    onItemClick({
                      name: inputValue,
                      isNew: true,
                    })
                  }
                  css={{
                    color: selectedOptionRef?.current
                      ? 'var(--blue)'
                      : 'inherit',
                    ...optionRule,
                  }}>
                  <div>
                    {inputValue}
                    <span css={{color: 'var(--text-muted)'}}>
                      <span css={{margin: '0 0.375rem'}}>-</span>
                      Custom event
                    </span>
                  </div>
                  {selectedOptionRef?.current && (
                    <Icon
                      icon='checkmark'
                      rules={() => ({
                        color: 'var(--blue)',
                        height: '1.25rem',
                        width: '1.25rem',
                      })}
                    />
                  )}
                </div>
              )}
            <>
              {results.map((item) => (
                <div
                  key={item.name}
                  onClick={() => onItemClick(item)}
                  css={{
                    color:
                      item.name === selectedOptionRef?.current?.name
                        ? 'var(--blue)'
                        : 'inherit',
                    ...optionRule,
                  }}>
                  <>
                    {typeof renderItem === 'function'
                      ? renderItem(item)
                      : item.name}
                    {item.name === selectedOptionRef?.current?.name && (
                      <Icon
                        icon='checkmark'
                        rules={() => ({
                          color: 'var(--blue)',
                          height: '1.25rem',
                          width: '1.25rem',
                        })}
                      />
                    )}
                  </>
                </div>
              ))}
            </>
          </div>
        </Overlay>
      )}
    </>
  );
};

export default PixelEventSelect;
