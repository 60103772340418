import {gql} from '@apollo/client';

export interface IKey {
  key: string;
  domain: string;
  createdAt: string;
  alias: string;
  installations: string;
  conversionUrls: Array<{
    id: string;
    url: string;
    action: string;
    value: string;
    type: string;
    category: string;
  }>;
  actionMaps: Array<{
    id: string;
    eventName: string;
    action: string;
  }>;
  debug: {
    hasData: boolean;
  };
  pixelEventRecords: Array<{
    id: string;
    name: string;
    eventType: string;
    installation: string;
    lastUpdatedBy: {
      id: string;
      firstName: string;
      lastName: string;
    };
    createdAt: string;
    lastActivity: string;
    lastActivityFlag: string;
    category: string;
    currency: string;
    type: string;
    discountCode: string;
    newCustomer: string;
    orderId: string;
    value: string;
  }>;
}

export interface IOrganizationKey {
  me: {
    id: string;
    organization: {
      id: string;
      key: IKey;
    };
  };
}

export interface IOrganizationKeyInput {
  organizationId: string;
  key: string;
}

export default gql`
  query OrganizationKeyQuery($organizationId: ID!, $key: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        key(key: $key) {
          key
          domain
          createdAt
          alias
          installations
          conversionUrls {
            id
            url
            action
            value
            type
            category
          }

          actionMaps {
            id
            eventName
            action
          }

          debug {
            hasData
          }

          pixelEventRecords {
            id
            name
            eventType
            installation
            lastUpdatedBy {
              id
              firstName
              lastName
            }
            createdAt
            lastActivity
            lastActivityFlag

            category
            currency
            type
            discountCode
            newCustomer
            orderId
            value

            quantity
            productId
            productName
            productType
            productVendor
            variantId
            variantName
          }
        }
      }
    }
  }
`;
