import {useEffect, useState} from 'react';
import {Element} from '@core/style';

const ResizeHandle = ({onResize, onResizeStart}) => {
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(false);

  const onMouseDown = (evt) => {
    if (dragging) {
      return;
    }
    document.body.style.userSelect = 'none';
    setStartX(evt.pageX);
    setDragging(true);
  };

  const onBodyMouseMove = (evt) => {
    const dx = evt.pageX - startX;
    if (typeof onResize === 'function') {
      onResize(dx);
    }
  };

  const onBodyMouseUp = () => {
    document.body.style.userSelect = '';
    setDragging(false);
  };

  const toggleListeners = (enable) => {
    const m = enable ? 'addEventListener' : 'removeEventListener';
    window[m]('mousemove', onBodyMouseMove);
    window[m]('mouseup', onBodyMouseUp);
  };

  useEffect(() => {
    if (dragging) {
      if (typeof onResizeStart === 'function') {
        onResizeStart();
      }
      toggleListeners(true);
    }

    return () => {
      toggleListeners(false);
    };
  }, [dragging]);

  return (
    <Element
      tabindex='-1'
      onMouseDown={onMouseDown}
      onDragStart={(evt) => evt.preventDefault()}
      onBlur={onBodyMouseUp}
      rules={() => ({
        cursor: 'ew-resize',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        outline: 0,
        padding: '0.25rem',
        ':focus': {},
        ':hover': {
          '> div': {
            opacity: '0.75',
          },
        },
      })}>
      <Element
        rules={() => ({
          background: '#fff',
          height: '1.75rem',
          opacity: '0.33',
          width: '0.1875rem',
        })}
      />
    </Element>
  );
};

const AudioPlacement = ({
  duration,
  placement,
  setActivePlacement,
  trackWidth,
  updatePlacement,
  ...props
}) => {
  let resizeStart = {};
  const left = (placement.startTime / duration) * 100;
  const width = ((placement.endTime - placement.startTime) / duration) * 100;

  const onResizeStart = () => {
    resizeStart = {
      startTime: placement.startTime,
      endTime: placement.endTime,
    };
  };

  const onResize = (dx, position) => {
    const pct = (dx * 100) / trackWidth;
    const secondsDiff = (pct * duration) / 100;

    if (position === 'start') {
      const _startTime = Math.min(
        Math.max(resizeStart.startTime + secondsDiff, 0),
        resizeStart.endTime - 3
      );
      placement.startTime = _startTime;
    } else if (position === 'end') {
      const _endTime = Math.max(
        Math.min(resizeStart.endTime + secondsDiff, duration),
        resizeStart.startTime + 3
      );
      placement.endTime = _endTime;
    }
    updatePlacement(placement);
  };

  return (
    <Element
      onClick={() => setActivePlacement(placement)}
      rules={() => ({
        bottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        top: 0,
      })}
      style={{
        background: placement.color,
        left: `${left}%`,
        width: `${width}%`,
      }}
      {...props}>
      <ResizeHandle
        onResizeStart={onResizeStart}
        onResize={(dx) => onResize(dx, 'start')}
      />
      <ResizeHandle
        onResizeStart={onResizeStart}
        onResize={(dx) => onResize(dx, 'end')}
      />
    </Element>
  );
};

export default AudioPlacement;
