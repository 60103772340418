import React, {ComponentClass, FC, PropsWithChildren, useState} from 'react';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import {MultipleSelect} from '@core/ui/Select';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  useCampaignOverviewImpressionsPublishersQuery,
} from '@analytics/graphql-api';
import InsightsDatePicker from './InsightsDatePicker';

type InsightsImpressionsQueryWrapperProps = PropsWithChildren<{
  after: string;
  before: string;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  title?: string;
  withCampaigns: boolean;
  withPublishers: boolean;
}>;

const InsightsImpressionsQueryWrapper: FC<
  InsightsImpressionsQueryWrapperProps
> = ({
  after,
  before,
  children,
  organization,
  title,
  withCampaigns,
  withPublishers,
}): JSX.Element => {
  const [selectedPublishers, setSelectedPublishers] = useState<any[]>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<any[]>([]);
  const {data, loading} = useCampaignOverviewImpressionsPublishersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: organization.id,
      after,
      before,
      isMarketing: organization.kind.toLowerCase() === 'podcast',
    },
  });

  if (loading) {
    return <LoadingSpinner size='large' centered />;
  } else if (!data) {
    return (
      <ToastMessage alertType='warning'>
        Error loading data. Please, try again later.
      </ToastMessage>
    );
  }
  const campaigns = (data?.me?.organization?.campaigns?.results ?? []).map(
    (campaign) => ({
      ...campaign,
      publisherName: campaign?.organization?.name || '',
    })
  );

  const publishers =
    data?.me?.organization?.overview?.impressions?.publishers ?? [];

  return (
    <>
      {title ? (
        <CardHeader rules={() => ({margin: '0 1.5rem 1rem'})}>
          {title}
        </CardHeader>
      ) : null}
      <Card rules={() => ({background: 'var(--bg-muted)'})}>
        <div
          css={{
            display: 'grid',
            gridGap: '.75rem',
            gridTemplateColumns: `repeat(${
              [withCampaigns, withPublishers].filter(Boolean).length + 1
            }, max-content)`,
          }}>
          <InsightsDatePicker defaultValue={{after, before}} />
          {withCampaigns ? (
            <MultipleSelect
              items={campaigns}
              searchable
              propertyForDescription=''
              propertyForName='name'
              propertyForValue='id'
              searchKeys={['name']}
              placeholder='Campaigns'
              onSelect={setSelectedCampaigns}
              small
              outline
            />
          ) : null}
          {withPublishers ? (
            <MultipleSelect
              items={publishers}
              searchable
              propertyForDescription=''
              propertyForName='name'
              propertyForValue='id'
              searchKeys={['name']}
              placeholder='Publishers'
              onSelect={setSelectedPublishers}
              small
              outline
            />
          ) : null}
        </div>
      </Card>
      {React.cloneElement(children as any, {
        publishers: selectedPublishers,
        campaigns: selectedCampaigns,
      })}
    </>
  );
};

export const withInsightsImpressionsQuery =
  (
    Component: ComponentClass<any>,
    {
      title,
      withCampaigns = false,
      withPublishers = false,
    }: {withCampaigns: boolean; withPublishers: boolean; title?: string}
  ) =>
  ({...props}) => {
    return (
      <InsightsImpressionsQueryWrapper
        title={title}
        withCampaigns={withCampaigns}
        withPublishers={withPublishers}
        {...(props as any)}>
        <Component {...props} />
      </InsightsImpressionsQueryWrapper>
    );
  };

export default InsightsImpressionsQueryWrapper;
