import {useMemo, useState} from 'react';
import {useMutation} from '@apollo/client';
import {Redirect, useHistory} from 'react-router-dom';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import {Element, HIGHLIGHTER_40} from '@core/style';
import Button from '@core/ui/Button/LegacyButton';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import EmptyMessage from '@core/ui/EmptyMessage';
import {FeedImage} from '@core/ui/Image';
import {LoadingSpinner} from '@core/ui/Loading';
import {FetchedCampaign, FetchedOrganization} from '@analytics/graphql-api';
import REMOVE_CAMPAIGN_EPISODE_MUTATION from '@analytics/graphql-api/_old_mutations/RemoveCampaignEpisode';
import UPDATE_CAMPAIGN_EPISODE_MUTATION from '@analytics/graphql-api/_old_mutations/UpdateCampaignEpisode';

const CloseEpisodesTable = ({
  advertiser,
  campaignPodcast: {feed, podcast},
  episodes,
  expectedPublished,
  organization,
  onSelect,
}: {
  advertiser: string;
  campaignPodcast: TSFixMe;
  episodes: TSFixMe[];
  organization: FetchedOrganization;
  expectedPublished: string;
  onSelect?: (id: string) => void;
}) => {
  const history = useHistory();

  const columns: DataTableColumn[] = useMemo(
    () => [
      {
        title: 'Episode',
        accessor: 'title',
        type: 'string',
        Cell: ({data: {title, placements}}) => (
          <Element
            rules={() => ({
              display: 'flex',
              flexDirection: 'column',
              padding: '0.425rem 0',
            })}>
            <Element
              rules={() => ({
                alignItems: 'center',
                display: 'flex',
              })}>
              <FeedImage feed={feed} width={35} />
              <Element
                rules={() => ({
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1rem',
                })}>
                <Element
                  rules={() => ({
                    fontWeight: 500,
                    fontSize: '0.95rem',
                    margin: '0.25rem 0',
                  })}>
                  {title}
                </Element>
                {placements.length > 0 && (
                  <Element
                    rules={() => ({
                      display: 'flex',
                      fontSize: '0.825rem',
                      margin: '0.25rem 0',
                    })}>
                    <Element
                      rules={() => ({
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                      })}>
                      Brands:
                    </Element>{' '}
                    <Element
                      rules={() => ({
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginLeft: '0.25rem',
                      })}>
                      {placements.split(', ').map((p: string) =>
                        p === advertiser ? (
                          <Element
                            rules={({theme}) => ({
                              background: HIGHLIGHTER_40,
                              borderRadius: '.325rem',
                              color:
                                theme.name === 'dark'
                                  ? theme.textInverse
                                  : 'inherit',
                              margin: '0 .225rem',
                              padding: '0 .275rem',
                            })}>
                            <div>{p}</div>
                          </Element>
                        ) : (
                          <Element
                            rules={() => ({
                              margin: '0 0.225rem',
                            })}>
                            {p}
                          </Element>
                        )
                      )}
                    </Element>
                  </Element>
                )}
              </Element>
            </Element>
          </Element>
        ),
      },
      {
        title: 'Published',
        accessor: 'published',
        type: 'date',
        fmt: (v: string): string => dayjs(v).utc().format('MMM DD, YYYY'),
      },
      {
        accessor: 'diff',
        type: 'number',
        hidden: true,
      },
      {
        title: '',
        accessor: 'id',
        type: 'number',
        Cell: ({data: {id}}) => (
          <Element
            rules={() => ({
              display: 'grid',
              gridTemplateColumns: onSelect ? '1fr 1fr' : '1fr',
              gap: '0.5rem',
            })}>
            <Button
              onClick={() =>
                history.push(
                  createOrgPath(
                    organization,
                    `podcasts/${podcast.id}/episodes/${id}`
                  )
                )
              }
              style-outline
              style-small
              rules={() => ({
                width: '100%',
              })}>
              View
            </Button>
            {onSelect && (
              <Button
                onClick={() => onSelect(id)}
                style-small
                style-secondary
                rules={() => ({
                  width: '100%',
                })}>
                Select
              </Button>
            )}
          </Element>
        ),
      },
    ],
    [episodes]
  );

  // we want to sort this by the diff between the expectedPublished and the actual published.
  const data = useMemo(
    () =>
      episodes.map((episode) => ({
        ...episode,
        diff: Math.abs(dayjs(expectedPublished).diff(dayjs(episode.published))),
        placements: episode.placements
          .reduce((arr, {ad: {company}}) => {
            const v = company ? company.name : 'Ad pending review';
            return arr.includes(v) ? arr : [...arr, v];
          }, [])
          .join(', '),
      })),
    [episodes]
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      orderBy='diff'
      searchKeys={['placements']}
      searchPlaceholder='Search advertiser'
    />
  );
};

const CampaignFutureEpisode = ({
  organization,
  campaign,
  campaignEpisode,
  campaignPodcast,
}: {
  organization: FetchedOrganization;
  campaign: FetchedCampaign;
  campaignEpisode: TSFixMe;
  campaignPodcast: TSFixMe;
}) => {
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [errors, setErrors] = useState(false);

  const [updateCampaignEpisode] = useMutation(
    UPDATE_CAMPAIGN_EPISODE_MUTATION,
    {
      update: (
        cache,
        {
          data: {
            updateCampaignEpisode: {campaignEpisode},
          },
        }
      ) => {
        cache.modify({
          id: cache.identify(campaignPodcast),
          fields: {
            campaignEpisodes(cached = []) {
              return cached.map((ce) => {
                if (ce.id == campaignEpisode.id) {
                  return campaignEpisode;
                }
                return ce;
              });
            },
          },
        });
      },
    }
  );

  const [removeCampaignEpisode] = useMutation(
    REMOVE_CAMPAIGN_EPISODE_MUTATION,
    {
      update: (cache) => {
        cache.modify({
          id: cache.identify(campaignPodcast),
          fields: {
            campaignEpisodes(cached = []) {
              return cached.filter((ce) => ce.id != campaignEpisode.id);
            },
          },
        });
      },
    }
  );

  const onSelectEpisode = async (episodeId: string): Promise<void> => {
    setLoading(true);
    try {
      await updateCampaignEpisode({
        variables: {
          input: {
            id: campaignEpisode.id,
            episodeId,
          },
        },
      });

      setComplete(true);
    } catch (err) {
      // TODO update err handling
      console.warn(err);
      setErrors([err]);
    }

    setLoading(false);
  };

  const onDeleteEpisode = async (): Promise<void> => {
    setLoading(true);
    try {
      await removeCampaignEpisode({
        variables: {
          input: {
            id: campaignEpisode.id,
          },
        },
      });

      setComplete(true);
    } catch (err) {
      // TODO update err handling
      console.warn(err);
      setErrors([err]);
    }

    setLoading(false);
  };

  if (complete) {
    return (
      <Redirect
        to={createOrgPath(
          organization,
          `/campaigns/${campaign.slug}/podcasts/${campaignPodcast.id}`
        )}
      />
    );
  }

  if (loading) {
    return (
      <Card
        rules={() => ({
          border: 'none',
          marginTop: '5rem',
        })}>
        <Element
          rules={() => ({
            justifyContent: 'center',
            display: 'flex',
          })}>
          <LoadingSpinner />
        </Element>
        <EmptyMessage>
          Ad Analytics is moving or removing data into the correct databases if
          necessary to ensure that the next attribution run is accurate. Please
          hold tight.
        </EmptyMessage>
      </Card>
    );
  }

  const futureEpisode = campaignEpisode?.futureEpisode;

  if (!futureEpisode) {
    return <></>;
  }

  const closeEpisodes = futureEpisode?.closeEpisodes ?? [];
  const closeInCampaignEpisodes = futureEpisode?.closeInCampaignEpisodes ?? [];
  const expectedPublished = futureEpisode?.expectedPublished;

  return (
    <>
      <Card
        rules={({theme}) => ({
          border: 'none',
          color: theme.textTertiary,
          padding: 'none',
          marginBottom: '3rem',
        })}>
        Sometimes episodes are published late or early or multiple on the same
        day, here you may manually select which episode this belongs to.
        Alternatively, this episode drop may have already been in the campaign
        or may no longer be needed, if so you can remove it from the campaign.
      </Card>
      <CardHeader
        rightContent={
          <Button
            onClick={onDeleteEpisode}
            style-small
            style-outline
            style-destructive>
            Remove upcoming episode
          </Button>
        }>
        Close Episodes
      </CardHeader>
      <Card>
        {closeEpisodes.length > 0 ? (
          <CloseEpisodesTable
            advertiser={campaign.advertiser?.name}
            campaignPodcast={campaignPodcast}
            episodes={closeEpisodes}
            expectedPublished={expectedPublished}
            organization={organization}
            onSelect={onSelectEpisode}
          />
        ) : (
          <EmptyMessage>
            We could not find any epsiodes that are close and not already in
            this campaign.
          </EmptyMessage>
        )}
      </Card>
      <CardHeader>Close Episodes Already in Campaign</CardHeader>
      <Card>
        {closeInCampaignEpisodes.length > 0 ? (
          <CloseEpisodesTable
            advertiser={campaign.advertiser?.name}
            campaignPodcast={campaignPodcast}
            episodes={closeInCampaignEpisodes}
            expectedPublished={expectedPublished}
            organization={organization}
          />
        ) : (
          <EmptyMessage>
            We could not find any episodes that are close and in this campaign.
          </EmptyMessage>
        )}
      </Card>
    </>
  );
};

export default CampaignFutureEpisode;
