import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {CampaignsRouteProps} from '../../_routes';

export const toBrandCreateCampaignBasePath = (
  organization: FetchedOrganization | FetchedBuySideOrganization
) => createOrgPath(organization, 'campaigns/create/brand');

export const getBrandCreateCampaignRoutes = ({
  organization,
}: Pick<CampaignsRouteProps, 'organization'>) => {
  return useRoutes({
    baseUrl: toBrandCreateCampaignBasePath(organization),
    basePath: '/:part(b|a|s|p)/:slug/campaigns/create/brand',
    routes: [
      {
        key: 'campaignType',
        path: '',
        exact: true,
      },
      {
        key: 'pubDetails',
        path: ':kind',
        exact: true,
      },
      {
        key: 'campaignDetails',
        path: ':kind/:pubId/:pubName/:details',
        exact: true,
      },
    ],
  });
};
