import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import Loading from '@core/ui/Loading';
import {useCampaignDynamicQuery} from '@analytics/graphql-api';
import {CampaignRouteProps} from '../../_routes';
import {CampaignDynamic} from './CampaignDynamic';
import {CampaignDynamicConsistency} from './CampaignDynamicConsistency';
import {useCampaignDynamicRoutes} from './_routes';
import {CampaignDynamicPodcastsIndex} from './podcasts';

export function CampaignDynamicIndex({
  user,
  organization,
  campaign,
  match: {
    params: {campaignDynamicId},
  },
}: CampaignRouteProps & {
  match: {
    params: {
      campaignDynamicId: string;
    };
  };
}) {
  const {data, loading, error} = useCampaignDynamicQuery({
    variables: {
      organizationId: organization.id,
      campaignId: campaign.id,
      id: campaignDynamicId,
    },
  });

  const {renderRoutes} = useCampaignDynamicRoutes({
    organization,
    campaign,
    campaignDynamicId,
  });

  if (loading) {
    return <Loading fixed />;
  }

  if (error) {
    return (
      <Redirect
        to={createOrgPath(organization, `campaigns/${campaign.slug}`)}
      />
    );
  }

  const campaignDynamic = data?.me?.organization.campaign.campaignDynamic;

  return (
    <Switch>
      {renderRoutes({
        components: {
          overview: CampaignDynamic,
          consistency: CampaignDynamicConsistency,
          podcasts: CampaignDynamicPodcastsIndex,
        },
        extraProps: {
          user,
          organization,
          campaign,
          campaignDynamic,
        },
      })}
    </Switch>
  );
}
