import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath, isSelling} from '@core/lib/organizations';
import {t} from '@core/texts';
import Button, {ButtonIcon} from '@core/ui/Button';
import {
  DropdownMenu,
  DropdownMenuDivider,
  DropdownMenuTitle,
} from '@core/ui/DropdownMenu';
import {CleanLink} from '@core/ui/Link';
import {useOverlay} from '@core/ui/Overlay';
import {Snackbar} from '@core/ui/Snackbar';
import {Tooltip} from '@core/ui/Tooltip';
import {UserAvatar} from '@core/ui/UserAvatar';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  useSyncUserEmailWithSpotifyAccountMutation,
} from '@analytics/graphql-api';
import SupportTicketFormOverlay from '../help/SupportTicketFormOverlay';
import {useExportsContext} from '../notifications/ExportsNotificationContext';
import {Notifications} from '../notifications/Notifications';
import SettingsOverlay from '../settings/SettingsOverlay';

interface Props {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
}

export default function LayoutToolbar({user, organization}: Props) {
  const history = useHistory();
  const {pollExports, clearExports} = useExportsContext();
  const [emailUpdatedError, toggleEmailUpdatedError] = useOverlay();
  const [emailUpdated, toggleEmailUpdated] = useOverlay();
  const [openedSettings, toggleSettings] = useOverlay();
  const [openedSupportTicket, toggleSupportTicket] = useOverlay();
  const [syncUserEmailWithSpotifyAccountMutation] =
    useSyncUserEmailWithSpotifyAccountMutation();

  const syncUserEmail = async () => {
    try {
      await syncUserEmailWithSpotifyAccountMutation();
      toggleEmailUpdated(true);
    } catch (error) {
      toggleEmailUpdatedError(true);
    }
  };

  const orgsMenuItems =
    user.organizations?.map((org) => ({
      title: org.name,
      callback: () => {
        // if polling exports, stop when user changes org
        if (pollExports.length > 0) clearExports();
        history.push(createOrgPath(org));
      },
      value: org.id,
    })) ?? [];

  const inviteMenuItems = [
    ...(isSelling(organization)
      ? [
          {
            title: 'Invite Brand',
            to: createOrgPath(organization, '/manage/invite'),
          },
        ]
      : []),
    ...(organization.meRole === 'admin' && organization.slug !== 'spotify'
      ? [
          {
            title: 'Invite Team Member',
            to: createOrgPath(organization, '/manage/members/add'),
          },
        ]
      : []),
  ];

  return (
    <_Toolbar>
      <_LogoContainer>
        <_LogoLink aria-label='Home' to={createOrgPath(organization)}>
          Ad Analytics
        </_LogoLink>
        {process.env.API_ENV === 'demo' && <_DemoBanner>DEMO</_DemoBanner>}
      </_LogoContainer>
      <_ToolbarRight displayInviteButton={inviteMenuItems.length > 0}>
        <Notifications organization={organization} />
        <DropdownMenu
          items={[
            {
              title: 'Help Center',
              href: 'https://help.adanalytics.spotify.com/',
              target: '_blank',
            },
            {
              title: 'Glossary',
              href: 'https://help.adanalytics.spotify.com/spotify-ad-analytics-dashboard-glossary',
              target: '_blank',
            },
            {content: <DropdownMenuDivider />},
            {
              title: 'Status',
              href: 'https://status.adanalytics.spotify.com/',
              target: '_blank',
            },
            {content: <DropdownMenuDivider />},
            {
              title: 'Terms of Service',
              href: 'https://www.spotify.com/us/legal/ad-analytics-terms/',
              target: '_blank',
            },
            {
              title: 'Privacy Policy',
              href: 'https://www.spotify.com/us/legal/ad-analytics-privacy-policy/',
              target: '_blank',
            },
          ]}
          overlayProps={{
            fixed: true,
            horizontalAlign: 'center',
            verticalOffset: 4,
          }}>
          <Tooltip
            description='Help'
            overlayProps={{horizontalAlign: 'center', verticalOffset: 1}}>
            <ButtonIcon
              aria-label='Help'
              icon={'help-outline'}
              css={`
                padding: 0.25rem;
                & svg {
                  height: 1.625rem;
                  width: 1.625rem;
                }
              `}
            />
          </Tooltip>
        </DropdownMenu>
        <Tooltip
          description='Settings'
          overlayProps={{horizontalAlign: 'center', verticalOffset: 2}}>
          <ButtonIcon
            aria-label='Settings'
            icon='tune'
            onClick={() => toggleSettings()}
            css={`
              padding: 0.25rem;
              & svg {
                height: 1.5rem;
                width: 1.5rem;
              }
            `}
          />
        </Tooltip>
        {inviteMenuItems.length > 0 && (
          <DropdownMenu
            items={inviteMenuItems}
            overlayProps={{
              fixed: true,
              horizontalAlign: 'left',
              verticalOffset: 4,
            }}>
            <Button
              variant='outlined'
              buttonSize='sm'
              css={`
                margin: 0 0.125rem;
              `}>
              Invite
            </Button>
          </DropdownMenu>
        )}
        <DropdownMenu
          selectedValue={organization.id}
          items={[
            {content: <DropdownMenuTitle>Organizations:</DropdownMenuTitle>},
            ...orgsMenuItems,
            {content: <DropdownMenuDivider />},
            {title: 'Sync Spotify Email', callback: syncUserEmail},
            {title: 'Log Out', to: '/logout'},
          ]}
          overlayProps={{
            fixed: true,
            verticalOffset: 4,
          }}
          css={`
            padding: 0.25rem;
          `}>
          <Tooltip
            title={`${user.firstName} ${user.lastName}`}
            description={
              <>
                <div>{user.email}</div>
                <div>{organization.name}</div>
              </>
            }>
            <Button variant='unstyled'>
              <UserAvatar user={user} size='small' withHoverOutline />
            </Button>
          </Tooltip>
        </DropdownMenu>
      </_ToolbarRight>
      <Snackbar
        autoHideDuration={4000}
        opened={emailUpdatedError}
        severity='error'
        toggle={toggleEmailUpdatedError}>
        Email update failed: {t('errors.network')}
      </Snackbar>
      <Snackbar
        autoHideDuration={4500}
        opened={emailUpdated}
        severity='success'
        toggle={toggleEmailUpdated}>
        Email updated successfully!
      </Snackbar>
      {openedSupportTicket && (
        <SupportTicketFormOverlay
          user={user}
          opened={openedSupportTicket}
          toggle={toggleSupportTicket}
        />
      )}
      <SettingsOverlay
        opened={openedSettings}
        toggle={toggleSettings}
        user={user}
      />
    </_Toolbar>
  );
}

const _Toolbar = styled.div`
  align-items: center;
  background: var(--bg-default);
  display: grid;
  font-size: 0.875rem;
  font-weight: 500;
  grid-gap: 1.5rem;
  grid-template-columns: var(--app-left-nav-width) 1fr;
  height: 100%;
  line-height: 1;
  user-select: none;
  width: 100%;
`;

const _LogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-left: 1.75rem;
`;

const _DemoBanner = styled.div`
  background: var(--pink);
  border-radius: 2rem;
  color: var(--white);
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: -0.125rem;
  margin-left: 0.5rem;
  padding: 0.1875rem 0.5rem;
`;

const _ToolbarRight = styled.div<{displayInviteButton: boolean}>`
  align-items: center;
  color: var(--text-muted);
  display: grid;
  flex-shrink: 0;
  grid-gap: 0.875rem;
  grid-template-columns: ${(p) =>
    p.displayInviteButton
      ? 'repeat(5, max-content)'
      : 'repeat(4, max-content)'};
  height: 100%;
  margin-left: auto;
  padding: 0 1.5rem 0 1.175rem;
`;

const _LogoLink = styled(CleanLink)`
  font-size: 1.625rem;
  font-weight: var(--font-weight-extrabold);
  font-family: var(--font-family-title);
`;
