import {useState} from 'react';
import styled from 'styled-components';
import ChartTime from '@core/components/ChartTime';
import {comma, toPercent} from '@core/lib/filters';
import {Alert} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {DataTableColumn} from '@core/ui/DataTable';
import DataTable from '@core/ui/DataTable';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import Select from '@core/ui/Select';
import {CardStat} from '@core/ui/Stat';
import {useGetCampaignWebhookConsistencyQuery} from '@analytics/graphql-api';
import {useGetCampaignPrefixConsistencyQuery} from '@analytics/graphql-api';

const CampaignConsistencyData = ({consistency, params}) => {
  const columns = [
    {
      title: 'Day',
      accessor: 'day',
      type: 'string',
    },
    ...params.map(({title, key}) => ({
      title: title,
      accessor: key,
      type: 'number',
      fmt: comma,
    })),
    {
      title: 'BQ/BigTable Difference',
      accessor: 'diff',
      type: 'number',
      fmt: comma,
      description:
        'Difference between total BigQuery impressions (valid + invalid) and BigTable impressions.',
    },
    {
      title: 'BQ/BigTable Percentage',
      accessor: 'percentage',
      type: 'number',
      fmt: toPercent,
    },
  ];

  const series = params.reduce((obj, {key}) => {
    obj[key] =
      consistency?.map((r) => ({
        x: r.day,
        y: r[key],
      })) || [];
    return obj;
  }, {});

  return (
    <>
      <Card>
        <ChartTime series={series} interval='daily' height={300} />
      </Card>
      <Card rules={() => ({marginTop: '1.5rem'})}>
        <DataTable columns={columns} data={consistency} orderBy='day' />
      </Card>
    </>
  );
};

const InvalidImpressionsTable = ({data}) => {
  const invalidImpressionsData =
    data?.me?.organization.campaign.debug?.webhookInvalidImpressions;
  const invalidImpressionsColumns: DataTableColumn[] = [
    {
      title: 'Reason',
      accessor: 'reason',
      type: 'string',
    },
    {
      title: 'Count',
      accessor: 'count',
      type: 'number',
      fmt: comma,
    },
    {
      title: 'Example Record',
      accessor: 'exampleRecord',
      type: 'string',
    },
  ];

  return (
    <>
      <CardHeader>Invalid Impressions (by reason)</CardHeader>
      <Card rules={() => ({marginTop: '1.5rem'})}>
        <DataTable
          columns={invalidImpressionsColumns}
          data={invalidImpressionsData}
          orderBy='-count'
        />
      </Card>
    </>
  );
};

type ConsistencyBaseProps = {
  organizationId: string;
  campaignId: string;
};

type WebhookConsistencyProps = {
  campaignDynamicId?: string | null;
};

type PrefixConsistencyProps = {
  campaignPodcastId?: string | null;
  campaignEpisodeId?: string | null;
};

const WebhookCampaignConsistency = ({
  organizationId,
  campaignId,
  campaignDynamicId = null,
}: ConsistencyBaseProps & WebhookConsistencyProps) => {
  const {loading, error, data} = useGetCampaignWebhookConsistencyQuery({
    variables: {
      organizationId: organizationId,
      campaignId: campaignId,
      campaignDynamicId: campaignDynamicId,
    },
  });

  if (loading) {
    return <Loading centered />;
  }

  if (error) {
    return (
      <Card>
        <EmptyMessage>Error retreiving Webhook consistency data</EmptyMessage>
      </Card>
    );
  }

  const consistency =
    data?.me?.organization.campaign.debug?.webhookConsistency.map(
      ({bigquery, diff, bigqueryInvalid, bigtable, ...rest}) => ({
        bigquery,
        diff,
        bigtable,
        percentage: Math.max(bigquery, bigtable)
          ? diff / Math.max(bigquery, bigtable)
          : 0,
        bigqueryValid: bigquery - (bigqueryInvalid ?? 0),
        bigqueryInvalid,
        ...rest,
      })
    );

  const bigqueryTotal =
    consistency?.reduce((a, {bigquery}) => (a += bigquery), 0) ?? 0;

  const bigtableTotal =
    consistency?.reduce((a, {bigtable}) => (a += bigtable), 0) ?? 0;

  const diffTotal = Math.abs(bigtableTotal - bigqueryTotal);
  const off = Math.max(bigqueryTotal, bigtableTotal)
    ? diffTotal / Math.max(bigqueryTotal, bigtableTotal)
    : 0;

  const bigqueryValidTotal =
    consistency?.reduce((a, {bigqueryValid}) => (a += bigqueryValid), 0) ?? 0;
  const bigqueryInvalidTotal =
    consistency?.reduce(
      (a, {bigqueryInvalid}) => (a += bigqueryInvalid ?? 0),
      0
    ) ?? 0;

  return (
    <>
      <StatContainer>
        <CardStat
          value={comma(bigqueryValidTotal)}
          title='BigQuery Valid Impressions'
          description='Impressions that made it to the brand_impressions table.'
        />
        <CardStat
          value={comma(bigqueryInvalidTotal)}
          title='BigQuery Invalid Impressions'
          description='Impressions that are not valid for attribution; found in the webhook_invalid_impressions table.'
        />
        <CardStat value={comma(bigtableTotal)} title='BigTable Impressions' />
        <CardStat
          value={comma(diffTotal)}
          title='BigQuery/BigTable Difference'
          description='Difference between total bigquery impressions (valid + invalid) and bigtable impressions.'
        />
        <CardStat value={toPercent(off)} title='Percentage Off' />
      </StatContainer>
      <CampaignConsistencyData
        consistency={consistency}
        params={[
          {
            key: 'bigtable',
            title: 'BigTable',
          },
          {
            key: 'bigqueryValid',
            title: 'BigQuery Valid',
          },
          {
            key: 'bigqueryInvalid',
            title: 'BigQuery Invalid',
          },
        ]}
      />
      {bigqueryInvalidTotal ? <InvalidImpressionsTable data={data} /> : null}
    </>
  );
};

const PrefixCampaignConsistency = ({
  organizationId,
  campaignId,
  campaignPodcastId = null,
  campaignEpisodeId = null,
}: ConsistencyBaseProps & PrefixConsistencyProps) => {
  const {loading, error, data} = useGetCampaignPrefixConsistencyQuery({
    variables: {
      organizationId: organizationId,
      campaignId: campaignId,
      campaignPodcastId: campaignPodcastId,
      campaignEpisodeId: campaignEpisodeId,
    },
  });

  if (loading) {
    return <Loading centered />;
  }

  if (error) {
    return (
      <Card>
        <EmptyMessage>Error retreiving Prefix consistency data</EmptyMessage>
      </Card>
    );
  }

  const consistency =
    data?.me?.organization.campaign.debug?.prefixConsistency.map(
      ({bigquery, publisher, diff, bigtable, ...rest}) => ({
        bigquery,
        publisher,
        diff,
        bigtable,
        percentage: Math.max(bigquery, publisher ?? 0, bigtable)
          ? diff / Math.max(bigquery, publisher ?? 0, bigtable)
          : 0,
        ...rest,
      })
    );

  const bigqueryTotal =
    consistency?.reduce((a, {bigquery}) => (a += bigquery), 0) ?? 0;
  const publisherTotal =
    consistency?.reduce((a, {publisher}) => (a += publisher ?? 0), 0) ?? 0;
  const bigtableTotal =
    consistency?.reduce((a, {bigtable}) => (a += bigtable), 0) ?? 0;

  const diffTotal =
    Math.max(bigtableTotal, bigqueryTotal, publisherTotal) -
    Math.min(bigtableTotal, bigqueryTotal, publisherTotal);

  const off = Math.max(bigtableTotal, bigqueryTotal, publisherTotal)
    ? diffTotal / Math.max(bigtableTotal, bigqueryTotal, publisherTotal)
    : 0;

  return (
    <>
      <StatContainer>
        <CardStat value={comma(bigqueryTotal)} title='BigQuery Impressions' />
        <CardStat value={comma(bigtableTotal)} title='BigTable Impressions' />
        <CardStat value={comma(publisherTotal)} title='Publisher Impressions' />
        <CardStat
          value={comma(diffTotal)}
          title='BigQuery/BigTable/Publisher Difference'
        />
        <CardStat
          value={toPercent(off)}
          title='BigQuery/BigTable/Publisher Percentage Off'
        />
      </StatContainer>
      <CampaignConsistencyData
        consistency={consistency}
        params={[
          {
            key: 'bigtable',
            title: 'BigTable',
          },
          {
            key: 'bigquery',
            title: 'BigQuery',
          },
          {
            key: 'publisher',
            title: 'Publisher',
          },
        ]}
      />
    </>
  );
};

const CampaignConsistency = ({
  campaignDynamicId = null,
  campaignPodcastId = null,
  ...props
}: ConsistencyBaseProps & WebhookConsistencyProps & PrefixConsistencyProps) => {
  const [kind, setKind] = useState(
    campaignPodcastId !== null ? 'prefix' : 'webhook'
  );

  return (
    <>
      <CardHeader
        rightContent={
          campaignDynamicId === null &&
          campaignPodcastId === null && (
            <Select
              items={[
                {title: 'Dynamic Line Items', value: 'webhook'},
                {title: 'Embedded Ads', value: 'prefix'},
              ]}
              onSelect={({value}) => {
                setKind(value);
              }}
              defaultValue={kind}
              small
              outline
            />
          )
        }>
        Data Consistency
      </CardHeader>
      <Alert
        severity='info'
        css={`
          margin: 0 1.5rem;
        `}>
        I'm a very specific tool that looks for differences between the BigTable
        database and the BigQuery database(s). Small deviations are acceptable,
        less than 5 percentage point. Percentages larger should be brought to
        the support team's attention.
        <br />
        For Prefix, we include the Publisher impressions table that we (will)
        use for backdating episodes. The Publisher line will <b>NOT</b> affect
        attribution.
      </Alert>

      {kind == 'webhook' ? (
        <WebhookCampaignConsistency
          campaignDynamicId={campaignDynamicId}
          {...props}
        />
      ) : (
        <PrefixCampaignConsistency
          campaignPodcastId={campaignPodcastId}
          {...props}
        />
      )}
    </>
  );
};

export default CampaignConsistency;

const StatContainer = styled.div`
  display: grid;
  gap: var(--spacing-4);
  grid-template-columns: repeat(4, 1fr);
  margin: var(--spacing-6);
`;
