import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {comma} from '@core/lib/filters';
import {TEXT_NORMAL} from '@core/style';
import DataTable from '@core/ui/DataTable';
import Loading from '@core/ui/Loading';
import BrandsOverviewQuery from '@analytics/graphql-api/_old_queries/research/BrandsOverviewQuery';

const BrandsTable = ({organization, data: {loading, me}}) => {
  if (loading) {
    return <Loading centered />;
  }

  const data = me.organization.research.brand.brandsOverview.results;

  const columns = [
    {
      title: 'Brand',
      accessor: 'name',
      type: 'string',
      rule: () => ({fontWeight: 500, color: TEXT_NORMAL}),
    },
    {
      title: 'Ads',
      accessor: 'adsCount',
      type: 'number',
      fmt: comma,
    },
    {
      title: 'Episodes',
      accessor: 'episodesCount',
      type: 'number',
      fmt: comma,
    },
    {
      title: 'Podcasts',
      accessor: 'feedsCount',
      type: 'number',
      fmt: comma,
    },
  ];

  return (
    <DataTable
      data={data}
      columns={columns}
      onClickRowPath={({id}) => `/research/brands/${id}`}
      orderBy='-adsCount'
    />
  );
};

export default graphql(BrandsOverviewQuery, {
  options: ({organization, limit = 10, after}) => ({
    variables: {
      id: organization.id,
      limit,
      after: after ? after : '2018-07-12',
    },
  }),
})(BrandsTable);
