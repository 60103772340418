import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {Redirect} from 'react-router-dom';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import {ButtonIcon} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import CampaignNoiseForm from '@analytics/components/forms/CampaignNoiseForm';
import {
  CampaignDocument,
  LiftReportPreCreateCampaignDocument,
} from '@analytics/graphql-api';
import CreateCampaignNoiseMutation from '@analytics/graphql-api/_old_mutations/CreateCampaignNoise';

class CampaignNoiseCreate extends React.Component {
  state = {
    loading: false,
    complete: false,
    errors: [],
  };

  onSubmit = ({
    startAt,
    endAt,
    name,
    targetImpressions,
    usePublisherImpressions,
  }) => {
    const {createCampaignNoise, campaign} = this.props;

    if (!startAt || !endAt) {
      return;
    }

    this.setState({
      loading: true,
    });

    const {id} = campaign;

    createCampaignNoise({
      campaignId: id,
      startAt: startAt.format('YYYY-MM-DD'),
      endAt: endAt.format('YYYY-MM-DD'),
      name: name
        ? name
        : `${startAt.format('YYYY-MM-DD')} - ${endAt.format('YYYY-MM-DD')}`,
      targetImpressions: parseInt(targetImpressions),
      usePublisherImpressions,
    })
      .then(() => {
        this.setState({
          complete: true,
        });
      })
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  render() {
    const {organization, campaign, data} = this.props;
    const {loading, complete, errors} = this.state;

    if (data.loading) {
      return <Loading fixed />;
    }

    const {
      downloads: {hourly},
      stateOverview: {
        pixel: {historical},
      },
    } = data.me.organization.campaign;

    if (complete) {
      return (
        <Redirect
          to={createOrgPath(organization, `/campaigns/${campaign.slug}/lift`)}
        />
      );
    }

    return (
      <ResponsiveContent containerSize='medium'>
        <CardHeader
          tag='h2'
          rightContent={
            <ButtonIcon
              icon='close'
              to={createOrgPath(
                organization,
                `/campaigns/${campaign.slug}/lift`
              )}
            />
          }>
          Create Household Control Group
        </CardHeader>
        <Card>
          <CampaignNoiseForm
            loading={loading}
            onSubmit={this.onSubmit}
            errors={errors}
            organization={organization}
            campaign={campaign}
            onErrorClose={() => {
              this.setState({
                errors: [],
              });
            }}
          />
        </Card>
      </ResponsiveContent>
    );
  }
}

export default compose(
  graphql(LiftReportPreCreateCampaignDocument, {
    options: ({organization, campaign}) => {
      const before = dayjs(campaign.endAt).isAfter(dayjs())
        ? dayjs()
        : dayjs(campaign.endAt);

      return {
        fetchPolicy: 'no-cache',
        variables: {
          organizationId: organization.id,
          campaignId: campaign.id,
          after: dayjs(campaign.startAt)
            .subtract(1, 'month')
            .format('YYYY-MM-DD'),
          before: before.format('YYYY-MM-DD'),
        },
      };
    },
  }),
  graphql(CreateCampaignNoiseMutation, {
    props: ({ownProps: {organization, campaign}, mutate}) => ({
      createCampaignNoise: (input) => {
        return mutate({
          variables: {
            input,
          },
          update: (
            store,
            {
              data: {
                createCampaignNoise: {campaignNoise},
              },
            }
          ) => {
            const query = {
              query: CampaignDocument,
              variables: {
                organizationId: organization.id,
                slug: campaign.slug,
              },
            };

            query.data = store.readQuery(query);

            const campaignNoises =
              query.data.me.organization.campaign.campaignNoises;

            campaignNoises.push(campaignNoise);

            query.data.me.organization.campaign.campaignNoises = campaignNoises;
            store.writeQuery(query);
          },
        });
      },
    }),
  })
)(CampaignNoiseCreate);
