import {useMemo, useState} from 'react';
import styled from 'styled-components';
import {t} from '@core/texts';
import {Button} from '@core/ui/Button';
import {Card} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import EmptyMessage from '@core/ui/EmptyMessage';
import {LoadingSpinner} from '@core/ui/Loading';
import TrackingUrlsTableBulkOverlay from '@analytics/components/tracking-urls/TrackingUrlsTableBulkOverlay';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedCampaignsConfirmTrackingUrls,
  FetchedOrganization,
  useCampaignTrackingUrlsQuery,
} from '@analytics/graphql-api';
import getTrackingUrlsTableColumns from './TrackingUrlsTableColumns';
import TrackingUrlsTableSelect from './TrackingUrlsTableSelect';
import {
  CampaignTrackingData,
  generateCsvString,
  getTrackingUrlsTableData,
  showTrackingConfirm,
} from './helpers';

type Action = {
  actionName: 'confirm' | 'unconfirm' | null;
  rows: CampaignTrackingData[];
};

type Props = {
  campaign: FetchedCampaign | FetchedCampaignsConfirmTrackingUrls[number];
  organization: FetchedOrganization | FetchedBuySideOrganization;
};

export default function TrackingUrlsTable({campaign, organization}: Props) {
  const [action, setAction] = useState<Action>({actionName: null, rows: []});
  const {data, loading, error} = useCampaignTrackingUrlsQuery({
    variables: {
      organizationId: organization.id,
      campaignId: campaign?.id,
    },
  });

  const tableData = useMemo(() => getTrackingUrlsTableData(data), [data]);
  const columns = getTrackingUrlsTableColumns({
    organization,
    campaign,
  });

  return (
    <>
      <_Description>
        Select a provider and copy the URL of the chosen ad, then add it to the
        dynamic or streaming insertion from the provider. Please install pixels
        at least one week prior to campaign launch.
      </_Description>
      <_ButtonContainer>
        <_SelectContainer>
          Apply to all:
          <TrackingUrlsTableSelect
            campaignId={campaign?.id}
            tableData={tableData}
          />
        </_SelectContainer>
        <Button
          download={`${campaign?.name.toLowerCase()}_tracking-urls.csv`}
          href={`data:text/csv;base64,${btoa(generateCsvString(tableData))}`}
          buttonSize='sm'
          color='secondary'>
          Export to CSV
        </Button>
      </_ButtonContainer>
      <Card scrollable rules={() => ({margin: '1rem 0 0', height: '100%'})}>
        {loading ? (
          <LoadingSpinner centered />
        ) : error ? (
          <EmptyMessage>{t('errors.network')}</EmptyMessage>
        ) : (
          <DataTable
            data={tableData}
            columns={columns}
            emptyMessageText='No ads found.'
            bulkActions={
              showTrackingConfirm(organization, campaign)
                ? [
                    {
                      title: 'Confirm Selected',
                      onClick: (rows) =>
                        setAction({
                          actionName: 'confirm',
                          rows: rows as CampaignTrackingData[],
                        }),
                    },
                    {
                      title: 'Unconfirm Selected',
                      onClick: (rows) =>
                        setAction({
                          actionName: 'unconfirm',
                          rows: rows as CampaignTrackingData[],
                        }),
                    },
                  ]
                : []
            }
          />
        )}
      </Card>
      {!!action.actionName && (
        <TrackingUrlsTableBulkOverlay
          action={action}
          toggleClose={() => setAction({actionName: null, rows: []})}
        />
      )}
    </>
  );
}

const _Description = styled.div`
  margin: 0 0 1rem;
`;

const _ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

const _SelectContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;
