import Select from '@core/ui/Select';
import {SelectProps} from '@core/ui/Select/Select';
import {INTERVALS, RANGES} from './_helpers';

type Props = Omit<SelectProps, 'items'>;

export function UsageRangeSelect({defaultValue, onSelect, ...props}: Props) {
  return (
    <Select
      defaultValue={defaultValue}
      items={RANGES}
      onSelect={({value}) => onSelect?.(value)}
      outline
      small
      {...props}
    />
  );
}

export function UsageIntervalSelect({defaultValue, onSelect, ...props}: Props) {
  return (
    <Select
      defaultValue={defaultValue}
      items={INTERVALS}
      onSelect={({value}) => onSelect?.(value)}
      outline
      small
      {...props}
    />
  );
}
