import {useQuery} from '@apollo/client';
import {Element} from '@core/style';
import EmptyMessage from '@core/ui/EmptyMessage';
import {FeedImage} from '@core/ui/Image';
import {Link} from '@core/ui/Link';
import {LoadingSpinner} from '@core/ui/Loading';
import {advisorPodcastURL} from '@analytics/components/nav/AdvisorPodcastNav';
import {
  FetchedAdvisorShow,
  FetchedBuySideOrganization,
  FetchedOrganization,
  useGetPodcastSimilarAudienceQuery,
} from '@analytics/graphql-api';
import ADVISOR_PODCAST_SAME_PUBLISHER_QUERY from '@analytics/graphql-api/_old_queries/advisor/AdvisorPodcastSamePublisherQuery';

const AdvisorPodcastMoreShows = ({
  organization,
  feeds,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  feeds: TSFixMe[];
}) => {
  return (
    <>
      <Element
        rules={() => ({
          display: 'grid',
          gap: '2rem',
          gridTemplateColumns: 'repeat(3, auto)',
        })}>
        {feeds?.length > 0 &&
          feeds.map((feed) => (
            <Link
              key={feed.id}
              to={advisorPodcastURL(organization, feed)}
              css={`
                color: var(--text-subtle);
                min-width: 0;
                text-decoration: none;
                :hover img {
                  box-shadow: var(---shadow-lg);
                }
              `}>
              <FeedImage feed={feed} />
              <Element
                rules={() => ({
                  fontWeight: 500,
                  fontSize: '0.85rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                })}>
                {feed.title}
              </Element>
            </Link>
          ))}
      </Element>
    </>
  );
};

const AdvisorSimilarAudience = ({
  organization,
  podcast,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  podcast: FetchedAdvisorShow;
}) => {
  const {data, loading, error} = useGetPodcastSimilarAudienceQuery({
    variables: {
      organizationId: organization.id,
      id: podcast?.id,
    },
  });

  if (loading || !data)
    return (
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
        })}>
        <LoadingSpinner />
      </Element>
    );

  const similarAudienceFeeds =
    data?.me?.organization?.advisor?.show?.similarAudiences ?? [];

  if (error || !similarAudienceFeeds.length)
    return <EmptyMessage>No shows with a similar audience found</EmptyMessage>;

  return (
    <AdvisorPodcastMoreShows
      organization={organization}
      feeds={similarAudienceFeeds}
    />
  );
};

const AdvisorSamePublisher = ({
  organization,
  podcast,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  podcast: FetchedAdvisorShow;
}) => {
  const {data, loading, error} = useQuery(
    ADVISOR_PODCAST_SAME_PUBLISHER_QUERY,
    {
      variables: {
        organizationId: organization.id,
        id: podcast?.id,
      },
    }
  );

  if (loading) {
    return (
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
        })}>
        <LoadingSpinner />
      </Element>
    );
  }

  const samePublisherFeeds =
    data?.me?.organization?.advisor?.show?.samePublisher ?? [];

  if (error || !samePublisherFeeds.length) {
    return (
      <EmptyMessage>
        No additional shows by {podcast?.publisher?.name} found
      </EmptyMessage>
    );
  }

  return (
    <AdvisorPodcastMoreShows
      organization={organization}
      feeds={samePublisherFeeds}
    />
  );
};

export {AdvisorSimilarAudience, AdvisorSamePublisher};
export default AdvisorPodcastMoreShows;
