import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import useQueryParams from '@core/lib/useQueryParams';
import useRoutes from '@core/lib/useRoutes';
import {MeasureInsightsNav} from '@analytics/components/nav/MeasureInsightsNav';
import {measureInsightsRoutes} from '@analytics/lib/routes/measureRoutes';
import {MeasureRouteProps} from '..';
import {InsightsPerformance} from './InsightsPerformance';
import InsightsFrequencyIndex from './frequency';
import InsightsLocationsIndex from './locations';
import {InsightsOverlapIndex} from './overlap';
import {InsightsPlayersIndex} from './players';
import {InsightsReachIndex} from './reach';

const DEFAULT_DATES = [dayjs().subtract(14, 'days'), dayjs()];

export type MeasureInsightsRouteProps = MeasureRouteProps & {
  after: string;
  before: string;
};

const MeasureInsights = ({organization, user}: MeasureRouteProps) => {
  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, '/measure/overview'),
    basePath: '/:part(b|a|s|p)/:slug/measure/overview',
    routes: measureInsightsRoutes(),
  });

  const {
    params: {after, before},
  } = useQueryParams(['after', 'before'], {
    after: DEFAULT_DATES[0].format('YYYY-MM-DD'),
    before: DEFAULT_DATES[1].format('YYYY-MM-DD'),
  });

  if (!after || !before) {
    return null;
  }

  return (
    <>
      <MeasureInsightsNav organization={organization} />
      <div>
        {renderRoutes({
          components: {
            locations: InsightsLocationsIndex,
            frequency: InsightsFrequencyIndex,
            overlap: InsightsOverlapIndex,
            performance: InsightsPerformance,
            players: InsightsPlayersIndex,
            reach: InsightsReachIndex,
          },
          extraProps: {
            organization,
            user,
            after,
            before,
          },
        })}
      </div>
    </>
  );
};

export default MeasureInsights;
