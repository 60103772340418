import CompanySelect from '@core/components/CompanySelect';
import ProductSelector from '@core/components/ProductSelector';
import {createStyledElement} from '@core/style';
import {Element} from '@core/style';

const Label = createStyledElement('div', () => ({
  fontWeight: '500',
  marginBottom: '0.625rem',
}));

const Help = createStyledElement('div', () => ({
  color: 'var(--text-muted)',
  cursor: 'pointer',
  fontSize: '13px',
  lineHeight: '1.4',
  marginTop: '0.625rem',
}));

const TranscriptCompanyProduct = ({
  companyId,
  onCompanyChange,
  onProductChange,
}) => {
  return (
    <>
      <Label>Company:</Label>
      <Element>
        <CompanySelect
          rules={({theme}) => ({background: theme.bgTertiary})}
          onSelect={onCompanyChange}
        />
        <Help>
          The company behind the product or service advertised. In the case of
          Rocket Mortgage by Quicken Loans, Quicken Loans would be the company
          and Rocket Mortgage the product.
        </Help>
      </Element>
      <Label rules={() => ({marginTop: '1.5rem'})}>Product:</Label>
      <Element>
        <ProductSelector
          rules={({theme}) => ({background: theme.bgTertiary})}
          companyId={companyId}
          onSelect={onProductChange}
        />
        <Help>
          The product being advertised. In the case of "The Everyday Card from
          American Express". The product is the "The Everyday Card". Note there
          is no mention of a product it can be none. "Sponsored by Blue Apron"
          is an example of this.
        </Help>
      </Element>
    </>
  );
};

export default TranscriptCompanyProduct;
