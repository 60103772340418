import {Dispatch, Fragment, SetStateAction} from 'react';
import {css} from 'styled-components';
import {Icon} from '@core/ui/Icon';
import {UserObject} from '@analytics/graphql-api';
import {AdvisorColumn} from '../types';
import {CategoriesHeader} from './CategoriesHeader';
import {SIDEBAR_NAV_ITEMS} from './utils';

interface Props {
  user: UserObject;
  currentActiveColumns: AdvisorColumn[];
  selectedColumnGroup: (typeof SIDEBAR_NAV_ITEMS)[number];
  setSelectedColumnGroup: Dispatch<
    SetStateAction<(typeof SIDEBAR_NAV_ITEMS)[number]>
  >;
}
export function LeftColumn({
  user,
  currentActiveColumns,
  selectedColumnGroup,
  setSelectedColumnGroup,
}: Props) {
  return (
    <div
      css={{
        borderRight: '2px solid var(--bg-muted)',
        padding: '0.375rem',
        width: '14.5rem',
      }}>
      {SIDEBAR_NAV_ITEMS.map((columnGroup, idx) => (
        <Fragment key={`${columnGroup.columnGroupId}|${idx}`}>
          <SelectCategoryButton
            columnGroup={columnGroup}
            selectedColumnGroup={selectedColumnGroup}
            setSelectedColumnGroup={setSelectedColumnGroup}
            user={user}
            currentActiveColumns={currentActiveColumns}
          />
          {idx === 0 && <CategoriesHeader />}
        </Fragment>
      ))}
    </div>
  );
}

function SelectCategoryButton({
  columnGroup,
  selectedColumnGroup,
  setSelectedColumnGroup,
  user,
  currentActiveColumns,
}: Props & {columnGroup: (typeof SIDEBAR_NAV_ITEMS)[number]}) {
  const isActive = (columnGroup: typeof selectedColumnGroup) =>
    selectedColumnGroup === columnGroup ||
    (!columnGroup.columnGroupId && !selectedColumnGroup);

  return (
    <button
      type='button'
      onMouseDown={(e) => {
        // Prevent focus redirection
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={() => setSelectedColumnGroup(columnGroup)}
      css={buttonCss(isActive(columnGroup), columnGroup)}>
      {'iconName' in columnGroup && (
        <Icon
          icon={columnGroup.iconName}
          css={{
            color: isActive(columnGroup) ? 'var(--blue)' : 'var(--icon-subtle)',
            height: '1.25rem',
            width: '1.25rem',
          }}
        />
      )}
      <span
        css={{
          fontSize: '0.875rem',
          fontWeight: isActive(columnGroup) ? 500 : 400,
        }}>
        {columnGroup.title}
        {!columnGroup.columnGroupId && ` (${currentActiveColumns.length})`}
      </span>
    </button>
  );
}

const buttonCss = (isActive: boolean, columnGroup: any) =>
  css({
    alignItems: 'center',
    background: isActive ? 'var(--bg-default-hover)' : 'transparent',
    borderRadius: '0.5rem',
    color: isActive ? 'var(--blue)' : 'inherit',
    cursor: 'pointer',
    display: 'grid',
    gridGap: '0.625rem',
    gridTemplateColumns: 'iconName' in columnGroup ? '1.5rem 1fr' : '1fr',
    padding: '0.375rem 0.75rem',
    transition: 'background 0.2s',
    width: '100%',
    textAlign: 'left',
    ':hover': {
      background: 'var(--bg-default-hover)',
      color: 'var(--blue)',
    },
    ':hover svg': {
      color: 'var(--blue)',
    },
    ':focus': {
      outline: '2px solid var(--blue-80)',
    },
  });
