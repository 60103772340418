import {
  FetchedCampaign,
  FetchedCampaignDynamic,
  FetchedCampaignDynamicEpisode,
  FetchedCampaignDynamicPodcast,
  FetchedCampaignStreaming,
} from '@analytics/graphql-api';
import useCampaignStats from '@analytics/lib/hooks/useCampaignStats';
import {_CampaignImpressionsCard} from './_CampaignImpressionsCard';
import {_CampaignPerformaceCard} from './_CampaignPerformaceCard';

const _CampaignStatsBase = ({
  instance,
  campaign,
}: {
  instance:
    | FetchedCampaign
    | FetchedCampaignDynamic
    | FetchedCampaignDynamicPodcast
    | FetchedCampaignDynamicEpisode
    | FetchedCampaignStreaming;
  campaign: FetchedCampaign;
}) => {
  const stats = useCampaignStats({campaign, instance});

  return (
    <div
      css={`
        display: grid;
        grid-gap: 1.5rem;
        grid-template-columns: 31.25rem 1fr;
        margin: 2.25rem 1.5rem 1.25rem;
      `}>
      <_CampaignImpressionsCard stats={stats} goal={instance?.goal ?? 0} />
      <_CampaignPerformaceCard stats={stats} campaign={campaign} />
    </div>
  );
};

export default _CampaignStatsBase;
