import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {CampaignsRouteProps} from '../../_routes';

export const toPubCreateCampaignBasePath = (
  organization: FetchedOrganization | FetchedBuySideOrganization
) => createOrgPath(organization, 'campaigns/create/publisher');

export const getPubCreateCampaignRoutes = ({
  organization,
}: Pick<CampaignsRouteProps, 'organization'>) => {
  return useRoutes({
    baseUrl: toPubCreateCampaignBasePath(organization),
    basePath: '/:part(b|a|s|p)/:slug/campaigns/create/publisher',
    routes: [
      {
        key: 'campaignType',
        path: '',
        exact: true,
      },
      {
        key: 'brandDetails',
        path: ':kind',
        exact: true,
      },
      {
        key: 'brandInvite',
        path: ':kind/:companyId',
        exact: true,
      },
      {
        key: 'campaignDetails',
        path: ':kind/:companyId/details',
        exact: true,
      },
    ],
  });
};
