import {gql} from '@apollo/client';

export default gql`
  query getSavedSearches($organizationId: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        advisor {
          id
          savedSearches {
            data
            id
            name
            orgDefault
            version

            user {
              id
              firstName
              lastName
            }

            organization {
              id
            }

            updatedAt
            createdAt
          }
        }
      }
    }
  }
`;
