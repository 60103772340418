import {useState} from 'react';
import {Card, CardHeader} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {Text} from '@core/ui/Text';
import {ExportButton} from '@analytics/components/ExportOverlay';
import PublisherSelect from '@analytics/components/research/PublisherSelector';
import {ManageAffinityRouteProps} from '.';

export default function Affinity({organization}: ManageAffinityRouteProps) {
  const [publisherId, setPublisherId] = useState<string | undefined>();
  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader>Export Affinity</CardHeader>
      <Card>
        <Stack direction='column' spacing='var(--spacing-4)'>
          <Text>Don't use this unless you have permission all around.</Text>
          <Stack spacing='var(--spacing-2)'>
            <PublisherSelect
              organization={organization}
              onSelect={(pub) => setPublisherId(pub.id)}
              css={{flex: 1}}
            />
            <ExportButton
              objectType='organization'
              kind='affinity'
              pk={organization.id}
              organizationId={organization.id}
              params={{
                ...(publisherId && {
                  publisher_id: publisherId,
                }),
              }}
              disabled={!publisherId}>
              Export
            </ExportButton>
          </Stack>
        </Stack>
      </Card>
    </ResponsiveContent>
  );
}
