import {useState} from 'react';
import {
  ButtonPrimary,
  ButtonSecondary,
  Text,
} from '@spotify-internal/encore-web';
import {csvParse} from 'd3-dsv';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {Stack} from '@core/ui/Layout';
import {Overlay} from '@core/ui/Overlay';

export type ImportsType = {
  dynamics: Array<Record<string, string>>;
  embedded: Array<Record<string, string>>;
  streaming: Array<Record<string, string>>;
};

export interface Props {
  opened: boolean;
  onSubmit: (data: ImportsType) => void;
  toggle: (opened: boolean) => void;
}

export const BulkImportOverlay = ({opened, toggle, onSubmit}: Props) => {
  const [imports, setImports] = useState<ImportsType>({
    dynamics: [],
    embedded: [],
    streaming: [],
  });

  const handleFileSelect = (evt) => {
    const file = evt.target.files[0];
    const name = evt.target.name;

    if (file) {
      const reader = new FileReader();

      reader.onload = function (evt) {
        if (!evt.target) return;
        const text = evt.target.result;
        const data = parseData(csvParse(text as string));
        setImports((imports) => ({...imports, [name]: data}));
      };
      reader.readAsText(file);
    }
  };

  const handleSubmit = () => {
    onSubmit(imports);
    toggle(false);
  };

  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      centered
      withBackdrop
      withShadow
      css={`
        max-width: 900px;
        padding: 30px;
      `}>
      <Text
        as='h2'
        css={`
          margin-bottom: 1rem;
        `}>
        Import Line Items (INTERNAL)
      </Text>
      <Text
        as='p'
        css={`
          margin-bottom: 2rem;
        `}>
        Please download the template and fill in your ads data. Do not add or
        modify the columns in the template; it is crucial for correctly
        formatting your data. This ensures an efficient and error-free import
        process.
      </Text>
      <ButtonSecondary
        href='https://docs.google.com/spreadsheets/d/1RRHaR00vt8LL4QGY_ZB3AmVYOwC0T5URt7eNUkOXOH4/edit?usp=sharing'
        target='_blank'
        rel='noopener noreferrer'
        css={`
          margin-bottom: 2rem;
        `}>
        Download Sheet Template
      </ButtonSecondary>
      <StyledRow>
        <Text as='p' variant='titleSmall'>
          Dynamic Ads
        </Text>
        <input
          name='dynamics'
          type='file'
          accept='.csv'
          onChange={handleFileSelect}
        />
      </StyledRow>
      <StyledRow
        css={`
          color: var(--text-muted);
        `}>
        <Text as='p' variant='titleSmall'>
          Embedded ads (coming soon)
        </Text>
        <input
          name='embedded'
          type='file'
          accept='.csv'
          disabled
          onChange={handleFileSelect}
        />
      </StyledRow>
      <StyledRow>
        <Text as='p' variant='titleSmall'>
          Streaming Ads
        </Text>
        <input
          name='streaming'
          type='file'
          accept='.csv'
          onChange={handleFileSelect}
        />
      </StyledRow>
      <Stack
        alignItems='center'
        justifyContent='flex-end'
        gap={5}
        css={`
          margin-top: 20px;
        `}>
        <ButtonSecondary onClick={() => toggle(false)}>Cancel</ButtonSecondary>
        <ButtonPrimary onClick={handleSubmit}>Import Ads</ButtonPrimary>
      </Stack>
    </Overlay>
  );
};

const StyledRow = styled.div`
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 200px max-content;
  margin-bottom: 10px;
`;

const columnKeyMap = {
  'Name*': {propName: 'name'},
  'Goal*': {propName: 'goal', fmt: convertToNumber},
  'Cost*': {propName: 'cost', fmt: convertToNumber},
  'Duration*': {propName: 'duration', fmt: convertToNumber},
  'Roll Placement': {
    cb: (entry, resp) => {
      if (!entry['Roll Placement']) return;
      const placement = entry['Roll Placement'].toLowerCase();
      resp.isPreRoll = placement === 'pre';
      resp.isMidRoll = placement === 'mid';
      resp.isPostRoll = placement === 'post';
    },
  },
  'Discount Code': {propName: 'discountCode'},
  'Discount URL': {propName: 'discountUrl'},
  'Effective URL': {propName: 'effectiveUrl'},
  'ART19 Secret': {propName: 'art19Secret'},
  'Ad Type': {
    propName: 'dynamicType',
    fmt: (str) => {
      const map = {'Host Read': 'host_read', Produced: 'produced', 'N/A': 'na'};
      return map[str];
    },
  },
  'Start Date': {
    cb: startEndDateCb,
  },
  'End Date': {
    cb: startEndDateCb,
  },
};

function parseData(data) {
  return data.map((entry) => {
    const parsedEntry = Object.keys(entry).reduce((resp, key) => {
      const {propName, cb, fmt} = columnKeyMap[key];
      if (cb) {
        cb(entry, resp);
      } else if (propName) {
        const value = fmt ? fmt(entry[key]) : entry[key];
        resp[propName] = value;
      }
      return resp;
    }, {});
    return parsedEntry;
  });
}

function convertToNumber(str) {
  const numericString = str.replace(/[^\d.-]/g, '');
  return parseFloat(numericString);
}

function startEndDateCb(entry, resp) {
  let startDate;
  let endDate;
  try {
    startDate = entry['Start Date'] ? dayjs(entry['Start Date']) : null;
    endDate = entry['End Date'] ? dayjs(entry['End Date']) : null;
  } catch (e) {
    // ignore
  }
  if (!startDate?.isValid() && !endDate?.isValid()) return;
  resp.expectedStartAt = startDate.toISOString();
  resp.expectedEndAt = endDate.toISOString();
}
