import {Icon} from '../Icon';
import {Stack, StackProps} from '../Layout';
import {Text} from '../Text';

type Step = {title: string; value: string};

export interface StepsProps extends StackProps {
  steps: Readonly<Step[]>;
  active: string;
}

export default function Steps({
  steps,
  active: activeProp,
  ...props
}: StepsProps) {
  const activeStepIdx = steps.findIndex(({value}) => activeProp === value);

  if (activeStepIdx === -1) {
    throw new Error(
      `Invalid active step '${activeProp}': active step not found in steps`
    );
  }

  return (
    <Stack
      alignItems='center'
      direction='row'
      spacing={9}
      {...props}
      css={`
        cursor: default;
      `}>
      {steps.map(({title, value}, i) => {
        const complete = i < activeStepIdx;
        const inProgress = value === activeProp;

        return (
          <Stack
            alignItems='center'
            direction='row'
            key={value}
            spacing={2}
            aria-label={`Step ${i + 1}, ${title}, is ${
              complete ? 'complete' : inProgress ? 'in progress' : 'incomplete'
            }`}>
            {complete ? (
              <CompletedStepIcon />
            ) : (
              <UncompletedStepIcon inProgress={inProgress}>
                {i + 1}
              </UncompletedStepIcon>
            )}
            <Text
              variant={inProgress || complete ? 'subtitle1' : 'body1'}
              color={inProgress || complete ? 'default' : 'muted'}>
              {title}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
}

function CompletedStepIcon() {
  return (
    <Icon
      icon='checkbox-marked-circle'
      css={`
        color: var(--blue);
        height: 1.75rem;
        width: 1.75rem;
      `}
    />
  );
}

function UncompletedStepIcon({
  inProgress,
  children,
}: {
  inProgress: boolean;
  children: React.ReactNode;
}) {
  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      css={{
        background: inProgress ? 'var(--blue)' : 'var(--bg-muted)',
        borderRadius: '50%',
        height: '1.5rem',
        userSelect: 'none',
        width: '1.5rem',
        margin: '0.125rem',
      }}>
      <Text color={inProgress ? 'tooltip' : 'muted'} variant='body3'>
        {children}
      </Text>
    </Stack>
  );
}
