import {gql} from '@apollo/client';

export default gql`
  query getCampaignPermission(
    $organizationId: ID!
    $campaignId: ID!
    $id: ID!
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          permission(id: $id) {
            id
            title
            description
            objectName
            organization {
              id
              name
            }
          }
        }
      }
    }
  }
`;
