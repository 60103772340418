import {Redirect} from 'react-router-dom';
import styled from 'styled-components';
import {createOrgPath} from '@core/lib/organizations';
import AdvisorTool from '@analytics/components/advisor/tool/AdvisorTool';
import {AdvisorRouteProps} from './_routes';

export function Advisor({user, organization}: AdvisorRouteProps) {
  if ('isAdvisor' in organization && !organization.isAdvisor && !user.isAdmin) {
    return <Redirect to={createOrgPath(organization, 'advisor/onboard')} />;
  }

  return (
    <Root>
      <AdvisorTool organization={organization} user={user} />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 6rem);
  padding: 0.5rem 1.5rem 0;
`;
