import {Switch} from 'react-router-dom';
import {Advisor} from './Advisor';
import {AdvisorRouteProps, useAdvisorRoutes} from './_routes';
import {AdvisorOnboardIndex} from './onboard';
import {AdvisorPodcastIndex} from './podcast';

export function AdvisorIndex({organization, user}: AdvisorRouteProps) {
  const {renderRoutes} = useAdvisorRoutes({organization});

  return (
    <Switch>
      {renderRoutes({
        components: {
          advisor: Advisor,
          podcast: AdvisorPodcastIndex,
          onboard: AdvisorOnboardIndex,
        },
        extraProps: {
          user,
          organization,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
