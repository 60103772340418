import styled from 'styled-components';
import {FelaElement} from '@core/style';
import {Separator} from '@core/ui/Content';
import {Link} from '@core/ui/Link';
import AdvisorPodcastBrandsTable from '@analytics/components/advisor/AdvisorPodcastBrandsTable';
import AdvisorPodcastEpisodesTable from '@analytics/components/advisor/AdvisorPodcastEpisodesTable';
import AdvisorPodcastIndustries from '@analytics/components/advisor/AdvisorPodcastIndustries';
import {
  AdvisorSamePublisher,
  AdvisorSimilarAudience,
} from '@analytics/components/advisor/AdvisorPodcastMoreShows';
import AdvisorPodcastPanel from '@analytics/components/advisor/AdvisorPodcastPanel';
import {advisorPodcastURL} from '@analytics/components/nav/AdvisorPodcastNav';
import {AdvisorPodcastRouteProps} from './_routes';

export function AdvisorPodcastOverview({
  organization,
  podcast,
}: AdvisorPodcastRouteProps) {
  return (
    <_OverviewContainer>
      <_TablesColumn>
        <_CardHeader>Top Brands</_CardHeader>
        <_Card>
          <_TableContainer>
            <AdvisorPodcastBrandsTable
              organization={organization}
              podcast={podcast}
              compact
            />
          </_TableContainer>
          <Link
            to={`${advisorPodcastURL(organization, podcast.feed)}/brands`}
            css={`
              text-decoration: none;
            `}>
            <_Footer>View Brands</_Footer>
          </Link>
        </_Card>
        <_CardHeader>Industries</_CardHeader>
        <_Card
          css={`
            padding-top: 10px;
          `}>
          <AdvisorPodcastIndustries
            organization={organization}
            podcast={podcast}
          />
          <Link
            to={`${advisorPodcastURL(organization, podcast.feed)}/industries`}
            css={`
              text-decoration: none;
            `}>
            <_Footer>View Industries</_Footer>
          </Link>
        </_Card>
        <_CardHeader>Recent Episodes</_CardHeader>
        <_Card>
          <_TableContainer>
            <AdvisorPodcastEpisodesTable
              organization={organization}
              podcast={podcast}
              compact
            />
          </_TableContainer>
          <Link
            to={`${advisorPodcastURL(organization, podcast.feed)}/episodes`}
            css={`
              text-decoration: none;
            `}>
            <_Footer>View Episodes</_Footer>
          </Link>
        </_Card>
      </_TablesColumn>
      <div>
        <AdvisorPodcastPanel podcast={podcast} />
        <div css={{padding: '1rem'}}>
          <_Subheader>Shows with similar audiences</_Subheader>
          <AdvisorSimilarAudience
            organization={organization}
            podcast={podcast}
          />
          <Separator />
          <_Subheader>More by {podcast?.publisher?.name}</_Subheader>
          <AdvisorSamePublisher organization={organization} podcast={podcast} />
        </div>
      </div>
    </_OverviewContainer>
  );
}

const _OverviewContainer = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: minmax(40rem, 1fr) 28rem;
  margin: 0 1.5rem;
`;

const _TablesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const _Card = styled.div`
  background: var(--bg-surface);
  border-radius: 0.5rem;
  border: 1px solid var(--border-default);
  min-width: 0;
  overflow: initial;
`;

const _CardHeader = styled.h3`
  font-size: 1.25rem;
  margin-bottom: '0 auto 0 0';
`;

const _TableContainer = styled.div`
  padding: 1.5rem;
`;

const _Subheader = styled.div`
  color: var(--text-subtle);
  font-weight: 500;
  margin: 0 0 1.5rem;
`;

const _Footer = styled(FelaElement)`
  align-items: center;
  background: var(--bg-muted);
  border-top: 1px solid var(--border-default);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  color: var(--text-muted);
  display: flex;
  font-weight: 500;
  height: 3rem;
  justify-content: center;
  width: 100%;
  :hover {
    cursor: pointer;
    color: var(--blue);
  }
`;
