import {isNone} from '@core/lib/utils';
import {campaignDynamicURL} from '@analytics/components/nav/CampaignDynamicNav';
import useSettings from '@analytics/components/settings/useSettings';
import {toModeled} from '@analytics/lib/utils';

export const getCampaignDynamics = (
  campaignDynamics,
  {campaign, organization}
) => {
  const [settings] = useSettings();
  const results = [];

  for (const item of campaignDynamics) {
    const {
      id,
      goal,
      cost,
      costToDate,
      name,
      reachTotal,
      downloadTotal,
      downloads,
      targetDownloadTotal,
      targetReachTotal,
      campaignDynamicPodcasts,
    } = item;
    const _downloadTotal =
      downloads && !isNone(downloads.count) ? downloads.count : downloadTotal;
    const frequency = reachTotal ? downloadTotal / reachTotal : 1;

    // deal with attribution.
    const _userTotal = toModeled(item, 'user', settings.modelled);
    const _reachTotal = _downloadTotal / frequency;
    let _costToDate = costToDate;
    const _targetDownloadTotal = targetDownloadTotal;
    const _targetReachTotal = targetReachTotal;

    _costToDate = settings.spendByImpressionsDelivered ? _costToDate : cost;

    const cr =
      campaign.kind.toLowerCase() == 'marketing'
        ? _targetReachTotal / _reachTotal
        : _reachTotal
        ? _userTotal / _reachTotal
        : 0;

    const isPpa = campaign?.isPpa;
    const cpm = isPpa
      ? 1
      : _downloadTotal
      ? (_costToDate / _downloadTotal) * 1000
      : 0;
    const cpv = _reachTotal && _userTotal ? _costToDate / _userTotal : 0;

    results.push({
      ...item,
      id,
      name,
      url: campaignDynamicURL(organization, campaign, {id}),
      goal,
      cost,
      costToDate: _costToDate,
      campaignDynamicPodcasts,
      reachTotal: _reachTotal,
      downloadTotal: _downloadTotal,
      attributionTotal: _userTotal,
      purchaseTotal: toModeled(item, 'purchase', settings.modelled),
      valueTotal: toModeled(item, 'value', settings.modelled),
      installedTotal: toModeled(item, 'installed', settings.modelled),
      leadTotal: toModeled(item, 'lead', settings.modelled),
      targetDownloadTotal: _targetDownloadTotal,
      targetReachTotal: _targetReachTotal,
      cr: isNaN(cr) ? 0 : cr,
      cpm,
      frequency,
      cpv,

      format: 'dynamic',
    });
  }

  return results.sort((a, b) => {
    return b.downloadTotal - a.downloadTotal;
  });
};
