import {createOrgPath} from '@core/lib/organizations';
import {FeedImage} from '@core/ui/Image';
import {Nav} from '@core/ui/Nav';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';

const campaignTargetURL = (organization, campaign, campaignTarget) =>
  createOrgPath(
    organization,
    `/campaigns/${campaign.slug}/targets/${campaignTarget.id}`
  );

const CampaignTargetNav = ({organization, campaign, campaignTarget}) => {
  const {feed} = campaignTarget;

  return (
    <Nav
      title={feed.title}
      description={feed.description}
      Image={() => <FeedImage feed={feed} width={150} />}
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/measure/campaigns'),
          title: 'Campaigns',
        },
        {
          to: toCampaignUrl(organization, campaign),
          title: campaign.name,
        },
        {
          title: 'Target',
        },
      ]}
    />
  );
};

export {campaignTargetURL};

export default CampaignTargetNav;
