import React from 'react';
import OSIcon from '@core/components/OSIcon';
import dayjs from '@core/lib/dayjs';
import {
  BLUE,
  BLUE_40,
  DARK_80,
  GREEN,
  PINK,
  PURPLE,
  RED_ORANGE,
} from '@core/style';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';

const actionsMap = [
  {
    actions: ['init', 'view'],
    attributes: [
      {name: 'urlDomain', title: 'Domain'},
      {name: 'url', title: 'Path'},
      {name: 'referrer', title: 'Referrer'},
      {name: 'eventName', title: 'Event Name'},
      {name: 'eventAction', title: 'Event Action'},
    ],
    color: BLUE_40,
  },
  {
    actions: ['lead'],
    attributes: [
      {name: 'type', title: 'Type'},
      {name: 'category', title: 'Category'},
      {name: 'value', title: 'Value'},
      {name: 'currency', title: 'Currency'},
      {name: 'eventName', title: 'Event Name'},
      {name: 'eventAction', title: 'Event Action'},
    ],
    color: BLUE,
  },
  {
    actions: ['product'],
    attributes: [
      {name: 'value', title: 'Value'},
      {name: 'currency', title: 'Currency'},
      {name: 'productId', title: 'Product ID'},
      {name: 'productName', title: 'Product Name'},
      {name: 'productType', title: 'Product Type'},
      {name: 'productVendor', title: 'Product Vender'},
      {name: 'eventAction', title: 'Event Action'},
    ],
    color: PURPLE,
  },
  {
    actions: ['addtocart'],
    attributes: [
      {name: 'value', title: 'Value'},
      {name: 'currency', title: 'Currency'},
      {name: 'productId', title: 'Product ID'},
      {name: 'productName', title: 'Product Name'},
      {name: 'productType', title: 'Product Type'},
      {name: 'productVendor', title: 'Product Vender'},
      {name: 'eventName', title: 'Event Name'},
      {name: 'eventAction', title: 'Event Action'},
    ],
    color: GREEN,
  },
  {
    actions: ['checkout'],
    attributes: [
      {name: 'value', title: 'Value'},
      {name: 'currency', title: 'Currency'},
      {name: 'discountCode', title: 'Discount Code'},
      {name: 'quantity', title: 'Quantity'},
      {name: 'eventName', title: 'Event Name'},
      {name: 'eventAction', title: 'Event Action'},
    ],
    color: GREEN,
  },
  {
    actions: ['purchase', 'af_purchase', 'af_first_purchase'],
    attributes: [
      {name: 'value', title: 'Value'},
      {name: 'currency', title: 'Currency'},
      {name: 'discountCode', title: 'Discount Code'},
      {name: 'orderId', title: 'Order ID'},
      {name: 'quantity', title: 'Quantity'},
      {name: 'eventName', title: 'Event Name'},
      {name: 'eventAction', title: 'Event Action'},
      {name: 'newCustomer', title: 'New Customer'},
    ],
    color: PINK,
  },
  {
    actions: ['alias'],
    attributes: [
      {name: 'alias', title: 'Alias'},
      {name: 'eventName', title: 'Event Name'},
      {name: 'eventAction', title: 'Event Action'},
    ],
    color: RED_ORANGE,
  },
  {
    actions: ['install'],
    attributes: [
      {name: 'eventName', title: 'Event Name'},
      {name: 'eventAction', title: 'Event Action'},
    ],
    color: DARK_80,
  },
  {
    actions: ['other'],
    attributes: [
      {name: 'eventName', title: 'Event Name'},
      {name: 'eventAction', title: 'Event Action'},
    ],
    color: DARK_80,
  },
];

const AttributesTableCell = ({data}): JSX.Element => {
  return (
    <div>
      {data.__def.attributes.map(({name, title}) => (
        <div
          key={name}
          css={{display: 'grid', gridTemplateColumns: 'min-content auto'}}>
          <div
            css={{
              color: 'var(--text-muted)',
              marginRight: '0.625rem',
              textAlign: 'right',
              textTransform: 'capitalize',
              width: '9.375rem',
            }}>
            {title}:
          </div>
          <div css={{textAlign: 'left'}}>{data[name] || '-'}</div>
        </div>
      ))}
    </div>
  );
};

interface IPixelEventsTableProps {
  events: any;
  orderBy: string;
  dynamicActionMappings: any;
}

const PixelEventsTable = ({
  events,
  orderBy,
  dynamicActionMappings,
}: IPixelEventsTableProps): JSX.Element => {
  const columns: DataTableColumn[] = [
    {
      title: 'Action',
      accessor: 'action',
      type: 'string',
      Cell: ({d: {action, __def}}) => (
        <b css={{display: 'flex', alignItems: 'center', wordBreak: 'normal'}}>
          <div
            css={{
              backgroundColor: __def.color,
              borderRadius: '100%',
              height: '0.625rem',
              marginRight: '0.375rem',
              width: '0.625rem',
            }}
          />

          {action === 'init' ? 'View' : action}
        </b>
      ),
      width: 100,
    },
    {
      title: 'Device',
      accessor: 'uid',
      type: 'string',
      Cell: ({d: {userAgent}}) => <OSIcon os={userAgent.os} />,
      width: 100,
    },
    {
      title: 'Time',
      accessor: 'time',
      type: 'date',
      fmt: (v) => dayjs(v).utc().format('MM/DD/YYYY HH:mm:ss'),
      width: 200,
    },
    {
      title: 'Data',
      accessor: '',
      type: 'string',
      Cell: AttributesTableCell,
    },
  ];

  const data = events.map((node, idx) => {
    const dynamicMapping =
      dynamicActionMappings?.find(
        (action) =>
          action.eventName === node.eventName ||
          action.eventName === node.action
      ) || [];

    const __def = actionsMap.find(
      ({actions}) =>
        actions.indexOf(node.action?.toLowerCase()) > -1 ||
        (dynamicMapping && actions.indexOf(dynamicMapping.action) > -1)
    ) || {attributes: []};

    return {
      ...node,
      __def,
      id: idx,
      referrer: node.referrerPath || node.referrer,
      url: node.urlPath,
      // We do this here so that dashboard recognized action is displayed in the
      // action column rather than the action value in the node itself.
      // node.eventAction will be hold the original action and will be displayed in
      // the Data column "Event Action" property to avoid losing the relationship
      // between the original action and the mapped action.
      action: dynamicMapping?.action ?? node.action,
      eventAction: node.action,
    };
  });

  return (
    <DataTable
      columns={columns}
      data={data}
      orderBy={orderBy ? orderBy : 'time'}
      emptyMessageText='No events found.'
    />
  );
};

export default PixelEventsTable;
