import {CampaignLeadStats} from '@analytics/components/campaigns/stats/CampaignLeadStats';
import {CampaignRouteProps} from '../../../_routes';

export function CampaignLeadsIndex({
  organization,
  campaign,
}: CampaignRouteProps) {
  return (
    <>
      <CampaignLeadStats organization={organization} campaign={campaign} />
    </>
  );
}
