import {useMemo, useState} from 'react';
import {
  ButtonPrimary,
  ButtonTertiary,
  IconExclamationCircle,
  Text,
  Tooltip,
  TooltipTrigger,
  cssSpacing,
  semanticColors,
} from '@spotify-internal/encore-web';
import {capitalize} from 'lodash';
import CampaignStateBadge from '@core/components/CampaignStateBadge';
import dayjs from '@core/lib/dayjs';
import {DataTableColumn} from '@core/ui/DataTable';
import {Stack} from '@core/ui/Layout';
import {useOverlay} from '@core/ui/Overlay';
import {
  FetchedCampaigns,
  FetchedOrganization,
  FetchedPublisherReviewCampaigns,
} from '@analytics/graphql-api';
import {PublisherApproveOverlay} from '../PublisherApproveOverlay';
import {PublisherRejectOverlay} from '../PublisherRejectOverlay';

export const usePublisherReviewColumns = ({
  organization,
}: {
  organization: FetchedOrganization;
}) =>
  useMemo(
    () =>
      [
        {
          title: 'Campaign',
          accessor: 'name',
          type: 'string',
          Cell: ({d}) => {
            const [showTooltip, setShowTooltip] = useState(false);
            return (
              <Stack alignItems='center' gap={cssSpacing('base', '16px')}>
                <TooltipTrigger
                  onShow={() => setShowTooltip(true)}
                  onHide={() => setShowTooltip(false)}
                  overlay={
                    showTooltip && (
                      <Tooltip id='campaignForReview'>
                        Approve or reject this campaign to ensure it goes live
                        on the scheduled start date.
                      </Tooltip>
                    )
                  }
                  placement='end'>
                  <ButtonTertiary
                    aria-labelledby='campaignForReview'
                    condensedAll
                    iconOnly={IconExclamationCircle}
                    semanticColor={semanticColors.textNegative}
                  />
                </TooltipTrigger>
                <Text variant='bodySmallBold' style={{minWidth: '12.5rem'}}>
                  {d?.name}
                </Text>
              </Stack>
            );
          },
        },
        {
          title: 'Advertiser',
          accessor: 'advertiser',
          type: 'string',
          noSortable: true,
          Cell: ({d}) => (
            <Text variant='bodySmall'>{d?.advertiser?.name ?? ''}</Text>
          ),
        },
        {
          title: 'Type',
          accessor: 'kind',
          type: 'string',
          Cell: ({d}) => (
            <Text variant='bodySmall'>{capitalize(d?.kind ?? '')}</Text>
          ),
        },
        {
          title: 'State',
          accessor: 'state',
          type: 'string',
          Cell: ({d}) => <CampaignStateBadge state={d?.state} />,
        },
        {
          title: 'Starts',
          accessor: 'startAt',
          type: 'date',
          fmt: (v) => (v ? dayjs(v).format('MMM DD, YYYY') : '-'),
        },
        {
          title: 'Ends',
          accessor: 'endAt',
          type: 'date',
          fmt: (v) => (v ? dayjs(v).format('MMM DD, YYYY') : '-'),
        },
        {
          title: 'Created At',
          accessor: 'createdAt',
          type: 'date',
          fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
        },
        {
          title: '',
          accessor: 'id',
          type: 'string',
          Cell: ({d}) => {
            const [openedReject, toggleReject] = useOverlay();
            const [openedApprove, toggleApprove] = useOverlay();
            return (
              <Stack
                alignItems='center'
                gap={cssSpacing('base', '16px')}
                css={{minWidth: 'max-content'}}>
                <ButtonTertiary
                  condensed
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleReject(true);
                  }}
                  size='small'>
                  Reject
                </ButtonTertiary>
                <ButtonPrimary
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleApprove(true);
                  }}
                  size='small'>
                  Approve
                </ButtonPrimary>
                {openedReject && (
                  <PublisherRejectOverlay
                    campaign={d}
                    organizationId={organization.id}
                    opened={openedReject}
                    toggle={toggleReject}
                  />
                )}
                {openedApprove && (
                  <PublisherApproveOverlay
                    campaign={d}
                    organizationId={organization.id}
                    opened={openedApprove}
                    toggle={toggleApprove}
                  />
                )}
              </Stack>
            );
          },
        },
      ] satisfies DataTableColumn<
        FetchedCampaigns[number] | FetchedPublisherReviewCampaigns[number]
      >[],
    [organization]
  );
