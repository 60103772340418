import {
  ExperianSegment,
  MosaicType,
  NielsenMetric,
  NielsenSegment,
} from '@analytics/graphql-api';
import {
  demographics,
  experian_buckets,
  nielsen_buckets,
} from './segmentsBuckets';

type _NielsenSegment = Omit<NielsenSegment, 'category'>;
type _ExperianSegment = Omit<ExperianSegment, 'category'>;
type _MosaicType = Omit<
  MosaicType,
  'hh' | 'segment' | 'pop' | 'reach' | 'totalHouseholds' | 'type'
>;
type _MosaicTypes = {
  types: (_MosaicType | null)[] | null;
};

const sortByTopic = (
  data: {
    topic: string;
    subtopic: string;
    metrics: {
      index: number;
      percentage: number;
      unavailable: boolean;
      variable: string;
    }[];
  }[]
) => {
  if (!data || !data.length) return null;
  const order = ['Age', 'Children', 'Income', 'Family Structure', 'Education'];

  return data.sort((a, b) => order.indexOf(a.topic) - order.indexOf(b.topic));
};

const sumProps = (arr: any[], prop: string) =>
  arr.reduce((a, b) => a + b[prop], 0);

const filterDataByVariable = (data, item: string) =>
  data.filter(({variable}) => variable.includes(item));

const filterDataByBucket = (
  data,
  buckets: {[key: string]: string[]},
  bucket: string
) => data.filter(({variable}) => buckets[bucket].includes(variable));

const buildBucketedGroup = (data, buckets: {[key: string]: string[]}) => {
  return Object.keys(buckets).map((x) => {
    const filtered = filterDataByBucket(data, buckets, x);
    if (!filtered.length)
      return {variable: x, index: 0, percentage: 0, unavailable: true};

    const index = Math.round(sumProps(filtered, 'index') / filtered.length);
    const percentage =
      sumProps(filtered, 'percentage') ||
      sumProps(filtered, 'podcastPercentOfPop');

    return {
      index,
      variable: x,
      percentage: Math.round(percentage * 100),
      unavailable: false,
    };
  });
};

const buildFilteredGroup = (data, buckets: string[]) => {
  return buckets.map((x) => {
    const filtered = filterDataByVariable(data, x);
    if (!filtered.length)
      return {variable: x, index: 0, percentage: 0, unavailable: true};

    const index = Math.round(sumProps(filtered, 'index') / filtered.length);
    return {
      index,
      variable: x,
      percentage: Math.round(sumProps(filtered, 'percentage') * 100),
      unavailable: false,
    };
  });
};

const buildGroup = (data, buckets: string[]) => {
  return buckets.map((x) => {
    const item = data.find(({variable}) => x === variable);
    if (!item) return {variable: x, index: 0, percentage: 0, unavailable: true};

    const {variable, index, percentage, podcastPercentOfPop} = item;
    const _percentage = Math.round((percentage || podcastPercentOfPop) * 100);

    return {
      variable: variable === 'Presence of a child' ? 'Child present' : variable,
      index: Math.round(index),
      percentage: _percentage,
      unavailable: false,
    };
  });
};

const buildTypesGroup = (data: _MosaicType[], features: string[]) =>
  data.map((x) => {
    const {name, descr, index, percentOfPop} = x;
    const percentage = ((percentOfPop ?? 0) * 100).toFixed(1);
    return {
      descr: descr ?? '',
      variable: name ?? '',
      index: Math.round(index ?? 0),
      features: features.map((feature) => x[feature]),
      percentage:
        percentage > 10 ? parseInt(percentage) : parseFloat(percentage),
    };
  });

type OccupationMetric = {
  variable: string;
  index: number;
  percentage: number;
};

type BuildOccupationGroupFn = (
  data: (NielsenMetric | null)[] | null,
  fixed?: boolean
) => OccupationMetric[];

const buildOccupationGroup: BuildOccupationGroupFn = (data, fixed = true) => {
  if (!data) return [];
  return (data.filter((d) => !!d) as NielsenMetric[]).map(
    ({index, podcastPercentOfPop, variable}) => {
      const percentage = ((podcastPercentOfPop ?? 0) * 100).toFixed(1);
      return {
        variable: variable ?? '',
        index: Math.round(index ?? 0),
        percentage:
          parseInt(percentage) < 10 && fixed
            ? parseFloat(percentage)
            : parseInt(percentage),
      };
    }
  );
};

const getExperianMetrics = (metrics, topic: string) => {
  const buckets = experian_buckets[topic];
  return topic === 'Income'
    ? buildBucketedGroup(metrics, buckets)
    : topic === 'Family Structure'
    ? buildFilteredGroup(metrics, buckets)
    : buildGroup(metrics, buckets);
};

const getNielsenMetrics = (metrics, topic: string) => {
  const buckets = nielsen_buckets[topic] || [];
  return topic === 'Income'
    ? buildBucketedGroup(metrics, buckets)
    : buildGroup(metrics, buckets);
};

const belowUniqueHhCount = (
  data: (_NielsenSegment | _ExperianSegment | null)[]
) => (data?.[0]?.uniqueHhCount ?? 0) < 5000;

const getDemographicsData = (
  data: (_ExperianSegment | _NielsenSegment | null)[] | undefined | null
) => {
  if (!data || !data.length || belowUniqueHhCount(data)) return null;

  const filtered = data.filter(
    (d) => !!d && !!d.topic && demographics[d.topic]
  ) as (_NielsenSegment | _ExperianSegment)[];

  const _data = filtered.map((x) => {
    const {topic, subtopic, type} = demographics[x.topic];
    return {
      topic,
      subtopic,
      metrics:
        type === 'experian'
          ? getExperianMetrics(x.metrics, topic)
          : getNielsenMetrics(x.metrics, topic),
    };
  });

  return sortByTopic(_data);
};

const getExperianTypesData = (
  data: (_MosaicTypes | null)[] | undefined | null
) => {
  if (!data || !data.length) return [null, null];

  const types = data
    .filter((d): d is _MosaicTypes => !!d)?.[0]
    .types?.filter((d) => !!d) as _MosaicType[];

  const features = Object.keys(types[0]).filter((el) => el.includes('feat'));
  const updated = buildTypesGroup(types, features);

  const index = updated.slice(0).sort((a, b) => b.index - a.index);
  const percentage = updated
    .slice(0)
    .sort((a, b) => b.percentage - a.percentage);

  return [index, percentage];
};

type GetNielsenOccupationDataFn = (
  data: (_NielsenSegment | null)[] | null | undefined
) => [OccupationMetric[] | null, OccupationMetric[] | null];

export const getNielsenOccupationData: GetNielsenOccupationDataFn = (data) => {
  if (!data || !data.length || belowUniqueHhCount(data)) return [null, null];

  const updated = buildOccupationGroup(data?.[0]?.metrics ?? null);
  const index = updated.slice(0).sort((a, b) => b.index - a.index);
  const percentage = updated
    .slice(0)
    .sort((a, b) => b.percentage - a.percentage);

  return [index, percentage];
};

export type IntentData = {
  topic: string;
  metrics: OccupationMetric[];
}[];

type GetNielsenIntentDataFn = (
  data: (_NielsenSegment | null)[] | null | undefined,
  classification: string
) => IntentData | [];

export const getNielsenIntentData: GetNielsenIntentDataFn = (
  data,
  classification
) => {
  if (!data || !data.length || belowUniqueHhCount(data)) return [];
  return (data.filter((d) => !!d) as NielsenSegment[]).reduce(
    (acc, {topic, metrics}) => {
      if (metrics?.length === 1) return acc;

      const _metrics = buildOccupationGroup(metrics, false);
      _metrics.sort((a, b) =>
        a.variable.localeCompare(b.variable, 'en', {numeric: true})
      );

      const _topic: string = (topic ?? '')
        .replace(`Intent - ${classification} - `, '')
        .replace(`Intent - ${classification}`, classification);

      acc.push({
        topic: _topic,
        metrics: _metrics,
      });

      return acc;
    },
    [] as IntentData
  );
};

export {
  belowUniqueHhCount,
  getExperianMetrics,
  getNielsenMetrics,
  sortByTopic,
  getDemographicsData,
  getExperianTypesData,
};
