import {useState} from 'react';
import {
  Banner,
  ButtonPrimary,
  ProgressCircle,
  Text,
  cssSpacing,
} from '@spotify-internal/encore-web';
import {createOrgPath} from '@core/lib/organizations';
import {t} from '@core/texts';
import {Card, CardHeader} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {Link} from '@core/ui/Link';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {useFillCampaignDynamicsMutation} from '@analytics/graphql-api';
import {CampaignRouteProps} from './_routes';

export function CampaignFillDynamics({
  campaign,
  organization,
  user,
}: CampaignRouteProps) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [fillCampaignDynamics] = useFillCampaignDynamicsMutation();

  const onSubmit = async () => {
    if (error) setError(false);
    setLoading(true);

    try {
      const response = await fillCampaignDynamics({
        variables: {
          input: {
            id: campaign.id,
          },
        },
      });

      const _success = response?.data?.fillCampaignDynamics?.success;

      if (_success === null) {
        throw new Error('The network returned a null response');
      }

      setSuccess(_success);
    } catch (e) {
      console.warn(e);
      setError(true);
    }
    setLoading(false);
  };

  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <ResponsiveContent containerSize='medium'>
        <CardHeader
          backPath={createOrgPath(
            organization,
            `/campaigns/${campaign.slug}/placements`
          )}>
          Ingest Dynamic Ads
        </CardHeader>
        <Card>
          <Stack direction='column' gap={cssSpacing('base', '16px')}>
            {error && (
              <Banner colorSet='negative'>{t('errors.network')}</Banner>
            )}
            {success === true && (
              <Banner colorSet='positive'>
                Success! Ad Analytics found data for your Dynamic Insertions.
              </Banner>
            )}
            {success === false && (
              <Banner colorSet='negative'>
                We have not found any dynamic insertion data for this campaign
                yet. Please wait a bit, check the integration, and try again. If
                this continues please contact{' '}
                <Link
                  color='tooltip'
                  href='mailto:support-adanalytics@spotify.com'
                  underline='always'>
                  support-adanalytics@spotify.com
                </Link>
              </Banner>
            )}
            <Text as='p'>
              Once you set your dynamic insertion live in your hosting provider
              it can take up to an hour for Ad Analytics to start filling out
              the campaign.
            </Text>
            <Text as='p'>
              Ingest let's you manually kick off the job to check that we are
              recieving data and to start the process of filling out the
              campaign.
            </Text>
          </Stack>
          <Stack
            alignItems='center'
            gap={cssSpacing('tighter', '12px')}
            justifyContent='flex-end'>
            {loading && (
              <ProgressCircle aria-label='Loading dynamic ingestion data' />
            )}
            <ButtonPrimary disabled={loading} onClick={onSubmit}>
              Ingest Dynamic Data
            </ButtonPrimary>
          </Stack>
        </Card>
      </ResponsiveContent>
    </>
  );
}
