import {
  ButtonPrimary,
  IconExclamationCircle,
  ProgressCircle,
  Text,
  cssSpacing,
  semanticEssentialColors,
} from '@spotify-internal/encore-web';
import {capitalize} from 'lodash';
import CampaignStateBadge from '@core/components/CampaignStateBadge';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import {t} from '@core/texts';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn, DataTableLink} from '@core/ui/DataTable';
import EmptyMessage from '@core/ui/EmptyMessage';
import {Stack} from '@core/ui/Layout';
import {useOverlay} from '@core/ui/Overlay';
import TrackingUrlsOverlay from '@analytics/components/tracking-urls/TrackingUrlsOverlay';
import {
  FetchedCampaignsConfirmTrackingUrls,
  useCampaignsConfirmTrackingUrlsQuery,
} from '@analytics/graphql-api';
import {CampaignsRouteProps} from './_routes';

export function CampaignsTrackingUrls({organization}: CampaignsRouteProps) {
  const {data, loading, error} = useCampaignsConfirmTrackingUrlsQuery({
    variables: {
      organizationId: organization.id,
    },
  });

  if (loading) {
    return <ProgressCircle aria-label='Loading unconfirmed tracking URLs' />;
  }

  if (error) {
    return <EmptyMessage>{t('errors.network')}</EmptyMessage>;
  }

  const unconfirmed = (data?.me?.organization?.campaigns?.results ?? []).filter(
    (campaign) =>
      totalUnconfirmed(campaign?.campaignDynamics) > 0 ||
      totalUnconfirmed(campaign?.campaignStreamings) > 0
  );

  return (
    <Card>
      <CardHeader>Unconfirmed Tracking URLs</CardHeader>
      <DataTable
        data={unconfirmed}
        emptyMessageText='No unconfirmed tracking URLs to review'
        orderBy='-createdAt'
        columns={
          [
            {
              title: 'Campaign',
              accessor: 'name',
              type: 'string',
              Cell: ({d}) => (
                <DataTableLink
                  to={createOrgPath(organization, `/campaigns/${d?.slug}`)}>
                  {d?.name}
                </DataTableLink>
              ),
            },
            {
              title: 'Advertiser',
              accessor: 'advertiser',
              type: 'string',
              noSortable: true,
              Cell: ({d}) => (
                <Text variant='bodySmall'>{d?.advertiser?.name ?? ''}</Text>
              ),
            },
            {
              title: 'State',
              accessor: 'state',
              type: 'string',
              noSortable: true,
              Cell: ({d}) => <CampaignStateBadge state={d?.state} />,
            },
            {
              title: 'Type',
              accessor: 'kind',
              type: 'string',
              Cell: ({d}) => (
                <Text variant='bodySmall'>{capitalize(d?.kind ?? '')}</Text>
              ),
            },
            {
              title: 'Created',
              accessor: 'createdAt',
              type: 'date',
              fmt: (v) => (v ? dayjs(v).format('MMM DD, YYYY') : '-'),
            },
            {
              title: 'Unconfirmed',
              accessor: 'unconfirmedCount',
              type: 'number',
              alignRight: false,
              Cell: ({d}) => {
                const unconfirmedCount =
                  totalUnconfirmed(d?.campaignDynamics) +
                  totalUnconfirmed(d?.campaignStreamings);

                return (
                  <Stack
                    alignItems='center'
                    gap={cssSpacing('tighter-2', '8px')}>
                    <IconExclamationCircle
                      semanticColor={semanticEssentialColors.essentialNegative}
                      size='small'
                    />
                    <Text variant='bodySmall'>
                      {unconfirmedCount} unconfirmed
                    </Text>
                  </Stack>
                );
              },
            },
            {
              title: '',
              accessor: 'id',
              type: 'string',
              noSortable: true,
              Cell: ({d}) => {
                const [opened, toggle] = useOverlay();
                return (
                  <>
                    <ButtonPrimary onClick={() => toggle(true)} size='small'>
                      Tracking URLs
                    </ButtonPrimary>
                    {opened && (
                      <TrackingUrlsOverlay
                        campaign={d}
                        organization={organization}
                        opened={opened}
                        toggle={toggle}
                      />
                    )}
                  </>
                );
              },
            },
          ] as DataTableColumn<FetchedCampaignsConfirmTrackingUrls[number]>[]
        }
      />
    </Card>
  );
}

type UnconfirmedDynamics = NonNullable<
  FetchedCampaignsConfirmTrackingUrls[number]
>['campaignDynamics'];
type UnconfirmedStreamings = NonNullable<
  FetchedCampaignsConfirmTrackingUrls[number]
>['campaignStreamings'];

const totalUnconfirmed = (
  items: UnconfirmedDynamics | UnconfirmedStreamings = []
) => {
  return items.filter((item) => item?.deliveries?.length < 1).length;
};
