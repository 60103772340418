import dayjs from '@core/lib/dayjs';
import {isSelling} from '@core/lib/organizations';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {Link} from '@core/ui/Link';
import CampaignEpisodesTable from '@analytics/components/campaigns/CampaignEpisodesTable';
import CampaignUpcomingEpisodesTable from '@analytics/components/campaigns/CampaignUpcomingEpisodesTable';
import {CampaignPodcastOmniChart} from '@analytics/components/campaigns/charts';
import {CampaignEmbeddedStats} from '@analytics/components/campaigns/stats/CampaignEmbeddedStats';
import CampaignPodcastNav from '@analytics/components/nav/CampaignPodcastNav';

const CampaignPodcast = ({
  user,
  organization,
  campaign,
  campaignPodcast,
  match: {url},
}) => {
  const goal = campaignPodcast?.goal ?? 0;
  const campaignEpisodes = campaignPodcast?.campaignEpisodes ?? [];

  const perEpisodeGoal =
    campaignEpisodes.length > 0 ? goal / campaignEpisodes.length : 0;

  const futureEpisodes = campaignEpisodes.filter(
    (ce) =>
      !!ce.futureEpisode &&
      dayjs(ce.futureEpisode.expectedPublished)
        .add(1, 'd')
        .isSameOrAfter(dayjs(), 'd')
  );

  const baseline = campaignEpisodes.filter(
    (ce) => !!ce.episode && ce.isBaseline
  );

  const episodes = campaignEpisodes.filter(
    (ce) => !!ce.episode && !ce.isBaseline
  );

  return (
    <>
      <CampaignPodcastNav
        user={user}
        organization={organization}
        campaign={campaign}
        campaignPodcast={campaignPodcast}
      />

      {!campaignPodcast?.hasPodsightsPrefix && (
        <ToastMessage alertType='error'>
          <span>
            <b>Ad Analytics Prefix is not installed.</b> Ad Analytics will not
            be able to measure this campaign until the prefix is installed.
          </span>
          {isSelling(organization) && (
            <Link
              to={`${url}/prefix`}
              css={`
                color: inherit;
                font-weight: 500;
                margin-left: 0.375rem;
              `}>
              Check Prefix Status
            </Link>
          )}
        </ToastMessage>
      )}
      <CampaignEmbeddedStats
        campaign={campaign}
        campaignPodcast={campaignPodcast}
      />

      <Card css={{marginTop: '2.375rem'}}>
        <CampaignPodcastOmniChart
          organization={organization}
          campaign={campaign}
          campaignPodcast={campaignPodcast}
        />
      </Card>

      {episodes.length > 0 && (
        <>
          <CardHeader>Episodes</CardHeader>
          <Card scrollable>
            <CampaignEpisodesTable
              user={user}
              feed={campaignPodcast?.feed}
              organization={organization}
              campaign={campaign}
              campaignEpisodes={episodes}
            />
          </Card>
        </>
      )}

      {baseline.length > 0 && (
        <Card>
          <CardHeader>Baseline</CardHeader>
          <CampaignEpisodesTable
            user={user}
            organization={organization}
            campaign={campaign}
            campaignEpisodes={baseline}
          />
        </Card>
      )}

      {futureEpisodes.length > 0 && (
        <Card>
          <CardHeader>Upcoming episodes</CardHeader>
          <CampaignUpcomingEpisodesTable
            futureEpisodes={futureEpisodes}
            organization={organization}
            downloadGoal={perEpisodeGoal}
            url={url}
          />
        </Card>
      )}
    </>
  );
};

export default CampaignPodcast;
