import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {TRules, css} from '@core/style';
import {Button} from '../Button';
import {LoadingSpinner} from '../Loading';

// Hubspot requires jQuery just to query nodes so we'll use this instead.
(window as any).jQuery =
  (window as any).jQuery ||
  ((n) => {
    if (typeof n === 'string') {
      return document.querySelector(n);
    }
    return n;
  });

interface IHubspotForm {
  formId: string;
  onCancelClick?: () => void;
  onFormSubmit?: (node: HTMLFormElement) => void;
  rules?: TRules;
}

const HubspotForm = ({
  formId,
  onCancelClick,
  onFormSubmit = () => undefined,
  rules,
}: IHubspotForm) => {
  const [loading, setLoading] = useState<boolean>(true);
  const ref = useRef<HTMLDivElement>(null);

  const createForm = () => {
    (window as any).hbspt.forms.create({
      region: 'na1',
      portalId: '20786575',
      css: '',
      formId,
      onFormSubmit,
      onFormReady: () => {
        setLoading(false);
      },
      target: `[data-form-id="${formId}"]`,
    });
  };

  useEffect(() => {
    if ((window as any).hbspt) {
      createForm();
    } else {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/v2.js';
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        createForm();
      };
    }

    return () => {};
  }, []);

  return (
    <div css={{width: '100%'}}>
      {loading ? (
        <LoadingSpinner centered size='medium' />
      ) : onCancelClick && ref.current ? (
        ReactDOM.createPortal(
          <Button
            type='button'
            onClick={onCancelClick}
            css={`
              margin: 0 var(--spacing-5);
            `}>
            Cancel
          </Button>,
          ref.current.querySelector('.actions')!
        )
      ) : null}
      <div
        data-form-id={formId}
        ref={ref}
        {...css([
          () => ({
            marginTop: '1rem',
            width: '100%',
            '& label': {
              color: 'inherit',
              display: 'block',
              fontSize: '.9375rem',
              fontWeight: 500,
              marginBottom: '.5rem',
              '> input': {
                marginTop: '.25rem',
              },
            },
            '& .hs-field-desc': {
              color: 'var(--text-muted)',
              display: 'block',
              fontSize: '.8125rem',
              marginBottom: '.5rem',
            },
            '& .hs-form-field': {
              margin: '0 0 1.25rem 0',
            },
            '& .hs-input': {
              background: 'var(--bg-default)',
              border: '.0625rem solid var(--border-default)',
              borderRadius: '.375rem',
              color: 'var(--text-default)',
              display: 'block',
              fontSize: '1rem',
              outline: 0,
              padding: '0.5rem 0.625rem !important',
              width: '100%',
              '[disabled]': {
                color: 'var(--text-disabled)',
              },
              ':focus': {
                borderColor: 'var(--blue)',
                boxShadow: '0 0 0 1px var(--blue)',
              },
              '::placeholder': {
                fontSize: '.9375rem',
                color: 'var(--text-muted)',
              },
            },
            '& .is-placeholder': {
              fontSize: '.9375rem',
              color: 'var(--text-muted)',
            },
            '& .hs-error-msgs': {
              background: 'var(--highlighter-40)',
              borderRadius: '.5rem',
              color: 'var(--red-orange)',
              listStyle: 'none',
              margin: '0.625rem 0',
              padding: '0.625rem 1rem 0',
            },
            '& .hs-error-msgs li': {
              padding: '0 0 0.625rem',
            },
            '& .hs-error-msgs label': {
              margin: 0,
            },
            '& ul.inputs-list[role="checkbox"]': {
              listStyle: 'none',
              padding: 0,
            },
            '& .hs-form-checkbox label': {
              alignItems: 'center',
              display: 'flex',
            },
            '& .hs-form-checkbox input': {
              width: 'auto',
              margin: '0 0.625rem 0',
            },
            '& .actions': {
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'flex-start',
              margin: '1.75rem 0 0 0',
            },
            '& .hs_error_rollup .hs-error-msgs': {
              background: 'var(--red-orange)',
              color: '#fff',
            },
            '& .hs-button': {
              alignItems: 'center',
              background: 'var(--blue)',
              border: 0,
              borderRadius: '2rem',
              borderStyle: 'solid',
              color: '#fff',
              cursor: 'pointer',
              display: 'inline-flex',
              fontSize: 'inherit',
              fontWeight: 500,
              height: '2.375rem',
              justifyContent: 'center',
              lineHeight: 1,
              padding: '0 1.25rem .0625rem',
              textDecoration: 'none',
              transition: 'all 420ms cubic-bezier(.165,.84,.44,1)',
              verticalAlign: 'middle',
              whiteSpace: 'nowrap',
              ':hover:not([disabled])': {
                background: 'var(--blue-80)',
                textDecoration: 'none',
              },
            },
            '& .form-columns-1': {
              maxWidth: '100%',
            },
            '& .form-columns-2': {
              maxWidth: '100%',
            },
          }),
          rules,
        ])}
      />
    </div>
  );
};

export default HubspotForm;
