import {comma} from '@core/lib/filters';
import {SliderBox} from '@analytics/components/advisor/onboarding/brand/SliderBox';
import {Question, _Description, _QuestionHeader} from '../../shared';
import {estimateImpressionsFromBudget} from '../helpers';
import {IQuestionProps} from '../types';

export const QuestionBudget = ({
  quizData: {budget},
  updateQuizData,
}: IQuestionProps) => {
  const {impressions, households, visitors} =
    estimateImpressionsFromBudget(budget);

  return (
    <Question>
      <_QuestionHeader>
        How much money are you looking to spend in your next campaign?
      </_QuestionHeader>
      <_Description>
        Sharing this information helps us tailor the product to your needs.
      </_Description>
      <SliderBox
        header={`$${comma(budget)}`}
        subheader='Estimated Budget'
        footer={
          <>
            Based on an estimated <b>$25 CPM</b> and <b>2% Conversion Rate</b>,
            we estimate this budget would yield <b>{comma(impressions)}</b>{' '}
            impressions across <b>{comma(households)}</b> households and{' '}
            <b>{comma(visitors)}</b> visitors to your brand.
          </>
        }
        value={budget}
        sliderProps={{
          min: 0,
          max: 1_000_000,
          step: 10_000,
          onChange: (_0, value: number, _1) => updateQuizData('budget', value),
        }}
      />
    </Question>
  );
};
