import {
  AudienceInsightFragment,
  CampaignSpotifyAudienceQuery,
  SpotifyAudienceFieldsFragment,
} from '@analytics/graphql-api';
import {DAI_TOP_AUDIENCES, SAI_TOP_AUDIENCES} from './constants';

export type AudienceInsights = Readonly<Readonly<AudienceInsightFragment>[]>;

export enum AudienceInsightsKind {
  DAI = 'DAI',
  SAI = 'SAI',
}

export enum AudienceGroup {
  AGE = 'age',
  DEVICE = 'device',
  FORMAT = 'format',
  GENDER = 'gender',
  GENRE = 'musicGenre',
  PODCAST = 'podcastCategory',
  PLAYLIST = 'playlist',
  SEGMENT = 'userSegment',
}

export type AudiencesByGroup = {
  [key in AudienceGroup]: AudienceInsights;
};

export type AudienceOrder = typeof DAI_TOP_AUDIENCES | typeof SAI_TOP_AUDIENCES;

type AudienceOverviewCard = 'OVERVIEW';

export type DAIAudienceCard =
  | AudienceOverviewCard
  | (typeof DAI_TOP_AUDIENCES)[number];

export type SAIAudienceCard =
  | AudienceOverviewCard
  | (typeof SAI_TOP_AUDIENCES)[number];

export enum InsightsMetric {
  IMPRESSIONS = 'impressions',
  CONVERSIONS = 'crIndex',
}

// Below are temporary until SAI data moved into new query
export type SAIData = NonNullable<
  NonNullable<CampaignSpotifyAudienceQuery>['me']
>['organization']['campaign'];

export type SAIInsight = Readonly<SpotifyAudienceFieldsFragment[]>;
