import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import Button from '@core/ui/Button/LegacyButton';
import {Card, CardHeader} from '@core/ui/Content';
import {FORM_ERRORS, Form, FormFooter, Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import DELETE_ACTION_MAP_MUTATION from '@analytics/graphql-api/_old_mutations/DeleteActionMap';
import ORGANIZATION_KEY_QUERY from '@analytics/graphql-api/_old_queries/OrganizationKeyQuery';
import {ManagePixelsPixelMappingsRouteProps} from '.';

export function PixelDeleteMapping({
  backPath,
  pixel,
  match: {
    params: {id},
  },
}: ManagePixelsPixelMappingsRouteProps & {
  match: {
    params: {id: string};
  };
}) {
  const [loading, setLoading] = useState(false);
  const [complete, setCompleted] = useState(false);
  const {
    handleSubmit,
    formState: {errors},
    clearErrors,
    setError,
  } = useForm();

  const [deleteActionMap] = useMutation(DELETE_ACTION_MAP_MUTATION, {
    refetchQueries: [ORGANIZATION_KEY_QUERY],
    awaitRefetchQueries: true,
  });

  const actionMap = pixel.actionMaps.find((map) => map.id === id);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const data = await deleteActionMap({
        variables: {
          input: {
            actionMapId: actionMap.id,
          },
        },
      });
      if (data) {
        setCompleted(true);
      }
    } catch (e) {
      setError('graphql', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  const onCancel = () => {
    clearErrors();
    setCompleted(true);
  };

  if ((!actionMap || complete) && !errors.graphql) {
    return <Redirect to={backPath} />;
  }

  return (
    <ResponsiveContent containerSize='small'>
      <CardHeader backPath={backPath}>Delete Event Mapping</CardHeader>
      <Card>
        <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
          <p>Are you sure you would like to delete "{actionMap?.eventName}"?</p>
          <FormFooter>
            <Button type='button' onClick={onCancel} disabled={loading}>
              Cancel
            </Button>
            <Submit loading={loading} disabled={loading} color='error'>
              Delete Event Mapping
            </Submit>
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
}
