import React, {useState} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {format} from 'd3-format';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from '@core/lib/dayjs';
import {financial as _financial} from '@core/lib/filters';
import {toTimeSeries} from '@core/lib/series';
import {Element} from '@core/style';
import {BLUE, TEXT_NORMAL} from '@core/style';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import CampaignAttributionPurchaseValueByDayQuery from '@analytics/graphql-api/_old_queries/CampaignAttributionPurchaseValueByDayQuery';

const financial = format('$.2s');

const renderTooltip = ({payload} = {}) => {
  if (!payload || payload.length < 1) {
    return null;
  }

  const {x, y} = payload[0].payload;

  return (
    <Element
      rules={() => ({
        background: '#fff',
        borderRadius: '.5rem',
        boxShadow: `0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)`,
        padding: '1rem',
      })}>
      <Element rules={() => ({fontWeight: 700, textTransform: 'capitalize'})}>
        {dayjs(x).utc().format('dddd, MMM DD')}
      </Element>
      <Element>Revenue {_financial(y)}</Element>
    </Element>
  );
};

const BarChartImpl = ({data, start, end}) => {
  const [focusBar, setFocusBar] = useState(null);

  return (
    <Element
      rules={() => ({fontSize: '0.875rem'})}
      onMouseLeave={() => setFocusBar(null)}>
      <ResponsiveContainer width='100%' height={300}>
        <BarChart
          data={data}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setFocusBar(state.activeTooltipIndex);
            } else {
              setFocusBar(null);
            }
          }}>
          <CartesianGrid stroke='#ccc' strokeDasharray='1 1' />
          <XAxis
            dataKey='x'
            domain={[start.toDate().getTime(), end.toDate().getTime()]}
            type='number'
            scale='time'
            tickFormatter={(timeStr) => dayjs(timeStr).utc().format('MMM DD')}
          />
          <YAxis tickFormatter={(value) => financial(value)} />
          <Tooltip content={renderTooltip} />
          <Bar dataKey='y' fill={BLUE}>
            {data.map((entry, idx) => {
              return (
                <Cell key={idx} fill={focusBar === idx ? TEXT_NORMAL : BLUE} />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Element>
  );
};

const CampaignAttributionPurchaseValueByDayBase = ({
  campaign,
  multiplier = 1,
  after,
  before,
  data: {me, loading, error},
}) => {
  if (loading) {
    return <Loading centered />;
  }

  let purchaseValueByDay;
  try {
    purchaseValueByDay =
      me.organization.campaign.attribution.events.purchaseValueByDay;
  } catch (err) {}

  if (!purchaseValueByDay || purchaseValueByDay.length < 1) {
    return (
      <EmptyMessage title='Purchases Unavailable'>
        Ad Analytics does not have download information for this podcast.
      </EmptyMessage>
    );
  }

  const yesterday = dayjs().utc().subtract(1, 'day');
  const start = after ? after : campaign.startAt;
  const end = (
    before.isBefore(yesterday)
      ? before
      : dayjs(campaign.endAt).isBefore(yesterday)
      ? dayjs(campaign.endAt)
      : yesterday
  ).subtract(1, 'day');

  const data = toTimeSeries(
    purchaseValueByDay.map(({day, value}) => {
      return {
        x: dayjs(day).valueOf(),
        y: value * multiplier,
      };
    }),
    'day',
    start,
    end
  );

  return <BarChartImpl data={data} start={start} end={end} />;
};

const CampaignAttributionPurchaseValueByDayChart = graphql(
  CampaignAttributionPurchaseValueByDayQuery,
  {
    options: ({organization, campaign: {id}, after, before}) => ({
      variables: {
        organizationId: organization.id,
        id,
        after: after ? after.format('YYYY-MM-DD') : null,
        before: before ? before.format('YYYY-MM-DD') : null,
      },
    }),
  }
)(CampaignAttributionPurchaseValueByDayBase);

export {CampaignAttributionPurchaseValueByDayChart};
