import {FetchedUser} from '@core/graphql-api';

const SUCCESS_EMAILS = [
  'djacks@podsights.com',
  'hannah@podsights.com',
  'jocelyn@podsights.com',
  'lena@podsights.com',
  'stimmins@podsights.com',
  'tomas@podsights.com',
  'will@podsights.com',
];

export const userIsSuccess = (email: string) =>
  !!SUCCESS_EMAILS.find((e) => e === email);

export const userHasOrgKind = (
  organizations: FetchedUser['organizations'],
  kind: string
) => !!organizations.find((org) => org.kind === kind);

export const toModeled = (instance: any, attr: string, modelled: boolean) => {
  // We have a constant approach to modeled values in and instance
  // instance {userTotal, userTotalModeled attribution {sum {users usersTotal}}}
  // this pulls the right value
  const attribution = instance.attribution ? instance.attribution : false;

  let _attr = !!attribution
    ? attr === 'value'
      ? attr
      : `${attr}s`
    : `${attr}Total`;
  _attr = modelled ? `${_attr}Modeled` : _attr;

  return attribution ? attribution.users.sum[_attr] : instance[_attr];
};
