import {gql} from '@apollo/client';

export default gql`
  fragment CampaignEpisode on CampaignEpisodeObject {
    id

    isBaseline

    startAt
    endAt

    downloadTotal
    reachTotal
    attributionTotal
    userTotal
    userTotalModeled
    productTotal
    productTotalModeled
    addtocartTotal
    addtocartTotalModeled
    checkoutTotal
    checkoutTotalModeled
    purchaseTotal
    purchaseTotalModeled
    leadTotal
    leadTotalModeled
    valueTotal
    valueTotalModeled
    installedTotal
    installedTotalModeled

    targetDownloadTotal
    targetReachTotal

    cost
    costToDate
    goal

    verified

    campaignPodcast {
      id
    }

    episode {
      id
      title
      description(words: 25)
      image {
        id
        href
        path
      }
      published
      enclosure {
        id
        length
      }
    }

    futureEpisode {
      id
      title
      subtitle
      expectedPublished
    }
  }
`;
