import React, {useMemo, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {createOrgPath} from '@core/lib/organizations';
import {ToastMessage} from '@core/ui/Alert';
import Button from '@core/ui/Button/LegacyButton';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Dummy, Img} from '@core/ui/Image';
import {Link} from '@core/ui/Link';
import {LoadingSpinner} from '@core/ui/Loading';
import Toggle from '@core/ui/Toggle';
import {Tooltip} from '@core/ui/Tooltip';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import SET_ADVISOR_OPTIONS_MUTATION from '@analytics/graphql-api/_old_mutations/SetAdvisorOptions';
import PODCASTS_QUERY from '@analytics/graphql-api/_old_queries/PodcastsQuery';

interface AdvisorOptionsPodcastsProps {
  onCancel: () => void;
  onSave: () => void;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}

type AdvisorPodcast = {
  id: string;
  isAdvisor: boolean;
  processingStartedAt: string;
  createdAt: string;
  feed: {
    id: string;
    title: string;
    description: string;
    href: string;
    state: string;
    publisherName: string;
    publisherEmail: string;
    image: {
      id: string;
      href: string;
      path: string;
    };
  };
};

interface AdvisorOptionsPodcastsContentProps
  extends AdvisorOptionsPodcastsProps {
  podcasts: Array<AdvisorPodcast>;
}

const AdvisorOptionsPodcastsContent = ({
  onCancel,
  onSave,
  organization,
  podcasts,
}: AdvisorOptionsPodcastsContentProps) => {
  const [setAdvisorOptions] = useMutation(SET_ADVISOR_OPTIONS_MUTATION);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [selectedPodcasts, setSelectedPodcasts] = useState<string[]>(
    ('isAdvisor' in organization && organization.isAdvisor
      ? podcasts.filter(({isAdvisor}) => isAdvisor)
      : podcasts
    ).map(({id}) => id)
  );

  const onSubmit = async () => {
    setLoading(true);
    try {
      await setAdvisorOptions({
        variables: {
          input: {
            organizationId: organization.id,
            podcastIds: selectedPodcasts,
          },
        },
      });
      onSave();
    } catch (err) {
      setError(error);
    }
    setLoading(false);
  };

  const data: Array<
    {
      isAdvisorable: boolean;
    } & AdvisorPodcast
  > = useMemo(() => {
    return podcasts
      .map((podcast) => ({
        ...podcast,
        isAdvisorable: !!podcast.processingStartedAt,
      }))
      .sort((a, b) => {
        return a.isAdvisorable === b.isAdvisorable
          ? 0
          : a.isAdvisorable
          ? -1
          : 1;
      });
  }, []);

  const columns: DataTableColumn[] = [
    {
      Cell: ({data: {id, isAdvisorable}}) => {
        if (!isAdvisorable) {
          return (
            <Tooltip
              description={
                "Ad Analytics' Analytics Prefix or Log transfer has not been set up on this show and is therefore not eligible for Advisor."
              }>
              <Toggle disabled />
            </Tooltip>
          );
        }
        return (
          <Toggle
            defaultChecked={selectedPodcasts.includes(id)}
            onChecked={() => {
              if (selectedPodcasts.indexOf(id) > -1) {
                setSelectedPodcasts(
                  selectedPodcasts.filter((selectedId) => selectedId !== id)
                );
              } else {
                setSelectedPodcasts([...selectedPodcasts, id]);
              }
            }}
          />
        );
      },
    },
    {
      title: 'Podcast',
      accessor: 'title',
      type: 'string',
      Cell: ({
        data: {
          feed: {title, description, image},
        },
      }) => {
        return (
          <div css={{display: 'flex', minWidth: '12.5rem'}}>
            <div css={{height: '4rem', marginRight: '1rem', width: '4rem'}}>
              {image ? <Img image={image} width={150} /> : <Dummy />}
            </div>
            <div css={{flex: 1}}>
              <div css={{color: 'var(--text-default)', fontWeight: 500}}>
                {title}
              </div>
              <p
                css={{
                  color: 'var(--text-muted)',
                  fontSize: '.875rem',
                  margin: '0.25rem 0 0 0',
                }}>
                {description}
              </p>
            </div>
          </div>
        );
      },
    },
  ];

  const elegibleCount = data.filter(
    ({isAdvisorable}) => !!isAdvisorable
  ).length;

  const notElegibleCount = data.filter(
    ({isAdvisorable}) => !isAdvisorable
  ).length;

  if (loading) {
    return <LoadingSpinner centered />;
  }

  if (error) {
    <ToastMessage alertType='warning'>
      Network error. Please try again later.
    </ToastMessage>;
  }
  return (
    <>
      <p>
        Please select the podcasts you would like to appear in Advisor.{' '}
        <b>{elegibleCount} are eligible</b> because you have installed the
        analytics prefix or set up a log transfer.{' '}
        <b>{notElegibleCount} are not eligible</b> for Advisor because we are
        not receiving download information.
      </p>
      <p>
        If you own podcasts that do not appear here, you can add them in the{' '}
        <Link to={createOrgPath(organization, '/manage/podcasts')}>
          Manage {'>'} Podcasts
        </Link>{' '}
        tab.
      </p>
      <DataTable
        columns={columns}
        data={data}
        orderBy='isAdvisorable'
        searchKeys={['title']}
        paginationRowsPerPage={50}
        paginationRowsPerPageOptions={[50, 100, 200]}
      />
      <div
        css={{
          margin: '2rem 0 1rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Button onClick={onCancel} style-default>
          Cancel
        </Button>
        <Button onClick={onSubmit} style-primary>
          Get Started with Advisor ⇾
        </Button>
      </div>
    </>
  );
};

const AdvisorOptionsPodcasts = ({
  onCancel,
  onSave,
  organization,
}: AdvisorOptionsPodcastsProps) => {
  const {data, error, loading} = useQuery(PODCASTS_QUERY, {
    variables: {id: organization.id},
  });

  let content: React.ReactNode;

  if (loading) {
    content = <LoadingSpinner centered />;
  } else if (error) {
    content = (
      <ToastMessage alertType='warning'>
        Network error. Please try again later.
      </ToastMessage>
    );
  } else {
    const {podcasts} = data.me.organization;

    content = (
      <AdvisorOptionsPodcastsContent
        onCancel={onCancel}
        onSave={onSave}
        organization={organization}
        podcasts={podcasts}
      />
    );
  }

  return (
    <Card>
      <CardHeader>Podcasts to Include in Advisor</CardHeader>
      {content}
    </Card>
  );
};

export default AdvisorOptionsPodcasts;
