import {useQuery} from '@apollo/client';
import {Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import Loading from '@core/ui/Loading';
import ManageNav from '@analytics/components/nav/ManageNav';
import {FetchedOrganization} from '@analytics/graphql-api';
import PODCASTS_QUERY from '@analytics/graphql-api/_old_queries/PodcastsQuery';
import ClaimPodcast from './ClaimPodcast';
import Podcasts from './Podcasts';
import {VerifyPodcast} from './VerifyPodcast';

type Props = {
  organization: FetchedOrganization;
  user: FetchedUser;
};

export type ManagePodcastsRouteProps = Props & {
  podcasts: TSFixMe[];
};

export function ManagePodcastsIndex({user, organization}: Props) {
  const {data, error, loading} = useQuery(PODCASTS_QUERY, {
    variables: {
      id: organization.id,
    },
  });

  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, '/manage/podcasts'),
    basePath: '/:part(b|p)/:slug/manage/podcasts',
    routes: [
      {key: 'podcasts', path: '', exact: true},
      {key: 'verify', exact: true},
      {key: 'claim', path: 'claim/:href', exact: true},
    ],
  });

  return (
    <>
      <ManageNav organization={organization} user={user} />
      {loading ? (
        <Loading centered />
      ) : (
        <Switch>
          {renderRoutes({
            components: {
              podcasts: Podcasts,
              verify: VerifyPodcast,
              claim: ClaimPodcast,
            },
            extraProps: {
              user,
              organization,
              podcasts: data?.me?.organization?.podcasts ?? [],
            },
            redirects: [{from: '*', to: ''}],
          })}
        </Switch>
      )}
    </>
  );
}
