import {useState} from 'react';
import dayjs from 'dayjs';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {Card, CardHeader} from '@core/ui/Content';
import {DateRangeInput} from '@core/ui/DatePicker';
import {
  FORM_ERRORS,
  Form,
  FormGroup,
  FormHelp,
  Label,
  LabelInput,
  LabelSelect,
  Submit,
} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import Toggle from '@core/ui/Toggle';
import {campaignLiftReportURL} from '@analytics/components/nav/CampaignLiftReportNav';
import {measureLiftReportUrl} from '@analytics/components/nav/MeasureLiftNav';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedCampaignLiftReports,
  FetchedIncrementalLiftReports,
  FetchedOrganization,
  LiftReportState,
  UpdateLiftReportInput,
  useUpdateLiftReportMutation,
} from '@analytics/graphql-api';
import {LiftReportRequestChart} from './LiftReportRequestChart';
import {defaultFormValues, liftReportHardDates} from './helpers';

type FormData = {
  name: string;
  delta: string;
  dateRange: {
    impressionsStartAt: dayjs.Dayjs;
    impressionsEndAt: dayjs.Dayjs;
  };
  state: LiftReportState;
  useNoise: boolean;
  clampUrl: string | undefined;
  networkError: boolean;
};

type Props = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign?: FetchedCampaign;
  liftReport:
    | NonNullable<FetchedIncrementalLiftReports[number]>
    | NonNullable<FetchedCampaignLiftReports[number]>;
};

export function LiftReportUpdateForm({
  campaign,
  organization,
  liftReport,
}: Props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [updateLiftReport] = useUpdateLiftReportMutation();

  const {
    control,
    clearErrors,
    formState: {errors},
    handleSubmit,
    setError,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      ...defaultFormValues({liftReport}),
    },
  });

  const onSubmit = async (formData: FormData) => {
    setLoading(true);
    clearErrors('networkError');
    try {
      await updateLiftReport({
        variables: {
          input: {
            liftReportId: liftReport.id,
            name: formData.name,
            delta: parseInt(formData.delta),
            state: formData.state,
            useNoise: formData.useNoise,
            clampUrl: formData.clampUrl,
            impressionsStartAt:
              formData.dateRange.impressionsStartAt.format('YYYY-MM-DD'),
            impressionsEndAt:
              formData.dateRange.impressionsEndAt.format('YYYY-MM-DD'),
          } as UpdateLiftReportInput,
        },
      });

      history.push(
        campaign
          ? campaignLiftReportURL(organization, campaign, liftReport)
          : measureLiftReportUrl(organization, liftReport)
      );
    } catch (e) {
      setError('networkError', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  // Need logic in order not to break old lift reports
  // Will be able to remove once API migrates campaign to campaigns on LiftReport
  const {hardStart, hardEnd} = liftReportHardDates(
    liftReport.campaigns?.length ? liftReport.campaigns : [liftReport.campaign]
  );

  const values = watch();

  return (
    <ResponsiveContent containerSize='medium'>
      <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          backPath={
            campaign
              ? campaignLiftReportURL(organization, campaign, liftReport)
              : measureLiftReportUrl(organization, liftReport)
          }>
          Update Lift Report
        </CardHeader>
        <Card variant='filled' css={{marginBottom: 'var(--spacing-4)'}}>
          <Controller
            name='dateRange'
            control={control}
            render={({field}) => (
              <DateRangeInput
                onDatesChange={({startDate, endDate}) =>
                  field.onChange({
                    impressionsStartAt: startDate,
                    impressionsEndAt: endDate,
                  })
                }
                startDate={values.dateRange.impressionsStartAt}
                endDate={values.dateRange.impressionsEndAt}
                isOutsideRange={(date) =>
                  !(
                    date.isSameOrAfter(hardStart) &&
                    date.isSameOrBefore(hardEnd)
                  )
                }
              />
            )}
          />
        </Card>
        <Card>
          <div css={{margin: '2rem 0', fontSize: '0.8125rem'}}>
            <LiftReportRequestChart
              organization={organization}
              liftReport={liftReport}
              impressionsRange={values.dateRange}
            />
          </div>
        </Card>
        <CardHeader>Additional Options</CardHeader>
        <Card>
          <FormGroup>
            <Controller
              name='delta'
              control={control}
              render={({field}) => (
                <LabelInput
                  label='Delta'
                  defaultValue={values.delta}
                  type='number'
                  step='1'
                  onChange={field.onChange}
                />
              )}
            />
            <FormHelp>Offset we want to show by default</FormHelp>
          </FormGroup>
          <FormGroup>
            <Controller
              name='state'
              control={control}
              render={({field}) => (
                <LabelSelect
                  label='Report State'
                  defaultValue={values.state}
                  items={[
                    {
                      title: 'Requested',
                      value: LiftReportState.Requested,
                    },
                    {
                      title: 'Running',
                      value: LiftReportState.Running,
                    },
                    {
                      title: 'Draft',
                      value: LiftReportState.Draft,
                    },
                    {
                      title: 'Error',
                      value: LiftReportState.Error,
                    },
                    {
                      title: 'Live',
                      value: LiftReportState.Live,
                    },
                  ]}
                  onSelect={({value}) => field.onChange(value)}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label>Use Noise</Label>
            <Controller
              name='useNoise'
              control={control}
              render={({field}) => (
                <Toggle
                  defaultChecked={values.useNoise}
                  onChecked={field.onChange}
                />
              )}
            />
            <FormHelp>Use the noise group instead of the baseline</FormHelp>
          </FormGroup>
          <FormGroup>
            <Controller
              name='clampUrl'
              control={control}
              render={({field}) => (
                <LabelInput
                  label='URL'
                  defaultValue={values.clampUrl}
                  onChange={field.onChange}
                />
              )}
            />
            <FormHelp>
              You are only seeing this because you are an admin — It allows us
              to run lift for a single URL
            </FormHelp>
          </FormGroup>
        </Card>
        <Stack justifyContent='flex-end' css={{margin: 'var(--spacing-6)'}}>
          <Submit loading={loading} color='primary'>
            Update Lift Report
          </Submit>
        </Stack>
      </Form>
    </ResponsiveContent>
  );
}
