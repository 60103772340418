import { assertNever } from "../../typeHelpers.js";
import styled from "../../styled-components.js";
export var getSize = function getSize(indicatorSize) {
  switch (indicatorSize) {
    case 'sm':
    case 'small':
      return 56;
    case 'md':
    case 'medium':
      return 84;
    case 'lg':
    case 'large':
      return 112;
    default:
      return assertNever(indicatorSize);
  }
};
export var LoadingIndicatorSvg = styled.svg.withConfig({
  displayName: "LoadingIndicatorSvg",
  componentId: "sc-dj4dcm-0"
})(["content:'';block-size:", "px;inline-size:", "px;"], function (props) {
  return getSize(props.indicatorSize) / 4.5;
}, function (props) {
  return getSize(props.indicatorSize);
});