import {TooltipProps} from 'recharts';
import {comma} from '@core/lib/filters';
import {BrandLiftChartTooltip} from '../shared';

export function BrandLiftPieChartTooltip({
  payload,
}: TooltipProps<number, string>) {
  if (!payload || !payload.length) return null;

  const showValueByGroup =
    payload[0]?.payload?.controlTotal !== undefined &&
    payload[0]?.payload?.exposedTotal !== undefined;

  return (
    <BrandLiftChartTooltip
      label={payload[0]?.payload?.group}
      labelValue={
        showValueByGroup ? undefined : `n=${comma(payload[0].payload?.total)}`
      }
      valueByGroup={
        showValueByGroup
          ? {
              control: {
                label: 'Control',
                value: `n=${comma(payload[0]?.payload?.controlTotal)}`,
              },
              exposed: {
                label: 'Exposed',
                value: `n=${comma(payload[0]?.payload?.exposedTotal)}`,
              },
            }
          : undefined
      }
    />
  );
}
