import {useMemo} from 'react';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Text} from '@core/ui/Text';
import useSettings from '@analytics/components/settings/useSettings';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
} from '@analytics/graphql-api';
import campaignTableColumns from '@analytics/lib/campaignTableColumns';
import {getCampaignStreamings} from './helpers/getCampaignStreamings';

export function CampaignStreamingsTable({
  organization,
  campaign,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign: FetchedCampaign;
}) {
  const settings = useSettings();
  const data = useMemo(
    () =>
      getCampaignStreamings(campaign.campaignStreamings, {
        campaign,
        organization,
      }),
    [organization, campaign, settings]
  );

  const columns = useMemo<DataTableColumn[]>(
    () => [
      {
        title: 'Line Item',
        accessor: 'name',
        type: 'string' as const,
        Cell: ({d: {name}}) => (
          <Text css={{minWidth: '12.5rem'}} variant='subtitle2'>
            {name}
          </Text>
        ),
      },
      ...campaignTableColumns(organization, campaign, data),
    ],
    [organization, campaign, data]
  );

  return (
    <DataTable
      searchKeys={['name']}
      columns={columns}
      orderBy='-downloadTotal'
      data={data}
      searchPlaceholder='Search'
      onClickRowPath={({url}) => url}
    />
  );
}
