import {DataTableFilterButton} from '@core/ui/DataTable';
import {Icon} from '@core/ui/Icon';
import {AdvisorColumn} from '../types';

export function OpenDropdownButton({
  buttonRef,
  toggle,
  activeColumns,
}: {
  buttonRef: React.RefObject<HTMLButtonElement>;
  toggle: () => void;
  activeColumns: AdvisorColumn[];
}) {
  return (
    <DataTableFilterButton
      domRef={buttonRef}
      onClick={() => toggle()}
      noArrowIcon
      active={activeColumns.length > 0}
      rules={[
        () => ({
          background: 'var(--bg-default)',
          paddingLeft: '0.675rem',
          paddingRight: '1rem',
        }),
      ]}>
      <Icon
        icon='columns'
        rules={() => ({
          color: 'var(--icon-subtle)',
          height: '1.25rem',
          marginRight: '0.375rem',
          width: '1.25rem',
        })}
      />
      Columns ({activeColumns.length})
    </DataTableFilterButton>
  );
}
