import { buttonLinkReset, controlPaddingBlock, controlSizeFallback, cssColorValue, cssControlSize, cssSpacing, rootStyle, semanticColors, viola } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
var hoverStyles = function hoverStyles(href) {
  return css(["background-color:", ";color:", ";", ";"], cssColorValue(semanticColors.backgroundElevatedHighlight), cssColorValue(semanticColors.textSubdued), href && css(["cursor:default;"]));
};
var activeStyles = css(["&,&:hover{background-color:", ";color:", ";}"], cssColorValue(semanticColors.backgroundElevatedPress), cssColorValue(semanticColors.textSubdued));
var selectedStyles = css(["&,&:hover{background-color:", ";color:", ";}"], cssColorValue(semanticColors.backgroundElevatedPress), cssColorValue(semanticColors.textBase));

// TODO: Add the margin reset (Safari fix) to the buttonLinkReset mixin

// eslint-disable-next-line prefer-arrow-callback
export var Link = styled.button.withConfig({
  displayName: "Link",
  componentId: "sc-qu2gai-0"
})(["", ";", ";", ";font-feature-settings:'tnum';text-align:start;display:block;width:100%;margin-block-start:0;margin-block-end:0;padding-block-start:", ";padding-inline-end:10px;padding-block-end:", ";padding-inline-start:10px;min-block-size:", ";color:", ";&:hover{", "}&:active,&:focus{", "}", " ", ";", ""], function (props) {
  return buttonLinkReset(props.useBrowserDefaultFocusStyle);
}, rootStyle(), viola(), cssSpacing('tighter-4', controlPaddingBlock.sm), cssSpacing('tighter-4', controlPaddingBlock.sm), cssControlSize('smaller', controlSizeFallback.small), cssColorValue(semanticColors.textSubdued), function (props) {
  return hoverStyles(props.href);
}, activeStyles, function (props) {
  return props.isHover && hoverStyles(props.href);
}, function (props) {
  return props.isActive && activeStyles;
}, function (props) {
  return props.selected && selectedStyles;
});