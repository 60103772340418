import {useLocation} from 'react-router-dom';

export const FEATURE_FLAGS = {
  SALES_ORDER: 'salesOrders',
  DAI_INSIGHTS: 'DAI_INSIGHTS',
} as const;

export type AcceptedFlagsType = keyof typeof FEATURE_FLAGS;

const useFeatureFlags = (flag: AcceptedFlagsType) => {
  const {search} = useLocation();
  const params = new URLSearchParams(search);
  const flagParam = params.get(flag);
  return Boolean(flagParam);
};

export default useFeatureFlags;
