import {FC, useState} from 'react';
import {stripHTML, truncate} from '@core/lib/words';
import {Text, TextProps} from '../Text';

export type CollapsableTextProps = TextProps & {
  /**
   * Limit of characters when collapsed.
   * @default 200
   */
  charLimit?: number;
  /**
   * Text for the collapse button.
   * If `noCollapseButton` is true, this doesn't do anything.
   * @default 'Show less'
   */
  collapseButtonText?: string;
  /**
   * Text for the expand button.
   * @default 'Show more'
   */
  expandButtonText?: string;
  /**
   * If `true`, the collapse button won't be rendered.
   * @default false
   */
  noCollapseButton?: boolean;
  /**
   * Text for the collapsable.
   */
  text: string;
  className?: string;
};

export const CollapsableText: FC<CollapsableTextProps> = ({
  charLimit = 200,
  collapseButtonText = 'Show less',
  expandButtonText = 'Show more',
  noCollapseButton = false,
  text,
  ...props
}): JSX.Element => {
  const [expanded, setExpaned] = useState<boolean>(false);
  const safeText = stripHTML(text);

  return (
    <Text {...props}>
      {safeText.length > charLimit ? (
        <>
          {expanded ? safeText : truncate(safeText, charLimit)}
          {expanded && noCollapseButton ? null : (
            <button
              css={`
                background: none;
                border: 0;
                color: var(--blue);
                margin-left: 0.25rem;
                text-decoration: none;
                :hover {
                  text-decoration: underline;
                }
              `}
              onClick={(evt) => {
                evt.preventDefault();
                setExpaned(!expanded);
              }}>
              {expanded ? collapseButtonText : expandButtonText}
            </button>
          )}
        </>
      ) : (
        safeText
      )}
    </Text>
  );
};
