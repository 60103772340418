import {useMemo, useState} from 'react';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {CardHeader} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import {Stack} from '@core/ui/Layout';
import useSettings from '@analytics/components/settings/useSettings';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {getCampaignDynamics} from '../../helpers/getCampaignDynamics';
import {getCampaignStreamings} from '../../helpers/getCampaignStreamings';
import {
  SuperCampaign,
  SuperCampaignDynamics,
  SuperCampaignStreamings,
} from '../_types';
import {AdsNav} from './AdsNav';
import {ActiveAdType} from './_types';
import {spotifyAdsTableColumns} from './columns';

export function SpotifySalesOrderAdsTable({
  headerTitle = 'Ad Placements',
  organization,
  superCampaign,
  superCampaignDynamics,
  superCampaignStreamings,
}: {
  headerTitle?: string;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  superCampaign: SuperCampaign;
  superCampaignDynamics: SuperCampaignDynamics;
  superCampaignStreamings: SuperCampaignStreamings;
}) {
  const [settings] = useSettings();
  const [activeAdType, setActiveAdType] = useState<ActiveAdType>('all');

  const data = useMemo(
    () =>
      [
        ...getCampaignDynamics(superCampaignDynamics, {
          campaign: superCampaign,
          organization,
        }),
        ...getCampaignStreamings(superCampaignStreamings, {
          campaign: superCampaign,
          organization,
        }),
      ].filter((ad) =>
        activeAdType !== 'all' ? ad.format === activeAdType : ad
      ),
    [
      activeAdType,
      superCampaignDynamics,
      superCampaignStreamings,
      superCampaign,
      organization,
    ]
  );

  return (
    <DataTable
      data={data}
      columns={spotifyAdsTableColumns({superCampaign, settings})}
      emptyMessageText='No ads found.'
      searchKeys={['id', 'name']}
      headerRenderer={({filtersContent, searchContent}) => (
        <Stack direction='column' gap={3}>
          <Stack justifyContent='space-between' alignItems='center'>
            <CardHeader
              css={`
                margin: 0;
              `}>
              {headerTitle}
            </CardHeader>
            {(searchContent || filtersContent) && (
              <Stack alignItems='center'>
                {searchContent}
                {filtersContent}
              </Stack>
            )}
          </Stack>
          <AdsNav activeAdType={activeAdType} onClick={setActiveAdType} />
          {(activeAdType === 'dynamic' || activeAdType === 'streaming') && (
            <Alert
              severity={activeAdType === 'streaming' ? 'spotify' : 'download'}>
              {t(`spotify.${activeAdType}.ads`)}
            </Alert>
          )}
        </Stack>
      )}
    />
  );
}
