import {gql} from '@apollo/client';

export default gql`
  query getBrandOrganizationImpressionsRegionLocationQuery(
    $id: ID!
    $after: Date
    $before: Date
    $publisherIds: [ID]
    $campaignIds: [ID]
    $country: String
    $region: String
  ) {
    me {
      id
      organization(id: $id) {
        id
        overview {
          impressions(
            after: $after
            before: $before
            publisherIds: $publisherIds
            campaignIds: $campaignIds
          ) {
            locations(country: $country, region: $region) {
              count
              cities {
                country
                region
                city
                total
              }
              nodes {
                country
                region
                city
                latitude
                longitude
                count: total
              }
            }
          }
        }
      }
    }
  }
`;
