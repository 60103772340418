import {
  Cell,
  Legend,
  LegendProps,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import styled from 'styled-components';
import {comma, toPercent} from '@core/lib/filters';
import {Stack} from '@core/ui/Layout';

const FILL_COLORS = ['#59A0F6', '#806AF4', '#F036A4', '#CC2FAF'];

const InsightsPerformancePlayersPieChartToolTipLabel = styled.div<{
  fill: string;
}>`
  color: ${({fill}) => fill};
  font-size: 1.25rem;
  font-weight: 500;
  margin-right: 0.5rem;
`;

const InsightsPerformancePlayersPieChartToolTip = ({payload, attr}) => {
  if (!payload || payload.length < 1) {
    return null;
  }
  const {name, fill, value} = payload[0].payload;

  return (
    <div
      css={`
        background: var(--bg-overlay);
        borderradius: 0.5rem;
        boxshadow: var(--shadow-lg);
        padding: 1rem;
        whitespace: nowrap;
      `}>
      <div
        css={`
          color: var(--text-subtle),
          marginBottom: '0.375rem',
        `}>
        {name}
      </div>
      <InsightsPerformancePlayersPieChartToolTipLabel fill={fill}>
        {comma(value)}{' '}
        <span
          css={`
            font-size: 1rem;
          `}>
          {attr == 'reach' ? 'Reach' : 'Impressions'}
        </span>
      </InsightsPerformancePlayersPieChartToolTipLabel>
    </div>
  );
};

const InsightsPieChartLegend = ({
  attr,
  payload,
}: {
  attr: string;
  payload?: {
    color?: string;
    payload: {
      percent: number;
    };
  }[];
} & LegendProps) => {
  return (
    <Stack
      direction='column'
      gap='var(--spacing-2)'
      css={{fontSize: 'var(--font-size-body-3)'}}>
      {payload?.map(({payload, color}) => (
        <Stack key={payload[attr]} alignItems='center' gap='var(--spacing-1)'>
          <div
            css={{
              background: color,
              height: '0.475rem',
              borderRadius: '50%',
              width: '0.475rem',
            }}
            color={color}
          />
          <div css={{fontWeight: 'var(--font-weight-bold)'}}>
            {toPercent(payload?.percent)}
          </div>
          <div>{payload[attr]}</div>
        </Stack>
      ))}
    </Stack>
  );
};

const InsightsPerformancePlayersPieChart = ({players, attr = 'total'}) => {
  let _index = 0;
  const rest = {
    name: 'All other players',
    value: 0,
    fill: '#4100F5',
  };

  const topPlayers = players
    .reduce((_players, d) => {
      if (d.player != 'Other' && _players.length < 4) {
        let _fill = FILL_COLORS[_index];
        if (d.player == 'Spotify') {
          _fill = '#27856A';
        } else {
          _index += 1;
        }
        _players.push({
          name: d.player,
          value: d[attr],
          fill: _fill,
        });
      } else {
        rest.value += d[attr];
      }
      return _players;
    }, [])
    .concat(rest);

  return (
    <div
      css={`
        fontsize: 0.875rem;
      `}>
      <ResponsiveContainer width='100%' height={325}>
        <PieChart>
          <Pie
            animationBegin={0}
            data={topPlayers}
            dataKey='value'
            innerRadius={125}
            outerRadius={155}
            stroke='var(--bg-default)'>
            {topPlayers.map((d, i) => (
              <Cell key={d.name} fill={d.fill} />
            ))}
          </Pie>
          <Tooltip
            content={<InsightsPerformancePlayersPieChartToolTip attr={attr} />}
          />
          <Legend
            content={<InsightsPieChartLegend attr='name' />}
            align='center'
            layout='vertical'
            verticalAlign='middle'
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InsightsPerformancePlayersPieChart;
