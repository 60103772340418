import {FetchedUser} from '@core/graphql-api';
import {capitalize} from '@core/lib/filters';
import {
  FetchedIncrementalLiftReport,
  LiftReportState,
} from '@analytics/graphql-api';
import {
  CampaignGroupBase,
  IncrementalCampaignLiftGroup,
  IncrementalLiftAverage,
  IncrementalLiftGroup,
  IncrementalLiftNode,
  LiftEventAvgsByKind,
  LiftEventData,
  LiftEventDataByKind,
  LiftEventNodeKind,
  LiftWeekData,
  LiftWeekDataByEvent,
  PartialLiftGroup,
  SerieData,
} from './types';

export const shouldDisplayUnavailable = (
  liftReport: FetchedIncrementalLiftReport,
  user: FetchedUser
) =>
  [
    LiftReportState.Requested,
    LiftReportState.Running,
    LiftReportState.Error,
  ].some((str) => str === liftReport.state) ||
  (!user.isAdmin &&
    (liftReport.state === LiftReportState.Draft ||
      liftReport.state === LiftReportState.Error));

export const getLiftDynamicBaselines = (
  liftReport: FetchedIncrementalLiftReport
) =>
  (liftReport?.lift?.averages || [])
    .filter(
      (a) =>
        !!a &&
        (a.groupType === 'dynamic' || a.groupType === 'streaming') &&
        a.kind === 'baseline'
    )
    .sort((a, b) => (b?.groupReach ?? 0) - (a?.groupReach ?? 0));

const sum = (array: number[]) => array.reduce((a, b) => a + b, 0);
const avg = (array: number[]) =>
  !array.length ? 0 : sum(array) / array.length;

const getWeeksNumbersArr = (nodes: IncrementalLiftNode[]) => {
  const w = [...new Set(nodes.map((n) => n.week))];
  const _length = w.length ? (w[w.length - 1] as number) + 1 : 0;
  return Array.from(Array(_length), (_, x) => x);
};

const seperateByKind = <
  T extends {
    groupId: string | null;
    kind: string | null;
  }
>(
  nodes: T[],
  dynamicBaselineGroupId: string | undefined
) =>
  nodes.reduce(
    (separatedNodes, node) => {
      switch (node.kind) {
        case 'exposed':
        case 'noise':
          return {
            ...separatedNodes,
            [node.kind]: [...separatedNodes[node.kind], node],
          };
        case 'baseline':
          return {
            ...separatedNodes,
            ...(!dynamicBaselineGroupId ||
            (dynamicBaselineGroupId && node.groupId === dynamicBaselineGroupId)
              ? [{[node.kind]: [...separatedNodes[node.kind], node]}]
              : []),
          };
        default:
          return separatedNodes as never;
      }
    },
    {
      exposed: [] as T[],
      baseline: [] as T[],
      noise: [] as T[],
    }
  );

const calcBaselineNodesByGroup = (
  groupId: string | null,
  baselines: (IncrementalLiftNode | IncrementalLiftAverage)[],
  noises: (IncrementalLiftNode | IncrementalLiftAverage)[]
) => {
  const _nodes = baselines.filter((node) => node.groupId === groupId) ?? [];
  // We don't have a match, so assume there is only one baseline control.
  if (!_nodes.length && baselines.length > 0) return baselines;
  // Go to the noise group instead.
  if (!_nodes.length && noises.length > 0) return noises;
  return _nodes;
};

const calcExposedEventDataByWeek = (
  node: IncrementalLiftNode,
  weekData: IncrementalLiftNode,
  exposed_groupHouseholds: number
) =>
  eventPropNames.reduce((eventMap, event) => {
    const newValue =
      (node[`${event}Value`] ?? 0) + (weekData[`${event}Value`] ?? 0);
    return {
      ...eventMap,
      [`${event}Value`]: newValue,
      [`${event}Percentage`]: newValue / exposed_groupHouseholds,
    };
  }, {} as LiftEventData);

const sumWeekData = (
  newEventData: LiftEventDataByKind<LiftEventNodeKind>,
  oldEventData: LiftEventDataByKind<LiftEventNodeKind>
) =>
  Object.keys(newEventData).reduce(
    (eventsSum, prop) => ({
      ...eventsSum,
      [prop]: newEventData[prop] + (oldEventData[prop] ?? 0),
    }),
    {} as LiftEventDataByKind<LiftEventNodeKind>
  );

const calcGroupWeekData = (group: PartialLiftGroup) => {
  const {
    baseline_avgInitPercentage,
    baseline_avgInstalledPercentage,
    baseline_avgLeadPercentage,
    baseline_avgPurchasePercentage,
    baselineNodes,
    exposed_groupReach,
    exposedNodes,
    groupCost,
  } = group;

  const weeks = getWeeksNumbersArr(
    baselineNodes.length ? baselineNodes : exposedNodes
  );

  const avgInitValue = baseline_avgInitPercentage * exposed_groupReach;
  const avgInstalledValue =
    baseline_avgInstalledPercentage * exposed_groupReach;
  const avgLeadValue = baseline_avgLeadPercentage * exposed_groupReach;
  const avgPurchaseValue = baseline_avgPurchasePercentage * exposed_groupReach;

  const eventBaselineMap = {
    init: avgInitValue,
    lead: avgLeadValue,
    purchase: avgPurchaseValue,
    installed: avgInstalledValue,
  };

  const weekData = weeks.map((week) => {
    const exposed = exposedNodes.filter((n) => n.week === week);

    const combinedWeekData = exposed.reduce((sumData, node) => {
      const eventData = eventPropNames.reduce((evtData, event) => {
        const eventValue =
          (node[`${event}Percentage`] ?? 0) * exposed_groupReach;
        const isLift = eventValue > eventBaselineMap[event];
        return {
          ...evtData,
          /* zero out exposed chart data when there is no lift for the week but keep baseline */
          [`exposed_${event}Value`]: isLift ? eventValue : 0,
          [`baseline_${event}Value`]: eventBaselineMap[event],
        };
      }, {} as LiftEventDataByKind<LiftEventNodeKind>);

      return {
        ...sumData,
        ...sumWeekData(eventData, sumData),
      };
    }, {} as LiftEventDataByKind<LiftEventNodeKind>);

    return {
      week,
      avgInitValue,
      avgInstalledValue,
      avgLeadValue,
      avgPurchaseValue,
      ...combinedWeekData,
    } as LiftWeekData;
  });

  const eventData = weekData.reduce((evtData, week) => {
    return eventPropNames.reduce((events, prop) => {
      const thisWeekExposed = week[`exposed_${prop}Value`];
      const thisWeekBaseline = week[`baseline_${prop}Value`];
      const thisWeekLift = thisWeekExposed > thisWeekBaseline;

      const exposed = (evtData?.[prop]?.exposed ?? 0) + (thisWeekExposed ?? 0);

      /* zero out baseline table data when there is no lift for the week */
      const baseline =
        (evtData?.[prop]?.baseline ?? 0) +
        (thisWeekLift ? thisWeekBaseline : 0);

      const diff = exposed - baseline;

      return {
        ...events,
        [prop]: {
          exposed,
          baseline,
          diff,
          lift: baseline ? diff / baseline : diff ? 1 : 0,
          cpa: diff ? groupCost / diff : '-',
        } satisfies SerieData,
      };
    }, {} as LiftWeekDataByEvent);
  }, {} as LiftWeekDataByEvent);

  return {weekData, ...eventData};
};

const calcCampaignGroupEventAverages = (
  nodes: IncrementalLiftNode[],
  type: LiftEventNodeKind
) =>
  eventPropNames.reduce(
    (averages, event) => ({
      ...averages,
      [`${type}_avg${capitalize(event)}Value`]: avg(
        nodes.map((node) => node[`${event}Value`])
      ),
      [`${type}_avg${capitalize(event)}Percentage`]: avg(
        nodes.map((node) => node[`${event}Percentage`])
      ),
    }),
    {} as LiftEventAvgsByKind
  );

const calcCampaignGroup = (groups: IncrementalLiftGroup[]) => {
  const group_base = groups.reduce(
    (group_base, group) => {
      const new_exposed_groupHouseholds =
        group_base.exposed_groupHouseholds + group.exposed_groupHouseholds;

      group.exposedNodes.forEach((node) => {
        const weekData =
          group_base.exposedNodes.get(node.week) ?? ({} as IncrementalLiftNode);
        const newWeekData = {
          ...node,
          ...calcExposedEventDataByWeek(
            node,
            weekData,
            new_exposed_groupHouseholds
          ),
        };

        group_base.exposedNodes.set(node.week, newWeekData);
      });

      return {
        ...group_base,
        groupCost: group_base.groupCost + group.exposed_groupCost,
        exposed_groupReach:
          group_base.exposed_groupReach + group.exposed_groupReach,
        exposed_groupHouseholds: new_exposed_groupHouseholds,
      };
    },
    {
      groupCost: 0,
      exposed_groupHouseholds: 0,
      exposed_groupReach: 0,
      exposedNodes: new Map(),
    } as CampaignGroupBase
  );

  const exposedNodes = Array.from(group_base.exposedNodes.values());
  const baselineNodes = groups[0]?.baselineNodes ?? [];

  // fix the averages
  const baseline_averages = calcCampaignGroupEventAverages(
    baselineNodes,
    'baseline'
  );

  const exposed_averages = calcCampaignGroupEventAverages(
    exposedNodes,
    'exposed'
  );

  const campaign_group = Object.assign(
    group_base,
    baseline_averages,
    exposed_averages,
    {
      exposedNodes,
      baselineNodes,
    }
  );

  return Object.assign(campaign_group, calcGroupWeekData(campaign_group));
};

export const eventPropNames = [
  'init',
  'lead',
  'purchase',
  'installed',
] as const;

export const groupPropNames = [
  'avgInitPercentage',
  'avgInitValue',
  'avgInstalledPercentage',
  'avgInstalledValue',
  'avgLeadPercentage',
  'avgLeadValue',
  'avgPurchasePercentage',
  'avgPurchaseValue',
  'groupHouseholds',
  'groupReach',
  'groupCost',
] as const;

export const generateCampaignLift = ({
  liftReport: {campaigns, lift, useNoise},
  dynamicBaselineGroupId,
}: {
  liftReport: FetchedIncrementalLiftReport;
  dynamicBaselineGroupId: string | undefined;
}) => {
  // Filter null values
  const averages = (lift?.averages ?? []).filter(
    (avg) => !!avg
  ) as IncrementalLiftAverage[];
  const nodes = (lift?.nodes ?? []).filter(
    (node) => !!node
  ) as IncrementalLiftNode[];

  const {
    exposed: exposedAverages,
    baseline: baselineAverages,
    noise: noiseAverages,
  } = seperateByKind<IncrementalLiftAverage>(averages, dynamicBaselineGroupId);

  const {
    exposed: exposedNodes,
    baseline: baselineNodes,
    noise: noiseNodes,
  } = seperateByKind<IncrementalLiftNode>(nodes, dynamicBaselineGroupId);

  // Break into groups
  const groups = exposedAverages.map(
    ({groupId, groupName, groupCost, campaignPodcast, campaignDynamic}) => {
      const _exposedAvg = exposedAverages.find(
        (avg) => avg.groupId === groupId
      );

      const _baselineAvg = useNoise
        ? noiseAverages[0]
        : calcBaselineNodesByGroup(groupId, baselineAverages, noiseAverages)[0];

      const _exposedNodes = exposedNodes.filter(
        (node) => node.groupId === groupId
      );

      const group = groupPropNames.reduce(
        (group, propName) => ({
          ...group,
          [`exposed_${propName}`]: _exposedAvg?.[propName] || 0,
          [`baseline_${propName}`]: _baselineAvg?.[propName] || 0,
        }),
        {
          groupId,
          groupName,
          groupCost,
          campaignPodcast,
          campaignDynamic,
          campaignsCount: _exposedNodes.reduce(
            (acc, node) =>
              acc.includes(node.campaignId) ? acc : [...acc, node.campaignId],
            [] as string[]
          ).length,
          exposedNodes: _exposedNodes,
          baselineNodes: useNoise
            ? noiseNodes
            : calcBaselineNodesByGroup(groupId, baselineNodes, noiseNodes),
        } as IncrementalLiftGroup
      );

      return Object.assign(group, calcGroupWeekData(group));
    }
  );

  const campaignGroup = Object.assign(
    {
      groupName: 'Cross-Campaign Overview',
      campaignsCount: campaigns?.length ?? 1,
      baseline_groupHouseholds: groups[0]?.baseline_groupHouseholds ?? 0,
      baseline_groupReach: groups[0]?.baseline_groupReach ?? 0,
    },
    calcCampaignGroup(groups)
  ) as IncrementalCampaignLiftGroup;

  return {
    groups,
    campaignGroup,
    baselineAverages,
    noiseAverages,
    exposedAverages,
  };
};
