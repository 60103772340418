import {cleanList} from '@core/lib/clean';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import {BrandLiftReportTable} from '@analytics/components/lift/SpotifyBrandLift';
import {useSpotifyBrandLiftReportsQuery} from '@analytics/graphql-api';
import {MeasureRouteProps} from '../..';

export function SpotifyLiftReports({organization, user}: MeasureRouteProps) {
  const {data, loading, error} = useSpotifyBrandLiftReportsQuery({
    variables: {
      organizationId: organization.id,
    },
  });

  const liftReports = cleanList(
    data?.me?.organization?.spotifyBrandLiftReports
  );

  return (
    <Card>
      <CardHeader>Spotify Brand Lift</CardHeader>
      {loading ? (
        <LoadingSpinner centered />
      ) : error ? (
        <Alert severity='error'>{t('errors.network')}</Alert>
      ) : (
        <BrandLiftReportTable
          liftReports={liftReports}
          organization={organization}
          user={user}
        />
      )}
    </Card>
  );
}
