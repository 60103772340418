import { generateCSSSelector } from 'fela-utils';
export default function generateCacheEntry(type, className, property, value) {
  var pseudo = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
  var media = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : '';
  var support = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : '';
  var specificityPrefix = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : '';
  var propertyPriority = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : 1;
  return {
    type: type,
    className: className,
    selector: generateCSSSelector(className, pseudo, specificityPrefix, propertyPriority),
    declaration: property + ':' + value,
    pseudo: pseudo,
    media: media,
    support: support
  };
}