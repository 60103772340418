import {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {createOrgPath} from '@core/lib/organizations';
import {ToastMessage} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {FORM_ERRORS, Form, FormFooter, Submit} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import CLAIM_FEED_MUTATION from '@analytics/graphql-api/_old_mutations/ClaimFeed';
import CLAIM_PUBLISHER_QUERY from '@analytics/graphql-api/_old_queries/ClaimPublisherQuery';
import {ManagePodcastsRouteProps} from '.';

const ClaimPodcastImpl = ({
  organization,
  publisher: {email, title, href},
}: Pick<ManagePodcastsRouteProps, 'organization'> & {
  publisher: {
    email: string;
    title: string;
    href: string;
  };
}) => {
  const {
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();
  const [claimFeed] = useMutation(CLAIM_FEED_MUTATION);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const claim = await claimFeed({
        variables: {
          input: {
            href,
            organizationId: organization.id,
            host: window.location.origin,
          },
        },
      });
      if (claim) {
        setSuccess(true);
      }
    } catch (e) {
      setError(null, FORM_ERRORS.network);
    }
    setLoading(false);
  };

  if (success) {
    return (
      <ResponsiveContent containerSize='medium'>
        <Card rules={() => ({textAlign: 'center'})}>
          <h4>You have an Email</h4>
          <p>Go to your mail box and click the link.</p>
        </Card>
      </ResponsiveContent>
    );
  }

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={createOrgPath(organization, '/manage/podcasts')}>
        {title}
      </CardHeader>
      <Card>
        <p>
          In order to add your podcast we need to verify that you are the owner.
          For this podcast we found the following ownership information:
        </p>
        <div
          css={{
            marginBottom: '20px',
            background: '#fafafa',
            padding: '10px',
            border: '1px solid #eee',
            fontWeight: 'bold',
            fontSize: '18px',
          }}>
          {email}
        </div>
        <p>
          If you do not have access to that email account, please update your
          RSS feed to include owner information. If you are unable to do so,
          contact us at{' '}
          <a
            css={{
              color: 'var(--blue)',
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
            href='mailto:support-adanalytics@spotify.com'>
            support-adanalytics@spotify.com
          </a>
          .
        </p>
        <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
          <FormFooter>
            <Submit buttonSize='lg' loading={loading} disabled={loading}>
              Verify Ownership
            </Submit>
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
};

const ClaimPodcast = ({
  organization,
  match: {
    params: {href},
  },
}: ManagePodcastsRouteProps & {
  match: {
    params: {
      href: string;
    };
  };
}) => {
  const {data, loading, error} = useQuery(CLAIM_PUBLISHER_QUERY, {
    variables: {
      href: decodeURIComponent(href),
    },
  });

  if (loading) {
    return (
      <Card>
        <Loading centered />
      </Card>
    );
  }

  if (error) {
    return (
      <Card rules={() => ({textAlign: 'center'})}>
        <ToastMessage alertType='error'>{error.toString()}</ToastMessage>
        <h4>Unable to Verify Ownership</h4>
        <p>Make sure you have the correct URL (include http or https).</p>
        <pre css={{marginBottom: '15px'}}>{decodeURIComponent(href)}</pre>
        <p>
          If you feel that this information is correct, shoot us a note at{' '}
          <a
            css={{
              color: 'var(--blue)',
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
            href='mailto:support-adanalytics@spotify.com'>
            support-adanalytics@spotify.com
          </a>
          .
        </p>
        <Button
          to={createOrgPath(organization, '/manage/podcasts/verify')}
          color='inverse'>
          Enter a Different URL
        </Button>
      </Card>
    );
  }

  return (
    <ClaimPodcastImpl
      organization={organization}
      publisher={data.claim.publisher}
    />
  );
};

export default ClaimPodcast;
