import {Controller} from 'react-hook-form';
import {Label} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {FieldProps} from '../../helpers';
import {SortInput} from './SortInput';

export function SortField({control, watch}: FieldProps) {
  const {typeId, fields, orderBy} = watch();
  return (
    <Stack direction='column'>
      <Label htmlFor='orderBy'>Sort By</Label>
      <Controller
        name='orderBy'
        control={control}
        render={({field}) => (
          <SortInput
            key={`${typeId}-${String(fields)}`}
            columns={fields}
            defaultValue={orderBy}
            onChange={field.onChange}
          />
        )}
      />
    </Stack>
  );
}
