import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {ChartTimeTooltip} from '@core/components/ChartTimeTooltip';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {BLUE, GREEN, PINK} from '@core/style';
import {t} from '@core/texts/';
import {Card, CardHeader, Grid} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import {CardStat} from '@core/ui/Stat';
import CampaignLinkTargetEpisodesTable from '@analytics/components/campaigns/CampaignLinkTargetEpisodesTable';
import LinkBuilder from '@analytics/components/links/LinkBuilder';

const getChartData = ({linkHourly, dateRange}) => {
  // Get array of events by hour
  const hourData = Object.values(
    linkHourly.reduce((r, a) => {
      const dateStr = new Date(a.hour).getTime();
      const action = a.action === 'init' ? 'view' : a.action;
      const update = {[action]: a.total};

      if (r[dateStr]) {
        Object.assign(r[dateStr], update);
      } else {
        r[dateStr] = {
          x: dateStr,
          view: 0,
          click: 0,
          ...update,
        };
      }

      return r;
    }, {})
  );

  if (dateRange) {
    let currentDate = dateRange[0].startOf('day');
    const lastDate = dateRange[1].startOf('day');

    while (currentDate.diff(lastDate) < 0) {
      const dateStr = currentDate.toDate().getTime();
      const match = hourData.find(({x}) => x === dateStr);
      if (!match) {
        hourData.push({
          x: dateStr,
          view: 0,
          click: 0,
        });
      }
      currentDate = currentDate.add(1, 'days');
    }
  }

  return hourData.sort((a, b) => a.x - b.x);
};

const CampaignLink = ({organization, campaign, campaignLink}) => {
  const {
    target: {feed},
    createdAt,
    attribution: {
      marketing: {linkHourly},
    },
  } = campaignLink;

  const data = getChartData({
    linkHourly,
    dateRange: [dayjs(createdAt), dayjs()],
  });

  const columns = [
    {
      title: 'Types',
      accessor: 'type',
      type: 'string',
    },
    {
      title: 'Category',
      accessor: 'category',
      type: 'string',
    },
    {
      title: 'Total',
      accessor: 'total',
      type: 'number',
    },
  ];

  const clicks = campaignLink.attribution.marketing.linkClicks
    .slice(0)
    .sort((a, b) => b.total - a.total);

  const linkTargetEpisodes =
    campaignLink.attribution.marketing.linkAttributionTargetEpisodes.nodes;

  return (
    <div>
      <Card rules={({theme}) => ({background: theme.bgTertiary, border: 0})}>
        <LinkBuilder
          organization={organization}
          campaign={campaign}
          campaignLink={campaignLink}
          feed={feed}
        />
      </Card>

      {data.length ? (
        <>
          <Grid
            cols={4}
            rules={() => ({
              gridGap: '1.5rem',
              margin: '2.375rem 1.5rem 0',
            })}>
            <CardStat
              value={comma(campaignLink.viewTotal)}
              {...t('stats.linkViewTotal')}
            />

            <CardStat
              value={comma(campaignLink.clickTotal)}
              {...t('stats.linkClickTotal')}
            />

            <CardStat
              value={comma(campaignLink.targetDownloadTotal)}
              {...t('stats.targetDownload')}
            />
            <CardStat
              value={comma(campaignLink.targetReachTotal)}
              {...t('stats.targetReach')}
            />
          </Grid>

          <CardHeader>Events</CardHeader>
          <Card rules={() => ({fontSize: '0.8125rem'})}>
            <ResponsiveContainer width='100%' height={300}>
              <LineChart data={data}>
                <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
                <XAxis
                  dataKey='x'
                  domain={['auto', 'auto']}
                  type='number'
                  scale='time'
                  tickFormatter={(timeStr) =>
                    dayjs(timeStr).utc().format('MMM DD')
                  }
                />
                <YAxis tickFormatter={(v) => comma(v)} />
                <Tooltip content={ChartTimeTooltip} />
                <Line
                  type='linear'
                  strokeWidth={2}
                  dataKey='view'
                  stroke={BLUE}
                  fill={BLUE}
                  dot={false}
                />

                <Line
                  type='linear'
                  strokeWidth={2}
                  dataKey='click'
                  stroke={GREEN}
                  fill={GREEN}
                  dot={false}
                />

                <Line
                  type='linear'
                  strokeWidth={2}
                  dataKey='redirect'
                  stroke={PINK}
                  fill={PINK}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </Card>
          <CardHeader>Click Actions</CardHeader>
          <Card>
            <DataTable
              columns={columns}
              data={clicks}
              orderBy='-createdAt'
              searchKeys={['url']}
              emptyMessageText='No Links Found.'
            />
          </Card>
          <CardHeader>Attributed Downloads</CardHeader>
          <Card>
            <CampaignLinkTargetEpisodesTable
              campaign={campaign}
              linkTargetEpisodes={linkTargetEpisodes}
            />
          </Card>
        </>
      ) : (
        <>
          <CardHeader>Start Sharing</CardHeader>
          <Card
            rules={() => ({
              textAlign: 'center',
            })}>
            <p>
              Once you start sharing the above link Ad Analytics will start
              displaying link actions.
            </p>
          </Card>
        </>
      )}
    </div>
  );
};

export default CampaignLink;
