import {gql} from '@apollo/client';

export default gql`
  query getReviewer($organizationId: ID!, $reviewerId: ID) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          reviewer(id: $reviewerId) {
            id
            firstName
            lastName
            email
            type
          }
        }
      }
    }
  }
`;
