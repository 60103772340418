import {isNumber} from 'lodash';
import {toFixed, toPercent} from '@core/lib/filters';
import {
  CampaignDynamicSource,
  CampaignState,
  CampaignStreamingSource,
  FetchedCampaign,
} from '@analytics/graphql-api';
import useFeatureFlags, {
  FEATURE_FLAGS,
} from '@analytics/lib/hooks/useFeatureFlags';
import {DEFAULT_METRIC, TOP_AUDIENCE_CONFIG} from './constants';
import {
  AudienceGroup,
  AudienceInsights,
  AudienceOrder,
  AudiencesByGroup,
  InsightsMetric,
  SAIData,
  SAIInsight,
} from './types';

export const dynamicItemIsNotGAM = (item: {source: CampaignDynamicSource}) =>
  item.source !== CampaignDynamicSource.Gam;

export const streamingItemIsGAM = (item: {source: CampaignStreamingSource}) =>
  item.source === CampaignStreamingSource.Gam;

type CanViewProps = {
  campaign: FetchedCampaign;
};

export const canViewDAIAudienceInsights = ({campaign}: CanViewProps) => {
  const hasDAIfeatureFlag = useFeatureFlags(FEATURE_FLAGS.DAI_INSIGHTS);
  return (
    hasDAIfeatureFlag &&
    campaign.state !== CampaignState.Draft &&
    campaign.state !== CampaignState.Ready &&
    ((campaign.campaignDynamics ?? []).filter(dynamicItemIsNotGAM).length > 0 ||
      (campaign.campaignPodcasts ?? []).length > 0)
  );
};

export const canViewSAIAudienceInsights = ({campaign}: CanViewProps) => {
  return (
    campaign.state !== CampaignState.Draft &&
    campaign.state !== CampaignState.Ready &&
    (campaign.campaignStreamings ?? []).filter(streamingItemIsGAM).length > 0
  );
};

export const canViewAudienceInsights = ({campaign}: CanViewProps) =>
  canViewSAIAudienceInsights({campaign}) ||
  canViewDAIAudienceInsights({campaign});

export const parseTopAudienceData = (
  audiences: AudiencesByGroup,
  metric: InsightsMetric,
  audienceOrder?: AudienceOrder
) => {
  return (audienceOrder ?? Object.values(AudienceGroup)).map((group) => {
    const topValue = cleanAudiences(audiences[group], metric).reduce(
      (top, audience) => {
        if (isNumber(audience[metric])) {
          return (top?.[metric] ?? 0) > (audience[metric] ?? 0)
            ? top
            : audience;
        }
      },
      undefined
    );

    return {
      ...TOP_AUDIENCE_CONFIG[group],
      group,
      value: topValue?.segment ?? '-',
    };
  });
};

export const parseInsightsCategoryData = (
  audiences: AudienceInsights,
  metric: InsightsMetric = DEFAULT_METRIC
) => {
  const _audiences = cleanAudiences(audiences, metric);
  const impressionsTotal = sumImpressions(_audiences);
  return [..._audiences]
    .map((a) => ({
      ...a,
      crIndex: crIndexToPercent(a.crIndex),
      impressions: toPercent((a.impressions ?? 0) / impressionsTotal, 1),
    }))
    .sort((a, b) => a.segment.localeCompare(b.segment));
};

export const parseInsightsBarChartData = (
  audiences: AudienceInsights,
  metric: InsightsMetric
) =>
  cleanAudiences(audiences, metric).sort(
    (a, b) => (b[metric] ?? 0) - (a[metric] ?? 0)
  );

export const crIndexToPercent = (crIndex: number | null) =>
  `${toFixed(crIndex ?? 0, 2)}%`;

// Temporary until SAI data moved into new query
export const formatSAIData = (
  data?: SAIData
): Record<AudienceGroup, AudienceInsights> | undefined => {
  if (!data) {
    return undefined;
  }

  const {
    spotAudAge,
    spotAudDevice,
    spotAudFormat,
    spotAudGender,
    spotAudGenre,
    spotAudPlaylist,
    spotAudSegment,
  } = data;

  return {
    [AudienceGroup.AGE]: formatSAIInsight(spotAudAge),
    [AudienceGroup.PODCAST]: [], // Don't have for SAI
    [AudienceGroup.DEVICE]: formatSAIInsight(spotAudDevice),
    [AudienceGroup.FORMAT]: formatSAIInsight(spotAudFormat),
    [AudienceGroup.GENDER]: formatSAIInsight(spotAudGender),
    [AudienceGroup.PLAYLIST]: formatSAIInsight(spotAudPlaylist),
    [AudienceGroup.GENRE]: formatSAIInsight(spotAudGenre),
    [AudienceGroup.SEGMENT]: formatSAIInsight(spotAudSegment),
  };
};

const cleanAudiences = (
  audiences: AudienceInsights = [],
  metric: InsightsMetric
) =>
  audiences.filter(
    (a) =>
      a.segment.toLowerCase() !== 'other' &&
      a.segment.toLowerCase() !== 'unknown' &&
      a.segment.toLowerCase() !== 'not_available' &&
      a.segment.toLowerCase() !== 'null' &&
      a[metric] !== null
  );

const sumImpressions = (data: AudienceInsights) =>
  data.reduce((acc, curr) => (acc += curr.impressions ?? 0), 0);

const formatSAIInsight = (data: SAIInsight) => {
  return data.map(({group, impressions}) => ({
    __typename: 'AudienceInsightsMetricObject',
    segment: group,
    impressions,
    cr: null,
    crIndex: null,
  })) satisfies AudienceInsights;
};
