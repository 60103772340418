import {gql} from '@apollo/client';

export default gql`
  query getBrandOrganizationImpressionsPlayersQuery(
    $id: ID!
    $after: Date
    $before: Date
    $campaignIds: [ID]
  ) {
    me {
      id
      organization(id: $id) {
        id
        overview {
          impressions(
            campaignIds: $campaignIds
            after: $after
            before: $before
          ) {
            players {
              player
              total
              reach
              frequency
              impressionsLessThan5
              percentageImpressionsLessThan5
              reachLessThan5
              percentageReachLessThan5
              impressionsBetween5And15
              percentageImpressionsBetween5And15
              reachBetween5And15
              percentageReachBetween5And15
              impressionsGreaterThan15
              percentageImpressionsGreaterThan15
              reachGreaterThan15
              percentageReachGreaterThan15
            }
          }
        }
      }
    }
  }
`;
