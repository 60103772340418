import {title} from 'process';
import {gtag} from '@core/lib/gtag';
import {t} from '@core/texts';
import {Switch} from '@core/ui/Switch';
import useSettings from './useSettings';

const themes = [
  {
    title: 'Light',
    Icon: () => (
      <svg
        viewBox='0 0 220 120'
        width='100%'
        height='112'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path fill='#fff' d='M0 0h220v120H0z' />
        <rect x='10' y='10' width='28' height='6' rx='3' fill='#D1D5DA' />
        <rect x='48' y='10' width='56' height='6' rx='3' fill='#D1D5DA' />
        <rect x='10' y='21' width='28' height='6' rx='3' fill='#D1D5DA' />
        <rect x='48' y='26' width='98' height='74' rx='5' fill='#F6F8FA' />
        <rect x='156' y='26' width='54' height='38' rx='5' fill='#F6F8FA' />
        <rect x='10' y='32' width='28' height='6' rx='3' fill='#D1D5DA' />
        <path
          d='M61.923 93.215c-1.754 0-2.923-1.169-2.923-2.923v-14.03c0-1.754 1.17-2.924 2.923-2.924 1.754 0 2.923 1.17 2.923 2.924v14.03c0 1.754-1.754 2.923-2.923 2.923zM75.954 93.215c-1.754 0-2.923-1.169-2.923-2.923V55.8c0-1.754 1.169-2.923 2.923-2.923s2.923 1.17 2.923 2.923v34.492c0 1.754-1.17 2.923-2.923 2.923zM89.985 93.215c-1.754 0-2.923-1.169-2.923-2.923V66.323c0-1.754 1.169-2.923 2.923-2.923s2.923 1.17 2.923 2.923v23.97c0 1.753-1.17 2.922-2.923 2.922zM104.015 93.215c-1.753 0-2.923-1.169-2.923-2.923v-44.43c0-1.754 1.17-2.924 2.923-2.924 1.754 0 2.923 1.17 2.923 2.923v44.431c0 1.754-1.169 2.923-2.923 2.923zM118.046 93.215c-1.754 0-2.923-1.169-2.923-2.923V55.8c0-1.754 1.169-2.923 2.923-2.923s2.923 1.17 2.923 2.923v34.492c0 1.754-1.169 2.923-2.923 2.923zM132.077 93.215c-1.754 0-2.923-1.169-2.923-2.923V35.923c0-1.754 1.169-2.923 2.923-2.923S135 34.17 135 35.923v54.954c0 1.17-1.169 2.338-2.923 2.338z'
          fill='#00D192'
        />
      </svg>
    ),
    value: 'light',
  },
  {
    title: 'Dark',
    Icon: () => (
      <svg
        viewBox='0 0 220 120'
        width='100%'
        height='112'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path fill='#21262D' d='M0 0h220v120H0z' />
        <rect x='10' y='10' width='28' height='6' rx='3' fill='#6E7681' />
        <rect x='48' y='10' width='56' height='6' rx='3' fill='#6E7681' />
        <rect x='10' y='21' width='28' height='6' rx='3' fill='#6E7681' />
        <rect x='48' y='26' width='98' height='74' rx='5' fill='#30363D' />
        <rect x='156' y='26' width='54' height='38' rx='5' fill='#30363D' />
        <rect x='10' y='32' width='28' height='6' rx='3' fill='#6E7681' />
        <path
          d='M61.923 93.215c-1.754 0-2.923-1.169-2.923-2.923v-14.03c0-1.754 1.17-2.924 2.923-2.924 1.754 0 2.923 1.17 2.923 2.924v14.03c0 1.754-1.754 2.923-2.923 2.923zM75.954 93.215c-1.754 0-2.923-1.169-2.923-2.923V55.8c0-1.754 1.169-2.923 2.923-2.923s2.923 1.17 2.923 2.923v34.492c0 1.754-1.17 2.923-2.923 2.923zM89.985 93.215c-1.754 0-2.923-1.169-2.923-2.923V66.323c0-1.754 1.169-2.923 2.923-2.923s2.923 1.17 2.923 2.923v23.97c0 1.753-1.17 2.922-2.923 2.922zM104.015 93.215c-1.753 0-2.923-1.169-2.923-2.923v-44.43c0-1.754 1.17-2.924 2.923-2.924 1.754 0 2.923 1.17 2.923 2.923v44.431c0 1.754-1.169 2.923-2.923 2.923zM118.046 93.215c-1.754 0-2.923-1.169-2.923-2.923V55.8c0-1.754 1.169-2.923 2.923-2.923s2.923 1.17 2.923 2.923v34.492c0 1.754-1.169 2.923-2.923 2.923zM132.077 93.215c-1.754 0-2.923-1.169-2.923-2.923V35.923c0-1.754 1.169-2.923 2.923-2.923S135 34.17 135 35.923v54.954c0 1.17-1.169 2.338-2.923 2.338z'
          fill='#00D192'
        />
      </svg>
    ),
    value: 'dark',
  },
  {
    title: 'System',
    Icon: () => (
      <svg
        width='100%'
        height='112'
        viewBox='0 0 220 120'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path fill='#21262D' d='M0 0h220v120H0z' />
        <path fill='#fff' d='M0 0h110v120H0z' />
        <rect x='10' y='10' width='28' height='6' rx='3' fill='#D1D5DA' />
        <rect x='48' y='10' width='56' height='6' rx='3' fill='#D1D5DA' />
        <rect x='10' y='21' width='28' height='6' rx='3' fill='#D1D5DA' />
        <rect x='48' y='26' width='98' height='74' rx='5' fill='#30363D' />
        <path d='M48 31a5 5 0 015-5h57v74H53a5 5 0 01-5-5V31z' fill='#F6F8FA' />
        <rect x='156' y='26' width='54' height='38' rx='5' fill='#30363D' />
        <rect x='10' y='32' width='28' height='6' rx='3' fill='#D1D5DA' />
        <path
          d='M61.923 93.215c-1.754 0-2.923-1.169-2.923-2.923v-14.03c0-1.754 1.17-2.924 2.923-2.924 1.754 0 2.923 1.17 2.923 2.924v14.03c0 1.754-1.754 2.923-2.923 2.923zM75.954 93.215c-1.754 0-2.923-1.169-2.923-2.923V55.8c0-1.754 1.169-2.923 2.923-2.923s2.923 1.17 2.923 2.923v34.492c0 1.754-1.17 2.923-2.923 2.923zM89.985 93.215c-1.754 0-2.924-1.169-2.924-2.923V66.323c0-1.754 1.17-2.923 2.924-2.923 1.753 0 2.923 1.17 2.923 2.923v23.97c0 1.753-1.17 2.922-2.923 2.922zM104.015 93.215c-1.753 0-2.923-1.169-2.923-2.923v-44.43c0-1.754 1.17-2.924 2.923-2.924 1.754 0 2.923 1.17 2.923 2.923v44.431c0 1.754-1.169 2.923-2.923 2.923zM118.046 93.215c-1.754 0-2.923-1.169-2.923-2.923V55.8c0-1.754 1.169-2.923 2.923-2.923s2.923 1.17 2.923 2.923v34.492c0 1.754-1.169 2.923-2.923 2.923zM132.077 93.215c-1.754 0-2.923-1.169-2.923-2.923V35.923c0-1.754 1.169-2.923 2.923-2.923S135 34.17 135 35.923v54.954c0 1.17-1.169 2.338-2.923 2.338z'
          fill='#00D192'
        />
      </svg>
    ),
    value: 'system',
  },
] as const;

const SettingsAppearance = ({user}) => {
  const [settings, dispatch] = useSettings();

  return (
    <>
      <div css={{margin: '0 0 1rem', fontWeight: 500}}>
        {t('settings.appearance.title')}
      </div>
      <p>{t('settings.appearance.longDescription')}</p>
      <div
        css={{
          display: 'grid',
          gridGap: '1rem',
          gridTemplateColumns: 'repeat(3, 1fr)',
          justifyContent: 'space-between',
        }}>
        {themes.map(({title, value, Icon}) => {
          const active = settings.theme === value;
          return (
            <button
              aria-label={`Update system theme to ${title}`}
              type='button'
              key={value}
              onClick={() =>
                dispatch({
                  type: 'UPDATE_SETTINGS',
                  settings: {theme: value},
                })
              }
              css={{
                alignItems: 'center',
                background: active ? 'var(--blue)' : 'var(--bg-muted)',
                border: `2px solid ${
                  active ? 'var(--blue)' : 'var(--border-default)'
                }`,
                borderRadius: '0.5rem',
                color: active ? '#fff' : 'inherit',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                transition: 'all 0.1s linear',
                ':hover': {
                  borderColor: 'var(--blue)',
                  transform: 'scale(1.025)',
                },
              }}>
              <Icon />
              <div css={{padding: '0.25rem 0', fontSize: '0.875rem'}}>
                {title}
              </div>
            </button>
          );
        })}
      </div>
    </>
  );
};

export default SettingsAppearance;
