import React from 'react';
import {TRules} from '@core/style';
import {Element} from '@core/style';
import {BLUE, GREEN, PINK, darken, lighten} from '@core/style';

interface ICapabilityProps {
  active: boolean;
  capability: {
    id: string;
    title: string;
    category: string;
  };
  onClick: (id: string) => void;
  rules?: TRules;
}

const COLORS = {
  ads: BLUE,
  placements: GREEN,
  hosting: PINK,
};

const Capability = ({
  active = false,
  capability: {id, title, category},
  onClick,
  rules,
}: ICapabilityProps) => {
  return (
    <Element
      onClick={() => onClick(id)}
      rules={[
        ({theme}) => ({
          border: `0.125rem solid ${
            active ? darken(COLORS[category], 0.1) : 'transparent'
          }`,
          background: active ? COLORS[category] : theme.bgSurface,
          ':hover': {
            background: active
              ? darken(COLORS[category], 0.1)
              : theme.name === 'light'
              ? theme.bgTertiary
              : theme.gray5,
          },
          color: active ? theme.white : theme.textTertiary,
          cursor: 'pointer',
          display: 'inline-flex',
          fontSize: '1rem',
          padding: '0.5rem .625rem',
          borderRadius: '.5rem',
          margin: '0 0.375rem 0.375rem 0',
          transition: 'all .15s ease-in-out',
        }),
        rules,
      ]}>
      {title}
    </Element>
  );
};

export default Capability;
