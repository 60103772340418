import {comma} from '@core/lib/filters';
import ProgressBar from '@core/ui/ProgressBar';
import {Tooltip} from '@core/ui/Tooltip';

interface IImpressionsProgressBarProp {
  goal: number;
  downloadTotal: number;
}

const ImpressionsProgressBar = ({
  goal,
  downloadTotal,
}: IImpressionsProgressBarProp) => {
  if (typeof goal !== 'number') {
    return <>{comma(downloadTotal)}</>;
  }
  const pct = Math.floor((downloadTotal * 100) / goal);

  return (
    <Tooltip description={`${pct}% of ${comma(goal)} goal`}>
      <div css={{marginBottom: '0.125rem'}}>{comma(downloadTotal)}</div>
      <ProgressBar
        value={downloadTotal}
        max={goal}
        css={`
          background: var(--border-subtle);
        `}
      />
    </Tooltip>
  );
};

export default ImpressionsProgressBar;
