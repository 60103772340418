import {comma, financial} from '@core/lib/filters';
import {DataTableColumn} from '@core/ui/DataTable';
import {CampaignDraftProps} from '..';
import {
  AdType,
  CampaignDynamic,
  CampaignPodcast,
  DynamicTableData,
  PodcastTableData,
  StreamingTableData,
} from './_types';

type DraftCampaign = Pick<CampaignDraftProps, 'campaign'>;

export function parseCampaignAds({
  campaign,
  type,
}: DraftCampaign & {
  type: AdType;
}) {
  switch (type) {
    case 'dynamic':
      return parseDynamicAds({campaign});
    case 'embedded':
      return parseEmbeddedAds({campaign});
    case 'streaming':
      return parseStreamingAds({campaign});
    default:
      return {
        data: [],
        columns: [],
      } as never;
  }
}

/* --------------------------------------------------------------------------- */

const rollPlacements = [
  {title: 'Pre', value: 'isPreRoll'},
  {title: 'Mid', value: 'isMidRoll'},
  {title: 'Post', value: 'isPostRoll'},
] as const;

function parseRollPlacement(item: CampaignDynamic | CampaignPodcast) {
  return rollPlacements
    .filter(({value}) => !!item?.[value])
    .map(({title}) => title)
    .join(', ');
}

const commonAdColumns = [
  {
    accessor: 'downloadTotal',
    title: 'Impressions',
    type: 'number',
    fmt: comma,
  },
  {accessor: 'goal', title: 'Goal', type: 'number', fmt: comma},
  {accessor: 'cost', title: 'Cost', type: 'number', fmt: financial},
  {accessor: 'duration', title: 'Duration', type: 'number', fmt: comma},
  {accessor: 'rollPlacement', title: 'Roll placement', type: 'string'},
  {accessor: 'discountCode', title: 'Discount Code', type: 'string'},
  {accessor: 'effectiveUrl', title: 'Effective URL', type: 'string'},
] satisfies DataTableColumn<
  DynamicTableData | PodcastTableData | StreamingTableData
>[];

function parseDynamicAds({campaign}: DraftCampaign) {
  return {
    data: (campaign.campaignDynamics ?? []).map((item) => ({
      ...item,
      rollPlacement: parseRollPlacement(item),
    })) as DynamicTableData,
    columns: [
      {
        accessor: 'name',
        title: 'Name',
        type: 'string',
        rule: () => ({minWidth: '8rem'}),
      },
      ...commonAdColumns,
    ] as DataTableColumn<DynamicTableData>[],
  };
}

function parseEmbeddedAds({campaign}: DraftCampaign) {
  return {
    data: (campaign.campaignPodcasts ?? []).map((item) => ({
      ...item,
      title: item?.feed?.title ?? '',
      rollPlacement: parseRollPlacement(item),
    })) as PodcastTableData,
    columns: [
      {
        accessor: 'title',
        title: 'Podcast',
        type: 'string',
        rule: () => ({minWidth: '8rem'}),
      },
      ...commonAdColumns,
    ] as DataTableColumn<PodcastTableData>[],
  };
}

function parseStreamingAds({campaign}: DraftCampaign) {
  return {
    data: (campaign.campaignStreamings ?? []).filter(
      ({isActive}) => isActive
    ) as StreamingTableData,
    columns: [
      {
        accessor: 'name',
        title: 'Name',
        type: 'string',
        rule: () => ({minWidth: '8rem'}),
      },
      ...commonAdColumns.filter(({accessor}) => accessor !== 'rollPlacement'),
    ] as DataTableColumn<StreamingTableData>[],
  };
}
