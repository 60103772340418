import DataTable from '@core/ui/DataTable';
import {CampaignDraftProps} from '..';
import {parseCampaignAds} from './_helpers';
import {AdType} from './_types';

export function CampaignDraftAdsTable({
  campaign,
  type,
}: Pick<CampaignDraftProps, 'campaign'> & {
  type: AdType;
}) {
  const {data, columns} = parseCampaignAds({campaign, type});
  return (
    <DataTable
      data={data}
      columns={columns}
      paginationRowsPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 50, 100]}
      emptyMessageText={`No ${type} ads found`}
      rules={() => ({
        borderRadius: 'var(--radius-2)',
        border: '1px solid var(--bg-muted)',
      })}
    />
  );
}
