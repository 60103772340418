import {Switch} from 'react-router-dom';
import {isOrgBrand, isOrgPodcast} from '@core/lib/organizations';
import {ManageRouteProps, useManageRoutes} from './_routes';
import {ManageAdInsertionProviderIndex} from './adInsertionProvider';
import {ManageAffinityIndex} from './affinity';
import {ManageAgenciesIndex} from './agencies';
import {ManageApiKeysIndex} from './api-keys';
import {ManageArt19Index} from './art19';
import {ManageConfigIndex} from './config';
import {ManageInviteIndex} from './invite';
import {ManageMembersIndex} from './members';
import {ManagePixelsIndex} from './pixels';
import {ManagePodcastsIndex} from './podcasts';
import {ManagePodlinkIndex} from './podlink';
import {ManageUsageIndex} from './usage';

export function ManageIndex({user, organization}: ManageRouteProps) {
  const {renderRoutes} = useManageRoutes({user, organization});
  return (
    <Switch>
      {renderRoutes({
        components: {
          'ad-providers': ManageAdInsertionProviderIndex,
          affinity: ManageAffinityIndex,
          agencies: ManageAgenciesIndex,
          api: ManageApiKeysIndex,
          art19: ManageArt19Index,
          config: ManageConfigIndex,
          invite: ManageInviteIndex,
          members: ManageMembersIndex,
          pixels: ManagePixelsIndex,
          podcasts: ManagePodcastsIndex,
          podlink: ManagePodlinkIndex,
          usage: ManageUsageIndex,
        },
        extraProps: {
          user,
          organization,
        },
        redirects: [
          {from: '*', to: ''},
          {
            from: '',
            to: isOrgPodcast(organization)
              ? 'members'
              : isOrgBrand(organization)
              ? 'pixels'
              : 'members',
          },
        ],
      })}
    </Switch>
  );
}
