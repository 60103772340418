import React from 'react';
import {Element} from '@core/style';

/**
 * @deprecated
 */
interface IInputPrefixContainerProps {
  children: React.ReactNode;
  prefix: string;
}

/**
 * @deprecated
 */
const InputPrefixContainer = ({
  children,
  prefix,
}: IInputPrefixContainerProps) => (
  <Element
    rules={() => ({
      alignItems: 'center',
      display: 'flex',
      fontSize: '0.875rem',
      position: 'relative',
    })}>
    <Element
      rules={() => ({
        color: 'var(--text-muted)',
        flexShrink: 0,
        left: '.5rem',
        pointerEvents: 'none',
        position: 'absolute',
        top: '0.375rem',
      })}>
      {prefix}
    </Element>
    {children}
  </Element>
);

export default InputPrefixContainer;
