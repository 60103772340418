import {Type} from '@spotify-internal/encore-web';
import {BrandLiftBarChart} from '../BrandLiftBarChart';
import {BrandLiftPieChart} from '../BrandLiftPieChart';
import {Grid} from '../shared';
import {LiftMessageCategory, SpotifyBrandLiftMessageResult} from '../types';
import {generateSBLChartData} from './helpers';

export function LiftMessageGroup({
  category,
  message,
}: {
  category: LiftMessageCategory;
  message: SpotifyBrandLiftMessageResult;
}) {
  const {age, control, exposed, gender} = generateSBLChartData({
    message,
  });

  switch (category) {
    case LiftMessageCategory.age:
      return <BrandLiftBarChart data={age} />;
    case LiftMessageCategory.gender:
      return <BrandLiftBarChart data={gender} />;
    case LiftMessageCategory.breakdown:
      return (
        <Grid columns='1fr 1fr'>
          <Grid columns='max-content 2fr'>
            <Type css={{justifySelf: 'end'}} as='h4' variant='celloCanon'>
              Control
            </Type>
            <BrandLiftPieChart css={{justifySelf: 'center'}} data={control} />
          </Grid>
          <Grid columns='max-content 2fr'>
            <Type css={{justifySelf: 'end'}} as='h4' variant='celloCanon'>
              Exposed
            </Type>
            <BrandLiftPieChart css={{justifySelf: 'center'}} data={exposed} />
          </Grid>
        </Grid>
      );
    default:
      return null as never;
  }
}
