import {FetchedUser} from '@core/graphql-api';
import {
  Significance,
  SpotifyBrandLiftMessageMessageType,
} from '@analytics/graphql-api';
import {SpotifyBrandLiftMessageContent} from './types';

export const DEFAULT_CHART_HEIGHT = 350;
export const DEFAULT_CHART_WIDTH = '100%';
export const DEFAULT_LABEL_SIZE = 'var(--font-size-body-3)';

export const CONTROL = '#D8A3FF';
export const EXPOSED = '#8649A3';

export const SIGNIFICANCE_MAP = {
  [Significance.Confidence_80]: 'var(--green-40)',
  [Significance.Confidence_90]: 'var(--green)',
  [Significance.NotDetected]: 'var(--icon-muted)',
};

export const fmtPercentDiff = (num: number) =>
  `${num < 0 ? '-' : '+'}${Math.round(Math.abs(num))}`;

export const toString = (v: string | number | undefined | null) => {
  if (typeof v === 'number') return v.toString();
  return v ?? '';
};

export const toNumString = (v: string) => v.match(/\d+/gi)?.[0] ?? '0';

export const toNum = (v: string | number | undefined | null) => {
  if (typeof v === 'string') return parseInt(v);
  return v ?? 0;
};

export const shouldHideBreakdown = ({
  messageContents,
  messageType,
}: {
  messageContents: Readonly<SpotifyBrandLiftMessageContent[]>;
  messageType: SpotifyBrandLiftMessageMessageType;
}) =>
  !Object.keys(messageContents[0]).filter(
    (key) => key.includes('answer') && !!messageContents[0][key]
  ).length || hideBreakdownFromMessageType(messageType);

export const getSignificanceDisplay = (significance?: Significance) => {
  switch (significance) {
    case Significance.Confidence_90:
      return {
        label: '90%c.I',
        description: '90% Confidence Interval',
      };
    case Significance.Confidence_80:
      return {
        label: '80%c.I',
        description: '80% Confidence Interval',
      };
    case Significance.NotDetected:
      return {
        label: 'Statistical significance not detected',
        description: 'Statistical significance not detected',
      };
    default:
      return {
        label: undefined,
        description: undefined,
      } as never;
  }
};

export const canViewPublishSblReport = ({user}: {user: FetchedUser}) =>
  !!(user?.organizations ?? []).find((org) => org?.slug === 'spotify');

export const canPublishSblReport = ({
  user,
  liftReport,
}: {
  user: FetchedUser;
  liftReport: {isPublished: boolean};
}) => canViewPublishSblReport({user}) && !liftReport.isPublished;

/* ----------------------------------------------------------------------- */
const hideBreakdownFromMessageType = (
  messageType: SpotifyBrandLiftMessageMessageType
) =>
  messageType === SpotifyBrandLiftMessageMessageType.AdRecall ||
  messageType === SpotifyBrandLiftMessageMessageType.AttributeRating ||
  messageType === SpotifyBrandLiftMessageMessageType.Favorability ||
  messageType === SpotifyBrandLiftMessageMessageType.PurchaseIntent;
