import {useState} from 'react';
import {ExportTypeColumn} from '@analytics/graphql-api';
import {LeftColumn} from './LeftColumn';
import {RightColumn} from './RightColumn';
import {ColumnsInputContainer} from './shared';

type Props = {
  columns: ExportTypeColumn[];
  defaultValue: ExportTypeColumn[];
  invalid?: boolean;
  onChange: (_: ExportTypeColumn[]) => void;
};

export function ColumnsInput({
  columns,
  defaultValue = [],
  invalid,
  onChange,
}: Props) {
  const [selectedColumns, setSelectedColumns] = useState(defaultValue);

  const onChangeColumns = (columns: ExportTypeColumn[]) => {
    setSelectedColumns(columns);
    onChange?.(columns);
  };

  const onRemoveColumn = (columnId: string) => {
    selectedColumns.splice(
      selectedColumns.indexOf(
        selectedColumns.find(({id}) => id === columnId) ??
          ({} as ExportTypeColumn)
      ),
      1
    );

    onChangeColumns([...selectedColumns]);
  };

  return (
    <ColumnsInputContainer invalid={invalid}>
      <LeftColumn
        columns={columns}
        onChangeColumns={onChangeColumns}
        onRemoveColumn={onRemoveColumn}
        selectedColumns={selectedColumns}
      />
      <RightColumn
        onChangeColumns={onChangeColumns}
        onRemoveColumn={onRemoveColumn}
        selectedColumns={selectedColumns}
      />
    </ColumnsInputContainer>
  );
}
