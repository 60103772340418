import {gql} from '@apollo/client';

export default gql`
  mutation updateAd($input: UpdateAdInput!) {
    updateAd(input: $input) {
      ad {
        id
        createdAt
      }
    }
  }
`;
