import React from 'react';
import {Element} from '@core/style';
import {BLUE, PINK} from '@core/style';
import {Button} from '@core/ui/Button';
import {ModalHeader} from '@core/ui/Content';
import {Icon} from '@core/ui/Icon';
import {Overlay, useOverlay} from '@core/ui/Overlay';

const Unclassified = ({name, format}) => {
  const [opened, toggleOverlay] = useOverlay();

  return (
    <>
      <Element rules={() => ({display: 'flex', alignItems: 'center'})}>
        <Element
          rules={() => ({
            alignItems: 'center',
            background: PINK,
            borderRadius: '.5rem',
            display: 'flex',
            height: '4.5rem',
            justifyContent: 'center',
            marginRight: '0.625rem',
            minWidth: '4.5rem',
            width: '4.5rem',
          })}>
          <Icon
            icon='kazoo'
            rules={() => ({
              color: PINK,
              width: '75%',
            })}
          />
        </Element>
        <div>
          <Element
            rules={({theme}) => ({
              color: theme.textPrimary,
              fontWeight: 500,
            })}>
            {name} Impressions
          </Element>
          <Element
            rules={({theme}) => ({
              color: theme.textTertiary,
              fontSize: '0.8125rem',
              lineHeight: 1.4,
            })}>
            Ad Analytics does not always receive podcast or episode level data
            for {format} line items.{' '}
            <Element
              tag='a'
              rules={() => ({':hover': {color: BLUE}})}
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                toggleOverlay();
              }}>
              Learn More
            </Element>
          </Element>
        </div>
      </Element>
      <Overlay
        opened={opened}
        toggle={toggleOverlay}
        centered
        withBackdrop
        css={`
          padding: 2rem;
          max-width: 60rem;
        `}>
        <ModalHeader toggle={toggleOverlay} withCloseIcon>
          {name} Impressions
        </ModalHeader>
        <p>
          Ad Analytics receives {format} impression data via the hosting
          provider's tracking URL. Often we get podcast and episode information
          for the impression, and in some cases, we do not.{' '}
        </p>
        <p>
          These impressions were served to listeners via the line item; Ad
          Analytics doesn't have the podcast/episode for these impressions.
        </p>
        <p>
          This can be because Ad Analytics doesn't ingest the feed, or the
          publisher is not allowed to show what podcast the ad appeared on.{' '}
        </p>
        <p>Ad Analytics will still perform attribution on these impressions.</p>
        <div
          css={`
            display: flex;
            justify-content: end;
          `}>
          <Button
            color='primary'
            onClick={(evt) => {
              evt.stopPropagation();
              toggleOverlay();
            }}
            css={`
              margin-top: var(--spacing-9);
            `}>
            Close
          </Button>
        </div>
      </Overlay>
    </>
  );
};

export default Unclassified;
