import {MultipleSelect, MultipleSelectProps} from '@core/ui/Select';
import {
  OrganizationObject,
  useAdvisorContentMaturityQuery,
} from '@analytics/graphql-api';

interface Props extends Omit<MultipleSelectProps, 'items' | 'defaultValue'> {
  organization: OrganizationObject;
  onChange: (_values: {id: string; title: string}) => void;
  defaultValues: {id: string; title: string}[];
}

export default function AdvisorContentMaturityFilter({
  defaultValues,
  organization,
  placeholder,
  disabled,
  onChange,
  ...props
}: Props) {
  const {data, loading, error} = useAdvisorContentMaturityQuery({
    variables: {
      organizationId: organization.id,
    },
  });

  return (
    <MultipleSelect
      key={`contentMaturity-${loading}`}
      defaultValue={defaultValues.map(({id}) => id)}
      disabled={disabled || loading || !!error}
      items={
        data?.me?.organization?.advisor?.columnGroups?.contentMaturity
          ?.columns || []
      }
      outline
      onSelect={onChange}
      placeholder={placeholder || 'Select Content Maturity'}
      propertyForName='title'
      propertyForValue='id'
      small
      rules={() => ({width: '100%'})}
      {...props}
    />
  );
}
