import {useReducer, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {createOrgPath} from '@core/lib/organizations';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {FieldGroup, FieldSet, Form, Help, Input, Legend} from '@core/ui/Form';
import {Label, Submit} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import Select from '@core/ui/Select';
import UPDATE_ATTRIBUTION_CONFIG from '@analytics/graphql-api/_old_mutations/UpdateAttributionConfig';
import ATTRIBUTION_CONFIG_QUERY from '@analytics/graphql-api/_old_queries/AttributionConfigQuery';
import {ManageConfigRouteProps} from '.';

const getDisplayValue = (value, interval) => {
  if (!value) {
    return '';
  }
  return interval == 'hours' ? value : Math.ceil(value / 24);
};

const AttributionInterval = ({
  defaultInterval,
  help,
  label,
  loading,
  name,
  onChange,
  value,
}) => {
  // Value will always be in hours, we need to deal with displaying
  // it based on the interval.
  const [interval, _setInterval] = useState(defaultInterval);

  const _onChange = ({target: {value}}) => {
    const v = value.trim();

    if (value == '' || isNaN(parseInt(v))) {
      onChange({
        name,
        value: '',
      });
    } else {
      onChange({
        name,
        value: interval == 'hours' ? v : v * 24,
      });
    }
  };

  return (
    <FieldGroup rules={() => ({marginTop: 0})}>
      <Label>{label}</Label>
      <Element
        rules={() => ({
          display: 'grid',
          gridTemplateColumns: '4fr 1fr',
          gridGap: '.5rem',
        })}>
        <Input
          type='text'
          name={name}
          required
          value={getDisplayValue(value, interval)}
          onChange={_onChange}
          disabled={loading}
        />
        <Select
          items={[
            {
              title: 'Hours',
              value: 'hours',
            },
            {
              title: 'Days',
              value: 'days',
            },
          ]}
          defaultValue={interval}
          onSelect={({value}) => {
            _setInterval(value);
          }}
        />
      </Element>

      <Help>{help}</Help>
    </FieldGroup>
  );
};

const AttributionConfigForm = ({organization, attributionConfig}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [complete, setComplete] = useState(false);

  const [state, setState] = useReducer(
    (state, newState) => {
      return {...state, ...newState};
    },
    {
      errors: [],
      complete: false,
      loading: false,

      householdInterval: attributionConfig?.householdInterval,
      cableInterval: attributionConfig?.cableInterval,
      cellularInterval: attributionConfig?.cellularInterval,
      corporateInterval: attributionConfig?.corporateInterval,
      attributionInterval: attributionConfig?.attributionInterval,
      maxUsersPerHousehold: attributionConfig?.maxUsersPerHousehold,

      changed: null,
    }
  );

  const [updateAttributionConfig] = useMutation(UPDATE_ATTRIBUTION_CONFIG);

  const _onSubmit = async () => {
    setLoading(true);

    const {
      householdInterval,
      cableInterval,
      cellularInterval,
      corporateInterval,
      attributionInterval,
      maxUsersPerHousehold,
    } = state;

    try {
      await updateAttributionConfig({
        variables: {
          input: {
            organizationId: organization.id,
            householdInterval,
            cableInterval,
            cellularInterval,
            corporateInterval,
            attributionInterval,
            maxUsersPerHousehold,
          },
        },
      });
      setComplete(true);
    } catch (err) {
      setErrors([err]);
    }

    setLoading(false);
  };

  const onChange = ({name, value}) => {
    const update = {};
    update[name] = value;
    setState(update);
  };

  // Create the Form
  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader rules={() => ({marginLeft: 0, marginRight: 0})}>
        Update Attribution Windows
      </CardHeader>
      {complete ? (
        <Card>
          <p>
            Ad Analytics has updated your settings. When attribution runs
            tonight, we will use these new values.
          </p>
          <Element rules={() => ({padding: '1rem', textAlign: 'right'})}>
            <Button to={createOrgPath(organization, 'manage')} color='primary'>
              Back to Settings
            </Button>
          </Element>
        </Card>
      ) : (
        <Form
          loading={loading}
          onSubmit={_onSubmit}
          errors={errors}
          onClose={() => {
            setState({errors: []});
          }}>
          <FieldSet>
            <Legend>Download Attribution Windows</Legend>
            <AttributionInterval
              label='Household Interval'
              name='householdInterval'
              value={state.householdInterval}
              onChange={onChange}
              defaultInterval='days'
              loading={loading}
              help='Attribution window for households as defined by the Tapad Graph.'
            />
            <AttributionInterval
              label='Cable Interval'
              name='cableInterval'
              value={state.cableInterval}
              onChange={onChange}
              defaultInterval='days'
              loading={loading}
              help='Attribution window for cable/dsl and dialup IPs as defined by MaxMind.'
            />
            <AttributionInterval
              label='Cellular Interval'
              name='cellularInterval'
              value={state.cellularInterval}
              onChange={onChange}
              defaultInterval='hours'
              loading={loading}
              help='Attribution window for cellular IPs as defined by MaxMind.'
            />
            <AttributionInterval
              label='Corporate Interval'
              name='corporateInterval'
              value={state.corporateInterval}
              onChange={onChange}
              defaultInterval='days'
              loading={loading}
              help='Attribution window for corporate IPs as defined by MaxMind.'
            />
          </FieldSet>

          <FieldSet>
            <Legend>On-Site Attribution Window</Legend>
            <AttributionInterval
              label='On-Site Attribution Interval'
              name='attributionInterval'
              value={state.attributionInterval}
              onChange={onChange}
              defaultInterval='days'
              loading={loading}
              help='After the first site visit, how long should we attribute actions back to the podcast. If a user visits one day and then comes back ten days later to purchase, should that purchase be attributable to the podcast? This value lets you configure this window.'
            />
          </FieldSet>

          <FieldSet>
            <Legend>Noise Settings</Legend>

            <FieldGroup rules={() => ({marginTop: 0})}>
              <Label>Max Users per Household</Label>

              <Input
                type='text'
                name='maxUsersPerHousehold'
                required
                value={state.maxUsersPerHousehold}
                onChange={({target: {value}}) => {
                  onChange({
                    name: 'maxUsersPerHousehold',
                    value:
                      value.trim() && !isNaN(parseInt(value))
                        ? parseInt(value)
                        : value,
                  });
                }}
                disabled={loading}
              />

              <Help>
                Due to the nature of IP classification, sometimes our upstream
                providers will get it wrong. Max Users Per Household reduces
                inflated attribution by capping the number of users that can
                exist in a household. I.e., a corporate IP gets classified as a
                household. Households/Cable/DSL/Dialup IPs over this limit are
                disregarded.
              </Help>
            </FieldGroup>
          </FieldSet>

          <Element rules={() => ({textAlign: 'right', flex: 1})}>
            <Button to={createOrgPath(organization, 'manage')}>Cancel</Button>
            <Submit
              color='error'
              value='Update Attribution Windows'
              disabled={loading}
              css={`
                margin-left: 1rem;
              `}
            />
          </Element>
        </Form>
      )}
    </ResponsiveContent>
  );
};

export function AttributionConfig({organization}: ManageConfigRouteProps) {
  const {loading, error, data} = useQuery(ATTRIBUTION_CONFIG_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      organizationId: organization.id,
    },
  });

  if (loading) {
    return <Loading centered />;
  }

  const attributionConfig = data?.me?.organization?.attributionConfig;

  return (
    <AttributionConfigForm
      organization={organization}
      attributionConfig={attributionConfig}
    />
  );
}
