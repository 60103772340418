import {useState} from 'react';
import {useQuery} from '@apollo/client';
import {t} from '@core/texts';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import ApprovalsRequestForm from '@analytics/components/approvals/ApprovalsRequestForm';
import CAMPAIGN_APPROVAL_CONFIG_QUERY from '@analytics/graphql-api/_old_queries/CampaignApprovalConfigQuery';
import {CampaignRouteProps, toCampaignUrl} from '../_routes';

export function ApprovalsRequest({campaign, organization}: CampaignRouteProps) {
  const [complete, setComplete] = useState(false);
  const {loading, error, data} = useQuery(CAMPAIGN_APPROVAL_CONFIG_QUERY, {
    variables: {
      organizationId: organization.id,
      campaignId: campaign.id,
    },
  });

  if (loading) {
    return <Loading centered />;
  }
  if (error) {
    return (
      <ToastMessage alertType='warning'>{t('errors.network')}</ToastMessage>
    );
  }
  const approvalConfig = data?.me.organization?.campaign?.approvalConfig;

  return (
    <ResponsiveContent containerSize='large'>
      <CardHeader
        backPath={`${toCampaignUrl(organization, campaign)}/approvals`}>
        Request approvals on campaign specifics before launch
      </CardHeader>
      <Card>
        {complete ? (
          <p>Request sent successfully.</p>
        ) : (
          <>
            <div css={{margin: '0 0 2.5rem'}}>
              <p>
                You can request approvals from your internal team, the brand,
                and/or the brand’s agency, if applicable.
              </p>
              <p>
                We highly recommend using this approval process to eliminate any
                surprises later in the flight.
              </p>
            </div>
            <ApprovalsRequestForm
              organization={organization}
              campaign={campaign}
              approvalConfig={approvalConfig}
              onComplete={() => setComplete(true)}
            />
          </>
        )}
      </Card>
    </ResponsiveContent>
  );
}
