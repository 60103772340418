import {gql} from '@apollo/client';

export default gql`
  query getBrandCampaignReferrers(
    $organizationId: ID!
    $id: ID!
    $domain: String
    $offset: Int
    $limit: Int
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $id) {
          id
          attribution {
            users {
              referrers(referrerDomain: $domain) {
                count
                sum
                nodes(offset: $offset, limit: $limit) {
                  referrer
                  count
                }
              }
            }
          }
        }
      }
    }
  }
`;
