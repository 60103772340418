import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {RED_ORANGE} from '@core/style';
import Button from '@core/ui/Button/LegacyButton';
import Checkbox from '@core/ui/Checkbox';
import {Card} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {
  Form,
  FormFooter,
  FormGroup,
  FormHelp,
  Input,
  Label,
  Submit,
} from '@core/ui/FormElements';
import {Dummy, Img} from '@core/ui/Image';
import {FetchedOrganization, FetchedPodcasts} from '@analytics/graphql-api';

const defaultSlug = (organization: FetchedOrganization) =>
  organization.name
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z\-0-9]/g, '');

type TItem = {
  podcastIds: string[];
  slug: string;
};

interface IPodlinkDomainFormProps {
  loading: boolean;
  onCancel: () => void;
  onDelete?: () => void;
  onSubmit: (item: TItem) => void;
  organization: FetchedOrganization;
  podlinkDomain?: TSFixMe;
  podcasts: FetchedPodcasts;
}

const PodlinkDomainForm = ({
  loading,
  onCancel,
  onDelete,
  onSubmit,
  organization,
  podlinkDomain,
  podcasts,
}: IPodlinkDomainFormProps): JSX.Element => {
  const [typeLoading, setTypeLoading] = useState<'save' | 'delete'>('save');
  const [selectAll, setSelectAll] = useState<boolean>(
    () => podlinkDomain?.podcasts.length === podcasts.length
  );
  const {
    control,
    handleSubmit,
    formState: {errors, isDirty},
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      slug: podlinkDomain ? podlinkDomain.slug : defaultSlug(organization),
      podcastIds: podlinkDomain ? podlinkDomain.podcasts.map(({id}) => id) : [],
    },
  });

  const _onSubmit = async (item: TItem) => {
    if (podlinkDomain && !isDirty) {
      onCancel();
    } else {
      onSubmit(item);
    }
  };

  const _onSelectAll = () => {
    if (!selectAll) {
      setValue(
        'podcastIds',
        podcasts.map(({id}) => id)
      );
    } else setValue('podcastIds', []);

    setSelectAll(!selectAll);
  };

  const {podcastIds} = watch();

  return (
    <Form errors={errors} onSubmit={handleSubmit(_onSubmit)}>
      <FormGroup>
        <Label>Subdomain</Label>
        <Controller
          name='slug'
          control={control}
          rules={{required: 'Please provide a subdomain.'}}
          render={({field}) => <Input {...field} />}
        />
        <FormHelp>
          Example: https://{defaultSlug(organization)}.pod.link
        </FormHelp>
      </FormGroup>
      <FormGroup>
        <div css={{display: 'flex', justifyContent: 'space-between'}}>
          <Label>Podcasts to appear on this Subdomain:</Label>
          <Label
            rules={() => ({
              color: !!errors.podcastIds ? RED_ORANGE : 'inherit',
            })}>
            {podcastIds.length} selected
          </Label>
        </div>
        <Controller
          name='podcastIds'
          control={control}
          rules={{
            validate: (value) =>
              !value.length ? 'Please select at least one podcast.' : true,
          }}
          render={({field}) => {
            const columns: DataTableColumn[] = [
              {
                title: (
                  <Checkbox defaultValue={selectAll} onChecked={_onSelectAll} />
                ),
                accessor: 'id',
                noSortable: true,
                Cell: ({d: {id}}) => {
                  const selected = podcastIds.includes(id);
                  return (
                    <Checkbox
                      key={`${selected}-${id}`}
                      defaultValue={selected}
                      onChecked={() => {
                        if (selected) {
                          field.onChange(podcastIds.filter((p) => p !== id));
                        } else {
                          field.onChange([...podcastIds, id]);
                        }
                      }}
                    />
                  );
                },
              },
              {
                title: 'Podcasts',
                accessor: 'title',
                type: 'string',
                getValue: ({d}) => d.title,
                Cell: ({d: {feed}}) => (
                  <div
                    css={{
                      alignItems: 'center',
                      borderRadius: '5px',
                      display: 'flex',
                      margin: '0 0 0.25rem',
                    }}>
                    <div
                      css={{
                        flex: '0 0 2.6875rem',
                        marginRight: '1rem',
                        maxHeight: '2.6875rem',
                      }}>
                      {feed.image ? (
                        <Img image={feed.image} width={50} />
                      ) : (
                        <Dummy />
                      )}
                    </div>
                    <div css={{flex: 1}}>
                      <div css={{fontWeight: 500}}>{feed.title}</div>
                    </div>
                  </div>
                ),
              },
            ];

            return (
              <DataTable
                data={podcasts}
                columns={columns}
                paginationRowsPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 50]}
                searchMinItems={11}
                searchKeys={['title']}
                rules={() => ({padding: '1rem'})}
              />
            );
          }}
        />
      </FormGroup>
      {onDelete && (
        <Card
          rules={() => ({
            alignItems: 'center',
            background: 'var(--bg-muted)',
            border: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            margin: 0,
          })}>
          <div>
            <Label>Delete Subdomain</Label>
            <FormHelp rules={() => ({margin: '0 0 0.75rem'})}>
              Remove this pod.link subdomain
            </FormHelp>
          </div>
          <Submit
            type='button'
            onClick={() => {
              setTypeLoading('delete');
              onDelete();
            }}
            disabled={loading}
            loading={typeLoading === 'delete' && loading}
            color='error'>
            Delete Subdomain
          </Submit>
        </Card>
      )}
      <FormFooter>
        <Button type='button' disabled={loading} onClick={onCancel}>
          Cancel
        </Button>
        <Submit disabled={loading} loading={typeLoading === 'save' && loading}>
          Save Subdomain
        </Submit>
      </FormFooter>
    </Form>
  );
};

export default PodlinkDomainForm;
