import {forwardRef} from 'react';
import dayjs from '@core/lib/dayjs';
import {
  DataTableColumn,
  DateRangeFilter,
  MultipleSelectFilter,
} from '@core/ui/DataTable';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {Tooltip} from '@core/ui/Tooltip';
import {UserAvatar} from '@core/ui/UserAvatar';
import {format} from './templates';
import {CampaignActivityLogEvents} from './types';

/* --------------------------------- Public -------------------------------- */

export const columns = [
  RangeFilterDate(),
  SelectFilterUser(),
  SelectFilterActions(),
  SelectFilterFieldname(),
  DateFromNowHeading(),
] satisfies DataTableColumn<CampaignActivityLogEvents>[];

/* -------------------------------- Private -------------------------------- */

function RangeFilterDate() {
  return {
    title: 'Date Changed',
    accessor: 'dateTimeChanged',
    type: 'date',
    hidden: true,
    fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
    Filter: forwardRef(function DateTimeChangedFilter({...props}, ref) {
      return (
        <DateRangeFilter
          ref={ref}
          startAccessor='dateTimeChanged'
          endAccessor='dateTimeChanged'
          {...props}
        />
      );
    }),
  } satisfies DataTableColumn;
}

function SelectFilterUser() {
  return {
    accessor: 'user',
    type: 'string',
    Cell: ({d}) => (
      <Stack alignItems={'center'} gap={'1rem'}>
        <UserAvatar
          user={{
            firstName: d.userFirstName,
            lastName: d.userLastInitial ?? '',
          }}
        />
        <Text as='span' gutterBottom={false} variant={'body2'} color='subtle'>
          <>{format(d)}</>
        </Text>
      </Stack>
    ),
    Filter: forwardRef(function UsersSelectFilter({...props}, ref) {
      return (
        <MultipleSelectFilter
          ref={ref}
          searchable
          placeholder='Users'
          {...props}
        />
      );
    }),
    rule: () => ({fontWeight: 500}),
  } satisfies DataTableColumn;
}

function SelectFilterFieldname() {
  return {
    title: 'Values Changed',
    accessor: 'fieldName',
    type: 'string',
    hidden: true,
    Filter: forwardRef(function FieldnameFilter({...props}, ref) {
      return (
        <MultipleSelectFilter
          ref={ref}
          searchable
          placeholder='Values Changed'
          {...props}
        />
      );
    }),
    rule: () => ({fontWeight: 500}),
    alignRight: true,
  } satisfies DataTableColumn;
}

function SelectFilterActions() {
  return {
    title: 'Action Taken',
    accessor: 'action',
    type: 'string',
    longTitle: 'Action Taken',
    hidden: true,
    Filter: forwardRef(function ActionsSelectFilter({...props}, ref) {
      return (
        <MultipleSelectFilter
          ref={ref}
          searchable
          placeholder='Action Taken'
          {...props}
        />
      );
    }),
    rule: () => ({fontWeight: 500}),
  } satisfies DataTableColumn;
}

function DateFromNowHeading() {
  return {
    accessor: 'dateTimeChanged',
    type: 'date',
    width: 125,
    Cell: ({d}) => (
      <Tooltip description={dayjs(d.dateTimeChanged).format('MMM DD, YYYY')}>
        <Text gutterBottom={false} variant={'body3'} color='muted'>
          {dayjs(d.dateTimeChanged).fromNow()}
        </Text>
      </Tooltip>
    ),
  } satisfies DataTableColumn;
}
