import React, {useEffect, useImperativeHandle, useState} from 'react';
import {Element, HIGHLIGHTER_40, RED_ORANGE} from '@core/style';
import {DropdownMenu} from '@core/ui/DropdownMenu';
import {Icon} from '@core/ui/Icon';
import {Stack} from '@core/ui/Layout';
import {useOverlay} from '@core/ui/Overlay';
import {InfoTooltip, Tooltip} from '@core/ui/Tooltip';
import BulkDeleteAdOverlay from './BulkDeleteAdOverlay';
import BulkInput from './BulkInput';

const parseInputVal = (value, type) => {
  switch (type) {
    case 'number':
      return parseFloat(value);
    case 'string':
      return value ? value : '';
    default:
      return value;
  }
};

const Row = ({columns, item, children, rules}) => {
  return (
    <Element
      key={item ? item.id : null}
      rules={[
        ({theme}) => ({
          display: 'grid',
          fontSize: '0.875rem',
          gridGap: '0.375rem',
          gridTemplateColumns: `0.125rem  ${columns
            .map(({width}) => {
              return width || '1fr';
            })
            .join(' ')} 2.5rem`,
          ':hover > :last-child': {
            display: 'flex',
          },
          ...(item && item.__changes
            ? {
                background: HIGHLIGHTER_40,
                color: theme.name === 'dark' ? theme.gray7 : 'inherit',
              }
            : null),
          ':hover': {
            outline: `1px solid ${theme.borderPrimary}`,
            boxShadow: theme.shadowMedium,
          },
        }),
        rules,
      ]}>
      {children}
    </Element>
  );
};

const HeaderCell = ({col: {title, longDescription}}) => {
  return (
    <Element
      rules={({theme}) => ({
        alignItems: 'center',
        color: theme.textTertiary,
        display: 'flex',
        fontSize: '.8125rem',
        fontWeight: 500,
        height: '2.5rem',
        lineHeight: '1',
        padding: '0 0.25rem',
        '> div': {
          margin: '0.125rem 0 0 0.25rem',
        },
      })}>
      {title}
      {longDescription ? <InfoTooltip description={longDescription} /> : ''}
    </Element>
  );
};

const getPropertyValue = ({item, accessor, propName}) => {
  const props = Object.assign({}, item, item.__changes);
  return typeof accessor === 'function' ? accessor(props) : props[propName];
};

const Cell = ({
  col: {
    accessor,
    Component,
    multipleLine,
    name,
    onChange,
    placeholder,
    propName,
    readonly,
    selectOnFocus,
    type,
    validate,
    validateErrorText,
  },
  campaign,
  item,
  organization,
  podcasts,
  refs,
  removeEmptyNewItems,
  updateItemProperty,
}) => {
  const _name = propName ? propName : name;
  const ref = React.useRef();
  const defaultValue = getPropertyValue({item, accessor, propName});

  refs[_name] = ref;

  const isValid =
    typeof validate === 'function'
      ? validate({item, value: defaultValue})
      : !(item.__invalid && item.__invalid[propName || name]);

  const maskedColumn = name === 'cpm' && campaign?.isPpa && defaultValue;

  return (
    <Element
      rules={() => ({
        alignItems: 'center',
        display: 'flex',
        lineHeight: 1.4,
        minHeight: '2.5rem',
        minWidth: 0,
      })}>
      <Tooltip
        title={validateErrorText}
        active={!isValid}
        rules={() => ({width: '100%'})}>
        {maskedColumn ? (
          <div>1</div>
        ) : Component ? (
          <Component
            domRef={ref}
            refs={refs}
            item={item}
            campaign={campaign}
            isValid={isValid}
            organization={organization}
            podcasts={podcasts}
            removeEmptyNewItems={removeEmptyNewItems}
            updateItemProperty={updateItemProperty}
          />
        ) : type === 'number' || type === 'string' ? (
          <BulkInput
            domRef={ref}
            type={type}
            name={_name}
            defaultValue={defaultValue}
            readOnly={readonly}
            multipleLine={multipleLine}
            style={{
              borderColor: isValid ? '' : RED_ORANGE,
            }}
            rules={({theme}) => ({
              ...(placeholder && !defaultValue && !item.__changes
                ? {border: `1px solid ${theme.inputBorder}`}
                : {}),
            })}
            onChange={(evt, value) => {
              value = parseInputVal(value, type);
              if (propName) {
                updateItemProperty({item, propName, value});
              }
              if (typeof onChange === 'function') {
                try {
                  onChange({
                    item,
                    value,
                    updateItemProperty,
                    refs,
                  });
                } catch (err) {
                  console.warn(err);
                }
              }
            }}
            {...(placeholder && {placeholder})}
            onBlur={removeEmptyNewItems}
            onFocus={({target}) => {
              removeEmptyNewItems();
              if (selectOnFocus) {
                target.select();
              }
            }}
          />
        ) : (
          <Element
            rules={() => ({
              padding: '0.2rem 0.25rem',
            })}>
            {defaultValue}
          </Element>
        )}
      </Tooltip>
    </Element>
  );
};

const MoreMenuDropdown = ({duplicate, onSubmit}) => {
  const menuItems = [{title: 'Delete...', value: 'delete'}];

  if (duplicate) {
    menuItems.unshift({
      title: 'Duplicate',
      value: 'duplicate-ad',
    });
  }

  return (
    <Stack direction='row' justifyContent='center' alignItems='center'>
      <DropdownMenu
        onSubmit={onSubmit}
        items={menuItems}
        overlayProps={{
          horizontalAlign: 'left',
          noHorizontalOverlap: true,
          verticalAlign: 'middle',
        }}>
        <Icon
          icon='more'
          rules={({theme}) => ({
            transform: 'rotate(90deg)',
            color: theme.textTertiary,
          })}
        />
      </DropdownMenu>
    </Stack>
  );
};

const TableRow = ({
  campaign,
  columns,
  duplicateAd,
  adType,
  item,
  onItemDelete,
  organization,
  podcasts,
  removeEmptyNewItems,
  updateItemProperty,
}) => {
  const [refs] = useState({});
  const [openedDeleteOverlay, toggleDeleteOverlay] = useOverlay();

  const onMoreMenuSubmit = ({value}) => {
    switch (value) {
      case 'duplicate-ad':
        duplicateAd(item);
        break;
      case 'delete':
        toggleDeleteOverlay(true);
        break;
    }
  };

  const duplicate = adType === 'dynamic' || adType === 'streaming';

  return (
    <>
      <Row columns={columns} item={item}>
        <div />
        {columns.map((col) => (
          <Cell
            campaign={campaign}
            col={col}
            item={item}
            organization={organization}
            podcasts={podcasts}
            refs={refs}
            removeEmptyNewItems={removeEmptyNewItems}
            updateItemProperty={updateItemProperty}
          />
        ))}
        {!item.isNew ? (
          <MoreMenuDropdown duplicate={duplicate} onSubmit={onMoreMenuSubmit} />
        ) : (
          <div />
        )}
      </Row>
      {openedDeleteOverlay ? (
        <BulkDeleteAdOverlay
          item={item}
          adType={adType}
          opened={openedDeleteOverlay}
          toggle={toggleDeleteOverlay}
          campaignId={campaign.id}
          onSuccess={onItemDelete}
          organizationId={organization.id}
        />
      ) : null}
    </>
  );
};

const BulkGroup = React.forwardRef(
  (
    {
      addToEdition,
      campaign,
      columns,
      adType,
      onItemDelete,
      items,
      organization,
      podcasts,
      setItems,
      updateItemProperty,
    } = {},
    ref
  ) => {
    const removeEmptyNewItems = () => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].isNew && !items[i].__changes && items[i + 1]) {
          items.splice(i, 1);
          setItems(items.slice(0));
        }
      }
    };

    const addEmptyItems = () => {
      const lastItem = items[items.length - 1];
      if (!lastItem || !lastItem.isNew || lastItem.__changes) {
        items.push({
          isNew: true,
          adType,
          id: Math.random().toString(36).substring(2, 15),
        });
        setItems(items.slice(0));
      }
    };

    useEffect(() => {
      addEmptyItems();
    });

    const duplicateAd = (item) => {
      const newItem = {
        isNew: true,
        adType,
        __changes: Object.assign({}, item),
      };
      items.push(newItem);
      setItems(items.slice(0));
      addToEdition(newItem);
    };

    const validateChanges = () => {
      let valid = true;

      for (const item of items) {
        if (!item.__changes) {
          continue;
        }
        delete item.__invalid;
        for (const col of columns) {
          const value = getPropertyValue({
            item,
            accessor: col.accessor,
            propName: col.propName,
          });
          if (
            (col.required && !value) ||
            (typeof col.validate === 'function' && !col.validate({value, item}))
          ) {
            valid = false;
            item.__invalid = item.__invalid || {};
            item.__invalid[col.propName || col.name] = true;
          }
        }
      }

      if (!valid) {
        setItems(items.slice(0));
      }

      return valid;
    };

    useEffect(() => {
      removeEmptyNewItems();
    });

    useImperativeHandle(ref, () => {
      return {
        validateChanges,
      };
    });

    return (
      <div>
        <Row
          columns={columns}
          rules={({theme}) => ({
            borderRadius: '.5rem',
            background: theme.bgTertiary,
            ':hover': null,
          })}>
          <div />
          {columns.map((col) => (
            <HeaderCell col={col} />
          ))}
          <div />
        </Row>
        <>
          {items.map((item) => {
            return (
              <TableRow
                key={item.id}
                campaign={campaign}
                columns={columns}
                duplicateAd={duplicateAd}
                adType={adType}
                item={item}
                onItemDelete={onItemDelete}
                organization={organization}
                podcasts={podcasts}
                removeEmptyNewItems={removeEmptyNewItems}
                updateItemProperty={(params) => {
                  updateItemProperty(params);
                  addEmptyItems();
                }}
              />
            );
          })}
        </>
      </div>
    );
  }
);

export default BulkGroup;
