import React from 'react';
import {Redirect} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {Card} from '@core/ui/Content';
import {FormFooter} from '@core/ui/FormElements';
import {Questions} from '@analytics/components/advisor/onboarding/brand/quiz/questions';
import {AdvisorQuizForm} from '@analytics/components/advisor/onboarding/brand/quiz/types';
import {
  QuizCardTitle,
  Rows,
} from '@analytics/components/advisor/onboarding/brand/shared';
import {AdvisorRouteProps} from '../../_routes';
import {useNavigateToReview} from './_routes';

type Props = AdvisorRouteProps & {
  canOnboard: boolean;
  quizData: AdvisorQuizForm;
  setQuizData: React.Dispatch<React.SetStateAction<AdvisorQuizForm>>;
};

export function OnboardingQuiz({
  canOnboard,
  quizData,
  setQuizData,
  organization,
}: Props) {
  const goToReview = useNavigateToReview(organization);

  if (!canOnboard) {
    return (
      <Redirect to={createOrgPath(organization, '/advisor/onboard/brand')} />
    );
  }

  return (
    <>
      <QuizCardTitle
        title='Audience Assessment'
        description='All questions are optional. Feel free to skip any that are not relevant
        to you.'
      />
      <Card css={{margin: 0}}>
        <Rows>
          <Questions
            organization={organization}
            quizData={quizData}
            updateQuizData={(key, value) =>
              setQuizData({...quizData, [key]: value})
            }
          />

          <FormFooter>
            <Button
              disabled={!canOnboard}
              color='primary'
              buttonSize='lg'
              onClick={() => {
                if (!canOnboard) return;
                goToReview();
              }}>
              Continue
            </Button>
          </FormFooter>
        </Rows>
      </Card>
    </>
  );
}
