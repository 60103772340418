import {graphql} from '@apollo/client/react/hoc';
import {Card} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import {companyURL} from '@analytics/components/nav/ResearchNav';
import CompaniesTable from '@analytics/components/research/CompaniesTable';
import ReviewerCompaniesQuery from '@analytics/graphql-api/_old_queries/reviewers/ReviewerCompaniesQuery';

const ReviewersCompanies = ({organization, data: {loading, me}}) => {
  if (loading) {
    return <Loading centered />;
  }

  const {companies} = me.organization.research.reviewing;

  return (
    <Card>
      <CompaniesTable
        organization={organization}
        companies={companies}
        onClickRowPath={(organization, company) => {
          return `${companyURL(organization, company)}/edit`;
        }}
      />
    </Card>
  );
};

export default graphql(ReviewerCompaniesQuery, {
  options: ({organization: {id}}) => {
    return {
      variables: {
        organizationId: id,
      },
      fetchPolicy: 'network-only',
    };
  },
})(ReviewersCompanies);
