import {gql} from '@apollo/client';

export default gql`
  query getPodcastSamePublisher($organizationId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        advisor {
          show(id: $id) {
            id

            samePublisher(limit: 6) {
              id
              title
              summary
              image {
                id
                href
                path
              }
            }
          }
        }
      }
    }
  }
`;
