import {gql} from '@apollo/client';
import CampaignDynamicFragment from './fragments/CampaignDynamicFragment';
import CampaignPodcastFragment from './fragments/CampaignPodcastFragment';
import CampaignStreamingFragment from './fragments/CampaignStreamingFragment';

export default gql`
  query getCampaignRevenue(
    $organizationId: ID!
    $id: ID!
    $after: String
    $before: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $id) {
          id
          name

          multiplier

          downloads(after: $after, before: $before) {
            count
          }

          attribution(after: $after, before: $before) {
            users {
              sum {
                users
                usersModeled
                pages
                pagesModeled
                sessions
                sessionsModeled
                products
                productsModeled
                addtocarts
                addtocartsModeled
                checkouts
                checkoutsModeled
                purchases
                purchasesModeled
                leads
                leadsModeled
                value
                valueModeled
              }
            }
          }

          campaignDynamics {
            ...CampaignDynamicOld
            downloads(after: $after, before: $before) {
              count
            }

            attribution(after: $after, before: $before) {
              users {
                sum {
                  users
                  usersModeled
                  pages
                  pagesModeled
                  sessions
                  sessionsModeled
                  products
                  productsModeled
                  addtocarts
                  addtocartsModeled
                  checkouts
                  checkoutsModeled
                  purchases
                  purchasesModeled
                  leads
                  leadsModeled
                  value
                  valueModeled
                }
              }
            }
          }

          campaignPodcasts {
            ...CampaignPodcast
            downloads(after: $after, before: $before) {
              count
            }

            attribution(after: $after, before: $before) {
              users {
                sum {
                  users
                  usersModeled
                  pages
                  pagesModeled
                  sessions
                  sessionsModeled
                  products
                  productsModeled
                  addtocarts
                  addtocartsModeled
                  checkouts
                  checkoutsModeled
                  purchases
                  purchasesModeled
                  leads
                  leadsModeled
                  value
                  valueModeled
                }
              }
            }
          }

          campaignStreamings {
            ...CampaignStreamingOld
            downloads(after: $after, before: $before) {
              count
            }

            attribution(after: $after, before: $before) {
              users {
                sum {
                  users
                  usersModeled
                  pages
                  pagesModeled
                  sessions
                  sessionsModeled
                  products
                  productsModeled
                  addtocarts
                  addtocartsModeled
                  checkouts
                  checkoutsModeled
                  purchases
                  purchasesModeled
                  leads
                  leadsModeled
                  value
                  valueModeled
                }
              }
            }
          }
        }
      }
    }
  }
  ${CampaignPodcastFragment}
  ${CampaignDynamicFragment}
  ${CampaignStreamingFragment}
`;
