import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Card, CardHeader} from '@core/ui/Content';
import {Form, FormFooter, LabelInput, Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {ManagePodcastsRouteProps} from '.';

export function VerifyPodcast({organization}: ManagePodcastsRouteProps) {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const onSubmit = (data) => {
    if (data.podcastUrl) {
      history.push(
        createOrgPath(
          organization,
          `/manage/podcasts/claim/${encodeURIComponent(data.podcastUrl)}`
        )
      );
    }
  };

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={createOrgPath(organization, 'manage/podcasts')}>
        Add Podcast
      </CardHeader>
      <Card>
        <p>
          Please enter the iTunes URL of your podcast (i.e.{' '}
          <strong>https://podcasts.apple.com/us/podcast/...</strong>)
        </p>
        <p>
          We ask for Apple Podcasts over the RSS URL to ensure that we do not
          duplicate your feed during automatic processing of Apple resources.
        </p>
        <p>
          If you have not added your podcast to Apple, enter the RSS feed below,
          but please use the URL you plan on submitting to Apple.
        </p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='podcastUrl'
            control={control}
            rules={{required: true}}
            render={({field}) => (
              <LabelInput
                label='Podcast URL (iTunes URL or RSS Feed URL)'
                invalid={!!errors.podcastUrl}
                {...field}
              />
            )}
          />
          <FormFooter>
            <Submit>Add Podcast</Submit>
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
}
