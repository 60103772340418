import styled from 'styled-components';
import {truncateWords} from '@core/lib/words';
import {FeedImage} from '@core/ui/Image';
import {Text} from '@core/ui/Text';
import {IncrementalCampaignLiftGroup, IncrementalLiftGroup} from '../types';

export function LiftGroupTitle({
  group,
}: {
  group: IncrementalLiftGroup | IncrementalCampaignLiftGroup;
}) {
  const imagesContent =
    'campaignPodcast' in group && group.campaignPodcast?.feed ? (
      <FeedImage feed={group.campaignPodcast.feed} width={72} />
    ) : null;

  return (
    <_GroupTitleContainer hasImage={!!imagesContent}>
      {imagesContent}
      <div>
        <Text variant={'groupId' in group ? 'h3' : 'h2'}>
          {group.groupName}
        </Text>
        {'campaignPodcast' in group && (
          <Text
            color='muted'
            variant='body2'
            css={{marginTop: 'var(--spacing-2)'}}>
            {truncateWords(group.campaignPodcast?.feed?.description ?? '', 20)}
          </Text>
        )}
      </div>
    </_GroupTitleContainer>
  );
}

const _GroupTitleContainer = styled.div<{hasImage?: boolean}>`
  align-items: center;
  display: grid;
  gap: 0.75rem;
  grid-template-columns: ${(div) => (div.hasImage ? 'max-content 1fr' : '1fr')};
  margin: 2rem 1.5rem 1.25rem;
`;
