import {gql} from '@apollo/client';
import AssetFragment from './fragments/AssetFragment';

export default gql`
  query getAssets($id: ID!) {
    me {
      id
      organization(id: $id) {
        id
        assets {
          ...Asset
        }
      }
    }
  }
  ${AssetFragment}
`;
