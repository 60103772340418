import {useLayoutEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {capitalize} from '@core/lib/filters';
import {useTheme} from '@core/style';
import {Icon} from '@core/ui/Icon';
import {LoadingSpinner} from '@core/ui/Loading';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import {Snackbar} from '@core/ui/Snackbar';
import {
  CampaignTrackingUrlsDocument,
  ProviderNameObject,
  useUpdateCampaignDynamicProviderMutation,
  useUpdateCampaignStreamingProviderMutation,
} from '@analytics/graphql-api';
import {CampaignTrackingData, getTrackingSelectItems} from './helpers';

type DropdownColumnProps = {
  campaignId?: string;
  format: 'dynamic' | 'streaming';
  items: ProviderNameObject[];
  maxHeight: number;
  onSelect: (_: ProviderNameObject) => void;
  selectedItem: string | null;
};

const DropdownColumn = ({
  campaignId,
  format,
  items,
  maxHeight,
  onSelect,
  selectedItem,
}: DropdownColumnProps) => {
  const [loading, setLoading] = useState(false);
  const [openedSnackbar, toggleSnackbar] = useOverlay(false);
  const [updateDynamicProvider] = useUpdateCampaignDynamicProviderMutation();
  const [updateStreamingProvider] =
    useUpdateCampaignStreamingProviderMutation();

  const onItemSelect = async (item: ProviderNameObject) => {
    setLoading(true);
    toggleSnackbar(false);
    try {
      if (format === 'dynamic') {
        await updateDynamicProvider({
          variables: {
            input: {
              id: campaignId,
              providerName: item.providerName,
            },
          },
          refetchQueries: [CampaignTrackingUrlsDocument],
        });
      } else {
        await updateStreamingProvider({
          variables: {
            input: {
              id: campaignId,
              providerName: item.providerName,
            },
          },
          refetchQueries: [CampaignTrackingUrlsDocument],
        });
      }
      onSelect(item);
    } catch (e) {
      toggleSnackbar(true);
    }
    setLoading(false);
  };

  return (
    <>
      <_DropdownColumn maxHeight={maxHeight}>
        <_Header>{capitalize(format)}</_Header>
        <_DropdownItems>
          {loading ? (
            <LoadingSpinner centered />
          ) : (
            items.map((item) => {
              const active = selectedItem === item?.providerName;
              return (
                <_DropdownItem
                  key={item?.providerNameDisplay}
                  active={active}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={() => onItemSelect(item)}>
                  {item?.providerNameDisplay}
                  <Icon
                    icon='checkmark'
                    rules={() => ({opacity: active ? 1 : 0})}
                  />
                </_DropdownItem>
              );
            })
          )}
        </_DropdownItems>
      </_DropdownColumn>
      <Snackbar
        severity='error'
        opened={openedSnackbar}
        toggle={toggleSnackbar}>
        Error updating {capitalize(format)} item providers. Please try again
        later.
      </Snackbar>
    </>
  );
};

type Props = {
  campaignId?: string;
  tableData: CampaignTrackingData[];
};

export default function TrackingUrlsTableSelect({
  campaignId,
  tableData,
}: Props) {
  const theme = useTheme();
  const ref = useRef<HTMLButtonElement>(null);
  const [opened, toggle] = useOverlay(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [selectedDynamic, setSelectedDynamic] = useState<string | null>(null);
  const [selectedStreaming, setSelectedStreaming] = useState<string | null>(
    null
  );

  const {dynamicItems, streamingItems} = useMemo(
    () => getTrackingSelectItems(tableData),
    [tableData]
  );

  useLayoutEffect(() => {
    if (ref.current) {
      const position = ref.current.getBoundingClientRect();
      const max =
        (window.innerHeight || document.documentElement.clientHeight) -
        position.y -
        position.height -
        25;
      setMaxHeight(max > 480 ? 480 : max);
    }
  }, [opened]);

  const hasSelected = !!(selectedDynamic || selectedStreaming);

  return (
    <>
      <_Select
        ref={ref}
        onClick={() => toggle(true)}
        hasSelected={hasSelected}
        theme={theme}>
        {hasSelected
          ? `Selected Provider(s): ${
              [!!selectedDynamic, !!selectedStreaming].filter(Boolean).length
            }`
          : 'Select Provider(s)'}
        <Icon icon='chevron-down-small' />
      </_Select>
      <Overlay
        opened={opened}
        toggle={toggle}
        positionTarget={ref.current}
        withShadow
        withBackdrop
        transparentBackdrop
        horizontalAlign='right'
        verticalOffset={2}>
        <_Dropdown
          showColumns={dynamicItems.length > 0 && streamingItems.length > 0}>
          {dynamicItems.length > 0 && (
            <DropdownColumn
              campaignId={campaignId}
              format='dynamic'
              items={dynamicItems}
              maxHeight={maxHeight}
              selectedItem={selectedDynamic}
              onSelect={(item) =>
                setSelectedDynamic(item?.providerName ?? null)
              }
            />
          )}
          {streamingItems.length > 0 && (
            <DropdownColumn
              campaignId={campaignId}
              format='streaming'
              items={streamingItems}
              maxHeight={maxHeight}
              selectedItem={selectedStreaming}
              onSelect={(item) =>
                setSelectedStreaming(item?.providerName ?? null)
              }
            />
          )}
        </_Dropdown>
      </Overlay>
    </>
  );
}

type _SelectProps = {
  hasSelected: boolean;
};

const _Select = styled.button<_SelectProps>`
  align-items: center;
  background: ${(button) =>
    button.theme === 'dark' ? 'var(--bg-default)' : 'var(--bg-muted)'};
  border: ${(button) =>
    button.theme === 'dark'
      ? '1px solid var(--border-default)'
      : '1px solid var(--bg-muted)'};
  border-radius: 0.375rem;
  color: ${(button) => (button.hasSelected ? 'inherit' : 'var(--text-muted)')};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: ${(button) => (button.hasSelected ? 500 : 400)};
  justify-content: space-between;
  min-width: 6rem;
  padding: 0.375rem 0.5rem 0.375rem 1rem;
  :hover {
    color: var(--blue);
  }
`;

type _DropdownProps = {
  showColumns: boolean;
};

const _Dropdown = styled.div<_DropdownProps>`
  background: var(--border-subtle);
  display: grid;
  gap: 1px;
  grid-template-columns: ${(div) => (div.showColumns ? '1fr 1fr' : '1fr')};
  overflow: hidden;
  min-width: initial;
`;

type _DropdownColumnProps = {
  maxHeight: number;
};

const _DropdownColumn = styled.div<_DropdownColumnProps>`
  background: var(--bg-default);
  display: flex;
  flex-direction: column;
  max-height: ${(div) => `${div.maxHeight}px`};
  padding: 0.5rem;
`;

const _Header = styled.div`
  color: var(--text-muted);
  font-size: 0.6875rem;
  font-weight: 500;
  letter-spacing: 0.0325rem;
  padding: 0.375rem 1rem;
  text-transform: uppercase;
`;

const _DropdownItems = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`;

type _DropdownItemProps = {
  active: boolean;
};

const _DropdownItem = styled.button<_DropdownItemProps>`
  align-items: center;
  border-radius: 0.375rem;
  color: ${(button) => (button.active ? 'var(--blue)' : 'inherit')};
  cursor: pointer;
  display: flex;
  gap: 0.375rem;
  justify-content: space-between;
  padding: 0.375rem 1rem;
  text-decoration: none;
  user-select: none;
  width: 100%;
  :hover {
    background: var(--bg-muted);
    color: var(--blue);
  }
`;
