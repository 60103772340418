import {createOrgPath} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {MembersDomainsTable} from '@analytics/components/members/MembersDomainsTable';
import MembersTable from '@analytics/components/members/MembersTable';
import {ManageMembersRouteProps} from '.';

export function Members({
  domains,
  match: {url},
  memberships,
  organization,
}: ManageMembersRouteProps & {match: {url: string}}) {
  const canManage = organization.meRole === 'admin';

  return (
    <>
      <Card>
        <MembersTable
          memberships={memberships}
          onClickRowPath={
            canManage ? ({id}) => `${url}/${id}/update` : undefined
          }
          headerRenderer={({filtersContent, searchContent}) => (
            <Stack direction='column' gap={2}>
              <Stack justifyContent='space-between' alignItems='center'>
                <CardHeader
                  css={`
                    margin: 0;
                  `}>
                  Your Members
                </CardHeader>
                {canManage && organization.slug !== 'spotify' && (
                  <Button
                    to={createOrgPath(organization, 'manage/members/add')}
                    color='secondary'>
                    Invite Member
                  </Button>
                )}
              </Stack>
              <Stack alignItems='center'>
                {searchContent}
                {filtersContent}
              </Stack>
            </Stack>
          )}
        />
      </Card>
      <Card>
        <MembersDomainsTable
          domains={domains}
          organization={organization}
          headerRenderer={({filtersContent, searchContent}) => (
            <Stack direction='column' gap={3}>
              <Stack justifyContent='space-between' alignItems='center'>
                <CardHeader
                  description='People with these email domains can automatically join your organization.'
                  css={`
                    margin: 0;
                  `}>
                  Associated email domains
                </CardHeader>
                {canManage && (
                  <Button
                    to={createOrgPath(
                      organization,
                      'manage/members/add-domain'
                    )}
                    color='secondary'>
                    Add Email Domain
                  </Button>
                )}
              </Stack>
              {searchContent ||
                (filtersContent && (
                  <Stack alignItems='center'>
                    {searchContent}
                    {filtersContent}
                  </Stack>
                ))}
            </Stack>
          )}
        />
      </Card>
    </>
  );
}
