import React from 'react';
import {Element} from '@core/style';

class Bar extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {duplicate, height, max, duration} = this.props;

    const ratio = duplicate.density / max;

    const barHeight = height * ratio;
    const barMargin = height - barHeight;

    const left = (duplicate.start / duration) * 100;
    const width = ((duplicate.end - duplicate.start) / duration) * 100;

    const style = {
      position: 'absolute',
      height: `${barHeight}px`,
      width: `${width}%`,
      left: `${left}%`,
      marginTop: `${barMargin}px`,
      background: 'rgba(255,255,255, 0.5)',
    };

    return <div style={style} />;
  }
}

class AudioDuplicates extends React.Component {
  render() {
    const {height, duplicates, duration} = this.props;

    if (!duplicates || !duplicates.length) {
      return '';
    }

    const max = duplicates.reduce((max, dup) => {
      return dup.density > max ? dup.density : max;
    }, 0);

    return (
      <Element
        rules={() => ({position: 'relative'})}
        domRef={(elem) => {
          this.elem = elem;
        }}>
        <Element>
          {duplicates.map((duplicate, n) => {
            return (
              <Bar
                duplicate={duplicate}
                height={height}
                max={max}
                duration={duration}
                key={n}
              />
            );
          })}
        </Element>
      </Element>
    );
  }
}

export default AudioDuplicates;
