import {graphql} from '@apollo/client/react/hoc';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import {
  FetchedCampaignDynamicPodcast,
  GetCampaignDynamicPodcastQuery,
} from '@analytics/graphql-api';
import CampaignDynamicPodcastQuery from '@analytics/graphql-api/_old_queries/CampaignDynamicPodcastQuery';
import {CampaignDynamicRouteProps} from '../../_routes';
import {CampaignDynamicPodcast} from './CampaignDynamicPodcast';
import {CampaignDynamicEpisodesIndex} from './episodes';

export type CampaignDynamicPodcastRouteProps = CampaignDynamicRouteProps & {
  campaignDynamicPodcast: FetchedCampaignDynamicPodcast;
};

const CampaignDynamicPodcastIndex = ({
  user,
  organization,
  campaign,
  campaignDynamic,
  data: {loading, me},
}: CampaignDynamicRouteProps & {
  data: {
    loading: boolean;
    me: GetCampaignDynamicPodcastQuery['me'];
  };
}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const campaignDynamicPodcast =
    me?.organization?.campaign?.campaignDynamic?.campaignDynamicPodcast;

  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/dynamics/:campaignDynamicId/podcasts/:campaignDynamicPodcastId'
        campaign={campaign}
        user={user}
        organization={organization}
        campaignDynamic={campaignDynamic}
        campaignDynamicPodcast={campaignDynamicPodcast}
        component={CampaignDynamicPodcast}
      />
      <PropsRoute
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/dynamics/:campaignDynamicId/podcasts/:campaignDynamicPodcastId/episodes'
        campaign={campaign}
        user={user}
        organization={organization}
        campaignDynamic={campaignDynamic}
        campaignDynamicPodcast={campaignDynamicPodcast}
        component={CampaignDynamicEpisodesIndex}
      />
    </Switch>
  );
};

export default graphql(CampaignDynamicPodcastQuery, {
  options: ({
    organization,
    campaign,
    campaignDynamic,
    match: {
      params: {campaignDynamicPodcastId},
    },
  }) => {
    return {
      variables: {
        organizationId: organization.id,
        campaignId: campaign.id,
        campaignDynamicId: campaignDynamic.id,
        id: campaignDynamicPodcastId,
      },
    };
  },
})(CampaignDynamicPodcastIndex);
