import {gql} from '@apollo/client';

export default gql`
  query getBrandCampaignOmniChart(
    $organizationId: ID!
    $id: ID!
    $after: String
    $before: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $id) {
          id
          attribution(after: $after, before: $before) {
            users {
              hourlyVisitors {
                count
                hour
              }
            }
          }
          downloads(after: $after, before: $before) {
            hourly {
              hour
              downloadClass
              count
            }
          }
          campaignPodcasts {
            id
            feed {
              id
              title
              image {
                href
                id
                path
              }
            }
            episodes(after: $after, before: $before) {
              id
              title
              published
            }
          }
        }
      }
    }
  }
`;
