import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {ExportButton} from '@analytics/components/ExportOverlay';
import {CompanyObject, OrganizationObject} from '@analytics/graphql-api';

type Props = {
  company: CompanyObject;
  organization: OrganizationObject;
};
export default function CompanyExport({company, organization}: Props) {
  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader>Export</CardHeader>
      <Card>
        <p css={{marginTop: '0.5rem', marginLeft: '0.25rem'}}>
          Export a CSV of all the ad placements for the brand{' '}
          <b>{company.name}</b>.
        </p>
        <ExportButton
          objectType='company'
          kind='placements'
          pk={company.id}
          organizationId={organization.id}>
          Export
        </ExportButton>
      </Card>
    </ResponsiveContent>
  );
}
