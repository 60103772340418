import {useEffect, useState} from 'react';
import {Text} from '@spotify-internal/encore-web';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {logoutViaSpotify} from '@core/components/SignInWithSpotify/auth';
import useQueryParams from '@core/lib/useQueryParams';
import {
  HadAccountMessage,
  OnboardBodyContainer,
  OnboardContentContainer,
  OnboardPageContainer,
  OnboardPrimaryButton,
  OnboardSecondaryButton,
  OnboardTitleText,
} from '@analytics/components/onboard/OnboardLayout';
import SideGraphic from '@analytics/components/onboard/SideGraphic';
import {
  useCreateUserMutation,
  useSendVerifyEmailMutation,
  useUserInfoQuery,
} from '@analytics/graphql-api';
import SpotifyBanner from '../../components/onboard/SpotifyBanner';

// TODO-SPAAF-51: Set this dynamically based on environment
const host = 'https://adanalytics.spotify.com';

export const HeaderTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.04em;
  z-index: 1;
`;

export const HeaderSubtitle = styled.div`
  z-index: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  media (min-width: 768px) {
    max-width: 70%;
  }
`;

const AccountConfirm = () => {
  const [sendVerifyEmailMutation] = useSendVerifyEmailMutation();
  const [createUser] = useCreateUserMutation();
  const [isLoading, setIsLoading] = useState(false);
  const {data: userData} = useUserInfoQuery();
  const history = useHistory();
  useEffect(() => {
    if (userData?.userInfo?.hasVerifiedEmailForSpaa) {
      history.push('/');
    }
  }, [userData?.userInfo?.hasVerifiedEmailForSpaa, history]);

  const userEmail = userData?.userInfo?.email;
  const {
    params: {error: errorFromUrl},
  } = useQueryParams(['error']);

  const isNewUser = errorFromUrl === 'new-user';

  const onContinue = async () => {
    setIsLoading(true);
    if (isNewUser) {
      await createUser({
        variables: {
          input: {
            host,
          },
        },
      });
    }
    await sendVerifyEmailMutation({variables: {input: {host}}});
    window.location.href = '/email-confirm';
  };

  const BodyText = styled(Text)`
    text-align: left;
  `;

  const boxBody = (
    <BodyText>
      {isNewUser ? (
        <>
          We recommend using a <strong>business email address</strong> to create
          your Spotify Ad Analytics account. Do you want to use{' '}
          <strong>{userEmail}</strong> to create your account?
        </>
      ) : (
        <>
          We’ve pulled your information from Podsights into your new Spotify Ad
          Analytics account.
        </>
      )}
    </BodyText>
  );

  const boxTitle = (
    <OnboardTitleText>
      {isNewUser ? 'Confirm your email address' : 'Welcome back'}
    </OnboardTitleText>
  );

  const onLogout = () => {
    logoutViaSpotify();
  };

  return (
    <OnboardPageContainer>
      <OnboardContentContainer>
        <SpotifyBanner advertising position='flex-start' />
        <OnboardBodyContainer>
          {boxTitle}
          {boxBody}
          <OnboardPrimaryButton
            onClick={onContinue}
            disabled={isLoading}
            style={{marginTop: 20, width: '100%'}}>
            {isNewUser ? 'Confirm' : 'Continue'}
          </OnboardPrimaryButton>
          {isNewUser && (
            <OnboardSecondaryButton onClick={onLogout}>
              Back
            </OnboardSecondaryButton>
          )}
          {isNewUser && <HadAccountMessage />}
        </OnboardBodyContainer>
      </OnboardContentContainer>
      <SideGraphic />
    </OnboardPageContainer>
  );
};

export default AccountConfirm;
