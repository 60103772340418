import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {createOrgPath} from '@core/lib/organizations';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {Submit} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import MeasureFeed from '@analytics/graphql-api/_old_mutations/MeasureFeed';
import FeedQuery from '@analytics/graphql-api/_old_queries/research/FeedQuery';

class FeedMeasure extends React.Component {
  state = {
    loading: false,
    complete: false,
  };

  onSubmit = (evt) => {
    evt.preventDefault();
    const {measureFeed, feed} = this.props;

    this.setState({
      loading: true,
    });

    measureFeed().then(() => {
      this.setState({
        loading: false,
        complete: true,
      });
    });
  };

  render() {
    const {feed, organization} = this.props;
    const {loading, complete} = this.state;

    return (
      <ResponsiveContent containerSize='medium'>
        <CardHeader>Measure Feed</CardHeader>
        <Card>
          {complete ? (
            <>
              <p>
                Thank you. Our reviewers will start pulling ads from this feed.
                It typically takes 24-48 hours to complete.
              </p>
              <Element rules={() => ({padding: '20px 0', textAlign: 'right'})}>
                <Button
                  organization={organization}
                  path={`/research/podcasts/${feed.id}`}>
                  Back To Feed
                </Button>
              </Element>
            </>
          ) : (
            <>
              <p>
                Ad Analytics works by sampling the top 200 podcasts on ITunes
                and pulling ads from those episodes. Because podcasts rise and
                fall we sometimes only process half of a feed.
              </p>
              <p>
                If you would like us to process this entire feed, select measure
                feed below.
              </p>

              <form onSubmit={this.onSubmit}>
                <Stack
                  direction='row'
                  justifyContent='flex-end'
                  gap={4}
                  css={`
                    padding: 20px 0;
                  `}>
                  <Button
                    to={createOrgPath(
                      organization,
                      `/research/podcasts/${feed.id}`
                    )}>
                    Back To Feed
                  </Button>
                  <Submit disabled={loading}>Measure Feed</Submit>
                </Stack>
              </form>
            </>
          )}
        </Card>
      </ResponsiveContent>
    );
  }
}

export default graphql(MeasureFeed, {
  props: ({ownProps: {organization, feed}, mutate}) => ({
    measureFeed: () => {
      return mutate({
        variables: {
          input: {
            feedId: feed.id,
          },
        },
        update: (store, {data: {measureFeed}}) => {
          const query = {
            query: FeedQuery,
            variables: {
              organizationId: organization.id,
              id: feed.id,
            },
          };

          query.data = store.readQuery(query);
          query.data.me.organization.feed.measure = true;
          query.data.me.organization.feed.processing = true;
          store.writeQuery(query);
        },
      });
    },
  }),
})(FeedMeasure);
