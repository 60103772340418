import React, {useEffect, useReducer} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {firstChar, obfuscateEmail} from '@core/lib/filters';
import {getTimestamp} from '@core/lib/time';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import Loading from '@core/ui/Loading';
import AudioPlayer from '@analytics/components/audio/AudioPlayer';
import Transcript from '@analytics/components/transcript';
import RejectAd from './mutations/RejectAd';
import SplitAd from './mutations/SplitAd';
import UpdateAd from './mutations/UpdateAd';

// TODO
// add list of podcast names and episodes where an ad appears
// so we can figure out what the promo code or url is if unclear

const ReviewAd = ({
  ad,
  autoplay,
  onComplete,
  reviewerId,
  organization,
  // Mutations
  rejectAd,
  splitAd,
  updateAd,
}) => {
  const startTime = getTimestamp();
  const [state, setState] = useReducer(
    (state, newState) => {
      return {...state, ...newState};
    },
    {
      audio: null,
      audioSrc: null,
      canplay: false,
      currentTime: 0,
      duration: null,
      keywordSliceWindow: 3,
      loading: false,
      playing: false,
      splitSeconds: null,
      splitting: false,
    }
  );

  const onTimeUpdate = ({target: {currentTime}}) => {
    setState({currentTime});
  };

  const setCurrentTime = (value) => {
    state.audio.currentTime = value;
  };

  const onReady = ({target}) => {
    const src = target.querySelectorAll('source')[0].src;

    if (state.audioSrc == src) {
      return true;
    }

    setState({
      audio: target,
      audioSrc: src,
      canplay: true,
      duration: target.duration,
      splitSeconds: target.duration / 2,
      playing: !!autoplay,
    });

    target.addEventListener('ended', () => {
      setState({playing: false});
    });
  };

  const togglePlay = () => {
    setState({playing: !state.playing});
  };

  const onSave = (data) => {
    setState({loading: true});
    data.reviewDuration = getTimestamp() - startTime;

    return updateAd(data).then(() => {
      setState({loading: false});
      onComplete(data);
    });
  };

  const reject = () => {
    setState({loading: true});
    return rejectAd({
      reviewDuration: getTimestamp() - startTime,
    }).then(() => {
      setState({loading: false});
      onComplete();
    });
  };

  const startAdSplit = () => {
    setState({splitting: true});
    // return true;
  };
  const onSplitUpdate = (seconds) => {
    setState({
      splitSeconds: seconds,
    });
  };

  const _splitAd = () => {
    const {splitSeconds} = state;
    setState({loading: true});

    return splitAd({
      splitSeconds,
      reviewDuration: getTimestamp() - startTime,
    }).then(() => {
      setState({loading: false});
      onComplete();
    });
  };

  useEffect(() => {
    setState({
      loading: false,
      canplay: false,
      playing: false,
      currentTime: 0,
      duration: null,
      splitting: false,
      splitSeconds: null,
    });
  }, [ad]);

  const {playing, currentTime, duration, splitting, splitSeconds, loading} =
    state;

  if (loading) {
    return <Loading centered />;
  }

  // try to find the initial reviewer.
  let review = null;

  const newReviews = ad.reviews.filter(({startState}) => 'NEW');

  if (newReviews.length) {
    review = newReviews[0];
  } else if (ad.reviews.length) {
    review = ad.reviews[0];
  }

  return (
    <Element>
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'grid',
          gridGap: '1rem',
          gridTemplateColumns: '1fr max-content max-content max-content',
          marginBottom: '1.25rem',
        })}>
        {review ? (
          <Element rules={() => ({fontSize: '0.875rem', lineHeight: 1.5})}>
            <Element rules={() => ({fontWeight: 500})}>Reviewer:</Element>
            {review.reviewer.firstName} {firstChar(review.reviewer.lastName)} (
            {obfuscateEmail(review.reviewer.email)})
          </Element>
        ) : (
          ''
        )}

        <Button onClick={reject} color='error'>
          Reject Ad
        </Button>
        <Button onClick={startAdSplit} variant='outlined'>
          Split Ad
        </Button>
      </Element>
      <AudioPlayer
        flacPath={ad.flacPath}
        mpegPath={ad.mpegPath}
        waveform={ad.waveform}
        onReady={onReady}
        togglePlay={togglePlay}
        onTimeUpdate={onTimeUpdate}
        playing={playing}
        currentTime={currentTime}
        setCurrentTime={setCurrentTime}
        duration={duration}
        splitting={splitting}
        splitSeconds={splitSeconds}
        onSplitUpdate={onSplitUpdate}
      />
      {splitting ? (
        <Element>
          <Element rules={() => ({marginTop: '10px', padding: '5px'})}>
            Split ad at <b>{splitSeconds} Seconds</b>
          </Element>

          <Element rules={() => ({display: 'flex'})}>
            <Element rules={() => ({padding: '5px', flex: 1, color: '#999'})}>
              You are currently splitting this ad into 2. Drag the bar above to
              change where the split happens.
            </Element>
            <Button onClick={() => setState({splitting: false})}>Cancel</Button>
            <Button
              color='inverse'
              onClick={_splitAd}
              css={`
                margin-left: 0.625rem;
              `}>
              Split Ad
            </Button>
          </Element>
        </Element>
      ) : (
        <Transcript
          ad={ad}
          guess={ad.guess}
          currentTime={currentTime}
          setCurrentTime={setCurrentTime}
          onSave={onSave}
          organization={organization}
        />
      )}
    </Element>
  );
};

export default compose(
  graphql(UpdateAd, {
    props: ({
      ownProps: {reviewerId, hitId, workerId, assignmentId, ad},
      mutate,
    }) => ({
      updateAd: (input) => {
        input.reviewerId = reviewerId;
        input.workerId = workerId;
        input.hitId = hitId;
        input.assignmentId = assignmentId;
        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  }),
  graphql(RejectAd, {
    props: ({ownProps: {reviewerId, ad}, mutate}) => ({
      rejectAd: (input) => {
        input.id = ad.id;
        input.reviewerId = reviewerId;

        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  }),
  graphql(SplitAd, {
    props: ({ownProps: {reviewerId, ad}, mutate}) => ({
      splitAd: (input) => {
        input.id = ad.id;
        input.reviewerId = reviewerId;

        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  })
)(ReviewAd);
