import {useMemo} from 'react';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import Unclassified from '@analytics/components/Unclassified';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedCampaignStreaming,
  FetchedOrganization,
} from '@analytics/graphql-api';
import campaignTableColumns from '@analytics/lib/campaignTableColumns';
import useCampaignStreamingStreams from '@analytics/lib/hooks/useCampaignStreamingStreams';

type Props = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign: FetchedCampaign;
  campaignStreaming: FetchedCampaignStreaming;
};

export function CampaignStreamingStreamsTable({
  organization,
  campaign,
  campaignStreaming,
}: Props) {
  const data = useCampaignStreamingStreams({
    campaignStreaming,
  });

  const columns = useMemo<DataTableColumn[]>(
    () => [
      {
        title: 'Placement',
        accessor: 'name',
        type: 'string',
        Cell: ({d: {campaignStreamingName}}) => (
          <Unclassified name={campaignStreamingName} format='streaming' />
        ),
      },
      ...campaignTableColumns(organization, campaign, data),
    ],
    [data, campaign, organization]
  );

  return (
    <DataTable
      columns={columns}
      orderBy='-downloadTotal'
      data={data}
      emptyMessageText='Campaign streaming placements not found'
    />
  );
}
