import {useState} from 'react';
import {LngLatBounds, Map as MapType} from 'mapbox-gl';
import {GREEN, PINK, RED_ORANGE} from '@core/style';
import Map from '../Map';

const COLORS = ['#45e57e', '#45e57e', '#e7fe02', RED_ORANGE];

const toGeoJSON = (data) => {
  return data.map((row) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [row.longitude, row.latitude],
      },
      properties: {
        count: typeof row.count === 'number' ? row.count : 1,
      },
    };
  });
};

interface DownloadsProps {
  height: number;
  locations: any[];
  zoom?: number;
}

const MapDownloads = ({height, locations, zoom}: DownloadsProps) => {
  const [isMapCentered, setIsMapCentered] = useState(false);

  if (!locations || locations.length < 1) {
    return null;
  }

  const onMapReady = (map: MapType, {bounds}: {bounds: LngLatBounds}) => {
    const data = toGeoJSON(locations);
    // Add a new source from our GeoJSON data and set the
    // 'cluster' option to true. GL-JS will add the point_count property to your source data.
    map.addSource('downloads', {
      type: 'geojson',
      data: {
        type: 'DownloadsCollection',
        features: data,
      },

      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      clusterProperties: {
        sum: ['+', ['get', 'count']],
      },
    });

    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'downloads',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          COLORS[1],
          100,
          COLORS[2],
          750,
          COLORS[3],
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      },
    });

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'downloads',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['number-format', ['get', 'sum'], {style: 'decimal'}],
        'text-font': [
          'Larsseit Bold',
          'DIN Offc Pro Medium',
          'Arial Unicode MS Bold',
        ],
        'text-size': 13,
      },
      paint: {
        'text-color': '#212121',
      },
    });

    map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'downloads',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-opacity': 0.8,
        'circle-color': COLORS[0],
        'circle-radius': 4,
        'circle-stroke-width': 1,
        'circle-stroke-color': COLORS[0],
      },
    });

    // inspect a cluster on click
    map.on('click', 'clusters', (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });
      const clusterId = features[0].properties.cluster_id;
      map
        .getSource('downloads')
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });

    map.on('mouseenter', 'clusters', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', () => {
      map.getCanvas().style.cursor = '';
    });

    data.forEach((d) => {
      if (d && d.geometry) {
        bounds.extend(d.geometry.coordinates);
      }
    });
    map.fitBounds(bounds, {animate: false, padding: 100, maxZoom: 7});
    requestAnimationFrame(() => {
      setIsMapCentered(true);
    });
  };

  return (
    <Map
      onMapReady={onMapReady}
      zoom={zoom}
      height={height}
      rules={() => ({opacity: isMapCentered ? 1 : 0})}
    />
  );
};

export default MapDownloads;
