import { titleSpacing } from "./SlotSizes.js";
import styled, { css } from "../../styled-components.js";
import { cssSpacing, isSpacer } from "../../styles/index.js";
export var InteractiveArea = styled.div.withConfig({
  displayName: "Areas__InteractiveArea",
  componentId: "sc-1tea2mc-0"
})(["& button,& a,& [role='button'],& [role='link']{position:relative;z-index:1;}"]);
export var MainArea = styled(InteractiveArea).withConfig({
  displayName: "Areas__MainArea",
  componentId: "sc-1tea2mc-1"
})(["", ""], function (_ref) {
  var $horizontalGap = _ref.$horizontalGap;
  return css(["column-gap:", ";align-items:center;display:flex;"], isSpacer($horizontalGap) ? cssSpacing($horizontalGap) : $horizontalGap);
});
export var Column = styled(InteractiveArea).withConfig({
  displayName: "Areas__Column",
  componentId: "sc-1tea2mc-2"
})(["", ""], function (_ref2) {
  var $size = _ref2.$size,
    _ref2$$titleGap = _ref2.$titleGap,
    $titleGap = _ref2$$titleGap === void 0 ? titleSpacing[$size] : _ref2$$titleGap;
  return css(["display:flex;flex-direction:column;gap:", ";flex-basis:100%;align-items:flex-start;"], isSpacer($titleGap) ? cssSpacing($titleGap) : $titleGap);
});
export var FooterArea = styled(InteractiveArea).withConfig({
  displayName: "Areas__FooterArea",
  componentId: "sc-1tea2mc-3"
})(["margin-top:auto;"]);