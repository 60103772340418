import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {CampaignRouteProps} from '../_routes';
import {useCampaignPixelsRoutes} from './_routes';
import {CampaignPixelIndex} from './pixel';

export function CampaignPixelsIndex({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  const {renderRoutes, getRouteUrl} = useCampaignPixelsRoutes({
    organization,
    campaign,
  });

  return (
    <Switch>
      {renderRoutes({
        components: {
          redirect: () => {
            if ((campaign.pixels ?? []).length > 0) {
              const key = campaign?.pixels?.[0]?.key;
              if (key) {
                return <Redirect to={getRouteUrl('pixel', {key})} />;
              }
            }

            return <Redirect to={createOrgPath(organization, '/campaigns')} />;
          },
          pixel: CampaignPixelIndex,
        },
        extraProps: {
          organization,
          user,
          campaign,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
