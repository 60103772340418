import {gql} from '@apollo/client';
import PodcastFragment from '../_old_queries/fragments/PodcastFragment';

export default gql`
  mutation isProcessingPodcasts($input: IsProcessingPodcastsInput!) {
    authed {
      success
    }
    isProcessingPodcasts(input: $input) {
      podcasts {
        ...Podcast
      }
    }
  }
  ${PodcastFragment}
`;
