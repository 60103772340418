import {useQuery} from '@apollo/client';
import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import Loading from '@core/ui/Loading';
import PixelNav from '@analytics/components/nav/PixelNav';
import ORGANIZATION_KEY_QUERY, {
  IOrganizationKey,
  IOrganizationKeyInput,
} from '@analytics/graphql-api/_old_queries/OrganizationKeyQuery';
import {ManagePixelsRouteProps} from '../_routes';
import {PixelDebug} from './PixelDebug';
import {PixelOverview} from './PixelOverview';
import {PixelUpdate} from './PixelUpdate';
import {usePixelRoutes} from './_routes';
import {PixelEventsIndex} from './events';
import {PixelMappingsIndex} from './mappings';
import {PixelUrlsIndex} from './urls';
import { ManageAdStudioIndex } from './adStudio';

export function PixelIndex({
  user,
  organization,
  match: {
    params: {key},
  },
}: ManagePixelsRouteProps & {
  match: {
    url: string;
    params: {
      key: string;
    };
  };
}) {
  const {data, loading, error} = useQuery<
    IOrganizationKey,
    IOrganizationKeyInput
  >(ORGANIZATION_KEY_QUERY, {
    variables: {
      organizationId: organization.id,
      key,
    },
  });

  const {renderRoutes} = usePixelRoutes({organization, key});

  if (loading) {
    return <Loading fixed />;
  }
  if (error) {
    return <Redirect to={createOrgPath(organization, 'manage/pixels')} />;
  }

  const pixel = data?.me.organization.key;

  return (
    <>
      <PixelNav organization={organization} pixel={pixel} user={user} />
      <Switch>
        {renderRoutes({
          components: {
            overview: PixelOverview,
            update: PixelUpdate,
            debug: PixelDebug,
            events: PixelEventsIndex,
            urls: PixelUrlsIndex,
            mappings: PixelMappingsIndex, 
            'ad-studio': ManageAdStudioIndex,
          },
          extraProps: {
            user,
            organization,
            pixel,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
}
