import {gql} from '@apollo/client';

export default gql`
  query EpisodeMinutesByDay(
    $organizationId: ID!
    $after: Date
    $reviewerId: ID
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          reviewing {
            episodeMinutesByDay(after: $after, reviewerId: $reviewerId) {
              day
              minutes
            }
          }
        }
      }
    }
  }
`;
