import DataSelector, {DataSelectorProps} from '@core/ui/DataSelector';
import COMPANY_SEARCH_QUERY from './CompanySearchQuery';

interface Props extends Omit<DataSelectorProps, 'dataQuery' | 'fromResponse'> {}

export default function CompanySelect({
  placeholder = 'Search brand',
  selectableInputValue = true,
  requireOrganization = false,
  selectableInputValuePlaceholder = 'Create brand',
  ...props
}: Props) {
  return (
    <DataSelector
      dataQuery={COMPANY_SEARCH_QUERY}
      fromResponse={(data) => data?.me?.companySearch ?? []}
      placeholder={placeholder}
      selectableInputValue={selectableInputValue}
      selectableInputValuePlaceholder={selectableInputValuePlaceholder}
      requireOrganization={requireOrganization}
      css={`
        width: 100%;
      `}
      {...props}
    />
  );
}
