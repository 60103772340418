import {Redirect} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {ToastMessage} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import PixelEventLog from '@analytics/components/pixels/PixelEventLog';
import PixelVerifiedEventsTable from '@analytics/components/pixels/PixelVerifiedEventsTable';
import {ManagePixelsPixelRouteProps} from './_routes';

export function PixelOverview({
  organization,
  pixel,
}: ManagePixelsPixelRouteProps) {
  if (!pixel) {
    return <Redirect to={createOrgPath(organization, 'manage/pixels')} />;
  }

  const {hasData} = pixel.debug;

  return (
    <>
      {hasData ? (
        <Card>
          <CardHeader>Event Log</CardHeader>
          <PixelEventLog organization={organization} pixel={pixel} />
        </Card>
      ) : (
        <ToastMessage
          alertType='info'
          css={`
            justify-content: left;
            padding: 1.25rem;
          `}>
          <h4>You're almost done!</h4>
          <div css={{margin: '.125rem 0 0'}}>
            Next, you must install these events on your brand's site before Ad
            Analytics can attribute any traffic for a campaign. The status
            indicator in the “Last Activity” column will turn green once Ad
            Analytics is detecting traffic. It may take up to 20 minutes to be
            reflected here.
          </div>
        </ToastMessage>
      )}

      <Card>
        <CardHeader
          rightContent={
            <Button
              to={createOrgPath(
                organization,
                `manage/pixels/${pixel.key}/events/create`
              )}
              color='secondary'>
              Add New Event
            </Button>
          }>
          Verified Events
        </CardHeader>
        <PixelVerifiedEventsTable organization={organization} pixel={pixel} />
      </Card>
    </>
  );
}
