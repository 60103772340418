import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

type SetParamFn = (_key: string, _value: string) => void;
type RemoveParamFn = (_key: string) => void;

const useQueryParams = (
  keys: string[],
  defaultValues?: Record<string, string>
): {
  params: {[key: string]: string | boolean};
  set: SetParamFn;
  remove: RemoveParamFn;
} => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  const set: SetParamFn = (key, value) => {
    queryParams.set(key, value);
    history.replace({
      search: queryParams.toString(),
    });
  };

  const remove: RemoveParamFn = (key) => {
    queryParams.delete(key);
    history.replace({
      search: queryParams.toString(),
    });
  };

  useEffect(() => {
    if (defaultValues) {
      keys.forEach((key) => {
        if (!queryParams.has(key) && defaultValues[key]) {
          set(key, defaultValues[key]);
        }
      });
    }
  }, []);

  return {
    params: keys.reduce((acc: object, key: string) => {
      acc[key] = queryParams.has(key)
        ? queryParams.get(key) || true
        : defaultValues?.[key];
      return acc;
    }, {}),
    set,
    remove,
  };
};

export default useQueryParams;
