/* eslint-disable */
// PactSafe Site Access ID
// The site access ID is unique to your PactSafe site and is meant only to help identify your implementation with PactSafe.
import {reject} from 'lodash';

// This means that it can safely be used in client-side implementations and mobile applications.
export const SITE_ACCESS_ID = '31271700-65b7-43e5-8744-0ef31c96b401';

// PactSafe Snippet
export const injectPactSafeSnippet = () => {
  (function (w, d, s, c, f, n, t, g, a, b, l) {
    w.PactSafeObject = n;
    (w[n] =
      w[n] ||
      function () {
        (w[n].q = w[n].q || []).push(arguments);
      }),
      (w[n].on = function () {
        (w[n].e = w[n].e || []).push(arguments);
      }),
      (w[n].once = function () {
        (w[n].eo = w[n].eo || []).push(arguments);
      }),
      (w[n].off = function () {
        (w[n].o = w[n].o || []).push(arguments);
      }),
      (w[n].t = 1 * new (Date as any)()),
      (w[n].l = 0);
    a = d.createElement(s);
    b = d.getElementsByTagName(s)[0];
    a.async = 1;
    a.src = c;
    a.onload = a.onreadystatechange = function () {
      w[n].l = 1;
    };
    a.onerror = a.onabort = function () {
      w[n].l = 0;
    };
    b.parentNode.insertBefore(a, b);
    setTimeout(function () {
      if (!w[n].l && !w[n].loaded) {
        w[n].error = 1;
        a = d.createElement(s);
        a.async = 1;
        a.src = f;
        a.onload = a.onreadystatechange = function () {
          w[n].l = 1;
        };
        a.onerror = a.onabort = function () {
          w[n].l = 0;
        };
        b.parentNode.insertBefore(a, b);
        l = function (u, e) {
          try {
            e = d.createElement('img');
            e.src = `https://d3r8bdci515tjv.cloudfront.net/error.gif?t=${
              w[n].t
            }&u=${encodeURIComponent(u)}`;
            d.getElementsByTagName('body')[0].appendChild(e);
          } catch (x) {}
        };
        l(c);
        setTimeout(function () {
          if (!w[n].l && !w[n].loaded) {
            w[n].error = 1;
            if (g && typeof g === 'function') {
              g.call(this);
            }
            l(f);
          }
        }, t);
      }
    }, t);
  })(
    window,
    document,
    'script',
    'https://vault.pactsafe.io/ps.min.js',
    'https://d3l1mqnl5xpsuc.cloudfront.net/ps.min.js',
    '_ps',
    4000,
    function optionalErrorCallback() {
      console.warn('Unable to load PS Clickwrap Library');
    }
  );
};

export const isSnippetLoaded = () => {
  const scripts = document.getElementsByTagName('script');
  if ((window as any)._ps && (window as any)._ps.loaded) return true;
  for (let i = 0; i < scripts.length; i += 1) {
    if (scripts[i].src.indexOf('https://vault.pactsafe.io/ps.min.js') !== -1) {
      return true;
    }
  }
  return false;
};

export const waitForSnippet = (): Promise<boolean> => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if ((window as any)._ps && (window as any)._ps.loaded) {
        clearInterval(interval);
        resolve(true);
      }
    }, 100);
  });
};
