import {gql} from '@apollo/client';

export default gql`
  query claimPublisher($href: String!) {
    claim {
      publisher(href: $href) {
        href
        title
        email
        name
      }
    }
  }
`;
