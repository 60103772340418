import {useQuery} from '@apollo/client';
import {useLocation} from 'react-router-dom';
import MapDownloads from '@core/components/MapDownloads';
import {findCountry} from '@core/lib/countryRegionData';
import {createOrgPath} from '@core/lib/organizations';
import {ToastMessage} from '@core/ui/Alert';
import {Breadcrumbs} from '@core/ui/Breadcrumbs';
import {Card} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import {AttributionRegionsTable} from '@analytics/components/attribution/AttributionLocationTable';
import BRAND_ORGANIZATION_IMPRESSIONS_COUNTRY_LOCATION_QUERY from '@analytics/graphql-api/_old_queries/BrandOrganizationImpressionsCountryLocationQuery';
import {MeasureInsightsLocationsRouteProps} from '.';

type Props = MeasureInsightsLocationsRouteProps & {
  match: {url: string; params: any};
};

export function InsightsLocationsCountry({
  after,
  before,
  campaigns,
  match: {
    url,
    params: {country},
  },
  organization,
  publishers,
}: Props) {
  const {search} = useLocation();
  const {data, loading, error} = useQuery(
    BRAND_ORGANIZATION_IMPRESSIONS_COUNTRY_LOCATION_QUERY,
    {
      variables: {
        id: organization.id,
        after,
        before,
        country,
        publisherIds: publishers.map(({id}) => id),
        campaignIds: campaigns.map(({id}) => id),
      },
    }
  );

  if (loading) {
    return (
      <LoadingSpinner
        centered
        css={`
          margin-top: var(--spacing-5);
        `}
      />
    );
  } else if (error) {
    return (
      <ToastMessage alertType='warning' rules={() => ({margin: '1rem 0'})}>
        Error loading data. Please try again later.
      </ToastMessage>
    );
  }

  const nodes =
    data?.me?.organization?.overview?.impressions?.locations?.nodes ?? [];
  const regions =
    data?.me?.organization?.overview?.impressions?.locations?.regions ?? [];
  const count =
    data?.me?.organization?.overview?.impressions?.locations?.count ?? 0;

  const countryMeta = findCountry(country);

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            to:
              createOrgPath(organization, 'measure/overview/locations') +
              search,
            title: 'Locations',
          },
          {
            title: countryMeta ? countryMeta.countryName : '',
          },
        ]}
        css={`
          margin: 1.25rem 1.5rem 0;
          padding: 0;
        `}
      />
      {nodes.length > 0 && (
        <Card
          css={{
            border: 0,
            display: 'flex',
            margin: '1.25rem 1.5rem 0',
            overflow: 'hidden',
            padding: 0,
            position: 'relative',
          }}>
          <MapDownloads height={500} locations={nodes} />
        </Card>
      )}
      <Card css={{margin: '1rem 1.5rem 0'}}>
        <AttributionRegionsTable
          country={country}
          regions={regions}
          count={count}
          onClickRowPath={({region}) =>
            `${url.replace(/\/$/, '')}/${region}${search}`
          }
        />
      </Card>
    </>
  );
}
