import {gql} from '@apollo/client';

export default gql`
  query getCompanyDetail($organizationId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          company(id: $id) {
            id
            name
            slug

            activity(interval: "week", kind: "episode") {
              time
              count
            }
          }
        }
      }
    }
  }
`;
