import {gql} from '@apollo/client';

export default gql`
  mutation createConversionUrl($input: CreateConversionURLInput!) {
    authed {
      success
    }
    createConversionUrl(input: $input) {
      conversionUrl {
        id
        url
        value
        action
        type
        category
      }
    }
  }
`;
