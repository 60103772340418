import {useMemo} from 'react';
import dayjs from '@core/lib/dayjs';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {
  ExportJobStatusStatus,
  FetchedBuySideOrganization,
  FetchedOrganization,
  FetchedReportExportInstances,
} from '@analytics/graphql-api';
import {fmtExportType} from '../helpers';
import {StatusBadge} from '../shared';
import DownloadInstanceButton from './DownloadInstanceButton';

type Props = {
  exportInstances: FetchedReportExportInstances;
  organization: FetchedOrganization | FetchedBuySideOrganization;
};

export default function ReportsExportInstancesTable({
  exportInstances,
  organization,
}: Props) {
  const columns = useMemo<
    DataTableColumn<FetchedReportExportInstances[number]>[]
  >(
    () => [
      {
        accessor: 'name',
        title: 'Name',
        type: 'string',
        getValue: ({d}) => d?.savedExport?.name ?? '',
      },
      {
        accessor: 'exportType',
        title: 'Type',
        type: 'string',
        getValue: ({d}) => fmtExportType(d?.exportType),
      },
      {
        accessor: 'createdBy',
        title: 'Created By',
        type: 'string',
        getValue: ({d}) =>
          `${d?.createdBy?.firstName} ${d?.createdBy?.lastName}`,
      },
      {
        accessor: 'updatedAt',
        title: 'Run On',
        type: 'string',
        fmt: (v) => (v ? dayjs(v).format('MMMM DD, YYYY h:mm A') : ''),
        getValue: ({d}) =>
          d?.updatedAt ? dayjs(d.updatedAt).toISOString() : '',
      },
      {
        accessor: 'status',
        title: 'Status',
        type: 'string',
        Cell: ({d}) => <StatusBadge exportInstance={d} />,
      },
      {
        accessor: 'id',
        title: '',
        type: 'string',
        Cell: ({d}) => {
          const disabled =
            d?.exportJobStatus?.status === ExportJobStatusStatus.Failed ||
            // must support old instances that do not have an exportJobStatus object
            d?.status === 'FAILED';
          return (
            <DownloadInstanceButton
              disabled={disabled}
              exportInstance={d}
              organization={organization}
            />
          );
        },
      },
    ],
    [organization]
  );

  return (
    <DataTable
      columns={columns}
      data={exportInstances}
      emptyMessageText='No reports found.'
      searchKeys={['name']}
      searchPlaceholder='Search'
      orderBy='-updatedAt'
    />
  );
}
