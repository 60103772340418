import {isOrgBrand} from '@core/lib/organizations';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import {categorizeCampaignPlacements} from '@analytics/components/campaigns/_helpers';
import {SuperCampaignOmniChart} from '@analytics/components/campaigns/charts';
import {
  SpotifySalesOrderAdsTable,
  createSuperCampaign,
} from '@analytics/components/campaigns/spotify-orders';
import {CampaignStats} from '@analytics/components/campaigns/stats/CampaignStats';
import {SpotifyOrderNav} from '@analytics/components/nav/SpotifyOrderNav';
import UpcomingEpisodesChart from '@analytics/components/upcoming-episodes/UpcomingEpisodesChart';
import {useSpotifySalesOrderQuery} from '@analytics/graphql-api';
import {CampaignsRouteProps} from '../_routes';

export function SpotifyOrder({
  organization,
  match: {
    params: {orderId},
  },
}: CampaignsRouteProps & {
  match: {params: {orderId: string}};
}) {
  const {data, loading, error} = useSpotifySalesOrderQuery({
    variables: {
      organizationId: organization.id,
      orderId,
    },
  });

  if (loading) {
    return <LoadingSpinner fixed />;
  }

  if (error) {
    return <Alert severity='error'>{t('errors.network')}</Alert>;
  }

  const superCampaign = createSuperCampaign(
    data?.me?.organization?.spotifyOrder
  );

  const {
    activeDynamics,
    activeStreamings,
    baselineDynamics,
    baselineStreamings,
    upcomingDynamics,
    upcomingStreamings,
  } = categorizeCampaignPlacements({
    campaignDynamics: superCampaign.campaignDynamics,
    campaignStreamings: superCampaign.campaignStreamings,
    /* super campaigns only contain dynamic or streaming items */
    campaignPodcasts: [],
  });

  const showPlacements =
    activeDynamics.length > 0 || activeStreamings.length > 0;
  const showUpcoming =
    upcomingDynamics.length > 0 || upcomingStreamings.length > 0;
  const showBaseline =
    baselineDynamics.length > 0 || baselineStreamings.length > 0;

  return (
    <>
      <SpotifyOrderNav
        superCampaign={superCampaign}
        organization={organization}
      />
      <CampaignStats campaign={superCampaign} />
      <Card
        css={{
          marginTop: 'var(--spacing-5)',
          [`@media (min-width: 1600px)`]: {
            marginTop: '2.375rem',
          },
        }}>
        <SuperCampaignOmniChart
          superCampaign={superCampaign}
          organization={organization}
        />
      </Card>
      {showPlacements && (
        <Card scrollable>
          <SpotifySalesOrderAdsTable
            superCampaign={superCampaign}
            superCampaignDynamics={activeDynamics}
            superCampaignStreamings={activeStreamings}
            organization={organization}
          />
        </Card>
      )}
      {isOrgBrand(organization) && (
        <Card>
          <CardHeader>Upcoming Episodes</CardHeader>
          <UpcomingEpisodesChart
            campaign={superCampaign}
            organization={organization}
          />
        </Card>
      )}
      {showUpcoming && (
        <Card scrollable>
          <SpotifySalesOrderAdsTable
            headerTitle='Upcoming Ads'
            organization={organization}
            superCampaign={superCampaign}
            superCampaignDynamics={upcomingDynamics}
            superCampaignStreamings={upcomingStreamings}
          />
        </Card>
      )}
      {showBaseline && (
        <Card scrollable>
          <SpotifySalesOrderAdsTable
            headerTitle='Baseline Ads'
            organization={organization}
            superCampaign={superCampaign}
            superCampaignDynamics={baselineDynamics}
            superCampaignStreamings={baselineStreamings}
          />
        </Card>
      )}
    </>
  );
}
