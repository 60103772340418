import dayjs from '@core/lib/dayjs';
import {stripHTML, truncateWords} from '@core/lib/words';
import {Card} from '@core/ui/Content';
import {FeedImage} from '@core/ui/Image';
import {Stack} from '@core/ui/Layout';
import {Link} from '@core/ui/Link';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {RightStat} from '@core/ui/Stat';
import {Text} from '@core/ui/Text';
import {FetchedOrganization, FetchedPodcast} from '@analytics/graphql-api';
import {VerifyProcessingPodcast} from './VerifyProcessingPodcast';

type Props = {
  organization: FetchedOrganization;
  podcast: FetchedPodcast;
};

export function ProcessPodcast({organization, podcast}: Props) {
  if (podcast.processingStartedAt) {
    throw new Error(
      `Invalid podcast: began processing on ${podcast.processingStartedAt}`
    );
  }

  const podcastRecentlyCreated = dayjs()
    .subtract(2, 'days')
    .isBefore(dayjs(podcast.createdAt));

  return (
    <ResponsiveContent containerSize='medium'>
      <Card
        css={{
          display: 'grid',
          gridGap: '1.25rem',
          alignItems: 'start',
          gridTemplateColumns: '6.25rem 1fr max-content',
        }}>
        <FeedImage feed={podcast?.feed} width={100} />
        <div>
          <Text variant='h3'>{podcast.feed?.title}</Text>
          <Text color='muted' variant='body2'>
            {truncateWords(
              stripHTML(podcast.feed?.subtitle || podcast.feed?.summary || ''),
              50
            )}
          </Text>
        </div>
        <RightStat
          value={String(podcast.feed?.episodesCount ?? 0)}
          title='Episodes'
        />
      </Card>
      <Card>
        <Stack direction='column' gap={1}>
          <Text variant='h3'>
            {podcastRecentlyCreated
              ? 'Processing'
              : 'Please add the Analytics Prefix'}
          </Text>
          <Text variant='body1'>
            {podcastRecentlyCreated
              ? `Ad Analytics is currently processing your podcast.
            We are pulling audio and checking recent episodes for ads.`
              : 'We currently have no download data for your podcast. '}{' '}
            If you haven't yet please add the <AnalyticsPrefixLink /> to your
            podcast.
          </Text>
        </Stack>
      </Card>
      <VerifyProcessingPodcast organization={organization} podcast={podcast} />
    </ResponsiveContent>
  );
}

const AnalyticsPrefixLink = () => (
  <Link
    href='https://podsights.com/docs#analytics-prefix'
    rel='noopener noreferrer'
    target='_blank'
    underline='always'>
    Analytics Prefix
  </Link>
);
