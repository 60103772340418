import React, {
  DragEvent,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {exists} from '@core/lib/utils';
import {
  applyDragNodeStyles,
  applySortableNodeStyles,
  findRect,
  sortNodes,
  translate3d,
} from './helpers';

type Props = PropsWithChildren<{
  active?: boolean;
  onSort?: (_ids: string[]) => void;
}>;

export const SortableList = ({
  active = true,
  children,
  onSort,
  ...props
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const containerRect = useRef<DOMRect | null>();
  const sortableNodes = useRef<HTMLElement[]>([]);
  const sortableNodesRects = useRef<DOMRect[]>([]);
  const startY = useRef<number>(0);
  const startX = useRef<number>(0);
  const dragNode = useRef<HTMLElement | null>();
  const dragNodeRect = useRef<DOMRect | null>();
  const [dragging, setDragging] = useState<boolean>(false);

  const handleMouseDown = (evt: MouseEvent<HTMLElement>) => {
    let n = evt.target as HTMLElement | null;
    let draggable = false;

    while (n) {
      if (n.dataset.sortableButton) {
        draggable = true;
      }
      if (draggable && n.dataset.sortableItem) {
        // Keep the node being dragged
        dragNode.current = n;
      }
      n = n.parentElement === ref.current ? null : n.parentElement;
    }

    if (draggable && dragNode.current) {
      startY.current = evt.clientY;
      startX.current = evt.clientX;
      setDragging(true);
    }
  };

  const handleMouseMove = useCallback((evt: any) => {
    if (!dragNode.current || !dragNodeRect.current || !containerRect.current) {
      return;
    }
    const dx = evt.clientX - startX.current;
    const dy = evt.clientY - startY.current;
    const left = dragNodeRect.current.left + dx;
    const top = dragNodeRect.current.top + dy;
    translate3d(dragNode.current, left, top, 1);

    const hoveredRect = findRect(evt.x, evt.y, sortableNodesRects.current);

    if (hoveredRect) {
      const hoveredItem =
        sortableNodes.current[sortableNodesRects.current.indexOf(hoveredRect)];

      if (hoveredItem && hoveredItem !== dragNode.current) {
        sortNodes(
          sortableNodes.current,
          sortableNodes.current.indexOf(dragNode.current),
          sortableNodes.current.indexOf(hoveredItem)
        );

        for (let idx = 0; idx < sortableNodes.current.length; idx++) {
          const node = sortableNodes.current[idx];
          if (node !== dragNode.current) {
            const rect = sortableNodesRects.current[idx];
            translate3d(
              node,
              rect.left - containerRect.current.left,
              rect.top - containerRect.current.top,
              1
            );
          }
        }
      }
    }
  }, []);

  const handleMouseUp = useCallback(
    (evt: any) => {
      evt.preventDefault();
      onSort?.(
        sortableNodes.current.map((node) => node.dataset.id).filter(exists)
      );
      setDragging(false);
    },
    [onSort]
  );

  useEffect(() => {
    if (dragging && dragNode.current && ref.current) {
      // Get sortable nodes
      sortableNodes.current = Array.from(
        ref.current.querySelectorAll('[data-sortable-item]')
      );
      // Get sortable nodes rects
      sortableNodesRects.current = sortableNodes.current.map((node) =>
        node.getBoundingClientRect()
      );
      dragNodeRect.current = dragNode.current.getBoundingClientRect();
      containerRect.current = ref.current.getBoundingClientRect();
      ref.current.style.width = `${containerRect.current.width}px`;
      ref.current.style.height = `${containerRect.current.height}px`;
      applyDragNodeStyles(dragNode.current, true);

      for (let idx = 0; idx < sortableNodes.current.length; idx++) {
        const node = sortableNodes.current[idx];
        const rect = sortableNodesRects.current[idx];
        const isDragNode = dragNode.current === node;
        applySortableNodeStyles({
          isDragNode,
          node,
          rect,
          to: true,
        });
        translate3d(
          node,
          isDragNode ? rect.left : rect.left - containerRect.current.left,
          isDragNode ? rect.top : rect.top - containerRect.current.top,
          1
        );
      }

      window.document.body.style.cursor = 'pointer';
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('contextmenu', handleMouseUp);
    } else {
      if (dragNode.current) {
        applyDragNodeStyles(dragNode.current, false);
        dragNode.current.style.transform = '';
      }
      startY.current = 0;
      startX.current = 0;
      dragNode.current = null;
      containerRect.current = null;
      if (sortableNodes.current.length) {
        sortableNodes.current.forEach((node, idx) => {
          const rect = sortableNodesRects.current[idx];
          applySortableNodeStyles({
            node,
            rect,
            to: false,
          });
          node.style.transform = '';
        });
      }
      if (ref.current) {
        ref.current.style.width = '';
        ref.current.style.height = '';
      }
      sortableNodes.current = [];
      sortableNodesRects.current = [];
    }

    return () => {
      window.document.body.style.cursor = '';
      window.removeEventListener('contextmenu', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, handleMouseMove, handleMouseUp]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={ref}
      onMouseDown={active ? handleMouseDown : undefined}
      onDragStart={(evt: DragEvent) => evt.preventDefault()}
      css={`
        position: relative;
        user-select: none;
        user-drag: none;
      `}
      {...props}>
      {children}
    </div>
  );
};
