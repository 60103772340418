//
// Forms
// --------------------------------------------------
import { controlPaddingBlock, cssSpacing } from "../index.js";
import { cssColorValue, semanticColors } from "../semantic-theme.js";
import { opacityDisabled } from "../variables.js";
import { rootStyle } from "./baseline.js";
import { ballad, mesto } from "./type.js";
import { css } from "../../styled-components.js";

// Shared styles for inputs, textareas, selects, dropdowns
export var formControlBase = function formControlBase(useBrowserDefaultFocusStyle, size) {
  return css(["-webkit-appearance:none;background-image:none;border:0;display:block;", ";transition:box-shadow ease-in-out 0.1s,color ease-in-out 0.1s;inline-size:100%;&::placeholder{opacity:1;}", " &:disabled{cursor:not-allowed;opacity:1;}"], size === 'small' ? mesto() : ballad(), !useBrowserDefaultFocusStyle && css(["&:focus,&:hover:focus{outline:0;}"]));
};

// Shared styles for radios + checkboxes

export var formCheck = function formCheck() {
  return css(["", ";align-items:center;display:flex;padding-block-end:", ";padding-block-start:", ";position:relative;min-block-size:32px;"], rootStyle(), cssSpacing('tighter-4', '4px'), cssSpacing('tighter-4', '4px'));
};
export var formCheckIndicator = function formCheckIndicator(radius) {
  return css(["", ";background:transparent;border-radius:", ";display:inline-block;block-size:16px;position:relative;user-select:none;inline-size:16px;flex-shrink:0;align-self:flex-start;top:0;"], rootStyle(), radius);
};
export var formControlTheme = function formControlTheme(_ref) {
  var _ref$isUsingKeyboard = _ref.isUsingKeyboard,
    isUsingKeyboard = _ref$isUsingKeyboard === void 0 ? true : _ref$isUsingKeyboard,
    isHover = _ref.isHover,
    isFocus = _ref.isFocus,
    useBrowserDefaultFocusStyle = _ref.useBrowserDefaultFocusStyle,
    error = _ref.error;
  var hoverStyles = css(["box-shadow:inset 0 0 0 1px ", ";"], cssColorValue(semanticColors.essentialBase));
  var focusStyles = css(["box-shadow:inset 0 0 0 ", "px ", ";"], isUsingKeyboard ? 3 : 1.5, cssColorValue(semanticColors.essentialBase));
  var errorStyles = css(["&,&:hover{box-shadow:inset 0 0 0 1px ", ";}&:focus{box-shadow:inset 0 0 0 ", "px ", ";}"], cssColorValue(semanticColors.essentialNegative), isUsingKeyboard ? 3 : 1.5, cssColorValue(semanticColors.essentialNegative));
  return css(["background-color:", ";box-shadow:inset 0 0 0 1px ", ";color:", ";&[readonly]{box-shadow:inset 0 0 0 1px ", ";}&:hover,&[readonly]:hover{", "}", " &:focus,&[readonly]:focus{", "}&&{", ";}&::placeholder{color:", ";}&:disabled{opacity:", ";box-shadow:inset 0 0 0 1px ", ";}&:invalid{", ";}", ""], cssColorValue(semanticColors.backgroundBase), cssColorValue(semanticColors.essentialSubdued), cssColorValue(semanticColors.textBase), cssColorValue(semanticColors.decorativeSubdued), hoverStyles, isHover && hoverStyles, !useBrowserDefaultFocusStyle && focusStyles, isFocus && !useBrowserDefaultFocusStyle && focusStyles, cssColorValue(semanticColors.textSubdued), opacityDisabled, cssColorValue(semanticColors.essentialSubdued), error === undefined && errorStyles, error && errorStyles);
};
export var getFormPaddingBlock = function getFormPaddingBlock(size) {
  switch (size) {
    case 'small':
      return cssSpacing('tighter-3', '6px');
    default:
      return cssSpacing('tighter', controlPaddingBlock.large);
  }
};
export var getFormPaddingInline = function getFormPaddingInline(size) {
  switch (size) {
    case 'small':
      return cssSpacing('tighter-2', '8px');
    default:
      return cssSpacing('base', '16px');
  }
};