import {forwardRef, useImperativeHandle} from 'react';
import {ApolloClient} from '@apollo/client';
import {
  CampaignDocument,
  CampaignDynamicDocument,
  CampaignStreamingDocument,
  CreateCampaignDynamicDocument,
  UpdateCampaignDynamicDocument,
  UpdateCampaignStreamingDocument,
} from '@analytics/graphql-api';
import CreateCampaignPodcastMutation from '@analytics/graphql-api/_old_mutations/CreateCampaignPodcast';
import CreateCampaignStreamingMutation from '@analytics/graphql-api/_old_mutations/CreateCampaignStreaming';
import UpdateCampaignPodcastMutation from '@analytics/graphql-api/_old_mutations/UpdateCampaignPodcast';
import CampaignPodcastQuery from '@analytics/graphql-api/_old_queries/CampaignPodcastQuery';

const PARAMS = {
  dynamic: {
    query: CampaignDynamicDocument,
    updateMutation: UpdateCampaignDynamicDocument,
    createMutation: CreateCampaignDynamicDocument,
    adType: 'dynamic',
    propName: 'campaignDynamic',
    attrs: [
      'name',
      'isPreRoll',
      'isMidRoll',
      'isPostRoll',
      'goal',
      'cost',
      'duration',
      'discountCode',
      'discountUrl',
      'effectiveUrl',
      'isBaseline',
      'art19Secret',
      'dynamicType',
      'expectedStartAt',
      'expectedEndAt',
    ],
  },
  podcast: {
    query: CampaignPodcastQuery,
    updateMutation: UpdateCampaignPodcastMutation,
    createMutation: CreateCampaignPodcastMutation,
    adType: 'podcast',
    propName: 'campaignPodcast',
    attrs: [
      'isPreRoll',
      'isMidRoll',
      'isPostRoll',
      'goal',
      'cost',
      'duration',
      'discountCode',
      'discountUrl',
      'effectiveUrl',
    ],
  },
  streaming: {
    query: CampaignStreamingDocument,
    updateMutation: UpdateCampaignStreamingDocument,
    createMutation: CreateCampaignStreamingMutation,
    adType: 'streaming',
    propName: 'campaignStreaming',
    attrs: [
      'name',
      'goal',
      'cost',
      'duration',
      'discountCode',
      'discountUrl',
      'effectiveUrl',
      'isBaseline',
    ],
  },
};

const capitalize = (str) => str[0].toUpperCase().concat(str.slice(1));

export default forwardRef(
  ({client, campaign, organization}: {client: ApolloClient<any>}, ref) => {
    const queryAd = async ({item}) => {
      const {query} = PARAMS[item.adType];

      return await client.query({
        query,
        fetchPolicy: 'network-only',
        variables: {
          organizationId: organization.id,
          campaignId: campaign.id,
          id: item.id,
        },
      });
    };

    const createAd = async ({item, refetch = false} = {}) => {
      const {attrs, createMutation, propName, adType} = PARAMS[item.adType];
      const input = {
        campaignId: campaign.id,
        organizationId: organization.id,
        ...(adType === 'podcast'
          ? {
              organizationId: organization.id,
              podcastId: item.__changes.podcastId,
            }
          : {}),
      };

      attrs.forEach((attrName) => {
        input[attrName] =
          typeof item.__changes[attrName] !== 'undefined'
            ? item.__changes[attrName]
            : attrName === 'expectedStartAt' || attrName === 'expectedEndAt'
            ? null
            : '';
      });

      const {data} = await client.mutate({
        mutation: createMutation,
        variables: {input},
        ...(refetch
          ? {refetchQueries: [CampaignDocument], awaitRefetchQueries: true}
          : {}),
      });

      const accessor = `create${capitalize(propName)}`;
      return data[accessor][propName];
    };

    const updateAd = async ({item, refetch = false} = {}) => {
      const {attrs, updateMutation, propName} = PARAMS[item.adType];
      const input = {id: item.id, organizationId: organization.id};

      attrs.forEach((attrName) => {
        input[attrName] =
          typeof item.__changes[attrName] !== 'undefined'
            ? item.__changes[attrName]
            : attrName === 'expectedStartAt' || attrName === 'expectedEndAt'
            ? null
            : attrName === 'dynamicType'
            ? item[attrName].toLowerCase()
            : item[attrName];
        item[attrName] = input[attrName];
      });

      const {data} = await client.mutate({
        mutation: updateMutation,
        variables: {input},
        ...(refetch
          ? {refetchQueries: [CampaignDocument], awaitRefetchQueries: true}
          : {}),
      });

      const accessor = `update${capitalize(propName)}`;
      return data[accessor][propName];
    };

    const updateAdCache = async ({item, adType}) => {
      const {query, attrs, propName} = PARAMS[adType];
      const q = {
        query,
        variables: {
          organizationId: organization.id,
          campaignId: campaign.id,
          id: item.id,
        },
      };

      q.data = await client.cache.readQuery(q);

      const cachedItem = q.data.me.organization.campaign[propName];

      attrs.forEach((attrName) => {
        cachedItem[attrName] = item[attrName];
      });

      return await client.cache.writeQuery(q);
    };

    const saveEdition = ({edition}) => {
      if (!edition.length) {
        return;
      }

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const createdItems = [];
        const updatedItems = [];

        try {
          for (const item of edition) {
            const {adType} = item;
            if (item.isNew) {
              const createdItem = await createAd({
                item,
                refetch: item === edition[edition.length - 1],
              });
              Object.assign(item, createdItem);
              delete item.isNew;
              createdItems.push(item);
            } else {
              await queryAd({item});
              const updatedItem = await updateAd({
                item,
                refetch: item === edition[edition.length - 1],
              });
              await updateAdCache({
                item: Object.assign({}, item, updatedItem),
                adType,
              });
              updatedItems.push(item);
            }
            // Remove changes
            delete item.__changes;
          }
          resolve({createdItems, updatedItems});
        } catch (err) {
          reject(err);
        }
      });
    };

    useImperativeHandle(ref, () => {
      return {
        saveEdition,
      };
    });

    return null;
  }
);
