import styled from 'styled-components';
import {Stack} from '@core/ui/Layout';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  FetchedSavedExports,
} from '@analytics/graphql-api';
import {reportFailedOrInProgress} from '../helpers';
import {StatusBadge} from '../shared';
import {ReportDownloadButton} from './ReportDownloadButton';
import {ReportMoreButton} from './ReportMoreButton';
import {ReportRerunButton} from './ReportRerunButton';

type Props = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  savedExport: FetchedSavedExports[number];
  disableExport: boolean;
};

export function ReportStatusCell({
  organization,
  savedExport,
  disableExport,
}: Props) {
  const {failed, inProgress} = reportFailedOrInProgress({savedExport});
  return (
    <_Grid>
      <StatusBadge exportInstance={savedExport?.lastExportInstance} />
      <Stack direction='row' spacing={2}>
        <ReportDownloadButton
          disabled={inProgress || failed}
          organization={organization}
          savedExport={savedExport}
        />
        <ReportRerunButton
          disabled={inProgress || disableExport}
          organization={organization}
          savedExport={savedExport}
        />
        <ReportMoreButton
          disabled={inProgress || disableExport}
          organization={organization}
          savedExport={savedExport}
        />
      </Stack>
    </_Grid>
  );
}

const _Grid = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
`;
