import {isOrgBrand, isOrgPodcast} from '@core/lib/organizations';
import {Alert} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Stack} from '@core/ui/Layout';
import {useOverlay} from '@core/ui/Overlay';
import {
  CampaignState,
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {PublisherApproveOverlay} from './PublisherApproveOverlay';
import {PublisherRejectOverlay} from './PublisherRejectOverlay';

export function PublisherApprovalAlert({
  organization,
  campaign,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign: FetchedCampaign;
}) {
  const [openedApprove, toggleApprove] = useOverlay();
  const [openedReject, toggleReject] = useOverlay();

  if (
    !campaign.isCreatedByBrand ||
    (campaign.state !== CampaignState.Draft &&
      campaign.state !== CampaignState.Ready)
  )
    return null;

  if (!campaign.isPublisherApproved) {
    if (campaign.publisherDenyReason) {
      return (
        <Alert severity='error'>
          This campaign has been rejected and will not go live on the scheduled
          start date.
          {isOrgBrand(organization) &&
            ' Please contact the publisher for more details.'}
        </Alert>
      );
    }

    if (isOrgPodcast(organization)) {
      return (
        <>
          <Alert>
            <Stack alignItems='center' gap='var(--spacing-8)'>
              <div>
                This campaign requires review. Approve or reject this campaign
                to ensure it goes live on the scheduled date.
              </div>
              <Stack gap='var(--spacing-2)' justifyContent='flex-end'>
                <Button
                  buttonSize='sm'
                  color='warning'
                  onClick={() => toggleApprove(true)}>
                  Approve
                </Button>
                <Button
                  buttonSize='sm'
                  css={{border: 0}}
                  onClick={() => toggleReject(true)}
                  variant='outlined'>
                  Reject
                </Button>
              </Stack>
            </Stack>
          </Alert>
          {openedApprove && (
            <PublisherApproveOverlay
              campaign={campaign}
              organizationId={organization.id}
              opened={openedApprove}
              toggle={toggleApprove}
            />
          )}
          {openedReject && (
            <PublisherRejectOverlay
              campaign={campaign}
              organizationId={organization.id}
              opened={openedReject}
              toggle={toggleReject}
            />
          )}
        </>
      );
    }

    return (
      <Alert>
        This campaign requires review from the publisher. Please check back to
        ensure it is approved by the scheduled start date.
      </Alert>
    );
  }

  return (
    <Alert severity='success'>
      This campaign has been successfully approved. It will go live on the
      scheduled start date.
    </Alert>
  );
}
