import React, {useRef, useState} from 'react';
import dayjs from '@core/lib/dayjs';
import {Element} from '@core/style';
import {BLUE, GREEN, RED_ORANGE} from '@core/style';
import {t} from '@core/texts';
import DatePicker from '@core/ui/DatePicker';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import {MultipleSelect} from '@core/ui/Select';
import Select from '@core/ui/Select';
import Toggle from '@core/ui/Toggle';
import BulkEpisodesOverlay from './episodes/BulkEpisodesOverlay';

const calculateCPM = ({cost, goal}) => {
  const cpm = (cost / goal) * 1000;
  return !isNaN(cpm) ? parseFloat(cpm.toFixed(2)) : '';
};

const calculateCost = ({cpm, goal}) => {
  const cost = (cpm / 1000) * goal;
  return !isNaN(cost) ? parseFloat(cost.toFixed(2)) : '';
};

const getPropertyValue = ({item, propName, type = 'string'}) => {
  return item.__changes && typeof item.__changes[propName] === type
    ? item.__changes[propName]
    : item[propName];
};

const isBaselineColumn = {
  ...t('bulk.columns.group'),
  name: 'group',
  width: '6rem',
  Component: ({item, updateItemProperty}) => {
    if (item.isNew && !item.__changes) {
      return null;
    }

    const {isBaseline} = item;
    const _isBaseline = getPropertyValue({
      item,
      propName: 'isBaseline',
      type: 'boolean',
    });

    return (
      <Toggle
        checkedColor={BLUE}
        uncheckedColor={GREEN}
        defaultChecked={isBaseline}
        onChecked={(checked) => {
          updateItemProperty({
            item,
            propName: 'isBaseline',
            value: checked,
          });
        }}
        height={12}
        style={{
          fontWeight: 500,
          paddingLeft: '0.125rem',
        }}
        labelStyle={{margin: '0 0 0 0.375rem'}}>
        {_isBaseline ? 'Baseline' : 'Exposed'}
      </Toggle>
    );
  },
};

const commonColumns = [
  {
    ...t('bulk.columns.goal'),
    type: 'number',
    propName: 'goal',
    required: true,
    onChange: ({item, value, refs}) => {
      const cpm = calculateCPM({
        cost: getPropertyValue({item, propName: 'cost', type: 'number'}),
        goal: value,
      });
      refs.cpm.current.value = cpm === Infinity ? '' : cpm.toLocaleString();
    },
  },
  {
    ...t('bulk.columns.cost'),
    type: 'number',
    propName: 'cost',
    required: true,
    onChange: ({item, value, refs}) => {
      const cpm = calculateCPM({
        cost: value,
        goal: getPropertyValue({item, propName: 'goal', type: 'number'}),
      });
      refs.cpm.current.value = cpm === Infinity ? '' : cpm.toLocaleString();
    },
  },
  {
    ...t('bulk.columns.cpm'),
    name: 'cpm',
    type: 'number',
    accessor: (item) => {
      return calculateCPM(item);
    },
    onChange: ({item, value, refs, updateItemProperty}) => {
      const cost = calculateCost({
        cpm: value,
        goal: getPropertyValue({item, propName: 'goal', type: 'number'}),
      });
      refs.cost.current.value = cost.toLocaleString();
      updateItemProperty({item, propName: 'cost', value: parseFloat(cost)});
    },
  },
  {
    ...t('bulk.columns.duration'),
    type: 'number',
    propName: 'duration',
    required: true,
  },
  {
    ...t('bulk.columns.placement'),
    name: 'placement',
    Component: ({item, updateItemProperty, removeEmptyNewItems}) => {
      if (item.isNew && !item.__changes) {
        return null;
      }

      const items = [
        {
          title: 'Pre',
          value: 'isPreRoll',
          selected: getPropertyValue({
            item,
            propName: 'isPreRoll',
            type: 'boolean',
          }),
        },
        {
          title: 'Mid',
          value: 'isMidRoll',
          selected: getPropertyValue({
            item,
            propName: 'isMidRoll',
            type: 'boolean',
          }),
        },
        {
          title: 'Post',
          value: 'isPostRoll',
          selected: getPropertyValue({
            item,
            propName: 'isPostRoll',
            type: 'boolean',
          }),
        },
      ];

      const onSelect = (selectedItems) => {
        const isSelected = (_value) => {
          return !!selectedItems.find(({value}) => value === _value);
        };
        updateItemProperty([
          {item, propName: 'isPreRoll', value: isSelected('isPreRoll')},
          {item, propName: 'isMidRoll', value: isSelected('isMidRoll')},
          {item, propName: 'isPostRoll', value: isSelected('isPostRoll')},
        ]);
        if (!selectedItems.length) {
          removeEmptyNewItems();
        }
      };

      return (
        <MultipleSelect
          name='placement'
          items={items}
          placeholder='Select...'
          getSelectLabel={(selectedItems) => {
            return items
              .filter(({value}) =>
                selectedItems.find(({value: _v}) => _v === value)
              )
              .map(({title}) => title)
              .join(', ');
          }}
          onSelect={onSelect}
          noChevronIcon
          rules={() => ({
            background: 'none',
            border: 0,
            outline: 0,
            padding: '0 0.25rem',
          })}
        />
      );
    },
  },
  {
    ...t('bulk.columns.discountCode'),
    type: 'string',
    propName: 'discountCode',
    selectOnFocus: true,
  },
  {
    ...t('bulk.columns.discountUrl'),
    type: 'string',
    propName: 'discountUrl',
    selectOnFocus: true,
  },
  {
    ...t('bulk.columns.effectiveUrl'),
    type: 'string',
    propName: 'effectiveUrl',
    validate: ({value}) => {
      if (!value) {
        return true;
      }
      try {
        const url = new URL(`https://${value.replace(/^https?\:\/\//i, '')}`);
        if (url.pathname.length > 1 || url.search) {
          return true;
        }
      } catch (e) {}
      return false;
    },
    validateErrorText: 'The URL must contain a path or query params.',
    selectOnFocus: true,
  },
];

const dynamicColumns = [
  isBaselineColumn,
  {
    ...t('bulk.columns.dynamicAdName'),
    type: 'string',
    propName: 'name',
    required: true,
    placeholder: 'Name',
    width: '2fr',
    multipleLine: true,
  },
  ...commonColumns,
  {
    ...t('bulk.columns.art19Secret'),
    type: 'string',
    propName: 'art19Secret',
    validate: ({value}) => {
      if (!value) {
        return true;
      }
      return value.length === 86;
    },
  },
  {
    title: 'Ad Type',
    type: 'string',
    propName: 'dynamicType',
    Component: ({item, updateItemProperty, isValid}) => {
      if (item.isNew && !item.__changes) {
        return null;
      }

      const onSelect = (selectedItem) =>
        updateItemProperty({
          item,
          propName: 'dynamicType',
          value: selectedItem.value || 'na',
        });

      const defaultValue = getPropertyValue({
        item,
        propName: 'dynamicType',
        type: 'string',
      });

      return (
        <Select
          name='adType'
          defaultValue={defaultValue}
          items={[
            {title: 'Host Read', value: 'host_read'},
            {title: 'Produced', value: 'produced'},
            {title: 'N/A', value: 'na'},
          ]}
          noChevronIcon
          onSelect={onSelect}
          placeholder='Select...'
          rules={() => ({
            background: 'none',
            border: '1px solid transparent',
            borderRadius: '0.1875rem',
            display: 'block',
            height: '2rem',
            outline: 0,
            padding: '0.2rem 0.25rem',
            ...(!isValid && {
              borderColor: RED_ORANGE,
            }),
          })}
        />
      );
    },
  },
  {
    title: 'Start / End',
    width: '1.75fr',
    validateErrorText: 'Must include end date.',
    validate: ({item}) => {
      if (item?.__changes) {
        if (item.__changes.expectedStartAt && !item.__changes.expectedEndAt)
          return false;
      }
      return true;
    },
    Component: ({item, updateItemProperty, isValid}) => {
      if (item.isNew && !item.__changes) {
        return null;
      }

      const inputRef = useRef();
      const [opened, toggle] = useOverlay();
      const [selectedDates, setSelectedDates] = useState(() => {
        const start = getPropertyValue({
          item,
          propName: 'expectedStartAt',
        });

        const end = getPropertyValue({
          item,
          propName: 'expectedEndAt',
        });

        return start && end ? [dayjs(start), dayjs(end)] : [];
      });

      const handleDatesChange = (dates) => {
        setSelectedDates(dates);
        updateItemProperty([
          {
            item,
            propName: 'expectedStartAt',
            value: dates[0]?.toDate().toISOString() || null,
          },
          {
            item,
            propName: 'expectedEndAt',
            value: dates[1]?.toDate().toISOString() || null,
          },
        ]);
      };

      return (
        <>
          <Element
            domRef={inputRef}
            onClick={() => toggle(true)}
            rules={({theme}) => ({
              alignItems: 'center',
              color: selectedDates.length
                ? theme.textPrimary
                : theme.textTertiary,
              border: '1px solid transparent',
              borderRadius: '0.1875rem',
              cursor: 'pointer',
              display: 'flex',
              fontWeight: selectedDates.length ? 500 : 'inherit',
              height: '2rem',
              minWidth: '7.8125rem',
              padding: '0.2rem 0.25rem',
              textAlign: 'left',
              ':hover': {
                color: BLUE,
              },
              ...(!isValid && {
                borderColor: RED_ORANGE,
              }),
            })}>
            {selectedDates.length > 0 ? (
              <>
                {selectedDates?.[0]?.format('MM/DD/YY') || null} -{' '}
                {selectedDates?.[1]?.format('MM/DD/YY') || null}
              </>
            ) : (
              'Select...'
            )}
          </Element>
          {opened && (
            <Overlay
              opened={opened}
              toggle={toggle}
              positionTarget={inputRef.current}
              withShadow
              withBackdrop
              transparentBackdrop
              css={`
                max-width: initial;
                padding: 1rem;
              `}>
              <DatePicker
                isOutsideRange={() => false}
                onDatesChange={handleDatesChange}
                defaultDates={selectedDates}
                twoMonthsView
                extraControls={[
                  {
                    title: 'Clear selection',
                    callback: () => {
                      handleDatesChange([]);
                      toggle(false);
                    },
                  },
                ]}
              />
            </Overlay>
          )}
        </>
      );
    },
  },
];

const embeddedColumns = [
  {
    ...t('bulk.columns.podcast'),
    Component: ({
      campaign,
      item,
      podcasts,
      removeEmptyNewItems,
      updateItemProperty,
    }) => {
      if (item.isNew) {
        const _podcasts = podcasts
          .filter((podcast) => {
            if (campaign.campaignPodcasts.length) {
              return !campaign.campaignPodcasts.find(({feed}) => {
                return feed && feed.id === podcast.feed.id;
              });
            }
            return true;
          })
          .map((podcast) => {
            const _podcast = {...podcast};
            _podcast.title = podcast.feed.title;
            return _podcast;
          });

        if (_podcasts.length) {
          return (
            <Element
              rules={() => ({
                alignItems: 'center',
                display: 'flex',
                padding: '0 .25rem',
                width: '100%',
              })}>
              <Select
                items={_podcasts}
                placeholder='Select Podcast'
                removeOption
                searchable
                searchKeys={['title']}
                small
                outline
                horizontalAlign='right'
                propertyForValue='id'
                onSelect={(podcast) => {
                  updateItemProperty({
                    item,
                    propName: 'podcastId',
                    value: podcast ? podcast.id : undefined,
                  });
                  if (!podcast) {
                    removeEmptyNewItems();
                  }
                }}
                rules={({theme}) => ({
                  borderRadius: '0.1875rem',
                  border: !!item.__changes
                    ? 0
                    : `1px solid ${theme.inputBorder}`,
                })}
              />
            </Element>
          );
        }
      } else if (item.feed) {
        return (
          // TODO: Make this editable, by default it should be the feed.title,
          // but people want to edit it.
          <Element rules={() => ({padding: '0.2rem 0.25rem'})}>
            {item.feed.title}
          </Element>
        );
      }
      return null;
    },
    width: '2fr',
  },
  {
    ...t('bulk.columns.episodes'),
    type: 'number',
    propName: 'campaignEpisodesCount',
    Component: ({item, organization, campaign, refs}) => {
      if (item.isNew) {
        return null;
      }

      const [opened, toggle] = useOverlay();

      return (
        <>
          <Element
            rules={() => ({
              alignItems: 'center',
              display: 'flex',
              padding: '0.2rem 0.25rem',
            })}>
            <Element rules={() => ({minWidth: '1.25rem'})}>
              {item.campaignEpisodesCount}
            </Element>
            <Element
              tag='a'
              rules={({theme}) => ({
                background: theme.bgTertiary,
                borderRadius: '0.25rem',
                color: theme.textTertiary,
                cursor: 'pointer',
                marginLeft: '0.625rem',
                padding: '0.25rem 0.375rem',
                textDecoration: 'none',
                ':hover': {color: BLUE},
              })}
              onClick={(evt) => {
                evt.preventDefault();
                toggle(true);
              }}>
              Edit
            </Element>
          </Element>
          {opened ? (
            <BulkEpisodesOverlay
              opened={opened}
              toggle={toggle}
              organization={organization}
              campaign={campaign}
              campaignPodcast={item}
              onEditionFinish={({episodesCount}) => {
                item.campaignEpisodesCount = episodesCount;
              }}
            />
          ) : null}
        </>
      );
    },
  },
  ...commonColumns,
];

const streamingColumns = [
  isBaselineColumn,
  {
    ...t('bulk.columns.streamingAdName'),
    type: 'string',
    propName: 'name',
    required: true,
    placeholder: 'Name',
    width: '2fr',
    multipleLine: true,
  },
  ...commonColumns.slice(0, 4).concat(commonColumns.slice(5)),
];

export {dynamicColumns, embeddedColumns, streamingColumns};
