import {useQuery} from '@apollo/client';
import {Helmet} from 'react-helmet-async';
import {Switch, useParams} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import FeedQuery from '@analytics/graphql-api/_old_queries/research/FeedQuery';
import Feed from './Feed';
import FeedAds from './FeedAds';
import FeedCompanies from './FeedCompanies';
import FeedExport from './FeedExport';
import FeedMeasure from './FeedMeasure';
import FeedProcess from './FeedProcess';
import FeedShare from './FeedShare';
import EpisodesIndex from './episodes';

export default function FeedIndex({user, organization}) {
  const {feedId} = useParams<{feedId: string}>();
  const {data, loading, error} = useQuery(FeedQuery, {
    variables: {organizationId: organization.id, id: feedId},
  });

  if (loading || error || !data) {
    return <Loading fixed />;
  }

  const {feed} = data.me.organization.research;

  return (
    <>
      <Helmet>
        <title>{feed.title} | Ad Analytics Research</title>
      </Helmet>
      <Switch>
        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/research/podcasts/:feedId'
          user={user}
          organization={organization}
          feed={feed}
          component={Feed}
        />
        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/research/podcasts/:feedId/ads'
          user={user}
          organization={organization}
          feed={feed}
          component={FeedAds}
        />
        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/research/podcasts/:feedId/brands'
          user={user}
          organization={organization}
          feed={feed}
          component={FeedCompanies}
        />

        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/research/podcasts/:feedId/measure'
          user={user}
          organization={organization}
          feed={feed}
          component={FeedMeasure}
        />
        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/research/podcasts/:feedId/export'
          user={user}
          organization={organization}
          feed={feed}
          component={FeedExport}
        />

        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/research/podcasts/:feedId/share'
          user={user}
          organization={organization}
          feed={feed}
          component={FeedShare}
        />

        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/research/podcasts/:feedId/process'
          user={user}
          organization={organization}
          feed={feed}
          component={FeedProcess}
        />

        <PropsRoute
          path='/:part(a|s|b|p)/:slug/research/podcasts/:feedId/episodes'
          user={user}
          organization={organization}
          feed={feed}
          component={EpisodesIndex}
        />
      </Switch>
    </>
  );
}
