/**
 * @fileOverview Z Axis
 */

export var ZAxis = function ZAxis() {
  return null;
};
ZAxis.displayName = 'ZAxis';
ZAxis.defaultProps = {
  zAxisId: 0,
  range: [64, 64],
  scale: 'auto',
  type: 'number'
};