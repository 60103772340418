import {Card, CardHeader} from '@core/ui/Content';
import {AttributionUrls} from '@analytics/components/attribution/AttributionUrls';
import {CampaignPixelRouteProps} from '../_routes';

export function CampaignUrlsIndex({
  organization,
  campaign,
  pixel,
}: CampaignPixelRouteProps) {
  return (
    <Card>
      <CardHeader>Landing Pages</CardHeader>
      <AttributionUrls
        organization={organization}
        campaign={campaign}
        pixel={pixel}
        limit={250}
      />
    </Card>
  );
}
