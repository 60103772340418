import {
  Container as MuiContainer,
  type ContainerProps as MuiContainerProps,
} from '@mui/material';

export type ContainerProps = Pick<
  MuiContainerProps,
  'disableGutters' | 'fixed' | 'maxWidth' | 'children'
>;

export function Container(props: ContainerProps) {
  return <MuiContainer {...props} />;
}
