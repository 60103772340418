import {useMemo, useState} from 'react';
import styled from 'styled-components';
import {CustomRangeOverlay} from '@core/components/CustomRangeOverlay';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {LoadingSpinner} from '@core/ui/Loading';
import {useOverlay} from '@core/ui/Overlay';
import {
  DATES_BY_RANGE,
  UsageChart,
  UsageDateRange,
  UsageInterval,
  UsageIntervalSelect,
  UsageRange,
  UsageRangeSelect,
  UsageTable,
  UsageTableGroupInput,
  defaultGroupBy,
  parseUsageData,
} from '@analytics/components/usage';
import {useMultiBillingRecordsQuery} from '@analytics/graphql-api';
import {ManageRouteProps} from '../_routes';

export function Usage({organization}: ManageRouteProps) {
  const [groupBy, setGroupBy] = useState(defaultGroupBy(organization));
  const [interval, setInterval] = useState<UsageInterval>('monthly');
  const [range, setRange] = useState<UsageRange>('year');
  const [reset, setReset] = useState(0);
  const [dateRange, setDateRange] = useState<UsageDateRange>(
    DATES_BY_RANGE[range]
  );
  const [openedCustomRange, toggleCustomRange] = useOverlay();

  const {data, loading, error} = useMultiBillingRecordsQuery({
    variables: {
      organizationId: organization.id,
      after: dateRange[0].format('YYYY-MM-DD'),
      before: dateRange[1].format('YYYY-MM-DD'),
    },
  });

  const {chartData, tableData} = useMemo(
    () =>
      parseUsageData({
        data: data?.me?.organization?.multiBillingRecords,
        dateRange,
        interval,
      }),
    [data, dateRange, interval]
  );

  const onSelectRange = (value: UsageRange) => {
    if (value === 'custom') toggleCustomRange(true);
    else {
      setRange(value);
      setDateRange(DATES_BY_RANGE[value]);
    }
  };

  return (
    <>
      <Alert
        severity='info'
        css={{margin: '0 var(--spacing-6) var(--spacing-6)'}}>
        This is a legacy feature and will no longer be actively supported.
      </Alert>
      <Card>
        <CardHeader>Usage Summary</CardHeader>
        <Stack gap='var(--spacing-4)' css={{padding: '0 0 var(--spacing-5)'}}>
          <UsageRangeSelect
            key={`${range}-${reset}`}
            defaultValue={range}
            onSelect={onSelectRange}
          />
          <UsageIntervalSelect defaultValue={interval} onSelect={setInterval} />
        </Stack>
        {loading ? (
          <LoadingSpinner centered />
        ) : error ? (
          <Alert severity='error'>{t('errors.network')}</Alert>
        ) : (
          <UsageChart data={chartData} interval={interval} />
        )}
      </Card>
      <Card>
        <CardHeader>Impressions Breakdown</CardHeader>
        <Stack gap='var(--spacing-4)' css={{padding: 'var(--spacing-5)'}}>
          <UsageRangeSelect
            key={`${range}-${reset + 1}`}
            defaultValue={range}
            onSelect={onSelectRange}
          />
          <UsageTableGroupInput
            defaultValue={groupBy}
            onChange={setGroupBy}
            organization={organization}
          />
        </Stack>
        <_Separator />
        {loading ? (
          <LoadingSpinner centered />
        ) : error ? (
          <Alert severity='error'>{t('errors.network')}</Alert>
        ) : (
          <UsageTable data={tableData} groupBy={groupBy} />
        )}
      </Card>
      {openedCustomRange && (
        <CustomRangeOverlay
          centered
          closeOnOutsideClick={false}
          datePickerProps={{defaultDates: dateRange}}
          opened={openedCustomRange}
          onCancel={() => setReset(reset + 1)}
          onSave={(dates) => {
            setDateRange(dates);
            setRange('custom');
          }}
          toggle={toggleCustomRange}
          withBackdrop
        />
      )}
    </>
  );
}

const _Separator = styled.div`
  background: var(--border-default);
  height: 1px;
`;
