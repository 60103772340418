import {useEffect, useRef, useState} from 'react';
import {HexColorInput, HexColorPicker} from 'react-colorful';
import {Overlay, useOverlay} from '../Overlay';

export interface ColorPickerProps {
  defaultColor?: string;
  onChange?: (_color: string) => void;
}

export default function ColorPicker({
  defaultColor,
  onChange,
}: ColorPickerProps) {
  const inputRef = useRef<HTMLButtonElement>(null);
  const [color, setColor] = useState(defaultColor);
  const [opened, toggle] = useOverlay();

  useEffect(() => {
    color && onChange?.(color);
  }, [color, onChange]);

  return (
    <>
      <button
        ref={inputRef}
        tabIndex={0}
        aria-label='Color Picker'
        css={`
          background: ${color};
          border-radius: 0.5rem;
          box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.33);
          cursor: pointer;
          flex: 1;
          height: 2.375rem;
          transform: ${opened ? 'scale(1.12)' : ''};
          transition: transform 0.1s linear;
          width: 2.375rem;
          :hover {
            transform: scale(1.12);
          }
          :focus-visible {
            box-shadow: inset 0 0 0 2px var(--white);
            outline: 2px solid var(--primary);
          }
        `}
        onClick={() => toggle(true)}
      />
      <Overlay
        opened={opened}
        toggle={toggle}
        positionTarget={inputRef.current}
        horizontalAlign='right'
        verticalOffset={4}
        withShadow
        withBackdrop
        transparentBackdrop
        css={`
          padding: 1rem;
          width: 14.625rem;
        `}>
        <HexColorPicker color={color} onChange={setColor} />
        <div
          css={`
            position: relative;
          `}>
          <div
            css={`
              align-items: center;
              background: var(--bg-muted);
              border-radius: 0.5rem 0 0 0.5rem;
              border-right: 1px solid var(--border-default);
              bottom: 0.0625rem;
              color: var(--text-muted);
              display: flex;
              left: 0.0625rem;
              padding: 0 0.625rem;
              position: absolute;
              top: 0.0625rem;
            `}>
            #
          </div>
          <div
            css={`
              & input {
                background: var(--white);
                border: 1px solid var(--border-default);
                border-radius: 0.5rem;
                color: inherit;
                display: block;
                line-height: 1.5;
                margin-top: 1rem;
                outline: 0;
                padding: 0.5rem 0.625rem 0.5rem 2.375rem;
                width: 100%;
              }
              & input:focus {
                border-color: var(--blue);
                box-shadow: 0 0 0 1px var(--blue);
              }
            `}>
            <HexColorInput color={color} onChange={setColor} />
          </div>
        </div>
      </Overlay>
    </>
  );
}
