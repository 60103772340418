import {gql} from '@apollo/client';
import AssetFragment from '../_old_queries/fragments/AssetFragment';
import PodlinkDomainFragment from '../_old_queries/fragments/PodlinkDomainFragment';

export default gql`
  mutation designPodlinkDomain($input: DesignPodlinkDomainInput!) {
    authed {
      success
    }
    designPodlinkDomain(input: $input) {
      podlinkDomain {
        ...PodlinkDomain
        backgroundColor
        fontColor
        primaryColor
        secondaryColor
        image {
          ...Asset
        }
        favicon {
          ...Asset
        }
      }
    }
  }
  ${PodlinkDomainFragment}
  ${AssetFragment}
`;
