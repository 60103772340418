import {gql} from '@apollo/client';

export interface ICampaignEpisode {
  futureEpisode: {
    id: string;
    expectedPublished: string;
  };
  goal: number;
  campaignPodcast: {
    id: string;
    campaign: {
      id: string;
      name: string;
      slug: string;
    };
    feed: {
      id: string;
      title: string;
      image: {
        id: string;
        href: string;
        path: string;
      };
    };
  };
}

export interface IOrganizationCampaignEpisodes {
  me: {
    id: string;
    organization: {
      id: string;
      campaignEpisodes: ICampaignEpisode[];
    };
  };
}

export interface IOrganizationCampaignEpisodesInput {
  after: string;
  before: string;
  organizationId: string;
}

export default gql`
  query OrganizationCampaignEpisodesQuery(
    $organizationId: ID!
    $after: String
    $before: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaignEpisodes(isFuture: true, after: $after, before: $before) {
          futureEpisode {
            id
            expectedPublished
          }
          goal
          campaignPodcast {
            id
            campaign {
              id
              name
              slug
            }
            feed {
              id
              title
              image {
                id
                href
                path
              }
            }
          }
        }
      }
    }
  }
`;
