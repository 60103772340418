import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {Nav} from '@core/ui/Nav';
import {measureInsightsRoutes} from '@analytics/lib/routes/measureRoutes';
import {MeasureInsightsRouteProps} from '@analytics/screens/unified/measure/insights';

export function MeasureInsightsNav({
  organization,
}: Pick<MeasureInsightsRouteProps, 'organization'>) {
  const {routes} = useRoutes({
    baseUrl: createOrgPath(organization, '/measure/overview'),
    basePath: '/:part(b|a|s|p)/:slug/measure/overview',
    routes: measureInsightsRoutes(),
  });

  return <Nav title='Overview' items={routes} />;
}
