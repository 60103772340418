import {gql} from '@apollo/client';

export default gql`
  mutation updateActionMap($input: UpdateActionMapInput!) {
    authed {
      success
    }
    updateActionMap(input: $input) {
      actionMap {
        id
        eventName
        action
      }
    }
  }
`;
