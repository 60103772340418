import {Card} from '@core/ui/Content';
import { CardTitle, Text, IconTime } from '@spotify-internal/encore-web';

export const RequestedPixel = ({
    organization,
    pixel
  }) => {

return (
    <Card id='none' title='none'>
        <CardTitle variant='balladBold'css={`font-size: 24px`}>
            Your request has been submitted  
            <IconTime style={{marginLeft: '8px'}}/>
        </CardTitle>
        <Text key='textSubdued' as="p" variant='bodyMedium' semanticColor='textSubdued' paddingBottom='1.5em'>
            An email will be sent once your pixel is available on Ad Studio within 1-2 business days. If you have questions, contact us at adstudio@spotify.com.
        </Text>
        <Text as="p" variant="bodySmallBold" paddingBottom="0.5em">
            Pixel ID
        </Text>
        <Text as="p" variant="bodySmall" paddingBottom='1.5em'>
            {pixel.key}
        </Text>
        <Text as="p" variant="bodySmallBold" paddingBottom="0.5em">
            Ad Studio account number
        </Text>
        <Text as="p" variant="bodySmall" paddingBottom="0.75em">
            {organization.adStudioBusinessId}
        </Text>
    </Card>
)};