import {useEffect, useState} from 'react';
import {comma} from '@core/lib/filters';
import {ButtonIcon} from '@core/ui/Button';
import {GroupedItem} from '../_types';
import {CellGrid, TableCell, TableRow} from './_shared';

export function UsageTableRow({
  depth = 0,
  expandAll,
  parentCollapsed = false,
  rowData,
}: {
  depth?: number;
  expandAll: boolean;
  parentCollapsed?: boolean;
  rowData: GroupedItem;
}) {
  const [expanded, setExpanded] = useState(expandAll);

  useEffect(() => {
    if (expanded !== expandAll) {
      setExpanded(expandAll);
    }
  }, [expandAll]);

  if (rowData.children) {
    return (
      <>
        <TableRow
          depth={depth}
          expandable
          parentCollapsed={parentCollapsed}
          onClick={() => setExpanded(!expanded)}>
          <TableCell depth={depth}>
            <CellGrid>
              <ButtonIcon
                icon='arrow-right'
                css={{
                  height: '1.5rem',
                  marginRight: 'var(--spacing-2)',
                  transform: expanded ? 'rotate(-90deg)' : 'rotate(90deg)',
                  transition: 'all 0.2s',
                  width: '1.5rem',
                }}
              />
              {rowData.name}
            </CellGrid>
          </TableCell>
          <TableCell isImpressions>
            <div>{comma(rowData.impressions)}</div>
          </TableCell>
        </TableRow>
        {rowData.children.map((c) => (
          <UsageTableRow
            key={c.id}
            depth={depth + 1}
            expandAll={expandAll}
            parentCollapsed={!expanded || parentCollapsed}
            rowData={c}
          />
        ))}
      </>
    );
  }

  return (
    <TableRow key={rowData.name} parentCollapsed={parentCollapsed}>
      <TableCell depth={depth}>
        <div>{rowData.name}</div>
      </TableCell>
      <TableCell isImpressions>
        <div>{comma(rowData.impressions)}</div>
      </TableCell>
    </TableRow>
  );
}
