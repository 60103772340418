import {useReducer} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {Element, GREEN} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {Form, Input, Label} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import AddArt19SecretMutation from '@analytics/graphql-api/_old_mutations/AddArt19Secret';
import OrganizationArt19Query from '@analytics/graphql-api/_old_queries/OrganizationArt19Query';

const AddArt19Secret = ({organization, art19Secret, addArt19Secret}) => {
  const [state, setState] = useReducer(
    (state, newState) => {
      return {...state, ...newState};
    },
    {
      errors: [],
      complete: false,
      loading: false,
      changed: null,
      secret: art19Secret || '',
    }
  );

  const onSecretChange = ({target: {value}}) => {
    setState({secret: value, errors: [], changed: true});
  };

  const onSubmit = () => {
    if (!state.secret || state.secret.indexOf('*') > -1) {
      return;
    }

    setState({errors: [], loading: true});

    addArt19Secret({
      secret: state.secret.trim(),
      contentType: 'organizations.organization',
      pk: organization.id,
    })
      .then(
        ({
          data: {
            addArt19Secret: {art19Secret},
          },
        }) => {
          setState({
            changed: false,
            complete: true,
            loading: false,
            secret: art19Secret,
          });
        }
      )
      .catch((resp) => {
        setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  return (
    <ResponsiveContent containerSize='medium'>
      {state.complete ? (
        <Card
          rules={() => ({background: GREEN, color: '#fff', fontWeight: 500})}>
          Successfully updated the Art19 Encryption Key
        </Card>
      ) : (
        ''
      )}

      <Card>
        <CardHeader>Add Art19 Encryption Key</CardHeader>

        <p>
          If you are using Art19 then we need your Encryption key. The
          Encryption key allows Ad Analytics to decrypt the IPs that are sent to
          us via the Tracking URL.
        </p>
        <p>
          You will need to reach out to Art19 to get a Data Processing Agreement
          in place.
        </p>

        <Form
          loading={state.loading}
          errors={state.errors}
          onSubmit={onSubmit}
          onClose={() => {
            setState({errors: []});
          }}>
          <Label>Art19 Encryption Key</Label>
          <Input
            type='text'
            name='secret'
            required
            value={state.secret}
            onChange={onSecretChange}
            placeholder="It's a really long string that you get from Art19"
            disabled={state.loading}
          />

          <Element rules={() => ({marginTop: '1.5rem', textAlign: 'right'})}>
            <Submit disabled={state.loading || !state.changed}>
              {addArt19Secret ? 'Update' : 'Add'} Encryption Key
            </Submit>
          </Element>
        </Form>
      </Card>
    </ResponsiveContent>
  );
};

export default graphql(AddArt19SecretMutation, {
  props: ({ownProps: {organization}, mutate}) => ({
    addArt19Secret: (input) => {
      return mutate({
        variables: {
          input,
        },
        update: (
          store,
          {
            data: {
              addArt19Secret: {art19Secret},
            },
          }
        ) => {
          // Stuff the new journey into the cache.
          const query = {
            query: OrganizationArt19Query,
            variables: {
              id: organization.id,
            },
          };

          query.data = store.readQuery(query);
          query.data.me.organization.art19Secret = art19Secret;
          store.writeQuery(query);
        },
      });
    },
  }),
})(AddArt19Secret);
