import assert from 'assert';
import {exists} from './utils';

export const cleanInt = (value: any) => {
  if (typeof value === 'string') {
    return value ? parseInt(value.trim().replace(/(,|\$)/g, '')) : value;
  }

  return value;
};

export const cleanFloat = (value: any) => {
  if (typeof value === 'string') {
    return value ? parseFloat(value.trim().replace(/(,|\$)/g, '')) : value;
  }

  return value;
};

/** Clean API response data that includes lists, which generally are of the type:
 *  type List<Item> = [Item | null] | null
 *  This function removes the nulls from the list.
 */
export function cleanList<T extends NonNullable<object>>(
  xs:
    | (T | null | undefined)[]
    | ReadonlyArray<T | null | undefined>
    | null
    | undefined
): ReadonlyArray<T> {
  if (!exists(xs)) return [] as readonly T[];
  return xs.filter(exists) as ReadonlyArray<T>;
}

assert(
  cleanList([
    null,
    {
      id: '123410091090923',
      __typename: 'CampaignAuditLogEventObject',
      userLastInitial: 'F',
      action: 'deleted',
      campaignOrLineItemName:
        'Bill Simmons_US_DAI_ROS_Host Read_ Mid-Roll_Flight 1',
      dateTimeChanged: '2023-01-06T19:28:44.705116+00:00',
      fieldName: null,
      newVal: null,
      oldVal: null,
      userFirstName: 'Farhana',
    },
    {
      id: '123412341123',
      __typename: 'CampaignAuditLogEventObject',
      userLastInitial: 'F',
      action: 'deleted',
      campaignOrLineItemName:
        'Bill Simmons_US_DAI_ROS_Host Read_Pre-Roll_Flight 1',
      dateTimeChanged: '2023-01-06T19:28:52.539381+00:00',
      fieldName: null,
      newVal: null,
      oldVal: null,
      userFirstName: 'Farhana',
    },
  ]).length === 2,
  'removes first null'
);

assert(
  Array.isArray(cleanList(null)) && cleanList(null).length === 0,
  'defaults to empty array'
);

assert(
  Array.isArray(cleanList([undefined])) && cleanList([undefined]).length === 0,
  'removes undefined from array'
);

assert(
  cleanList([
    {
      id: '123412341123',
      __typename: 'CampaignAuditLogEventObject',
      userLastInitial: 'F',
      action: 'deleted',
      campaignOrLineItemName:
        'Bill Simmons_US_DAI_ROS_Host Read_Pre-Roll_Flight 1',
      dateTimeChanged: '2023-01-06T19:28:52.539381+00:00',
      fieldName: null,
      newVal: null,
      oldVal: null,
      userFirstName: 'Farhana',
    },
  ]).length === 1,
  'no change to truthy array values'
);
