import {LegendProps} from 'recharts';
import {toPercent} from '@core/lib/filters';
import {Stack} from '@core/ui/Layout';
import {DEFAULT_LABEL_SIZE} from '../helpers';
import {PieLegenIcon} from '../shared';
import {PieLiftData} from '../types';

export function BrandLiftPieChartLegend({
  payload,
}: {
  payload?: {
    color?: string;
    payload: PieLiftData & {
      percent: number;
    };
  }[];
} & LegendProps) {
  return (
    <Stack
      direction='column'
      gap='var(--spacing-2)'
      css={{fontSize: DEFAULT_LABEL_SIZE}}>
      {payload?.map(
        ({color, payload}) =>
          payload?.group && (
            <Stack
              key={payload?.group}
              alignItems='center'
              gap='var(--spacing-1)'>
              <PieLegenIcon fill={color} />
              <div css={{fontWeight: 'var(--font-weight-bold)'}}>
                {toPercent(payload?.percent ?? 0)}
              </div>
              <div>{payload?.group}</div>
            </Stack>
          )
      )}
    </Stack>
  );
}
