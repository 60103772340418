import Nav from '@core/ui/Nav';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {getLiftRoutes} from '@analytics/screens/unified/measure/lift/_routes';
import {MeasureIncrementalLiftRouteProps} from '@analytics/screens/unified/measure/lift/incremental/_routes';
import {MeasureSpotifyLiftRouteProps} from '@analytics/screens/unified/measure/lift/spotify/report/_routes';

export function LiftNav({
  organization,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) {
  const {routes} = getLiftRoutes({organization});

  return <Nav title='Lift Reports' items={routes} />;
}

export function withLiftNav(
  Component: React.ComponentType<
    MeasureSpotifyLiftRouteProps | MeasureIncrementalLiftRouteProps
  >
) {
  return ({
    organization,
    ...props
  }: MeasureSpotifyLiftRouteProps | MeasureIncrementalLiftRouteProps) => (
    <>
      <LiftNav organization={organization} />
      <Component organization={organization} {...props} />
    </>
  );
}
