import ErrorBase from './ErrorBase';

const MaintenanceError = () => {
  return (
    <ErrorBase
      title='Unfortunately, the site is down for a bit of maintenance right now.'
      baseColor='var(--green)'>
      <p>
        We will be back soon. Please reach out to{' '}
        <a
          style={{color: 'var(--green)'}}
          href='mailto:support-adanalytics@spotify.com'>
          support-adanalytics@spotify.com
        </a>{' '}
        with any questions.
      </p>
    </ErrorBase>
  );
};

export default MaintenanceError;
