import {gql} from '@apollo/client';
import PodlinkDomainFragment from '../_old_queries/fragments/PodlinkDomainFragment';

export default gql`
  mutation createPodlinkDomain($input: CreatePodlinkDomainInput!) {
    authed {
      success
    }
    createPodlinkDomain(input: $input) {
      podlinkDomain {
        ...PodlinkDomain
      }
    }
  }
  ${PodlinkDomainFragment}
`;
