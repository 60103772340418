import {Label} from 'recharts';
import MultipleDataSelector from '@core/ui/MultipleDataSelector';
import {GetAdvisorQuizRadioMarketsDocument} from '@analytics/graphql-api';
import {DropdownItem, Question} from '../../shared';
import {getAdvisorColumnData} from '../helpers';
import {IQuestionProps} from '../types';

export const QuestionSpecificMarkets = ({
  updateQuizData,
  organization,
}: IQuestionProps) => (
  <Question>
    <Label>Which markets?</Label>
    <MultipleDataSelector
      onChange={(items) => updateQuizData('radioMarkets', items)}
      dataQuery={GetAdvisorQuizRadioMarketsDocument}
      renderItem={(item) => <DropdownItem label={item.title} />}
      placeholder='Search markets'
      queryVariables={{orgId: organization.id}}
      propertyForName='title'
      fromResponse={getAdvisorColumnData('radioMarkets')}
    />
  </Question>
);
