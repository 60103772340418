import {useMemo, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {createOrgPath} from '@core/lib/organizations';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {ButtonContainer} from '@core/ui/Button/LegacyButton';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import EmptyMessage from '@core/ui/EmptyMessage';
import {FORM_ERRORS, Form, Submit} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import EditManagerAccessForm from '@analytics/components/forms/EditManagerAccessForm';
import REMOVE_ACCESS_MUTATION from '@analytics/graphql-api/_old_mutations/RemoveAccess';
import BRAND_CAMPAIGN_AGENCIES_QUERY from '@analytics/graphql-api/_old_queries/BrandCampaignAgenciesQuery';
import {ManageAgenciesRouteProps} from '.';

const AgencyRemoveCell = ({agency, permissionId, refetch}) => {
  const [opened, toggle] = useOverlay(false);
  const [removeAccess] = useMutation(REMOVE_ACCESS_MUTATION);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();

  const onSubmit = async () => {
    setLoading(true);

    try {
      await removeAccess({
        variables: {
          input: {
            accessId: permissionId,
          },
        },
      });
      await refetch();
      toggle(false);
    } catch (err) {
      console.warn(err);
      setError(null, FORM_ERRORS.network);
    }
    setLoading(false);
  };

  return (
    <>
      {opened ? (
        <Overlay
          opened={opened}
          toggle={toggle}
          withShadow
          withBackdrop
          centered
          css={`
            padding: 1.75rem;
          `}>
          <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
            <p>
              Are you sure you want to remove the agency <b>{agency.name}</b>?
              The agency won't be able to see your campaigns anymore.
            </p>
            <ButtonContainer
              style-alignRight
              rules={() => ({marginTop: '2rem'})}>
              <Button type='button' style-default onClick={() => toggle(false)}>
                Cancel
              </Button>
              <Submit color='error' loading={loading}>
                Remove agency
              </Submit>
            </ButtonContainer>
          </Form>
        </Overlay>
      ) : null}
      <Button style-small style-outline onClick={() => toggle(true)}>
        Remove
      </Button>
    </>
  );
};

const AgenciesTable = ({managers, reversePermissions, refetch}) => {
  const columns = useMemo(
    () => [
      {
        title: 'Agency Name',
        accessor: 'name',
        type: 'string',
      },
      {
        Cell: ({data}) => {
          const permission = reversePermissions.find(
            (item) => item.organization.id === data.id
          );

          if (permission) {
            return (
              <AgencyRemoveCell
                agency={data}
                permissionId={permission.id}
                refetch={refetch}
              />
            );
          }
          return null;
        },
      },
    ],
    [managers, reversePermissions]
  );

  return <DataTable columns={columns} data={managers} />;
};

export function Agencies({organization}: ManageAgenciesRouteProps) {
  const {loading, error, data, refetch} = useQuery(
    BRAND_CAMPAIGN_AGENCIES_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        organizationId: organization.id,
      },
    }
  );

  if (loading) {
    return <Loading centered />;
  } else if (error) {
    return <Alert severity='error'>{t('errors.network')}</Alert>;
  }

  const campaigns = data?.me?.organization?.campaigns?.results ?? [];
  const managers = data?.me?.organization?.managers ?? [];
  const reversePermissions = data?.me?.organization?.reversePermissions ?? [];

  return (
    <>
      <Card>
        <CardHeader
          rightContent={
            <Button
              color='secondary'
              to={createOrgPath(organization, `/manage/agencies/add`)}>
              Add Agency
            </Button>
          }>
          Your Agencies
        </CardHeader>
        {managers.length > 0 ? (
          <AgenciesTable
            managers={managers}
            organization={organization}
            reversePermissions={reversePermissions}
            refetch={refetch}
          />
        ) : (
          <EmptyMessage>No agencies found.</EmptyMessage>
        )}
      </Card>
      {managers.length > 0 && (
        <Card>
          <CardHeader>Agencies Campaign Access</CardHeader>
          <EditManagerAccessForm
            campaigns={campaigns}
            managers={managers}
            organization={organization}
            refetch={refetch}
          />
        </Card>
      )}
    </>
  );
}
