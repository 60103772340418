import {gql} from '@apollo/client';

export default gql`
  query getCompanySimilarCompany(
    $organizationId: ID!
    $id: ID!
    $companyId: ID!
    $limit: Int
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          company(id: $companyId) {
            id
            name
            description

            feeds(companyId: $id, limit: $limit) {
              id
              title
              subtitle
              summary
              image {
                id
                href
                path
              }
            }

            episodes(companyId: $id, limit: $limit) {
              id
              title
              subtitle
              summary
              placementsCount
              image {
                id
                href
                path
              }
              feed {
                id
                image {
                  id
                  href
                  path
                }
              }
              enclosure {
                id
                length
              }
            }
          }
        }
      }
    }
  }
`;
