import {createOrgPath} from '@core/lib/organizations';
import {RouteType} from '@core/lib/useRoutes';
import Nav from '@core/ui/Nav';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {InsightsMetricSelect} from '../audience-insights';
import {InsightsMetric} from '../audience-insights/types';

export function CampaignInsightsNav({
  organization,
  campaign,
  routes,
  metric,
  setMetric,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign: FetchedCampaign;
  routes?: RouteType[];
  metric?: InsightsMetric;
  setMetric?: (_: InsightsMetric) => void;
}) {
  return (
    <Nav
      title='Audience Insights'
      titleRightContent={
        !!metric &&
        !!setMetric && (
          <InsightsMetricSelect
            defaultValue={metric}
            onChangeMetric={setMetric}
          />
        )
      }
      items={routes}
      breadcrumbs={[
        {
          title: 'Campaigns',
          to: createOrgPath(organization, '/measure/campaigns'),
        },
        {
          title: campaign.name,
          to: toCampaignUrl(organization, campaign),
        },
        {title: 'Insights'},
      ]}
    />
  );
}
