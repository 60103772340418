import {Card, CardHeader} from '@core/ui/Content';
import AttributionReferralDomains from '@analytics/components/attribution/AttributionReferralDomains';
import {CampaignPixelRouteProps} from '../_routes';

export function CampaignReferrerDomains({
  organization,
  campaign,
  pixel,
}: CampaignPixelRouteProps) {
  return (
    <Card>
      <CardHeader>Referrers</CardHeader>
      <AttributionReferralDomains
        organization={organization}
        campaign={campaign}
        pixel={pixel}
        limit={100}
      />
    </Card>
  );
}
