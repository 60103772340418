import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Controller, useForm} from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import {Card, CardHeader} from '@core/ui/Content';
import {Form, FormFooter, LabelInput, Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {reviewersURL} from '@analytics/components/nav/ResearchNav';
import CREATE_REVIEWER, {
  ICreateReviewer,
  ICreateReviewerInput,
} from '@analytics/graphql-api/_old_mutations/CreateReviewer';

const ReviewerCreate = ({organization}): JSX.Element => {
  const [complete, setComplete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [createReviewer] = useMutation<ICreateReviewer, ICreateReviewerInput>(
    CREATE_REVIEWER
  );

  const {
    control,
    handleSubmit,
    formState: {errors, isDirty},
    setError,
  } = useForm();

  const onSubmit = async ({email, firstName, lastName}) => {
    if (!isDirty) {
      setComplete(true);
      return;
    }

    setLoading(true);
    try {
      const resp = await createReviewer({
        variables: {
          input: {
            email,
            firstName,
            lastName,
            type: 'all',
          },
        },
      });

      if (resp) {
        setComplete(true);
      }
    } catch (e) {
      setError(null, e.message || 'Network error.  Please try again.');
    }
    setLoading(false);
  };

  if (complete) return <Redirect to={reviewersURL(organization)} />;

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={reviewersURL(organization)}>
        Add Reviewer
      </CardHeader>
      <Card>
        {/* <p>
          We want to make you aware that Ad Analytics keeps a detailed audit log of
          all ad pulls and classifications. We display this information
          publically for accountability.
        </p>
        <p>
          You can update that public information here, but note this will still
          be tied to your user account for the Ad Analytics admins to view.
        </p> */}
        <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
          <div
            css={{
              display: 'grid',
              gap: '1rem',
              gridTemplateColumns: '1fr 1fr',
              margin: '0 0 1rem',
            }}>
            <Controller
              name='firstName'
              control={control}
              rules={{required: 'Please provide reviewer first name.'}}
              render={({field}) => (
                <LabelInput
                  label='First Name'
                  onChange={(value) => field.onChange(value)}
                  invalid={!!errors.firstName}
                />
              )}
            />
            <Controller
              name='lastName'
              control={control}
              rules={{required: 'Please provide reviewer last name.'}}
              render={({field}) => (
                <LabelInput
                  label='Last Name'
                  onChange={(value) => field.onChange(value)}
                  invalid={!!errors.lastName}
                />
              )}
            />
          </div>
          <Controller
            name='email'
            control={control}
            rules={{required: 'Please provide reviewer email.'}}
            render={({field}) => (
              <LabelInput
                label='Email'
                onChange={(value) => field.onChange(value)}
                invalid={!!errors.email}
              />
            )}
          />
          <FormFooter>
            <Submit disabled={loading} loading={loading}>
              Add Reviewer
            </Submit>
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
};

export default ReviewerCreate;
