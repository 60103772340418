import React, {Suspense} from 'react';
import {LoadingSpinner} from '@core/ui/Loading';
import {MapProps} from './Map';

const MapBase = React.lazy(() => import('./Map'));

const Map = ({...props}: MapProps) => {
  return (
    <Suspense fallback={<LoadingSpinner centered />}>
      <MapBase {...props} />
    </Suspense>
  );
};

export default Map;
