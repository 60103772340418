import {useState} from 'react';
import {useQuery} from '@apollo/client';
import {
  EmptyState,
  EmptyStateButton,
  EmptyStateTitle,
  IconExclamationCircle,
  ProgressCircle,
  cssSpacing,
} from '@spotify-internal/encore-web';
import {format} from 'd3-format';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {ChartTimeTooltip} from '@core/components/ChartTimeTooltip';
import dayjs from '@core/lib/dayjs';
import {DateRangeBar} from '@core/ui/DatePicker';
import {Stack} from '@core/ui/Layout';
import EPISODE_HOURLY_DOWNLOADS_QUERY from '@analytics/graphql-api/_old_queries/EpisodeHourlyDownloadsQuery';
import PODCAST_HOURLY_DOWNLOADS_QUERY from '@analytics/graphql-api/_old_queries/PodcastHourlyDownloadsQuery';

const SERIES = [{key: 'impressions', color: 'var(--green)'}];

const getData = ({hourlyImpressions, dateRange}) => {
  const keyMap = {
    good: 'impressions',
  };

  const data = hourlyImpressions.reduce((acc, obj) => {
    const date = dayjs(obj.hour).utc();
    const x = date.startOf('day').toDate().getTime();
    const y = keyMap[obj.downloadClass.toLowerCase()];

    if (acc[x]) {
      acc[x][y] = acc[x][y] ? acc[x][y] + obj.count : obj.count;
    } else {
      acc[x] = {
        x: x,
        [y]: obj.count,
        date,
      };
    }

    return acc;
  }, {});

  let currentDate = dateRange[0].utc().startOf('day');
  const lastDate = dateRange[1].utc().startOf('day');

  while (currentDate.diff(lastDate) < 0) {
    const x = currentDate.toDate().getTime();

    if (!data[x]) {
      data[x] = {
        x,
        impressions: 0,
        date: currentDate.clone(),
      };
    }
    currentDate = currentDate.add(1, 'days');
  }

  return Object.values(data).sort((a, b) => a.x - b.x);
};

const LineChartImpl = ({dateRange, hourlyImpressions, height = 300}) => {
  const data = getData({hourlyImpressions, dateRange});

  return (
    <div css={{color: 'var(--text-muted)', fontSize: '0.75rem'}}>
      <ResponsiveContainer width='100%' height={height}>
        <ComposedChart
          data={data}
          barGap={0}
          barCategoryGap={0}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}>
          <CartesianGrid
            stroke='var(--border-default)'
            strokeDasharray='0 0'
            vertical={false}
          />
          <XAxis
            type='number'
            scale='time'
            dataKey='x'
            domain={['dataMin', 'dataMax']}
            tick={{fill: 'var(--text-muted)'}}
            tickSize={10}
            tickLine={false}
            tickFormatter={(timeStr) =>
              dayjs(timeStr).utc().format('D MMM').toUpperCase()
            }
            axisLine={false}
          />
          <YAxis
            tickFormatter={(val) => format('.4s')(val)}
            tick={{fill: 'var(--text-muted)'}}
            tickSize={10}
            axisLine={false}
            tickLine={false}
          />
          {SERIES.map(({key, color}) => (
            <Line
              key={key}
              strokeWidth={2}
              dataKey={key}
              stroke={color}
              fill={color}
              dot={false}
            />
          ))}
          <Tooltip
            content={({...props}) => (
              <ChartTimeTooltip displayZeroValues {...props} />
            )}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const ImpressionsChartTpl = ({getDataFn, minDate, query, queryVariables}) => {
  const [dateRange, setDateRange] = useState([
    dayjs().utc().subtract(7, 'd'),
    dayjs().utc(),
  ]);

  const onDatesChange = ({startDate, endDate}) =>
    setDateRange([startDate, endDate]);

  const {loading, error, data, refetch} = useQuery(query, {
    variables: {
      ...queryVariables,
      after: dateRange[0].format('YYYY-MM-DD'),
      before: dateRange[1].format('YYYY-MM-DD'),
    },
  });

  return (
    <Stack direction='column' gap={cssSpacing('base')}>
      <DateRangeBar
        startDate={dateRange[0]}
        endDate={dateRange[1]}
        onDatesChange={onDatesChange}
        minDate={minDate}
      />
      {loading ? (
        <ProgressCircle
          aria-label='podcast-impressions-progress'
          size='medium'
        />
      ) : error ? (
        <EmptyState
          icon={IconExclamationCircle}
          iconColorSet='negative'
          variant='contextual'>
          <EmptyStateTitle variant='cello'>Couldn't load data</EmptyStateTitle>
          <EmptyStateButton
            condensedAll
            semanticColor='textSubdued'
            onClick={refetch}>
            Try again
          </EmptyStateButton>
        </EmptyState>
      ) : (
        <LineChartImpl
          dateRange={dateRange}
          hourlyImpressions={getDataFn(data)}
        />
      )}
    </Stack>
  );
};

const PodcastEpisodeImpressionsChart = ({episode, organization, podcast}) => {
  return (
    <ImpressionsChartTpl
      query={EPISODE_HOURLY_DOWNLOADS_QUERY}
      queryVariables={{
        id: episode.id,
        podcastId: podcast.id,
        organizationId: organization.id,
        algorithm: organization.downloadAlgorithm,
      }}
      getDataFn={(data) =>
        data.me.organization.podcast.feed.episode.downloads.hourly
      }
      minDate={dayjs(episode.published).utc()}
    />
  );
};

const PodcastImpressionsChart = ({organization, podcast}) => {
  return (
    <ImpressionsChartTpl
      query={PODCAST_HOURLY_DOWNLOADS_QUERY}
      queryVariables={{
        id: podcast.id,
        organizationId: organization.id,
        algorithm: organization.downloadAlgorithm,
      }}
      getDataFn={(data) => data.me.organization.podcast.downloads.hourly}
      minDate={dayjs(podcast.createdAt).utc()}
    />
  );
};

export {PodcastImpressionsChart, PodcastEpisodeImpressionsChart};
