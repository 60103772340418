import {useMemo, useState} from 'react';
import {CustomRangeOverlay} from '@core/components/CustomRangeOverlay';
import dayjs from '@core/lib/dayjs';
import {exists} from '@core/lib/utils';
import {DropdownMenu} from '@core/ui/DropdownMenu';
import {DropdownMenuItemType} from '@core/ui/DropdownMenu/types';
import {useOverlay} from '@core/ui/Overlay';
import {ExportTypeId} from '../../helpers';
import {
  DefaultValues,
  dateRangeItems,
  fmtDates,
  getDefaultSelected,
} from './helpers';
import {DatesInputButton} from './shared';

type OnSelectRangeProps =
  | {
      startDate: undefined;
      endDate: undefined;
      reportPeriod: undefined;
    }
  | {
      startDate: string;
      endDate: string;
      reportPeriod: undefined;
    }
  | {
      startDate: undefined;
      endDate: undefined;
      reportPeriod: number;
    };

type Props = {
  defaultValues: DefaultValues;
  invalid: boolean;
  onSelectRange: (_: OnSelectRangeProps) => void;
  typeId: ExportTypeId;
};

export function RangeInput({
  defaultValues,
  invalid,
  onSelectRange,
  typeId,
}: Props) {
  const dropdownItems = useMemo(() => dateRangeItems(typeId), [typeId]);
  const [openedCustom, toggleCustom] = useOverlay();
  const [selected, setSelected] = useState<DropdownMenuItemType | undefined>(
    () => getDefaultSelected(defaultValues, typeId)
  );

  const onSelectItem = (item: DropdownMenuItemType) => {
    if (item.value === 'custom') return toggleCustom(true);
    setSelected(item);
    onSelectRange({
      startDate: undefined,
      endDate: undefined,
      reportPeriod: typeof item.value === 'number' ? item.value : undefined,
    });
  };

  const onSaveCustom = (dates: dayjs.Dayjs[]) => {
    setSelected({title: fmtDates(dates), value: 'custom'});
    onSelectRange({
      startDate: dates[0].format('YYYY-MM-DD'),
      endDate: dates[1].format('YYYY-MM-DD'),
      reportPeriod: undefined,
    });
  };

  return (
    <>
      <DropdownMenu
        items={dropdownItems}
        onSubmit={onSelectItem}
        selectedValue={selected?.value}>
        <DatesInputButton
          active={!!selected}
          invalid={invalid}
          placeholder={(selected?.title as string) ?? 'Date Range'}
        />
      </DropdownMenu>
      {openedCustom && (
        <CustomRangeOverlay
          centered
          closeOnOutsideClick={false}
          datePickerProps={{
            defaultDates:
              exists(defaultValues.startDate) && exists(defaultValues.endDate)
                ? [defaultValues.startDate, defaultValues.endDate]
                : undefined,
          }}
          opened={openedCustom}
          onSave={onSaveCustom}
          toggle={toggleCustom}
          withBackdrop
        />
      )}
    </>
  );
}
