import {useRef, useState} from 'react';
import dayjs from '@core/lib/dayjs';
import {t} from '@core/texts';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import DatePicker from '@core/ui/DatePicker';
import EmptyMessage from '@core/ui/EmptyMessage';
import {Icon} from '@core/ui/Icon';
import Loading from '@core/ui/Loading';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import ReviewAd from '@analytics/components/ads/ReviewAd';
import {
  AdObject,
  OrganizationObject,
  useReviewerAdMultiQuery,
} from '@analytics/graphql-api';

type ReviewerAdsProps = {
  reviewerId: string;
  organization: OrganizationObject;
  match: {
    params: {
      reviewedById: string;
      feedId: string;
    };
  };
};

export default function ReviewerAds({
  reviewerId,
  organization,
  match: {
    params: {reviewedById, feedId},
  },
}: ReviewerAdsProps) {
  const filterRef = useRef<HTMLButtonElement>(null);
  const [openedDateFilter, toggleDateFilter] = useOverlay();
  const [selectedDate, setSelectedDate] = useState([dayjs()]);
  const [adsCount, setAdsCount] = useState(0);
  const [adsQueue, setAdsQueue] = useState<AdObject[]>([]);
  const {loading, error} = useReviewerAdMultiQuery({
    variables: {
      organizationId: organization.id,
      feedId,
      state: feedId || reviewedById ? null : 'unknown',
      reviewedById,
      includeUnknown: true,
      before: selectedDate[0].format('YYYY-MM-DD'),
    },
    skip: adsQueue.length > 0,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const adsCount =
        data?.me?.organization?.research?.reviewing?.adsCount ?? 0;
      const ads =
        (data?.me?.organization?.research?.reviewing?.ads?.filter(
          (ad) => !!ad
        ) as AdObject[]) ?? [];
      setAdsCount(adsCount);
      setAdsQueue(ads);
    },
  });

  const onComplete = () => {
    const updatedAdsQueue = adsQueue.slice(1);
    setAdsQueue(updatedAdsQueue);
    setAdsCount(adsCount - 1);
  };

  const currentAd = adsQueue?.[0] || null;

  return (
    <>
      <CardHeader>
        Ads to review {adsCount > 0 ? `(${adsCount} left)` : null}
      </CardHeader>
      <Card
        rules={() => ({
          background: 'var(--bg-muted)',
          border: 'none',
          marginBottom: '1rem',
        })}>
        <div css={{display: 'flex', alignItems: 'center'}}>
          <div css={{fontSize: '0.975rem', marginRight: '0.625rem'}}>
            Review ads prior to:
          </div>
          <Icon icon='date' rules={() => ({color: 'var(--icon-subtle)'})} />
          <button
            ref={filterRef}
            onClick={() => toggleDateFilter(true)}
            css={{
              background: 'var(--bg-default)',
              border: `0.0625rem solid ${
                openedDateFilter ? 'var(--blue)' : 'var(--border-default)'
              }`,
              borderRadius: '0.375rem',
              boxShadow: openedDateFilter ? '0 0 0 0.0625rem var(--blue)' : '',
              color:
                openedDateFilter || selectedDate.length
                  ? 'var(--text-default)'
                  : 'var(--text-muted)',
              cursor: 'pointer',
              marginLeft: '0.625rem',
              minHeight: '2.5rem',
              minWidth: '7.8125rem',
              outline: 0,
              padding: '0.5rem 0.625rem',
              textAlign: 'left',
              userSelect: 'none',
              ':focus': {
                borderColor: 'var(--blue)',
                boxShadow: '0 0 0 0.0625rem var(--blue)',
              },
            }}>
            {selectedDate.length > 0 ? (
              <>{selectedDate[0].format('MM/DD/YY')}</>
            ) : (
              'Select date...'
            )}
          </button>
        </div>
      </Card>
      <Card>
        {feedId || reviewedById ? (
          <b
            css={{
              textAlign: 'right',
              padding: '0 20px',
              display: 'block',
              fontSize: '14px',
            }}>
            Feed: {feedId} | reviewedBy: {reviewedById}
          </b>
        ) : null}
        {loading ? (
          <Loading centered />
        ) : error ? (
          <ToastMessage alertType='error'>{t('errors.network')}</ToastMessage>
        ) : currentAd ? (
          <ReviewAd
            ad={currentAd}
            autoplay
            reviewerId={reviewerId}
            onComplete={onComplete}
            organization={organization}
          />
        ) : (
          <EmptyMessage>No Ads to Review.</EmptyMessage>
        )}
      </Card>
      {openedDateFilter && (
        <Overlay
          opened={openedDateFilter}
          toggle={toggleDateFilter}
          positionTarget={filterRef.current}
          withShadow
          withBackdrop
          transparentBackdrop
          css={`
            max-width: initial;
            padding: 1rem;
          `}>
          <DatePicker
            mode='day'
            isOutsideRange={(date) => date.isAfter(dayjs())}
            onDatesChange={(date) => {
              setAdsQueue([]);
              setSelectedDate(date);
            }}
            defaultDates={selectedDate}
          />
        </Overlay>
      )}
    </>
  );
}
