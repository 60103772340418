import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Element} from '@core/style';
import {FieldGroup, Input, Label, TextArea} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import CreateFutureEpisodeMutation from '@analytics/graphql-api/_old_mutations/CreateFutureEpisode';
import FutureEpisodesQuery from '@analytics/graphql-api/_old_queries/FutureEpisodesQuery';

class NewFutureEpisode extends React.Component {
  state = {
    title: '',
    subtitle: '',
    expectedPublished: '',
    loading: false,
    complete: false,
  };

  onChange = ({target: {name, value}}) => {
    const update = {error: false};
    update[name] = value;
    this.setState(update);
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {
      createFutureEpisode,
      organization,
      match: {
        params: {podcastId},
      },
    } = this.props;
    const {title, subtitle, expectedPublished} = this.state;

    if (!title || !expectedPublished) {
      return;
    }

    this.setState({
      loading: true,
    });

    createFutureEpisode({
      title,
      subtitle,
      expectedPublished,
      podcastId,
      organizationId: organization.id,
    }).then(
      ({
        data: {
          createFutureEpisode: {
            futureEpisode: {id},
          },
        },
      }) => {
        this.setState({
          complete: id,
        });
      }
    );
  };

  render() {
    const {
      organization,
      match: {
        params: {podcastId},
      },
    } = this.props;
    const {loading, complete, title, subtitle, expectedPublished, error} =
      this.state;

    if (complete) {
      return (
        <Redirect
          to={createOrgPath(
            organization,
            `/podcast/${podcastId}/new/${complete}`
          )}
        />
      );
    }

    return (
      <Element rules={() => ({maxWidth: '600px', margin: '25px auto'})}>
        <h3>Add a Future Episode</h3>
        <p>
          Create a placeholder for a future episode you plan to release. You
          will then be able to attach advertisers to the episode for Ad
          Analytics to properly onboard.
        </p>
        <form onSubmit={this.onSubmit}>
          <FieldGroup>
            <Label>Future Title</Label>
            <Input
              type='text'
              name='title'
              value={title}
              onChange={this.onChange}
              placeholder='Our Episode on Cats'
              style-error={error}
              disabled={loading}
            />
          </FieldGroup>
          <FieldGroup>
            <Label>Future Subtitle</Label>
            <TextArea
              name='subtitle'
              value={subtitle}
              onChange={this.onChange}
              placeholder='The one we are going to do on cats, it will be awesome. (optional)'
              style-error={error}
              disabled={loading}
            />
          </FieldGroup>
          <FieldGroup>
            <Label>Expected Published Date</Label>
            <Input
              type='date'
              name='expectedPublished'
              value={expectedPublished}
              onChange={this.onChange}
              style-error={error}
              disabled={loading}
            />
          </FieldGroup>

          <Element rules={() => ({padding: '20px 0', textAlign: 'right'})}>
            <Submit disabled={loading}>Add Future Episode</Submit>
          </Element>
        </form>
      </Element>
    );
  }
}

export default graphql(CreateFutureEpisodeMutation, {
  props: ({mutate}) => ({
    createFutureEpisode: (input) => {
      return mutate({
        variables: {
          input,
        },
        update: (
          store,
          {
            data: {
              createFutureEpisode: {organizationId, podcastId, futureEpisode},
            },
          }
        ) => {
          // Stuff the new journey into the cache.
          const query = {
            query: FutureEpisodesQuery,
            variables: {
              organizationId,
              podcastId,
            },
          };

          query.data = store.readQuery(query);

          const futureEpisodes =
            query.data.me.organization.podcast.futureEpisodes.slice(0);
          futureEpisodes.push(futureEpisode);
          query.data.me.organization.futureEpisodes = futureEpisodes;
          store.writeQuery(query);
        },
      });
    },
  }),
})(NewFutureEpisode);
