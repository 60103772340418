import {Button, ButtonIcon} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Stack} from '@core/ui/Layout';
import {ManagePixelsPixelUrlRouteProps} from '.';

export function PixelUrlsOverview({
  pixel,
  match: {url},
}: ManagePixelsPixelUrlRouteProps & {
  match: {url: string};
}) {
  const columns: DataTableColumn[] = [
    {
      title: 'URL',
      accessor: 'url',
      type: 'string',
    },
    {
      title: 'Action',
      accessor: 'action',
      type: 'string',
    },
    {
      title: 'Value',
      accessor: 'value',
      type: 'string',
    },
    {
      title: 'Type',
      accessor: 'type',
      type: 'string',
      /* help: 'Only for lead type actions', */
    },
    {
      title: 'Category',
      accessor: 'category',
      type: 'string',
      /* help: 'Only for lead type actions', */
    },
    {
      title: '',
      Cell: ({data: {id}}) => (
        <div css={{display: 'flex', gap: '1rem'}}>
          <ButtonIcon
            to={`${url}/${id}/update`}
            icon='pencil'
            css={`
              color: var(--text-muted);
              display: inline-flex;
              }
            `}
          />
          <ButtonIcon to={`${url}/${id}/delete`} icon='bin' />
        </div>
      ),
    },
  ];

  return (
    <Card>
      <DataTable
        data={pixel.conversionUrls}
        columns={columns}
        emptyMessageText='No URLs found'
        searchKeys={['url']}
        headerRenderer={({filtersContent, searchContent}) => (
          <Stack direction='column' gap={3}>
            <Stack justifyContent='space-between' alignItems='center'>
              <CardHeader
                css={`
                  margin: 0;
                `}>
                Conversion URLs
              </CardHeader>
              <Button to={`${url}/create`} color='secondary'>
                Add URL
              </Button>
            </Stack>
            {(searchContent || filtersContent) && (
              <Stack alignItems='center'>
                {searchContent}
                {filtersContent}
              </Stack>
            )}
          </Stack>
        )}
      />
    </Card>
  );
}
