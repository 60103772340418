import {gql} from '@apollo/client';
import PodcastFragment from '../_old_queries/fragments/PodcastFragment';

export default gql`
  mutation setAdvisorOptions($input: SetAdvisorOptionsInput!) {
    authed {
      success
    }
    setAdvisorOptions(input: $input) {
      organization {
        isAdvisor
        podcasts {
          ...Podcast
        }
        capabilities {
          id
          category
          title
        }
      }
    }
  }
  ${PodcastFragment}
`;
