import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import Designer from '@analytics/components/designer/index';
import {ManagePodlinkRouteProps} from '.';

export function PodlinkPodcastDesigner({
  organization,
  podcasts,
  match: {
    params: {podcastId},
  },
}: ManagePodlinkRouteProps & {
  match: {params: {podcastId: string}};
}) {
  const history = useHistory();

  const onComplete = () => {
    history.push(createOrgPath(organization, 'manage/podlink'));
  };

  const _podcast = podcasts.filter(({id}) => id == podcastId)[0];

  return (
    <Designer
      organization={organization}
      podcast={_podcast}
      onCancel={onComplete}
      onSave={onComplete}
    />
  );
}
