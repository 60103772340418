import {gql} from '@apollo/client';

export default gql`
  query getPublisherCompanies(
    $organizationId: ID!
    $publisherSlug: String!
    $limit: Int
    $orderBy: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          publisher(slug: $publisherSlug) {
            id
            companies(after: "2018-07-12", limit: $limit, orderBy: $orderBy) {
              id
              name
              description
              url
              image {
                id
                width
                height
                src
                path
              }

              adsCount(after: "2018-07-12")
              adFirstPublished(after: "2018-07-12")
            }
          }
        }
      }
    }
  }
`;
