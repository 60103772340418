import {cleanFloat, cleanInt} from '@core/lib/clean';
import dayjs from '@core/lib/dayjs';
import {FetchedCampaign} from '@analytics/graphql-api';
import {CampaignKind} from '../_types';
import {CampaignFormData} from './_types';

export const getCampaignDefaultValues = ({
  campaign,
  kind,
  payerId,
}: {
  campaign?: FetchedCampaign;
  kind: CampaignKind | undefined;
  payerId: string | undefined;
}) => {
  if (campaign) {
    return {
      name: campaign.name,
      kind: campaign.kind ?? null,
      cost: campaign.cost ?? null,
      goal: campaign.goal ?? null,
      payerId: campaign.payer?.id ?? null,
      isHiddenFromAgency: campaign.isHiddenFromAgency,
      goLiveDate: campaign.goLiveDate ? dayjs(campaign.goLiveDate) : null,
      expectedDates: {
        startAt: campaign.startAt
          ? dayjs(campaign.startAt)
          : null,
        endAt: campaign.endAt
          ? dayjs(campaign.endAt)
          : null,
      },
      networkError: false,
    } as const;
  }

  return {
    name: '',
    kind: kind ?? null,
    cost: null,
    goal: null,
    payerId: payerId ?? null,
    isHiddenFromAgency: false,
    goLiveDate: null,
    expectedDates: {
      startAt: dayjs(),
      endAt: dayjs().add(14, 'd'),
    },
    networkError: false,
  } as const;
};

export const getCampaignFormInput = ({data}: {data: CampaignFormData}) => {
  const {startAt, endAt} = data.expectedDates;
  return {
    name: data.name,
    kind: data.kind,
    cost: cleanFloat(data.cost),
    goal: cleanInt(data.goal),
    payerId: data.payerId,
    startAt: startAt?.clone().toDate().toISOString() ?? null,
    endAt: endAt?.clone().toDate().toISOString() ?? null,
    goLiveDate: data.goLiveDate?.clone().format('YYYY-MM-DD') ?? null,
    isHiddenFromAgency: data.isHiddenFromAgency,
  } as const;
};
