import {gql} from '@apollo/client';

export default gql`
  mutation claimAddPodcast($input: ClaimAddPodcastInput!) {
    claimAddPodcast(input: $input) {
      podcast {
        id
      }
      organization {
        id
        slug
      }
    }
  }
`;
