import {Switch} from 'react-router-dom';
import {t} from '@core/texts';
import {ToastMessage} from '@core/ui/Alert';
import {LoadingSpinner} from '@core/ui/Loading';
import {useLimitedCompanyQuery} from '@analytics/graphql-api';
import {ManageInviteRouteProps} from '../_routes';
import {InviteMembers} from './InviteMembers';
import {InvitePixel} from './InvitePixel';
import {useInviteCompanyRoutes} from './_routes';

export function InviteCompanyIndex({
  user,
  organization,
  match: {
    params: {companyId},
  },
}: ManageInviteRouteProps & {
  match: {params: {companyId: string}};
}) {
  const {data, loading, error} = useLimitedCompanyQuery({
    variables: {
      organizationId: organization.id,
      id: companyId,
    },
  });

  if (loading) {
    return <LoadingSpinner centered />;
  }

  if (error) {
    return <ToastMessage alertType='error'>{t('errors.network')}</ToastMessage>;
  }

  const {renderRoutes} = useInviteCompanyRoutes({organization, companyId});

  return (
    <Switch>
      {renderRoutes({
        components: {
          'invite-members': InviteMembers,
          'invite-pixel': InvitePixel,
        },
        extraProps: {
          user,
          organization,
          company: data?.me?.organization?.limitedCompany,
        },
      })}
    </Switch>
  );
}
