import dayjs from '@core/lib/dayjs';
import {DropdownMenuItemType} from '@core/ui/DropdownMenu/types';
import {InputMaybe} from '@analytics/graphql-api';
import {ExportTypeId} from '../../helpers';
import {Separator} from './shared';

export const fmtDates = (dates: dayjs.Dayjs[]) =>
  `${dates[0].format('MM/DD/YY')}-${dates[1].format('MM/DD/YY')}`;

type DatesFormInputs = {
  startDate: InputMaybe<string> | undefined;
  endDate: InputMaybe<string> | undefined;
  reportPeriod: InputMaybe<number> | undefined;
};

export type DefaultValues =
  | {
      startDate: undefined;
      endDate: undefined;
      reportPeriod: number;
    }
  | {
      startDate: dayjs.Dayjs;
      endDate: dayjs.Dayjs;
      reportPeriod: undefined;
    }
  | {
      startDate: undefined;
      endDate: undefined;
      reportPeriod: undefined;
    };

type DefaultValuesFn = (_: DatesFormInputs) => DefaultValues;

export const defaultValues: DefaultValuesFn = ({
  startDate,
  endDate,
  reportPeriod,
}) => {
  if (typeof reportPeriod === 'number') {
    return {
      startDate: undefined,
      endDate: undefined,
      reportPeriod: reportPeriod,
    };
  }

  if (startDate && endDate) {
    return {
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      reportPeriod: undefined,
    };
  }

  return {
    startDate: undefined,
    endDate: undefined,
    reportPeriod: undefined,
  };
};

const DEFAULT_ALL_TIME_REPORTS = [
  'campaign_overview',
  'campaign_impressions',
  'organization_benchmark_campaigns',
  'organization_benchmark_campaigns_detail',
] as Partial<ExportTypeId>[];

const RANGE_ITEMS = [
  {title: 'Last 7 days', value: 7},
  {title: 'Last 14 days', value: 14},
  {title: 'Last 28 days', value: 28},
  {content: <Separator />},
  {title: 'Custom', value: 'custom'},
];

type DateRangeItemsFn = (_: ExportTypeId) => DropdownMenuItemType[];

export const dateRangeItems: DateRangeItemsFn = (typeId) => [
  ...(DEFAULT_ALL_TIME_REPORTS.includes(typeId)
    ? [{title: 'All time', value: 'all-time'}, {content: <Separator />}]
    : []),
  ...RANGE_ITEMS,
];

type GetDefaultSelectedFn = (
  defaultValues: DefaultValues,
  typeId: ExportTypeId
) => DropdownMenuItemType | undefined;

export const getDefaultSelected: GetDefaultSelectedFn = (
  {startDate, endDate, reportPeriod},
  typeId
) => {
  if (startDate && endDate) {
    return {title: fmtDates([startDate, endDate]), value: 'custom'};
  }

  if (reportPeriod) {
    return RANGE_ITEMS.find(({value}) => value === reportPeriod);
  }

  if (DEFAULT_ALL_TIME_REPORTS.includes(typeId)) {
    return {title: 'All time', value: 'all-time'};
  }

  return undefined;
};

type ErrorMsg = string;
type ValidateDatesFieldFn = (
  _: DatesFormInputs & {showRange: boolean; typeId: ExportTypeId}
) => true | ErrorMsg;

export const validateDatesField: ValidateDatesFieldFn = ({
  endDate,
  reportPeriod,
  showRange,
  startDate,
  typeId,
}) => {
  if (DEFAULT_ALL_TIME_REPORTS.includes(typeId)) return true;

  if (showRange) {
    return (
      (!!startDate && !!endDate) ||
      typeof reportPeriod === 'number' ||
      'Please select date range'
    );
  }

  return !!startDate || 'Please select date';
};
