import {forwardRef} from 'react';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {toTimeString} from '@core/lib/time';
import {stripHTML, truncateWords} from '@core/lib/words';
import {DataTableColumn, DateRangeFilter} from '@core/ui/DataTable';
import {Img} from '@core/ui/Image';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {FetchedPodcastEpisode} from '@analytics/graphql-api';

export const COLUMNS = [
  {
    title: 'Episode',
    accessor: 'title',
    type: 'string',
    Cell: ({d: {title, description, image}}) => (
      <Stack gap={2}>
        <Img image={image} width={40} />
        <Stack direction='column'>
          <Text
            css={{
              minWidth: '12.5rem',
              whiteSpace: 'nowrap',
            }}
            variant='subtitle2'>
            {title}
          </Text>
          <Text color='muted' variant='body2'>
            {truncateWords(stripHTML(description ?? ''), 15)}
          </Text>
        </Stack>
      </Stack>
    ),
  },
  {
    title: 'Published',
    accessor: 'published',
    type: 'date',
    Filter: forwardRef(({...props}, ref) => (
      <DateRangeFilter
        ref={ref}
        label='Published'
        startAccessor='published'
        endAccessor='published'
        {...props}
      />
    )),
    fmt: (v) => (v ? dayjs(v).format('MM/DD/YY') : '-'),
  },
  {
    title: 'Duration',
    accessor: 'enclosure',
    type: 'number',
    fmt: (v) => toTimeString(v.duration),
  },
  {
    title: 'Ads',
    accessor: 'placementsCount',
    type: 'number',
    fmt: comma,
  },
  {
    title: 'Impressions',
    accessor: 'downloads',
    type: 'number',
    fmt: (v) => comma(v.count),
  },
] as DataTableColumn<FetchedPodcastEpisode>[];
