import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import {DEFAULT_CHART_HEIGHT, DEFAULT_CHART_WIDTH} from '../helpers';
import {PieLiftData} from '../types';
import {BrandLiftPieChartLegend} from './BrandLiftPieChartLegend';
import {BrandLiftPieChartTooltip} from './BrandLiftPieChartTooltip';

const COLORS = ['#806AF4', '#59A0F6', '#CC2FAF', '#10B28E', '#85E0FF'];

export function BrandLiftPieChart({
  data,
  height = DEFAULT_CHART_HEIGHT,
  width = DEFAULT_CHART_WIDTH,
  ...props
}: {
  data: PieLiftData[];
  height?: number;
  width?: string | number;
}) {
  return (
    <ResponsiveContainer height={height} width={width} {...props}>
      <PieChart>
        <Pie
          animationBegin={0}
          data={data}
          dataKey='total'
          innerRadius={145}
          outerRadius={175}
          stroke='var(--bg-default)'>
          {data.map((d, i) => (
            <Cell key={d.group} fill={COLORS[i % data.length]} />
          ))}
        </Pie>
        <Tooltip content={BrandLiftPieChartTooltip} cursor={false} />
        <Legend
          align='center'
          content={BrandLiftPieChartLegend}
          layout='vertical'
          verticalAlign='middle'
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
