import {createOrgPath} from '@core/lib/organizations';
import {Breadcrumbs} from '@core/ui/Breadcrumbs';
import {
  CampaignRouteProps,
  toCampaignUrl,
} from '@analytics/screens/unified/campaigns/campaign/_routes';

export function CampaignBulkNav({
  campaign,
  organization,
}: Omit<CampaignRouteProps, 'user'>) {
  return (
    <div
      css={`
        height: 5.25rem;
        margin: 0;
      `}>
      <Breadcrumbs
        breadcrumbs={[
          {
            to: createOrgPath(organization, '/measure/campaigns'),
            title: 'Campaigns',
          },
          {
            to: toCampaignUrl(organization, campaign),
            title: campaign.name,
          },
          {
            title: 'Edit Ads',
          },
        ]}
        css={`
          margin: 0 var(--spacing-6);
          padding: 1rem 0 0.875rem 0;
        `}
      />
      <div
        css={`
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
        `}>
        {campaign.name}
      </div>
    </div>
  );
}
