import dayjs from '@core/lib/dayjs';
import {
  FetchedCampaign,
  FetchedCampaignLiftReports,
  FetchedIncrementalLiftReports,
} from '@analytics/graphql-api';

export const generateLiftTableData = ({
  liftReports,
  campaign,
}: {
  liftReports: FetchedIncrementalLiftReports | FetchedCampaignLiftReports;
  campaign?: FetchedCampaign;
}) =>
  liftReports.map((item) => ({
    ...item,
    campaigns:
      item.campaigns?.length > 0 ? item.campaigns : [item.campaign ?? campaign],
    createdAt: item.createdAt && dayjs(item.createdAt),
    name:
      item.name ??
      (item.campaign ?? campaign)?.name ??
      fmtDisplayName(item.impressionsStartAt, item.impressionsEndAt),
    startDate: item.impressionsStartAt,
    endDate: item.impressionsEndAt,
  }));

export const fmtDateRange = ({
  d: {startDate, endDate},
}: {
  d: {startDate: string | null; endDate: string | null};
}) =>
  startDate && endDate
    ? `${dayjs(startDate).format('M/D/YYYY')} to ${dayjs(endDate).format(
        'M/D/YYYY'
      )}`
    : '-';

/* ---------------------------------------------------------------------------- */

const fmtDisplayName = (start: string, end: string, FMT = 'YYYY-MM-DD') =>
  `Report ${dayjs(start).format(FMT)} to ${dayjs(end).format(FMT)}`;
