import {gql} from '@apollo/client';

export default gql`
  query getResearchCompanyAd($organizationId: ID!, $companyId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          company(id: $companyId) {
            id

            ad(id: $id) {
              id

              placements {
                id
                startTime
                endTime
                episode {
                  id
                  title
                  subtitle
                  summary
                  description(words: 50)
                  published
                  image {
                    id
                    href
                    path
                  }
                  feed {
                    id
                    title
                    image {
                      id
                      href
                      path
                    }
                  }
                  enclosure {
                    id
                    length
                  }
                  placementsCount
                }
              }

              reviews {
                id
                startState
                endState

                reviewer {
                  id
                  firstName
                  lastName
                  email
                }

                duration

                createdAt
                updatedAt
              }
            }
          }
        }
      }
    }
  }
`;
