import {
  Banner,
  ButtonPrimary,
  Text,
  cssSpacing,
} from '@spotify-internal/encore-web';
import {createOrgPath, isOrgPodcast} from '@core/lib/organizations';
import {Card, CardHeader} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {CampaignsTable} from '@analytics/components/campaigns/CampaignsTable';
import {PublisherReviewTable} from '@analytics/components/campaigns/publisher-review';
import {FetchedOrganization} from '@analytics/graphql-api';
import {canCreateCampaign} from '@analytics/lib/campaigns';

export function MeasureCampaignsIndex({
  organization,
}: {
  organization: FetchedOrganization;
}) {
  return (
    <>
      <Stack
        justifyContent='space-between'
        css={`
          margin: var(--spacing-4) var(--spacing-6) var(--spacing-6);
        `}>
        <Text as='h1' variant='titleMedium'>
          Campaigns
        </Text>
        {canCreateCampaign({organization}) && (
          <ButtonPrimary
            href={createOrgPath(organization, '/campaigns/create')}>
            Create Campaign
          </ButtonPrimary>
        )}
      </Stack>
      {isOrgPodcast(organization) && (
        <Card>
          <CardHeader>For Review</CardHeader>
          <Banner contextual css={{marginBottom: cssSpacing('base', '16px')}}>
            Approve or reject these campaigns to ensure they go live on the
            scheduled start date.
          </Banner>
          <PublisherReviewTable organization={organization} />
        </Card>
      )}
      <Card>
        <CardHeader>Attribution Campaigns</CardHeader>
        <CampaignsTable
          organization={organization}
          filtersStorageKey={`campaigns-attribution-${organization.slug}`}
        />
      </Card>
    </>
  );
}
