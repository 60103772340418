import ImpressionsProgressBar from '@core/components/ImpressionsProgressBar';
import dayjs from '@core/lib/dayjs';
import {
  comma,
  financial,
  toCampaignCurrency,
  toFixed,
  toPercent,
} from '@core/lib/filters';
import {organizationCanViewBrandData} from '@core/lib/organizations';
import {t} from '@core/texts';
import {DataTableColumn} from '@core/ui/DataTable';
import useSettings from '@analytics/components/settings/useSettings';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
} from '@analytics/graphql-api';

const campaignTableColumns = (
  organization: FetchedOrganization | FetchedBuySideOrganization,
  campaign: FetchedCampaign,
  data: any
) => {
  const [settings] = useSettings();

  const fmtCurrency = campaign.currency
    ? toCampaignCurrency(campaign.currency)
    : financial;

  const baseColumns: DataTableColumn[] = [
    {
      ...t('stats.impressions'),
      accessor: 'downloadTotal',
      type: 'number',
      Cell: ({d}) => <ImpressionsProgressBar {...d} />,
    },
    {
      ...t('stats.reach'),
      accessor: 'reachTotal',
      type: 'number',
      fmt: comma,
    },
    {
      ...t('stats.frequency'),
      accessor: 'frequency',
      type: 'number',
      fmt: toFixed,
    },
    {
      ...t('stats.spend', settings),
      accessor: 'costToDate',
      type: 'number',
      fmt: fmtCurrency,
    },
    {
      ...t('stats.cpm', settings),
      accessor: 'cpm',
      type: 'number',
      fmt: fmtCurrency,
    },
  ];

  const attributionColumns: DataTableColumn[] = [
    {
      ...t('stats.visitors'),
      accessor: 'attributionTotal',
      type: 'number',
      fmt: comma,
    },
    {
      ...t('stats.cr'),
      accessor: 'cr',
      type: 'number',
      fmt: toPercent,
    },
    {
      ...t('stats.cpv'),
      accessor: 'cpv',
      type: 'number',
      fmt: fmtCurrency,
    },
  ];

  const marketingColumns: DataTableColumn[] = [
    {
      ...t('stats.targetDownload'),
      accessor: 'targetDownloadTotal',
      type: 'number',
      fmt: toFixed,
    },
    {
      ...t('stats.targetReach'),
      accessor: 'targetReachTotal',
      type: 'number',
      fmt: toFixed,
    },
    {
      ...t('stats.cr'),
      accessor: 'cr',
      type: 'number',
      fmt: toPercent,
    },
  ];

  let columns = baseColumns.slice(0);

  const hasDrops = data.filter((item) => item.drops >= 1).length > 0;
  const isAttribution = campaign.kind?.toLowerCase() == 'attribution';
  if (hasDrops) {
    columns.splice(2, 0, {
      title: 'Spots',
      accessor: 'drops',
      type: 'number',
      Cell: ({d: {drops, totalDrops}}) => {
        if (!drops && !totalDrops) return <div>-</div>;
        return (
          <span>
            {drops}{' '}
            <span css={{color: '#666', fontSize: 'var(--font-size-body-2)'}}>
              / {totalDrops}
            </span>
          </span>
        );
      },
    });
  }

  if (isAttribution) {
    columns = columns.concat(attributionColumns.slice(0));
  }

  if (campaign.kind?.toLowerCase() == 'marketing') {
    columns = columns.concat(marketingColumns.slice(0));
  }

  if (isAttribution && organizationCanViewBrandData(organization, campaign)) {
    const hasValue = data.filter((item) => item.valueTotal > 1).length > 0;
    const hasPurchases =
      data.filter((item) => item.purchaseTotal >= 1).length > 0;
    const hasLeads = data.filter((item) => item.leadTotal >= 1).length > 0;
    const hasInstalled =
      data.filter((item) => item.installedTotal >= 1).length > 0;

    if (hasLeads) {
      columns.push({
        ...t('stats.leads'),
        accessor: 'leadTotal',
        type: 'number',
        fmt: toFixed,
      });
    }

    if (hasPurchases) {
      columns.push({
        ...t('stats.purchases'),
        accessor: 'purchaseTotal',
        type: 'number',
        fmt: toFixed,
      });
    }

    if (hasInstalled) {
      columns.push({
        ...t('stats.installs'),
        accessor: 'installedTotal',
        type: 'number',
        fmt: toFixed,
      });
    }

    if (hasValue) {
      columns.push({
        ...t('stats.revenue'),
        accessor: 'valueTotal',
        type: 'number',
        fmt: fmtCurrency,
      });
    }
  }

  const showExpectedDateRange = !!data.find(
    ({startAt, endAt}) => startAt || endAt
  );

  if (showExpectedDateRange) {
    columns.push({
      title: 'Expected Start / End',
      accessor: 'startAt',
      type: 'date',
      Cell: ({d: {startAt, endAt}}) => {
        if (!startAt || !endAt) return <div>-</div>;
        return (
          <div>
            {dayjs(startAt).format('MM/DD/YY')} -{' '}
            {dayjs(endAt).format('MM/DD/YY')}
          </div>
        );
      },
    });
  }

  return columns;
};

export default campaignTableColumns;
