import React from 'react';
import Badge from '@core/ui/Badge';
import {LabelMultipleSelect} from '@core/ui/FormElements';
import {LabelMultipleSelectProps} from '@core/ui/FormElements/LabelMultipleSelect';
import {Icon} from '@core/ui/Icon';
import {pixelInstallationTypes} from './helpers';

const MethodsDropdownItem = ({
  item: {title, value},
  active,
  ...props
}: {
  item: {
    title: string;
    value: string;
  };
  active: boolean;
}): JSX.Element => (
  <>
    {(value === 'JAVASCRIPT' || value === 'APPSFLYER') && (
      <div
        css={{
          color: 'var(--text-muted)',
          fontSize: '0.6875rem',
          letterSpacing: '.5px',
          padding: '0 1rem',
          textTransform: 'uppercase',
        }}>
        {value === 'JAVASCRIPT' ? 'Web' : 'Mobile'}
      </div>
    )}
    <div
      css={{
        alignItems: 'center',
        borderRadius: '0.375rem',
        color: active ? 'var(--black)' : 'inherit',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0.375rem 1rem',
        textDecoration: 'none',
        userSelect: 'none',
        fontWeight: active ? 700 : 400,
        ':hover': {
          background: 'var(--bg-muted)',
          color: 'var(--black)',
        },
      }}
      {...props}>
      {title}
      <Icon
        icon='checkmark'
        rules={() => ({
          flexShrink: 0,
          height: '1.25rem',
          marginLeft: '0.375rem',
          opacity: active ? 1 : 0,
          width: '1.25rem',
        })}
      />
    </div>
    {value === 'JAVASCRIPT' && (
      <div
        css={{
          background: 'var(--border-default)',
          height: '0.0675rem',
          margin: '0.375rem 0',
        }}
      />
    )}
  </>
);

interface IPixelMethodMultipleSelectProps
  extends Omit<LabelMultipleSelectProps, 'label' | 'items'> {}

const PixelMethodMultipleSelect = ({
  ...props
}: IPixelMethodMultipleSelectProps): JSX.Element => (
  <LabelMultipleSelect
    label='Methods'
    getSelectLabel={(items) => (
      <div css={{display: 'flex', gap: '0.275rem', marginTop: '0.275rem'}}>
        {items.map(({title}) => (
          <Badge key={title} variant='outlined'>
            {title}
          </Badge>
        ))}
      </div>
    )}
    items={pixelInstallationTypes}
    OptionContent={MethodsDropdownItem}
    {...props}
  />
);

export default PixelMethodMultipleSelect;
