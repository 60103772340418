import { cssColorValue, semanticColors } from "../../styles/index.js";
import { IconChevronDown } from "../Icon/icons/IconChevronDown/index.js";
import { getPadding } from "./Button.js";
import styled, { css } from "../../styled-components.js";
export var Arrow = styled(IconChevronDown).withConfig({
  displayName: "Arrow",
  componentId: "sc-1slzr1b-0"
})(["", ""], function (_ref) {
  var size = _ref.size;
  return css(["color:", ";pointer-events:none;position:absolute;right:", ";[dir='rtl'] &{left:", ";right:auto;}"], cssColorValue(semanticColors.essentialSubdued), getPadding(size), getPadding(size));
});