import {gql} from '@apollo/client';

export default gql`
  mutation updateMember($input: UpdateMemberInput!) {
    authed {
      success
    }
    updateMember(input: $input) {
      membership {
        id
        role
        roleDisplay
        createdAt
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;
