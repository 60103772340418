import {gql} from '@apollo/client';

export default gql`
  query FeedEpisodes($organizationId: ID!, $id: ID!, $limit: Int) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          feed(id: $id) {
            id
            episodes(limit: $limit) {
              id
              title
              subtitle
              summary
              description(words: 50)
              published
              reviewed
              hasNoAds
              placementsCount
              image {
                id
                href
                path
              }
              enclosure {
                id
                duration
                length
              }
            }

            createdAt
          }
        }
      }
    }
  }
`;
