import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {ManagePixelsRouteProps} from '../_routes';

export type ManagePixelsPixelRouteProps = ManagePixelsRouteProps & {
  pixel: TSFixMe;
};

export const usePixelRoutes = ({
  organization,
  key,
}: Pick<ManagePixelsPixelRouteProps, 'organization'> & {
  key: string;
}) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, `manage/pixels/${key}`),
    basePath: '/:part(b|a|s|p)/:slug/manage/pixels/:key',
    routes: [
      {title: 'Overview', key: 'overview', path: '', exact: true},
      {key: 'update', exact: true},
      {title: 'Debugger', key: 'debug', exact: true},
      {key: 'events'},
      {title: 'Conversion URLs', key: 'urls'},
      {title: 'Event Mappings', key: 'mappings'},
      {title: 'Pixel in Ad Studio', key: 'ad-studio'},
    ],
  });
};
