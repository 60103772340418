import {useEffect, useState} from 'react';
import {Text, TextLink} from '@spotify-internal/encore-web';
import {useHistory} from 'react-router-dom';
import {
  OnboardBodyContainer,
  OnboardContentContainer,
  OnboardPageContainer,
  OnboardTitleText,
} from '@analytics/components/onboard/OnboardLayout';
import SideGraphic from '@analytics/components/onboard/SideGraphic';
import {
  useSendVerifyEmailMutation,
  useUserInfoQuery,
} from '@analytics/graphql-api';
import SpotifyBanner from '../../components/onboard/SpotifyBanner';

const host = 'https://adanalytics.spotify.com';

const EMAIL_PROMPTS = {
  RESEND: 'Resend',
  SENDING: 'Sending...',
  ERROR: 'Somthing went wrong. Try again.',
  ERROR_ALREADY_VERIFIED: 'You are already verified!\nRedirecting...',
  SENT: 'Email Sent!',
};

const EmailConfirm = () => {
  const {data: userData} = useUserInfoQuery();
  const history = useHistory();
  useEffect(() => {
    if (userData?.userInfo?.hasVerifiedEmailForSpaa) {
      history.push('/');
    }
  }, [userData?.userInfo?.hasVerifiedEmailForSpaa, history]);

  const [emailPrompt, setEmailPrmopt] = useState(EMAIL_PROMPTS.RESEND);
  const [sendVerifyEmailMutation, {loading, error}] =
    useSendVerifyEmailMutation();

  const onContinue = async () => {
    try {
      setEmailPrmopt(EMAIL_PROMPTS.SENDING);
      await sendVerifyEmailMutation({variables: {input: {host}}});
      setEmailPrmopt(EMAIL_PROMPTS.SENT);
    } catch (e) {
      if (
        e?.message?.includes(
          'User already is verified for Spotify Ad Analytics.'
        )
      ) {
        setEmailPrmopt(EMAIL_PROMPTS.ERROR_ALREADY_VERIFIED);
        history.push('/');
        return;
      }
      setEmailPrmopt(EMAIL_PROMPTS.ERROR);
    }
  };

  return (
    <OnboardPageContainer>
      <OnboardContentContainer>
        <SpotifyBanner advertising position='flex-start' />
        <OnboardBodyContainer>
          <OnboardTitleText>Check your email</OnboardTitleText>
          <Text style={{paddingTop: 28}}>
            We’ve sent you a verification link.
          </Text>
          <Text
            variant='bodySmall'
            style={{fontSize: 16, color: '#727272', paddingTop: 44}}>
            Didn’t get an email?
            <TextLink
              style={{
                fontSize: 14,
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                color: '#000000',
                marginLeft: 4,
              }}
              href='#'
              onClick={onContinue}>
              {emailPrompt}
            </TextLink>
          </Text>
        </OnboardBodyContainer>
      </OnboardContentContainer>
      <SideGraphic />
    </OnboardPageContainer>
  );
};

export default EmailConfirm;
