import {gql} from '@apollo/client';
import CampaignDynamicPodcastFragment from './CampaignDynamicPodcastFragment';

export default gql`
  fragment CampaignDynamicOld on CampaignDynamicObject {
    id
    name
    art19Secret
    isPreRoll
    isMidRoll
    isPostRoll

    discountCode
    discountUrl
    effectiveUrl

    duration
    goal
    cost
    costToDate

    downloadTotal
    reachTotal
    attributionTotal
    userTotal
    userTotalModeled
    productTotal
    productTotalModeled
    addtocartTotal
    addtocartTotalModeled
    checkoutTotal
    checkoutTotalModeled
    purchaseTotal
    purchaseTotalModeled
    leadTotal
    leadTotalModeled
    valueTotal
    valueTotalModeled
    installedTotal
    installedTotalModeled

    targetDownloadTotal
    targetReachTotal

    startAt
    endAt

    dynamicType

    expectedStartAt
    expectedEndAt

    deliveries {
      id
      viewedBy {
        id
        firstName
        lastName
        email
      }
      viewedAt
    }

    providerName
    providerTrackingUrl

    campaignDynamicPodcasts {
      ...CampaignDynamicPodcast
    }

    isBaseline

    createdAt
    updatedAt
  }
  ${CampaignDynamicPodcastFragment}
`;
