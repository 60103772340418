import {useMemo} from 'react';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {FeedImage} from '@core/ui/Image';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {
  CampaignPodcastObject,
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
} from '@analytics/graphql-api';
import campaignTableColumns from '@analytics/lib/campaignTableColumns';
import {getCampaignPodcasts} from './helpers/getCampaignPodcasts';

type Props = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign: FetchedCampaign;
  campaignPodcasts: CampaignPodcastObject[];
};

const CampaignPodcastsTable = ({
  organization,
  campaign,
  campaignPodcasts,
}: Props) => {
  const data = getCampaignPodcasts(campaignPodcasts, {
    campaign,
    organization,
  }).map((obj) => {
    obj.feedTitle = obj.feed.title;
    return obj;
  });

  const columns = useMemo<DataTableColumn[]>(
    () =>
      [
        {
          title: 'Podcast',
          accessor: 'name',
          type: 'string' as const,
          Cell: ({d: {feed, name}}) => {
            return (
              <Stack
                alignItems='center'
                gap='var(--spacing-2)'
                css={{minWidth: '12.5rem'}}>
                <FeedImage
                  feed={feed}
                  width={350}
                  rules={() => ({
                    height: '4.375rem',
                    marginRight: '0.625rem',
                    paddingBottom: 0,
                    width: '4.375rem',
                  })}
                />
                <Text variant='subtitle2'>{name}</Text>
              </Stack>
            );
          },
        },
      ].concat(campaignTableColumns(organization, campaign, data)),
    [campaign, data, organization]
  );

  return (
    <DataTable
      searchPlaceholder='Search podcast'
      searchKeys={['feedTitle']}
      columns={columns}
      orderBy='-downloadTotal'
      data={data}
      onClickRowPath={({url}) => url}
    />
  );
};

export default CampaignPodcastsTable;
