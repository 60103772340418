import {gql} from '@apollo/client';

export interface ICreateReviewer {
  authed: {
    success: boolean;
  };
  createReviewer: {
    reviewer: {
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      type: string;
    };
  };
}

export interface ICreateReviewerInput {
  input: {
    email: string;
    firstName: string;
    lastName: string;
    type: string;
  };
}

export default gql`
  mutation createReviewer($input: CreateReviewerInput!) {
    authed {
      success
    }
    createReviewer(input: $input) {
      reviewer {
        id
        firstName
        lastName
        email
        type
      }
    }
  }
`;
