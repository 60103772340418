import {useState} from 'react';
import {useExportsContext} from '@analytics/components/notifications/ExportsNotificationContext';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  FetchedSavedExports,
  useCreateExportInstanceMutation,
} from '@analytics/graphql-api';
import {doCreateReportInstance} from '../helpers';
import {ReportsTableButton} from '../shared';

type Props = {
  disabled?: boolean;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  savedExport: FetchedSavedExports[number];
};

export function ReportRerunButton({
  disabled,
  organization,
  savedExport,
}: Props) {
  const {addExport} = useExportsContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [createExportInstance] = useCreateExportInstanceMutation();

  const onClickRerun = async () => {
    if (disabled) return;

    setLoading(true);
    if (error) setError(false);

    try {
      await doCreateReportInstance({
        createExportInstance,
        savedExport,
        onError: () => setError(true),
        organizationId: organization.id,
      });

      addExport(savedExport?.id);
    } catch (e) {
      console.warn(e.message);
      setError(true);
    }

    setLoading(false);
  };

  return (
    <ReportsTableButton
      error={error}
      loading={loading}
      buttonIconProps={{
        disabled,
        icon: 'sync',
        onClick: onClickRerun,
      }}
      tooltipProps={{
        description: 'Rerun report',
      }}
    />
  );
}
