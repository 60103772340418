import {gql} from '@apollo/client';

export default gql`
  query getBrandsOverview(
    $id: ID!
    $after: String
    $query: String
    $limit: Int
    $orderBy: String
    $before: String
    $minAds: Int
    $maxAds: Int
    $minPodcasts: Int
    $maxPodcasts: Int
    $minEpisodes: Int
    $maxEpisodes: Int
  ) {
    me {
      id
      organization(id: $id) {
        id
        research {
          brand {
            brandsOverview(
              limit: $limit
              query: $query
              after: $after
              orderBy: $orderBy
              before: $before
              minAds: $minAds
              maxAds: $maxAds
              minPodcasts: $minPodcasts
              maxPodcasts: $maxPodcasts
              minEpisodes: $minEpisodes
              maxEpisodes: $maxEpisodes
            ) {
              total
              results {
                id
                name
                slug
                adFirstPublished
                adsCount
                episodesCount
                feedsCount
              }
            }
          }
        }
      }
    }
  }
`;
