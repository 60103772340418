import {Redirect, Switch} from 'react-router-dom';
import {Alert} from '@core/ui/Alert';
import {
  LiftReportDeleteForm,
  LiftReportRange,
  LiftReportRunForm,
  LiftReportUpdateForm,
} from '@analytics/components/lift/IncrementalBrandLift';
import CampaignLiftReportNav from '@analytics/components/nav/CampaignLiftReportNav';
import {CampaignLiftRouteProps} from '../_routes';
import {useCampaignLiftReportRoutes} from './_routes';

export function CampaignLiftReportIndex({
  user,
  organization,
  campaign,
  liftReports,
  match: {
    url,
    params: {liftReportId},
  },
}: CampaignLiftRouteProps & {
  match: {
    params: {liftReportId: string};
    url: string;
  };
}) {
  const liftReport = liftReports.find((report) => report.id === liftReportId);

  if (!liftReport) {
    return <Alert severity='error'>Invalid Report ID</Alert>;
  }

  const {renderRoutes, routes} = useCampaignLiftReportRoutes({
    organization,
    campaign,
    liftReport,
    user,
  });

  return (
    <>
      <CampaignLiftReportNav
        campaign={campaign}
        isAdmin={user.isAdmin}
        items={routes}
        liftReport={liftReport}
        organization={organization}
      />
      <Switch>
        {renderRoutes({
          components: {
            lift: () => <Redirect to={`${url}/0/${liftReport.delta}`} />,
            delete: LiftReportDeleteForm,
            run: LiftReportRunForm,
            update: LiftReportUpdateForm,
            range: LiftReportRange,
          },
          extraProps: {
            user,
            organization,
            campaign,
            liftReport,
          },
        })}
      </Switch>
    </>
  );
}
