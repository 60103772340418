import {Route, Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import ManageNav from '@analytics/components/nav/ManageNav';
import {FetchedOrganization} from '@analytics/graphql-api';
import {AttributionConfig} from './AttributionConfig';
import {Config} from './Config';

export type ManageConfigRouteProps = {
  organization: FetchedOrganization;
  user: FetchedUser;
};

export function ManageConfigIndex({
  user,
  organization,
}: ManageConfigRouteProps) {
  return (
    <Switch>
      {[
        {
          exact: true,
          path: '/:part(b)/:slug/manage/config',
          Component: Config,
        },
        {
          exact: true,
          path: '/:part(b)/:slug/manage/config/attribution',
          Component: AttributionConfig,
        },
      ].map(({exact = false, path, Component}) => (
        <Route
          key={path}
          exact={exact}
          path={path}
          render={(routeProps) => {
            return (
              <>
                {exact && <ManageNav user={user} organization={organization} />}
                <Component
                  user={user}
                  organization={organization}
                  {...routeProps}
                />
              </>
            );
          }}
        />
      ))}
    </Switch>
  );
}
