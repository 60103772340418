import {comma, financial, toFixed} from '@core/lib/filters';
import {t} from '@core/texts';
import {Grid} from '@core/ui/Content';
import {CardStat} from '@core/ui/Stat';
import useSettings from '@analytics/components/settings/useSettings';
import {FetchedCampaign} from '@analytics/graphql-api';

export const CampaignLeadStats = ({campaign}: {campaign: FetchedCampaign}) => {
  const [settings] = useSettings();
  const {
    costToDate,
    downloads,
    downloadTotal,
    leadTotal,
    leadTotalModeled,
    reachTotal,
  } = campaign;

  const _downloadTotal =
    downloads && downloads.count ? downloads.count : downloadTotal;
  const _leadTotal = (settings.modelled ? leadTotalModeled : leadTotal) ?? 0;
  const costPerLead = _leadTotal ? (costToDate ?? 0) / _leadTotal : 0;

  return (
    <Grid
      cols={4}
      rules={() => ({
        gridGap: '1.5rem',
        margin: '0 1.5rem',
      })}>
      <CardStat value={comma(_downloadTotal)} {...t('stats.impressions')} />
      <CardStat value={comma(reachTotal ?? 0)} {...t('stats.reach')} />
      <CardStat value={toFixed(_leadTotal)} {...t('stats.leads')} />
      <CardStat value={financial(costPerLead)} {...t('stats.cpl')} />
    </Grid>
  );
};
