import {useState} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import {Card, CardHeader, CardHelp} from '@core/ui/Content';
import {Link} from '@core/ui/Link';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import Steps from '@core/ui/Steps';
import PixelEventOnboardForm from '@analytics/components/pixels/PixelEventOnboardForm';
import PixelOnboardForm from '@analytics/components/pixels/PixelOnboardForm';
import {ManagePixelsRouteProps} from './_routes';

export function AddPixel({organization, user}: ManagePixelsRouteProps) {
  const history = useHistory();
  const [updated, setUpdated] = useState(0);

  const showPixelStep = !organization.keys.length;
  const showEventsStep =
    dayjs(organization.keys?.[0]?.createdAt).isSameOrAfter('2022-05-03') &&
    !organization.keys?.[0]?.pixelEventRecords?.length;

  if (!showEventsStep && !showPixelStep)
    return <Redirect to={createOrgPath(organization, 'manage/pixels')} />;

  return (
    <ResponsiveContent containerSize='medium'>
      <Steps
        active={showPixelStep ? 'add-pixel' : 'add-events'}
        steps={[
          {title: 'Create a Pixel', value: 'add-pixel'},
          {title: 'Add Events', value: 'add-events'},
        ]}
        css={`
          margin: 2rem 1.5rem 1.75rem;
        `}
      />
      {showPixelStep ? (
        <>
          <CardHeader>Create a Pixel</CardHeader>
          <CardHelp rules={() => ({fontSize: '0.95rem'})}>
            Pixels allow you to measure user behavior from download to on-site
            activity. If you are a podcaster that also runs a website, Ad
            Analytics can help you attribute your audio efforts to push users to
            the site.
          </CardHelp>
          <Card>
            <PixelOnboardForm
              organization={organization}
              user={user}
              onSuccess={() => setUpdated(updated + 1)}
            />
          </Card>
        </>
      ) : (
        <>
          <CardHeader>Add Events</CardHeader>
          <CardHelp rules={() => ({fontSize: '0.95rem'})}>
            Ad Analytics can capture various conversion events including page
            views, leads, purchases, and{' '}
            <Link
              href='https://podsights.com/docs/#conversion-event-pixel-scripts'
              target='_blank'>
              more
            </Link>
            . Here you have the option to begin setting up a few events you'd
            like to track — We've included the default event to get you started.
          </CardHelp>
          <Card>
            <PixelEventOnboardForm
              organization={organization}
              onSuccess={() =>
                history.push(
                  createOrgPath(
                    organization,
                    `manage/pixels/${organization.keys[0].key}`
                  )
                )
              }
            />
          </Card>
        </>
      )}
    </ResponsiveContent>
  );
}
