import {gql} from '@apollo/client';

export default gql`
  query getCampaignApprovalConfig($organizationId: ID!, $campaignId: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          approvalConfig {
            publisherMembers {
              id
              firstName
              lastName
            }

            brandMembers {
              id
              firstName
              lastName
            }

            buySideAgencies {
              id
              name
              members {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;
