import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import searchFilter from '@core/lib/searchFilter';
import Button from '@core/ui/Button';
import EmptyMessage from '@core/ui/EmptyMessage';
import {Input} from '@core/ui/FormElements';
import {Icon} from '@core/ui/Icon';
import {Stack} from '@core/ui/Layout';
import Loading from '@core/ui/Loading';
import {Text} from '@core/ui/Text';
import {
  FetchedOrganization,
  FetchedPodcast,
  OrganizationObject,
  PodcastObject,
  useAudienceNielsenSegmentLazyQuery,
} from '@analytics/graphql-api';
import AudienceChartCard from './AudienceChartCard';
import AudienceHeader, {ArrowButton} from './AudienceHeader';
import {IntentData, getNielsenIntentData} from './helpers_chartData';
import intentBuckets from './intentBuckets';
import {AudienceCategory} from './types';

const PodcastPurchaseIntentClass = ({
  organization,
  podcast,
  classification,
  category,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [expanded, toggleExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<IntentData>([]);
  const [query, setQuery] = useState('');
  const [fetchIntentByClass, {data, error}] =
    useAudienceNielsenSegmentLazyQuery({
      variables: {
        organizationId: organization.id,
        podcastId: podcast.id,
        classifications: intentBuckets[classification],
      },
    });

  const onRowClick = () => {
    toggleExpanded(!expanded);
    if (!expanded) setQuery('');
  };

  useEffect(() => {
    if (!expanded || !!results.length) return;
    setLoading(true);
    fetchIntentByClass();
  }, [expanded, fetchIntentByClass, results.length]);

  useEffect(() => {
    if (!data || !!results.length) return;
    const intent = getNielsenIntentData(
      data?.me?.organization?.advisor?.podcast?.feed?.nielsenSegments,
      classification
    );
    setResults(intent);
    setLoading(false);
  }, [data, classification, results.length]);

  const filtered = searchFilter({data: results, query, searchKeys: ['topic']});

  return (
    <_Card expanded={expanded} withBorder>
      <_Row onClick={onRowClick}>
        <_RowHeader id='row-header'>{classification}</_RowHeader>
        <ArrowButton expanded={expanded} />
      </_Row>
      {expanded &&
        (loading ? (
          <Loading
            centered
            css={`
              margin-top: 2rem;
            `}
          />
        ) : error || !results?.length ? (
          <EmptyMessage>Purchase Intent data unavailable.</EmptyMessage>
        ) : (
          <>
            <_SearchBar>
              <_MagnifyIcon icon='magnify' />
              <Input
                domRef={inputRef}
                onChange={(e) => setQuery((e.target as HTMLInputElement).value)}
                placeholder='Search'
                small
                style={{paddingLeft: '2.5rem'}}
              />
            </_SearchBar>
            <_ChartsColumns>
              {filtered.map(({topic, metrics}, i) => (
                <_Chart key={i}>
                  <AudienceChartCard
                    category={category}
                    metrics={metrics}
                    topic={topic}
                  />
                </_Chart>
              ))}
            </_ChartsColumns>
            <Stack justifyContent='center'>
              <Button iconLeading='close' onClick={() => toggleExpanded(false)}>
                Collapse All
              </Button>
            </Stack>
          </>
        ))}
    </_Card>
  );
};

type Props = {
  organization: FetchedOrganization;
  podcast: FetchedPodcast;
  category: AudienceCategory;
  toggleCategory: () => void;
};

export default function AudiencePurchaseIntent({
  organization,
  podcast,
  category,
  toggleCategory,
}: Props) {
  const [expanded, toggleExpanded] = useState(true);
  const intentClasses = Object.keys(intentBuckets);

  return (
    <>
      <AudienceHeader
        organizationId={organization.id}
        podcast={podcast}
        category={category}
        toggleCategory={toggleCategory}
      />
      <_Card expanded={expanded}>
        <Stack justifyContent='space-between'>
          <Text color='muted' variant='h4'>
            Understanding Nielsen Purchase Intent
          </Text>
          <ArrowButton
            expanded={expanded}
            onClick={() => toggleExpanded(!expanded)}
          />
        </Stack>
        {expanded && (
          <Text color='muted' variant='body2' css={{margin: '0.5rem 0'}}>
            The data found in the charts below is based on Nielsen eXelate data
            and represents percentage or index of households matched to each of
            the below purchase intent segments. Intent means these households
            have not necessarily bought a product rather that they possess
            certain characteristics or behaviors as defined by Nielsen
            demonstrating the Household is interested in a specific product
            type.
          </Text>
        )}
      </_Card>
      {intentClasses.map((classification, i) => (
        <PodcastPurchaseIntentClass
          key={`purchase-intent-${i}`}
          classification={classification}
          category={category}
          organization={organization}
          podcast={podcast}
        />
      ))}
    </>
  );
}

type _CardProps = {
  withBorder?: boolean;
  expanded?: boolean;
};

const _Card = styled.div<_CardProps>`
  background: ${(div) =>
    div.withBorder ? 'var(--bg-surface)' : 'var(--bg-muted)'};
  border: ${(div) => div.withBorder && '1px solid var(--border-default)'};
  border-radius: 0.5rem;
  font-size: 85%;
  margin: 1rem 1.5rem 0;
  padding: 1.25rem;
  transition: ${(div) => div.withBorder && '0.3s all ease'};
  :hover {
    #row-header {
      color: ${(div) => (div.expanded ? 'var(--blue)' : 'inherit')};
      cursor: ${(div) => (div.expanded ? 'pointer' : 'auto')};
    }
  }
`;

const _Chart = styled.div`
  display: inline-block;
  margin: 0 0 1rem;
  width: 100%;
`;

const _ChartsColumns = styled.div`
  column-count: 2;
  column-gap: 1rem;
`;

const _MagnifyIcon = styled(Icon)`
  bottom: 0;
  color: var(--icon-muted);
  height: 1.25rem;
  left: 0.625rem;
  margin: auto;
  position: absolute;
  top: 0;
`;

const _Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0.25rem;
  :hover {
    color: var(--blue);
    cursor: pointer;
  }
`;

const _RowHeader = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
`;

const _SearchBar = styled.div`
  position: relative;
  margin: 1.75rem auto;
`;
