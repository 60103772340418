import {useState} from 'react';
import styled from 'styled-components';
import DataTablePagination from '@core/ui/DataTable/DataTablePagination';
import EmptyMessage from '@core/ui/EmptyMessage/EmptyMessage';
import {Stack} from '@core/ui/Layout';
import {GroupBy, SortBy, TableItem} from '../_types';
import {UsageTableRow} from './UsageTableRow';
import {groupByTableData} from './_helpers';
import {ExpandButton, SortIcon, TableHeader, Title} from './_shared';

export function UsageTable({
  data = [],
  groupBy = [],
  paginationRowsPerPage = 20,
  paginationRowsPerPageOptions = [20, 50, 100],
  ...props
}: {
  data: TableItem[];
  groupBy: GroupBy[];
  paginationRowsPerPage?: number;
  paginationRowsPerPageOptions?: number[];
}) {
  const [expandAll, setExpandAll] = useState(true);
  const [rowStart, setRowStart] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(paginationRowsPerPage);
  const [sortBy, setSortBy] = useState<SortBy>('desc');

  const groupedData = groupByTableData({data, groupBy, sortBy});
  const currentPageData = groupedData.slice(rowStart, rowsPerPage + rowStart);

  return (
    <>
      {data.length > 0 ? (
        <>
          <div css={{minHeight: 0, overflowY: 'auto'}} {...props}>
            <_Table>
              <thead>
                <tr>
                  <TableHeader>
                    <Stack alignItems='center'>
                      {!!groupBy.length && (
                        <ExpandButton
                          expandAll={expandAll}
                          onClick={() => setExpandAll(!expandAll)}
                        />
                      )}
                      <Title>Row Label</Title>
                    </Stack>
                  </TableHeader>
                  <TableHeader
                    active
                    onClick={() =>
                      setSortBy(sortBy === 'asc' ? 'desc' : 'asc')
                    }>
                    <Stack alignItems='center'>
                      <SortIcon sortBy={sortBy} />
                      <Title>Impressions</Title>
                    </Stack>
                  </TableHeader>
                </tr>
              </thead>
              <tbody>
                {currentPageData.map((d) => (
                  <UsageTableRow key={d.id} rowData={d} expandAll={expandAll} />
                ))}
              </tbody>
            </_Table>
          </div>
          <DataTablePagination
            count={data.length}
            paginationRowsPerPage={paginationRowsPerPage}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            rowStart={rowStart}
            onRowStartChange={setRowStart}
            onRowsPerPageChange={setRowsPerPage}
          />
        </>
      ) : (
        <EmptyMessage>No data available.</EmptyMessage>
      )}
    </>
  );
}

const _Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.875rem;
  margin: 0;
  padding: 1rem;
  text-align: left;
  width: 100%;
`;
