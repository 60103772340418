import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import EpisodeDetail from '@analytics/components/research/EpisodeDetail';

const EpisodesIndex = ({user, organization, company, feed}) => {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/research/brands/:companyId/podcasts/:feedId/episodes/:episodeId'
        user={user}
        organization={organization}
        company={company}
        feed={feed}
        component={EpisodeDetail}
      />
    </Switch>
  );
};

export default EpisodesIndex;
