import {
  InputLabel as MuiInputLabel,
  type InputLabelProps as MuiInputLabelProps,
  ThemeOptions,
} from '@mui/material';

export const InputLabel = MuiInputLabel;
export type InputLabelProps = MuiInputLabelProps;

export const MuiInputLabelStyles: ThemeOptions['components'] = {
  MuiInputLabel: {
    styleOverrides: {
      shrink: {
        backgroundColor: 'transparent',
        transform: 'scale(.75) translate(21px, 6px)',
      },
      root: {
        transform: 'translate(14px,18px) scale(1)',
      },
    },
  },
};
