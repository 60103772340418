import {useQuery} from '@apollo/client';
import {useLocation} from 'react-router-dom';
import MapDownloads from '@core/components/MapDownloads';
import {findRegion} from '@core/lib/countryRegionData';
import {createOrgPath} from '@core/lib/organizations';
import {ToastMessage} from '@core/ui/Alert';
import {Breadcrumbs} from '@core/ui/Breadcrumbs';
import {Card} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import {AttributionCitiesTable} from '@analytics/components/attribution/AttributionLocationTable';
import BRAND_ORGANIZATION_IMPRESSIONS_REGION_LOCATION_QUERY from '@analytics/graphql-api/_old_queries/BrandOrganizationImpressionsRegionLocationQuery';
import {MeasureInsightsLocationsRouteProps} from '.';

type Props = MeasureInsightsLocationsRouteProps & {
  match: {url: string; params: any};
};

export function InsightsLocationsRegion({
  after,
  before,
  campaigns,
  match: {
    params: {country, region},
  },
  organization,
  publishers,
}: Props) {
  const {search} = useLocation();
  const {data, loading, error} = useQuery(
    BRAND_ORGANIZATION_IMPRESSIONS_REGION_LOCATION_QUERY,
    {
      variables: {
        id: organization.id,
        after,
        before,
        country,
        region,
        publisherIds: publishers.map(({id}) => id),
        campaignIds: campaigns.map(({id}) => id),
      },
    }
  );

  if (loading) {
    return (
      <LoadingSpinner
        centered
        css={`
          margin-top: var(--spacing-5);
        `}
      />
    );
  } else if (error) {
    return (
      <ToastMessage alertType='warning' rules={() => ({margin: '1rem 0'})}>
        Error loading data. Please try again later.
      </ToastMessage>
    );
  }

  const nodes =
    data?.me?.organization?.overview?.impressions?.locations?.nodes ?? [];
  const cities =
    data?.me?.organization?.overview?.impressions?.locations?.cities ?? [];
  const count =
    data?.me?.organization?.overview?.impressions?.locations?.count ?? 0;

  const regionMeta = findRegion({
    countryIso2Code: country,
    regionIso2Code: region,
  });

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            to:
              createOrgPath(organization, 'measure/overview/locations') +
              search,
            title: 'Locations',
          },
          {
            to:
              createOrgPath(
                organization,
                `measure/overview/locations/${country}`
              ) + search,
            title: regionMeta?.country?.countryName || country,
          },
          {
            title: regionMeta?.name || region,
          },
        ]}
        css={`
          margin: 1.25rem 1.5rem 0;
          padding: 0;
        `}
      />
      {nodes.length > 0 && (
        <Card
          css={{
            border: 0,
            display: 'flex',
            margin: '1.25rem 1.5rem 0',
            overflow: 'hidden',
            padding: 0,
            position: 'relative',
          }}>
          <MapDownloads height={500} locations={nodes} />
        </Card>
      )}
      <Card css={{margin: '1rem 1.5rem 0'}}>
        <AttributionCitiesTable cities={cities} count={count} />
      </Card>
    </>
  );
}
