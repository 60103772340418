import {t} from '@core/texts';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import {canCampaignRequestLift} from '@analytics/components/lift/IncrementalBrandLift';
import {LiftReportTable} from '@analytics/components/lift/IncrementalBrandLift';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignLiftRouteProps} from './_routes';

export function CampaignLift({
  campaign,
  liftReports,
  match: {url},
  organization,
  user,
}: CampaignLiftRouteProps & {
  match: {
    url: string;
  };
}) {
  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <Card>
        <CardHeader
          rightContent={
            canCampaignRequestLift(campaign) && (
              <Button to={`${url}/request`} color='secondary'>
                New Lift Report
              </Button>
            )
          }>
          Conversion Lift Reports
        </CardHeader>
        {canCampaignRequestLift(campaign) ? (
          <LiftReportTable
            organization={organization}
            campaign={campaign}
            liftReports={liftReports}
            user={user}
          />
        ) : (
          <EmptyMessage>{t('lift.notAvailable.description')}</EmptyMessage>
        )}
      </Card>
    </>
  );
}
