import {FC, PropsWithChildren} from 'react';
import dayjs from 'dayjs';
import {TooltipProps} from 'recharts';
import {comma} from '@core/lib/filters';

export type ChartTimeTooltipProps = PropsWithChildren<{
  dateFormat?: string;
  displayZeroValues?: boolean;
  exclude?: string[];
  label?: string;
  valueToFixed?: number;
}> &
  TooltipProps<number, string>;

export const ChartTimeTooltip: FC<ChartTimeTooltipProps> = ({
  children,
  dateFormat = "DD MMM 'YY · HH:00",
  displayZeroValues = false,
  exclude = [],
  label,
  payload,
  valueToFixed = 0,
}): JSX.Element | null => {
  if (!Array.isArray(payload) || !payload.length) {
    return null;
  }

  const data = payload.filter(({name, value}) => {
    if (exclude.length && exclude.indexOf(name) > -1) {
      return false;
    }
    return displayZeroValues ? true : value > 0;
  });

  return (
    <div
      css={{
        background: 'var(--bg-overlay)',
        borderRadius: '.5rem',
        boxShadow: 'var(--shadow-xl)',
        color: 'var(--text-muted)',
        fontSize: '0.875rem',
        lineHeight: 1.4,
        padding: '0.625rem',
      }}>
      <div
        css={{
          fontSize: '0.875rem',
          marginBottom: '0.25rem',
          textTransform: 'capitalize',
        }}>
        {dayjs(label).utc().format(dateFormat)}
      </div>
      {data.map(({name, color, value}) => (
        <div
          key={name}
          css={{
            color,
            display: 'grid',
            fontWeight: 500,
            gridGap: '1rem',
            gridTemplateColumns: '1fr 1fr',
            padding: '0.0625rem 0',
          }}>
          <div css={{textTransform: 'capitalize'}}>
            {name
              .replace(/_/g, ' ')
              .replace(/([A-Z])/g, ' $1')
              .trim()}
          </div>
          {comma(value, valueToFixed).replace(
            `.${Array.from(Array(valueToFixed).keys())
              .map(() => 0)
              .join('')}`,
            ''
          )}
        </div>
      ))}
      {children}
    </div>
  );
};
