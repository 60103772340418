import {gql} from '@apollo/client';

export default gql`
  mutation createFutureEpisode($input: CreateFutureEpisodeInput!) {
    authed {
      success
    }
    createFutureEpisode(input: $input) {
      organizationId
      podcastId
      futureEpisode {
        id
        title
        subtitle
        expectedPublished
        createdAt

        campaignEpisodes {
          id
          campaignPodcast {
            id
          }
        }
      }
    }
  }
`;
