import EpisodePlayer from '@core/components/EpisodePlayer';
import {Card, CardHeader} from '@core/ui/Content';
import {CampaignDynamicEpisodeOmniChart} from '@analytics/components/campaigns/charts';
import {CampaignDynamicEpisodeStats} from '@analytics/components/campaigns/stats/CampaignDynamicEpisodeStats';
import CampaignDynamicEpisodeNav from '@analytics/components/nav/CampaignDynamicEpisodeNav';
import {CampaignDynamicPodcastEpisodeRouteProps} from '.';

export function CampaignDynamicEpisode({
  organization,
  campaign,
  campaignDynamic,
  campaignDynamicPodcast,
  campaignDynamicEpisode,
}: CampaignDynamicPodcastEpisodeRouteProps) {
  return (
    <>
      <CampaignDynamicEpisodeNav
        organization={organization}
        campaign={campaign}
        campaignDynamic={campaignDynamic}
        campaignDynamicPodcast={campaignDynamicPodcast}
        campaignDynamicEpisode={campaignDynamicEpisode}
      />
      <CampaignDynamicEpisodeStats
        campaign={campaign}
        campaignDynamicEpisode={campaignDynamicEpisode}
      />
      <Card rules={() => ({marginTop: '2.375rem'})}>
        <CampaignDynamicEpisodeOmniChart
          organization={organization}
          campaign={campaign}
          campaignDynamic={campaignDynamic}
          campaignDynamicPodcast={campaignDynamicPodcast}
          campaignDynamicEpisode={campaignDynamicEpisode}
        />
      </Card>
      <Card>
        <CardHeader>Episode</CardHeader>
        <EpisodePlayer episode={campaignDynamicEpisode?.episode} height={75} />
      </Card>
    </>
  );
}
