import { screenXsMin } from '@spotify-internal/encore-foundation';
import { cssBorderRadius, cssBorderWidth, cssColorValue, cssSpacing } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
export var Background = styled.div.withConfig({
  displayName: "Background",
  componentId: "sc-1tbg7b2-0"
})(["", ""], function (_ref) {
  var $background = _ref.$background,
    $variant = _ref.$variant;
  return css(["align-items:center;display:flex;@media (min-width:", "){padding:", ";}", " ", ""], screenXsMin, cssSpacing('looser', '24px'), $variant === 'fullscreen' && css(["block-size:100%;"]), $variant === 'contextual' && css(["background-color:", ";border:", " solid transparent;border-radius:", ";", " ", ""], cssColorValue('backgroundHighlight'), cssBorderWidth('hairline', '1px'), cssBorderRadius('larger-2', '8px'), $background && css(["@media (min-width:", "){background-clip:content-box;background-color:transparent;background-image:url(", ");background-position-y:center;background-repeat:no-repeat;border-color:", ";}"], screenXsMin, $background.url, cssColorValue('decorativeSubdued')), $background && css(["@media (min-width:", "){min-block-size:", "px;}"], screenXsMin, $background.height)));
});