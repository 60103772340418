import {Switch, useParams} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {LoadingSpinner} from '@core/ui/Loading';
import {Text} from '@core/ui/Text';
import Bulk from '@analytics/components/bulk/Bulk';
import {useCampaignQuery} from '@analytics/graphql-api';
import {CampaignsRouteProps} from '../_routes';
import Campaign from './Campaign';
import {CampaignDebug} from './CampaignDebug';
import {CampaignDelete} from './CampaignDelete';
import {CampaignFillDynamics} from './CampaignFillDynamics';
import {CampaignFrequency} from './CampaignFrequency';
import {CampaignOverlap} from './CampaignOverlap';
import {CampaignPlayers} from './CampaignPlayers';
import CampaignState from './CampaignState';
import {CampaignUpdate} from './CampaignUpdate';
import {useCampaignRoutes} from './_routes';
import {CampaignActivityLog} from './activity-log';
import {CampaignApprovalsIndex} from './approvals';
import {CampaignDynamicsIndex} from './dynamics';
import {CampaignInsights} from './insights';
import {CampaignLiftIndex} from './lift';
import {CampaignLinksIndex} from './links';
import {CampaignLocationsIndex} from './locations';
import {CampaignPermissionsIndex} from './permissions';
import {CampaignPixelsIndex} from './pixels';
import {CampaignPlacementsIndex} from './placements';
import {CampaignPodcastsIndex} from './podcasts';
import {CampaignRevenueIndex} from './revenue';
import CampaignStreaming from './streaming';
import {CampaignTargetsIndex} from './targets';

export function CampaignIndex({user, organization}: CampaignsRouteProps) {
  const {campaignSlug} = useParams<{campaignSlug: string}>();
  const {data, loading, error} = useCampaignQuery({
    variables: {
      organizationId: organization.id,
      slug: campaignSlug,
    },
  });

  if (loading) {
    return <LoadingSpinner fixed />;
  }

  if (error || !data?.me?.organization?.campaign) {
    return (
      <div css={{marginTop: 'var(--spacing-15)', textAlign: 'center'}}>
        <Text
          variant='h2'
          color='muted'
          css={{marginBottom: 'var(--spacing-2)'}}>
          Campaign not found
        </Text>
        <Text variant='body1' color='muted'>
          We can't seem to find the campaign you are looking for.
        </Text>
        <Button
          to={createOrgPath(organization, '/measure/campaigns')}
          color='primary'
          css={{marginTop: 'var(--spacing-6)'}}>
          Back to campaigns
        </Button>
      </div>
    );
  }

  const campaign = data?.me?.organization?.campaign;
  const {renderRoutes} = useCampaignRoutes({organization, campaign, user});

  return (
    <Switch>
      {renderRoutes({
        components: {
          activity: CampaignActivityLog,
          approvals: CampaignApprovalsIndex,
          bulk: Bulk,
          debug: CampaignDebug,
          delete: CampaignDelete,
          dynamics: CampaignDynamicsIndex,
          frequency: CampaignFrequency,
          ingest: CampaignFillDynamics,
          insights: CampaignInsights,
          lift: CampaignLiftIndex,
          links: CampaignLinksIndex,
          locations: CampaignLocationsIndex,
          overlap: CampaignOverlap,
          overview: Campaign,
          permissions: CampaignPermissionsIndex,
          pixels: CampaignPixelsIndex,
          players: CampaignPlayers,
          placements: CampaignPlacementsIndex,
          podcasts: CampaignPodcastsIndex,
          revenue: CampaignRevenueIndex,
          state: CampaignState,
          streaming: CampaignStreaming,
          targets: CampaignTargetsIndex,
          update: CampaignUpdate,
        },
        extraProps: {
          user,
          organization,
          campaign,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
