import {
  FetchedOrganization,
  PodcastsAudienceQuery,
} from '@analytics/graphql-api';
import {
  belowUniqueHhCount,
  getExperianMetrics,
  getNielsenMetrics,
} from './helpers_chartData';
import {demographics} from './segmentsBuckets';

const filterBuckets = (data) => {
  return data.filter(({topic}) => {
    const t = topic.toLowerCase();
    return (
      demographics[topic] &&
      (t.includes('age') ||
        t.includes('income') ||
        t.includes('race') ||
        t.includes('education') ||
        t.includes('political'))
    );
  });
};

const getDemographics = (
  data: PodcastsAudienceQuery,
  type: 'nielsen' | 'experian'
) => {
  const filtered = filterBuckets(data);
  const metrics = {};

  filtered.forEach((x) => {
    const {topic} = demographics[x.topic];

    const items =
      type === 'nielsen'
        ? getNielsenMetrics(x.metrics, topic)
        : getExperianMetrics(x.metrics, topic);

    metrics[topic] = items;
    metrics[`${topic}Max`] = items.reduce(
      (a, {index}) => (a > index ? a : index),
      0
    );
    metrics[`${topic}Idx`] = items
      .filter(({index}) => index >= 100)
      .map(({variable}) => variable.toLowerCase());
  });

  return metrics;
};

const getAudienceTableData = (podcasts, organization: FetchedOrganization) => {
  const experianData = [];
  const nielsenData = [];

  podcasts.forEach(
    ({
      id,
      feed: {
        title,
        image,
        experianSegments,
        isExperianApproved,
        nielsenSegments,
        isNielsenApproved,
      },
    }) => {
      let n;
      let e;

      if (
        nielsenSegments &&
        nielsenSegments.length &&
        !belowUniqueHhCount(nielsenSegments) &&
        (isNielsenApproved || organization.isNielsenApproved)
      ) {
        n = getDemographics(nielsenSegments, 'nielsen');
      }

      if (
        experianSegments &&
        experianSegments.length &&
        !belowUniqueHhCount(experianSegments) &&
        (isExperianApproved || organization.isExperianApproved)
      ) {
        e = getDemographics(experianSegments, 'experian');
      }

      experianData.push({id, title, image, organization, ...e});
      nielsenData.push({id, title, image, organization, ...n});
    }
  );

  return [experianData, nielsenData];
};

export default getAudienceTableData;
