import {gql} from '@apollo/client';

export default gql`
  query getBrandOrganizationOverlap($id: ID!, $after: Date, $before: Date) {
    me {
      id
      organization(id: $id) {
        id
        overlap(after: $after, before: $before) {
          pairs {
            baseCampaignDynamicId
            baseCampaignDynamic {
              id
              name
            }
            baseFeedId
            baseFeed {
              id
              title
              image {
                id
                width
                height
                path
              }
            }
            compCampaignDynamicId
            compCampaignDynamic {
              id
              name
            }
            compFeedId
            compFeed {
              id
              title
              image {
                id
                width
                height
                path
              }
            }

            baseReach
            compReach
            overlap
          }
        }
      }
    }
  }
`;
