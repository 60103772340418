import {capitalize} from '@core/lib/filters';
import {Stack} from '@core/ui/Layout';
import {LabelItem, SignificanceLegend} from '../shared';

export function BrandLiftBarChartLegend() {
  return (
    <Stack
      alignItems='center'
      css={{width: '100%'}}
      gap='var(--spacing-2)'
      justifyContent='space-between'>
      <Stack
        alignItems='center'
        gap='var(--spacing-4)'
        css={{marginLeft: 'var(--spacing-8)'}}>
        {(['control', 'exposed'] as const).map((group) => (
          <LabelItem key={group} icon={group} label={capitalize(group)} />
        ))}
      </Stack>
      <SignificanceLegend />
    </Stack>
  );
}
