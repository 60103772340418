import {useEffect} from 'react';
import {LazyQueryExecFunction} from '@apollo/client';
import {
  Exact,
  GetAdvisorColumnsQuery,
  InputMaybe,
  OrganizationObject,
} from '@analytics/graphql-api';
import {ActiveWorkspace} from '../AdvisorTool';
import {columnGroupsConfig, getDefaultColumns} from '../columns';

type FetchColumns = LazyQueryExecFunction<
  GetAdvisorColumnsQuery,
  Exact<{
    organizationId: string;
    ids: InputMaybe<string> | InputMaybe<string>[];
  }>
>;

export const SIDEBAR_NAV_ITEMS = [
  {
    title: 'Added Columns',
    columnGroupName: '',
    columnGroupId: undefined,
  },
  ...columnGroupsConfig,
] as const;

export const getColumnGroupId = (str: string) =>
  str.indexOf('::') > 0 ? str.slice(0, str.indexOf('::')) : 'general';

export function useDefaultWorkspaceIfNoSelection(
  workspaceId: string,
  fetchColumns: FetchColumns,
  organization: OrganizationObject
) {
  return useEffect(() => {
    if (workspaceId) return;

    fetchColumns({
      variables: {
        organizationId: organization.id,
        ids: getDefaultColumns(organization),
      },
    });
  }, [workspaceId, organization, fetchColumns]);
}

export function useResetSearchOnColumnSelection(
  setSearchValue: (value: string) => void,
  selectedColumnGroup: (typeof SIDEBAR_NAV_ITEMS)[number]
) {
  return useEffect(() => setSearchValue(''), [selectedColumnGroup]);
}

export function useResetDropdownOnOpen(
  opened: boolean,
  setSearchValue: (value: string) => void,
  setSelectedColumnGroup: (value: (typeof SIDEBAR_NAV_ITEMS)[number]) => void
) {
  return useEffect(() => {
    if (!opened) return;

    setSearchValue('');
    setSelectedColumnGroup(SIDEBAR_NAV_ITEMS[0]);
  }, [opened]);
}

export function useActiveWorkspaceIfAvailable(
  activeWorkspace: ActiveWorkspace | undefined,
  fetchColumns: FetchColumns,
  organization: OrganizationObject
) {
  return useEffect(() => {
    if (!activeWorkspace) return;

    try {
      const {columns} = JSON.parse(activeWorkspace.data);
      fetchColumns({
        variables: {
          organizationId: organization.id,
          ids: columns,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, [activeWorkspace, organization.id, fetchColumns]);
}
