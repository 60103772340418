import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import dayjs from '@core/lib/dayjs';
import {gtag} from '@core/lib/gtag';
import useQueryParams from '@core/lib/useQueryParams';
import {Card} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import {LoadingSpinner} from '@core/ui/Loading';
import Select, {MultipleSelect} from '@core/ui/Select';
import InsightsDatePicker from '@analytics/components/insights/InsightsDatePicker';
import InsightsPerformanceChart from '@analytics/components/insights/InsightsPerformanceChart';
import InsightsPerformanceStats from '@analytics/components/insights/InsightsPerformanceStats';
import InsightsPerformanceTable from '@analytics/components/insights/InsightsPerformanceTable';
import useInsightsPerformanceData from '@analytics/components/insights/useInsightsPerformanceData';
import useSettings from '@analytics/components/settings/useSettings';
import ORGANIZATION_OVERVIEW_BRAND_QUERY, {
  IOrganizationOverviewBrand,
  IOrganizationOverviewBrandInput,
} from '@analytics/graphql-api/_old_queries/OrganizationOverviewBrandQuery';
import {MeasureInsightsRouteProps} from '.';

export function InsightsPerformance({
  after,
  before,
  organization,
}: MeasureInsightsRouteProps) {
  const [settings] = useSettings();
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
  const [selectedPublishers, setSelectedPublishers] = useState<string[]>([]);
  const {
    params: {kind, interval},
    set: setQueryParam,
  } = useQueryParams(['kind', 'interval'], {
    kind: 'attribution',
    interval: 'day',
  });
  const {data, loading, error} = useQuery<
    IOrganizationOverviewBrand,
    IOrganizationOverviewBrandInput
  >(ORGANIZATION_OVERVIEW_BRAND_QUERY, {
    variables: {
      after,
      before: dayjs(before).add(1, 'day').format('YYYY-MM-DD'),
      id: organization.id,
      interval: interval as any,
      spendByImpressions: settings.spendByImpressionsDelivered,
    },
  });

  const resetSelectedItems = () => {
    setSelectedCampaigns([]);
    setSelectedPublishers([]);
  };

  let content: React.ReactNode;
  let filtersContent: React.ReactNode;

  if (loading) {
    content = (
      <div css={{margin: '.75rem 0'}}>
        <LoadingSpinner centered />
      </div>
    );
  } else if (error) {
    content = (
      <Card rules={() => ({margin: '1rem 1.5rem'})}>
        <EmptyMessage>
          No performance data found for the selected date range.
        </EmptyMessage>
      </Card>
    );
  } else {
    const lineItems = (
      data?.me?.organization?.overview?.brand?.nodes || []
    ).filter((item) => item.campaignKind === kind);

    let currentLineItems = lineItems;
    if (selectedCampaigns.length) {
      currentLineItems = lineItems.filter((item) =>
        selectedCampaigns.includes(item.campaignId)
      );
    } else if (selectedPublishers.length) {
      currentLineItems = lineItems.filter((item) =>
        selectedPublishers.includes(item.publisherName)
      );
    }

    const {tableData, chartData, statsData, statsSeries, chartSeries} =
      useInsightsPerformanceData(currentLineItems);
    const {campaigns, publishers} = getSelectItems({lineItems});

    filtersContent = (
      <>
        <Select
          items={[
            {title: 'Attribution', value: 'attribution'},
            {title: 'Marketing', value: 'marketing'},
            {title: 'Reporting', value: 'reporting'},
          ]}
          defaultValue={kind}
          onSelect={(item) => setQueryParam('kind', item.value)}
          placeholder='Campaign Type'
          outline
        />
        <MultipleSelect
          key={`publisher-${kind}`}
          items={publishers}
          searchable
          searchKeys={['title']}
          propertyForValue='title'
          placeholder='Publishers'
          onSelect={(items) => {
            setSelectedPublishers(items.map((item) => item.title));
            setSelectedCampaigns([]);
          }}
          outline
        />
        <MultipleSelect
          key={`campaigns-${kind}-${selectedPublishers.join(':')}`}
          placeholder='Campaigns'
          items={
            selectedPublishers.length
              ? campaigns.filter((campaign) =>
                  selectedPublishers.includes(campaign.publisherName)
                )
              : campaigns
          }
          searchable
          searchKeys={['title']}
          propertyForValue='campaignId'
          onSelect={(items) => {
            setSelectedCampaigns(items.map((item) => item.campaignId));
          }}
          outline
        />
      </>
    );

    content = campaigns.length ? (
      <>
        <Card rules={() => ({marginTop: '1rem'})}>
          <InsightsPerformanceChart
            data={chartData}
            interval={interval as any}
            series={chartSeries}
          />
        </Card>
        <InsightsPerformanceStats
          data={statsData}
          before={before}
          after={after}
          organization={organization}
          lineItems={currentLineItems}
          campaignKind={kind}
          series={statsSeries}
        />
        <Card rules={() => ({marginTop: '1rem'})}>
          <InsightsPerformanceTable data={tableData} series={statsSeries} />
        </Card>
      </>
    ) : (
      <Card rules={() => ({marginTop: '1rem'})}>
        <EmptyMessage>No data found for the selected dates.</EmptyMessage>
      </Card>
    );
  }

  return (
    <>
      <Card
        css={`
          background: var(--bg-subtle);
        `}>
        <div
          css={`
            display: flex;
            gap: 0.75rem;
          `}>
          <InsightsDatePicker
            defaultValue={{after, before}}
            isOutsideRange={(date) => date.isAfter(dayjs())}
            onChange={() => {
              resetSelectedItems();
              gtag('event', 'date_range_adjusted', {
                event_category: 'measure_overview',
              });
            }}
          />
          <Select
            items={[
              {title: 'Weekly', value: 'week'},
              {title: 'Daily', value: 'day'},
            ]}
            onSelect={({value}) => {
              setQueryParam('interval', value);
              resetSelectedItems();
              gtag('event', value, {
                event_category: 'measure_overview',
              });
            }}
            defaultValue={interval}
            outline
          />
          {filtersContent}
        </div>
      </Card>
      {content}
    </>
  );
}

const getSelectItems = ({
  lineItems,
}): {publishers: any[]; campaigns: any[]} => ({
  publishers: [
    ...new Map(
      lineItems.map((item: any) => [
        item.publisherName,
        {title: item.publisherName},
      ])
    ).values(),
  ],
  campaigns: [
    ...new Map(
      lineItems.map((item: any) => [
        item.campaignName,
        {
          title: item.campaignName,
          campaignName: item.campaignName,
          campaignId: item.campaignId,
          publisherName: item.publisherName,
        },
      ])
    ).values(),
  ],
});
