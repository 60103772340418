import {Stack} from '@mui/system';
import {comma} from '@core/lib/filters';
import {Card} from '@core/ui/Content';
import ProgressBar from '@core/ui/ProgressBar';
import Stat from '@core/ui/Stat';

export function _CampaignImpressionsCard({stats, goal}: TSFixMe) {
  const pct = Math.floor((stats.impressions.value * 100) / goal);
  const displayGoal = typeof goal === 'number';

  return (
    <Card
      css={`
        display: flex;
        flex-direction: column;
        margin: 0;
        gap: var(--spacing-4);
        padding: var(--spacing-7);
      `}
      elevated>
      <Stack direction='column'>
        <Stat {...stats.impressions} />
        {displayGoal && (
          <div
            css={`
              margin: 1rem 0 1.5rem;
            `}>
            <ProgressBar
              value={stats.impressions.value}
              max={goal}
              css={`
                border-radius: var(--radius-2);
                height: 10px;
                margin-bottom: 0.5rem;
              `}
            />
            <div
              css={`
                color: var(--text-muted);
                font-size: 0.875rem;
              `}>
              {pct}% of {comma(goal)}
            </div>
          </div>
        )}
      </Stack>
      <Stack
        gap={4}
        css={`
          padding-bottom: var(--spacing-2);
        `}>
        <Stat {...stats.reach} />
        {stats.verifiedReach.value > 0 && <Stat {...stats.verifiedReach} />}
      </Stack>
    </Card>
  );
}
