import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {FetchedOrganization} from '@analytics/graphql-api';

export type ManagePixelsRouteProps = {
  organization: FetchedOrganization;
  user: FetchedUser;
};

export const usePixelsRoutes = ({
  organization,
}: Pick<ManagePixelsRouteProps, 'organization'>) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, 'manage/pixels'),
    basePath: '/:part(a|s|b|p)/:slug/manage/pixels',
    routes: [
      {title: 'Your Pixels', key: 'pixels', path: '', exact: true},
      {key: 'add', exact: true},
      {key: 'pixel', path: ':key'},
    ],
  });
};
