import {useEffect, useState} from 'react';
import {Overlay, OverlayProps} from '@core/ui/Overlay';
import {SortableList} from '@core/ui/SortableList';
import {OrganizationObject} from '@analytics/graphql-api';
import {GroupBy} from '../../_types';
import {DropdownItem, getDropdownItems} from './_helpers';
import {SelectedIcon, SortButton, SortableItem} from './_shared';

export function GroupInputOverlay({
  defaultValue = [],
  onChange,
  opened,
  organization,
  toggle,
  ...props
}: OverlayProps & {
  defaultValue?: GroupBy[];
  onChange?: (_values: GroupBy[]) => void;
  organization: OrganizationObject;
}) {
  const [dropdownItems, setDropdownItems] = useState(
    getDropdownItems(organization)
  );
  const [selectedValues, setSelectedValues] = useState(
    dropdownItems.filter(
      (item) => item.value && defaultValue.includes(item.value)
    )
  );

  const onItemClick = (item: DropdownItem) => {
    const match = selectedValues.find(
      (selected) => selected.value && selected.value === item.value
    );

    if (match) {
      selectedValues.splice(selectedValues.indexOf(match), 1);
    } else {
      selectedValues.push(item);
    }

    setSelectedValues([...selectedValues]);
    onChange?.(selectedValues.map(({value}) => value));
  };

  const onSortItems = (values: string[]) => {
    const _selectedValues = values.map(
      (value) => selectedValues.find((s) => s.value === value) as DropdownItem
    );

    setSelectedValues(_selectedValues);
    onChange?.(_selectedValues.map(({value}) => value));
  };

  useEffect(() => {
    if (selectedValues.length > 1) {
      const _dropdownItems = selectedValues.map(
        (s) => dropdownItems.find((d) => s === d) as DropdownItem
      );
      setDropdownItems(_dropdownItems);
    }
  }, [selectedValues]);

  return (
    <Overlay
      toggle={toggle}
      opened={opened}
      transparentBackdrop
      withBackdrop
      withShadow
      horizontalAlign='right'
      verticalOffset={2}
      css={`
        display: flex;
        flex-direction: column;
        font-size: 0.875rem;
        max-width: initial;
        padding: 0.375rem;
      `}
      {...props}>
      <SortableList onSort={onSortItems}>
        {dropdownItems.map((item) => {
          const isSelected = selectedValues.some(
            ({value}) => value === item.value
          );
          return (
            <SortableItem
              key={item.value}
              data-id={item.value}
              data-sortable-item
              isSelected={isSelected}
              isSortable={selectedValues.length > 1}
              onClick={() => onItemClick(item)}>
              {selectedValues.length > 1 && <SortButton isSelected />}
              <div css={{lineHeight: 1.5}}>{item.name}</div>
              <SelectedIcon isSelected={isSelected} />
            </SortableItem>
          );
        })}
      </SortableList>
    </Overlay>
  );
}
