import {Card, CardHeader} from '@core/ui/Content';
import {podcastURL} from '@analytics/components/nav/ResearchNav';
import PodcastsTable from '@analytics/components/podcasts/PodcastsTable';

const Publisher = ({organization, publisher}) => {
  const podcasts = publisher.podcasts.map(({id, feed}) => {
    return {
      id,
      feed,
      linkTo: podcastURL(organization, feed),
    };
  });

  return (
    <>
      <CardHeader>Podcasts</CardHeader>
      <Card>
        <PodcastsTable organization={organization} podcasts={podcasts} />
      </Card>
    </>
  );
};

export default Publisher;
