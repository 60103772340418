import dayjs from '@core/lib/dayjs';

const getEpisodeProps = (episode) => {
  if (episode.futureEpisode) {
    return {
      title: episode.futureEpisode.title,
      description: episode.futureEpisode.subtitle,
      published: episode.futureEpisode.expectedPublished,
      futureEpisodeId: episode.futureEpisode?.id,
      episode: null,
    };
  } else if (episode.episode) {
    return {
      title: episode.episode.title,
      description: episode.episode.description,
      published: episode.episode.published,
      episode: episode.episode,
    };
  } else if (episode.expectedPublished) {
    return {
      title: episode.title,
      description: episode.subtitle,
      expectedPublished: episode.expectedPublished,
      episode: null,
    };
  }
  return {
    title: episode.title,
    description: episode.description,
    published: episode.published,
    episode,
  };
};

class Episode {
  constructor({episode, isPastEpisode = false, isUpcomingEpisode = false}) {
    this.id = episode.id;
    this.isBaseline = episode.isBaseline;
    this.isUpcomingEpisode = isUpcomingEpisode;
    this.isPastEpisode = isPastEpisode;

    const props = getEpisodeProps(episode);
    for (const k in props) {
      if (Object.hasOwnProperty.call(props, k)) {
        this[k] = props[k];
      }
    }
  }

  isSameEpisode(reference) {
    if (this.isUpcomingEpisode) {
      return this.id === (reference.futureEpisodeId || reference.id);
    } else if (reference.isUpcomingEpisode) {
      return this.futureEpisodeId === reference.id;
    } else if (this.episode) {
      if (reference.episode) {
        return this.episode.id === reference.episode.id;
      }
      return this.episode.id === reference.id;
    }
    return this.id === reference.id;
  }
}

const findEditionEpisode = (episode, edition, only = []) => {
  let episodes = [];
  ['creates', 'removes', 'updates'].forEach((key) => {
    if (only.length > 0 && only.indexOf(key) < 0) {
      // Ignore
    } else {
      episodes = episodes.concat(edition[key]);
    }
  });

  return episodes.find((item) => item.episode.isSameEpisode(episode));
};

const findEpisode = (episode, episodes) => {
  return episodes.find((ep) => ep.isSameEpisode(episode));
};

const formatDate = (v) => (v ? dayjs(v).utc().format('MMM DD, YYYY') : '-');

export {formatDate, findEpisode, findEditionEpisode, Episode};
