import {useCallback, useMemo, useState} from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {COLORS, TRules, css} from '@core/style';
import SegmentedControls from '@core/ui/SegmentedControls';
import {ChartTimeTooltip} from '../ChartTimeTooltip';
import {fillEmptyX, intervalsDateFormat, intervalsMap} from './helpers';

const getData = ({series, interval}: {series: any; interval: string}) => {
  const seriesKeys = Object.keys(series);
  let minTime: number;
  let maxTime: number;
  const map: {
    [key: string]: {
      x: number;
      [key: string]: number;
    };
  } = {};

  const setMinMax = (x: number) => {
    if (!minTime || minTime > x) {
      minTime = x;
    }
    if (!maxTime || maxTime < x) {
      maxTime = x;
    }
  };

  seriesKeys.forEach((key) => {
    series[key].forEach(({x, y}: {x: number; y: number}) => {
      const time = dayjs(x)
        .utc()
        .startOf(intervalsMap[interval])
        .toDate()
        .getTime();

      setMinMax(x);

      if (map[time]) {
        if (map[time][key]) {
          map[time][key] += y;
        } else {
          map[time][key] = y;
        }
      } else {
        map[time] = {
          [key]: y,
          x: time,
        };
      }
    });
  });

  const data = fillEmptyX({map, minTime, maxTime, interval, seriesKeys});

  return Object.values(data).sort((a, b) => a.x - b.x);
};

interface IChartTimeProps {
  displayZeroValues?: boolean;
  height?: number;
  hideLegends?: boolean;
  interval?: string;
  intervalSelector?: boolean;
  reversedY?: boolean;
  rules?: TRules;
  series: any[];
}

const ChartTime = ({
  displayZeroValues = true,
  height = 400,
  hideLegends = false,
  interval = 'hourly',
  intervalSelector = false,
  reversedY = false,
  rules,
  series,
}: IChartTimeProps): JSX.Element => {
  const [selectedInterval, setSelectedInterval] = useState(interval);
  const data = useMemo(
    () => getData({series, interval: selectedInterval}),
    [selectedInterval, series]
  );

  const onChangeInterval = useCallback(({value}: any) => {
    setSelectedInterval(value);
  }, []);

  return (
    <div
      {...css([
        () => ({
          color: 'var(--text-muted)',
          fontSize: '0.75rem',
        }),
        rules,
      ])}>
      {intervalSelector ? (
        <SegmentedControls
          propertyForValue='title'
          items={[
            {
              title: 'Hourly',
              value: 'hourly',
            },
            {
              title: 'Daily',
              value: 'daily',
            },
            {
              title: 'Weekly',
              value: 'weekly',
            },
          ]}
          onChange={onChangeInterval}
          segmentWidth={75}
          css={`
            margin-bottom: 0.625rem;
          `}
        />
      ) : null}
      <ResponsiveContainer width='100%' height={height}>
        <LineChart
          data={data}
          margin={{
            bottom: 15,
            left: 15,
            right: 15,
            top: 15,
          }}>
          <CartesianGrid
            stroke='var(--border-default)'
            strokeDasharray='0 0'
            vertical={false}
          />
          <XAxis
            dataKey='x'
            type='number'
            scale='time'
            domain={['dataMin', 'dataMax']}
            tick={{fill: 'var(--text-muted)'}}
            tickLine={false}
            tickFormatter={(timeStr) =>
              dayjs(timeStr).utc().format('D MMM').toUpperCase()
            }
            axisLine={false}
          />
          <YAxis
            tickFormatter={(val) => comma(val)}
            tick={{fill: 'var(--text-muted)'}}
            tickSize={10}
            axisLine={false}
            tickLine={false}
            reversed={reversedY}
          />
          {Object.keys(series).map((key, n) => (
            <Line
              key={key}
              dataKey={key}
              stroke={COLORS[n]}
              fill={COLORS[n]}
              strokeWidth={2}
              dot={false}
            />
          ))}
          {hideLegends ? null : <Legend />}
          <Tooltip
            content={({...props}) => (
              <ChartTimeTooltip
                dateFormat={intervalsDateFormat[selectedInterval]}
                displayZeroValues={displayZeroValues}
                {...props}
              />
            )}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartTime;
