import {isOrgAgency} from '@core/lib/organizations';
import {RouteType} from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';

export const measureRoutes = ({
  organization,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
}): RouteType[] => {
  const hasCampaigns =
    (organization.companies || []).filter((company) => !!company?.hasCampaigns)
      .length > 0 || false;

  return [
    {
      key: 'measure',
      path: '',
      exact: true,
    },
    {
      key: 'overview',
      title: 'Overview',
      path: 'overview',
      isAvailable:
        organization.kind === 'BRAND' ||
        (organization.kind === 'PODCAST' && hasCampaigns),
    },
    {
      key: 'campaigns',
      title: 'Campaigns',
      exact: true,
    },

    {
      key: 'lift',
      title: 'Lift Reports',
      path: 'lift',
      isAvailable: process.env.API_ENV !== 'demo' && !isOrgAgency(organization),
    },
  ];
};

export const measureInsightsRoutes = (): RouteType[] => {
  return [
    {
      key: 'performance',
      title: 'Performance',
      path: '',
      exact: true,
    },
    {
      key: 'frequency',
      title: 'Frequency',
      exact: true,
    },
    {
      key: 'reach',
      title: 'Reach',
      exact: true,
    },
    {
      key: 'overlap',
      title: 'Overlap',
      exact: true,
    },
    {
      key: 'players',
      title: 'Players',
      exact: true,
    },
    {
      key: 'locations',
      title: 'Locations',
      isActive: ({path}) => path.indexOf('locations') > -1,
    },
  ];
};
