// import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Alert} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {Text} from '@core/ui/Text';
import {ManageMembersRouteProps} from '.';

export function MembersAddDomain({
  organization,
}: // domains,
ManageMembersRouteProps) {
  // const history = useHistory();
  const membersPath = createOrgPath(organization, `/manage/members`);

  // const onComplete = () => {
  //   history.push(membersPath);
  // };

  // const onCancel = () => {
  //   history.push(membersPath);
  // };

  return (
    <>
      <ResponsiveContent containerSize='medium'>
        <CardHeader backPath={membersPath}>Add Email Domain</CardHeader>
        <Card>
          <Text>
            <p>
              Once a new domain has been added, any Ad Analytics user who has a
              verified email address at this domain will automatically be added
              to this Organization as a Member. <br />
              Current Organization Members who do not share the new email domain
              will continue to have access to their data.
            </p>
          </Text>
          <Alert severity='warning'>
            Please reach out to support to add email domains for your
            Organization.
          </Alert>
          {/* <MembersDomainForm
            organization={organization}
            onComplete={onComplete}
            onCancel={onCancel}
          /> */}
        </Card>
      </ResponsiveContent>
    </>
  );
}
