import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignPixelRouteProps} from '../_routes';
import HouseholdIndex from './household';

export function CampaignHouseholdsIndex({
  user,
  organization,
  campaign,
  pixel,
}: CampaignPixelRouteProps) {
  return (
    <Switch>
      <PropsRoute
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/pixels/:key/households/:householdId'
        user={user}
        organization={organization}
        campaign={campaign}
        pixel={pixel}
        component={HouseholdIndex}
      />
    </Switch>
  );
}
