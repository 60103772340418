import {gql} from '@apollo/client';
import ReviewerEpisodeFragment from '../fragments/ReviewerEpisodeFragment';

export default gql`
  query ReviewerEpisodes($organizationId: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          reviewing {
            episodes {
              ...ReviewerEpisode
            }
          }
        }
      }
    }
  }
  ${ReviewerEpisodeFragment}
`;
