import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {LimitedCompany} from './_types';

export const PUB_STEPS = [
  {title: 'Campaign Type', value: 'campaign-type'},
  {title: 'Brand Details', value: 'brand-details'},
  {title: 'Invite Brand', value: 'brand-invite'},
  {title: 'Campaign Details', value: 'campaign-details'},
] as const;

export const BRAND_STEPS = [
  {title: 'Campaign Type', value: 'campaign-type'},
  {title: 'Publisher Details', value: 'pub-details'},
  {title: 'Campaign Details', value: 'campaign-details'},
] as const;

export const isBrandClaimedByOrg = ({
  company,
  organization,
}: {
  company: LimitedCompany | undefined;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) =>
  organization.companies.filter(({id}) => company?.id == id).length > 0 ||
  organization.claims.filter((claim) => company?.id == claim.company?.id)
    .length > 0;
