import queryNode from './queryNode';
import createNode from './createNode';

function getReference(_ref) {
  var type = _ref.type,
      _ref$media = _ref.media,
      media = _ref$media === void 0 ? '' : _ref$media,
      _ref$support = _ref.support,
      support = _ref$support === void 0 ? '' : _ref$support;
  return type + media + support;
}

export default function getNodeFromCache(attributes, renderer) {
  var targetDocument = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : document;
  var reference = getReference(attributes);

  if (!renderer.nodes[reference]) {
    var node = queryNode(attributes, targetDocument) || createNode(attributes, targetDocument, renderer.sortMediaQuery, renderer.styleNodeAttributes);
    renderer.nodes[reference] = node;
  }

  return renderer.nodes[reference];
}