import {useQuery} from '@apollo/client';
import {Card} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import CampaignOverviewFrequency from '@analytics/components/campaigns/CampaignOverviewFrequency';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import CampaignFrequencyQuery from '@analytics/graphql-api/_old_queries/CampaignFrequencyQuery';
import {CampaignRouteProps} from './_routes';

export function CampaignFrequency({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  const {loading, error, data} = useQuery(CampaignFrequencyQuery, {
    variables: {
      organizationId: organization.id,
      campaignId: campaign.id,
    },
  });

  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      {loading ? (
        <Loading centered />
      ) : error ? (
        <Card>
          <EmptyMessage>No frequency data found.</EmptyMessage>
        </Card>
      ) : (
        <CampaignOverviewFrequency
          impressions={campaign.downloadTotal}
          reach={campaign.reachTotal}
          data={data?.me?.organization?.campaign?.impressions?.frequency}
        />
      )}
    </>
  );
}
