import {gql} from '@apollo/client';

export default gql`
  fragment CampaignStreamingStream on CampaignStreamingStreamObject {
    id
    cost
    costToDate

    downloadTotal
    reachTotal
    attributionTotal
    userTotal
    userTotalModeled
    productTotal
    productTotalModeled
    addtocartTotal
    addtocartTotalModeled
    checkoutTotal
    checkoutTotalModeled
    purchaseTotal
    purchaseTotalModeled
    leadTotal
    leadTotalModeled
    valueTotal
    valueTotalModeled
    installedTotal
    installedTotalModeled

    targetDownloadTotal
    targetReachTotal

    startAt
    endAt
  }
`;
