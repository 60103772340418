import {useState} from 'react';
import {useQuery} from '@apollo/client';
import {ToastMessage} from '@core/ui/Alert';
import Button from '@core/ui/Button/LegacyButton';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import Select from '@core/ui/Select';
import Toggle from '@core/ui/Toggle';
import PixelEventsTable from '@analytics/components/pixels/PixelEventsTable';
import {FetchedOrganization} from '@analytics/graphql-api';
import ORGANIZATION_KEY_DEBUG_NODES_QUERY, {
  IOrganizationKeyDebugNodes,
  IOrganizationKeyDebugNodesInput,
} from '@analytics/graphql-api/_old_queries/OrganizationKeyDebugNodesQuery';

interface IPixel {
  key: string;
  actionMaps: Array<{
    id: string;
    eventName: string;
    action: string;
  }>;
}
interface IPixelDebuggerBaseProps {
  action: string;
  organization: FetchedOrganization;
  pixel: IPixel;
  showMobileOnly: boolean;
}

const PixelDebuggerBase = ({
  action,
  organization,
  pixel: {key, actionMaps},
  showMobileOnly,
}: IPixelDebuggerBaseProps): JSX.Element => {
  const {data, loading, error} = useQuery<
    IOrganizationKeyDebugNodes,
    IOrganizationKeyDebugNodesInput
  >(ORGANIZATION_KEY_DEBUG_NODES_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      organizationId: organization.id,
      key,
      action,
      showMobileOnly,
    },
  });

  if (loading) {
    return <Loading centered />;
  }

  if (error) {
    return (
      <ToastMessage alertType='error' rules={() => ({margin: 0})}>
        Network Error. Please try again.
      </ToastMessage>
    );
  }

  const nodes = data?.me?.organization?.key?.debug.query.nodes ?? [];

  return (
    <PixelEventsTable
      events={nodes}
      dynamicActionMappings={actionMaps}
      orderBy='-time'
    />
  );
};

interface IPixelDebuggerProps {
  organization: FetchedOrganization;
  pixel: IPixel;
}

const PixelDebugger = ({
  organization,
  pixel,
}: IPixelDebuggerProps): JSX.Element => {
  const [action, setAction] = useState<string>(null);
  const [showMobileOnly, setShowMobileOnly] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);

  const onSelect = (selected: {title: string; value: string}) => {
    const selectedValue = selected !== null ? selected.value : null;
    setAction(selectedValue);
  };

  return (
    <>
      <Card>
        <CardHeader>Debugger</CardHeader>

        <div
          css={{
            alignItems: 'center',
            display: 'flex',
            marginBottom: '1.5rem',
            gap: 'var(--spacing-5)',
          }}>
          <Select
            items={[
              {title: 'Page View', value: 'view'},
              {title: 'Purchase', value: 'purchase'},
              {title: 'Lead', value: 'lead'},
              {title: 'Product', value: 'product'},
              {title: 'Add To Cart', value: 'addtocart'},
              {title: 'Checkout', value: 'checkout'},
              {title: 'Alias', value: 'alias'},
              {title: 'Install', value: 'install'},
              {title: 'Other', value: 'other'},
            ]}
            onSelect={onSelect}
            placeholder='Filter'
            removeOption
          />
          <div>
            <Toggle
              defaultChecked={showMobileOnly}
              onChecked={setShowMobileOnly}>
              <div
                css={`
                  margin-left: 10px;
                `}>
                Mobile Events Only
              </div>
            </Toggle>
          </div>
          <Button style-secondary onClick={() => setRefresh(refresh + 1)}>
            Refresh
          </Button>
        </div>
        <PixelDebuggerBase
          key={refresh}
          organization={organization}
          pixel={pixel}
          action={action}
          showMobileOnly={showMobileOnly}
        />
      </Card>
    </>
  );
};

export {PixelEventsTable};

export default PixelDebugger;
