import {Redirect} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import { RequestedPixel } from './RequestedPixel';
import { AcceptedPixel } from './AcceptedPixel';
import { ManageAdStudioRouteProps } from '.';
import { RejectedPixel } from './RejectedPixel';

export const AdStudioAdAccount = ({
  organization,
  pixel,
}: ManageAdStudioRouteProps) => {

  if (
    !organization.linkingRequestState && !organization.adStudioBusinessId
  ) {
    return (
      <Redirect to={createOrgPath(organization, `manage/pixels/${pixel.key}/ad-studio/add`)} />
    );
  }

  return (
    <ResponsiveContent containerSize='medium'>
      {((organization.linkingRequestState === 'accepted') || (!organization.linkingRequestState && organization.adStudioBusinessId)) && <AcceptedPixel organization={organization} pixel={pixel}/>}
      {organization.linkingRequestState === 'requested' && <RequestedPixel organization={organization} pixel={pixel}/>}
      {organization.linkingRequestState === 'rejected' && <RejectedPixel organization={organization} pixel={pixel}/>}
    </ResponsiveContent>
  );
};
