import {useState} from 'react';
import {Snackbar} from '@spotify-internal/encore-web';
import dayjs from '@core/lib/dayjs';
import {createOrgPath, isBuying, isSelling} from '@core/lib/organizations';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {DropdownMenuDivider, DropdownMenuTitle} from '@core/ui/DropdownMenu';
import {Icon} from '@core/ui/Icon';
import {Stack} from '@core/ui/Layout';
import {Nav, NavItem} from '@core/ui/Nav';
import {useOverlay} from '@core/ui/Overlay';
import ReportsFormOverlay from '@analytics/components/reports/ReportsFormOverlay';
import Tags from '@analytics/components/tags/Tags';
import {
  CampaignState,
  UpdateAllowBrandEditAccessInput,
  useUpdateAllowBrandEditAccessMutation,
} from '@analytics/graphql-api';
import {
  CampaignRouteProps,
  toCampaignUrl,
  useCampaignRoutes,
} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {CampaignInfoBadge, CampaignSalesOrderLink} from './_shared';

export function CampaignNav({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  const [openedExportOverlay, toggleOpenedExportOverlay] = useOverlay(false);
  const [updateBrandEditAccess] = useUpdateAllowBrandEditAccessMutation();
  const [showError, toggleError] = useOverlay();
  const [showSuccess, toggleSuccess] = useOverlay();
  const [brandEditState, setBrandEditState] = useState(
    campaign.allowBrandEditAccess
  );
  const url = toCampaignUrl(organization, campaign);
  const {routes} = useCampaignRoutes({user, organization, campaign});
  const publisher = campaign.organization;
  const advertiser = campaign.advertiser?.organization;
  const canUseExport =
    campaign.kind !== 'marketing' &&
    campaign.state !== CampaignState.Draft &&
    campaign.state !== CampaignState.Ready &&
    (campaign.downloadTotal || 0) > 0 &&
    (isBuying(organization) || isSelling(organization));

  const handleUpdateAccess = async () => {
    try {
      await updateBrandEditAccess({
        variables: {
          input: {
            campaignId: campaign.id,
          } as UpdateAllowBrandEditAccessInput,
        },
      });
      setBrandEditState((prev) => !prev);
      toggleSuccess(true);
      setTimeout(() => toggleSuccess(false), 3_000);
    } catch (e) {
      console.warn(e);
      toggleError(true);
      setTimeout(() => toggleError(false), 4_000);
    }
  };

  return (
    <>
      <Nav
        title={campaign.name}
        contentAfterTitle={
          <Stack
            direction='column'
            gap={4}
            css={{margin: 'var(--spacing-1) 0 var(--spacing-5)'}}>
            <Stack alignItems='center' gap='var(--spacing-3)'>
              <CampaignInfoBadge
                css={`
                  align-items: center;
                  display: flex;
                  gap: var(--spacing-1);
                `}>
                <Icon
                  icon='date-alternative'
                  css={`
                    width: 1rem;
                    height: 1rem;
                  `}
                />
                {campaign.startAt
                  ? formatDate(campaign.startAt)
                  : '?'}
                {' to '}
                {campaign.endAt
                  ? formatDate(campaign.endAt)
                  : '?'}
              </CampaignInfoBadge>
              <CampaignInfoBadge
                css={`
                  align-items: center;
                  display: flex;
                  gap: var(--spacing-1);
                  text-transform: capitalize;
                `}>
                {(campaign.state === CampaignState.Live ||
                  campaign.state === CampaignState.Agency) && (
                  <div
                    css={`
                      background: var(--green);
                      border-radius: 50%;
                      height: 0.5rem;
                      width: 0.5rem;
                    `}
                  />
                )}
                {campaign.state.toLowerCase()}
              </CampaignInfoBadge>
              <CampaignInfoBadge
                css={`
                  align-items: center;
                  display: flex;
                  gap: var(--spacing-2);
                  text-transform: capitalize;
                `}>
                <Icon
                  icon='measure-outline'
                  css={`
                    width: 1rem;
                    height: 1rem;
                  `}
                />
                {campaign.kind}
              </CampaignInfoBadge>
              <CampaignInfoBadge>
                Publisher: {campaign.organization?.name}
              </CampaignInfoBadge>
              <CampaignInfoBadge>
                Advertiser:{' '}
                {campaign.advertiser?.organization
                  ? campaign.advertiser.organization.name
                  : '—'}
              </CampaignInfoBadge>
              <Tags
                instance={campaign}
                organization={organization}
                contentType='campaigns.campaign'
                showEditButtonIfEmpty
                overlayProps={{
                  horizontalAlign: 'center',
                  verticalAlign: 'bottom',
                  noVerticalOverlap: true,
                }}
              />
            </Stack>
            {campaign.spotifyOrder && (
              <Alert compact severity='info-highlight'>
                <Stack direction='column' gap='var(--spacing-3)'>
                  {t('spotify.orders.child.description')}
                  <CampaignSalesOrderLink
                    name={campaign.spotifyOrder.name}
                    to={createOrgPath(
                      organization,
                      `campaigns/orders/${campaign.spotifyOrder.id}`
                    )}
                  />
                </Stack>
              </Alert>
            )}
          </Stack>
        }
        moreDropdownItems={[
          ...routes
            .filter(({key}) => DROPDOWN_ITEMS.includes(key))
            .map((route) => ({
              to: route.to,
              title: route.title,
              exact: route.exact,
            })),
          ...(isSelling(organization) && brandEditState
            ? [
                {
                  title: 'Remove Brand Edit Access',
                  callback: () => handleUpdateAccess(),
                },
              ]
            : []),
          ...(isSelling(organization) && !brandEditState
            ? [
                {
                  title: 'Give Brand Edit Access',
                  callback: () => handleUpdateAccess(),
                },
              ]
            : []),
          {
            title: 'Activity Log',
            to: `${url}/activity`,
            exact: true,
          },
          ...(canUseExport
            ? [
                {
                  title: 'Export...',
                  callback: () => toggleOpenedExportOverlay(true),
                },
              ]
            : []),
          ...(user?.isAdmin
            ? [
                {content: <DropdownMenuDivider />},
                {
                  content: (
                    <DropdownMenuTitle>
                      Only visible to Ad Analytics members:
                    </DropdownMenuTitle>
                  ),
                },
                {to: `${url}/debug`, title: 'Debug Campaign'},
                {
                  title: 'Approvals',
                  to: `${url}/approvals`,
                  isVisible: () => isSelling(organization),
                },
                {
                  to: toCampaignUrl(publisher, campaign),
                  title: 'Open Campaign as Publisher',
                  target: '_blank',
                  isVisible: () => organization.kind !== 'PODCAST',
                },
                {
                  to: advertiser ? toCampaignUrl(advertiser, campaign) : null,
                  title: 'Open Campaign as Advertiser',
                  target: '_blank',
                  isVisible: () =>
                    !!advertiser && organization.kind !== 'BRAND',
                },
                {
                  href: `https://admin.podsights.com/campaigns/${campaign.id}`,
                  title: 'Open Campaign in Admin',
                  target: '_blank',
                },
                {
                  href: advertiser
                    ? `https://admin.podsights.com/organizations/${advertiser.id}`
                    : null,
                  title: 'Open Advertiser in Admin',
                  target: '_blank',
                  isVisible: () => !!advertiser,
                },
                {
                  href: `https://admin.podsights.com/organizations/${publisher.id}`,
                  title: 'Open Publisher in Admin',
                  target: '_blank',
                },
              ]
            : []),
        ]}
        breadcrumbs={[
          {
            title: 'Campaigns',
            to: createOrgPath(organization, '/measure/campaigns'),
          },
          {
            title: 'Campaign',
          },
        ]}>
        {routes
          .filter(({key}) => NAV_ITEMS.includes(key))
          .map(({title, to, key, exact}) => (
            <NavItem
              key={key}
              to={to}
              isActive={
                exact ? undefined : ({path}) => path.indexOf(to ?? '') === 0
              }>
              {title}
            </NavItem>
          ))}
      </Nav>
      <ReportsFormOverlay
        category='campaign'
        opened={openedExportOverlay}
        toggle={toggleOpenedExportOverlay}
        organization={organization}
        presetExport={{
          name: `${campaign.name} ${dayjs().format('MM/DD/YY')}`,
          typeId: 'campaign_overview' as const,
          exportParameters: {
            instanceId: campaign.id,
          },
        }}
      />
      <Snackbar showSnackbar={showError} colorSet='negative'>
        {t('errors.network')}
      </Snackbar>
      <Snackbar showSnackbar={showSuccess} colorSet='positive'>
        Updated brand edit access.
      </Snackbar>
    </>
  );
}

const NAV_ITEMS = [
  'overview',
  'insights',
  'revenue',
  'pixels',
  'overlap',
  'placements',
  'locations',
  'players',
  'frequency',
  'lift',
  'links',
];
const DROPDOWN_ITEMS = ['update', 'bulk', 'permissions', 'delete'];

const formatDate = (v) => (v ? dayjs(v).format('MMM DD, YYYY') : '-');
