import React from 'react';
import {Line, LineChart, ResponsiveContainer} from 'recharts';

interface IHistoricalEvent {
  name: string;
  eventCounts: Array<{
    name: string;
    value: number;
  }>;
}

interface IPixelEventsTinyChartProps {
  historical: Array<{
    hour: string;
    js: IHistoricalEvent[];
    mobile: IHistoricalEvent[];
    img: IHistoricalEvent[];
  }>;
  height?: number;
}

const PixelEventsTinyChart = ({
  historical,
  height = 50,
}: IPixelEventsTinyChartProps): JSX.Element => {
  const data = historical.map(({hour, js, mobile, img}) => {
    const x = new Date(hour).getTime();
    const all = js.concat(mobile).concat(img);

    return {
      x,
      events: all.reduce((k, acc) => {
        const events = acc.eventCounts.reduce((e, _acc) => {
          return e + _acc.value;
        }, 0);
        return k + events;
      }, 0),
    };
  });

  if (!!data.length) {
    return (
      <div
        css={`
          pointer-events: none;
        `}>
        <ResponsiveContainer width='100%' height={height}>
          <LineChart data={data}>
            <Line
              isAnimationActive={false}
              type='linear'
              strokeWidth={2}
              dataKey='events'
              stroke='var(--green)'
              fill='var(--green)'
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
  return (
    <div
      css={`
        align-items: flex-end;
        display: flex;
        height: ${height}px;
        padding: 0.5rem 0.375rem;
      `}>
      <div
        css={`
          background: var(--green);
          height: 0.125rem;
          width: 100%;
        `}
      />
    </div>
  );
};

export default PixelEventsTinyChart;
