import React from 'react';
import {Switch} from 'react-router-dom';
import ManageNav from '@analytics/components/nav/ManageNav';
import {AddPixel} from './AddPixel';
import {Pixels} from './Pixels';
import {ManagePixelsRouteProps, usePixelsRoutes} from './_routes';
import {PixelIndex} from './pixel';

export function ManagePixelsIndex({
  user,
  organization,
}: ManagePixelsRouteProps) {
  const {renderRoutes} = usePixelsRoutes({organization});

  const withManageNav = (
    Component: React.FunctionComponent<any> | React.ComponentClass<any>
  ) => {
    return ({...props}) => (
      <>
        <ManageNav organization={organization} user={user} />
        <Component {...props} />
      </>
    );
  };

  return (
    <Switch>
      {renderRoutes({
        components: {
          pixels: withManageNav(Pixels),
          add: withManageNav(AddPixel),
          pixel: PixelIndex,
        },
        extraProps: {
          user,
          organization,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
