import {
  FocusEvent,
  FormEvent,
  KeyboardEvent,
  PropsWithChildren,
  useState,
} from 'react';
import styled from 'styled-components';
import {TRules} from '@core/style';
import {FelaElement} from '@core/style';

export interface ContentEditableInputProps extends PropsWithChildren {
  defaultValue?: string;
  onBlur?: (_evt: FocusEvent<HTMLDivElement>) => void;
  onFocus?: (_evt: FocusEvent<HTMLDivElement>) => void;
  onInput?: (_evt: FormEvent<HTMLDivElement>) => void;
  onKeyDown?: (_evt: KeyboardEvent<HTMLDivElement>) => void;
  placeholder?: string;
  /**
   * @deprecated
   */
  rules?: TRules;
}

export default function ContentEditableInput({
  children,
  defaultValue = '',
  onBlur,
  onFocus,
  onInput,
  onKeyDown,
  placeholder,
  rules,
  ...props
}: ContentEditableInputProps) {
  const [focused, setFocused] = useState<boolean>(false);
  const [value, setValue] = useState<string>(defaultValue);

  const _onFocus = (evt: FocusEvent<HTMLDivElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(evt);
    }
  };

  const _onBlur = (evt: FocusEvent<HTMLDivElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(evt);
    }
  };

  const _onInput = (evt: KeyboardEvent<HTMLDivElement>) => {
    setValue((evt.target as HTMLElement).innerText);
    if (onInput) {
      onInput(evt);
    }
  };

  const _onKeydown = (evt: KeyboardEvent<HTMLDivElement>) => {
    if (evt.key === 'Enter') {
      evt.preventDefault();
    }
    if (onKeyDown) {
      onKeyDown(evt);
    }
  };

  const showPlaceholder = !focused && !!placeholder && !value;

  return (
    <_Input
      role='form'
      contentEditable
      onKeyDown={_onKeydown}
      onFocus={_onFocus}
      onBlur={_onBlur}
      onInput={_onInput}
      suppressContentEditableWarning
      rules={rules}
      {...props}>
      {children}
      {defaultValue}
      {showPlaceholder && (
        <div
          css={`
            color: var(--text-muted);
            pointer-events: none;
          `}>
          {placeholder}
        </div>
      )}
    </_Input>
  );
}

const _Input = styled(FelaElement)``;
