export const sortNodes = (
  nodes: HTMLElement[],
  oldIndex: number,
  newIndex: number
) => {
  if (newIndex >= nodes.length) {
    let k = newIndex - nodes.length;
    while (k-- + 1) {
      nodes.push(undefined);
    }
  }
  nodes.splice(newIndex, 0, nodes.splice(oldIndex, 1)[0]);
  return nodes;
};

export const findRect = (x: number, y: number, rects: DOMRect[]) => {
  if (Array.isArray(rects)) {
    for (let idx = 0; idx < rects.length; idx++) {
      const rect = rects[idx];
      if (
        x >= rect.left &&
        x <= rect.left + rect.width &&
        y >= rect.top &&
        y <= rect.top + rect.height
      ) {
        return rect;
      }
    }
  }
  return null;
};

export const translate3d = (
  node: HTMLElement,
  x: number,
  y: number,
  z: number
) => {
  node.style.transform = `translate3d(${x}px, ${y}px, ${z}px)`;
};

export const applySortableNodeStyles = ({
  isDragNode = false,
  node,
  rect,
  to = false,
}: {
  isDragNode?: boolean;
  node: HTMLElement;
  rect: DOMRect;
  to: boolean;
}): void => {
  Object.assign(node.style, {
    height: to ? `${rect.height}px` : null,
    left: to ? '0' : null,
    margin: to ? '0' : null,
    position: to ? (isDragNode ? 'fixed' : 'absolute') : null,
    top: to ? '0' : null,
    width: to ? `${rect.width}px` : null,
    willChange: to ? 'transform' : null,
  });
};

export const applyDragNodeStyles = (node: HTMLElement, to: boolean): void => {
  Object.assign(node.style, {
    backdropFilter: to ? 'blur(1rem)' : null,
    borderRadius: to ? '.375rem' : null,
    boxShadow: to ? '0 1px 4px rgba(0,0,0,0.15)' : null,
    filter: to ? 'brightness(1.1)' : null,
    zIndex: to ? '999999' : null,
  });
};
