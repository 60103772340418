import {gql} from '@apollo/client';
import UserAccessFragment from '@analytics/graphql-api/_old_queries/fragments/UserAccessFragment';

export default gql`
  mutation createUserAccess($input: CreateUserAccessInput!) {
    authed {
      success
    }
    createUserAccess(input: $input) {
      userAccess {
        ...UserAccess
      }
    }
  }
  ${UserAccessFragment}
`;
