import { css } from "../../styled-components.js";

/* Transition durations */
export var durationShort = '100ms';
export var durationMedium = '200ms';

/* Transition timing functions */
export var advanceGentle = 'cubic-bezier(0.3, 0, 0.4, 1)';
export var advanceFierce = 'cubic-bezier(0, 0, 0, 1)';
export var recedeGentle = 'cubic-bezier(0.6, 0, 0.7, 1)';
export var recedeFierce = 'cubic-bezier(1, 0, 1, 1)';
export var prefersReducedMotion = css(["@media (prefers-reduced-motion){transition-duration:0s;}"]);