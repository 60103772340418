import {useHistory} from 'react-router-dom';
import {Card} from '@core/ui/Content';
import {CampaignKind} from '@analytics/components/campaigns/_types';
import {
  CampaignCreateSelectTypeForm,
  CampaignCreateSteps,
} from '@analytics/components/campaigns/create';
import {CampaignsRouteProps} from '../../_routes';
import {toPubCreateCampaignBasePath} from './_routes';

export function PublisherSelectCampaignType({
  organization,
}: CampaignsRouteProps) {
  const history = useHistory();

  const goToStep2 = ({kind}: {kind: CampaignKind}) => {
    history.push(`${toPubCreateCampaignBasePath(organization)}/${kind}`);
  };

  return (
    <>
      <CampaignCreateSteps active='campaign-type' />
      <Card>
        <CampaignCreateSelectTypeForm onSubmit={goToStep2} />
      </Card>
    </>
  );
}
