import {useState} from 'react';
import {Switch, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {PropsRoute} from '@core/app/Routes';
import dayjs from '@core/lib/dayjs';
import {DateRangeInput} from '@core/ui/DatePicker';
import {DropdownMenu} from '@core/ui/DropdownMenu';
import {Icon} from '@core/ui/Icon';
import {useOverlay} from '@core/ui/Overlay';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import ReportsFormOverlay, {
  ExportTypeId,
} from '@analytics/components/reports/ReportsFormOverlay';
import {CampaignRouteProps, toCampaignUrl} from '../../_routes';
import CampaignRevenue from './CampaignRevenue';

type DateRangeToolbarProps = Pick<
  CampaignRouteProps,
  'organization' | 'campaign'
> & {
  start: string;
  end: string;
};

const DateRangeToolbar = ({
  organization,
  campaign,
  start,
  end,
}: DateRangeToolbarProps) => {
  const history = useHistory();
  const [exportType, setExportType] = useState<ExportTypeId | undefined>();
  const [openedFormOverlay, toggleFormOverlay] = useOverlay();
  const rangeParams = {
    after: start,
    before: dayjs(end).add(1, 'days').format('YYYY-MM-DD'),
  };

  const onDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => {
    history.push(
      `${toCampaignUrl(organization, campaign)}/revenue/${startDate.format(
        'YYYY-MM-DD'
      )}/${endDate.format('YYYY-MM-DD')}`
    );
  };

  const isOutsideRange = (date: dayjs.Dayjs) => {
    const {startAt, endAt} = campaign;
    const hardStart = dayjs(startAt).utc();
    const hardEnd = dayjs(endAt).isAfter(dayjs()) ? dayjs() : dayjs(endAt);

    return !(date.isSameOrAfter(hardStart) && date.isSameOrBefore(hardEnd));
  };

  return (
    <_ToolbarContainer>
      <DateRangeInput
        onDatesChange={onDatesChange}
        startDate={dayjs(start)}
        endDate={dayjs(end)}
        isOutsideRange={isOutsideRange}
      />
      <DropdownMenu
        onSubmit={(item) => {
          setExportType(item.value);
          toggleFormOverlay(true);
        }}
        items={[
          {
            title: 'Export Overview',
            value: 'campaign_overview',
          },
          {
            title: 'Export Conversions',
            value: 'campaign_conversions',
          },
        ]}
        overlayProps={{
          horizontalAlign: 'left',
        }}
        css={`
          color: var(--text-muted);
          margin-left: auto;
        `}>
        Export
        <Icon icon='chevron-down-small' />
      </DropdownMenu>
      {exportType && (
        <ReportsFormOverlay
          category='campaign'
          opened={openedFormOverlay}
          organization={organization}
          presetExport={{
            name: `${campaign.name} - ${dayjs().format('MM/DD/YYYY')}`,
            typeId: exportType,
            exportParameters: {
              instanceId: campaign.id,
              startDate: rangeParams.after,
              endDate: rangeParams.before,
            },
          }}
          toggle={toggleFormOverlay}
        />
      )}
    </_ToolbarContainer>
  );
};

export default function RangeIndex({
  user,
  organization,
  campaign,
  match: {
    params: {start, end},
  },
}: CampaignRouteProps & {
  match: {
    params: {
      start: string;
      end: string;
    };
  };
}) {
  const after = dayjs(start);
  const before = dayjs(end).clone().add(1, 'days');

  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <DateRangeToolbar
        organization={organization}
        campaign={campaign}
        start={start}
        end={end}
      />
      <Switch>
        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/campaigns/:campaignSlug/revenue/:start/:end'
          after={after}
          before={before}
          campaign={campaign}
          component={CampaignRevenue}
          organization={organization}
          user={user}
        />
      </Switch>
    </>
  );
}

const _ToolbarContainer = styled.div`
  align-items: center;
  background: var(--bg-muted);
  border-radius: 0.5rem;
  display: flex;
  margin: 1.5rem 1.5rem 2.325rem;
  padding: 1rem;
`;
