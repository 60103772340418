import React from 'react';
import {useQuery} from '@apollo/client';
import PixelEventsChart from '@core/components/PixelEventsChart';
import dayjs from '@core/lib/dayjs';
import EmptyMessage from '@core/ui/EmptyMessage';
import {LoadingSpinner} from '@core/ui/Loading';
import ORGANIZATION_KEY_DEBUG_HISTORICAL_QUERY, {
  IOrganizationKeyDebugHistorical,
  IOrganizationKeyDebugHistoricalInput,
} from '@analytics/graphql-api/_old_queries/OrganizationKeyDebugHistoricalQuery';

interface IPixelEventLogProps {
  organization: {
    id: string;
  };
  pixel: {
    key: string;
  };
}

const PixelEventLog = ({
  organization,
  pixel,
}: IPixelEventLogProps): JSX.Element => {
  const {data, loading, error} = useQuery<
    IOrganizationKeyDebugHistorical,
    IOrganizationKeyDebugHistoricalInput
  >(ORGANIZATION_KEY_DEBUG_HISTORICAL_QUERY, {
    variables: {
      organizationId: organization.id,
      key: pixel.key,
      after: dayjs().subtract(45, 'days').format('YYYY-MM-DD'),
      before: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    },
  });

  if (loading) return <LoadingSpinner centered />;

  if (error) return <EmptyMessage>Pixel event data unavailable.</EmptyMessage>;

  const {historical} = data.me.organization.key.debug;

  return <PixelEventsChart historical={historical} />;
};

export default PixelEventLog;
