import React, {useEffect, useState} from 'react';
import {Button} from '@core/ui/Button';
import Copy from '@core/ui/Copy';
import {Input} from '@core/ui/Form';
import {campaignLinkURL} from '@analytics/components/nav/CampaignLinkNav';
import {getPodlinkData} from '@analytics/lib/podlink';

const LinkBuilder = ({organization, campaign, campaignLink, feed}) => {
  const [url, setUrl] = useState('');
  const key = campaign.pixels.filter(
    ({role}) => role.toLowerCase() === 'production'
  )[0].key;
  const params = {
    pdst_key: key,
    pdst_group: campaign.id,
    pdst_label: campaignLink.id,
  };

  useEffect(() => {
    const fetchPodlinkData = async () => {
      const data = await getPodlinkData(feed.href);
      setUrl(`${data.url}?${new URLSearchParams(params).toString()}`);
    };

    fetchPodlinkData();
  }, []);

  return (
    <div css={{alignItems: 'center', display: 'flex'}}>
      <Input
        type='text'
        value={url}
        readOnly
        onFocus={(evt) => evt.target.select()}
        rules={() => ({
          flex: 1,
          marginBottom: '0',
          marginRight: '1rem',
        })}
      />
      <Copy text={url} />
      <Button
        to={`${campaignLinkURL(organization, campaign, campaignLink)}/build`}
        color='primary'
        css={`
          margin-left: var(--spacing-2);
        `}>
        Customize
      </Button>
    </div>
  );
};

export default LinkBuilder;
