import {gql} from '@apollo/client';

export default gql`
  fragment PodcastApproval on PodcastApprovalObject {
    id
    requestedAt
    updatedAt
    state
    comment
    campaignPodcast {
      id
    }
    updatedBy {
      email
    }
    requestedBy {
      id
      firstName
      lastName
      email
    }
    requestedByOrganization {
      id
      name
    }
    requestee {
      id
      firstName
      lastName
      email
    }
    requesteeOrganization {
      id
      name
    }
    rejectedReason
  }
`;
