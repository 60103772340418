import React from 'react';
import {Element} from '@core/style';

const AdCopy = ({text, words, rules}) => {
  const copy = text
    ? text
    : words
    ? JSON.parse(words)
        .map((w) => w.word)
        .join(' ')
    : '';

  return (
    <Element
      rules={[
        ({theme}) => ({
          color: theme.textTertiary,
          fontSize: '0.75rem',
          lineHeight: '1.4',
          margin: '1rem 0',
        }),
        rules,
      ]}>
      {copy}
    </Element>
  );
};

export default AdCopy;
