import {t} from '../texts';
import {TOrganizationKind} from './organizations';

type TRole = 'member' | 'admin' | 'ad-ops' | 'sales';

const rulesMap: {[_key in TOrganizationKind]: TRole[]} = {
  BRAND: ['member', 'admin'],
  BUY_SIDE: ['member', 'admin'],
  PODCAST: ['admin', 'ad-ops', 'sales'],
  SELL_SIDE: ['admin', 'ad-ops', 'sales'],
};

export const getRoles = (kind: TOrganizationKind) => {
  const result = rulesMap[kind];

  if (result) {
    return result.map((value) => ({...t(`roles.${value}`), value}));
  }
  return '_unknown_';
};

export const getRoleDocumentation = (value: TRole) =>
  t(`roles.${value}`).description;
