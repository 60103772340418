import {FC, useCallback, useMemo} from 'react';
import {comma, contentMaturityRating, toPercent} from '@core/lib/filters';
import {toTimeString} from '@core/lib/time';
import {stripHTML} from '@core/lib/words';
import {t} from '@core/texts';
import {DataTableColumn, DataTableServer} from '@core/ui/DataTable';
import {Icon} from '@core/ui/Icon';
import {FeedImage} from '@core/ui/Image';
import {Tooltip} from '@core/ui/Tooltip';
import {UserObject} from '@analytics/graphql-api';
import ADVISOR_DATA_QUERY from '@analytics/graphql-api/_old_queries/advisor/AdvisorDataQuery';
import AdvisorNielsenCell from './AdvisorNielsenCell';
import {AdvisorColumn, AdvisorFilterItem, AdvisorSortByItem} from './types';

const getColumnId = (str: string) => str.substr(str.indexOf('::') + 2);
const getColumnGroupId = (str: string) =>
  str.indexOf('::') > 0 ? str.slice(0, str.indexOf('::')) : 'general';

const getColumnProps = ({id}: {id: string}) => {
  const styles: {[x: string]: DataTableColumn} = {
    publisher: {
      rule: () => ({whiteSpace: 'nowrap'}),
    },
    tags: {
      rule: () => ({minWidth: '7.5rem'}),
    },
    nielsen: {
      iconName: 'nielsen',
      alignRight: false,
    },
    experian: {
      iconName: 'experian',
      alignRight: false,
    },
    radioMarkets: {
      alignRight: false,
    },
    urbanClassification: {
      alignRight: false,
    },
  };

  return styles[id.split('::')[0]] || null;
};

const getTableData = ({rows}) => {
  if (rows) {
    return rows.map(({cells, feed}) => {
      const d = {feedId: feed.id, feed};

      cells.map((cell) => {
        d[getColumnId(cell.id)] = cell.values.map(
          ({value, filterId, index}) => {
            if (
              filterId === 'nielsen' ||
              filterId === 'experian' ||
              filterId === 'radio_markets' ||
              cell.id.indexOf('feedGrowth') > -1 ||
              cell.id.indexOf('urbanClassification') > -1
            ) {
              return {value, index, filterId};
            }
            return value;
          }
        );
      });

      return d;
    });
  }
  return [];
};

const getTableColumns = (activeColumns: AdvisorColumn[]): DataTableColumn[] => {
  if (activeColumns) {
    return activeColumns.map(
      ({
        id,
        type,
        title,
        description,
        filterIndex,
        filterMax,
        filterMin,
        filterStddev,
      }): DataTableColumn => ({
        title,
        accessor: id,
        noSortable: true,
        type: type === 'segment' ? 'number' : type,
        ...(getColumnProps({id}) || {}),
        description: stripHTML(description),
        Cell: ({d}) => {
          if (d[id]) {
            if (id === 'feedCr' || id === 'publisherCr') {
              return (
                d[id].map((val) => toPercent(Number(val))).join(', ') || '-'
              );
            } else if (id === 'affinity') {
              const noAffinity = !d[id][0];
              const isMegaphone = d.feed?.href.indexOf('megaphone') > -1;
              return (
                <Tooltip
                  active={noAffinity || isMegaphone}
                  description={
                    noAffinity
                      ? t('advisor.affinityNotAvailable').description
                      : isMegaphone
                      ? t('advisor.affinityMegaphoneUprank').description
                      : ''
                  }>
                  <div
                    css={`
                      align-items: center;
                      background: ${isMegaphone
                        ? 'var(--advisor-affinity-megaphone-bg)'
                        : 'var(--advisor-affinity-bg)'};
                      border-radius: 2rem;
                      color: ${isMegaphone
                        ? 'var(--advisor-affinity-megaphone-color)'
                        : 'var(--advisor-affinity-color)'};
                      display: inline-flex;
                      font-weight: 500;
                      opacity: ${d[id][0] ? 1 : 0.5};
                      padding: 0.1875rem 0.75rem 0.1875rem 0.5rem;
                      white-space: nowrap;
                    `}>
                    <Icon
                      icon='affinity'
                      css={`
                        color: 'inherit';
                        height: 1.325rem;
                        margin-right: 0.25rem;
                        width: 1.325rem;
                      `}
                    />
                    {d[id][0] || '-'}
                  </div>
                </Tooltip>
              );
            } else if (id === 'newReach') {
              return (
                <div
                  css={`
                    align-items: center;
                    border-radius: 2rem;
                    display: inline-flex;
                    font-weight: 500;
                    min-height: 1.625rem;
                    padding: 0.125rem 0.75rem 0.125rem 0.5rem;
                    white-space: nowap;
                    background: var(--advisor-reach-bg);
                    color: var(--advisor-reach-color);
                  `}>
                  <Icon
                    icon='radio'
                    css={`
                      margin-right: 0.25rem;
                      width: 1.3125rem;
                      height: 1.3125rem;
                    `}
                  />
                  {d[id].map((val) => toPercent(Number(val))).join(', ') || '-'}
                </div>
              );
            } else if (getColumnGroupId(id) === 'tag') {
              return d[id][0] === 'true' ? (
                <Icon
                  icon='checkbox-marked-circle'
                  rules={() => ({color: 'var(--green)'})}
                />
              ) : (
                <Icon
                  icon='close-circle'
                  rules={() => ({color: 'var(--icon-muted)'})}
                />
              );
            } else if (type === 'int') {
              return d[id][0] ? comma(d[id][0]) : '-';
            } else if (type === 'float') {
              return d[id][0] ? comma(d[id][0], 2) : '-';
            } else if (type === 'segment') {
              const {value} = d[id][0];
              const name = id.split('::')[0];

              return (
                <AdvisorNielsenCell
                  cellName={name}
                  value={value}
                  filterIndex={filterIndex}
                  filterMin={filterMin}
                  filterMax={filterMax}
                  filterStddev={filterStddev}
                />
              );
            } else if (type === 'percent') {
              return (
                d[id].map((val) => toPercent(Number(val))).join(', ') || '-'
              );
            } else if (type === 'duration') {
              return d[id][0] ? toTimeString(d[id][0]) : '-';
            } else if (type === 'maturity') {
              return contentMaturityRating(d[id][0]);
            }
            return d[id].join(', ');
          }
          return '-';
        },
      })
    );
  }
  return [];
};

interface IAdvisorDataTable {
  activeColumns: AdvisorColumn[];
  activeFilters: AdvisorFilterItem[];
  activeSort: AdvisorSortByItem[];
  feedId: string;
  onClickRowPath: (_row: object) => string;
  organizationId: string;
  searchValue?: string;
  user: UserObject;
}

const AdvisorDataTable: FC<IAdvisorDataTable> = ({
  activeColumns,
  activeFilters,
  activeSort,
  feedId,
  onClickRowPath,
  organizationId,
  searchValue,
  user,
}): JSX.Element => {
  const columns = useMemo(
    (): DataTableColumn[] => [
      {
        accessor: 'podcast',
        title: 'Podcast',
        noSortable: true,
        getValue: ({d}) => d.feed.title,
        Cell: ({d}) => {
          return (
            <div
              css={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
                minWidth: '12.5rem',
              }}>
              <FeedImage
                feed={d.feed}
                rules={() => ({
                  borderRadius: '0.375rem',
                  height: '3.25rem',
                  marginRight: '0.625rem',
                  paddingBottom: 0,
                  width: '3.25rem',
                })}
              />
              <div
                css={{
                  flex: 1,
                  fontWeight: 500,
                }}>
                {d.feed.title}
              </div>
            </div>
          );
        },
      },
      ...getTableColumns(activeColumns),
    ],
    [activeColumns]
  );

  return (
    <DataTableServer
      query={ADVISOR_DATA_QUERY}
      columns={columns}
      emptyMessageText='No data found.'
      fetchPolicy='no-cache'
      searchValue={searchValue}
      propertyForKey='feedId'
      toVariables={useCallback(
        ({query, rowsPerPage, rowStart}) => ({
          columns: activeColumns.map(({id}) => id),
          filters: [
            ...activeFilters.map(({columnGroup, values}) => ({
              id: columnGroup.id,
              operator: columnGroup.filterType || '',
              values: values.map((value) => {
                if (value.id !== undefined) {
                  if (value.id.startsWith('tag')) {
                    return value.title;
                  }
                  if (value.id.includes('::')) {
                    return value.id.split('::')[1];
                  }
                  return value.id;
                }
                return value;
              }),
            })),
          ],
          limit: rowsPerPage,
          offset: rowStart,
          organizationId,
          query,
          includeTestData: user.isAdmin,
          sort: activeSort.map(
            ({id, direction}) => `${direction === 'asc' ? '' : '-'}${id}`
          ),
        }),
        [activeColumns, activeFilters, activeSort, organizationId, user.isAdmin]
      )}
      toData={(resp) => {
        try {
          const {count, rows} = resp.me.organization.advisor.results;
          return {data: getTableData({rows}), count};
        } catch (err) {
          return {data: [], count: 0};
        }
      }}
      rowRuleFn={({theme, data: {feed}}) => {
        if (feedId && feed.id === feedId) {
          return {
            background: theme.stateHoverSurfaceBg,
            '& td:first-child': {
              background: `${theme.stateHoverSurfaceBg}!important`,
            },
          };
        }
        return {};
      }}
      onClickRowPath={onClickRowPath}
      css={{
        overflow: 'auto',
        '& table thead th': {
          padding: '0.625rem 0.625rem',
        },
        '& table thead th:first-child': {
          left: 0,
          paddingLeft: '1.25rem',
          position: 'sticky',
          zIndex: 2,
        },
        '& table thead th:last-child': {
          paddingRight: '1.25rem',
        },
        '& table thead td:last-child': {
          paddingRight: '1.25rem',
        },
        '& table tbody td:first-child': {
          background: 'var(--bg-surface)',
          left: 0,
          paddingLeft: '1.25rem',
          position: 'sticky',
          zIndex: 2,
        },
      }}
    />
  );
};

export default AdvisorDataTable;
