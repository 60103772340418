import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignRouteProps} from '../_routes';
import CampaignLocationCountry from './CampaignLocationCountry';
import CampaignLocationRegion from './CampaignLocationRegion';
import CampaignLocations from './CampaignLocations';

export function CampaignLocationsIndex({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <Switch>
        <PropsRoute
          exact
          path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/locations'
          user={user}
          organization={organization}
          campaign={campaign}
          component={CampaignLocations}
        />
        <PropsRoute
          exact
          path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/locations/:country'
          user={user}
          organization={organization}
          campaign={campaign}
          component={CampaignLocationCountry}
        />
        <PropsRoute
          exact
          path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/locations/:country/:region'
          user={user}
          organization={organization}
          campaign={campaign}
          component={CampaignLocationRegion}
        />
      </Switch>
    </>
  );
}
