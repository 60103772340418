import {useQuery} from '@apollo/client';
import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import Loading from '@core/ui/Loading';
import PODLINK_DOMAIN_QUERY from '@analytics/graphql-api/_old_queries/podlink/PodlinkDomainQuery';
import {podlinkDomainRoutes} from '@analytics/lib/routes/podlinkRoutes';
import {ManagePodlinkRouteProps} from '..';
import {PodlinkDomain} from './PodlinkDomain';
import {PodlinkDomainDesigner} from './PodlinkDomainDesigner';
import {PodlinkPodcastDesigner} from './PodlinkDomainPodcastDesigner';
import {PodlinkDomainUpdate} from './PodlinkDomainUpdate';

export type ManagePodlinkDomainRouteProps = ManagePodlinkRouteProps & {
  podlinkDomain: TSFixMe;
};

export function PodlinkDomainIndex({
  user,
  organization,
  podcasts,
  match: {
    params: {podlinkDomainSlug},
  },
}: ManagePodlinkRouteProps & {
  match: {
    params: {
      podlinkDomainSlug: string;
    };
  };
}) {
  const {loading, error, data} = useQuery(PODLINK_DOMAIN_QUERY, {
    variables: {
      organizationId: organization.id,
      slug: podlinkDomainSlug,
    },
  });

  if (loading) {
    return <Loading fixed />;
  }

  if (error) {
    return <Redirect to={createOrgPath(organization, 'manage/podlink')} />;
  }

  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, `manage/podlink/${podlinkDomainSlug}`),
    basePath: '/:part(b|p)/:slug/manage/podlink/:podlinkDomainSlug',
    routes: podlinkDomainRoutes(),
  });

  const podlinkDomain = data?.me?.organization?.podlinkDomain;

  return (
    <>
      <Switch>
        {renderRoutes({
          components: {
            domain: PodlinkDomain,
            update: PodlinkDomainUpdate,
            design: PodlinkDomainDesigner,
            'podcast-designer': PodlinkPodcastDesigner,
          },
          extraProps: {
            user,
            organization,
            podcasts,
            podlinkDomain,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
}
