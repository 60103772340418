import React from 'react';
import {useQuery} from '@apollo/client';
import {comma, toPercent} from '@core/lib/filters';
import {Element} from '@core/style';
import {ToastMessage} from '@core/ui/Alert';
import {Breadcrumbs} from '@core/ui/Breadcrumbs';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {LoadingSpinner} from '@core/ui/Loading';
import {campaignPixelURL} from '@analytics/components/nav/CampaignPixelNav';
import BRAND_CAMPAIGN_REFERRERS_QUERY from '@analytics/graphql-api/_old_queries/BrandCampaignReferrersQuery';
import {CampaignPixelRouteProps} from '../_routes';

export function CampaignReferrer({
  organization,
  campaign,
  pixel,
  match: {
    params: {domain},
  },
}: CampaignPixelRouteProps & {
  match: {
    params: {domain: string};
  };
}) {
  const {loading, data, error} = useQuery(BRAND_CAMPAIGN_REFERRERS_QUERY, {
    variables: {
      domain: domain ? domain : null,
      id: campaign.id,
      limit: 100,
      offset: 0,
      organizationId: organization.id,
    },
  });

  let content: React.ReactNode;

  if (loading) {
    content = <LoadingSpinner centered />;
  } else if (error) {
    content = (
      <ToastMessage alertType='error'>
        Network error. Please try again later
      </ToastMessage>
    );
  } else {
    const pixelBaseUrl = campaignPixelURL(organization, campaign, pixel);
    const {referrers} = data.me.organization.campaign.attribution.users;
    const tableData = referrers.nodes.map(({referrer, count}) => {
      return {
        displayReferrerName: referrer ? referrer : '(direct)',
        referrer,
        count,
        to: `${pixelBaseUrl}/users?referrer=${encodeURIComponent(
          referrer ? referrer : 'direct'
        )}`,
      };
    });

    const columns = [
      {
        title: 'Referrer',
        type: 'string',
        accessor: 'displayReferrerName',
      },
      {
        title: 'Count',
        type: 'number',
        accessor: 'count',
        Cell: ({data: {count}}) => (
          <Element
            rules={() => ({
              display: 'flex',
              justifyContent: 'flex-end',
              whiteSpace: 'nowrap',
            })}>
            {comma(count)}
            <Element
              rules={({theme}) => ({
                color: theme.textTertiary,
                marginLeft: '0.25rem',
              })}>
              ({toPercent(count / referrers.sum)})
            </Element>
          </Element>
        ),
      },
    ] as DataTableColumn[];

    content = (
      <DataTable
        columns={columns}
        data={tableData}
        orderBy='-count'
        emptyMessageText='No referrers found.'
        onClickRowPath={({to}) => to}
      />
    );
  }

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            title: 'Referrers',
            to: `${campaignPixelURL(organization, campaign, pixel)}/referrers`,
          },
          {
            title: domain,
          },
        ]}
        css={`
          margin: 0 1.5rem 0.75rem;
          padding: 0;
        `}
      />

      <Card>
        <CardHeader>{domain}</CardHeader>
        {content}
      </Card>
    </>
  );
}
