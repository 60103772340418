import {Element} from '@core/style';
import {GREEN, RED, lighten} from '@core/style';

// TODO: REMOVE THIS COMPONENT FROM EXISTING CODE AND THIS FILE

export interface RightStatProps {
  error?: boolean;
  primary?: boolean;
  success?: boolean;
  title: string;
  value: string;
}

const RightStat = ({
  error,
  primary,
  success,
  title,
  value,
}: RightStatProps): JSX.Element => (
  <Element rules={() => ({textAlign: 'right', marginBottom: '0.3125rem'})}>
    <Element
      tag='b'
      style-success={!!success}
      style-error={!!error}
      style-dark={!!primary}
      rules={({primary, success, error}) => ({
        fontSize: primary ? '1.375rem' : '0.875rem',
        color: (success && GREEN) || (error && lighten(RED, 0.35)) || '#333',
      })}>
      {value}
    </Element>
    <Element
      rules={() => ({
        fontSize: '0.75rem',
        lineHeight: '1',
      })}>
      {title}
    </Element>
  </Element>
);

export default RightStat;
