import dayjs from '@core/lib/dayjs';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import {
  FetchedOrganization,
  useFutureEpisodesQuery,
} from '@analytics/graphql-api';
import UpcomingEpisodesCalendar from './UpcomingEpisodesCalendar';
import useUpcomingEpisodesCalendarData from './useUpcomingEpisodesCalendarData';

const formatDate = (date: dayjs.Dayjs) => date.utc().format('YYYY-MM-DD');

const UpcomingEpisodesPodcasterCalendar = ({
  organization,
}: {
  organization: FetchedOrganization;
}) => {
  const today = dayjs();
  const endRange = today.add(6, 'M').endOf('M');

  const {loading, error, data} = useFutureEpisodesQuery({
    variables: {
      organizationId: organization.id,
      after: formatDate(today),
      before: formatDate(endRange),
    },
  });

  const upcomingEpisodes = useUpcomingEpisodesCalendarData(
    data?.me?.organization?.futureEpisodes
  );

  return (
    <>
      {loading || !upcomingEpisodes ? (
        <Loading centered />
      ) : error || !upcomingEpisodes.length ? (
        <EmptyMessage title='No Upcoming Episodes found'>
          No upcoming epsidoes scheduled within the next 6 months.
        </EmptyMessage>
      ) : (
        <UpcomingEpisodesCalendar
          endRange={endRange}
          upcomingEpisodes={upcomingEpisodes}
          organization={organization}
        />
      )}
    </>
  );
};

export default UpcomingEpisodesPodcasterCalendar;
