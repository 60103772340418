import React, {FC, useEffect} from 'react';
import useSettings from '@analytics/components/settings/useSettings';
import {OrganizationObject, PodcastObject} from '@analytics/graphql-api';
import DesignDomain from './DesignDomain';
import DesignPodcast from './DesignPodcast';

interface DesignerProps {
  organization: OrganizationObject;
  podcast?: PodcastObject;
  podlinkDomain?: string;
  onSave?: (_: any) => void;
  onCancel?: (_: any) => void;
}

const Designer: FC<DesignerProps> = ({
  organization,
  podcast,
  podlinkDomain,
  onSave,
  onCancel,
}): JSX.Element => {
  const [_, dispatch] = useSettings();

  const hideUi = (to: boolean) => {
    dispatch({
      type: 'UPDATE_SETTINGS',
      settings: {hideUi: to},
    });
  };

  useEffect(() => {
    hideUi(true);
    return () => hideUi(false);
  }, []);

  if (podcast) {
    return (
      <DesignPodcast
        podcast={podcast}
        organization={organization}
        onSave={onSave}
        onCancel={onCancel}
      />
    );
  } else if (podlinkDomain) {
    return (
      <DesignDomain
        podlinkDomain={podlinkDomain}
        organization={organization}
        onSave={onSave}
        onCancel={onCancel}
      />
    );
  }

  return <></>;
};

export default Designer;
