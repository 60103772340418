import {Redirect, Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import CampaignEpisodeNav from '@analytics/components/nav/CampaignEpisodeNav';
import {useGetCampaignEpisodeQuery} from '@analytics/graphql-api';
import {toCampaignUrl} from '../../../../_routes';
import CampaignEpisode from './CampaignEpisode';
import CampaignEpisodeConsistency from './CampaignEpisodeConsistency';

export const CampaignEpisodeIndex = ({
  user,
  organization,
  campaign,
  campaignPodcast,
  match: {
    params: {campaignEpisodeId},
  },
}) => {
  const {data, loading, error} = useGetCampaignEpisodeQuery({
    variables: {
      organizationId: organization.id,
      campaignId: campaign.id,
      id: campaignEpisodeId,
    },
  });

  if (loading) {
    return <Loading fixed />;
  }

  if (error) {
    return <Redirect to={toCampaignUrl(organization, campaign)} />;
  }

  const campaignEpisode = data?.me?.organization?.campaign?.campaignEpisode;

  return (
    <>
      <CampaignEpisodeNav
        user={user}
        organization={organization}
        campaign={campaign}
        campaignPodcast={campaignPodcast}
        campaignEpisode={campaignEpisode}
      />
      <Switch>
        <PropsRoute
          exact
          path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/podcasts/:campaignPodcastId/episodes/:campaignEpisodeId'
          user={user}
          organization={organization}
          campaign={campaign}
          campaignPodcast={campaignPodcast}
          campaignEpisode={campaignEpisode}
          component={CampaignEpisode}
        />
        <PropsRoute
          exact
          path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/podcasts/:campaignPodcastId/episodes/:campaignEpisodeId/consistency'
          user={user}
          organization={organization}
          campaign={campaign}
          campaignPodcast={campaignPodcast}
          campaignEpisode={campaignEpisode}
          component={CampaignEpisodeConsistency}
        />
      </Switch>
    </>
  );
};
