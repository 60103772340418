import React from 'react';
import {Icon} from '@core/ui/Icon';
import ResponsiveContent from '@core/ui/ResponsiveContent';

interface GettingStartedStepsProps {
  steps: {
    label: string;
    completed: boolean;
    renderContent: () => React.ReactNode;
  }[];
  children: React.ReactNode[];
}

const GettingStartedSteps = ({
  steps,
  children,
}: GettingStartedStepsProps): JSX.Element => {
  let stepsCompleted = 0;

  for (const step of steps) {
    if (step.completed) {
      stepsCompleted += 1;
    } else {
      break;
    }
  }

  const currentStep = steps[stepsCompleted];

  if (stepsCompleted === steps.length) {
    return <>{children}</>;
  }
  return (
    <ResponsiveContent>
      <div css={{margin: '1.5rem 0'}}>
        <div css={{display: 'flex', alignItems: 'center'}}>
          {steps.map((step, idx) => {
            const completed = idx < steps.indexOf(currentStep);
            const isCurrentStep = step === currentStep;

            return (
              <div
                key={step.label}
                css={{
                  alignItems: 'center',
                  color:
                    completed || isCurrentStep
                      ? 'var(--text-default)'
                      : 'var(--text-muted)',
                  display: 'flex',
                  marginRight: '1.75rem',
                  fontWeight: 500,
                }}>
                {completed ? (
                  <Icon
                    icon='checkbox-marked-circle'
                    rules={() => ({color: 'var(--black)'})}
                  />
                ) : (
                  <div
                    css={{
                      border: `2px solid ${
                        isCurrentStep ? 'var(--black)' : 'var(--text-muted)'
                      }`,
                      borderRadius: '2rem',
                      color: isCurrentStep
                        ? 'var(--black)'
                        : 'var(--text-muted)',
                      display: 'flex',
                      fontSize: '0.875rem',
                      height: '1.5rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                      lineHeight: 1,
                      width: '1.5rem',
                      padding: idx === 0 || idx === 1 ? '0 1px 0 0' : 0,
                    }}>
                    {idx + 1}
                  </div>
                )}
                <div css={{marginLeft: '0.5rem'}}>{step.label}</div>
              </div>
            );
          })}
        </div>
      </div>
      <ResponsiveContent
        containerSize='medium'
        rules={() => ({margin: '4rem auto 1.5rem'})}>
        <div
          css={{
            '> h1': {marginBottom: '1.75rem'},
            '> p': {color: 'var(--text-muted)'},
          }}>
          {currentStep.renderContent()}
        </div>
      </ResponsiveContent>
    </ResponsiveContent>
  );
};

export default GettingStartedSteps;
