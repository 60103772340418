import {useEffect, useMemo, useState} from 'react';
import {stripHTML, truncateWords} from '@core/lib/words';
import Button from '@core/ui/Button';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Dummy, Img} from '@core/ui/Image';
import {Stack} from '@core/ui/Layout';
import {Link} from '@core/ui/Link';
import {Text} from '@core/ui/Text';
import {FetchedPodcasts} from '@analytics/graphql-api';
import {getPodlinkData} from '@analytics/lib/podlink';

type Props = {
  podcasts: FetchedPodcasts;
  podlinkDomain?: TSFixMe;
};

export function PodlinkTable({podlinkDomain, podcasts}: Props) {
  const columns: DataTableColumn[] = useMemo(
    () => [
      {
        title: 'Podcast',
        accessor: 'title',
        type: 'string',
        Cell: ({d: {title, description, image}}) => (
          <Stack alignItems='center' css={{lineHeight: 1.4, minWidth: '15rem'}}>
            <div
              css={{
                flex: '0 0 4.6875rem',
                marginRight: '1rem',
                maxHeight: '4.6875rem',
              }}>
              {image ? <Img image={image} width={150} /> : <Dummy />}
            </div>
            <div css={{flex: 1}}>
              <Text variant='subtitle2'>{title}</Text>
              <Text color='muted' variant='body3'>
                {truncateWords(stripHTML(description ?? ''), 10)}
              </Text>
            </div>
          </Stack>
        ),
      },
      {
        title: 'Podlink URL',
        accessor: 'href',
        type: 'string',
        Cell: ({d: {href}}) => {
          const [url, setUrl] = useState<string>();

          useEffect(() => {
            const getPodlinkUrl = async () => {
              const data = await getPodlinkData(href);
              setUrl(data.url);
            };

            getPodlinkUrl();
          }, [href]);

          if (url)
            return (
              <Link href={url} target='_blank' css={{whiteSpace: 'nowrap'}}>
                {url}
              </Link>
            );

          return <span>-</span>;
        },
      },
      {
        title: 'Design',
        accessor: 'id',
        type: 'string',
        Cell: ({d: {id}}) => (
          <Button
            buttonSize='sm'
            to={
              podlinkDomain
                ? `podlink/${podlinkDomain.slug}/podcasts/${id}`
                : `podlink/podcasts/${id}`
            }
            variant='outlined'>
            Customize
          </Button>
        ),
      },
    ],
    [podlinkDomain]
  );

  return (
    <DataTable
      searchKeys={['title']}
      columns={columns}
      orderBy='-title'
      data={podcasts.map(({id, feed}) => ({
        ...feed,
        id,
      }))}
      searchPlaceholder='Search podcast'
      emptyMessageText='No podcasts found.'
    />
  );
}
