import { cssColorValue, cursorDisabled, focusBorderWidth, focusGapBorder, formCheckIndicator, getFocusDimensionsAndPosition, opacityDisabled, semanticColors } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
var borderRadius = 3;
var defaultBorderWidth = 1;
var activeBorderWidth = 2;
var uncheckedFocusPosition = function uncheckedFocusPosition(uncheckedBorderWidth) {
  var _getFocusDimensionsAn = getFocusDimensionsAndPosition(),
    dimension = _getFocusDimensionsAn.dimension,
    position = _getFocusDimensionsAn.position;
  return css(["inline-size:", ";block-size:", ";top:", ";left:", ";"], "calc(100% + ".concat(dimension, " + ").concat(2 * uncheckedBorderWidth, "px)"), "calc(100% + ".concat(dimension, " + ").concat(2 * uncheckedBorderWidth, "px)"), "calc(".concat(position, " - ").concat(uncheckedBorderWidth, "px)"), "calc(".concat(position, " - ").concat(uncheckedBorderWidth, "px)"));
};
export var Indicator = styled.span.withConfig({
  displayName: "Indicator",
  componentId: "sc-1airx73-0"
})(["", ""], function (props) {
  // organizing states into base (all), unchecked, checked, and indeterminate
  var baseStyles = css(["", ";", " input + label &{background-color:", ";}input:focus + label &::after{border-color:", ";}", " input:active + label &{border-color:", ";}", " input:disabled + label &{cursor:", ";opacity:", ";}"], formCheckIndicator("".concat(borderRadius, "px")), focusGapBorder("".concat(borderRadius + focusBorderWidth, "px"), props.isUsingKeyboard), cssColorValue(semanticColors.backgroundBase), cssColorValue(semanticColors.essentialBase), props.isFocus && css(["&::after{border-color:", ";}"], cssColorValue(semanticColors.essentialBase)), cssColorValue(props.semanticColor), props.isActive && css(["input + label &{border-color:", ";}"], cssColorValue(props.semanticColor)), cursorDisabled, opacityDisabled);
  var uncheckedStyles = css(["input:not(:checked) + label &&{border:", "px solid ", ";}input:not(:checked):focus + label &::after{", "}", " input:not(:checked):hover + label &{border-color:", ";}", " input:not(:checked):active + label &{border-width:", "px;border-color:", ";}input:not(:checked):active:focus + label &::after{", "}", " ", " input:not(:checked):disabled + label &{background-color:", ";border-color:", ";border-width:", "px;}"], defaultBorderWidth, cssColorValue(semanticColors.essentialSubdued), uncheckedFocusPosition(defaultBorderWidth), props.isFocus && css(["input:not(:checked) + label &&::after{", "}input:not(:checked):active + label &::after{", "}"], uncheckedFocusPosition(defaultBorderWidth), uncheckedFocusPosition(activeBorderWidth)), cssColorValue(props.semanticColor), props.isHover && css(["input:not(:checked) + label &&{border-color:", ";}"], cssColorValue(props.semanticColor)), activeBorderWidth, cssColorValue(props.semanticColor), uncheckedFocusPosition(activeBorderWidth), props.isActive && css(["input:not(:checked) + label &&{border-width:", "px;border-color:", ";}input:not(:checked):focus + label &::after{", "}"], activeBorderWidth, cssColorValue(props.semanticColor), uncheckedFocusPosition(activeBorderWidth)), props.isActive && props.isFocus && css(["input:not(:checked) + label &::after{", "}"], uncheckedFocusPosition(activeBorderWidth)), cssColorValue(semanticColors.backgroundBase), cssColorValue(semanticColors.essentialSubdued), defaultBorderWidth);
  var checkedStyles = css(["input:checked + label &&{background-color:", ";border-width:0;&::before{box-sizing:border-box;background-color:unset;border-bottom:2px solid;border-left:2px solid;border-color:", ";display:block;content:'';block-size:5px;inline-size:9px;position:absolute;top:46%;left:50%;transform:translate3d(-50%,-50%,0) rotate(-48deg);}}input:checked:disabled + label &{background-color:", ";}"], cssColorValue(props.semanticColor), cssColorValue(semanticColors.backgroundBase), cssColorValue(props.semanticColor));

  /*
   * We use a CSS class bc the styling of indeterminate can be inconsistent across browsers.
   * Also, indeterminate is a HTML property (not an attribute) so it requires added
   * complexity to manage refs in the React component, whereas a class is more straightforward.
   */
  var indeterminateStyles = css(["input + label &.Encore_indeterminate{background-color:", ";border-width:0;&::before{background-color:", ";content:'';block-size:0.15em;left:50%;position:absolute;top:51%;transform:translate(-50%,-50%);inline-size:0.5em;}}input:disabled + label &.Encore_indeterminate{background-color:", ";}"], cssColorValue(props.semanticColor), cssColorValue(semanticColors.backgroundBase), cssColorValue(props.semanticColor));
  return css(["", ";", " ", " ", ""], baseStyles, !props.indeterminate && uncheckedStyles, props.indeterminate && indeterminateStyles, checkedStyles);
});