import React, {useEffect, useState} from 'react';
import {Element} from '@core/style';
import {ToastMessage} from '@core/ui/Alert';
import Loading from '@core/ui/Loading';
import DesignPreview from '@analytics/components/designer/DesignPreview';
import LinkDesignForm from '@analytics/components/links/LinkDesignForm';
import useSettings from '@analytics/components/settings/useSettings';
import {getPodlinkData, getPodlinkEpsiodes} from '@analytics/lib/podlink';

const LinkDesignerContent = ({
  organization,
  campaign,
  campaignLink,
  onCancel,
  url,
  episodes,
}) => {
  return (
    <Element
      rules={() => ({
        display: 'grid',
        gridTemplateColumns: '23.75rem 1fr',
        height: '100%',
        width: '100%',
      })}>
      <Element
        rules={({theme}) => ({
          borderRight: `2px solid ${theme.borderPrimary}`,
          minWidth: 0,
        })}>
        <LinkDesignForm
          organization={organization}
          campaign={campaign}
          campaignLink={campaignLink}
          previewURL={url}
          episodes={episodes}
          onCancel={onCancel}
        />
      </Element>
      <DesignPreview previewURL={url} config={{}} />
    </Element>
  );
};

const LinkDesigner = ({organization, campaign, campaignLink, onCancel}) => {
  const [settings, dispatch] = useSettings();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [podlinkData, setPodlinkData] = useState({url: '', episodes: []});

  const {
    target: {
      feed: {href},
    },
  } = campaignLink;

  const hideUi = (to) => {
    dispatch({
      type: 'UPDATE_SETTINGS',
      settings: {hideUi: to},
    });
  };

  useEffect(() => {
    hideUi(true);
    return () => hideUi(false);
  }, []);

  useEffect(() => {
    const fetchPodlinkData = async () => {
      try {
        const {url} = await getPodlinkData(href);
        const episodes = await getPodlinkEpsiodes(url);

        setPodlinkData({
          url,
          episodes,
        });
      } catch (err) {
        setError(true);
      }

      setLoading(false);
    };

    fetchPodlinkData();
  }, []);

  if (error) {
    return (
      <ToastMessage alertType='error' rules={() => ({margin: 0})}>
        Error loading pod.link data
      </ToastMessage>
    );
  } else if (loading) {
    return <Loading fixed />;
  }
  return (
    <LinkDesignerContent
      organization={organization}
      campaign={campaign}
      campaignLink={campaignLink}
      onCancel={onCancel}
      {...podlinkData}
    />
  );
};

export default LinkDesigner;
