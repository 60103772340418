import styled from 'styled-components';
import {Link} from '../Link';

type MenuListItem = {
  title: string;
  to?: string;
};

export interface MenuListProps {
  onClickRow?: (item: MenuListItem) => void;
  items: MenuListItem[];
}

export const MenuList = ({items = [], onClickRow, ...props}: MenuListProps) => {
  return (
    <Root role='navigation' {...props}>
      {items.map((item) =>
        item.to ? (
          <MenuListItem
            key={item.title}
            as={Link}
            to={item.to}
            underline='none'
            onClick={() => onClickRow?.(item)}
            css={`
              :hover {
                background: var(--bg-subtle);
              }
            `}>
            {item.title}
          </MenuListItem>
        ) : (
          <MenuListItem key={item.title} onClick={() => onClickRow?.(item)}>
            {item.title}
          </MenuListItem>
        )
      )}
    </Root>
  );
};

const Root = styled.div`
  border-radius: 0.5rem;
  border: 1px solid var(--border-default);
  overflow: hidden;
`;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const MenuListItem = styled.div`
  border-bottom: 1px solid var(--bg-muted);
  display: block;
  padding: var(--spacing-3) var(--spacing-5);
  &:last-child {
    border-bottom: none;
  }
`;
