import {useRequestNewBrandForSpotifyOrderMutation} from '@admin/graphql-api';
import {
  Box,
  ButtonPrimary,
  DialogConfirmation,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Type,
} from '@spotify-internal/encore-web';
import {Controller, useForm} from 'react-hook-form';
import {DIALOGUE_STATES} from './DialogueStates';

const FIELD_NAMES = {
  SPOTIFY_ORDER_ID: 'spotify-order-id',
  ORGANIZATION_NAME: 'organization-name',
  FIRST_NAME: 'first-name',
  LAST_NAME: 'last-name',
  EMAIL: 'email',
  SALES_ORDER_BOOKED: 'sales-order-booked',
  CAMPAIGN_TYPE: 'campaign-type',
  ADVERTISER_WEBSITE: 'advertiser-website',
  OPPORTUNITY_NAME: 'opportunity-name',
  COMMENTS: 'comments'
};

export const SubmitATicketDialogue = ({
  id,
  onClose,
  setApprovalDialogueState,
}) => {
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: {
      [FIELD_NAMES.SPOTIFY_ORDER_ID]: id,
    },
  });
  const [requestNewBrandForSpotifyOrder] =
    useRequestNewBrandForSpotifyOrderMutation();

  const onSubmit = async (data) => {
    const input = {
      spotifyOrderId: data[FIELD_NAMES.SPOTIFY_ORDER_ID],
      brandName: data[FIELD_NAMES.ORGANIZATION_NAME],
      brandContactFirstName: data[FIELD_NAMES.FIRST_NAME],
      brandContactLastName: data[FIELD_NAMES.LAST_NAME],
      brandContactEmail: data[FIELD_NAMES.EMAIL],
      bookedStatus:
        data[FIELD_NAMES.SALES_ORDER_BOOKED] === 'yes-attribution-campaign'
          ? 'Y'
          : 'N',
      campaignKind:
        data[FIELD_NAMES.CAMPAIGN_TYPE] === 'attribution-campaign'
          ? 'attribution'
          : 'reporting',
      advertiserWebsite: data[FIELD_NAMES.ADVERTISER_WEBSITE],
      opportunityName: data[FIELD_NAMES.OPPORTUNITY_NAME],
      comments: data[FIELD_NAMES.COMMENTS],
    };

    try {
      const response = await requestNewBrandForSpotifyOrder({
        variables: {input},
      });
      onClose();
      console.log(response);
    } catch (error) {
      setApprovalDialogueState(DIALOGUE_STATES.ERROR);
      console.error(error);
    }
  };

  return (
    <DialogConfirmation
      aria-label='Approved Dialogue'
      dialogTitle='Create a new organization'
      body={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Type variant='mesto'>
            Submit a ticket to the Customer Success team to create a new
            organization in Spotify Ad Analytics
          </Type>

          <Box style={{paddingLeft: 0, marginTop: 24}}>
            <FormGroup
              label='New organization name'
              labelFor={FIELD_NAMES.ORGANIZATION_NAME}>
              <Controller
                name={FIELD_NAMES.ORGANIZATION_NAME}
                control={control}
                rules={{required: 'Organization name is required'}}
                render={({field}) => (
                  <FormInput
                    {...field}
                    id={FIELD_NAMES.ORGANIZATION_NAME}
                    type='text'
                    placeholder='Organization name'
                  />
                )}
              />
              {errors[FIELD_NAMES.ORGANIZATION_NAME] && (
                <Type semanticColor='textNegative'>
                  {errors[FIELD_NAMES.ORGANIZATION_NAME]?.message as string}
                </Type>
              )}
            </FormGroup>
            <FormGroup
              label='Campaign Type'
              labelFor={FIELD_NAMES.CAMPAIGN_TYPE}>
              <Controller
                name={FIELD_NAMES.CAMPAIGN_TYPE}
                control={control}
                rules={{required: 'Campaign type is required'}}
                render={({field}) => (
                  <FormSelect {...field} defaultValue=''>
                    <option disabled value=''>
                      Select campaign type
                    </option>
                    <option value='attribution-campaign'>
                      Attribution campaign
                    </option>
                    <option value='reporting-campaign'>
                      Reporting campaign
                    </option>
                  </FormSelect>
                )}
              />
              {errors[FIELD_NAMES.CAMPAIGN_TYPE] && (
                <Type semanticColor='textNegative'>
                  {errors[FIELD_NAMES.CAMPAIGN_TYPE]?.message as string}
                </Type>
              )}
            </FormGroup>
            <FormGroup
              label='Advertiser website'
              labelFor={FIELD_NAMES.ADVERTISER_WEBSITE}>
              <Controller
                name={FIELD_NAMES.ADVERTISER_WEBSITE}
                control={control}
                rules={{required: 'Advertiser website is required'}}
                render={({field}) => (
                  <FormInput
                    {...field}
                    id={FIELD_NAMES.ADVERTISER_WEBSITE}
                    type='text'
                    placeholder='Advertiser website'
                  />
                )}
              />
              {errors[FIELD_NAMES.ADVERTISER_WEBSITE] && (
                <Type semanticColor='textNegative'>
                  {errors[FIELD_NAMES.ADVERTISER_WEBSITE]?.message as string}
                </Type>
              )}
            </FormGroup>
            <FormGroup
              label='Opportunity name'
              labelFor={FIELD_NAMES.OPPORTUNITY_NAME}>
              <Controller
                name={FIELD_NAMES.OPPORTUNITY_NAME}
                control={control}
                rules={{required: 'Opportunity name is required'}}
                render={({field}) => (
                  <FormInput
                    {...field}
                    id={FIELD_NAMES.OPPORTUNITY_NAME}
                    type='text'
                    placeholder='Opportunity name'
                  />
                )}
              />
              {errors[FIELD_NAMES.OPPORTUNITY_NAME] && (
                <Type semanticColor='textNegative'>
                  {errors[FIELD_NAMES.OPPORTUNITY_NAME]?.message as string}
                </Type>
              )}
            </FormGroup>
            <FormGroup
              label='First name (of brand contact)'
              labelFor={FIELD_NAMES.FIRST_NAME}>
              <Controller
                name={FIELD_NAMES.FIRST_NAME}
                control={control}
                rules={{required: 'First name is required'}}
                render={({field}) => (
                  <FormInput
                    {...field}
                    id={FIELD_NAMES.FIRST_NAME}
                    type='text'
                    placeholder='First name'
                  />
                )}
              />
              {errors[FIELD_NAMES.FIRST_NAME] && (
                <Type semanticColor='textNegative'>
                  {errors[FIELD_NAMES.FIRST_NAME]?.message as string}
                </Type>
              )}
            </FormGroup>
            <FormGroup
              label='Last name (of brand contact)'
              labelFor={FIELD_NAMES.LAST_NAME}>
              <Controller
                name={FIELD_NAMES.LAST_NAME}
                control={control}
                rules={{required: 'Last name is required'}}
                render={({field}) => (
                  <FormInput
                    {...field}
                    id={FIELD_NAMES.LAST_NAME}
                    type='text'
                    placeholder='Last name'
                  />
                )}
              />
              {errors[FIELD_NAMES.LAST_NAME] && (
                <Type semanticColor='textNegative'>
                  {errors[FIELD_NAMES.LAST_NAME]?.message as string}
                </Type>
              )}
            </FormGroup>
            <FormGroup
              label='Email (of brand contact)'
              labelFor={FIELD_NAMES.EMAIL}>
              <Controller
                name={FIELD_NAMES.EMAIL}
                control={control}
                rules={{required: 'Email is required'}}
                render={({field}) => (
                  <FormInput
                    {...field}
                    id={FIELD_NAMES.EMAIL}
                    type='text'
                    placeholder='Email'
                  />
                )}
              />
              {errors[FIELD_NAMES.EMAIL] && (
                <Type semanticColor='textNegative'>
                  {errors[FIELD_NAMES.EMAIL]?.message as string}
                </Type>
              )}
            </FormGroup>
            <FormGroup
              label='Spotify order ID'
              labelFor={FIELD_NAMES.SPOTIFY_ORDER_ID}>
              <Controller
                name={FIELD_NAMES.SPOTIFY_ORDER_ID}
                control={control}
                rules={{required: 'Order ID is required'}}
                render={({field}) => (
                  <FormInput
                    {...field}
                    id={FIELD_NAMES.SPOTIFY_ORDER_ID}
                    type='text'
                    placeholder='Order ID'
                  />
                )}
              />
              {errors[FIELD_NAMES.SPOTIFY_ORDER_ID] && (
                <Type semanticColor='textNegative'>
                  {errors[FIELD_NAMES.SPOTIFY_ORDER_ID]?.message as string}
                </Type>
              )}
            </FormGroup>
            <FormGroup
              label='Additional Information'
              labelFor={FIELD_NAMES.COMMENTS}>
              <Controller
                name={FIELD_NAMES.COMMENTS}
                control={control}
                render={({field}) => (
                  <FormTextarea
                    {...field}
                    id={FIELD_NAMES.COMMENTS}
                    type='text'
                    placeholder='Additional Information'
                  />
                )}
              />
            </FormGroup>
            <FormGroup
              label='Has the sales order been booked in CloudSense?'
              labelFor={FIELD_NAMES.SALES_ORDER_BOOKED}>
              <Controller
                name={FIELD_NAMES.SALES_ORDER_BOOKED}
                control={control}
                rules={{required: 'Booked status is required'}}
                render={({field}) => (
                  <FormSelect {...field} defaultValue=''>
                    <option disabled value=''>
                      Select booked status
                    </option>
                    <option value='yes-attribution-campaign'>
                      Yes, it is booked in CloudSense
                    </option>
                    <option value='no-attribution-campaign'>
                      No, it is not booked in CloudSense
                    </option>
                  </FormSelect>
                )}
              />
              {errors[FIELD_NAMES.SALES_ORDER_BOOKED] && (
                <Type semanticColor='textNegative'>
                  {errors[FIELD_NAMES.SALES_ORDER_BOOKED]?.message as string}
                </Type>
              )}
            </FormGroup>
            <ButtonPrimary size='medium' type='submit'>
              Submit
            </ButtonPrimary>
          </Box>
        </form>
      }
    />
  );
};
