import {RouteType} from '@core/lib/useRoutes';

const researchRoutes = (): RouteType[] => {
  return [
    {
      key: 'research',
      title: 'Research',
      path: '',
      exact: true,
    },
    {
      key: 'brands',
      title: 'Brands',
    },
    {
      key: 'podcasts',
      title: 'Podcasts',
    },
    {
      key: 'rankings',
      title: 'Rankings',
    },
    {
      key: 'publishers',
      title: 'Publishers',
    },
    {
      key: 'reviewers',
      title: 'Reviewers',
    },
  ];
};

export const researchReviewersRoutes = (): RouteType[] => [
  {key: 'reviewers', exact: true, path: ''},
  {key: 'create', exact: true},
  {key: 'classify', exact: true},
  {key: 'ads', exact: true},
  {key: 'ad', exact: true, path: 'ads/:adId'},
  {key: 'feedAd', exact: true, path: 'ads/feed/:feedId'},
  {key: 'episodes', exact: true},
  {key: 'transcribable'},
  {key: 'companies'},
  {key: 'reviewer', exact: true, path: ':reviewerId'},
];

export const researchCompanyRoutes = (): RouteType[] => [
  {key: 'overview', path: '', exact: true},
  {key: 'export', exact: true},
  {key: 'edit', exact: true},
  {key: 'podcasts'},
  {key: 'ads', exact: true},
  {key: 'ad', path: 'ads/:adId'},
  {key: 'similar'},
];

export default researchRoutes;
