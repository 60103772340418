import {useCallback, useMemo, useState} from 'react';
import {useMutation} from '@apollo/client';
import {Controller, useForm} from 'react-hook-form';
import {Element} from '@core/style';
import {GREEN, HIGHLIGHTER_40} from '@core/style';
import {IconButton} from '@core/ui/Button/LegacyButton';
import DataTable from '@core/ui/DataTable';
import {FORM_ERRORS, Form, Submit} from '@core/ui/FormElements';
import {Icon} from '@core/ui/Icon';
import UPDATE_CAMPAIGN_BRAND_AGENCIES from '@analytics/graphql-api/_old_mutations/UpdateCampaignBrandAgencies';
import BRAND_CAMPAIGN_AGENCIES_QUERY from '@analytics/graphql-api/_old_queries/BrandCampaignAgenciesQuery';

const baseColumns = [
  {
    title: 'Campaign',
    accessor: 'name',
    type: 'string',
  },

  {
    title: 'Publisher Name',
    accessor: 'publisherName',
    type: 'string',
  },

  {
    title: 'Agency Access Toggle',
    accessor: 'agencyAccessToggle',
    type: 'boolean',
    longDescription: `If this switch is on, you can set any agency to have access to view this campaign.`,
    Cell: ({data}) => {
      return data.isHiddenFromAgency ? 'Off' : 'On';
    },
  },
];

const objForEach = (object, callback) => {
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      callback(key, object[key]);
    }
  }
};

const EditManagerAccessForm = ({
  organization,
  campaigns,
  managers,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);
  const [updateCampaignBrandAgencies] = useMutation(
    UPDATE_CAMPAIGN_BRAND_AGENCIES,
    {
      update: async (
        cache,
        {
          data: {
            updateCampaignBrandAgencies: {campaigns},
          },
        }
      ) => {
        const q = {
          query: BRAND_CAMPAIGN_AGENCIES_QUERY,
          variables: {
            organizationId: organization.id,
          },
        };

        const data = await cache.readQuery(q);
        return await cache.writeQuery({
          data: {
            me: {
              ...data.me,
              organization: {
                ...data.me.organization,
                campaigns,
              },
            },
          },
          ...q,
        });
      },
    }
  );
  const {
    control,
    handleSubmit,
    reset,
    setError,
    watch,
    formState: {errors},
  } = useForm();

  const tableData = useMemo(
    () =>
      campaigns.map(
        ({id, name, isHiddenFromAgency, organization, buySideAgencies}) => ({
          id,
          name,
          isHiddenFromAgency,
          publisherName: organization.name,
          ...managers.reduce((acc, {id}) => {
            acc[id] =
              buySideAgencies.filter(
                ({organizationId}) => organizationId === id
              ).length > 0;
            return acc;
          }, {}),
        })
      ),
    [campaigns]
  );

  const columns = useMemo(
    () =>
      baseColumns.concat(
        managers.map(({id, name}) => {
          return {
            title: name,
            accessor: id,
            type: 'string',
            Cell: ({data}) => {
              return (
                <Controller
                  name={`${data.id}.${id}`}
                  control={control}
                  render={({field}) => {
                    const enabled =
                      typeof field.value !== 'undefined'
                        ? field.value
                        : data[id];

                    return (
                      <IconButton
                        type='button'
                        onClick={() => {
                          field.onChange(!enabled);
                        }}>
                        {enabled ? (
                          <Icon
                            icon='checkbox-marked-circle'
                            rules={() => ({
                              color: GREEN,
                              ':hover': {
                                color: GREEN,
                                filter: 'brightness(1.05)',
                              },
                            })}
                          />
                        ) : (
                          <Icon
                            icon='add-circle-outline'
                            rules={({theme}) => ({
                              color: theme.iconSecondary,
                            })}
                          />
                        )}
                      </IconButton>
                    );
                  }}
                />
              );
            },
          };
        })
      ),
    [managers]
  );

  const getUpdates = useCallback(
    (formData) => {
      const updates = [];
      objForEach(formData, (campaignId, agencies) => {
        objForEach(agencies, (agencyId, enabled) => {
          if (typeof enabled === 'boolean') {
            const match = tableData.find((d) => d.id === campaignId);

            if (match && match[agencyId] !== enabled) {
              updates.push({
                campaignId,
                organizationId: agencyId,
                enabled,
              });
            }
          }
        });
      });
      return updates;
    },
    [tableData]
  );

  const onSubmit = async (data) => {
    setLoading(true);
    const updates = getUpdates(data, tableData);

    try {
      await updateCampaignBrandAgencies({
        variables: {
          input: {
            organizationId: organization.id,
            updates,
          },
        },
      });
      await refetch();
      setLoading(false);
      reset();
    } catch (err) {
      setLoading(false);
      setError(null, FORM_ERRORS.network);
    }
  };

  const updates = getUpdates(watch(), tableData);
  const displayEditionToolbar = updates.length > 0 || loading;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
      <Element
        rules={loading ? () => ({opacity: 0.7, pointerEvents: 'none'}) : null}>
        <DataTable
          searchKeys={['name', 'publisherName']}
          columns={columns}
          data={tableData}
          rowRuleFn={({data, theme}) => {
            const changes = updates.find(
              (update) => data.id === update.campaignId
            );
            if (changes) {
              return {
                background: HIGHLIGHTER_40,
                color: theme.name === 'dark' ? theme.gray7 : 'inherit',
                ':hover': {
                  background: HIGHLIGHTER_40,
                },
              };
            }
            return {};
          }}
        />
      </Element>
      <Element
        rules={({theme}) => ({
          alignItems: 'center',
          background: theme.textPrimary,
          borderTopLeftRadius: '1rem',
          borderTopRightRadius: '1rem',
          bottom: '0',
          color: theme.textInverse,
          display: 'flex',
          fontSize: '0.9375rem',
          left: '15.5rem',
          opacity: displayEditionToolbar ? 1 : 0.5,
          padding: '0.75rem 1.25rem 1.25rem',
          position: 'fixed',
          right: '1.5rem',
          transform: displayEditionToolbar
            ? 'translateY(0)'
            : 'translateY(4.375rem)',
          transition: 'transform 0.4s cubic-bezier(0.25, 1, 0.5, 1)',
          trasnformOrigin: 'bottom',
          whiteSpace: 'nowrap',
          zIndex: 1,
        })}>
        <Element rules={() => ({marginRight: 'auto'})}>
          {updates.length} updates
        </Element>
        <Submit loading={loading}>Save Changes</Submit>
      </Element>
    </Form>
  );
};

export default EditManagerAccessForm;
