import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {Button} from '@core/ui/Button';
import Select from '@core/ui/Select';
import {ApprovalsReportContext} from './ApprovalsConfirmReport';
import ApprovalsStateBadge from './ApprovalsStateBadge';
import {
  ApprovalsStateName,
  COMMON_REJECT_REASONS,
  DYNAMIC_REJECT_REASONS,
} from './helpers';

export type ApprovalsStateFormCellStateType = {
  state: ApprovalsStateName;
  rejectedReason: string;
  comment: string;
};

type ApprovalsStateFormCellDataType = {
  id: string;
  state: ApprovalsStateName;
};

type ApprovalsStateFormCellProps = {
  data: ApprovalsStateFormCellDataType;
  isEmbedded?: boolean;
  onUpdate: (_props: {
    state: ApprovalsStateFormCellStateType;
    data: ApprovalsStateFormCellDataType;
  }) => void;
};

const ApprovalsStateFormCell: FC<ApprovalsStateFormCellProps> = ({
  data,
  onUpdate,
  isEmbedded = false,
}): JSX.Element => {
  const {forceUpdateEdition} = useContext(ApprovalsReportContext);
  const [state, setState] = useState<ApprovalsStateFormCellStateType>({
    state: 'REQUESTED',
    rejectedReason: '',
    comment: '',
  });

  const handleNewState = useCallback(
    (_state: ApprovalsStateFormCellStateType) => {
      setState(_state);
      onUpdate({state: _state, data});
    },
    [data, onUpdate]
  );

  useEffect(() => {
    if (forceUpdateEdition === 'APPROVED') {
      handleNewState({
        state: 'APPROVED',
        rejectedReason: '',
        comment: '',
      });
    } else if (forceUpdateEdition === 'REJECTED') {
      handleNewState({
        state: 'REJECTED',
        rejectedReason: '',
        comment: '',
      });
    }
  }, [forceUpdateEdition, handleNewState]);

  if (data.state === 'REQUESTED') {
    return (
      <div css={{width: '25rem'}}>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.625rem',
            '> button': {
              padding: '0 0.625rem',
            },
          }}>
          <Button
            color={state.state === 'APPROVED' ? 'secondary' : 'default'}
            iconLeading='checkmark-circle-outline'
            buttonSize='sm'
            onClick={() =>
              handleNewState({
                state: 'APPROVED',
                rejectedReason: '',
                comment: '',
              })
            }>
            Approve
          </Button>
          <Button
            color={state.state === 'REJECTED' ? 'error' : 'default'}
            buttonSize='sm'
            iconLeading='close-circle-outline'
            onClick={() =>
              handleNewState({
                ...state,
                state: 'REJECTED',
              })
            }>
            Reject
          </Button>
        </div>
        {state.state === 'REJECTED' ? (
          <div
            css={{
              background: 'var(--bg-muted)',
              borderRadius: '.5rem',
              marginTop: '0.625rem',
              padding: '0.625rem',
            }}>
            <Select
              items={
                isEmbedded ? COMMON_REJECT_REASONS : DYNAMIC_REJECT_REASONS
              }
              placeholder='Reject Reason'
              onSelect={({value}) =>
                handleNewState({
                  ...state,
                  rejectedReason: value,
                })
              }
              small
              rules={() => ({
                background: 'var(--bg-default)',
                borderColor: 'var(--border-default)',
                width: '100%',
              })}
            />
            <textarea
              placeholder='Comment (optional)'
              onChange={(evt) =>
                handleNewState({
                  ...state,
                  comment: evt.target.value,
                })
              }
              css={{
                background: 'var(--bg-default)',
                border: '1px solid var(--border-default)',
                borderRadius: '0.375rem',
                color: 'inherit',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                marginTop: '0.625rem',
                padding: '.5rem .75rem',
                resize: 'none',
                width: '100%',
              }}
            />
          </div>
        ) : null}
      </div>
    );
  }
  return <ApprovalsStateBadge state={data.state} />;
};

export default ApprovalsStateFormCell;
