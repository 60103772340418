import {graphql} from '@apollo/client/react/hoc';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import ReviewersEpisodesTable from '@analytics/components/research/ReviewersEpisodesTable';
import ReviewerEpisodesQuery from '@analytics/graphql-api/_old_queries/reviewers/ReviewerEpisodesQuery';

const ReviewersEpisodes = ({organization, data: {loading, me}}) => {
  if (loading || me === null) {
    return <Loading centered />;
  }

  const episodes = me?.organization?.research?.reviewing?.episodes ?? [];

  return (
    <>
      {episodes.length ? (
        <CardHeader>Episodes to review ({episodes.length})</CardHeader>
      ) : null}
      <Card>
        <ReviewersEpisodesTable
          episodes={episodes}
          organization={organization}
          emptyMessageText='No episodes to review.'
        />
      </Card>
    </>
  );
};

export default graphql(ReviewerEpisodesQuery, {
  options: ({organization: {id}}) => {
    return {
      variables: {
        organizationId: id,
      },
      fetchPolicy: 'network-only',
    };
  },
})(ReviewersEpisodes);
