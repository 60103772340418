import {gql} from '@apollo/client';

export default gql`
  query getReviewerDetail($organizationId: ID!, $reviewerId: ID) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          reviewer(id: $reviewerId) {
            id
            firstName
            lastName
            email

            totalEpisodeMinutes
            totalClassifyCount
            totalEpisodeCount

            episodes {
              id
              title
              description(words: 25)
              image {
                id
                path
              }
              feed {
                id
                image {
                  id
                  path
                }
              }
              enclosure {
                id
                length
              }
              hasNoAds

              reviews(reviewerId: $reviewerId, includeAdReviews: true) {
                id
                hasNoAds
                duration

                startState
                endState

                ad {
                  id
                  company {
                    id
                    name
                  }
                }

                createdAt
                updatedAt
              }
            }
          }
        }
      }
    }
  }
`;
