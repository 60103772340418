import {Banner, IconSpotifyLogo} from '@spotify-internal/encore-web';

type Props = {
  children?: React.ReactNode;
  isStreaming?: boolean;
};

export function InsightsBanner({children, isStreaming = false}: Props) {
  return (
    <Banner
      css={isStreaming ? {backgroundColor: '#DAF6E0'} : {}}
      renderIcon={IconSpotifyLogo}>
      <div css={{marginLeft: '0.5rem'}}>{children}</div>
    </Banner>
  );
}
