import {Card, CardHeader} from '@core/ui/Content';
import AdvisorPodcastIndustriesLayout from '@analytics/components/advisor/AdvisorPodcastIndustries';
import {AdvisorPodcastRouteProps} from './_routes';

export function AdvisorPodcastIndustries({
  organization,
  podcast,
}: AdvisorPodcastRouteProps) {
  return (
    <Card>
      <CardHeader>Industries</CardHeader>
      <AdvisorPodcastIndustriesLayout
        organization={organization}
        podcast={podcast}
      />
    </Card>
  );
}
