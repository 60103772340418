import {GroupBy, GroupedItem, SortBy, TableItem} from '../_types';

export const groupByTableData = ({
  data,
  groupBy,
  sortBy,
}: {
  data: TableItem[];
  groupBy: GroupBy[];
  sortBy: SortBy;
}) => {
  const groupedData = data.reduce((grouped, item) => {
    let depth = 0;
    let currentGroup = grouped;

    while (depth < groupBy.length) {
      const accessor = `${groupBy[depth]}`;
      let found = currentGroup.find(({id}) => id === item[`${accessor}Id`]);

      if (found) {
        found.impressions += item.impressions;
      } else {
        found = {
          id: item[`${accessor}Id`],
          name: item[`${accessor}Name`] ?? '',
          impressions: item.impressions,
          children: [],
        };
        currentGroup.push(found);
      }
      if (found.children) currentGroup = found.children;
      depth += 1;
    }

    currentGroup.push({
      id: item.campaignId ?? '',
      name: item.campaignName ?? '',
      impressions: item.impressions,
    });

    return grouped;
  }, [] as GroupedItem[]);

  return sortGroupedData({groupedData, sortBy});
};

/* ----------------------------------------------------------------------- */

const sortGroupedData = ({
  groupedData,
  sortBy,
}: {
  groupedData: GroupedItem[];
  sortBy: SortBy;
}) => {
  const _sorted = groupedData.sort((a, b) =>
    sortBy === 'asc'
      ? a.impressions - b.impressions
      : b.impressions - a.impressions
  );

  _sorted.forEach((s) => {
    if (s.children) sortGroupedData({groupedData: s.children, sortBy});
  });

  return _sorted;
};
