import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {FetchedCampaignStreaming} from '@analytics/graphql-api';
import {CampaignRouteProps} from '../_routes';

export type CampaignStreamingRouteProps = CampaignRouteProps & {
  campaignStreaming: FetchedCampaignStreaming;
};

export const useCampaignStreamingRoutes = ({
  organization,
  campaign,
  campaignStreamingId,
}: Pick<CampaignRouteProps, 'organization' | 'campaign'> & {
  campaignStreamingId: string;
}) => {
  return useRoutes({
    baseUrl: createOrgPath(
      organization,
      `campaigns/${campaign.slug}/streaming/${campaignStreamingId}`
    ),
    basePath:
      '/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/streaming/:campaignStreamingId',
    routes: [{key: 'stream', path: '', exact: true}],
  });
};
