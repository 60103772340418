import {graphql} from '@apollo/client/react/hoc';
import MapDownloads from '@core/components/MapDownloads';
import {findRegion} from '@core/lib/countryRegionData';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import {AttributionCitiesTable} from '@analytics/components/attribution/AttributionLocationTable';
import CampaignDownloadRegionLocationsQuery from '@analytics/graphql-api/_old_queries/CampaignDownloadRegionLocationsQuery';

const CampaignLocationRegion = ({
  data: {loading, me},
  match: {
    url,
    params: {country, region},
  },
}) => {
  if (loading) {
    return <Loading centered />;
  }

  const {count, nodes, cities} = me.organization.campaign.downloads.locations;
  const regionMeta = findRegion({
    countryIso2Code: country,
    regionIso2Code: region,
  });

  return (
    <>
      <CardHeader
        css={`
          margin: 0 var(--spacing-6) var(--spacing-6);
        `}>
        {regionMeta && regionMeta.country ? (
          <Element rules={() => ({alignItems: 'center', display: 'flex'})}>
            <Element
              tag='img'
              src={regionMeta.country.flag}
              rules={() => ({
                height: 'auto',
                marginRight: '0.625rem',
                width: '1.5rem',
              })}
            />
            {regionMeta.name}, {regionMeta.country.countryName} Impressions
          </Element>
        ) : (
          <>Impressions</>
        )}
      </CardHeader>
      <Card rules={() => ({padding: 0, overflow: 'hidden', border: 0})}>
        <MapDownloads
          height={500}
          locations={nodes.map(({latitude, longitude, total}) => ({
            count: total,
            latitude,
            longitude,
          }))}
        />
      </Card>
      <Card rules={() => ({marginTop: '1.5rem'})}>
        <AttributionCitiesTable cities={cities} count={count} />
      </Card>
    </>
  );
};

export default graphql(CampaignDownloadRegionLocationsQuery, {
  options: ({
    organization,
    campaign: {id},
    match: {
      params: {country, region},
    },
  }) => {
    return {
      variables: {
        organizationId: organization.id,
        id,
        country,
        region,
      },
    };
  },
})(CampaignLocationRegion);
