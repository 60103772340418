import {gql} from '@apollo/client';
import DynamicApprovalFragment from './fragments/DynamicApprovalFragment';
import PodcastApprovalFragment from './fragments/PodcastApprovalFragment';
import StreamingApprovalFragment from './fragments/StreamingApprovalFragment';

export default gql`
  query getMeApprovals($state: String, $campaignId: ID) {
    me {
      id
      dynamicApprovals(state: $state, campaignId: $campaignId) {
        ...DynamicApproval
      }
      podcastApprovals(state: $state, campaignId: $campaignId) {
        ...PodcastApproval
      }
      streamingApprovals(state: $state, campaignId: $campaignId) {
        ...StreamingApproval
      }
    }
  }
  ${DynamicApprovalFragment}
  ${PodcastApprovalFragment}
  ${StreamingApprovalFragment}
`;
