import {gql} from '@apollo/client';

export default gql`
  mutation processCampaignEpisodes($input: ProcessCampaignEpisodesInput!) {
    authed {
      success
    }
    processCampaignEpisodes(input: $input) {
      success
    }
  }
`;
