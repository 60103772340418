import {useState} from 'react';
import {Element, TEXT_MUTED} from '@core/style';
import {Card} from '@core/ui/Content';
import {Input} from '@core/ui/Form';

const getSnippets = (query, words) => {
  const snippets = [];

  if (!query) {
    return [];
  }

  for (let i = 0; i < words.length; i++) {
    if (words[i].word.startsWith(query.toLowerCase())) {
      const start = i - 10 < 0 ? 0 : i - 10;
      const end = start + 20 > words.length ? words.length : start + 20;

      const snippetWords = [];

      for (let n = start; n < end; n++) {
        snippetWords.push({
          word: words[n].punctuatedWord,
          bold: i == n,
        });
      }

      // Add a snippet of the text.
      snippets.push({
        start: words[i].start,
        words: snippetWords,
      });

      if (snippets.length > 10) {
        break;
      }
    }
  }
  return snippets;
};

const AudioTranscript = ({asr, setCurrentTime}) => {
  if (!asr.transcript) {
    return '';
  }

  const {words} = asr.transcript;

  const [query, setQuery] = useState('');
  const snippets = getSnippets(query, words);

  return (
    <Card rules={() => ({margin: '2rem 0'})}>
      <Input
        placeholder='Search Transcript'
        value={query}
        onChange={({target: {value}}) => setQuery(value)}
      />
      <>
        {snippets.map(({start, words}, i) => {
          return (
            <Element
              onClick={() => setCurrentTime(start)}
              key={i}
              rules={() => ({
                padding: '1rem 1rem',
                marginBottom: '1rem',
                borderBottom: '1px solid #ddd',
                cursor: 'pointer',
                ':hover': {
                  background: '#efefef',
                },
              })}>
              {words.map(({word, bold}, n) => {
                return (
                  <Element
                    tag='span'
                    key={n}
                    style-bold={bold}
                    rules={({bold}) => ({
                      fontWeight: bold ? 500 : 'inherit',
                      color: bold ? 'inherit' : TEXT_MUTED,
                    })}>
                    {word}{' '}
                  </Element>
                );
              })}
            </Element>
          );
        })}
      </>
    </Card>
  );
};

export default AudioTranscript;
