import {FC, useMemo} from 'react';
import dayjs from '@core/lib/dayjs';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {FetchedCampaign} from '@analytics/graphql-api';
import ApprovalsStateFormCell from './ApprovalsStateFormCell';
import {DetailCell} from './helpers';

type ApprovalsStreamingTableProps = {
  campaign: FetchedCampaign;
  onUpdateState: (_obj: any) => void;
  streamingApprovals: any[];
};

const ApprovalsStreamingTable: FC<ApprovalsStreamingTableProps> = ({
  campaign,
  onUpdateState,
  streamingApprovals,
}): JSX.Element => {
  const data = useMemo(
    () =>
      streamingApprovals.map((item) => {
        const campaignStreaming = campaign.campaignStreamings.find(
          ({id}) => id === item.campaignStreaming.id
        );

        return {
          ...item,
          lineItem: campaignStreaming?.name,
          campaignStreaming,
        };
      }),
    [streamingApprovals, campaign]
  );

  const columns: DataTableColumn[] = useMemo(
    () => [
      {
        title: 'Line Item',
        accessor: 'lineItem',
        type: 'string',
        rule: () => ({width: '30%'}),
      },
      {
        title: 'Detail',
        accessor: 'detail',
        noSortable: true,
        Cell: ({d}) => <DetailCell d={d.campaignStreaming} />,
      },
      {
        title: 'Updated',
        accessor: 'updatedAt',
        fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
      },
      {
        title: 'State',
        accessor: 'state',
        type: 'string',
        noSortable: true,
        rule: () => ({width: '25rem'}),
        Cell: ({d}) => (
          <ApprovalsStateFormCell data={d} onUpdate={onUpdateState} />
        ),
      },
    ],
    [onUpdateState]
  );

  return (
    <DataTable
      emptyMessageText='No approvals have been requested.'
      columns={columns}
      data={data}
      rules={() => ({'& tbody': {verticalAlign: 'top'}})}
    />
  );
};

export default ApprovalsStreamingTable;
