import {gql} from '@apollo/client';

export interface IOrganizationOverviewBrand {
  me: {
    id: string;
    organization: {
      id: string;
      overview: {
        brand: {
          count: number;
          nodes: Array<{
            attributedDownloads: number;
            attributedDownloadsModeled: number;
            attributedReach: number;
            attributedReachModeled: number;
            campaignDynamicId: string;
            campaignId: string;
            campaignKind: string;
            campaignName: string;
            code: string;
            episodeId: string;
            episodeTitle: string;
            feedId: string;
            households: number;
            householdsModeled: number;
            impressions: number;
            installed: number;
            installedModeled: number;
            leads: number;
            leadsModeled: number;
            lineItemName: string;
            podcastTitle: string;
            published: string;
            publisherName: string;
            purchases: number;
            purchasesModeled: number;
            reach: number;
            spend: number;
            time: string;
            value: number;
            valueModeled: number;
            views: number;
            viewsModeled: number;
          }>;
        };
      };
    };
  };
}

export interface IOrganizationOverviewBrandInput {
  after: string;
  before: string;
  /**
   * organizationId
   */
  id: string;
  interval: 'day' | 'week';
  spendByImpressions: boolean;
}

export default gql`
  query OrganizationOverviewBrandQuery(
    $after: Date!
    $before: Date!
    $id: ID!
    $interval: String
    $spendByImpressions: Boolean
  ) {
    me {
      id
      organization(id: $id) {
        id
        overview {
          brand(
            after: $after
            before: $before
            interval: $interval
            spendByImpressions: $spendByImpressions
          ) {
            count
            nodes {
              attributedDownloads
              attributedDownloadsModeled
              attributedReach
              attributedReachModeled
              campaignDynamicId
              campaignId
              campaignKind
              campaignName
              code
              episodeId
              episodeTitle
              feedId
              households
              householdsModeled
              impressions
              installed
              installedModeled
              leads
              leadsModeled
              lineItemName
              podcastTitle
              published
              publisherName
              purchases
              purchasesModeled
              reach
              spend
              time
              value
              valueModeled
              views
              viewsModeled
            }
          }
        }
      }
    }
  }
`;
