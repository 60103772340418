import {RouteType} from '@core/lib/useRoutes';

export const podlinkRoutes = (): RouteType[] => {
  return [
    {key: 'podlink', path: '', exact: true},
    {key: 'create', exact: true},
    {key: 'podcast-designer', path: 'podcasts/:podcastId', exact: true},
    {key: 'domain', path: ':podlinkDomainSlug'},
  ];
};

export const podlinkDomainRoutes = (): RouteType[] => {
  return [
    {key: 'domain', path: '', exact: true},
    {key: 'update', exact: true},
    {key: 'design', exact: true},
    {key: 'podcast-designer', path: 'podcasts/:podcastId', exact: true},
  ];
};
