import {FC, useState} from 'react';
import {copyToClipboard} from '@core/lib/utils';
import {TRules, css} from '@core/style';
import {IconButton} from '../Button/LegacyButton';
import {Input} from '../FormElements';
import {Icon} from '../Icon';

export interface IInputCopyProps {
  text: string;
  rules?: TRules;
  onCopy?: () => void;
}

const InputCopy: FC<IInputCopyProps> = ({text, rules, onCopy}) => {
  const [copied, setCopied] = useState<boolean>(false);

  const onClickCopy = () => {
    if (!copied) {
      copyToClipboard(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
      if (onCopy) {
        onCopy();
      }
    }
  };

  return (
    <div {...css([() => ({position: 'relative'}), rules])}>
      <Input
        type='text'
        value={text}
        readOnly
        onFocus={(evt) => {
          evt.target.select();
        }}
        rules={() => ({
          width: '100%',
          paddingRight: '3rem',
        })}
        small
      />
      <IconButton
        rules={() => ({
          bottom: 0,
          cursor: copied ? 'default' : 'pointer',
          margin: 'auto',
          pointerEvents: copied ? 'none ' : 'initial',
          position: 'absolute',
          right: '0.625rem',
          top: 0,
        })}
        onClick={onClickCopy}>
        {copied ? (
          <Icon
            icon='checkbox-marked-circle'
            rules={() => ({color: 'var(--green)'})}
          />
        ) : (
          <Icon icon='copy' />
        )}
      </IconButton>
    </div>
  );
};

export default InputCopy;
