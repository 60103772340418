import useSettings from '@analytics/components/settings/useSettings';
import {toModeled} from '@analytics/lib/utils';

const useCampaignEpisodes = (campaignEpisodes, {user, campaign}) => {
  const [settings] = useSettings();

  const results = [];
  const isAdmin = user && user.isAdmin;

  for (const loopEpisode of campaignEpisodes) {
    if (!loopEpisode.episode) {
      continue;
    }

    const {
      id,
      episode,
      cost,
      costToDate,
      reachTotal,
      downloadTotal,
      downloads,
      targetDownloadTotal,
      verified,
    } = loopEpisode;

    const _downloadTotal =
      downloads && downloads.count ? downloads.count : downloadTotal;
    const _attributionTotal = toModeled(loopEpisode, 'user', settings.modelled);

    const _cost = settings.spendByImpressionsDelivered ? costToDate : cost;
    let cr;
    if (campaign.kind.toLowerCase() === 'marketing') {
      cr = reachTotal ? targetDownloadTotal / reachTotal : 0;
    } else {
      cr = reachTotal ? _attributionTotal / reachTotal : 0;
    }
    const cpm = _downloadTotal ? (_cost / _downloadTotal) * 1000 : 0;
    const frequency = reachTotal ? _downloadTotal / reachTotal : 0;
    const cpv = reachTotal && _attributionTotal ? _cost / _attributionTotal : 0;

    results.push({
      ...loopEpisode,
      id,
      title: episode.title || null,
      published: episode.published || null,
      episode,
      cost,
      costToDate: _cost,
      reachTotal,
      downloadTotal: _downloadTotal,
      attributionTotal: _attributionTotal,
      purchaseTotal: toModeled(loopEpisode, 'purchase', settings.modelled),
      valueTotal: toModeled(loopEpisode, 'value', settings.modelled),
      installedTotal: toModeled(loopEpisode, 'installed', settings.modelled),
      leadTotal: toModeled(loopEpisode, 'lead', settings.modelled),
      cr,
      cpm,
      frequency,
      cpv,
      verified: isAdmin ? verified : undefined,
    });
  }

  return results.sort((a, b) => {
    return b.downloadTotal - a.downloadTotal;
  });
};

export default useCampaignEpisodes;
