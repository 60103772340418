import {toFixed} from '@core/lib/filters';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {FeedImage} from '@core/ui/Image';
import {campaignTargetURL} from '@analytics/components/nav/CampaignTargetNav';
import useSettings from '@analytics/components/settings/useSettings';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
} from '@analytics/graphql-api';

export function CampaignTargetsTable({
  organization,
  campaign,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign: FetchedCampaign;
}) {
  const [settings] = useSettings();
  const {multiplier} = campaign;

  const data = (campaign.campaignTargets ?? [])
    .slice(0)
    .map(({id, feed, reachTotal, downloadTotal}) => {
      const _reachTotal = reachTotal ?? 0;
      const _downloadTotal = downloadTotal ?? 0;
      return {
        id,
        title: feed.title,
        url: campaignTargetURL(organization, campaign, {id}),
        feed,
        reachTotal: settings.modelled ? _reachTotal * multiplier : reachTotal,
        downloadTotal: settings.modelled
          ? _downloadTotal * multiplier
          : _downloadTotal,
      };
    })
    .sort((a, b) => {
      return b.downloadTotal - a.downloadTotal;
    });

  const columns = [
    {
      title: 'Podcast',
      accessor: 'title',
      type: 'string',
      Cell: ({data: {feed}}) => {
        return (
          <Element
            rules={() => ({
              alignItems: 'center',
              display: 'flex',
              minWidth: '15.625rem',
            })}>
            <FeedImage
              feed={feed}
              width={350}
              rules={() => ({
                height: 'auto',
                marginRight: '0.625rem',
                width: '4.375rem',
              })}
            />
            <Element
              rules={({theme}) => ({
                color: theme.textPrimary,
                flex: 1,
                fontWeight: 500,
              })}>
              {feed.title}
            </Element>
          </Element>
        );
      },
    },
    {
      title: settings.modelled ? 'Attributed Downloads' : 'Downloads',
      accessor: 'downloadTotal',
      type: 'number',
      fmt: toFixed,
    },
    {
      title: settings.modelled ? 'Attributed Reach' : 'Reach',
      accessor: 'reachTotal',
      type: 'number',
      fmt: toFixed,
    },
  ];

  return (
    <DataTable
      columns={columns}
      orderBy='-downloadTotal'
      data={data}
      onClickRowPath={({url}) => url}
    />
  );
}
