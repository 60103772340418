import {useMemo} from 'react';
import {Controller} from 'react-hook-form';
import {FormHelperText} from '@core/ui/Form New';
import {Label} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {
  ExportTypeColumn,
  useExportTypesColumnsQuery,
} from '@analytics/graphql-api';
import {FieldProps} from '../../helpers';
import {LoadingContainer} from '../../shared';
import {ColumnsInput} from './ColumnsInput';

type Props = FieldProps & {
  organizationId: string;
};

export function ColumnsField({control, organizationId, watch}: Props) {
  const typeId = watch('typeId');

  const {
    data,
    loading,
    error: fetchError,
  } = useExportTypesColumnsQuery({
    variables: {
      organizationId,
      typeId,
    },
  });

  const columns = useMemo(
    () =>
      (data?.me?.organization?.exports?.exportTypes?.columns ?? []).filter(
        (c) => !!c
      ) as ExportTypeColumn[],
    [data]
  );

  return (
    <Stack direction='column' spacing='var(--spacing-3)'>
      <div>
        <Label htmlFor='fields' rules={() => ({marginBottom: 0})}>
          Columns
        </Label>
        <FormHelperText>
          Select the fields you would like to include within the report.
        </FormHelperText>
      </div>
      <Controller
        name='fields'
        control={control}
        rules={{
          validate: (v) => v?.length > 0 || 'Please select report columns',
        }}
        render={({field, fieldState: {error}}) =>
          loading || !!fetchError ? (
            <LoadingContainer
              css={{height: '15rem'}}
              fetchError={!!fetchError}
              formError={!!error}
              loading={loading}
            />
          ) : (
            <ColumnsInput
              columns={columns}
              defaultValue={field.value}
              invalid={!!error}
              onChange={field.onChange}
            />
          )
        }
      />
    </Stack>
  );
}
