import MultipleDataSelector from '@core/ui/MultipleDataSelector';
import {GetAdvisorQuizBrandsDocument} from '@analytics/graphql-api';
import {DropdownItem, Question, _QuestionHeader} from '../../shared';
import {getAdvisorColumnData} from '../helpers';
import {IQuestionProps} from '../types';

export const QuestionCompetingBrands = ({
  updateQuizData,
  organization,
}: IQuestionProps) => (
  <Question>
    <_QuestionHeader>
      Who are your competing brands in podcasting?
    </_QuestionHeader>
    <MultipleDataSelector
      onChange={(items) => updateQuizData('brands', items)}
      dataQuery={GetAdvisorQuizBrandsDocument}
      renderItem={(item) => <DropdownItem label={item.title} />}
      placeholder='Search brands'
      queryVariables={{orgId: organization.id}}
      propertyForName='title'
      fromResponse={getAdvisorColumnData('brands')}
    />
  </Question>
);
