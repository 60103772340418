import {graphql} from '@apollo/client/react/hoc';
import {comma, financial, toFixed, toPercent} from '@core/lib/filters';
import {t} from '@core/texts';
import {Card, CardHeader, Grid} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import {CardStat} from '@core/ui/Stat';
import {CampaignAttributionPurchaseValueByDayChart} from '@analytics/components/attribution/AttributionPurchasesChart';
import CampaignDynamicsTable from '@analytics/components/campaigns/CampaignDynamicsTable';
import CampaignPodcastsTable from '@analytics/components/campaigns/CampaignPodcastsTable';
import {CampaignStreamingsTable} from '@analytics/components/campaigns/CampaignStreamingsTable';
import useSettings from '@analytics/components/settings/useSettings';
import CampaignRevenueQuery from '@analytics/graphql-api/_old_queries/CampaignRevenueQuery';
import {impressionsForPlacements} from '@analytics/lib/placements';

const CampaignRevenue = ({
  organization,
  campaign,
  after,
  before,
  data: {loading, me},
}) => {
  if (loading) {
    return (
      <Loading
        centered
        css={`
          margin-top: 2rem;
        `}
      />
    );
  }
  const {cost, goal} = campaign;
  const [settings] = useSettings();

  const frequency = campaign.reachTotal
    ? campaign.downloadTotal / campaign.reachTotal
    : 1;

  const cp = cost / goal;

  // Yeah.
  const sum = me?.organization?.campaign?.attribution?.users?.sum ?? 0;
  let downloadTotal = me?.organization?.campaign?.downloads?.count ?? 0;
  if (settings.impressionsByPlacements) {
    downloadTotal = impressionsForPlacements({
      value: downloadTotal,
      campaign: campaign,
    });
  }
  const {campaignPodcasts, campaignDynamics, campaignStreamings} =
    me.organization.campaign;
  // const spend = (downloadTotal / goal) * cost

  // We need to figure out the cost based on line items.
  let spend = 0;

  // Figure out the cost to date here based on the impressions.
  // Limit line item spend to max of cost vs cost to date by impressions
  spend += campaignPodcasts.reduce((sum, cp) => {
    if (cp.goal && cp.cost) {
      const cpSpend = (cp.downloads.count / cp.goal) * cp.cost;
      return sum + (cpSpend > cp.cost ? cp.cost : cpSpend);
    }

    return sum;
  }, 0);

  spend += campaignDynamics.reduce((sum, cd) => {
    if (cd.goal && cd.cost) {
      const cdSpend = (cd.downloads.count / cd.goal) * cd.cost;
      return sum + (cdSpend > cd.cost ? cd.cost : cdSpend);
      // return sum + (cd.downloads.count / cd.goal) * cd.cost
    }

    return sum;
  }, 0);

  spend += campaignStreamings.reduce((sum, cs) => {
    if (cs.goal && cs.cost) {
      const csSpend = (cs.downloads.count / cs.goal) * cs.cost;
      return sum + (csSpend > cs.cost ? cs.cost : csSpend);
    }

    return sum;
  }, 0);

  // Limit spend to the camapign cost
  spend = Math.min(campaign.cost, spend);

  const reachTotal = downloadTotal / frequency;
  const costToDate = cp * downloadTotal;

  // modeled behavior.
  const modeledValueTotal = sum.valueModeled;
  const modeledPurchaseTotal = sum.purchasesModeled;
  const modeledAttributionTotal = sum.usersModeled;

  const modeledCR = reachTotal ? modeledAttributionTotal / reachTotal : 0;
  const modeledROAS = costToDate ? modeledValueTotal / costToDate : 0;
  const modeledCAC = modeledPurchaseTotal
    ? costToDate / modeledPurchaseTotal
    : 0;

  // standard
  const standardValueTotal = sum.value;
  const standardPurchaseTotal = sum.purchases;
  const standardAttributionTotal = sum.users;

  const standardCR = reachTotal ? standardAttributionTotal / reachTotal : 0;
  const standardROAS = costToDate ? standardValueTotal / costToDate : 0;
  const standardCAC = standardPurchaseTotal
    ? costToDate / standardPurchaseTotal
    : 0;

  const activeCampaignPodcasts = campaignPodcasts.filter(
    (cp) => cp.downloadTotal
  );

  return (
    <>
      {settings.modelled ? (
        <>
          <Grid
            cols={4}
            rules={() => ({
              gridGap: 'var(--spacing-5)',
              margin: '0 var(--spacing-5) ',
            })}>
            <CardStat
              value={comma(modeledAttributionTotal)}
              {...t('stats.visitors')}
            />
            <CardStat value={toPercent(modeledCR)} {...t('stats.cr')} />
            <CardStat
              value={toFixed(modeledPurchaseTotal)}
              title='Attributed Purchases'
              {...t('stats.purchases')}
            />
            <CardStat
              value={financial(modeledValueTotal)}
              {...t('stats.revenue')}
            />
          </Grid>
          <Grid
            cols={4}
            rules={() => ({
              gridGap: 'var(--spacing-5)',
              margin: 'var(--spacing-5) var(--spacing-5) 0',
            })}>
            <CardStat
              value={financial(spend)}
              {...t('stats.spend', {spendByImpressionsDelivered: true})}
            />
            <CardStat
              value={comma(downloadTotal)}
              {...t('stats.impressions')}
            />
            <CardStat value={toPercent(modeledROAS)} {...t('stats.roas')} />
            <CardStat value={financial(modeledCAC)} {...t('stats.cac')} />
          </Grid>
        </>
      ) : (
        <>
          <Grid
            cols={4}
            rules={() => ({
              gridGap: '1.5rem',
              margin: '0 1.5rem ',
            })}>
            <CardStat value={comma(standardAttributionTotal)} title='Events' />
            <CardStat value={toPercent(standardCR)} {...t('stats.cr')} />
            <CardStat
              value={comma(standardPurchaseTotal)}
              {...t('stats.purchases')}
            />
            <CardStat
              value={financial(standardValueTotal)}
              {...t('stats.revenue')}
            />
          </Grid>
          <Grid
            cols={4}
            rules={() => ({
              gridGap: '1.5rem',
              margin: '1.5rem 1.5rem 0',
            })}>
            <CardStat
              value={financial(spend)}
              {...t('stats.spend', {spendByImpressionsDelivered: true})}
            />
            <CardStat
              value={comma(downloadTotal)}
              {...t('stats.impressions')}
            />
            <CardStat value={toPercent(standardROAS)} {...t('stats.roas')} />
            <CardStat value={financial(standardCAC)} {...t('stats.cac')} />
          </Grid>
        </>
      )}

      <Card
        css={`
          margin-top: var(--spacing-8);
        `}>
        <CardHeader>Revenue by day</CardHeader>
        <CampaignAttributionPurchaseValueByDayChart
          organization={organization}
          campaign={campaign}
          multiplier={settings.modelled ? campaign.multiplier : 1}
          after={after}
          before={before}
        />
      </Card>

      {campaignDynamics.length > 0 && (
        <Card>
          <CardHeader>Revenue by dynamic line item</CardHeader>
          <CampaignDynamicsTable
            organization={organization}
            campaign={campaign}
            campaignDynamics={campaignDynamics}
          />
        </Card>
      )}

      {activeCampaignPodcasts.length > 0 && (
        <Card scrollable>
          <CardHeader>Revenue by podcast</CardHeader>
          <CampaignPodcastsTable
            organization={organization}
            campaign={campaign}
            campaignPodcasts={activeCampaignPodcasts}
          />
        </Card>
      )}

      {campaignStreamings.length > 0 && (
        <Card>
          <CardHeader>Revenue by streaming line item</CardHeader>
          <CampaignStreamingsTable
            organization={organization}
            campaign={campaign}
          />
        </Card>
      )}
    </>
  );
};

export default graphql(CampaignRevenueQuery, {
  options: ({organization, campaign: {id}, after, before}) => {
    return {
      variables: {
        organizationId: organization.id,
        id,
        after: after.format('YYYY-MM-DD'),
        before: before.format('YYYY-MM-DD'),
      },
    };
  },
})(CampaignRevenue);
