import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedAdvisorShow,
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {AdvisorRouteProps} from '../_routes';

export type AdvisorPodcastRouteProps = AdvisorRouteProps & {
  podcast: FetchedAdvisorShow;
};

export const generateAdvisorPodcastRoutes = ({
  organization,
  podcastId,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  podcastId: string;
}) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, `/advisor/podcast/${podcastId}`),
    basePath: '/:part(b|a|s|p)/:slug/advisor/podcast/:podcastId',
    routes: [
      {
        key: 'overview',
        title: 'Overview',
        path: '',
        exact: true,
      },
      {
        title: 'Audience',
        key: 'audience',
        exact: true,
      },
      {
        key: 'brands',
        title: 'Brands',
        exact: true,
      },
      {
        key: 'industries',
        title: 'Industries',
        exact: true,
      },
      {
        key: 'episodes',
        title: 'Episodes',
        exact: true,
      },
    ],
  });
};
