import {Card, PageTitle} from '@core/ui/Content';
import {Icon} from '@core/ui/Icon';
import {Stack} from '@core/ui/Layout';
import {Link} from '@core/ui/Link';
import {Text} from '@core/ui/Text';
import AudiencePodcastsTable from '@analytics/components/audience-intel/AudiencePodcastsTable';
import {PodcastsRouteProps} from '.';

export default function PodcastsAudience({organization}: PodcastsRouteProps) {
  return (
    <>
      <PageTitle>Podcasts</PageTitle>
      <Card variant='filled'>
        <Stack direction='column' gap='var(--spacing-2)'>
          <Text color='muted' variant='body2'>
            The blue bars below indicate the percentage of your podcast's
            audience that makes up each segment. The indices for each variable
            are represented by overindexing variables in green and underindexing
            variables in dark gray relative to the 100 baseline shown in white
            for each audience variable at the Head of Household level when
            compared to the US general population.
          </Text>
          <Text color='muted' variant='body2'>
            {`An index of 100 indicates that the audience segment is in line with the
          US population. An index > 100 indicates that the audience segment is
          over-represented in your podcast compared to the US general population,
          and an index < 100 indicates that your podcast's audience is
          under-represented compared to the US general population.`}
          </Text>
          <Text color='muted' variant='body2'>
            Filter for top indexing audience segment variables below or click
            into each podcast to see all audience segments.
          </Text>
          <Text color='muted' variant='body2'>
            <Icon
              icon='info-small'
              rules={() => ({height: '10px', marginRight: '0.275rem'})}
            />
            <i>
              This feature is currently in beta. Please reach out to our support
              team at{' '}
              <Link href='mailto:support-adanalytics@spotify.com'>
                support-adanalytics@spotify.com
              </Link>{' '}
              with any questions.
            </i>
          </Text>
        </Stack>
      </Card>
      <Card css={{marginTop: '2rem', position: 'relative'}}>
        <AudiencePodcastsTable organization={organization} />
      </Card>
    </>
  );
}
