import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import {format} from 'd3-format';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from '@core/lib/dayjs';
import {comma, toPercent} from '@core/lib/filters';
import {createOrgPath} from '@core/lib/organizations';
import {getColor} from '@core/style';
import Badge from '@core/ui/Badge';
import {DataTableColumn} from '@core/ui/DataTable';
import DataTable from '@core/ui/DataTable';
import {DateRangeInput} from '@core/ui/DatePicker';
import EmptyMessage from '@core/ui/EmptyMessage';
import {FeedImage} from '@core/ui/Image';
import Loading from '@core/ui/Loading';
import Select from '@core/ui/Select';
import {FetchedCampaign, FetchedOrganization} from '@analytics/graphql-api';
import ORGANIZATION_CAMPAIGN_EPISODES_QUERY, {
  ICampaignEpisode,
  IOrganizationCampaignEpisodes,
  IOrganizationCampaignEpisodesInput,
} from '@analytics/graphql-api/_old_queries/OrganizationCampaignEpisodesQuery';
import useUpcomingEpisodesChartData from './useUpcomingEpisodesChartData';

const formatDate = (date: dayjs.Dayjs) => date.utc().format('YYYY-MM-DD');

const INTERVALS = [
  {title: 'Daily', value: 'daily'},
  {title: 'Weekly', value: 'weekly'},
];

const COLUMNS: DataTableColumn[] = [
  {
    title: 'Podcast',
    accessor: 'title',
    type: 'string',
    Cell: ({data: {feed}}) => (
      <div css={{alignItems: 'center', display: 'flex', minWidth: '12.5rem'}}>
        <FeedImage
          feed={feed}
          rules={() => ({
            height: '4.375rem',
            marginRight: '0.625rem',
            paddingBottom: 0,
            width: '4.375rem',
          })}
        />
        <div css={{flex: 1, fontWeight: 500}}>{feed.title}</div>
      </div>
    ),
  },
  {
    title: 'Expected Published',
    accessor: 'start',
    type: 'date',
    fmt: (v: string) => dayjs(v).utc().format('MMM DD, YYYY'),
  },
  {
    title: 'Download Goal',
    accessor: 'goal',
    type: 'number',
    fmt: comma,
  },
];

const UpcomingEpisodesChartTooltip = ({
  payload,
  table,
}: {
  payload?: any;
  table: any;
}): JSX.Element => {
  if (!payload || payload.length < 1) {
    return null;
  }

  const downloads: {
    [key: string]: number;
  } = payload[0].payload;

  const podcastDownloads: {
    downloads: number;
    feed: TSFixMe;
    key: string;
  }[] = [];

  let total = 0;
  for (const key in downloads) {
    if (key !== 'x') {
      total += downloads[key];
      const {feed} = table[key];
      podcastDownloads.push({
        downloads: downloads[key],
        feed,
        key: key,
      });
    }
  }

  podcastDownloads.sort((a, b) => b.downloads - a.downloads).splice(10);

  return (
    <div
      css={{
        background: 'var(--bg-overlay)',
        border: '1px solid var(--border-default)',
        borderRadius: '.5rem',
        boxShadow: 'var(--shadow-xl)',
        color: 'var(--text-muted)',
        // theme.name === 'light' ? theme.textTertiary : theme.textPrimary,
        fontSize: '0.875rem',
        height: 'fit-content',
        lineHeight: 1.4,
        minWidth: '10rem',
        padding: '0.625rem',
        width: 'fit-content',
      }}>
      <div>{dayjs(downloads.x).utc().format('MMMM DD, YYYY')}</div>
      <div
        css={{
          display: 'flex',
          fontWeight: 500,
          justifyContent: 'space-between',
          marginTop: '0.125rem',
        }}>
        Estimated downloads
        <div>{format('.4s')(total)}</div>
      </div>
      <div
        css={{
          background: 'var(--bg-muted)',
          height: '0.0625rem',
          margin: '0.375rem 0 0.625rem',
        }}
      />
      {podcastDownloads.map((podcast, idx) => {
        const color = getColor(podcast.key);
        const percentage = toPercent(podcast.downloads / total, 0);

        if (!parseInt(percentage.replace('%', ''))) {
          return null;
        }
        return (
          <div
            key={idx}
            css={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom:
                idx === podcastDownloads.length - 1 ? 0 : '0.375rem',
            }}>
            <div
              css={{
                alignItems: 'center',
                display: 'flex',
                maxWidth: '17.5rem',
                width: '17.5rem',
              }}>
              <FeedImage
                feed={podcast.feed}
                width={48}
                rules={() => ({
                  borderRadius: '0.25rem',
                  height: 'auto',
                  marginRight: '0.5rem',
                  width: '2.625rem',
                })}
              />
              {podcast.feed.title}
            </div>
            <Badge
              customColors={{
                background: color,
                borderColor: color,
                color: '#fff',
              }}
              css={`
                font-size: 0.8125rem;
                letter-spacing: 0;
              `}>
              {percentage}
            </Badge>
          </div>
        );
      })}
    </div>
  );
};

const UpcomingEpisodesChartBase = ({
  endDate,
  height,
  interval,
  organization,
  upcomingEpisodes,
  withLegend = true,
}: {
  endDate: dayjs.Dayjs;
  height: number;
  interval: string;
  organization: FetchedOrganization;
  upcomingEpisodes: ICampaignEpisode[];
  withLegend?: boolean;
}) => {
  const {chart, table} = useUpcomingEpisodesChartData(
    upcomingEpisodes,
    endDate,
    interval
  );

  const getChartBars = (length: number): React.ReactNode[] => {
    return Array(length)
      .fill('x')
      .map((_, i) => (
        <Bar key={i} dataKey={i} fill={getColor(i)} stackId='a' />
      ));
  };

  return (
    <>
      <div css={{color: 'var(--text-muted)', fontSize: '0.75rem'}}>
        <ResponsiveContainer width='100%' height={height}>
          <BarChart
            data={chart}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}>
            <CartesianGrid
              stroke='var(--border-default)'
              strokeDasharray='0 0'
              vertical={false}
            />
            <XAxis
              type='category'
              dataKey='x'
              tick={{fill: 'var(--text-muted)'}}
              tickSize={10}
              tickLine={false}
              tickFormatter={(timeStr) =>
                dayjs(timeStr).utc().format('D MMM').toUpperCase()
              }
              axisLine={false}
            />
            <YAxis
              type='number'
              tick={{fill: 'var(--text-muted)'}}
              tickSize={10}
              axisLine={false}
              tickLine={false}
              tickFormatter={(val) => format('.3s')(val)}
            />
            <Tooltip
              cursor={false}
              content={<UpcomingEpisodesChartTooltip table={table} />}
              wrapperStyle={{zIndex: 1000}}
            />
            {getChartBars(table.length)}
          </BarChart>
        </ResponsiveContainer>
      </div>
      {withLegend && (
        <div css={{margin: '1.75rem 1.125rem 1.25rem'}}>
          <DataTable
            columns={COLUMNS}
            data={table}
            emptyMessageText='No podcasts found.'
            orderBy='start'
            paginationRowsPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 50]}
            propertyForKey='feId'
            searchKeys={['title']}
            searchPlaceholder='Search podcast'
            onClickRowPath={({id, slug}) =>
              createOrgPath(organization, `/campaigns/${slug}/podcasts/${id}`)
            }
          />
        </div>
      )}
    </>
  );
};

interface IUpcomingEpisodesChartProps {
  organization: FetchedOrganization;
  campaign?: FetchedCampaign;
  height?: number;
}

const UpcomingEpisodesChart = ({
  organization,
  campaign,
  height = 300,
}: IUpcomingEpisodesChartProps) => {
  const today = dayjs().startOf('d');
  const [interval, setInterval] = useState<string>(INTERVALS[0].value);
  const [dateRange, setDateRange] = useState<dayjs.Dayjs[]>([
    today,
    today.add(1, 'M'),
  ]);
  const {loading, error, data} = useQuery<
    IOrganizationCampaignEpisodes,
    IOrganizationCampaignEpisodesInput
  >(ORGANIZATION_CAMPAIGN_EPISODES_QUERY, {
    variables: {
      organizationId: organization.id,
      after: formatDate(dateRange[0]),
      before: formatDate(dateRange[1]),
    },
  });

  const isOutsideRange = (date: dayjs.Dayjs) =>
    date.isBefore(today) || date.isAfter(today.add(6, 'M'));

  const onDatesChange = ({
    startDate,
    endDate,
  }: {
    [key: string]: dayjs.Dayjs;
  }) => {
    setDateRange([startDate, endDate]);
  };

  let upcomingEpisodes = data?.me.organization.campaignEpisodes;

  if (campaign && upcomingEpisodes) {
    upcomingEpisodes = upcomingEpisodes.filter(
      ({
        campaignPodcast: {
          campaign: {id},
        },
      }) => id == campaign.id
    );
  }

  return (
    <>
      <div
        css={{alignItems: 'center', display: 'flex', marginBottom: '1.75rem'}}>
        <DateRangeInput
          endDate={dateRange[1]}
          isOutsideRange={isOutsideRange}
          onDatesChange={onDatesChange}
          startDate={dateRange[0]}
        />
        <Select
          defaultValue={interval}
          onSelect={({value}) => setInterval(value)}
          items={INTERVALS}
          rules={({theme}) => ({
            color: theme.textTertiary,
            marginLeft: '0.625rem',
          })}
          small
        />
      </div>
      {loading ? (
        <Loading centered />
      ) : error || !upcomingEpisodes?.length ? (
        <EmptyMessage title='No Upcoming Episodes found'>
          Ad Analytics does not have upcoming episode information for the
          selected date range.
        </EmptyMessage>
      ) : (
        <UpcomingEpisodesChartBase
          endDate={dateRange[1]}
          height={height}
          interval={interval}
          organization={organization}
          upcomingEpisodes={upcomingEpisodes}
        />
      )}
    </>
  );
};

export default UpcomingEpisodesChart;
