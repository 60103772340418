import {Redirect, Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import dayjs from '@core/lib/dayjs';
import {CampaignRouteProps, toCampaignUrl} from '../_routes';
import RangeIndex from './range';

const CampaignRevenue = ({organization, campaign}: CampaignRouteProps) => {
  const hardStart = dayjs(campaign.startAt).utc();
  const hardEnd = dayjs(campaign.endAt).isAfter(dayjs())
    ? dayjs()
    : dayjs(campaign.endAt);

  return (
    <Redirect
      to={`${toCampaignUrl(organization, campaign)}/revenue/${hardStart.format(
        'YYYY-MM-DD'
      )}/${hardEnd.format('YYYY-MM-DD')}`}
    />
  );
};

export function CampaignRevenueIndex({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/campaigns/:campaignSlug/revenue'
        user={user}
        organization={organization}
        campaign={campaign}
        component={CampaignRevenue}
      />
      <PropsRoute
        path='/:part(a|s|b|p)/:slug/campaigns/:campaignSlug/revenue/:start/:end'
        user={user}
        organization={organization}
        campaign={campaign}
        component={RangeIndex}
      />
    </Switch>
  );
}
