import {comma, financial, toFixed, toPercent} from '@core/lib/filters';
import {t} from '@core/texts';
import {Card, CardHeader} from '@core/ui/Content';
import {CardStat} from '@core/ui/Stat';
import AttributionReferralDomains from '@analytics/components/attribution/AttributionReferralDomains';
import {AttributionUrls} from '@analytics/components/attribution/AttributionUrls';
import {CampaignOmniChart} from '@analytics/components/campaigns/charts';
import {campaignPixelURL} from '@analytics/components/nav/CampaignPixelNav';
import useSettings from '@analytics/components/settings/useSettings';
import {toModeled} from '@analytics/lib/utils';
import {CampaignPixelRouteProps} from './_routes';

export function PixelOverview({
  organization,
  campaign,
  pixel,
}: CampaignPixelRouteProps) {
  const [settings] = useSettings();

  const costToDate = campaign.costToDate ?? 0;
  const reachTotal = campaign.reachTotal ?? 0;
  const pages = (settings.modelled ? campaign.attribution?.users?.sum?.pagesModeled : campaign.attribution?.users?.sum?.pages) ?? 0;

  const attributionTotal =
    (settings.modelled ? campaign.userTotalModeled : campaign.userTotal) ?? 0;

  const cr = reachTotal ? attributionTotal / reachTotal : 0;
  const cpv = attributionTotal ? costToDate / attributionTotal : 0;
  const purchaseTotal = toModeled(campaign, 'purchase', settings.modelled);
  const url = campaignPixelURL(organization, campaign, pixel);

  return (
    <>
      <div
        css={{
          display: 'grid',
          gap: '1rem',
          gridTemplateColumns: `repeat(${purchaseTotal ? 5 : 4}, 1fr)`,
          margin: '0 1.5rem 1rem',
        }}>
        <CardStat
          value={attributionTotal}
          displayValue={comma(attributionTotal)}
          {...t('stats.visitors')}
        />
        <CardStat value={comma(pages)} {...t('stats.pageViews')} />
        {!!purchaseTotal && (
          <CardStat value={toFixed(purchaseTotal)} {...t('stats.purchases')} />
        )}
        <CardStat value={toPercent(cr)} {...t('stats.cr')} />
        <CardStat value={financial(cpv)} {...t('stats.cpv')} />
      </div>
      <Card>
        <CampaignOmniChart organization={organization} campaign={campaign} />
      </Card>
      <Card>
        <CardHeader cta={{title: 'All URLs ⇾', path: `${url}/urls`}}>
          Top Landing Pages
        </CardHeader>
        <AttributionUrls
          organization={organization}
          campaign={campaign}
          pixel={pixel}
          limit={10}
        />
      </Card>
      <Card>
        <CardHeader cta={{title: 'All Referrers ⇾', path: `${url}/referrers`}}>
          Top Referrers
        </CardHeader>
        <AttributionReferralDomains
          organization={organization}
          campaign={campaign}
          pixel={pixel}
          limit={10}
        />
      </Card>
    </>
  );
}
