import {forwardRef} from 'react';
import {useQuery} from '@apollo/client';
import dayjs from '@core/lib/dayjs';
import {toFormattedTimeString} from '@core/lib/time';
import {stripHTML, truncateWords} from '@core/lib/words';
import {Element} from '@core/style';
import {getColor} from '@core/style';
import DataTable, {DataTableColumn, SelectFilter} from '@core/ui/DataTable';
import EmptyMessage from '@core/ui/EmptyMessage';
import {Img} from '@core/ui/Image';
import {LoadingSpinner} from '@core/ui/Loading';
import AudioBarWaveformPlayer from '@analytics/components/audio/AudioBarWaveformPlayer';
import {
  FetchedAdvisorShow,
  FetchedBuySideOrganization,
  FetchedOrganization,
  OrganizationObject,
} from '@analytics/graphql-api';
import ADVISOR_PODCAST_EPISODE_DETAIL_QUERY from '@analytics/graphql-api/_old_queries/advisor/AdvisorPodcastEpisodeDetailQuery';
import ADVISOR_PODCAST_EPISODES_QUERY from '@analytics/graphql-api/_old_queries/advisor/AdvisorPodcastEpisodesQuery';

const EpisodePlayerCell = ({
  organization,
  feed,
  id,
}: {
  organization: OrganizationObject;
  feed: TSFixMe;
  id: string;
}) => {
  const {data, loading, error} = useQuery(
    ADVISOR_PODCAST_EPISODE_DETAIL_QUERY,
    {
      variables: {
        organizationId: organization.id,
        feedId: feed.id,
        id: id,
      },
    }
  );

  if (loading)
    return (
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          margin: '1rem 0',
        })}>
        <LoadingSpinner />
      </Element>
    );

  if (error) return <EmptyMessage>Audio unavailable</EmptyMessage>;

  const playerPlacements = (
    data.me.organization.advisor.show.feed.episode.placements || []
  ).map((p, i) => ({
    ...p,
    fill: getColor(i),
  }));

  const {enclosure} = data.me.organization.advisor.show.feed.episode;

  return (
    <AudioBarWaveformPlayer
      enclosure={enclosure}
      placements={playerPlacements}
      withTimeStamp
      rules={() => ({margin: '1.5rem 0 0.5rem', height: '4rem'})}
    />
  );
};

const getColumns = (compact: boolean): DataTableColumn[] => [
  {
    accessor: 'title',
    title: 'Episode',
    type: 'string',
    noSortable: true,
    Cell: ({
      data: {
        id,
        title,
        description,
        image,
        organization,
        feed,
        published,
        duration,
      },
    }) => (
      <Element
        rules={() => ({
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem 0 0',
        })}>
        <Element
          rules={() => ({
            alignItems: 'center',
            display: 'flex',
          })}>
          {image && (
            <Img
              image={image}
              rules={() => ({
                height: '4rem',
                width: '4rem',
                marginRight: '0.625rem',
              })}
            />
          )}
          <Element
            rules={() => ({
              display: 'flex',
              flexDirection: 'column',
            })}>
            <Element
              rules={({theme}) => ({
                color: theme.textTertiary,
                fontSize: '0.825rem',
                marginBottom: '0.375rem',
              })}>
              {dayjs(published).utc().format('MMM D, YYYY')} •{' '}
              {toFormattedTimeString(duration)}
            </Element>
            <Element
              rules={() => ({
                fontSize: '1.125rem',
                fontWeight: 500,
              })}>
              {title}
            </Element>
          </Element>
        </Element>
        <Element
          rules={({theme}) => ({
            color: theme.textTertiary,
            fontSize: '0.875rem',
            marginTop: '0.475rem',
          })}>
          {stripHTML(truncateWords(description, compact ? 45 : 65))}
        </Element>
        <EpisodePlayerCell
          organization={organization}
          feed={feed}
          id={id}
          compact={compact}
        />
      </Element>
    ),
  },
  {
    accessor: 'published',
    title: 'Published',
    type: 'string',
    hidden: true,
  },
  {
    accessor: 'placementsCount',
    title: 'Ads Count',
    type: 'number',
    hidden: true,
  },
  {
    accessor: 'withAds',
    title: 'Ads',
    noSortable: true,
    hidden: true,
    Filter:
      !compact &&
      forwardRef(({...props}, ref) => (
        <SelectFilter
          ref={ref}
          items={[
            {title: 'With Ads', value: true},
            {title: 'Without Ads', value: false},
          ]}
          {...props}
        />
      )),
  },
];

const AdvisorPodcastEpisodesTable = ({
  organization,
  podcast,
  compact = false,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  podcast: FetchedAdvisorShow;
  compact?: boolean;
}) => {
  const {data, error, loading} = useQuery(ADVISOR_PODCAST_EPISODES_QUERY, {
    variables: {
      organizationId: organization.id,
      id: podcast?.feed.id,
      limit: compact ? 5 : null,
    },
  });

  if (loading)
    return (
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          padding: '1rem',
        })}>
        <LoadingSpinner />
      </Element>
    );

  if (error)
    return (
      <EmptyMessage rules={() => ({padding: '1rem'})}>
        No episodes found
      </EmptyMessage>
    );

  const {feed} = data.me.organization.advisor.show;

  const episodesData = feed.episodes.map((episode) => ({
    ...episode,
    feed,
    organization,
    duration: episode.enclosure.duration,
    withAds: episode.placementsCount > 0,
  }));

  return (
    <DataTable
      data={episodesData}
      columns={getColumns(compact)}
      emptyMessageText='No episodes found'
      orderBy='-published'
      searchKeys={compact ? [] : ['title']}
      searchPlaceholder={compact ? '' : 'Search episode'}
      withSortFilter={!compact}
      noHeader
    />
  );
};

export default AdvisorPodcastEpisodesTable;
