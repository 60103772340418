import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {OrganizationKind} from '@analytics/graphql-api';

export const advisorURL = (organization: {
  kind: OrganizationKind;
  slug: string;
}) => createOrgPath(organization, '/advisor');

export const advisorPodcastURL = (
  organization: {
    kind: OrganizationKind;
    slug: string;
  },
  feedId: string
) => createOrgPath(organization, `/advisor/podcast/${feedId}`);

export const advisorOnboardBrandURL = (organization: {
  kind: OrganizationKind;
  slug: string;
}) => createOrgPath(organization, '/advisor/onboard/brand');

export const advisorOnboardBrandConfirmationURL = (organization: {
  kind: OrganizationKind;
  slug: string;
}) => createOrgPath(organization, '/advisor/onboard/brand/confirm');

/**
 * Main /advisor routes.
 */
export const useAdvisorRoutes = ({
  organization,
}: {
  organization: {
    kind: OrganizationKind;
    slug: string;
  };
}) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, '/advisor'),
    basePath: '/:part(b|a|s|p)/:slug/advisor',
    routes: [
      {
        key: 'advisor',
        title: 'Advisor',
        path: '',
        exact: true,
      },
      {
        key: 'podcast',
        path: 'podcast/:feedId',
      },
      {
        title: '',
        key: 'onboard',
        path: 'onboard',
      },
    ],
  });
};

/**
 * /advisor/onboard routes.
 */
export const useAdvisorOnboardRoutes = ({
  organization,
}: {
  organization: {
    kind: OrganizationKind;
    slug: string;
  };
}) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, '/advisor/onboard'),
    basePath: '/:part(b|a|s|p)/:slug/advisor/onboard',
    routes: [
      {
        key: 'redirect',
        path: '',
        exact: true,
      },
      {
        key: 'publisher',
      },
      {
        key: 'brand',
      },
    ],
  });
};

/**
 * /advisor/onboard/publisher routes.
 */
export const useAdvisorOnboardPublisherRoutes = ({
  organization,
}: {
  organization: {
    kind: OrganizationKind;
    slug: string;
  };
}) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, '/advisor/onboard/publisher'),
    basePath: '/:part(b|a|s|p)/:slug/advisor/onboard/publisher',
    routes: [
      {
        key: 'index',
        path: '',
        exact: true,
      },
      {
        key: 'podcasts',
        exact: true,
      },
      {
        key: 'capabilities',
        exact: true,
      },
    ],
  });
};

/**
 * /advisor/onboard/brand routes.
 */
export const useAdvisorOnboardBrandRoutes = ({
  organization,
}: {
  organization: {
    kind: OrganizationKind;
    slug: string;
  };
}) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, '/advisor/onboard/brand'),
    basePath: '/:part(b|a|s|p)/:slug/advisor/onboard/brand',
    routes: [
      {
        key: 'index',
        path: '',
        exact: true,
      },
      {
        key: 'assessment',
        exact: true,
        title: 'Onboarding Assessment',
      },
      {
        key: 'review',
        exact: true,
        title: 'Confirm Advisor Onboarding Results',
      },
    ],
  });
};

/**
 * /advisor/podcast/:feedid routes.
 */
export const useAdvisorPodcastRoutes = ({
  organization,
  feedId,
}: {
  organization: {
    kind: OrganizationKind;
    slug: string;
  };
  feedId: string;
}) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, `/advisor/podcast${feedId}`),
    basePath: '/:part(b|a|s|p)/:slug/advisor/podcast/:feedId',
    routes: [
      {
        key: 'overview',
        title: 'Overview',
        path: '',
        exact: true,
      },
      {
        title: 'Audience',
        key: 'audience',
        exact: true,
      },
      {
        key: 'brands',
        title: 'Brands',
        exact: true,
      },
      {
        key: 'industries',
        title: 'Industries',
        exact: true,
      },
      {
        key: 'episodes',
        title: 'Episodes',
        exact: true,
      },
    ],
  });
};
