import {gql} from '@apollo/client';
import AttributionConfigFragment from '@analytics/graphql-api/_old_queries/fragments/AttributionConfigFragment';

export default gql`
  mutation updateAttributionConfig($input: UpdateAttributionConfigInput!) {
    authed {
      success
    }
    updateAttributionConfig(input: $input) {
      attributionConfig {
        ...AttributionConfig
      }
    }
  }
  ${AttributionConfigFragment}
`;
