import {useState} from 'react';
import dayjs from '@core/lib/dayjs';
import {IconButton} from '@core/ui/Button/LegacyButton';
import DataTable, {DataTableColumn, DataTableProps} from '@core/ui/DataTable';
import {Icon} from '@core/ui/Icon';
import {
  FetchedBuySideOrganization,
  FetchedMembersDomains,
  FetchedOrganization,
} from '@analytics/graphql-api';
import MembersDomainDeleteFormOverlay from './MembersDomainDeleteFormOverlay';

type Props = {
  domains: FetchedMembersDomains;
  organization: FetchedOrganization | FetchedBuySideOrganization;
} & Pick<DataTableProps, 'headerRenderer'>;

export const MembersDomainsTable = ({
  domains,
  organization,
  headerRenderer,
}: Props) => {
  const [deleteId, setDeleteId] = useState<string>('');
  const columns: DataTableColumn[] = [
    {
      accessor: 'domain',
      type: 'string',
    },
    {
      title: 'Created',
      accessor: 'createdAt',
      type: 'date',
      fmt: (v) => dayjs(v).format('MM/DD/YY'),
    },
    {
      title: '',
      Cell: ({data}) => (
        <div className='delete-btn'>
          <IconButton onClick={() => setDeleteId(data.id)}>
            <Icon icon='bin' />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <DataTable
        data={domains}
        columns={columns}
        orderBy='-createdAt'
        emptyMessageText='No domains found.'
        headerRenderer={headerRenderer}
        rowRuleFn={() => ({
          '& .delete-btn': {
            opacity: 0,
          },
          '&:hover .delete-btn': {
            opacity: 1,
          },
        })}
      />
      {deleteId && (
        <MembersDomainDeleteFormOverlay
          opened
          toggle={() => setDeleteId('')}
          domainId={deleteId}
          organization={organization}
        />
      )}
    </>
  );
};
