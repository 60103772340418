import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {PodcastRouteProps} from '..';
import NewFutureEpisode from './NewFutureEpisode';
import NewHome from './NewHome';

const NewIndex = ({user, organization}: PodcastRouteProps) => {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/podcasts/:podcastId/new'
        user={user}
        organization={organization}
        component={NewHome}
      />
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/podcasts/:podcastId/new/episode'
        user={user}
        organization={organization}
        component={NewFutureEpisode}
      />
    </Switch>
  );
};

export default NewIndex;
