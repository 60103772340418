import {useState} from 'react';
import {Box, ProgressCircle} from '@spotify-internal/encore-web';
import {useHistory, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {NotAuthorizedError} from '@core/components/AppError';
import PactsafeClickwrap from '@core/ui/PactsafeClickwrap';
import {
  useCreateTermsAndConditionsMutation,
  useOrganizationQuery,
  useUserInfoQuery,
} from '@analytics/graphql-api';
import SpotifyBanner from '../../components/onboard/SpotifyBanner';
import {ContentContainer, PageContainer} from './AccountLayout';
import ConfirmationDialog from './ConfirmationDialog';
import TermsOfServiceImage from './terms.svg';

const CheckboxContainer = styled.label`
  display: flex;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-self: flex-start;
`;

const BoldLink = styled.a`
  font-weight: bold;
  text-decoration: none;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  margin-left: 2px;
  margin-right: 2px;
  color: #000000;
`;

const BodyText = styled.p`
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 90%;
  text-align: center;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 8px;
`;

const AccountTerms = () => {
  const {slug} = useParams<{slug: string}>();
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [createTermsAndConditions] = useCreateTermsAndConditionsMutation();
  const navigation = useHistory();

  const {data: userData} = useUserInfoQuery();

  const {data, loading, error, refetch} = useOrganizationQuery({
    variables: {
      slug,
    },
  });

  const email = userData?.userInfo?.email;

  if (loading)
    return (
      <PageContainer>
        <SpotifyBanner advertising progress={90} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}>
          <ProgressCircle size='xlarge' />
        </div>
      </PageContainer>
    );

  if (error || !data?.me?.organization || !email)
    return (
      <NotAuthorizedError
        user={{
          email: userData?.userInfo?.email,
        }}
      />
    );

  const organization = data?.me?.organization;

  const handleAccept = async () => {
    try {
      await createTermsAndConditions({
        variables: {
          input: {
            organizationId: organization.id,
          },
        },
      });
      await refetch();
      navigation.push('/');
    } catch (err) {
      console.warn(err);
    }
  };

  const boxBody = (
    <BodyContainer>
      <BodyText>
        By checking the box below, you acknowledge that you understand and agree
        to the
        <BoldLink
          target='_blank'
          rel='noopener'
          href='https://www.spotify.com/us/legal/ad-analytics-terms'>
          Terms of Service
        </BoldLink>
        for Spotify Ad Analytics.
      </BodyText>
      <CheckboxContainer>
        <PactsafeClickwrap
          groupKey='group-hkdy3bvrf'
          onChecked={setHasAcceptedTerms}
          sendData={hasAcceptedTerms}
          signerId={email}
        />
      </CheckboxContainer>
    </BodyContainer>
  );

  const imageSrc = <img src={TermsOfServiceImage} alt='Terms' />;

  return (
    <PageContainer>
      <SpotifyBanner advertising progress={90} />
      <ContentContainer>
        <ConfirmationDialog
          imageSrc={imageSrc}
          boxTitle='Terms of Service'
          boxBody={boxBody}
          onContinue={handleAccept}
          wide
          ctaLabel={'Continue'}
          ctaDisabled={!hasAcceptedTerms}
        />
      </ContentContainer>
    </PageContainer>
  );
};

export default AccountTerms;
