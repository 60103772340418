import {Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {pixelEditRoutes} from '@analytics/lib/routes/pixelsRoutes';
import {ManagePixelsPixelRouteProps} from '../_routes';
import {PixelAddUrl} from './PixelAddUrl';
import {PixelDeleteUrl} from './PixelDeleteUrl';
import {PixelUpdateUrl} from './PixelUpdateUrl';
import {PixelUrlsOverview} from './PixelUrlsOverview';

export type ManagePixelsPixelUrlRouteProps = ManagePixelsPixelRouteProps & {
  backPath: string;
};

export function PixelUrlsIndex({
  organization,
  pixel,
}: ManagePixelsPixelRouteProps) {
  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, `manage/pixels/${pixel.key}`),
    basePath: '/:part(b|a|s|p)/:slug/manage/pixels/:key',
    routes: pixelEditRoutes({baseRoute: 'urls'}),
  });

  const backPath = createOrgPath(
    organization,
    `manage/pixels/${pixel.key}/urls`
  );

  return (
    <Switch>
      {renderRoutes({
        components: {
          overview: PixelUrlsOverview,
          create: PixelAddUrl,
          update: PixelUpdateUrl,
          delete: PixelDeleteUrl,
        },
        extraProps: {
          organization,
          pixel,
          backPath,
        },
        redirects: [{from: '*', to: 'urls'}],
      })}
    </Switch>
  );
}
