import {Control, Controller} from 'react-hook-form';
import {DateRangeInput} from '@core/ui/DatePicker';
import {FormHelp, Label} from '@core/ui/FormElements';
import {CampaignFormData} from '../_types';
import {FormGroup} from './_shared';

export function CampaignDatesField({
  control,
}: {
  control: Control<CampaignFormData>;
}) {
  return (
    <FormGroup>
      <Label htmlFor='expectedDates'>Start / End</Label>
      <Controller
        name='expectedDates'
        control={control}
        rules={{
          validate: ({startAt, endAt}) =>
            (!!startAt && !!endAt) ||
            'Please provide expected dates.',
        }}
        render={({field, fieldState}) => (
          <DateRangeInput
            startDate={field.value?.startAt ?? undefined}
            endDate={field.value?.endAt ?? undefined}
            invalid={!!fieldState.error}
            onDatesChange={({startDate, endDate}) =>
              field.onChange({
                startAt: startDate,
                endAt: endDate,
              })
            }
          />
        )}
      />
      <FormHelp>Impressions will run within the selected date range.</FormHelp>
    </FormGroup>
  );
}
