import {gql} from '@apollo/client';

export default gql`
  query AdvisorPodcastBrands(
    $organizationId: ID!
    $id: ID!
    $limit: Int
    $offset: Int
    $query: String
    $after: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        advisor {
          show(id: $id) {
            id
            adPlacements(q: "", limit: 100, sort: "-published", after: $after) {
              count
              rows {
                brand {
                  id
                  name
                }
                episode {
                  published
                }
              }
            }

            topBrands(
              q: $query
              sort: "-adCount"
              limit: $limit
              offset: $offset
            ) {
              offset
              limit
              count
              rows {
                adCount
                episodeCount
                brand {
                  id
                  name
                  industry
                  industryDisplay
                }
              }
            }
          }
        }
      }
    }
  }
`;

// ads(limit: 1, feedId: $id) {
//   id
//   mpegPath
//   waveform
//   createdAt
//   duration
// }
