import {Redirect} from 'react-router-dom';
import {Card} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import {CampaignKind} from '@analytics/components/campaigns/_types';
import {
  CampaignCreateInviteBrand,
  CampaignCreateSteps,
  isBrandClaimedByOrg,
} from '@analytics/components/campaigns/create';
import {useCompanyMembersQuery} from '@analytics/graphql-api';
import {CampaignsRouteProps} from '../../_routes';
import {toPubCreateCampaignBasePath} from './_routes';

export function PublisherInviteBrand({
  organization,
  match: {
    params: {companyId, kind},
  },
}: CampaignsRouteProps & {
  match: {
    params: {companyId: string; kind: CampaignKind};
  };
}) {
  const {data, loading, error} = useCompanyMembersQuery({
    variables: {
      organizationId: organization.id,
      id: companyId,
    },
  });

  const company = data?.me?.organization?.limitedCompany;
  const brandIsClaimedByOrg = isBrandClaimedByOrg({organization, company});

  if (brandIsClaimedByOrg)
    /* go to step 4 */
    return (
      <Redirect
        to={`${toPubCreateCampaignBasePath(
          organization
        )}/${kind}/${companyId}/details`}
      />
    );

  const brandHasMembers =
    (company?.organization?.members ?? []).length > 0 ||
    (company?.organization?.managers ?? []).length > 0;

  return (
    <>
      <CampaignCreateSteps active='brand-invite' />
      <Card>
        {loading ? (
          <LoadingSpinner centered css={{padding: 'var(--spacing-8)'}} />
        ) : error ? (
          /* go back to step 2 */
          <Redirect
            to={`${toPubCreateCampaignBasePath(organization)}/${kind}`}
          />
        ) : (
          <CampaignCreateInviteBrand
            brandExists={brandHasMembers}
            company={company}
            onCompletePath={`${toPubCreateCampaignBasePath(
              organization
            )}/${kind}/${companyId}/details`}
            organization={organization}
          />
        )}
      </Card>
    </>
  );
}
