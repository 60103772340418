import {gql} from '@apollo/client';

export default gql`
  query AdvisorPodcastIndustries($organizationId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        advisor {
          show(id: $id) {
            id

            industriesOverview(q: "", sort: "-adCount") {
              adCount
              episodeCount
              industry
              percent
              totalAds
            }

            topBrands(q: "", sort: "-adCount", limit: 100) {
              rows {
                adCount
                episodeCount
                brand {
                  id
                  name
                  industry
                  industryDisplay
                }
              }
            }
          }
        }
      }
    }
  }
`;
