import {gql} from '@apollo/client';

export default gql`
  query getCampaignDynamicEpisodeOmniChart(
    $organizationId: ID!
    $campaignId: ID!
    $campaignDynamicId: ID!
    $campaignDynamicPodcastId: ID!
    $id: ID!
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          campaignDynamic(id: $campaignDynamicId) {
            id
            campaignDynamicPodcast(id: $campaignDynamicPodcastId) {
              id
              campaignDynamicEpisode(id: $id) {
                id
                attribution {
                  users {
                    hourlyVisitors {
                      count
                      hour
                    }
                  }
                }

                downloads {
                  hourly {
                    hour
                    downloadClass
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
