import {createOrgPath} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {ManageConfigRouteProps} from '.';

export function Config({organization}: ManageConfigRouteProps) {
  return (
    <ResponsiveContent containerSize='medium'>
      <Card>
        <CardHeader>Attribution Config</CardHeader>
        <p>
          You are about to enter the danger zone. Please read this before
          continuing, as updating attribution settings will vastly alter your
          results using Ad Analytics.
        </p>
        <p>
          Podcasting attribution is not digital attribution. We do not get
          user-level data with a podcast download, only an IP address. We also
          do not know when a user listens to a podcast after the download; it
          could be the same day or three weeks later.
        </p>
        <p>
          IPs can also be noisy in that there can be millions of users coming
          through the same IP, specifically on mobile IPs. Because of this, Ad
          Analytics uses multiple attribution windows depending on how the IP is
          classified.
        </p>
        <p>
          If you are not absolutely sure about why you are here, then please
          reach out to the Ad Analytics team; we'd be happy to talk.
        </p>
        <Stack
          direction='row'
          justifyContent='flex-end'
          gap={2}
          css={`
            margin-top: var(-spacing-7);
          `}>
          <Button
            to={createOrgPath(organization, '/manage/config/attribution')}
            variant='outlined'>
            I understand, let me in
          </Button>
          <Button to={createOrgPath(organization, '/manage')} color='secondary'>
            Back to Safety
          </Button>
        </Stack>
      </Card>
    </ResponsiveContent>
  );
}
