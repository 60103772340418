import {useHistory} from 'react-router-dom';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import Button from '@core/ui/Button/LegacyButton';
import {Card} from '@core/ui/Content';
import {Overlay} from '@core/ui/Overlay';
import {FetchedOrganization} from '@analytics/graphql-api';
import {IUpcomingEpisodePodcast} from './useUpcomingEpisodesCalendarData';

const toCampaignEpisode = (
  {
    slug,
    campaignPodcastId,
    campaignEpisodeId,
  }: {slug: string; campaignPodcastId: string; campaignEpisodeId: string},
  organization: FetchedOrganization
) =>
  createOrgPath(
    organization,
    `campaigns/${slug}/podcasts/${campaignPodcastId}/futures/${campaignEpisodeId}`
  );

const UpcomingEpisodeOverlay = ({
  date,
  opened,
  organization,
  podcast: {campaigns, title},
  positionTarget,
  toggle,
}: {
  date: dayjs.Dayjs;
  opened: boolean;
  organization: FetchedOrganization;
  podcast: IUpcomingEpisodePodcast;
  positionTarget: HTMLElement | null;
  toggle: (to?: boolean) => void;
}): JSX.Element => {
  const history = useHistory();
  return (
    <>
      <Overlay
        horizontalAlign='right'
        horizontalOffset={-15}
        noHorizontalOverlap
        opened={opened}
        positionTarget={positionTarget}
        toggle={toggle}
        verticalAlign='middle'
        withShadow
        css={`
          font-size: 0.875rem;
          overflow: initial;
          height: fit-content;
          max-width: 32rem;
          overflow-y: auto;
          padding: 0.75rem;
        `}>
        <h1 css={{fontSize: '1rem', margin: '0 1rem 0 0'}}>
          {title} ({date.format('MM/DD')})
        </h1>
        <>
          {campaigns.map((campaign) => {
            const {advertiser, campaignEpisodeId, name} = campaign;
            return (
              <Card
                key={campaignEpisodeId}
                onClick={() =>
                  history.push(toCampaignEpisode(campaign, organization))
                }
                rules={() => ({
                  alignItems: 'center',
                  background: 'var(--bg-muted)',
                  border: 'none',
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '0.75rem 0 0',
                  padding: '0.75rem',
                })}>
                <div
                  css={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 4fr',
                    gap: '0.5rem',
                    margin: '0 0.75rem 0 0',
                  }}>
                  <div css={{color: 'var(--text-muted)'}}>Campaign</div>
                  <div>{name}</div>
                  <div css={{color: 'var(--text-muted)'}}>Advertiser</div>
                  <div>{advertiser.name}</div>
                </div>
                <Button
                  onClick={() =>
                    history.push(toCampaignEpisode(campaign, organization))
                  }
                  rules={({theme}) => ({
                    ':hover': {
                      background: `${theme.bgTertiary} !important`,
                    },
                  })}
                  style-small
                  style-outline>
                  View
                </Button>
              </Card>
            );
          })}
        </>
      </Overlay>
    </>
  );
};

export default UpcomingEpisodeOverlay;
