import {createOrgPath} from '@core/lib/organizations';
import {FeedImage} from '@core/ui/Image';
import {Nav} from '@core/ui/Nav';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {campaignDynamicURL} from './CampaignDynamicNav';

const campaignDynamicPodcastURL = (
  organization,
  campaign,
  campaignDynamic,
  campaignDynamicPodcast
) =>
  createOrgPath(
    organization,
    `/campaigns/${campaign.slug}/dynamics/${campaignDynamic.id}/podcasts/${campaignDynamicPodcast.id}`
  );

const CampaignDynamicPodcastNav = ({
  campaign,
  campaignDynamic,
  campaignDynamicPodcast,
  organization,
}) => {
  const {feed} = campaignDynamicPodcast;

  return (
    <Nav
      title={feed?.title ?? 'Unknown'}
      description={feed?.description ?? ''}
      Image={
        feed
          ? () => <FeedImage feed={campaignDynamicPodcast.feed} width={150} />
          : undefined
      }
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/measure/campaigns'),
          title: 'Campaigns',
        },
        {
          to: toCampaignUrl(organization, campaign),
          title: `${campaign.name}`,
        },
        {
          to: campaignDynamicURL(organization, campaign, campaignDynamic),
          title: `${campaignDynamic.name}`,
        },
        {
          title: 'Dynamic Ad Podcast',
        },
      ]}
    />
  );
};

export {campaignDynamicPodcastURL};

export default CampaignDynamicPodcastNav;
