import {FormControlLabel, FormControlLabelProps} from '@mui/material';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import {Switch, SwitchProps} from './Switch';

export const SwitchControlled = <T extends FieldValues>({
  name,
  control,
  SwitchProps,
  label = <></>,
  ...labelProps
}: Omit<FormControlLabelProps, 'control'> & {
  control?: Control<T>;
  name: Path<T>;
  label?: FormControlLabelProps['label'];
  SwitchProps?: Omit<SwitchProps, 'name'>;
}) => (
  <Controller
    name={name}
    control={control}
    render={({field}) => (
      <FormControlLabel
        label={label}
        {...labelProps}
        control={<Switch {...field} {...SwitchProps} checked={!!field.value} />}
      />
    )}
  />
);
