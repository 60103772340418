function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { RULE_TYPE, generateDeclarationReference } from 'fela-utils';
import { camelCaseProperty } from 'css-in-js-utils';
import generateCacheEntry from './generateCacheEntry'; // Escaping for RegExp taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping

function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
} // we reuse regexes for improved performance


var regexMap = {};

function getRegex(specificityPrefix) {
  if (!regexMap[specificityPrefix]) {
    regexMap[specificityPrefix] = new RegExp("".concat(escapeRegExp(specificityPrefix), "[.]([0-9a-z_-]+)(([.][0-9a-z_-]+){0,})([^{]+)?{([^:]+):([^}]+)}"), 'gi');
  }

  return regexMap[specificityPrefix];
}

function rehydrateClassList(classList, className) {
  if (classList) {
    var regex = new RegExp("(([.]".concat(className, ")+)?(.*)?"), '');

    var _classList$match = classList.match(regex),
        _classList$match2 = _slicedToArray(_classList$match, 4),
        match = _classList$match2[0],
        repeated = _classList$match2[1],
        selector = _classList$match2[2],
        _classList$match2$ = _classList$match2[3],
        other = _classList$match2$ === void 0 ? '' : _classList$match2$;

    return [other, repeated ? repeated.length / selector.length + 1 : 1];
  }

  return ['', 1];
}

export default function rehydrateRules(css) {
  var media = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var support = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var cache = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var specificityPrefix = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
  var decl;
  var DECL_REGEX = getRegex(specificityPrefix); // This excellent parsing implementation was originally taken from Styletron and modified to fit Fela
  // https://github.com/rtsao/styletron/blob/master/packages/styletron-client/src/index.js#L47

  /* eslint-disable no-unused-vars,no-cond-assign */

  while (decl = DECL_REGEX.exec(css)) {
    var _decl = decl,
        _decl2 = _slicedToArray(_decl, 7),
        ruleSet = _decl2[0],
        className = _decl2[1],
        classList = _decl2[2],
        _ = _decl2[3],
        _decl2$ = _decl2[4],
        pseudo = _decl2$ === void 0 ? '' : _decl2$,
        property = _decl2[5],
        value = _decl2[6];
    /* eslint-enable */


    var _rehydrateClassList = rehydrateClassList(classList, className),
        _rehydrateClassList2 = _slicedToArray(_rehydrateClassList, 2),
        classes = _rehydrateClassList2[0],
        propertyPriority = _rehydrateClassList2[1];

    var declarationReference = generateDeclarationReference( // keep css custom properties as lower-cased props
    property.indexOf('--') === 0 ? property : camelCaseProperty(property), value, classes + pseudo, media, support);
    cache[declarationReference] = generateCacheEntry(RULE_TYPE, className, property, value, classes + pseudo, media, support, specificityPrefix, propertyPriority);
  }

  return cache;
}