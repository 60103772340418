import React, {FC} from 'react';
import {Pie, PieChart} from 'recharts';
import {comma} from '@core/lib/filters';

type InsightsOverlapPieChartProps = {
  baseName: string;
  baseReach: number;
  combined: number;
  compName: string;
  compReach: number;
  overlap: number;
};

const InsightsOverlapPieChart: FC<InsightsOverlapPieChartProps> = ({
  baseName,
  baseReach,
  combined,
  compName,
  compReach,
  overlap,
}): JSX.Element => {
  const ct = {
    baseName: baseReach > compReach ? baseName : compName,
    compName: baseReach > compReach ? compName : baseName,
    reachPct: (baseReach > compReach
      ? ((baseReach - compReach) / compReach) * 100
      : ((compReach - baseReach) / baseReach) * 100
    ).toFixed(2),
    overlapCount: comma(overlap),
    overlapPct: ((overlap * 100) / combined).toFixed(2),
    totalOverlap: comma(combined),
  };

  return (
    <div
      css={{
        alignItems: 'center',
        display: 'grid',
        gridGap: '2rem',
        gridTemplateColumns: 'max-content 1fr',
      }}>
      <PieChart
        width={110}
        height={110}
        margin={{top: 0, right: 0, bottom: 0, left: 0}}>
        <Pie
          dataKey='value'
          data={[
            {
              name: 'Reach A',
              value: baseReach,
              fill: 'var(--green)',
              stroke: 'none',
            },

            {
              name: 'Reach B',
              value: compReach,
              fill: 'var(--blue)',
              stroke: 'none',
            },
          ]}
          outerRadius='100%'
          innerRadius='75%'
        />
        <Pie
          dataKey='value'
          data={[
            {
              name: 'Overlap',
              value: overlap,
              fill: 'var(--pink)',
              stroke: 'none',
            },
            {
              name: '',
              value: baseReach + compReach - overlap,
              fill: 'var(--bg-surface)',
              stroke: 'none',
            },
          ]}
          outerRadius='75%'
          innerRadius='0%'
        />
      </PieChart>
      <div
        css={{
          fontSize: '1rem',
          color: 'var(--text-muted)',
          lineHeight: 1.6,
          '& b': {color: 'var(--text-default)'},
          '& .pink': {color: 'var(--pink)'},
          '& .baseName': {
            color: baseReach > compReach ? 'var(--green)' : 'var(--blue)',
            fontWeight: 500,
          },
          '& .compName': {
            color: baseReach > compReach ? 'var(--blue)' : 'var(--green)',
            fontWeight: 500,
          },
        }}>
        <span className='baseName'>{ct.baseName}</span> has an increased{' '}
        <b>{ct.reachPct}% reach</b> over{' '}
        <span className='compName'>{ct.compName}</span> with an overlap of{' '}
        <b>
          <span className='pink'>
            {ct.overlapCount} ({ct.overlapPct}%)
          </span>{' '}
          households
        </b>{' '}
        and a <b>{ct.totalOverlap} combined reach.</b>
      </div>
    </div>
  );
};

export default InsightsOverlapPieChart;
