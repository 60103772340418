import React, {useState} from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {toTimeSeries} from '@core/lib/series';
import {Element} from '@core/style';
import {BLUE, TEXT_NORMAL} from '@core/style';

const renderTooltip = ({payload} = {}) => {
  if (!payload || payload.length < 1) {
    return null;
  }

  const {x, y} = payload[0].payload;

  return (
    <Element
      rules={() => ({
        background: '#fff',
        borderRadius: '.5rem',
        boxShadow: `0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)`,
        padding: '1rem',
      })}>
      <Element rules={() => ({fontWeight: 700, textTransform: 'capitalize'})}>
        {dayjs(x).utc().format('dddd, MMM DD')}
      </Element>
      <Element>Downloads {comma(y)}</Element>
    </Element>
  );
};

const AttributionAcquiredDayChart = ({acquired, start, end, height = 300}) => {
  const [focusBar, setFocusBar] = useState(null);
  const data = acquired.map(({day, count}) => {
    return {
      x: dayjs(day).valueOf(),
      y: count,
    };
  });
  const _data = toTimeSeries(data, 'day', start, end);

  return (
    <Element
      rules={() => ({fontSize: '0.8125rem'})}
      onMouseLeave={() => setFocusBar(null)}>
      <ResponsiveContainer width='100%' height={height}>
        <BarChart
          data={_data}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setFocusBar(state.activeTooltipIndex);
            } else {
              setFocusBar(null);
            }
          }}>
          <CartesianGrid stroke='var(--border-default)' strokeDasharray='0 0' />
          <XAxis
            dataKey='x'
            domain={['auto', 'auto']}
            type='number'
            scale='time'
            padding={{left: 30}}
            tickFormatter={(timeStr) => dayjs(timeStr).utc().format('MMM DD')}
          />
          <YAxis />
          <Tooltip content={renderTooltip} />
          <Bar dataKey='y' fill={BLUE}>
            {_data.map((entry, idx) => {
              return (
                <Cell key={idx} fill={focusBar === idx ? TEXT_NORMAL : BLUE} />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Element>
  );
};

export default AttributionAcquiredDayChart;
