import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {CampaignsRouteProps} from '../_routes';

export const getCreateCampaignRoutes = ({
  organization,
}: Pick<CampaignsRouteProps, 'organization'>) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, 'campaigns/create'),
    basePath: '/:part(b|a|s|p)/:slug/campaigns/create',
    routes: [
      {
        key: 'redirect',
        path: '',
        exact: true,
      },
      {
        key: 'publisher',
      },
      {
        key: 'brand',
      },
    ],
  });
};
