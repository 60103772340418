import {gql} from '@apollo/client';

export default gql`
  fragment CampaignTarget on CampaignTargetObject {
    id
    podcast {
      id
    }

    feed {
      id
      title
      href
      description(words: 50)
      image {
        href
        id
        path
        src
      }
    }

    reachTotal
    downloadTotal
  }
`;
