import {Helmet} from 'react-helmet-async';
import {Redirect, Route, Switch} from 'react-router-dom';
import {NotAuthorizedError} from '@core/components/AppError';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath, isOrgBuySide} from '@core/lib/organizations';
import Loading from '@core/ui/Loading';
import Layout from '@analytics/components/Layout';
import {useBuySideOrganizationQuery} from '@analytics/graphql-api';
import {AdvisorIndex} from '../unified/advisor';
import {CampaignsIndex} from '../unified/campaigns';
import {ManageIndex} from '../unified/manage';
import {MeasureIndex} from '../unified/measure';
import {ReportsIndex} from '../unified/reports';
import {ResearchIndex} from '../unified/research';
import {BuySideHome} from './BuySideHome';

type Props = {
  user: FetchedUser;
  match: {
    params: {slug: string};
  };
};

export function BuySideIndex({
  user,
  match: {
    params: {slug},
  },
}: Props) {
  const {loading, error, data} = useBuySideOrganizationQuery({
    variables: {
      slug,
    },
  });

  const organization = data?.me?.organization;

  if (loading) {
    return <Loading fixed />;
  }

  if (error || !organization) {
    return <NotAuthorizedError user={user} />;
  }

  if (!isOrgBuySide(organization)) {
    return <Redirect to={createOrgPath(organization)} />;
  }

  return (
    <>
      <Helmet>
        <title>{organization.name} | Ad Analytics</title>
      </Helmet>
      <Layout user={user} organization={organization}>
        <Switch>
          {[
            {exact: true, path: '/a/:slug', Component: BuySideHome},
            {path: '/a/:slug/campaigns', Component: CampaignsIndex},
            {path: '/a/:slug/research', Component: ResearchIndex},
            {path: '/a/:slug/advisor', Component: AdvisorIndex},
            {path: '/a/:slug/measure', Component: MeasureIndex},
            {path: '/a/:slug/manage', Component: ManageIndex},
            {path: '/a/:slug/reports', Component: ReportsIndex},
          ].map(({exact = false, path, Component}) => (
            <Route
              key={path}
              exact={exact}
              path={path}
              render={(routeProps) => (
                <Component
                  user={user}
                  organization={organization}
                  {...routeProps}
                />
              )}
            />
          ))}
        </Switch>
      </Layout>
    </>
  );
}
