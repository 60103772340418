import React, {FC} from 'react';
import {Stack} from '@mui/system';
import {IconHelpCircle} from '@spotify-internal/encore-web';
import styled from 'styled-components';
import {comma, toFixed, toPercent} from '@core/lib/filters';
import {CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import ProgressBar from '@core/ui/ProgressBar';
import {Tooltip} from '@core/ui/Tooltip';

type InsightsPerformancePlayersProps = {
  players: Array<{
    player: string;
    total: number;
    reach: number;
    frequency: number;
    impressionsLessThan5: number;
    percentageImpressionsLessThan5: number;
    reachLessThan5: number;
    percentageReachLessThan5: number;
    impressionsBetween5And15: number;
    percentageImpressionsBetween5And15: number;
    reachBetween5And15: number;
    percentageReachBetween5And15: number;
    impressionsGreaterThan15: number;
    percentageImpressionsGreaterThan15: number;
    reachGreaterThan15: number;
    percentageReachGreaterThan15: number;
  }>;
};

const _FrequencyValue = ({title, value, percent}) => (
  <div>
    <div
      css={`
        display: flex;
      `}>
      <span
        css={`
          flex: 1;
          text-align: left;
        `}>
        {title}: {comma(value)}
      </span>
      <span
        css={`
          font-size: 0.75rem;
          color: var(--gray-7);
        `}>
        {toPercent(percent, 1)}
      </span>
    </div>
    <div>
      <ProgressBar
        value={percent}
        max={1}
        css={`
          border-radius: var(--radius-2);
          height: 10px;
          margin-bottom: 0.5rem;
        `}
      />
    </div>
  </div>
);

const _Frequency = ({
  reach,
  impressions,
  percentReach,
  percentImpressions,
  description,
}) => {
  return (
    <Tooltip description={description}>
      <div>
        <_FrequencyValue
          title='Impressions'
          value={impressions}
          percent={percentImpressions}
        />
        <_FrequencyValue title='Reach' value={reach} percent={percentReach} />
      </div>
    </Tooltip>
  );
};

const columns: DataTableColumn[] = [
  {
    title: 'Player',
    accessor: 'player',
    type: 'string',
    Cell: ({d: {player}}) => {
      if (player == 'Other') {
        return (
          <Tooltip
            description={
              "'Other' represents players not considered to be broadly-compatible podcast player user agents."
            }>
            <span
              css={`
                display: flex;
                align-items: center;
              `}>
              Other&nbsp;
              <IconHelpCircle size='small' />
            </span>
          </Tooltip>
        );
      }

      return player;
    },
  },
  {
    title: 'Impressions',
    accessor: 'total',
    type: 'number',
    fmt: comma,
  },
  {
    title: 'Player Reach',
    accessor: 'reach',
    type: 'number',
    fmt: comma,
  },
  {
    title: 'Player Frequency',
    accessor: 'frequency',
    type: 'number',
    fmt: toFixed,
  },
  {
    title: 'Player Frequency < 5',
    accessor: 'percentageImpressionsLessThan5',
    type: 'number',
    description:
      'Impressions and reach for listeners exposed to the ad less than five times during the flight.',
    fmt: toFixed,
    Cell: ({
      d: {
        player,
        totalImpressions,
        reachLessThan5,
        percentageReachLessThan5,
        impressionsLessThan5,
        percentageImpressionsLessThan5,
      },
    }) => (
      <_Frequency
        reach={reachLessThan5}
        percentReach={percentageReachLessThan5}
        impressions={impressionsLessThan5}
        percentImpressions={percentageImpressionsLessThan5}
        description={`
        ${toPercent(
          percentageImpressionsLessThan5
        )} of impressions reached ${toPercent(
          percentageReachLessThan5
        )} of the audience for users exposed to the ad less than five times on the ${player} player. In total, this bucket accounts for ${toPercent(
          impressionsLessThan5 / totalImpressions
        )} of the entire flight's impressions.
        `}
      />
    ),
  },
  {
    title: '5 <= Player Frequency <= 15',
    accessor: 'percentageImpressionsBetween5And15',
    type: 'number',
    description:
      'Impressions and reach for listeners exposed to the ad between five and fifteen times during the flight.',
    fmt: toFixed,
    Cell: ({
      d: {
        player,
        totalImpressions,
        reachBetween5And15,
        percentageReachBetween5And15,
        impressionsBetween5And15,
        percentageImpressionsBetween5And15,
      },
    }) => (
      <_Frequency
        reach={reachBetween5And15}
        percentReach={percentageReachBetween5And15}
        impressions={impressionsBetween5And15}
        percentImpressions={percentageImpressionsBetween5And15}
        description={`
        ${toPercent(
          percentageImpressionsBetween5And15
        )} of impressions reached ${toPercent(
          percentageReachBetween5And15
        )} of the audience for users exposed to the ad between five and fifteen times on the ${player} player. In total, this bucket accounts for ${toPercent(
          impressionsBetween5And15 / totalImpressions
        )} of the entire flight's impressions.
        `}
      />
    ),
  },
  {
    title: '15 < Player Frequency',
    accessor: 'percentageImpressionsGreaterThan15',
    type: 'number',
    description:
      'Impressions and reach for listeners exposed to the ad more fifteen times during the flight.',
    fmt: toFixed,
    Cell: ({
      d: {
        player,
        totalImpressions,
        reachGreaterThan15,
        percentageImpressionsGreaterThan15,
        impressionsGreaterThan15,
        percentageReachGreaterThan15,
      },
    }) => (
      <_Frequency
        reach={reachGreaterThan15}
        percentReach={percentageReachGreaterThan15}
        impressions={impressionsGreaterThan15}
        percentImpressions={percentageImpressionsGreaterThan15}
        description={`
        ${toPercent(
          percentageImpressionsGreaterThan15
        )} of impressions reached ${toPercent(
          percentageReachGreaterThan15
        )} of the audience for users exposed to the ad more than fifteen times on the ${player} player. In total, this bucket accounts for ${toPercent(
          impressionsGreaterThan15 / totalImpressions
        )} of the entire flight's impressions.
        `}
      />
    ),
  },
];

const InsightsPerformancePlayersTable: FC<InsightsPerformancePlayersProps> = ({
  players,
}): JSX.Element => {
  const totalImpressions = players.reduce((v, {total}) => v + total, 0);

  return (
    <DataTable
      columns={columns}
      data={players.map((player) => ({totalImpressions, ...player}))}
      orderBy='-total'
      emptyMessageText='No players data found'
      searchKeys={['player']}
      searchPlaceholder='Search by player'
      headerRenderer={({filtersContent, searchContent}) => (
        <Stack gap={3} justifyContent='space-between'>
          <CardHeader
            css={`
              margin: 0;
            `}>
            Players
          </CardHeader>
          {(searchContent || filtersContent) && (
            <Stack alignItems='center'>
              {searchContent}
              {filtersContent}
            </Stack>
          )}
        </Stack>
      )}
    />
  );
};

export default InsightsPerformancePlayersTable;
