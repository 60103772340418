import {gql} from '@apollo/client';

export default gql`
  fragment StreamingApproval on StreamingApprovalObject {
    id
    requestedAt
    updatedAt
    state
    comment
    campaignStreaming {
      id
    }
    updatedBy {
      email
    }
    requestedBy {
      id
      firstName
      lastName
      email
    }
    requestedByOrganization {
      id
      name
    }
    requestee {
      id
      firstName
      lastName
      email
    }
    requesteeOrganization {
      id
      name
    }
    rejectedReason
  }
`;
