import {gql} from '@apollo/client';

export default gql`
  query getCompanyFeeds($organizationId: ID!, $id: ID!, $limit: Int) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          company(id: $id) {
            id
            feeds(limit: $limit) {
              id
              title
              subtitle
              summary
              image {
                id
                href
                path
              }
              avgRank
              episodesCount(companyId: $id)
            }
          }
        }
      }
    }
  }
`;
