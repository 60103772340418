import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignDynamicRouteProps} from '../_routes';
import CampaignDynamicPodcastIndex from './podcast';

export function CampaignDynamicPodcastsIndex({
  user,
  organization,
  campaign,
  campaignDynamic,
}: CampaignDynamicRouteProps) {
  return (
    <Switch>
      <PropsRoute
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/dynamics/:campaignDynamicId/podcasts/:campaignDynamicPodcastId'
        campaign={campaign}
        user={user}
        organization={organization}
        campaignDynamic={campaignDynamic}
        component={CampaignDynamicPodcastIndex}
      />
    </Switch>
  );
}
