import {cleanList} from '@core/lib/clean';
import dayjs from '@core/lib/dayjs';
import {Text, TextProps} from '@core/ui/Text';
import {
  CampaignAuditLogEventObject,
  CampaignAuditLogEventTypes,
  CampaignAuditLogEvents,
} from '@analytics/graphql-api';
import {
  CampaignActivityLogAction,
  CampaignActivityLogEvent,
  FieldNameChanged,
  FormatStyle,
} from './types';

/* ---------------------------- Public Formatters --------------------------- */

export const format = (o: CampaignActivityLogEvent) =>
  Object.freeze({
    changed: (
      <>
        <Bold>{o.user}</Bold> {o.action} the {formatType(o.eventType)}{' '}
        {formatField(o.fieldName)?.[0] ?? 'a value'}{' '}
        {fieldValue(o.fieldName, o.oldVal, o.newVal)} on{' '}
        <Bold>{o.campaignOrLineItemName}</Bold>
      </>
    ),

    deleted: (
      <>
        <Bold>{o.user}</Bold> {o.action} {formatType(o.eventType)}{' '}
        <Bold>{o.campaignOrLineItemName}</Bold>
      </>
    ),

    'removed episode': (
      <>
        <Bold>{o.user}</Bold> removed {formatType(o.eventType)} {o.oldVal} on{' '}
        <Bold>{o.campaignOrLineItemName}</Bold>
      </>
    ),

    'added episode': (
      <>
        <Bold>{o.user}</Bold> added {formatType(o.eventType)} {o.newVal} on{' '}
        <Bold>{o.campaignOrLineItemName}</Bold>
      </>
    ),
  })[o.action];

export function parseAuditLogEvents(events?: CampaignAuditLogEvents | null) {
  const MOVE_TO_FRONT = -1;
  const MOVE_TO_REAR = 1;

  return cleanList(events)
    .map(
      (event) =>
        ({
          ...event,
          action: event.action as CampaignActivityLogAction,
          key: event.id,
          user: name(event),
          fieldName: event.fieldName ?? '',
          oldVal: event.oldVal ?? '',
          newVal: event.newVal ?? '',
        } as const)
    )
    .sort(byDateDescending);

  function byDateDescending(
    event1: WithDateTime,
    event2: WithDateTime
  ): typeof MOVE_TO_FRONT | typeof MOVE_TO_REAR {
    return dayjs(event1.dateTimeChanged).isBefore(dayjs(event2.dateTimeChanged))
      ? MOVE_TO_FRONT
      : MOVE_TO_REAR;
  }
  type WithDateTime = {dateTimeChanged: string};
}

export const prettifyFieldName = (fieldName: FieldNameChanged) =>
  ({
    expected_start_at: 'Expected Start Date',
    go_live_date: 'Go Live Date',
    payer_id: 'Payer ID',
    discount_code: 'Discount Code',
    discount_url: 'Discount URL',
    effective_url: 'Effective URL',
    is_mid_roll: 'Is Mid Roll',
    is_post_roll: 'Is Post Roll',
    is_pre_roll: 'Is Pre Roll',
  }[fieldName] ?? fieldName);

/* --------------------------- Private Formatters --------------------------- */

const Bold = (props: TextProps) => (
  <Text as='span' gutterBottom={false} variant={'subtitle2'} {...props} />
);

const formatType = (
  eventType: 'CAMPAIGN' | 'DYNAMIC' | 'STREAMING' | 'EMBEDDED' | 'EPISODE'
) =>
  (
    Object.freeze({
      CAMPAIGN: 'campaign',
      DYNAMIC: 'dynamic ad',
      STREAMING: 'streaming ad',
      EMBEDDED: 'embedded ad',
      EPISODE: 'embedded episode',
    }) satisfies Record<CampaignAuditLogEventTypes[number], string>
  )[eventType];

const _SHARED_CHANGE_TYPES = Object.freeze({
  cost: ['cost', 'currency'],
  goal: ['impressions goal', 'integer'],
  name: ['name', 'name'],
});

// fieldName values to track campaign-level changes
const _CAMPAIGN_CHANGE_TYPES = Object.freeze({
  expected_end_at: ['expected end date', 'date'],
  expected_start_at: ['expected start date', 'date'],
  go_live_date: ['go-live date', 'date'],
  payer_id: ['payer', 'name'],
});

// shared fieldName values to track ad-level (dynamic, embedded) changes on a campaign
const _SHARED_AD_CHANGE_TYPES = Object.freeze({
  discount_code: ['unique podcast visitor discount code', 'capitalized'],
  discount_url: ['unique discount URL for podcast visitors', 'url'],
  duration: ['duration', 'seconds'],
  effective_url: ['unique URL for podcast visitors', 'url'],
});

// fieldName values to track ad-level (dynamic, embedded) changes on a campaign
const _EMBEDDED_AND_DYNAMIC_CHANGE_TYPES = Object.freeze({
  is_mid_roll: ['mid-roll placement', 'boolean'],
  is_post_roll: ['post-roll placement', 'boolean'],
  is_pre_roll: ['pre-roll placement', 'boolean'],
});

// fieldName -> the field that was changed
const formatField = (
  fieldName: string
): Record<FieldNameChanged, FormatStyle> =>
  Object.freeze({
    ..._SHARED_CHANGE_TYPES,
    ..._CAMPAIGN_CHANGE_TYPES,
    ..._SHARED_AD_CHANGE_TYPES,
    ..._EMBEDDED_AND_DYNAMIC_CHANGE_TYPES,
  })[fieldName];

// value -> `oldVal` or `newVal` for the fieldName that changed
const formatValue = (style: FormatStyle, value: string) => (
  <Bold>
    {Object.freeze(
      {
        date: dayjs(value).format('MMM D, YYYY (h:m A)'),
        boolean: `${value}`,
        integer: new Intl.NumberFormat().format(parseInt(value)),
        name: `${capitalize(value)}`,
        capitalized: `${value.toLocaleUpperCase()}`,
        url: `${value}`,
        seconds: new Intl.NumberFormat().format(parseInt(value)),
        currency: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(parseFloat(value)),
      }[style] ?? value
    )}
  </Bold>
);

// format the `oldVal` and `newVal` for the fieldName that changed
const fieldValue = (objFieldName: string, oldVal: string, newVal: string) => {
  const style = formatField(objFieldName)?.[1];

  if (!!oldVal && !!newVal && objFieldName !== 'name') {
    return (
      <>
        from {formatValue(style, oldVal)} to {formatValue(style, newVal)}
        {objFieldName === 'duration' ? ' seconds' : ''}
      </>
    );
  }

  // The campaign / line item names are too long. Separate them out not to show the original (before) value of the changed named.
  if (objFieldName === 'name' || !oldVal) {
    return <>to {formatValue(style, newVal)}</>;
  }

  if (!newVal) {
    return <>from {formatValue(style, oldVal)} to another value</>;
  }

  return <>to another value</>;
};

/* -------------------------------- Helpers -------------------------------- */

function capitalizeFirstLetters(wordOrWords: string[]) {
  return wordOrWords.map(([firstLetter, ...letters]) =>
    firstLetter.toLocaleUpperCase().concat(letters.join('').toLocaleLowerCase())
  );
}

function capitalize(wordOrWords: string) {
  if (!wordOrWords) return wordOrWords;
  return capitalizeFirstLetters(wordOrWords.split(' ').filter(Boolean)).join(
    ' '
  );
}

// Combine first name and first letter of last name into a single string
function name({userFirstName, userLastInitial}: CampaignAuditLogEventObject) {
  if (!userLastInitial) return `${userFirstName}`;
  return `${userFirstName} ${userLastInitial}.`;
}
