import {graphql} from '@apollo/client/react/hoc';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import AdsTable from '@analytics/components/ads/AdsTable';
import CompanyAdsQuery from '@analytics/graphql-api/_old_queries/research/CompanyAdsQuery';

const CompanyAds = ({organization, company, data: {loading, error, me}}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const {ads} = me.organization.research.company;

  return (
    <>
      <CardHeader>Ads</CardHeader>
      <Card>
        <Element tag='p' rules={() => ({fontSize: '0.9375rem'})}>
          All {company.name} ads across all podcasts
        </Element>
        <AdsTable
          organization={organization}
          ads={ads}
          company={company}
          orderBy='-firstPublished'
        />
      </Card>
    </>
  );
};

export default graphql(CompanyAdsQuery, {
  options: ({organization, company: {id}}) => ({
    variables: {
      organizationId: organization.id,
      id,
      limit: 100,
      orderBy: '-firstPublished',
    },
  }),
})(CompanyAds);
