import React from 'react';
import {Route, Switch} from 'react-router-dom';
import AccountTerms from '../account/AccountTerms';
import Onboard from './Onboard';
import OnboardAdInsertion from './OnboardAdInsertion';
import OnboardCreate from './OnboardCreate';
import OnboardEvent from './OnboardEvents';
import OnboardPixel from './OnboardPixel';
import OnboardResearch from './OnboardResearch';
import OnboardWelcome from './OnboardWelcome';

const OnboardIndex = () => {
  return (
    <Switch>
      <Route exact path='/onboard' component={Onboard} />
      <Route exact path='/onboard/welcome' component={OnboardWelcome} />
      <Route exact path='/onboard/terms/:slug' component={AccountTerms} />
      <Route
        exact
        path='/onboard/create-pixel/:slug'
        component={OnboardPixel}
      />
      <Route
        exact
        path='/onboard/create-event/:slug'
        component={OnboardEvent}
      />
      <Route
        exact
        path='/onboard/ad-insertion/:slug'
        component={OnboardAdInsertion}
      />

      <Route
        exact
        path='/onboard/research/:organizationId'
        component={OnboardResearch}
      />
      <Route path='/onboard/:kind' component={OnboardCreate} />
    </Switch>
  );
};

export default OnboardIndex;
