import {Switch} from 'react-router-dom';
import {CampaignsRouteProps} from '../../_routes';
import {PublisherSelectCampaignType} from './Step1_SelectCampaignType';
import {PublisherSelectBrandDetails} from './Step2_BrandDetails';
import {PublisherInviteBrand} from './Step3_InviteBrand';
import {PublisherCampaignDetails} from './Step4_CampaignDetails';
import {getPubCreateCampaignRoutes} from './_routes';

export function PublisherCampaignCreateIndex({
  user,
  organization,
}: CampaignsRouteProps) {
  const {renderRoutes} = getPubCreateCampaignRoutes({organization});
  return (
    <Switch>
      {renderRoutes({
        components: {
          campaignType: PublisherSelectCampaignType,
          brandDetails: PublisherSelectBrandDetails,
          brandInvite: PublisherInviteBrand,
          campaignDetails: PublisherCampaignDetails,
        },
        extraProps: {
          organization,
          user,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
