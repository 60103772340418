import React from 'react';
import {
  Control,
  Controller,
  ControllerProps,
  FieldValues,
  Path,
} from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '../Form New';
import {Checkbox, CheckboxProps} from './Checkbox.new';

export type CheckboxControlledProps<T extends FieldValues> = Omit<
  CheckboxProps,
  'name'
> & {
  control?: Control<T>;
  helperText?: string;
  label?: React.ReactNode;
  name: Path<T>;
  validation?: ControllerProps['rules'];
};

export function CheckboxControlled<T extends FieldValues>({
  color = 'primary',
  control,
  helperText: helperTextProp,
  label = '',
  name,
  required,
  validation = {},
  ...props
}: CheckboxControlledProps<T>) {
  if (required && !validation.required) {
    validation.required = 'This field is required';
  }

  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      render={({field: {value, onChange}, fieldState: {error}}) => {
        const helperText = error?.message ?? helperTextProp;

        return (
          <FormControl required={required} error={!!error}>
            <FormGroup row>
              <FormControlLabel
                label={label}
                control={
                  <Checkbox
                    {...props}
                    color={color}
                    value={value}
                    checked={!!value}
                    onChange={() => onChange(!value)}
                  />
                }
              />
            </FormGroup>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
}
