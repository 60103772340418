import {gql} from '@apollo/client';

export default gql`
  query getPaginatedPodcasterCampaignEpisodesNodes(
    $organizationId: ID!
    $podcastId: ID!
    $campaignId: ID
    $offset: Int
    $limit: Int
    $query: String
    $orderBy: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        podcast(id: $podcastId, campaignId: $campaignId) {
          id
          feed {
            id
            title
            episodeNodes(
              offset: $offset
              limit: $limit
              query: $query
              orderBy: $orderBy
            ) {
              offset
              pages
              count
              hasNext
              hasPrev
              nodes {
                id
                title
                published
              }
            }
          }
        }
      }
    }
  }
`;
