import {ProgressCircle} from '@spotify-internal/encore-web';
import {useHistory, useParams} from 'react-router-dom';
import SpotifyBanner from '@analytics/components/onboard/SpotifyBanner';
import {useOrganizationQuery} from '@analytics/graphql-api';
import {PageContainer} from '../account/AccountLayout';
import AddAdInsertionProvider from '../unified/manage/adInsertionProvider/AddAdInsertionProvider';

const OnboardAdInsertion = () => {
  const {slug} = useParams<{slug: string}>();
  const navigation = useHistory();

  const {data: orgData, loading: orgLoading} = useOrganizationQuery({
    variables: {
      slug,
    },
  });
  const organization = orgData?.me?.organization;

  if (orgLoading) {
    return (
      <PageContainer>
        <SpotifyBanner advertising progress={60} />
        <ProgressCircle
          aria-label='ProgressCircle'
          style={{alignSelf: 'center', marginTop: '100px'}}
        />
      </PageContainer>
    );
  }

  const onSucess = () => {
    navigation.push(`/onboard/terms/${slug}`);
  };

  return (
    <PageContainer style={{flexDirection: 'column'}}>
      <SpotifyBanner advertising progress={60} />
      <AddAdInsertionProvider
        organization={organization}
        onSuccess={onSucess}
        skippable
      />
    </PageContainer>
  );
};

export default OnboardAdInsertion;
