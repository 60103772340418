import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import DataTable from '@core/ui/DataTable';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  FetchedSpotifyLiftReports,
} from '@analytics/graphql-api';
import {generateColumns} from './columns';

export function BrandLiftReportTable({
  liftReports,
  organization,
  user,
  ...props
}: {
  liftReports: FetchedSpotifyLiftReports;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
}) {
  return (
    <DataTable
      data={liftReports}
      columns={generateColumns({organization, user})}
      orderBy='-createdAt'
      emptyMessageText='No lift reports found'
      searchKeys={['name']}
      onClickRowPath={({id}) =>
        createOrgPath(organization, `measure/lift/spotify/reports/${id}`)
      }
      {...props}
    />
  );
}
