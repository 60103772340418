import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import {capitalize} from '@core/lib/filters';
import {Card} from '@core/ui/Content';
import {Input, Label} from '@core/ui/FormElements';
import {LoadingSpinner} from '@core/ui/Loading';
import Select from '@core/ui/Select';
import {InfoTooltip} from '@core/ui/Tooltip';
import {withInsightsImpressionsQuery} from '@analytics/components/insights/InsightsImpressionsQueryWrapper';
import InsightsReachAnalysisTable from '@analytics/components/insights/InsightsReachAnalysisTable';
import INTERVAL_REACH_OVERLAP_QUERY from '@analytics/graphql-api/_old_queries/BrandOrganizationImpressionsIntervalReachOverlapQuery';
import {MeasureInsightsRouteProps} from '..';

type Props = MeasureInsightsRouteProps & {
  campaigns: any[];
  publishers: any[];
};

const DEFAULT_TRAILING = 3;

const INTERVALS = [
  {title: 'Monthly', value: 'month'},
  {title: 'Weekly', value: 'week'},
  {title: 'Daily', value: 'day'},
];

const ATTRS = [
  {title: 'Households', value: 'household_id'},
  {title: 'Ips', value: 'ip'},
];

export const InsightsReachIndex = withInsightsImpressionsQuery(
  ({after, before, campaigns, organization, publishers}: Props) => {
    const [trailing, setTrailing] = useState(DEFAULT_TRAILING);
    const [interval, _setInterval] = useState<'day' | 'week' | 'month'>(
      'month'
    );
    const [attr, setAttr] = useState<'household_id' | 'ip'>('household_id');
    const {loading, error, data} = useQuery(INTERVAL_REACH_OVERLAP_QUERY, {
      variables: {
        id: organization.id,
        after,
        before,
        trailing: trailing ? trailing : 0,
        interval,
        attr,
        publisherIds: publishers.map(({id}) => id),
        campaignIds: campaigns.map(({id}) => id),
      },
    });

    const exportURLParams = {
      after,
      attr,
      before,
      interval,
      trailing,
    } as any;

    if (campaigns.length > 0) {
      exportURLParams.campaign_id = campaigns.map(({id}) => id);
    }

    if (publishers.length > 0) {
      exportURLParams.publisher_id = publishers.map(({id}) => id);
    }

    let content: React.ReactNode;

    if (loading) {
      content = <LoadingSpinner centered />;
    } else {
      const intervalReachOverlap =
        data?.me?.organization?.overview?.impressions?.intervalReachOverlap ??
        [];
      content = <InsightsReachAnalysisTable data={intervalReachOverlap} />;
    }

    return (
      <>
        <Card rules={() => ({margin: '1rem 1.5rem 0'})}>
          <div
            css={{
              alignItems: 'end',
              display: 'grid',
              gridGap: '1rem',
              gridTemplateColumns: 'repeat(3, 2fr) max-content',
              margin: '0 0 1.75rem 0',
            }}>
            <div>
              <Label>Interval</Label>
              <Select
                defaultValue={interval}
                items={INTERVALS}
                onSelect={({value}) => _setInterval(value)}
                outline
                rules={() => ({width: '100%'})}
              />
            </div>
            <div>
              <Label>
                Trailing {capitalize(interval)}
                {trailing > 1 ? 's' : null}
                <InfoTooltip
                  title={`Trailing ${capitalize(interval)}`}
                  description={`The number of prior ${interval}s to use to compare with the current
            interval. We will use ${trailing} ${interval}s prior to the current
            month to determine the new reach.`}
                  rules={() => ({margin: '0.1875rem 0 0 0.375rem'})}
                />
              </Label>
              <Input
                type='number'
                name='trailing'
                value={trailing}
                onChange={(evt) => {
                  const target = evt.target as HTMLInputElement;
                  setTrailing(Number(target.value.trim()));
                }}
                small
              />
            </div>
            <div>
              <Label>
                Households or IP Reach
                <InfoTooltip
                  title='Households or IP Reach'
                  description={` We can calculate these numbers based on the Tapad household Ids or
              IP addresses.`}
                  rules={() => ({margin: '0.1875rem 0 0 0.375rem'})}
                />
              </Label>
              <Select
                defaultValue={attr}
                items={ATTRS}
                onSelect={({value}) => setAttr(value)}
                outline
                rules={() => ({width: '100%'})}
              />
            </div>
          </div>
          {content}
        </Card>
      </>
    );
  },
  {
    title: 'Interval Reach Overlap',
    withCampaigns: true,
    withPublishers: true,
  }
);
