import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import PodlinkDomainForm from '@analytics/components/podlink/PodlinkDomainForm';
import DELETE_PODLINK_DOMAIN from '@analytics/graphql-api/_old_mutations/DeletePodlinkDomain';
import UPDATE_PODLINK_DOMAIN from '@analytics/graphql-api/_old_mutations/UpdatePodlinkDomain';
import PODLINK_DOMAINS_QUERY from '@analytics/graphql-api/_old_queries/podlink/PodlinkDomainsQuery';
import {ManagePodlinkDomainRouteProps} from '.';

export function PodlinkDomainUpdate({
  organization,
  podlinkDomain,
  podcasts,
}: ManagePodlinkDomainRouteProps) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [error, setError] = useState<string>(null);

  const [updatePodlinkDomain] = useMutation(UPDATE_PODLINK_DOMAIN);
  const [deletePodlinkDomain] = useMutation(DELETE_PODLINK_DOMAIN, {
    refetchQueries: [PODLINK_DOMAINS_QUERY],
  });

  const onSubmit = async ({slug, podcastIds}) => {
    setError(null);
    setLoading(true);
    try {
      const resp = await updatePodlinkDomain({
        variables: {
          input: {
            id: podlinkDomain.id,
            organizationId: organization.id,
            podcastIds,
            slug,
          },
        },
      });

      const podlinkSlug = resp.data.updatePodlinkDomain.slug;
      history.push(
        createOrgPath(organization, `manage/podlink/${podlinkSlug}`)
      );
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  const onDelete = async () => {
    setError(null);
    setDeleteLoading(true);
    try {
      await deletePodlinkDomain({
        variables: {
          input: {
            id: podlinkDomain.id,
          },
        },
      });

      history.push(createOrgPath(organization, 'manage/podlink'));
    } catch (e) {
      setError(e.message);
    }
    setDeleteLoading(false);
  };

  return (
    <>
      <ResponsiveContent containerSize='medium'>
        <CardHeader
          backPath={createOrgPath(
            organization,
            `manage/podlink/${podlinkDomain.slug}`
          )}>
          Update pod.link Subdomain
        </CardHeader>
        <Card>
          {!!error && (
            <ToastMessage alertType='error' rules={() => ({margin: 0})}>
              {error}
            </ToastMessage>
          )}
          <PodlinkDomainForm
            loading={loading || deleteLoading}
            onCancel={() => {
              history.push(
                createOrgPath(
                  organization,
                  `manage/podlink/${podlinkDomain.slug}`
                )
              );
            }}
            onDelete={onDelete}
            onSubmit={onSubmit}
            organization={organization}
            podcasts={podcasts}
            podlinkDomain={podlinkDomain}
          />
        </Card>
      </ResponsiveContent>
    </>
  );
}
