import React, {useEffect} from 'react';
import {Element} from '@core/style';
import {GREEN} from '@core/style';

class Bar extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {value, height, pretty, color} = this.props;
    const barHeight = (height * value) / 100;

    const style = {
      flex: '1 0 0%',
      height: `${barHeight}px`,
      marginTop: `${height - barHeight}px`,
      background: color ? color : 'rgba(255,255,255, 0.5)',
    };

    if (pretty) {
      style.borderRadius = '2px 2px 0 0';
      style.marginLeft = '1px';
      style.marginRight = '1px';
    }

    return <div style={style} />;
  }
}

class AudioWaveform extends React.Component {
  constructor(props) {
    super(props);

    const {waveform} = props;

    this.state = {
      pretty: false,
      waveform: waveform ? JSON.parse(waveform) : [],
    };
  }

  getBarWidth = (trackWidth) => {
    const {waveform} = this.state;
    return trackWidth / waveform.length;
  };

  shouldComponentUpdate(nextProps) {
    if (this.state.pretty != this.getBarWidth(nextProps.trackWidth) > 5) {
      return true;
    }
    return false;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      pretty: this.getBarWidth(nextProps.trackWidth) > 5,
    });
  }

  render() {
    const {height, color} = this.props;
    const {pretty, waveform} = this.state;

    if (!waveform.length) {
      return '';
    }

    return (
      <Element
        rules={() => ({position: 'relative'})}
        domRef={(elem) => {
          this.elem = elem;
        }}>
        <Element
          style-show={!pretty}
          rules={({show}) => ({display: show ? 'flex' : 'none'})}>
          {waveform
            .filter((v, n) => n % 2 == 0)
            .map((v, n) => {
              return <Bar value={v} height={height} key={n} color={color} />;
            })}
        </Element>
        <Element
          style-show={pretty}
          rules={({show}) => ({display: show ? 'flex' : 'none'})}>
          {waveform.map((v, n) => {
            return <Bar value={v} height={height} key={n} color={color} />;
          })}
        </Element>
      </Element>
    );
  }
}

const AudioWaveformCanvas = ({
  rules,
  trackWidth,
  waveColor = GREEN,
  waveform,
  barGutter = 1,
} = {}) => {
  const canvasRef = React.useRef();

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = trackWidth || canvas.offsetWidth;
    const height = canvas.offsetHeight;
    const halfHeight = height / 2;
    const barWidth = (width - waveform.length) / waveform.length;

    ctx.canvas.width = width;
    ctx.canvas.height = height;

    window.requestAnimationFrame(() => {
      ctx.clearRect(0, 0, width, height);

      waveform.forEach(({value, color}, index) => {
        ctx.fillStyle = color;
        // Top
        ctx.fillRect(
          index * (barWidth + barGutter),
          halfHeight - halfHeight * (value / 100),
          barWidth,
          halfHeight * (value / 100)
        );
        // Bottom
        ctx.fillRect(
          index * (barWidth + barGutter),
          halfHeight,
          barWidth,
          halfHeight * (value / 100)
        );
      });
    });
  }, [trackWidth]);

  return (
    <Element
      tag='canvas'
      domRef={canvasRef}
      rules={[
        rules,
        () => ({width: trackWidth ? 'inherit' : '100%', height: '100%'}),
      ]}
    />
  );
};

export {AudioWaveformCanvas};

export default AudioWaveform;
