import { screenSmMin } from '@spotify-internal/encore-foundation';
import { cssSpacing, dialog } from "../../styles/index.js";
import { Body } from "./Body.js";
import styled from "../../styled-components.js";
export var Dialog = styled.div.attrs({
  role: 'dialog'
}).withConfig({
  displayName: "Dialog",
  componentId: "sc-1o778i8-0"
})(["", ";width:600px;overflow-wrap:break-word;", ":first-child{padding-block-start:", ";}@media (min-width:", "){", ":last-child{padding-block-end:", ";}}"], function (props) {
  return dialog({
    colorSet: props.$colorSet
  });
}, Body, cssSpacing('base', '16px'), screenSmMin, Body, cssSpacing('base', '16px'));