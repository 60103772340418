import React, {useState} from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {comma, toFixed} from '@core/lib/filters';
import {Element} from '@core/style';
import {GREEN, darken} from '@core/style';
import {Card, CardHeader, Grid} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import {CardStat} from '@core/ui/Stat';

const renderTooltip = ({payload}) => {
  if (!payload || payload.length < 1) {
    return null;
  }
  const {total, frequency} = payload[0].payload;

  return (
    <Element
      rules={() => ({
        background: '#fff',
        borderRadius: '.5rem',
        boxShadow: `0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)`,
        display: 'grid',
        fontSize: '0.875rem',
        gridGap: '1rem',
        gridTemplateColumns: '1fr 1fr',
        padding: '1rem',
      })}>
      <Element>
        <Element
          rules={({theme}) => ({
            color: theme.textTertiary,
            margin: '6px 0 6px',
          })}>
          Households
        </Element>
        <Element
          rules={() => ({
            color: GREEN,
            fontSize: '1.75rem',
            fontWeight: 500,
            marginRight: '.5rem',
          })}>
          {comma(total)}
        </Element>
      </Element>
      <Element>
        <Element
          rules={({theme}) => ({
            color: theme.textTertiary,
            margin: '6px 0 6px',
          })}>
          Frequency
        </Element>
        <Element
          rules={() => ({
            fontSize: '1.75rem',
            fontWeight: 500,
            marginRight: '.5rem',
          })}>
          {comma(frequency)}
        </Element>
      </Element>
    </Element>
  );
};

const CampaignOverviewFrequency = ({impressions, reach, data}) => {
  if (!data) {
    return (
      <Card>
        <EmptyMessage>No frequency data for this period of time.</EmptyMessage>
      </Card>
    );
  }
  const frequency = impressions / reach;
  const {nodes} = data;
  const [focusBar, setFocusBar] = useState(null);

  return (
    <Element rules={() => ({fontSize: '0.8125rem'})}>
      <Grid cols={3} rules={() => ({gridGap: '1.5rem', margin: '0 1.5rem'})}>
        <CardStat value={comma(impressions)} title='Impressions' />
        <CardStat value={comma(reach)} title='Reached' />
        <CardStat value={toFixed(frequency)} title='Frequency' />
      </Grid>
      <Card
        rules={({theme}) => ({
          background: theme.bgTertiary,
          border: 0,
          margin: '1rem 1.5rem',
        })}>
        Ad Analytics uses a weighted average of the IP frequency and the
        Household Frequency to calculate frequency. The calculation is (1.5*
        {'{'}Household Frequency{'}'} + {'{'}IP Frequency{'}'}) / 2.5. We only
        use the Household Frequency as long as the panel size is at least 10% of
        total downloads; otherwise, a small number of impressions can skew the
        whole result. The above is calculated with this methodology.
      </Card>
      <Card>
        <CardHeader>Frequency</CardHeader>

        <Element
          rules={() => ({
            color: 'rgb(102, 102, 102)',
            fontSize: '0.8125rem',
            marginBottom: '0.125rem',
          })}>
          Households
        </Element>
        <Element onMouseLeave={() => setFocusBar(null)}>
          <ResponsiveContainer width='100%' height={300}>
            <BarChart
              data={nodes}
              onMouseMove={(state) => {
                if (state.isTooltipActive) {
                  setFocusBar(state.activeTooltipIndex);
                } else {
                  setFocusBar(null);
                }
              }}>
              <CartesianGrid
                strokeDasharray='0 0'
                stroke='var(--border-default)'
                vertical={false}
              />
              <XAxis
                dataKey='frequency'
                type='number'
                padding={{left: 0, right: 45}}
              />
              <YAxis tickFormatter={(value) => comma(value)} />
              <Tooltip content={renderTooltip} cursor={{fill: 'transparent'}} />
              <Bar dataKey='total'>
                {nodes.map((entry, idx) => {
                  return (
                    <Cell
                      key={idx}
                      fill={focusBar === idx ? darken(GREEN, 0.4) : GREEN}
                    />
                  );
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Element>

        <Element
          rules={() => ({
            color: 'rgb(102, 102, 102)',
            fontSize: '0.8125rem',
            marginTop: '-1rem',
            paddingLeft: '2rem',
            textAlign: 'center',
          })}>
          Frequency
        </Element>
      </Card>
    </Element>
  );
};

export default CampaignOverviewFrequency;
