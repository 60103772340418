import {gql} from '@apollo/client';

export default gql`
  fragment CampaignLink on CampaignLinkObject {
    id
    name

    viewTotal
    clickTotal

    targetReachTotal
    targetDownloadTotal
    createdAt
  }
`;
