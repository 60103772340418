import {useState} from 'react';
import {useQuery} from '@apollo/client';
import ChartTime from '@core/components/ChartTime';
import PixelEventsChart from '@core/components/PixelEventsChart';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {Element} from '@core/style';
import {Alert} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import DetailsCard from '@core/ui/DetailsCard';
import {Submit} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import CampaignConsistency from '@analytics/components/campaigns/CampaignConsistency';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {useAttributionCampaignPreRunMutation} from '@analytics/graphql-api';
import ORGANIZATION_CAMPAIGN_DEBUG_QUERY from '@analytics/graphql-api/_old_queries/OrganizationCampaignDebugQuery';
import {CampaignRouteProps} from './_routes';

const CampaignDebugEvents = ({
  organization,
  campaign,
}: Omit<CampaignRouteProps, 'user'>) => {
  const {data, loading} = useQuery(ORGANIZATION_CAMPAIGN_DEBUG_QUERY, {
    variables: {
      organizationId: organization.id,
      id: campaign.id,
    },
  });

  if (loading) {
    return <Loading centered />;
  }

  const historical =
    data?.me?.organization?.campaign?.debug?.pixel?.historical ?? [];
  const hourly = data?.me?.organization?.campaign?.debug?.events?.hourly ?? [];

  const actions = hourly.reduce((array, hour) => {
    if (!array.includes(hour.action)) {
      array.push(hour.action);
    }
    return array;
  }, []);

  const eventsSeries = actions.reduce((obj, action) => {
    obj[action] = hourly
      .filter((hour) => hour.action == action)
      .map(({hour, count}) => ({
        x: hour,
        y: count,
      }));

    return obj;
  }, {});

  return (
    <>
      <CardHeader>
        Pixel Events (did we get pixel data from the client.)
      </CardHeader>
      <Card>
        <PixelEventsChart historical={historical} />
      </Card>
      <CardHeader>Attribution Hourly No long flat lines.</CardHeader>
      <Card>
        <ChartTime series={eventsSeries} height={300} intervalSelector />
      </Card>
    </>
  );
};

export function CampaignDebug({
  organization,
  campaign,
  user,
}: CampaignRouteProps) {
  const [errorRunningPre, setErrorRunningPre] = useState(false);
  const [runningPre, setRunningPre] = useState(false);
  const [successRunningPre, setSuccessRunningPre] = useState(false);
  const [attributionCampaignPreRun] = useAttributionCampaignPreRunMutation();

  const onClickFixCampaignImpressions = async () => {
    setErrorRunningPre(false);
    setSuccessRunningPre(false);
    setRunningPre(true);
    try {
      await attributionCampaignPreRun({
        variables: {
          input: {
            ranges: [
              {
                after: dayjs(campaign.startAt).format('YYYY-MM-DD'),
                before: dayjs(campaign.endAt).format('YYYY-MM-DD'),
                campaignId: campaign.id,
              },
            ],
          },
        },
      });
      setSuccessRunningPre(true);
    } catch (err) {
      setErrorRunningPre(true);
    }
    setRunningPre(false);
  };

  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <Element
        rules={() => ({
          display: 'grid',
          gridGap: '1rem',
          gridTemplateColumns: 'max-content max-content',
          margin: '0 1.5rem',
        })}>
        <DetailsCard
          data={[
            {title: 'Campaign ID', value: campaign.id},
            {
              title: 'Attribution Model',
              value:
                (campaign.advertiser?.organization?.mode ?? '').toLowerCase() ==
                'sql'
                  ? '2.0'
                  : '1.0',
            },
            {
              title: 'Last Attribution Run',
              value: dayjs(campaign.lastBuilt).utc().format('YYYY-MM-DD'),
            },
          ]}
        />

        <DetailsCard
          data={[
            {title: 'Computed Reach', value: comma(campaign.reachTotal ?? 0)},
            {
              title: 'Household Only Reach',
              value: comma(campaign.householdReach ?? 0),
            },
            {
              title: 'Noiseless Reach',
              value: comma(campaign.noiselessReach ?? 0),
            },
            {title: 'IAB Reach', value: comma(campaign.goodReach ?? 0)},
            {
              title: 'Verified Reach',
              value: comma(campaign.userReachTotal ?? 0),
            },
          ]}
        />
      </Element>
      <CampaignConsistency
        organizationId={organization.id}
        campaignId={campaign.id}
      />
      <CampaignDebugEvents organization={organization} campaign={campaign} />
      <Card
        css={`
          margin-top: 2rem;
        `}>
        <Submit
          variant='outlined'
          onClick={onClickFixCampaignImpressions}
          loading={runningPre}>
          Fix Campaign Impressions (Run Pre)
        </Submit>
        {successRunningPre && (
          <Alert
            severity='success'
            css={`
              margin-top: 1rem;
            `}>
            It worked!
          </Alert>
        )}
        {errorRunningPre && (
          <Alert
            severity='error'
            css={`
              margin-top: 1rem;
            `}>
            Now you've gone and done it... Pre failed, let's all hope it's
            fixable yet.
          </Alert>
        )}
      </Card>
    </>
  );
}
