import {useState} from 'react';
import {
  PaginationControls,
  PaginationDropdown,
  PaginationDropdownItem,
  PaginationDropdownLink,
  PaginationDropdownList,
  PaginationDropdownTrigger,
  TablePagination,
} from '@spotify-internal/encore-web';

const PAGE_SIZE_OPTIONS = [10, 20, 50];

interface PaginationTypes {
  offset: number;
  limit: number;
  handleSelect: (a: number) => void;
  handlePageSizeChange: (a: number) => void;
  total: number;
}

export const SalesOrderReviewPagination = ({
  offset,
  limit,
  handleSelect,
  handlePageSizeChange,
  total,
}: PaginationTypes) => {
  const handlePrev = () => handleSelect(Math.max(offset - limit, 0));
  const handleNext = () => handleSelect(Math.min(offset + limit, total));
  const rangeStart = Math.max(offset + 1, 1);
  const rangeEnd = Math.min(offset + limit, total);
  const [showDropdown, setShowDropdown] = useState(false);

  const isFirstPage = offset === 0;
  const isLastPage = offset + limit >= total;

  return (
    <div
      style={{
        alignItems: 'center',
        color: 'var(--text-muted)',
        display: 'flex',
        fontSize: '0.8125rem',
        justifyContent: 'flex-end',
        padding: '.5rem 0.625rem',
        userSelect: 'none',
      }}>
      <TablePagination>
        <div
          css={{
            marginRight: '4rem',
          }}>
          Rows per page:
          <PaginationDropdownTrigger
            overlay={
              showDropdown && (
                <PaginationDropdownList>
                  {PAGE_SIZE_OPTIONS.map((pageSize, index) => (
                    <PaginationDropdownItem key={index}>
                      <PaginationDropdownLink
                        onClick={() => handlePageSizeChange(pageSize)}
                        selected={limit === pageSize}>
                        {pageSize}
                      </PaginationDropdownLink>
                    </PaginationDropdownItem>
                  ))}
                </PaginationDropdownList>
              )
            }
            onShow={() => setShowDropdown(true)}
            onHide={() => setShowDropdown(false)}>
            <PaginationDropdown
              style={{
                color: 'var(--text-muted)',
                fontSize: '0.8125rem',
                fontWeight: '400',
              }}>
              {limit}
            </PaginationDropdown>
          </PaginationDropdownTrigger>
        </div>
        <div>
          <PaginationControls
            onIncrement={!isLastPage ? () => handleNext() : undefined}
            onDecrement={!isFirstPage ? () => handlePrev() : undefined}>
            <div
              style={{
                color: 'var(--text-muted)',
                fontSize: '0.8125rem',
              }}>
              {rangeStart} to {rangeEnd} of {total}
            </div>
          </PaginationControls>
        </div>
      </TablePagination>
    </div>
  );
};
