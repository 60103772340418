import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {FetchedCampaign} from '@analytics/graphql-api';
import {CampaignRouteProps} from '../../_routes';

export type CampaignPixelRouteProps = CampaignRouteProps & {
  pixel: NonNullable<FetchedCampaign['pixels']>[number];
};

export const useCampaignPixelRoutes = ({
  campaign,
  organization,
  pixelKey,
}: Pick<CampaignRouteProps, 'organization' | 'campaign'> & {
  pixelKey: string;
}) => {
  return useRoutes({
    baseUrl: createOrgPath(
      organization,
      `/campaigns/${campaign.slug}/pixels/${pixelKey}`
    ),
    basePath: '/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/pixels/:key',
    routes: [
      {
        key: 'overview',
        title: 'Overview',
        path: '',
        exact: true,
      },
      {
        key: 'referrers',
        title: 'Referrers',
        isActive: ({path}) => path.includes('/referrers'),
      },
      {
        key: 'leads',
        title: 'Leads',
        isAvailable: (campaign.leadTotal ?? 0) > 0,
      },
      {
        key: 'checkouts',
        title: 'Checkouts',
        isAvailable: (campaign.checkoutTotal ?? 0) > 0,
      },
      {
        key: 'urls',
        title: 'URLs',
      },
      {
        key: 'households',
        title: 'Households',
      },
    ],
  });
};
