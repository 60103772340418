import {
  ButtonTertiary,
  Chip,
  IconEnhanceActive,
  IconX,
  Popover,
  PopoverOnboarding,
  PopoverTrigger,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';
import {ProgressRings} from '@analytics/screens/account/AccountLayout';
import IntroPopover1 from './assets/onboarding-intro-1.svg';
import IntroPopover2 from './assets/onboarding-intro-2.svg';
import IntroPopover3 from './assets/onboarding-intro-3.svg';
import IntroPopover4 from './assets/onboarding-intro-4.svg';

const PopoverContainer = styled.div`
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PopoverContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const IntroHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const PopoverTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  align-self: flex-start;
  margin-bottom: 8px;
`;

const CloseButtonContainer = styled.button`
  background: rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  width: 32px;
  height: 32px;
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
`;

const ChipContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const contentAgency = [
  {
    title: 'Measure more on Spotify',
    body: 'Solutions to measure all ad formats across each stage of the funnel.',
    image: IntroPopover1,
  },
  {
    title: 'Build your own campaign',
    body: 'Audio attribution is in your hands with our new campaign builder.',
    image: IntroPopover2,
  },
  {
    title: 'Learn about your audience',
    body: 'Tap into insights about the listeners who stream your ads on Spotify.',
    image: IntroPopover3,
  },
  {
    title: 'Find answers or contact support',
    body: 'Learn more about Spotify Ad Analytics and find answers to frequently asked questions.',
    image: IntroPopover4,
  },
];

const IntroHeader = ({onClick, src}) => (
  <IntroHeaderContainer>
    <CloseButtonContainer onClick={onClick}>
      <IconX />
    </CloseButtonContainer>
    <img src={src} />
  </IntroHeaderContainer>
);

export default function OnboardingPopover({
  navItem,
  shouldShowOverlay,
  currentTutorialPage,
  onNextClick,
  onCloseClick,
  totalTutorialPages,
  slug,
}) {
  const title = contentAgency[currentTutorialPage].title;
  const body = contentAgency[currentTutorialPage].body;

  return (
    <PopoverTrigger
      placement={PopoverTrigger.right}
      hideCloseButton
      overlay={
        shouldShowOverlay && (
          <PopoverOnboarding
            id={`onboarding-popoverStep${currentTutorialPage}`}
            popoverTitle={
              <IntroHeader
                onClick={onCloseClick}
                src={contentAgency[currentTutorialPage].image}
              />
            }
            arrow={Popover.left}
            navStepper={
              <ProgressRings
                count={totalTutorialPages}
                selected={currentTutorialPage}
              />
            }
            footerButton={
              <div>
                <ButtonTertiary size='small' condensed onClick={onNextClick}>
                  {currentTutorialPage === totalTutorialPages - 1
                    ? 'Done'
                    : 'Next'}
                </ButtonTertiary>
              </div>
            }>
            <PopoverContainer>
              <ChipContainer>
                <Chip
                  iconLeading={IconEnhanceActive}
                  as='span'
                  selectedColorSet='mutedAccent'
                  style={{backgroundColor: '#9bf0e1'}}>
                  New
                </Chip>
              </ChipContainer>
              <PopoverTitle>{title}</PopoverTitle>
              <PopoverContent>{body}</PopoverContent>
            </PopoverContainer>
          </PopoverOnboarding>
        )
      }>
      {navItem}
    </PopoverTrigger>
  );
}
