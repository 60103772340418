import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import LinkDesigner from '@analytics/components/links/LinkDesigner';
import {campaignLinkURL} from '@analytics/components/nav/CampaignLinkNav';

const CampaignLinkBuild = ({organization, campaign, campaignLink}) => {
  const [complete, setComplete] = useState(false);

  if (complete) {
    return (
      <Redirect to={campaignLinkURL(organization, campaign, campaignLink)} />
    );
  }
  return (
    <LinkDesigner
      organization={organization}
      campaign={campaign}
      campaignLink={campaignLink}
      onCancel={() => setComplete(true)}
    />
  );
};

export default CampaignLinkBuild;
