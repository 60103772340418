import {useState} from 'react';
import {ProgressCircle, blue100, pink100} from '@spotify-internal/encore-web';
import {Redirect} from 'react-router-dom';
import {
  InsightsBanner,
  InsightsBarChart,
  InsightsCategoryTable,
  InsightsDisplayCard,
  InsightsGrid,
  InsightsLayout,
  InsightsTopAudiences,
} from '@analytics/components/audience-insights';
import {
  DAI_SUBTITLES,
  DAI_TABLES,
  DAI_TOP_AUDIENCES,
  DEFAULT_METRIC,
  EMPTY_AUDIENCES,
} from '@analytics/components/audience-insights/constants';
import {
  AudienceGroup,
  InsightsMetric,
} from '@analytics/components/audience-insights/types';
import {CampaignInsightsNav} from '@analytics/components/nav/CampaignInsightsNav';
import {useCampaignAudienceInsightsQuery} from '@analytics/graphql-api';
import {CampaignRouteProps, toCampaignUrl} from '../_routes';

export function InsightsDAI({
  organization,
  campaign,
}: Omit<CampaignRouteProps, 'user'>) {
  const [metric, setMetric] = useState<InsightsMetric>(DEFAULT_METRIC);

  const {data, loading, error} = useCampaignAudienceInsightsQuery({
    variables: {
      organizationId: organization.id,
      campaignId: campaign.id,
    },
  });

  if (loading) {
    return <ProgressCircle aria-label='daiInsightsLoading' size='medium' />;
  }

  if (error) {
    return <Redirect to={toCampaignUrl(organization, campaign)} />;
  }

  const campaignAudiences =
    data?.me?.organization?.campaign?.audienceInsights ?? EMPTY_AUDIENCES;

  return (
    <>
      <CampaignInsightsNav
        organization={organization}
        campaign={campaign}
        metric={metric}
        setMetric={setMetric}
      />

      <InsightsLayout>
        <InsightsBanner>
          Audience insights reports on-Spotify platform downloads only
        </InsightsBanner>
        <InsightsDisplayCard
          title='Audience overview'
          subtitle={DAI_SUBTITLES[metric].OVERVIEW}
          content={
            <InsightsTopAudiences
              audiences={campaignAudiences}
              audienceOrder={DAI_TOP_AUDIENCES}
              metric={metric}
            />
          }
        />
        <InsightsDisplayCard
          title='Podcast categories'
          subtitle={DAI_SUBTITLES[metric][AudienceGroup.PODCAST]}
          content={
            <InsightsBarChart
              audience={campaignAudiences[AudienceGroup.PODCAST]}
              color={pink100}
              metric={metric}
            />
          }
        />
        <InsightsGrid columns={2}>
          {DAI_TABLES.map(({group, title, categoryTitle}) => (
            <InsightsDisplayCard
              key={group}
              title={title}
              subtitle={DAI_SUBTITLES[metric][group]}
              content={
                <InsightsCategoryTable
                  audience={campaignAudiences[group]}
                  categoryTitle={categoryTitle}
                  metric={metric}
                />
              }
            />
          ))}
        </InsightsGrid>
        <InsightsDisplayCard
          title='Music genres'
          subtitle={DAI_SUBTITLES[metric][AudienceGroup.GENRE]}
          content={
            <InsightsBarChart
              audience={campaignAudiences[AudienceGroup.GENRE]}
              color={blue100}
              metric={metric}
            />
          }
        />
      </InsightsLayout>
    </>
  );
}
