import {Card} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {CampaignKind} from '@analytics/components/campaigns/_types';
import {CampaignCreateSteps} from '@analytics/components/campaigns/create/CampaignCreateSteps';
import {CampaignCreateForm} from '@analytics/components/campaigns/forms';
import {CampaignsRouteProps} from '../../_routes';

export function PublisherCampaignDetails({
  organization,
  match: {
    params: {companyId, kind},
  },
}: CampaignsRouteProps & {
  match: {
    params: {
      companyId: string;
      kind: CampaignKind;
    };
  };
}) {
  return (
    <>
      <CampaignCreateSteps active='campaign-details' />
      <Card>
        <Stack direction='column' spacing='var(--spacing-4)'>
          <Text>
            Campaigns are created in draft mode allowing you to build out the
            campaign over time. When you are ready, you can invite the
            advertiser to view the campaign.
          </Text>
          <CampaignCreateForm
            organization={organization}
            companyId={companyId}
            kind={kind}
          />
        </Stack>
      </Card>
    </>
  );
}
