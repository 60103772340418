import {gql} from '@apollo/client';

export default gql`
  query getBrandOrganizationFrequencyQuery(
    $id: ID!
    $after: Date
    $before: Date
    $publisherIds: [ID]
    $campaignIds: [ID]
  ) {
    me {
      id
      organization(id: $id) {
        id
        overview {
          impressions(
            publisherIds: $publisherIds
            campaignIds: $campaignIds
            after: $after
            before: $before
          ) {
            impressions
            reach

            frequency {
              max

              over {
                impressions
                reach
              }

              nodes {
                frequency
                total
              }
            }
          }
        }
      }
    }
  }
`;
