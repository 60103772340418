import {useEffect, useState} from 'react';
import {createOrgPath} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {FormFooter} from '@core/ui/FormElements';
import {Link} from '@core/ui/Link';
import PactsafeClickwrap from '@core/ui/PactsafeClickwrap';
import {AdvisorImage} from '@analytics/components/advisor/onboarding/AdvisorImage';
import {NoActivePixelWarning} from '@analytics/components/advisor/onboarding/brand/NoActivePixelWarning';
import {AdvisorRouteProps} from '../../_routes';
import {useNavigateToQuiz} from './_routes';

type Props = AdvisorRouteProps & {
  setCanOnboard: (_: boolean) => void;
};
export const SplashPage = ({user, organization, setCanOnboard}: Props) => {
  const goToQuiz = useNavigateToQuiz(organization);

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const canOnboard =
    acceptedTerms &&
    'hasActivePixel' in organization &&
    organization.hasActivePixel;

  useEffect(() => {
    setCanOnboard(canOnboard);
  }, [setCanOnboard, canOnboard]);

  return (
    <>
      <NoActivePixelWarning organization={organization} />
      <CardHeader>Welcome to Advisor!</CardHeader>
      <Card>
        <AdvisorImage />
        <p>
          Advisor is now available to all brands with a Spotify pixel installed!
          Advisor is a podcast discovery tool for brands. Advertisers use
          Advisor to help research and discover podcasts for their media plans.
          If you have not installed your pixel, please go to{' '}
          <Link to={createOrgPath(organization, `manage/pixels`)}>
            Manage &gt; Your Pixels
          </Link>{' '}
          to complete your installation.
        </p>

        <ul>
          <li>Set your target audience based on your campaigns.</li>
          <li>
            Discover brand-curated podcast audience insights through Nielsen and
            Experian.
          </li>
          <li>
            Compare affinity scores between podcasts to find shows that are most
            aligned with your existing audience.
          </li>
          <li>
            Update the sorting criteria and choose what's most important to your
            campaign by adding or removing any relevant show-level audience data
            columns.
          </li>
        </ul>
        <PactsafeClickwrap
          groupKey='group-syvpp4y3c'
          sendData
          signerId={user.email}
          onChecked={setAcceptedTerms}
        />
        <FormFooter>
          <Button
            disabled={!canOnboard}
            color='primary'
            buttonSize='lg'
            onClick={() => {
              if (!canOnboard) return;
              goToQuiz();
            }}>
            Take the Quiz
          </Button>
        </FormFooter>
      </Card>
    </>
  );
};
