import styled from 'styled-components';
import {Stack} from '@core/ui/Layout';
import {AD_TYPES} from './_helpers';
import {ActiveAdType} from './_types';

export function AdsNav({
  activeAdType,
  onClick,
  ...props
}: {
  activeAdType: ActiveAdType;
  onClick: (_: ActiveAdType) => void;
}) {
  return (
    <Stack alignItems='center' gap='var(--spacing-2)' {...props}>
      {AD_TYPES.map((type) => (
        <NavPill
          key={type}
          active={type === activeAdType}
          onClick={() => onClick(type)}>
          {type}
        </NavPill>
      ))}
    </Stack>
  );
}

const NavPill = styled.button<{active?: boolean}>`
  ${({active}) =>
    active
      ? `background: var(--bg-default-hover); color: var(--text-default);`
      : `background: var(--bg-default); color: var(--text-muted);`}
  border-radius: var(--radius-4);
  font-size: var(--font-size-body-4);
  font-weight: var(--font-weight-bold);
  height: var(--spacing-8);
  letter-spacing: 0.0725rem;
  line-height: var(--line-height-md);
  padding: 0 var(--spacing-5);
  text-transform: uppercase;
  :hover {
    color: var(--text-default);
    transition: all 0.2s;
  }
`;
