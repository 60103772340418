import {useHistory} from 'react-router-dom';
import {PublisherSelect} from '@core/components/PublisherSelect';
import {Card} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {CampaignKind} from '@analytics/components/campaigns/_types';
import {CampaignCreateSteps} from '@analytics/components/campaigns/create/CampaignCreateSteps';
import {CampaignsRouteProps} from '../../_routes';
import {toBrandCreateCampaignBasePath} from './_routes';

export function BrandSelectPublisherDetails({
  organization,
  match: {
    params: {kind},
  },
}: CampaignsRouteProps & {
  match: {
    params: {kind: CampaignKind};
  };
}) {
  const history = useHistory();

  const goToStep3 = ({id, name}: {id: string; name: string}) => {
    history.push(
      `${toBrandCreateCampaignBasePath(
        organization
      )}/${kind}/${id}/${encodeURIComponent(name)}/details`
    );
  };

  return (
    <>
      <CampaignCreateSteps active='pub-details' isBrand />
      <Card>
        <Stack direction='column' gap='var(--spacing-4)'>
          <Text>Please select the publisher for this campaign.</Text>
          <PublisherSelect organization={organization} onSelect={goToStep3} />
        </Stack>
      </Card>
    </>
  );
}
