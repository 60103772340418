import React, {useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {Element} from '@core/style';
import {TEXT_NORMAL} from '@core/style';
import {t} from '@core/texts';
import {ToastMessage} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Input, Label} from '@core/ui/Form';
import {Stack} from '@core/ui/Layout';
import CREATE_GENERIC_TAG from '@analytics/graphql-api/_old_mutations/CreateGenericTag';
import DELETE_GENERIC_TAG from '@analytics/graphql-api/_old_mutations/DeleteGenericTag';
import UPDATE_GENERIC_TAG from '@analytics/graphql-api/_old_mutations/UpdateGenericTag';

const COLORS = [
  {name: 'Dark Red', value: '#b71c1c'},
  {name: 'Red', value: '#f44336'},
  {name: 'Pink', value: '#e91e63'},
  {name: 'Purple', value: '#9c27b0'},
  {name: 'Deep Purple', value: '#673ab7'},
  {name: 'Indigo', value: '#3f51b5'},
  {name: 'Blue', value: '#1e88e5'},
  {name: 'Light Blue', value: '#29b6f6'},
  {name: 'Cyan', value: '#00bcd4'},
  {name: 'Teal', value: '#009688'},
  {name: 'Green', value: '#4caf50'},
  {name: 'Light Green', value: '#8bc34a'},
  {name: 'Lime', value: '#cddc39'},
  {name: 'Yellow', value: '#fdd835'},
  {name: 'Amber', value: '#ffa000'},
  {name: 'Orange', value: '#ef6c00'},
  {name: 'Deep Orange', value: '#ff5722'},
  {name: 'Brown', value: '#795548'},
  {name: 'Grey', value: '#9e9e9e'},
  {name: 'Blue Grey', value: '#607d8b'},
  {name: 'Dark', value: '#263238'},
];

const EditOrganizationTag = ({
  organization,
  setTitle,
  genericTag,
  onAddOrganizationTag,
  onUpdateOrganizationTag,
  onRemoveOrganizationTag,
}) => {
  const [createGenericTag] = useMutation(CREATE_GENERIC_TAG);
  const [updateGenericTag] = useMutation(UPDATE_GENERIC_TAG);
  const [deleteGenericTag] = useMutation(DELETE_GENERIC_TAG);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formParams, setFormParams] = useState({
    color: genericTag.id ? genericTag.color : COLORS[0].value,
    title: genericTag.id ? genericTag.title : '',
  });
  const inputRef = React.useRef();
  setTitle(genericTag.id ? 'Edit Tag' : 'New Tag');

  useEffect(() => {
    requestAnimationFrame(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    });
  }, []);

  const createTag = async () => {
    setLoading(true);
    try {
      const resp = await createGenericTag({
        variables: {
          input: {
            organizationId: organization.id,
            color: formParams.color,
            title: formParams.title,
          },
        },
      });
      const _genericTag = resp.data.createGenericTag.genericTag;
      delete _genericTag.organization;
      setLoading(false);
      onAddOrganizationTag(_genericTag);
    } catch (err) {
      console.warn(err);
      setError(err);
      setLoading(false);
    }
  };

  const updateTag = async () => {
    setLoading(true);
    try {
      const input = {
        id: genericTag.id,
        color: formParams.color,
        title: formParams.title,
      };
      await updateGenericTag({
        variables: {input},
      });
      setLoading(false);
      onUpdateOrganizationTag(input);
    } catch (err) {
      console.warn(err);
      setError(err);
      setLoading(false);
    }
  };

  const onRemoveTag = async () => {
    setLoading(true);
    try {
      await deleteGenericTag({
        variables: {
          input: {
            id: genericTag.id,
          },
        },
      });
      setLoading(false);
      onRemoveOrganizationTag(genericTag.id);
    } catch (err) {
      console.warn(err);
      setError(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Input
        domRef={inputRef}
        label='Tag title'
        defaultValue={genericTag.title}
        onInput={(evt) => {
          setError(null);
          setFormParams(
            Object.assign({}, formParams, {title: evt.target.value.trim()})
          );
        }}
        withMargin
      />
      {error ? (
        <ToastMessage alertType='error' rules={() => ({margin: '0 0 1rem'})}>
          {t('tags.formError', {errorMessage: error.message}).description}
        </ToastMessage>
      ) : null}
      <Label>Pick a color</Label>
      <Element
        rules={() => ({
          display: 'grid',
          gridGap: '0.3125rem',
          gridTemplateColumns: 'repeat(7, 1fr)',
          marginBottom: '1rem',
        })}>
        {COLORS.map(({value, title}) => (
          <Element
            style-active={value === formParams.color}
            title={title}
            onClick={() => {
              setFormParams(Object.assign({}, formParams, {color: value}));
            }}
            rules={({active}) => ({
              background: value,
              border: `2px solid ${active ? TEXT_NORMAL : 'transparent'}`,
              borderRadius: '3.125rem',
              cursor: 'pointer',
              height: '2rem',
              transform: active ? 'scale(1.12)' : null,
              transition: 'transform 0.1s linear',
              width: '2rem',
              ':hover': {
                transform: 'scale(1.12)',
                borderColor: TEXT_NORMAL,
              },
            })}
          />
        ))}
      </Element>
      <Stack
        direction='row'
        justifyContent='space-between'
        css={`
          margin-top: 1.5rem;
        `}>
        <Button
          color='inverse'
          disabled={loading}
          onClick={() => {
            if (genericTag.id) {
              updateTag();
            } else {
              createTag();
            }
          }}>
          {genericTag.id ? 'Save' : 'Create'}
        </Button>
        {genericTag.id ? (
          <Button
            disabled={loading}
            color='error'
            variant='outlined'
            onClick={onRemoveTag}>
            Remove
          </Button>
        ) : null}
      </Stack>
    </>
  );
};

export default EditOrganizationTag;
