import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Companies from './Companies';
import CompanyIndex from './company';

const CompaniesIndex = ({user, organization}) => {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/research/brands'
        user={user}
        organization={organization}
        component={Companies}
      />
      <PropsRoute
        path='/:part(a|s|b|p)/:slug/research/brands/:companyId'
        user={user}
        organization={organization}
        component={CompanyIndex}
      />
    </Switch>
  );
};

export default CompaniesIndex;
