import React, {FC} from 'react';
import {ApprovalsStateName} from './helpers';

type ApprovalsStateBadgeProps = {
  state: ApprovalsStateName;
};

const ApprovalsStateBadge: FC<ApprovalsStateBadgeProps> = ({
  state,
}): JSX.Element => {
  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        textTransform: 'capitalize',
      }}>
      <div
        css={{
          border: `4px solid ${
            state === 'APPROVED'
              ? 'var(--green)'
              : state === 'REJECTED'
              ? 'var(--red-orange)'
              : 'var(--border-default)'
          }`,
          borderRadius: '50%',
          height: '1rem',
          marginRight: '0.625rem',
          width: '1rem',
        }}
      />
      {state.toLowerCase()}
    </div>
  );
};

export default ApprovalsStateBadge;
