import {graphql} from '@apollo/client/react/hoc';
import {createOrgPath} from '@core/lib/organizations';
import {Card, CardHeader} from '@core/ui/Content';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';
import {CompanySimilarByFeedTable} from '@analytics/components/research/CompaniesTable';
import CompanySimilarByFeedQuery from '@analytics/graphql-api/_old_queries/research/CompanySimilarByFeedQuery';

const SimilarByFeed = ({organization, company, data: {loading, error, me}}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const {similarByFeed} = me.organization.research.company;

  return (
    <>
      <CardHeader>Similar companies by podcast</CardHeader>
      <Card>
        <div
          css={`
            display: flex;
          `}>
          <p
            css={`
              flex: 1;
            `}>
            The following companies have advertised on the same podcasts as{' '}
            {company.name}.
          </p>
          <div>
            <Link
              to={createOrgPath(
                organization,
                `/research/brands/${company.id}/similar/episodes`
              )}
              css={`
                color: var(--text-muted);
                font-weight: 500;
                text-decoration: none;
                :hover {
                  color: var(--blue);
                }
              `}>
              View by episodes
            </Link>
          </div>
        </div>
        <CompanySimilarByFeedTable
          organization={organization}
          company={company}
          companies={similarByFeed}
          orderBy='-feedsCount'
        />
      </Card>
    </>
  );
};

export default graphql(CompanySimilarByFeedQuery, {
  options: ({organization, company: {id}}) => ({
    variables: {
      organizationId: organization.id,
      id,
      limit: 100,
    },
  }),
})(SimilarByFeed);
