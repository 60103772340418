import {Controller} from 'react-hook-form';
import {FormHelperText} from '@core/ui/Form New';
import {Label} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import Select from '@core/ui/Select';
import {InfoTooltip} from '@core/ui/Tooltip';
import {FieldProps} from '../helpers';
import {Link} from '../shared';

export function AttributesField({control, watch}: FieldProps) {
  return (
    <Stack direction='column'>
      <Label
        htmlFor='attributes'
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
          gap: '0.375rem',
        })}>
        Households or IP Reach
        <InfoTooltip
          title='Households or IP Reach'
          description='Unique households are identified based on IP and UserAgent via third-party digital identity graphs.'
        />
      </Label>
      <Controller
        name='exportParameters.attr'
        control={control}
        render={({field}) => (
          <Select
            key={watch('typeId')}
            buttonProps={{id: 'attributes'}}
            defaultValue={field.value ?? undefined}
            items={[
              {title: 'Households ', value: 'household_id'},
              {title: 'IPs', value: 'ip'},
            ]}
            onSelect={({value}) => field.onChange(value)}
            outline
            placeholder='Attribute'
          />
        )}
      />
      <FormHelperText css={{display: 'flex'}}>
        <Link href='https://help.podsights.com/podsights-methodology'>
          Learn more
        </Link>
        about our methodology.
      </FormHelperText>
    </Stack>
  );
}
