import {capitalize} from '@core/lib/filters';
import {isOrgAgency, isOrgBrand} from '@core/lib/organizations';
import {OrganizationKind} from '@analytics/graphql-api';
import {GroupBy} from '../../_types';

export type DropdownItem = {
  name: string;
  value: GroupBy;
};

export const getDisplayValue = (defaultValue: GroupBy[]) => {
  if (!defaultValue.length) return 'Grouping';
  if (defaultValue.length === 1)
    return `Grouping: ${capitalize(defaultValue[0])}`;
  return `Grouping (${defaultValue.length})`;
};

export const getDropdownItems = ({
  kind,
}: {
  kind: OrganizationKind;
}): DropdownItem[] => {
  if (isOrgAgency({kind})) return [...advertiser, ...publisher];
  if (isOrgBrand({kind})) return publisher;

  return advertiser;
};

/* -------------------------------------------------------------------- */

const advertiser: DropdownItem[] = [
  {name: 'Group by Advertiser', value: 'advertiser'},
];
const publisher: DropdownItem[] = [
  {name: 'Group by Publisher', value: 'publisher'},
];
