import {Redirect, Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import researchRoutes from '@analytics/lib/routes/researchRoutes';
import Research from './Research';
import CompaniesIndex from './companies';
import FeedsIndex from './feeds';
import PublishersIndex from './publishers';
import RankingsIndex from './rankings';
import ReviewersIndex from './reviewers';

type Props = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
  match: {url: string};
};

export function ResearchIndex({user, organization, match: {url}}: Props) {
  const {renderRoutes} = useRoutes({
    baseUrl: url,
    basePath: '/:part(a|s|b|p)/:slug/research',
    routes: researchRoutes(),
  });

  if (!user.isAdmin && !user.reviewer) {
    return <Redirect to={createOrgPath(organization)} />;
  }

  return (
    <>
      <Switch>
        {renderRoutes({
          components: {
            research: Research,
            brands: CompaniesIndex,
            podcasts: FeedsIndex,
            rankings: RankingsIndex,
            publishers: PublishersIndex,
            reviewers: ReviewersIndex,
          },
          extraProps: {
            user,
            organization,
          },
          redirects: [
            {
              from: '*',
              to: '',
            },
          ],
        })}
      </Switch>
    </>
  );
}
