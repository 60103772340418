import {gtag} from '@core/lib/gtag';
import {t} from '@core/texts';
import {Stack} from '@core/ui/Layout';
import {Switch} from '@core/ui/Switch';
import {Text} from '@core/ui/Text';
import useSettings from './useSettings';

const DATA_FILTERS_MAP = [
  {
    propName: 'modelled',
    gaLabel: 'modeled_attribution',
    texts: t('settings.data.modelled'),
  },
  {
    propName: 'spendByImpressionsDelivered',
    gaLabel: 'spend_by_impressions_delivered',
    texts: t('settings.data.spendByImpressionsDelivered'),
  },
  {
    propName: 'impressionsByPlacements',
    gaLabel: 'impressions_by_placements',
    texts: t('settings.data.impressionsByPlacements'),
  },
] as const;

const SettingsDataFilters = () => {
  const [settings, dispatch] = useSettings();

  return (
    <>
      <div
        css={`
          font-weight: 500;
          margin: 0 0 var(--spacing-8);
        `}>
        How Ad Analytics handles data:
      </div>
      <Stack direction='column' gap={4}>
        {DATA_FILTERS_MAP.map(
          ({propName, gaLabel, texts: {title, longDescription}}) => {
            return (
              <div key={propName}>
                <label
                  css={`
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    gap: var(--spacing-2);
                  `}>
                  <Switch
                    color='primary'
                    checked={settings[propName]}
                    onChange={(evt) => {
                      const value = evt.target.checked;

                      dispatch({
                        type: 'UPDATE_SETTINGS',
                        settings: {[propName]: value},
                      });
                      gtag('event', gaLabel, {
                        event_category: 'settings',
                        event_label: `${gaLabel}_${value ? 'true' : 'false'}`,
                      });
                    }}
                  />
                  {title}
                </label>
                <Text
                  variant='body2'
                  color='muted'
                  css={`
                    margin-top: var(--spacing-2);
                  `}>
                  {longDescription}
                </Text>
              </div>
            );
          }
        )}
      </Stack>
    </>
  );
};

export default SettingsDataFilters;
