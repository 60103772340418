export const LoadingImage = (
  <svg
    width='69'
    height='59'
    viewBox='0 0 69 59'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <style>
      {`
        @keyframes loadingFromEmptyBar {
          0%, 100% { height: 14.75px; y: 44.25px; }
          50% { height: 59px; y: 0px; }
        }
        @keyframes loading2init {
          0%,  { height: 29.5px; y: 29.5px; }
          100% { height: 59px; y: 0px; }
        }
        @keyframes loading3init {
          0% { height: 44.25px; y: 14.75px; }
          100% { height: 59px; y: 0px; }
        }
        @keyframes loadingFromFullBar {
          0%, 100% { height: 59px; y: 0px; }
          50% { height: 14.75px; y: 44.25px; }
        }
      `}
    </style>
    <rect
      style={{
        animation: 'loadingFromEmptyBar 2s ease-in-out infinite',
        animationDelay: '0s',
      }}
      x='0.5'
      y='44.25'
      width='14'
      height='14.75'
      rx='7'
      fill='#F573A0'
    />
    <rect
      style={{
        animation:
          'loading2init 0.75s ease-in-out, loadingFromFullBar 2s ease-in-out infinite',
        animationDelay: '0s, 0.75s', // delay for the respective animations
      }}
      x='18.5'
      y='29.5'
      width='14'
      height='29.5'
      rx='7'
      fill='#509BF5'
    />

    <rect
      style={{
        animation:
          'loading3init 0.5s ease-in-out, loadingFromFullBar 2s ease-in-out infinite',
        animationDelay: '0s, 0.5s',
      }}
      x='36.5'
      y='14.75'
      width='14'
      height='44.25'
      rx='7'
      fill='#2D46B9'
    />
    <rect
      style={{
        animation: 'loadingFromFullBar 2s ease-in-out infinite',
        animationDelay: '0.25s',
      }}
      x='54.5'
      y='0'
      width='14'
      height='59'
      rx='7'
      fill='#9BF0E1'
    />
  </svg>
);
