import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import Loading from '@core/ui/Loading';
import {SpotifyLiftReportNav} from '@analytics/components/nav/SpotifyLiftReportNav';
import {
  FetchedSpotifyLiftReport,
  useSpotifyBrandLiftReportQuery,
} from '@analytics/graphql-api';
import {MeasureRouteProps} from '../../..';
import {SpotifyLiftReport} from './SpotifyLiftReport';
import {getSpotifyLiftReportRoutes} from './_routes';

type Props = MeasureRouteProps & {
  match: {
    params: {
      liftReportId: string;
    };
  };
};

export function SpotifyLiftReportIndex({
  organization,
  user,
  match: {
    params: {liftReportId},
  },
}: Props) {
  const {data, loading, error} = useSpotifyBrandLiftReportQuery({
    variables: {
      id: liftReportId,
      organizationId: organization.id,
    },
  });

  const {renderRoutes, routes} = getSpotifyLiftReportRoutes({
    organization,
    liftReportId,
  });

  if (loading) {
    return <Loading centered fixed />;
  }

  if (error) {
    return (
      <Redirect
        to={createOrgPath(organization, 'measure/lift/spotify/reports')}
      />
    );
  }

  const liftReport =
    data?.me?.organization?.spotifyBrandLiftReport ??
    ({} as FetchedSpotifyLiftReport);

  return (
    <>
      <SpotifyLiftReportNav
        organization={organization}
        user={user}
        liftReport={liftReport}
        routes={routes}
      />
      <Switch>
        {renderRoutes({
          components: {
            liftReport: SpotifyLiftReport,
          },
          extraProps: {
            organization,
            user,
            liftReport,
          },
        })}
      </Switch>
    </>
  );
}
