import {graphql} from '@apollo/client/react/hoc';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import {PublisherNav} from '@analytics/components/nav/ResearchNav';
import PublisherDetailQuery from '@analytics/graphql-api/_old_queries/research/PublisherDetailQuery';
import Publisher from './Publisher';
import PublisherCompanies from './PublisherCompanies';
import PublisherContact from './PublisherContact';

const PublisherIndex = ({user, organization, data: {loading, me}}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const {publisher} = me.organization.research;

  return (
    <>
      <PublisherNav organization={organization} publisher={publisher} />
      <Switch>
        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/research/publishers/:publisherSlug'
          user={user}
          organization={organization}
          publisher={publisher}
          component={Publisher}
        />
        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/research/publishers/:publisherSlug/contact'
          user={user}
          organization={organization}
          publisher={publisher}
          component={PublisherContact}
        />

        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/research/publishers/:publisherSlug/brands'
          user={user}
          organization={organization}
          publisher={publisher}
          component={PublisherCompanies}
        />
      </Switch>
    </>
  );
};

export default graphql(PublisherDetailQuery, {
  options: ({
    organization,
    match: {
      params: {publisherSlug},
    },
  }) => ({
    variables: {
      organizationId: organization.id,
      publisherSlug: publisherSlug,
    },
  }),
})(PublisherIndex);
