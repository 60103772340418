import {graphql} from '@apollo/client/react/hoc';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import ReviewerQuery from '@analytics/graphql-api/_old_queries/research/ReviewerQuery';
import Reviewer from './Reviewer';

const ReviewerIndex = ({user, organization, data: {loading, me}}) => {
  if (loading) {
    return <Loading centered />;
  }

  const {reviewer} = me.organization.research;

  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/research/reviewers/:reviewerId'
        user={user}
        organization={organization}
        reviewer={reviewer}
        component={Reviewer}
      />
    </Switch>
  );
};

export default graphql(ReviewerQuery, {
  options: ({
    organization: {id},
    match: {
      params: {reviewerId},
    },
  }) => ({
    variables: {
      organizationId: id,
      reviewerId,
    },
  }),
})(ReviewerIndex);

// const ReviewerIndex = ({ user }) => {
//   return (
//     <Card>
//       <p>Hi {user.firstName},</p>
//
//       <p>
//         We had to take this page down temporarily because the query to generate
//         your counts is hurting the site performance as a whole. We will bring
//         this back as soon as we can
//       </p>
//
//       <p>
//         Best,
//         <br />
//         Podsights
//       </p>
//     </Card>
//   );
// };

// export default ReviewerIndex;
