import {gql} from '@apollo/client';

export default gql`
  mutation updateCampaignState($input: UpdateCampaignStateInput!) {
    authed {
      success
    }
    updateCampaignState(input: $input) {
      campaign {
        id
        name
        slug
        state
        createdAt
      }
    }
  }
`;
