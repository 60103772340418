export default function getRuleScore() {
  var ruleOrder = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var pseudo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  if (ruleOrder.length === 0 || pseudo.length === 0) {
    return 0;
  }

  return ruleOrder.indexOf(ruleOrder.find(function (regex) {
    return pseudo.match(regex) !== null;
  })) + 1;
}