import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {stripHTML, truncateWords} from '@core/lib/words';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {FeedImage} from '@core/ui/Image';
import Loading from '@core/ui/Loading';
import CompanyFeedsQuery from '@analytics/graphql-api/_old_queries/research/CompanyFeedsQuery';

const FeedsTable = ({company, organization, data: {loading, me}}) => {
  if (loading) {
    return <Loading centered />;
  }

  const {feeds} = me.organization.research.company;

  const columns = [
    {
      title: 'Feed',
      accessor: 'title',
      type: 'string',
      Cell: ({data}) => {
        return (
          <Element
            rules={() => ({
              display: 'flex',
              minWidth: '15.5rem',
            })}>
            <Element
              rules={() => ({
                flex: '0 0 4.6875rem',
                marginRight: '1rem',
                marginTop: '0.25rem',
              })}>
              <FeedImage feed={data} width={100} />
            </Element>
            <Element rules={() => ({flex: 1})}>
              <Element
                rules={({theme}) => ({
                  color: theme.textPrimary,
                  fontWeight: 500,
                })}>
                {data.title}
              </Element>
              <Element
                rules={({theme}) => ({
                  color: theme.textTertiary,
                  fontSize: '.875rem',
                  lineHeight: 1.5,
                })}>
                {truncateWords(
                  stripHTML(data.subtitle || data.summary || ''),
                  35
                )}
              </Element>
            </Element>
          </Element>
        );
      },
    },
    {
      title: 'Episodes',
      accessor: 'episodesCount',
      type: 'number',
    },
    {
      title: 'Avg. Rank',
      accessor: 'avgRank',
      type: 'number',
    },
  ];
  return (
    <DataTable
      searchPlaceholder='Search podcast'
      searchKeys={['title']}
      columns={columns}
      data={feeds}
      orderBy='-episodesCount'
      onClickRowPath={({id}) => {
        return `/research${
          company ? `/brands/${company.id}` : ''
        }/podcasts/${id}`;
      }}
    />
  );
};

export default graphql(CompanyFeedsQuery, {
  options: ({company, organization, limit = null}) => ({
    variables: {
      id: company.id,
      organizationId: organization.id,
      limit,
    },
  }),
})(FeedsTable);
