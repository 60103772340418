import EpisodePlayer from '@core/components/EpisodePlayer';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {Link} from '@core/ui/Link';
import Placement from '@analytics/components/Placement';
import {CampaignEpisodeOmniChart} from '@analytics/components/campaigns/charts';
import {CampaignEmbeddedEpisodeStats} from '@analytics/components/campaigns/stats/CampaignEmbeddedEpisodeStats';
import {episodeURL} from '@analytics/components/nav/ResearchNav';

const CampaignEpisode = ({
  user,
  organization,
  campaign,
  campaignPodcast,
  campaignEpisode,
}) => {
  const feed = campaignPodcast?.feed;
  const episode = campaignEpisode?.episode;
  const placements = campaignEpisode?.placements ?? [];
  const otherPlacements = campaignEpisode?.otherPlacements ?? [];

  const episodePlacements = placements
    .slice(0)
    .concat(otherPlacements.slice(0));

  return (
    <>
      {user.isAdmin && !campaignEpisode?.verified && (
        <ToastMessage>
          {otherPlacements.length ? (
            <>
              The Ad Analytics review team did not find an ad for{' '}
              <b>{campaign.advertiser.name}</b> in this episode but did find{' '}
              <b>{otherPlacements.length}</b> other placements. Pulling ads from
              an episode is not an exact science. Ad Analytics uses a
              combination of transcripts, machine learning, and human reviewers
              to find ads, but sometimes we may miss one. Please reach out to{' '}
              <b>{campaign.organization.name}</b> to confirm the timestamp of
              your placement in this episode.
            </>
          ) : (
            <>
              This episode is pending an aircheck by the Ad Analytics team.{' '}
              {user.isAdmin ? (
                <Link to={`${episodeURL(organization, feed, episode)}/review`}>
                  Review Episode
                </Link>
              ) : (
                ''
              )}
            </>
          )}
        </ToastMessage>
      )}
      <CampaignEmbeddedEpisodeStats
        campaign={campaign}
        campaignEpisode={campaignEpisode}
      />
      {placements.length > 0 && (
        <>
          <CardHeader>Ads</CardHeader>
          <Card>
            {placements.map((placement) => (
              <Placement
                organization={organization}
                feed={campaignPodcast.feed}
                episode={episode}
                key={placement.id}
                placement={placement}
              />
            ))}
          </Card>
        </>
      )}
      <Card css={{marginTop: '2.375rem'}}>
        <CampaignEpisodeOmniChart
          organization={organization}
          campaign={campaign}
          campaignEpisode={campaignEpisode}
        />
      </Card>
      <Card>
        <CardHeader>Episode</CardHeader>
        <EpisodePlayer
          episode={episode}
          placements={episodePlacements}
          height={75}
        />
      </Card>
    </>
  );
};

export default CampaignEpisode;
