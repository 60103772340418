import React from 'react';
import {formatDate} from '@core/lib/time';
import {Element} from '@core/style';
import FeedImage from './FeedImage';

interface IFutureEpisodeImage {
  feed: any;
  width: number;
  expectedPublished?: string;
}

const FutureEpisodeImage = ({
  expectedPublished,
  feed,
  width,
}: IFutureEpisodeImage): JSX.Element => (
  <Element
    rules={() => ({
      display: 'grid',
      overflow: 'hidden',
      position: 'relative',
    })}>
    <FeedImage feed={feed} width={width} />
    <Element
      rules={() => ({
        background: 'rgba(0,0,0,0.5)',
        height: '150%',
        left: '40%',
        position: 'absolute',
        top: '40%',
        transform: 'rotate(45deg)',
        width: '150%',
      })}
    />
    <Element
      rules={() => ({
        bottom: '0.25rem',
        color: '#fff',
        fontSize: '0.75rem',
        position: 'absolute',
        right: '0.25rem',
      })}>
      {formatDate(expectedPublished)}
    </Element>
  </Element>
);

export default FutureEpisodeImage;
