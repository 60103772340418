import {useQuery} from '@apollo/client';
import {createOrgPath} from '@core/lib/organizations';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {Dummy, Img} from '@core/ui/Image';
import Loading from '@core/ui/Loading';
import {FetchedOrganization, FetchedPodcasts} from '@analytics/graphql-api';
import PODCASTS_QUERY from '@analytics/graphql-api/_old_queries/PodcastsQuery';

const PodcastsTable = ({
  organization,
  podcasts,
}: {
  organization: FetchedOrganization;
  podcasts: FetchedPodcasts;
}) => {
  const columns = [
    {
      title: 'Podcast',
      accessor: 'title',
      type: 'string',
      Cell: ({data: {title, description, image}}) => {
        return (
          <Element rules={() => ({display: 'flex', minWidth: '12.5rem'})}>
            <Element
              rules={() => ({
                flex: '0 0 4.6875rem',
                marginRight: '1rem',
                maxHeight: '4.6875rem',
              })}>
              {image ? <Img image={image} width={150} /> : <Dummy />}
            </Element>
            <Element rules={() => ({flex: 1})}>
              <Element
                rules={({theme}) => ({
                  color: theme.textPrimary,
                  fontWeight: 500,
                })}>
                {title}
              </Element>
              <Element
                tag='p'
                rules={({theme}) => ({
                  fontSize: '.875rem',
                  color: theme.textTertiary,
                })}>
                {description}
              </Element>
            </Element>
          </Element>
        );
      },
    },
  ];

  const data = podcasts
    .slice(0)
    .map(
      ({
        id,
        linkTo,
        feed: {title, description, image, episodesCount, lastPublished},
      }) => ({
        id,
        title,
        description,
        linkTo,
        image,
        episodesCount,
        lastPublished,
      })
    );

  return (
    <DataTable
      searchKeys={['title']}
      columns={columns}
      orderBy='-lastPublished'
      data={data}
      searchPlaceholder='Search podcast'
      emptyMessageText='No podcasts found.'
      onClickRowPath={({id, linkTo}) => {
        return linkTo ? linkTo : createOrgPath(organization, `/podcasts/${id}`);
      }}
    />
  );
};

const PodcastsTableWrapper = ({
  organization,
  podcasts,
}: {
  organization: FetchedOrganization;
  podcasts?: FetchedPodcasts;
}) => {
  if (podcasts && podcasts.length > 0) {
    return <PodcastsTable organization={organization} podcasts={podcasts} />;
  }

  const {data, error, loading} = useQuery(PODCASTS_QUERY, {
    variables: {
      id: organization.id,
    },
  });

  if (loading) {
    return <Loading centered />;
  }

  return (
    <PodcastsTable
      organization={organization}
      podcasts={data?.me?.organization?.podcasts ?? []}
    />
  );
};

export default PodcastsTableWrapper;
