import {Element, getColor} from '@core/style';
import {MiniPlayer} from '../AudioMiniPlayer/AudioMiniPlayer';

const addColor = (placements) => {
  return placements.slice(0).map((placement, i) => {
    return Object.assign({}, placement, {color: getColor(i)});
  });
};

const EpisodePlayer = ({episode, placements, height, rules}) => {
  const playerPlacements = addColor(
    placements ? placements : episode.placements ? episode.placements : []
  );

  return (
    <Element rules={[() => ({margin: '1rem 0'}), rules]}>
      <MiniPlayer
        enclosure={episode.enclosure}
        height={height || 100}
        placements={playerPlacements}
      />
    </Element>
  );
};

export {addColor};

export default EpisodePlayer;
