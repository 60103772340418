import React, {useEffect, useRef} from 'react';
import {Element} from '@core/style';

const DesignPreview = ({previewURL, config}) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current) {
      const {
        favicon,
        image,
        backgroundColor,
        fontColor,
        primaryColor,
        secondaryColor,
        title,
        description,
      } = config;

      iframeRef.current.contentWindow.postMessage(
        {
          type: 'podlink:customColors/update',
          customColors: {
            backgroundColor,
            fontColor,
            primaryColor,
            secondaryColor,
          },
        },
        '*'
      );
      iframeRef.current.contentWindow.postMessage(
        {
          type: 'podlink:publisher/updateProperties',
          properties: {
            title,
            description,
            faviconUrl: favicon ? favicon.src : null,
            imageUrl: image ? image.src : null,
          },
        },
        '*'
      );
    }
  }, [config]);

  return (
    <Element
      rules={({theme}) => ({height: '100%', background: theme.bgTertiary})}>
      <Element
        rules={() => ({
          maxWidth: '450px',
          margin: '0 auto',
          height: '100%',
        })}>
        <Element
          domRef={iframeRef}
          tag='iframe'
          frameBorder='0'
          rules={() => ({
            width: '100%',
            height: '100%',
          })}
          src={previewURL}
        />
      </Element>
    </Element>
  );
};

export default DesignPreview;
