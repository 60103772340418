import React, {useEffect, useImperativeHandle, useReducer} from 'react';
import {Element} from '@core/style';
import {AudioWaveformCanvas} from '../AudioWaveform';

const TrackElement = React.forwardRef(
  ({containerRef, onChange, rules, width, ...props}, forwardedRef) => {
    const thisRef = React.useRef();
    const [s, setState] = useReducer(
      (state, newState) => {
        return {...state, ...newState};
      },
      {
        containerRect: null,
        distanceCenter: 0,
        dragging: false,
        startX: 0,
        thisRect: null,
        x: 0,
      }
    );

    useEffect(() => {
      if (s.dragging) {
        window.addEventListener('mouseup', onBodyMouseUp);
        window.addEventListener('mousemove', onBodyMouseMove);
      }

      return () => {
        window.removeEventListener('mouseup', onBodyMouseUp);
        window.removeEventListener('mousemove', onBodyMouseMove);
      };
    }, [s.dragging]);

    const onMouseDown = (evt) => {
      if (!s.dragging) {
        document.body.style.userSelect = 'none';

        const containerRect = containerRef.current.getBoundingClientRect();
        const thisRect = thisRef.current.getBoundingClientRect();
        const startX = evt.pageX - containerRect.left;
        const distanceCenter = evt.pageX - thisRect.left;

        setState({
          containerRect,
          distanceCenter,
          dragging: true,
          startX,
          thisRect,
        });
      }
    };

    const onBodyMouseMove = (evt) => {
      if (s.dragging) {
        const dx = evt.pageX - s.containerRect.left - s.startX;
        const x = Math.min(
          Math.max(s.startX + dx - s.distanceCenter, 0),
          s.containerRect.width - s.thisRect.width
        );
        const pct = (x * 100) / s.containerRect.width;
        if (typeof onChange === 'function') {
          onChange({pct});
        }
        setState({x});
      }
    };

    const onBodyMouseUp = () => {
      if (s.dragging) {
        document.body.style.userSelect = '';
        thisRef.current.blur();
        setState({dragging: false});
      }
    };

    useImperativeHandle(forwardedRef, () => ({
      updatePosition: ({pct}) => {
        if (!s.dragging && containerRef.current) {
          const _x =
            (pct * containerRef.current.getBoundingClientRect().width) / 100;
          setState({x: _x});
        }
      },
    }));

    return (
      <Element
        domRef={thisRef}
        tabindex='-1'
        onMouseDown={onMouseDown}
        onBlur={onBodyMouseUp}
        onDragStart={(evt) => evt.preventDefault()}
        onContextMenu={(evt) => {
          if (s.dragging) {
            evt.preventDefault();
            onBodyMouseUp();
          }
        }}
        style-dragging={s.dragging}
        style={{
          transform: `translateX(${s.x}px)`,
          width: `${width}%`,
        }}
        rules={[
          rules,
          ({dragging}) => ({
            background: 'rgba(55, 55, 55, .075)',
            border: '2px solid rgba(55, 55, 55, .2)',
            borderRadius: '4px',
            bottom: 0,
            cursor: dragging ? '-webkit-grabbing' : '-webkit-grab',
            left: 0,
            outline: '0',
            position: 'absolute',
            top: 0,
            willChange: 'transform',
            ':focus': {
              borderColor: 'rgba(55, 55, 55, .5)',
            },
          }),
        ]}
        {...props}
      />
    );
  }
);

const PlacementIndicator = ({
  duration,
  placement: {color, startTime, endTime},
  ...props
}) => {
  const width = ((endTime - startTime) / duration) * 100;
  const left = (startTime / duration) * 100;

  return (
    <Element
      {...props}
      style={{
        background: color,
        left: `${left}%`,
        width: `${width}%`,
      }}
      rules={() => ({
        position: 'absolute',
        height: '100%',
      })}
    />
  );
};

const CurrentTimeIndicator = ({duration, currentTime}) => {
  if (duration && currentTime > 0) {
    return (
      <Element
        style={{
          left: `${(currentTime / duration) * 100}%`,
        }}
        rules={() => ({
          background: '#666',
          bottom: 0,
          position: 'absolute',
          top: 0,
          width: '1px',
          zIndex: 1,
        })}
      />
    );
  }
  return null;
};

const AudioTrackPlacement = ({
  currentTime,
  duration,
  onChange,
  placements,
  scale,
  trackWidth,
  waveform,
  trackOffset,
}) => {
  const containerRef = React.useRef();
  const trackElementRef = React.useRef();

  useEffect(() => {
    const pct = (Math.abs(trackOffset) * 100) / trackWidth;
    trackElementRef.current.updatePosition({pct});
  }, [trackWidth, trackOffset]);

  return (
    <Element
      domRef={containerRef}
      rules={() => ({
        height: '2.5rem',
        marginTop: '1px',
        position: 'relative',
        width: '100%',
      })}>
      <CurrentTimeIndicator duration={duration} currentTime={currentTime} />
      {placements.map((placement) => {
        return (
          <PlacementIndicator
            key={placement.id}
            placement={placement}
            duration={duration}
          />
        );
      })}
      <AudioWaveformCanvas
        waveform={waveform}
        waveColor='#c5c5c5'
        rules={() => ({
          padding: '0.5rem 0',
        })}
      />
      <TrackElement
        ref={trackElementRef}
        containerRef={containerRef}
        width={(100 / scale) * 100}
        onChange={({pct}) => {
          const _trackOffset = (pct / 100) * trackWidth;
          requestAnimationFrame(() => {
            onChange(-_trackOffset);
          });
        }}
      />
    </Element>
  );
};

export default AudioTrackPlacement;
