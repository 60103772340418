import {Controller} from 'react-hook-form';
import {capitalize} from '@core/lib/filters';
import {Input, Label} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {InfoTooltip} from '@core/ui/Tooltip';
import {FieldProps} from '../helpers';

export function TrailingField({control, watch}: FieldProps) {
  const {interval, trailing} = watch('exportParameters');
  const title = `Trailing ${interval ? `${capitalize(interval)}s` : ''}`;

  return (
    <Stack direction='column'>
      <Label
        htmlFor='trailing'
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
          gap: '0.375rem',
        })}>
        {title}
        <InfoTooltip
          title={title}
          description={`The number of prior ${
            interval ?? 'days, weeks, or month'
          }s to use to compared with the current
            interval. We will use the ${trailing ?? 'trailing'} ${
            interval ?? 'interval'
          }s prior to the current
            month to determine the new reach.`}
        />
      </Label>
      <Controller
        control={control}
        name='exportParameters.trailing'
        render={({field}) => (
          <Input
            key={watch('typeId')}
            defaultValue={field.value ?? undefined}
            id='trailing'
            onChange={field.onChange}
            placeholder='Trailing'
            type='number'
          />
        )}
      />
    </Stack>
  );
}
