import dayjs from '@core/lib/dayjs';

interface IUpcomingEpisodePodcast {
  id: string;
  description: string;
  image: TSFixMe;
  title: string;
  campaigns: {
    id: string;
    campaignEpisodeId: string;
    campaignPodcastId: string;
    name: string;
    slug: string;
    advertiser: {
      id: string;
      name: string;
    };
  }[];
}

interface IUpcomingEpisode {
  date: string;
  podcasts: IUpcomingEpisodePodcast[];
}

const useUpcomingEpisodesCalendarData = (data) => {
  if (!data) return;

  const upcomingEpisodes: IUpcomingEpisode[] = [];

  data.forEach(({campaignEpisodes, expectedPublished, id, podcast: {feed}}) => {
    // if no campaign episode attached, filter out
    if (!campaignEpisodes.length) return;

    let episodeDate = upcomingEpisodes.find(
      ({date}) => expectedPublished == date
    );
    let podcast;

    if (!episodeDate) {
      episodeDate = {date: expectedPublished, podcasts: []};
      upcomingEpisodes.push(episodeDate);
    } else {
      podcast = episodeDate.podcasts.find(({title}) => title == feed.title);
    }

    if (!podcast) {
      podcast = Object.assign({campaigns: []}, feed);
      episodeDate.podcasts.push(podcast);
      episodeDate.podcasts.sort((a, b) => a.title.localeCompare(b.title));
    }

    podcast.campaigns.push(
      ...campaignEpisodes.map(({id, campaignPodcast}) => ({
        campaignEpisodeId: id,
        campaignPodcastId: campaignPodcast.id,
        ...campaignPodcast.campaign,
      }))
    );

    podcast.date = expectedPublished;
    podcast.id = `${id}+${dayjs(expectedPublished).utc()}`;
  });

  return upcomingEpisodes;
};

export default useUpcomingEpisodesCalendarData;
