import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import PodlinkDomainForm from '@analytics/components/podlink/PodlinkDomainForm';
import CREATE_PODLINK_DOMAIN from '@analytics/graphql-api/_old_mutations/CreatePodlinkDomain';
import PODLINK_DOMAINS_QUERY from '@analytics/graphql-api/_old_queries/podlink/PodlinkDomainsQuery';
import {ManagePodlinkRouteProps} from '.';

export function PodlinkDomainCreate({
  organization,
  podcasts,
}: ManagePodlinkRouteProps) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [createPodlinkDomain] = useMutation(CREATE_PODLINK_DOMAIN, {
    refetchQueries: [PODLINK_DOMAINS_QUERY],
  });

  const onSubmit = async ({slug, podcastIds}) => {
    setLoading(true);
    setError(null);
    try {
      const resp = await createPodlinkDomain({
        variables: {
          input: {
            title: organization.name,
            organizationId: organization.id,
            slug,
            podcastIds,
          },
        },
      });

      const podlinkSlug = resp.data.createPodlinkDomain.podlinkDomain.slug;
      history.push(
        createOrgPath(organization, `manage/podlink/${podlinkSlug}`)
      );
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={createOrgPath(organization, 'manage/podlink')}>
        Create pod.link Subdomain
      </CardHeader>
      <Card>
        {!!error && (
          <ToastMessage alertType='error' rules={() => ({margin: 0})}>
            {error}
          </ToastMessage>
        )}
        <PodlinkDomainForm
          loading={loading}
          organization={organization}
          podcasts={podcasts}
          onSubmit={onSubmit}
          onCancel={() =>
            history.push(createOrgPath(organization, 'manage/podlink'))
          }
        />
      </Card>
    </ResponsiveContent>
  );
}
