import {useCallback, useMemo} from 'react';
import {useQuery} from '@apollo/client';
import dayjs from '@core/lib/dayjs';
import {Element} from '@core/style';
import {GREEN} from '@core/style';
import {DataTableColumn, DataTableServer} from '@core/ui/DataTable';
import EmptyMessage from '@core/ui/EmptyMessage';
import {LoadingSpinner} from '@core/ui/Loading';
import AudioBarWaveformPlayer from '@analytics/components/audio/AudioBarWaveformPlayer';
import {
  FetchedAdvisorShow,
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import ADVISOR_BRAND_AD_QUERY from '@analytics/graphql-api/_old_queries/advisor/AdvisorBrandAdQuery';
import ADVISOR_PODCAST_BRANDS_QUERY from '@analytics/graphql-api/_old_queries/advisor/AdvisorPodcastBrandsQuery';
import {DetailBadge} from './tool/AdvisorPreviewPodcastPanel';

const useBrandData = ({
  brands,
  adPlacements,
  startMonth,
}: {
  brands: {
    brand: {
      id: string;
      industryDisplay: string;
    };
    adCount: number;
  }[];
  adPlacements: {
    brand: {
      id: string;
    };
    episode: {
      published: string;
    };
  }[];
  startMonth: dayjs.Dayjs;
}) =>
  brands.map((row) => {
    const placements = adPlacements.filter(
      ({brand, episode}) =>
        brand?.id === row.brand.id &&
        dayjs(episode.published).isSameOrAfter(startMonth)
    );

    return {
      ...row.brand,
      adCount: row.adCount,
      industry: row.brand.industryDisplay ? row.brand.industryDisplay : 'N/A',
      placements: placements,
    };
  });

const BrandAdCell = ({
  organizationId,
  feedId,
  id,
}: {
  organizationId: string;
  feedId: string;
  id: string;
}) => {
  const {data, loading, error} = useQuery(ADVISOR_BRAND_AD_QUERY, {
    variables: {
      organizationId,
      feedId,
      id,
    },
  });

  if (loading)
    return (
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          minWidth: '8.5rem',
        })}>
        <LoadingSpinner size='small' />
      </Element>
    );

  if (error) return <EmptyMessage>Audio unavailable</EmptyMessage>;

  return (
    <AudioBarWaveformPlayer
      enclosure={data?.me?.organization?.limitedCompany?.ads?.[0]}
    />
  );
};

const getColumns = ({
  startMonth,
  compact,
  feedId,
  organizationId,
}: {
  startMonth: dayjs.Dayjs;
  compact: boolean;
  feedId: string;
  organizationId: string;
}): DataTableColumn[] => {
  const months = Array.from({length: 6}, (_, i) => startMonth.add(i, 'M'));
  return [
    {
      accessor: 'id',
      title: 'Listen',
      noSortable: true,
      type: 'string',
      Cell: ({data: {id}}) => (
        <Element
          rules={() => ({
            maxWidth: '25rem',
          })}>
          <BrandAdCell
            feedId={feedId}
            organizationId={organizationId}
            id={id}
          />
        </Element>
      ),
    },
    {
      accessor: 'name',
      title: 'Brand',
      type: 'string',
      rule: () => ({whiteSpace: 'nowrap'}),
    },
    {
      accessor: 'placements',
      title: 'Placements Found in Past 6 Months',
      noSortable: true,
      type: 'string',
      description: `A green circle indicates at least 1 ad placement for the brand was found during this month`,
      Cell: ({data: {placements}}) => {
        const monthData = months.map((m) =>
          placements.find(
            ({episode}) => dayjs(episode.published).month() === m.month()
          )
        );
        return (
          <Element
            rules={({theme}) => ({
              alignItems: 'center',
              color: theme.textTertiary,
              display: 'grid',
              fontSize: '0.825rem',
              gridTemplateColumns: 'repeat(6, 32px)',
              gridTemplateRows: '18px 18px',
              justifyItems: 'center',
            })}>
            {months.map((m) => (
              <div key={m.format('MM-YY')}>{m.format('MMM')}</div>
            ))}
            {monthData.map((m, i) => (
              <Element
                key={i}
                rules={({theme}) => ({
                  borderRadius: '50%',
                  background: m ? GREEN : theme.bgTertiary,
                  height: '0.675rem',
                  width: '0.675rem',
                })}
              />
            ))}
          </Element>
        );
      },
    },
    !compact && {
      accessor: 'industry',
      title: 'Industry',
      type: 'number',
      noSortable: true,
      Cell: ({data: {industry}}) =>
        industry !== 'N/A' ? <DetailBadge>{industry}</DetailBadge> : null,
    },
    {
      accessor: 'adCount',
      title: 'Total Ads',
      type: 'number',
    },
  ];
};

const AdvisorPodcastsBrandsTable = ({
  organization,
  podcast,
  compact,
  className,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  podcast: FetchedAdvisorShow;
  compact?: boolean;
  className?: string;
}) => {
  const startMonth = dayjs().subtract(5, 'M').startOf('M').utc();

  const columns = useMemo(
    () =>
      getColumns({
        startMonth,
        compact,
        feedId: podcast?.id,
        organizationId: organization.id,
      }),
    [organization, podcast]
  );

  return (
    <DataTableServer
      query={ADVISOR_PODCAST_BRANDS_QUERY}
      columns={columns}
      emptyMessageText='No brands found'
      toVariables={useCallback(
        ({query, rowsPerPage, rowStart}) => ({
          organizationId: organization.id,
          id: podcast?.feed.id,
          limit: compact ? 10 : rowsPerPage,
          offset: rowStart,
          query,
        }),
        [organization, podcast]
      )}
      toData={(resp) => {
        try {
          const {count, rows: brands} =
            resp.me.organization.advisor.show.topBrands;
          const {rows: adPlacements} =
            resp.me.organization.advisor.show.adPlacements;
          return {
            data: useBrandData({brands, adPlacements, startMonth}),
            count,
          };
        } catch (err) {
          return {data: [], count: 0};
        }
      }}
      searchKeys={compact ? [] : ['name']}
      searchPlaceholder={compact ? '' : 'Search brand'}
      pagination={!compact}
      className={className}
    />
  );
};

export default AdvisorPodcastsBrandsTable;
