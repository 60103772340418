import CampaignOverlapContent from '@analytics/components/campaigns/CampaignOverlap';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignRouteProps} from './_routes';

export function CampaignOverlap({
  organization,
  user,
  campaign,
}: CampaignRouteProps) {
  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <CampaignOverlapContent organization={organization} campaign={campaign} />
    </>
  );
}
