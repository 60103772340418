import React, {PropsWithChildren, useMemo} from 'react';
import dayjs from '@core/lib/dayjs';
import {comma, toPercent} from '@core/lib/filters';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import ProgressBar from '@core/ui/ProgressBar';
import {Tooltip} from '@core/ui/Tooltip';

const TooltipLabel = ({
  color,
  children,
}: PropsWithChildren<{color: string}>) => {
  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        padding: '0.125rem 0',
      }}>
      <div
        css={{
          background: color || 'var(--border-default)',
          borderRadius: '50%',
          height: '0.625rem',
          marginRight: '0.625rem',
          width: '0.625rem',
        }}
      />
      {children}
    </div>
  );
};

const InsightsReachAnalysisTable = ({data}: {data: any[]}): JSX.Element => {
  const maxReach = data.reduce((acc, currentValue) => {
    return acc >= currentValue.reach ? acc : currentValue.reach;
  }, 0);

  const columns: DataTableColumn[] = useMemo(
    () => [
      {
        title: 'Date',
        accessor: 'interval',
        type: 'date',
        fmt: (v) => dayjs(v).utc().format('MMM DD, YYYY'),
      },
      {
        title: 'Reach',
        accessor: 'reach',
        type: 'number',
        alignRight: false,
        Cell: ({d}) => {
          return (
            <Tooltip
              description={
                <>
                  <TooltipLabel color='var(--blue)'>
                    {comma(d.newReach)} New ({toPercent(d.percentNew)})
                  </TooltipLabel>
                  <TooltipLabel color='var(--blue-40)'>
                    {comma(d.existingReach)} Existing
                  </TooltipLabel>
                </>
              }
              rules={() => ({cursor: 'pointer'})}>
              <div>{comma(d.reach)}</div>
              <ProgressBar
                value={d.newReach}
                max={d.reach}
                progressColor='var(--blue)'
                bgColor='var(--blue-40)'
                rules={() => ({
                  height: '0.125rem',
                  marginTop: '0.3125rem',
                  width: `${(d.reach * 100) / maxReach}%`,
                })}
              />
            </Tooltip>
          );
        },
      },
      {
        title: 'Prior Reach',
        accessor: 'priorReach',
        type: 'number',
        fmt: comma,
      },
      {
        title: 'Combined Reach',
        accessor: 'combinedReach',
        type: 'number',
        fmt: comma,
      },
      {
        title: 'Prior Impressions',
        accessor: 'priorImpressions',
        type: 'number',
        fmt: comma,
      },
      {
        title: 'Impressions',
        accessor: 'impressions',
        type: 'number',
        fmt: comma,
      },
      {
        title: 'Impressions To New Reach',
        accessor: 'impressionsToNewReach',
        type: 'number',
        fmt: comma,
      },
      {
        title: 'Impressions To Prior Reach',
        accessor: 'impressionsToPriorReach',
        type: 'number',
        fmt: comma,
      },
    ],
    [maxReach]
  );

  return (
    <DataTable
      data={data}
      columns={columns}
      orderBy='-interval'
      emptyMessageText='No reach data available within the given range.'
    />
  );
};

export default InsightsReachAnalysisTable;
