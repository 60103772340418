import {gql} from '@apollo/client';
import AttributionConfigFragment from './fragments/AttributionConfigFragment';

export default gql`
  query getAttributionConfig($organizationId: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        attributionConfig {
          ...AttributionConfig
        }
      }
    }
  }
  ${AttributionConfigFragment}
`;
