import {gql} from '@apollo/client';

export default gql`
  query searchCompanies($query: String!, $requireOrganization: Boolean) {
    me {
      companySearch(query: $query, requireOrganization: $requireOrganization) {
        id
        name
        slug
        hasActivePixel
        industry
        industryDisplay
        company {
          campaignCount
        }
      }
    }
  }
`;
