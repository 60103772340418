import {isSelling} from '@core/lib/organizations';
import {OrganizationObject} from '@analytics/graphql-api';
import {AdvisorColumnGroupsConfig} from './types';

export const getDefaultColumns = (
  organization: OrganizationObject
): string[] => {
  if (isSelling(organization)) {
    return [
      'averageMonthlyImpressions',
      'averageMonthlyReach',
      'publisherCr',
      'feedCr',
      'tags',
      'radioMarkets::001',
      'nielsen::27',
      'experian::A01',
      'averageEpisodeLength',
      'adsSampled',
    ];
  }

  return [
    'publisher',
    'tags',
    'affinityScore',
    'newReach',
    'averageMonthlyImpressions',
    'averageMonthlyReach',
    'radioMarkets::001',
    'nielsen::27',
    'experian::A01',
    'averageEpisodeLength',
    'adsSampled',
  ];
};

export const columnGroupsConfig: AdvisorColumnGroupsConfig[] = [
  {
    columnGroupId: 'general',
    columnGroupName: 'general',
    queryImmediately: true,
    searchPlaceholder: 'Search',
    title: 'General',
    iconName: 'ray',
  },
  {
    columnGroupId: 'brand',
    columnGroupName: 'brands',
    searchPlaceholder: 'Search Brand',
    title: 'Brands',
    iconName: 'verified',
  },
  {
    columnGroupId: 'tag',
    columnGroupName: 'tags',
    queryImmediately: true,
    searchPlaceholder: 'Search Tag',
    title: 'Tags',
    iconName: 'tag-outline',
  },
  {
    columnGroupId: 'radioMarkets',
    columnGroupName: 'radioMarkets',
    searchPlaceholder: 'Search Markets',
    title: 'Radio Markets',
    iconName: 'locate',
  },
  {
    columnGroupId: 'overlap',
    columnGroupName: 'overlap',
    searchPlaceholder: 'Search Overlapping Audiences',
    title: 'Audience Overlap',
    iconName: 'overlap',
  },
  {
    columnGroupId: 'industries',
    columnGroupName: 'industries',
    searchPlaceholder: 'Search Industries',
    title: 'Industries',
    iconName: 'industries',
  },
  {
    columnGroupId: 'nielsen',
    columnGroupName: 'nielsen',
    searchPlaceholder: 'Search Nielsen',
    title: 'Nielsen',
    iconName: 'nielsen',
  },
  {
    columnGroupId: 'experian',
    columnGroupName: 'experian',
    searchPlaceholder: 'Search Experian',
    title: 'Experian',
    iconName: 'experian',
  },
];
