import React, {useState} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import MeQuery from '@core/graphql-api/queries/MeQuery';
import Loading from '@core/ui/Loading';
import OnboardSelector from '@analytics/components/onboard/OnboardSelector';
import {UpdateOrganizationDocument} from '@analytics/graphql-api';

const OnboardResearch = ({
  updateOrganization,
  match: {
    params: {organizationId},
  },
}) => {
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const onSubmit = async (kind) => {
    setLoading(true);

    try {
      await updateOrganization({
        id: organizationId,
        kind,
      });
      setComplete(true);
    } catch (err) {
      console.warn(err);
    }
  };

  if (loading) {
    return <Loading centered />;
  } else if (complete) {
    return <Redirect to='/' />;
  }
  return <OnboardSelector onSubmit={onSubmit} />;
};

export default graphql(UpdateOrganizationDocument, {
  props: ({mutate}) => ({
    updateOrganization: (input) => {
      return mutate({
        variables: {
          input,
        },
        update: (
          store,
          {
            data: {
              updateOrganization: {organization},
            },
          }
        ) => {
          const query = {
            query: MeQuery,
            variables: {
              service: 'analytics',
            },
          };

          query.data = store.readQuery(query);

          const organizations = query.data.me.organizations.map((org) => {
            if (org.id == organization.id) {
              return organization;
            }
            return org;
          });

          query.data.me.organizations = organizations;
          store.writeQuery(query);
        },
      });
    },
  }),
})(OnboardResearch);
