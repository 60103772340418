import DataSelector, {DataSelectorProps} from '@core/ui/DataSelector';
import AGENCY_SEARCH_QUERY from './AgencySearchQuery';

export default function AgencySelec({
  placeholder = 'Search agency',
  ...props
}: DataSelectorProps) {
  return (
    <DataSelector
      dataQuery={AGENCY_SEARCH_QUERY}
      fromResponse={(data) => data.me.agencySearch}
      placeholder={placeholder}
      propertyForName='name'
      css={`
        width: 100%;
      `}
      {...props}
    />
  );
}
