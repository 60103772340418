import {Radio, RadioGroup} from '@core/ui/Radio';
import {Question, _QuestionHeader} from '../../shared';
import {IQuestionProps} from '../types';

export const QuestionProductAvailability = ({
  quizData,
  updateQuizData,
}: IQuestionProps) => (
  <Question>
    <_QuestionHeader>Where are your products available?</_QuestionHeader>
    <RadioGroup
      value={quizData.availability}
      name='availability'
      onChange={(e) => updateQuizData('availability', e.target.value)}>
      <Radio value='nationwide' label='US, Canada, Australia' />
      <Radio value='specific_markets' label='Specific Markets' />
    </RadioGroup>
  </Question>
);
