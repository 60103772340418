import {HTMLAttributes, useState} from 'react';
import styled from 'styled-components';
import {Stack} from '@core/ui/Layout';

type Props = HTMLAttributes<HTMLDivElement> & {
  options: Array<{
    label: string;
    value: string;
  }>;
  onChange?: (value: string) => void;
  defaultSelected?: string;
};

export function NavPills({
  options,
  defaultSelected,
  onChange,
  ...props
}: Props) {
  const [selected, setSelected] = useState(defaultSelected || options[0].value);
  return (
    <Stack
      alignItems='center'
      gap={2}
      css={`
        margin: var(--spacing-4) 0 var(--spacing-6);
      `}
      {...props}>
      {options.map((option) => (
        <NavPill
          key={option.value}
          $selected={selected === option.value}
          onClick={() => {
            setSelected(option.value);
            onChange?.(option.value);
          }}>
          {option.label}
        </NavPill>
      ))}
    </Stack>
  );
}

const NavPill = styled.button<{$selected?: boolean}>`
  ${({$selected}) =>
    $selected
      ? `background: var(--bg-default-hover); color: var(--text-default);`
      : `background: var(--bg-default); color: var(--text-muted);`}
  align-items: center;
  border-radius: var(--radius-4);
  display: inline-flex;
  font-size: var(--font-size-body-4);
  font-weight: var(--font-weight-bold);
  height: var(--spacing-8);
  height: var(--spacing-8);
  letter-spacing: 0.0725rem;
  padding: 0 var(--spacing-5);
  text-transform: uppercase;
  :hover {
    color: var(--text-default);
    transition: all 0.2s;
  }
`;
