import {useState} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {Form} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import CampaignPodcastNav, {
  campaignPodcastURL,
} from '@analytics/components/nav/CampaignPodcastNav';
import CheckCampaignPodcastPrefixMutation from '@analytics/graphql-api/_old_mutations/CheckCampaignPodcastPrefix';
import CampaignPodcastQuery from '@analytics/graphql-api/_old_queries/CampaignPodcastQuery';

const CampaignPodcastPrefix = ({
  organization,
  campaign,
  campaignPodcast,
  checkCampaignPodcastPrefix,
}) => {
  const {feed} = campaignPodcast;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [complete, setCompleted] = useState(false);

  const onSubmit = async (item) => {
    setErrors([]);
    setLoading(true);
    try {
      await checkCampaignPodcastPrefix();
      setLoading(false);

      if (!campaignPodcast.hasPodsightsPrefix) {
        setErrors([{message: 'Unable to find Ad Analytics Prefix in feed.'}]);
      } else {
        setCompleted(true);
      }
    } catch (error) {
      setErrors([error]);
      setLoading(false);
    }
  };

  if (complete && !errors.length) {
    return (
      <Redirect
        to={campaignPodcastURL(organization, campaign, campaignPodcast)}
      />
    );
  }

  return (
    <>
      <CampaignPodcastNav
        organization={organization}
        campaign={campaign}
        campaignPodcast={campaignPodcast}
      />
      <ResponsiveContent containerSize='medium'>
        {!campaignPodcast.hasPodsightsPrefix ? (
          <CardHeader>Ad Analytics prefix is not Installed</CardHeader>
        ) : (
          <CardHeader>Ad Analytics prefix is installed</CardHeader>
        )}
        <Card>
          {!campaignPodcast.hasPodsightsPrefix ? (
            <Form onSubmit={onSubmit} loading={loading} errors={errors}>
              <p>
                Ad Analytics could not find the Ad Analytics Analytics Prefix on
                the RSS feed{' '}
                <a href={feed.href} target='_blank' rel='noreferrer'>
                  {feed.href}
                </a>
                . Please install the Ad Analytics Prefix. If you have done so,
                you can use the tester here.
              </p>

              <Element rules={() => ({textAlign: 'right'})}>
                <Submit>Check Ad Analytics Prefix</Submit>
              </Element>
            </Form>
          ) : (
            <p>
              Ad Analytics found the Ad Analytics Analytics Prefix on the RSS
              feed{' '}
              <a href={feed.href} target='_blank' rel='noreferrer'>
                {feed.href}
              </a>
            </p>
          )}
        </Card>
      </ResponsiveContent>
    </>
  );
};

export default graphql(CheckCampaignPodcastPrefixMutation, {
  props: ({mutate, ownProps: {organization, campaign, campaignPodcast}}) => ({
    checkCampaignPodcastPrefix: () => {
      return mutate({
        variables: {
          input: {
            id: campaignPodcast.id,
          },
        },
        update: (
          store,
          {
            data: {
              checkCampaignPodcastPrefix: {campaignPodcast},
            },
          }
        ) => {
          const query = {
            query: CampaignPodcastQuery,
            variables: {
              organizationId: organization.id,
              campaignId: campaign.id,
              id: campaignPodcast.id,
            },
          };

          query.data = store.readQuery(query);
          query.data.me.organization.campaign.campaignPodcast.hasPodsightsPrefix =
            campaignPodcast.hasPodsightsPrefix;

          store.writeQuery(query);
        },
      });
    },
  }),
})(CampaignPodcastPrefix);
