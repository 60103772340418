import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {
  FieldGroup,
  FieldSet,
  Form,
  Input,
  Label,
  Legend,
  TextArea,
} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import ContactPublisherMutation from '@analytics/graphql-api/_old_mutations/ContactPublisher';

class ContactPublisherForm extends React.Component {
  constructor(props) {
    super(props);

    const {
      user: {firstName, lastName, email},
      organization,
    } = props;

    this.state = {
      firstName: firstName ? firstName : '',
      lastName: lastName ? lastName : '',
      email: email ? email : '',
      phone: '',
      message: '',
      company: organization.name,

      success: false,
      loading: false,
      errors: [],
    };
  }

  onChange = ({target: {name, value}}) => {
    const update = {};
    update[name] = value;
    this.setState(update);
  };

  onSubmit = () => {
    const {contactPublisher, publisher} = this.props;
    const {firstName, lastName, email, phone, company, message} = this.state;

    this.setState({
      loading: true,
    });

    contactPublisher({
      publisherId: publisher.id,
      firstName,
      lastName,
      email,
      phone,
      company,
      message,
    })
      .then(() => {
        this.setState({
          loading: false,
          errors: [],
          success: true,
        });
      })
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  render() {
    const {organization, publisher} = this.props;
    const {
      loading,
      errors,
      firstName,
      lastName,
      email,
      phone,
      company,
      message,
      success,
    } = this.state;

    if (success) {
      return (
        <ResponsiveContent containerSize='medium'>
          <CardHeader>Success</CardHeader>
          <Card>
            <p>
              We sent an email to {publisher.name}, notifying them of your
              interest. A representative from the publisher should be in touch
              soon.
            </p>
          </Card>
        </ResponsiveContent>
      );
    }

    return (
      <ResponsiveContent containerSize='medium'>
        <CardHeader>Contact {publisher.name}</CardHeader>
        <Card>
          <p>
            The following allows you to contact {publisher.name} about
            advertising on their podcasts. When you submit this form, it goes
            directly to
            {publisher.name}, and the conversation will take place off the Ad
            Analytics platform.
          </p>

          <Form
            loading={loading}
            onSubmit={this.onSubmit}
            errors={errors}
            onClose={() => {
              this.setState({
                errors: [],
              });
            }}>
            <FieldSet>
              <Legend>You</Legend>
              <Element rules={() => ({display: 'flex'})}>
                <FieldGroup rules={() => ({flex: 1})}>
                  <Label>First Name</Label>
                  <Element rules={() => ({display: 'flex'})}>
                    <Input
                      type='text'
                      name='firstName'
                      value={firstName}
                      onChange={this.onChange}
                      placeholder='Jane'
                      disabled={loading}
                      required
                      rules={() => ({flex: 1})}
                    />
                  </Element>
                </FieldGroup>

                <FieldGroup rules={() => ({flex: 1})}>
                  <Label>Last Name</Label>
                  <Input
                    type='text'
                    name='lastName'
                    value={lastName}
                    onChange={this.onChange}
                    disabled={loading}
                    required
                  />
                </FieldGroup>
              </Element>
              <FieldGroup>
                <Label>Email</Label>
                <Input
                  type='text'
                  name='email'
                  value={email}
                  onChange={this.onChange}
                  disabled={loading}
                  required
                />
              </FieldGroup>

              <FieldGroup>
                <Label>Phone</Label>
                <Input
                  type='text'
                  name='phone'
                  value={phone}
                  onChange={this.onChange}
                  disabled={loading}
                />
              </FieldGroup>
            </FieldSet>

            <FieldSet>
              <FieldGroup>
                <Label>Company</Label>
                <Input
                  type='text'
                  name='company'
                  value={company}
                  onChange={this.onChange}
                  disabled={loading}
                />
              </FieldGroup>
            </FieldSet>

            <FieldSet>
              <FieldGroup>
                <Label>Message</Label>
                <TextArea
                  name='message'
                  value={message}
                  onChange={this.onChange}
                  placeholder='Tell us about your next campaign. (optional)'
                  disabled={loading}
                />
              </FieldGroup>
            </FieldSet>

            <Element
              rules={() => ({padding: '0.625rem 0 1rem', textAlign: 'right'})}>
              <Submit disabled={loading}>Submit</Submit>
            </Element>
          </Form>
        </Card>
      </ResponsiveContent>
    );
  }
}

export default graphql(ContactPublisherMutation, {
  props: ({mutate}) => ({
    contactPublisher: (input) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
  }),
})(ContactPublisherForm);
