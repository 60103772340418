import {useCallback, useEffect, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {Element} from '@core/style';
import {ToastMessage} from '@core/ui/Alert';
import {Button, IconButton} from '@core/ui/Button';
import {Card} from '@core/ui/Content';
import {FORM_ERRORS, Form, Submit} from '@core/ui/FormElements';
import {Icon} from '@core/ui/Icon';
import {Stack} from '@core/ui/Layout';
import Loading from '@core/ui/Loading';
import {Overlay} from '@core/ui/Overlay';
import SegmentedControls from '@core/ui/SegmentedControls';
import PROCESS_CAMPAIGN_EPISODES from '@analytics/graphql-api/_old_mutations/ProcessCampaignEpisodes';
import CAMPAIGN_PODCAST_QUERY from '@analytics/graphql-api/_old_queries/CampaignPodcastQuery';
import BulkEpisodesAddedTable from './BulkEpisodesAddedTable';
import BulkEpisodesNewEpisodeOverlay from './BulkEpisodesNewEpisodeOverlay';
import BulkEpisodesNotAddedTable from './BulkEpisodesNotAddedTable';
import {Episode, findEditionEpisode, findEpisode} from './helpers';

const OverlayContent = ({
  campaign,
  campaignEpisodes,
  campaignPodcast,
  organization,
  refetch,
  toggle,
}) => {
  const [loading, setLoading] = useState(false);
  const [upcomingKey, setUpcomingKey] = useState(0);
  const [newOverlayOpened, toggleNewOverlay] = useState(false);
  const [tab, setTab] = useState('upcoming');
  const [edition, setEdition] = useState({
    removes: [],
    creates: [],
    updates: [],
  });
  const [processCampaignEpisodes] = useMutation(PROCESS_CAMPAIGN_EPISODES);
  const {
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();

  // eslint-disable-next-line prefer-spread
  const flatEdition = [].concat.apply([], Object.values(edition));

  const onAddEpisode = useCallback(
    ({episode}) => {
      const match = findEditionEpisode(episode, edition, ['removes']);

      if (match) {
        // Remove from removes
        edition.removes.splice(edition.removes.indexOf(match), 1);
      } else {
        // Add to creates
        edition.creates = [...edition.creates, {episode}];
      }
      setEdition({...edition});
    },
    [edition]
  );

  const onRemoveEpisode = useCallback(
    ({episode}) => {
      const match = findEditionEpisode(episode, edition, ['creates']);

      if (match) {
        // Remove from creates
        edition.creates.splice(edition.creates.indexOf(match), 1);
      } else {
        // Add to removes
        edition.removes = [
          ...edition.removes,
          {
            episode:
              episode.isUpcomingEpisode || episode.isPastEpisode
                ? findEpisode(episode, campaignEpisodes)
                : episode,
          },
        ];
      }
      setEdition({...edition});
    },
    [campaignEpisodes, edition]
  );

  const onUpdateEpisode = useCallback(
    ({episode, isBaseline}) => {
      const createsMatch = findEditionEpisode(episode, edition, ['creates']);

      if (createsMatch) {
        createsMatch.isBaseline = isBaseline;
      } else {
        const updatesMatch = findEditionEpisode(episode, edition, ['updates']);

        if (updatesMatch) {
          edition.updates.splice(edition.updates.indexOf(updatesMatch), 1);
        } else {
          edition.updates.push({episode, isBaseline});
        }
      }

      setEdition({...edition});
    },
    [edition]
  );

  const onSubmit = async () => {
    if (flatEdition.length > 0) {
      setLoading(true);
      try {
        const creates = edition.creates.map(
          ({episode, isBaseline = false}) => ({
            campaignPodcastId: campaignPodcast.id,
            isBaseline,
            ...(episode.isUpcomingEpisode
              ? {
                  futureEpisodeId: episode.id,
                }
              : {
                  episodeId: episode.id,
                }),
          })
        );
        const updates = edition.updates.map(({episode, isBaseline}) => ({
          id: episode.id,
          isBaseline,
        }));
        const removes = edition.removes.map(({episode}) => ({
          id: episode.id,
        }));
        await processCampaignEpisodes({
          variables: {
            input: {
              organizationId: organization.id,
              creates,
              updates,
              removes,
            },
          },
        });
      } catch (err) {
        console.error(err);
        setError(null, FORM_ERRORS.network);
      }
      await refetch();
      setEdition({
        removes: [],
        creates: [],
        updates: [],
      });
      setLoading(false);
    }
  };

  const onCreatedEpisode = () => {
    setUpcomingKey((prev) => prev + 1);
  };

  return (
    <>
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: 0,
        })}>
        <Element
          rules={() => ({
            fontSize: '1.375rem',
            fontWeight: 700,
          })}>
          Add Episodes to "{campaignPodcast.name}"
        </Element>
        <IconButton onClick={() => toggle(false)} style-rounded>
          <Icon icon='close' />
        </IconButton>
      </Element>

      <Element
        rules={() => ({
          display: 'grid',
          gridGap: '1.25rem',
          gridTemplateColumns: '1fr 1fr',
          height: '100%',
          margin: '1.5rem 0',
          minHeight: 0,
        })}>
        <Card
          rules={() => ({
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            margin: 0,
            minHeight: 0,
            paddingBottom: '0.75rem',
          })}>
          <Element
            rules={() => ({
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '1rem',
            })}>
            <SegmentedControls
              propertyForValue='title'
              items={[
                {
                  title: 'Upcoming Episodes',
                  value: 'upcoming',
                },
                {
                  title: 'Past Episodes',
                  value: 'past',
                },
              ]}
              onChange={({value}) => setTab(value)}
              segmentWidth={160}
            />
          </Element>
          <Element rules={() => ({minHeight: 0, flex: 1})}>
            {tab === 'upcoming' ? (
              <BulkEpisodesNotAddedTable
                key={upcomingKey}
                kind='upcoming'
                campaign={campaign}
                campaignEpisodes={campaignEpisodes}
                campaignPodcast={campaignPodcast}
                edition={edition}
                onAddEpisode={onAddEpisode}
                onRemoveEpisode={onRemoveEpisode}
                organization={organization}
              />
            ) : (
              <BulkEpisodesNotAddedTable
                kind='past'
                campaign={campaign}
                campaignEpisodes={campaignEpisodes}
                campaignPodcast={campaignPodcast}
                edition={edition}
                onAddEpisode={onAddEpisode}
                onRemoveEpisode={onRemoveEpisode}
                organization={organization}
              />
            )}
          </Element>
        </Card>
        <Card
          rules={() => ({
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            margin: 0,
            minHeight: 0,
            paddingBottom: '0.75rem',
          })}>
          <Element
            rules={({theme}) => ({
              color: theme.textTertiary,
              fontSize: '0.875rem',
              fontWeight: 500,
              marginBottom: '1rem',
              textAlign: 'center',
              width: '100%',
            })}>
            Added Episodes (
            {campaignEpisodes.length +
              edition.creates.length -
              edition.removes.length}
            )
          </Element>
          <Element rules={() => ({minHeight: 0, flex: 1})}>
            <BulkEpisodesAddedTable
              campaignEpisodes={campaignEpisodes}
              edition={edition}
              onRemoveEpisode={onRemoveEpisode}
              onUpdateEpisode={onUpdateEpisode}
            />
          </Element>
        </Card>
      </Element>
      <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
        <Stack direction='row'>
          <Button
            type='button'
            onClick={() => toggleNewOverlay(true)}
            variant='outlined'
            iconLeading='plus'
            css={`
              margin-right: auto;
            `}>
            New Episode
          </Button>
          <Button type='button' onClick={toggle}>
            Cancel
          </Button>
          <Submit
            loading={loading}
            css={`
              margin-left: 1rem;
            `}>
            {flatEdition.length > 0
              ? `Save Changes (${flatEdition.length})`
              : 'Save Changes '}
          </Submit>
        </Stack>
      </Form>
      <BulkEpisodesNewEpisodeOverlay
        campaign={campaign}
        campaignPodcast={campaignPodcast}
        onCreatedEpisode={onCreatedEpisode}
        opened={newOverlayOpened}
        organization={organization}
        toggle={toggleNewOverlay}
      />
    </>
  );
};

const BulkEpisodesOverlay = ({
  campaign,
  campaignPodcast,
  onEditionFinish,
  opened,
  organization,
  toggle,
}) => {
  const {loading, error, data, refetch} = useQuery(CAMPAIGN_PODCAST_QUERY, {
    variables: {
      organizationId: organization.id,
      campaignId: campaign.id,
      id: campaignPodcast.id,
    },
  });

  useEffect(() => {
    if (!loading && data.me && typeof onEditionFinish === 'function') {
      onEditionFinish({
        episodesCount:
          data.me.organization.campaign.campaignPodcast.campaignEpisodes.length,
      });
    }
  }, [loading, data]);

  let content;

  if (loading) {
    content = <Loading absolute />;
  } else if (error || !data?.me?.organization) {
    content = (
      <ToastMessage alertType='error' rules={() => ({margin: 0})}>
        Networtk Error. Please try again.
      </ToastMessage>
    );
  } else {
    const campaignEpisodes =
      data.me.organization.campaign.campaignPodcast.campaignEpisodes.map(
        (episode) => new Episode({episode})
      );

    content = (
      <OverlayContent
        toggle={toggle}
        campaign={campaign}
        refetch={refetch}
        campaignEpisodes={campaignEpisodes}
        campaignPodcast={campaignPodcast}
        organization={organization}
      />
    );
  }

  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      closeOnOutsideClick={false}
      animation='scale-in'
      withBackdrop
      css={`
        border-radius: 1rem;
        border: 0;
        display: flex;
        flex-direction: column;
        inset: 6rem;
        max-height: 65rem;
        max-width: 80rem;
        overflow: hidden;
        padding: 2rem;
      `}>
      {content}
    </Overlay>
  );
};

export default BulkEpisodesOverlay;
