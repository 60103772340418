import {useState} from 'react';
import {Element} from '@core/style';
import {GREEN, RED_ORANGE} from '@core/style';
import {Icon} from '@core/ui/Icon';
import {LoadingSpinner} from '@core/ui/Loading';
import {Tooltip} from '@core/ui/Tooltip';
import {
  OrganizationObject,
  useUpdateAdvisorSavedSearchMutation,
} from '@analytics/graphql-api';
import {ActiveWorkspace} from './AdvisorTool';
import {AdvisorFilterItem, AdvisorSortByItem} from './types';

const AdvisorSavedSearchEditToolbar = ({
  activeWorkspace,
  isEditingWorkspace,
  onComplete,
  onDiscard,
  onSave,
  organization,
  workspaceConfig,
}: {
  activeWorkspace: ActiveWorkspace;
  isEditingWorkspace: boolean;
  onComplete: () => void;
  onDiscard: () => void;
  onSave: () => void;
  organization: OrganizationObject;
  workspaceConfig: {
    columns: string[];
    filters: AdvisorFilterItem[];
    sort: AdvisorSortByItem[];
  };
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [updateSavedSearch] = useUpdateAdvisorSavedSearchMutation({
    refetchQueries: ['getSavedSearches'],
  });

  const onApplyChanges = async () => {
    if (
      JSON.stringify(workspaceConfig) !==
      JSON.stringify(JSON.parse(activeWorkspace.data))
    ) {
      setLoading(true);
      try {
        const {
          id,
          name,
          version,
          organization: workspaceHasOrg,
        } = activeWorkspace;
        await updateSavedSearch({
          variables: {
            input: {
              id,
              data: JSON.stringify(workspaceConfig),
              name,
              version,
              organizationId: workspaceHasOrg ? organization.id ?? null : null,
              orgDefault: null,
            },
          },
        });

        onSave();
      } catch (err) {
        console.warn(err);
      }
      setLoading(false);
    }
    onComplete();
  };

  const onDiscardChanges = () => {
    if (
      JSON.stringify(workspaceConfig) !==
      JSON.stringify(JSON.parse(activeWorkspace.data))
    ) {
      onDiscard();
    }
    onComplete();
  };

  return (
    <Element
      rules={({theme}) => ({
        alignItems: 'center',
        background: theme.bgPrimary,
        border: `1px solid ${theme.borderPrimary}`,
        borderRadius: '0.375rem',
        display: 'flex',
        fontSize: '0.875rem',
        height: '2.125rem',
      })}>
      <Tooltip
        description={isEditingWorkspace ? 'Apply changes' : null}
        overlayProps={{
          horizontalAlign: 'center',
          verticalOffset: 1,
        }}
        rules={() => ({
          alignItems: 'center',
          borderTopLeftRadius: 'inherit',
          borderBottomLeftRadius: 'inherit',
          display: 'flex',
          marginLeft: 'auto',
          height: '100%',
        })}>
        <Element
          onClick={onApplyChanges}
          rules={({theme}) => ({
            alignItems: 'center',
            borderRadius: 'inherit',
            color: isEditingWorkspace ? GREEN : theme.textDisabled,
            display: 'flex',
            cursor: 'pointer',
            height: '100%',
            padding: '0 0.375rem',
            transition: 'background 0.2s',
            ':hover': {
              background: GREEN,
              color: '#fff',
            },
            ...((!isEditingWorkspace || loading) && {pointerEvents: 'none'}),
          })}>
          {loading ? (
            <LoadingSpinner size='small' />
          ) : (
            <Icon
              icon='checkmark'
              rules={() => ({
                height: '1.25rem',
                width: '1.25rem',
              })}
            />
          )}
        </Element>
      </Tooltip>
      <Tooltip
        description={isEditingWorkspace ? 'Discard changes' : null}
        overlayProps={{
          horizontalAlign: 'center',
          verticalOffset: 1,
        }}
        rules={({theme}) => ({
          alignItems: 'center',
          borderLeft: `1px solid ${theme.borderPrimary}`,
          borderTopRightRadius: 'inherit',
          borderBottomRightRadius: 'inherit',
          display: 'flex',
          height: '100%',
        })}>
        <Element
          onClick={onDiscardChanges}
          rules={({theme}) => ({
            alignItems: 'center',
            borderRadius: 'inherit',
            color: isEditingWorkspace ? RED_ORANGE : theme.textDisabled,
            cursor: 'pointer',
            display: 'flex',
            height: '100%',
            padding: '0 0.375rem',
            transition: 'background 0.2s',
            ':hover': {
              background: RED_ORANGE,
              color: '#fff',
            },
            ...((!isEditingWorkspace || loading) && {pointerEvents: 'none'}),
          })}>
          <Icon
            icon='close'
            rules={() => ({
              height: '1.25rem',
              width: '1.25rem',
            })}
          />
        </Element>
      </Tooltip>
    </Element>
  );
};

export default AdvisorSavedSearchEditToolbar;
