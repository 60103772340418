import {FetchedUser} from '@core/graphql-api';
import {createOrgPath, isSelling} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  OrganizationKind,
} from '@analytics/graphql-api';
import useFeatureFlags, {
  FEATURE_FLAGS,
} from '@analytics/lib/hooks/useFeatureFlags';
import {measureRoutes} from '@analytics/lib/routes/measureRoutes';
import {MainNavItem} from './LayoutLeftNavItem';

type UseLeftNavItemsFn = (_props: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
}) => MainNavItem[];

export const useLeftNavItems: UseLeftNavItemsFn = ({organization, user}) => {
  const {routes: _measureRoutes} = useRoutes({
    basePath: '/:part(b|a|s|p)/:slug/measure',
    baseUrl: createOrgPath(organization, 'measure'),
    routes: measureRoutes({organization}),
  });

  const showSalesOrderFeatureFlag = useFeatureFlags(FEATURE_FLAGS.SALES_ORDER);
  const showSalesOrder =
    organization.slug === 'spotify' && showSalesOrderFeatureFlag;
  const isSellSide = organization.kind === OrganizationKind.SellSide;
  const isBrand = organization.kind === OrganizationKind.Brand;
  const isPodcast = organization.kind === OrganizationKind.Podcast;
  // const hasPodcasts =
  //   isSelling(organization) ||
  //   (organization.podcastsCount && organization.podcastsCount > 0);

  return [
    {
      activeIcon: 'home',
      icon: 'home-outline',
      label: 'Home',
      path: '/',
    },
    ...(isBrand || isPodcast
      ? [
          {
            activeIcon: 'discover',
            icon: 'discover-outline',
            label: 'Discover',
            path: '/advisor',
          } as MainNavItem,
        ]
      : []),
    {
      activeIcon: 'measure',
      icon: 'measure-outline',
      label: 'Measure',
      path: '/measure',
      routes: _measureRoutes,
    },
    /*    ...(hasPodcasts
      ? [
          {
            activeIcon: 'podcast',
            icon: 'podcast-outline',
            label: 'Podcasts',
            path: '/podcasts',
          } as MainNavItem,
        ]
      : []), */
    ...(!isSellSide
      ? [
          {
            activeIcon: 'reports',
            icon: 'reports-outline',
            label: 'Reports',
            path: '/reports',
          } as MainNavItem,
        ]
      : []),
    {
      activeIcon: 'manage',
      icon: 'manage-outline',
      label: 'Manage',
      path: '/manage',
    },
    ...(showSalesOrder
      ? [
          {
            activeIcon: 'spotify',
            icon: 'spotify',
            label: 'Sales Orders',
            path: '/sales-orders',
          } as MainNavItem,
        ]
      : []),
    {
      activeIcon: 'help',
      icon: 'help-outline',
      label: 'Help Center',
      href: 'https://help.adanalytics.spotify.com',
    },
  ];
};
