import {useState} from 'react';
import {Controller, ErrorOption, useForm} from 'react-hook-form';
import {Card, CardHeader} from '@core/ui/Content';
import {Form, FormFooter, Input, Label, Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {useAddPixelMutation} from '@analytics/graphql-api';
import {toInviteUrl} from '../_routes';
import {ManageInviteCompanyRouteProps} from './_routes';

export function InvitePixel({
  company,
  organization,
  match: {
    params: {inviteeOrganizationId},
  },
}: ManageInviteCompanyRouteProps & {
  match: {params: {inviteeOrganizationId: string}};
}) {
  const [addPixel] = useAddPixelMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm({
    defaultValues: {
      domain: '',
    },
  });

  const onSubmit = async (formData: {domain: string}) => {
    setLoading(true);
    try {
      await addPixel({
        variables: {
          input: {
            domain: formData.domain,
            role: 'production',
            organizationId: inviteeOrganizationId,
          },
        },
      });
      setComplete(true);
    } catch (err) {
      setError(null, err as ErrorOption);
    }
    setLoading(false);
  };

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={toInviteUrl(organization)}>
        Invite sent to {company.name}!
      </CardHeader>
      <Card>
        <p>
          You have successfully invited <b>{company.name}</b> to Ad Analytics 🎉
        </p>
        <Card
          css={`
            background: var(--bg-muted);
            border: 0;
            margin: 0;
          `}>
          {complete ? (
            <>
              Pixel added succesfully. The brand will be able to edit this
              domain when they login.
            </>
          ) : (
            <>
              <p>
                <strong>Add Pixel</strong>
              </p>
              <p>
                Now, please take a moment to input the website where the brand
                will place their pixel. This field will create the pixel. It
                will not execute anything on the domain input.
              </p>
              <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
                <div
                  css={`
                    margin-bottom: 1rem;
                  `}>
                  <Controller
                    name='domain'
                    control={control}
                    rules={{required: 'Please provide a domain URL'}}
                    render={({field}) => (
                      <>
                        <Label>Pixel Domain</Label>
                        <Input
                          invalid={!!errors.domain}
                          autofocus
                          placeholder='https://example.com'
                          onChange={(value) => field.onChange(value)}
                        />
                      </>
                    )}
                  />
                </div>
                <FormFooter>
                  <Submit loading={loading}>Add Pixel</Submit>
                </FormFooter>
              </Form>
            </>
          )}
        </Card>
      </Card>
    </ResponsiveContent>
  );
}
