import {gql} from '@apollo/client';
import ReviewerEpisodeFragment from '../fragments/ReviewerEpisodeFragment';

export default gql`
  query ReviewerTranscribable(
    $organizationId: ID!
    $after: Date!
    $before: Date!
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          reviewing {
            transcribable(after: $after, before: $before) {
              ...ReviewerEpisode
            }
          }
        }
      }
    }
  }
  ${ReviewerEpisodeFragment}
`;
