import {fillEmptyX} from '@core/components/ChartTime/helpers';
import dayjs from '@core/lib/dayjs';

const DEFAULT_POINT = {
  impressions: 0,
  episode: 0,
  visitors: 0,
};

const getX = ({date, interval}) => {
  let d = dayjs(date).utc();

  switch (interval) {
    case 'weekly':
      d = d.startOf('week');
      break;
    case 'daily':
      d = d.startOf('day');
      break;
    default:
      // data is hourly by default
      break;
  }

  return {x: d.toDate().getTime(), date: d};
};

export const getCampaignOmniChartData = ({
  attribution,
  campaignPodcasts,
  downloads,
  interval,
}) => {
  let minTime;
  let maxTime;

  const setMinMax = (x) => {
    if (!minTime || minTime > x) {
      minTime = x;
    }
    if (!maxTime || maxTime < x) {
      maxTime = x;
    }
  };

  const map = {};

  for (const {downloadClass, hour, count} of downloads?.hourly || []) {
    if (downloadClass === 'good') {
      const {x} = getX({date: hour, interval});
      setMinMax(x);

      if (map[x]) {
        map[x].impressions += count;
      } else {
        map[x] = {
          ...DEFAULT_POINT,
          impressions: count,
          x,
        };
      }
    }
  }

  for (const {hour, count} of attribution?.users?.hourlyVisitors || []) {
    const {x} = getX({date: hour, interval});
    setMinMax(x);

    if (map[x]) {
      map[x].visitors += count;
    } else {
      map[x] = {
        ...DEFAULT_POINT,
        visitors: count,
        x,
      };
    }
  }

  const myEpisodes = [];

  if (campaignPodcasts) {
    const addEpisode = ({x, data}) => {
      if (!map[x]) {
        map[x] = {...DEFAULT_POINT};
      }
      Object.assign(map[x], data, {x});
    };

    for (const {feed, episodes} of campaignPodcasts) {
      for (const episode of episodes) {
        const date = dayjs(episode.published).utc().startOf('day');
        const data = {feed, episode, episodes: 1};
        let x = date.toDate().getTime();

        if (interval === 'hourly') {
          let count = 0;
          let loopDate = date.clone().utc();

          while (loopDate.isSame(date, 'day') && count < 6) {
            addEpisode({x: loopDate.toDate().getTime(), data});
            count += 1;
            loopDate = loopDate.add(1, 'hour');
          }
        } else if (interval === 'weekly') {
          x = date.startOf('week').toDate().getTime();
          addEpisode({x, data});
        } else {
          addEpisode({x, data});
        }

        myEpisodes.push({...data, x});
      }
    }
  }

  const data = Object.values(
    fillEmptyX({
      map,
      minTime,
      maxTime,
      interval,
      seriesKeys: ['impressions', 'visitors'],
    })
  ).sort((a, b) => {
    return a.x - b.x;
  });

  return {
    data,
    episodes: myEpisodes,
  };
};
