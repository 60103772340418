import {ProgressCircle} from '@spotify-internal/encore-web';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {
  FetchedOrganization,
  useSpotifySalesOrdersQuery,
} from '@analytics/graphql-api';
import {SpotifySalesOrdersTable} from './SpotifySalesOrdersTable';

export function SpotifySalesOrdersCard({
  organization,
}: {
  organization: FetchedOrganization;
}) {
  const {data, loading} = useSpotifySalesOrdersQuery({
    variables: {organizationId: organization.id},
  });

  const orders = data?.me?.organization?.spotifyOrders ?? [];
  if (loading) {
    return (
      <Card
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
        <CardHeader>Spotify Sales Orders</CardHeader>
        <ProgressCircle aria-label='loading' style={{alignSelf: 'center'}} />
      </Card>
    );
  }

  if (orders.length > 0)
    return (
      <Card>
        <CardHeader>Spotify Sales Orders</CardHeader>
        <Alert compact severity='info' css={{marginBottom: 'var(--spacing-4)'}}>
          {t('spotify.orders.table.description')}
        </Alert>
        <SpotifySalesOrdersTable data={orders} organization={organization} />
      </Card>
    );

  return null;
}
