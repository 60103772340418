import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {FetchedOrganization} from '@analytics/graphql-api';
import {PodcastRouteProps} from '..';

export type PodcastAudienceRouteProps = PodcastRouteProps & {
  experianApproval?: boolean;
  nielsenApproval?: boolean;
};

export const getPodcastAudienceRoutes = ({
  organization,
}: {
  organization: FetchedOrganization;
}) =>
  useRoutes({
    baseUrl: createOrgPath(organization, 'podcasts/:podcastId/audience'),
    basePath: '/:part(a|s|b|p)/:slug/podcasts/:podcastId/audience',
    routes: [
      {
        key: 'demographics',
        title: 'Demographics',
        path: '',
        exact: true,
      },
      {
        key: 'intent',
        title: 'Purchase Intent',
        exact: true,
      },
    ],
  });
