import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {
  ButtonPrimary,
  ButtonTertiary,
  FormCheckbox,
  FormGroup,
  ProgressCircle,
  TextLink,
  Type,
} from '@spotify-internal/encore-web';
import {Controller, useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import {useGetMeQuery} from '@core/graphql-api';
import {Card} from '@core/ui/Content';
import {FORM_ERRORS, Form, FormFooter} from '@core/ui/FormElements';
import {FormContainer} from '@analytics/components/onboard/OnboardLayout';
import {useOrganizationQuery} from '@analytics/graphql-api';
import CREATE_PIXEL_EVENT_RECORD from '@analytics/graphql-api/_old_mutations/CreatePixelEventRecord';
import ORGANIZATION_QUERY from '@analytics/graphql-api/_old_queries/OrganizationQuery';
import SpotifyBanner from '../../components/onboard/SpotifyBanner';
import {PageContainer} from '../account/AccountLayout';

const eventsData = [
  {
    name: 'page_view',
    type: 'page_view',
    category: 'Page views (base code)',
    defaultChecked: true,
    disabled: true,
    description: 'Measure page views and website visitors.',
  },
  {
    name: 'leads',
    type: 'lead',
    category: 'Leads',
    description:
      'Measure how often people share their information so you can contact them later.',
  },
  {
    name: 'product_view',
    type: 'lead',
    category: 'Product view',
    description: 'Measure how often people view a product',
  },
  {
    name: 'add_to_cart',
    type: 'lead',
    category: 'Adds to cart',
    description: 'Measure how often people add items to their cart.',
  },
  {
    name: 'purchase',
    type: 'purchase',
    category: 'Purchases',
    description: 'Measure how often people complete a purchase.',
  },
];

const OnboardEvent = () => {
  const {slug} = useParams<{slug: string}>();
  const history = useHistory();

  const {data: orgData, loading: orgLoading} = useOrganizationQuery({
    variables: {
      slug,
    },
  });
  const {data: userData, loading: userLoading} = useGetMeQuery();
  const organization = orgData?.me?.organization;
  const user = userData?.me;

  const [loading, setLoading] = useState<boolean>(false);
  const [createPixelEventRecord] = useMutation(CREATE_PIXEL_EVENT_RECORD, {
    refetchQueries: [ORGANIZATION_QUERY],
  });

  const defaultValues = eventsData.reduce((acc, event) => {
    acc[event.name] = event.defaultChecked;
    return acc;
  }, {});

  const {
    control,
    handleSubmit,
    formState: {errors},
    setError,
    getValues,
    register,
  } = useForm({defaultValues});

  const handleSkip = () => {
    history.push(`/onboard/terms/${slug}`);
  };

  const onSubmit = async () => {
    const formValues = getValues();
    const events = eventsData
      .filter((event) => formValues[event.name])
      .map((event) => ({
        name: event.name,
        type: event.type,
        installation: 'JAVASCRIPT',
      }));

    setLoading(true);

    try {
      for (let i = 0; i < events.length; i++) {
        const name = events[i].name;
        const eventType = events[i].type.toUpperCase();

        await createPixelEventRecord({
          variables: {
            input: {
              key: organization.keys[0].key,
              name,
              eventType,
              category: eventType === 'LEAD' ? name : '',
              installation: events[i].installation
                ? events[i].installation
                : 'JAVASCRIPT',
            },
          },
        });
      }
      history.push(`/onboard/terms/${slug}`);
    } catch (e) {
      setError(null, FORM_ERRORS.network);
    }
    setLoading(false);
  };

  if (orgLoading || userLoading) {
    return (
      <PageContainer>
        <SpotifyBanner advertising progress={70} />
        <ProgressCircle
          aria-label='ProgressCircle'
          style={{alignSelf: 'center', marginTop: '100px'}}
        />
      </PageContainer>
    );
  }

  if (!organization || !user) {
    return null;
  }

  return (
    <PageContainer>
      <SpotifyBanner advertising progress={60} />
      <>
        <FormContainer>
          <Type
            variant='alto'
            as='h1'
            style={{textAlign: 'center', marginBottom: 24, marginTop: 70}}>
            Add conversion events
          </Type>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              marginBottom: 48,
            }}>
            <Type
              variant='ballad'
              semanticColor='textSubdued'
              style={{marginBottom: 16}}>
              Conversion events are actions people take on your website. Add the
              events you want your Spotify Pixel to capture and measure. You can
              always edit events later{' '}
              <TextLink
                href='https://help.adanalytics.spotify.com/about-the-spotify-pixel'
                target='_blank'>
                Learn more
              </TextLink>
            </Type>
          </div>
          <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
            <Card rules={() => ({margin: 0})}>
              <Controller
                name='events'
                control={control}
                render={() => (
                  <FormGroup withFieldset>
                    {eventsData.map((event) => (
                      <Controller
                        key={event.name}
                        name={event.name}
                        control={control}
                        render={({field}) => (
                          <FormCheckbox
                            {...register('events')}
                            id={event.name}
                            {...field}
                            defaultChecked={event.defaultChecked}
                            disabled={event.disabled}>
                            <Type variant='mestoBold'>{event.category}</Type>
                            <br />
                            <Type variant='mesto' semanticColor='textSubdued'>
                              {event.description}
                            </Type>
                          </FormCheckbox>
                        )}
                      />
                    ))}
                  </FormGroup>
                )}
              />

              <FormFooter>
                <ButtonTertiary
                  style={{minWidth: 200}}
                  type='button'
                  onClick={handleSkip}>
                  Skip
                </ButtonTertiary>
                <ButtonPrimary disabled={loading} style={{minWidth: 200}}>
                  Next
                  {loading && <ProgressCircle style={{marginLeft: 20}} />}
                </ButtonPrimary>
              </FormFooter>
            </Card>
          </Form>
        </FormContainer>
      </>
    </PageContainer>
  );
};

export default OnboardEvent;
