import {FetchedUser} from '@core/graphql-api';
import {RouteType} from '@core/lib/useRoutes';

const campaignPodcastRoutes = ({user}: {user: FetchedUser}): RouteType[] => {
  return [
    {
      key: 'campaign-podcast',
      path: '',
      exact: true,
    },
    {
      key: 'update',
      exact: true,
    },
    {
      key: 'episodes',
    },
    {
      key: 'futures',
    },
    {
      key: 'prefix',
      exact: true,
    },
    {
      key: 'consistency',
      exact: true,
      isAvailable: user.isAdmin,
    },
  ];
};

export default campaignPodcastRoutes;
