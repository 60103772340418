import CampaignConsistency from '@analytics/components/campaigns/CampaignConsistency';

const CampaignEpisodeConsistency = ({
  organization,
  campaign,
  campaignPodcast,
  campaignEpisode,
}) => {
  return (
    <>
      <CampaignConsistency
        organizationId={organization.id}
        campaignId={campaign.id}
        campaignPodcastId={campaignPodcast.id}
        campaignEpisodeId={campaignEpisode.id}
      />
    </>
  );
};

export default CampaignEpisodeConsistency;
