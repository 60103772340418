import Steps from '@core/ui/Steps';

interface Props {
  active: string;
}
export default function AdvisorOnboardPublisherSteps({active}: Props) {
  return (
    <Steps
      active={active}
      steps={[
        {title: 'Podcasts', value: 'podcasts'},
        {title: 'Capabilities', value: 'capabilities'},
      ]}
      css={`
        margin: 2rem 1.5rem 1.75rem;
      `}
    />
  );
}
