import {CampaignCheckoutStats} from '@analytics/components/campaigns/stats/CampaignCheckoutStats';
import {CampaignPixelRouteProps} from '../_routes';

export function CampaignCheckoutsIndex({
  organization,
  campaign,
}: CampaignPixelRouteProps) {
  return (
    <>
      <CampaignCheckoutStats organization={organization} campaign={campaign} />
    </>
  );
}
