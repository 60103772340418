import {Control, Controller} from 'react-hook-form';
import {Input, Label} from '@core/ui/FormElements';
import {CampaignFormData} from '../_types';
import {FormGroup} from './_shared';

export function CampaignNameField({
  control,
}: {
  control: Control<CampaignFormData>;
}) {
  return (
    <FormGroup>
      <Label htmlFor='name'>Campaign Name</Label>
      <Controller
        name='name'
        control={control}
        rules={{required: 'Please provide a campaign name.'}}
        render={({field, fieldState}) => (
          <Input
            id='name'
            defaultValue={field.value}
            invalid={!!fieldState.error}
            onChange={field.onChange}
            placeholder='Campaign Name'
          />
        )}
      />
    </FormGroup>
  );
}
