import {gql} from '@apollo/client';

export default gql`
  fragment AttributionConfig on AttributionConfigObject {
    id
    householdInterval
    cableInterval
    cellularInterval
    corporateInterval
    attributionInterval
    maxUsersPerHousehold
  }
`;
