import {gql} from '@apollo/client';

export default gql`
  fragment CampaignDynamicPodcast on CampaignDynamicPodcastObject {
    id
    cost
    costToDate

    downloadTotal
    reachTotal
    attributionTotal
    userTotal
    userTotalModeled
    productTotal
    productTotalModeled
    addtocartTotal
    addtocartTotalModeled
    checkoutTotal
    checkoutTotalModeled
    purchaseTotal
    purchaseTotalModeled
    leadTotal
    leadTotalModeled
    valueTotal
    valueTotalModeled
    installedTotal
    installedTotalModeled

    targetDownloadTotal
    targetReachTotal

    startAt
    endAt

    podcast {
      id
    }

    feed {
      id
      title
      description(words: 50)
      image {
        href
        id
        path
      }
    }
  }
`;
