import {Element} from '@core/style';
import {ResearchNav} from '@analytics/components/nav/ResearchNav';
import CompaniesFilter from '@analytics/components/research/CompaniesFilter';

export default function HomeCompanies({user, organization}) {
  return (
    <>
      <ResearchNav user={user} organization={organization} />
      <Element
        tag='p'
        rules={({theme}) => ({
          color: theme.textTertiary,
          padding: '0 1.5rem',
        })}>
        Ad Analytics Research samples hundreds of podcasts, including the iTunes
        top 200, and identifies ads on a daily basis. The following are the
        brands we found buying those ads.
      </Element>
      <CompaniesFilter organization={organization} />
    </>
  );
}
