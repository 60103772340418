import {Switch} from 'react-router-dom';
import {withLiftNav} from '@analytics/components/nav/LiftNav';
import {MeasureRouteProps} from '../..';
import {SpotifyLiftReports} from './SpotifyLiftReports';
import {getSpotifyLiftRoutes} from './_routes';
import {SpotifyLiftReportIndex} from './report';

export function SpotifyLiftIndex({organization, user}: MeasureRouteProps) {
  const {renderRoutes} = getSpotifyLiftRoutes({organization});
  return (
    <Switch>
      {renderRoutes({
        components: {
          reports: withLiftNav(SpotifyLiftReports),
          report: SpotifyLiftReportIndex,
        },
        extraProps: {
          organization,
          user,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
