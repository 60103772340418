import {gql} from '@apollo/client';
import CampaignLinkFragment from '@analytics/graphql-api/_old_queries/fragments/CampaignLinkFragment';

export default gql`
  mutation createCampaignLink($input: CreateCampaignLinkInput!) {
    authed {
      success
    }
    createCampaignLink(input: $input) {
      campaignLink {
        ...CampaignLink
      }
    }
  }
  ${CampaignLinkFragment}
`;
