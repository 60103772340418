import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {useTheme} from '@core/style';
import {Dummy, Img} from '@core/ui/Image';
import {Tooltip as TooltipBase} from '@core/ui/Tooltip';

const getIndexPosition = (index: number) => {
  if (index >= 196) return '98%';
  return `${(index / 200) * 100}%`;
};

const AudienceTooltip = ({
  index,
  percentage,
  unavailable,
}: {
  index: number;
  percentage: number;
  unavailable: boolean;
}) => {
  const theme = useTheme();
  return (
    <>
      {unavailable ? (
        <div css={{textAlign: 'center'}}>Data unavailable</div>
      ) : (
        <div css={{minWidth: '6.75rem'}}>
          <div css={{display: 'flex', justifyContent: 'space-between'}}>
            <div>Percentage:</div>
            <div css={{display: 'flex', alignItems: 'center'}}>
              <div>{percentage}%</div>
              <div
                css={{
                  background: 'var(--blue-80)',
                  borderRadius: '50%',
                  marginLeft: '0.15rem',
                  height: '6px',
                  width: '6px',
                }}
              />
            </div>
          </div>
          <div css={{display: 'flex', justifyContent: 'space-between'}}>
            <div>Index:</div>
            <div css={{display: 'flex', alignItems: 'center'}}>
              <div>{index}</div>
              <div
                css={{
                  background:
                    index >= 100
                      ? 'var(--green)'
                      : theme == 'light'
                      ? '#6a737d'
                      : '#b1bac4',
                  borderRadius: '50%',
                  marginLeft: '0.15rem',
                  height: '6px',
                  width: '6px',
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const AudienceBar = ({
  index,
  percentage,
  unavailable,
}: {
  index: number;
  percentage: number;
  unavailable: boolean;
}) => {
  const theme = useTheme();
  return (
    <TooltipBase
      description={
        <AudienceTooltip
          index={index}
          percentage={percentage}
          unavailable={unavailable}
        />
      }>
      <div
        css={{
          background: 'var(--bg-muted)',
          margin: '0.125rem 0',
          position: 'relative',
          height: '1.5rem',
          width: '100%',
          minWidth: '4rem',
        }}>
        {!unavailable && (
          <div
            css={{
              background: 'var(--blue-80)',
              position: 'absolute',
              height: 'inherit',
              width: `${percentage}%`,
            }}
          />
        )}
        <div
          css={{
            background: theme === 'light' ? 'var(--white)' : 'var(--black)',
            marginLeft: '50%',
            position: 'absolute',
            height: 'inherit',
            width: '0.175rem',
          }}
        />
        {!unavailable && (
          <div
            css={{
              background:
                index >= 100
                  ? 'var(--green)'
                  : theme === 'light'
                  ? '#6a737d'
                  : '#b1bac4',
              marginLeft: getIndexPosition(index),
              position: 'absolute',
              height: 'inherit',
              width: '0.185rem',
            }}
          />
        )}
      </div>
    </TooltipBase>
  );
};

const AudienceCell = ({data, topic}) => {
  const width = {
    Age: 40,
    Income: 64,
    Education: 95,
    'Political Party': 78,
  };

  return (
    <div
      css={{
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: `${width[topic] ? width[topic] : 75}px auto`,
      }}>
      {data.map(({variable, index, percentage, unavailable}) => (
        <>
          <div
            key={`${variable}-${percentage}`}
            css={{
              fontSize: '95%',
              lineHeight: '1',
              marginRight: '0.15rem',
              textAlign: 'right',
            }}>
            {variable}
          </div>
          <AudienceBar
            index={index}
            percentage={percentage}
            unavailable={unavailable}
          />
        </>
      ))}
      <div />
      <div css={{fontSize: '95%', textAlign: 'center'}}>100</div>
    </div>
  );
};

const EmptyCell = () => {
  return (
    <div css={{minWidth: '8rem'}}>
      <TooltipBase description='Data unavailable.'>-</TooltipBase>
    </div>
  );
};

const PodcastCell = ({podcast, image, id, organization}) => {
  const history = useHistory();
  return (
    <div
      onClick={() =>
        history.push(createOrgPath(organization, `podcasts/${id}`))
      }
      css={{
        alignItems: 'center',
        display: 'inline-flex',
        padding: '0.75rem 0 1.25rem',
        ':hover': {
          cursor: 'pointer',
        },
      }}>
      <div
        css={{
          flex: '0 0 4.5rem',
          height: '4.5rem',
          marginRight: '0.625rem',
          width: '4.5rem',
        }}>
        {image ? <Img image={image} /> : <Dummy />}
      </div>
      <div
        css={{
          fontWeight: 500,
          lineHeight: 1.4,
          ':hover': {
            color: 'var(--blue-80)',
          },
        }}>
        {podcast}
      </div>
    </div>
  );
};

export default AudienceCell;
export {PodcastCell, EmptyCell};
