import {Card, CardHeader, PageTitle} from '@core/ui/Content';
import {CampaignsTable} from '@analytics/components/campaigns/CampaignsTable';
import {SpotifySalesOrdersCard} from '@analytics/components/campaigns/spotify-orders';
import UpcomingEpisodesChart from '@analytics/components/upcoming-episodes/UpcomingEpisodesChart';
import {FetchedOrganization} from '@analytics/graphql-api';

type Props = {
  organization: FetchedOrganization;
};

export function BrandHome({organization}: Props) {
  return (
    <>
      <PageTitle>{organization.name}</PageTitle>
      <SpotifySalesOrdersCard organization={organization} />
      <Card>
        <CardHeader>Attribution Campaigns</CardHeader>
        <CampaignsTable
          organization={organization}
          emptyMessageText='Your organization is not part of any campaign yet.'
          filtersStorageKey={`campaigns-brand-attribution-${organization.slug}`}
        />
      </Card>
      <Card>
        <CardHeader>Upcoming Episodes</CardHeader>
        <UpcomingEpisodesChart organization={organization} />
      </Card>
    </>
  );
}
