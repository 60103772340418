window.dataLayer = window.dataLayer || [];

export const gtag = function gtag(...props: any): void {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
};

export const installGtag = ({
  trackingId,
  userId,
}: {
  trackingId: string;
  userId?: string;
}): void => {
  if (document.getElementById('ga-gtag')) {
    return;
  }

  const {head} = document;
  const script = document.createElement('script');
  script.id = 'ga-gtag';
  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  head.insertBefore(script, head.firstChild);

  gtag('js', new Date());
  gtag('config', trackingId);
  if (userId) {
    gtag('set', 'dimension1', userId);
    gtag('set', {user_id: userId});
  }
};

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    dataLayer: any;
  }
}
