import {useState} from 'react';
import {useMutation} from '@apollo/client';
import Button from '@core/ui/Button/LegacyButton';
import {ModalHeader} from '@core/ui/Content';
import {FormFooter, Submit} from '@core/ui/FormElements';
import {Overlay} from '@core/ui/Overlay';
import REMOVE_DOMAIN_MUTATION from '@analytics/graphql-api/_old_mutations/RemoveDomain';
import MEMBERS_QUERY from '@analytics/graphql-api/_old_queries/MembersQuery';

interface IMembersDomainDeleteFormOverlayProps {
  domainId: string;
  toggle: (to?: boolean) => void;
  opened: boolean;
}

const MembersDomainDeleteFormOverlay = ({
  opened,
  toggle,
  domainId,
}: IMembersDomainDeleteFormOverlayProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [removeDomain] = useMutation(REMOVE_DOMAIN_MUTATION, {
    refetchQueries: [MEMBERS_QUERY],
  });

  const onSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    try {
      await removeDomain({
        variables: {
          input: {
            id: domainId,
          },
        },
      });
      toggle();
    } catch (err) {}
    setLoading(false);
  };

  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      closeOnEscKey
      centered
      closeOnOutsideClick
      withBackdrop
      css={`
        min-width: 37.5rem;
        padding: 1.25rem;
      `}>
      <ModalHeader toggle={toggle}>Delete Domain</ModalHeader>
      <form onSubmit={onSubmit}>
        <p>
          Removing an associated email domain prevents new users from that
          domain from automatically joining this Organization. It does not
          remove any exisiting users.
        </p>
        <FormFooter>
          <Button style-default onClick={toggle}>
            Cancel
          </Button>
          <Submit loading={loading} style-destructive style-secondary={false}>
            Remove Domain
          </Submit>
        </FormFooter>
      </form>
    </Overlay>
  );
};

export default MembersDomainDeleteFormOverlay;
