import {gql} from '@apollo/client';

export default gql`
  mutation deleteCampaign($input: DeleteCampaignInput!) {
    authed {
      success
    }
    deleteCampaign(input: $input) {
      success
    }
  }
`;
