import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import CampaignLinksTable from '@analytics/components/campaigns/CampaignLinksTable';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignRouteProps} from '../_routes';

export function CampaignLinks({
  user,
  organization,
  campaign,
  match: {url},
}: CampaignRouteProps & {
  match: {
    url: string;
  };
}) {
  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <CardHeader
        rightContent={
          <Button color='secondary' to={`${url.replace(/\/$/, '')}/create`}>
            Create Link
          </Button>
        }>
        Links
      </CardHeader>
      <Card>
        <CampaignLinksTable
          organization={organization}
          campaign={campaign}
          campaignLinks={campaign.campaignLinks}
        />
      </Card>
    </>
  );
}
