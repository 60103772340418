import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {FORM_ERRORS, Form, FormFooter, Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignsDocument} from '@analytics/graphql-api';
import DELETE_CAMPAIGN_MUTATION from '@analytics/graphql-api/_old_mutations/DeleteCampaign';
import {CampaignRouteProps, toCampaignUrl} from './_routes';

export function CampaignDelete({
  organization,
  campaign,
  user,
}: CampaignRouteProps) {
  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN_MUTATION);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();

  const onSubmit = async () => {
    setLoading(true);

    try {
      await deleteCampaign({
        variables: {input: {id: campaign.id}},
        refetchQueries: [CampaignsDocument],
      });
      history.push(createOrgPath(organization, '/measure/campaigns'));
    } catch (err) {
      setError('networkError', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <ResponsiveContent containerSize='medium'>
        <CardHeader backPath={toCampaignUrl(organization, campaign)}>
          Delete Campaign
        </CardHeader>
        <Card>
          <p>
            This action is permanent. You can only delete campaigns that are
            still in draft.
          </p>
          <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
            <FormFooter>
              <Button type='button' to={toCampaignUrl(organization, campaign)}>
                Cancel
              </Button>
              <Submit color='error' loading={loading}>
                Delete Campaign
              </Submit>
            </FormFooter>
          </Form>
        </Card>
      </ResponsiveContent>
    </>
  );
}
