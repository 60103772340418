// TODO-SPAAF-51 move this to its own component
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {FetchedUser, useGetMeQuery} from '@core/graphql-api';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  useSetAdAnalyticsTutorialProgressMutation,
} from '@analytics/graphql-api';
import LayoutLeftNavItem from './LayoutLeftNavItem';
import OnboardingPopover from './OnboardingPopover';
import {useLeftNavItems} from './helpers';

const getCurrentPath = (
  organization: FetchedOrganization | FetchedBuySideOrganization
): string => {
  const match = window.location.pathname.match(`${organization.slug}(.*)`);
  if (match) {
    return match[1].replace(/\/$/, '');
  }
  return '';
};

type Props = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
};

const TutorialPages = ['Measure', 'Measure', 'Measure', 'Help Center'];

export default function LayoutLeftNav({user, organization}: Props) {
  const currentPath = getCurrentPath(organization);
  const navItems = useLeftNavItems({organization, user});
  const {data, loading, error, refetch} = useGetMeQuery();
  const [setAdAnalyticsTutorialProgress] =
    useSetAdAnalyticsTutorialProgressMutation();
  const [currentTutorialPage, setCurrentTutorialPage] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (
      !loading &&
      !error &&
      currentTutorialPage === null &&
      typeof data?.me?.adAnalyticsTutorialProgress === 'number'
    ) {
      // Initialize the current tutorial page to the user's current progress

      setCurrentTutorialPage(data?.me?.adAnalyticsTutorialProgress);
    }
  }, [loading, error, data, currentTutorialPage]);

  const updateTutorialProgress = (progress) => {
    setAdAnalyticsTutorialProgress({
      variables: {
        input: {progress},
      },
    });
    setCurrentTutorialPage(progress);
    refetch();
  };

  return (
    <Nav>
      {navItems.map((item, i) => {
        const shouldShowOverlay =
          !loading &&
          !error &&
          'campaignsCount' in organization &&
          organization?.campaignsCount > 0 &&
          (organization?.slug === 'spotify' ||
            organization?.kind !== 'PODCAST') &&
          typeof currentTutorialPage === 'number' &&
          currentTutorialPage < TutorialPages.length &&
          TutorialPages[currentTutorialPage] === item.label;

        const navItem = (
          <LayoutLeftNavItem
            key={i}
            currentPath={currentPath}
            organization={organization}
            {...item}
          />
        );

        if (!shouldShowOverlay) {
          return navItem;
        }

        return (
          <OnboardingPopover
            key={i}
            totalTutorialPages={TutorialPages.length}
            navItem={navItem}
            shouldShowOverlay={shouldShowOverlay}
            currentTutorialPage={currentTutorialPage}
            onCloseClick={() => updateTutorialProgress(TutorialPages.length)}
            onNextClick={() => updateTutorialProgress(currentTutorialPage + 1)}
            slug={organization.slug}
          />
        );
      })}
    </Nav>
  );
}

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-weight: 500;
  margin: 0.5rem 0 0 1rem;
`;
