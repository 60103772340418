import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import AdvisorOptionsCapabilitiesWrapper from '@analytics/components/advisor/AdvisorOptionsCapabilities';
import AdvisorOnboardPublisherSteps from '@analytics/components/advisor/onboarding/publisher/AdvisorOnboardPublisherSteps';
import {AdvisorRouteProps} from '../../_routes';

export function AdvisorOnboardPublisherCapabilities({
  organization,
}: AdvisorRouteProps) {
  const history = useHistory();

  const toAdvisor = () => {
    // TODO: quick fix until we fix cache
    // https://app.asana.com/0/1202498986979151/1203330375720469/f
    window.location.pathname = createOrgPath(organization, '/advisor');
  };

  const toOnboardPublisherIndex = () =>
    history.push(`${createOrgPath(organization)}/advisor/onboard/publisher`);

  return (
    <>
      <AdvisorOnboardPublisherSteps active='capabilities' />
      <AdvisorOptionsCapabilitiesWrapper
        organization={organization}
        onSave={toAdvisor}
        onCancel={toOnboardPublisherIndex}
      />
    </>
  );
}
