import {Controller} from 'react-hook-form';
import {FormHelperText} from '@core/ui/Form New';
import {Label} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {Link} from '@core/ui/Link';
import Select from '@core/ui/Select';
import {
  ExportType,
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {ExportTypeId, FieldProps} from '../helpers';

type Props = FieldProps & {
  exportTypes: ExportType[];
  organization: FetchedOrganization | FetchedBuySideOrganization;
};

export function TypeField({
  control,
  exportTypes,
  organization,
  reset,
  watch,
}: Props) {
  const docsLink =
    organization.kind === 'PODCAST'
      ? 'https://help.adanalytics.spotify.com/report-type-for-publishers'
      : 'https://help.adanalytics.spotify.com/report-types-for-brands-and-agencies';

  const onSelectType = (item: ExportType) => {
    const isOrgReport = item.id.indexOf('organization') === 0;
    reset({
      name: watch('name'),
      typeId: item.id as ExportTypeId,
      fields: [],
      orderBy: [],
      exportParameters: isOrgReport
        ? {
            instanceId: organization.id,
          }
        : {},
    });
  };

  return (
    <Stack direction='column'>
      <Label htmlFor='reportType'>Report Type</Label>
      <Controller
        name='typeId'
        control={control}
        rules={{required: 'Please select a data set'}}
        render={({field, fieldState: {error}}) => (
          <Select
            buttonProps={{id: 'reportType'}}
            defaultValue={field.value}
            invalid={!!error}
            items={exportTypes}
            onSelect={onSelectType}
            outline
            placeholder='Report Type'
            propertyForName='name'
            propertyForValue='id'
          />
        )}
      />
      <FormHelperText css={{marginTop: 'var(--spacing-2)'}}>
        <Link href={docsLink} underline='always' color='muted'>
          Learn more
        </Link>{' '}
        about different report types.
      </FormHelperText>
    </Stack>
  );
}
