import {useState} from 'react';
import {
  ButtonPrimary,
  ButtonTertiary,
  ProgressCircle,
  Type,
} from '@spotify-internal/encore-web';
import {Controller, useForm} from 'react-hook-form';
import {t} from '@core/texts';
import {ToastMessage} from '@core/ui/Alert';
import {Card} from '@core/ui/Content';
import {FORM_ERRORS, Form, FormGroup, Label} from '@core/ui/FormElements';
import {LoadingSpinner} from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {MultipleSelect} from '@core/ui/Select';
import {
  CampaignTrackingUrlsDocument,
  ProviderNameObject,
  useOrganizationProvidersQuery,
  useUpdateOrganizationMutation,
} from '@analytics/graphql-api';
import ORGANIZATION_QUERY from '@analytics/graphql-api/_old_queries/OrganizationQuery';
import {ManageAdInsertionProviderRouteProps} from '.';

const getDefaultItems = (providerString: string) =>
  providerString?.split(',') || [''];

type Props = ManageAdInsertionProviderRouteProps & {
  onSuccess: () => void;
  skippable: boolean;
};

export default function AddAdInsertionProvider({
  organization,
  onSuccess,
  skippable,
}: Props) {
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateOrganization] = useUpdateOrganizationMutation();

  const {
    data,
    loading: providersLoading,
    error,
  } = useOrganizationProvidersQuery({
    variables: {
      organizationId: organization.id,
    },
  });

  const {
    control,
    clearErrors,
    formState: {errors, isDirty},
    handleSubmit,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      dynamicProvider: organization.dynamicProvider || '',
      streamingProvider: organization.streamingProvider || '',
      networkError: false,
    },
  });

  const onSubmit = async ({dynamicProvider, streamingProvider}) => {
    setLoading(true);
    if (errors.networkError) clearErrors('networkError');
    try {
      const resp = await updateOrganization({
        variables: {
          input: {
            id: organization.id,
            dynamicProvider,
            streamingProvider,
          },
        },
        refetchQueries: [ORGANIZATION_QUERY, CampaignTrackingUrlsDocument],
      });

      if (resp) {
        reset({}, {keepValues: true});
        setComplete(true);
      }
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      setError('networkError', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  const getSelectLabel = (items: ProviderNameObject[]) =>
    items.map(({providerNameDisplay}) => providerNameDisplay).join(', ');

  return (
    <ResponsiveContent containerSize='medium'>
      {complete && (
        <ToastMessage alertType='success'>
          Successfully updated Ad Insertion Providers
        </ToastMessage>
      )}
      <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            textAlign: 'center',
            marginTop: '4rem',
            marginBottom: '4rem',
          }}>
          <Type variant='alto'>Select your ad insertion providers</Type>
        </div>
        <Card>
          {providersLoading ? (
            <LoadingSpinner centered />
          ) : error ? (
            <ToastMessage alertType='error' rules={() => ({margin: '1rem 0'})}>
              {t('errors.network')}
            </ToastMessage>
          ) : (
            <>
              <FormGroup>
                <Label>Dynamic Provider(s)</Label>
                <Controller
                  name='dynamicProvider'
                  control={control}
                  render={({field}) => (
                    <MultipleSelect
                      defaultValue={getDefaultItems(field.value)}
                      getSelectLabel={getSelectLabel}
                      items={
                        data?.me?.organization?.dynamicProviderOptions ?? []
                      }
                      onSelect={(items) =>
                        field.onChange(
                          items.map(({providerName}) => providerName).join(',')
                        )
                      }
                      placeholder='Select...'
                      propertyForValue='providerName'
                      propertyForName='providerNameDisplay'
                      rules={() => ({width: '100%'})}
                      removeOption
                      withMargin
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Label>Streaming Provider(s)</Label>
                <Controller
                  name='streamingProvider'
                  control={control}
                  render={({field}) => (
                    <MultipleSelect
                      defaultValue={getDefaultItems(field.value)}
                      getSelectLabel={getSelectLabel}
                      onSelect={(items) =>
                        field.onChange(
                          items.map(({providerName}) => providerName).join(',')
                        )
                      }
                      items={
                        data?.me?.organization?.streamingProviderOptions ?? []
                      }
                      placeholder='Select...'
                      propertyForValue='providerName'
                      propertyForName='providerNameDisplay'
                      rules={() => ({width: '100%'})}
                      removeOption
                      withMargin
                    />
                  )}
                />
              </FormGroup>
            </>
          )}
        </Card>

        <div style={{textAlign: 'center'}}>
          {skippable && (
            <ButtonTertiary
              style={{minWidth: '150px'}}
              type='button'
              onClick={onSuccess}
              color='secondary'
              disabled={loading}>
              Skip
            </ButtonTertiary>
          )}
          <ButtonPrimary
            style={{minWidth: '150px'}}
            color='secondary'
            disabled={loading || !isDirty}>
            Next {loading && <ProgressCircle style={{marginLeft: 20}} />}
          </ButtonPrimary>
        </div>
      </Form>
    </ResponsiveContent>
  );
}
