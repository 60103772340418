import {Redirect} from 'react-router-dom';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import {ButtonIcon} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Icon} from '@core/ui/Icon';
import {ManagePixelsRouteProps} from './_routes';

export function Pixels({organization}: ManagePixelsRouteProps) {
  if (
    !organization.keys.length ||
    (dayjs(organization.keys?.[0]?.createdAt).isSameOrAfter('2022-05-03') &&
      !organization.keys?.[0]?.pixelEventRecords?.length)
  ) {
    return <Redirect to={createOrgPath(organization, 'manage/pixels/add')} />;
  }

  const columns: DataTableColumn[] = [
    {
      title: 'Pixel ID',
      accessor: 'key',
      type: 'string',
    },
    {
      title: 'Domain',
      accessor: 'domain',
      type: 'string',
    },
    {
      title: 'Alias Events',
      accessor: 'alias',
      type: 'string',
      Cell: ({data: {key, alias}}) =>
        !!alias ? (
          <Icon
            icon='checkbox-marked-circle'
            rules={() => ({
              color: 'var(--green)',
              height: '1.25rem',
              width: '1.25rem',
            })}
          />
        ) : (
          <ButtonIcon
            onClick={(e) => e.stopPropagation()}
            to={createOrgPath(organization, `manage/pixels/${key}/update`)}
            icon='add'
          />
        ),
    },
    {
      title: 'Created',
      accessor: 'createdAt',
      type: 'date',
      fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
    },
    {
      title: '',
      accessor: 'key',
      type: 'string',
      Cell: ({data: {key}}) => (
        <ButtonIcon
          onClick={(e) => e.stopPropagation()}
          to={createOrgPath(organization, `manage/pixels/${key}/update`)}
          icon='pencil'
        />
      ),
    },
  ];

  const data = organization.keys
    .slice(0)
    .map(({alias, key, domain, createdAt}) => ({
      id: key,
      alias,
      key,
      domain,
      createdAt,
    }));

  return (
    <Card>
      <CardHeader>Your Pixels</CardHeader>
      <DataTable
        columns={columns}
        data={data}
        orderBy='-createdAt'
        onClickRowPath={({key}) =>
          createOrgPath(organization, `manage/pixels/${key}`)
        }
        emptyMessageText='No pixels found.'
      />
    </Card>
  );
}
