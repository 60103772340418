import React from 'react';
import {withRouter} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {truncateWords} from '@core/lib/words';
import {Element} from '@core/style';
import DataSelector from '@core/ui/DataSelector';
import SearchQuery from '@analytics/graphql-api/_old_queries/research/SearchQuery';

const SearchItemComponent = ({kind, title, subtitle}) => {
  const _kind = kind === 'company' ? 'Company' : 'Podcast';

  return (
    <>
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
        })}>
        <Element rules={() => ({fontWeight: 500})}>{title}</Element>
        <Element
          rules={({theme}) => ({
            color: theme.textTertiary,
            fontSize: '.875rem',
            marginLeft: 'auto',
            paddingLeft: '1rem',
            whiteSpace: 'nowrap',
          })}>
          {_kind}
        </Element>
      </Element>
      <Element
        rules={({theme}) => ({
          color: theme.textTertiary,
          fontSize: '.875rem',
        })}>
        {subtitle ? <Element>{truncateWords(subtitle, 20)}</Element> : null}
      </Element>
    </>
  );
};

const Search = ({organization, rules, history, ...props}) => {
  const onSelect = (data) => {
    const path = createOrgPath(
      organization,
      `/research/${data.kind === 'company' ? 'brands' : 'podcasts'}/${data.id}`
    );
    history.push(path);
  };

  return (
    <DataSelector
      dataQuery={SearchQuery}
      overlayProps={{
        horizontalAlign: 'left',
      }}
      renderItem={(item) => <SearchItemComponent {...item} />}
      onSelect={onSelect}
      placeholder='Search podcast'
      queryVariables={{
        organizationId: organization.id,
      }}
      fromResponse={(data) => {
        return data.me.organization.research.search.results;
      }}
      {...props}
    />
  );
};

export default withRouter(Search);
