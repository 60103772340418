import { screenSmMin, screenXsMax } from '@spotify-internal/encore-foundation';
import { controlSizeFallback, cssBorderRadius, cssControlSize, cssSpacing, formControlBase, formControlTheme, getFormPaddingBlock, getFormPaddingInline, rootStyle, slControlSize } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
export var Input = styled.input.withConfig({
  displayName: "Input",
  componentId: "sc-1gbx9xe-0"
})(["", ""], function (_ref) {
  var $size = _ref.$size,
    $isUsingKeyboard = _ref.$isUsingKeyboard,
    $isHover = _ref.$isHover,
    $isFocus = _ref.$isFocus,
    $error = _ref.$error,
    $useBrowserDefaultFocusStyle = _ref.$useBrowserDefaultFocusStyle;
  return css(["", ";", ";margin-block-start:0;margin-block-end:0;border-radius:", ";padding-inline-start:", ";padding-inline-end:", ";padding-block-start:", ";padding-block-end:", ";min-block-size:", ";", ";&[type='date'],&[type='month'],&[type='time'],&[type='week']{@media (min-width:", "){padding-block-end:", ";}}&[type='color']{@media (max-width:", "){padding:0;border-block-end:0;&:focus,&:hover:focus{padding:0;}@media not all and (resolution >= 0.001dpcm){@supports (-webkit-appearance:none){border-block-end:1px solid;padding-block-start:10px;padding-block-end:9px;&:focus,&:hover:focus{padding-block-start:10px;padding-block-end:", ";border-block-end-width:2px;}}}}@media (min-width:", "){padding:", ";block-size:", ";inline-size:", ";@media not all and (resolution >= 0.001dpcm){@supports (-webkit-appearance:none){inline-size:100%;padding:14px;}}}}&[type='file']{padding-inline-start:0;padding-inline-end:0;padding-block-start:", ";padding-block-end:0;line-height:17px;@media (max-width:", "){border-block-end:0;}@media (min-width:", "){box-shadow:none;}&:focus,&:hover:focus{@media (min-width:", "){box-shadow:none;}}}&[type='search']::-webkit-search-cancel-button{display:none;}"], formControlBase($useBrowserDefaultFocusStyle, $size), rootStyle(), cssBorderRadius('base', '4px'), getFormPaddingInline($size), getFormPaddingInline($size), getFormPaddingBlock($size), getFormPaddingBlock($size), cssControlSize(slControlSize($size), controlSizeFallback[$size]), formControlTheme({
    isUsingKeyboard: $isUsingKeyboard,
    isHover: $isHover,
    isFocus: $isFocus,
    error: $error,
    useBrowserDefaultFocusStyle: $useBrowserDefaultFocusStyle
  }), screenSmMin, cssSpacing('tighter', '12px'), screenXsMax, cssSpacing('tighter-2', '8px'), screenSmMin, cssSpacing('tighter-4', '4px'), cssControlSize('base', '48px'), cssControlSize('base', '48px'), cssSpacing('tighter-2', '8px'), screenXsMax, screenSmMin, screenSmMin);
});