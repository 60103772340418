import {
  IconDownload,
  IconEnhance,
  IconPayment,
  IconPlaylist,
  IconSpotifyLogo,
  IconTrending,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  display: flex;
  margin-bottom: 1em;
`;

const IconWrapper = styled.div`
  flex: 0 0 auto;
  margin-right: 1em;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6a6a6a;
`;

const content = {
  brand: [
    {
      title: 'Measure all your ads on-Spotify',
      description:
        'See delivery and measure results across Spotify’s audio, video, and display formats on podcasts and music.',
      icon: IconPlaylist,
    },
    {
      title: 'Supercharged by streaming',
      description:
        'Understand exactly how your audience engages on Spotify with precise reporting and insights.',
      icon: IconSpotifyLogo,
    },
    {
      title: 'Continue to track conversions off-Spotify',
      description:
        'Track conversions from your audio campaigns wherever else you’re buying, on and off Spotify.',
      icon: IconDownload,
    },
  ],
  publisher: [
    {
      title: 'Prove campaign performance',
      description:
        'Tap into attribution and conversion lift reporting across your audio inventory.',
      icon: IconTrending,
    },
    {
      title: 'Get discovered by new advertisers',
      description:
        'Enroll in Advisor, which helps brands discover 700+ podcasts that align with their target audiences.',
      icon: IconEnhance,
    },
    {
      title: 'Did we mention it’s free?',
      description:
        'You heard us. Measure unlimited impressions for an unlimited number of advertisers, free of charge.',
      icon: IconPayment,
    },
  ],
  agency: [
    {
      title: 'Measure all of your ads on-Spotify',
      description:
        'See delivery and measure results across audio, video, and display formats for podcasts and music on Spotify.',
      icon: IconPlaylist,
    },
    {
      title: 'Supercharged by streaming',
      description:
        'Understand exactly how your audience engages on Spotify with precise reporting and insights.',
      icon: IconSpotifyLogo,
    },
    {
      title: 'Continue to track conversions off-Spotify',
      description:
        'Track conversions from your audio campaigns wherever else you’re buying, on and off Spotify.',
      icon: IconDownload,
    },
  ],
};

const IntroModalContent = ({kind, slug}) => {
  let contentItems = content.brand;
  if (!(slug === 'spotify')) {
    if (kind === 'BUY_SIDE' || kind === 'SELL_SIDE') {
      contentItems = content.agency;
    } else if (kind === 'PODCAST') {
      contentItems = content.publisher;
    }
  }

  return (
    <>
      {contentItems.map(({title, description, icon: IconComponent}, index) => (
        <ContentWrapper key={index}>
          <IconWrapper>
            <IconComponent />
          </IconWrapper>
          <div>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </div>
        </ContentWrapper>
      ))}
    </>
  );
};

export default IntroModalContent;
