import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {useCampaignActivityLogQuery} from '@analytics/graphql-api';
import {CampaignRouteProps} from '../_routes';
import {ActivityLogTable} from './table/ActivityLogTable';
import {parseAuditLogEvents} from './table/templates';

export const CampaignActivityLog = ({
  campaign,
  user,
  organization,
}: CampaignRouteProps) => {
  const {loading, error, data} = useCampaignActivityLogQuery({
    variables: {
      organizationId: organization.id,
      slug: campaign.slug,
    },
  });

  if (loading) return <Loading fixed />;
  if (error || !data) {
    // eslint-disable-next-line react/no-children-prop
    return <Alert severity='warning' children={t('errors.network')} />;
  }

  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <Card scrollable>
        <CardHeader>Activity Log</CardHeader>
        <ActivityLogTable
          auditLogEvents={parseAuditLogEvents(
            data.me?.organization?.campaign?.auditLogEvents
          )}
        />
      </Card>
    </>
  );
};
