import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignLiftRouteProps} from '../../_routes';
import CampaignNoiseDelete from './CampaignNoiseDelete';
import CampaignNoiseUpdate from './CampaignNoiseUpdate';

export function CampaignNoiseIndex({
  user,
  organization,
  campaign,
  match: {
    params: {campaignNoiseId},
  },
}: CampaignLiftRouteProps & {
  match: {
    params: {campaignNoiseId: string};
  };
}) {
  const campaignNoise = campaign.campaignNoises.filter(
    ({id}) => id == campaignNoiseId
  )[0];

  return (
    <>
      <Switch>
        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/campaigns/:campaignSlug/lift/households/:campaignNoiseId'
          user={user}
          organization={organization}
          campaign={campaign}
          campaignNoise={campaignNoise}
          component={CampaignNoiseUpdate}
        />
        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/campaigns/:campaignSlug/lift/households/:campaignNoiseId/delete'
          user={user}
          organization={organization}
          campaign={campaign}
          campaignNoise={campaignNoise}
          component={CampaignNoiseDelete}
        />
      </Switch>
    </>
  );
}
