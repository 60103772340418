import {useCallback, useMemo} from 'react';
import {GREEN} from '@core/style';
import {IconButton} from '@core/ui/Button/LegacyButton';
import {DataTableServer} from '@core/ui/DataTable';
import {Icon} from '@core/ui/Icon';
import PODCASTER_CAMPAIGN_EPISODES_NODES from '@analytics/graphql-api/_old_queries/PodcasterCampaignEpisodesNodesQuery';
import PODCASTER_CAMPAIGN_FUTURE_EPISODES_NODES from '@analytics/graphql-api/_old_queries/PodcasterCampaignFutureEpisodesNodesQuery';
import {Episode, findEditionEpisode, findEpisode, formatDate} from './helpers';

const config = {
  upcoming: {
    query: PODCASTER_CAMPAIGN_FUTURE_EPISODES_NODES,
    dateAccessor: 'expectedPublished',
    emptyMessageText: 'No upcoming episodes.',
    toData: (data) => {
      try {
        const {nodes, count} = data.me.organization.podcast.futureEpisodeNodes;
        return {
          data: nodes.map(
            (node) => new Episode({episode: node, isUpcomingEpisode: true})
          ),
          count,
        };
      } catch (err) {
        return {data: [], count: 0};
      }
    },
  },
  past: {
    query: PODCASTER_CAMPAIGN_EPISODES_NODES,
    dateAccessor: 'published',
    emptyMessageText: 'No past episodes.',
    toData: (data) => {
      try {
        const {nodes, count} = data.me.organization.podcast.feed.episodeNodes;

        return {
          data: nodes.map(
            (node) => new Episode({episode: node, isPastEpisode: true})
          ),
          count,
        };
      } catch (err) {
        return {data: [], count: 0};
      }
    },
  },
};

const BulkEpisodesNotAddedTable = ({
  campaign,
  campaignEpisodes,
  campaignPodcast,
  edition,
  kind,
  onAddEpisode,
  onRemoveEpisode,
  organization,
}) => {
  const myConfig = config[kind];
  const columns = useMemo(
    () => [
      {
        title: 'Episode',
        accessor: 'title',
        type: 'string',
      },
      {
        title: 'Date',
        accessor: myConfig.dateAccessor,
        type: 'date',
        fmt: formatDate,
      },
      {
        Cell: ({data: episode}) => {
          if (
            (findEpisode(episode, campaignEpisodes) ||
              findEditionEpisode(episode, edition, ['creates'])) &&
            !findEditionEpisode(episode, edition, ['removes'])
          ) {
            return (
              <IconButton
                onClick={() => onRemoveEpisode({episode})}
                rules={() => ({
                  color: GREEN,
                  ':hover > svg': {color: GREEN, filter: 'brightness(1.05)'},
                })}>
                <Icon
                  icon='checkbox-marked-circle'
                  name='checked'
                  rules={() => ({
                    height: '1.5rem',
                    width: '1.5rem',
                  })}
                />
              </IconButton>
            );
          }
          return (
            <IconButton onClick={() => onAddEpisode({episode})}>
              <Icon
                icon='add-circle-outline'
                rules={() => ({
                  height: '1.5rem',
                  width: '1.5rem',
                })}
              />
            </IconButton>
          );
        },
      },
    ],
    [edition, campaignEpisodes, onRemoveEpisode, onAddEpisode]
  );

  return (
    <DataTableServer
      query={myConfig.query}
      columns={columns}
      searchKeys={['title']}
      emptyMessageText={myConfig.emptyMessageText}
      orderBy={`-${myConfig.dateAccessor}`}
      toVariables={useCallback(
        ({sortBy, query, rowsPerPage, rowStart}) => ({
          campaignId: campaign.id,
          limit: rowsPerPage,
          offset: rowStart / rowsPerPage,
          orderBy: sortBy,
          organizationId: organization.id,
          podcastId: campaignPodcast.podcast.id,
          query: query,
        }),
        [campaign.id, organization.id, campaignPodcast.podcast.id]
      )}
      toData={myConfig.toData}
    />
  );
};

export default BulkEpisodesNotAddedTable;
