import {useQuery} from '@apollo/client';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import {withInsightsImpressionsQuery} from '@analytics/components/insights/InsightsImpressionsQueryWrapper';
import InsightsPerformancePlayersPieChart from '@analytics/components/insights/InsightsPerformancePlayersPieChart';
import InsightsPerformancePlayersTable from '@analytics/components/insights/InsightsPerformancePlayersTable';
import BRAND_ORGANIZATION_IMPRESSIONS_PLAYERS_QUERY from '@analytics/graphql-api/_old_queries/BrandOrganizationImpressionsPlayersQuery';
import {MeasureInsightsRouteProps} from '..';

type Props = MeasureInsightsRouteProps & {
  campaigns: any[];
};

export const InsightsPlayersIndex = withInsightsImpressionsQuery(
  ({after, before, campaigns, organization}: Props) => {
    const {data, loading, error} = useQuery(
      BRAND_ORGANIZATION_IMPRESSIONS_PLAYERS_QUERY,
      {
        variables: {
          id: organization.id,
          after,
          before,
          campaignIds: campaigns.map(({id}) => id),
        },
      }
    );

    if (loading) {
      return (
        <div css={{margin: '.75rem 0'}}>
          <LoadingSpinner centered />
        </div>
      );
    } else if (error) {
      return (
        <Card rules={() => ({margin: '1.5rem 1.5rem 0'})}>
          <ToastMessage alertType='error' rules={() => ({margin: 0})}>
            Error loading data. Please try again later.
          </ToastMessage>
        </Card>
      );
    }
    const players =
      data?.me?.organization?.overview?.impressions?.players ?? [];

    return (
      <div
        css={{
          alignItems: 'start',
          display: 'grid',
          gridGap: '1rem',
          gridTemplateColumns: '1fr 0.45fr',
          margin: '1rem 1.5rem 0',
        }}>
        <Card rules={() => ({margin: 0})}>
          <InsightsPerformancePlayersTable players={players} />
        </Card>
        <div>
          <Card rules={() => ({margin: 0})}>
            <CardHeader>Impressions By Player</CardHeader>
            <InsightsPerformancePlayersPieChart players={players} />
          </Card>
          <Card
            css={`
              margin: 1rem 0 0 0;
            `}>
            <CardHeader>Reach By Player</CardHeader>
            <InsightsPerformancePlayersPieChart
              players={players}
              attr='reach'
            />
          </Card>
        </div>
      </div>
    );
  },
  {
    withCampaigns: true,
    withPublishers: false,
  }
);
