import {Card} from '@core/ui/Content';
import {CampaignDraftProps} from '..';
import {CampaignApprovalsDetails} from './CampaignApprovalsDetails';
import {CampaignPixelDetails} from './CampaignPixelDetails';
import {CampaignPrefixDetails} from './CampaignPrefixDetails';
import {CampaignStaticDetails} from './CampaignStaticDetails';
import {CampaignTrackingUrlsDetails} from './CampaignTrackingUrlsDetails';

export function CampaignDraftDetailsCard({...props}: CampaignDraftProps) {
  return (
    <Card elevated css={{margin: 0}}>
      <CampaignStaticDetails {...props} />
      <CampaignApprovalsDetails {...props} />
      <CampaignTrackingUrlsDetails {...props} />
      <CampaignPrefixDetails {...props} />
      <CampaignPixelDetails {...props} />
    </Card>
  );
}
