import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Card} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import {ReportsSavedExportsTable} from '@analytics/components/reports/ReportsSavedExportsTable';
import {useSavedExportsQuery} from '@analytics/graphql-api';
import {ReportsRouteProps} from './_routes';

export default function Reports({organization}: ReportsRouteProps) {
  const {data, loading, error} = useSavedExportsQuery({
    variables: {
      id: organization.id,
    },
  });

  if (loading) {
    return <LoadingSpinner centered />;
  }

  if (error) {
    return (
      <Alert severity='error' css={{margin: 'var(--spacing-7)'}}>
        {t('errors.network')}
      </Alert>
    );
  }

  return (
    <Card>
      <ReportsSavedExportsTable
        organization={organization}
        savedExports={data?.me?.organization?.exports?.savedExports ?? []}
        supportedTypes={
          data?.me?.organization?.exports?.exportTypes?.types ?? []
        }
      />
    </Card>
  );
}
