import styled from 'styled-components';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {LoadingSpinner} from '@core/ui/Loading';

export const Grid = styled.div<{columns: number}>`
  display: grid;
  gap: 1rem;
  grid-template-columns: ${(div) => `repeat(${div.columns}, 1fr)`};
`;

export const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 0.9375rem;
  font-weight: 500;
  gap: var(--spacing-2);
`;

export const Link = styled.a.attrs({target: '_blank', rel: 'noreferrer'})`
  color: var(--text-muted);
  display: block;
  font-size: var(--font-size-body-3);
  margin-right: 0.175rem;
  :hover {
    color: var(--blue);
  }
`;

const _LoadingContainer = styled.div<{formError: boolean; noBorder: boolean}>`
  align-items: center;
  border: ${(div) =>
    div.noBorder
      ? null
      : `1px solid ${
          div.formError ? 'var(--red-orange)' : 'var(--border-default)'
        }`};
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  padding: 8px;
  svg {
    color: var(--red-orange);
  }
`;

type LoadingContainerProps = {
  errorMessage?: string;
  fetchError?: boolean;
  formError?: boolean;
  loading: boolean;
  noBorder?: boolean;
};

export const LoadingContainer = ({
  errorMessage,
  formError = false,
  loading,
  noBorder = false,
  ...props
}: LoadingContainerProps) => (
  <_LoadingContainer noBorder={noBorder} formError={formError} {...props}>
    {loading ? (
      <LoadingSpinner size='small' />
    ) : (
      <Alert severity='error'>{errorMessage ?? t('errors.network')}</Alert>
    )}
  </_LoadingContainer>
);
