import {
  FetchedCampaign,
  FetchedCampaignDynamicEpisode,
} from '@analytics/graphql-api';
import _CampaignStatsBase from './_CampaignStatsBase';

export const CampaignDynamicEpisodeStats = ({
  campaign,
  campaignDynamicEpisode,
}: {
  campaign: FetchedCampaign;
  campaignDynamicEpisode: FetchedCampaignDynamicEpisode;
}) => {
  return (
    <_CampaignStatsBase campaign={campaign} instance={campaignDynamicEpisode} />
  );
};
