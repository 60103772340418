import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {Button, ButtonIcon} from '@core/ui/Button';
import {Form, LabelInput, Submit} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import CREATE_BRAND_INVITES_MUTATION from '@analytics/graphql-api/_old_mutations/CreateBrandInvites';

export const BrandInviteForm = ({onComplete, companyId, organization}) => {
  const [createBrandInvite] = useMutation(CREATE_BRAND_INVITES_MUTATION);
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues: {users: [{firstName: '', lastName: '', email: ''}]},
  });
  const {fields, remove, append} = useFieldArray({
    control,
    name: 'users',
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const resp = await createBrandInvite({
        variables: {
          input: {
            organizationId: organization.id,
            companyId,
            invitees: data.users,
          },
        },
      });
      if (typeof onComplete === 'function') {
        onComplete({
          organizationId: resp.data.createBrandInvites.organizationId,
        });
      }
      setInvitations([1]);
    } catch (err) {
      setError('email', {
        type: 'manual',
        message: err.message,
      });
    }
    setLoading(false);
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
      onChange={() => clearErrors()}>
      {fields.map((field, idx) => (
        <div key={field.id}>
          <div
            css={`
              align-items: center;
              display: grid;
              grid-gap: 1rem;
              grid-template-columns: ${idx > 0
                ? 'repeat(3, 1fr) auto'
                : 'repeat(3, 1fr)'};
              margin-bottom: 1rem;
            `}>
            <Controller
              name={`users.${idx}.firstName`}
              control={control}
              render={({field}) => (
                <LabelInput label='First name' required {...field} />
              )}
            />
            <Controller
              name={`users.${idx}.lastName`}
              control={control}
              render={({field}) => (
                <LabelInput label='Last name' required {...field} />
              )}
            />
            <Controller
              name={`users.${idx}.email`}
              control={control}
              render={({field}) => (
                <LabelInput
                  label='Email address'
                  required
                  type='email'
                  invalid={
                    errors.email
                      ? errors.email?.message.indexOf(
                          getValues().users[idx].email
                        ) > -1
                      : false
                  }
                  {...field}
                />
              )}
            />
            {idx > 0 && (
              <ButtonIcon
                onClick={() => remove(idx)}
                icon='bin'
                css={`
                  height: 2.125rem;
                  margin-right: var(--spacing-3);
                `}
              />
            )}
          </div>
        </div>
      ))}
      <Stack
        justifyContent='space-between'
        css={`
          margin-top: var(--spacing-10);
        `}>
        <Button
          buttonSize='md'
          variant='outlined'
          onClick={() => {
            append({
              firstName: '',
              lastName: '',
              email: '',
            });
          }}>
          Add Invite
        </Button>
        <Submit loading={loading} disabled={loading}>
          Send Invites
        </Submit>
      </Stack>
    </Form>
  );
};
