import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';

export type ManageInviteRouteProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
};

export const toInviteUrl = (
  organization: FetchedOrganization | FetchedBuySideOrganization
) => {
  return createOrgPath(organization, `/manage/invite`);
};

export const toInviteCompanyUrl = (
  organization: FetchedOrganization | FetchedBuySideOrganization,
  companyId: string
) => {
  return createOrgPath(organization, `/manage/invite/${companyId}`);
};

export const useInviteRoutes = ({
  organization,
}: Pick<ManageInviteRouteProps, 'organization'>) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, '/manage/invite'),
    basePath: `/:part(a|s|p)/:slug/manage/invite`,
    routes: [
      {key: 'invite', path: '', exact: true},
      {key: 'invite-company', path: ':companyId'},
    ],
  });
};
