import {Button} from '@core/ui/Button';
import {Stack} from '@core/ui/Layout';

export function DropdownFooter({
  onCancelClick,
  onSaveClick,
}: {
  onCancelClick: () => void;
  onSaveClick: () => void;
}) {
  return (
    <Stack
      direction='row'
      justifyContent='flex-end'
      spacing='0.625rem'
      css={{padding: '0.75rem 1rem', borderTop: '2px solid var(--bg-muted)'}}>
      <Button
        onClick={onCancelClick}
        buttonSize='sm'
        css={{minWidth: '4.5rem'}}>
        Cancel
      </Button>
      <Button
        onClick={onSaveClick}
        buttonSize='sm'
        color='primary'
        css={{minWidth: '4.5rem'}}>
        Save
      </Button>
    </Stack>
  );
}
