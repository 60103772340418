import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';

export const getLiftRoutes = ({
  organization,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, `/measure/lift`),
    basePath: '/:part(b|a|s|p)/:slug/measure/lift',
    routes: [
      {key: 'lift', exact: true, path: ''},
      {
        key: 'spotify',
        title: 'Spotify Brand Lift',
      },
      {key: 'incremental', title: 'Conversion Lift'},
    ],
  });
};
