import React, {useEffect, useRef, useState} from 'react';
import {
  IconChevronDown,
  IconChevronUp,
  IconEnhance,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';
import {Overlay} from '@core/ui/Overlay';
import {useOrganizationQuery} from '@analytics/graphql-api';
import SpotifyHeader from '@analytics/screens/account/SpotifyHeader';
import IntroBottomSheetContent from './IntroBottomSheetContent';

const Header = styled.div<ExpandProps>`
  font-style: normal;
  font-weight: 700;
  font-size: 19.3px;
  line-height: 23px;
  /* identical to box height */

  text-align: left;
  display: flex;
  /* space between */
  justify-content: space-between;
  align-items: center;
  ${({isExpanded}) => `
    font-size: ${isExpanded ? '15px' : '19px'};
    line-height: ${isExpanded ? '22px' : '23px'};
  `}
`;

const ContentContainer = styled.div<ExpandProps>`
  background: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 340px;
  display: flex;
  flex-direction: column;
  border: 0.804167px solid #dedede;
  box-shadow: 4.825px 6.43333px 38.6px rgba(0, 0, 0, 0.2);
  border-radius: 6.43333px;
  padding: 12px 16px;
  ${({isExpanded}) => `
    background-color: ${!isExpanded ? '#daf6e0' : 'none'};
  `}
  z-index: 1;
  position: fixed;
`;

interface ExpandProps {
  isExpanded: boolean;
}

const Body = styled.div<ExpandProps>`
  flex-direction: column;
  display: flex;
  text-align: left;
  ${({isExpanded}) => `
    max-height: ${isExpanded ? '500px' : '0'};
    overflow: hidden;
    transition: max-height 0.3s ease;
  `}
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  background: #daf6e0;
  padding: 9.65px 12.8667px;
  gap: 12.87px;
  border-radius: 5px;
  text-align: left;
`;

const BannerContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const FooterText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

const SupportLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
  color: inherit;
`;

const IntroBottomSheet = ({kind, slug}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    data: orgData,
    loading,
    error,
  } = useOrganizationQuery({
    variables: {
      slug,
    },
  });

  const campaignsCount = orgData?.me?.organization?.campaignsCount;

  const userHasFinishedPixelCreationProcess =
    orgData?.me?.organization?.keys?.some((key) =>
      key.pixelEventRecords?.some(
        (record) =>
          typeof record.name === 'string' && record.name.trim().length > 0
      )
    ) ?? false;

  if (
    loading ||
    error ||
    typeof campaignsCount !== 'number' ||
    campaignsCount > 0
  ) {
    return null;
  }

  if (
    orgData?.me?.organization?.kind === 'BRAND' &&
    !userHasFinishedPixelCreationProcess
  ) {
    return null;
  }

  const onClose = () => {
    setIsExpanded(false);
  };

  const header = 'Get started with Ad Analytics';

  return (
    <ContentContainer isExpanded={isExpanded}>
      <Header
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}>
        {header}
        {isExpanded ? (
          <IconChevronDown
            size='small'
            onClick={onClose}
            semanticColor='textSubdued'
          />
        ) : (
          <IconChevronUp size='small' />
        )}
      </Header>
      <Body isExpanded={isExpanded}>
        <BannerContainer>
          <SpotifyHeader size={'smallest'} />
        </BannerContainer>

        <IntroBottomSheetContent kind={kind} slug={slug} />
        <Footer>
          <IconEnhance />
          <FooterText>
            Have a question?{' '}
            <SupportLink href='https://help.adanalytics.spotify.com'>
              Contact Support
            </SupportLink>
          </FooterText>
        </Footer>
      </Body>
    </ContentContainer>
  );
};

export default IntroBottomSheet;
