import {useEffect, useRef, useState} from 'react';
import {FetchedUser} from '@core/graphql-api';
import {Element} from '@core/style';
import {BLUE, lighten} from '@core/style';
import EmptyMessage from '@core/ui/EmptyMessage';
import {Icon} from '@core/ui/Icon';
import {LoadingSpinner} from '@core/ui/Loading';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import {Tooltip} from '@core/ui/Tooltip';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  OrganizationObject,
  UserObject,
  useGetSavedSearchesQuery,
} from '@analytics/graphql-api';
import AdvisorSavedSearchDropdownItem from './AdvisorSavedSearchDropdownItem';
import AdvisorSavedSearchFormOverlay from './AdvisorSavedSearchFormOverlay';
import {ActiveWorkspace, AdvisorWorkspace} from './AdvisorTool';
import {AdvisorFilterItem, AdvisorSortByItem} from './types';

interface DropdownContentProps {
  activeWorkspace?: ActiveWorkspace;
  onClickWorkspace: (_id: string) => void;
  onDeleteWorkspace: (_id: string) => void;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
}
const AdvisorSavedSearchDropdownContent = ({
  activeWorkspace,
  onClickWorkspace,
  onDeleteWorkspace,
  organization,
  user,
}: DropdownContentProps) => {
  const [personalWorkspaces, setPersonalWorkspaces] = useState<
    AdvisorWorkspace[]
  >([]);
  const [orgWorkspaces, setOrgWorkspaces] = useState<AdvisorWorkspace[]>([]);

  const {data, loading, error} = useGetSavedSearchesQuery({
    variables: {organizationId: organization.id},
  });

  const workspacesProps = {
    user,
    activeWorkspace,
    onItemClick: onClickWorkspace,
    onDelete: onDeleteWorkspace,
  };

  useEffect(() => {
    if (data) {
      const savedSearches =
        data?.me?.organization?.advisor?.savedSearches?.filter((s) => !!s) as
          | AdvisorWorkspace[]
          | undefined;
      if (!savedSearches) return;

      setOrgWorkspaces(
        savedSearches
          .filter(({organization}) => !!organization)
          .sort((a, b) => a.name.localeCompare(b.name))
      );

      setPersonalWorkspaces(
        savedSearches
          .filter(
            ({user: searchUser, organization}) =>
              searchUser?.id === user.id && !organization
          )
          .sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  }, [data]);

  if (loading)
    return (
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          minHeight: '24rem',
        })}>
        <LoadingSpinner size='small' />
      </Element>
    );

  if (error) return <EmptyMessage>No workspaces found</EmptyMessage>;

  return (
    <Element
      rules={() => ({
        background: 'var(--bg-muted)',
        display: 'grid',
        flex: 1,
        gap: '2px',
        gridTemplateRows: '14rem 14rem',
      })}>
      <Element rules={() => ({background: 'var(--bg-overlay)'})}>
        <Element rules={titleRule}>Your saved workspaces</Element>
        <div css={{height: '12rem', overflowY: 'auto'}}>
          <PersonalWorkspaces
            {...workspacesProps}
            workspaces={personalWorkspaces}
          />
        </div>
      </Element>
      <Element rules={() => ({background: 'var(--bg-overlay)'})}>
        <Element rules={titleRule}>In your organization</Element>
        <Element
          rules={() => ({
            height: '12rem',
            overflowY: 'auto',
          })}>
          <OrgWorkspaces {...workspacesProps} workspaces={orgWorkspaces} />
        </Element>
      </Element>
    </Element>
  );
};

interface WorkspacesProps {
  user: FetchedUser;
  workspaces: AdvisorWorkspace[];
  activeWorkspace?: ActiveWorkspace;
  onItemClick: (_id: string) => void;
  onDelete: (_id: string) => void;
}
function PersonalWorkspaces({
  activeWorkspace,
  workspaces,
  ...props
}: WorkspacesProps) {
  if (workspaces.length === 0)
    return <EmptyMessage>No workspaces found</EmptyMessage>;

  return (
    <>
      {workspaces.map((workspace) => (
        <AdvisorSavedSearchDropdownItem
          {...props}
          key={workspace.id}
          isActive={!!activeWorkspace && workspace.id === activeWorkspace?.id}
          workspace={workspace}
          withEditOption
        />
      ))}
    </>
  );
}

function OrgWorkspaces({
  activeWorkspace,
  user,
  workspaces,
  ...props
}: WorkspacesProps) {
  if (workspaces.length === 0)
    return <EmptyMessage>No workspaces found</EmptyMessage>;

  return (
    <>
      {workspaces.map((workspace) => (
        <AdvisorSavedSearchDropdownItem
          {...props}
          key={workspace.id}
          isActive={!!activeWorkspace && workspace.id === activeWorkspace?.id}
          workspace={workspace}
          withEditOption={user.id === workspace.user?.id}
          withPersonalIcon
        />
      ))}
    </>
  );
}

interface Props {
  activeWorkspace?: ActiveWorkspace;
  onCloseWorkspace: () => void;
  onClickWorkspace: (_id: string) => void;
  onDeleteWorkspace: (_id: string) => void;
  organization: OrganizationObject;
  user: UserObject;
  workspaceConfig: {
    columns: string[];
    filters: AdvisorFilterItem[];
    sort: AdvisorSortByItem[];
  };
}
export default function AdvisorSavedSearchDropdown({
  activeWorkspace,
  onCloseWorkspace,
  onClickWorkspace,
  onDeleteWorkspace,
  organization,
  user,
  workspaceConfig,
}: Props) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [openedDropdown, toggleDropdown] = useOverlay();
  const [openedSaveOverlay, toggleSaveOverlay] = useOverlay();

  const _onDeleteWorkspace = (id: string) => {
    onDeleteWorkspace(id);
    toggleDropdown();
  };

  const _onClickWorkspace = (id: string) => {
    onClickWorkspace(id);
    toggleDropdown();
  };

  return (
    <Element>
      <Element
        domRef={dropdownRef}
        rules={() => ({
          alignItems: 'center',
          background: activeWorkspace ? BLUE : 'var(--bg-default)',
          border: '1px solid var(--border-default)',
          borderRadius: '0.375rem',
          color: activeWorkspace ? '#fff' : 'var(--icon-subtle)',
          cursor: 'pointer',
          display: 'flex',
          fontSize: '0.875rem',
          height: '2.125rem',
          transition: 'background 0.2s',
          ...(activeWorkspace && {
            ':hover': {
              background: lighten(BLUE, 0.075),
            },
          }),
        })}>
        <Tooltip
          description='Saved workspaces'
          overlayProps={{
            horizontalAlign: 'center',
            verticalOffset: 7,
          }}
          rules={() => ({
            alignItems: 'center',
            display: 'flex',
          })}>
          <Element
            onClick={toggleDropdown}
            rules={() => ({
              alignItems: 'center',
              height: '100%',
              padding: '0 0.375rem',
              display: 'flex',
              ...(!activeWorkspace ? {':hover': {color: BLUE}} : {}),
            })}>
            <Icon
              icon='bookmarks-outline'
              rules={() => ({height: '1.25rem', width: '1.25rem'})}
            />
            {activeWorkspace && (
              <Element rules={() => ({marginLeft: '0.375rem'})}>
                {activeWorkspace.name}
              </Element>
            )}
          </Element>
        </Tooltip>
        {activeWorkspace && (
          <Element
            onClick={onCloseWorkspace}
            rules={() => ({
              alignItems: 'center',
              borderRadius: '50%',
              display: 'flex',
              marginRight: '0.375rem',
              transition: 'background 0.2s',
              ':hover': {
                color: BLUE,
                background: 'var(--bg-default)',
              },
            })}>
            <Icon
              icon='close'
              rules={() => ({height: '1rem', width: '1rem'})}
            />
          </Element>
        )}
      </Element>
      <Overlay
        closeOnEscKey={false}
        horizontalAlign='left'
        opened={openedDropdown}
        positionTarget={dropdownRef.current}
        toggle={toggleDropdown}
        transparentBackdrop
        verticalOffset={2}
        withBackdrop
        withShadow
        animation='opacity'
        css={`
          display: flex;
          flex-direction: column;
          font-size: 0.875rem;
          max-width: initial;
          min-width: 25rem;
          padding: 0.375rem;
        `}>
        <Element
          rules={() => ({
            borderBottom: '2px solid var(--bg-muted)',
            padding: '0 0 0.375rem',
          })}>
          <Element onClick={toggleSaveOverlay} rules={itemRule}>
            <Icon
              icon='plus'
              rules={() => ({
                height: '1.25rem',
                marginRight: '0.375rem',
                width: '1.25rem',
              })}
            />
            Save new workspace
          </Element>
        </Element>
        <AdvisorSavedSearchDropdownContent
          activeWorkspace={activeWorkspace}
          onDeleteWorkspace={_onDeleteWorkspace}
          onClickWorkspace={_onClickWorkspace}
          organization={organization}
          user={user}
        />
      </Overlay>
      {openedSaveOverlay && (
        <AdvisorSavedSearchFormOverlay
          onSave={_onClickWorkspace}
          opened={openedSaveOverlay}
          organization={organization}
          toggle={toggleSaveOverlay}
          workspaceConfig={workspaceConfig}
        />
      )}
    </Element>
  );
}

const titleRule = () =>
  ({
    color: 'var(--text-muted)',
    fontSize: '0.6875rem',
    fontWeight: '500',
    letterSpacing: '.5px',
    margin: '0.375rem 0.75rem 0.125rem',
    textTransform: 'uppercase',
  } as const);

const itemRule = () =>
  ({
    alignItems: 'center',
    borderRadius: '0.5rem',
    color: 'var(--text-subtle)',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 500,
    padding: '0.375rem 0.75rem',
    transition: 'all 0.2s',
    width: '100%',
    ':hover': {
      background: 'var(--bg-subtle-hover)',
      color: BLUE,
    },
  } as const);
