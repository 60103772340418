import {Stack} from '../Layout';
import {Text, TextProps} from '../Text';

type Desc = {text: string; variant?: TextProps['variant']};
type Title = Desc | string;

export interface HeadingProps {
  /**
   * The title of the heading.
   */
  title: Title;
  /**
   * The subtitle of the heading.
   */
  subtitle?: Title;
}
export function Heading({title, subtitle}: HeadingProps) {
  const titleVariant = typeof title === 'string' ? 'h2' : title.variant;
  const titleText = typeof title === 'string' ? title : title.text;

  const subtitleVariant =
    typeof subtitle === 'string' ? 'subtitle1' : subtitle?.variant;
  const subtitleText = typeof subtitle === 'string' ? subtitle : subtitle?.text;

  return (
    <Stack component='hgroup' direction='column'>
      <Text variant={titleVariant}>{titleText}</Text>
      {subtitle && (
        <Text variant={subtitleVariant} color='muted'>
          {subtitleText}
        </Text>
      )}
    </Stack>
  );
}
