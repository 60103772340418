import {gql} from '@apollo/client';

export default gql`
  query getBrandCampaignOverlap($organizationId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $id) {
          id
          overlap {
            pairs {
              baseCampaignDynamicId
              baseCampaignDynamic {
                id
                name
              }
              baseCampaignStreaming {
                id
                name
              }
              baseFeedId
              baseFeed {
                id
                title
                image {
                  id
                  width
                  height
                  path
                }
              }
              compCampaignDynamicId
              compCampaignDynamic {
                id
                name
              }
              compCampaignStreaming {
                id
                name
              }
              compFeedId
              compFeed {
                id
                title
                image {
                  id
                  width
                  height
                  path
                }
              }

              baseReach
              compReach
              overlap
            }
          }
        }
      }
    }
  }
`;
