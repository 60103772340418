import {Card, CardHeader} from '@core/ui/Content';
import CampaignDynamicEpisodesTable from '@analytics/components/campaigns/CampaignDynamicEpisodesTable';
import {CampaignDynamicPodcastUnknown} from '@analytics/components/campaigns/CampaignDynamicUnknown';
import {CampaignDynamicPodcastOmniChart} from '@analytics/components/campaigns/charts';
import {CampaignDynamicPodcastStats} from '@analytics/components/campaigns/stats/CampaignDynamicPodcastStats';
import CampaignDynamicPodcastNav from '@analytics/components/nav/CampaignDynamicPodcastNav';
import {CampaignDynamicPodcastRouteProps} from '.';

export function CampaignDynamicPodcast({
  organization,
  campaign,
  campaignDynamic,
  campaignDynamicPodcast,
}: CampaignDynamicPodcastRouteProps) {
  const isUnknown = !campaignDynamicPodcast?.feed;

  return (
    <>
      <CampaignDynamicPodcastNav
        organization={organization}
        campaign={campaign}
        campaignDynamic={campaignDynamic}
        campaignDynamicPodcast={campaignDynamicPodcast}
      />
      <CampaignDynamicPodcastStats
        campaign={campaign}
        campaignDynamicPodcast={campaignDynamicPodcast}
      />
      <Card css={{marginTop: '2.375rem'}}>
        <CampaignDynamicPodcastOmniChart
          organization={organization}
          campaign={campaign}
          campaignDynamic={campaignDynamic}
          campaignDynamicPodcast={campaignDynamicPodcast}
        />
      </Card>
      {isUnknown ? (
        <Card css={{marginTop: '2rem'}}>
          <CampaignDynamicPodcastUnknown campaignDynamic={campaignDynamic} />
        </Card>
      ) : (
        <Card scrollable>
          <CardHeader>Episodes</CardHeader>
          <CampaignDynamicEpisodesTable
            organization={organization}
            campaign={campaign}
            campaignDynamic={campaignDynamic}
            campaignDynamicPodcast={campaignDynamicPodcast}
            campaignDynamicEpisodes={
              campaignDynamicPodcast?.campaignDynamicEpisodes
            }
          />
        </Card>
      )}
    </>
  );
}
