import React from 'react';
import dayjs from '@core/lib/dayjs';
import DataTable from '@core/ui/DataTable';
import {campaignLinkURL} from '@analytics/components/nav/CampaignLinkNav';

const CampaignLinksTable = ({organization, campaign, campaignLinks}) => {
  const columns = [
    {
      title: 'Name',
      accessor: 'name',
      type: 'string',
    },
    {
      title: 'Views',
      accessor: 'viewTotal',
      type: 'number',
    },
    {
      title: 'Clicks',
      accessor: 'clickTotal',
      type: 'number',
    },
    {
      title: 'Attributed Downloads',
      accessor: 'targetDownloadTotal',
      type: 'number',
    },
    {
      title: 'Attributed Reach',
      accessor: 'targetReachTotal',
      type: 'number',
    },

    {
      title: 'Created',
      accessor: 'createdAt',
      type: 'date',
      fmt: (v) => dayjs(v).format('MM/DD/YY'),
    },
  ];

  const data = campaignLinks.map(
    ({
      id,
      name,
      createdAt,
      viewTotal,
      clickTotal,
      targetDownloadTotal,
      targetReachTotal,
    }) => ({
      id,
      name,
      createdAt,
      viewTotal,
      clickTotal,
      targetDownloadTotal: targetDownloadTotal ? targetDownloadTotal : 0,
      targetReachTotal: targetReachTotal ? targetReachTotal : 0,
    })
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      orderBy='-createdAt'
      searchKeys={['url']}
      searchPlaceholder='Search by URL'
      emptyMessageText='No Links Found.'
      onClickRowPath={({id}) => {
        return campaignLinkURL(organization, campaign, {id});
      }}
    />
  );
};

export default CampaignLinksTable;
