import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {ReviewersNav} from '@analytics/components/nav/ResearchNav';
import {OrganizationObject, UserObject} from '@analytics/graphql-api';
import {researchReviewersRoutes} from '@analytics/lib/routes/researchRoutes';
import ReviewerCreate from './ReviewerCreate';
import Reviewers from './Reviewers';
import ReviewersAd from './ReviewersAd';
import ReviewersAds from './ReviewersAds';
import ReviewersClassify from './ReviewersClassify';
import ReviewersCompanies from './ReviewersCompanies';
import ReviewersEpisodes from './ReviewersEpisodes';
import ReviewerIndex from './reviewer';
import ReviewersTranscribableIndex from './transcribable';

type ReviewersIndexProps = {
  user: UserObject;
  organization: OrganizationObject;
};

export default function ReviewersIndex({
  user,
  organization,
}: ReviewersIndexProps) {
  if (!user.reviewer)
    return <Redirect to={createOrgPath(organization, 'research')} />;

  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, 'research/reviewers'),
    basePath: '/:part(b|a|s|p)/:slug/research/reviewers',
    routes: researchReviewersRoutes(),
  });

  return (
    <>
      <ReviewersNav user={user} organization={organization} />
      <Switch>
        {renderRoutes({
          components: {
            reviewers: Reviewers,
            create: ReviewerCreate,
            classify: ReviewersClassify,
            ads: ReviewersAds,
            ad: ReviewersAd,
            feedAd: ReviewersAds,
            episodes: ReviewersEpisodes,
            transcribable: ReviewersTranscribableIndex,
            companies: ReviewersCompanies,
            reviewer: ReviewerIndex,
          },
          extraProps: {
            user,
            organization,
            reviewerId: user.reviewer.id,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
}
