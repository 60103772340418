import {useState} from 'react';
import {useForm} from 'react-hook-form';
import styled from 'styled-components';
import {capitalize} from '@core/lib/filters';
import {Button, ButtonIcon} from '@core/ui/Button';
import {FormControlled} from '@core/ui/Form New/FormControlled';
import {FORM_ERRORS, Submit} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {
  CreateSavedExportInput,
  FetchedBuySideOrganization,
  FetchedOrganization,
  FetchedSavedExports,
  SavedExportObject,
  UpdateSavedExportInput,
  useCreateExportInstanceMutation,
  useCreateSavedExportMutation,
  useUpdateSavedExportMutation,
} from '@analytics/graphql-api';
import {doCreateReportInstance} from '../helpers';
import ReportsFormFields from './ReportsFormFields';
import {
  PresetExport,
  ReportCategory,
  ReportsFormData,
  doCreateSavedExport,
  doUpdateSavedExport,
  reportsFormDefaultValues,
} from './helpers';

type Props = {
  category: ReportCategory | undefined;
  onCancel?: () => void;
  onComplete?: (_?: string) => void;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  savedExport: FetchedSavedExports[number] | undefined;
  presetExport: PresetExport | undefined;
};

export const ReportsForm = ({
  category,
  onCancel,
  onComplete,
  organization,
  presetExport,
  savedExport,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [createExportInstance] = useCreateExportInstanceMutation();
  const [createSavedExport] = useCreateSavedExportMutation();
  const [updateSavedExport] = useUpdateSavedExportMutation();
  const form = useForm<ReportsFormData>({
    defaultValues: reportsFormDefaultValues({presetExport, savedExport}),
  });

  const _onSubmit = async (formData: ReportsFormData) => {
    setLoading(true);
    if (formData.networkError) form.clearErrors('networkError');

    try {
      const input = {
        name: formData.name,
        exportType: formData.typeId,
        fields: (formData.fields ?? []).map((f) => ({
          id: f.id,
          name: f.name,
        })),
        orderBy: formData.orderBy,
        exportParameters: formData.exportParameters,
      };

      if (savedExport) {
        await doUpdateSavedExport({
          updateSavedExport,
          input: {
            id: savedExport.id,
            ...input,
          } as UpdateSavedExportInput,
        });

        await doCreateReportInstance({
          createExportInstance,
          savedExport,
          organizationId: organization.id,
        });

        onComplete?.(savedExport.id);
      } else {
        const response = await doCreateSavedExport({
          createSavedExport,
          input: {
            organizationId: organization.id,
            ...input,
          } as CreateSavedExportInput,
        });

        const _savedExport = response?.data?.createSavedExport?.savedExport;
        if (_savedExport) {
          await doCreateReportInstance({
            createExportInstance,
            savedExport: _savedExport as SavedExportObject,
            organizationId: organization.id,
          });
        }

        onComplete?.(_savedExport?.id);
      }
    } catch (e) {
      console.warn(e.message);
      form.setError('networkError', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  return (
    <FormControlled formContext={form} onSuccess={_onSubmit}>
      <_HeaderStack
        direction='row'
        alignItems='center'
        justifyContent='space-between'>
        <_CircleButtonIcon icon='close' onClick={() => onCancel?.()} />
        <Text variant='h2'>
          {savedExport ? 'Update' : 'New'} {capitalize(category ?? '')} Report
        </Text>
        <Button
          onClick={() => onCancel?.()}
          css={{marginLeft: 'auto', marginRight: 'var(--spacing-4)'}}>
          Cancel
        </Button>
        <Submit loading={loading}>{savedExport ? 'Save' : 'Export'}</Submit>
      </_HeaderStack>
      <_FormContainer>
        <ReportsFormFields
          category={category}
          form={form}
          organization={organization}
          presetExport={presetExport}
        />
      </_FormContainer>
    </FormControlled>
  );
};

const _HeaderStack = styled(Stack)`
  background: var(--bg-overlay);
  padding: var(--spacing-4) var(--spacing-8) var(--spacing-4) var(--spacing-5);
  position: sticky;
  top: 0;
`;

const _FormContainer = styled.div`
  padding: var(--spacing-6) var(--spacing-8) var(--spacing-15);
`;

const _CircleButtonIcon = styled(ButtonIcon)`
  border-radius: 50%;
  margin-bottom: -1px;
  margin-right: var(--spacing-4);
  padding: var(--spacing-2);
  :hover {
    background: var(--bg-muted);
  }
`;
