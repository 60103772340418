import styled from 'styled-components';
import {Card} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import {
  FetchedOrganization,
  FetchedPodcast,
  usePodcastFeedSegmentsQuery,
} from '@analytics/graphql-api';
import AudienceChartCard from './AudienceChartCard';
import AudienceHeader from './AudienceHeader';
import {getDemographicsData} from './helpers_chartData';
import {AudienceCategory, AudienceType} from './types';

type Props = {
  organization: FetchedOrganization;
  podcast: FetchedPodcast;
  nielsenApproval?: boolean;
  experianApproval?: boolean;
  category: AudienceCategory;
  toggleCategory: (category: AudienceCategory) => void;
  type: AudienceType;
  toggleType: (type: AudienceType) => void;
};

export default function AudienceDemographicsMosaic({
  organization,
  podcast,
  nielsenApproval,
  experianApproval,
  category,
  toggleCategory,
  type,
  toggleType,
}: Props) {
  const {data, loading} = usePodcastFeedSegmentsQuery({
    variables: {
      organizationId: organization.id,
      podcastId: podcast.id,
    },
  });

  const nielsen =
    nielsenApproval &&
    getDemographicsData(
      data?.me?.organization?.advisor?.podcast?.feed?.nielsenSegments
    );

  const experian =
    experianApproval &&
    getDemographicsData(
      data?.me?.organization?.advisor?.podcast?.feed?.experianSegments
    );

  return (
    <>
      <AudienceHeader
        organizationId={organization.id}
        podcast={podcast}
        category={category}
        toggleCategory={toggleCategory}
        type={type}
        toggleType={toggleType}
        disabled={!nielsen && !experian}
      />
      <div css={{margin: '1.75rem 1.5rem'}}>
        {loading ? (
          <Loading centered />
        ) : experian && type === 'experian' ? (
          <_Mosaic>
            {experian.map(({metrics, topic, subtopic}, i) => (
              <_CardContainer key={`experian-${i}`}>
                <AudienceChartCard
                  metrics={metrics}
                  topic={topic}
                  subtopic={subtopic}
                  category={category}
                  tooltip
                />
              </_CardContainer>
            ))}
          </_Mosaic>
        ) : nielsen && type === 'nielsen' ? (
          <_Mosaic>
            {nielsen.map(({metrics, topic, subtopic}, i) => (
              <_CardContainer key={`nielsen-${i}`}>
                <AudienceChartCard
                  metrics={metrics}
                  topic={topic}
                  subtopic={subtopic}
                  category={category}
                  tooltip
                />
              </_CardContainer>
            ))}
          </_Mosaic>
        ) : (
          <Card rules={() => ({margin: 0})}>
            <EmptyMessage>Demographics data unavailable.</EmptyMessage>
          </Card>
        )}
      </div>
    </>
  );
}

const _Mosaic = styled.div`
  column-count: 3;
  column-gap: 1.75rem;
`;

const _CardContainer = styled.div`
  display: inline-block;
  margin: 0 0 1.75rem;
  width: 100%;
`;
