import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';
import FutureEpisodesQuery from '@analytics/graphql-api/_old_queries/FutureEpisodesQuery';

class NewHome extends React.Component {
  render() {
    const {
      organization,
      data: {loading, me},
      match: {
        params: {podcastId},
      },
    } = this.props;

    if (loading) {
      return <Loading centered />;
    }

    const {futureEpisodes} = me.organization.podcast;

    if (futureEpisodes.length == 0) {
      return (
        <Redirect
          to={createOrgPath(organization, `/podcast/${podcastId}/new/episode`)}
        />
      );
    }

    return (
      <div>
        {futureEpisodes.map((fe) => {
          return (
            <Link
              key={fe.id}
              to={createOrgPath(
                organization,
                `/podcast/${podcastId}/new/${fe.id}`
              )}>
              {fe.title}
            </Link>
          );
        })}
      </div>
    );
  }
}

export default graphql(FutureEpisodesQuery, {
  options: ({
    organization: {id},
    match: {
      params: {podcastId},
    },
  }) => ({
    variables: {
      organizationId: id,
      podcastId,
    },
  }),
})(NewHome);
