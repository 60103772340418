import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {CampaignsIntegrity} from './CampaignsIntegrity';
import {CampaignsTrackingUrls} from './CampaignsTrackingUrls';
import {CampaignsRouteProps, useCampaignsRoutes} from './_routes';
import {CampaignIndex} from './campaign';
import {CampaignCreateIndex} from './create';
import {SpotifyOrder} from './order';

export function CampaignsIndex({organization, user}: CampaignsRouteProps) {
  const {renderRoutes} = useCampaignsRoutes({organization});

  return (
    <Switch>
      {renderRoutes({
        components: {
          redirect: () => (
            // Redirect /campaigns to /measure/campaigns
            <Redirect to={createOrgPath(organization, '/measure/campaigns')} />
          ),
          create: CampaignCreateIndex,
          integrity: CampaignsIntegrity,
          'tracking-urls': CampaignsTrackingUrls,
          order: SpotifyOrder,
          campaign: CampaignIndex,
        },
        extraProps: {
          organization,
          user,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
