import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Redirect} from 'react-router-dom';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import PixelConversionUrlForm from '@analytics/components/pixels/PixelConversionUrlForm';
import CREATE_CONVERSION_URL_MUTATION from '@analytics/graphql-api/_old_mutations/CreateConversionUrl';
import ORGANIZATION_KEY_QUERY from '@analytics/graphql-api/_old_queries/OrganizationKeyQuery';
import {ManagePixelsPixelUrlRouteProps} from '.';

export function PixelAddUrl({backPath, pixel}: ManagePixelsPixelUrlRouteProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [complete, setCompleted] = useState(false);

  const [createConversionUrl] = useMutation(CREATE_CONVERSION_URL_MUTATION, {
    refetchQueries: [ORGANIZATION_KEY_QUERY],
    awaitRefetchQueries: true,
  });

  const onSubmit = async (item: {
    url: string;
    action: string;
    value?: string;
    type?: string;
    category?: string;
  }) => {
    setError(false);
    setLoading(true);
    try {
      const data = await createConversionUrl({
        variables: {
          input: {
            key: pixel.key,
            ...item,
          },
        },
      });
      if (data) {
        setCompleted(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const onCancel = () => {
    setError(false);
    setCompleted(true);
  };

  if (complete && !error) {
    return <Redirect to={backPath} />;
  }

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={backPath}>Add Conversion URL</CardHeader>
      <Card>
        {error && (
          <ToastMessage
            rules={() => ({margin: '0 0 1.5rem'})}
            alertType='error'>
            Network Error. Please Try again.
          </ToastMessage>
        )}
        <PixelConversionUrlForm
          onSubmit={onSubmit}
          onCancel={onCancel}
          loading={loading}
        />
      </Card>
    </ResponsiveContent>
  );
}
