import {gql} from '@apollo/client';

export default gql`
  query getRecentCompanyAds(
    $organizationId: ID!
    $id: ID!
    $limit: Int
    $orderBy: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id

        research {
          company(id: $id) {
            id
            ads(limit: $limit, orderBy: $orderBy, state: "edited") {
              id
              mpegPath
              flacPath
              waveform
              text
              duration
              state
              src

              firstPublished

              placements {
                episode {
                  id
                  feed {
                    id
                    title
                    image {
                      id
                      href
                      path
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
