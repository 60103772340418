import {gql} from '@apollo/client';

export default gql`
  query getFeed($organizationId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          feed(id: $id) {
            id
            title
            subtitle
            summary
            description(words: 50)
            image {
              id
              href
              path
            }

            podcast {
              id
              organization {
                id
                name
                slug
              }
            }

            processing
            measure
          }
        }
      }
    }
  }
`;
