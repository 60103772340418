import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {FetchedCampaignDynamic} from '@analytics/graphql-api';
import {CampaignRouteProps} from '../../_routes';

export type CampaignDynamicRouteProps = CampaignRouteProps & {
  campaignDynamic: FetchedCampaignDynamic;
};

export const useCampaignDynamicRoutes = ({
  organization,
  campaign,
  campaignDynamicId,
}: Pick<CampaignRouteProps, 'organization' | 'campaign'> & {
  campaignDynamicId: string;
}) => {
  return useRoutes({
    baseUrl: createOrgPath(
      organization,
      `/campaigns/${campaign.slug}/dynamics/${campaignDynamicId}`
    ),
    basePath:
      '/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/dynamics/:campaignDynamicId',
    routes: [
      {key: 'overview', path: '', exact: true},
      {key: 'consistency', exact: true},
      {key: 'podcasts'},
    ],
  });
};
