import React from 'react';
import {formatDate} from '@core/lib/time';
import {Element} from '@core/style';
import {t} from '@core/texts';
import DataTable, {DataTableColumn, DataTableProps} from '@core/ui/DataTable';
import {Stack} from '@core/ui/Layout';
import {InfoTooltip} from '@core/ui/Tooltip';

type Props = {
  memberships: any[];
} & Pick<DataTableProps, 'onClickRowPath' | 'headerRenderer'>;

export function MembersTable({
  memberships,
  onClickRowPath,
  headerRenderer,
}: Props) {
  const data = memberships.map(
    ({id, createdAt, role, roleDisplay, user: {email}}) => ({
      id,
      role: role.toLowerCase(),
      roleDisplay,
      createdAt,
      email,
    })
  );

  const columns: DataTableColumn[] = [
    {
      title: 'Email',
      accessor: 'email',
      type: 'string',
    },
    {
      title: 'Role',
      accessor: 'roleDisplay',
      type: 'string',
      Cell: ({d: {role, roleDisplay}}) => (
        <Stack alignItems='center'>
          {roleDisplay}
          <InfoTooltip
            {...t(`roles.${role}`)}
            rules={() => ({
              marginLeft: '0.375rem',
            })}
          />
        </Stack>
      ),
    },
    {
      title: 'Joined',
      accessor: 'createdAt',
      type: 'date',
      fmt: formatDate,
    },
  ];

  return (
    <DataTable
      data={data}
      columns={columns}
      searchKeys={['email']}
      searchPlaceholder='Search'
      orderBy='-createdAt'
      emptyMessageText='No members found'
      paginationRowsPerPage={10}
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
      onClickRowPath={onClickRowPath}
      headerRenderer={headerRenderer}
    />
  );
}

export default MembersTable;
