import {
  EmptyState,
  EmptyStateText,
  IconExclamationCircle,
  semanticColors,
} from '@spotify-internal/encore-web';

export function InsightsEmptyState() {
  return (
    <EmptyState variant='contextual'>
      <IconExclamationCircle semanticColor={semanticColors.essentialSubdued} />
      <EmptyStateText semanticColor={semanticColors.textSubdued}>
        No data available
      </EmptyStateText>
    </EmptyState>
  );
}
