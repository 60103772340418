import {useRef, useState} from 'react';
import {withApollo} from '@apollo/client/react/hoc';
import {ButtonSecondary} from '@spotify-internal/encore-web';
import {financial} from '@core/lib/filters';
import {createOrgPath} from '@core/lib/organizations';
import {Element} from '@core/style';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Stack} from '@core/ui/Layout';
import Loading from '@core/ui/Loading';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import {InfoTooltip} from '@core/ui/Tooltip';
import {usePodcasterPodcastsQuery} from '@analytics/graphql-api';
import {CampaignBulkNav} from '../nav/CampaignBulkNav';
import TrackingUrlsOverlay from '../tracking-urls/TrackingUrlsOverlay';
import {dynamicColumns, embeddedColumns, streamingColumns} from './BulkColumns';
import BulkEditionPopup from './BulkEditionPopup';
import BulkGroup from './BulkGroup';
import BulkService from './BulkService';
import {BulkImportButton} from './import/BulkImportButton';

const isNumber = (val) => {
  return typeof val === 'number' && !isNaN(val);
};

const capitalize = (s) => s[0].toUpperCase().concat(s.slice(1));

const GroupHeader = ({children, tooltipDescription, rightContent}) => {
  return (
    <Element
      rules={() => ({
        alignItems: 'center',
        display: 'flex',
        height: '1.375rem',
        margin: '1.5rem 0 1rem',
      })}>
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
        })}>
        <Element
          rules={() => ({
            fontSize: '1rem',
            fontWeight: 700,
          })}>
          {children}
        </Element>
        <InfoTooltip
          description={tooltipDescription}
          rules={({theme}) => ({
            color: theme.textTertiary,
            display: 'flex',
            marginLeft: '0.375rem',
          })}
        />
      </Element>
      {rightContent}
    </Element>
  );
};

const getItems = ({campaign, adType}) => {
  const accessor = `campaign${capitalize(adType)}s`;

  let items = campaign[accessor] || [];
  items = items.map((item) => ({...item, adType}));

  //! update to filter at query level
  if (adType === 'streaming') items = items.filter(({isActive}) => isActive);

  return items.slice(0).sort((a, b) => {
    return new Date(a.createdAt) - new Date(b.createdAt);
  });
};

const getTotalCost = ({embeddedAds, dynamicAds, streamingAds, edition}) => {
  return [...embeddedAds, ...dynamicAds, ...streamingAds].reduce(
    (acc, current) => {
      if (
        current.__changes &&
        (isNumber(current.__changes.cost) || isNaN(current.__changes.cost))
      ) {
        return acc + (current.__changes.cost || 0);
      } else if (isNumber(current?.cost)) {
        return acc + current.cost;
      }
      return acc;
    },
    0
  );
};

const BulkImpl = withApollo(
  ({client, user, organization, podcasts, campaign, discardChanges}) => {
    const [dynamicAds, setDynamicAds] = useState(
      getItems({campaign, adType: 'dynamic'})
    );
    const [embeddedAds, setEmbeddedAds] = useState(
      getItems({campaign, adType: 'podcast'})
    );
    const [streamingAds, setStreamingAds] = useState(
      getItems({campaign, adType: 'streaming'})
    );
    const [edition, setEdition] = useState([]);
    const [error, setError] = useState(null);
    const [trackingURLsOpened, toggleTrackingURLsOpened] = useOverlay();
    const [loading, setLoading] = useState(false);
    const dynamicsTableRef = useRef();
    const embeddedTableRef = useRef();
    const serviceRef = useRef();
    const streamingTableRef = useRef();

    const updateItemProperty = (params) => {
      if (Array.isArray(params)) {
        for (const p of params) {
          updateItemProperty(p);
        }
        return;
      }

      const {item, propName, value} = params;

      const _edition = edition.slice(0);
      const idx = _edition.indexOf(item);

      if (value === item[propName] || (!value && item.isNew)) {
        // Remove from item.__changes
        if (item.__changes) {
          delete item.__changes[propName];
        }
      } else {
        // Add to item.__changes
        item.__changes = item.__changes || {};
        item.__changes[propName] = value;
      }

      if (item.__changes && Object.keys(item.__changes).length) {
        // Add to edition
        if (idx < 0) {
          _edition.push(item);
        }
      } else if (idx > -1) {
        // Remove from edition
        _edition.splice(idx, 1);
        delete item.__changes;
      }
      setEdition(_edition);
    };

    const addToEdition = (item) => {
      edition.push(item);
      setEdition(edition.slice(0));
    };

    const saveChanges = async () => {
      const validDynamic = dynamicsTableRef.current.validateChanges();
      const validEmbedded = embeddedTableRef.current.validateChanges();
      const validStreaming = streamingTableRef.current.validateChanges();

      if (validDynamic && validEmbedded && validStreaming) {
        setLoading(true);
        try {
          await serviceRef.current.saveEdition({edition});
          setEdition([]);
        } catch (e) {
          setError(e);
        }
        setLoading(false);
      }
    };

    const onItemDelete = (item) => {
      const idx = edition.indexOf(item);
      if (idx > -1) {
        edition.splice(idx, 1);
        setEdition(edition.slice(0));
      }
      const {adType} = item;
      if (adType === 'dynamic') {
        setDynamicAds(dynamicAds.filter(({id}) => id !== item.id));
      } else if (adType === 'podcast') {
        setEmbeddedAds(embeddedAds.filter(({id}) => id !== item.id));
      } else if (adType === 'streaming') {
        setStreamingAds(streamingAds.filter(({id}) => id !== item.id));
      }
    };

    const totalCost = getTotalCost({
      embeddedAds,
      dynamicAds,
      streamingAds,
      edition,
    });
    const campaignUrl = createOrgPath(
      organization,
      `/campaigns/${campaign.slug}`
    );

    return (
      <>
        <BulkService
          ref={serviceRef}
          client={client}
          campaign={campaign}
          organization={organization}
        />
        <Overlay
          opened={loading}
          withBackdrop
          centered
          css={`
            background: transparent;
            border: 0;
            overflow: hidden;
          `}>
          <Loading centered />
        </Overlay>
        <div>
          {Math.abs(totalCost - campaign.cost) > 0.01 ? (
            <Alert
              severity='error'
              compact
              css={`
                align-items: center;
                margin: 1rem 0 2rem 0;
              `}>
              <Stack direction='row' alignItems='center' gap={4}>
                <div>
                  <span>
                    The sum of the spend for these ads (
                    <strong>{financial(totalCost)}</strong>) does not align with
                    the total spend for this campaign (
                    <strong>{financial(campaign.cost)}</strong>).
                  </span>
                </div>
                <Button
                  href={`${campaignUrl}/update`}
                  buttonSize='sm'
                  color='text-error'>
                  Edit Campaign
                </Button>
              </Stack>
            </Alert>
          ) : null}
          <GroupHeader
            tooltipDescription={t('bulk.dynamicAds.longDescription')}
            rightContent={
              <div
                css={`
                  align-items: center;
                  display: flex;
                  margin-left: auto;
                  margin-bottom: 0.5rem;
                  gap: 0.75rem;
                `}>
                  <BulkImportButton
                    onSubmit={(data) => {
                      const dynamicsEdition = data.dynamics.map((d) => {
                        return {
                          isNew: true,
                          adType: 'dynamic',
                          id: Math.random().toString(36).substr(2, 9),
                          __changes: Object.assign({}, d),
                        };
                      });
                      const streamingEdition = data.streaming.map((d) => {
                        return {
                          isNew: true,
                          adType: 'streaming',
                          id: Math.random().toString(36).substr(2, 9),
                          __changes: Object.assign({}, d),
                        };
                      });
                      setDynamicAds((prev) => {
                        return [...prev, ...dynamicsEdition];
                      });
                      setStreamingAds((prev) => [...prev, ...streamingEdition]);
                      setEdition(
                        (prev) =>
                          [
                            ...prev,
                            ...dynamicsEdition,
                            ...streamingEdition,
                          ] as TSFixMe
                      );
                    }}
                  />
                <ButtonSecondary
                  size='small'
                  onClick={() => toggleTrackingURLsOpened(true)}>
                  Tracking URLs
                </ButtonSecondary>
              </div>
            }>
            {t('bulk.dynamicAds.title')}
          </GroupHeader>
          <BulkGroup
            ref={dynamicsTableRef}
            items={dynamicAds}
            setItems={setDynamicAds}
            addToEdition={addToEdition}
            campaign={campaign}
            columns={dynamicColumns}
            adType='dynamic'
            onItemDelete={onItemDelete}
            organization={organization}
            podcasts={podcasts}
            updateItemProperty={updateItemProperty}
          />
        </div>
        <div>
          <GroupHeader
            tooltipDescription={t('bulk.embeddedAds.longDescription')}>
            {t('bulk.embeddedAds.title')}
          </GroupHeader>
          <BulkGroup
            ref={embeddedTableRef}
            items={embeddedAds}
            setItems={setEmbeddedAds}
            campaign={campaign}
            columns={embeddedColumns}
            podcasts={podcasts}
            adType='podcast'
            onItemDelete={onItemDelete}
            organization={organization}
            updateItemProperty={updateItemProperty}
          />
        </div>
        <div>
          <GroupHeader
            tooltipDescription={t('bulk.streamingAds.longDescription')}>
            {t('bulk.streamingAds.title')}
          </GroupHeader>
          <BulkGroup
            ref={streamingTableRef}
            items={streamingAds}
            addToEdition={addToEdition}
            setItems={setStreamingAds}
            campaign={campaign}
            columns={streamingColumns}
            adType='streaming'
            onItemDelete={onItemDelete}
            organization={organization}
            podcasts={podcasts}
            updateItemProperty={updateItemProperty}
          />
        </div>
        {edition.length ? (
          <BulkEditionPopup
            edition={edition}
            saveChanges={saveChanges}
            discardChanges={discardChanges}
          />
        ) : (
          ''
        )}
        {trackingURLsOpened ? (
          <TrackingUrlsOverlay
            campaign={campaign}
            opened={trackingURLsOpened}
            organization={organization}
            toggle={toggleTrackingURLsOpened}
          />
        ) : null}
      </>
    );
  }
);

const Bulk = ({user, organization, campaign}) => {
  const {data, error, loading} = usePodcasterPodcastsQuery({
    variables: {
      id: organization.id,
      campaignId: campaign.id,
    },
  });

  const [key, setKey] = useState(0);

  const discardChanges = () => {
    setKey(new Date().getTime());
  };

  if (loading) {
    return <Loading fixed />;
  }
  return (
    <Element
      rules={() => ({
        flex: '1',
        margin: '.5rem 1.5rem 1.5rem',
        position: 'relative',
      })}>
      <CampaignBulkNav organization={organization} campaign={campaign} />
      <BulkImpl
        key={key}
        user={user}
        organization={organization}
        podcasts={data.me.organization.podcasts}
        campaign={campaign}
        discardChanges={discardChanges}
      />
    </Element>
  );
};

export default Bulk;
