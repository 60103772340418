import {Card, CardHeader} from '@core/ui/Content';
import AdvisorPodcastEpisodesTable from '@analytics/components/advisor/AdvisorPodcastEpisodesTable';
import {AdvisorPodcastRouteProps} from './_routes';

export function AdvisorPodcastEpisodes({
  organization,
  podcast,
}: AdvisorPodcastRouteProps) {
  return (
    <Card>
      <CardHeader>Episodes</CardHeader>
      <AdvisorPodcastEpisodesTable
        organization={organization}
        podcast={podcast}
      />
    </Card>
  );
}
