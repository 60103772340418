import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Card, CardHeader} from '@core/ui/Content';
import {Link} from '@core/ui/Link';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import AddBrandAccessForm from '@analytics/components/forms/AddBrandAccessForm';
import {ManageAgenciesRouteProps} from '.';

export function AgenciesAdd({organization}: ManageAgenciesRouteProps) {
  const history = useHistory();
  const backPath = createOrgPath(organization, `/manage/agencies`);

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={backPath}>Add Agency</CardHeader>
      <Card>
        <p>
          If you can't find the agency name, please contact us at{' '}
          <Link href='mailto:support-adanalytics@spotify.com'>
            support-adanalytics@spotify.com
          </Link>{' '}
          for further help.
        </p>
        <AddBrandAccessForm
          organization={organization}
          onSuccess={() => history.push(backPath)}
        />
      </Card>
    </ResponsiveContent>
  );
}
