import {
  ButtonPrimary,
  IconArrowRight,
  Type,
} from '@spotify-internal/encore-web';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import SpotifyBanner from '../../components/onboard/SpotifyBanner';
import {PageContainer} from '../account/AccountLayout';
import BackgroundImage from './backgroundImage.svg';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-top: 75px;
  margin-bottom: 32px;
`;

const WelcomeImage = styled.img`
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
`;

const OnboardWelcome = () => {
  const history = useHistory();

  const onCtaClick = () => {
    history.push('/onboard');
  };

  return (
    <PageContainer>
      <WelcomeImage src={BackgroundImage} />
      <SpotifyBanner advertising progress={6} />
      <ContentContainer>
        <Type variant='ballad' as='h1'>
          Welcome to Spotify Ad Analytics
        </Type>
        <Type variant='altoBrio' as='h2' style={{lineHeight: '48px'}}>
          Ready to set up your organization?
        </Type>
      </ContentContainer>
      <ButtonPrimary colorSet='brightAccent' onClick={onCtaClick}>
        Let's go
        <IconArrowRight style={{marginLeft: 15}} />
      </ButtonPrimary>
    </PageContainer>
  );
};

export default OnboardWelcome;
