export default class DummyCache {
  get() {
    return null;
  }
  has() {
    return null;
  }
  set() {
    return null;
  }
}
