import {Control, Controller} from 'react-hook-form';
import {DatePickerInput} from '@core/ui/DatePicker';
import {FormHelp, Label} from '@core/ui/FormElements';
import {CampaignFormData} from '../_types';
import {FormGroup} from './_shared';

export function CampaignGoLiveDateField({
  control,
}: {
  control: Control<CampaignFormData>;
}) {
  return (
    <FormGroup>
      <Label htmlFor='goLiveDate'>
        Campaign Dashboard Live Date (Optional)
      </Label>
      <Controller
        name='goLiveDate'
        control={control}
        render={({field}) => (
          <DatePickerInput
            date={field.value ?? undefined}
            onDateChange={field.onChange}
          />
        )}
      />
      <FormHelp>
        The brand will receive access to the real-time campaign dashboard on
        this date.
      </FormHelp>
    </FormGroup>
  );
}
