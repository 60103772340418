import {ButtonIcon} from '@core/ui/Button';
import EmptyMessage from '@core/ui/EmptyMessage';
import {SortableList} from '@core/ui/SortableList';
import {Text} from '@core/ui/Text';
import {ExportTypeColumn} from '@analytics/graphql-api';
import {Column, ColumnContent, ColumnItem, RemoveIcon} from './shared';

type Props = {
  onChangeColumns: (_: ExportTypeColumn[]) => void;
  onRemoveColumn: (_: string) => void;
  selectedColumns: ExportTypeColumn[];
};

export function RightColumn({
  onChangeColumns,
  onRemoveColumn,
  selectedColumns,
}: Props) {
  const onSort = (columnsIds: string[]) => {
    const _selectedColumns = columnsIds.map(
      (columnId) =>
        selectedColumns.find(({id}) => columnId === id) ??
        ({} as ExportTypeColumn)
    );

    onChangeColumns(_selectedColumns);
  };

  return (
    <Column>
      <div css={{height: '2.5rem', padding: '0.25rem 0 0 0.575rem'}}>
        <Text color='muted' variant='overline'>
          Added Columns ({selectedColumns.length})
        </Text>
      </div>
      <ColumnContent>
        <SortableList onSort={onSort}>
          {selectedColumns.length > 0 ? (
            selectedColumns.map(({id, name}) => (
              <ColumnItem key={id} data-sortable-item data-id={id} sortable>
                <ButtonIcon
                  icon='order'
                  data-sortable-button
                  css={{padding: 0}}
                />
                <div>{name}</div>
                <RemoveIcon icon='close' onClick={() => onRemoveColumn(id)} />
              </ColumnItem>
            ))
          ) : (
            <EmptyMessage>No columns selected</EmptyMessage>
          )}
        </SortableList>
      </ColumnContent>
    </Column>
  );
}
