import { cssBorderRadius, cssGraphicSize, imageStyle, isBorderRadiusSize, isGraphicSize } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
var imageStyles = function imageStyles() {
  return css(["", ";", ";", ";", ";", ";", ";"], function (props) {
    return props.imageWidth && css(["inline-size:", ";"], isGraphicSize(props.imageWidth) ? cssGraphicSize(props.imageWidth, props.alt ? 'informative' : 'decorative') : props.imageWidth);
  }, function (props) {
    return props.imageHeight && css(["block-size:", ";"], isGraphicSize(props.imageHeight) ? cssGraphicSize(props.imageHeight, props.alt ? 'informative' : 'decorative') : props.imageHeight);
  }, function (props) {
    return props.borderRadius && css(["border-radius:", ";"], isBorderRadiusSize(props.borderRadius) ? cssBorderRadius(props.borderRadius) : props.borderRadius);
  }, function (props) {
    return props.circle && css(["border-radius:50%;"]);
  }, function (props) {
    return props.crop && css(["object-fit:cover;"]);
  }, function (props) {
    return props.fluid && css(["max-inline-size:100%;block-size:auto;"]);
  });
};
export var PlaceholderImageWrapper = styled.div.withConfig({
  displayName: "Image__PlaceholderImageWrapper",
  componentId: "sc-1u215sg-0"
})(["overflow:hidden;img{", ";filter:blur(20px);transform:scale(1.03);}", ";"], imageStyle(), imageStyles());
export var PlaceholderImage = styled.img.withConfig({
  displayName: "Image__PlaceholderImage",
  componentId: "sc-1u215sg-1"
})(["", ";inline-size:100%;block-size:100%;"], imageStyle());
export var StyledLazyImage = styled.img.withConfig({
  displayName: "Image__StyledLazyImage",
  componentId: "sc-1u215sg-2"
})(["", ";display:", ";", ";"], imageStyle(), function (props) {
  return props.isLoaded ? 'initial' : 'none';
}, imageStyles());
export default styled.img.withConfig({
  displayName: "Image",
  componentId: "sc-1u215sg-3"
})(["", ";"], imageStyles());