import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import OnboardSelector from '@analytics/components/onboard/OnboardSelector';

const Onboard = ({match}) => {
  const [kind, setKind] = useState('');

  if (kind) {
    return <Redirect push to={`/onboard/${kind}`} />;
  }
  return <OnboardSelector onSubmit={setKind} />;
};

export default Onboard;
