import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {MiniPlayer} from '@core/components/AudioMiniPlayer';
import {createOrgPath} from '@core/lib/organizations';
import {Element, css} from '@core/style';
import {Button} from '@core/ui/Button';
import {buttonRule} from '@core/ui/Button/LegacyButton';
import {Help, Input, Label} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import DownloadAdMutation from '@analytics/graphql-api/_old_mutations/DownloadAd';

const DownloadButton = ({src}) => {
  return (
    <Element style-dark tag='a' href={src} rules={buttonRule} download>
      Download
    </Element>
  );
};

class Download extends React.Component {
  state = {
    loading: false,
    complete: false,

    padding: false,
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {downloadAd, ad} = this.props;

    this.setState({
      loading: true,
    });

    downloadAd({
      id: ad.id,
      padding: 30,
    }).then(() => {
      this.setState({
        loading: false,
        complete: true,
      });
    });
  };

  onPaddingChange = () => {
    this.setState({
      padding: !this.state.padding,
    });
  };

  render() {
    const {organization, ad, company} = this.props;
    const {loading, padding, complete} = this.state;

    if (complete) {
      return (
        <Element
          rules={() => ({
            textAlign: 'center',
            width: '300px',
            margin: '50px auto',
          })}>
          <p>
            We are processing your Ad download and will send you an email when
            it's ready.
          </p>
          <Button
            color='inverse'
            to={createOrgPath(
              organization,
              `/research/brands/${company.id}/ads/${ad.id}`
            )}>
            Back to Ad
          </Button>
        </Element>
      );
    }

    return (
      <Element
        rules={() => ({
          width: '500px',
          margin: '50px auto',
        })}>
        <h2 css={{fontSize: '1.25rem', marginBottom: '1rem'}}>Download Ad</h2>
        <Element rules={() => ({margin: '20px 0'})}>
          <MiniPlayer enclosure={ad} />
        </Element>
        <form onSubmit={this.onSubmit}>
          <Element>
            <Input
              type='checkbox'
              checked={padding}
              disabled={loading}
              onChange={this.onPaddingChange}
              rules={() => ({
                display: 'inline-block',
                width: 'auto',
                margin: '0 10px 14px 0',
              })}
            />
            <Label
              rules={() => ({
                display: 'inline-block',
              })}>
              Include 30 Second Padding?
            </Label>
            <Help>
              In the audio file would you like us to include 30 seconds of
              padding on both sides of the ad? We need to do a little extra
              work, so we will send you an email when it's ready.
            </Help>
          </Element>
          <Element rules={() => ({padding: '40px 0', textAlign: 'right'})}>
            {padding ? (
              <Submit disabled={loading}>Download</Submit>
            ) : (
              <DownloadButton src={ad.src} />
            )}
          </Element>
        </form>
      </Element>
    );
  }
}

export default graphql(DownloadAdMutation, {
  props: ({mutate}) => ({
    downloadAd: (input) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
  }),
})(Download);
