import {gql} from '@apollo/client';

export default gql`
  query getOrganizationArt19Secret($id: ID!) {
    me {
      id
      organization(id: $id) {
        id
        art19Secret
      }
    }
  }
`;
