import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button} from '@core/ui/Button';
import {FORM_ERRORS, Form, Submit} from '@core/ui/FormElements';
import {Overlay, OverlayProps} from '@core/ui/Overlay';
import {
  FetchedCampaigns,
  FetchedPublisherReviewCampaigns,
  usePublisherApproveCampaignMutation,
} from '@analytics/graphql-api';
import {doApproveCampaign} from './_helpers';
import {CampaignDetailsCard, Footer, Header} from './_shared';

export function PublisherApproveOverlay({
  campaign,
  organizationId,
  opened,
  toggle,
  ...props
}: Omit<
  OverlayProps,
  'centered' | 'closeOnOutsideClick' | 'fixed' | 'withBackdrop'
> & {
  campaign: FetchedCampaigns[number] | FetchedPublisherReviewCampaigns[number];
  organizationId: string;
}) {
  const [loading, setLoading] = useState(false);
  const [approveCampaign] = usePublisherApproveCampaignMutation();

  const {
    clearErrors,
    formState: {errors},
    handleSubmit,
    setError,
  } = useForm();

  const onSubmitApprove = async () => {
    setLoading(true);
    if (errors.networkError) clearErrors('networkError');
    try {
      await doApproveCampaign({
        approveCampaign,
        campaign,
        input: {
          campaignId: campaign!.id,
          organizationId,
        },
      });
      toggle?.(false);
    } catch (e) {
      setError('networkError', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  return (
    <Overlay
      animation='scale-in'
      centered
      css={{padding: '1.75rem', maxWidth: '40rem', minWidth: '30rem'}}
      closeOnOutsideClick={false}
      fixed
      opened={opened}
      toggle={toggle}
      withBackdrop
      {...props}>
      <Header>Approve a campaign</Header>
      <Form onSubmit={handleSubmit(onSubmitApprove)} errors={errors}>
        <CampaignDetailsCard campaign={campaign} />
        <Footer>
          <Button disabled={loading} onClick={() => toggle?.(false)}>
            Cancel
          </Button>
          <Submit color='success' loading={loading}>
            Confirm Approval
          </Submit>
        </Footer>
      </Form>
    </Overlay>
  );
}
