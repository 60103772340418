import {isSelling} from '@core/lib/organizations';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {CampaignStreamingStreamsTable} from '@analytics/components/campaigns/CampaignStreamingStreamsTable';
import {CampaignStreamingOmniChart} from '@analytics/components/campaigns/charts';
import {CampaignStreamingStats} from '@analytics/components/campaigns/stats/CampaignStreamingStats';
import CampaignStreamingNav from '@analytics/components/nav/CampaignStreamingNav';
import TrackingUrlsDropdown from '@analytics/components/tracking-urls/TrackingUrlsDropdown';
import {CampaignStreamingRouteProps} from './_routes';

export function CampaignStream({
  user,
  organization,
  campaign,
  campaignStreaming,
}: CampaignStreamingRouteProps) {
  const downloadTotal = campaignStreaming?.downloadTotal ?? 0;

  return (
    <>
      <CampaignStreamingNav
        user={user}
        organization={organization}
        campaign={campaign}
        campaignStreaming={campaignStreaming}
      />
      {downloadTotal === 0 ? (
        isSelling(organization) ? (
          <ResponsiveContent>
            <TrackingUrlsDropdown
              organization={organization}
              campaign={campaign}
              campaignItem={campaignStreaming}
              type='streaming'
            />
          </ResponsiveContent>
        ) : (
          <Card>
            <EmptyMessage title='No data found.'>
              Ad Analytics has not recieved data for this streaming placement
              yet.
            </EmptyMessage>
          </Card>
        )
      ) : (
        <>
          <CampaignStreamingStats
            campaign={campaign}
            campaignStreaming={campaignStreaming}
          />
          <Card css={{marginTop: '2.375rem'}}>
            <CampaignStreamingOmniChart
              organization={organization}
              campaign={campaign}
              campaignStreaming={campaignStreaming}
            />
          </Card>
          <Card>
            <CardHeader>Placements</CardHeader>
            <CampaignStreamingStreamsTable
              organization={organization}
              campaign={campaign}
              campaignStreaming={campaignStreaming}
            />
          </Card>
        </>
      )}
    </>
  );
}
