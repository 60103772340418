import {UseFormReturn} from 'react-hook-form';
import {
  CreateSavedExportInput,
  CreateSavedExportMutationFn,
  ExportInstanceParameters,
  ExportType,
  ExportTypeColumn,
  ExportTypesQuery,
  FetchedSavedExports,
  SavedExportExportType,
  SavedExportsDocument,
  SavedExportsQuery,
  UpdateSavedExportInput,
  UpdateSavedExportMutationFn,
} from '@analytics/graphql-api';

/*
 *
 * Types
 *
 */

export type _ExportInstanceParams = Partial<ExportInstanceParameters>;

type CamelToSnake<T extends string> = string extends T
  ? string
  : T extends `${infer C0}${infer R}`
  ? `${C0 extends Lowercase<C0> ? '' : '_'}${Lowercase<C0>}${CamelToSnake<R>}`
  : '';

type CamelKeysToSnake<T> = {
  [K in keyof T as CamelToSnake<Extract<K, string>>]: T[K];
};

type ParsedExportInstanceParams = CamelKeysToSnake<ExportInstanceParameters>;

export type ReportCategory = 'campaign' | 'organization';

export type ExportTypeId = Lowercase<
  (typeof SavedExportExportType)[keyof typeof SavedExportExportType]
>;

export type PresetExport = {
  name?: string;
  typeId: ExportTypeId;
  exportParameters: _ExportInstanceParams;
};

export type ReportsFormData = {
  name: string;
  fields: ExportTypeColumn[];
  orderBy: string[];
  typeId: ExportTypeId;
  exportParameters: _ExportInstanceParams;
  networkError: boolean;
};

export type FieldProps = UseFormReturn<ReportsFormData, object>;

type SupportedParamsMap = Record<keyof ExportInstanceParameters, true>;

/*
 *
 * Helper functions
 *
 */

const parseExportType = <T extends string>(x: T) =>
  x.toLowerCase() as Lowercase<T>;

const snakeToCamel = (s: string) =>
  s.replace(/([-_][a-z])/gi, ($1) =>
    $1.toUpperCase().replace('-', '').replace('_', '')
  );

export const showCampaignField = (
  typeId: ExportTypeId,
  params: SupportedParamsMap
) => typeId?.indexOf('campaign') === 0 || params?.campaignId;

export const filterExportTypes = ({
  data,
  category,
}: {
  data: ExportTypesQuery | undefined;
  category: ReportCategory | undefined;
}) =>
  (
    (data?.me?.organization?.exports?.exportTypes?.types ?? []).filter(
      (type) => !!type
    ) as ExportType[]
  ).filter((type) =>
    category
      ? !type?.id.indexOf(category)
      : !type?.id.indexOf('campaign') || !type?.id.indexOf('organization')
  );

export const supportedParams = ({
  exportTypes,
  typeId,
}: {
  exportTypes: ExportType[];
  typeId: ExportTypeId;
}) => {
  const supportedParams =
    exportTypes.find(({id}: {id: string}) => id === typeId)
      ?.supportedParameters ?? [];

  return supportedParams.reduce(
    (acc, curr) => ({
      ...acc,
      ...(curr
        ? {
            [snakeToCamel(curr.id)]: true,
          }
        : {}),
    }),
    {} as SupportedParamsMap
  );
};

export const reportsFormDefaultValues = ({
  presetExport,
  savedExport,
}: {
  presetExport?: PresetExport | undefined;
  savedExport?: FetchedSavedExports[number];
}) => {
  if (presetExport) return presetExport;

  if (savedExport) {
    const fields: ExportTypeColumn[] = savedExport
      ? JSON.parse(savedExport.fields) ?? []
      : [];
    const orderBy: string[] = savedExport
      ? JSON.parse(savedExport.orderBy) ?? []
      : [];
    const params: ParsedExportInstanceParams = savedExport
      ? JSON.parse(savedExport.exportParameters)
      : {};

    return {
      name: savedExport.name,
      fields,
      orderBy,
      typeId: parseExportType(savedExport.exportType),
      exportParameters: {
        actions: params.actions,
        attr: params.attr,
        campaignId: params.campaign_id,
        campaignIds: params.campaign_ids,
        endDate: params.end_date,
        feedIds: params.feed_ids,
        instanceId: params.instance_id,
        interval: params.interval,
        modelled: params.modelled,
        publisherIds: params.publisher_ids,
        reportPeriod: params.report_period,
        spendByImpressions: params.spend_by_impressions,
        startDate: params.start_date,
        trailing: params.trailing,
      },
    };
  }

  return undefined;
};

export const doCreateSavedExport = ({
  createSavedExport,
  input,
}: {
  createSavedExport: CreateSavedExportMutationFn;
  input: CreateSavedExportInput;
}) => {
  return createSavedExport({
    variables: {
      input,
    },
    update: (cache, {data}) => {
      const _savedExport = data?.createSavedExport?.savedExport;

      if (_savedExport) {
        const query = {
          query: SavedExportsDocument,
          variables: {id: input.organizationId},
        };

        const response: SavedExportsQuery | null = cache.readQuery(query);

        if (response) {
          cache.writeQuery({
            ...query,
            data: {
              me: {
                ...response?.me,
                organization: {
                  ...response?.me?.organization,
                  exports: {
                    ...response?.me?.organization?.exports,
                    savedExports: [
                      ...(response?.me?.organization?.exports?.savedExports ??
                        []),
                      {
                        ..._savedExport,
                      },
                    ],
                  },
                },
              },
            },
          });
        }
      }
    },
  });
};

export const doUpdateSavedExport = ({
  updateSavedExport,
  input,
}: {
  updateSavedExport: UpdateSavedExportMutationFn;
  input: UpdateSavedExportInput;
}) => {
  return updateSavedExport({
    variables: {
      input,
    },
    update: (cache, {data}) => {
      const _savedExport = data?.updateSavedExport?.savedExport;
      if (_savedExport) {
        cache.modify({
          id: cache.identify(_savedExport),
          fields: {
            name() {
              return _savedExport.name;
            },
            exportType() {
              return _savedExport.exportType;
            },
            createdBy() {
              return {..._savedExport.createdBy};
            },
            fields() {
              return _savedExport.fields;
            },
            exportParameters() {
              return _savedExport.exportParameters;
            },
            orderBy() {
              return _savedExport.orderBy;
            },
            updatedBy() {
              return _savedExport.updatedAt;
            },
            lastExportInstance() {
              return {..._savedExport.lastExportInstance};
            },
          },
        });
      }
    },
  });
};
