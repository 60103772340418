import React from 'react';
import {Element} from '@core/style';

class AudioSplit extends React.Component {
  dragStart = (evt) => {
    // Move the track around on drag.

    const {duration, onSplitUpdate} = this.props;

    this.setState({
      dragging: true,
    });

    let parentNode = evt.target.parentNode;

    if (this.marker == parentNode) {
      parentNode = parentNode.parentNode;
    }

    const trackWidth = parentNode.offsetWidth;
    const offsetX = parentNode.getBoundingClientRect().x;

    const _mousemove = (evt) => {
      const diff = evt.pageX - offsetX;
      let secondsDiff = (diff / trackWidth) * duration;

      secondsDiff = secondsDiff < 0 ? 0 : secondsDiff;
      secondsDiff = secondsDiff > duration ? duration : secondsDiff;

      secondsDiff = Math.round(secondsDiff * 10000) / 10000;

      onSplitUpdate(secondsDiff);
    };

    const _mouseup = () => {
      document.body.removeEventListener('mousemove', _mousemove);
      document.body.removeEventListener('mouseup', _mouseup);

      this.setState({
        dragging: false,
      });
    };

    document.body.addEventListener('mouseup', _mouseup);
    document.body.addEventListener('mousemove', _mousemove);
  };

  render() {
    const {onSplitUpdate, splitSeconds, duration} = this.props;
    return (
      <Element
        domRef={(marker) => {
          this.marker = marker;
        }}
        onMouseDown={this.dragStart}
        style-height={100}
        style-offset={splitSeconds / duration}
        rules={({height, offset}) => ({
          height: `${height}px`,
          width: '4px',
          top: '0',
          background: '#000',
          position: 'absolute',
          marginLeft: `${(offset * 100).toFixed(5)}%`,
        })}>
        <Element
          rules={() => ({
            position: 'absolute',
            top: 0,
            left: '-5px',
            right: '-5px',
            bottom: 0,
            background: 'rgba(255,255,255, 0.3)',
          })}
        />
      </Element>
    );
  }
}

export default AudioSplit;
