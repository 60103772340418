import {PublisherSearchDocument} from '@core/graphql-api';
import {
  DataSelector,
  DataSelectorProps,
} from '@core/ui/DataSelector/DataSelector';

type Props = Omit<DataSelectorProps, 'dataQuery' | 'fromResponse'> & {
  organization: {
    id: string;
  };
};

export function PublisherSelect({
  organization: {id},
  placeholder = 'Search publisher',
  queryVariables = {},
  ...props
}: Props) {
  return (
    <DataSelector
      css={{width: '100%'}}
      dataQuery={PublisherSearchDocument}
      fromResponse={(data) =>
        data?.me?.organization?.publisherSearch?.results ?? []
      }
      placeholder={placeholder}
      queryVariables={{
        organizationId: id,
        ...queryVariables,
      }}
      {...props}
    />
  );
}
