import dayjs from '@core/lib/dayjs';
import {Card, CardHeader} from '@core/ui/Content';
import AttributionAcquiredDayChart from '@analytics/components/attribution/AttributionAcquiredDayChart';
import CampaignTargetEpisodesTable from '@analytics/components/campaigns/CampaignTargetEpisodesTable';
import CampaignTargetNav from '@analytics/components/nav/CampaignTargetNav';
import {CampaignTargetRouteProps} from '.';

export function CampaignTarget({
  organization,
  campaign,
  campaignTarget,
}: CampaignTargetRouteProps) {
  const acquired = campaignTarget?.attribution?.marketing?.acquired;
  const nodes =
    campaignTarget?.attribution?.marketing?.targetEpisodes?.nodes ?? [];
  const end = dayjs(campaign.endAt).isAfter(dayjs())
    ? dayjs()
    : dayjs(campaign.endAt);

  return (
    <>
      <CampaignTargetNav
        organization={organization}
        campaign={campaign}
        campaignTarget={campaignTarget}
      />
      <Card>
        <CardHeader>Downloads</CardHeader>
        <AttributionAcquiredDayChart
          acquired={acquired}
          start={campaign.startAt}
          end={end}
        />
      </Card>
      <Card>
        <CardHeader>Episodes</CardHeader>
        <CampaignTargetEpisodesTable
          campaign={campaign}
          campaignTargetEpisodes={nodes}
        />
      </Card>
    </>
  );
}
