import {Button, ButtonIcon} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Stack} from '@core/ui/Layout';
import {ManagePixelsPixelMappingsRouteProps} from '.';

export function PixelMappingsOverview({
  pixel,
  match: {url},
}: ManagePixelsPixelMappingsRouteProps & {
  match: {url: string};
}) {
  const columns: DataTableColumn[] = [
    {
      title: 'Event Name',
      accessor: 'eventName',
      type: 'string',
    },
    {
      title: 'Action',
      accessor: 'action',
      type: 'string',
    },
    {
      title: '',
      accessor: 'id',
      type: 'string',
      Cell: ({data: {id}}) => (
        <div css={{display: 'flex', gap: '1rem'}}>
          <ButtonIcon to={`${url}/${id}/update`} icon='pencil' />
          <ButtonIcon to={`${url}/${id}/delete`} icon='bin' />
        </div>
      ),
    },
  ];

  return (
    <Card>
      <DataTable
        columns={columns}
        data={pixel.actionMaps}
        emptyMessageTitle='No Event Mappings Found'
        emptyMessageText='Event Mappings allow you to map events from 3rd party integrations
          like Segment into Ad Analytics standard types.'
        searchKeys={['eventName']}
        headerRenderer={({filtersContent, searchContent}) => (
          <Stack direction='column' gap={3}>
            <Stack justifyContent='space-between' alignItems='center'>
              <CardHeader
                css={`
                  margin: 0;
                `}>
                Event Mappings
              </CardHeader>
              <Button to={`${url}/create`} color='secondary'>
                Add Mapping
              </Button>
            </Stack>
            {(searchContent || filtersContent) && (
              <Stack alignItems='center'>
                {searchContent}
                {filtersContent}
              </Stack>
            )}
          </Stack>
        )}
      />
    </Card>
  );
}
