import { cssColorValue, opacityDisabled, semanticColors } from "../../styles/index.js";
import { Svg } from "../Icon/Svg.js";
import styled, { css } from "../../styled-components.js";
function getPositionStyles(position) {
  var ltrProperty = position === 'trailing' ? 'right' : 'left';
  var rtlProperty = position === 'trailing' ? 'left' : 'right';
  return css(["", ":12px;[dir='rtl'] &&{", ":auto;", ":12px;}"], ltrProperty, ltrProperty, rtlProperty);
}
export var IconContainer = styled.div.withConfig({
  displayName: "IconContainer",
  componentId: "sc-1oa4n9m-0"
})(["position:absolute;top:50%;transform:translateY(-50%);display:flex;color:", ";input:disabled + &,input[readonly] + &{box-shadow:inset 0 0 0 1px ", ";opacity:", ";}", " ", "{display:block;}"], cssColorValue(semanticColors.textSubdued), cssColorValue(semanticColors.essentialSubdued), opacityDisabled, function (_ref) {
  var $position = _ref.$position;
  return getPositionStyles($position);
}, Svg);