import styled from 'styled-components';
import {Stack, StackProps} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';

const Status = styled.div<{success: boolean}>`
  background: ${({success}) =>
    success ? 'var(--green)' : 'var(--red-orange)'};
  border-radius: var(--radius-1);
  flex-shrink: 0;
  height: 12px;
  width: 12px;
`;

export function DisplayStatus({
  success,
  statusText,
  statusDetails,
  ...props
}: StackProps & {
  success: boolean;
  statusText?: string;
  statusDetails?: string | React.ReactNode;
}) {
  return (
    <Stack direction='column' gap='var(--spacing-1)' {...props}>
      <Stack alignItems='center' gap='var(--spacing-2)'>
        <Status success={success} />
        <Text variant='body2'>{statusText ?? (success ? 'Yes' : 'No')}</Text>
      </Stack>
      {statusDetails && <Text variant='body2'>{statusDetails}</Text>}
    </Stack>
  );
}
