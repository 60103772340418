import {useState} from 'react';
import dayjs from 'dayjs';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ChartLegend from '@core/components/ChartLegend';
import {ChartTimeTooltip} from '@core/components/ChartTimeTooltip';
import {comma} from '@core/lib/filters';
import {PURPLE} from '@core/style';
import {t} from '@core/texts';
import EmptyMessage from '@core/ui/EmptyMessage';
import {LoadingSpinner} from '@core/ui/Loading';
import {
  FetchedBuySideOrganization,
  FetchedCampaignLiftReports,
  FetchedIncrementalLiftReports,
  FetchedOrganization,
  useLiftReportPreCreateQuery,
} from '@analytics/graphql-api';
import {liftRequestChartData} from './helpers';

const SERIES = [
  {name: 'downloads', color: 'var(--blue)'},
  {name: 'init', color: 'var(--green)'},
  {name: 'installed', color: PURPLE},
  {name: 'lead', color: 'var(--red-orange)'},
  {name: 'purchase', color: 'var(--pink)'},
];

type LiftReportRequestChartProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  liftReport:
    | NonNullable<FetchedIncrementalLiftReports[number]>
    | NonNullable<FetchedCampaignLiftReports[number]>;
  impressionsRange: {
    impressionsStartAt: dayjs.Dayjs;
    impressionsEndAt: dayjs.Dayjs;
  };
};

export function LiftReportRequestChart({
  organization,
  liftReport,
  impressionsRange,
}: LiftReportRequestChartProps) {
  const [selectedSeries, setSelectedSeries] = useState(SERIES);
  const {data, loading, error} = useLiftReportPreCreateQuery({
    variables: {
      organizationId: organization.id,
      liftReportId: liftReport.id,
      after: impressionsRange.impressionsStartAt.format('YYYY-MM-DD'),
      before: impressionsRange.impressionsEndAt.format('YYYY-MM-DD'),
    },
  });

  if (loading) {
    return <LoadingSpinner centered />;
  }

  if (error) {
    return <EmptyMessage>{t('errors.network')}</EmptyMessage>;
  }

  const liftCampaignData = data?.me?.organization?.liftReport;

  // TODO Need logic in order not to break old lift reports
  // Will be able to remove campaign piece once API migrates campaign to campaigns on LiftReport
  const chartData =
    liftCampaignData && liftCampaignData?.campaigns?.length > 0
      ? liftRequestChartData(liftCampaignData.campaigns)
      : liftCampaignData?.campaign
      ? liftRequestChartData([liftCampaignData.campaign])
      : [];

  const activeSeriesNames = selectedSeries.map(({name}) => name);
  const filteredChartData = chartData.filter((d) =>
    Object.keys(d).some((r) => activeSeriesNames.includes(r))
  );

  return (
    <>
      <ResponsiveContainer width='100%' height={300}>
        <BarChart data={filteredChartData}>
          <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
          <XAxis
            dataKey='x'
            domain={['auto, auto']}
            type='number'
            scale='time'
            padding={{left: 20, right: 20}}
            tickFormatter={(timeStr) => dayjs(timeStr).utc().format('MMM DD')}
          />
          <YAxis tickFormatter={(value) => comma(value)} />
          <Tooltip
            content={({...props}) => (
              <ChartTimeTooltip dateFormat='dddd, MMM DD' {...props} />
            )}
            wrapperStyle={{textTransform: 'capitalize'}}
          />
          {selectedSeries.map(({name, color}) => (
            <Bar key={name} dataKey={name} fill={color} />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <div css={{margin: '0.5rem 0'}}>
        <ChartLegend
          series={SERIES}
          setSelectedSeries={setSelectedSeries}
          selectedSeries={selectedSeries}
          horizontal
          multipleSelect
        />
      </div>
    </>
  );
}
