import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Button} from '@core/ui/Button';
import {FORM_ERRORS, Form, Input, Label, Submit} from '@core/ui/FormElements';
import {Overlay, OverlayProps} from '@core/ui/Overlay';
import {
  FetchedCampaigns,
  FetchedPublisherReviewCampaigns,
  usePublisherRejectCampaignMutation,
} from '@analytics/graphql-api';
import {doRejectCampaign} from './_helpers';
import {CampaignDetailsCard, Footer, Header} from './_shared';

export function PublisherRejectOverlay({
  campaign,
  organizationId,
  opened,
  toggle,
  ...props
}: Omit<
  OverlayProps,
  'centered' | 'closeOnOutsideClick' | 'fixed' | 'withBackdrop'
> & {
  campaign: FetchedCampaigns[number] | FetchedPublisherReviewCampaigns[number];
  organizationId: string;
}) {
  const [loading, setLoading] = useState(false);
  const [rejectCampaign] = usePublisherRejectCampaignMutation();

  const {
    control,
    clearErrors,
    formState: {errors},
    handleSubmit,
    setError,
  } = useForm();

  const onSubmitReject = async ({publisherDenyReason}) => {
    setLoading(true);
    if (errors.networkError) clearErrors('networkError');
    try {
      await doRejectCampaign({
        rejectCampaign,
        campaign,
        input: {
          campaignId: campaign!.id,
          organizationId,
          publisherDenyReason,
        },
      });
      toggle?.(false);
    } catch (e) {
      setError('networkError', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  return (
    <Overlay
      animation='scale-in'
      centered
      closeOnOutsideClick={false}
      css={{padding: '1.75rem', maxWidth: '40rem', minWidth: '30rem'}}
      fixed
      opened={opened}
      toggle={toggle}
      withBackdrop
      {...props}>
      <Header>Reject a campaign</Header>
      <Form errors={errors} onSubmit={handleSubmit(onSubmitReject)}>
        <CampaignDetailsCard
          campaign={campaign}
          css={{marginBottom: 'var(--spacing-6)'}}
        />
        <Label htmlFor='publisherDenyReason'>
          What is the reason for rejection?
        </Label>
        <Controller
          control={control}
          name='publisherDenyReason'
          rules={{required: 'Please provide a reason for rejection.'}}
          render={({field, fieldState: {error}}) => (
            <Input
              id='publisherDenyReason'
              invalid={!!error}
              onChange={field.onChange}
              placeholder='Reason for Rejection'
            />
          )}
        />
        <Footer>
          <Button disabled={loading} onClick={() => toggle?.(false)}>
            Cancel
          </Button>
          <Submit color='error' loading={loading}>
            Confirm Rejection
          </Submit>
        </Footer>
      </Form>
    </Overlay>
  );
}
