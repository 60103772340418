import {ChangeEvent} from 'react';
import {LabelInput} from '@core/ui/FormElements';
import {Question, _Description, _QuestionHeader} from '../../shared';
import {IQuestionProps} from '../types';

export const QuestionAudienceName = ({
  quizData: {audienceName},
  updateQuizData,
}: IQuestionProps) => (
  <Question>
    <div>
      <_QuestionHeader>Who are you trying to reach?</_QuestionHeader>
      <_Description>
        Please give your audience a short, descriptive name.
      </_Description>
    </div>
    <LabelInput
      value={audienceName}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        updateQuizData('audienceName', e.target.value.slice(0, 256))
      }
      name='audienceName'
      label='Audience Name'
    />
  </Question>
);
