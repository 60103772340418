import { cssSpacing, defaultTheme } from "../../styles/index.js";
import { Type } from "../Type/index.js";
import styled, { css } from "../../styled-components.js";
export var Title = styled(Type).attrs(function (_ref) {
  var variant = _ref.variant;
  return {
    forwardedAs: 'h1',
    variant: variant || 'cello'
  };
}).withConfig({
  displayName: "Title",
  componentId: "sc-7gi4ab-0"
})(["padding-block-start:", ";padding-block-end:", ";padding-inline-start:", ";padding-inline-end:", ";", ";"], cssSpacing('base', '16px'), cssSpacing('tighter-2', '8px'), cssSpacing('looser', '20px'), cssSpacing('looser', '20px'), function (props) {
  return props.$hasBorder && css(["border-block-end:1px solid var(--decorative-subdued,", ");"], defaultTheme[props.$colorSet].decorative.subdued);
});