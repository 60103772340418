import {useState} from 'react';
import {useMutation} from '@apollo/client';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import MembershipDeleteForm from '@analytics/components/members/MembershipDeleteForm';
import MembershipForm from '@analytics/components/members/MembershipForm';
import MembershipPermissionsForm from '@analytics/components/members/MembershipPermissionsForm';
import UPDATE_MEMBER_MUTATION from '@analytics/graphql-api/_old_mutations/UpdateMember';
import MEMBERS_QUERY from '@analytics/graphql-api/_old_queries/MembersQuery';
import {ManageMembersRouteProps} from '.';

export function MemberUpdate({
  organization,
  memberships,
  match: {
    params: {membershipId},
  },
}: ManageMembersRouteProps & {
  match: {
    params: {membershipId: string};
  };
}) {
  const [updateMember] = useMutation(UPDATE_MEMBER_MUTATION, {
    refetchQueries: [MEMBERS_QUERY],
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const onSubmit = async ({firstName, lastName, role}) => {
    setErrors([]);
    setLoading(true);
    try {
      await updateMember({
        variables: {
          input: {
            id: membershipId,
            firstName,
            lastName,
            role,
          },
        },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors([error]);
    }
  };

  const membership = memberships.filter(({id}) => id == membershipId)[0];
  const canSeePermissions =
    membership && (membership.role === 'ad-ops' || membership.role === 'sales');

  return (
    <ResponsiveContent containerSize='large'>
      <MembershipForm
        onSubmit={onSubmit}
        loading={loading}
        errors={errors}
        updateMode
        membership={membership}
        organization={organization}
      />
      {canSeePermissions && (
        <MembershipPermissionsForm
          membership={membership}
          organization={organization}
        />
      )}
      <MembershipDeleteForm
        membership={membership}
        organization={organization}
      />
    </ResponsiveContent>
  );
}
