import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import {Card, CardHeader} from '@core/ui/Content';
import {GraphErrors} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {CampaignDocument} from '@analytics/graphql-api';
import DeleteCampaignNoiseMutation from '@analytics/graphql-api/_old_mutations/DeleteCampaignNoise';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';

class CampaignNoiseDelete extends React.Component {
  state = {
    loading: false,
    complete: false,
    errors: [],
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {
      deleteCampaignNoise,
      campaignNoise: {id},
    } = this.props;

    this.setState({
      loading: true,
      errors: [],
    });

    deleteCampaignNoise({
      campaignNoiseId: id,
    })
      .then(() => {
        this.setState({
          complete: true,
        });
      })
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  render() {
    const {organization, campaign} = this.props;
    const {loading, complete, errors} = this.state;

    if (complete) {
      return <Redirect to={`${toCampaignUrl(organization, campaign)}/lift`} />;
    }

    return (
      <ResponsiveContent containerSize='medium'>
        <CardHeader>Delete Household Control Group</CardHeader>
        <Card>
          <GraphErrors
            errors={errors}
            onClose={() => {
              this.setState({
                errors: [],
              });
            }}
          />
          <p>This action is permanent. Are you sure?</p>
          <form onSubmit={this.onSubmit}>
            <Submit color='error' disabled={loading}>
              Delete Household Control Group
            </Submit>
          </form>
        </Card>
      </ResponsiveContent>
    );
  }
}

export default graphql(DeleteCampaignNoiseMutation, {
  props: ({ownProps: {organization, campaign, campaignNoise}, mutate}) => ({
    deleteCampaignNoise: (input) => {
      return mutate({
        variables: {
          input,
        },
        update: (
          store,
          {
            data: {
              deleteCampaignNoise: {success},
            },
          }
        ) => {
          if (!success) {
            return;
          }

          const query = {
            query: CampaignDocument,
            variables: {
              organizationId: organization.id,
              slug: campaign.slug,
            },
          };

          query.data = store.readQuery(query);

          query.data.me.organization.campaign.campaignNoises =
            query.data.me.organization.campaign.campaignNoises.filter(
              ({id}) => id != campaignNoise.id
            );

          store.writeQuery(query);
        },
      });
    },
  }),
})(CampaignNoiseDelete);
