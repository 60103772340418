import {graphql} from '@apollo/client/react/hoc';
import dayjs from '@core/lib/dayjs';
import {comma, firstChar, obfuscateEmail} from '@core/lib/filters';
import {Element} from '@core/style';
import {Card, CardHeader, Grid} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import {EpisodeImage} from '@core/ui/Image';
import {CleanLink, Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {CardStat} from '@core/ui/Stat';
import {
  companyAdURL,
  episodeURL,
  reviewersURL,
} from '@analytics/components/nav/ResearchNav';
import EpisodeMinutesByDay from '@analytics/components/research/EpisodeMinutesByDay';
import ReviewerDetailQuery from '@analytics/graphql-api/_old_queries/research/ReviewerDetailQuery';

const EpisodeTable = ({episode, organization}) => {
  const columns = [
    {
      title: 'Start State',
      accessor: 'startState',
      type: 'string',
    },
    {
      title: 'End State',
      accessor: 'endState',
      type: 'string',
    },
    {
      title: 'Created',
      accessor: 'createdAt',
      type: 'date',
      fmt: (v) => (v ? dayjs(v).format('YYYY-MM-DD HH:mm') : '-'),
    },
    {
      title: 'Ad',
      accessor: 'companyName',
      type: 'string',
      Cell: ({data: {ad, endState}}) => {
        if (ad) {
          if (ad.company) {
            return (
              <Link
                to={companyAdURL(organization, ad.company, ad)}
                css={`
                  color: inherit;
                  font-weight: 500;
                  :hover {
                    color: var(--blue);
                  }
                `}>
                {ad.company.name}
              </Link>
            );
          } else if (endState !== 'REJECTED') {
            return (
              <Link
                to={`${reviewersURL(organization)}/ads/${ad.id}`}
                css={`
                  color: inherit;
                  font-weight: 500;
                  :hover {
                    color: var(--blue);
                  }
                `}>
                Unclassified
              </Link>
            );
          }
        }
        return null;
      },
    },
  ];

  const data = episode.reviews.map((d) =>
    Object.assign(
      {companyName: d.ad && d.ad.company ? d.ad.company.name : ''},
      d
    )
  );

  return <DataTable columns={columns} data={data} orderBy='-createdAt' />;
};

const Reviewer = ({organization, data: {loading, me}}) => {
  if (loading) {
    return <Loading centered />;
  }

  const {reviewer} = me.organization.research;

  const {
    episodes,
    totalEpisodeMinutes,
    totalClassifyCount,
    totalEpisodeCount,
    firstName,
    lastName,
    email,
  } = reviewer;

  return (
    <ResponsiveContent containerSize='large'>
      <CardHeader>
        {firstName} {firstChar(lastName)} ({obfuscateEmail(email)})
      </CardHeader>

      <Grid cols={3} rules={() => ({gridGap: '1.5rem', margin: '0 1.5rem'})}>
        <CardStat
          value={comma(totalEpisodeMinutes)}
          title='Minutes Processed'
        />
        <CardStat value={comma(totalClassifyCount)} title='Ads Classified' />
        <CardStat value={comma(totalEpisodeCount)} title='Total Episodes' />
      </Grid>

      <EpisodeMinutesByDay
        organization={organization}
        reviewerId={reviewer.id}
      />

      <CardHeader>Recently Reviewed Episodes</CardHeader>
      {episodes.map((episode) => {
        const _episodeUrl = episodeURL(organization, episode.feed, episode);

        return (
          <Card key={episode.id} rules={() => ({marginBottom: '1.5rem'})}>
            <Element
              rules={() => ({
                display: 'grid',
                gridGap: '1rem',
                gridTemplateColumns: '75px 1fr',
              })}>
              <CleanLink to={_episodeUrl}>
                <EpisodeImage episode={episode} width={150} />
              </CleanLink>
              <div>
                <CleanLink to={_episodeUrl}>
                  <Element rules={() => ({fontWeight: 500})}>
                    {episode.title}
                  </Element>
                  <Element
                    rules={({theme}) => ({
                      color: theme.textTertiary,
                      fontSize: '0.875rem',
                      marginTop: '0.25rem',
                    })}>
                    {episode.description}
                  </Element>
                </CleanLink>
                <Element rules={() => ({marginTop: '1rem'})}>
                  <EpisodeTable episode={episode} organization={organization} />
                </Element>
              </div>
            </Element>
          </Card>
        );
      })}
    </ResponsiveContent>
  );
};

export default graphql(ReviewerDetailQuery, {
  options: ({organization, reviewer}) => ({
    variables: {
      organizationId: organization.id,
      reviewerId: reviewer.id,
    },
  }),
})(Reviewer);
