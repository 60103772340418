import {useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import Steps from '@core/ui/Steps';
import {AdvisorQuizForm} from '@analytics/components/advisor/onboarding/brand/quiz/types';
import {Rows} from '@analytics/components/advisor/onboarding/brand/shared';
import {
  advisorOnboardBrandURL,
  advisorURL,
} from '@analytics/lib/routes/useAdvisorRoutes';
import {AdvisorRouteProps} from '../../_routes';
import {SplashPage} from './Step1_SplashPage';
import {OnboardingQuiz} from './Step2_QuizPage';
import {ReviewQuiz} from './Step3_ReviewPage';

export type OnboardingStep = 'quiz' | 'review';

export function AdvisorOnboardBrandIndex({
  organization,
  user,
}: AdvisorRouteProps) {
  const [canOnboard, setCanOnboard] = useState(false);
  const [quizData, setQuizData] = useState<AdvisorQuizForm>({
    availability: 'nationwide',
    audienceName: '',
    industries: [],
    nielsenInterests: [],
    contentMaturity: ['g', 'pg', 'pg13', 'r'],
    radioMarkets: [],
    tags: [],
    brands: [],
    budget: 10_000,
  });

  const sharedProps = {
    user,
    organization,
    canOnboard,
    setCanOnboard,
    quizData,
    setQuizData,
  };
  const here = advisorOnboardBrandURL(organization);

  if ('isAdvisor' in organization && organization.isAdvisor && !user.isAdmin) {
    return <Redirect to={advisorURL(organization)} />;
  }

  return (
    <ResponsiveContent containerSize='medium'>
      <Switch>
        <Route
          path={here}
          exact
          render={() => <SplashPage {...sharedProps} />}
        />
        <Route
          path={`${here}/:step(quiz|review)`}
          exact
          render={({
            match: {
              params: {step},
            },
          }) => (
            <Rows>
              <StepProgress step={step as 'quiz' | 'review'} />
              {step === 'quiz' && <OnboardingQuiz {...sharedProps} />}
              {step === 'review' && <ReviewQuiz {...sharedProps} />}
            </Rows>
          )}
        />
        <Route path='*' render={() => <Redirect to={here} />} />
      </Switch>
    </ResponsiveContent>
  );
}

function StepProgress({step}: {step?: OnboardingStep}) {
  if (!step) return null;

  return (
    <Steps
      css={{margin: '1.5rem 0'}}
      active={step}
      steps={[
        {title: 'Assessment', value: 'quiz'},
        {title: 'Confirm', value: 'review'},
      ]}
    />
  );
}
