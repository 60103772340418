import {gql} from '@apollo/client';

export default gql`
  mutation updatePixelEventRecord($input: UpdatePixelEventRecordInput!) {
    authed {
      success
    }
    updatePixelEventRecord(input: $input) {
      pixelEventRecord {
        id
        name
        eventType
        installation
        lastUpdatedBy {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
    }
  }
`;
