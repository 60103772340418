import {gql} from '@apollo/client';

export default gql`
  fragment CampaignNoise on CampaignNoiseObject {
    id
    name
    startAt
    endAt
    targetImpressions
    usePublisherImpressions
  }
`;
