import {Button} from '@core/ui/Button';
import TrackingUrlsTable from '@analytics/components/tracking-urls/TrackingUrlsTable';
import {CampaignRouteProps, toCampaignUrl} from '../_routes';

export function ApprovalsTrackingUrls({
  campaign,
  organization,
}: CampaignRouteProps) {
  return (
    <div
      css={`
        margin: 0 var(---spacing-7);
        position: relative;
      `}>
      <Button
        to={toCampaignUrl(organization, campaign)}
        iconTrailing='arrow-back'
        css={`
          position: absolute;
          right: 0;
          top: -0.525rem;
          > svg {
            height: 1rem;
            transform: rotate(180deg);
          }
        `}>
        <div>Back to Campaign</div>
      </Button>
      <TrackingUrlsTable organization={organization} campaign={campaign} />
    </div>
  );
}
