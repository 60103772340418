import {createOrgPath, isSelling} from '@core/lib/organizations';
import {
  DropdownMenuDivider,
  DropdownMenuItemType,
  DropdownMenuTitle,
} from '@core/ui/DropdownMenu';
import {Nav} from '@core/ui/Nav';
import {NavProps} from '@core/ui/Nav/Nav';
import {useOverlay} from '@core/ui/Overlay';
import ExportOverlay from '@analytics/components/ExportOverlay';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {CampaignLiftReportRouteProps} from '@analytics/screens/unified/campaigns/campaign/lift/report/_routes';

export const campaignLiftReportURL = (
  organization: CampaignLiftReportRouteProps['organization'],
  campaign: CampaignLiftReportRouteProps['campaign'],
  liftReport: CampaignLiftReportRouteProps['liftReport']
) =>
  createOrgPath(
    organization,
    `/campaigns/${campaign.slug}/lift/reports/${liftReport.id}`
  );

type Props = NavProps &
  Omit<CampaignLiftReportRouteProps, 'user'> & {
    isAdmin: boolean;
  };

export default function CampaignLiftReportNav({
  campaign,
  liftReport,
  isAdmin,
  organization,
  ...props
}: Props) {
  const [openedExport, toggleExport] = useOverlay();
  const url = campaignLiftReportURL(organization, campaign, liftReport);

  return (
    <>
      <Nav
        title={liftReport.name ?? liftReport.campaign?.name ?? 'Lift Report'}
        description={liftReport.clampUrl ? liftReport.clampUrl : ''}
        breadcrumbs={[
          {
            to: createOrgPath(organization, 'campaigns'),
            title: 'Campaigns',
          },
          {
            to: toCampaignUrl(organization, campaign),
            title: 'Campaign',
          },
          {
            to: `${toCampaignUrl(organization, campaign)}/lift`,
            title: 'Lift Reports',
          },
          {
            title: `Lift Report`,
          },
        ]}
        moreDropdownItems={[
          {
            to: `${url}/delete`,
            title: 'Delete',
            isVisible: () => isSelling(organization) || isAdmin,
          },
          ...(isAdmin
            ? ([
                {content: <DropdownMenuDivider />},
                {content: <DropdownMenuTitle>Admin Only:</DropdownMenuTitle>},
                {
                  to: `${url}/update`,
                  title: 'Update Report',
                },
                {
                  to: `${url}/run`,
                  title: 'Run Report',
                },
                {
                  title: 'Export',
                  callback: () => toggleExport(true),
                  isVisible: () => liftReport.state !== 'REQUESTED',
                },
              ] as DropdownMenuItemType[])
            : []),
        ]}
        {...props}
      />
      <ExportOverlay
        opened={openedExport}
        toggle={toggleExport}
        organizationId={organization.id}
        pk={liftReport.id}
        kind='report'
        objectType='lift'
        params={{
          after: 0,
          before: liftReport.delta,
        }}
      />
    </>
  );
}
