import {Control, Controller} from 'react-hook-form';
import {Label} from '@core/ui/FormElements';
import Select from '@core/ui/Select';
import {CAMPAIGN_TYPE_SELECT} from '../../_helpers';
import {CampaignFormData} from '../_types';
import {FormGroup} from './_shared';

export function CampaignTypeField({
  control,
}: {
  control: Control<CampaignFormData>;
}) {
  return (
    <FormGroup>
      <Label htmlFor='kind'>Type</Label>
      <Controller
        name='kind'
        control={control}
        rules={{
          validate: (value) => !!value || 'Please provide a campaign type.',
        }}
        render={({field, fieldState}) => (
          <Select
            buttonProps={{id: 'kind'}}
            defaultValue={field.value ?? undefined}
            items={
              /* To support legacy marketing campaigns */
              field.value === 'marketing'
                ? [
                    ...CAMPAIGN_TYPE_SELECT,
                    {
                      title: 'Marketing Campaign (Target Another Podcast)',
                      value: 'marketing',
                    },
                  ]
                : CAMPAIGN_TYPE_SELECT
            }
            invalid={!!fieldState.error}
            onSelect={(item) => field.onChange(item?.value ?? null)}
            placeholder='Select Campaign Type'
          />
        )}
      />
    </FormGroup>
  );
}
