import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  GetAdvisorPublishersDocument,
} from '@analytics/graphql-api';
import AdvisorSelectFilter from './AdvisorSelectFilter';

interface Props {
  defaultValues: {id: string; value: string}[];
  onChange: (_: {id: string; value: string}) => void;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}

export default function AdvisorPublisherFilter({
  defaultValues,
  onChange,
  organization,
}: Props) {
  return (
    <AdvisorSelectFilter
      label='Publishers'
      query={GetAdvisorPublishersDocument}
      defaultValues={defaultValues}
      onChange={onChange}
      organization={organization}
      selectDataFromResponse={(resp) =>
        resp.me.organization.advisor.columnGroups.publishers.columns
      }
    />
  );
}
