import {Text, TextProps} from '../Text';

export type BlockquoteProps = Omit<TextProps, 'variant' | 'color'>;

export const Blockquote = ({...props}: BlockquoteProps) => {
  return (
    <Text
      component='blockquote'
      variant='body1'
      color='default'
      css={`
        background: var(--bg-muted);
        border-left: 0.25rem solid var(--blue);
        border-radius: var(--radius-1);
        margin: 0 0 var(--spacing-5) 0;
        padding: var(--spacing-4);
      `}
      {...props}
    />
  );
};
