import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {comma, toPercent} from '@core/lib/filters';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {LoadingSpinner} from '@core/ui/Loading';
import {campaignPixelURL} from '@analytics/components/nav/CampaignPixelNav';
import BrandCampaignAttributionReferrerDomainsQuery from '@analytics/graphql-api/_old_queries/BrandCampaignAttributionReferrerDomainsQuery';

const AttributionReferralDomainsTable = ({
  organization,
  campaign,
  pixel,
  referrerDomains,
}) => {
  const pixelBaseUrl = campaignPixelURL(organization, campaign, pixel);

  const data = referrerDomains.nodes.map(({referrerDomain, count}) => ({
    id: referrerDomain,
    referrerDomain,
    displayDomainName: referrerDomain ? referrerDomain : '(direct)',
    count,
    to:
      referrerDomain && referrerDomain != 'direct'
        ? `${pixelBaseUrl}/referrers/${referrerDomain}`
        : `${pixelBaseUrl}/users?referrer=direct`,
  }));

  const columns = [
    {
      title: 'Referrer',
      type: 'string',
      accessor: 'displayDomainName',
    },
    {
      title: 'Count',
      type: 'number',
      accessor: 'count',
      Cell: ({data: {count}}) => (
        <Element
          rules={() => ({
            display: 'flex',
            justifyContent: 'flex-end',
            whiteSpace: 'nowrap',
          })}>
          {comma(count)}
          <Element
            rules={({theme}) => ({
              color: theme.textTertiary,
              marginLeft: '0.25rem',
            })}>
            ({toPercent(count / referrerDomains.sum)})
          </Element>
        </Element>
      ),
    },
  ];

  return (
    <DataTable
      emptyMessageText='No domains found'
      columns={columns}
      data={data}
      orderBy='-count'
      onClickRowPath={({to}) => to}
    />
  );
};

const AttributionReferralDomainsBase = ({
  organization,
  campaign,
  pixel,
  data: {loading, me},
}) => {
  if (loading) {
    return <LoadingSpinner centered />;
  }

  const {referrerDomains} = me.organization.campaign.attribution.users;

  return (
    <AttributionReferralDomainsTable
      organization={organization}
      campaign={campaign}
      pixel={pixel}
      referrerDomains={referrerDomains}
    />
  );
};

const AttributionReferralDomains = graphql(
  BrandCampaignAttributionReferrerDomainsQuery,
  {
    options: ({organization, campaign: {id}, limit}) => {
      return {
        variables: {
          organizationId: organization.id,
          id,
          offset: 0,
          limit: limit ? limit : 100,
        },
      };
    },
  }
)(AttributionReferralDomainsBase);

export default AttributionReferralDomains;
