import React from 'react';
import dayjs from '@core/lib/dayjs';
import {comma, firstChar, obfuscateEmail} from '@core/lib/filters';
import DataTable from '@core/ui/DataTable';
import {reviewersURL} from '@analytics/components/nav/ResearchNav';

const ReviewersTable = ({organization, reviewers}) => {
  const columns = [
    {
      title: 'Reviewer',
      accessor: 'displayName',
      type: 'string',
    },
    {
      title: 'Reviews',
      accessor: 'reviewsCount',
      type: 'number',
      fmt: comma,
    },
    {
      title: 'Joined',
      accessor: 'createdAt',
      type: 'date',
      fmt: (v) => dayjs(v).utc().format('MM/DD/YYYY'),
    },
  ];

  const data = reviewers.map(
    ({id, firstName, lastName, email, reviewsCount, createdAt}) => ({
      id,
      firstName,
      lastName,
      email,
      reviewsCount,
      createdAt,
      url: `${reviewersURL(organization)}/${id}`,
      displayName: `${firstName} ${firstChar(lastName)} (${obfuscateEmail(
        email
      )})`,
    })
  );

  return (
    <DataTable
      searchKeys={['displayName']}
      searchPlaceholder='Search by name'
      columns={columns}
      orderBy='-reviewsCount'
      data={data}
      onClickRowPath={({url}) => url}
    />
  );
};

export default ReviewersTable;
