import {comma, financial, toFixed} from '@core/lib/filters';
import {PURPLE} from '@core/style';
import {t} from '@core/texts';

export type InsightPerformanceSerie = {
  accessor: string;
  color?: string;
  description?: string;
  fmt: (_val: any) => any;
  isModeled?: boolean;
  longDescription?: string;
  title: string;
  type: string;
};

export const SERIES: InsightPerformanceSerie[] = [
  {
    accessor: 'impressions',
    title: 'Impressions',
    color: 'var(--green)',
    fmt: comma,
    type: 'number',
  },
  {
    accessor: 'reach',
    title: 'Reach',
    color: 'var(--blue)',
    fmt: comma,
    type: 'number',
    ...t('stats.reach'),
  },
  {
    accessor: 'spend',
    title: 'Spend',
    color: 'var(--pink)',
    fmt: financial,
    type: 'number',
  },
  {
    accessor: 'households',
    title: 'Visitors',
    color: 'var(--red-orange)',
    fmt: toFixed,
    type: 'number',
    isModeled: true,
    ...t('stats.visitors'),
  },
  {
    accessor: 'leads',
    title: 'Leads',
    color: PURPLE,
    fmt: toFixed,
    type: 'number',
    isModeled: true,
  },
  {
    accessor: 'installed',
    title: 'Installed',
    color: 'var(--green)',
    fmt: toFixed,
    type: 'number',
    isModeled: true,
  },
  {
    accessor: 'purchases',
    title: 'Purchases',
    color: 'var(--pink)',
    fmt: toFixed,
    type: 'number',
    isModeled: true,
  },
  {
    accessor: 'views',
    title: 'Views',
    color: 'var(--red-orange)',
    fmt: toFixed,
    type: 'number',
    isModeled: true,
  },
  {
    accessor: 'attributedDownloads',
    title: 'Attributed Downloads',
    color: PURPLE,
    fmt: toFixed,
    type: 'number',
    isModeled: true,
  },
  {
    accessor: 'attributedReach',
    title: 'Attributed Reach',
    color: 'var(--blue)',
    fmt: toFixed,
    type: 'number',
    isModeled: true,
  },
];
