import {gql} from '@apollo/client';
import CampaignPodcastFragment from '@analytics/graphql-api/_old_queries/fragments/CampaignPodcastFragment';

export default gql`
  mutation createCampaignPodcast($input: CreateCampaignPodcastInput!) {
    authed {
      success
    }
    createCampaignPodcast(input: $input) {
      campaignPodcast {
        ...CampaignPodcast
      }
    }
  }
  ${CampaignPodcastFragment}
`;
