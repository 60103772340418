import {useMemo, useState} from 'react';
import {FetchResult} from '@apollo/client';
import {Redirect} from 'react-router-dom';
import {Button} from '@core/ui/Button';
import {Card} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import {FormFooter} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import {InfoTooltip} from '@core/ui/Tooltip';
import {
  defaultWorkspaceConfig,
  toWorkspaceDTO,
} from '@analytics/components/advisor/onboarding/brand/quiz/helpers';
import {AdvisorQuizForm} from '@analytics/components/advisor/onboarding/brand/quiz/types';
import {QuizCardTitle} from '@analytics/components/advisor/onboarding/brand/shared';
import {
  CreateAdvisorSavedSearchMutation,
  CreateAdvisorSavedSearchMutationFn,
  FetchedBuySideOrganization,
  FetchedOrganization,
  useAddOrganizationAdvisorMutation,
  useCreateAdvisorSavedSearchMutation,
} from '@analytics/graphql-api';
import OrganizationQuery from '@analytics/graphql-api/_old_queries/OrganizationQuery';
import {advisorOnboardBrandURL} from '@analytics/lib/routes/useAdvisorRoutes';
import {AdvisorRouteProps} from '../../_routes';
import {useNavigateToAdvisor, useNavigateToQuiz} from './_routes';

type DataRow = {
  name: string;
  category: string;
  categoryFriendly: JSX.Element;
  id: string;
};

type Props = AdvisorRouteProps & {
  canOnboard: boolean;
  quizData: AdvisorQuizForm;
};

export function ReviewQuiz({quizData, canOnboard, organization}: Props) {
  const [addOrganizationAdvisor, {loading}] = useAddOrganizationAdvisorMutation(
    {
      awaitRefetchQueries: true,
      refetchQueries: [OrganizationQuery],
    }
  );
  const goToAdvisor = useNavigateToAdvisor(organization);
  const goToQuiz = useNavigateToQuiz(organization);
  const [createAdvisorWorkspace, {loading: _loading, error: _error}] =
    useCreateAdvisorSavedSearchMutation();

  const initialRows = useMemo(() => toDataRows(quizData), [quizData]);
  const [dataRows, setDataRows] = useState(initialRows);

  if (loading) return <Loading centered />;

  if (!canOnboard) {
    return <Redirect to={advisorOnboardBrandURL(organization)} />;
  }

  return (
    <>
      <QuizCardTitle
        title='Your Audience'
        description='Based on your answers to the quiz, we’ve tailored Advisor to your
        brand’s audience.'
      />
      <Card css={{marginRight: 0, marginLeft: 0}}>
        <DataTable
          data={initialRows}
          columns={[
            {
              title: 'Name',
              accessor: 'name',
              type: 'string',
            },
            {
              alignRight: true,
              title: 'Category',
              accessor: 'categoryFriendly',
              type: 'string',
            },
          ]}
          pagination={false}
          defaultSelectedRows={initialRows.map(({id}) => id)}
          emptyMessageText="You didn't fill out any questions in the onboarding quiz, so we'll set you up with something reasonable."
          onChangeSelectedRows={(rows) => {
            setDataRows(rows);
          }}
          bulkActions={[
            {
              title: '',
              onClick: () => undefined,
            },
          ]}
        />

        <FormFooter>
          <Button
            buttonSize='lg'
            disabled={!canOnboard}
            onClick={() => {
              goToQuiz();
            }}>
            Back
          </Button>
          <Button
            buttonSize='lg'
            disabled={!canOnboard}
            color='primary'
            onClick={() => {
              Promise.all([
                doCreateWorkspace(
                  quizData.audienceName,
                  createAdvisorWorkspace,
                  dataRows,
                  organization
                ),
                addOrganizationAdvisor({
                  variables: {input: {id: organization.id}},
                }),
              ])
                .then(getWorkspaceId)
                .then(goToAdvisor);
            }}>
            Submit
          </Button>
        </FormFooter>
      </Card>
    </>
  );
}

function doCreateWorkspace(
  name: string,
  createWorkspace: CreateAdvisorSavedSearchMutationFn,
  dataRows: DataRow[],
  organization: FetchedOrganization | FetchedBuySideOrganization
) {
  function trim(s?: string) {
    return (s ?? 'My Workspace').trim().slice(0, 256);
  }

  const workspaceConfig = defaultWorkspaceConfig({
    columns: dataRows
      .filter((row) =>
        [
          'nielsenInterests',
          'industries',
          'radioMarkets',
          'brands',
          'tags',
        ].includes(row.category)
      )
      .map((row) => row.id),
  });

  return createWorkspace({
    refetchQueries: [OrganizationQuery],
    variables: {
      input: {
        name: trim(name),
        version: 1,
        orgDefault: true,
        organizationId: organization.id,
        data: toWorkspaceDTO(workspaceConfig),
      },
    },
  });
}

function getWorkspaceId([workspaceResult]: [
  FetchResult<CreateAdvisorSavedSearchMutation>,
  any
]) {
  return workspaceResult.data?.createAdvisorSavedSearch?.advisorSavedSearch?.id;
}

const categories = {
  industries: 'Industry',
  nielsenInterests: 'Nielsen',
  contentMaturity: 'Content Maturity',
  radioMarkets: 'Radio Markets',
  tags: 'Tags',
  brands: 'Brands',
} as const;

function toFriendlyCategory(
  category: keyof typeof categories,
  description: string
) {
  return (
    <span css={{display: 'inline-flex', alignItems: 'center'}}>
      {categories[category]}
      <InfoTooltip
        description={description}
        iconSize={12}
        css={{marginLeft: '0.375rem', display: 'inline-block'}}
      />
    </span>
  );
}

function toDataRows(quizData: AdvisorQuizForm): DataRow[] {
  return [
    ...quizData.industries.map(({title, id}) => ({
      name: title,
      category: 'industries',
      categoryFriendly: toFriendlyCategory(
        'industries',
        "Industries that describe your brand's audience"
      ),
      id,
    })),
    ...quizData.nielsenInterests.map(({title, id}) => ({
      name: title,
      category: 'nielsenInterests',
      categoryFriendly: toFriendlyCategory(
        'nielsenInterests',
        'Percentage of audience that aligns with a segment'
      ),
      id,
    })),
    ...quizData.contentMaturity.map((id) => ({
      name: ({g: 'G', pg: 'PG', pg13: 'PG-13', r: 'R'} as const)[id],
      category: 'contentMaturity',
      categoryFriendly: toFriendlyCategory(
        'contentMaturity',
        'Rates the podcast transcripts according to content maturity standards inspired by historical MPAA and TV Parental Guidelines'
      ),
      id,
    })),
    ...quizData.radioMarkets.map(({title, id}) => ({
      name: title,
      category: 'radioMarkets',
      categoryFriendly: toFriendlyCategory(
        'radioMarkets',
        'Percentage of audience that aligns with a market'
      ),
      id,
    })),
    ...quizData.tags.map(({title, id}) => ({
      name: title,
      category: 'tags',
      categoryFriendly: toFriendlyCategory('tags', 'iTunes tags'),
      id,
    })),
    ...quizData.brands.map(({title, id}) => ({
      name: title,
      category: 'brands',
      categoryFriendly: toFriendlyCategory(
        'brands',
        'The number of ad placements of a brand in a specific show'
      ),
      id,
    })),
  ];
}
