import CampaignStateBadge from '@core/components/CampaignStateBadge/CampaignStateBadge';
import ImpressionsProgressBar from '@core/components/ImpressionsProgressBar/ImpressionsProgressBar';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Text} from '@core/ui/Text';
import {FetchedOrganization} from '@analytics/graphql-api';
import {SpotifyOrders} from './_types';

const DATE_FMT = 'MMM DD, YYYY';

export function SpotifySalesOrdersTable({
  data,
  organization,
}: {
  data: Readonly<SpotifyOrders>;
  organization: FetchedOrganization;
}) {
  return (
    <DataTable
      data={data}
      columns={
        [
          {
            title: 'Opportunity',
            accessor: 'name',
            type: 'string',
            Cell: ({d: {name}}) => (
              <Text variant='subtitle2' css={{minWidth: '12.5rem'}}>
                {name}
              </Text>
            ),
          },
          {
            title: 'Sales Order',
            accessor: 'id',
            type: 'string',
          },
          {
            title: 'Status',
            type: 'string',
            Cell: ({d: {campaigns}}) => {
              const state = campaigns[0]?.state;
              return state ? <CampaignStateBadge state={state} /> : '-';
            },
          },
          {
            title: 'Impressions',
            accessor: 'downloadTotal',
            type: 'number',
            Cell: ({d: {downloadTotal, campaigns}}) => {
              const goal = campaigns.reduce(
                (total, {goal}) => total + (goal ?? 0),
                0
              );
              return (
                <ImpressionsProgressBar
                  downloadTotal={downloadTotal ?? 0}
                  goal={goal}
                />
              );
            },
          },
          {
            title: 'Created',
            type: 'date',
            accessor: 'createdAt',
            fmt: (v) => dayjs(v).format(DATE_FMT),
          },
          {
            title: 'Ends',
            type: 'date',
            getValue: ({d: {campaigns}}) => {
              const ends = campaigns.reduce((endDate, campaign) => {
                if (!endDate) return campaign.expectedEndAt;
                return dayjs(campaign.expectedEndAt).isAfter(endDate, 'day')
                  ? campaign.expectedEndAt
                  : endDate;
              }, undefined);
              return dayjs(ends).format(DATE_FMT) ?? '-';
            },
          },
        ] as DataTableColumn<SpotifyOrders[number]>[]
      }
      onClickRowPath={({id}) =>
        createOrgPath(organization, `campaigns/orders/${id}`)
      }
      orderBy='-createdAt'
      paginationRowsPerPage={10}
      paginationRowsPerPageOptions={[10, 25, 50]}
      searchKeys={['id', 'name']}
    />
  );
}
