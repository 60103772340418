import { Arrow } from "../Dropdown/Arrow.js";
import { OverlayTrigger } from "../OverlayTrigger/index.js";
import { Overlay } from "../OverlayTrigger/Overlay.js";
import styled, { css } from "../../styled-components.js";
export var Trigger = styled(OverlayTrigger).withConfig({
  displayName: "Trigger",
  componentId: "sc-v2zcxe-0"
})(["", "{min-width:fit-content;width:100%;transform:", ";}", "{", ";}"], Overlay, function (props) {
  return props.placement === 'bottom' ? 'translate(-50%, 8px)' : 'translate(-50%, -8px)';
}, Arrow, function (props) {
  return props.overlay && css(["transform:rotate(180deg);"]);
});
export default Trigger;