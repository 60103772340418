import dayjs from '@core/lib/dayjs';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {ResearchNav} from '@analytics/components/nav/ResearchNav';
import BrandsTable from '@analytics/components/research/BrandsTable';
import RankingsTable from '@analytics/components/research/RankingsTable';

const Research = ({user, organization}) => {
  return (
    <>
      <ResearchNav user={user} organization={organization} />
      <Element
        tag='p'
        rules={({theme}) => ({
          color: theme.textTertiary,
          padding: '0 1.5rem',
        })}>
        200k+ ads from 5k+ brands, research organizes the world of podcast
        advertising.
      </Element>

      <CardHeader cta={{title: 'Explore all', path: '/research/rankings'}}>
        Top 10 Podcasts
      </CardHeader>
      <Card>
        <RankingsTable organization={organization} limit={10} />
      </Card>
      <CardHeader cta={{title: 'Explore all', path: '/research/brands'}}>
        Top 10 Brands
      </CardHeader>
      <Card>
        <BrandsTable
          organization={organization}
          limit={10}
          after={dayjs().subtract(2, 'weeks').format('YYYY-MM-DD')}
        />
      </Card>
    </>
  );
};

export default Research;
