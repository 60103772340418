import { assertNever } from "../../typeHelpers.js";
import styled from "../../styled-components.js";
export var getSize = function getSize(size) {
  switch (size) {
    case 'small':
      return 56;
    case 'medium':
      return 84;
    case 'large':
      return 112;
    default:
      return assertNever(size);
  }
};
export var ProgressDotsSvg = styled.svg.withConfig({
  displayName: "ProgressDotsSvg",
  componentId: "sc-18dhrwh-0"
})(["content:'';block-size:", "px;inline-size:", "px;"], function (props) {
  return getSize(props.size) / 4.5;
}, function (props) {
  return getSize(props.size);
});