import {
  FetchedCampaign,
  FetchedCampaignStreaming,
} from '@analytics/graphql-api';
import _CampaignStatsBase from './_CampaignStatsBase';
import {formatDate} from './helpers';

export const CampaignStreamingStats = ({
  campaign,
  campaignStreaming,
}: {
  campaign: FetchedCampaign;
  campaignStreaming: FetchedCampaignStreaming;
}) => {
  return (
    <_CampaignStatsBase
      instance={campaignStreaming}
      campaign={campaign}
      details={[
        {
          title: 'Started',
          value: campaignStreaming?.startAt
            ? formatDate(campaignStreaming.startAt)
            : '—',
        },
        {
          title: 'Group',
          value: campaignStreaming?.isBaseline ? 'Baseline' : 'Exposed',
        },
        {
          title: 'Discount Code',
          value: campaignStreaming?.discountCode || '—',
        },
        {
          title: 'Discount URL',
          value: campaignStreaming?.discountUrl || '—',
        },
        {
          title: 'Effective URL',
          value: campaignStreaming?.effectiveUrl || '—',
        },
      ]}
    />
  );
};
