import dayjs from 'dayjs';
import {Redirect, Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import ReviewersTranscribable from './ReviewersTranscribable';

const ReviewersTranscribableRedirect = ({match: {url}}) => {
  const after = dayjs().subtract(4, 'days').format('YYYY-MM-DD');
  const before = dayjs().subtract(1, 'days').format('YYYY-MM-DD');

  return <Redirect to={`${url}/${after}/${before}`} />;
};

const ReviewersTranscribableIndex = ({user, organization, reviewerId}) => {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/research/reviewers/transcribable'
        user={user}
        organization={organization}
        reviewerId={reviewerId}
        component={ReviewersTranscribableRedirect}
      />
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/research/reviewers/transcribable/:after/:before'
        user={user}
        organization={organization}
        reviewerId={reviewerId}
        component={ReviewersTranscribable}
      />
    </Switch>
  );
};

export default ReviewersTranscribableIndex;
