import { screenXsMax } from '@spotify-internal/encore-foundation';
import { cssSpacing, rootStyle, violaBold, visuallyHidden } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
export var LabelGroup = styled.div.withConfig({
  displayName: "LabelGroup",
  componentId: "sc-1ibddrg-0"
})(["", ";", ";align-items:center;display:flex;inline-size:100%;padding-block-end:", ";@media (max-width:", "){justify-content:space-between;}", ";", ";"], rootStyle(), violaBold(), cssSpacing('tighter-2', '8px'), screenXsMax, function (_ref) {
  var as = _ref.as;
  return as === 'legend' && css(["padding-inline-start:0;padding-inline-end:0;"]);
}, function (_ref2) {
  var $isHidden = _ref2.$isHidden;
  return $isHidden && css(["", ""], visuallyHidden());
});