import {ModalHeader} from '@core/ui/Content';
import {Overlay} from '@core/ui/Overlay';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedCampaignsConfirmTrackingUrls,
  FetchedOrganization,
} from '@analytics/graphql-api';
import TrackingUrlsTable from './TrackingUrlsTable';

type Props = {
  campaign: FetchedCampaign | FetchedCampaignsConfirmTrackingUrls[number];
  organization: FetchedOrganization | FetchedBuySideOrganization;
  opened: boolean;
  toggle: (_to?: boolean) => void;
};

export default function TrackingUrlsOverlay({
  campaign,
  organization,
  opened,
  toggle,
}: Props) {
  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      closeOnOutsideClick={false}
      withBackdrop
      css={`
        border-radius: 1rem;
        border: 0;
        display: flex;
        flex-direction: column;
        inset: 6rem;
        max-width: 80rem;
        overflow: hidden;
        padding: 2rem;
      `}>
      <ModalHeader toggle={toggle} withCloseIcon>
        Tracking URLs
      </ModalHeader>
      <TrackingUrlsTable campaign={campaign} organization={organization} />
    </Overlay>
  );
}
