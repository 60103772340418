import React from 'react';
import {TRules} from '@core/style';
import {Element} from '@core/style';

interface GridProps {
  children?: React.ReactNode;
  /**
   * @default 4
   */
  cols?: number;
  /**
   * @default '1rem'
   */
  gap?: string;
  /**
   * @default 1
   */
  rows?: number;
  rules?: TRules;
}

/**
 * @deprecated
 */
const Grid = ({
  children,
  cols = 4,
  gap = '1rem',
  rows = 1,
  rules,
}: GridProps): JSX.Element => (
  <Element
    rules={[
      () => ({
        display: 'grid',
        gridGap: gap,
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        gridTemplateRows: `repeat(${rows}, 1fr)`,
      }),
      rules,
    ]}>
    {children}
  </Element>
);

export default Grid;
