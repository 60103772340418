import styled from 'styled-components';
import {Overlay} from '@core/ui/Overlay';
import {useExportsContext} from '@analytics/components/notifications/ExportsNotificationContext';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  FetchedSavedExports,
} from '@analytics/graphql-api';
import {ReportsForm} from './ReportsForm';
import {PresetExport, ReportCategory} from './helpers';

export type {ExportTypeId, PresetExport, ReportsFormData} from './helpers';

type BaseProps = {
  category?: ReportCategory;
  onCancel?: () => void;
  onComplete?: () => void;
  opened: boolean;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  toggle: (_?: boolean) => void;
};

type Props =
  | (BaseProps & {
      presetExport?: undefined;
      savedExport?: undefined;
    })
  | (BaseProps & {
      presetExport?: undefined;
      savedExport: FetchedSavedExports[number];
    })
  | (BaseProps & {
      presetExport: PresetExport;
      savedExport?: undefined;
    });

export default function ReportsFormOverlay({
  category,
  onCancel,
  onComplete,
  opened,
  organization,
  presetExport,
  savedExport,
  toggle,
}: Props) {
  const {addExport} = useExportsContext();

  const _onComplete = (id?: string) => {
    if (id) addExport(id);
    onComplete?.();
    toggle(false);
  };

  return (
    <_Overlay
      animation='opacity'
      closeOnOutsideClick={false}
      opened={opened}
      toggle={toggle}
      withBackdrop
      fixed>
      <ReportsForm
        category={category}
        onCancel={() => {
          onCancel?.();
          toggle(false);
        }}
        onComplete={_onComplete}
        organization={organization}
        presetExport={presetExport}
        savedExport={savedExport}
      />
    </_Overlay>
  );
}

const _Overlay = styled(Overlay)`
  border-radius: 0;
  display: grid;
  grid-template-rows: auto 1fr;
  inset: 0 0 0 auto;
  max-width: 856px;
  width: 100%;
  overflow: none;
  padding: 0;
`;
