import {gql} from '@apollo/client';

export default gql`
  fragment PodlinkDomain on PodlinkDomainObject {
    id
    slug
    title
    description
  }
`;
