import CampaignConsistency from '@analytics/components/campaigns/CampaignConsistency';
import CampaignDynamicNav from '@analytics/components/nav/CampaignDynamicNav';
import {CampaignDynamicRouteProps} from './_routes';

export function CampaignDynamicConsistency({
  campaign,
  campaignDynamic,
  organization,
  user,
}: CampaignDynamicRouteProps) {
  return (
    <>
      <CampaignDynamicNav
        campaign={campaign}
        campaignDynamic={campaignDynamic}
        organization={organization}
        user={user}
      />
      <CampaignConsistency
        campaignId={campaign.id}
        campaignDynamicId={campaignDynamic?.id}
        organizationId={organization.id}
      />
    </>
  );
}
