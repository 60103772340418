import {cleanList} from '@core/lib/clean';
import dayjs from '@core/lib/dayjs';
import {isOrgAgency, isOrgBrand} from '@core/lib/organizations';
import {OrganizationKind} from '@analytics/graphql-api';
import {
  ChartItem,
  DailyCampaignBillingRecord,
  GroupBy,
  SimpleBillingRecord,
  TableItem,
  UsageData,
  UsageDateRange,
  UsageInterval,
} from './_types';

export const defaultGroupBy = (organization: {
  kind: OrganizationKind;
}): GroupBy[] => {
  if (isOrgAgency(organization)) return ['advertiser', 'publisher'];
  if (isOrgBrand(organization)) return ['publisher'];
  return ['advertiser'];
};

export const parseUsageData = ({
  data,
  dateRange,
  interval,
}: {
  data: UsageData | undefined;
  dateRange: UsageDateRange;
  interval: UsageInterval;
}) => {
  if (!data)
    return {
      chartData: [],
      tableData: [],
    };

  const {dailyCampaignBillingRecords, simpleBillingRecords} = data;

  return {
    chartData: parseChartData(
      cleanList(simpleBillingRecords),
      dateRange,
      interval
    ),
    tableData: parseTableData(cleanList(dailyCampaignBillingRecords)),
  };
};

export const RANGES = [
  {title: 'Current year', value: 'year'},
  {type: 'separator'},
  {title: 'Current month', value: 'month'},
  {title: 'Last month', value: 'last-month'},
  {title: 'Last 3 months', value: 'last-3-months'},
  {type: 'separator'},
  {title: 'Custom range', value: 'custom'},
];

export const INTERVALS = [
  {title: 'Daily cumulative', value: 'daily-cumulative'},
  {title: 'Monthly cumulative', value: 'monthly-cumulative'},
  {type: 'separator'},
  {title: 'Daily', value: 'daily'},
  {title: 'Monthly', value: 'monthly'},
];

const TODAY = dayjs().startOf('d');

export const DATES_BY_RANGE: Record<string, UsageDateRange> = {
  year: [TODAY.startOf('year'), TODAY],
  month: [TODAY.startOf('month'), TODAY],
  'last-month': [
    TODAY.subtract(1, 'month').startOf('month'),
    TODAY.subtract(1, 'month').endOf('month'),
  ],
  'last-3-months': [
    TODAY.subtract(3, 'months').startOf('month'),
    TODAY.subtract(1, 'month').endOf('month'),
  ],
};

/* ----------------------------------------------------------------------- */

const parseChartData = (
  data: ReadonlyArray<NonNullable<SimpleBillingRecord>>,
  dateRange: [dayjs.Dayjs, dayjs.Dayjs],
  interval: UsageInterval
) => {
  const isMonthly = interval.includes('monthly');
  const isCumulative = interval.includes('cumulative');

  const totalDaysOrMonths = Array(
    dateRange[1].diff(dateRange[0], isMonthly ? 'M' : 'd') + 1
  ).fill(0);

  return totalDaysOrMonths.reduce((dates, _, i) => {
    const findDate = dateRange[0].add(i, isMonthly ? 'months' : 'days');
    const sumCumulative = isCumulative && dates[i - 1] ? dates[i - 1].y : 0;
    return [
      ...dates,
      {
        x: findDate.toDate().getTime(),
        y: sumImpressions({data, findDate, isMonthly}) + sumCumulative,
      },
    ];
  }, [] as ChartItem[]);
};

const parseTableData = (
  data: ReadonlyArray<NonNullable<DailyCampaignBillingRecord>>
) => {
  return Array.from(
    data
      .reduce((acc, curr) => {
        if (!curr.campaignId) return acc;

        const oldImpressions = acc.get(curr.campaignId)?.impressions ?? 0;
        const newImpressions = curr?.impressions ?? 0;
        const impressions = oldImpressions + newImpressions;

        const tableData = acc.get(curr.campaignId) ?? curr;

        return acc.set(curr.campaignId, {...tableData, impressions});
      }, new Map<string, TableItem>())
      .values()
  );
};

const sumImpressions = ({
  data,
  findDate,
  isMonthly,
}: {
  data: ReadonlyArray<NonNullable<SimpleBillingRecord>>;
  findDate: dayjs.Dayjs;
  isMonthly: boolean;
}) =>
  data.reduce(
    (sum, {downloadDate, impressions}) =>
      dayjs(downloadDate).isSame(findDate, isMonthly ? 'month' : 'date')
        ? sum + (impressions ?? 0)
        : sum,
    0
  );
