import {useMemo, useState} from 'react';
import {
  ButtonTertiary,
  IconExclamationCircle,
  Text,
  Tooltip,
  TooltipTrigger,
  cssSpacing,
  semanticColors,
} from '@spotify-internal/encore-web';
import {capitalize} from 'lodash';
import CampaignStateBadge from '@core/components/CampaignStateBadge';
import ImpressionsProgressBar from '@core/components/ImpressionsProgressBar';
import dayjs from '@core/lib/dayjs';
import {comma, fmtCurrency, toFixed, toPercent} from '@core/lib/filters';
import {isOrgBrand, isOrgBuySide} from '@core/lib/organizations';
import {t} from '@core/texts';
import {DataTableColumn, DataTableServerFilterProps} from '@core/ui/DataTable';
import {Stack} from '@core/ui/Layout';
import {SettingsContextValue} from '@analytics/components/settings/SettingsContext';
import Tags from '@analytics/components/tags/Tags';
import {
  CampaignState,
  FetchedCampaigns,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {campaignNeedsReview} from '@analytics/lib/campaigns';
import {
  CampaignsTableDateRangeFilter,
  CampaignsTableImpressionsFilter,
  CampaignsTableMultipleSelectFilter,
  CampaignsTableTagsFilter,
} from './filters';
import {
  toCPM,
  toCR,
  toDownloadTotal,
  toFrequency,
  toRevenue,
  toSpend,
} from './utils';

type CampaignsTableColumn = FetchedCampaigns[number];

export const useCampaignsTableColumns = ({
  organization,
  settings,
}: {
  organization: FetchedOrganization;
  settings: SettingsContextValue;
}) =>
  useMemo(
    () =>
      [
        {
          title: 'Campaign',
          accessor: 'name',
          type: 'string',
          Cell: ({d}) => {
            const [showTooltip, setShowTooltip] = useState(false);

            const showTags = (d?.genericTags ?? []).length > 0;

            return (
              <Stack alignItems='center' gap={cssSpacing('base', '16px')}>
                {campaignNeedsReview({campaign: d}) &&
                  isOrgBrand(organization) && (
                    <TooltipTrigger
                      overlay={
                        showTooltip && (
                          <Tooltip id='campaignForReview'>
                            This campaign requires review from the publisher.
                            Please check back to ensure it is approved by the
                            scheduled start date.
                          </Tooltip>
                        )
                      }
                      placement='end'
                      onShow={() => setShowTooltip(true)}
                      onHide={() => setShowTooltip(false)}>
                      <ButtonTertiary
                        aria-labelledby='campaignForReview'
                        condensedAll
                        iconOnly={IconExclamationCircle}
                        semanticColor={semanticColors.textNegative}
                      />
                    </TooltipTrigger>
                  )}
                <Text variant='bodySmallBold' style={{minWidth: '12.5rem'}}>
                  {d?.name}
                </Text>
                {showTags && (
                  <Tags
                    instance={d}
                    organization={organization}
                    overlayProps={{
                      horizontalAlign: 'right',
                      noVerticalOverlap: true,
                      verticalAlign: 'bottom',
                      verticalOffset: 4,
                    }}
                  />
                )}
              </Stack>
            );
          },
          Filter: (props: DataTableServerFilterProps<CampaignsTableColumn>) => (
            <CampaignsTableTagsFilter organization={organization} {...props} />
          ),
        },
        {
          title: 'Advertiser',
          accessor: 'advertiser',
          type: 'string',
          noSortable: true,
          Cell: ({d}) => (
            <Text variant='bodySmall'>{d?.advertiser?.name ?? ''}</Text>
          ),
          hidden: isOrgBrand(organization), // Don't show for brands
        },
        {
          title: 'Publisher',
          accessor: 'organization',
          type: 'string',
          noSortable: true,
          fmt: (v) => v?.name,
          hidden: !isOrgBrand(organization) && !isOrgBuySide(organization), // Only show for brands or buy-side
        },
        {
          title: 'Type',
          accessor: 'kind',
          type: 'string',
          Cell: ({d}) => (
            <Text variant='bodySmall'>{capitalize(d?.kind ?? '')}</Text>
          ),
          Filter: (props: DataTableServerFilterProps<CampaignsTableColumn>) => (
            <CampaignsTableMultipleSelectFilter
              items={[
                {title: 'Attribution', value: 'attribution'},
                {title: 'Reporting', value: 'reporting'},
              ]}
              placeholder='Type'
              {...props}
            />
          ),
        },
        {
          title: 'State',
          accessor: 'state',
          type: 'string',
          Cell: ({d}) => <CampaignStateBadge state={d?.state} />,
          Filter: (props: DataTableServerFilterProps<CampaignsTableColumn>) => (
            <CampaignsTableMultipleSelectFilter
              items={Object.keys(CampaignState).map((state) => ({
                title: state,
                value: state.toLowerCase(),
              }))}
              placeholder='State'
              {...props}
            />
          ),
        },
        {
          ...t('stats.impressions'),
          accessor: 'downloadTotal',
          type: 'number',
          Cell: ({d}) => (
            <ImpressionsProgressBar
              downloadTotal={toDownloadTotal(d, settings)}
              goal={d?.goal}
            />
          ),
          Filter: (props: DataTableServerFilterProps<CampaignsTableColumn>) => (
            <CampaignsTableImpressionsFilter
              organization={organization}
              {...props}
            />
          ),
        },
        {
          ...t('stats.reach'),
          accessor: 'reachTotal',
          type: 'number',
          fmt: comma,
        },
        {
          ...t('stats.frequency'),
          accessor: 'frequency',
          noSortable: true,
          type: 'number',
          Cell: ({d}) => (
            <Text variant='bodySmall'>{toFixed(toFrequency(d, settings))}</Text>
          ),
        },
        {
          ...t('stats.spend', settings),
          accessor: settings.spendByImpressionsDelivered
            ? 'costToDate'
            : 'cost',
          noSortable: settings.spendByImpressionsDelivered,
          type: 'number',
          Cell: ({d}) => (
            <Text variant='bodySmall'>
              {fmtCurrency(toSpend(d, settings), d?.currency)}
            </Text>
          ),
        },
        {
          ...t('stats.cpm'),
          accessor: 'cpm',
          noSortable: true,
          type: 'number',
          Cell: ({d}) => (
            <Text variant='bodySmall'>
              {fmtCurrency(toCPM(d, settings), d?.currency)}
            </Text>
          ),
          hidden: isOrgBrand(organization), // Don't show for brands
        },
        {
          ...t('stats.cr'),
          accessor: 'cr',
          noSortable: true,
          type: 'number',
          Cell: ({d}) => (
            <Text variant='bodySmall'>{toPercent(toCR(d, settings))}</Text>
          ),
        },
        {
          ...t('stats.revenue'),
          accessor: 'valueTotal',
          type: 'number',
          Cell: ({d}) => (
            <Text variant='bodySmall'>
              {fmtCurrency(toRevenue(d, settings), d?.currency)}
            </Text>
          ),
          hidden: !isOrgBrand(organization), // Only show for brands
        },
        {
          title: 'Attributed Downloads',
          accessor: 'targetDownloadTotal',
          type: 'number',
          fmt: toFixed,
          hidden: !isOrgBrand(organization), // Only show for brands
        },
        {
          title: 'Attributed Reach',
          accessor: 'targetReachTotal',
          type: 'number',
          fmt: toFixed,
          hidden: !isOrgBrand(organization), // Only show for brands
        },
        {
          title: 'Created',
          accessor: 'createdAt',
          type: 'date',
          fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
          Filter: (props: DataTableServerFilterProps<CampaignsTableColumn>) => (
            <CampaignsTableDateRangeFilter
              label='Created'
              organization={organization}
              {...props}
            />
          ),
        },
        {
          title: 'Starts',
          accessor: 'startAt',
          type: 'date',
          fmt: (v) => (v ? dayjs(v).format('MMM DD, YYYY') : '-'),
        },
        {
          title: 'Ends',
          accessor: 'endAt',
          type: 'date',
          fmt: (v) => (v ? dayjs(v).format('MMM DD, YYYY') : '-'),
        },
      ] satisfies DataTableColumn<CampaignsTableColumn>[],
    [organization, settings]
  );
