import React, {useRef, useState} from 'react';
import {Element} from '@core/style';
import Button, {IconButton} from '@core/ui/Button/LegacyButton';
import DataTableFilterButton from '@core/ui/DataTable/misc/DataTableFilterButton';
import {Icon} from '@core/ui/Icon';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import Select from '@core/ui/Select';
import {AdvisorColumn, AdvisorSortByItem, AdvisorSortDirection} from './types';

interface IAdvisorSortDropdown {
  activeColumns: AdvisorColumn[];
  defaultSortBy?: AdvisorSortByItem[];
  onChange: (_props: {
    sortBy: AdvisorSortByItem[];
    fromUserAction?: boolean;
  }) => void;
}

interface IAdvisorSortDropdownContent extends IAdvisorSortDropdown {
  toggle: (_to: boolean) => void;
}

const SORT_OPTIONS: {title: string; value: AdvisorSortDirection}[] = [
  {title: 'ASC', value: 'asc'},
  {title: 'DESC', value: 'desc'},
];

const AdvisorSortDropdownContent = ({
  activeColumns,
  defaultSortBy,
  onChange,
  toggle,
}: IAdvisorSortDropdownContent): JSX.Element => {
  // TODO: do a proper deep merge...
  const oldDefaultSortBy = useRef<AdvisorSortByItem[]>(
    JSON.parse(JSON.stringify(defaultSortBy))
  );
  const [sortBy, setSort] = useState<AdvisorSortByItem[]>(
    defaultSortBy ? defaultSortBy.slice(0) : []
  );

  const onRemoveField = (item: AdvisorSortByItem) => {
    sortBy.splice(sortBy.indexOf(item), 1);
    setSort(sortBy.slice(0));
  };

  const onAddField = () => {
    sortBy.push({id: '', direction: 'desc'});
    setSort(sortBy.slice(0));
  };

  const onColumnSelect = ({
    item,
    columnId,
  }: {
    item: AdvisorSortByItem;
    columnId: string;
  }) => {
    item.id = columnId;
    setSort(sortBy.slice(0));
  };

  const onDirectionSelect = ({
    item,
    value,
  }: {
    item: AdvisorSortByItem;
    value: AdvisorSortDirection;
  }) => {
    item.direction = value;
    setSort(sortBy.slice(0));
  };

  const onClickSave = () => {
    onChange({sortBy: sortBy.filter(({id}) => !!id), fromUserAction: true});
    toggle(false);
  };

  const onClickClose = () => {
    onChange({sortBy: oldDefaultSortBy.current});
    toggle(false);
  };

  return (
    <>
      <Element rules={() => ({fontSize: '0.875rem', padding: '1rem'})}>
        <Element rules={() => ({minWidth: '23.125rem'})}>
          {sortBy.map((item, idx) => {
            return (
              <Element
                key={`${item.id}${idx}`}
                rules={() => ({
                  alignItems: 'center',
                  display: 'grid',
                  gridGap: '0.625rem',
                  gridTemplateColumns:
                    '1.5rem 3.5rem minmax(11.25rem, 1fr) 5rem',
                  marginBottom: '0.625rem',
                })}>
                <IconButton
                  onClick={() => onRemoveField(item)}
                  rules={({theme}) => ({
                    padding: 0,
                    color: theme.iconSecondary,
                  })}>
                  <Icon icon='remove-circle-outline' />
                </IconButton>
                <div>{idx === 0 ? 'Sort by' : 'and then'}</div>
                <Select
                  key={sortBy.map(({id}) => id).join('-')}
                  items={activeColumns.filter(
                    ({id}) =>
                      item.id === id || !sortBy.find((obj) => obj.id === id)
                  )}
                  propertyForName='title'
                  propertyForValue='id'
                  propertyForDescription=''
                  defaultValue={item.id}
                  outline
                  small
                  onSelect={({id}) => onColumnSelect({item, columnId: id})}
                  rules={() => ({width: '100%'})}
                />
                <Select
                  items={SORT_OPTIONS}
                  defaultValue={item.direction}
                  small
                  onSelect={({value}) => onDirectionSelect({item, value})}
                  rules={() => ({width: '100%'})}
                />
              </Element>
            );
          })}
        </Element>
        <IconButton
          onClick={onAddField}
          rules={() => ({
            padding: '0.25rem 0 0 0',
          })}>
          <Icon
            icon='add-circle-outline'
            rules={({theme}) => ({
              marginRight: '0.375rem',
              color: theme.iconSecondary,
            })}
          />
          Add field
        </IconButton>
      </Element>
      <Element
        rules={({theme}) => ({
          borderTop: `2px solid ${theme.bgTertiary}`,
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0.75rem 1rem',
        })}>
        <Button
          style-small
          onClick={onClickClose}
          rules={() => ({marginRight: '0.625rem'})}>
          Cancel
        </Button>
        <Button style-small style-secondary onClick={onClickSave}>
          Save
        </Button>
      </Element>
    </>
  );
};

const AdvisorSortDropdown = ({
  activeColumns,
  defaultSortBy,
  onChange,
}: IAdvisorSortDropdown): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [opened, toggle] = useOverlay();

  return (
    <>
      <DataTableFilterButton
        domRef={buttonRef}
        onClick={toggle}
        active={defaultSortBy && defaultSortBy.length > 0}
        noArrowIcon
        rules={({theme}) => ({
          background: theme.bgPrimary,
          padding: '0 0.9375rem 0 0.75rem',
        })}>
        <Icon
          icon='sort'
          rules={({theme}) => ({
            color: theme.iconSecondary,
            margin: '0.125rem 0.375rem 0 0',
            width: '1.25rem',
            height: '1.25rem',
          })}
        />{' '}
        Sort
        {defaultSortBy && defaultSortBy.length > 0
          ? ` (${defaultSortBy && defaultSortBy.length})`
          : ''}
      </DataTableFilterButton>
      {opened ? (
        <Overlay
          opened={opened}
          toggle={toggle}
          positionTarget={buttonRef.current}
          horizontalAlign='right'
          verticalOffset={2}
          withBackdrop
          transparentBackdrop
          withShadow
          animation='opacity'
          css={`
            max-width: 33rem;
          `}>
          <AdvisorSortDropdownContent
            activeColumns={activeColumns}
            defaultSortBy={defaultSortBy}
            toggle={toggle}
            onChange={onChange}
          />
        </Overlay>
      ) : null}
    </>
  );
};

export default AdvisorSortDropdown;
