import {gql} from '@apollo/client';
import CampaignTargetFragment from './fragments/CampaignTargetFragment';

export default gql`
  query getCampaignTarget($organizationId: ID!, $campaignId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          campaignTarget(id: $id) {
            ...CampaignTarget

            attribution {
              marketing {
                acquired {
                  day
                  count
                }

                targetEpisodes {
                  count
                  nodes {
                    id
                    downloads
                    episode {
                      id
                      title
                      description(words: 50)
                      published
                      image {
                        id
                        href
                        path
                      }
                      enclosure {
                        id
                        length
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CampaignTargetFragment}
`;
