import {capitalize} from '@core/lib/filters';
import {Card} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {canUpdateCampaign} from '@analytics/lib/campaigns';
import {CampaignDraftProps} from '..';
import {CampaignPathButton, Title} from '../_shared';
import {CampaignDraftAdsTable} from './CampaignDraftAdsTable';

export function CampaignDraftAdsCard({
  organization,
  campaign,
}: CampaignDraftProps) {
  return (
    <Card css={{margin: 0}}>
      <Stack direction='column' gap='var(--spacing-6)'>
        {(['dynamic', 'embedded', 'streaming'] as const).map((type) => (
          <Stack key={type} direction='column' gap='var(--spacing-1)'>
            <Title
              rightContent={
                displayEditAdsButton({organization, campaign, type}) && (
                  <CampaignPathButton toCampaignPath='bulk'>
                    Edit Ads
                  </CampaignPathButton>
                )
              }>
              {capitalize(type)} Ads
            </Title>
            <CampaignDraftAdsTable campaign={campaign} type={type} />
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}

const displayEditAdsButton = ({organization, campaign, type}) =>
  canUpdateCampaign({organization, campaign}) && type === 'dynamic';
