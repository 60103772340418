import {useCallback, useState} from 'react';
// import {useDeferSpotifyOrderBrandAssignmentMutation} from '@admin/graphql-api';
import {
  Banner,
  Box,
  FormInput,
  FormInputIcon,
  IconSearch,
  ProgressCircle,
} from '@spotify-internal/encore-web';
import debounce from 'lodash.debounce';
import {Card, CardHeader} from '@core/ui/Content';
import {useUnmappedSpotifyOrdersQuery} from '@analytics/graphql-api';
import {DIALOGUE_STATES} from './DialogueStates';
import {MappingDialogue} from './MappingDialogue';
import {SalesOrderReviewTable} from './SalesOrderReviewTable';
import {UnmappedSalesOrder, unmappedSalesOrderMapper} from './mapRowToData';

const SalesOrderLoading = () => {
  return (
    <Box style={{display: 'flex', justifyContent: 'center'}}>
      <ProgressCircle aria-label='Loading Sales Order Data' />
    </Box>
  );
};

export const SalesOrderReviewCard = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [approvalDialogueState, setApprovalDialogueState] = useState(
    DIALOGUE_STATES.CLOSED
  );
  const [currentRow, setCurrentRow] = useState<UnmappedSalesOrder>();

  const debouncedSetSearchTerm = useCallback(
    debounce((value) => setSearchTerm(value), 300),
    []
  );

  const handleSearchOnChange = (e) => {
    setInputValue(e.target.value); // immediate update for search input
    debouncedSetSearchTerm(e.target.value); // debounced update for the network call
  };

  const [pagination, setPagination] = useState({
    limit: 50,
    offset: 0,
  });

  const handlePaginationChange = (newPage) => {
    setPagination((prev) => ({ ...prev, ...newPage }));
  };

  const {
    data: salesOrderData,
    // TODO:SPAA-32 Handle loading and error once error screen is added
    loading: salesOrderLoading,
    error: salesOrderError,
    refetch,
  } = useUnmappedSpotifyOrdersQuery({
    variables: {
      nameSearch: searchTerm,
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });

  // const [deferOrder] = useDeferSpotifyOrderBrandAssignmentMutation();

  const unmappedSpotifyOrdersRaw =
    salesOrderData?.me?.organization?.unmappedSpotifyOrders.results ?? [];

  const unmappedSpotifyOrders = unmappedSpotifyOrdersRaw?.map(
    unmappedSalesOrderMapper
  );

  const setSelectedRowAndState = (id, state) => {
    const selectedRow = unmappedSpotifyOrdersRaw.find((row) => row.id === id);
    if (selectedRow) {
      setCurrentRow(unmappedSalesOrderMapper(selectedRow));
      setApprovalDialogueState(state);
    }
  };

  const onApprove = (id) => {
    setSelectedRowAndState(id, DIALOGUE_STATES.APPROVE);
  };

  const onEdit = (id) => {
    setSelectedRowAndState(id, DIALOGUE_STATES.EDIT);
  };

  const onDefer = (id) => {
    setSelectedRowAndState(id, DIALOGUE_STATES.SUBMIT_A_TICKET);
  };

  const onClose = () => {
    refetch();
    setCurrentRow(undefined);
    setApprovalDialogueState(DIALOGUE_STATES.CLOSED);
  };

  const ordersCount = unmappedSpotifyOrders.length;

  return (
    <Card style={{overflow: 'scroll'}}>
      {approvalDialogueState !== DIALOGUE_STATES.CLOSED && (
        <MappingDialogue
          onClose={onClose}
          data={currentRow}
          approvalDialogueState={approvalDialogueState}
          setApprovalDialogueState={setApprovalDialogueState}
        />
      )}
      <CardHeader>For review</CardHeader>
      <Banner style={{marginTop: 24, marginBottom: 24}}>
        {`There are ${ordersCount} opportunities for review. Please help correctly map these
        from Cloudsense to an organization in Spotify Ad Analytics.`}
      </Banner>
      <FormInputIcon
        iconLeading={<IconSearch />}
        style={{width: 200, marginBottom: 24}}>
        <FormInput
          type='text'
          placeholder='Search'
          onChange={handleSearchOnChange}
          value={inputValue}
        />
      </FormInputIcon>
      {salesOrderLoading ? (
        <SalesOrderLoading />
      ) : (
        <SalesOrderReviewTable
          unmappedSpotifyOrders={unmappedSpotifyOrders}
          onEdit={onEdit}
          onApprove={onApprove}
          onDefer={onDefer}
          onPaginationChange={handlePaginationChange}
          pagination={pagination}
          total={salesOrderData?.me?.organization?.unmappedSpotifyOrders.total ?? 0}
        />
      )}
    </Card>
  );
};
