import {TooltipProps} from 'recharts';
import {toFixed} from '@core/lib/filters';
import {fmtPercentDiff} from '../helpers';
import {BrandLiftChartTooltip} from '../shared';

export function BrandLiftBarChartTooltip({
  label,
  payload,
}: TooltipProps<number, string>) {
  if (!payload || !payload.length) return null;
  return (
    <BrandLiftChartTooltip
      label={label}
      labelItem={{
        label: fmtPercentDiff(payload[0]?.payload?.percentChange),
        significance: payload[0]?.payload?.significance,
      }}
      valueByGroup={{
        control: {
          label: `Control (n=${payload[0]?.payload?.controlTotal})`,
          value: `${toFixed(payload[0]?.payload?.controlPercentage, 1)}%`,
        },
        exposed: {
          label: `Exposed (n=${payload[0]?.payload?.exposedTotal})`,
          value: `${toFixed(payload[0]?.payload?.exposedPercentage, 1)}%`,
        },
      }}
    />
  );
}
