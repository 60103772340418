import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Redirect} from 'react-router-dom';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import PixelActionMapForm from '@analytics/components/pixels/PixelActionMapForm';
import CREATE_ACTION_MAP_MUTATION from '@analytics/graphql-api/_old_mutations/CreateActionMap';
import ORGANIZATION_KEY_QUERY from '@analytics/graphql-api/_old_queries/OrganizationKeyQuery';
import {ManagePixelsPixelMappingsRouteProps} from '.';

export function PixelAddMapping({
  backPath,
  pixel,
}: ManagePixelsPixelMappingsRouteProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [complete, setCompleted] = useState(false);
  const [createActionMap] = useMutation(CREATE_ACTION_MAP_MUTATION, {
    refetchQueries: [ORGANIZATION_KEY_QUERY],
    awaitRefetchQueries: true,
  });

  const onSubmit = async (item: {eventName: string; action: string}) => {
    setError(false);
    setLoading(true);
    try {
      const data = await createActionMap({
        variables: {
          input: {
            key: pixel.key,
            ...item,
          },
        },
      });

      if (data) {
        setCompleted(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const onCancel = () => {
    setError(false);
    setCompleted(true);
  };

  if (complete && !error) {
    return <Redirect to={backPath} />;
  }

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={backPath}>Add Event Mapping</CardHeader>
      <Card>
        {error && (
          <ToastMessage
            rules={() => ({margin: '0 0 1.5rem'})}
            alertType='error'>
            Network Error. Please Try again.
          </ToastMessage>
        )}
        <PixelActionMapForm
          onCancel={onCancel}
          onSubmit={onSubmit}
          loading={loading}
        />
      </Card>
    </ResponsiveContent>
  );
}
