import {Controller} from 'react-hook-form';
import {Label} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import Select from '@core/ui/Select';
import {
  CampaignsQueryVariables,
  useCampaignsQuery,
} from '@analytics/graphql-api';
import {FieldProps} from '../helpers';
import {LoadingContainer} from '../shared';

type Props = FieldProps & {
  disabled?: boolean;
  optional?: boolean;
  organizationId: string;
};

export function CampaignField({
  control,
  disabled = false,
  optional = false,
  organizationId,
  watch,
}: Props) {
  const {
    data,
    loading,
    error: fetchError,
  } = useCampaignsQuery({
    variables: {
      organizationId,
    } as CampaignsQueryVariables,
  });

  return (
    <Stack direction='column'>
      <Label
        htmlFor='campaign'
        rules={() => ({...(disabled && {pointerEvents: 'none'})})}>
        Campaign
      </Label>
      <Controller
        name={
          optional
            ? 'exportParameters.campaignId'
            : 'exportParameters.instanceId'
        }
        control={control}
        rules={{
          required: !optional && 'Please select a campaign',
        }}
        render={({field, fieldState: {error}}) =>
          loading || !!fetchError ? (
            <LoadingContainer
              fetchError={!!fetchError}
              formError={!!error}
              loading={loading}
            />
          ) : (
            <Select
              key={watch('typeId')}
              buttonProps={{id: 'campaign'}}
              defaultValue={field.value ?? undefined}
              disabled={disabled}
              invalid={!!error}
              items={data?.me?.organization.campaigns?.results ?? []}
              onSelect={({id}) => field.onChange(id)}
              outline
              placeholder='Campaign'
              propertyForName='name'
              propertyForValue='id'
              searchable
              searchKeys={['name']}
            />
          )
        }
      />
    </Stack>
  );
}
