import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Feed from './Feed';
import Feeds from './Feeds';
import EpisodesIndex from './episodes';

const FeedsIndex = ({user, organization, company}) => {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/research/brands/:companyId/podcasts'
        user={user}
        organization={organization}
        company={company}
        component={Feeds}
      />
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/research/brands/:companyId/podcasts/:feedId'
        user={user}
        organization={organization}
        company={company}
        component={Feed}
      />
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/research/brands/:companyId/podcasts/:feedId/episodes/:episodeId'
        user={user}
        organization={organization}
        company={company}
        component={EpisodesIndex}
      />
    </Switch>
  );
};

export default FeedsIndex;
