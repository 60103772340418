import {gql} from '@apollo/client';

export default gql`
  query getAdvisorPodcastEpisodeDetail(
    $organizationId: ID!
    $feedId: ID!
    $id: ID!
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        advisor {
          show(id: $feedId) {
            id

            feed {
              id

              episode(id: $id) {
                id
                title
                enclosure {
                  id
                  episodeId
                  href
                  type
                  duration
                  length
                  mpegPath
                  waveform
                }

                placements {
                  id
                  startTime
                  endTime
                  ad {
                    id
                    mpegPath
                    flacPath
                    waveform
                    duration

                    company {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
