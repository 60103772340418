import {Switch} from 'react-router-dom';
import PodcastAudienceNav from '@analytics/components/nav/PodcastAudienceNav';
import {PodcastRouteProps} from '..';
import AudienceDemographics from './AudienceDemographics';
import AudienceIntent from './AudienceIntent';
import {getPodcastAudienceRoutes} from './_routes';

export default function AudienceIndex({
  user,
  organization,
  podcast,
}: PodcastRouteProps) {
  const {renderRoutes} = getPodcastAudienceRoutes({organization});

  const nielsenApproval =
    organization.isNielsenApproved || podcast.feed?.isNielsenApproved;
  const experianApproval =
    organization.isExperianApproved || podcast.feed?.isExperianApproved;
  const {isSegmentsEnabled} = organization;

  return (
    <>
      {(isSegmentsEnabled || user.isAdmin) && (
        <>
          <PodcastAudienceNav organization={organization} podcast={podcast} />
          <Switch>
            {renderRoutes({
              components: {
                demographics: AudienceDemographics,
                intent: AudienceIntent,
              },
              extraProps: {
                user,
                organization,
                podcast,
                experianApproval,
                nielsenApproval,
              },
              redirects: [{from: '*', to: ''}],
            })}
          </Switch>
        </>
      )}
    </>
  );
}
