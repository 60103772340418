import {gql} from '@apollo/client';

export default gql`
  query CompanyAds(
    $organizationId: ID!
    $id: ID!
    $limit: Int
    $orderBy: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id

        research {
          company(id: $id) {
            id
            ads(limit: $limit, orderBy: $orderBy, state: "edited") {
              id
              mpegPath
              flacPath
              waveform
              text
              duration
              state
              src

              firstPublished

              company {
                id
                name
              }

              product {
                id
                name
              }

              sponsor {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
