import {DropdownMenu} from '@core/ui/DropdownMenu';

const AudioPlayback = ({playbackRate, onSelectPlaybackRate}) => {
  return (
    <div
      css={`
        align-items: center;
        background: var(--bg-muted);
        border-radius: 0.5rem;
        color: var(--text-muted);
        cursor: pointer;
        display: inline-flex;
        font-size: 13px;
        font-weight: 500;
        height: 2.1875rem;
        justify-content: center;
        white-space: nowrap;
        width: 2.1875rem;
        :hover {
          color: var(--primary);
        }
      `}>
      <DropdownMenu
        selectedValue={playbackRate}
        items={[
          {title: '0.5x', value: 0.5},
          {title: '1x (Normal)', value: 1},
          {title: '2x', value: 2},
          {title: '3x', value: 3},
          {title: '4x', value: 4},
        ]}
        onSubmit={({value}) => onSelectPlaybackRate(value)}>
        {playbackRate}x
      </DropdownMenu>
    </div>
  );
};

export default AudioPlayback;
