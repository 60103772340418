import {ChangeEvent} from 'react';
import {ButtonIcon} from '@core/ui/Button';
import {DropdownMenu} from '@core/ui/DropdownMenu';
import {SearchInput} from '@core/ui/FormElements';
import {SearchContainer} from './shared';

type Props = {
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onDeselectAll: () => void;
  onSelectAll: () => void;
};

export function SearchColumnsInput({
  onInputChange,
  onDeselectAll,
  onSelectAll,
}: Props) {
  return (
    <SearchContainer>
      <SearchInput
        placeholder='Search Columns'
        onInput={onInputChange}
        small
        inputRules={() => ({fontSize: '0.875rem'})}
      />
      <DropdownMenu
        items={[
          {
            title: 'Select All',
            callback: onSelectAll,
          },
          {
            title: 'Deselect All',
            callback: onDeselectAll,
          },
        ]}>
        <ButtonIcon
          icon='more'
          css={{padding: 0, transform: 'rotate(90deg)'}}
        />
      </DropdownMenu>
    </SearchContainer>
  );
}
