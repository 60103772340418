import {useState} from 'react';
import dayjs from '@core/lib/dayjs';
import {Card, CardHeader} from '@core/ui/Content';
import {DateRangeInput} from '@core/ui/DatePicker';
import {Stack} from '@core/ui/Layout';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {Text} from '@core/ui/Text';
import {ExportButton} from '@analytics/components/ExportOverlay';
import {FeedNav} from '@analytics/components/nav/ResearchNav';
import {FeedObject, OrganizationObject} from '@analytics/graphql-api';

type Props = {
  organization: OrganizationObject;
  feed: FeedObject;
};

export default function FeedExport({organization, feed}: Props) {
  const [selectedDates, setSelectedDates] = useState({
    startDate: dayjs().startOf('month'),
    endDate: dayjs().startOf('d'),
  });
  return (
    <>
      <FeedNav organization={organization} feed={feed} />
      <ResponsiveContent containerSize='medium'>
        <CardHeader>Export</CardHeader>
        <Card>
          <Stack direction='column' spacing='var(--spacing-4)'>
            <Text>
              Export a CSV of all the ad placements in the podcast{' '}
              <b>{feed.title}</b>
            </Text>
            <Text>Select a range of dates for the export.</Text>
            <DateRangeInput
              startDate={selectedDates.startDate}
              endDate={selectedDates.endDate}
              onDatesChange={({startDate, endDate}) => {
                setSelectedDates({startDate, endDate});
              }}
              isOutsideRange={(date) => {
                return (
                  date.isBefore(dayjs('2019-01-01', 'YYYY-MM-DD')) ||
                  date.isAfter(dayjs())
                );
              }}
            />
            <ExportButton
              objectType='feed'
              kind='placements'
              pk={feed.id}
              params={{
                after: selectedDates.startDate.format('YYYY-MM-DD'),
                before: selectedDates.endDate.format('YYYY-MM-DD'),
              }}
              organizationId={organization.id}>
              Export
            </ExportButton>
          </Stack>
        </Card>
      </ResponsiveContent>
    </>
  );
}
