import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {Element, GREEN} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import RemoveAccessMutation from '@analytics/graphql-api/_old_mutations/RemoveAccess';
import CampaignPermissionQuery from '@analytics/graphql-api/_old_queries/CampaignPermissionQuery';
import CampaignPermissionsQuery from '@analytics/graphql-api/_old_queries/CampaignPermissionsQuery';

class CampaignPermission extends React.Component {
  state = {
    // Campaign Name
    state: '',

    loading: false,
    complete: false,
  };

  onChange = ({target: {name, value}}) => {
    const update = {error: false};
    update[name] = value;
    this.setState(update);
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {updateCampaignState, campaign} = this.props;

    this.setState({
      loading: true,
    });

    updateCampaignState({
      id: campaign.id,
      state: 'live',
    }).then(() => {
      this.setState({
        complete: true,
      });
    });
  };

  render() {
    const {user, organization, campaign, data} = this.props;
    const {loading, complete, error} = this.state;

    if (data.loading) {
      return <Loading centered />;
    }

    const {
      permission: {id, title, description},
    } = data.me.organization.campaign;

    return (
      <>
        <CampaignNav
          organization={organization}
          campaign={campaign}
          user={user}
        />
        <ResponsiveContent containerSize='large'>
          <CardHeader>Campaign Permissions</CardHeader>
          <Card>
            <p>The brand has granted you the following permissions.</p>

            <Element key={id} rules={() => ({display: 'flex'})}>
              <Element rules={() => ({flex: 1})}>
                <h5>
                  <Element
                    rules={() => ({
                      background: GREEN,
                      display: 'inline-block',
                      marginRight: '5px',
                      width: '12px',
                      height: '12px',
                      borderRadius: '100%',
                    })}
                  />
                  {title}
                </h5>
                <p>{description}</p>
              </Element>
            </Element>
          </Card>
        </ResponsiveContent>
      </>
    );
  }
}

export default compose(
  graphql(CampaignPermissionQuery, {
    options: ({
      organization,
      campaign,
      match: {
        params: {accessId},
      },
    }) => ({
      variables: {
        organizationId: organization.id,
        campaignId: campaign.id,
        id: accessId,
      },
    }),
  }),
  graphql(RemoveAccessMutation, {
    props: ({
      ownProps: {
        organization,
        campaign,
        match: {
          params: {accessId},
        },
      },
      mutate,
    }) => ({
      removeAccess: (input) => {
        return mutate({
          variables: {
            input,
          },
          update: (
            store,
            {
              data: {
                removeAccess: {success},
              },
            }
          ) => {
            const query = {
              query: CampaignPermissionsQuery,
              variables: {
                organizationId: organization.id,
                id: campaign.id,
              },
            };

            query.data = store.readQuery(query);
            query.data.me.organization.campaign.permissions =
              query.data.me.organization.campaign.permissions.filter(
                ({id}) => id != accessId
              );
            store.writeQuery(query);
          },
        });
      },
    }),
  })
)(CampaignPermission);
