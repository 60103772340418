import {
  EmptyState,
  EmptyStateButton,
  EmptyStateTitle,
  IconAd,
  IconExclamationCircle,
  ProgressDots,
  cssSpacing,
} from '@spotify-internal/encore-web';
import EpisodePlayer from '@core/components/EpisodePlayer';
import {comma} from '@core/lib/filters';
import {formatDate} from '@core/lib/time';
import {Card, CardHeader} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {CardStat} from '@core/ui/Stat';
import Placement from '@analytics/components/Placement';
import {PodcastEpisodeNav} from '@analytics/components/nav/PodcastNav';
import {PodcastEpisodeImpressionsChart} from '@analytics/components/podcasts/PodcastImpressionsChart';
import {useGetPodcasterEpisodeQuery} from '@analytics/graphql-api';
import {PodcastRouteProps} from '.';

export function PodcastEpisode({
  organization,
  podcast,
  match: {
    params: {episodeId},
  },
}: PodcastRouteProps & {
  match: {params: {episodeId: string}};
}) {
  const {data, loading, error, refetch} = useGetPodcasterEpisodeQuery({
    variables: {
      organizationId: organization.id,
      podcastId: podcast.id,
      id: episodeId,
      algorithm: organization.downloadAlgorithm,
    },
  });

  if (loading) {
    return <ProgressDots size='medium' />;
  }

  if (error) {
    return (
      <EmptyState icon={IconExclamationCircle} iconColorSet='negative'>
        <EmptyStateTitle variant='cello'>
          Couldn't load the page
        </EmptyStateTitle>
        <EmptyStateButton
          condensedAll
          semanticColor='textSubdued'
          onClick={() => refetch()}>
          Try again
        </EmptyStateButton>
      </EmptyState>
    );
  }

  const episode = data?.me?.organization.podcast.feed?.episode;

  const downloads = episode?.downloads;
  const placements = episode?.placements ?? [];

  return (
    <>
      <PodcastEpisodeNav
        organization={organization}
        podcast={podcast}
        episode={episode}
      />
      <Stack gap={cssSpacing('base')} css={{margin: '0 1.5rem 1.5rem'}}>
        <CardStat title='Impressions' value={comma(downloads?.count ?? 0)} />
        <CardStat title='Published' value={formatDate(episode?.published)} />
      </Stack>
      <Card>
        <CardHeader>Impressions</CardHeader>
        <PodcastEpisodeImpressionsChart
          organization={organization}
          podcast={podcast}
          episode={episode}
        />
      </Card>
      <Card>
        <CardHeader>Episode</CardHeader>
        {placements.length > 0 ? (
          <EpisodePlayer
            episode={episode}
            placements={placements}
            height={75}
          />
        ) : (
          <EmptyState
            icon={IconAd}
            iconColorSet='announcement'
            variant='contextual'>
            <EmptyStateTitle variant='cello'>
              No ads found for this episode
            </EmptyStateTitle>
          </EmptyState>
        )}
      </Card>
      <Card>
        <CardHeader>Ads</CardHeader>
        {placements.length > 0 ? (
          placements.map((placement) => (
            <Placement
              key={placement?.id}
              episode={episode}
              feed={podcast.feed}
              organization={organization}
              placement={placement}
            />
          ))
        ) : (
          <EmptyState
            icon={IconAd}
            iconColorSet='announcement'
            variant='contextual'>
            <EmptyStateTitle variant='cello'>
              No ads found for this episode
            </EmptyStateTitle>
          </EmptyState>
        )}
      </Card>
    </>
  );
}
