import React from 'react';
import PublisherContactForm from '@analytics/components/research/PublisherContactForm';

const PublisherContact = ({user, organization, publisher}) => {
  return (
    <PublisherContactForm
      user={user}
      organization={organization}
      publisher={publisher}
    />
  );
};

export default PublisherContact;
