import {gql} from '@apollo/client';

export default gql`
  query getBrandCampaignAgencies($organizationId: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id

        managers {
          id
          name
          slug
          kind
        }

        reversePermissions {
          id
          name
          objectName
          permission
          contentObjectName
          organization {
            id
            name
          }
        }

        campaigns {
          results {
            id
            name
            isHiddenFromAgency
            organization {
              id
              name
            }
            buySideAgencies {
              organizationId
              organizationName
            }
          }
        }
      }
    }
  }
`;
