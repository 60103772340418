import React, {FC, useState} from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {TooltipProps} from 'recharts/types/component/Tooltip';
import {comma, toFixed} from '@core/lib/filters';
import {Card} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import {CardStat} from '@core/ui/Stat';

const CustomTooltip = ({
  payload,
}: TooltipProps<number, string>): JSX.Element => {
  if (payload && payload.length) {
    const {total, frequency} = payload[0].payload;

    return (
      <div
        css={{
          background: 'var(--bg-overlay)',
          border: '1px solid var(--border-default)',
          borderRadius: '.5rem',
          boxShadow: 'var(--shadow-xl)',
          display: 'grid',
          fontSize: '0.875rem',
          gridGap: '1rem',
          gridTemplateColumns: '1fr 1fr',
          padding: '0.625rem',
        }}>
        <div>
          <div css={{color: 'var(--text-muted)', margin: '0 0 0.375rem'}}>
            Households
          </div>
          <div
            css={{
              color: 'var(--green)',
              fontSize: '1.75rem',
              fontWeight: 500,
              marginRight: '.5rem',
            }}>
            {comma(total)}
          </div>
        </div>
        <div>
          <div css={{color: 'var(--text-muted)', margin: '0 0 0.375rem'}}>
            Frequency
          </div>
          <div
            css={{fontSize: '1.75rem', fontWeight: 500, marginRight: '.5rem'}}>
            {comma(frequency)}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

type OverviewFrequencyProps = {
  data: any;
  impressions: number;
  reach: number;
};

const OverviewFrequency: FC<OverviewFrequencyProps> = ({
  data,
  impressions,
  reach,
}): JSX.Element => {
  const frequency = impressions / reach;
  const [focusBar, setFocusBar] = useState<number>(null);

  if (data) {
    const {nodes} = data;

    return (
      <>
        <Card
          rules={() => ({
            background: 'var(--bg-muted)',
            color: 'var(--text-muted)',
            fontSize: '0.875rem',
            marginTop: '1rem',
          })}>
          Ad Analytics uses a weighted average of the IP frequency and the
          Household Frequency to calculate frequency. The calculation is (1.5*
          {'{'}Household Frequency{'}'} + {'{'}IP Frequency{'}'}) / 2.5. We only
          use the Household Frequency as long as the panel size is at least 10%
          of total downloads; otherwise, a small number of impressions can skew
          the whole result. The above is calculated with this methodology.
        </Card>
        <div
          css={{
            display: 'grid',
            gridGap: '1rem',
            gridTemplateColumns: 'repeat(3, 1fr)',
            margin: '1rem 1.5rem',
          }}>
          <CardStat value={comma(impressions)} title='Impressions' />
          <CardStat value={comma(reach)} title='Reached' />
          <CardStat value={toFixed(frequency)} title='Frequency' />
        </div>
        <Card>
          <div
            css={{
              color: 'var(--text-muted)',
              fontSize: '0.8125rem',
              marginBottom: '0.125rem',
            }}>
            Households
          </div>
          <div
            onMouseLeave={() => setFocusBar(null)}
            css={{fontSize: '0.8125rem'}}>
            <ResponsiveContainer width='100%' height={300}>
              <BarChart
                data={nodes}
                onMouseMove={(state) => {
                  if (state.isTooltipActive) {
                    setFocusBar(state.activeTooltipIndex);
                  } else {
                    setFocusBar(null);
                  }
                }}>
                <CartesianGrid
                  strokeDasharray='0 0'
                  stroke='var(--border-default)'
                  vertical={false}
                />
                <XAxis
                  dataKey='frequency'
                  type='number'
                  padding={{left: 0, right: 45}}
                />
                <YAxis tickFormatter={(value) => comma(value)} />
                <Tooltip
                  content={CustomTooltip}
                  cursor={{fill: 'transparent'}}
                />
                <Bar dataKey='total'>
                  {nodes.map((_entry: any, idx: number) => {
                    return (
                      <Cell
                        key={idx}
                        fill={
                          focusBar === idx ? 'var(--green-80)' : 'var(--green)'
                        }
                      />
                    );
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div
            css={{
              color: 'var(--text-muted)',
              fontSize: '0.8125rem',
              marginTop: '-1rem',
              paddingLeft: '2rem',
              textAlign: 'center',
            }}>
            Frequency
          </div>
        </Card>
      </>
    );
  }
  return (
    <Card css={{marginTop: '1rem'}}>
      <EmptyMessage>No frequency data for this period of time.</EmptyMessage>
    </Card>
  );
};

export default OverviewFrequency;
