import {useEffect} from 'react';
import LogRocket from 'logrocket';
import {ErrorBoundary} from 'react-error-boundary';
import {Helmet} from 'react-helmet-async';
import {Redirect, Switch, useLocation} from 'react-router-dom';
import Logout from '@core/app/Logout';
import RedirectTo from '@core/app/RedirectTo';
import {PropsRoute} from '@core/app/Routes';
import {AppError, NotFoundError} from '@core/components/AppError';
import {gtag, installGtag} from '@core/lib/gtag';
import {createOrgPath} from '@core/lib/organizations';
import {ThemeProvider, getSystemTheme} from '@core/style';
import {Card} from '@core/ui/Content';
import {Label} from '@core/ui/FormElements';
import {MenuList} from '@core/ui/MenuList';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {ExportsProvider} from '@analytics/components/notifications/ExportsNotificationContext';
import {SettingsProvider} from '@analytics/components/settings/SettingsContext';
import useSettings from '@analytics/components/settings/useSettings';
import {userHasOrgKind, userIsSuccess} from '@analytics/lib/utils';
import AccountConfirm from './account/AccountConfirm';
import EmailConfirm from './account/EmailConfirm';
import {BrandIndex} from './brand';
import {BuySideIndex} from './buy-side';
import DebugScreen from './debug';
import OnboardIndex from './onboard';
import {PodcasterIndex} from './podcaster';
import SalesOrderScreen from './sales-order';
import {SellSideIndex} from './sell-side';
import Claim from './unified/Claim';

const IS_PROD =
  process.env.NODE_ENV === 'production' &&
  window.location.href.indexOf('adanalytics.spotify.com') > -1;

const HomeRedirect = () => <Redirect to='/' />;

const EnterRedirect = ({
  location,
  match: {
    params: {token},
  },
}) => {
  return <Logout to={`/enter/${token}${location.search}`} />;
};

const ResearchRedirect = ({user, match: {url}}) => {
  if (user.organizations.length === 0) {
    return <Redirect to='/onboard/welcome' />;
  }

  const orgs = user.organizations.filter(
    ({kind}) => kind.toLowerCase() !== 'research'
  );

  if (orgs.length === 0) {
    return <Redirect to='/onboard/welcome' />;
  }

  return <Redirect to={createOrgPath(orgs[0], url)} />;
};

const AnalyticsHome = ({user}) => {
  if (!user) {
    return null;
  }

  if (user.organizations.length === 0) {
    return <Redirect to='/onboard/welcome' />;
  }

  if (user.organizations.length === 1) {
    const org = user.organizations[0];

    if (org.kind === 'RESEARCH') {
      return <Redirect to={`/onboard/research/${org.id}`} />;
    }

    if (org.isLockedForTerms) {
      return <Redirect to={`/onboard/terms/${org.slug}`} />;
    }

    return <Redirect to={createOrgPath(org)} />;
  }

  return (
    <ResponsiveContent
      containerSize='medium'
      rules={() => ({marginTop: '3.75rem'})}>
      <Card>
        <p>
          Your account (<b>{user.email}</b>) is a member of multiple
          organizations in Ad Analytics.
        </p>
        <Label>Select organization to login</Label>
        <MenuList
          items={user.organizations.map((item) => ({
            title: item.name,
            to: createOrgPath(item),
          }))}
        />
      </Card>
    </ResponsiveContent>
  );
};

const AppContent = ({user}) => {
  const [settings] = useSettings();
  const theme = settings.theme === 'system' ? getSystemTheme() : settings.theme;

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <html lang='en' data-theme={theme} />
      </Helmet>
      <Switch>
        {/* <PropsRoute path="*" user={user} component={MaintenanceError} /> */}
        <PropsRoute
          user={user}
          path='/p/spotify/sales-orders'
          component={SalesOrderScreen}
        />
        <PropsRoute path='/debug' component={DebugScreen} />
        <PropsRoute path='/account-confirm' component={AccountConfirm} />
        <PropsRoute exact path='/' user={user} component={AnalyticsHome} />
        <PropsRoute path='/b/:slug' user={user} component={BrandIndex} />
        <PropsRoute path='/p/:slug' user={user} component={PodcasterIndex} />
        <PropsRoute path='/s/:slug' user={user} component={SellSideIndex} />
        <PropsRoute path='/a/:slug' user={user} component={BuySideIndex} />
        <PropsRoute exact path='/logout' user={user} component={Logout} />
        <PropsRoute path='/onboard' user={user} component={OnboardIndex} />
        <PropsRoute path='/enter/:token' component={EnterRedirect} />
        <PropsRoute path='/claim/:payload' component={Claim} />
        <PropsRoute path={['/signin', 'welcome']} component={HomeRedirect} />
        <PropsRoute path='/email-confirm' component={EmailConfirm} />
        <PropsRoute path='/to' component={RedirectTo} />
        <PropsRoute
          path='/research/*'
          user={user}
          component={ResearchRedirect}
        />

        <PropsRoute path='*' user={user} component={NotFoundError} />
      </Switch>
    </ThemeProvider>
  );
};

const App = ({me: user}) => {
  const location = useLocation();

  useEffect(() => {
    if (IS_PROD && user && user.id) {
      // LogRocket
      LogRocket.init('kzaigx/podsights');
      LogRocket.identify(user.id, {
        isBrand: userHasOrgKind(user.organizations, 'BRAND'),
        isAgency:
          userHasOrgKind(user.organizations, 'SELL_SIDE') ||
          userHasOrgKind(user.organizations, 'BUY_SIDE'),
        isPublisher: userHasOrgKind(user.organizations, 'PODCAST'),
        isAdmin: user.isAdmin,
        isContractor: /\w+@podsights\.contractors/.test(user.email),
        isSuccess: userIsSuccess(user.email),
        isAdvisor: !!user.organizations.find((org) => org.isAdvisor),
        isSegmentsEnabled: !!user.organizations.find(
          (org) => org.isSegmentsEnabled
        ),
      });
      // Hubspot
      const hubspotScript = document.createElement('script');
      hubspotScript.src = '//js.hs-scripts.com/20786575.js';
      hubspotScript.async = true;
      hubspotScript.defer = true;
      window.document.body.appendChild(hubspotScript);
      // GTag
      installGtag({
        trackingId: 'UA-123654770-3',
        userId: user.id,
      });
    }
  }, [user]);

  useEffect(() => {
    if (IS_PROD) {
      gtag('event', 'page_view', {
        page_title: location.pathname,
        page_location: location.pathname,
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <ErrorBoundary
      FallbackComponent={({...props}) => <AppError user={user} {...props} />}>
      <SettingsProvider>
        <ExportsProvider>
          <AppContent user={user} />
        </ExportsProvider>
      </SettingsProvider>
    </ErrorBoundary>
  );
};

export default App;
