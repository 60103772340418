import {gql} from '@apollo/client';

interface IHistoricalEvent {
  name: string;
  eventCounts: Array<{
    name: string;
    value: number;
  }>;
}

export interface IOrganizationCampaignDebug {
  me: {
    id: string;
    organization: {
      id: string;
      campaign: {
        id: string;
        debug: {
          events: Array<{
            hourly: {
              hour: string;
              action: string;
              count: number;
            };
          }>;
          pixel: {
            historical: Array<{
              js: Array<IHistoricalEvent>;
              mobile: Array<IHistoricalEvent>;
              img: Array<IHistoricalEvent>;
            }>;
          };
        };
      };
    };
  };
}

export interface IOrganizationCampaignDebugInput {
  organizationId: string;
  id: string;
}

export default gql`
  query OrganizationCampaignDebugQuery($organizationId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $id) {
          id

          debug {
            events {
              hourly {
                hour
                action
                count
              }
            }

            pixel {
              historical {
                hour
                js {
                  name
                  eventCounts {
                    name
                    value
                  }
                }
                mobile {
                  name
                  eventCounts {
                    name
                    value
                  }
                }
                img {
                  name
                  eventCounts {
                    name
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
