import {useRef, useState} from 'react';
import dayjs from '@core/lib/dayjs';
import {TRules, css} from '@core/style';
import Button, {IconButton} from '@core/ui/Button/LegacyButton';
import EmptyMessage from '@core/ui/EmptyMessage';
import {Icon} from '@core/ui/Icon';
import {FeedImage} from '@core/ui/Image';
import {useOverlay} from '@core/ui/Overlay';
import {FetchedOrganization} from '@analytics/graphql-api';
import UpcomingEpisodeOverlay from './UpcomingEpisodeOverlay';
import {
  IUpcomingEpisode,
  IUpcomingEpisodePodcast,
} from './useUpcomingEpisodesCalendarData';

const areSameDates = (a: dayjs.Dayjs, b: dayjs.Dayjs) =>
  a && b && a.format('YYYYMMDD') === b.format('YYYYMMDD');

const areSameMonth = (a: dayjs.Dayjs, b: dayjs.Dayjs) =>
  a && b && a.month() === b.month();

const formatDisplayMonth = (a: dayjs.Dayjs, b: dayjs.Dayjs) =>
  areSameMonth(a, b)
    ? a.format('MMMM YYYY')
    : `${a.format('MMMM')} - ${b.format('MMMM YYYY')}`;

const getDaysInWeek = (date: dayjs.Dayjs) => {
  const dayOfWeek = date.day();
  let days: dayjs.Dayjs[];

  // begin week on monday
  if (dayOfWeek > 1) {
    days = Array.from({length: dayOfWeek}, (_, i) =>
      date.subtract(i, 'd')
    ).reverse();
    days.push(
      ...Array.from({length: 7 - dayOfWeek}, (_, i) => date.add(i + 1, 'd'))
    );
  } else if (!dayOfWeek) {
    days = Array.from({length: 7}, (_, i) => date.subtract(i, 'd')).reverse();
  } else {
    days = Array.from({length: 7}, (_, i) => date.add(i, 'd'));
  }

  return days;
};

const getRenderedWeeks = ({date}: {date: dayjs.Dayjs}) => {
  return [date.subtract(1, 'w'), date, date.add(1, 'w')];
};

const ArrowButton = ({
  direction,
  disabled,
  onClick,
}: {
  direction: string;
  disabled: boolean;
  onClick: () => void;
}) => {
  return (
    <IconButton
      type='button'
      onClick={onClick}
      style-small
      rules={({theme}) => ({
        color: theme.iconSecondary,
        [direction]: 0,
        position: 'absolute',
        marginTop: '0.275rem',
        width: '2.125rem',
        zIndex: 1,
        ...(disabled
          ? {
              pointerEvents: 'none',
              opacity: 0.2,
            }
          : {}),
      })}>
      <Icon
        icon='arrow-right'
        rules={() => ({
          transform: direction === 'left' ? 'rotate(180deg)' : null,
        })}
      />
    </IconButton>
  );
};

const UpcomingEpisodeDayDetails = ({
  date,
  podcast,
  organization,
}: {
  date: dayjs.Dayjs;
  podcast: IUpcomingEpisodePodcast;
  organization: FetchedOrganization;
}) => {
  const [opened, toggle] = useOverlay();
  const ref = useRef<HTMLDivElement>();

  const onMouseEnter = () => {
    if (ref.current) {
      ref.current.focus();
      toggle(true);
    }
  };

  return (
    <div
      ref={ref}
      key={podcast.id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => toggle(false)}
      onBlur={() => toggle(false)}
      css={{
        alignItems: 'center',
        borderRadius: '0.25rem',
        display: 'flex',
        fontSize: '0.85rem',
        justifyContent: 'flex-start',
        padding: '0.5rem',
        ':hover': {
          background: 'var(--bg-muted)',
          cursor: 'pointer',
        },
        ['@media (max-width: 1800px)']: {
          justifyContent: 'center !important',
        },
      }}>
      <FeedImage
        feed={podcast}
        rules={() => ({
          height: '5rem',
          paddingBottom: 0,
          width: '5rem',
        })}
      />
      <div
        css={{
          marginLeft: '0.625rem',
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
          ['@media (max-width: 1800px)']: {
            display: 'none !important',
          },
        }}>
        {podcast.title}
      </div>
      {opened && (
        <UpcomingEpisodeOverlay
          date={date}
          positionTarget={ref.current}
          podcast={podcast}
          opened={opened}
          organization={organization}
          toggle={toggle}
        />
      )}
    </div>
  );
};

const UpcomingEpisodesDay = ({
  date,
  disabled,
  organization,
  upcomingEpisodes,
}: {
  date: dayjs.Dayjs;
  disabled: boolean;
  organization: FetchedOrganization;
  upcomingEpisodes: IUpcomingEpisode[];
}) => {
  const episodes = upcomingEpisodes.find((episode) =>
    areSameDates(dayjs(episode.date).startOf('d').add(1, 'd'), date)
  );
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0.25rem',
        width: '100%',
      }}>
      {episodes ? (
        episodes.podcasts.map((podcast) => (
          <UpcomingEpisodeDayDetails
            key={podcast.id}
            date={date}
            podcast={podcast}
            organization={organization}
          />
        ))
      ) : !disabled ? (
        <EmptyMessage>No episodes scheduled</EmptyMessage>
      ) : null}
    </div>
  );
};

const UpcomingEpisodesWeek = ({
  isOutsideRange,
  organization,
  today,
  upcomingEpisodes,
  week,
}: {
  isOutsideRange: (date: dayjs.Dayjs) => boolean;
  organization: FetchedOrganization;
  today: dayjs.Dayjs;
  upcomingEpisodes: IUpcomingEpisode[];
  week: dayjs.Dayjs;
}) => {
  const days = getDaysInWeek(week);
  return (
    <div>
      <div
        css={{
          alignItems: 'center',
          borderBottom: '1px solid var(--border-default)',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '0.5rem',
          paddingBottom: '1.5rem',
        }}>
        {formatDisplayMonth(days[0], days[6])}
      </div>
      <div
        css={{
          background: 'var(--border-default)',
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          gap: '1px',
          height: '28rem',
          overflowY: 'auto',
        }}>
        {days.map((date) => {
          const isToday = areSameDates(date, today);
          const disabled = isOutsideRange(date);
          return (
            <div
              key={date.toISOString()}
              css={{background: 'var(--bg-default)'}}>
              <div
                css={{
                  alignItems: 'center',
                  background: 'var(--bg-default)',
                  color: isToday
                    ? 'var(--blue)'
                    : disabled
                    ? 'var(--text-disabled)'
                    : 'inherit',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0.325rem 0',
                  position: 'sticky',
                  top: 0,
                }}>
                <div css={{fontSize: '0.75rem', fontWeight: 500}}>
                  {date.format('ddd')}
                </div>
                <div
                  key={date.utc().format()}
                  css={{
                    alignItems: 'center',
                    background: isToday ? 'var(--blue)' : null,
                    borderRadius: '0.25rem',
                    color: isToday ? 'var(--white)' : 'inherit',
                    display: 'flex',
                    fontSize: '1.125rem',
                    justifyContent: 'center',
                    padding: '0.15rem',
                    height: '1.975rem',
                    width: '1.975rem',
                  }}>
                  {date.date()}
                </div>
              </div>
              <UpcomingEpisodesDay
                date={date}
                disabled={disabled}
                organization={organization}
                upcomingEpisodes={upcomingEpisodes}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const UpcomingEpisodesCalendar = ({
  endRange,
  organization,
  upcomingEpisodes,
  rules,
  ...props
}: {
  endRange?: dayjs.Dayjs;
  organization: FetchedOrganization;
  upcomingEpisodes: IUpcomingEpisode[];
  rules?: TRules;
}) => {
  const today = dayjs().startOf('d');
  const [renderedWeeks, setRenderedWeeks] = useState(
    getRenderedWeeks({date: today})
  );
  const [transitionJob, setTransitionJob] = useState<{date: dayjs.Dayjs}>(null);

  const isOutsideRange = (date: dayjs.Dayjs) =>
    date.isBefore(today) ||
    date.isAfter(endRange || today.add(6, 'M').endOf('M'));

  const onTodayClick = () => {
    setRenderedWeeks(getRenderedWeeks({date: today}));
  };

  const onTransitionEnd = () => {
    if (transitionJob) {
      setRenderedWeeks(getRenderedWeeks({date: transitionJob.date}));
      setTransitionJob(null);
    }
  };

  const onArrowClick = (direction: string) => {
    setTransitionJob({
      date: renderedWeeks[direction == 'left' ? 0 : 2],
    });
  };

  const disableLeftArrow: boolean = renderedWeeks[0].isBefore(today);
  const disableRightArrow: boolean = renderedWeeks[2].isAfter(
    endRange || today.add(6, 'M')
  );

  return (
    <div
      {...css([
        () => ({
          overflow: 'hidden',
          position: 'relative',
        }),
        rules,
      ])}
      {...props}>
      <div css={{display: 'flex'}}>
        <ArrowButton
          direction='left'
          disabled={disableLeftArrow}
          onClick={!disableLeftArrow ? () => onArrowClick('left') : null}
        />
        <Button
          type='button'
          onClick={onTodayClick}
          style-small
          style-outline
          style-default
          rules={({theme}) => ({
            background: theme.bgPrimary,
            left: '50px',
            position: 'absolute',
            zIndex: 1,
          })}>
          Today
        </Button>
        <ArrowButton
          direction='right'
          disabled={disableRightArrow}
          onClick={!disableRightArrow ? () => onArrowClick('right') : null}
        />
      </div>
      <div
        onTransitionEnd={onTransitionEnd}
        css={{
          display: 'grid',
          transition: transitionJob
            ? 'transform 300ms cubic-bezier(0.22, 1, 0.36, 1)'
            : null,
          transform: `translateX(${
            transitionJob
              ? transitionJob.date === renderedWeeks[0]
                ? '0'
                : `-200%`
              : `-100%`
          })`,
          gridTemplateColumns: `repeat(3,  100%)`,
          height: 'fit-content',
          width: '100%',
        }}>
        {renderedWeeks.map((week) => (
          <UpcomingEpisodesWeek
            key={week.format('MM/DD/YYYY')}
            isOutsideRange={isOutsideRange}
            organization={organization}
            today={today}
            upcomingEpisodes={upcomingEpisodes}
            week={week}
          />
        ))}
      </div>
    </div>
  );
};

export default UpcomingEpisodesCalendar;
