import {gql} from '@apollo/client';

export default gql`
  query getBrandAd($organizationId: ID!, $feedId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        limitedCompany(id: $id) {
          id
          ads(feedId: $feedId, limit: 1) {
            id
            mpegPath
            waveform
            createdAt
            duration
          }
        }
      }
    }
  }
`;
