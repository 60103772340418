import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  GetAdvisorQuizTagsDocument,
  GetAdvisorQuizTagsQuery,
} from '@analytics/graphql-api';
import AdvisorSelectFilter from './AdvisorSelectFilter';

interface Props {
  defaultValues: {id: string; value: string}[];
  onChange: (_: {id: string; value: string}) => void;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}

export default function AdvisorTagFilter({
  defaultValues,
  onChange,
  organization,
}: Props) {
  return (
    <AdvisorSelectFilter
      label='Podcast Defined Tags'
      query={GetAdvisorQuizTagsDocument}
      defaultValues={defaultValues}
      onChange={onChange}
      organization={organization}
      selectDataFromResponse={(resp: GetAdvisorQuizTagsQuery) =>
        resp.me?.organization.advisorQuiz?.columnGroups?.tags?.columns ?? []
      }
    />
  );
}
