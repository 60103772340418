import {useState} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {Controller, useForm} from 'react-hook-form';
import {withRouter} from 'react-router-dom';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {FORM_ERRORS, Form, FormFooter, Submit} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import Select from '@core/ui/Select';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignDocument} from '@analytics/graphql-api';
import CreateCampaignTargetMutation from '@analytics/graphql-api/_old_mutations/CreateCampaignTarget';
import CampaignTargetOptionsQuery from '@analytics/graphql-api/_old_queries/CampaignTargetOptionsQuery';
import {toCampaignUrl} from '../_routes';

const CampaignTargetCreate = withRouter(
  ({user, organization, campaign, data, createCampaignTarget, history}) => {
    const [formLoading, setFormLoading] = useState(false);
    const {
      control,
      handleSubmit,
      setError,
      formState: {errors},
    } = useForm();

    const onSubmit = (data) => {
      setFormLoading(true);

      createCampaignTarget(data)
        .then(
          ({
            data: {
              createCampaignTarget: {
                campaignTarget: {id},
              },
            },
          }) => {
            setFormLoading(false);
            history.push(toCampaignUrl(organization, campaign));
          }
        )
        .catch((resp) => {
          setFormLoading(false);
          setError(null, FORM_ERRORS.network);
        });
    };

    if (data.loading) {
      return <Loading fixed />;
    }
    const targetOptions = data.me.organization.campaign.targetOptions.map(
      ({id, feed}) => {
        return {
          value: id,
          title: feed.title,
        };
      }
    );

    return (
      <>
        <CampaignNav
          organization={organization}
          campaign={campaign}
          user={user}
        />
        <ResponsiveContent containerSize='medium'>
          <CardHeader backPath={toCampaignUrl(organization, campaign)}>
            Select Podcast Target
          </CardHeader>
          <Card>
            <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
              <p>
                Select the podcast you will be targeting with this marketing
                campaign.
              </p>
              <Controller
                name='podcastId'
                control={control}
                rules={{
                  validate: (value) =>
                    !!value ? true : 'Please select a target',
                }}
                render={({field}) => (
                  <Select
                    items={targetOptions}
                    onSelect={(item) => field.onChange(item.value)}
                    rules={() => ({width: '100%'})}
                    {...field}
                  />
                )}
              />

              <FormFooter>
                <Button
                  type='button'
                  to={toCampaignUrl(organization, campaign)}>
                  Cancel
                </Button>
                <Submit loading={formLoading}>Add Podcast Target</Submit>
              </FormFooter>
            </Form>
          </Card>
        </ResponsiveContent>
      </>
    );
  }
);

export default compose(
  graphql(CampaignTargetOptionsQuery, {
    options: ({organization, campaign: {id}}) => ({
      variables: {
        organizationId: organization.id,
        id,
      },
    }),
  }),
  graphql(CreateCampaignTargetMutation, {
    props: ({ownProps: {organization, campaign}, mutate}) => ({
      createCampaignTarget: (input) => {
        input.campaignId = campaign.id;
        return mutate({
          variables: {
            input,
          },
          update: (
            store,
            {
              data: {
                createCampaignTarget: {campaignTarget},
              },
            }
          ) => {
            const query = {
              query: CampaignDocument,
              variables: {
                organizationId: organization.id,
                slug: campaign.slug,
              },
            };

            query.data = store.readQuery(query);
            const campaignTargets =
              query.data.me.organization.campaign.campaignTargets.slice(0);
            query.data.me.organization.campaign.campaignTargets =
              campaignTargets.concat(campaignTarget);
            store.writeQuery(query);
          },
        });
      },
    }),
  })
)(CampaignTargetCreate);
