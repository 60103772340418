import {FC, useState} from 'react';
import {useQuery} from '@apollo/client';
import {Redirect} from 'react-router-dom';
import withLock from '@core/components/withLock';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import Loading from '@core/ui/Loading';
import AudioReviewTool from '@analytics/components/audio/AudioReviewTool/AudioReviewTool';
import {FetchedOrganization} from '@analytics/graphql-api';
import EPISODE_REVIEW_QUERY from '@analytics/graphql-api/_old_queries/reviewers/EpisodeReviewQuery';

interface IEpisodeReviewProps {
  user: FetchedUser;
  organization: FetchedOrganization;
  feed: TSFixMe;
  episode: TSFixMe;
}

const EpisodeReview: FC<IEpisodeReviewProps> = ({
  user,
  organization,
  feed,
  episode,
}): JSX.Element => {
  const [completed, setCompleted] = useState<boolean>(false);
  const {data, loading, error} = useQuery(EPISODE_REVIEW_QUERY, {
    variables: {
      organizationId: organization.id,
      feedId: feed.id,
      id: episode.id,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <Loading centered />;
  }

  if (completed || error) {
    return (
      <Redirect
        to={createOrgPath(organization, 'research/reviewers/episodes')}
      />
    );
  }

  const startTime = Math.round(new Date().getTime() / 1000);
  const ep = data.me.organization.research.feed.episode;
  const advertisers = ep.campaignEpisodes.map(
    ({
      campaignPodcast: {
        campaign: {
          advertiser: {name},
        },
      },
    }) => name
  );

  return (
    <AudioReviewTool
      advertisers={advertisers}
      episode={ep}
      onComplete={() => setCompleted(true)}
      reviewer={user.reviewer}
      reviewerId={user.reviewer.id}
      rules={() => ({margin: '0 1.5rem'})}
      startTime={startTime}
      user={user}
    />
  );
};

export default withLock(EpisodeReview);
