import React, {useState} from 'react';
import {Element} from '@core/style';
import DesignForm from './DesignForm';
import DesignPreview from './DesignPreview';

const DEFAULT_COLORS = {
  backgroundColor: '#ffffff',
  fontColor: '#000000',
  primaryColor: '#DA71FF',
  secondaryColor: '#FFFFFF',
};

const DesignContent = ({
  organization,
  config,
  setConfig,
  previewURL,
  kind,
  onSave,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);

  const _onSave = (data) => {
    setLoading(true);
    onSave(data);
  };

  return (
    <Element
      rules={() => ({
        display: 'grid',
        gridTemplateColumns: '23.75rem 1fr',
        height: '100%',
        width: '100%',
      })}>
      <Element
        rules={({theme}) => ({
          borderRight: `2px solid ${theme.borderPrimary}`,
          minWidth: 0,
        })}>
        <DesignForm
          kind={kind}
          loading={loading}
          organization={organization}
          config={config}
          setConfig={setConfig}
          onSave={_onSave}
          onCancel={onCancel}
        />
      </Element>
      <DesignPreview previewURL={previewURL} config={config} />
    </Element>
  );
};

export {DEFAULT_COLORS};

export default DesignContent;
