import {FetchedUser} from '@core/graphql-api';
import {createOrgPath, isOrgPodcast} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {canCreateCampaign} from '@analytics/lib/campaigns';

export type CampaignsRouteProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
};

export const useCampaignsRoutes = ({
  organization,
}: Pick<CampaignsRouteProps, 'organization'>) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, '/campaigns'),
    basePath: '/:part(b|a|s|p)/:slug/campaigns',
    routes: [
      {
        key: 'redirect',
        path: '',
        exact: true,
      },
      {
        key: 'create',
        isAvailable: canCreateCampaign({organization}),
      },
      {
        key: 'integrity',
        path: 'integrity',
        exact: true,
        isAvailable: isOrgPodcast(organization),
      },
      {
        key: 'tracking-urls',
        path: 'tracking-urls',
        exact: true,
        isAvailable: isOrgPodcast(organization),
      },
      {key: 'order', path: 'orders/:orderId', exact: true},
      {key: 'campaign', path: ':campaignSlug'},
    ],
  });
};
