import {Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import ManageNav from '@analytics/components/nav/ManageNav';
import {ManageRouteProps} from '../_routes';
import {Usage} from './Usage';

export function ManageUsageIndex({user, organization}: ManageRouteProps) {
  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, '/manage/usage'),
    basePath: '/:part(b|a|s|p)/:slug/manage/usage',
    routes: [{key: 'usage', path: '', exact: true}],
  });

  return (
    <>
      <ManageNav user={user} organization={organization} />
      <Switch>
        {renderRoutes({
          components: {
            usage: Usage,
          },
          extraProps: {
            organization,
            user,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
}
