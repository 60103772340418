import {useState} from 'react';
import {Card} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import AudiencePurchaseIntent from '@analytics/components/audience-intel/AudiencePurchaseIntent';
import {AudienceCategory} from '@analytics/components/audience-intel/types';
import {PodcastAudienceRouteProps} from './_routes';

const AudienceIntent = ({
  podcast,
  organization,
  nielsenApproval,
}: PodcastAudienceRouteProps) => {
  const [category, toggleCategory] = useState<AudienceCategory>('index');
  return (
    <>
      {nielsenApproval ? (
        <AudiencePurchaseIntent
          organization={organization}
          podcast={podcast}
          category={category}
          toggleCategory={toggleCategory}
        />
      ) : (
        <Card>
          <EmptyMessage>Intent data unavailable.</EmptyMessage>
        </Card>
      )}
    </>
  );
};

export default AudienceIntent;
