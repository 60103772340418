import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import Loading from '@core/ui/Loading';
import {useCampaignStreamingQuery} from '@analytics/graphql-api';
import {CampaignRouteProps} from '../_routes';
import {CampaignStream} from './CampaignStream';
import {useCampaignStreamingRoutes} from './_routes';

export default function CampaignStreamingIndex({
  user,
  organization,
  campaign,
  match: {
    params: {campaignSlug, campaignStreamingId},
  },
}: CampaignRouteProps & {
  match: {
    params: {
      campaignSlug: string;
      campaignStreamingId: string;
    };
  };
}) {
  const {data, loading, error} = useCampaignStreamingQuery({
    variables: {
      organizationId: organization.id,
      campaignId: campaign.id,
      id: campaignStreamingId,
    },
  });

  const {renderRoutes} = useCampaignStreamingRoutes({
    organization,
    campaign,
    campaignStreamingId,
  });

  if (loading) {
    return <Loading fixed />;
  }

  if (error) {
    return (
      <Redirect to={createOrgPath(organization, `campaigns/${campaignSlug}`)} />
    );
  }

  const campaignStreaming = data?.me?.organization.campaign.campaignStreaming;

  return (
    <Switch>
      {renderRoutes({
        components: {
          stream: CampaignStream,
        },
        extraProps: {
          user,
          organization,
          campaign,
          campaignStreaming,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
