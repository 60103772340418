import {gql} from '@apollo/client';

export default gql`
  query getCompanyFeed($organizationId: ID!, $companyId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          feed(id: $id) {
            id
            title
            subtitle
            summary
            description(words: 50)
            image {
              id
              href
              path
            }

            episodes(companyId: $companyId) {
              id
              title
              subtitle
              summary
              description(words: 50)
              published
              reviewed
              hasNoAds
              placementsCount(companyId: $companyId)

              image {
                id
                href
                path
              }
              enclosure {
                id
                length
              }
            }
          }
        }
      }
    }
  }
`;
