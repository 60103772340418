import {gql} from '@apollo/client';

export default gql`
  query getBrandOrganizationPublisherOverlapQuery(
    $id: ID!
    $after: Date
    $before: Date
  ) {
    me {
      id
      organization(id: $id) {
        id
        overview {
          impressions(after: $after, before: $before) {
            overlap {
              publishers {
                baseDownloads
                baseReach
                basePublisherId
                basePublisher {
                  id
                  name
                }

                compDownloads
                compReach
                compPublisherId
                compPublisher {
                  id
                  name
                }
                overlap
                combined
              }
            }
          }
        }
      }
    }
  }
`;
