import {Element} from '@core/style';

interface IChartLegendProps {
  horizontal?: boolean;
  multipleSelect?: boolean;
  propertyForName?: string;
  selectedSeries: any;
  series: any[];
  setSelectedSeries: (_any) => void;
}

const ChartLegend = ({
  horizontal = false,
  multipleSelect = false,
  propertyForName = 'name',
  selectedSeries,
  series,
  setSelectedSeries,
}: IChartLegendProps) => {
  return (
    <Element
      rules={() => ({
        alignItems: horizontal ? 'center' : 'flex-start',
        display: 'flex',
        flexDirection: horizontal ? 'row' : 'column',
        justifyContent: horizontal && 'center',
      })}>
      {series.map((serie) => {
        const {color} = serie;
        const name = serie[propertyForName];
        const active = !!selectedSeries.find(
          (s) => s[propertyForName] === name
        );

        return (
          <Element
            tag='button'
            key={name}
            rules={[
              () => ({
                unset: 'all',
              }),
              () => ({
                alignItems: 'center',
                background: active ? `${color}22` : 'none',
                borderRadius: '2rem',
                color: active ? color : 'var(--text-muted)',
                cursor: 'pointer',
                display: 'inline-flex',
                fontSize: '0.875rem',
                fontWeight: 500,
                margin: horizontal ? '0 0.3125rem 0 0' : '0 0 0.25rem 0',
                outline: 0,
                padding: '0.25rem 0.75rem',
                textTransform: 'capitalize',
                ':hover': {
                  background: active ? `${color}22` : 'var(--bg-default-hover)',
                },
              }),
            ]}
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              if (multipleSelect) {
                if (active) {
                  setSelectedSeries(
                    selectedSeries.filter((s) => s[propertyForName] !== name)
                  );
                } else {
                  setSelectedSeries([...selectedSeries, serie]);
                }
              } else if (!active) {
                setSelectedSeries([serie]);
              }
            }}>
            <Element
              rules={({theme}) => ({
                background: active
                  ? color
                  : theme.name === 'light'
                  ? 'var(--gray-3)'
                  : 'var(--gray-5)',
                borderRadius: '50%',
                display: 'inline-flex',
                height: '0.6875rem',
                marginRight: '0.5rem',
                width: '0.6875rem',
              })}
            />
            <Element rules={() => ({marginTop: '-0.0625rem'})}>{name}</Element>
          </Element>
        );
      })}
    </Element>
  );
};

export default ChartLegend;
