import {useEffect} from 'react';
import dayjs from '@core/lib/dayjs';
import {isOrgBrand, isOrgPodcast} from '@core/lib/organizations';
import {
  CampaignsQueryVariables,
  ExportJobStatusStatus,
  FetchedBuySideOrganization,
  FetchedOrganization,
  SavedExportObject,
  useCampaignsDeliveriesQuery,
  useCampaignsQuery,
  useCompanyIndustryQuery,
  useFutureEpisodesQuery,
  useSavedExportsQuery,
} from '@analytics/graphql-api';
import {campaignNeedsReview} from '@analytics/lib/campaigns';
import {useExportsContext} from './ExportsNotificationContext';

const hasUnconfirmedUrls = (
  items: {
    id: string;
    deliveries: {
      id: string;
    }[];
  }[]
) => items.filter((item) => (item?.deliveries?.length ?? 0) < 1).length > 0;

const hasCompletedExportJob = (
  savedExport: SavedExportObject
): boolean | never => {
  const status = savedExport?.lastExportInstance?.exportJobStatus?.status;
  switch (status) {
    case ExportJobStatusStatus.Completed:
    case ExportJobStatusStatus.Failed:
      return true;
    case ExportJobStatusStatus.Received:
    case ExportJobStatusStatus.Started:
    case undefined:
      return false;
    default:
      return false as never;
  }
};

export const useNotifications = ({
  organization,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) => {
  /**
   *
   * Saved Exports notification
   *
   */
  const {pollExports} = useExportsContext();
  const {
    data: exportsData,
    loading: exportsLoading,
    startPolling: startExportsPolling,
    stopPolling: stopExportsPolling,
  } = useSavedExportsQuery({
    variables: {
      id: organization.id,
    },
    skip: !pollExports.length,
    onError: (error) => error && stopExportsPolling(),
  });

  const completedExports = (
    exportsData?.me?.organization.exports.savedExports ?? []
  ).filter(
    (e): e is SavedExportObject =>
      !!e &&
      pollExports.includes(e.id as ExportId) &&
      hasCompletedExportJob(e as SavedExportObject)
  );

  useEffect(() => {
    if (pollExports.length > 0) {
      return startExportsPolling(30 * 1_000); // 30 seconds
    }
    stopExportsPolling();
  }, [pollExports, startExportsPolling, stopExportsPolling]);

  /**
   *
   * Brand Industry notification
   *
   */
  const {data: companyData, loading: companyLoading} = useCompanyIndustryQuery({
    variables: {organizationId: organization.id},
    skip: !isOrgBrand(organization),
  });

  const company = companyData?.me?.organization?.companies?.[0];

  /**
   *
   * Unconfirmed Tracking URLs notification
   *
   */
  const {data: trackingData, loading: trackingLoading} =
    useCampaignsDeliveriesQuery({
      variables: {
        organizationId: organization.id,
      },
      skip: !isOrgPodcast(organization),
    });
  const unconfirmedUrls =
    trackingData?.me?.organization?.campaigns?.results?.filter(
      (c) =>
        hasUnconfirmedUrls(c?.campaignDynamics ?? []) ||
        hasUnconfirmedUrls(c?.campaignStreamings ?? [])
    )?.length ?? 0;

  /**
   *
   * Missed Episodes notification
   *
   */
  const {
    data: episodesData,
    loading: episodesLoading,
    startPolling: startEpisodesPolling,
    stopPolling: stopEpisodesPolling,
  } = useFutureEpisodesQuery({
    variables: {
      organizationId: organization.id,
      after: dayjs().subtract(1, 'M').utc().format('YYYY-MM-DD'),
      before: dayjs().utc().format('YYYY-MM-DD'),
    },
    skip: !isOrgPodcast(organization),
    onError: (error) => error && stopEpisodesPolling(),
  });

  const missedEpisodes =
    episodesData?.me?.organization?.futureEpisodes?.filter(
      (fe) => !!fe?.campaignEpisodes?.length
    )?.length ?? 0;

  useEffect(() => {
    startEpisodesPolling(60 * 10 * 1_000); // 10 min
    return () => stopEpisodesPolling();
  }, [startEpisodesPolling, stopEpisodesPolling]);

  /**
   *
   * Campaigns review notification
   *
   */
  const {data: campaignsData, loading: campaignsLoading} = useCampaignsQuery({
    variables: {
      organizationId: organization.id,
      query: {isMarketing: false},
    } as CampaignsQueryVariables,
    skip: !isOrgPodcast(organization),
  });

  const campaignsForReview =
    campaignsData?.me?.organization?.campaigns?.results?.filter((campaign) =>
      campaignNeedsReview({campaign})
    )?.length ?? 0;

  return {
    data: {
      campaignsForReview,
      company,
      completedExports,
      missedEpisodes,
      unconfirmedUrls,
    },
    loading:
      campaignsLoading ||
      companyLoading ||
      exportsLoading ||
      episodesLoading ||
      trackingLoading,
  };
};
