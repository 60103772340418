import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {FetchedIncrementalLiftReports} from '@analytics/graphql-api';
import {MeasureRouteProps} from '../..';

export type MeasureIncrementalLiftRouteProps = MeasureRouteProps & {
  liftReports: FetchedIncrementalLiftReports;
};

export const getIncrementalLiftRoutes = ({
  organization,
}: {
  organization: MeasureIncrementalLiftRouteProps['organization'];
}) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, `/measure/lift/incremental`),
    basePath: '/:part(b|a|s|p)/:slug/measure/lift/incremental',
    routes: [
      {key: 'reports', exact: true, path: ''},
      {key: 'request', exact: true},
      {key: 'report', path: 'reports/:liftReportId'},
      {key: 'households'},
    ],
  });
};
