import React, {useState} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import dayjs from 'dayjs';
import {MiniPlayer} from '@core/components/AudioMiniPlayer';
import {toTimeString} from '@core/lib/time';
import {Element} from '@core/style';
import {IconButton} from '@core/ui/Button/LegacyButton';
import DataTable from '@core/ui/DataTable';
import {Icon} from '@core/ui/Icon';
import {FeedImage} from '@core/ui/Image';
import Loading from '@core/ui/Loading';
import AdCopy from '@analytics/components/ads/AdCopy';
import CompanyRecentAds from '@analytics/graphql-api/_old_queries/research/CompanyRecentAds';

const RecentBrandAdsTable = ({company, orderBy, data: {loading, me}}) => {
  if (loading) {
    return <Loading centered />;
  }
  const {ads} = me.organization.research.company;

  const columns = [
    {
      title: 'Feed',
      accessor: 'text',
      type: 'string',
      Cell: ({data}) => {
        const [expanded, toggleExpanded] = useState(false);
        const feed = data.placements[0].episode.feed;
        const ArrowButton = ({expanded, ...props}) => {
          return (
            <IconButton
              style-small
              rules={() => ({
                marginTop: '0.75rem',
                width: '1.75rem',
                zIndex: 1,
              })}
              {...props}>
              <Icon
                icon='arrow-right'
                rules={() => ({
                  transform: expanded ? 'rotate(90deg)' : null,
                })}
              />
            </IconButton>
          );
        };

        return (
          <Element
            rules={() => ({
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            })}>
            <Element
              rules={() => ({
                flex: '0 0 4.6875rem',
                marginRight: '1rem',
                marginTop: '0.25rem',
              })}>
              <FeedImage feed={feed} width={100} />
            </Element>
            <Element
              rules={() => ({
                flexDirection: 'column',
                alignItems: 'center',
              })}>
              <Element
                rules={() => ({
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  minWidth: '15.5rem',
                })}>
                <Element
                  onClick={(e) => e.stopPropagation()}
                  rules={() => ({
                    paddingTop: '1.5rem',
                    alignItems: 'center',
                    height: '4rem',
                    width: '100%',
                  })}>
                  <MiniPlayer enclosure={data} preload='none' width='100%' />
                </Element>
                <ArrowButton
                  expanded={expanded}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleExpanded(!expanded);
                  }}
                />
              </Element>
              <Element
                rules={() => ({
                  margin: '1rem 2rem 0rem 2rem',
                  visibility: expanded ? null : 'hidden',
                  height: expanded ? '100%' : '0rem',
                  overflow: 'hidden',
                })}>
                <AdCopy text={data.text} />
              </Element>
            </Element>
          </Element>
        );
      },
    },
    {
      title: 'Published',
      accessor: 'firstPublished',
      type: 'date',
      fmt: (firstPublished) => dayjs(firstPublished).format('MMM DD, YYYY'),
    },
    {
      title: 'Duration',
      accessor: 'duration',
      type: 'number',
      fmt: toTimeString,
    },
  ];

  return (
    <DataTable
      data={ads}
      columns={columns}
      onClickRowPath={({placements}) => {
        const {id} = placements[0].episode.feed;
        return `/research/brands/${company.id}/podcasts/${id}`;
      }}
      orderBy={orderBy ? orderBy : '-firstPublished'}
    />
  );
};

export default graphql(CompanyRecentAds, {
  options: ({organization, company: {id}, limit = 10}) => ({
    variables: {
      organizationId: organization.id,
      id,
      limit,
    },
  }),
})(RecentBrandAdsTable);
