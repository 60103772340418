import {gql} from '@apollo/client';

export default gql`
  query getPodcastHourlyDownloads(
    $organizationId: ID!
    $id: ID!
    $after: String
    $before: String
    $kind: String
    $algorithm: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        podcast(id: $id) {
          id
          downloads(
            algorithm: $algorithm
            before: $before
            after: $after
            kind: $kind
          ) {
            count
            hourly {
              hour
              count
              downloadClass
            }
          }
        }
      }
    }
  }
`;
