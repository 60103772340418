import React, {useState} from 'react';
import {Control, Controller, useForm} from 'react-hook-form';
import Button from '@core/ui/Button/LegacyButton';
import {
  Form,
  FormFooter,
  FormGroup,
  FormHelp,
  LabelInput,
  LabelSelect,
  Submit,
} from '@core/ui/FormElements';
import {Icon} from '@core/ui/Icon';
import Select from '@core/ui/Select';
import {InfoTooltip} from '@core/ui/Tooltip';
import PixelEventInstallation from '@analytics/components/pixels/PixelEventInstallation';
import PixelEventSelect from '@analytics/components/pixels/PixelEventSelect';
import {
  fmtPixelEvent,
  getInstallationOptions,
  pixelEventTypes,
  pixelInstallationFields,
} from './helpers';

const OptionalFields = ({
  control,
  eventType,
  onFieldFocus,
}: {
  control: Control<any, any>;
  eventType: string;
  onFieldFocus: (_focusLine?: number) => void;
}): JSX.Element => {
  const fields = pixelInstallationFields[eventType] || [];
  return (
    <div
      css={{display: 'flex', flexWrap: 'wrap', gap: '1rem', marginTop: '1rem'}}>
      {fields.map(({name, label, line}) => (
        <Controller
          key={name}
          name={name}
          control={control}
          render={({field}) => (
            <div
              css={{
                position: 'relative',
                ...(name === 'value' || name === 'currency'
                  ? {
                      flexGrow: '1',
                    }
                  : {
                      width: '100%',
                    }),
              }}>
              <LabelInput
                label={label}
                onBlur={() => onFieldFocus(null)}
                onChange={(e) =>
                  field.onChange((e.target as HTMLInputElement).value)
                }
                onFocus={() => onFieldFocus(line)}
                value={field.value}
              />
              {field.name !== 'currency' && (
                <InfoTooltip
                  description="This field should be a variable - the value will be different each time it's called. If you're not sure about this field, check with your developer."
                  iconSize={14}
                  rules={() => ({
                    position: 'absolute',
                    top: '1.5rem',
                    right: '1rem',
                  })}
                />
              )}
            </div>
          )}
        />
      ))}
    </div>
  );
};

type TItem = {
  customEvent?: boolean;
  name: string;
  eventType: string;
  installation: string;
  type?: string;
  // category?: string;
  currency: string;
  discountCode: string;
  newCustomer: string;
  orderId: string;
  value: string;
  quantity: string;
  productId: string;
  productName: string;
  productType: string;
  productVendor: string;
  variantId: string;
  variantName: string;
} | null;
interface IPixelEventFormProps {
  loading: boolean;
  onCancel: () => void;
  onSubmit: (_item: TItem) => void;
  pixel: {
    key: string;
    alias: string;
    installations: string;
  };
  updateItem?: TItem;
}

const PixelEventForm = ({
  loading,
  onCancel,
  onSubmit,
  pixel,
  updateItem = null,
}: IPixelEventFormProps): JSX.Element => {
  const [focusLine, setFocusLine] = useState<number | null>(null);
  const installationOptions = getInstallationOptions(pixel);
  const mobileOptions = installationOptions.filter(
    ({value}) => value !== 'JAVASCRIPT'
  );

  const {
    control,
    handleSubmit,
    formState: {errors, isDirty},
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      customEvent: !updateItem
        ? true
        : !pixelEventTypes.find(({name}) => name === updateItem.name),
      name: updateItem?.name || '',
      eventType: updateItem?.eventType || null,
      installation: updateItem?.installation || installationOptions[0].value,
      type: updateItem?.type || '',
      currency: updateItem?.currency || '',
      discountCode: updateItem?.discountCode || '',
      newCustomer: updateItem?.newCustomer || '',
      orderId: updateItem?.orderId || '',
      value: updateItem?.value || '',
      quantity: updateItem?.quantity || '',
      productId: updateItem?.productId || '',
      productName: updateItem?.productName || '',
      productType: updateItem?.productType || '',
      productVendor: updateItem?.productVendor || '',
      variantId: updateItem?.variantId || '',
      variantName: updateItem?.variantName || '',
    },
  });

  const data = watch();

  const onSelectDefaultEvent = (type: string) => {
    setValue('customEvent', false);
    setValue('eventType', type);
    if (type === 'INSTALL') setValue('installation', mobileOptions[0].value);
  };

  const onSelectCustomEvent = () => {
    setValue('customEvent', true);
    setValue('installation', installationOptions[0].value);
    if (data.eventType === 'INSTALL') setValue('eventType', 'LEAD');
  };

  const _onSubmit = async (item: TItem) => {
    if (updateItem && !isDirty) {
      onCancel();
    } else {
      onSubmit(item);
    }
  };

  return (
    <Form errors={errors} onSubmit={handleSubmit(_onSubmit)}>
      <FormGroup>
        <Controller
          name='name'
          control={control}
          rules={{
            validate: (value) =>
              !!value ? true : 'Please provide an event name.',
          }}
          render={({field}) => (
            <PixelEventSelect
              defaultValue={field.value}
              invalid={!!errors.name}
              items={
                !!mobileOptions.length
                  ? pixelEventTypes
                  : pixelEventTypes.filter(({type}) => type !== 'install')
              }
              onSelect={({name, type}) => {
                if (type) onSelectDefaultEvent(type.toUpperCase());
                else onSelectCustomEvent();
                field.onChange(name);
              }}
              onClearInput={() => field.onChange('')}
            />
          )}
        />
        <FormHelp>
          Suggested events might include: newsletter signups, free trials, or
          requests for quote.
        </FormHelp>
      </FormGroup>
      <FormGroup>
        <Controller
          name='eventType'
          control={control}
          rules={{
            validate: (value) =>
              !!value ? true : 'Please select an event action.',
          }}
          render={({field}) =>
            data.customEvent ? (
              <LabelSelect
                key={data.eventType}
                defaultValue={field.value}
                label='Event Action'
                invalid={!!errors.eventType}
                items={[
                  {title: 'Lead', value: 'LEAD'},
                  {title: 'Purchase', value: 'PURCHASE'},
                  {title: 'Add to cart', value: 'ADDTOCART'},
                ]}
                onSelect={({value}) => field.onChange(value)}
              />
            ) : (
              <LabelInput
                readOnly
                label='Event Action'
                value={fmtPixelEvent(data.eventType)}
              />
            )
          }
        />
        {data.eventType && data.installation === 'JAVASCRIPT' && (
          <OptionalFields
            control={control}
            eventType={data.eventType}
            onFieldFocus={setFocusLine}
          />
        )}
      </FormGroup>
      {data.eventType && (
        <>
          <div
            css={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}>
            <h4>Installation</h4>
            {installationOptions.length > 1 && (
              <Controller
                name='installation'
                control={control}
                render={({field}) => (
                  <Select
                    key={data.name}
                    defaultValue={field.value}
                    OptionContent={({item, active, ...props}) => (
                      <>
                        <div
                          css={{
                            alignItems: 'center',
                            borderRadius: '0.375rem',
                            color: active ? 'var(--blue)' : 'inherit',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '0.375rem 1rem',
                            textDecoration: 'none',
                            userSelect: 'none',
                            ':hover': {
                              background: 'var(--bg-muted)',
                              color: 'var(--blue)',
                            },
                          }}
                          {...props}>
                          {item.title}
                          <Icon
                            icon='checkmark'
                            rules={() => ({
                              flexShrink: 0,
                              height: '1.25rem',
                              marginLeft: '0.375rem',
                              opacity: active ? 1 : 0,
                              width: '1.25rem',
                            })}
                          />
                        </div>
                        {item.value === 'JAVASCRIPT' && (
                          <div
                            css={{
                              background: 'var(--border-default)',
                              height: '0.0675rem',
                              margin: '0.375rem 0',
                            }}
                          />
                        )}
                      </>
                    )}
                    items={
                      data.eventType === 'INSTALL'
                        ? mobileOptions
                        : installationOptions
                    }
                    onSelect={({value}) => field.onChange(value)}
                  />
                )}
              />
            )}
          </div>
          <PixelEventInstallation
            event={data}
            focusLine={focusLine}
            pixel={pixel}
          />
        </>
      )}
      <FormFooter>
        <Button type='button' disabled={loading} onClick={onCancel}>
          Cancel
        </Button>
        <Submit disabled={loading} loading={loading}>
          Save Event
        </Submit>
      </FormFooter>
    </Form>
  );
};

export default PixelEventForm;
