import {useQuery} from '@apollo/client';
import {Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import Loading from '@core/ui/Loading';
import {EpisodeNav} from '@analytics/components/nav/ResearchNav';
import EpisodeDetail from '@analytics/components/research/EpisodeDetail';
import {FetchedOrganization} from '@analytics/graphql-api';
import EPISODE_QUERY from '@analytics/graphql-api/_old_queries/research/EpisodeQuery';
import EpisodeExport from './EpisodeExport';
import EpisodeProcess from './EpisodeProcess';
import EpisodeReview from './EpisodeReview';

interface IEpisodesIndexProps {
  user: FetchedUser;
  organization: FetchedOrganization;
  feed: TSFixMe;
  match: {
    params: {
      episodeId: string;
      feedId: string;
    };
  };
}

const EpisodeIndex = ({
  user,
  organization,
  feed,
  match: {
    params: {feedId, episodeId},
  },
}: IEpisodesIndexProps): JSX.Element => {
  const {data, loading, error} = useQuery(EPISODE_QUERY, {
    variables: {
      organizationId: organization.id,
      feedId: feedId,
      id: episodeId,
    },
  });

  if (loading) {
    return <Loading fixed />;
  }

  const {episode} = data.me.organization.research.feed;

  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(
      organization,
      `research/podcasts/${feedId}/episodes/${episodeId}`
    ),
    basePath:
      '/:part(a|s|b|p)/:slug/research/podcasts/:feedId/episodes/:episodeId',
    routes: [
      {key: 'detail', path: '', exact: true},
      {key: 'export', exact: true},
      {key: 'process', exact: true},
      {key: 'review', exact: true, isAvailable: !!user.reviewer},
    ],
  });

  return (
    <>
      <EpisodeNav organization={organization} feed={feed} episode={episode} />
      <Switch>
        {renderRoutes({
          components: {
            detail: EpisodeDetail,
            export: EpisodeExport,
            process: EpisodeProcess,
            review: EpisodeReview,
          },
          extraProps: {
            user,
            organization,
            feed,
            episode,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
};

export default EpisodeIndex;
