import {
  Box,
  ButtonTertiary,
  DialogConfirmation,
  Image,
  Type,
} from '@spotify-internal/encore-web';
import ErrorImage from './error.svg';

export const ErroredDialogue = ({onClose}) => (
  <DialogConfirmation
    aria-label='Error Dialogue'
    body={
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 16,
        }}>
        <Image
          src={ErrorImage}
          alt='error'
          style={{
            height: 118,
            width: 126,
          }}
        />
        <Type variant='cello'>Approval failed</Type>
        <Type variant='mesto'>
          We were unable to process your request. This has been reported and
          will be investigated. Please try again later.
        </Type>
        <ButtonTertiary onClick={onClose}>Close</ButtonTertiary>
      </Box>
    }
  />
);
