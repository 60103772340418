import {useEffect} from 'react';
import Prism from 'prismjs';
import 'prismjs/plugins/line-highlight/prism-line-highlight.css';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/themes/prism.css';
import styled from 'styled-components';

export interface CodeBlockProps {
  codeString: string;
  /**
   * Code language
   */
  language?: string;
  /**
   * Set to true to show line numbers
   */
  lineNumbers?: boolean;
  /**
   * String containing line numbers to highlight
   *     For multiple lines, seperate with comma or use a dash to highlight a range
   *     i.e. 1-5 for lines 1 through 5
   *          1,4, 6-8 for lines 1, 4, and 8 through 8
   */
  lineHighlight?: number | string;
}

export const CodeBlock = ({
  codeString,
  language = 'javascript',
  lineHighlight,
  lineNumbers = false,
  ...props
}: CodeBlockProps) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [codeString, lineHighlight, lineNumbers]);

  return (
    <_CodeBlock $lineHighlight={lineHighlight} {...props}>
      <pre
        className={`${lineNumbers ? '' : 'no-'}line-numbers`}
        data-line={lineHighlight || null}>
        <code className={`language-${language}`}>{codeString}</code>
      </pre>
    </_CodeBlock>
  );
};

const _CodeBlock = styled.div<{$lineHighlight: any}>`
  font-size: var(--font-size-body-3);
  > pre {
    background: var(--bg-muted) !important;
    border-radius: var(--radius-2);
    line-height: var(--line-height-md);
    margin: 0 !important;
    > div {
      background: ${(p) =>
        p.$lineHighlight ? ' hsla(10, 10%, 5%,.08)' : 'none'} !important;
      margin-top: 0.785rem !important;
    }
    > code {
      color: var(--text-default);
      font-family: monospace !important;
      text-shadow: none !important;
      > span {
        background: none !important;
      }
      > .keyword {
        color: #ab47bc !important;
      }
      > .string {
        color: #18bd7b !important;
      }
      > .function {
        color: #697eff !important;
      }
      > .property {
        color: #ed6152 !important;
      }
      > .number {
        color: #ff91d9 !important;
      }
      > .operator {
        color: #6a747f !important;
      }
    }
  }
`;
