import {IconSpotifyLogo, spacer4} from '@spotify-internal/encore-web';
import {Stack} from '@core/ui/Layout';

export function InsightsNavTitle() {
  return (
    <Stack gap={spacer4}>
      <IconSpotifyLogo size='small' />
      <span>Insights</span>
    </Stack>
  );
}
