import {useState} from 'react';
import dayjs from 'dayjs';
import {Alert} from '@core/ui/Alert';
import Button from '@core/ui/Button';
import DatePicker, {DatePickerProps} from '@core/ui/DatePicker/DatePicker';
import {Icon} from '@core/ui/Icon';
import {Stack} from '@core/ui/Layout';
import {Overlay, OverlayProps} from '@core/ui/Overlay';

type Dates = [dayjs.Dayjs, dayjs.Dayjs];

type Props = OverlayProps & {
  dateFormat?: string;
  datePickerProps?: DatePickerProps;
  onCancel?: () => void;
  onSave?: (_dates: Dates) => void;
  toggle?: (_to?: boolean) => void;
};

export function CustomRangeOverlay({
  datePickerProps = {},
  dateFormat = 'MM/DD/YYYY',
  onCancel,
  onSave,
  toggle,
  ...props
}: Props) {
  const [error, setError] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Dates>(
    (datePickerProps.defaultDates as Dates) ?? []
  );

  const onSaveDates = () => {
    if (selectedDates.length < 2) {
      setError(true);
    } else {
      onSave?.(selectedDates);
      toggle?.(false);
    }
  };

  const onCancelDates = () => {
    onCancel?.();
    toggle?.(false);
  };

  return (
    <Overlay
      toggle={toggle}
      css={{maxWidth: 'initial', padding: 'var(--spacing-6)'}}
      {...props}>
      {error && (
        <Alert severity='error' css={{margin: '0 0 var(--spacing-6) 0'}}>
          Please select a valid date range
        </Alert>
      )}
      <DatePicker
        onDatesChange={(dates) => setSelectedDates(dates as Dates)}
        {...datePickerProps}
      />
      <div
        css={{
          alignItems: 'center',
          display: 'grid',
          gap: '0.5rem',
          gridTemplateColumns: 'max-content 1fr',
          margin: '1rem 0',
        }}>
        <Icon icon='date' rules={() => ({color: 'var(--icon-subtle)'})} />
        <Stack
          direction='column'
          css={{
            border: '0.0625rem solid var(--blue)',
            boxShadow: '0 0 0 0.0625rem var(--blue)',
            borderRadius: 'var(--radius-1)',
            color: selectedDates.length > 0 ? 'inherit' : 'var(--text-muted)',
            padding: '0.5rem 0.625rem',
          }}>
          {selectedDates.length > 0 ? (
            <>
              {selectedDates[0] ? selectedDates[0].format(dateFormat) : null} -{' '}
              {selectedDates[1] ? selectedDates[1].format(dateFormat) : null}
            </>
          ) : (
            'Select dates...'
          )}
        </Stack>
      </div>
      <div
        css={{
          alignItems: 'center',
          display: 'grid',
          gap: 'var(--spacing-4)',
          gridTemplateColumns: 'max-content max-content',
          justifyContent: 'right',
          margin: '1rem 0 0',
        }}>
        <Button onClick={onCancelDates}>Cancel</Button>
        <Button color='primary' onClick={onSaveDates}>
          Save
        </Button>
      </div>
    </Overlay>
  );
}
