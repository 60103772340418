import {ReactNode} from 'react';
import {Card} from '@core/ui/Content';
import Stat from '@core/ui/Stat';

export function _CampaignPerformaceCard({stats, campaign}) {
  let content: ReactNode = null;

  if (campaign.kind === 'reporting') {
    content = (
      <>
        <Stat {...stats.frequency} />
        {stats.verifiedReach.value > 0 && <Stat show={!campaign.isMegaphone} {...stats.verifiedFrequency} />}
        <Stat {...stats.cpm} />
      </>
    );
  } else if (campaign.kind === 'marketing') {
    content = (
      <>
        <Stat {...stats.frequency} />
        <Stat {...stats.spend} />
        <Stat {...stats.targetDownloadTotal} />
        <Stat {...stats.targetReachTotal} />
        <Stat {...stats.targetCr} />
      </>
    );
  } else {
    const canViewPurchases =
      stats.purchases.value && campaign.pixels.length > 0;

    content = (
      <>
        <Stat {...stats.visitors} />
        <Stat {...stats.frequency} />
        {stats.verifiedReach.value > 0 && <Stat show={!campaign.isMegaphone} {...stats.verifiedFrequency} />}
        <Stat {...stats.cr} />

        {canViewPurchases ? (
          <>
            <Stat {...stats.spend} />
            <Stat {...stats.cpm} />
            <div
              css={`
                border-top: 1px solid var(--border-default);
                display: grid;
                gap: var(--spacing-8);
                grid-column: 1 / -1;
                grid-template-columns: repeat(4, 1fr);
                margin-top: var(--spacing-6);
                padding-top: var(--spacing-6);
              `}>
              <Stat {...stats.revenue} />
              {stats.installs.value ? (
                <Stat {...stats.installs} />
              ) : (
                <Stat {...stats.aov} />
              )}
              <Stat {...stats.purchases} />
              <Stat {...stats.cac} />
            </div>
          </>
        ) : (
          <>
            <Stat {...stats.spend} />
            <Stat {...stats.cpm} />
          </>
        )}
      </>
    );
  }

  return (
    <Card
      css={`
        display: grid;
        grid-gap: var(--spacing-8);
        grid-template-columns: repeat(3, 1fr);
        height: 100%;
        justify-content: center;
        margin: 0;
        padding: var(--spacing-7);
      `}>
      {content}
    </Card>
  );
}
