/**
 * Encore Foundation - 5.32.2
 *
 * The contents of this file is owned and generated by the Encore team.
 * If you need to make changes to the values stored here please reach out
 * to #encore on Slack.
 *
 * Links:
 *
 * - Source & Documentation: https://ghe.spotify.net/encore/foundation
 *
 * Tack!
 *
 * Encore
 * encore.spotify.net
 */
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer4 = '4px';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer8 = '8px';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer12 = '12px';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer16 = '16px';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer20 = '20px';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer24 = '24px';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer32 = '32px';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer40 = '40px';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer48 = '48px';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer56 = '56px';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer64 = '64px';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer72 = '72px';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This token is deprecated, you can instead use a semantic spacer token that fits your use case. Learn more at https://encore.spotify.net/foundations/layout-themes#spacers
 */
export var spacer80 = '80px';
