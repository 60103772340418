import { screenXsMax } from '@spotify-internal/encore-foundation';
import { cssColorValue, semanticColors } from "../../styles/index.js";
import { TableHeaderCellElement as TableHeaderCell } from "../TableHeaderCell/TableHeaderCell.js";
import styled, { css } from "../../styled-components.js";
var responsiveWrapper = function responsiveWrapper() {
  return css(["@media screen and (max-width:", "){display:block;inline-size:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar;}"], screenXsMax);
};
var stickyHeaderWrapper = function stickyHeaderWrapper() {
  return css(["overflow:auto;max-block-size:100%;", "{background-color:", ";position:sticky;z-index:1;top:0;", ";}"], TableHeaderCell, cssColorValue(semanticColors.backgroundBase), function (props) {
    return props.isSticky && css(["box-shadow:-3px 0 0 ", ",3px 0 0 ", ",0 1px 6px ", ";"], cssColorValue(semanticColors.backgroundBase), cssColorValue(semanticColors.backgroundBase), cssColorValue(semanticColors.backgroundTintedBase));
  });
};
export var Container = styled.div.withConfig({
  displayName: "TableContainer__Container",
  componentId: "sc-86p3fa-0"
})(["", ";", ";"], function (props) {
  return props.responsive && responsiveWrapper;
}, function (props) {
  return props.stickyHeader && stickyHeaderWrapper;
});