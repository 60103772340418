import {Stack} from '@core/ui/Layout';
import {LoadingSpinner} from '@core/ui/Loading';

export function LoadingOptions() {
  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      css={{height: '100%', padding: '1rem 0'}}>
      <LoadingSpinner />
    </Stack>
  );
}
