import {useLayoutEffect} from 'react';
import {FieldErrors, useForm} from 'react-hook-form';
import {FetchedCampaign} from '@analytics/graphql-api';
import {CampaignKind} from '../_types';
import {getCampaignDefaultValues} from './_helpers';
import {CampaignFormData} from './_types';

type UseCampaignFormInput =
  | {
      campaign: FetchedCampaign;
      kind?: undefined;
      payerId?: undefined;
    }
  | {
      campaign?: undefined;
      kind: CampaignKind;
      payerId: string;
    };

export function useCampaignForm({
  campaign,
  kind,
  payerId,
}: UseCampaignFormInput) {
  return useForm({
    defaultValues: getCampaignDefaultValues({
      campaign,
      kind,
      payerId,
    }),
  });
}

export function useScrollToTopOnErrors(errors: FieldErrors<CampaignFormData>) {
  return useLayoutEffect(() => {
    if (
      typeof errors === 'object' &&
      Object.keys(errors).length > 0 &&
      window.document.firstElementChild
    ) {
      window.document.firstElementChild.scrollTop = 0;
    }
  }, [errors]);
}
