import {ProgressCircle} from '@spotify-internal/encore-web';
import {Redirect} from 'react-router-dom';
import {
  InsightsBanner,
  InsightsBarChart,
  InsightsCategoryTable,
  InsightsDisplayCard,
  InsightsGrid,
  InsightsLayout,
  InsightsTopAudiences,
} from '@analytics/components/audience-insights';
import {
  EMPTY_AUDIENCES,
  SAI_CHARTS,
  SAI_SUBTITLES,
  SAI_TABLES,
  SAI_TOP_AUDIENCES,
} from '@analytics/components/audience-insights/constants';
import {formatSAIData} from '@analytics/components/audience-insights/utils';
import {CampaignInsightsNav} from '@analytics/components/nav/CampaignInsightsNav';
import {useCampaignSpotifyAudienceQuery} from '@analytics/graphql-api';
import {CampaignRouteProps, toCampaignUrl} from '../_routes';

export function InsightsSAI({
  organization,
  campaign,
}: Omit<CampaignRouteProps, 'user'>) {
  const {data, loading, error} = useCampaignSpotifyAudienceQuery({
    variables: {
      id: organization.id,
      campaignId: campaign.id,
    },
  });

  if (loading) {
    return <ProgressCircle aria-label='saiInsightsLoading' size='medium' />;
  }

  if (error) {
    return <Redirect to={toCampaignUrl(organization, campaign)} />;
  }

  const campaignAudiences =
    formatSAIData(data?.me?.organization?.campaign) ?? EMPTY_AUDIENCES;

  return (
    <>
      <CampaignInsightsNav organization={organization} campaign={campaign} />
      <InsightsLayout>
        <InsightsBanner isStreaming>
          Audience insights reports on-Spotify platform streams only
        </InsightsBanner>
        <InsightsDisplayCard
          title='Audience overview'
          subtitle={SAI_SUBTITLES.OVERVIEW}
          content={
            <InsightsTopAudiences
              audiences={campaignAudiences}
              audienceOrder={SAI_TOP_AUDIENCES}
            />
          }
        />
        {SAI_CHARTS.map(({group, title}) => (
          <InsightsDisplayCard
            key={group}
            title={title}
            subtitle={SAI_SUBTITLES[group]}
            content={<InsightsBarChart audience={campaignAudiences[group]} />}
          />
        ))}
        <InsightsGrid columns={2}>
          {SAI_TABLES.map(({group, title, categoryTitle}) => (
            <InsightsDisplayCard
              key={group}
              title={title}
              subtitle={SAI_SUBTITLES[group]}
              content={
                <InsightsCategoryTable
                  audience={campaignAudiences[group]}
                  categoryTitle={categoryTitle}
                />
              }
            />
          ))}
        </InsightsGrid>
      </InsightsLayout>
    </>
  );
}
