import {useState} from 'react';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {FetchedUser} from '@core/graphql-api';
import {Container, Stack} from '@core/ui/Layout';
import Toggle from '@core/ui/Toggle';
import {FetchedOrganization} from '@analytics/graphql-api';
import Podcasts from './Podcasts';
import PodcastsAudience from './PodcastsAudience';
import {PodcastIndex} from './podcast';

export type PodcastsRouteProps = {
  organization: FetchedOrganization;
  user: FetchedUser;
};

export function PodcastsIndex({user, organization}: PodcastsRouteProps) {
  const {isNielsenApproved, isExperianApproved, isSegmentsEnabled} =
    organization;

  // confirm if segments is enabled/toggled on
  const [showSegments, setShowSegments] = useState(isSegmentsEnabled);

  return (
    <>
      {user.isAdmin &&
        !isSegmentsEnabled &&
        (isNielsenApproved || isExperianApproved) && (
          <Container maxWidth={false}>
            <Stack
              alignItems='center'
              justifyContent='flex-end'
              spacing='var(--spacing-2)'>
              <b>Admin Feature:</b> Toggle Show Segments
              <Toggle
                defaultChecked={showSegments}
                onChecked={setShowSegments}
              />
            </Stack>
          </Container>
        )}
      <Switch>
        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/podcasts'
          user={user}
          organization={organization}
          component={showSegments ? PodcastsAudience : Podcasts}
        />
        <PropsRoute
          path='/:part(a|s|b|p)/:slug/podcasts/:podcastId'
          user={user}
          organization={organization}
          component={PodcastIndex}
        />
      </Switch>
    </>
  );
}
