import {useState} from 'react';
import dayjs from 'dayjs';
import {capitalize} from '@core/lib/filters';
import {copyToClipboard} from '@core/lib/utils';
import {Button} from '@core/ui/Button';
import {DataTableColumn} from '@core/ui/DataTable';
import {Icon} from '@core/ui/Icon';
import {LoadingSpinner} from '@core/ui/Loading';
import {useOverlay} from '@core/ui/Overlay';
import Select from '@core/ui/Select';
import {Snackbar} from '@core/ui/Snackbar';
import {Tooltip} from '@core/ui/Tooltip';
import {
  CampaignDynamicProviderName,
  CampaignStreamingProviderName,
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedCampaignsConfirmTrackingUrls,
  FetchedOrganization,
  UpdateCampaignDynamicInput,
  UpdateCampaignStreamingInput,
  useUpdateCampaignDynamicMutation,
  useUpdateCampaignStreamingMutation,
} from '@analytics/graphql-api';
import {CampaignTrackingData, showTrackingConfirm} from './helpers';

type ProviderCellProps = {
  d: CampaignTrackingData;
  organizationId: string;
};

const ProviderCell = ({d, organizationId}: ProviderCellProps) => {
  const [loading, setLoading] = useState(false);
  const [openedSnackbar, toggleSnackbar] = useOverlay(false);
  const [updateCampaignDynamic] = useUpdateCampaignDynamicMutation();
  const [updateCampaignStreaming] = useUpdateCampaignStreamingMutation();

  const onSelect = async ({
    providerName,
  }: {
    providerName: CampaignDynamicProviderName | CampaignStreamingProviderName;
  }) => {
    setLoading(true);
    toggleSnackbar(false);
    try {
      if (d.format === 'dynamic') {
        await updateCampaignDynamic({
          variables: {
            input: {
              id: d.id,
              organizationId,
              providerName,
            } as UpdateCampaignDynamicInput,
          },
          update: (cache, {data}) => {
            const campaignDynamic =
              data?.updateCampaignDynamic?.campaignDynamic;
            if (campaignDynamic) {
              cache.modify({
                id: cache.identify(campaignDynamic),
                fields: {
                  providerName() {
                    return campaignDynamic.providerName;
                  },
                  providerTrackingUrl() {
                    return campaignDynamic.providerTrackingUrl;
                  },
                },
              });
            }
          },
        });
      } else {
        await updateCampaignStreaming({
          variables: {
            input: {
              id: d.id,
              organizationId,
              providerName,
            } as UpdateCampaignStreamingInput,
          },
          update: (cache, {data}) => {
            const campaignStreaming =
              data?.updateCampaignStreaming?.campaignStreaming;
            if (campaignStreaming) {
              cache.modify({
                id: cache.identify(campaignStreaming),
                fields: {
                  providerName() {
                    return campaignStreaming.providerName;
                  },
                  providerTrackingUrl() {
                    return campaignStreaming.providerTrackingUrl;
                  },
                },
              });
            }
          },
        });
      }
    } catch (e) {
      toggleSnackbar(true);
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingSpinner size='small' />;
  }

  return (
    <>
      <Select
        key={`${d.providerName}-${String(openedSnackbar)}`}
        defaultValue={d.providerName?.toLowerCase()}
        items={d.providerNames ?? []}
        onSelect={onSelect}
        propertyForName='providerNameDisplay'
        propertyForValue='providerName'
        small
      />
      <Snackbar
        severity='error'
        opened={openedSnackbar}
        toggle={toggleSnackbar}>
        Error updating item provider. Please try again later.
      </Snackbar>
    </>
  );
};

type GetTrackingUrlsTableColumnsFn = (_props: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign: FetchedCampaign | FetchedCampaignsConfirmTrackingUrls[number];
}) => DataTableColumn<CampaignTrackingData>[];

const getTrackingUrlsTableColumns: GetTrackingUrlsTableColumnsFn = ({
  organization,
  campaign,
}) => {
  return [
    {
      title: 'Name',
      accessor: 'name',
      type: 'string',
      rule: () => ({minWidth: '12.5rem'}),
    },
    {
      title: 'Format',
      accessor: 'format',
      type: 'string',
      fmt: (v) => capitalize(v),
    },
    {
      title: 'Provider',
      accessor: 'providerName',
      type: 'string',
      Cell: (props) => (
        <ProviderCell {...props} organizationId={organization.id} />
      ),
    },
    {
      title: 'Tracking URL',
      accessor: 'providerTrackingUrl',
      type: 'string',
      rule: () => ({width: '100%'}),
      Cell: ({d}) => {
        const [copied, setCopied] = useState(false);
        const defaultValue = d.providerTrackingUrl ?? '';

        const onClick = () => {
          copyToClipboard(defaultValue);
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        };

        return (
          <div css={{alignItems: 'center', display: 'flex', gap: '1rem'}}>
            <input
              defaultValue={defaultValue}
              readOnly
              onClick={({target}) => (target as HTMLInputElement).select()}
              css={{color: 'var(--text-default)', width: '100%'}}
            />
            {!!defaultValue && (
              <Button buttonSize='sm' variant='outlined' onClick={onClick}>
                {copied ? 'Copied!' : 'Copy'}
              </Button>
            )}
          </div>
        );
      },
    },
    ...(showTrackingConfirm(organization, campaign)
      ? [
          {
            title: 'Confirmed',
            accessor: 'deliveries',
            type: 'string',
            noSortable: true,
            Cell: ({d}) => {
              const confirmed = d.deliveries?.[0];
              return (
                <Tooltip
                  description={
                    confirmed &&
                    `Confirmed on ${dayjs(confirmed.viewedAt).format(
                      'MM/DD/YY'
                    )}`
                  }
                  rules={() => ({
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  })}>
                  <Icon
                    icon={confirmed ? 'checkbox-marked-circle' : 'alert'}
                    rules={() => ({
                      color: confirmed ? 'var(--green)' : 'var(--red-orange)',
                    })}
                  />
                </Tooltip>
              );
            },
          } as DataTableColumn,
        ]
      : []),
  ];
};

export default getTrackingUrlsTableColumns;
