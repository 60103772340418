import {useMemo} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {Stack} from '@core/ui/Layout';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  useExportTypesQuery,
} from '@analytics/graphql-api';
import {
  PresetExport,
  ReportCategory,
  ReportsFormData,
  filterExportTypes,
  showCampaignField,
  supportedParams,
} from '../helpers';
import {Grid, LoadingContainer} from '../shared';
import {ActionsField} from './ActionsField';
import {AttributesField} from './AttributesField';
import {CampaignField} from './CampaignField';
import {ColumnsField} from './ColumnsField';
import {DatesField} from './DatesField';
import {IntervalField} from './IntervalField';
import {ModelledField} from './ModelledField';
import {NameField} from './NameField';
import {SortField} from './SortField';
import {SpendByImpressionsField} from './SpendByImpressionsField';
import {TrailingField} from './TrailingField';
import {TypeField} from './TypeField';

type Props = {
  category: ReportCategory | undefined;
  form: UseFormReturn<ReportsFormData>;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  presetExport: PresetExport | undefined;
};

export default function ReportsFormFields({
  category,
  form,
  organization,
  presetExport,
}: Props) {
  const {data, loading, error} = useExportTypesQuery({
    variables: {
      organizationId: organization.id,
    },
  });

  const exportTypes = useMemo(
    () => filterExportTypes({data, category}),
    [data, category]
  );

  const typeId = form.watch('typeId');
  const params = useMemo(
    () => supportedParams({exportTypes, typeId}),
    [exportTypes, typeId]
  );

  return (
    <>
      {loading || !!error ? (
        <LoadingContainer noBorder loading={loading} fetchError={!!error} />
      ) : (
        <Stack direction='column' spacing={6}>
          <NameField {...form} />
          <TypeField
            exportTypes={exportTypes}
            organization={organization}
            {...form}
          />
          {typeId && (
            <>
              {showCampaignField(typeId, params) && (
                <CampaignField
                  disabled={category === 'campaign' && !!presetExport}
                  optional={params.campaignId}
                  organizationId={organization.id}
                  {...form}
                />
              )}
              <ColumnsField organizationId={organization.id} {...form} />
              <SortField {...form} />
              {params.startDate && (
                <DatesField showRange={params.endDate} {...form} />
              )}
              {params.actions && <ActionsField {...form} />}
              {params.interval && params.trailing && (
                <Grid columns={2}>
                  <IntervalField {...form} />
                  <TrailingField {...form} />
                </Grid>
              )}
              {params.attr && <AttributesField {...form} />}
              {params.modelled && <ModelledField {...form} />}
              {params.spendByImpressions && (
                <SpendByImpressionsField {...form} />
              )}
            </>
          )}
        </Stack>
      )}
    </>
  );
}
