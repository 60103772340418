import {gql} from '@apollo/client';

export default gql`
  query getPublisherDetail($organizationId: ID!, $publisherSlug: String!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          publisher(slug: $publisherSlug) {
            id
            slug
            name

            podcasts {
              id

              feed {
                id
                title
                description(words: 25)
                href
                image {
                  id
                  href
                  path
                }
                episodesCount
                lastPublished
              }
            }
          }
        }
      }
    }
  }
`;
