import {Card} from '@core/ui/Content';
import {CampaignTargetsTable} from '@analytics/components/campaigns/CampaignTargetsTable';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignRouteProps} from '../_routes';

export function CampaignTargets({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  return (
    <>
      <CampaignNav
        user={user}
        organization={organization}
        campaign={campaign}
      />
      <Card>
        <CampaignTargetsTable organization={organization} campaign={campaign} />
      </Card>
    </>
  );
}
