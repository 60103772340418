import {useEffect} from 'react';
import {t} from '@core/texts';
import Badge, {BadgeProps} from '@core/ui/Badge';
import {ButtonIcon, ButtonIconProps} from '@core/ui/Button';
import {LoadingSpinner} from '@core/ui/Loading';
import {useOverlay} from '@core/ui/Overlay';
import {Snackbar} from '@core/ui/Snackbar';
import {Tooltip} from '@core/ui/Tooltip';
import {TooltipProps} from '@core/ui/Tooltip/Tooltip';
import {
  FetchedReportExportInstances,
  FetchedSavedExports,
} from '@analytics/graphql-api';
import {getReportStatus} from './helpers';

type ReportsTableButtonProps = {
  error?: boolean;
  loading: boolean;
  buttonIconProps: ButtonIconProps;
  tooltipProps: TooltipProps;
};

export const ReportsTableButton = ({
  error,
  loading,
  buttonIconProps,
  tooltipProps,
}: ReportsTableButtonProps) => {
  const [openedError, toggleError] = useOverlay();

  useEffect(() => {
    if (error) toggleError(true);
  }, [error, toggleError]);

  return (
    <>
      <Tooltip
        css={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}
        {...tooltipProps}>
        {loading ? (
          <LoadingSpinner size='small' css={{padding: '0 8px'}} />
        ) : (
          <ButtonIcon css={{paddingLeft: '0.375rem'}} {...buttonIconProps} />
        )}
      </Tooltip>
      {openedError && (
        <Snackbar
          severity='error'
          autoHideDuration={4000}
          opened={openedError}
          toggle={toggleError}>
          {t('errors.network')}
        </Snackbar>
      )}
    </>
  );
};

interface StatusBadgeProps extends BadgeProps {
  exportInstance?:
    | NonNullable<FetchedSavedExports[number]>['lastExportInstance']
    | FetchedReportExportInstances[number];
}

export const StatusBadge = ({exportInstance}: StatusBadgeProps) => {
  const reportStatus = getReportStatus({exportInstance});

  return (
    <div>
      {reportStatus && (
        <Badge color={reportStatus.color} variant={reportStatus.variant}>
          {reportStatus.text}
        </Badge>
      )}
    </div>
  );
};
