import {gql} from '@apollo/client';
import CampaignStreamingFragment from '@analytics/graphql-api/_old_queries/fragments/CampaignStreamingFragment';

export default gql`
  mutation createCampaignStreaming($input: CreateCampaignStreamingInput!) {
    authed {
      success
    }
    createCampaignStreaming(input: $input) {
      campaignStreaming {
        ...CampaignStreamingOld
      }
    }
  }
  ${CampaignStreamingFragment}
`;
