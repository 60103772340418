const impressionsForPlacements = ({value, campaign, campaignPodcast}) => {
  if (campaign && campaign.campaignPodcasts) {
    for (const {
      isPreRoll,
      isMidRoll,
      isPostRoll,
      downloadTotal,
    } of campaign.campaignPodcasts) {
      value +=
        downloadTotal *
        Math.max(
          [isPreRoll, isMidRoll, isPostRoll].filter(Boolean).length - 1,
          0
        );
    }
  } else if (campaignPodcast) {
    const {isPreRoll, isMidRoll, isPostRoll} = campaignPodcast;
    const m = [isPreRoll, isMidRoll, isPostRoll].filter(Boolean).length || 1;

    return value * m;
  }
  return value;
};

const getPlacementsStr = (instance) => {
  const result = [];

  for (const p of [
    {value: 'isPreRoll', title: 'Pre'},
    {value: 'isMidRoll', title: 'Mid'},
    {value: 'isPostRoll', title: 'Post'},
  ]) {
    if (instance[p.value]) {
      result.push(p.title);
    }
  }

  return result.join(', ');
};

export {getPlacementsStr, impressionsForPlacements};
