import styled from 'styled-components';
import {createOrgPath} from '@core/lib/organizations';
import {stripHTML, truncateWords} from '@core/lib/words';
import {Breadcrumbs} from '@core/ui/Breadcrumbs';
import {FeedImage} from '@core/ui/Image';
import {NavItem} from '@core/ui/Nav';
import AdvisorSimpleAudioPlayer from '@analytics/components/advisor/AdvisorSimpleAudioPlayer';
import {
  FeedObject,
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {
  AdvisorPodcastRouteProps,
  generateAdvisorPodcastRoutes,
} from '@analytics/screens/unified/advisor/podcast/_routes';

export const advisorPodcastURL = (
  organization: FetchedOrganization | FetchedBuySideOrganization,
  feed: FeedObject
) => createOrgPath(organization, `/advisor/podcast/${feed.id}`);

export function AdvisorPodcastNav({
  podcast,
  organization,
}: Omit<AdvisorPodcastRouteProps, 'user'>) {
  const {routes} = generateAdvisorPodcastRoutes({
    organization,
    podcastId: podcast?.id ?? '',
  });

  const canPlayTrailer =
    podcast?.feed?.trailer &&
    podcast?.feed?.trailer?.enclosure?.mpegPath &&
    podcast?.feed?.trailer?.enclosure?.waveform;

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            title: 'Advisor',
            to: createOrgPath(organization, '/advisor'),
          },
          {title: 'Podcast', to: ''},
        ]}
        css={`
          margin: 1rem 1.5rem 0;
          padding: 0;
        `}
      />
      <div css={{margin: '0.875rem 1.5rem 2rem'}}>
        <_NavContainer>
          <FeedImage
            feed={podcast?.feed}
            width={85}
            rules={() => ({
              boxShadow: 'var(--shadow-lg)',
              marginRight: '1.25rem',
              width: '12rem',
              height: '12rem',
            })}
          />
          <_HeaderContainer>
            <_Subheader>{podcast?.publisher?.name}</_Subheader>
            <_Header>{podcast?.feed?.title}</_Header>
            <_Description>
              {truncateWords(
                stripHTML(
                  podcast?.feed?.summary ?? podcast?.feed?.description ?? ''
                ),
                40
              )}
            </_Description>
            {canPlayTrailer && (
              <AdvisorSimpleAudioPlayer
                ad={podcast?.feed?.trailer?.enclosure}
              />
            )}
          </_HeaderContainer>
        </_NavContainer>
        <div css={{flex: 1}}>
          <_NavItemsContainer>
            {routes.map(({title, to, key, exact}) => (
              <NavItem
                key={key}
                to={to}
                isActive={
                  exact ? undefined : ({path}) => path.indexOf(to ?? '') === 0
                }>
                {title}
              </NavItem>
            ))}
          </_NavItemsContainer>
        </div>
      </div>
    </>
  );
}

const _NavContainer = styled.div`
  display: flex;
  margin: 0 0 1rem;
`;

const _NavItemsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

const _HeaderContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 52rem;
`;

const _Header = styled.div`
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
`;

const _Subheader = styled.div`
  align-items: center;
  display: flex;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;

const _Description = styled.div`
  color: var(--text-muted);
  font-size: 0.975rem;
  margin-top: 0.75rem;
`;
