import {useState} from 'react';
import Select from '@spotify-internal/react-select';
import {InsightsMetric} from '../types';

type Props = {
  children?: React.ReactNode;
  defaultValue?: InsightsMetric;
  onChangeMetric?: (_: InsightsMetric) => void;
};

export function InsightsMetricSelect({defaultValue, onChangeMetric}: Props) {
  const [selectedOption, setSelectedOption] = useState(
    OPTIONS.find(({value}) => defaultValue === value)
  );

  return (
    <Select
      defaultValue={selectedOption}
      isSearchable={false}
      onChange={(option: (typeof OPTIONS)[number]) => {
        setSelectedOption(option);
        onChangeMetric?.(option.value);
      }}
      options={OPTIONS}
      value={selectedOption}
    />
  );
}

const OPTIONS = [
  {label: 'Impressions', value: InsightsMetric.IMPRESSIONS},
  {label: 'Conversions', value: InsightsMetric.CONVERSIONS},
];
