import {createOrgPath} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {PodlinkTable} from '@analytics/components/podlink/PodlinkTable';
import {ManagePodlinkDomainRouteProps} from '.';

export function PodlinkDomain({
  organization,
  podlinkDomain,
  match: {url},
}: ManagePodlinkDomainRouteProps & {
  match: {url: string};
}) {
  // Get the podcasts that we can add.
  return (
    <ResponsiveContent containerSize='xlarge'>
      <CardHeader
        backPath={createOrgPath(organization, 'manage/podlink')}
        rightContent={
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '0.5rem',
            }}>
            <Button variant='outlined' buttonSize='sm' to={`${url}/update`}>
              Update
            </Button>
            <Button color='secondary' buttonSize='sm' to={`${url}/design`}>
              Design
            </Button>
          </div>
        }>
        {podlinkDomain.title}
      </CardHeader>
      <Card>
        <PodlinkTable
          organization={organization}
          podlinkDomain={podlinkDomain}
          podcasts={podlinkDomain.podcasts}
        />
      </Card>
    </ResponsiveContent>
  );
}
