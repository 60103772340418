/**
 * Encore Foundation - 5.32.2
 *
 * The contents of this file is owned and generated by the Encore team.
 * If you need to make changes to the values stored here please reach out
 * to #encore on Slack.
 *
 * Links:
 *
 * - Source & Documentation: https://ghe.spotify.net/encore/foundation
 *
 * Tack!
 *
 * Encore
 * encore.spotify.net
 */
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var screenXxsMax = '479px';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var screenXsMin = '480px';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var screenXsMax = '767px';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var screenSmMin = '768px';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var screenSmMax = '991px';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var screenMdMin = '992px';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var screenMdMax = '1199px';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var screenLgMin = '1200px';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var screenLgMax = '1919px';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var screenXlMin = '1920px';
