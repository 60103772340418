import {Type, spacer8} from '@spotify-internal/encore-web';
import {capitalize} from 'lodash';
import {Stack} from '@core/ui/Layout';
import {DEFAULT_METRIC} from '../constants';
import {AudienceOrder, AudiencesByGroup, InsightsMetric} from '../types';
import {parseTopAudienceData} from '../utils';
import {InsightsGrid} from './styled';

type Props = {
  audiences: AudiencesByGroup;
  audienceOrder?: AudienceOrder;
  metric?: InsightsMetric;
};

export function InsightsTopAudiences({
  audiences,
  audienceOrder,
  metric = DEFAULT_METRIC,
}: Props) {
  const data = parseTopAudienceData(audiences, metric, audienceOrder);
  return (
    <InsightsGrid columns={Math.min(data.length, 4)}>
      {data.map(({title, Icon, value}) => (
        <Stack key={title} direction='column' gap={spacer8}>
          <Stack gap={spacer8} alignItems='center'>
            <Icon size='small' />
            <Type>{title}</Type>
          </Stack>
          <Type variant='celloCanon'>{capitalize(value)}</Type>
        </Stack>
      ))}
    </InsightsGrid>
  );
}
