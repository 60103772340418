import {BarProps} from 'recharts';
import {Significance} from '@analytics/graphql-api';
import {SIGNIFICANCE_MAP, fmtPercentDiff, toNum} from '../helpers';
import {BarLiftData} from '../types';

const LINE_COLOR = 'var(--border-default)';
const LABEL_COLOR = 'var(--text-muted)';
const STROKE_WIDTH = 2;
const STROKE_DASH = '4 4';

export function BrandLiftCustomBar({
  controlPercentage,
  exposedPercentage,
  fill,
  fillOpacity,
  height,
  percentChange,
  rx,
  significance,
  width,
  x,
  y,
}: BarLiftData & BarProps) {
  const positiveLift = exposedPercentage >= controlPercentage;
  const dividePercentage =
    exposedPercentage > 0 ? controlPercentage / exposedPercentage : 0;
  const _height = toNum(height);
  const _y = toNum(y);
  const _x = toNum(x);
  const _width = toNum(width);

  const x2 = positiveLift ? _x - 4 - _width / 2 : _x + _width / 2;

  const y2 = _y + _height - dividePercentage * _height;
  const y1 = positiveLift ? y : y2;

  return (
    <svg>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        fillOpacity={fillOpacity}
        rx={rx}
      />
      {(significance === Significance.Confidence_80 ||
        significance === Significance.Confidence_90) && (
        <>
          <line
            x1={x}
            x2={x2}
            y1={y1}
            y2={y1}
            stroke={LINE_COLOR}
            strokeWidth={STROKE_WIDTH}
            strokeDasharray={STROKE_DASH}
          />
          <line
            x1={x2}
            x2={x2}
            y1={y}
            y2={y2}
            stroke={LINE_COLOR}
            strokeWidth={STROKE_WIDTH}
            strokeDasharray={STROKE_DASH}
          />
        </>
      )}
      <circle
        cx={_x - 8 - _width / 4}
        cy={positiveLift ? _y - 15.75 : y2 - 15.75}
        r={3}
        fill={SIGNIFICANCE_MAP[significance]}
      />
      <text
        fill={LABEL_COLOR}
        fontSize='var(--font-size-h5)'
        x={_x - 2 - _width / 4}
        y={positiveLift ? _y - 10 : y2 - 10}>
        {fmtPercentDiff(percentChange)}
      </text>
    </svg>
  );
}
