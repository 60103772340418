import dayjs from '@core/lib/dayjs';
import {
  BaseCampaignsArray,
  BaseOrderCampaign,
  SpotifyOrder,
  SpotifyOrderCampaign,
  SuperCampaign,
  SuperCampaignIds,
} from './_types';

export const createSuperCampaign = (spotifyOrder?: SpotifyOrder | null) => {
  const {campaigns, ...totals} = spotifyOrder ?? BASE_ORDER;
  const combined = campaigns.reduce(
    (acc, curr) => {
      return Object.fromEntries(
        Object.keys(acc).map((key) => {
          switch (typeof acc[key]) {
            case 'number':
              return parseNumber(acc, curr, key);
            case 'object':
              return parseObject(acc, curr, key);
            case 'string':
              return parseString(acc, curr, key);
            default:
              return [key, acc[key]] as never;
          }
        })
      );
    },
    {...BASE_CAMPAIGN}
  );

  return {...totals, ...combined};
};

export const getSuperCampaignIds = ({
  superCampaign,
}: {
  superCampaign: SuperCampaign;
}) => {
  return (superCampaign.campaigns ?? []).reduce(
    (acc, curr, i) => ({
      ...acc,
      [`id${i + 1}`]: curr.id,
    }),
    {
      id1: '',
      id2: undefined,
      id3: undefined,
      id4: undefined,
    } as SuperCampaignIds
  );
};

/* --------------------------------------------------------------------- */

const DATES = ['expectedStartAt', 'expectedEndAt'];

const BASE_CAMPAIGN = {
  advertiser: {} as SpotifyOrderCampaign['advertiser'],
  campaigns: [] as BaseCampaignsArray,
  campaignDynamics: [] as SpotifyOrderCampaign['campaignDynamics'],
  campaignStreamings: [] as SpotifyOrderCampaign['campaignStreamings'],
  campaignTargets: [] as SpotifyOrderCampaign['campaignTargets'],
  cost: 0,
  costToDate: 0,
  currency: 'USD' as SpotifyOrderCampaign['currency'],
  expectedStartAt: '',
  expectedEndAt: '',
  goal: 0,
  isMegaphone: '',
  kind: '',
  multiplier: 1,
  organization: {} as SpotifyOrderCampaign['organization'],
  pixels: [] as SpotifyOrderCampaign['pixels'],
  slug: '',
  state: '' as SpotifyOrderCampaign['state'],
  targetDownloadTotal: 0,
  targetReachTotal: 0,
  verifiedReach: 0,
} as BaseOrderCampaign;

const BASE_ORDER = {
  id: '',
  name: '',
  createdAt: '',
  addtocartTotal: 0,
  addtocartTotalModeled: 0,
  checkoutTotal: 0,
  checkoutTotalModeled: 0,
  downloadTotal: 0,
  installedTotal: 0,
  installedTotalModeled: 0,
  isMegaphone: '',
  leadTotal: 0,
  leadTotalModeled: 0,
  productTotal: 0,
  productTotalModeled: 0,
  purchaseTotal: 0,
  purchaseTotalModeled: 0,
  reachTotal: 0,
  userTotal: 0,
  userTotalModeled: 0,
  valueTotal: 0,
  valueTotalModeled: 0,
  campaigns: [] satisfies BaseOrderCampaign[],
} satisfies Omit<SpotifyOrder, '__typename'>;

const parseNumber = (oldVal, newVal, key) => {
  const updated = oldVal[key] + (newVal[key] ?? 0);
  return [key, updated];
};

const parseObject = (oldVal, newVal, key) => {
  if (Array.isArray(oldVal[key])) {
    if (key == 'campaigns' && newVal.id)
      return [
        key,
        [...oldVal[key], {id: newVal.id, name: newVal.name, slug: newVal.slug}],
      ];

    return [key, [...oldVal[key], ...(newVal[key] ?? [])]];
  }
  return [key, newVal[key]];
};

const parseString = (oldVal, newVal, key) => {
  if (DATES.includes(key)) {
    const prevDate = oldVal[key] ? dayjs(oldVal[key]) : undefined;
    const newDate = dayjs(newVal[key]);

    if (!prevDate) return [key, newDate];

    const compareDate = (
      key.toLowerCase().includes('start')
        ? newDate.isBefore(prevDate, 'day')
        : newDate.isAfter(prevDate, 'day')
    )
      ? newDate.format('YYYY-MM-DD')
      : prevDate.format('YYYY-MM-DD');

    return [key, compareDate];
  }

  return [key, newVal[key]];
};
