import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignLiftRouteProps} from '../_routes';
import CampaignNoiseCreate from './CampaignNoiseCreate';
import {CampaignNoiseIndex} from './noise';

export function CampaignNoisesIndex({
  user,
  organization,
  campaign,
}: CampaignLiftRouteProps) {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/campaigns/:campaignSlug/lift/households/create'
        user={user}
        organization={organization}
        campaign={campaign}
        component={CampaignNoiseCreate}
      />
      <PropsRoute
        path='/:part(a|s|b|p)/:slug/campaigns/:campaignSlug/lift/households/:campaignNoiseId'
        user={user}
        organization={organization}
        campaign={campaign}
        component={CampaignNoiseIndex}
      />
    </Switch>
  );
}
