import {gql} from '@apollo/client';

export default gql`
  fragment Podcast on PodcastObject {
    id
    isAdvisor
    processingStartedAt
    createdAt
    feed {
      id
      title
      description(words: 25)
      href
      state
      publisherName
      publisherEmail
      image {
        id
        href
        path
      }
    }
  }
`;
