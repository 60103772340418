import {PropsWithChildren} from 'react';
import {createOrgPath} from '@core/lib/organizations';
import Nav from '@core/ui/Nav';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {useReportsRoutes} from '@analytics/screens/unified/reports/_routes';

export const ReportsNav = ({
  children,
  organization,
}: PropsWithChildren<{
  organization: FetchedOrganization | FetchedBuySideOrganization;
}>) => {
  const {routes} = useReportsRoutes({organization});
  return (
    <Nav title='Reports' items={routes}>
      {children}
    </Nav>
  );
};

export const toReportsUrl = (
  organization: FetchedOrganization | FetchedBuySideOrganization
) => createOrgPath(organization, 'reports');
