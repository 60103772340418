import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import Button from '@core/ui/Button/LegacyButton';
import {
  Form,
  FormFooter,
  FormGroup,
  FormHelp,
  Label,
  LabelInput,
  Submit,
} from '@core/ui/FormElements';
import PactsafeClickwrap from '@core/ui/PactsafeClickwrap';
import Toggle from '@core/ui/Toggle';
import {InfoTooltip} from '@core/ui/Tooltip';
import PixelMethodMultipleSelect from '@analytics/components/pixels/PixelMethodMultipleSelect';
import {IKey} from '@analytics/graphql-api/_old_queries/OrganizationKeyQuery';

type TItem = {
  domain: string;
  hasAlias: boolean;
  alias: string;
  installations: string | string[];
};

interface IPixelFormProps {
  loading: boolean;
  onCancel?: () => void;
  onSubmit: (_item: TItem) => void;
  pixel?: IKey;
  user?: {
    email: string;
  };
}

const PixelForm = ({
  loading,
  onSubmit,
  onCancel,
  pixel,
  user,
}: IPixelFormProps): JSX.Element => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: {errors, isDirty},
    watch,
  } = useForm({
    defaultValues: {
      domain: pixel?.domain || '',
      hasAlias: !!pixel?.alias || false,
      alias: pixel?.alias || '',
      installations: !!pixel?.installations
        ? pixel.installations.split(',')
        : ['JAVASCRIPT'],
      policy: !!pixel,
    },
  });

  const _onSubmit = async (item: TItem) => {
    if (pixel && !isDirty) {
      onCancel();
    } else {
      setSubmitted(true);
      const _item = {
        ...item,
        ...(Array.isArray(item.installations) && {
          installations: item.installations.join(','),
        }),
      };

      onSubmit(_item);
    }
  };

  const hasAlias = watch('hasAlias');

  return (
    <Form errors={errors} onSubmit={handleSubmit(_onSubmit)}>
      {!!pixel && (
        <FormGroup>
          <LabelInput
            defaultValue={pixel.key}
            label='Pixel ID'
            readOnly
            rules={() => ({background: 'var(--bg-muted)'})}
          />
        </FormGroup>
      )}
      <FormGroup>
        <Controller
          name='domain'
          control={control}
          rules={{required: 'Please provide a pixel domain.'}}
          render={({field}) => (
            <LabelInput
              label='Pixel Domain'
              onChange={(value) => field.onChange(value)}
              invalid={!!errors.domain}
              {...field}
            />
          )}
        />
        {!pixel && user && (
          <Controller
            name='policy'
            control={control}
            rules={{required: 'Please review our data privacy agreement.'}}
            render={({field}) => (
              <PactsafeClickwrap
                groupKey='group-hkdy3bvrf'
                sendData={submitted}
                invalid={!!errors.policy}
                onChecked={(checked) => field.onChange(checked)}
                signerId={user.email}
                css={`
                  margin-top: 0.5rem;
                `}
              />
            )}
          />
        )}
      </FormGroup>
      <FormGroup>
        <Label>Alias Events</Label>
        <FormHelp>
          For advanced users, you can optionally pass us an internal user ID
          along with each event, which we can pass back to you when reporting on
          conversions. You can then match back the conversions we attribute with
          your internal systems.
        </FormHelp>
        <Controller
          name='hasAlias'
          control={control}
          render={({field}) => (
            <Toggle
              defaultChecked={!!hasAlias}
              onChecked={(value) => {
                field.onChange(value);
                if (!value) clearErrors('alias');
              }}
              style={{marginTop: '0.75rem'}}
            />
          )}
        />
        {hasAlias && (
          <>
            <Controller
              name='alias'
              control={control}
              render={({field}) => (
                <div css={{position: 'relative'}}>
                  <LabelInput
                    invalid={!!errors.alias}
                    label='Alias ID'
                    rules={() => ({marginTop: '0.5rem'})}
                    {...field}
                  />
                  <InfoTooltip
                    description="This field should be a variable - the value will be different each time it's called. If you're not sure about this field, check with your developer."
                    iconSize={14}
                    rules={() => ({
                      position: 'absolute',
                      top: '1.5rem',
                      right: '1rem',
                    })}
                  />
                </div>
              )}
            />
            <FormHelp>
              The Alias ID is for JavaScript pixel integrations only. For mobile
              integrations, only AppsFlyer provides an Alias ID (enabled by
              default).
            </FormHelp>
          </>
        )}
      </FormGroup>
      <FormGroup>
        <Label>Installation</Label>
        <FormHelp>
          Please select all of the installation methods that your organization
          will use.
        </FormHelp>
        <Controller
          name='installations'
          control={control}
          rules={{
            validate: (value) =>
              !!value.length
                ? true
                : 'Please select at least one installation method.',
          }}
          render={({field}) => (
            <PixelMethodMultipleSelect
              defaultValue={field.value}
              invalid={!!errors.installations}
              onSelect={(items) =>
                field.onChange(
                  items.map(({value}: {value: string}) => value).join(',')
                )
              }
              rules={() => ({marginTop: '0.5rem'})}
            />
          )}
        />
      </FormGroup>
      <FormFooter>
        {!!onCancel && (
          <Button type='button' disabled={loading} onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Submit loading={loading} disabled={loading}>
          {!pixel ? 'Create' : 'Save'} Pixel
        </Submit>
      </FormFooter>
    </Form>
  );
};

export default PixelForm;
