import React from 'react';
import dayjs from '@core/lib/dayjs';
import {truncate} from '@core/lib/words';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {Dummy, EpisodeImage} from '@core/ui/Image';
import {campaignDynamicEpisodeURL} from '@analytics/components/nav/CampaignDynamicEpisodeNav';
import campaignTableColumns from '@analytics/lib/campaignTableColumns';
import useCampaignEpisodes from '@analytics/lib/hooks/useCampaignEpisodes';

const CampaignDynamicEpisodesTable = ({
  organization,
  campaign,
  campaignDynamic,
  campaignDynamicPodcast,
  campaignDynamicEpisodes,
}) => {
  const data = useCampaignEpisodes(campaignDynamicEpisodes, {
    campaign,
  }).map((episode) => {
    episode.episodeTitle = episode.title;
    episode.to = campaignDynamicEpisodeURL(
      organization,
      campaign,
      campaignDynamic,
      campaignDynamicPodcast,
      {id: episode.id}
    );

    return episode;
  });

  const columns = [
    {
      title: 'Episode',
      accessor: 'episodeTitle',
      type: 'string',
      Cell: ({data: {episode}}) => {
        return (
          <Element
            rules={() => ({
              display: 'flex',
              minWidth: '15.5rem',
            })}>
            <Element
              rules={() => ({
                flex: '0 0 4.6875rem',
                marginRight: '1rem',
                marginTop: '0.25rem',
              })}>
              {episode ? <EpisodeImage episode={episode} /> : <Dummy />}
            </Element>
            <Element rules={() => ({flex: 1})}>
              <Element
                rules={({theme}) => ({
                  color: theme.textPrimary,
                  fontWeight: 500,
                })}>
                {episode.title}
              </Element>
              <Element
                rules={({theme}) => ({
                  color: theme.textTertiary,
                  fontSize: '.875rem',
                  lineHeight: 1.5,
                })}>
                {truncate(episode.description, 50)}
              </Element>
            </Element>
          </Element>
        );
      },
    },
  ].concat(campaignTableColumns(organization, campaign, data));

  columns.push({
    title: 'Published',
    accessor: 'published',
    type: 'date',
    fmt: (v) => (v ? dayjs(v).format('MMM DD, YYYY') : 'never'),
  });

  return (
    <DataTable
      searchKeys={['episodeTitle']}
      searchPlaceholder='Search by episode name'
      columns={columns}
      orderBy='-downloadTotal'
      data={data}
      onClickRowPath={({to}) => to}
    />
  );
};

export default CampaignDynamicEpisodesTable;
