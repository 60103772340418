import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignRouteProps} from '../_routes';
import {CampaignPodcastIndex} from './podcast';

export function CampaignPodcastsIndex({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  return (
    <Switch>
      <PropsRoute
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/podcasts/:campaignPodcastId'
        campaign={campaign}
        user={user}
        organization={organization}
        component={CampaignPodcastIndex}
      />
    </Switch>
  );
}
