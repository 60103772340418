import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';

export type ReportsRouteProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
};

export const useReportsRoutes = ({
  organization,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) =>
  useRoutes({
    baseUrl: createOrgPath(organization, 'reports'),
    basePath: '/:part(b|a|s|p)/:slug/reports',
    routes: [
      {
        key: 'reports',
        title: 'Reports',
        path: '',
        exact: true,
      },
      {
        key: 'history',
        title: 'History',
        exact: true,
      },
    ],
  });
