import {gql} from '@apollo/client';

export default gql`
  mutation updateFeeds($input: UpdateFeedsInput!) {
    authed {
      success
    }
    updateFeeds(input: $input) {
      feeds {
        id
        title
        description(words: 25)
        href
        state
        publisherName
        publisherEmail
        image {
          id
          href
          path
        }
      }
    }
  }
`;
