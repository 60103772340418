import {useCreateExportTokenMutation} from '@analytics/graphql-api';
import {exporterEndpoint} from '../../../core/lib/environment';

type OrganizationExport = {
  objectType: 'organization';
  kind:
    | 'daily'
    | 'weekly'
    | 'conversions'
    | 'benchmark_campaigns'
    | 'benchmark_campaigns_detail'
    | 'locations'
    | 'affinity';
};

type CompanyExport = {
  objectType: 'company';
  kind: 'placements';
};

type CampaignExport = {
  objectType: 'campaign';
  kind: 'overview' | 'conversions' | 'impressions';
};

type PodcastExport = {
  objectType: 'podcast';
  kind: 'downloads_full' | 'downloads_hourly' | 'downloads_daily';
};

type FeedExport = {
  objectType: 'feed';
  kind: 'placements' | 'segments';
};

type EpisodeExport = {
  objectType: 'episode';
  kind:
    | 'placements'
    | 'downloads_full'
    | 'downloads_hourly'
    | 'downloads_daily';
};

type LiftExport = {
  objectType: 'lift';
  kind: 'report' | 'multi_report';
};

type PixelExport = {
  objectType: 'key';
  kind: 'pixel_hourly';
};

export type ExportsByType = {
  /* Primary key (ID) of instance  */
  pk: string;
  organizationId: string;
  params?: {[key: string]: string | number};
} & (
  | OrganizationExport
  | CompanyExport
  | CampaignExport
  | PodcastExport
  | FeedExport
  | EpisodeExport
  | LiftExport
  | PixelExport
);

export default function useExportTokenUriMutation() {
  const [createExportToken] = useCreateExportTokenMutation();

  return [
    async ({pk, kind, objectType, organizationId, params}: ExportsByType) => {
      try {
        const response = await createExportToken({
          variables: {
            input: {
              entityId: pk,
              kind,
              objectType,
              organizationId,
            },
          },
        });

        const token = response?.data?.createExportToken?.token;
        if (token) {
          return `${exporterEndpoint}/broker?${new URLSearchParams({
            token,
            pk,
            kind,
            object_type: objectType,
            organization_id: organizationId,
            ...(params ?? {}),
          }).toString()}`;
        }
        throw new Error('Failed to generate export token');
      } catch (e) {
        console.warn(e);
        throw new Error(e);
      }
    },
  ];
}
