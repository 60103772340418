import {Nav} from '@core/ui/Nav';
import {
  ManageRouteProps,
  useManageRoutes,
} from '@analytics/screens/unified/manage/_routes';

export default function ManageNav({user, organization}: ManageRouteProps) {
  const {routes} = useManageRoutes({user, organization});

  return <Nav title='Manage' description={organization.name} items={routes} />;
}
