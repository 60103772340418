import {useRef, useState} from 'react';
import dayjs from '@core/lib/dayjs';
import DatePicker from '@core/ui/DatePicker';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import {DatesInputButton} from './shared';

type Props = {
  invalid: boolean;
  onSelect: (_: string) => void;
};

export function DateInput({invalid, onSelect}: Props) {
  const ref = useRef<HTMLButtonElement>(null);
  const [opened, toggle] = useOverlay();
  const [selected, setSelected] = useState<dayjs.Dayjs>();

  const _onSelect = (dates: dayjs.Dayjs[]) => {
    onSelect(dates[0]?.format('YYYY-MM-DD'));
    setSelected(dates[0]);
    toggle(false);
  };

  return (
    <>
      <DatesInputButton
        active={!!selected}
        domRef={ref}
        invalid={invalid}
        noArrowIcon
        onClick={() => toggle()}
        placeholder={selected?.format('MM/DD/YY') ?? 'Date'}
      />
      {opened && (
        <Overlay
          horizontalAlign='right'
          opened={opened}
          positionTarget={ref.current}
          toggle={toggle}
          transparentBackdrop
          verticalAlign='bottom'
          verticalOffset={2}
          withShadow
          withBackdrop
          css={`
            max-width: initial;
            padding: 1rem;
          `}>
          <DatePicker
            extraControls={[
              {
                title: 'Clear',
                callback: () => {
                  _onSelect([]);
                  toggle(false);
                },
              },
            ]}
            mode='day'
            onDatesChange={_onSelect}
          />
        </Overlay>
      )}
    </>
  );
}
