import {gql} from '@apollo/client';

export default gql`
  query search($organizationId: ID!, $query: String!, $limit: Int) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          search(query: $query, limit: $limit) {
            total
            results {
              id
              kind
              title
              slug
              subtitle
              body
              imagePath
              duration
              published
            }
          }
        }
      }
    }
  }
`;
