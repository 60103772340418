import {campaignPodcastURL} from '@analytics/components/nav/CampaignPodcastNav';
import useSettings from '@analytics/components/settings/useSettings';
import {toModeled} from '@analytics/lib/utils';

export const getCampaignPodcasts = (
  campaignPodcasts,
  {organization, campaign}
) => {
  const [settings] = useSettings();
  const results = [];

  for (const podcast of campaignPodcasts) {
    const {
      id,
      name,
      cost,
      costToDate,
      drops,
      campaignEpisodesCount,
      reachTotal,
      downloadTotal,
      downloads,
      attribution,
      isPreRoll,
      isMidRoll,
      isPostRoll,
      targetDownloadTotal,
    } = podcast;

    const multiplyDownloadsByPlacement = (value) => {
      return (
        value *
        Math.max([isPreRoll, isMidRoll, isPostRoll].filter(Boolean).length, 1)
      );
    };

    let _downloadTotal =
      downloads && downloads.count ? downloads.count : downloadTotal;
    if (settings.impressionsByPlacements) {
      _downloadTotal = multiplyDownloadsByPlacement(_downloadTotal);
    }

    const frequency = reachTotal ? _downloadTotal / reachTotal : 1;
    const _reachTotal = _downloadTotal / frequency;

    // Deal with attribution
    const _userTotal = toModeled(podcast, 'user', settings.modelled);

    const _costToDate = attribution
      ? (costToDate / downloadTotal) * _downloadTotal
      : costToDate;

    const _cost = settings.spendByImpressionsDelivered
      ? _costToDate
      : cost * (drops / campaignEpisodesCount);

    let cr;
    if (campaign.kind.toLowerCase() === 'marketing') {
      cr = _reachTotal ? targetDownloadTotal / _reachTotal : 0;
    } else {
      cr = _reachTotal ? _userTotal / _reachTotal : 0;
    }
    const isPpa = campaign?.isPpa;
    const cpm = isPpa
      ? 1
      : _downloadTotal
      ? (_cost / _downloadTotal) * 1000
      : 0;
    const cpv = _reachTotal && _userTotal ? _cost / _userTotal : 0;

    results.push({
      ...podcast,
      name,
      url: campaignPodcastURL(organization, campaign, {id}),
      costToDate: _cost,
      totalDrops: campaignEpisodesCount,
      downloadTotal: _downloadTotal,
      attributionTotal: _userTotal,
      purchaseTotal: toModeled(podcast, 'purchase', settings.modelled),
      valueTotal: toModeled(podcast, 'value', settings.modelled),
      installedTotal: toModeled(podcast, 'installed', settings.modelled),
      leadTotal: toModeled(podcast, 'lead', settings.modelled),
      cr,
      cpm,
      frequency,
      cpv,
      format: 'embedded',
    });
  }

  return results.sort((a, b) => {
    return b.downloadTotal - a.downloadTotal;
  });
};
