import {gql} from '@apollo/client';

export default gql`
  fragment Company on CompanyObject {
    id
    slug
    name
    description
    url
    industry
    industryDisplay
    image {
      id
      width
      height
      src
      path
    }
    createdAt
  }
`;
