import {ChangeEvent, useState} from 'react';
import {QueryResult} from '@apollo/client';
import {Query} from '@apollo/client/react/components';
import {
  Banner,
  ButtonPrimary,
  FormGroup,
  FormInput,
  FormToggle,
  ProgressCircle,
  Text,
  cssSpacing,
  semanticColors,
} from '@spotify-internal/encore-web';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ChartLegend from '@core/components/ChartLegend';
import {ChartTimeTooltip} from '@core/components/ChartTimeTooltip';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {BLUE, GREEN, PINK, PURPLE, RED_ORANGE} from '@core/style';
import {DateRangeInput} from '@core/ui/DatePicker';
import EmptyMessage from '@core/ui/EmptyMessage';
import {FieldSet} from '@core/ui/Form';
import {FORM_ERRORS} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import Loading from '@core/ui/Loading';
import {LiftReportPreCreateCampaignDocument} from '@analytics/graphql-api';

const getDailyDownloads = (hourly) => {
  const daily = {};

  hourly.forEach(({hour, count}) => {
    const day = dayjs(hour).format('MMMM DD, YYYY');
    daily[day] = daily[day] || [];
    daily[day].push(count);
  });

  let max = 0;
  const downloads = Object.keys(daily)
    .map((day) => {
      const y = daily[day].reduce((a, b) => a + b, 0);
      max = y > max ? y : max;

      return {
        x: dayjs(day).toDate().getTime(),
        downloads: y,
      };
    })
    .map(({x, downloads}) => {
      return {x, downloads: Math.round((downloads * 100) / max)};
    });

  return downloads;
};

const getChartData = ({historical, hourly}) => {
  const downloads = getDailyDownloads(hourly);
  const data = historical.map((obj) => {
    obj.x = dayjs(dayjs(obj.day).format('MMMM DD, YYYY')).toDate().getTime();
    return obj;
  });

  downloads.forEach(({x, downloads}) => {
    const match = data.find(({x: _x}) => x === _x);
    if (match) {
      match.downloads = downloads;
    } else {
      data.push({x, downloads});
    }
  });

  return data;
};

const SERIES = [
  {name: 'downloads', color: BLUE},
  {name: 'init', color: GREEN},
  {name: 'installed', color: PURPLE},
  {name: 'lead', color: RED_ORANGE},
  {name: 'purchase', color: PINK},
];

const LiftReportRequestChart = ({data}) => {
  const [selectedSeries, setSelectedSeries] = useState(SERIES);
  const activeSeriesNames = selectedSeries.map(({name}) => name);
  if (activeSeriesNames.length !== SERIES.length) {
    data = data
      .map((d) => {
        if (Object.keys(d).some((r) => activeSeriesNames.includes(r))) {
          return d;
        }
        return false;
      })
      .filter(Boolean);
  }

  return (
    <div>
      <ResponsiveContainer width='100%' height={300}>
        <BarChart data={data}>
          <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
          <XAxis
            dataKey='x'
            domain={['auto, auto']}
            type='number'
            scale='time'
            padding={{left: 20, right: 20}}
            tickFormatter={(timeStr) => dayjs(timeStr).utc().format('MMM DD')}
          />
          <YAxis tickFormatter={(value) => comma(value)} />
          <Tooltip
            content={({...props}) => (
              <ChartTimeTooltip dateFormat='dddd, MMM DD' {...props} />
            )}
            wrapperStyle={{textTransform: 'capitalize'}}
          />
          {selectedSeries.map(({name, color}) => (
            <Bar key={name + color} dataKey={name} fill={color} />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <Stack justifyContent='center'>
        <ChartLegend
          series={SERIES}
          setSelectedSeries={setSelectedSeries}
          selectedSeries={selectedSeries}
          horizontal
          multipleSelect
        />
      </Stack>
    </div>
  );
};

const CampaignHouseholdsForm = ({
  campaign,
  campaignNoise,
  isLoading,
  networkError,
  organization,
  onSubmit,
}) => {
  const [state, setState] = useState(() => {
    if (campaignNoise) {
      return {
        name: campaignNoise?.name ?? 'Control Households',
        startAt: dayjs(campaignNoise.startAt),
        endAt: dayjs(campaignNoise.endAt),
        targetImpressions: campaignNoise?.targetImpressions ?? campaign.goal,
        usePublisherImpressions: !!campaignNoise?.usePublisherImpressions,
      };
    }

    return {
      name: 'Control Households',
      startAt: dayjs(campaign.startAt).subtract(1, 'month'),
      endAt: dayjs(campaign.endAt).isAfter(dayjs())
        ? dayjs()
        : dayjs(campaign.endAt),
      targetImpressions: campaign.goal,
      usePublisherImpressions: false,
    };
  });

  const hardEnd = dayjs(campaign.endAt).isAfter(dayjs())
    ? dayjs()
    : dayjs(campaign.endAt);

  const hardStart = dayjs(campaign.startAt).subtract(1, 'month');

  return (
    <Query
      query={LiftReportPreCreateCampaignDocument}
      fetchPolicy='no-cache'
      variables={{
        organizationId: organization.id,
        campaignId: campaign.id,
        after: state.startAt.format('YYYY-MM-DD'),
        before: state.endAt.format('YYYY-MM-DD'),
      }}>
      {({loading, error, data}: QueryResult) => {
        if (loading) {
          return <Loading centered />;
        }
        if (error) {
          return <EmptyMessage>No data found.</EmptyMessage>;
        }

        const {
          downloads: {hourly},
          stateOverview: {
            pixel: {historical},
          },
        } = data.me.organization.campaign;

        const loadingInProgress = loading || isLoading;

        return (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await onSubmit(state);
            }}>
            <Stack direction='column' gap={cssSpacing('base')}>
              {networkError && (
                <Banner colorSet='negative'>
                  {FORM_ERRORS.network.message}
                </Banner>
              )}
              <DateRangeInput
                onDatesChange={({endDate, startDate}) => {
                  setState((prev) => ({
                    ...prev,
                    endAt: endDate,
                    startAt: startDate,
                  }));
                }}
                startDate={state.startAt}
                endDate={state.endAt}
                isOutsideRange={(date) => {
                  return !(
                    date.isSameOrAfter(hardStart) &&
                    date.isSameOrBefore(hardEnd)
                  );
                }}
              />

              <Text
                as='div'
                semanticColor={semanticColors.textSubdued}
                variant='bodySmall'>
                <LiftReportRequestChart
                  data={getChartData({historical, hourly})}
                />
              </Text>
            </Stack>
            <FieldSet>
              <Stack direction='column' gap={cssSpacing('tighter')}>
                <Text variant='bodyMediumBold'>Control Household Range</Text>
                <FormGroup label='Group Name' labelFor='name'>
                  <FormInput
                    disabled={loadingInProgress}
                    name='name'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setState((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }));
                    }}
                    placeholder='Noise 1/2029 - 5/2029'
                    type='text'
                    value={state.name}
                  />
                </FormGroup>
                <FormGroup
                  label='Target Impressions'
                  labelFor='targetImpressions'>
                  <FormInput
                    disabled={loadingInProgress}
                    name='targetImpressions'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setState((prev) => ({
                        ...prev,
                        targetImpressions: e.target.value,
                      }));
                    }}
                    placeholder={campaign.goal}
                    step='1'
                    type='number'
                    value={state.targetImpressions}
                  />
                  <Text
                    semanticColor={semanticColors.textSubdued}
                    variant='marginal'>
                    Target number of Households to use in the Control Group.
                  </Text>
                </FormGroup>
                <FormGroup label='Use Publisher Impressions' labelFor='name'>
                  <Text
                    paddingBottom={cssSpacing('base')}
                    semanticColor={semanticColors.textSubdued}
                    variant='marginal'>
                    By Default Ad Analytics uses it's household graph to create
                    a control group. If you wish to only use impressions from{' '}
                    <b>{organization.name}</b> then please select this box.
                    <b>
                      {' '}
                      Note that you must of Ad Analytics' RSS prefix install.
                    </b>
                  </Text>
                  <FormToggle
                    onChange={() => {
                      setState((prev) => ({
                        ...prev,
                        usePublisherImpressions: !state.usePublisherImpressions,
                      }));
                    }}
                  />
                </FormGroup>
              </Stack>
            </FieldSet>
            <Stack justifyContent='end'>
              <ButtonPrimary
                disabled={loadingInProgress}
                size='small'
                type='submit'>
                {loadingInProgress ? (
                  <ProgressCircle
                    aria-label='updating-campaign-noise'
                    size='xsmall'
                  />
                ) : campaignNoise ? (
                  'Update'
                ) : (
                  'Add Noise Range'
                )}
              </ButtonPrimary>
            </Stack>
          </form>
        );
      }}
    </Query>
  );
};

export default CampaignHouseholdsForm;
