import {useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {Redirect} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import {FieldGroup, FieldSet, Form, Input, Legend} from '@core/ui/Form';
import {Label, Submit} from '@core/ui/FormElements';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {campaignLinkURL} from '@analytics/components/nav/CampaignLinkNav';
import CREATE_CAMPAIGN_LINK from '@analytics/graphql-api/_old_mutations/CreateCampaignLink';
import {getPodlinkData} from '@analytics/lib/podlink';
import {CampaignRouteProps, toCampaignUrl} from '../../_routes';

export function CampaignLinkCreate({
  organization,
  campaign,
  match: {
    params: {campaignTargetId},
  },
}: CampaignRouteProps & {
  match: {
    params: {
      campaignTargetId: string;
    };
  };
}) {
  const [complete, setComplete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  const [createCampaignLink] = useMutation(CREATE_CAMPAIGN_LINK, {
    update: (
      cache,
      {
        data: {
          createCampaignLink: {campaignLink},
        },
      }
    ) => {
      cache.modify({
        id: cache.identify(campaign),
        fields: {
          campaignLinks(cached = []) {
            return [...cached, campaignLink];
          },
        },
      });
    },
  });

  const campaignTarget = campaign.campaignTargets.filter(
    ({id}) => id == campaignTargetId
  )[0];
  const {
    feed: {href},
  } = campaignTarget;

  const _onSubmit = async () => {
    if (!name) {
      setErrors(['Name is required']);
    } else {
      setLoading(true);
      try {
        const resp = await createCampaignLink({
          variables: {
            input: {
              campaignId: campaign.id,
              campaignTargetId,
              name,
            },
          },
        });

        const {
          data: {
            createCampaignLink: {campaignLink},
          },
        } = resp;

        setComplete(campaignLink.id);
      } catch (err) {
        setErrors([err]);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const data = await getPodlinkData(href);
      setUrl(data.url);
      setLoading(false);
    })();
  }, [href]);

  if (loading) {
    return <Loading fixed />;
  }

  if (complete !== null) {
    return (
      <Redirect to={campaignLinkURL(organization, campaign, {id: complete})} />
    );
  }

  return (
    <ResponsiveContent containerSize='medium'>
      <Form
        loading={loading}
        onSubmit={_onSubmit}
        errors={errors}
        onClose={() => {
          setErrors([]);
        }}>
        <FieldSet>
          <Legend>Create Link</Legend>
          <FieldGroup rules={() => ({margin: 0})}>
            <Label>Link Name</Label>
            <Input
              type='text'
              name='name'
              defaultValue={name}
              onChange={({target: {value}}) => {
                if (value) {
                  setErrors([]);
                  setName(value);
                }
              }}
              placeholder='Where you are sharing this link.'
              error={errors.length > 0}
              withMargin
            />

            <p>
              Ad Analytics uses{' '}
              <Link href='https://pod.link' target='_blank' rel='noopener'>
                pod.link
              </Link>{' '}
              by default for campaign links. pod.link has built-in support for
              our JavaScript SDK to track actions such as view, click,
              redirects, and attributed downloads. The URL for this podcast is:
            </p>
            <p>
              <Link href={url} target='_blank'>
                {url.replace('https://', '')}
              </Link>
            </p>

            <p>
              Ad Analytics will then append query parameters to map links to
              this campaign. You may skip this landing page altogether and link
              to a specific podcast app.{' '}
            </p>

            <p>
              These pages are customizable. If you wish to customize the
              subdomain, slug, and colors, head to the{' '}
              <Link to={createOrgPath(organization, 'manage/podlink')}>
                pod.link tab in manage
              </Link>
              .
            </p>
          </FieldGroup>
        </FieldSet>

        <Element rules={() => ({margin: '1rem 0', display: 'flex'})}>
          <Element rules={() => ({textAlign: 'right', flex: 1})}>
            <Button to={`${toCampaignUrl(organization, campaign)}/links`}>
              Cancel
            </Button>
            <Submit
              disabled={loading}
              css={`
                margin-left: 1rem;
              `}>
              Create
            </Submit>
          </Element>
        </Element>
      </Form>
    </ResponsiveContent>
  );
}
