import AdvisorPng from './Advisor.png';

export function AdvisorImage() {
  return (
    <img
      alt='advisor'
      src={AdvisorPng}
      css={{
        border: '1px solid var(--border-default)',
        borderRadius: '.375rem',
        marginBottom: '1rem',
        maxWidth: '100%',
        width: '42.5rem',
      }}
    />
  );
}
