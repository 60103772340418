import {comma, financial, toPercent} from '@core/lib/filters';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {SerieData} from '../types';

const columns: DataTableColumn<
  SerieData & {
    id: string;
    name: string;
  }
>[] = [
  {
    accessor: 'name',
    type: 'string',
    Cell: ({d}) => (
      <div css={{fontWeight: 'var(--font-weight-medium)'}}>{d.name}</div>
    ),
  },
  {
    title: 'Lift',
    accessor: 'lift',
    type: 'number',
    fmt: toPercent,
  },
  {
    title: 'Baseline',
    accessor: 'baseline',
    type: 'number',
    fmt: comma,
  },
  {
    title: 'Exposed',
    accessor: 'exposed',
    type: 'number',
    fmt: comma,
  },
  {
    title: 'Gain',
    accessor: 'diff',
    type: 'number',
    fmt: comma,
  },
  {
    title: 'Cost Per Unit',
    accessor: 'cpa',
    type: 'number',
    fmt: financial,
  },
];

export function LiftGroupTable({
  init,
  purchase,
  lead,
  installed,
}: {
  init: SerieData;
  purchase: SerieData;
  lead: SerieData;
  installed: SerieData;
}) {
  return (
    <DataTable
      columns={columns}
      data={[
        {
          ...init,
          ...{
            id: 'views',
            name: 'Views',
          },
        },
        {
          ...purchase,
          ...{
            id: 'purchases',
            name: 'Purchases',
          },
        },
        {
          ...lead,
          ...{
            id: 'leads',
            name: 'Leads',
          },
        },
        {
          ...installed,
          ...{
            id: 'installed',
            name: 'Installs',
          },
        },
      ]}
      orderBy='-name'
      pagination={false}
    />
  );
}
