import { IconChevronDown } from "../Icon/icons/IconChevronDown/index.js";
import styled, { css } from "../../styled-components.js";
import { cssSpacing } from "../../styles/layout-theme.js";
export var Arrow = styled(IconChevronDown).attrs({
  size: 'small',
  'aria-hidden': 'true'
}).withConfig({
  displayName: "Arrow",
  componentId: "sc-104rm6n-0"
})(["margin-inline-start:", ";", ";"], cssSpacing('tighter-4', '4px'), function (props) {
  return props.$expanded && css(["transform:rotate(0.5turn);"]);
});