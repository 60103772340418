import {createOrgPath, isSelling} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {FetchedCampaignLiftReports} from '@analytics/graphql-api';
import {CampaignLiftRouteProps} from '../_routes';

export type CampaignLiftReportRouteProps = Omit<
  CampaignLiftRouteProps,
  'liftReports'
> & {
  liftReport: NonNullable<FetchedCampaignLiftReports[number]>;
};

export const useCampaignLiftReportRoutes = ({
  organization,
  campaign,
  liftReport,
  user,
}: CampaignLiftReportRouteProps) => {
  return useRoutes({
    baseUrl: createOrgPath(
      organization,
      `/campaigns/${campaign.slug}/lift/reports/${liftReport.id}`
    ),
    basePath:
      '/:part(a|s|b|p)/:slug/campaigns/:campaignSlug/lift/reports/:liftReportId',
    routes: [
      {key: 'lift', exact: true, path: ''},
      {
        key: 'delete',
        exact: true,
        isAvailable: isSelling(organization) || user.isAdmin,
      },
      {key: 'run', exact: true, isAvailable: user.isAdmin},
      {key: 'update', exact: true, isAvailable: user.isAdmin},
      {
        key: 'range',
        title: 'Overview',
        path: ':after/:before',
        to: `0/${liftReport.delta}`,
      },
    ],
  });
};
