import {
  Box,
  ProgressCircle,
  TableCell,
  TableHeaderCell,
  Type,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';
import {NotAuthorizedError} from '@core/components/AppError';
import Layout from '@analytics/components/Layout/Layout';
import {SpotifySalesOrdersCard} from '@analytics/components/campaigns/spotify-orders';
import {InternalChip} from '@analytics/components/sales-order-review/SalesOrderIcons';
import {SalesOrderReviewCard} from '@analytics/components/sales-order-review/SalesOrderReviewCard';
import {useOrganizationQuery} from '@analytics/graphql-api';

const HeaderContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin: 24px 0 24px var(--spacing-6);
`;

const SalesOrderScreen = ({user}) => {
  const {data, loading, error} = useOrganizationQuery({
    variables: {
      slug: 'spotify',
    },
    fetchPolicy: 'cache-and-network',
  });

  const organization = data?.me?.organization;

  if (loading) {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <ProgressCircle aria-label='loading' />
      </Box>
    );
  }

  if (error || !organization) {
    return <NotAuthorizedError user={user} />;
  }

  return (
    <>
      <Layout user={user} organization={organization}>
        {/* TODO:SPAA-32 fix type error */}
        <HeaderContainer>
          <Type as='h1' variant='alto'>
            Spotify Sales Orders
          </Type>
          <InternalChip />
        </HeaderContainer>

        <SalesOrderReviewCard />
        <SpotifySalesOrdersCard organization={organization} />
      </Layout>
    </>
  );
};

export default SalesOrderScreen;
