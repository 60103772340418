import {useState} from 'react';
import {
  Dropdown,
  DropdownLink,
  DropdownList,
  DropdownTrigger,
  FormGroup,
} from '@spotify-internal/encore-web';

const OnboardDropdown = ({label, labelFor, items, field}): JSX.Element => {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <FormGroup label={label} labelFor={labelFor}>
      <DropdownTrigger
        overlay={
          show && (
            <DropdownList
              label={labelFor}
              onBlur={() => setShow(false)}
              aria-activedescendant={items[selectedItem].id}>
              {items.map((item, index) => (
                <DropdownLink
                  role='option'
                  key={field.value}
                  defaultValue={field.value}
                  onClick={() =>
                    field.onChange(item.id, setSelectedItem(index))
                  }
                  selected={items[selectedItem].id === item.id}
                  aria-selected={items[selectedItem].id === item.id}>
                  {item.text}
                </DropdownLink>
              ))}
            </DropdownList>
          )
        }
        onShow={() => setShow(true)}
        onHide={() => setShow(false)}>
        <Dropdown
          id='dropdown-toggle'
          aria-labelledby='dropdown-label dropdown-toggle'
          aria-expanded={show || undefined}
          type='button'>
          {items[selectedItem].text}
        </Dropdown>
      </DropdownTrigger>
    </FormGroup>
  );
};

export default OnboardDropdown;
