import styled from 'styled-components';
import {ButtonIcon} from '@core/ui/Button';
import {Icon} from '@core/ui/Icon';

export const SortableItem = styled.div<{
  isSelected: boolean;
  isSortable: boolean;
}>`
  align-items: center;
  background: var(--bg-overlay);
  border-radius: 0.375rem;
  color: ${(div) => (div.isSelected ? 'var(--blue)' : 'inherit')};
  cursor: pointer;
  display: grid;
  gap: 0.375rem;
  grid-template-columns: ${(div) =>
    div.isSortable ? 'max-content 1fr max-content' : '1fr max-content'};
  padding: 0.375rem 0.75rem;
  :hover {
    background: var(--bg-default-hover);
    color: var(--blue);
  }
`;

export const SelectedIcon = styled(Icon).attrs({icon: 'checkmark'})<{
  isSelected: boolean;
}>`
  flex-shrink: 0;
  height: 1.25rem;
  opacity: ${({isSelected}) => (isSelected ? 1 : 0)};
  width: 1.25rem;
`;

export const SortButton = ({isSelected}: {isSelected: boolean}) => (
  <ButtonIcon
    icon='order'
    data-sortable-button
    onClick={(e) => e.stopPropagation()}
    css={`
      color: var(--icon-muted);
      opacity: ${!isSelected ? 0.275 : '100%'};
      padding: 0;
      pointer-events: ${!isSelected ? 'none' : 'auto'};
    `}
  />
);
