const defaultColors: Array<[string, string]> = [
  ['#697fff', '#ff9ada'],
  ['#ff5900', '#ff9ada'],
  ['#697fff', '#00d192'],
  ['#ff5900', '#e6ff00'],
  ['#00d192', '#e6ff00'],
  ['#0061ff', '#60efff'],
  ['#ba42c0', '#deb8f5'],
  ['#01dbff', '#0f971c'],
  ['#ff51eb', '#ede342'],
  ['#a106f4', '#00d192'],
  ['#697fff', '#8752a3'],
  ['#f40752', '#ff9ada'],
  ['#f19e18', '#ff5900'],
  ['#6c35c8', '#ef566a'],
  ['#00d192', '#46b83d'],
  ['#ff9ada', '#ed5c86'],
  ['#ff9ada', '#00458e'],
  ['#01dbff', '#f55c7a'],
  ['#ff5900', '#f90c71'],
  ['#00d192', '#affcaf'],
];

// https://en.wikipedia.org/wiki/Linear_congruential_generator
const stringAsciiPRNG = (value: string, m: number): number => {
  const charCodes = [...value].map((letter) => letter.charCodeAt(0));
  const len = charCodes.length;
  const a = (len % (m - 1)) + 1;
  const c = charCodes.reduce((current, next) => current + next) % m;

  let random = charCodes[0] % m;
  for (let i = 0; i < len; i++) random = (a * random + c) % m;

  return random;
};

/**
 * Get a value based color.
 * The reason for this is we want colors to be consistent for the same value.
 */
export const getRandomGradient = (
  value: string,
  colors: string[][] = defaultColors
): string => {
  if (!value) return 'transparent';
  const idx = stringAsciiPRNG(value, colors.length);
  const [colorA, colorB] = colors[idx > -1 ? idx : 0];
  return `linear-gradient(to bottom, ${colorA}, ${colorB})`;
};
