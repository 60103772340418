import {graphql} from '@apollo/client/react/hoc';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import {CompanyAdNav} from '@analytics/components/nav/ResearchNav';
import CompanyAdQuery from '@analytics/graphql-api/_old_queries/research/CompanyAdQuery';
import AdDownload from './AdDownload';
import AdOverview from './AdOverview';

const AdIndex = ({user, organization, company, data: {loading, me}}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const {ad} = me.organization.research.company;

  return (
    <>
      <CompanyAdNav organization={organization} company={company} ad={ad} />
      <Switch>
        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/research/brands/:companyId/ads/:adId'
          user={user}
          organization={organization}
          company={company}
          ad={ad}
          component={AdOverview}
        />

        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/research/brands/:companyId/ads/:adId/download'
          user={user}
          organization={organization}
          company={company}
          ad={ad}
          component={AdDownload}
        />
      </Switch>
    </>
  );
};

export default graphql(CompanyAdQuery, {
  options: ({
    organization,
    company,
    match: {
      params: {adId},
    },
  }) => {
    return {
      variables: {
        organizationId: organization.id,
        companyId: company.id,
        id: adId,
      },
    };
  },
})(AdIndex);
