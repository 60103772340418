import { arrayReduce } from 'fast-loops';
export default function processStyleWithPlugins(renderer, style, type) {
  var props = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var plugins = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : renderer.plugins;

  if (plugins.length > 0) {
    return arrayReduce(plugins, function (processedStyle, plugin) {
      return plugin(processedStyle, type, renderer, props);
    }, style);
  }

  return style;
}