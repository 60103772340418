import {forwardRef} from 'react';
import LiftStateBadge from '@core/components/LiftStateBadge/LiftStateBadge';
import {DataTableColumn, MultipleSelectFilter} from '@core/ui/DataTable';
import {
  FetchedCampaignLiftReports,
  FetchedIncrementalLiftReports,
} from '@analytics/graphql-api';
import {CampaignsFilter} from './CampaignsFilter';
import {fmtDateRange} from './_helpers';

export const generateColumns = ({
  liftReports,
}: {
  liftReports: FetchedIncrementalLiftReports | FetchedCampaignLiftReports;
}) =>
  [
    {
      accessor: 'name',
      title: 'Survey Name',
      type: 'string',
      Filter: CampaignsFilter,
    },
    {
      accessor: 'clampUrl',
      title: 'URL',
      type: 'string',
      hidden: ![...liftReports].filter(
        (report) => 'clampUrl' in report && !!report.clampUrl
      ).length,
    },
    {
      accessor: 'state',
      title: 'Status',
      type: 'string',
      Cell: ({d}) => <LiftStateBadge state={d.state} />,
      Filter: forwardRef(({...props}, ref) => (
        <MultipleSelectFilter ref={ref} searchable={false} {...props} />
      )),
    },
    {
      accessor: 'campaigns',
      title: 'Campaigns Included',
      type: 'number',
      getValue: ({d}) => d.campaigns.length,
    },
    {
      accessor: 'startDate',
      title: 'Date Range',
      type: 'string',
      getValue: fmtDateRange,
    },
  ] as DataTableColumn<
    FetchedIncrementalLiftReports[number] | FetchedCampaignLiftReports[number]
  >[];
