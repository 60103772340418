import {ChangeEventHandler, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {
  FetchedUser,
  Scalars,
  UnAuthedOrganizationObject,
  UnAuthedUserObject,
} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useQueryParams from '@core/lib/useQueryParams';
import {IconButton} from '@core/ui/Button/LegacyButton';
import {Card} from '@core/ui/Content';
import {SearchInput} from '@core/ui/FormElements';
import {Icon} from '@core/ui/Icon';
import {Tooltip} from '@core/ui/Tooltip';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  useGetSavedSearchLazyQuery,
  useGetSavedSearchesLazyQuery,
} from '@analytics/graphql-api';
import AdvisorColumnsDropdown from './AdvisorColumnsDropdown';
import AdvisorDataTable from './AdvisorDataTable';
import AdvisorFilterDropdown from './AdvisorFilterDropdown';
import AdvisorPreviewPodcastPanel from './AdvisorPreviewPodcastPanel';
import AdvisorSavedSearchDropdown from './AdvisorSavedSearchDropdown';
import AdvisorSavedSearchEditToolbar from './AdvisorSavedSearchEditToolbar';
import AdvisorSortDropdown from './AdvisorSortDropdown';
import {getDefaultSortBy} from './helpers';
import {AdvisorColumn, AdvisorFilterItem, AdvisorSortByItem} from './types';

export type AdvisorWorkspace = {
  data: string;
  id: string;
  name: string;
  organization?: UnAuthedOrganizationObject;
  orgDefault: boolean;
  slug: string;
  user?: UnAuthedUserObject;
  version: number;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ActiveWorkspace = AdvisorWorkspace & {
  discard: number;
};

type AdvisorToolProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
};

export default function AdvisorTool({organization, user}: AdvisorToolProps) {
  const [searchValue, setSearchValue] = useState('');
  const [activeColumns, setActiveColumns] = useState<AdvisorColumn[]>([]);
  const [activeFilters, setActiveFilters] = useState<AdvisorFilterItem[]>([]);
  const [activeSort, setActiveSort] = useState<AdvisorSortByItem[]>(
    getDefaultSortBy({organization})
  );
  const [activeWorkspace, setActiveWorkspace] = useState<
    ActiveWorkspace | undefined
  >();
  const [isEditingWorkspace, setIsEditingWorkspace] = useState(false);

  const workspaceConfig = {
    columns: activeColumns.map(({id}) => id),
    filters: activeFilters,
    sort: activeSort,
  };

  const {
    params: {id: feedId, expanded, workspace: workspaceIdParam},
    remove: removeQueryParam,
    set: setQueryParam,
  } = useQueryParams(['id', 'expanded', 'workspace']) as {
    params: {id: string; workspace: string};
  };
  const [workspaceId, setWorkspaceId] = useState<string | undefined>(
    workspaceIdParam
  );

  const [fetchSavedSearchData] = useGetSavedSearchLazyQuery({
    onCompleted: (data) => {
      const savedSearch = data?.me?.organization?.advisor?.savedSearch;
      if (typeof savedSearch?.data === 'string') {
        setActiveWorkspace({...(savedSearch as AdvisorWorkspace), discard: 0});
        return;
      }

      removeQueryParam('workspace');
      setActiveWorkspace(undefined);
    },
    fetchPolicy: 'network-only',
  });

  const [fetchSavedSearches] = useGetSavedSearchesLazyQuery();

  const onSearchInput: ChangeEventHandler<HTMLInputElement> = ({target}) =>
    setSearchValue(target.value.trim());

  const closeWorkspace = () => {
    removeQueryParam('workspace');
    setWorkspaceId(undefined);
    setActiveWorkspace(undefined);
    if (isEditingWorkspace) setIsEditingWorkspace(false);
  };

  const onChangeInputs = () => {
    if ((activeWorkspace || workspaceId) && !isEditingWorkspace) {
      if (activeWorkspace?.user?.id === user.id) setIsEditingWorkspace(true);
      else closeWorkspace();
    }
  };

  useEffect(() => {
    const onDocumentKeydown = (evt: KeyboardEvent) => {
      if (evt.key !== 'Escape') return;

      if (feedId) removeQueryParam('id');
      else if (expanded) removeQueryParam('expanded');
    };

    document.addEventListener('keydown', onDocumentKeydown);
    return () => document.removeEventListener('keydown', onDocumentKeydown);
  });

  useEffect(() => {
    /**
     * Fetch the active workspace. The active workspace is
     * an AdvisorSavedSearch gql object in order of priority:
     * 1. The one specified by the `workspaceId` query param
     * 2. The active organization's default workspace
     *      (AdvisorSavedSearch.orgDefault == true). There should
     *      only be one of these per organization.
     * 3. Otherwise, there is no active workspace and the default
     *      advisor config is used.
     */
    if (workspaceId) {
      fetchSavedSearchData({
        variables: {id: workspaceId, organizationId: organization.id},
      });
      return;
    }

    if (activeWorkspace) return;

    fetchSavedSearches({variables: {organizationId: organization.id}}).then(
      ({data}) => {
        const defaultWorkspace =
          data?.me?.organization?.advisor?.savedSearches?.find(
            (search) => search?.orgDefault
          );
        if (defaultWorkspace) setWorkspaceId(defaultWorkspace.id);
      }
    );
  }, [fetchSavedSearchData, organization.id, workspaceId]);

  useEffect(() => {
    if (!activeWorkspace) {
      /* Return to default state */
      if (activeFilters.length > 0) setActiveFilters([]);
      if (activeSort.length > 0)
        setActiveSort(getDefaultSortBy({organization}));
      return;
    }

    const {filters, sort} = JSON.parse(activeWorkspace.data);
    setActiveFilters(filters);
    setActiveSort(sort);
  }, [activeWorkspace, setActiveFilters, setActiveSort]);

  return (
    <AdvisorRoot $feedId={feedId} $expanded={expanded as boolean}>
      <Card
        css={`
          display: flex;
          flex-direction: column;
          height: 100%;
          margin: 0;
          min-height: 0;
          padding: 0;
          ${!!feedId &&
          `
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          `}
        `}
        rules={() => ({})}>
        <AdvisorToolbar $feedId={feedId}>
          <AdvisorColumnsDropdown
            activeColumns={activeColumns}
            activeWorkspace={activeWorkspace}
            organization={organization}
            onColumnsChange={({columns, fromUserAction = false}) => {
              if (fromUserAction) onChangeInputs();
              setActiveColumns(columns);
            }}
            user={user}
          />
          <AdvisorFilterDropdown
            activeColumns={activeColumns}
            defaultFilters={activeFilters}
            onChange={({filters, fromUserAction = false}) => {
              if (fromUserAction) onChangeInputs();
              setActiveFilters(filters);
            }}
            organization={organization}
          />
          <AdvisorSortDropdown
            activeColumns={activeColumns}
            defaultSortBy={activeSort}
            onChange={({sortBy, fromUserAction = false}) => {
              if (fromUserAction) onChangeInputs();
              setActiveSort(sortBy);
            }}
          />
          <SearchInput
            onInput={onSearchInput}
            placeholder='Search'
            onCancel={() => setSearchValue('')}
            withCancelIcon
            small
            rules={() => ({
              marginRight: '0.625rem',
            })}
          />
          <AdvisorToolbarButtons>
            {activeWorkspace && activeWorkspace.user?.id === user.id ? (
              <AdvisorSavedSearchEditToolbar
                activeWorkspace={activeWorkspace}
                isEditingWorkspace={isEditingWorkspace}
                onComplete={() => setIsEditingWorkspace(false)}
                onSave={() =>
                  setActiveWorkspace({
                    ...activeWorkspace,
                    data: JSON.stringify(workspaceConfig),
                  })
                }
                onDiscard={() =>
                  setActiveWorkspace({
                    ...activeWorkspace,
                    discard: activeWorkspace.discard + 1,
                  })
                }
                organization={organization}
                workspaceConfig={workspaceConfig}
              />
            ) : (
              <div />
            )}
            <AdvisorSavedSearchDropdown
              activeWorkspace={activeWorkspace}
              organization={organization}
              onCloseWorkspace={closeWorkspace}
              onClickWorkspace={(id: string) => {
                setQueryParam('workspace', id);
                setWorkspaceId(id);
                if (isEditingWorkspace) setIsEditingWorkspace(false);
              }}
              onDeleteWorkspace={(id: string) =>
                workspaceId && workspaceId === id && closeWorkspace()
              }
              user={user}
              workspaceConfig={workspaceConfig}
            />
            <Tooltip
              description={expanded ? 'Contract' : 'Expand'}
              overlayProps={{horizontalAlign: 'center'}}
              rules={() => ({marginLeft: 'auto'})}>
              <IconButton
                onClick={() =>
                  expanded
                    ? removeQueryParam('expanded')
                    : setQueryParam('expanded', 'true')
                }
                rules={() => ({
                  background: 'var(--bg-default)',
                  border: '1px solid var(--border-default)',
                  borderRadius: '0.375rem',
                  color: 'var(--icon-subtle)',
                  height: '2.125rem',
                  padding: '0 0.375rem',
                })}>
                {expanded ? (
                  <Icon
                    icon='contract'
                    rules={() => ({
                      height: '1.25rem',
                      width: '1.25rem',
                    })}
                  />
                ) : (
                  <Icon
                    icon='expand'
                    rules={() => ({
                      height: '1.25rem',
                      width: '1.25rem',
                    })}
                  />
                )}
              </IconButton>
            </Tooltip>
          </AdvisorToolbarButtons>
        </AdvisorToolbar>
        <AdvisorDataTable
          activeColumns={activeColumns}
          activeFilters={activeFilters}
          activeSort={activeSort}
          feedId={feedId}
          organizationId={organization.id}
          searchValue={searchValue}
          user={user}
          onClickRowPath={(d: any) =>
            createOrgPath(
              organization,
              `/advisor/?id=${d.feed.id}${expanded ? `&expanded=true` : ''}${
                workspaceId ? `&workspace=${workspaceId}` : ''
              }`
            )
          }
        />
      </Card>
      {feedId && (
        <Sidebar>
          <AdvisorPreviewPodcastPanel
            key={feedId}
            feedId={feedId}
            user={user}
            onClickClose={() => removeQueryParam('id')}
            organization={organization}
          />
        </Sidebar>
      )}
    </AdvisorRoot>
  );
}

const AdvisorRoot = styled.div<{$feedId?: string; $expanded?: boolean}>`
  display: grid;
  flex: 1;
  grid-template-columns: ${(p) => (p.$feedId ? '1fr 37.5rem' : '1fr')};
  height: 100%;
  min-height: 0;
  ${(p) =>
    p.$expanded &&
    css`
      inset: 0;
      margin: 0;
      position: fixed;
      z-index: 5;
    `}
`;

const AdvisorToolbar = styled.div<{$feedId?: string}>`
  align-items: center;
  border-top-left-radius: 0.5rem;
  background: var(--bg-subtle);
  display: grid;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(4, max-content) 1fr;
  padding: var(--spacing-5);
  ${(p) =>
    !p.$feedId &&
    css`
      bordertoprightradius: 0.5rem;
    `};
`;

const AdvisorToolbarButtons = styled.div`
  display: grid;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(3, max-content);
  justify-self: end;
`;

const Sidebar = styled.div`
  background: var(--bg-overlay);
  border: 1px solid var(--border-default);
  border-bottom-right-radius: 0.5rem;
  border-left: 0;
  border-top-right-radius: 0.5rem;
  box-shadow: var(--shadow-lg);
  min-width: 37.5rem;
  overflow-y: auto;
  padding: 1.5rem;
  z-index: 1;
`;
