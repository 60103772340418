import {IQuestionProps} from '../types';
import {QuestionAudienceName} from './_QuestionAudienceName';
import {QuestionBudget} from './_QuestionBudget';
import {QuestionCompetingBrands} from './_QuestionCompetingBrands';
import {QuestionContentMaturity} from './_QuestionContentMaturity';
import {QuestionGenres} from './_QuestionGenresGood';
import {QuestionProductAvailability} from './_QuestionProductAvailability';
import {QuestionProductsServices} from './_QuestionProductsServices';
import {QuestionSpecificMarkets} from './_QuestionSpecificMarkets';

export function Questions(props: IQuestionProps) {
  return (
    <>
      <QuestionAudienceName {...props} />
      <QuestionProductsServices {...props} />
      <QuestionProductAvailability {...props} />
      {props.quizData.availability === 'specific_markets' && (
        <QuestionSpecificMarkets {...props} />
      )}
      <QuestionContentMaturity {...props} />
      <QuestionCompetingBrands {...props} />
      <QuestionGenres {...props} />
      <QuestionBudget {...props} />
    </>
  );
}
