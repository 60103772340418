import {Redirect, Switch} from 'react-router-dom';
import styled from 'styled-components';
import {SecondaryNav} from '@core/ui/SecondaryNav';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignRouteProps, toCampaignUrl} from '../../_routes';
import {PixelOverview} from './PixelOverview';
import {useCampaignPixelRoutes} from './_routes';
import {CampaignCheckoutsIndex} from './checkouts';
import {CampaignHouseholdsIndex} from './households';
import {CampaignLeadsIndex} from './leads';
import {CampaignReferrersIndex} from './referrers';
import {CampaignUrlsIndex} from './urls';

export function CampaignPixelIndex({
  user,
  organization,
  campaign,
  match: {
    params: {key},
  },
}: CampaignRouteProps & {
  match: {
    params: {key: string};
  };
}) {
  const {renderRoutes, routes} = useCampaignPixelRoutes({
    campaign,
    organization,
    pixelKey: key,
  });

  const matchPixel = (campaign.pixels ?? []).find((k) => k?.key === key);

  if (!matchPixel) {
    return <Redirect to={toCampaignUrl(organization, campaign)} />;
  }

  return (
    <>
      <CampaignNav
        user={user}
        organization={organization}
        campaign={campaign}
      />
      <Layout>
        <SecondaryNav items={routes.filter(({key}) => key !== 'households')} />
        <div>
          <Switch>
            {renderRoutes({
              components: {
                overview: PixelOverview,
                referrers: CampaignReferrersIndex,
                leads: CampaignLeadsIndex,
                checkouts: CampaignCheckoutsIndex,
                urls: CampaignUrlsIndex,
                households: CampaignHouseholdsIndex,
              },
              extraProps: {
                campaign,
                organization,
                pixel: matchPixel,
                user,
              },
            })}
          </Switch>
        </div>
      </Layout>
    </>
  );
}

const Layout = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: 11.5rem 1fr;
  margin: 0 0 0 1.5rem;
`;
