import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import AdvisorOptionsPodcasts from '@analytics/components/advisor/AdvisorOptionsPodcasts';
import AdvisorOnboardPublisherSteps from '@analytics/components/advisor/onboarding/publisher/AdvisorOnboardPublisherSteps';
import {AdvisorRouteProps} from '../../_routes';

export function AdvisorOnboardPublisherPodcasts({
  organization,
}: AdvisorRouteProps) {
  const history = useHistory();
  return (
    <>
      <AdvisorOnboardPublisherSteps active='podcasts' />
      <AdvisorOptionsPodcasts
        organization={organization}
        onSave={() => {
          history.push(
            `${createOrgPath(
              organization
            )}/advisor/onboard/publisher/capabilities`
          );
        }}
        onCancel={() => {
          history.push(
            `${createOrgPath(organization)}/advisor/onboard/publisher`
          );
        }}
      />
    </>
  );
}
