import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Card, CardHeader} from '@core/ui/Content';
import {FORM_ERRORS, Form, Submit} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {
  CampaignLiftReportsDocument,
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedCampaignLiftReports,
  FetchedIncrementalLiftReports,
  FetchedOrganization,
  LiftReportsDocument,
  useDeleteLiftReportMutation,
} from '@analytics/graphql-api';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';

type Props = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  liftReport:
    | NonNullable<FetchedIncrementalLiftReports[number]>
    | NonNullable<FetchedCampaignLiftReports[number]>;
  campaign?: FetchedCampaign;
};

export function LiftReportDeleteForm({
  organization,
  liftReport,
  campaign,
}: Props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [deleteLiftReport] = useDeleteLiftReportMutation();

  const returnUrl = campaign
    ? `${toCampaignUrl(organization, campaign)}/lift`
    : createOrgPath(organization, 'measure/lift');

  const {
    clearErrors,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm({
    defaultValues: {
      networkError: false,
    },
  });

  const onSubmit = async () => {
    clearErrors('networkError');
    setLoading(true);
    try {
      await deleteLiftReport({
        variables: {
          input: {
            liftReportId: liftReport.id,
          },
        },
        refetchQueries: [CampaignLiftReportsDocument, LiftReportsDocument],
      });

      history.push(returnUrl);
    } catch (err) {
      setError('networkError', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={`${returnUrl}/reports/${liftReport.id}`}>
        Delete Lift Report
      </CardHeader>
      <Card>
        <p>This action is permanent. Are you sure you would like to proceed?</p>
        <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
          <Stack justifyContent='flex-end'>
            <Submit color='error' loading={loading}>
              Delete Lift Report
            </Submit>
          </Stack>
        </Form>
      </Card>
    </ResponsiveContent>
  );
}
