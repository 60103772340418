import React from 'react';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {isBuying} from '@core/lib/organizations';
import DataTable from '@core/ui/DataTable';

const CampaignUpcomingEpisodesTable = ({
  url,
  organization,
  futureEpisodes,
  downloadGoal,
}) => {
  const columns = [
    {
      title: 'Episode',
      accessor: 'title',
      type: 'string',
    },
    {
      title: 'Air date',
      accessor: 'expectedPublished',
      type: 'date',
      fmt: (v) => dayjs(v).utc().format('MMM DD, YYYY'),
    },
    {
      title: 'Download Goal',
      accessor: 'downloadGoal',
      type: 'number',
      fmt: comma,
    },
  ];

  const data = futureEpisodes.map(({id, futureEpisode}) => ({
    id,
    expectedPublished: futureEpisode.expectedPublished,
    title: futureEpisode.title,
    downloadGoal,
  }));

  return (
    <DataTable
      data={data}
      columns={columns}
      orderBy='expectedPublished'
      emptyMessageText='No upcoming episodes.'
      onClickRowPath={({id}) => {
        if (isBuying(organization)) {
          return null;
        }
        return `${url}/futures/${id}`;
      }}
    />
  );
};

export default CampaignUpcomingEpisodesTable;
