export function CategoriesHeader() {
  return (
    <>
      <div
        css={{
          background: 'var(--bg-muted)',
          height: '0.125rem',
          margin: '0.375rem 0',
        }}
      />
      <div
        css={{
          color: 'var(--text-muted)',
          fontSize: '0.6875rem',
          fontWeight: '500',
          letterSpacing: '.5px',
          margin: '0.375rem 1rem 0.125rem',
          textTransform: 'uppercase',
        }}>
        Categories
      </div>
    </>
  );
}
