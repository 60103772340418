import {isSelling} from '@core/lib/organizations';
import {MenuList} from '@core/ui/MenuList';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {CampaignDraftProps} from '..';

export function parseApprovalsStatusDisplay({requested, rejected}) {
  const totalRequested = requested.length;
  const totalRejected = rejected.length;
  return {
    success: !totalRequested && !totalRejected,
    statusText:
      totalRequested || (totalRejected ? `${totalRejected} rejected` : '0'),
    statusDetails: totalRequested
      ? 'You have been requested to approve this campaign'
      : totalRejected
      ? 'The Ad Analytics team is reviewing your report. Please check back later.'
      : undefined,
  };
}

export function parsePrefixStatusDisplay({
  campaign,
  organization,
}: CampaignDraftProps) {
  const missingPrefixes = (campaign.campaignPodcasts ?? []).reduce(
    (_missing, item) => [
      ..._missing,
      ...(!item?.hasPodsightsPrefix
        ? [
            {
              ...item,
              title: item?.name ?? '',
              to: `${toCampaignUrl(organization, campaign)}/podcasts/${
                item?.id
              }`,
            },
          ]
        : []),
    ],
    []
  );

  const success = !missingPrefixes.length;

  return {
    success,
    statusText: !success
      ? 'The following podcasts do not have the Ad Analytics Prefix installed:'
      : undefined,
    statusDetails: !success ? <MenuList items={missingPrefixes} /> : undefined,
  };
}

export function parseTrackingStatusDisplay({
  campaign: {campaignDynamics, campaignStreamings},
  organization,
}: CampaignDraftProps) {
  const missingConfirmations = [
    ...campaignDynamics.filter(({deliveries}) => deliveries.length < 1),
    ...campaignStreamings.filter(({deliveries}) => deliveries.length < 1),
  ].length;

  return {
    success: !missingConfirmations,
    statusText: missingConfirmations
      ? `${missingConfirmations} unconfirmed`
      : 'Yes',
    statusDetails: isSelling(organization)
      ? `Confirm that you added the tracking urls to the dynamic or streaming insertion from the provider.`
      : `The publisher has ${
          missingConfirmations ? 'not' : ''
        } confirmed that they added the tracking urls to the dynamic or streaming insertion from the provider.`,
  };
}
