import { cssSpacing } from "../../styles/index.js";
import { Input } from "../FormInput/Input.js";
import styled, { css } from "../../styled-components.js";
/* static icon size plus spacing left and right */
var iconPadding = "calc( 24px + ".concat(cssSpacing('tighter', '12px'), " * 2)");
export var InputContainer = styled.div.withConfig({
  displayName: "InputContainer",
  componentId: "sc-a5ofs0-0"
})(["position:relative;width:100%;", ""], function (_ref) {
  var $iconLeading = _ref.$iconLeading,
    $iconTrailing = _ref.$iconTrailing;
  return ($iconLeading || $iconTrailing) && css(["", "{", " ", " [dir='rtl'] &{padding-left:", ";padding-right:", ";}@media not all and (resolution >= 0.001dpcm){@supports (-webkit-appearance:none){", " ", " [dir='rtl'] &{padding-left:", ";padding-right:", ";}}}", "}"], Input, $iconLeading && "padding-left: ".concat(iconPadding, ";"), $iconTrailing && "padding-right: ".concat(iconPadding, ";"), $iconTrailing ? iconPadding : '14px', $iconLeading ? iconPadding : '14px', $iconLeading && "padding-left: 38px;", $iconTrailing && "padding-right: 38px;", $iconTrailing ? '38px' : iconPadding, $iconLeading ? '38px' : iconPadding, $iconTrailing && css(["&::-ms-clear{display:none;}"]));
});