import {FC} from 'react';
import {
  ChartTimeTooltip,
  ChartTimeTooltipProps,
} from '@core/components/ChartTimeTooltip';
import {CampaignOmniChartInterval} from './helpers';

export const CampaignOmniChartTooltip: FC<
  ChartTimeTooltipProps & {
    episodes: any;
    interval: CampaignOmniChartInterval;
  }
> = ({episodes, interval, payload}): JSX.Element | null => {
  if (!payload || payload.length === 0) {
    return null;
  }
  const {x} = payload[0].payload;
  const episodesList = episodes.filter(
    (episode: {x: number}) => episode.x === x
  );

  return (
    <ChartTimeTooltip
      label={x}
      payload={payload}
      exclude={['episodes']}
      dateFormat={interval === 'hourly' ? "MMM DD, 'YY · HH:00" : "MMM DD, 'YY"}
      valueToFixed={2}>
      {episodesList && episodesList.length ? (
        <div
          css={{
            borderTop: '1px solid var(--bg-muted)',
            marginTop: '.5rem',
            paddingTop: '.5rem',
          }}>
          <div
            css={{
              fontSize: '0.875rem',
              marginBottom: '0.25rem',
              textTransform: 'capitalize',
            }}>
            Episodes launched:
          </div>
          {episodesList.map((episode) => {
            return (
              <div
                key={episode.id}
                css={{
                  alignItems: 'start',
                  display: 'flex',
                  marginTop: '0.75rem',
                  maxWidth: '17.5rem',
                  width: '17.5rem',
                }}>
                <img
                  src={episode.feed.image.href}
                  onError={(evt) =>
                    ((evt.target as HTMLImageElement).style.display = 'none')
                  }
                  alt={episode.feed.title}
                  css={{
                    borderRadius: '0.375rem',
                    height: 'auto',
                    marginRight: '0.625rem',
                    width: '4rem',
                  }}
                />
                {episode.episode.title}
              </div>
            );
          })}
        </div>
      ) : null}
    </ChartTimeTooltip>
  );
};
