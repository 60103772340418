import {Controller} from 'react-hook-form';
import {t} from '@core/texts';
import {Stack} from '@core/ui/Layout';
import {Switch} from '@core/ui/Switch';
import {Text} from '@core/ui/Text';
import {FieldProps} from '../helpers';
import {Label} from '../shared';

export function ModelledField({control}: FieldProps) {
  const {title, longDescription} = t('settings.data.modelled');
  return (
    <Stack direction='column' spacing={1}>
      <Controller
        control={control}
        name='exportParameters.modelled'
        render={({field}) => (
          <Stack alignItems='center' direction='row' spacing={2}>
            <Label htmlFor='modelled'>
              <Switch
                id='modelled'
                defaultChecked={field.value ?? true}
                onChange={field.onChange}
              />
              {title}
            </Label>
          </Stack>
        )}
      />
      <Text variant='body2' color='muted'>
        {longDescription}
      </Text>
    </Stack>
  );
}
