import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {TRules} from '@core/style';
import {Element} from '@core/style';
import Button, {ButtonContainer} from '@core/ui/Button/LegacyButton';
import {ModalHeader} from '@core/ui/Content';
import {FORM_ERRORS, Form, LabelInput, Submit} from '@core/ui/FormElements';
import {Overlay} from '@core/ui/Overlay';
import Toggle from '@core/ui/Toggle';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  useCreateAdvisorSavedSearchMutation,
} from '@analytics/graphql-api';
import ADVISOR_SAVED_SEARCHES_QUERY from '@analytics/graphql-api/_old_queries/advisor/AdvisorSavedSearchesQuery';
import {AdvisorFilterItem, AdvisorSortByItem} from './types';

const AdvisorSavedSearchFormOverlay = ({
  onSave,
  opened,
  organization,
  rules,
  toggle,
  workspaceConfig,
  ...props
}: {
  onSave?: (id: string) => void;
  opened: boolean;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  rules?: TRules;
  toggle: (to?: boolean) => void;
  workspaceConfig: {
    columns: string[];
    filters: AdvisorFilterItem[];
    sort: AdvisorSortByItem[];
  };
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [createSavedSearch] = useCreateAdvisorSavedSearchMutation({
    refetchQueries: [ADVISOR_SAVED_SEARCHES_QUERY],
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const {name, shareWithOrg} = data;

      const resp = await createSavedSearch({
        variables: {
          input: {
            name,
            version: 1,
            data: JSON.stringify(workspaceConfig),
            ...(shareWithOrg && {
              organizationId: organization.id,
            }),
          },
        },
      });

      const id = resp.data?.createAdvisorSavedSearch?.advisorSavedSearch?.id;
      id && onSave?.(id);
      toggle(false);
    } catch (err) {
      console.warn(err);
      setError(null, FORM_ERRORS.network);
    }
    setLoading(false);
  };

  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      centered
      withBackdrop
      withShadow
      animation='opacity'
      css={`
        padding: 1.5rem;
        min-width: 30rem;
      `}
      rules={rules}
      {...props}>
      <ModalHeader
        toggle={toggle}
        css={`
          margin-bottom: 2rem;
        `}>
        New workspace
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
        <Controller
          name='name'
          control={control}
          rules={{required: 'Please provide a name.'}}
          render={({field}) => (
            <LabelInput label='Workspace name' autofocus {...field} />
          )}
        />
        <Element
          rules={() => ({
            alignItems: 'center',
            display: 'flex',
            marginTop: '1.75rem',
          })}>
          <Element
            rules={({theme}) => ({
              color: theme.textTertiary,
              fontSize: '0.875rem',
              fontWeight: 500,
              marginRight: '1.5rem',
            })}>
            Share with members of your organization
          </Element>
          <Controller
            name='shareWithOrg'
            control={control}
            render={({field}) => (
              <Toggle
                defaultChecked={false}
                onChecked={(checked) => field.onChange(checked)}
              />
            )}
          />
        </Element>
        <ButtonContainer style-alignRight rules={() => ({marginTop: '2rem'})}>
          <Button style-default style-outline onClick={() => toggle(false)}>
            Cancel
          </Button>
          <Submit loading={loading}>Save</Submit>
        </ButtonContainer>
      </Form>
    </Overlay>
  );
};

export default AdvisorSavedSearchFormOverlay;
