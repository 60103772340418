import {AdvisorColumn} from '../types';

export function fromResponse({
  columnGroupName,
  data,
}: {
  columnGroupName?: string;
  data: any;
}) {
  return (
    columnGroupName
      ? data?.me.organization.advisor.columnGroups[columnGroupName].columns
      : []
  ) satisfies AdvisorColumn[];
}
export type ColumnGroupName =
  | 'general'
  | 'tags'
  | 'contentMaturity'
  | 'brands'
  | 'industries'
  | 'nielsen'
  | 'experian'
  | 'publishers'
  | 'radioMarkets'
  | 'overlap'
  | 'feedGrowth';

export function columnGroupVariables(
  organizationId: string,
  queryImmediately: boolean,
  searchValue: string,
  fields: Partial<Record<ColumnGroupName, boolean>>
) {
  return {
    query: queryImmediately ? null : searchValue,
    organizationId,
    tags: fields.tags ?? false,
    contentMaturity: fields.contentMaturity ?? false,
    brands: fields.brands ?? false,
    industries: fields.industries ?? false,
    nielsen: fields.nielsen ?? false,
    experian: fields.experian ?? false,
    clearbit: false,
    publishers: fields.publishers ?? false,
    radioMarkets: fields.radioMarkets ?? false,
    overlap: fields.overlap ?? false,
    feedGrowth: fields.feedGrowth ?? false,
  } as const;
}
