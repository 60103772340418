import React, {useState} from 'react';
import {copyToClipboard} from '@core/lib/utils';
import {GREEN} from '@core/style';
import {ToastMessage} from '@core/ui/Alert';
import {IconButton} from '@core/ui/Button/LegacyButton';
import {CodeBlock} from '@core/ui/CodeBlock';
import {LabelInputCopy} from '@core/ui/Copy';
import {Icon} from '@core/ui/Icon';
import {pixelInstallationFields} from './helpers';

interface IJsDisplayProps {
  event: {
    eventType: string;
    name: string;
    [key: string]: string;
  };
  focusLine: number;
  pixel: {
    alias: string;
    key: string;
  };
}

const JsDisplay = ({event, focusLine, pixel}: IJsDisplayProps): JSX.Element => {
  const [copied, setCopied] = useState<boolean>(false);
  const options = (pixelInstallationFields[event.eventType] || []).map(
    ({name, value}) =>
      `${value}: '${event[name]}',  ${
        value !== 'currency' ? '// Dynamically populate from session data' : ''
      }`
  );

  const codeString = `<script>
  // Installation script generated by Ad Analytics
  (function(w, d){
    var id='spdt-capture', n='script';
    if (!d.getElementById(id)) {
      w.spdt =
        w.spdt ||
        function() {
          (w.spdt.q = w.spdt.q || []).push(arguments);
        };
      var e = d.createElement(n); e.id = id; e.async=1;
      e.src = 'https://pixel.byspotify.com/ping.min.js';
      var s = d.getElementsByTagName(n)[0];
      s.parentNode.insertBefore(e, s);
    }
    w.spdt('conf', { key: '${pixel.key}' });${
    pixel.alias &&
    `\n    // Dynamically populate alias from session data\n    w.spdt('alias', { id: '${pixel.alias}' });`
  }
    w.spdt('${
      event.eventType === 'PAGE_VIEW' ? 'view' : event.eventType.toLowerCase()
    }'${
    !!options.length
      ? `, {${
          event.eventType === 'LEAD' ? `\n      category: '${event.name}',` : ''
        }
      ${options.join('\n      ')}
    }`
      : ''
  });
  })(window, document);
</script>`;

  const onClickCopy = () => {
    if (!copied) {
      copyToClipboard(codeString);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  return (
    <>
      <div css={{position: 'relative'}}>
        <CodeBlock
          highlight
          lineNumbers
          lineHighlight={focusLine}
          codeString={codeString}
        />
        <IconButton
          type='button'
          onClick={onClickCopy}
          rules={() => ({
            cursor: copied ? 'default' : 'pointer',
            pointerEvents: copied ? 'none' : null,
            position: 'absolute',
            top: '1rem',
            right: '1rem',
          })}>
          {copied ? (
            <Icon
              icon='checkbox-marked-circle'
              rules={() => ({color: GREEN})}
            />
          ) : (
            <Icon icon='copy' />
          )}
        </IconButton>
      </div>
    </>
  );
};

const MobileDisplay = ({
  pixel,
  children,
}: {
  pixel: {
    key: string;
  };
  children?: React.ReactNode[];
}): JSX.Element => (
  <div
    css={{
      background: 'var(--bg-muted)',
      borderRadius: '.5rem',
      display: 'grid',
      gap: '1rem',
      gridTemplateColumns: 'repeat(auto, 1fr)',
      padding: '1rem',
    }}>
    <LabelInputCopy label='Ad Analytics Key' text={pixel.key} />
    {children}
  </div>
);

const installationTypes: {
  [key: string]: {
    description: string;
    docs: string;
    Display: React.FC<any>;
  };
} = {
  JAVASCRIPT: {
    description: `To install this event on your site, please copy and paste the following code before the closing </body> tag in your html document. If you're using a tag managers, please check out our docs for additional guidance.`,
    docs: 'https://help.adanalytics.spotify.com/install-the-spotify-pixel',
    Display: JsDisplay,
  },
  APPSFLYER: {
    description:
      'From your AppsFlyer dashboard, add the Ad Analytics integration and enable the “Active Partner” toggle. Enter the values below to setup this event.',
    docs: 'https://podsights.com/docs/mobile-integration/#appsflyer',
    Display: ({
      pixel,
      event,
    }: {
      pixel: {
        key: string;
      };
      event: {
        eventType: string;
      };
    }): JSX.Element => (
      <MobileDisplay pixel={pixel}>
        <LabelInputCopy label='Sending Option' text='All media' />
        {(event.eventType === 'LEAD' || event.eventType === 'PURCHASE') && (
          <>
            <LabelInputCopy
              label='In-App Events Settings (optional)'
              text={pixel.key}
            />
            <LabelInputCopy label='In-App Events Postback' text='True' />
          </>
        )}
      </MobileDisplay>
    ),
  },
  BRANCH: {
    description: `From your Branch dashboard, locate Data Feeds -> Data Integrations Tab -> Search for Ad Analytics on the Branch dashboard -> Enter the key provided below and enable the integration.`,
    docs: 'https://help.branch.io/using-branch/docs/data-integration-implementation-guide',
    Display: MobileDisplay,
  },
  ADJUST: {
    description:
      'Follow the steps outlined in the Adjust documentation. You will need the Ad Analytics key provided below.',
    docs: 'https://help.adjust.com/en/integrated-partners/podsights',
    Display: MobileDisplay,
  },
  KOCHAVA: {
    description:
      'Follow the steps outlined in the Kochava documentation. You will need the Ad Analytics key provided below.',
    docs: 'https://support.kochava.com/campaign-management/create-a-kochava-certified-postback/',
    Display: ({
      pixel,
    }: {
      pixel: {
        key: string;
      };
    }): JSX.Element => (
      <MobileDisplay pixel={pixel}>
        <LabelInputCopy label='Delivery Delay' text='Realtime Delivery' />
        <LabelInputCopy label='Retry Attempts' text='3 Attempts' />
      </MobileDisplay>
    ),
  },
};

interface IPixelEventInstallationProps {
  event: {
    eventType: string;
    name: string;
    installation: string;
  };
  focusLine: number;
  pixel: {
    key: string;
    alias: string;
  };
}

const PixelEventInstallation = ({
  event,
  focusLine,
  pixel,
}: IPixelEventInstallationProps): JSX.Element => {
  const {description, docs, Display} = installationTypes[event.installation];
  return (
    <>
      <ToastMessage
        alertType='info'
        rules={() => ({
          margin: '0 0 1rem',
          '& svg': {
            marginBottom: '1.25rem',
          },
        })}>
        <div>{description}</div>
        <a
          href={docs}
          css={{
            color: 'var(--blue)',
            display: 'block',
            marginTop: '0.25rem',
            textDecoration: 'none',
            ':hover': {
              textDecoration: 'underline',
            },
          }}
          target='_blank'
          rel='noreferrer'>
          Learn More
        </a>
      </ToastMessage>
      <Display pixel={pixel} event={event} focusLine={focusLine} />
    </>
  );
};

export default PixelEventInstallation;
