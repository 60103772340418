import {gql} from '@apollo/client';

export default gql`
  query getEpisodeHourlyDownloadsQuery(
    $organizationId: ID!
    $podcastId: ID!
    $id: ID!
    $algorithm: String
    $after: String
    $before: String
    $kind: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        podcast(id: $podcastId) {
          id
          feed {
            id
            episode(id: $id) {
              id
              downloads(
                algorithm: $algorithm
                before: $before
                after: $after
                kind: $kind
              ) {
                count
                hourly {
                  hour
                  count
                  downloadClass
                }
              }
            }
          }
        }
      }
    }
  }
`;
