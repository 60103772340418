import ImpressionsProgressBar from '@core/components/ImpressionsProgressBar/ImpressionsProgressBar';
import {comma, toCampaignCurrency, toFixed, toPercent} from '@core/lib/filters';
import {t} from '@core/texts';
import {DataTableColumn} from '@core/ui/DataTable';
import {Icon} from '@core/ui/Icon';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';

export const spotifyAdsTableColumns = ({superCampaign, settings}) => {
  const fmtCurrency = toCampaignCurrency(superCampaign.currency);
  const isMegaphone = superCampaign.isMegaphone;
  return [
    {
      title: 'Line Item',
      accessor: 'name',
      type: 'string',
      Cell: ({d: {name}}) => (
        <Text variant='subtitle2' css={{minWidth: '12.5rem'}}>
          {name}
        </Text>
      ),
    },
    {
      title: 'Ad Type',
      accessor: 'format',
      type: 'string',
      Cell: ({d: {format}}) => (
        <div css={{textTransform: 'capitalize', whiteSpace: 'nowrap'}}>
          {format}
        </div>
      ),
    },
    {
      title: 'Format',
      accessor: 'spotifyAdFormat',
      type: 'string',
      getValue: ({d: {spotifyAdFormat}}) =>
        spotifyAdFormat !== '_unknown_' ? spotifyAdFormat ?? '-' : '-',
    },
    {
      ...t('stats.impressions'),
      accessor: 'downloadTotal',
      type: 'number',
      Cell: ({d}) => <ImpressionsProgressBar {...d} />,
    },
    {
      title: (
        <Stack alignItems='center' gap='var(--spacing-1)'>
          <span>Reach</span>
          <Icon icon='spotify' css={{height: '0.875rem', width: '0.875rem'}} />
        </Stack>
      ),
      accessor: 'verifiedReach',
      type: 'number',
      fmt: comma,
    },
    {
      title: (
        <Stack alignItems='center' gap='var(--spacing-1)'>
          <span>Frequency</span>
          <Icon icon='spotify' css={{height: '0.875rem', width: '0.875rem'}} />
        </Stack>
      ),
      hidden: isMegaphone,
      accessor: 'verifiedFrequency',
      type: 'number',
      getValue: ({d: {downloadTotal, verifiedReach}}) =>
        verifiedReach ? toFixed(downloadTotal / verifiedReach) : 0,
    },
    {
      ...t('stats.reach'),
      accessor: 'reachTotal',
      type: 'number',
      fmt: comma,
    },
    {
      ...t('stats.frequency'),
      accessor: 'frequency',
      type: 'number',
      fmt: toFixed,
    },
    {
      ...t('stats.spend', settings),
      accessor: 'costToDate',
      type: 'number',
      fmt: fmtCurrency,
    },
    {
      ...t('stats.cr'),
      accessor: 'cr',
      type: 'number',
      fmt: toPercent,
    },
  ] as DataTableColumn[];
};
