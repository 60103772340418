import {gql} from '@apollo/client';

export default gql`
  fragment CampaignPodcast on CampaignPodcastObject {
    id
    name
    isDynamic
    podcast {
      id
    }

    feed {
      id
      title
      href
      description(words: 50)
      image {
        href
        id
        path
      }
    }

    drops
    campaignEpisodesCount

    isPreRoll
    isMidRoll
    isPostRoll

    discountCode
    discountUrl
    effectiveUrl

    duration
    goal
    cost
    costToDate

    downloadTotal
    reachTotal
    attributionTotal
    userTotal
    userTotalModeled
    productTotal
    productTotalModeled
    addtocartTotal
    addtocartTotalModeled
    checkoutTotal
    checkoutTotalModeled
    purchaseTotal
    purchaseTotalModeled
    leadTotal
    leadTotalModeled
    valueTotal
    valueTotalModeled
    installedTotal
    installedTotalModeled

    targetDownloadTotal
    targetReachTotal

    startAt
    endAt

    hasPodsightsPrefix

    createdAt
    updatedAt
  }
`;
