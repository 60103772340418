import {gql} from '@apollo/client';

export default gql`
  query getBrandCampaignHousehold(
    $organizationId: ID!
    $campaignId: ID!
    $id: ID!
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          attribution {
            households {
              household(id: $id) {
                events {
                  count
                  nodes {
                    latitude
                    longitude
                    country
                    region
                    city
                    time
                    action
                    referrer
                    referrerDomain

                    url
                    urlPath

                    userAgent {
                      os {
                        family
                      }
                      device {
                        family
                      }
                    }

                    value
                    currency
                    product
                    discountCode
                    orderId
                    quantity
                    lineItems
                    leadType
                    leadCategory

                    alias
                  }
                }

                downloads {
                  count

                  nodes {
                    campaignId
                    campaignDynamicId
                    source
                    kind
                    time
                    attribution

                    userAgent {
                      os {
                        family
                      }
                      device {
                        family
                      }
                    }

                    country
                    region
                    city

                    longitude
                    latitude

                    feed {
                      title
                    }
                    episode {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
