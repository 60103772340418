import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Controller, useForm} from 'react-hook-form';
import dayjs from '@core/lib/dayjs';
import {Element} from '@core/style';
import Button from '@core/ui/Button/LegacyButton';
import DatePicker from '@core/ui/DatePicker';
import {FORM_ERRORS, Form, Submit} from '@core/ui/FormElements';
import {Overlay} from '@core/ui/Overlay';
import CREATE_FUTURE_EPISODE_MUTATION from '@analytics/graphql-api/_old_mutations/CreateFutureEpisode';
import PODCASTER_CAMPAIGN_FUTURE_EPISODES_NODES from '@analytics/graphql-api/_old_queries/PodcasterCampaignFutureEpisodesNodesQuery';

const BulkEpisodesNewEpisodeOverlay = ({
  campaign,
  campaignPodcast,
  onCreatedEpisode,
  opened,
  organization,
  toggle,
}) => {
  const [loading, setLoading] = useState(false);
  const [createFutureEpisode] = useMutation(CREATE_FUTURE_EPISODE_MUTATION);
  const {
    control,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();

  const onSubmit = async ({selectedDates}) => {
    setLoading(true);
    try {
      for (const date of selectedDates) {
        const newEpisode = await createFutureEpisode({
          variables: {
            input: {
              organizationId: organization.id,
              campaignId: campaign.id,
              podcastId: campaignPodcast.podcast.id,
              expectedPublished: date,
              title: `Episode (${dayjs(date).format('MMM D, YYYY')})`,
              subtitle: '',
            },
          },
          refetchQueries: [PODCASTER_CAMPAIGN_FUTURE_EPISODES_NODES],
        });

        if (typeof onCreatedEpisode === 'function') {
          onCreatedEpisode(newEpisode.data.createFutureEpisode.futureEpisode);
          toggle(false);
        }
      }
    } catch (err) {
      setError(null, FORM_ERRORS.network);
    }
    setLoading(false);
  };

  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      withShadow
      withBackdrop
      centered
      css={`
        padding: 1.5rem;
        max-width: 24rem;
      `}>
      <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
        <Controller
          name='selectedDates'
          control={control}
          rules={{required: 'Please select a date.'}}
          render={({field}) => (
            <DatePicker
              mode='days'
              isOutsideRange={() => false}
              onDatesChange={(dates) =>
                field.onChange(
                  dates.length
                    ? dates.map((d) => d.utc().format('YYYY-MM-DD'))
                    : null
                )
              }
            />
          )}
        />
        <Element
          rules={() => ({
            display: 'grid',
            gridGap: '0.625rem',
            gridTemplateColumns: 'max-content max-content',
            justifyContent: 'flex-end',
            marginTop: '2rem',
          })}>
          <Button onClick={() => toggle(false)}>Cancel</Button>
          <Submit loading={loading}>Save Episodes</Submit>
        </Element>
      </Form>
    </Overlay>
  );
};

export default BulkEpisodesNewEpisodeOverlay;
