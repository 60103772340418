import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import FeedsTable from '@analytics/components/research/FeedsTable';

const CompanyFeeds = ({organization, company}) => {
  return (
    <>
      <CardHeader>Podcasts</CardHeader>
      <Card>
        <Element tag='p' rules={() => ({fontSize: '15px'})}>
          All podcasts where {company.name} has placed ads
        </Element>
        <FeedsTable
          organization={organization}
          company={company}
          orderBy='-episodesCount'
        />
      </Card>
    </>
  );
};

export default CompanyFeeds;
