import React, {useCallback, useRef} from 'react';
import {Element} from '@core/style';
import {BLUE} from '@core/style';
import ContentEditableInput from '@core/ui/ContentEditableInput';

const handleInputNumber = (target) => {
  const value = target.value.replace(/,/gi, '').replace(/[^0-9.]/g, '');
  const isNumber = !isNaN(parseFloat(value));
  const enteringDecimal = value.slice(-1) === '.' || value.slice(-2) === '.0';

  if (!enteringDecimal) {
    if (isNumber) {
      target.value = parseFloat(value).toLocaleString('en-EN');
    } else {
      target.value = '';
    }
  }

  return value;
};

const inputRule =
  ({readOnly}) =>
  ({theme}) => ({
    border: '1px solid transparent',
    borderRadius: '0.1875rem',
    color: 'inherit',
    display: 'block',
    fontFamily: 'inherit',
    minWidth: '1rem',
    outline: 0,
    padding: '0.2rem 0.25rem',
    pointerEvents: readOnly ? 'none' : '',
    width: '100%',
    ':hover': {
      background: theme.inputBg,
      border: `1px solid ${theme.inputBorder}`,
      color: theme.textPrimary,
    },
    ':focus': {
      background: theme.inputBg,
      borderColor: BLUE,
      boxShadow: `0 0 0 1px ${BLUE}`,
      color: theme.textPrimary,
      position: 'relative',
      zIndex: 1,
    },
  });

const BulkInput = ({
  defaultValue,
  multipleLine,
  onChange,
  readOnly,
  rules,
  type,
  placeholder,
  ...props
}) => {
  const onInputChange = useCallback(
    (evt) => {
      if (type === 'number') {
        onChange(evt, handleInputNumber(evt.target));
      } else {
        onChange(evt, multipleLine ? evt.target.innerText : evt.target.value);
      }
    },
    [onChange, type]
  );

  const defaultValueRef = useRef(
    type === 'number' && !isNaN(parseFloat(defaultValue))
      ? parseFloat(defaultValue).toLocaleString('en-EN')
      : defaultValue
  );

  if (multipleLine) {
    return (
      <ContentEditableInput
        defaultValue={defaultValueRef.current}
        onInput={onInputChange}
        placeholder={placeholder}
        rules={[
          inputRule({readOnly}),
          () => ({
            padding: '0.375rem 0.25rem 0.3125rem',
          }),
          rules,
        ]}
        {...props}
      />
    );
  }
  return (
    <Element
      tag='input'
      type={type === 'number' ? 'text' : type}
      autoComplete='off'
      defaultValue={defaultValueRef.current}
      onChange={onInputChange}
      readOnly={readOnly}
      placeholder={placeholder}
      spellCheck={false}
      rules={[
        inputRule({readOnly}),
        () => ({
          height: '2rem',
        }),
        rules,
      ]}
      {...props}
    />
  );
};

export default BulkInput;
