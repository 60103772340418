import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import SimilarByEpisode from './SimilarByEpisode';
import SimilarByFeed from './SimilarByFeed';
import SimilarCompany from './SimilarCompany';

const SimilarIndex = ({user, organization, company}) => {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/research/brands/:companyId/similar'
        user={user}
        organization={organization}
        company={company}
        component={SimilarByFeed}
      />
      <PropsRoute
        path='/:part(a|s|b|p)/:slug/research/brands/:companyId/similar/episodes'
        user={user}
        organization={organization}
        company={company}
        component={SimilarByEpisode}
      />
      <PropsRoute
        path='/:part(a|s|b|p)/:slug/research/brands/:companyId/similar/:secondaryCompanyId'
        user={user}
        organization={organization}
        company={company}
        component={SimilarCompany}
      />
    </Switch>
  );
};

export default SimilarIndex;
