import {createOrgPath, isSelling} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {FetchedIncrementalLiftReports} from '@analytics/graphql-api';
import {MeasureRouteProps} from '../../..';

export const getLiftReportRoutes = ({
  organization,
  liftReport,
  user,
}: MeasureRouteProps & {
  liftReport: FetchedIncrementalLiftReports[number];
}) => {
  return useRoutes({
    baseUrl: createOrgPath(
      organization,
      `measure/lift/incremental/reports/${liftReport.id}`
    ),
    basePath:
      '/:part(b|a|s|p)/:slug/measure/lift/incremental/reports/:liftReportId',
    routes: [
      {key: 'liftReport', exact: true, path: ''},
      {
        key: 'delete',
        exact: true,
        isAvailable: isSelling(organization) || user.isAdmin,
      },
      {key: 'run', exact: true, isAvailable: user.isAdmin},
      {key: 'update', exact: true, isAvailable: user.isAdmin},
      {
        key: 'range',
        title: 'Overview',
        path: ':after/:before',
        to: `0/${liftReport.delta}`,
      },
    ],
  });
};
