import React, {useState} from 'react';
import AudioPlayButton from '@core/components/AudioPlayButton/AudioPlayButton';
import {toTimeString} from '@core/lib/time';
import {Element} from '@core/style';
import {buttonRule} from '@core/ui/Button/LegacyButton';

const chevronRule = () => ({
  color: '#aaa',
  cursor: 'pointer',
  display: 'inline-flex',
  padding: '5px 0',
  userSelect: 'none',
  ':hover': {
    color: '#333',
  },
});

const Time = ({time, updateTime}) => {
  return (
    <Element
      rules={({theme}) => ({
        alignItems: 'center',
        color: theme.textTertiary,
        display: 'flex',
      })}>
      <Element
        rules={chevronRule}
        onClick={() => {
          updateTime(-1);
        }}>
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'>
          <path d='M18.41 7.41L17 6l-6 6 6 6 1.41-1.41L13.83 12l4.58-4.59m-6 0L11 6l-6 6 6 6 1.41-1.41L7.83 12l4.58-4.59z' />
        </svg>
      </Element>

      <Element
        rules={chevronRule}
        onClick={() => {
          updateTime(-0.5);
        }}>
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'>
          <path d='M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.42z' />
        </svg>
      </Element>
      <Element
        tag='span'
        rules={() => ({
          fontSize: '0.875rem',
          padding: '0 0.5rem',
        })}
        onClick={() => {
          updateTime(0);
        }}>
        {toTimeString(time)}
      </Element>
      <Element
        rules={chevronRule}
        onClick={() => {
          updateTime(0.5);
        }}>
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'>
          <path d='M8.59 16.58L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42z' />
        </svg>
      </Element>
      <Element
        rules={chevronRule}
        onClick={() => {
          updateTime(1);
        }}>
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'>
          <path d='M5.59 7.41L7 6l6 6-6 6-1.41-1.41L10.17 12 5.59 7.41m6 0L13 6l6 6-6 6-1.41-1.41L16.17 12l-4.58-4.59z' />
        </svg>
      </Element>
    </Element>
  );
};

const AudioPlacementTrack = ({
  currentTime,
  endTime,
  placement,
  setCurrentTime,
  startTime,
}) => {
  const onMouseDown = (evt) => {
    const duration = endTime - startTime;
    const rect = ref.current.getBoundingClientRect();
    const percent = (evt.pageX - rect.x) / rect.width;

    setCurrentTime(percent * duration + startTime, endTime);
  };

  const onMouseMove = (evt) => {
    const rect = ref.current.getBoundingClientRect();
    const percent = (evt.pageX - rect.x) / rect.width;

    setMousePosition(Math.min(Math.max(100 * percent, 0), 100));
  };

  const [mousePosition, setMousePosition] = useState(0);
  const ref = React.useRef();
  const currentPct = Math.min(
    Math.max(((currentTime - startTime) / (endTime - startTime)) * 100, 0),
    100
  );

  return (
    <Element
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseLeave={() => setMousePosition(0)}
      rules={() => ({
        padding: '4px 0',
        cursor: 'pointer',
      })}>
      <Element
        domRef={ref}
        style-mousePosition={mousePosition}
        rules={() => ({
          background: `linear-gradient(to right, #ddd ${mousePosition}%, #eee ${mousePosition}%)`,
          borderRadius: '50px',
          height: '8px',
          overflow: 'hidden',
          position: 'relative',
        })}>
        <Element
          style-color={placement.color}
          style-currentPct={currentPct}
          rules={({color, currentPct}) => ({
            background: color,
            height: '8px',
            left: 0,
            position: 'absolute',
            top: 0,
            width: `${currentPct}%`,
            zIndex: 1,
          })}
        />
      </Element>
    </Element>
  );
};

const AudioPlacementDetail = ({
  playing,
  placement,
  togglePlay,
  currentTime,
  removePlacement,
  updatePlacement,
  setCurrentTime,
  setActivePlacement,
}) => {
  const savePlacement = (evt) => {
    evt.preventDefault();
    updatePlacement(placement);
    setActivePlacement(null);
  };

  const {startTime, endTime} = placement;
  const duration = endTime - startTime;
  const isPlaying =
    playing && startTime <= currentTime && currentTime <= endTime;

  return (
    <Element
      rules={() => ({
        borderBottom: '1px solid #eee',
        fontSize: '0.875rem',
        padding: '0.75rem 0 1rem 1rem',
      })}>
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'grid',
          gridGap: '1.5rem',
          gridTemplateColumns:
            'max-content max-content 1fr max-content max-content',
        })}>
        <AudioPlayButton
          playing={isPlaying}
          onClick={() => {
            if (isPlaying) {
              togglePlay();
            } else {
              setCurrentTime(startTime, endTime);
            }
          }}
          rules={() => ({width: '25px', display: 'flex'})}
        />
        <Element
          rules={() => ({
            display: 'flex',
            alignItems: 'center',
          })}>
          <Time
            time={startTime}
            updateTime={(diff) => {
              placement.startTime += diff;
              setCurrentTime(placement.startTime, placement.endTime);
              updatePlacement(placement);
            }}
          />
          <Time
            time={endTime}
            updateTime={(diff) => {
              placement.endTime += diff;
              setCurrentTime(placement.endTime - 1.5, placement.endTime);
              updatePlacement(placement);
            }}
          />
        </Element>
        <AudioPlacementTrack
          currentTime={currentTime}
          startTime={startTime}
          endTime={endTime}
          placement={placement}
          setCurrentTime={setCurrentTime}
        />
        <Element>{toTimeString(duration)}</Element>

        <Element
          rules={() => ({
            display: 'grid',
            gridGap: '0.625rem',
            gridTemplateColumns: '1fr 1fr',
          })}>
          <Element
            tag='a'
            rules={buttonRule}
            href='#'
            onClick={(evt) => {
              evt.preventDefault();
              removePlacement(placement);
            }}>
            Discard
          </Element>{' '}
          <Element
            tag='a'
            rules={buttonRule}
            href='#'
            onClick={savePlacement}
            style-secondary>
            Save
          </Element>
        </Element>
      </Element>
    </Element>
  );
};

export default AudioPlacementDetail;
