import {Switch} from 'react-router-dom';
import Button from '@core/ui/Button';
import {useOverlay} from '@core/ui/Overlay';
import {ReportsNav} from '@analytics/components/nav/ReportsNav';
import ReportsFormOverlay from '@analytics/components/reports/ReportsFormOverlay';
import Reports from './Reports';
import ReportsHistory from './ReportsHistory';
import {ReportsRouteProps, useReportsRoutes} from './_routes';

export function ReportsIndex({user, organization}: ReportsRouteProps) {
  const [openedNewForm, toggleNewForm] = useOverlay();
  const {renderRoutes} = useReportsRoutes({organization});

  return (
    <>
      <ReportsNav organization={organization}>
        <Button
          onClick={() => toggleNewForm(true)}
          color='secondary'
          css={{marginLeft: 'auto'}}>
          New Report
        </Button>
      </ReportsNav>
      <Switch>
        {renderRoutes({
          components: {
            reports: Reports,
            history: ReportsHistory,
          },
          extraProps: {
            user,
            organization,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
      <ReportsFormOverlay
        opened={openedNewForm}
        organization={organization}
        toggle={toggleNewForm}
      />
    </>
  );
}
