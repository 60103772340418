import {ChangeEvent} from 'react';
import {Control, Controller} from 'react-hook-form';
import styled from 'styled-components';
import {FormHelp, Input, Label} from '@core/ui/FormElements';
import {CampaignFormData} from '../_types';
import {FormGroup} from './_shared';

export function CampaignCostField({
  control,
}: {
  control: Control<CampaignFormData>;
}) {
  return (
    <Grid>
      <FormGroup>
        <Label htmlFor='cost'>Cost ($)</Label>
        <Controller
          name='cost'
          control={control}
          rules={{required: 'Please provide a cost.'}}
          render={({field, fieldState}) => (
            <Input
              id='cost'
              type='number'
              defaultValue={field.value ?? undefined}
              invalid={!!fieldState.error}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                field.onChange(parseInt(e.target.value))
              }
              placeholder='Cost ($)'
            />
          )}
        />
        <FormHelp>
          What was the agreed upon price? We use this in the calculation of ROI
          for the advertiser.
        </FormHelp>
      </FormGroup>
      <FormGroup>
        <Label htmlFor='impressionsGoal'>Impressions Goal</Label>
        <Controller
          name='goal'
          control={control}
          rules={{required: 'Please provide an impressions goal.'}}
          render={({field, fieldState}) => (
            <Input
              id='impressionsGoal'
              type='number'
              defaultValue={field.value ?? undefined}
              invalid={!!fieldState.error}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                field.onChange(parseInt(e.target.value))
              }
              placeholder='Impressions Goal'
            />
          )}
        />
        <FormHelp>How many downloads did you sell them on?</FormHelp>
      </FormGroup>
    </Grid>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-8);
`;
