import {gql} from '@apollo/client';

export default gql`
  mutation deleteUserAccess($input: DeleteUserAccessInput!) {
    authed {
      success
    }
    deleteUserAccess(input: $input) {
      success
    }
  }
`;
