import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Stack} from '@core/ui/Layout';
import {LoadingSpinner} from '@core/ui/Loading';
import {
  GetMeApprovalsQueryVariables,
  useGetMeApprovalsQuery,
} from '@analytics/graphql-api';
import {getApprovalsByState} from '@analytics/lib/approvals';
import {CampaignDraftProps} from '..';
import {CampaignPathButton, Row, Title} from '../_shared';
import {parseApprovalsStatusDisplay} from './_helpers';
import {DisplayStatus} from './_shared';

export function CampaignApprovalsDetails({campaign}: CampaignDraftProps) {
  const {data, loading, error} = useGetMeApprovalsQuery({
    variables: {
      campaignId: campaign.id,
    } as GetMeApprovalsQueryVariables,
  });

  const {requested, rejected} = getApprovalsByState({
    campaign,
    dynamicApprovals: data?.me?.dynamicApprovals ?? [],
    podcastApprovals: data?.me?.podcastApprovals ?? [],
    streamingApprovals: data?.me?.streamingApprovals ?? [],
  });

  const {success, statusText, statusDetails} = parseApprovalsStatusDisplay({
    requested,
    rejected,
  });

  return (
    <Row>
      <Title
        rightContent={
          requested.length > 0 && (
            <CampaignPathButton toCampaignPath='approvals/confirm'>
              Confirm Approvals
            </CampaignPathButton>
          )
        }>
        Pending Approvals
      </Title>
      <Stack>
        {loading ? (
          <LoadingSpinner size='small' />
        ) : error ? (
          <Alert severity='error'>{t('errors.network')}</Alert>
        ) : (
          <DisplayStatus
            success={success}
            statusText={statusText}
            statusDetails={statusDetails}
          />
        )}
      </Stack>
    </Row>
  );
}
