import {gql} from '@apollo/client';
import CampaignPodcastFragment from '../_old_queries/fragments/CampaignPodcastFragment';

export default gql`
  mutation updateCampaignPodcast($input: UpdateCampaignPodcastInput!) {
    authed {
      success
    }
    updateCampaignPodcast(input: $input) {
      campaignPodcast {
        ...CampaignPodcast
      }
    }
  }
  ${CampaignPodcastFragment}
`;
