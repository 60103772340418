import {Redirect, Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import Loading from '@core/ui/Loading';
import CampaignFutureEpisodeNav from '@analytics/components/nav/CampaignFutureEpisodeNav';
import {
  FetchedCampaign,
  FetchedOrganization,
  useGetPodcasterCampaignEpisodeQuery,
} from '@analytics/graphql-api';
import CampaignFutureEpisode from './CampaignFutureEpisode';

const CampaignFutureIndex = ({
  user,
  organization,
  campaign,
  campaignPodcast,
  match: {
    params: {campaignEpisodeId},
  },
}: {
  user: FetchedUser;
  organization: FetchedOrganization;
  campaign: FetchedCampaign;
  campaignPodcast: TSFixMe;
  match: {
    params: {
      campaignEpisodeId: string;
    };
  };
}) => {
  const {data, loading, error} = useGetPodcasterCampaignEpisodeQuery({
    variables: {
      organizationId: organization.id,
      campaignId: campaign.id,
      campaignPodcastId: campaignPodcast.id,
      id: campaignEpisodeId,
    },
  });

  if (loading) {
    return <Loading fixed />;
  }

  if (error) {
    return (
      <Redirect
        to={createOrgPath(
          organization,
          `/campaigns/${campaign.slug}/podcasts/${campaignPodcast.id}`
        )}
      />
    );
  }

  const campaignEpisode = data?.me?.organization.campaign?.campaignEpisode;

  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(
      organization,
      `/campaigns/${campaign.slug}/podcasts/${campaignPodcast.id}/futures/${campaignEpisodeId}`
    ),
    basePath:
      '/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/podcasts/:campaignPodcastId/futures/:campaignEpisodeId',
    routes: [{key: 'futureEpisode', path: ''}],
  });

  return (
    <>
      <CampaignFutureEpisodeNav
        organization={organization}
        campaign={campaign}
        campaignPodcast={campaignPodcast}
        campaignEpisode={campaignEpisode}
      />
      <Switch>
        {renderRoutes({
          components: {
            futureEpisode: CampaignFutureEpisode,
          },
          extraProps: {
            user,
            organization,
            campaign,
            campaignPodcast,
            campaignEpisode,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
};

export default CampaignFutureIndex;
