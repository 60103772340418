import { cssColorValue, cssSpacing, cursorDisabled, finale, mesto, opacityDisabled, semanticColors } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
export var TextForLabel = styled.span.withConfig({
  displayName: "TextForLabel",
  componentId: "sc-1jqya9m-0"
})(["position:relative;top:-2px;color:", ";overflow-wrap:break-word;min-inline-size:0;", " padding-inline-start:", ";padding-inline-end:", ";input:disabled + label &{cursor:", ";opacity:", ";}"], cssColorValue(semanticColors.textBase), function (_ref) {
  var size = _ref.size;
  switch (size) {
    case 'small':
      return css(["top:0;", ""], finale());
    default:
      return mesto();
  }
}, cssSpacing('tighter', '12px'), cssSpacing('looser', '24px'), cursorDisabled, opacityDisabled);