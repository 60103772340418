import {Switch} from 'react-router-dom';
import ManageNav from '@analytics/components/nav/ManageNav';
import {ManageInvite} from './Invite';
import {ManageInviteRouteProps, useInviteRoutes} from './_routes';
import {InviteCompanyIndex} from './company';

export function ManageInviteIndex({
  user,
  organization,
}: ManageInviteRouteProps) {
  const {renderRoutes} = useInviteRoutes({organization});

  return (
    <>
      <ManageNav user={user} organization={organization} />
      <Switch>
        {renderRoutes({
          components: {
            invite: ManageInvite,
            'invite-company': InviteCompanyIndex,
          },
          extraProps: {
            user,
            organization,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
}
