import {useState} from 'react';
import AudienceDemographicsMosaic from '@analytics/components/audience-intel/AudienceDemographicsMosaic';
import AudienceExperianTypes from '@analytics/components/audience-intel/AudienceExperianTypes';
import AudienceNielsenOccupations from '@analytics/components/audience-intel/AudienceNielsenOccupations';
import {
  AudienceCategory,
  AudienceType,
} from '@analytics/components/audience-intel/types';
import {PodcastAudienceRouteProps} from './_routes';

export default function AudienceDemographics({
  organization,
  podcast,
  experianApproval,
  nielsenApproval,
}: PodcastAudienceRouteProps) {
  const [type, toggleType] = useState<AudienceType>('nielsen');
  const [category, toggleCategory] = useState<AudienceCategory>('percentage');

  return (
    <>
      <AudienceDemographicsMosaic
        organization={organization}
        podcast={podcast}
        nielsenApproval={nielsenApproval}
        experianApproval={experianApproval}
        category={category}
        toggleCategory={toggleCategory}
        toggleType={toggleType}
        type={type}
      />
      {type === 'experian' && (
        <AudienceExperianTypes
          category={category}
          experianApproval={experianApproval}
          organization={organization}
          podcast={podcast}
        />
      )}
      {type === 'nielsen' && (
        <AudienceNielsenOccupations
          category={category}
          nielsenApproval={nielsenApproval}
          organization={organization}
          podcast={podcast}
        />
      )}
    </>
  );
}
