import {graphql} from '@apollo/client/react/hoc';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {Card} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import BrandCampaignHouseholdQuery from '@analytics/graphql-api/_old_queries/BrandCampaignHouseholdQuery';
import Household from './Household';

const CampaignHouseholdIndex = ({
  user,
  organization,
  campaign,
  pixel,
  data: {loading, me},
}) => {
  if (loading) {
    return <Loading centered />;
  }

  let data;
  try {
    data = me.organization.campaign.attribution.households.household;
  } catch (err) {
    return (
      <Card>
        <EmptyMessage>Could not fetch data</EmptyMessage>
      </Card>
    );
  }

  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/pixels/:key/households/:householdId'
        user={user}
        organization={organization}
        campaign={campaign}
        pixel={pixel}
        data={data}
        component={Household}
      />
    </Switch>
  );
};

export default graphql(BrandCampaignHouseholdQuery, {
  options: ({
    organization,
    campaign: {id},
    match: {
      params: {householdId},
    },
  }) => {
    return {
      variables: {
        organizationId: organization.id,
        campaignId: id,
        id: householdId,
      },
    };
  },
})(CampaignHouseholdIndex);
