import {FC, useState} from 'react';
import {copyToClipboard} from '@core/lib/utils';
import {TRules} from '@core/style';
import Button from '../Button/LegacyButton';

export interface ICopyProps {
  text: string;
  rules?: TRules;
}

const Copy: FC<ICopyProps> = ({text, rules}) => {
  const [copied, setCopied] = useState<boolean>(false);

  const onClick = () => {
    copyToClipboard(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Button
      style-secondary
      style={{
        ...(copied && {
          background: 'var(--green)',
          borderColor: 'var(--green)',
          ':hover': {
            background: 'var(--green)',
            borderColor: 'var(--green)',
          },
        }),
      }}
      rules={[() => ({minWidth: '6rem'}), rules]}
      onClick={onClick}>
      {copied ? 'Copied' : 'Copy'}
    </Button>
  );
};

export default Copy;
