import {
  FetchedCampaign,
  FetchedCampaignDynamicPodcast,
} from '@analytics/graphql-api';
import _CampaignStatsBase from './_CampaignStatsBase';

export const CampaignDynamicPodcastStats = ({
  campaign,
  campaignDynamicPodcast,
}: {
  campaign: FetchedCampaign;
  campaignDynamicPodcast: FetchedCampaignDynamicPodcast;
}) => {
  return (
    <_CampaignStatsBase campaign={campaign} instance={campaignDynamicPodcast} />
  );
};
