import styled from 'styled-components';
import DataTableFilterButton, {
  DataTableFilterButtonProps,
} from '@core/ui/DataTable/misc/DataTableFilterButton';

interface DatesInputButtonProps extends DataTableFilterButtonProps {
  invalid?: boolean;
}

/* TODO: Don't use DataTableFilterButton */
export const DatesInputButton = ({
  active,
  invalid,
  noArrowIcon,
  placeholder,
  rules,
  ...props
}: DatesInputButtonProps) => (
  <DataTableFilterButton
    id='dates'
    active={active}
    noArrowIcon={noArrowIcon}
    rules={[
      () => ({
        fontSize: '1rem',
        fontWeight: '400 !important',
        height: null,
        padding: '0.375rem 1rem',
        width: noArrowIcon ? '7.8125rem' : '100%',
        ':hover': {
          borderColor: 'var(--border-default) !important',
        },
        ...(active && {
          color: 'inherit',
          '> svg': {color: 'var(--text-muted)'},
        }),
        ...(noArrowIcon && {justifyContent: null}),
        ...(invalid && {
          borderColor: 'var(--red-orange)',
          color: 'var(--red-orange)',
          '> svg': {
            color: 'var(--red-orange)',
          },
        }),
      }),
      rules,
    ]}
    {...props}>
    {placeholder}
  </DataTableFilterButton>
);

export const Separator = styled.div`
  background: var(--border-default);
  height: 1px;
  margin: 0.275rem;
`;
