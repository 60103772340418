import React from 'react';
import {Element} from '@core/style';

class Bar extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {marker, height, max, duration} = this.props;

    const ratio = marker.count / max;

    const barHeight = height * ratio;
    const barMargin = height - barHeight;

    const left = (marker.seconds / duration) * 100;

    const style = {
      position: 'absolute',
      height: `${barHeight}px`,
      width: '2px',
      left: `${left}%`,
      marginLeft: '-1px',
      marginTop: `${barMargin}px`,
      background: 'rgba(0,0,255, 0.7)',
    };

    return <div style={style} />;
  }
}

class AudioAdMarkers extends React.Component {
  render() {
    const {height, adMarkers, duration} = this.props;

    if (!adMarkers || !adMarkers.length) {
      return '';
    }

    const max = adMarkers.reduce((max, marker) => {
      return marker.count > max ? marker.count : max;
    }, 0);

    return (
      <Element rules={() => ({position: 'relative'})}>
        {adMarkers.map((marker, n) => {
          return (
            <Bar
              marker={marker}
              height={height}
              max={max}
              duration={duration}
              key={n}
            />
          );
        })}
      </Element>
    );
  }
}

export default AudioAdMarkers;
