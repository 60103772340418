import MultipleDataSelector from '@core/ui/MultipleDataSelector';
import {GetAdvisorQuizTagsDocument} from '@analytics/graphql-api';
import {DropdownItem, Question, _QuestionHeader} from '../../shared';
import {getAdvisorColumnData} from '../helpers';
import {IQuestionProps} from '../types';

export const QuestionGenres = ({
  updateQuizData,
  organization,
}: IQuestionProps) => (
  <Question>
    <_QuestionHeader>
      What genres of podcasts align with your brand value?
    </_QuestionHeader>
    <MultipleDataSelector
      onChange={(items) => updateQuizData('tags', items)}
      dataQuery={GetAdvisorQuizTagsDocument}
      renderItem={(item) => <DropdownItem label={item.title} />}
      placeholder='Search genres'
      queryVariables={{orgId: organization.id}}
      propertyForName='title'
      fromResponse={getAdvisorColumnData('tags')}
    />
  </Question>
);
