import {useRef, useState} from 'react';
import {
  Box,
  ButtonSecondary,
  FormGroup,
  FormInput,
  IconCheckAltFill,
  Text,
  cssSpacing,
  semanticColors,
} from '@spotify-internal/encore-web';
import {isEqual} from 'lodash';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {
  DataTableFilterButton,
  DataTableServerFilterProps,
} from '@core/ui/DataTable';
import DatePicker from '@core/ui/DatePicker';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import RangeSlider from '@core/ui/RangeSlider';
import {MultipleSelect} from '@core/ui/Select';
import {
  FetchedCampaigns,
  FetchedOrganization,
  useCampaignsImpressionsMaxQuery,
  useOrganizationGenericTagsQuery,
} from '@analytics/graphql-api';

type CampaignsTableFilterProps = DataTableServerFilterProps<
  FetchedCampaigns[number]
> & {
  organization: FetchedOrganization;
};

export const CampaignsTableTagsFilter = ({
  column,
  defaultValue,
  onChange,
  organization,
}: CampaignsTableFilterProps) => {
  const {data, loading, error} = useOrganizationGenericTagsQuery({
    variables: {
      id: organization.id,
    },
  });

  const items = (data?.me?.organization?.genericTags ?? []).filter(
    ({title}) => !!title
  );

  return (
    <MultipleSelect
      key={String(items)}
      defaultValue={items
        ?.filter((item) => defaultValue?.includes(item.title))
        .map(({id}) => id)}
      disabled={loading || !!error}
      emptyText='No tags found.'
      items={items}
      isLoading={loading}
      onSelect={(items) => {
        onChange({column, defaultValue: items.map((item) => item.title)});
      }}
      placeholder='Tags'
      propertyForName='title'
      propertyForValue='id'
      searchable
      searchKeys={['title']}
      OptionContent={({item, active, isLast, ...props}) => (
        <Box
          hoverBackgroundColor='backgroundTintedPress'
          minBlockSize='fit-content'
          padding={cssSpacing('tighter-3', '6px')}
          css={{
            alignItems: 'center',
            background: item.color,
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: isLast ? '0' : cssSpacing('tighter-4', '4px'),
          }}
          {...props}>
          <Text
            variant='bodySmall'
            css={{
              minWidth: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {item.title}
          </Text>
          <IconCheckAltFill
            aria-hidden={!active}
            css={{opacity: active ? 1 : 0}}
            size='small'
          />
        </Box>
      )}
    />
  );
};

export const CampaignsTableDateRangeFilter = ({
  column,
  defaultValue,
  onChange,
  organization,
  label = 'Date Range',
}: CampaignsTableFilterProps & {
  label?: string;
}) => {
  const inputRef = useRef<HTMLButtonElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const minMaxDates = [dayjs(organization.createdAt), dayjs()];
  const defaultDates =
    (defaultValue && defaultValue.map((date) => dayjs(date))) || minMaxDates;

  const [opened, toggle] = useOverlay();
  const [selectedDates, setSelectedDates] = useState(defaultDates);

  const onDatesChange = (dates: dayjs.Dayjs[]) => {
    if (dates.length === 2) {
      onChange({
        column,
        defaultValue: dates.map((d) => d.format('YYYY-MM-DD')),
      });
      setSelectedDates(dates);
    }
  };

  return (
    <>
      <DataTableFilterButton domRef={inputRef} onClick={() => toggle()}>
        {label}
        {defaultValue?.length === 2
          ? `: ${dayjs(defaultValue[0]).format('MM/DD/YY')} - ${dayjs(
              defaultValue[1]
            ).format('MM/DD/YY')}`
          : null}
      </DataTableFilterButton>
      {opened && (
        <Overlay
          horizontalAlign='right'
          opened={opened}
          positionTarget={inputRef.current}
          ref={overlayRef}
          toggle={toggle}
          transparentBackdrop
          verticalAlign='bottom'
          verticalOffset={2}
          withBackdrop
          withShadow
          css={`
            max-width: initial;
            padding: 1rem;
          `}>
          <DatePicker
            defaultDates={selectedDates}
            extraControls={[
              {
                title: 'Clear Filter',
                callback: () => {
                  onChange({
                    column,
                    defaultValue: undefined,
                  });
                  setSelectedDates(minMaxDates);
                  toggle(false);
                },
              },
            ]}
            isOutsideRange={(date) =>
              !(
                date.isSameOrAfter(minMaxDates[0]) &&
                date.isSameOrBefore(minMaxDates[1])
              )
            }
            onDatesChange={onDatesChange}
            twoMonthsView
          />
        </Overlay>
      )}
    </>
  );
};

export const CampaignsTableImpressionsFilter = ({
  column,
  defaultValue,
  organization,
  onChange,
}: CampaignsTableFilterProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [opened, toggle] = useOverlay();
  const [minMaxRange, setMinMaxRange] = useState([0, 0]);
  const [range, setRange] = useState(defaultValue);

  const {loading, error} = useCampaignsImpressionsMaxQuery({
    variables: {
      organizationId: organization.id,
    },
    onCompleted: (data) => {
      const max = data?.me?.organization?.campaignsImpressionsMax;
      if (max) {
        setMinMaxRange([0, max]);
        if (!defaultValue) setRange([0, max]);
      }
    },
  });

  const clearFilter = () => {
    onChange({
      column,
      defaultValue: undefined,
    });
    setRange(minMaxRange);
  };

  const handleOnChange = (updatedRange: [number, number]) => {
    if (isEqual(updatedRange, minMaxRange)) {
      clearFilter();
    } else {
      setRange(updatedRange);
      onChange({column, defaultValue: updatedRange});
    }
  };

  const displayMin = range?.[0] ?? minMaxRange[0];
  const displayMax = range?.[1] ?? minMaxRange[1];

  return (
    <>
      <DataTableFilterButton
        domRef={buttonRef}
        disabled={loading || !!error}
        isLoading={loading}
        onClick={() => toggle(true)}>
        Impressions
        {range && !isEqual(range, minMaxRange)
          ? `: ${comma(range[0])} - ${comma(range[1])}`
          : null}
      </DataTableFilterButton>
      {opened && (
        <Overlay
          opened={opened}
          toggle={toggle}
          positionTarget={buttonRef.current}
          horizontalAlign='right'
          transparentBackdrop
          withBackdrop
          withShadow
          verticalOffset={2}
          css={`
            padding: 1rem;
          `}>
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: cssSpacing('tighter-2', '8px'),
              minWidth: '12.5rem',
            }}>
            <FormGroup label='Min' labelFor='impressionsMin' css={{padding: 0}}>
              <FormInput
                id='impressionsMin'
                onChange={(e) => {
                  handleOnChange([Number(e.currentTarget.value), displayMax]);
                }}
                min={0}
                max={minMaxRange[1]}
                size='small'
                step={1}
                type='number'
                value={displayMin}
              />
            </FormGroup>
            <FormGroup
              inline
              label='Max'
              labelFor='impressionsMax'
              css={{padding: 0}}>
              <FormInput
                id='impressionsMax'
                onChange={(e) => {
                  handleOnChange([displayMin, Number(e.currentTarget.value)]);
                }}
                min={range?.[0]}
                max={minMaxRange[1]}
                size='small'
                step={1}
                type='number'
                value={displayMax}
              />
            </FormGroup>
          </div>
          <div
            css={`
              margin: ${cssSpacing('base', '16px')}
                ${cssSpacing('tighter-2', '8px')}
                ${cssSpacing('looser', '24px')};
            `}>
            <RangeSlider
              defaultRange={[displayMin, displayMax]}
              min={0}
              max={minMaxRange[1]}
              onChange={handleOnChange}
            />
          </div>
          <ButtonSecondary
            onClick={() => {
              clearFilter();
              toggle(false);
            }}
            semanticColor={semanticColors.textSubdued}
            size='small'>
            Clear
          </ButtonSecondary>
        </Overlay>
      )}
    </>
  );
};

export const CampaignsTableMultipleSelectFilter = ({
  column,
  defaultValue,
  items,
  placeholder,
  onChange,
}: DataTableServerFilterProps<FetchedCampaigns[number]> & {
  items: {title: string; value: string}[];
  placeholder?: string;
}) => (
  <MultipleSelect
    defaultValue={defaultValue}
    items={items}
    onSelect={(items) => {
      onChange({
        column,
        defaultValue: items.map(({value}) => value),
      });
    }}
    placeholder={placeholder}
  />
);
