import {useState} from 'react';
import searchFilter from '@core/lib/searchFilter';
import {ButtonIcon} from '@core/ui/Button';
import {InfoTooltip} from '@core/ui/Tooltip';
import {ExportTypeColumn} from '@analytics/graphql-api';
import {SearchColumnsInput} from './SearchColumnsInput';
import {Column, ColumnContent, ColumnItem, SelectedIcon} from './shared';

// TODO: temp until we pull descriptions into columns query
const COLUMN_DESCRIPTIONS = {
  uid: {
    title: 'UID',
    description:
      'Randomized Ad Analytics unique identifier for an attributed user. This ID will not match back to external systems, and may appear multiple times throughout your report.',
  },
  feed_name: {
    title: 'Feed Name',
    description: 'Title of podcast for which the ad was exposed',
  },
};

type Props = {
  columns: ExportTypeColumn[];
  onChangeColumns: (_: ExportTypeColumn[]) => void;
  onRemoveColumn: (_: string) => void;
  selectedColumns: ExportTypeColumn[];
};

export function LeftColumn({
  columns,
  onChangeColumns,
  onRemoveColumn,
  selectedColumns,
}: Props) {
  const [searchValue, setSearchValue] = useState('');

  const onAddColumn = (columnId: string) => {
    const column = columns.find(({id}) => id === columnId);
    const _selectedColumns = [...selectedColumns, ...(column ? [column] : [])];
    onChangeColumns(_selectedColumns);
  };

  return (
    <Column withRightBorder>
      <SearchColumnsInput
        onDeselectAll={() => onChangeColumns([])}
        onSelectAll={() => onChangeColumns([...columns])}
        onInputChange={(evt) => setSearchValue(evt.target.value.trim())}
      />
      <ColumnContent>
        {searchFilter({
          data: columns,
          query: searchValue,
          searchKeys: ['name'],
        }).map(({id, name}) => {
          const isSelected = selectedColumns.find((c) => c.id === id);

          return (
            <ColumnItem key={id}>
              <div>
                {name}
                {!!COLUMN_DESCRIPTIONS[id] && (
                  <InfoTooltip
                    title={COLUMN_DESCRIPTIONS[id].title}
                    description={COLUMN_DESCRIPTIONS[id].description}
                    css={{marginLeft: '0.25rem'}}
                  />
                )}
              </div>
              {isSelected ? (
                <SelectedIcon
                  icon='checkbox-marked-circle'
                  onClick={() => onRemoveColumn(id)}
                />
              ) : (
                <ButtonIcon
                  icon='add-circle-outline'
                  onClick={() => onAddColumn(id)}
                  css={{padding: 0}}
                />
              )}
            </ColumnItem>
          );
        })}
      </ColumnContent>
    </Column>
  );
}
