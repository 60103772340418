import { useCallback, useEffect, useRef } from 'react';
function useInView(ref, callback) {
  var hasIntersectionObserverSupport = 'IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in IntersectionObserverEntry.prototype;
  var observer = useRef(null);
  var handleObserver = useCallback(function (entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        if (observer.current && ref.current) {
          observer.current.unobserve(ref.current);
          callback();
        }
      }
    });
  }, [callback, ref]);
  var observe = useCallback(function () {
    if (hasIntersectionObserverSupport) {
      observer.current = new IntersectionObserver(handleObserver);
      if (ref.current) {
        observer.current.observe(ref.current);
      }
    } else {
      callback();
    }
  }, [callback, handleObserver, hasIntersectionObserverSupport, ref]);
  useEffect(function () {
    observe();
  }, [observe]);
}
export { useInView };