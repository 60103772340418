import React from 'react';
import styled from 'styled-components';
import {Button} from '@core/ui/Button';
import {ButtonColor} from '@core/ui/Button/buttonStyles';
import {FormFooter} from '@core/ui/FormElements';
import {LoadingSpinner} from '@core/ui/Loading';
import {Text} from '@core/ui/Text';

export function QuizCardTitle({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <Text variant='h2'>
      {title}
      <Text
        variant='body1'
        color='muted'
        css={`
          margin-top: 0.25rem;
        `}>
        {description}
      </Text>
    </Text>
  );
}

interface ButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  color?: ButtonColor;
}

interface CardFooterProps {
  left: ButtonProps;
  right: ButtonProps;
}

export function CardFooter({left, right}: CardFooterProps) {
  function mergeProps(color: ButtonColor) {
    return (p: ButtonProps): ButtonProps => ({
      disabled: !!p.disabled,
      loading: !!p.loading,
      onClick: (e) => {
        e?.preventDefault();
        if (p.disabled) return;
        p.onClick(e);
      },
      text: p.text,
      color: p.color ?? color,
    });
  }

  return (
    <FormFooter>
      <_Button {...mergeProps('default')(left)} />
      <_Button {...mergeProps('secondary')(right)} />
    </FormFooter>
  );
}

function _Button(p: ButtonProps) {
  return (
    <Button
      css={{borderRadius: '50px'}}
      disabled={p.disabled || p.loading}
      color={p.color}
      onClick={p.onClick}
      // eslint-disable-next-line react/no-children-prop
      children={p.loading ? <LoadingSpinner /> : p.text}
    />
  );
}
export const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const _QuestionHeader = styled.h4`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25;
`;

export const _Description = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6a737d;
  margin-bottom: 0;
`;

export const DropdownItem = ({label}: {label: string}) => (
  <div>
    <span css={{color: 'var(--text-muted)'}}>{label}</span>
  </div>
);
