import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Controller, useForm} from 'react-hook-form';
import AgencySelect from '@core/components/AgencySelect';
import {
  FORM_ERRORS,
  Form,
  FormFooter,
  Label,
  Submit,
} from '@core/ui/FormElements';
import ADD_ACCESS_MUTATION from '@analytics/graphql-api/_old_mutations/AddAccess';

const AddBrandAccessForm = ({organization, onSuccess}) => {
  const [addAccess] = useMutation(ADD_ACCESS_MUTATION);
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: {errors},
  } = useForm();

  const onSubmit = async ({agency}) => {
    if (agency) {
      setLoading(true);
      try {
        await addAccess({
          variables: {
            input: {
              organizationId: agency.id,
              name: 'buy-side:organization',
              permission: 'can-manage',
              objectPk: organization.id,
              contentType: 'organizations.organization',
            },
          },
        });

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      } catch (err) {
        setError(null, FORM_ERRORS.network);
      }
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
      <Label>Agency Name</Label>
      <Controller
        name='agency'
        control={control}
        render={({field}) => (
          <AgencySelect
            onSelect={(agency) => {
              field.onChange(agency);
            }}
            {...field}
          />
        )}
      />
      <FormFooter>
        <Submit loading={loading}>Add agency</Submit>
      </FormFooter>
    </Form>
  );
};

export default AddBrandAccessForm;
