import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {Form, FormFooter, Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {podcastURL} from '@analytics/components/nav/ResearchNav';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import PROCESS_FEED from '@analytics/graphql-api/_old_mutations/ProcessFeed';

interface IEpisodeProcessProps {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  feed: TSFixMe;
  episode: TSFixMe;
}

const EpisodeProcess = ({
  organization,
  feed,
  episode,
}: IEpisodeProcessProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [processFeed] = useMutation(PROCESS_FEED);

  const {
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();

  const onSubmit = async () => {
    setLoading(true);
    try {
      await processFeed({
        variables: {
          input: {
            feedId: feed.id,
            episodeId: episode.id,
          },
        },
      });
      setComplete(true);
    } catch (e) {
      setError(null, e.message);
    }
    setLoading(false);
  };

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader>Process Episode</CardHeader>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
          <p>
            {complete
              ? ' We are processing this episode. Please wait about 5 minutes and it should be complete.'
              : 'Generate Waveform for this Episode'}
          </p>
          <FormFooter>
            {complete ? (
              <Button type='button' to={podcastURL(organization, feed)}>
                Back to Podcast
              </Button>
            ) : (
              <Submit disabled={loading} loading={loading}>
                Process Episode
              </Submit>
            )}
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
};

export default EpisodeProcess;
