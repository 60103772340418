import React, {useState} from 'react';
import dayjs from '@core/lib/dayjs';
import {DropdownMenu} from '@core/ui/DropdownMenu';
import {Icon} from '@core/ui/Icon';

const DATE_FORMAT = 'YYYY-MM-DD HH:00';

function parseEndDate(endDate) {
  const date = dayjs(endDate);

  if (!date.isSameOrBefore(new Date())) {
    return dayjs();
  }

  return date;
}

function getDateRanges(endDate, format = DATE_FORMAT) {
  const endDateObj = parseEndDate(endDate);
  const endDateStr = endDateObj.utc().format();

  return [
    {
      title: 'Last 7 days',
      endDate: endDateStr,
      startDate: endDateObj.subtract(7, 'd').utc().format(format),
    },
    {
      title: 'Last month',
      endDate: endDateStr,
      startDate: endDateObj.subtract(1, 'month').utc().format(format),
    },
    {
      title: 'Last 3 months',
      endDate: endDateStr,
      startDate: endDateObj.subtract(3, 'month').utc().format(format),
    },
    {
      title: 'All time',
      endDate: endDateStr,
      startDate: '2018-07-12',
    },
  ];
}

export default function DateFilterDropdown({selected, onSubmit, dateRanges}) {
  return (
    <DropdownMenu
      items={dateRanges}
      selectedValue={selected.name}
      onSubmit={onSubmit}
      overlayProps={{
        horizontalAlign: 'left',
      }}
      css={`
        color: var(--text-muted);
      `}>
      <Icon icon='date' rules={() => ({marginRight: '.5rem'})} />
      {selected.name}
      <Icon icon='chevron-down-small' rules={() => ({marginLeft: 'auto'})} />
    </DropdownMenu>
  );
}

function useDateFilter({endDate} = {}) {
  const dateRanges = getDateRanges(endDate);
  const [selected, setSelected] = useState(dateRanges[1]);

  const Element = (
    <DateFilterDropdown
      dateRanges={dateRanges}
      selected={selected}
      onSubmit={(item) => setSelected(item)}
    />
  );

  return [selected, Element];
}

export {useDateFilter};
