import dayjs from '@core/lib/dayjs';
import {CampaignObject, CampaignState} from '@analytics/graphql-api';

// Minimum days required to request a lift report.
const MIN_DAYS = 28;

export const validateLiftDates = ({
  startDate,
  endDate,
}: {
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
}) => endDate && startDate && endDate.diff(startDate, 'd') >= MIN_DAYS;

export const isCampaignValidForLift = (campaign) => {
  const kind = campaign.kind.toLowerCase();
  return (
    kind !== 'reporting' &&
    kind !== 'marketing' &&
    campaign.state !== CampaignState.Draft &&
    campaign.state !== CampaignState.Ready
  );
};

export const canCampaignRequestLift = (campaign) =>
  isCampaignValidForLift(campaign) &&
  campaign.downloadTotal >= 250000 &&
  Math.abs(dayjs(campaign.startAt).diff(dayjs(), 'days')) >= MIN_DAYS;

type HardDates = {
  hardStart: dayjs.Dayjs;
  hardEnd: dayjs.Dayjs;
};

export const liftReportHardDates = (campaigns: Partial<CampaignObject>[]) => {
  const today = dayjs();
  return campaigns.reduce((dates, campaign) => {
    if (campaign) {
      const startAt = dayjs(campaign.startAt);
      const endAt = dayjs(campaign.endAt);

      if (!dates.hardStart || startAt.isBefore(dates.hardStart))
        dates.hardStart = startAt;

      if (!dates.hardEnd) {
        dates.hardEnd = endAt.isSameOrBefore(today) ? endAt : today;
      } else if (endAt.isAfter(dates.hardEnd) && endAt.isSameOrBefore(today)) {
        dates.hardEnd = endAt;
      }
    }
    return dates;
  }, {} as HardDates);
};
