import {isSelling} from '@core/lib/organizations';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import CampaignDynamicPodcastsTable from '@analytics/components/campaigns/CampaignDynamicPodcastsTable';
import {CampaignDynamicOmniChart} from '@analytics/components/campaigns/charts';
import {CampaignDynamicStats} from '@analytics/components/campaigns/stats/CampaignDynamicStats';
import CampaignDynamicNav from '@analytics/components/nav/CampaignDynamicNav';
import TrackingUrlsDropdown from '@analytics/components/tracking-urls/TrackingUrlsDropdown';
import {CampaignDynamicRouteProps} from './_routes';

export function CampaignDynamic({
  campaign,
  campaignDynamic,
  organization,
  user,
}: CampaignDynamicRouteProps) {
  return (
    <>
      <CampaignDynamicNav
        campaign={campaign}
        campaignDynamic={campaignDynamic}
        organization={organization}
        user={user}
      />
      {!campaignDynamic?.downloadTotal ? (
        isSelling(organization) ? (
          <ResponsiveContent containerSize='large'>
            <TrackingUrlsDropdown
              organization={organization}
              campaign={campaign}
              campaignItem={campaignDynamic}
              type='dynamic'
            />
          </ResponsiveContent>
        ) : (
          <Card>
            <EmptyMessage title='No data found.'>
              Ad Analytics has not recieved data for this dynamic insertion yet.
            </EmptyMessage>
          </Card>
        )
      ) : (
        <>
          <CampaignDynamicStats
            campaign={campaign}
            campaignDynamic={campaignDynamic}
          />
          <Card css={{marginTop: '2.375rem'}}>
            <CampaignDynamicOmniChart
              organization={organization}
              campaign={campaign}
              campaignDynamic={campaignDynamic}
            />
          </Card>
          <Card>
            <CardHeader>Podcasts</CardHeader>
            <CampaignDynamicPodcastsTable
              organization={organization}
              campaign={campaign}
              campaignDynamic={campaignDynamic}
              campaignDynamicPodcasts={[]}
            />
          </Card>
        </>
      )}
    </>
  );
}
