import DataTable from '@core/ui/DataTable';
import {FeedMultipleImages} from '@core/ui/Image';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
} from '@analytics/graphql-api';
import campaignTableColumns from '@analytics/lib/campaignTableColumns';
import {getCampaignDynamics} from './helpers/getCampaignDynamics';

const NameCell = ({data: {name, campaignDynamicPodcasts}}) => {
  const feeds = campaignDynamicPodcasts
    .map(({feed}) => feed)
    .filter((feed) => !!feed);

  return (
    <Stack
      alignItems='center'
      gap='var(--spacing-2)'
      css={{minWidth: '12.5rem'}}>
      <FeedMultipleImages feeds={feeds} />
      <Text variant='subtitle2'>{name}</Text>
    </Stack>
  );
};

const CampaignDynamicsTable = ({
  organization,
  campaign,
  campaignDynamics,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign: FetchedCampaign;
  campaignDynamics: FetchedCampaign['campaignDynamics'];
}) => {
  const data = getCampaignDynamics(campaignDynamics, {
    campaign,
    organization,
  });

  const columns = [
    {
      title: 'Line Item',
      accessor: 'name',
      type: 'string',
      Cell: NameCell,
    },
  ].concat(campaignTableColumns(organization, campaign, data));

  return (
    <DataTable
      searchKeys={['name']}
      columns={columns}
      orderBy='-downloadTotal'
      data={data}
      searchPlaceholder='Search'
      onClickRowPath={({url}) => url}
    />
  );
};

export default CampaignDynamicsTable;
