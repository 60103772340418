import {FC, useMemo} from 'react';
import dayjs from '@core/lib/dayjs';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {FetchedCampaign} from '@analytics/graphql-api';
import ApprovalsStateFormCell from './ApprovalsStateFormCell';
import {DetailCell, rollPlacementsToString} from './helpers';

type ApprovalsConfirmEmbeddedTableProps = {
  podcastApprovals: any[];
  campaign: FetchedCampaign;
  onUpdateState: (_obj: any) => void;
};

const ApprovalsConfirmEmbeddedTable: FC<ApprovalsConfirmEmbeddedTableProps> = ({
  podcastApprovals,
  campaign,
  onUpdateState,
}): JSX.Element => {
  const data = useMemo(
    () =>
      podcastApprovals.map((item) => {
        const campaignPodcast = (campaign.campaignPodcasts ?? []).find(
          (cp) => cp?.id === item.campaignPodcast.id
        );

        const rollPlacement = rollPlacementsToString(campaignPodcast);
        return {
          ...item,
          podcast: campaignPodcast?.name,
          campaignPodcast: {
            ...campaignPodcast,
            rollPlacement,
          },
        };
      }),
    [podcastApprovals, campaign]
  );

  const columns: DataTableColumn[] = useMemo(
    () => [
      {
        accessor: 'podcast',
        title: 'Podcast',
        type: 'string',
        rule: () => ({width: '30%'}),
      },
      {
        title: 'Detail',
        noSortable: true,
        Cell: ({d}) => <DetailCell d={d.campaignPodcast} />,
      },
      {
        title: 'Updated',
        accessor: 'updatedAt',
        fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
      },
      {
        title: 'State',
        accessor: 'state',
        type: 'string',
        noSortable: true,
        rule: () => ({
          width: '25rem',
        }),
        Cell: ({d}) => (
          <ApprovalsStateFormCell
            data={d}
            onUpdate={onUpdateState}
            isEmbedded
          />
        ),
      },
    ],
    [onUpdateState]
  );

  return (
    <DataTable
      emptyMessageText='No approvals have been requested.'
      columns={columns}
      data={data}
      rules={() => ({'& tbody': {verticalAlign: 'top'}})}
    />
  );
};

export default ApprovalsConfirmEmbeddedTable;
