import {
  Bar,
  BarChart,
  BarProps,
  LabelList,
  LabelProps,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {exists} from '@core/lib/utils';
import {useTheme} from '@core/style';
import {Card} from '@core/ui/Content';
import {AudienceCategory} from './types';

type Metric = {
  variable: string;
  index: number;
  percentage: number;
  unavailable?: boolean;
};

const getLeftDataKey = (category: AudienceCategory, x) => {
  if (category === 'index') {
    return x.index >= 100 || x.unavailable ? 100 : x.index;
  }
  return x.percentage;
};

const getRightDataKey = (x) => (x.index >= 100 ? x.index - 100 : 100 - x.index);

const getYAxisWidth = (metrics: Metric[]) => {
  return metrics.reduce((max, {variable}) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.font = getComputedStyle(document.body).font;

    const value = context.measureText(variable);
    const width = variable.includes(' ') ? value.width / 1.95 : value.width;

    return width > max ? Math.ceil(width) : max;
  }, 0);
};

const DemographicsTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  if (!active || !payload || !payload.length) return null;
  const {index, percentage, unavailable} = payload[0].payload;
  return (
    <div
      css={{
        backgroundColor: 'var(--bg-default)',
        border: '1px solid var(--border-subtle)',
        borderRadius: '.5rem',
        boxShadow: 'var(--shadow-md)',
        color: 'var(--text-muted)',
        fontSize: '0.875rem',
        padding: '0.575rem',
        height: 'fit-content',
        minWidth: '10em',
        width: 'fit-content',
      }}>
      <div css={{fontSize: '.95rem', marginBottom: '0.5rem'}}>{label}</div>
      {unavailable ? (
        <div>Data unavailable</div>
      ) : (
        <>
          <div
            css={{
              color: 'var(--green)',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div>Index</div>
            <div>{index}</div>
          </div>
          <div
            css={{
              color: 'var(--blue-80)',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div>Percentage</div>
            <div>{percentage}%</div>
          </div>
        </>
      )}
    </div>
  );
};

const PercentageLabel = ({
  value,
  y,
  x,
  width,
  height,
  index,
  metrics,
}: LabelProps & {index?: number; metrics: Metric[]}) => {
  if (index === undefined || metrics[index].unavailable) return null;

  const isOffset = exists(width) && width < 45;
  const offset = isOffset ? -15 : 25;

  return (
    <text
      fill={isOffset ? 'var(--text-muted)' : 'var(--white)'}
      x={
        (x as number) +
        (width as number) -
        offset -
        (value ?? 0).toString().length * 5
      }
      y={(y as number) + (height as number) / 2 + 5}>
      {value}%
    </text>
  );
};

const IndexLabel = ({
  value,
  y,
  x,
  width,
  height,
  index,
  metrics,
}: LabelProps & {index?: number; metrics: Metric[]}) => {
  if (index === undefined || metrics[index].unavailable) return null;

  const isOffset = exists(width) && width < 45;
  let offset;

  if (exists(index) && metrics[index].index < 100) {
    offset = isOffset ? (width as number) + 10 : (width as number) - 25;
  } else {
    offset = isOffset ? -20 : 15;
  }

  return (
    <text
      fill={
        !isOffset
          ? 'var(--white)'
          : exists(index) && metrics[index].index < 100
          ? 'var(--text-subtle)'
          : 'var(--text-muted)'
      }
      x={
        (x as number) +
        (width as number) -
        offset -
        (value ?? 0).toString().length * 5
      }
      y={(y as number) + (height as number) / 2 + 5}>
      {exists(index) && metrics[index].index}
    </text>
  );
};

const CustomBarWithLine = ({
  fill,
  x,
  y,
  width,
  height,
  payload,
}: Omit<BarProps, 'dataKey'> & {
  payload?: {index: number; unavailable: boolean};
}) => {
  const {index, unavailable} = payload ?? {};
  const targetX =
    (exists(index) && index > 100) || unavailable
      ? x
      : (x as number) + (width as number);
  return (
    <svg>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={
          exists(index) && index > 100
            ? fill
            : unavailable
            ? 'var(--bg-muted)'
            : '#6a737d'
        }
      />
      <line
        x1={targetX}
        x2={targetX}
        y1={y}
        y2={(y as number) + (height as number)}
        stroke={index === 100 ? 'var(--green)' : 'var(--bg-default)'}
        strokeWidth={4}
      />
    </svg>
  );
};

type Props = {
  category: AudienceCategory;
  extended?: boolean;
  metrics: Metric[];
  onBarClick?: (data?: Metric[], idx?: number) => void;
  subtopic?: string;
  tooltip?: any;
  topic: string;
};

export default function AudienceChartCard({
  category,
  extended = false,
  metrics,
  onBarClick,
  subtopic,
  topic,
  tooltip,
}: Props) {
  const theme = useTheme();
  return (
    <Card
      rules={() => ({
        boxShadow: '0 4px 10px 4px rgba(149,157,165,0.15)',
        margin: 0,
        height: 'fit-content',
        padding: '1rem 1.5rem',
      })}>
      <div css={{fontSize: '1.125rem', fontWeight: 600}}>{topic}</div>
      {subtopic && (
        <div
          css={{
            color: 'var(--text-muted)',
            fontSize: '0.95rem',
            margin: 0,
          }}>
          {subtopic}
        </div>
      )}
      <ResponsiveContainer width='100%' height={50 * metrics.length + 45}>
        <BarChart
          barSize={50}
          style={{cursor: extended && !tooltip ? 'pointer' : 'cursor'}}
          data={metrics}
          layout='vertical'
          margin={{
            top: extended && category === 'index' ? -8 : 15,
            right: 15,
            left: 15,
          }}>
          <YAxis
            axisLine={false}
            dataKey='variable'
            tick={{fontSize: '95%', fill: 'var(--text-muted)'}}
            tickLine={false}
            type='category'
            width={getYAxisWidth(metrics)}
          />
          {extended && category === 'index' && (
            <XAxis
              allowDataOverflow
              axisLine={false}
              dataKey='index'
              domain={[0, 'auto']}
              tick={{fill: 'var(--text-subtle)'}}
              orientation='top'
              xAxisId={1}
              ticks={[100]}
              tickCount={3}
              tickLine={false}
              type='number'
            />
          )}
          <XAxis
            allowDataOverflow
            axisLine={false}
            domain={category === 'index' && !extended ? [0, 200] : [0, 'auto']}
            tick={{fill: 'var(--text-muted)'}}
            ticks={category === 'index' && extended ? [100] : undefined}
            tickCount={category === 'index' ? 3 : 2}
            tickFormatter={
              category === 'index'
                ? (value, index) => {
                    return index > 1 ? `${value}+` : value;
                  }
                : (value) => `${value}%`
            }
            tickLine={false}
            type='number'
          />
          {(!extended || tooltip) && (
            <Tooltip
              content={<DemographicsTooltip />}
              cursor={false}
              wrapperStyle={{zIndex: 1000}}
            />
          )}
          <Bar
            background={{fill: 'var(--bg-muted)'}}
            dataKey={(x) => getLeftDataKey(category, x)}
            fill={
              category === 'percentage'
                ? 'var(--blue-80)'
                : theme === 'dark'
                ? 'var(--bg-default)'
                : '#d1d5da'
            }
            stackId='a'
            onClick={onBarClick}
            isAnimationActive={false}>
            {category === 'percentage' && (
              <LabelList content={<PercentageLabel metrics={metrics} />} />
            )}
          </Bar>
          {category === 'index' && (
            <Bar
              dataKey={getRightDataKey}
              fill='var(--green)'
              stackId='a'
              shape={<CustomBarWithLine />}
              onClick={onBarClick}
              isAnimationActive={false}>
              <LabelList content={<IndexLabel metrics={metrics} />} />
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
}
