import {useOverlay} from '@core/ui/Overlay';
import TrackingUrlsOverlay from '@analytics/components/tracking-urls/TrackingUrlsOverlay';
import {CampaignDraftProps} from '..';
import {CampaignPathButton, Row, Title} from '../_shared';
import {parseTrackingStatusDisplay} from './_helpers';
import {DisplayStatus} from './_shared';

export function CampaignTrackingUrlsDetails({
  organization,
  campaign,
}: CampaignDraftProps) {
  const [opened, toggleOverlay] = useOverlay();

  if (
    !campaign.campaignDynamics.length &&
    !campaign.campaignStreamings.length
  ) {
    return null;
  }

  const {success, statusText, statusDetails} = parseTrackingStatusDisplay({
    campaign,
    organization,
  });

  return (
    <>
      <Row>
        <Title
          rightContent={
            <CampaignPathButton onClick={() => toggleOverlay(true)}>
              Tracking URLs
            </CampaignPathButton>
          }>
          Confirmed Tracking URLs
        </Title>
        <DisplayStatus
          success={success}
          statusText={statusText}
          statusDetails={statusDetails}
        />
      </Row>
      {opened && (
        <TrackingUrlsOverlay
          campaign={campaign}
          opened={opened}
          organization={organization}
          toggle={toggleOverlay}
        />
      )}
    </>
  );
}
