import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import {Button, ButtonIcon} from '@core/ui/Button';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Icon} from '@core/ui/Icon';
import {FetchedOrganization} from '@analytics/graphql-api';
import {NoPageViewEventsWarning} from './NoPageViewEventsWarning';
import {fmtPixelEvent} from './helpers';

const INSTALLATIONS = [
  'JavaScript',
  'AppsFlyer',
  'Branch',
  'Adjust',
  'Kochava',
  'Segment',
];
interface IPixelVerifiedEventsTableProps {
  organization: FetchedOrganization;
  pixel: {
    key: string;
    createdAt: string;
    pixelEventRecords: Array<{
      id: string;
      name: string;
      eventType: string;
      createdAt: string;
      lastActivity: string;
      lastActivityFlag: string;
      lastUpdatedBy: {
        firstName: string;
        lastName: string;
      };
      installation: string;
    }>;
  };
}

const PixelVerifiedEventsTable = ({
  organization,
  pixel,
}: IPixelVerifiedEventsTableProps): JSX.Element => {
  const toPixelEventsPath = (id: string, kind: string) =>
    createOrgPath(
      organization,
      `manage/pixels/${pixel.key}/events/${id}/${kind}`
    );

  const columns: DataTableColumn[] = [
    {
      title: 'Event Name',
      accessor: 'name',
      type: 'string',
    },
    {
      title: 'Action',
      accessor: 'eventType',
      type: 'string',
      fmt: (v) => fmtPixelEvent(v),
    },
    {
      title: 'Last Edited By',
      accessor: 'lastUpdatedBy',
      type: 'string',
      Cell: ({
        data: {
          lastUpdatedBy: {firstName, lastName},
        },
      }) => (
        <>
          {firstName} {lastName}
        </>
      ),
    },
    {
      title: 'Installation',
      accessor: 'installation',
      type: 'string',
      Cell: ({data: {id, lastActivity, installation}}) => {
        if (!!lastActivity) {
          const installationDisplay = INSTALLATIONS.find(
            (opt) => opt.toUpperCase() === installation
          );
          return <>{installationDisplay}</>;
        }
        return (
          <Button
            variant='outlined'
            color='secondary'
            buttonSize='sm'
            to={toPixelEventsPath(id, 'update')}>
            Install
          </Button>
        );
      },
    },
    {
      title: 'Last Activity',
      accessor: 'active',
      type: 'string',
      Cell: ({data: {lastActivity, lastActivityFlag}}) => {
        let activityDisplay = 'No activity yet';
        if (!!lastActivity) {
          const date = dayjs(lastActivity);
          if (date.isSame(dayjs(), 'day')) activityDisplay = 'Today';
          else activityDisplay = date.format('MM/DD/YYYY');
        }

        return (
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: '0.5rem auto',
              gap: '0.5rem',
            }}>
            <div
              css={{
                alignSelf: 'center',
                background:
                  lastActivityFlag === 'green'
                    ? 'var(--green)'
                    : lastActivityFlag === 'yellow'
                    ? 'var(--highlighter)'
                    : 'var(--red-orange)',
                borderRadius: '50%',
                height: '0.5rem',
                width: '0.5rem',
              }}
            />
            <div>{activityDisplay}</div>
          </div>
        );
      },
    },
    {
      title: '',
      accessor: 'id',
      type: 'string',
      Cell: ({data: {id, index}}) => {
        const modifyIsDisabled =
          !index && dayjs(pixel.createdAt).isSameOrAfter('2022-05-03');
        return (
          <div css={{display: 'flex', gap: '1rem'}}>
            <ButtonIcon
              to={toPixelEventsPath(id, 'update')}
              icon='code-outline'
            />
            {modifyIsDisabled ? (
              <Icon
                icon='pencil'
                rules={({theme}) => ({color: theme.textDisabled})}
              />
            ) : (
              <ButtonIcon to={toPixelEventsPath(id, 'update')} icon='pencil' />
            )}
            {modifyIsDisabled ? (
              <Icon
                icon='bin'
                rules={({theme}) => ({color: theme.textDisabled})}
              />
            ) : (
              <ButtonIcon to={toPixelEventsPath(id, 'delete')} icon='bin' />
            )}
          </div>
        );
      },
    },
  ];

  const data = [...pixel.pixelEventRecords]
    .sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    )
    .map((e, i) => ({...e, index: i}));

  const thereAreNoPageViewEvents = data.every(
    (e) => e.eventType !== 'PAGE_VIEW'
  );

  return (
    <>
      <DataTable
        data={data}
        columns={columns}
        emptyMessageTitle='No verified events found'
      />
      {thereAreNoPageViewEvents && <NoPageViewEventsWarning />}
    </>
  );
};

export default PixelVerifiedEventsTable;
