import {gql} from '@apollo/client';

export default gql`
  mutation removeDomain($input: RemoveDomainInput!) {
    authed {
      success
    }
    removeDomain(input: $input) {
      success
    }
  }
`;
