import {graphql} from '@apollo/client/react/hoc';
import MapDownloads from '@core/components/MapDownloads';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import {AttributionCountriesTable} from '@analytics/components/attribution/AttributionLocationTable';
import CampaignDownloadLocationsQuery from '@analytics/graphql-api/_old_queries/CampaignDownloadLocationsQuery';
import {toCampaignUrl} from '../_routes';

const CampaignLocations = ({
  user,
  organization,
  campaign,
  data: {loading, error, me},
}) => {
  if (loading) {
    return <Loading centered />;
  }

  let locations;
  try {
    locations = me.organization.campaign.downloads.locations;
  } catch (err) {
    error = true;
  }

  if (error) {
    return (
      <Card>
        <EmptyMessage>No location data found.</EmptyMessage>
      </Card>
    );
  }

  const {count, nodes, countries} = locations;
  const shouldDisplayMap = nodes.length > 0;

  return (
    <>
      <CardHeader
        css={`
          margin: 0 var(--spacing-6) var(--spacing-6);
        `}>
        Impressions
      </CardHeader>
      {shouldDisplayMap ? (
        <Card
          rules={() => ({
            border: 0,
            marginBottom: '1.5rem',
            overflow: 'hidden',
            padding: 0,
          })}>
          <MapDownloads
            height={500}
            locations={nodes.map(({latitude, longitude, total}) => ({
              count: total,
              latitude,
              longitude,
            }))}
          />
        </Card>
      ) : null}
      <Card>
        <AttributionCountriesTable
          count={count}
          countries={countries}
          onClickRowPath={({country}) =>
            `${toCampaignUrl(organization, campaign)}/locations/${country}`
          }
        />
      </Card>
    </>
  );
};

export default graphql(CampaignDownloadLocationsQuery, {
  options: ({organization, campaign: {id}}) => {
    return {
      variables: {
        organizationId: organization.id,
        id,
      },
    };
  },
})(CampaignLocations);
