import {useState} from 'react';
import {loginViaSpotify} from '@core/components/SignInWithSpotify/auth';
import SpotifyBanner from '../../components/onboard/SpotifyBanner';
import {
  ContentContainer,
  Footer,
  FooterLink,
  PageContainer,
} from './AccountLayout';
import ConfirmationDialog from './ConfirmationDialog';
import SpotifyHeader from './SpotifyHeader';

enum IsLoadingStates {
  LoginLoading = 'LoginLoading',
  SignUpLoading = 'SignUpLoading',
}

const AccountMigration = () => {
  const [isLoading, setIsLoading] = useState<IsLoadingStates | null>(null);

  const onContinueLogin = async () => {
    setIsLoading(IsLoadingStates.LoginLoading);
    await loginViaSpotify();
    setIsLoading(null);
  };

  const onContinueSignUp = async () => {
    setIsLoading(IsLoadingStates.SignUpLoading);
    await loginViaSpotify();
    setIsLoading(null);
  };

  return (
    <PageContainer>
      <SpotifyBanner advertising />
      <SpotifyHeader
        size={'medium'}
        title='Podsights is now Spotify Ad Analytics'
        subTitle='We may look different, but we still have the same standards for best-in-class measurement with even more ways to prove success.'
      />
      <ContentContainer>
        <ConfirmationDialog
          boxTitle='Log in to your Spotify Advertising account'
          boxBody='Log in to your Spotify account that uses the same email address associated with your Podsights account. Your existing campaign data will be waiting for you.'
          ctaLabel='Log in'
          onContinue={onContinueLogin}
          ctaIsLoading={isLoading === IsLoadingStates.LoginLoading}
          centered={false}
          fixedHeight
          bodyLeftAligned
        />
        <ConfirmationDialog
          boxTitle='Sign up for a Spotify Advertising account'
          boxBody='Sign up for a free Spotify account using the email address associated with your Podsights account. Your existing campaign data will be waiting for you.'
          ctaLabel='Sign up'
          onContinue={onContinueSignUp}
          ctaIsLoading={isLoading === IsLoadingStates.SignUpLoading}
          centered={false}
          fixedHeight
          bodyLeftAligned
        />
      </ContentContainer>
      <Footer>
        Have questions?
        <FooterLink href='https://help.adanalytics.spotify.com/spotify-ad-analytics-faq'>
          Learn more
        </FooterLink>
      </Footer>
    </PageContainer>
  );
};

export default AccountMigration;
