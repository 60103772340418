import React from 'react';
import {toTimeString} from '@core/lib/time';
import {Element} from '@core/style';

const mods = [1, 5, 15, 30, 60, 120, 60 * 5, 60 * 10, 60 * 60];

class AudioGrid extends React.Component {
  state = {
    dragging: false,
    mod: 60 * 5,
  };

  updateMod = () => {
    const {duration} = this.props;

    if (!duration) {
      return;
    }

    // a block is 20px wide, so how many blocks can we fit in the loop?
    const numBlocks = (this.grid.offsetWidth - 40) / 40;

    // How many seconds will fit per block?
    const secondsPerBlock = duration / numBlocks;
    let index = 0;

    // We want to round up to the nearest mod.
    while (secondsPerBlock > mods[index]) {
      index++;
    }

    this.setState({
      mod: mods[index],
    });
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.scale !== this.props.scale ||
      prevProps.duration !== this.props.duration
    ) {
      // Get the new width
      this.updateMod();
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.updateMod();
    }, 10);
  }

  dragStart = (evt) => {
    // Move the track around on drag.

    const {updateTrackOffset} = this.props;

    this.setState({
      dragging: true,
    });

    let last = evt.pageX;

    const _mousemove = (evt) => {
      const diff = last - evt.pageX;
      last = evt.pageX;

      updateTrackOffset(diff);
    };

    const _mouseup = () => {
      document.body.removeEventListener('mousemove', _mousemove);
      document.body.removeEventListener('mouseup', _mouseup);

      this.setState({
        dragging: false,
      });
    };

    document.body.addEventListener('mouseup', _mouseup);
    document.body.addEventListener('mousemove', _mousemove);
  };

  render() {
    const {duration, scale, onMouseDown} = this.props;
    const {mod, dragging} = this.state;
    const range = (n) => [...Array(parseInt(n)).keys()];

    // We need to figure out how many breakpoints we can put in here.
    return (
      <Element
        domRef={(grid) => {
          this.grid = grid;
        }}
        onMouseDown={this.dragStart}
        style-dragging={dragging}
        rules={({dragging}) => ({
          cursor: dragging ? '-webkit-grabbing' : '-webkit-grab',
          display: 'flex',
          height: '24px',
          borderTop: '1px solid #eee',
          borderBottom: '1px solid #eee',
          lineHeight: 1,
          userSelect: 'none',
        })}>
        <Element rules={() => ({flex: 1, position: 'relative'})}>
          {duration
            ? range(duration)
                .filter((v) => v % mod == 0)
                .map((v) => {
                  return (
                    <div
                      key={v}
                      style={{
                        alignItems: 'center',
                        borderLeft: '1px solid #eee',
                        display: 'flex',
                        fontSize: '10px',
                        height: '100%',
                        marginLeft: `${((v / duration) * 100).toFixed(10)}%`,
                        overflow: 'hidden',
                        paddingLeft: '4px',
                        position: 'absolute',
                        width: '40px',
                      }}>
                      {toTimeString(v)}
                    </div>
                  );
                })
            : ''}
        </Element>
      </Element>
    );
  }
}

export default AudioGrid;
