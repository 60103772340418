import {useEffect, useReducer} from 'react';
import {Element} from '@core/style';
import {AudioWaveformCanvas} from '../AudioWaveform';
import AudioAdMarkers from './AudioAdMarkers';
import AudioDuplicates from './AudioDuplicates';
import AudioKeywords from './AudioKeywords';
import AudioPlacement from './AudioPlacement';

const CurrentTimeIndicator = ({currentTime, duration}) => {
  if (currentTime > 1) {
    return (
      <Element
        style={{
          marginLeft: `${((currentTime / duration) * 100).toFixed(5)}%`,
        }}
        rules={() => ({
          background: '#000',
          height: '100%',
          position: 'absolute',
          top: '0',
          width: '2px',
        })}
      />
    );
  }
  return null;
};

const AdInsertionContainer = ({
  createPlacement,
  duration,
  setCurrentTime,
  trackWidth,
}) => {
  const [state, setState] = useReducer(
    (state, newState) => {
      return {...state, ...newState};
    },
    {
      isMouseDown: false,
      containerRect: null,
      selectingArea: false,
      endX: 0,
      startX: 0,
    }
  );

  const onMouseDown = (evt) => {
    if (state.isMouseDown) {
      return;
    }
    document.body.style.userSelect = 'none';
    setState({
      isMouseDown: true,
      startX: evt.pageX,
      containerRect: evt.target.getBoundingClientRect(),
    });
  };

  const onBodyMouseMove = (evt) => {
    if (Math.abs(evt.pageX - state.startX) > 4) {
      setState({endX: evt.pageX, selectingArea: state.endX !== 0});
    }
  };

  const onBodyMouseUp = () => {
    if (selectingArea) {
      document.body.style.userSelect = '';
      const startTime =
        ((state.startX - state.containerRect.left) / trackWidth) * duration;
      const endTime =
        ((state.endX - state.containerRect.left) / trackWidth) * duration;
      createPlacement({startTime, endTime});
    }
    setState({isMouseDown: false, selectingArea: false});
  };

  const onMouseUp = (evt) => {
    if (!state.selectingArea) {
      const containerRect = evt.target.getBoundingClientRect();

      setCurrentTime(
        ((evt.pageX - containerRect.left) / containerRect.width) * duration
      );
    }
  };

  useEffect(() => {
    if (state.isMouseDown) {
      window.addEventListener('mousemove', onBodyMouseMove);
      window.addEventListener('mouseup', onBodyMouseUp);
    } else {
      setState({
        endX: 0,
        startX: 0,
        selectingArea: false,
        containerRect: null,
      });
    }

    return () => {
      window.removeEventListener('mousemove', onBodyMouseMove);
      window.removeEventListener('mouseup', onBodyMouseUp);
    };
  }, [state.isMouseDown, state.endX]);

  const {selectingArea, containerRect, endX, startX} = state;

  return (
    <Element
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onDragStart={(evt) => evt.preventDefault()}
      rules={() => ({
        bottom: 0,
        cursor: 'pointer',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      })}>
      {selectingArea && containerRect ? (
        <Element
          key={endX}
          style={{
            ...(endX < startX
              ? {
                  left: `${endX - containerRect.left}px`,
                  width: `${startX - endX}px`,
                }
              : {
                  width: `${endX - startX}px`,
                  left: `${startX - containerRect.left}px`,
                }),
          }}
          rules={() => ({
            background: 'rgba(50,50,50,0.4)',
            bottom: 0,
            display: 'block',
            position: 'absolute',
            top: 0,
          })}
        />
      ) : null}
    </Element>
  );
};

const AudioTrack = ({
  waveform,
  playing,
  trackWidth,
  setCurrentTime,
  togglePlay,
  currentTime,
  duration,
  placements,
  updatePlacement,
  setActivePlacement,
  duplicates,
  adMarkers,
  diarization,
  keywords,
  showDuplicates,
  showKeywords,
  showAdMarkers,
  createPlacement,
  showPlacements,
  keywordSliceWindow,
}) => {
  const height = 150;

  return (
    <Element rules={() => ({height: '9rem', position: 'relative'})}>
      <AdInsertionContainer
        createPlacement={createPlacement}
        duration={duration}
        trackWidth={trackWidth}
        setCurrentTime={setCurrentTime}
      />
      {duration ? (
        <>
          {showKeywords && keywords ? (
            <AudioKeywords
              height={height}
              keywords={keywords}
              duration={duration}
              keywordSliceWindow={keywordSliceWindow}
            />
          ) : null}
          {showAdMarkers && adMarkers ? (
            <AudioAdMarkers
              height={height}
              adMarkers={adMarkers}
              duration={duration}
            />
          ) : null}
          {showDuplicates && duplicates ? (
            <AudioDuplicates
              height={height}
              duplicates={duplicates}
              duration={duration}
            />
          ) : null}
          <AudioWaveformCanvas trackWidth={trackWidth} waveform={waveform} />
          <CurrentTimeIndicator currentTime={currentTime} duration={duration} />
        </>
      ) : null}
      {showPlacements
        ? placements.map((placement, n) => {
            return (
              <AudioPlacement
                key={n}
                height={height}
                placement={placement}
                duration={duration}
                playing={playing}
                currentTime={currentTime}
                setCurrentTime={setCurrentTime}
                togglePlay={togglePlay}
                trackWidth={trackWidth}
                updatePlacement={updatePlacement}
                setActivePlacement={setActivePlacement}
              />
            );
          })
        : null}
    </Element>
  );
};

export default AudioTrack;
