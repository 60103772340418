import React from 'react';
import DataSelector from '@core/ui/DataSelector';
import {OrganizationObject} from '@analytics/graphql-api';
import PublisherSearchQuery from '@analytics/graphql-api/_old_queries/research/PublishersQuery';

interface Props {
  organization: OrganizationObject;
  placeholder?: string;
  selectableInputValue?: boolean;
  selectableInputValuePlaceholder?: string;
}

export default function PublisherSelect({
  organization,
  placeholder = 'Select Publisher',
  selectableInputValue = false,
  selectableInputValuePlaceholder = 'Create Publisher',
  ...props
}: Props) {
  return (
    <DataSelector
      dataQuery={PublisherSearchQuery}
      fromResponse={(data) => data.me.organization.research.publishers}
      queryVariables={{
        organizationId: organization.id,
      }}
      placeholder={placeholder}
      selectableInputValue={selectableInputValue}
      selectableInputValuePlaceholder={selectableInputValuePlaceholder}
      css={`
        width: 100%;
      `}
      {...props}
    />
  );
}
