import React from 'react';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {toTimeString} from '@core/lib/time';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {Img} from '@core/ui/Image';

const EpisodesTable = ({organization, episodes, ...props}) => {
  const columns = [
    {
      title: 'Episode',
      accessor: 'title',
      type: 'string',
      Cell: ({data: {title, description, image, feed}}) => {
        return (
          <Element rules={() => ({display: 'flex'})}>
            <Element
              rules={() => ({
                flex: '0 0 75px',
                marginRight: '1rem',
              })}>
              <Img image={image ? image : feed.image} width={150} />
            </Element>
            <Element rules={() => ({flex: 1})}>
              <Element
                rules={({theme}) => ({
                  fontWeight: 500,
                  color: theme.textPrimary,
                })}>
                {title}
              </Element>
              <Element
                rules={({theme}) => ({
                  color: theme.textTertiary,
                  fontSize: '0.875rem',
                })}>
                {description}
              </Element>
            </Element>
          </Element>
        );
      },
    },
    {
      title: 'Published',
      accessor: 'published',
      type: 'date',
      fmt: (v) => (v ? dayjs(v).utc().format('MM/DD/YYYY') : '-'),
    },
    {
      title: 'Duration',
      accessor: 'duration',
      type: 'number',
      fmt: toTimeString,
    },
    {
      title: 'Ads',
      accessor: 'placementsCount',
      type: 'number',
      fmt: comma,
    },
  ];

  const data = episodes.map(
    ({
      id,
      title,
      description,
      published,
      placementsCount,
      image,
      feed,
      enclosure: {length},
    }) => ({
      id,
      published,
      title,
      description,
      duration: length,
      placementsCount,
      image,
      feed: feed ? feed : props.feed,
    })
  );

  return (
    <DataTable
      columns={columns}
      orderBy='-published'
      data={data}
      onClickRowPath={({feed, id}) =>
        `/research/podcasts/${feed.id}/episodes/${id}`
      }
    />
  );
};

export default EpisodesTable;
