import {FC} from 'react';
import {Icon} from '@core/ui/Icon';

interface IOSIconProps {
  os: {
    family: 'iOS' | 'Android' | 'Windows' | 'macOS' | 'Mac OS X';
  };
  width?: number;
}

const OSIcon: FC<IOSIconProps> = ({os, width = 24}): JSX.Element | null => {
  if (os.family === 'iOS' || os.family === 'Mac OS X') {
    return <Icon icon='apple' rules={() => ({width: `${width}px`})} />;
  }

  if (os.family === 'Android') {
    return <Icon icon='android' rules={() => ({width: `${width}px`})} />;
  }

  if (os.family === 'Windows') {
    return <Icon icon='windows' rules={() => ({width: `${width}px`})} />;
  }

  return null;
};

export default OSIcon;
