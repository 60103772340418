import {gql} from '@apollo/client';
import CampaignDynamicEpisodeFragment from './fragments/CampaignDynamicEpisodeFragment';
import CampaignDynamicPodcastFragment from './fragments/CampaignDynamicPodcastFragment';

export default gql`
  query getCampaignDynamicPodcast(
    $organizationId: ID!
    $campaignId: ID!
    $campaignDynamicId: ID!
    $id: ID!
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          campaignDynamic(id: $campaignDynamicId) {
            id
            campaignDynamicPodcast(id: $id) {
              ...CampaignDynamicPodcast

              campaignDynamicEpisodes {
                ...CampaignDynamicEpisode
              }
            }
          }
        }
      }
    }
  }
  ${CampaignDynamicPodcastFragment}
  ${CampaignDynamicEpisodeFragment}
`;
