import React from 'react';
import {createOrgPath} from '@core/lib/organizations';
import {stripHTML, truncateWords} from '@core/lib/words';
import {Element} from '@core/style';
import {FeedImage} from '@core/ui/Image';
import {Link} from '@core/ui/Link';
import Stat from '@core/ui/Stat';

const Feed = ({organization, feed}) => {
  return (
    <Element rules={() => ({flex: 1})}>
      <Element rules={() => ({display: 'flex', margin: '15px 0'})}>
        <Element rules={() => ({flex: '0 0 100px', padding: '5px'})}>
          <FeedImage feed={feed} width={100} />
        </Element>
        <Element
          rules={() => ({
            flex: 1,
            marginLeft: '10px',
            paddingLeft: '10px',
            borderLeft: '1px solid #efefef',
          })}>
          <Element
            rules={() => ({
              display: 'flex',
              alignItems: 'center',
            })}>
            <Element tag='h6' rules={() => ({flex: 1})}>
              <Link
                to={createOrgPath(
                  organization,
                  `/research/podcasts/${feed.id}`
                )}>
                {feed.title}
              </Link>
            </Element>
            {feed.episodesCount && (
              <Stat value={feed.episodesCount} title='episodes' show />
            )}

            {feed.placementsCount && (
              <Stat value={feed.placementsCount} title='ads' show />
            )}
          </Element>

          <Element
            tag='p'
            rules={() => ({
              fontSize: '14px',
              marginBottom: '0',
              color: '#666',
            })}>
            {truncateWords(stripHTML(feed.subtitle || feed.summary || ''), 50)}
          </Element>
        </Element>
      </Element>
    </Element>
  );
};

export default Feed;
