import {gql} from '@apollo/client';

export default gql`
  query getReviewerAd($organizationId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          reviewing {
            ad(id: $id) {
              id

              mpegPath
              flacPath
              waveform
              words
              state

              company {
                id
                name
              }
              product {
                id
                name
                url
                discountCode
              }

              sponsor {
                id
                name
              }

              placements {
                id
                episode {
                  id
                  title
                  subtitle
                  summary
                  feed {
                    id
                  }
                  image {
                    href
                    path
                  }
                }
              }
              guess {
                id
                kind
                body
                companyId
                companyName
                productId
                productName
                maxScore
                score
                duration
              }

              reviews {
                id
                startState
                reviewer {
                  id
                  firstName
                  lastName
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;
