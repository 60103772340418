import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedCampaignDynamic,
  FetchedCampaignDynamicEpisode,
  FetchedCampaignDynamicPodcast,
  FetchedCampaignStreaming,
  FetchedOrganization,
  SuperCampaignOmniChartDocument,
  SuperCampaignOmniChartQuery,
} from '@analytics/graphql-api';
import BrandCampaignDynamicEpisodeOmniChartQuery from '@analytics/graphql-api/_old_queries/BrandCampaignDynamicEpisodeOmniChartQuery';
import BrandCampaignDynamicOmniChartQuery from '@analytics/graphql-api/_old_queries/BrandCampaignDynamicOmniChartQuery';
import BrandCampaignDynamicPodcastOmniChartQuery from '@analytics/graphql-api/_old_queries/BrandCampaignDynamicPodcastOmniChartQuery';
import BrandCampaignEpisodeOmniChartQuery from '@analytics/graphql-api/_old_queries/BrandCampaignEpisodeOmniChartQuery';
import BrandCampaignOmniChartQuery from '@analytics/graphql-api/_old_queries/BrandCampaignOmniChartQuery';
import BrandCampaignPodcastOmniChartQuery from '@analytics/graphql-api/_old_queries/BrandCampaignPodcastOmniChartQuery';
import BrandCampaignStreamingOmniChartQuery from '@analytics/graphql-api/_old_queries/BrandCampaignStreamingOmniChartQuery';
import {getSuperCampaignIds} from '../spotify-orders/_helpers';
import {SuperCampaign} from '../spotify-orders/_types';
import CampaignOmniChartBase from './CampaignOmniChartBase';
import {mergeSuperCampaignData} from './helpers';

export const CampaignOmniChart = ({
  campaign,
  organization,
  ...props
}: {
  campaign: FetchedCampaign;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) => {
  return (
    <CampaignOmniChartBase
      organization={organization}
      campaign={campaign}
      dataAccessorFn={(data) => data.me.organization.campaign}
      query={BrandCampaignOmniChartQuery}
      queryVariables={{
        id: campaign.id,
      }}
      {...props}
    />
  );
};

export const SuperCampaignOmniChart = ({
  superCampaign,
  organization,
  ...props
}: {
  superCampaign: SuperCampaign;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) => {
  const {id1, id2, id3, id4} = getSuperCampaignIds({superCampaign});
  return (
    <CampaignOmniChartBase
      organization={organization}
      campaign={superCampaign}
      dataAccessorFn={(data: SuperCampaignOmniChartQuery) => {
        const campaigns = Object.values(data?.me?.organization ?? {}).filter(
          (c) => typeof c !== 'string'
        );
        return mergeSuperCampaignData(campaigns);
      }}
      query={SuperCampaignOmniChartDocument}
      queryVariables={{
        organizationId: organization.id,
        id1,
        id2,
        id3,
        id4,
        include2: !!id2,
        include3: !!id3,
        include4: !!id4,
      }}
      {...props}
    />
  );
};

export const CampaignDynamicOmniChart = ({
  campaign,
  campaignDynamic,
  organization,
  ...props
}: {
  campaign: FetchedCampaign;
  campaignDynamic: FetchedCampaignDynamic;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) => {
  return (
    <CampaignOmniChartBase
      organization={organization}
      campaign={campaign}
      dataAccessorFn={(data) => data.me.organization.campaign.campaignDynamic}
      query={BrandCampaignDynamicOmniChartQuery}
      queryVariables={{
        campaignId: campaign.id,
        id: campaignDynamic?.id,
      }}
      {...props}
    />
  );
};

export const CampaignDynamicPodcastOmniChart = ({
  campaign,
  campaignDynamic,
  campaignDynamicPodcast,
  organization,
  ...props
}: {
  campaign: FetchedCampaign;
  campaignDynamic: FetchedCampaignDynamic;
  campaignDynamicPodcast: FetchedCampaignDynamicPodcast;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) => {
  return (
    <CampaignOmniChartBase
      organization={organization}
      campaign={campaign}
      dataAccessorFn={(data) =>
        data.me.organization.campaign.campaignDynamic.campaignDynamicPodcast
      }
      query={BrandCampaignDynamicPodcastOmniChartQuery}
      queryVariables={{
        organizationId: organization.id,
        campaignId: campaign.id,
        campaignDynamicId: campaignDynamic?.id,
        id: campaignDynamicPodcast?.id,
      }}
      {...props}
    />
  );
};

export const CampaignPodcastOmniChart = ({
  campaign,
  campaignPodcast,
  organization,
  ...props
}: {
  campaign: FetchedCampaign;
  campaignPodcast: TSFixMe; // TODO
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) => {
  return (
    <CampaignOmniChartBase
      organization={organization}
      campaign={campaign}
      dataAccessorFn={(data) => {
        const {attribution, downloads, episodes} =
          data.me.organization.campaign.campaignPodcast;

        return {
          attribution,
          downloads,
          campaignPodcasts: [{feed: campaignPodcast.feed, episodes}],
        };
      }}
      query={BrandCampaignPodcastOmniChartQuery}
      queryVariables={{
        organizationId: organization.id,
        campaignId: campaign.id,
        id: campaignPodcast.id,
      }}
      {...props}
    />
  );
};

export const CampaignDynamicEpisodeOmniChart = ({
  organization,
  campaign,
  campaignDynamic,
  campaignDynamicPodcast,
  campaignDynamicEpisode,
  ...props
}: {
  campaign: FetchedCampaign;
  campaignDynamic: FetchedCampaignDynamic;
  campaignDynamicPodcast: FetchedCampaignDynamicPodcast;
  campaignDynamicEpisode: FetchedCampaignDynamicEpisode;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) => {
  return (
    <CampaignOmniChartBase
      organization={organization}
      campaign={campaign}
      dataAccessorFn={(data) =>
        data.me.organization.campaign.campaignDynamic.campaignDynamicPodcast
          .campaignDynamicEpisode
      }
      query={BrandCampaignDynamicEpisodeOmniChartQuery}
      queryVariables={{
        organizationId: organization.id,
        campaignId: campaign.id,
        campaignDynamicId: campaignDynamic?.id,
        campaignDynamicPodcastId: campaignDynamicPodcast?.id,
        id: campaignDynamicEpisode?.id,
      }}
      withoutDatePicker
      {...props}
    />
  );
};

export const CampaignEpisodeOmniChart = ({
  campaign,
  campaignEpisode,
  organization,
  ...props
}: {
  campaign: FetchedCampaign;
  campaignEpisode: TSFixMe; // TODO
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) => {
  return (
    <CampaignOmniChartBase
      organization={organization}
      campaign={campaign}
      dataAccessorFn={(data) => data.me.organization.campaign.campaignEpisode}
      query={BrandCampaignEpisodeOmniChartQuery}
      queryVariables={{
        organizationId: organization.id,
        campaignId: campaign.id,
        id: campaignEpisode.id,
      }}
      withoutDatePicker
      {...props}
    />
  );
};

export const CampaignStreamingOmniChart = ({
  campaign,
  campaignStreaming,
  organization,
  ...props
}: {
  campaign: FetchedCampaign;
  campaignStreaming: FetchedCampaignStreaming;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) => {
  return (
    <CampaignOmniChartBase
      organization={organization}
      campaign={campaign}
      dataAccessorFn={(data) => data.me.organization.campaign.campaignStreaming}
      query={BrandCampaignStreamingOmniChartQuery}
      queryVariables={{
        campaignId: campaign.id,
        id: campaignStreaming?.id,
      }}
      {...props}
    />
  );
};
