import {Card} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {CampaignKind} from '@analytics/components/campaigns/_types';
import {CampaignCreateSteps} from '@analytics/components/campaigns/create/CampaignCreateSteps';
import {CampaignCreateForm} from '@analytics/components/campaigns/forms';
import {CampaignsRouteProps} from '../../_routes';

export function BrandCampaignDetails({
  organization,
  match: {
    params: {kind, pubId, pubName},
  },
}: CampaignsRouteProps & {
  match: {
    params: {kind: CampaignKind; pubId: string; pubName: string};
  };
}) {
  return (
    <>
      <CampaignCreateSteps active='campaign-details' isBrand />
      <Card>
        <Stack direction='column' spacing='var(--spacing-4)'>
          <Text>
            Campaigns are created in draft mode allowing you to build out the
            campaign over time. When you are ready, you can invite the
            advertiser to view the campaign.
          </Text>
          <CampaignCreateForm
            /* Brands only have 1 company */
            companyId={organization.companies?.[0]?.id}
            kind={kind}
            organization={organization}
            pubOrg={{
              id: pubId,
              name: pubName,
            }}
          />
        </Stack>
      </Card>
    </>
  );
}
