import {gql} from '@apollo/client';
import CampaignEpisodeFragment from '@analytics/graphql-api/_old_queries/fragments/CampaignEpisodeFragment';

export default gql`
  mutation updateCampaignEpisode($input: UpdateCampaignEpisodeInput!) {
    authed {
      success
    }
    updateCampaignEpisode(input: $input) {
      campaignEpisode {
        ...CampaignEpisode
      }
    }
  }
  ${CampaignEpisodeFragment}
`;
