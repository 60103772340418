import {createOrgPath} from '@core/lib/organizations';
import {FeedImage} from '@core/ui/Image';
import {Nav, NavItem} from '@core/ui/Nav';
import {PodcastRouteProps} from '@analytics/screens/unified/podcasts/podcast';
import {podcastURL} from './PodcastNav';

const podcastAudienceURL = (
  organization: PodcastRouteProps['organization'],
  podcast: PodcastRouteProps['podcast']
) => createOrgPath(organization, `/podcasts/${podcast.id}/audience`);

const PodcastAudienceNav = ({
  organization,
  podcast,
}: Omit<PodcastRouteProps, 'user'>) => {
  const url = podcastAudienceURL(organization, podcast);

  const {feed} = podcast;

  return (
    <Nav
      title={feed?.title}
      description={feed?.description}
      Image={() => <FeedImage feed={feed} width={150} />}
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/podcasts'),
          title: 'Podcasts',
        },
        {
          to: podcastURL(organization, podcast),
          title: podcast.feed?.title,
        },
        {
          title: 'Audience',
        },
      ]}>
      <NavItem to={url}>Demographics</NavItem>
      <NavItem to={`${url}/intent`}>Purchase Intent</NavItem>
    </Nav>
  );
};

export {podcastAudienceURL};

export default PodcastAudienceNav;
