import {isSelling} from '@core/lib/organizations';
import {exists, jsonToCsv, removeNonLatinChars} from '@core/lib/utils';
import {
  CampaignDynamicObject,
  CampaignDynamicProviderName,
  CampaignStreamingObject,
  CampaignStreamingProviderName,
  CampaignTrackingUrlsQuery,
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedCampaignsConfirmTrackingUrls,
  FetchedOrganization,
  ProviderNameObject,
} from '@analytics/graphql-api';

export type DynamicTrackingData = CampaignDynamicObject & {format: 'dynamic'};
export type StreamingTrackingData = CampaignStreamingObject & {
  format: 'streaming';
};
export type CampaignTrackingData = DynamicTrackingData | StreamingTrackingData;

type GetTrackingUrlsTableDataFn = (
  _data: CampaignTrackingUrlsQuery | undefined
) => CampaignTrackingData[];

export const getTrackingUrlsTableData: GetTrackingUrlsTableDataFn = (data) => {
  if (!data) return [];

  const campaignDynamics = (
    (data?.me?.organization?.campaign?.campaignDynamics ?? []).filter(
      (d) => !!d
    ) as CampaignDynamicObject[]
  ).map((d) => ({
    ...d,
    format: 'dynamic' as const,
    providerNames: (
      (d.providerNames ?? []).filter(
        (p) => !!p && exists(p.providerNameDisplay)
      ) as {
        __typename: 'ProviderNameObject';
        providerName: CampaignDynamicProviderName;
        providerNameDisplay: string;
      }[]
    ).sort((a, b) => a.providerName?.localeCompare(b?.providerName)),
  }));

  const campaignStreamings = (
    (data?.me?.organization?.campaign?.campaignStreamings ?? []).filter(
      (s) => !!s
    ) as CampaignStreamingObject[]
  ).map((s) => ({
    ...s,
    format: 'streaming' as const,
    providerNames: (
      (s.providerNames ?? []).filter(
        (p) => !!p && exists(p.providerNameDisplay)
      ) as {
        __typename: 'ProviderNameObject';
        providerName: CampaignStreamingProviderName;
        providerNameDisplay: string;
      }[]
    ).sort((a, b) => a.providerName?.localeCompare(b?.providerName)),
  }));

  return [...campaignDynamics, ...campaignStreamings];
};

export const showTrackingConfirm = (
  organization: FetchedOrganization | FetchedBuySideOrganization,
  campaign: FetchedCampaign | FetchedCampaignsConfirmTrackingUrls[number]
) =>
  isSelling(organization) &&
  (campaign?.state === 'DRAFT' ||
    campaign?.state === 'READY' ||
    // API typing is incorrect & needs to be updated - pending is a valid campaign state that needs checked
    campaign?.state === 'PENDING');

export const generateCsvString = (tableData: CampaignTrackingData[]) => {
  return jsonToCsv(
    tableData.map((item) => ({
      lineItem: removeNonLatinChars(item.name),
      provider: item.providerName,
      format: item.format,
      url: item.providerTrackingUrl,
    }))
  );
};

export const getTrackingSelectItems = (data: CampaignTrackingData[]) => {
  return {
    dynamicItems:
      (data.find((d) => d.format === 'dynamic')
        ?.providerNames as ProviderNameObject[]) ?? [],
    streamingItems:
      (data.find((d) => d.format === 'streaming')
        ?.providerNames as ProviderNameObject[]) ?? [],
  };
};
