import React from 'react';
import {useQuery} from '@apollo/client';
import {Helmet} from 'react-helmet-async';
import {Redirect, Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {IOrganizationCompany} from '@core/types';
import Loading from '@core/ui/Loading';
import {CompanyNav} from '@analytics/components/nav/ResearchNav';
import {FetchedOrganization} from '@analytics/graphql-api';
import COMPANY_QUERY from '@analytics/graphql-api/_old_queries/research/CompanyQuery';
import {researchCompanyRoutes} from '@analytics/lib/routes/researchRoutes';
import CompanyEdit from './CompanyEdit';
import CompanyExport from './CompanyExport';
import CompanyOverview from './CompanyOverview';
import Ads from './ads/Ads';
import AdIndex from './ads/ad';
import FeedsIndex from './feeds';
import SimilarIndex from './similar';

interface IWrappedProps {
  organization: FetchedOrganization;
  user: FetchedUser;
  company: IOrganizationCompany;
}

// workaround to show nav
const withCompanyNav = (
  Wrapped: React.FC<IWrappedProps> | React.ComponentClass<any, IWrappedProps>
): React.FC => {
  return (props: IWrappedProps) => (
    <>
      <CompanyNav {...props} />
      <Wrapped {...props} />
    </>
  );
};

interface ICompanyIndexProps {
  user: FetchedUser;
  organization: FetchedOrganization;
  match: {
    params: {
      companyId: string;
    };
  };
}

const CompanyIndex = ({
  user,
  organization,
  match: {
    params: {companyId},
  },
}: ICompanyIndexProps): JSX.Element => {
  const {data, loading, error} = useQuery(COMPANY_QUERY, {
    variables: {
      organizationId: organization.id,
      id: companyId,
    },
  });

  if (loading) {
    return <Loading fixed />;
  }

  if (error) {
    return <Redirect to={createOrgPath(organization, 'research/brands')} />;
  }

  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, `research/brands/${companyId}`),
    basePath: '/:part(b|a|s|p)/:slug/research/brands/:companyId',
    routes: researchCompanyRoutes(),
  });

  const company = data?.me?.organization?.research?.company;

  return (
    <>
      <Helmet>
        <title>{company.name} | Ad Analytics Research</title>
      </Helmet>
      <Switch>
        {renderRoutes({
          components: {
            overview: withCompanyNav(CompanyOverview),
            export: withCompanyNav(CompanyExport),
            edit: withCompanyNav(CompanyEdit),
            podcasts: withCompanyNav(FeedsIndex),
            ads: withCompanyNav(Ads),
            ad: AdIndex,
            similar: withCompanyNav(SimilarIndex),
          },
          extraProps: {
            user,
            organization,
            company,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
};

export default CompanyIndex;
