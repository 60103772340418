import {gql} from '@apollo/client';
import DynamicApprovalFragment from '@analytics/graphql-api/_old_queries/fragments/DynamicApprovalFragment';
import PodcastApprovalFragment from '@analytics/graphql-api/_old_queries/fragments/PodcastApprovalFragment';
import StreamingApprovalFragment from '@analytics/graphql-api/_old_queries/fragments/StreamingApprovalFragment';

export default gql`
  mutation updateCampaignApprovals($input: UpdateCampaignApprovalsInput!) {
    authed {
      success
    }
    updateCampaignApprovals(input: $input) {
      dynamicApprovals {
        ...DynamicApproval
      }
      podcastApprovals {
        ...PodcastApproval
      }
      streamingApprovals {
        ...StreamingApproval
      }
    }
  }
  ${DynamicApprovalFragment}
  ${PodcastApprovalFragment}
  ${StreamingApprovalFragment}
`;
