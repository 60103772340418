import isBase64 from './isBase64';
var formats = {
  '.woff': 'woff',
  '.woff2': 'woff2',
  '.eot': 'embedded-opentype',
  '.ttf': 'truetype',
  '.otf': 'opentype',
  '.svg': 'svg',
  '.svgz': 'svg'
};
var base64Formats = {
  'image/svg+xml': 'svg',
  'application/x-font-woff': 'woff',
  'application/font-woff': 'woff',
  'application/x-font-woff2': 'woff2',
  'application/font-woff2': 'woff2',
  'font/woff2': 'woff2',
  'application/octet-stream': 'truetype',
  'application/x-font-ttf': 'truetype',
  'application/x-font-truetype': 'truetype',
  'application/x-font-opentype': 'opentype',
  'application/vnd.ms-fontobject': 'embedded-opentype',
  'application/font-sfnt': 'sfnt'
};
export default function getFontFormat(src) {
  if (isBase64(src)) {
    var mime = '';

    for (var i = 5;; i++) {
      // 'data:'.length === 5
      var c = src.charAt(i);

      if (c === ';' || c === ',') {
        break;
      }

      mime += c;
    }

    var fmt = base64Formats[mime];

    if (fmt) {
      return fmt;
    }

    console.warn("A invalid base64 font was used. Please use one of the following mime type: ".concat(Object.keys(base64Formats).join(', '), "."));
  } else {
    var extension = '';

    for (var _i = src.length - 1;; _i--) {
      var _c = src.charAt(_i);

      if (_c === '.') {
        // fetches all the string from the gotten dot to the end
        // of the string
        var strippedSrc = src.slice(_i, src.length); // removes all query string that are usually attached to the
        // font face strings e.g ./font-location/font.woff2?some-query
        // Reference: https://github.com/robinweser/fela/issues/642

        extension = strippedSrc.includes('?') ? strippedSrc.split('?', 1)[0] : strippedSrc;
        break;
      }
    }

    var _fmt = formats[extension];

    if (_fmt) {
      return _fmt;
    }

    console.warn("A invalid font-format was used in \"".concat(src, "\". Use one of these: ").concat(Object.keys(formats).join(', '), "."));
  }

  return '';
}