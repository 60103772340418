import {useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import styled from 'styled-components';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {Submit} from '@core/ui/FormElements';
import PactsafeClickwrap from '@core/ui/PactsafeClickwrap';
import {AdvisorImage} from '@analytics/components/advisor/onboarding/AdvisorImage';
import {useAddOrganizationAdvisorMutation} from '@analytics/graphql-api';
import {AdvisorRouteProps} from '../../_routes';

export function AdvisorOnboardPublisher({
  user,
  organization,
}: AdvisorRouteProps) {
  const {url} = useRouteMatch();
  const history = useHistory();
  const [addOrganizationAdvisor] = useAddOrganizationAdvisorMutation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [sendData, setSendData] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    setSendData(true);
    try {
      await addOrganizationAdvisor({
        variables: {
          input: {
            id: organization.id,
          },
        },
      });
      history.push(`${url}/podcasts`);
    } catch (err) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <>
      <CardHeader>Welcome to Advisor!</CardHeader>
      <Card>
        <AdvisorImage />
        {error && (
          <Alert severity='error' css={{margin: '0.375rem 0 1rem'}}>
            {t('errors.network')}
          </Alert>
        )}
        <p>
          Advisor is the best way for publishers to get podcasts in front of
          brands. Join publishers like Spotify, NPR, Wondery, PodcastOne, and
          many more:
        </p>
        <ul>
          <li>Be discoverable to brands across all industries</li>
          <li>
            Bring valuable audience insights to brands through Nielsen and
            Experian
          </li>
          <li>
            Monetize your remaining podcast inventory by allowing advertisers to
            add your podcast(s) to their media plans within Advisor
          </li>
          <li>Let brands contact you directly</li>
        </ul>
        <PactsafeClickwrap
          groupKey='group-syvpp4y3c'
          sendData={sendData}
          onChecked={setAcceptedTerms}
          signerId={user.email}
        />
        <_ButtonContainer>
          <Submit
            type='button'
            color='secondary'
            disabled={!acceptedTerms}
            loading={loading}
            onClick={onSubmit}>
            Get Started
          </Submit>
        </_ButtonContainer>
      </Card>
    </>
  );
}

const _ButtonContainer = styled.div`
  text-align: right;
  margin-top: 1rem;
`;
