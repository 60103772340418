import {useState} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {Element} from '@core/style';
import {t} from '@core/texts';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import Loading from '@core/ui/Loading';
import SegmentedControls from '@core/ui/SegmentedControls';
import Select from '@core/ui/Select';
import {Tooltip} from '@core/ui/Tooltip';
import CreateUserAccessMutation from '@analytics/graphql-api/_old_mutations/CreateUserAccess';
import DeleteUserAccessMutation from '@analytics/graphql-api/_old_mutations/DeleteUserAccess';
import UserAccessDetailQuery from '@analytics/graphql-api/_old_queries/access/UserAccessDetailQuery';

const PERMISSIONS = {
  canView: 'can-view',
  canEdit: 'can-edit',
};

const CONTENT_TYPES = {
  campaign: 'campaigns.campaign',
  advertiser: 'ads.company',
};

const SELECT_ITEMS = [
  {title: 'Can View', value: PERMISSIONS.canView},
  {title: 'Can Edit', value: PERMISSIONS.canEdit},
];

const getTableData = ({campaigns, memberAccess}) => {
  const memberAccessMap = memberAccess.reduce(
    (obj, d) => {
      if (d.model === 'company') {
        obj.advertisers[d.objectPk] = d;
      } else if (d.model === 'campaign') {
        obj.campaigns[d.objectPk] = d;
      }
      return obj;
    },
    {advertisers: {}, campaigns: {}}
  );

  const advertisersData = campaigns.reduce((arr, {advertiser}) => {
    const match = arr.find((d) => d.id === advertiser?.id);

    if (match) {
      match.campaignsTotal += 1;
    } else {
      arr.push({
        id: advertiser.id,
        name: advertiser.name,
        campaignsTotal: 1,
        memberAccess: memberAccessMap.advertisers[advertiser.id],
      });
    }
    return arr;
  }, []);

  const campaignsData = campaigns.map((campaign) => {
    const inheritBrandAccess =
      memberAccessMap.advertisers[campaign?.advertiser?.id];

    return {
      id: campaign?.id,
      name: campaign?.name,
      advertiserName: campaign?.advertiser?.name,
      propertyForSearch: `${campaign?.name}-${campaign?.advertiser?.name}`,
      inheritBrandAccess,
      memberAccess: memberAccessMap.campaigns[campaign?.id],
    };
  });

  return {
    advertisersData,
    campaignsData,
  };
};

const AdvertisersPermissionsTable = ({data, onPermissionToggle}) => {
  const columns = [
    {
      title: 'Advertiser',
      accessor: 'name',
      type: 'string',
    },
    {
      title: 'Campaigns',
      accessor: 'campaignsTotal',
      type: 'number',
    },
    {
      title: 'Permissions',
      Cell: ({data}) => {
        return (
          <Select
            small
            removeOption
            placeholder='Select permission'
            defaultValue={data.memberAccess ? data.memberAccess.permission : ''}
            items={SELECT_ITEMS}
            onSelect={(item) => {
              onPermissionToggle({
                checked: !!item,
                advertiser: data,
                permission: item ? item.value : '',
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      searchKeys={['name']}
      searchPlaceholder='Search'
      emptyMessageText='No advertisers found.'
    />
  );
};

const CampaignsPermissionsTable = ({data, onPermissionToggle}) => {
  const columns = [
    {
      title: 'Campaign',
      accessor: 'name',
      type: 'string',
    },
    {
      title: 'Advertiser',
      accessor: 'advertiserName',
      type: 'string',
    },
    {
      title: 'Permissions',
      Cell: ({data}) => {
        const defaultValue = data.inheritBrandAccess
          ? data.inheritBrandAccess.permission
          : data.memberAccess
          ? data.memberAccess.permission
          : '';

        return (
          <Tooltip
            active={!!data.inheritBrandAccess}
            description={t('permissions.inheritBrandAccess.description')}>
            <Select
              small
              removeOption
              placeholder='Select permission'
              defaultValue={defaultValue}
              items={SELECT_ITEMS}
              disabled={!!data.inheritBrandAccess}
              onSelect={(item) => {
                onPermissionToggle({
                  checked: !!item,
                  campaign: data,
                  permission: item ? item.value : '',
                });
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      searchKeys={['propertyForSearch']}
      searchPlaceholder='Search'
      emptyMessageText='No campaigns found.'
    />
  );
};

const MembershipPermissionsForm = ({
  addUserAccess,
  data,
  organization,
  removeUserAccess,
}) => {
  if (data.loading) {
    return (
      <>
        <CardHeader>Permissions</CardHeader>
        <Card>
          <Loading centered />
        </Card>
      </>
    );
  }

  const member = data?.me?.organization?.member;
  const memberAccess = data?.me?.organization?.memberAccess;
  const campaigns = data?.me?.organization?.campaigns?.results ?? [];

  const _campaigns = campaigns.filter((c) => !!c?.advertiser);
  // States
  const [view, setView] = useState('advertisers');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const callMutation = async (callback) => {
    setErrors([]);
    setLoading(true);
    try {
      await callback();
    } catch (err) {
      console.warn(err);
      setLoading(false);
      setErrors([err]);
    }
    setLoading(false);
  };

  const addMemberAccess = async ({contentType, permission, objectPk}) => {
    return callMutation(() =>
      addUserAccess({
        organizationId: organization.id,
        userId: member.id,
        contentType,
        permission,
        objectPk,
      })
    );
  };

  const removeMemberAccess = ({userAccessId}) => {
    return callMutation(() => removeUserAccess({userAccessId}));
  };

  const onAdvertisersPermissionToggle = async ({
    checked,
    permission,
    advertiser,
  }) => {
    if (!checked || advertiser.memberAccess) {
      await removeMemberAccess({userAccessId: advertiser.memberAccess.id});
    }
    if (checked) {
      await addMemberAccess({
        contentType: CONTENT_TYPES.advertiser,
        permission,
        objectPk: advertiser.id,
      });
    }
  };

  const onCampaignsPermissionsToggle = async ({
    checked,
    campaign,
    permission,
  }) => {
    if (!checked || campaign.memberAccess) {
      await removeMemberAccess({userAccessId: campaign.memberAccess.id});
    }
    if (checked) {
      await addMemberAccess({
        contentType: CONTENT_TYPES.campaign,
        permission,
        objectPk: campaign.id,
      });
    }
  };

  const {advertisersData, campaignsData} = getTableData({
    campaigns: _campaigns,
    memberAccess,
  });

  return (
    <>
      <CardHeader>Permissions</CardHeader>
      <Card>
        <Element
          rules={() => ({
            marginBottom: '1rem',
            textAlign: 'center',
          })}>
          <SegmentedControls
            items={[
              {title: 'Advertisers', value: 'advertisers'},
              {title: 'Campaigns', value: 'campaigns'},
            ]}
            onChange={({value}) => setView(value)}
          />
        </Element>
        <Element
          style-loading={loading}
          rules={({loading}) => ({
            pointerEvents: loading ? 'none' : 'initial',
            position: 'relative',
          })}>
          {loading ? <Loading absolute /> : null}
          {view === 'advertisers' ? (
            <AdvertisersPermissionsTable
              data={advertisersData}
              onPermissionToggle={onAdvertisersPermissionToggle}
            />
          ) : view === 'campaigns' ? (
            <CampaignsPermissionsTable
              data={campaignsData}
              onPermissionToggle={onCampaignsPermissionsToggle}
            />
          ) : null}
        </Element>
      </Card>
    </>
  );
};

export default compose(
  graphql(UserAccessDetailQuery, {
    options: ({organization, membership}) => {
      return {
        variables: {
          organizationId: organization.id,
          membershipId: membership.id,
        },
      };
    },
  }),
  graphql(CreateUserAccessMutation, {
    props: ({mutate, ownProps: {organization, membership}}) => ({
      addUserAccess: (input) => {
        return mutate({
          variables: {
            input,
          },
          update: (
            store,
            {
              data: {
                createUserAccess: {userAccess},
              },
            }
          ) => {
            // Stuff the new journey into the cache.
            const query = {
              query: UserAccessDetailQuery,
              variables: {
                organizationId: organization.id,
                membershipId: membership.id,
              },
            };

            query.data = store.readQuery(query);

            query.data.me.organization.memberAccess =
              query.data.me.organization.memberAccess.concat([userAccess]);

            store.writeQuery(query);
          },
        });
      },
    }),
  }),
  graphql(DeleteUserAccessMutation, {
    props: ({mutate, ownProps: {organization, membership}}) => ({
      removeUserAccess: ({userAccessId}) => {
        return mutate({
          variables: {
            input: {userAccessId},
          },
          update: (
            store,
            {
              data: {
                deleteUserAccess: {sucess},
              },
            }
          ) => {
            // Stuff the new journey into the cache.
            const query = {
              query: UserAccessDetailQuery,
              variables: {
                organizationId: organization.id,
                membershipId: membership.id,
              },
            };

            query.data = store.readQuery(query);

            query.data.me.organization.memberAccess =
              query.data.me.organization.memberAccess.filter(
                (m) => m.id !== userAccessId
              );

            store.writeQuery(query);
          },
        });
      },
    }),
  })
)(MembershipPermissionsForm);
