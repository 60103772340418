import {gql} from '@apollo/client';

export default gql`
  query searchAgencies($query: String!) {
    me {
      agencySearch(query: $query) {
        id
        name
        slug
      }
    }
  }
`;
