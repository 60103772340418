import {gql} from '@apollo/client';

export default gql`
  query Organization($slug: String!) {
    me {
      id
      hasSeenAdAnalyticsWelcome
      adAnalyticsTutorialProgress
      organization(slug: $slug) {
        id
        isLockedForTerms
        name
        slug
        kind
        mode
        downloadAlgorithm
        dynamicProvider
        streamingProvider
        showTerms
        showTermsReminder
        hasActivePixel
        isNielsenApproved
        isExperianApproved
        isSegmentsEnabled
        isAdvisor
        isManager
        meRole
        createdAt
        campaignsCount
        podcastsCount
        adStudioBusinessId
        linkingRequestState

        keys {
          key
          role
          domain
          alias
          createdAt
          installations

          pixelEventRecords {
            id
            name
          }
        }

        companies {
          id
          slug
          name
          description
          url

          image {
            id
            width
            height
            src
            path
          }

          hasCampaigns
        }

        claims {
          id
          state
          company {
            id
            name
          }
          createdAt
        }

        managers {
          id
          name
          slug
          kind
        }

        managing {
          id
          name
          slug
          kind
        }
      }
    }
  }
`;
