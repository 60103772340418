import {Route, Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import Loading from '@core/ui/Loading';
import ManageNav from '@analytics/components/nav/ManageNav';
import {
  FetchedArt19Secret,
  FetchedOrganization,
  useGetOrganizationArt19SecretQuery,
} from '@analytics/graphql-api';
import Art19Secret from './Art19Secret';

type Props = {
  organization: FetchedOrganization;
  user: FetchedUser;
};

export type ManageArt19RouteProps = Props & {
  art19Secret: FetchedArt19Secret;
};

export function ManageArt19Index({user, organization}: Props) {
  const {data, loading, error} = useGetOrganizationArt19SecretQuery({
    variables: {
      id: organization.id,
    },
  });

  if (loading) {
    return <Loading centered />;
  }

  const art19Secret = data?.me?.organization?.art19Secret;

  return (
    <Switch>
      {[
        {
          exact: true,
          path: '/:part(s|p)/:slug/manage/art19',
          Component: Art19Secret,
        },
      ].map(({exact = false, path, Component}) => (
        <Route
          key={path}
          exact={exact}
          path={path}
          render={(routeProps) => (
            <>
              {exact && <ManageNav user={user} organization={organization} />}
              <Component
                user={user}
                organization={organization}
                art19Secret={art19Secret}
                {...routeProps}
              />
            </>
          )}
        />
      ))}
    </Switch>
  );
}
