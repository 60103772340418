import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import {FieldGroup, FieldSet, Form, Input, Legend} from '@core/ui/Form';
import {Label, Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import CampaignPodcastNav, {
  campaignPodcastURL,
} from '@analytics/components/nav/CampaignPodcastNav';
import UPDATE_CAMPAIGN_PODCAST from '@analytics/graphql-api/_old_mutations/UpdateCampaignPodcast';

const CampaignPodcastUpdate = ({organization, campaign, campaignPodcast}) => {
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [name, setName] = useState(campaignPodcast.name);

  const [updateCampaignPodcast] = useMutation(UPDATE_CAMPAIGN_PODCAST, {
    update: (
      cache,
      {
        data: {
          updateCampaignPodcast: {
            campaignPodcast: {name},
          },
        },
      }
    ) => {
      cache.modify({
        id: cache.identify(campaignPodcast),
        fields: {
          name() {
            return name;
          },
        },
      });
    },
  });

  const _onSubmit = async () => {
    if (!name) {
      setErrors(['Name is required']);
    } else {
      setLoading(true);
      try {
        const resp = await updateCampaignPodcast({
          variables: {
            input: {
              id: campaignPodcast.id,
              name,
            },
          },
        });

        setComplete(true);
      } catch (err) {
        setErrors([err]);
      }

      setLoading(false);
    }
  };

  if (complete) {
    return (
      <Redirect
        to={campaignPodcastURL(organization, campaign, campaignPodcast)}
      />
    );
  }

  return (
    <>
      <CampaignPodcastNav
        organization={organization}
        campaign={campaign}
        campaignPodcast={campaignPodcast}
      />

      <ResponsiveContent containerSize='medium'>
        <Form
          loading={loading}
          onSubmit={_onSubmit}
          errors={errors}
          onClose={() => {
            setErrors([]);
          }}>
          <FieldSet>
            <Legend>Update Name</Legend>
            <FieldGroup rules={() => ({margin: 0})}>
              <Label>Name</Label>
              <Input
                type='text'
                name='name'
                defaultValue={name}
                onChange={({target: {value}}) => {
                  if (value) {
                    setErrors([]);
                    setName(value);
                  }
                }}
                error={errors.length > 0}
                withMargin
              />
              <p>
                For reporting reasons, Ad Analytics allows you to edit the name
                of the podcast as it appears in the dash and exports.{' '}
              </p>
            </FieldGroup>
          </FieldSet>

          <Element rules={() => ({margin: '1rem 0', display: 'flex'})}>
            <Element rules={() => ({textAlign: 'right', flex: 1})}>
              <Button
                to={`${campaignPodcastURL(
                  organization,
                  campaign,
                  campaignPodcast
                )}`}>
                Cancel
              </Button>
              <Submit
                disabled={loading}
                css={`
                  margin-left: 1rem;
                `}>
                Update
              </Submit>
            </Element>
          </Element>
        </Form>
      </ResponsiveContent>
    </>
  );
};

export default CampaignPodcastUpdate;
