import {gql} from '@apollo/client';

export default gql`
  query getCampaignTargetOptions($organizationId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $id) {
          id
          targetOptions {
            id

            feed {
              id
              title
              description(words: 50)
              image {
                href
                id
                path
              }
            }
          }
        }
      }
    }
  }
`;
