import React, {useEffect, useState} from 'react';
import {Element} from '@core/style';
import Button from '@core/ui/Button/LegacyButton';

const BulkEditionPopup = ({discardChanges, saveChanges, edition}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      setVisible(true);
    });
  }, []);

  return (
    <Element
      style-visible={visible}
      rules={({theme, visible}) => ({
        alignItems: 'center',
        background: theme.textPrimary,
        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '1rem',
        bottom: '0',
        color: theme.textInverse,
        display: 'flex',
        fontSize: '0.9375rem',
        left: '15.5rem',
        opacity: visible ? 1 : 0.8,
        padding: '0.75rem 1.25rem 1.25rem',
        position: 'fixed',
        right: '1.5rem',
        transform: visible ? 'translateY(0)' : 'translateY(4.25rem)',
        transition: 'transform 0.4s cubic-bezier(0.25, 1, 0.5, 1)',
        trasnformOrigin: 'bottom',
        whiteSpace: 'nowrap',
        zIndex: 1,
      })}>
      <Element rules={() => ({marginRight: 'auto'})}>
        {edition.length} ads with changes
      </Element>
      {/*       <Button
        onClick={() => {
          if (typeof discardChanges === 'function') {
            discardChanges();
          }
        }}
        style-dark={true}
        rules={() => ({
          background: 'rgba(255,255,255,0.1)',
        })}
      >
        Discard
      </Button> */}
      <Button
        onClick={saveChanges}
        rules={() => ({marginLeft: '1rem', fontSize: '0.9375rem'})}
        style-primary>
        Save changes
      </Button>
    </Element>
  );
};

export default BulkEditionPopup;
