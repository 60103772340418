import {useState} from 'react';
import {ButtonPrimary, FormGroup, FormHelpText, FormInput, Snackbar, TextLink, Type} from '@spotify-internal/encore-web';
import {Controller, useForm} from 'react-hook-form';
import {Redirect, useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {t} from '@core/texts';
import {Card} from '@core/ui/Content';
import { CardTitle, Text } from '@spotify-internal/encore-web';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {
  OrganizationDocument,
  useAddAdStudioAdAccountMutation,
} from '@analytics/graphql-api';
import { ManageAdStudioRouteProps } from '.';

interface IFormValues {
  businessId: string;
}

const AD_STUDIO_BUSINESS_ID_REGEX =
  /^[\da-fA-F]{8}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{12}$/i;

export const AddAdStudioAdAccount = ({
  organization,
  pixel,
}: ManageAdStudioRouteProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const toggleSnackbar = () => {
    setShowSnackbar(true);
    setTimeout(() => setShowSnackbar(false), 10000);
  };
  const [addAdStudio] = useAddAdStudioAdAccountMutation({
    refetchQueries: [OrganizationDocument],
    awaitRefetchQueries: true,
  });

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: {
      businessId: '',
    },
  });

  if (
    ('adStudioBusinessId' in organization &&
    organization.adStudioBusinessId) ||
    (organization.linkingRequestState)
  ) {
    return <Redirect to={createOrgPath(organization, `manage/pixels/${pixel.key}/ad-studio`)} />;
  }

  const onSubmit = async ({businessId}: IFormValues) => {
    setLoading(true);
    try {
      const addAdStudioResp = await addAdStudio({
        variables: {
          input: {
            id: organization.id,
            businessId,
          },
        },
      });
      if (addAdStudioResp) {
        history.push(createOrgPath(organization, `manage/pixels/${pixel.key}/ad-studio`));
      }
    } catch (err) {
      toggleSnackbar()
    } finally {
      setLoading(false);
    }
  };

  return (
    <ResponsiveContent containerSize='medium'>
      <Card>
        <CardTitle variant='balladBold'css={`font-size: 24px`}>
          Add Spotify Pixel to your Spotify Ad Studio account
        </CardTitle>
        <Text key='textSubdued' as="p" variant='bodyMedium' semanticColor='textSubdued' paddingBottom='1.5em'>
          Enter your Ad Studio account number below to add your pixel to Ad 
          Studio. Once your pixel has been added, you will be able to activate
          it on your next campaign.
        </Text>
        <Text key='textSubdued' as="p" variant='bodyMedium' semanticColor='textSubdued' paddingBottom='1.5em'>
          If you have multiple pixel events for the same event type, these 
          will be consolidated and renamed as a single event in the Ad Studio 
          platform. For example, if you have multiple "lead" event pixels in 
          Ad Analytics, you will see one aggregated "lead" event in Ad Studio. <TextLink semanticColor='textSubdued'
          target='_blank' href="https://www.help.adanalytics.spotify.com/conversion-event-types-in-ad-studio">Learn more</TextLink>
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label="Ad Studio account number" labelFor='businessId'>
            <FormHelpText style={{marginBottom: '.5em'}}>
              Learn how to find your ad account number <TextLink semanticColor='textSubdued' target='_blank' href="https://www.help.adanalytics.spotify.com/add-a-spotify-pixel-to-ad-studio">here</TextLink>.
            </FormHelpText>
            <Controller
              name='businessId'
              control={control}
              defaultValue=""
              rules={{
                pattern: AD_STUDIO_BUSINESS_ID_REGEX
              }}
              render={({field}) => (
                <FormInput
                  id='businessId'
                  placeholder='(e.g. abf4de9b-799d-4f23-9ef8-9fd0c91aa07a)'
                  {...field}
                />
              )}
            />
              {errors.businessId && (
                <Type semanticColor='textNegative'>
                  Provide a valid Ad Studio account number
                </Type>
              )}
          </FormGroup>
          <ButtonPrimary disabled={loading} type="submit">
            Add Pixel
          </ButtonPrimary>
        </form>
      </Card>
      <Snackbar
        showSnackbar={showSnackbar}
        colorSet="negative">
        Business ID update failed: {t('errors.network')}
      </Snackbar>
    </ResponsiveContent>
  );
};
