import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath, isOrgBrand, isSelling} from '@core/lib/organizations';
import {ToastMessage} from '@core/ui/Alert';
import {useAdvisorOnboardRoutes} from '@analytics/lib/routes/useAdvisorRoutes';
import {AdvisorRouteProps} from '../_routes';
import {AdvisorOnboardBrandIndex} from './brand';
import {AdvisorOnboardPublisherIndex} from './publisher';

export function AdvisorOnboardIndex({organization, user}: AdvisorRouteProps) {
  const {renderRoutes} = useAdvisorOnboardRoutes({organization});

  return (
    <Switch>
      {renderRoutes({
        components: {
          redirect: AdvisorOnboardRedirect,
          publisher: AdvisorOnboardPublisherIndex,
          brand: AdvisorOnboardBrandIndex,
        },
        extraProps: {
          user,
          organization,
        },
      })}
    </Switch>
  );
}

const AdvisorOnboardRedirect = ({organization}) => {
  if (isSelling(organization)) {
    return (
      <Redirect
        to={createOrgPath(organization, '/advisor/onboard/publisher')}
      />
    );
  }

  if (isOrgBrand(organization)) {
    return (
      <Redirect to={createOrgPath(organization, '/advisor/onboard/brand')} />
    );
  }

  return <ToastMessage alertType='warning'>No Advisor Support</ToastMessage>;
};
