import {useQuery} from '@apollo/client';
import {t} from '@core/texts';
import {ToastMessage} from '@core/ui/Alert';
import Loading from '@core/ui/Loading';
import ApprovalsConfirmReport from '@analytics/components/approvals/ApprovalsConfirmReport';
import ME_APPROVALS_QUERY from '@analytics/graphql-api/_old_queries/MeApprovalsQuery';
import {CampaignRouteProps} from '../_routes';

export function ApprovalsConfirm({organization, campaign}: CampaignRouteProps) {
  const {loading, error, data} = useQuery(ME_APPROVALS_QUERY, {
    variables: {
      campaignId: campaign.id,
    },
  });

  if (loading) {
    return <Loading centered />;
  }

  if (error) {
    return (
      <ToastMessage alertType='warning'>{t('errors.network')}</ToastMessage>
    );
  }

  const dynamicApprovals = (data?.me?.dynamicApprovals ?? []).filter((da) => {
    const valid = campaign.campaignDynamics.find(
      ({id}) => id === da?.campaignDynamic.id
    );

    return valid && da?.state === 'REQUESTED';
  });

  const podcastApprovals = (data?.me?.podcastApprovals ?? []).filter((cp) => {
    const valid = (campaign.campaignPodcasts ?? []).find(
      (campaignCp) => campaignCp?.id === cp.id
    );

    return valid && cp?.state === 'REQUESTED';
  });

  const streamingApprovals: any[] = data.me.streamingApprovals.filter(
    ({state, campaignStreaming}: any) => {
      const valid = campaign.campaignStreamings.find(
        ({id}) => id === campaignStreaming.id
      );

      return valid && state === 'REQUESTED';
    }
  );

  return (
    <ApprovalsConfirmReport
      campaign={campaign}
      organization={organization}
      dynamicApprovals={dynamicApprovals}
      podcastApprovals={podcastApprovals}
      streamingApprovals={streamingApprovals}
    />
  );
}
