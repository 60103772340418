import {gql} from '@apollo/client';
import PodcastFragment from './fragments/PodcastFragment';

export default gql`
  query podcasterPodcastsQuery($id: ID!, $campaignId: ID) {
    me {
      id
      organization(id: $id) {
        id
        podcasts(campaignId: $campaignId) {
          ...Podcast
        }
      }
    }
  }
  ${PodcastFragment}
`;
