import {gql} from '@apollo/client';

export default gql`
  mutation deletePixelEventRecord($input: DeletePixelEventRecordInput!) {
    authed {
      success
    }
    deletePixelEventRecord(input: $input) {
      success
    }
  }
`;
