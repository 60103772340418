import {FetchedUser} from '@core/graphql-api';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import Loading from '@core/ui/Loading';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedIncrementalLiftReport,
  FetchedOrganization,
  useLiftReportQuery,
} from '@analytics/graphql-api';
import {LiftReport} from './LiftReport';

type Props = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
  campaign?: FetchedCampaign;
  match: {
    params: {liftReportId: string; after: string; before: string};
  };
};

export function LiftReportRange({
  organization,
  user,
  campaign,
  match: {
    params: {liftReportId, after, before},
  },
}: Props) {
  const {data, loading, error} = useLiftReportQuery({
    variables: {
      id: liftReportId,
      organizationId: organization.id,
      after: parseInt(after),
      before: parseInt(before),
    },
  });

  const liftReport =
    data?.me?.organization?.liftReport ?? ({} as FetchedIncrementalLiftReport);

  if (loading) {
    return <Loading centered fixed />;
  }

  if (error || (data && !liftReport)) {
    return <Alert severity='error'>{t('errors.network')}</Alert>;
  }

  return (
    <LiftReport
      organization={organization}
      user={user}
      campaign={campaign}
      liftReport={liftReport}
    />
  );
}
