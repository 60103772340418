import {gql} from '@apollo/client';

export default gql`
  mutation createActionMap($input: CreateActionMapInput!) {
    authed {
      success
    }
    createActionMap(input: $input) {
      actionMap {
        id
        eventName
        action
      }
    }
  }
`;
