import {comma, toPercent} from '@core/lib/filters';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import Stat from '@core/ui/Stat';
import InsightsOverlapPieChart from './InsightsOverlapPieChart';

const statRule = () => ({
  margin: '0.375rem 0',
  minWidth: 0,
});

type OverlapInstanceStatsProps = {
  color: string;
  description?: string;
  downloads?: number;
  name: string;
  overlap: number;
  reach: number;
};

const OverlapInstanceStats = ({
  color,
  description,
  downloads,
  name,
  overlap,
  reach,
}: OverlapInstanceStatsProps) => {
  return (
    <div
      css={{
        alignSelf: 'end',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
      }}>
      <div css={{marginBottom: '0.75rem'}}>
        <div
          css={{
            color,
            fontSize: '1rem',
            fontWeight: 500,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
          }}>
          {name}
        </div>
        {description ? (
          <div
            css={`
              color: var(--text-muted);
              font-size: 0.875rem;
              margin-top: 0.25rem;
              font-weight: 500;
            `}>
            {description}
          </div>
        ) : null}
      </div>
      {typeof downloads === 'number' ? (
        <Stat
          title='Downloads'
          value={comma(downloads)}
          rules={statRule}
          horizontal
        />
      ) : null}
      <Stat title='Reach' value={comma(reach)} rules={statRule} horizontal />
      <Stat
        title='Overlap'
        value={toPercent(overlap / reach)}
        rules={statRule}
        horizontal
      />
    </div>
  );
};

type Props = {
  data: Array<{
    baseDescription?: string;
    baseDownloads?: number;
    baseName: string;
    baseReach: number;
    combined: number;
    compDescription?: string;
    compDownloads?: number;
    compName: string;
    compReach: number;
    overlap: number;
  }>;
  type: 'campaigns' | 'publishers' | 'episodes';
};

export default function InsightsOverlapTable({data, type}: Props) {
  const columns: DataTableColumn[] = [
    {
      accessor: 'baseName',
      hidden: true,
    },
    {
      accessor: 'compName',
      hidden: true,
    },
    {
      accessor: 'baseDescription',
      hidden: true,
    },
    {
      accessor: 'compDescription',
      hidden: true,
    },
    {
      Cell: ({
        data: {
          baseDescription,
          baseDownloads,
          baseName,
          baseReach,
          combined,
          compDescription,
          compDownloads,
          compName,
          compReach,
          overlap,
        },
      }) => {
        return (
          <div
            css={{
              display: 'grid',
              gridGap: '1.5rem',
              gridTemplateColumns: '0.5fr 0.5fr 1fr',
              padding: '2rem 0.625rem',
            }}>
            <OverlapInstanceStats
              name={baseName}
              reach={baseReach}
              downloads={baseDownloads}
              overlap={overlap}
              color='var(--green)'
              description={baseDescription}
            />
            <OverlapInstanceStats
              name={compName}
              reach={compReach}
              downloads={compDownloads}
              overlap={overlap}
              description={compDescription}
              color='var(--blue)'
            />
            <InsightsOverlapPieChart
              baseName={baseName}
              baseReach={baseReach}
              combined={combined}
              compName={compName}
              compReach={compReach}
              overlap={overlap}
            />
          </div>
        );
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      noHeader
      searchKeys={[
        'baseName',
        'compName',
        'baseDescription',
        'compDescription',
      ]}
      propertyForKey={({d}) => d.baseName + d.compName}
      paginationRowsPerPage={15}
      paginationRowsPerPageOptions={[15, 30, 50]}
      emptyMessageText='No overlap data available within the given range.'
    />
  );
}
