import React, {useEffect} from 'react';
import {gtag} from '@core/lib/gtag';
import {ModalHeader} from '@core/ui/Content';
import HubspotForm from '@core/ui/HubspotForm';
import {Overlay} from '@core/ui/Overlay';
import {UserObject} from '@analytics/graphql-api';

interface Props {
  opened: boolean;
  toggle: (_value?: boolean) => void;
  user: UserObject;
}

const SupportTicketFormOverlay = ({user, toggle, opened, ...props}: Props) => {
  useEffect(() => {
    gtag('event', 'support_ticket_form_opened', {
      event_category: 'contact_support',
    });
  }, []);

  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      withBackdrop
      withShadow
      animation='scale-in'
      centered
      closeOnOutsideClick={false}
      css={`
        border-radius: var(--spacing-4);
        border: 0;
        display: grid;
        gap: 0.625rem;
        grid-template-rows: auto 1fr;
        max-width: 60rem;
        min-width: 50rem;
        padding: 1.5rem 1.375rem;
      `}
      {...props}>
      <ModalHeader toggle={toggle} withCloseIcon>
        Submit a Support Ticket
      </ModalHeader>
      <div
        css={`
          min-height: 0;
          overflow-y: auto;
        `}>
        <HubspotForm
          formId='f86bfa8d-fd26-41aa-b027-44f2bbac80e3'
          onFormSubmit={(formEl) => {
            (
              formEl.querySelector('.hs_email input') as HTMLInputElement
            ).value = user.email;
            (
              formEl.querySelector('.hs_company input') as HTMLInputElement
            ).value =
              user.organizations?.map((org) => org.name).join(', ') ?? '';
            (
              formEl.querySelector(
                'input[name="TICKET.url_location"]'
              ) as HTMLInputElement
            ).value = window.location.href;
          }}
          onCancelClick={() => toggle(false)}
        />
      </div>
    </Overlay>
  );
};

export default SupportTicketFormOverlay;
