import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {FORM_ERRORS, Form, FormFooter, Submit} from '@core/ui/FormElements';
import {
  FetchedCampaign,
  UpdateCampaignInput,
  useUpdateCampaignMutation,
} from '@analytics/graphql-api';
import {CampaignFormFields} from './CampaignFormFields';
import {getCampaignFormInput} from './_helpers';
import {useCampaignForm, useScrollToTopOnErrors} from './_hooks';
import {CampaignFormBaseProps, CampaignFormData} from './_types';

export function CampaignUpdateForm({
  campaign,
  onCancel,
  onComplete,
  organization,
}: CampaignFormBaseProps & {
  campaign: FetchedCampaign;
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [updateCampaign] = useUpdateCampaignMutation();

  const {
    clearErrors,
    control,
    handleSubmit,
    setError,
    formState: {errors},
  } = useCampaignForm({campaign});

  const onSubmit = async (data: CampaignFormData) => {
    setLoading(true);
    if (errors.networkError) clearErrors('networkError');

    try {
      const input = getCampaignFormInput({data});
      const resp = await updateCampaign({
        variables: {
          input: {
            id: campaign.id,
            ...input,
          } as UpdateCampaignInput,
        },
      });

      const updated_campaign = resp?.data?.updateCampaign?.campaign;

      if (updated_campaign) {
        onComplete?.(updated_campaign);
        /* return to campaign page */
        history.push(
          createOrgPath(organization, `/campaigns/${campaign.slug}`)
        );
      }
    } catch (e) {
      setError('networkError', FORM_ERRORS.network);
    }

    setLoading(false);
  };

  const _onCancel = () => {
    onCancel?.();
    /* return to campaign page */
    history.push(createOrgPath(organization, `/campaigns/${campaign.slug}`));
  };

  useScrollToTopOnErrors(errors);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
      <CampaignFormFields
        control={control}
        companyId={campaign.advertiser?.id as string}
        organizationId={organization.id}
        pubOrg={{
          id: campaign.organization?.id as string,
          name: campaign.organization?.name as string,
        }}
      />
      <FormFooter>
        {onCancel && <Button onClick={_onCancel}>Cancel</Button>}
        <Submit loading={loading}>Update Campaign</Submit>
      </FormFooter>
    </Form>
  );
}
