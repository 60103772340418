import {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import CampaignNoiseForm from '@analytics/components/forms/CampaignNoiseForm';
import {useUpdateCampaignNoiseMutation} from '@analytics/graphql-api';

export default function CampaignNoiseUpdate({
  organization,
  campaign,
  campaignNoise,
  match: {url},
}) {
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const [updateCampaignNoise] = useUpdateCampaignNoiseMutation();

  const onSubmit = async ({
    startAt,
    endAt,
    name,
    targetImpressions,
    usePublisherImpressions,
  }) => {
    if (error) setError(undefined);
    setLoading(true);

    try {
      await updateCampaignNoise({
        variables: {
          input: {
            campaignNoiseId: campaignNoise.id,
            startAt: startAt.format('YYYY-MM-DD'),
            endAt: endAt.format('YYYY-MM-DD'),
            name,
            targetImpressions: parseInt(targetImpressions),
            usePublisherImpressions,
          },
        },
      });

      setComplete(true);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  if (complete) {
    return (
      <Redirect
        to={createOrgPath(organization, `/campaigns/${campaign.slug}/lift`)}
      />
    );
  }

  return (
    <ResponsiveContent containerSize='large'>
      <CardHeader
        rightContent={
          <Button color='error' to={`${url}/delete`}>
            Delete
          </Button>
        }>
        Update Household Control Group
      </CardHeader>
      <Card>
        <CampaignNoiseForm
          campaign={campaign}
          campaignNoise={campaignNoise}
          isLoading={loading}
          networkError={error}
          onSubmit={onSubmit}
          organization={organization}
        />
      </Card>
    </ResponsiveContent>
  );
}
