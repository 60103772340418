import React from 'react';
import {TRules} from '@core/style';
import {Element} from '@core/style';
import FeedImage from './FeedImage';

interface IFeedMultipleImages {
  feeds: any[];
  width?: number;
  compact?: boolean;
  rules?: TRules;
}

const FeedMultipleImages = ({
  compact = false,
  feeds,
  rules,
  width = 72,
}: IFeedMultipleImages): JSX.Element | null => {
  if (Array.isArray(feeds) && feeds.length) {
    const count = feeds.length;
    const gridTpl =
      count === 1 ? '1fr' : count <= 4 ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)';

    return (
      <Element
        rules={[
          () => ({
            borderRadius: compact ? '.5rem' : undefined,
            display: 'grid',
            gridGap: compact ? undefined : '0.125rem',
            gridTemplateColumns: gridTpl,
            gridTemplateRows: gridTpl,
            height: `${width}px`,
            overflow: 'hidden',
            width: `${width}px`,
          }),
          rules,
        ]}>
        {feeds.slice(0, 9).map((feed, n) => (
          <FeedImage
            key={feed.id}
            feed={feed}
            width={width}
            rules={() => ({
              borderRadius: compact
                ? 0
                : feeds.length > 1
                ? '0.25rem'
                : '0.5rem',
              height: '100%',
              minHeight: 0,
              minWidth: 0,
              width: '100%',
            })}
          />
        ))}
      </Element>
    );
  }
  return null;
};

export default FeedMultipleImages;
