import {useMemo, useState} from 'react';
import {createOrgPath} from '@core/lib/organizations';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn, DataTableProps} from '@core/ui/DataTable';
import {Stack} from '@core/ui/Layout';
import {NavPills} from '@core/ui/NavPills';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
} from '@analytics/graphql-api';
import campaignTableColumns from '@analytics/lib/campaignTableColumns';
import {getPlacementsStr} from '@analytics/lib/placements';
import {getCampaignDynamics} from '../helpers/getCampaignDynamics';
import {getCampaignPodcasts} from '../helpers/getCampaignPodcasts';
import {getCampaignStreamings} from '../helpers/getCampaignStreamings';
import {LineItemCell} from './LineItemCell';

type Props = {
  campaign: FetchedCampaign;
  campaignDynamics?: FetchedCampaign['campaignDynamics'];
  campaignPodcasts?: FetchedCampaign['campaignPodcasts'];
  campaignStreamings?: FetchedCampaign['campaignStreamings'];
  displayIngestDynamicAdsButton?: boolean;
  headerTitle?: string;
  onClickRowPath?: DataTableProps['onClickRowPath'];
  organization: FetchedOrganization | FetchedBuySideOrganization;
};

export function CampaignAdPlacementsTable({
  campaign,
  campaignDynamics = [],
  campaignPodcasts = [],
  campaignStreamings = [],
  displayIngestDynamicAdsButton = false,
  headerTitle = 'Ad Placements',
  onClickRowPath,
  organization,
}: Props) {
  const [selectedTab, setSelectedTab] = useState<
    'all' | 'streaming' | 'dynamic'
  >('all');
  const {data} = useMemo(() => {
    const displayDynamics =
      (campaignDynamics.length > 0 && selectedTab === 'all') ||
      selectedTab === 'dynamic';
    const displayPodcasts =
      (campaignPodcasts ?? []).length > 0 && selectedTab === 'all';
    const displayStreaming =
      (campaignStreamings.length > 0 && selectedTab === 'all') ||
      selectedTab === 'streaming';
    return {
      data: [
        ...(displayDynamics
          ? getCampaignDynamics(campaignDynamics, {
              campaign,
              organization,
            })
          : []),
        ...(displayPodcasts
          ? getCampaignPodcasts(campaignPodcasts, {campaign, organization})
          : []),
        ...(displayStreaming
          ? getCampaignStreamings(campaignStreamings, {
              campaign,
              organization,
            })
          : []),
      ],
    };
  }, [
    campaign,
    campaignDynamics,
    campaignPodcasts,
    campaignStreamings,
    organization,
    selectedTab,
  ]);

  const columns = useMemo<DataTableColumn[]>(
    () => [
      {
        title: 'Line Item',
        accessor: 'name',
        type: 'string',
        Cell: LineItemCell,
      },
      {
        ...t('stats.adType'),
        accessor: 'format',
        type: 'string',
        Cell: ({d: {format}}) => (
          <div css={{textTransform: 'capitalize', whiteSpace: 'nowrap'}}>
            {format}
          </div>
        ),
      },
      {
        title: 'Roll Placement',
        getValue: ({d}) => getPlacementsStr(d),
      },
      ...campaignTableColumns(organization, campaign, data),
    ],
    [campaign, data, organization]
  );

  return (
    <Card scrollable>
      <DataTable
        searchKeys={['name']}
        columns={columns}
        orderBy='-downloadTotal'
        data={data}
        searchPlaceholder='Search'
        emptyMessageText='No ads found.'
        searchMinItems={0}
        onClickRowPath={onClickRowPath ? onClickRowPath : ({url}) => url}
        headerRenderer={({filtersContent, searchContent}) => (
          <Stack direction='column' gap={3}>
            <Stack justifyContent='space-between' alignItems='center'>
              <CardHeader
                css={`
                  margin: 0;
                `}>
                {headerTitle}
              </CardHeader>
              {(searchContent ||
                filtersContent ||
                displayIngestDynamicAdsButton) && (
                <Stack alignItems='center'>
                  {displayIngestDynamicAdsButton &&
                  campaignPodcasts?.length === 0 ? (
                    <Button
                      to={createOrgPath(
                        organization,
                        `/campaigns/${campaign.slug}/ingest`
                      )}
                      color='secondary'
                      buttonSize='sm'
                      css={`
                        margin-right: var(--spacing-4);
                      `}>
                      Ingest Dynamic Ads
                    </Button>
                  ) : null}
                  {searchContent}
                  {filtersContent}
                </Stack>
              )}
            </Stack>
            <NavPills
              defaultSelected='all'
              onChange={(value) => setSelectedTab(value)}
              options={[
                {label: 'All ad types', value: 'all'},
                {
                  label: 'Streaming ads',
                  value: 'streaming',
                },
                {
                  label: 'Dynamic ads',
                  value: 'dynamic',
                },
              ]}
              css={`
                margin: 0;
              `}
            />
            {(selectedTab === 'dynamic' || selectedTab === 'streaming') && (
              <Alert
                severity={selectedTab === 'streaming' ? 'spotify' : 'download'}>
                {t(`spotify.${selectedTab}.ads`)}
              </Alert>
            )}
          </Stack>
        )}
      />
    </Card>
  );
}
