import {spacer16, spacer24, spacer32} from '@spotify-internal/encore-web';
import styled from 'styled-components';
import {Stack} from '@core/ui/Layout';

export const InsightsGrid = styled.div<{columns: number}>`
  column-gap: ${spacer24};
  display: grid;
  grid-template-columns: ${({columns}) => `repeat(${columns}, 1fr)`};
  row-gap: ${spacer32};
`;

export const InsightsLayout = styled(Stack).attrs({
  direction: 'column',
  gap: spacer32,
})`
  margin: 0 ${spacer24};
`;

export const LegendIndicator = styled.div<{color: string}>`
  background-color: ${({color}) => color};
  border-radius: 2px;
  height: ${spacer16};
  width: ${spacer16};
`;
