import {FetchedUser} from '@core/graphql-api';
import dayjs from '@core/lib/dayjs';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {EpisodeCell} from '@analytics/components/campaigns/CampaignTableCells';
import {campaignEpisodeURL} from '@analytics/components/nav/CampaignEpisodeNav';
import useSettings from '@analytics/components/settings/useSettings';
import {
  CampaignEpisodeObject,
  FeedObject,
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
} from '@analytics/graphql-api';
import campaignTableColumns from '@analytics/lib/campaignTableColumns';
import useCampaignEpisodes from '@analytics/lib/hooks/useCampaignEpisodes';
import {impressionsForPlacements} from '@analytics/lib/placements';

type CampaignEpisodesTableProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign: FetchedCampaign;
  user: FetchedUser;
  feed?: FeedObject;
  campaignEpisodes: CampaignEpisodeObject[];
};

export default function CampaignEpisodesTable({
  user,
  organization,
  campaign,
  feed,
  campaignEpisodes,
}: CampaignEpisodesTableProps) {
  const [settings] = useSettings();
  const data = useCampaignEpisodes(campaignEpisodes, {
    user,
    campaign,
  }).map((episode) => {
    episode.url = campaignEpisodeURL(
      organization,
      campaign,
      episode.campaignPodcast,
      {id: episode.id}
    );

    if (feed) {
      episode.feed = feed;
    }

    if (settings.impressionsByPlacements) {
      const campaignPodcast = campaign.campaignPodcasts?.find(
        (cp) => episode.campaignPodcast.id === cp?.id
      );

      if (campaignPodcast) {
        episode.downloadTotal = impressionsForPlacements({
          value: episode.downloadTotal,
          campaignPodcast,
        });
      }
    }

    return episode;
  });

  const columns: DataTableColumn[] = [
    {
      title: 'Episode',
      accessor: 'title',
      type: 'string',
      Cell: EpisodeCell,
    },
    {
      title: 'Published',
      accessor: 'published',
      type: 'date',
      fmt: (v) => (v ? dayjs(v).utc().format('MM/DD/YYYY') : '-'),
    },
    ...campaignTableColumns(organization, campaign, data),
  ];

  return (
    <DataTable
      columns={columns}
      orderBy='published'
      data={data}
      onClickRowPath={({url}) => url}
    />
  );
}
