import {Text} from '@spotify-internal/encore-web';
import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath, isOrgBrand, isSelling} from '@core/lib/organizations';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {CampaignsRouteProps} from '../_routes';
import {getCreateCampaignRoutes} from './_routes';
import {BrandCampaignCreateIndex} from './brand';
import {PublisherCampaignCreateIndex} from './publisher';

export function CampaignCreateIndex({user, organization}: CampaignsRouteProps) {
  const {renderRoutes} = getCreateCampaignRoutes({organization});

  return (
    <ResponsiveContent containerSize='large'>
      <Text as='h2' style={{margin: '0 1.5rem'}} variant='titleSmall'>
        Create Campaign
      </Text>
      <Switch>
        {renderRoutes({
          components: {
            redirect: CampaignCreateRedirect,
            publisher: PublisherCampaignCreateIndex,
            brand: BrandCampaignCreateIndex,
          },
          extraProps: {
            organization,
            user,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </ResponsiveContent>
  );
}

const CampaignCreateRedirect = ({
  organization,
}: Pick<CampaignsRouteProps, 'organization'>) => {
  if (isSelling(organization))
    return (
      <Redirect
        to={createOrgPath(organization, '/campaigns/create/publisher')}
      />
    );

  if (isOrgBrand(organization))
    return (
      <Redirect to={createOrgPath(organization, '/campaigns/create/brand')} />
    );

  return <Redirect to={createOrgPath(organization, '/measure/campaigns')} />;
};
