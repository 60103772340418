import {FetchedSpotifyLiftReport} from '@analytics/graphql-api';

/**
 *
 * Spotify Lift Report Query Types
 *
 */

export type SpotifyBrandLiftMessageResult = NonNullable<
  FetchedSpotifyLiftReport['messages'][number]
>;

export type SpotifyBrandLiftMessageResultGroup = NonNullable<
  SpotifyBrandLiftMessageResult['result']
>['overall'];

export type SpotifyBrandLiftMessageContent =
  SpotifyBrandLiftMessageResult['messageContents'][number];

/**
 *
 * Spotify Lift Chart Types
 *
 */
type BaseLiftData = {group: string};

export type PieLiftData = BaseLiftData & {total: number; groupTotal?: number};
export type BarLiftData = BaseLiftData & SpotifyBrandLiftMessageResultGroup;

/**
 *
 * Spotify Lift Misc Types
 *
 */
export type GroupType = 'control' | 'exposed';

export enum LiftMessageCategory {
  age = 'age',
  gender = 'gender',
  breakdown = 'breakdown',
}
