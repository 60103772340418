import { opacityBlack50 } from '@spotify-internal/encore-foundation';
import { rootStyle, zIndexDialogBackdrop, zIndexPreviewBackdrop } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
export var staticSet = 'overMedia';
export var OverlayBackdrop = styled.div.attrs({
  role: 'presentation'
}).withConfig({
  displayName: "OverlayBackdrop",
  componentId: "sc-1vbts8w-0"
})(["", ";background:", ";height:100%;left:0;position:fixed;top:0;width:100%;z-index:", ";", ";.docs-story &{position:absolute;z-index:", ";}"], rootStyle(), opacityBlack50, zIndexDialogBackdrop, function (props) {
  return props.$center && css(["align-items:center;display:flex;justify-content:center;"]);
}, zIndexPreviewBackdrop);