import {Card, CardHeader} from '@core/ui/Content';
import {PodcastNav} from '@analytics/components/nav/PodcastNav';
import {EpisodesTable, ProcessPodcast} from '@analytics/components/podcasts';
import {PodcastImpressionsChart} from '@analytics/components/podcasts/PodcastImpressionsChart';
import {PodcastRouteProps} from '.';

export function Podcast({organization, podcast, user}: PodcastRouteProps) {
  if (!podcast.processingStartedAt) {
    return <ProcessPodcast organization={organization} podcast={podcast} />;
  }

  return (
    <>
      <PodcastNav organization={organization} podcast={podcast} user={user} />
      <Card>
        <CardHeader>Impressions</CardHeader>
        <PodcastImpressionsChart
          organization={organization}
          podcast={podcast}
        />
      </Card>
      <Card>
        <CardHeader>Episodes</CardHeader>
        <EpisodesTable organization={organization} podcast={podcast} />
      </Card>
    </>
  );
}
