import {useMemo, useState} from 'react';
import {useMutation} from '@apollo/client';
import {formatDate} from '@core/lib/time';
import {BLUE, Element} from '@core/style';
import Badge from '@core/ui/Badge';
import {Button} from '@core/ui/Button';
import {Card, CardHeader, ModalHeader} from '@core/ui/Content';
import DataTable, {
  DataTableColumn,
  DataTableDotsDropdownMenuCell,
} from '@core/ui/DataTable';
import {Icon} from '@core/ui/Icon';
import {Stack} from '@core/ui/Layout';
import {LoadingSpinner} from '@core/ui/Loading';
import {Overlay} from '@core/ui/Overlay';
import {Snackbar} from '@core/ui/Snackbar';
import DELETE_PODCASTS_MUTATION from '@analytics/graphql-api/_old_mutations/DeletePodcasts';
import IS_PROCESSING_PODCASTS_MUTATION from '@analytics/graphql-api/_old_mutations/IsProcessingPodcasts';
import UPDATE_FEEDS_MUTATION from '@analytics/graphql-api/_old_mutations/UpdateFeeds';
import PODCASTS_QUERY from '@analytics/graphql-api/_old_queries/PodcastsQuery';
import {ManagePodcastsRouteProps} from '.';

type Props = ManagePodcastsRouteProps & {
  match: {
    url: string;
  };
};

const Podcasts = ({user, organization, podcasts, match: {url}}: Props) => {
  const refetchQueries = [
    {
      query: PODCASTS_QUERY,
      variables: {
        id: organization.id,
      },
    },
  ];
  const [updateFeeds] = useMutation(UPDATE_FEEDS_MUTATION, {refetchQueries});
  const [deletePodcasts] = useMutation(DELETE_PODCASTS_MUTATION, {
    refetchQueries,
  });
  const [isProcessingPodcasts] = useMutation(IS_PROCESSING_PODCASTS_MUTATION, {
    refetchQueries,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [action, setAction] = useState<
    | {
        actionName: 'update' | 'remove' | 'processing';
        rows: any[];
      }
    | undefined
  >();

  const data = useMemo(
    () =>
      podcasts.map(({id, createdAt, processingStartedAt, feed}) => ({
        title: feed.title,
        createdAt,
        id,
        href: feed.href,
        state: feed.state,
        feedId: feed.id,
        isProcessing: !!processingStartedAt,
        publisher: `${feed.publisherName ? feed.publisherName : ''}${
          feed.publisherEmail ? ` (${feed.publisherEmail})` : ''
        }`,
      })),
    [podcasts]
  );

  const columns: DataTableColumn[] = useMemo(
    () => [
      {
        title: 'Podcast',
        accessor: 'title',
        type: 'string',
      },
      {
        title: 'RSS',
        accessor: 'href',
        type: 'string',
      },
      {
        title: 'Owner',
        accessor: 'publisher',
        type: 'string',
      },
      {
        title: 'Receiving Downloads',
        accessor: 'processingStartedAt',
        type: 'boolean',
        Cell: ({data: {isProcessing}}) => {
          if (isProcessing) {
            return (
              <Icon
                icon='checkbox-marked-circle'
                rules={() => ({
                  color: BLUE,
                })}
              />
            );
          }
          return (
            <Icon
              icon='close-circle'
              rules={({theme}) => ({
                color: theme.textDisabled,
              })}
            />
          );
        },
        rule: () => ({
          textAlign: 'center',
        }),
      },
      {
        title: 'Added',
        accessor: 'createdAt',
        type: 'date',
        fmt: formatDate,
      },
      ...(user.isAdmin
        ? [
            {
              title: 'State (Admin Only)',
              accessor: 'state',
              type: 'string',
              Cell: ({data: {state}}) => {
                if (state === 'ACTIVE') {
                  return (
                    <Badge variant='filled' color='secondary'>
                      {state}
                    </Badge>
                  );
                }
                return <Badge variant='outlined'>{state}</Badge>;
              },
            },
            {
              title: 'Feed ID (Admin Only)',
              accessor: 'feedId',
              type: 'string',
            },
          ]
        : []),
      {
        rule: () => ({padding: 0}),
        Cell: ({data, ...props}) => {
          return (
            <DataTableDotsDropdownMenuCell
              propertyForDescription='description'
              items={[
                {
                  title: 'Check Processing',
                  description:
                    'Check if Ad Analytics is recieving data for this podcast.',
                  callback: () =>
                    setAction({actionName: 'processing', rows: [data]}),
                },
                {
                  title: 'Update Podcast',
                  description:
                    'Force Ad Analytics to look for updates to this feed.',
                  callback: () =>
                    setAction({actionName: 'update', rows: [data]}),
                },
                {
                  title: 'Remove Podcast',
                  description:
                    'Remove this podcast from your Ad Analytics account.',
                  callback: () =>
                    setAction({actionName: 'remove', rows: [data]}),
                },
              ]}
              {...props}
            />
          );
        },
      },
    ],
    [user]
  );

  const onConfirm = async () => {
    const {actionName, rows} = action;
    const selectedData = data
      .filter(({id}) => rows.find((r) => r.id === id))
      .map(({id, feedId}) => ({id, feedId}));

    if (selectedData.length == 0) {
      setAction(undefined);
      return;
    }

    setLoading(true);

    try {
      switch (actionName) {
        case 'update':
          await updateFeeds({
            variables: {
              input: {
                feedIds: selectedData.map(({feedId}) => feedId),
              },
            },
          });
          break;
        case 'remove':
          await deletePodcasts({
            variables: {
              input: {
                podcastIds: selectedData.map(({id}) => id),
              },
            },
          });
          break;
        case 'processing':
          await isProcessingPodcasts({
            variables: {
              input: {
                podcastIds: selectedData.map(({id}) => id),
                organizationId: organization.id,
              },
            },
          });
          break;
      }
    } catch (err) {
      console.warn(err);
      setError(err.toString());
    }

    setAction(undefined);
    setLoading(false);
  };

  return (
    <>
      {!!action ? (
        <Overlay
          opened
          centered
          withShadow
          withBackdrop
          closeOnOutsideClick={false}
          css={`
            padding: 1.25rem;
            min-width: 37.5rem;
          `}>
          <ModalHeader>
            {action.actionName === 'processing'
              ? 'Check Processing'
              : (action.actionName === 'update'
                  ? 'Update Podcast'
                  : 'Remove Podcast') + (action.rows.length > 1 ? 's' : '')}
          </ModalHeader>
          {loading || !action ? (
            <>
              <p>
                Podcast actions can take a bit as we need to hit RSS feeds.
                Please be patient.
              </p>
              <LoadingSpinner size='medium' centered />
            </>
          ) : (
            <>
              <p>
                Please confirm you would like to{' '}
                {action.actionName === 'processing'
                  ? 'check processing'
                  : action.actionName}{' '}
                the following podcast{action.rows.length > 1 ? 's' : ''}:
              </p>
              <DataTable
                data={action.rows}
                columns={[{title: 'Podcast', accessor: 'title'}]}
                paginationRowsPerPage={6}
                paginationRowsPerPageOptions={[6, 20, 40]}
              />
              <Element
                rules={() => ({
                  display: 'flex',
                  justifyContent: 'end',
                  marginTop: '1rem',
                  gap: '.625rem',
                })}>
                <Button onClick={() => setAction(undefined)}>Cancel</Button>
                <Button
                  color={action.actionName === 'remove' ? 'error' : 'primary'}
                  onClick={() => onConfirm()}>
                  Confirm
                </Button>
              </Element>
            </>
          )}
        </Overlay>
      ) : null}
      <Card>
        <DataTable
          data={data}
          columns={columns}
          orderBy='-createdAt'
          searchKeys={['title']}
          searchPlaceholder='Find podcast'
          emptyMessageText='No podcasts found.'
          headerRenderer={({filtersContent, searchContent}) => (
            <Stack direction='column' gap={3}>
              <Stack justifyContent='space-between' alignItems='center'>
                <CardHeader
                  css={`
                    margin: 0;
                  `}>
                  Your Podcasts
                </CardHeader>
                <Button color='secondary' to={`${url}/verify`}>
                  Add Podcast
                </Button>
              </Stack>
              {(searchContent || filtersContent) && (
                <Stack alignItems='center'>
                  {searchContent}
                  {filtersContent}
                </Stack>
              )}
            </Stack>
          )}
          bulkActions={[
            {
              title: 'Check Processing',
              description:
                'Check if Ad Analytics is recieving data for these Podcasts.',
              onClick: (rows) => setAction({actionName: 'processing', rows}),
            },
            {
              title: 'Update Podcasts',
              description:
                'Force Ad Analytics to look for updates to these feeds.',
              onClick: (rows) => setAction({actionName: 'update', rows}),
            },
            {
              title: 'Remove Podcasts',
              description:
                'Remove these podcasts from your Ad Analytics account.',
              onClick: (rows) => setAction({actionName: 'remove', rows}),
            },
          ]}
        />
      </Card>
      <Snackbar
        opened={!!error}
        toggle={() => setError(false)}
        severity='error'
        noCloseButton={false}
        autoHideDuration={null}>
        {error}
      </Snackbar>
    </>
  );
};

export default Podcasts;
