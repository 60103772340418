import {useQuery} from '@apollo/client';
import {CampaignsLiveBadge} from '@core/components/CampaignStateBadge';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import DataTable, {
  DataTableColumn,
  DataTableLinkButton,
} from '@core/ui/DataTable';
import {Icon} from '@core/ui/Icon';
import {CleanLink} from '@core/ui/Link';
import {LoadingSpinner} from '@core/ui/Loading';
import PixelEventsTinyChart from '@analytics/components/pixels/PixelEventsTinyChart';
import ORGANIZATION_KEY_DEBUG_HISTORICAL_QUERY, {
  IOrganizationKeyDebugHistorical,
  IOrganizationKeyDebugHistoricalInput,
} from '@analytics/graphql-api/_old_queries/OrganizationKeyDebugHistoricalQuery';

interface ICompany {
  organization: {
    id: string;
    name: string;
    kind: string;
    keys: Array<{
      key: string;
    }>;
    slug: string;
  };
  campaigns: Array<{
    state: string;
  }>;
}

interface ICompanyData {
  name: string;
  organizationId: string;
  campaignsCount: number;
  liveCampaignsCount: number;
  manageUrl: string;
  pixelKey: string;
  pixelUrl: string;
}

const getTableData = ({companies}: {companies: Array<ICompany>}) => {
  const data: Array<ICompanyData> = [];
  if (Array.isArray(companies) && companies.length) {
    companies.forEach(({organization, campaigns}) => {
      if (organization) {
        const {name, id, keys} = organization;
        const pixel = Array.isArray(keys) && keys[0] ? keys[0] : null;

        data.push({
          name,
          organizationId: id,
          campaignsCount: campaigns.length,
          liveCampaignsCount: campaigns.filter((_c) => _c.state === 'LIVE')
            .length,
          manageUrl: createOrgPath(organization),
          pixelKey: pixel ? pixel.key : '',
          pixelUrl: pixel
            ? createOrgPath(organization, `/manage/pixels/${pixel.key}`)
            : '',
        });
      }
    });
  }

  return data;
};

interface IPixelEventsCellProps {
  organizationId: string;
  pixelKey: string;
  pixelUrl: string;
}

const PixelEventsCell = ({
  organizationId,
  pixelKey,
  pixelUrl,
}: IPixelEventsCellProps): JSX.Element => {
  const height = 50;
  const {data, loading, error} = useQuery<
    IOrganizationKeyDebugHistorical,
    IOrganizationKeyDebugHistoricalInput
  >(ORGANIZATION_KEY_DEBUG_HISTORICAL_QUERY, {
    variables: {
      organizationId,
      key: pixelKey,
      after: dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
      before: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    },
    skip: !pixelKey,
  });

  if (!pixelKey || !data || loading || error) {
    return (
      <div
        css={`
          align-items: center;
          display: flex;
          height: ${height}px;
          justify-content: center;
        `}>
        {loading ? <LoadingSpinner centered size='small' /> : '-'}
      </div>
    );
  }

  const {historical, hasData} = data.me.organization.key.debug;

  return (
    <CleanLink
      to={pixelUrl}
      onClick={(evt) => evt.stopPropagation()}
      css={`
        cursor: pointer;
        display: block;
        :hover {
          filter: brightness(0.8);
        },

      `}>
      {hasData ? (
        <PixelEventsTinyChart historical={historical} height={height} />
      ) : (
        <div
          css={`
            align-items: center;
            display: flex;
            height: ${height}px;
            justify-content: center;
          `}>
          <Icon
            icon='alert'
            rules={() => ({color: 'var(--red-orange)', width: '1.5rem'})}
          />
        </div>
      )}
    </CleanLink>
  );
};

interface IAgencyCompaniesTableProps {
  companies: Array<ICompany>;
}

const AgencyCompaniesTable = ({
  companies,
}: IAgencyCompaniesTableProps): JSX.Element => {
  const data = getTableData({companies});

  const columns: DataTableColumn[] = [
    {
      title: 'Brand',
      accessor: 'name',
      type: 'string',
      rule: () => ({fontWeight: 500}),
    },
    {
      title: 'Total Campaigns',
      accessor: 'campaignsCount',
      type: 'number',
    },
    {
      title: 'Live Campaigns',
      accessor: 'liveCampaignsCount',
      type: 'number',
      Cell: ({data: {liveCampaignsCount}}) => (
        <CampaignsLiveBadge count={liveCampaignsCount} />
      ),
    },
    {
      title: 'Pixel Events (7d)',
      noSortable: true,
      alignRight: true,
      rule: () => ({paddingTop: '0.625rem', paddingBottom: '0.625rem'}),
      Cell: ({data: {organizationId, pixelKey, pixelUrl}}) => {
        return (
          <div
            css={`
              margin-left: auto;
              max-width: 7.5rem;
            `}>
            <PixelEventsCell
              organizationId={organizationId}
              pixelKey={pixelKey}
              pixelUrl={pixelUrl}
            />
          </div>
        );
      },
    },
    {
      title: '',
      type: 'number',
      Cell: ({data: {manageUrl}}) => (
        <DataTableLinkButton to={manageUrl}>Manage</DataTableLinkButton>
      ),
    },
  ];

  return (
    <DataTable
      searchKeys={['name']}
      columns={columns}
      orderBy='-liveCampaignsCount'
      data={data}
      searchPlaceholder='Search by brand name'
      emptyMessageTitle='No brands found.'
      emptyMessageText="Your organization doesn't manage any brand yet."
      onClickRowPath={({manageUrl}) => manageUrl}
    />
  );
};

export default AgencyCompaniesTable;
