import {Backdrop} from '@spotify-internal/encore-web';
import {DIALOGUE_STATES} from '@analytics/components/sales-order-review/DialogueStates';
import {useUserInfoQuery} from '@analytics/graphql-api';
import {ApprovalDialogue} from './ApprovalDialogue';
import {ApprovedDialogue} from './ApprovedDialogue';
import {ErroredDialogue} from './ErroredDialogue';
import {SubmitATicketDialogue} from './SubmitATicketDialogue';

export const MappingDialogue = ({
  onClose,
  data,
  approvalDialogueState = DIALOGUE_STATES.CLOSED,
  setApprovalDialogueState,
}) => {
  const {data: userData} = useUserInfoQuery();
  const email = userData?.userInfo?.email || '';

  if (!data) {
    return null;
  }

  return (
    <Backdrop center onClose={onClose}>
      {(approvalDialogueState === DIALOGUE_STATES.APPROVED ||
        approvalDialogueState === DIALOGUE_STATES.EDITED) && (
        <ApprovedDialogue
          onClose={onClose}
          approvalDialogueState={approvalDialogueState}
        />
      )}
      {approvalDialogueState === DIALOGUE_STATES.ERROR && (
        <ErroredDialogue onClose={onClose} />
      )}
      {(approvalDialogueState === DIALOGUE_STATES.APPROVE ||
        approvalDialogueState === DIALOGUE_STATES.EDIT) && (
        <ApprovalDialogue
          email={email}
          approvalDialogueState={approvalDialogueState}
          setApprovalDialogueState={setApprovalDialogueState}
          {...data}
        />
      )}
      {approvalDialogueState === DIALOGUE_STATES.SUBMIT_A_TICKET && (
        <SubmitATicketDialogue
          onClose={onClose}
          {...data}
          setApprovalDialogueState={setApprovalDialogueState}
        />
      )}
    </Backdrop>
  );
};
