import React from 'react';
import {graphql, withApollo} from '@apollo/client/react/hoc';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import ClassifyAd from '@analytics/components/ads/ClassifyAd';
import {ReviewerAdMultiDocument} from '@analytics/graphql-api';

class ClassifyMulti extends React.Component {
  state = {
    redirect: false,
    index: 0,
    count: null,
  };

  componentWillReceiveProps(props) {
    const {
      data: {me},
    } = props;

    const {count} = this.state;

    if (me && count === null) {
      const {adsCount} = me.organization.research.reviewing;

      this.setState({
        count: adsCount,
      });
    }
  }

  onComplete = ({id}) => {
    this.setState({
      index: this.state.index + 1,
      count: this.state.count - 1,
    });

    // See if we need to load anymore.
    const {
      organization,
      data: {
        me: {
          organization: {
            research: {
              reviewing: {ads},
            },
          },
        },
        fetchMore,
      },
    } = this.props;

    const {index} = this.state;

    // we don't need to add more.
    if (ads.length - index > 5) {
      return;
    }

    const offset = ads.length - index;
    // if the number of ads gets small, update the query.
    fetchMore({
      variables: {
        organizationId: organization.id,
        offset,
      },
      updateQuery: (prev, {fetchMoreResult}, p) => {
        const ad_ids = prev.me.organization.research.reviewing.ads.map(
          (ad) => ad.id
        );

        const nextAds =
          fetchMoreResult.me.organization.research.reviewing.ads.filter(
            (ad) => ad_ids.indexOf(ad.id) === -1
          );

        prev.me.organization.research.reviewing.ads = [
          ...prev.me.organization.research.reviewing.ads,
          ...nextAds,
        ];

        return prev;
      },
    });
  };

  goToPreviousAd() {
    this.setState({
      index: this.state.index - 1,
      count: this.state.count + 1,
    });
  }

  render() {
    const {
      user: {reviewer},
      data: {loading, me},
      match: {
        params: {reviewedById, feedId},
      },
    } = this.props;

    const {redirect} = this.state;

    if (loading || me === null) {
      return <Loading centered />;
    }

    const {ads} = me.organization.research.reviewing;
    const {index, count} = this.state;

    return (
      <>
        {count > 0 ? (
          <CardHeader>Ads to clasify ({count} left)</CardHeader>
        ) : null}
        <Card>
          {ads[index] ? (
            <ClassifyAd
              key={index}
              ad={ads[index]}
              autoplay
              reviewerId={reviewer.id}
              onComplete={this.onComplete}
              displayBackButton={index >= 1}
              onBackClick={() => this.goToPreviousAd()}
            />
          ) : (
            <EmptyMessage>No Ads to Review.</EmptyMessage>
          )}
        </Card>
      </>
    );
  }
}

export default withApollo(
  graphql(ReviewerAdMultiDocument, {
    options: ({
      organization,
      match: {
        params: {feedId, reviewedById},
      },
    }) => ({
      variables: {
        organizationId: organization.id,
        feedId: feedId,
        reviewedById: reviewedById,
      },
      fetchPolicy: 'network-only',
    }),
  })(ClassifyMulti)
);
