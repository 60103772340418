import {Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {pixelEditRoutes} from '@analytics/lib/routes/pixelsRoutes';
import {ManagePixelsPixelRouteProps} from '../_routes';
import {PixelAddEvent} from './PixelAddEvent';
import {PixelDeleteEvent} from './PixelDeleteEvent';
import {PixelUpdateEvent} from './PixelUpdateEvent';

export type ManagePixelsPixelEventsRouteProps = ManagePixelsPixelRouteProps & {
  backPath: string;
};

export function PixelEventsIndex({
  organization,
  pixel,
}: ManagePixelsPixelRouteProps) {
  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, `manage/pixels/${pixel.key}`),
    basePath: '/:part(b|a|s|p)/:slug/manage/pixels/:key',
    routes: pixelEditRoutes({baseRoute: 'events'}),
  });

  const backPath = createOrgPath(organization, `manage/pixels/${pixel.key}`);

  return (
    <Switch>
      {renderRoutes({
        components: {
          create: PixelAddEvent,
          update: PixelUpdateEvent,
          delete: PixelDeleteEvent,
        },
        extraProps: {
          organization,
          pixel,
          backPath,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
