import {Switch} from 'react-router-dom';
import {isSelling} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {CampaignApprovalsNav} from '@analytics/components/nav/CampaignApprovalsNav';
import {CampaignRouteProps} from '../_routes';
import {Approvals} from './Approvals';
import {ApprovalsConfirm} from './ApprovalsConfirm';
import {ApprovalsRequest} from './ApprovalsRequest';
import {ApprovalsTrackingUrls} from './ApprovalsTrackingUrls';

export function CampaignApprovalsIndex({
  user,
  organization,
  campaign,
  match: {url},
}: CampaignRouteProps & {
  match: {
    url: string;
  };
}) {
  const {isAdmin} = user;
  const {renderRoutes} = useRoutes({
    baseUrl: url,
    basePath: '/:part(b|a|s|p)/:slug/campaigns/:campaignSlug',
    routes: [
      ...(isAdmin
        ? [
            {key: 'approvals', exact: true},
            {key: 'request', path: 'approvals/request', exact: true},
          ]
        : []),
      {
        key: 'confirm',
        path: 'approvals/confirm',
        exact: true,
      },
      {
        key: 'tracking',
        path: 'approvals/confirm/tracking',
        exact: true,
        isAvailable: isSelling(organization) && campaign.state === 'DRAFT',
      },
    ],
  });

  return (
    <>
      <CampaignApprovalsNav organization={organization} campaign={campaign} />
      <Switch>
        {renderRoutes({
          components: {
            approvals: Approvals,
            request: ApprovalsRequest,
            confirm: ApprovalsConfirm,
            tracking: ApprovalsTrackingUrls,
          },
          extraProps: {
            user,
            organization,
            campaign,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
}
