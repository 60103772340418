import AudioPlayButton from '@core/components/AudioPlayButton/AudioPlayButton';
import {toTimeString} from '@core/lib/time';
import {
  BACKGROUND_MODIFIER_HOVER,
  BACKGROUND_SECONDARY,
  BLUE,
  Element,
  TEXT_NORMAL,
} from '@core/style';

const AudioPlayControls = ({
  playing,
  currentTime,
  duration,
  togglePlay,
  isAdmin,
}) => {
  return (
    <Element
      rules={() => ({
        alignItems: 'center',
        display: 'flex',
        fontSize: '13px',
      })}>
      <Element
        rules={() => ({
          background: BACKGROUND_MODIFIER_HOVER,
          borderRadius: '.5rem',
          color: TEXT_NORMAL,
          cursor: 'pointer',
          display: 'inline-flex',
          marginRight: '0.625rem',
          padding: '0.3438rem',
          ':hover': {
            background: BACKGROUND_SECONDARY,
            color: BLUE,
          },
        })}>
        <AudioPlayButton
          rules={() => ({width: '1.5rem', height: '1.5rem'})}
          onClick={togglePlay}
          playing={playing}
        />
      </Element>
      <Element rules={() => ({padding: '5px'})}>
        {toTimeString(currentTime)} / {toTimeString(duration)}
      </Element>
      {isAdmin ? (
        <Element rules={() => ({padding: '5px'})}>
          ({currentTime} / {duration})
        </Element>
      ) : (
        ''
      )}
    </Element>
  );
};

export default AudioPlayControls;
