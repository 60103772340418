import {createOrgPath} from '@core/lib/organizations';
import {Alert} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {LiftReportTable} from '@analytics/components/lift/IncrementalBrandLift';
import {MeasureIncrementalLiftRouteProps} from './_routes';

export function LiftReports({
  organization,
  user,
  liftReports,
}: MeasureIncrementalLiftRouteProps) {
  return (
    <Card>
      <LiftReportTable
        liftReports={liftReports}
        organization={organization}
        user={user}
        headerRenderer={({filtersContent, searchContent}) => (
          <Stack direction='column' gap={3}>
            <Stack justifyContent='space-between' alignItems='center'>
              <CardHeader
                css={`
                  margin: 0;
                `}>
                Conversion Lift
              </CardHeader>
              <Button
                to={createOrgPath(
                  organization,
                  `/measure/lift/incremental/request`
                )}
                color='secondary'
                css={{marginLeft: 'auto'}}>
                New Lift Report
              </Button>
            </Stack>
            <Alert
              severity='info'
              css={`
                margin: var(--spacing-1) 0 var(--spacing-4);
              `}>
              Conversion lift measures the number of conversions, site visits,
              and any other action directly driven by your audience viewing your
              ad.
            </Alert>
            {(searchContent || filtersContent) && (
              <Stack alignItems='center'>
                {searchContent}
                {filtersContent}
              </Stack>
            )}
          </Stack>
        )}
      />
    </Card>
  );
}
