export function nodeIsString(node) {
  return node && typeof node.valueOf() === 'string';
}
export function renderConfigOrCustom(node, DefaultEl) {
  if (nodeIsString(node)) {
    return DefaultEl;
  }
  return node;
}
export var isWindowDefined = function isWindowDefined() {
  return typeof window !== 'undefined';
};
export var capitalize = function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export function isLtr(ref) {
  var direction = 'ltr';
  if (ref.current) {
    direction = window.getComputedStyle(ref.current).direction;
  }
  return direction === 'ltr';
}