import {useLocation} from 'react-router-dom';
import MapDownloads from '@core/components/MapDownloads';
import {ToastMessage} from '@core/ui/Alert';
import {Card} from '@core/ui/Content';
import {LoadingSpinner} from '@core/ui/Loading';
import {AttributionCountriesTable} from '@analytics/components/attribution/AttributionLocationTable';
import {useBrandLocationsOverviewQuery} from '@analytics/graphql-api';
import {MeasureInsightsLocationsRouteProps} from '.';

type Props = MeasureInsightsLocationsRouteProps & {
  match: {url: string};
};

export function InsightsLocationsOverview({
  after,
  before,
  campaigns,
  match: {url},
  organization,
  publishers,
}: Props) {
  const {search} = useLocation();
  const {data, loading, error} = useBrandLocationsOverviewQuery({
    variables: {
      id: organization.id,
      after,
      before,
      publisherIds: publishers.map(({id}) => id),
      campaignIds: campaigns.map(({id}) => id),
    },
  });

  if (loading) {
    return (
      <LoadingSpinner
        centered
        css={`
          margin-top: var(--spacing-5);
        `}
      />
    );
  }

  if (error) {
    return (
      <ToastMessage alertType='warning' rules={() => ({margin: '1rem 0'})}>
        Error loading data. Please try again later.
      </ToastMessage>
    );
  }

  const impressions = data?.me?.organization?.overview?.impressions;

  const count = impressions?.locations?.count ?? 0;
  const countries = impressions?.locations?.countries ?? [];
  const nodes = impressions?.locations?.nodes ?? [];

  return (
    <>
      {nodes.length > 0 && (
        <Card
          rules={() => ({
            border: 0,
            display: 'flex',
            margin: '1rem 1.5rem 0',
            overflow: 'hidden',
            padding: 0,
            position: 'relative',
          })}>
          <MapDownloads height={500} locations={nodes} />
        </Card>
      )}
      <Card css={{margin: '1rem 1.5rem 0'}}>
        <AttributionCountriesTable
          countries={countries}
          count={count}
          onClickRowPath={({country}) =>
            `${url.replace(/\/$/, '')}/${country}${search}`
          }
        />
      </Card>
    </>
  );
}
