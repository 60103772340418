import {RouteType} from '@core/lib/useRoutes';

export const pixelEditRoutes = ({
  baseRoute,
}: {
  baseRoute: string;
}): RouteType[] => {
  return [
    {
      key: 'overview',
      path: baseRoute,
      exact: true,
      isAvailable: baseRoute !== 'events',
    },
    {key: 'create', path: `${baseRoute}/create`, exact: true},
    {key: 'update', path: `${baseRoute}/:id/update`, exact: true},
    {key: 'delete', path: `${baseRoute}/:id/delete`, exact: true},
  ];
};
