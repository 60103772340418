import {createOrgPath} from '@core/lib/organizations';
import {RouteType} from '@core/lib/useRoutes';
import {
  DropdownMenuDivider,
  DropdownMenuItem,
  DropdownMenuTitle,
} from '@core/ui/DropdownMenu';
import {Nav} from '@core/ui/Nav';
import {useOverlay} from '@core/ui/Overlay';
import {
  BrandLiftPublishOverlay,
  canPublishSblReport,
} from '@analytics/components/lift/SpotifyBrandLift';
import {MeasureSpotifyLiftRouteProps} from '@analytics/screens/unified/measure/lift/spotify/report/_routes';

export function SpotifyLiftReportNav({
  organization,
  user,
  liftReport,
  routes,
}: MeasureSpotifyLiftRouteProps & {
  routes: RouteType[];
}) {
  const [opened, toggle] = useOverlay();
  return (
    <>
      <Nav
        title={liftReport.name ?? 'Spotify Brand Lift Report'}
        items={routes}
        breadcrumbs={[
          {
            title: 'Lift Reports',
            to: createOrgPath(organization, 'measure/lift/spotify'),
          },
          {
            title: 'Spotify Brand Lift Report',
          },
        ]}
        moreDropdownItems={[
          {
            title: 'Export...',
            disabled: true,
          },
          ...(canPublishSblReport({user, liftReport})
            ? [
                {content: <DropdownMenuDivider />},
                {
                  content: (
                    <DropdownMenuTitle>
                      Only visible to Spotify members:
                    </DropdownMenuTitle>
                  ),
                },
                {
                  content: (
                    <DropdownMenuItem
                      item={{
                        title: 'Publish',
                        disabled: liftReport.isPublished,
                      }}
                      onSubmit={() => toggle(true)}
                      toggle={toggle}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
      <BrandLiftPublishOverlay
        opened={opened}
        toggle={toggle}
        liftReport={liftReport}
      />
    </>
  );
}
