import styled from 'styled-components';
import dayjs from '@core/lib/dayjs';
import {comma, financial} from '@core/lib/filters';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {FetchedIncrementalLiftReport} from '@analytics/graphql-api';
import {IncrementalCampaignLiftGroup, IncrementalLiftGroup} from '../types';

export function LiftGroupStats({
  liftReport,
  group,
}: {
  liftReport: FetchedIncrementalLiftReport;
  group: IncrementalLiftGroup | IncrementalCampaignLiftGroup;
}) {
  const startRange = dayjs(liftReport.impressionsStartAt).format('M/D/YYYY');
  const endRange = dayjs(liftReport.impressionsEndAt).format('M/D/YYYY');

  return (
    <_StatsContainer>
      <StatsItem groupName='Start Range' value={startRange} />
      <StatsItem groupName='End Range' value={endRange} />
      <StatsItem groupName='Campaigns' value={group.campaignsCount ?? 1} />
      <StatsItem groupName='Total Spend' value={financial(group.groupCost)} />
      <StatsItem
        borderLeft
        groupName='Exposed Households'
        value={comma(group.exposed_groupHouseholds)}
      />
      <StatsItem
        groupName='Exposed IPs'
        value={comma(group.exposed_groupReach)}
      />
      <StatsItem
        borderLeft
        groupName='Baseline Households'
        value={comma(group.baseline_groupHouseholds)}
      />
      <StatsItem
        groupName='Baseline IPs'
        value={comma(group.baseline_groupReach)}
      />
    </_StatsContainer>
  );
}

const _StatsContainer = styled(Stack)`
  border-bottom: 1px solid var(--border-default);
  margin-bottom: 1rem;
  padding: 0 0 1rem 0;
`;

const _ItemContainer = styled.div<{borderLeft: boolean}>`
  border-left: ${(div) => div.borderLeft && '1px solid var(--border-muted)'};
  display: grid;
  flex-grow: 1;
  gap: 0.5rem;
  grid-template-rows: 1fr 1fr;
  ${(p) =>
    p.borderLeft
      ? 'border-left: 1px solid var(--border-muted); padding: 0 1.5rem;'
      : 'padding: 0 1rem'}
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`;

const StatsItem = ({
  borderLeft = false,
  groupName,
  value,
}: {
  borderLeft?: boolean;
  groupName: string;
  value: string | number;
}) => (
  <_ItemContainer borderLeft={borderLeft}>
    <Text
      color='muted'
      variant='body2'
      css={{alignSelf: 'end', fontWeight: 'var(--font-weight-medium)'}}>
      {groupName}
    </Text>
    <Text variant='h4'>{value}</Text>
  </_ItemContainer>
);
