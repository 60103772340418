import {useState} from 'react';
import {copyToClipboard} from '@core/lib/utils';
import {ButtonIcon} from '@core/ui/Button';
import {DropdownMenu} from '@core/ui/DropdownMenu';
import {DeleteOverlay, useOverlay} from '@core/ui/Overlay';
import {Snackbar} from '@core/ui/Snackbar';
import {
  DeleteSavedExportDocument,
  FetchedBuySideOrganization,
  FetchedOrganization,
  FetchedSavedExports,
  SavedExportsDocument,
  useExportTokenUriMutation,
} from '@analytics/graphql-api';
import ReportsFormOverlay from '../ReportsFormOverlay';
import {generateReportApiParams} from '../helpers';

type Props = {
  disabled?: boolean;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  savedExport: FetchedSavedExports[number];
};

export function ReportMoreButton({disabled, organization, savedExport}: Props) {
  const [_loading, setLoading] = useState(false);
  const [openedError, toggleError] = useOverlay();
  const [copied, toggleCopied] = useOverlay();
  const [openedDeleteOverlay, toggleDeleteOverlay] = useOverlay();
  const [openedReportsFormOverlay, toggleReportsFormOverlay] = useOverlay();
  const [createExportTokenUri] = useExportTokenUriMutation();

  const onCopyDownloadLink = async () => {
    setLoading(true);

    const params = generateReportApiParams({savedExport});

    try {
      const exportUri = await createExportTokenUri({
        organizationId: params.organization_id,
        objectType: params.object_type,
        kind: params.kind,
        pk: params.pk,
        params: params,
      });

      copyToClipboard(exportUri);
      toggleCopied(true);
    } catch (e) {
      console.warn(e);
      toggleError(true);
    }
    setLoading(false);
  };

  return (
    <>
      <DropdownMenu
        items={[
          {
            title: 'Copy Download Link',
            callback: onCopyDownloadLink,
            disabled,
          },
          {
            title: 'Edit Report',
            callback: () => toggleReportsFormOverlay(),
            disabled,
          },
          {
            title: 'Delete Report',
            callback: () => toggleDeleteOverlay(),
          },
        ]}>
        <ButtonIcon
          icon='more'
          disabled={disabled}
          css={{padding: 0, transform: 'rotate(90deg)'}}
        />
      </DropdownMenu>
      <ReportsFormOverlay
        opened={openedReportsFormOverlay}
        organization={organization}
        savedExport={savedExport}
        toggle={toggleReportsFormOverlay}
      />
      <DeleteOverlay
        opened={openedDeleteOverlay}
        toggle={toggleDeleteOverlay}
        title='Delete Report'
        mutation={DeleteSavedExportDocument}
        refetchQueries={[SavedExportsDocument]}
        submitLabel='Delete'
        toVariables={() => ({
          input: {id: savedExport?.id},
        })}>
        <p>Are you sure you want to delete "{savedExport?.name}"?</p>
      </DeleteOverlay>
      <Snackbar
        severity='error'
        autoHideDuration={4000}
        opened={openedError}
        toggle={toggleError}>
        Error generating download link. Please try again.
      </Snackbar>
      <Snackbar
        autoHideDuration={4000}
        opened={copied}
        toggle={toggleCopied}
        severity='success'>
        Copied download link to clipboard
      </Snackbar>
    </>
  );
}
