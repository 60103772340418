import {graphql} from '@apollo/client/react/hoc';
import {Pie, PieChart} from 'recharts';
import {comma} from '@core/lib/filters';
import {Element} from '@core/style';
import {BLUE, GREEN, PINK} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import {FeedImage} from '@core/ui/Image';
import Loading from '@core/ui/Loading';
import BrandCampaignOverlapQuery from '@analytics/graphql-api/_old_queries/BrandCampaignOverlapQuery';

const PieChartImpl = ({baseReach, compReach, overlap}) => {
  return (
    <PieChart
      width={100}
      height={100}
      margin={{top: 0, right: 0, bottom: 0, left: 0}}>
      <Pie
        data={[
          {
            name: 'Reach A',
            value: baseReach,
            fill: GREEN,
            stroke: 'none',
          },

          {
            name: 'Reach B',
            value: compReach,
            fill: BLUE,
            stroke: 'none',
          },
        ]}
        outerRadius='100%'
        innerRadius='75%'
      />
      <Pie
        data={[
          {
            name: 'Overlap',
            value: overlap,
            fill: PINK,
            stroke: 'none',
          },
          {
            name: '',
            value: baseReach + compReach - overlap,
            fill: 'var(--bg-default)',
            stroke: 'none',
          },
        ]}
        outerRadius='75%'
        innerRadius='0%'
      />
    </PieChart>
  );
};

const CompareText = ({
  baseTitle,
  baseReach,
  compTitle,
  compReach,
  overlap,
  rules,
}) => {
  const baseTitleEl = (
    <span
      css={`
        font-weight: 500;
        color: var(--green);
      `}>
      {baseTitle}
    </span>
  );
  const compTitleEl = (
    <span
      css={`
        font-weight: 500;
        color: var(--blue);
      `}>
      {compTitle}
    </span>
  );

  return (
    <Element rules={rules}>
      {baseReach > compReach ? (
        <>
          {baseTitleEl} has{' '}
          <b>{(((baseReach - compReach) / compReach) * 100).toFixed(2)}%</b>{' '}
          more reach than {compTitleEl}
        </>
      ) : (
        <>
          {compTitleEl} has{' '}
          <b>{(((compReach - baseReach) / baseReach) * 100).toFixed(2)}%</b>{' '}
          more reach than {baseTitleEl}
        </>
      )}
      , with an overlap of{' '}
      <Element tag='span' rules={() => ({color: PINK, fontWeight: 500})}>
        {comma(overlap)} households
      </Element>{' '}
      <Element tag='span' rules={() => ({color: PINK, fontWeight: 500})}>
        ({((overlap * 100) / (baseReach + compReach)).toFixed(2)}%)
      </Element>
    </Element>
  );
};

const CompareItem = ({title, feed, reach, color}) => {
  return (
    <Element
      rules={() => ({
        display: 'grid',
        gridGap: '1rem',
        alignItems: 'center',
        gridTemplateColumns: '15rem 5rem',
        [`@media (min-width: 1200px)`]: {
          gridTemplateColumns: '25rem 5rem',
        },
      })}>
      <Element rules={() => ({display: 'flex', alignItems: ' center'})}>
        {feed ? (
          <FeedImage
            feed={feed}
            width={100}
            rules={() => ({
              marginRight: '1rem',
              padding: '0',
              width: '4.6875rem',
            })}
          />
        ) : (
          <Element
            rules={({theme}) => ({
              border: `1px solid ${theme}`,
              marginRight: '1rem',
              minHeight: '4.6875rem',
              minWidth: '4.6875rem',
            })}
          />
        )}

        <div>
          <Element
            rules={() => ({
              fontWeight: '500',
              color,
              lineHeight: 1.5,
              wordBreak: 'break-all',
            })}>
            {title}
          </Element>
          {feed ? (
            <Element
              rules={({theme}) => ({
                color: theme.textTertiary,
                marginTop: '0.3125rem',
              })}>
              {feed.title}
            </Element>
          ) : null}
        </div>
      </Element>
      <Element rules={() => ({whiteSpace: 'nowrap'})}>
        <Element rules={() => ({fontSize: '1.125rem', fontWeight: 500})}>
          {comma(reach)}
        </Element>
        <Element rules={({theme}) => ({color: theme.textTertiary})}>
          Reach
        </Element>
      </Element>
    </Element>
  );
};

const CampaignOverlapContent = ({pairs}) => {
  return (
    <div>
      {pairs.map(
        (
          {
            baseCampaignDynamic,
            baseCampaignStreaming,
            baseFeed,
            baseReach,
            compCampaignDynamic,
            compCampaignStreaming,
            compFeed,
            compReach,
            overlap,
          },
          idx
        ) => {
          const baseTitle = baseCampaignDynamic
            ? baseCampaignDynamic.name
            : baseCampaignStreaming
            ? baseCampaignStreaming.name
            : baseFeed
            ? baseFeed.title
            : '';
          const compTitle = compCampaignDynamic
            ? compCampaignDynamic.name
            : compCampaignStreaming
            ? compCampaignStreaming.name
            : compFeed
            ? compFeed.title
            : '';

          return (
            <Element
              key={baseTitle + compTitle}
              rules={({theme}) => ({
                alignItems: 'center',
                borderBottom: `1px solid ${theme.borderPrimary}`,
                display: 'grid',
                fontSize: '0.9375rem',
                gridGap: '2rem',
                gridTemplateColumns: 'max-content max-content 1fr',
                padding: idx ? '2rem 0' : '1rem 0 2rem 0',
              })}>
              <Element
                rules={() => ({
                  display: 'grid',
                  gridGap: '1.5rem',
                  gridTemplateRows: '1fr 1fr',
                  minWidth: 0,
                })}>
                <CompareItem
                  title={baseTitle}
                  color={GREEN}
                  reach={baseReach}
                  overlap={overlap}
                  feed={baseFeed}
                />
                <CompareItem
                  title={compTitle}
                  color={BLUE}
                  reach={compReach}
                  overlap={overlap}
                  feed={compFeed}
                />
              </Element>
              <PieChartImpl
                baseReach={baseReach}
                compReach={compReach}
                overlap={overlap}
              />
              <CompareText
                baseTitle={baseTitle}
                baseReach={baseReach}
                compTitle={compTitle}
                compReach={compReach}
                overlap={overlap}
              />
            </Element>
          );
        }
      )}
    </div>
  );
};

const CampaignOverlap = ({data: {loading, me}}) => {
  if (loading) {
    return <Loading centered />;
  }

  let pairs = [];
  let error = false;
  try {
    pairs = me.organization.campaign.overlap.pairs;
  } catch (err) {
    error = true;
  }

  return (
    <Card>
      <CardHeader>Overlap</CardHeader>
      {error || pairs.length < 1 ? (
        <EmptyMessage>
          We do not have enough data to run an overlap analysis yet.
        </EmptyMessage>
      ) : (
        <CampaignOverlapContent pairs={pairs} />
      )}
    </Card>
  );
};

export default graphql(BrandCampaignOverlapQuery, {
  options: ({organization, campaign: {id}}) => {
    return {
      variables: {
        organizationId: organization.id,
        id,
      },
    };
  },
})(CampaignOverlap);
