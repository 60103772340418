import {Card, CardHeader, PageTitle} from '@core/ui/Content';
import {CampaignsTable} from '@analytics/components/campaigns/CampaignsTable';
import {SpotifySalesOrdersCard} from '@analytics/components/campaigns/spotify-orders';
import PodcasterGettingStarted from '@analytics/components/getting-started/PodcasterGettingStarted';
import PodcastsTable from '@analytics/components/podcasts/PodcastsTable';
import UpcomingEpisodesPodcasterCalendar from '@analytics/components/upcoming-episodes/UpcomingEpisodesPodcasterCalendar';
import {FetchedOrganization} from '@analytics/graphql-api';

type Props = {
  organization: FetchedOrganization;
};

export function PodcasterHome({organization}: Props) {
  return (
    <PodcasterGettingStarted organization={organization}>
      <PageTitle>{organization.name}</PageTitle>
      <SpotifySalesOrdersCard organization={organization} />
      <Card>
        <CardHeader>Campaigns</CardHeader>
        <CampaignsTable
          organization={organization}
          filtersStorageKey={`campaigns-podcaster-${organization.slug}`}
        />
      </Card>
      <Card>
        <CardHeader>Upcoming Episodes</CardHeader>
        <UpcomingEpisodesPodcasterCalendar organization={organization} />
      </Card>
      <Card>
        <CardHeader>Podcasts</CardHeader>
        <PodcastsTable organization={organization} />
      </Card>
    </PodcasterGettingStarted>
  );
}
