import { controlSizeFallback, cssBorderRadius, cssControlSize, cssGraphicSize, cssSpacing, formControlBase, formControlTheme } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
export var getPadding = function getPadding(size) {
  return size === 'small' ? cssSpacing('tighter-3', '6px') : cssSpacing('tighter', '12px');
};
var getDownArrowSize = function getDownArrowSize(size) {
  return size === 'small' ? cssGraphicSize('smaller', 'decorative', '16px') : cssGraphicSize('base', 'decorative', '24px');
};
export var Button = styled.button.withConfig({
  displayName: "Button",
  componentId: "sc-nfsrja-0"
})(["", ""], function (_ref) {
  var $size = _ref.$size,
    $useBrowserDefaultFocusStyle = _ref.$useBrowserDefaultFocusStyle,
    $isUsingKeyboard = _ref.$isUsingKeyboard,
    $isHover = _ref.$isHover,
    $isFocus = _ref.$isFocus,
    $error = _ref.$error;
  return css(["", ";text-align:start;overflow-wrap:break-word;min-block-size:", ";border-radius:", ";padding-block-start:", ";padding-block-end:", ";padding-inline-start:", ";padding-inline-end:calc((2 * ", ") + ", ");", ";"], formControlBase($useBrowserDefaultFocusStyle, $size), $size === 'small' ? cssControlSize('smaller', controlSizeFallback.small) : cssControlSize('base', controlSizeFallback.medium), cssBorderRadius('base', '4px'), getPadding($size), getPadding($size), getPadding($size), getPadding($size), getDownArrowSize($size), formControlTheme({
    isUsingKeyboard: $isUsingKeyboard,
    isHover: $isHover,
    isFocus: $isFocus,
    error: $error,
    useBrowserDefaultFocusStyle: $useBrowserDefaultFocusStyle
  }));
});