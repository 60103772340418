import {QueryResult} from '@apollo/client';
import {Query} from '@apollo/client/react/components';
import {Stack} from '@mui/system';
import {toPercent} from '@core/lib/filters';
import {Alert} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import Stat from '@core/ui/Stat';
import {Text} from '@core/ui/Text';
import InsightsPerformancePlayersPieChart from '@analytics/components/insights/InsightsPerformancePlayersPieChart';
import InsightsPerformancePlayersTable from '@analytics/components/insights/InsightsPerformancePlayersTable';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import CampaignPlayersQuery from '@analytics/graphql-api/_old_queries/CampaignPlayersQuery';
import ErrorCords from '@analytics/static/error-cords.svg';
import {CampaignRouteProps} from './_routes';

const UserAgentError = ({title, children}) => (
  <>
    <div
      css={`
        display: flex;
        height: 125px;
        justify-content: center;
      `}>
      <img src={ErrorCords} alt='Error Cords' />
    </div>
    <Text
      variant='h3'
      css={`
        text-align: center;
        margin-top: 1rem;
      `}>
      {title}
    </Text>
    <div
      css={`
        display: flex;
        justify-content: center;
      `}>
      <p
        css={`
          max-width: 70%;
          text-align: center;
          font-size: 0.9rem;
          margin-top: 0.5rem;
        `}>
        {children}
      </p>
    </div>
  </>
);

const UserAgentConversions = ({enhancedConversions}) => {
  if (!enhancedConversions) {
    return (
      <UserAgentError title='Campaign not eligible'>
        This campaign is not eligible for the Conversion Rate by Player feature.
        Only attribution campaigns started after January 2023 are qualified.
      </UserAgentError>
    );
  }

  const {
    spotifyAttribution,
    appleAttribution,
    spotifyCr,
    appleWeightedCr,
    otherWeightedCr,
  } = enhancedConversions;

  if (spotifyAttribution < 25 || appleAttribution < 25) {
    return (
      <UserAgentError title='Feature not available'>
        This campaign is not qualified for the Conversion Rate by Player
        feature. Attribution campaigns need a statistically significant number
        of conversions on both Apple Podcasts and Spotify to be eligible. If
        your campaign just started, please check back later.
      </UserAgentError>
    );
  }

  const diff = (spotifyCr - appleWeightedCr) / spotifyCr;

  return (
    <>
      <div>
        <Stack direction='row' spacing={10}>
          <Stat
            title='Apple Podcasts Conversion Rate'
            value={toPercent(appleWeightedCr, 4)}
            description='Percent of users that visited the site or app per impression listened to on Apple Podcasts.'
          />
          <Stat
            title='Spotify Conversion Rate'
            value={toPercent(spotifyCr, 4)}
            description='Percent of users that visited the site or app per impression listened to on Spotify.'
          />
          <Stat
            title='All Other Players Conversion Rate'
            value={toPercent(otherWeightedCr, 4)}
            description='Percent of users that visited the site or app per impression listened to on All Other Players.'
          />
        </Stack>
      </div>
      <div
        css={`
          flex: 1;
          display: flex;
          align-items: end;
        `}>
        <Alert
          severity='insight'
          css={`
            font-size: 0.8rem;
            margin-top: 2rem;
          `}>
          <p
            css={`
              margin-bottom: 0;
            `}>
            To better understand how players affect performance, we calculate
            conversion rate as attributed household divided by impressions to
            the player in this section. By default, Spotify Ad Analytics
            measures conversion rate as attributed households divided by reach,
            so the conversion rate by impression will also be orders of
            magnitude lower than the default. Due to the long tail of players
            after Apple Podcasts and Spotify, we bucket those all together under
            All Other Players.
          </p>
        </Alert>
      </div>
    </>
  );
};

export function CampaignPlayers({
  organization,
  campaign,
  user,
}: CampaignRouteProps) {
  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <Query
        query={CampaignPlayersQuery}
        variables={{
          organizationId: organization.id,
          id: campaign.id,
        }}>
        {({loading, error, data}: QueryResult) => {
          if (loading) {
            return <Loading centered />;
          }

          if (error) {
            return (
              <Card>
                <EmptyMessage>No players data.</EmptyMessage>
              </Card>
            );
          }

          const players =
            data?.me?.organization?.campaign?.impressions?.players;
          const enhancedConversions =
            data?.me?.organization?.campaign?.enhancedConversions;

          const hasEnhancedConversions =
            enhancedConversions &&
            enhancedConversions.spotifyAttribution >= 25 &&
            enhancedConversions.appleAttribution >= 25;

          return (
            <>
              <div
                css={`
                  display: grid;
                  grid-template-columns: 450px 450px 1fr;
                  @media (max-width: 1800px) {
                    grid-template-columns: 1fr 1fr;
                  }
                `}>
                <Card
                  css={`
                    @media (max-width: 1800px) {
                      order: 1;
                    }
                  `}>
                  <CardHeader>Impressions By Player</CardHeader>
                  <InsightsPerformancePlayersPieChart players={players} />
                </Card>
                <Card
                  css={`
                    @media (max-width: 1800px) {
                      order: 2;
                    }
                  `}>
                  <CardHeader>Reach By Player</CardHeader>
                  <InsightsPerformancePlayersPieChart
                    players={players}
                    attr='reach'
                  />
                </Card>

                <Card
                  css={`
                    flex 1;
                    display: flex;
                    flex-direction: column;
                    @media (max-width: 1800px) {
                      grid-column: span 2;
                      order: ${hasEnhancedConversions ? '0' : '3'};
                    }
                  `}>
                  <CardHeader>Conversion Rate by Player</CardHeader>
                  <UserAgentConversions
                    enhancedConversions={enhancedConversions}
                  />
                </Card>
              </div>
              <Card>
                <InsightsPerformancePlayersTable players={players} />
              </Card>
            </>
          );
        }}
      </Query>
    </>
  );
}
