import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {FetchedLimitedCompany} from '@analytics/graphql-api';
import {ManageInviteRouteProps} from '../_routes';

export type ManageInviteCompanyRouteProps = ManageInviteRouteProps & {
  company: FetchedLimitedCompany;
};

export const useInviteCompanyRoutes = ({
  organization,
  companyId,
}: Pick<ManageInviteCompanyRouteProps, 'organization'> & {
  companyId: string;
}) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, `/manage/invite/${companyId}`),
    basePath: `/:part(a|s|p)/:slug/manage/invite/:companyId`,
    routes: [
      {key: 'invite-members', path: '', exact: true},
      {key: 'invite-pixel', path: ':inviteeOrganizationId/pixel', exact: true},
    ],
  });
};

export const toInvitePixelUrl = ({
  company,
  inviteeOrganizationId,
  organization,
}: Pick<ManageInviteCompanyRouteProps, 'organization' | 'company'> & {
  inviteeOrganizationId: string;
}) => {
  return createOrgPath(
    organization,
    `/manage/invite/${company.id}/${inviteeOrganizationId}/pixel`
  );
};
