import {useMemo, useState} from 'react';
import styled from 'styled-components';
import {FetchedUser} from '@core/graphql-api';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import {Link} from '@core/ui/Link';
import Select from '@core/ui/Select';
import {campaignLiftReportURL} from '@analytics/components/nav/CampaignLiftReportNav';
import {measureLiftReportUrl} from '@analytics/components/nav/MeasureLiftNav';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedIncrementalLiftReport,
  FetchedOrganization,
  LiftReportState,
} from '@analytics/graphql-api';
import {LiftAveragesTable} from './LiftAveragesTable';
import {LiftGroup} from './LiftGroup';
import {
  generateCampaignLift,
  getLiftDynamicBaselines,
  shouldDisplayUnavailable,
} from './helpers';

export function LiftReport({
  user,
  organization,
  campaign,
  liftReport,
}: {
  user: FetchedUser;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign?: FetchedCampaign;
  liftReport: FetchedIncrementalLiftReport;
}) {
  const displayUnavailableMessage = shouldDisplayUnavailable(liftReport, user);
  const dynamicBaselines = getLiftDynamicBaselines(liftReport);

  const [dynamicBaselineGroupId, setdynamicBaselineGroupId] = useState(
    dynamicBaselines?.[0]?.groupId ?? undefined
  );

  const {
    groups,
    campaignGroup,
    baselineAverages,
    exposedAverages,
    noiseAverages,
  } = useMemo(
    () => generateCampaignLift({liftReport, dynamicBaselineGroupId}),
    [dynamicBaselineGroupId, liftReport]
  );

  return (
    <>
      {displayUnavailableMessage ? (
        <Card css={{marginTop: 'var(--spacing-4)'}}>
          <EmptyMessage>
            This incremental lift report is {liftReport.state.toLowerCase()},
            and the Ad Analytics team will reach out soon.{' '}
            {!!liftReport.requestedBy && (
              <span>
                {liftReport.requestedBy.firstName}{' '}
                {liftReport.requestedBy.lastName} (
                {liftReport.requestedBy.email}) requested the report.
              </span>
            )}
          </EmptyMessage>
        </Card>
      ) : (
        <>
          {liftReport.state === LiftReportState.Draft && (
            <_DraftContainer>
              <div>
                <b>This Lift Report is a Draft.</b> The advertiser cannot yet
                view this lift report.
              </div>
              <Link
                to={
                  campaign
                    ? `${campaignLiftReportURL(
                        organization,
                        campaign,
                        liftReport
                      )}/update`
                    : `${measureLiftReportUrl(organization, liftReport)}/update`
                }
                css={`
                  color: var(--text-muted);
                  font-weight: 500;
                `}>
                Set live
              </Link>
            </_DraftContainer>
          )}
          {!liftReport.useNoise && dynamicBaselines.length > 1 && (
            <Select
              css={{margin: '0 1.5rem 1.5rem'}}
              defaultValue={dynamicBaselineGroupId}
              items={dynamicBaselines.map((db) => ({
                title: db?.groupName,
                value: db?.groupId,
              }))}
              onSelect={({value}) => setdynamicBaselineGroupId(value)}
              placeholder='Select Baseline'
            />
          )}
          <LiftGroup group={campaignGroup} liftReport={liftReport} />
          {groups.map((group) => (
            <LiftGroup
              key={group.groupId}
              group={group}
              liftReport={liftReport}
            />
          ))}
          <CardHeader>Control</CardHeader>
          <Card>
            <LiftAveragesTable
              data={baselineAverages.length ? baselineAverages : noiseAverages}
            />
          </Card>
          <CardHeader>Exposed</CardHeader>
          <Card>
            <LiftAveragesTable data={exposedAverages} />
          </Card>
        </>
      )}
    </>
  );
}

const _DraftContainer = styled.div`
  background: var(--bg-muted);
  border-radius: 0.5rem;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  margin: 1.5rem 1.5rem 1.5rem;
  padding: 0.625rem 1rem;
`;
