import React from 'react';
import {useQuery} from '@apollo/client';
import {Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {ToastMessage} from '@core/ui/Alert';
import {LoadingSpinner} from '@core/ui/Loading';
import ManageNav from '@analytics/components/nav/ManageNav';
import {
  FetchedMembersDomains,
  FetchedMembersMemberships,
} from '@analytics/graphql-api';
import MEMBERS_QUERY from '@analytics/graphql-api/_old_queries/MembersQuery';
import {MeasureRouteProps} from '../../measure';
import {MemberAdd} from './MemberAdd';
import {MemberUpdate} from './MemberUpdate';
import {Members} from './Members';
import {MembersAddDomain} from './MembersAddDomain';

export type ManageMembersRouteProps = MeasureRouteProps & {
  domains: FetchedMembersDomains;
  isAdmin: boolean;
  memberships: FetchedMembersMemberships;
};

export function ManageMembersIndex({user, organization}: MeasureRouteProps) {
  const {renderRoutes} = useRoutes({
    basePath: '/:part(a|s|b|p)/:slug/manage/members',
    baseUrl: createOrgPath(organization, '/manage/members'),
    routes: [
      {exact: true, key: 'members', path: ''},
      {
        exact: true,
        key: 'add',
        isAvailable:
          organization.meRole === 'admin' && organization.slug !== 'spotify',
      },
      {exact: true, key: 'update', path: ':membershipId/update'},
      {exact: true, key: 'add-domain'},
    ],
  });

  const {data, error, loading} = useQuery(MEMBERS_QUERY, {
    variables: {
      id: organization.id,
    },
  });

  let content: React.ReactNode;

  if (loading) {
    content = <LoadingSpinner centered />;
  } else if (error) {
    content = (
      <ToastMessage alertType='error'>
        Network error. Please try again later.
      </ToastMessage>
    );
  } else {
    const memberships = data?.me?.organization?.memberships ?? [];
    const isAdmin = data?.me?.organization?.isAdmin;
    const domains = data?.me?.organization?.domains ?? [];

    content = (
      <Switch>
        {renderRoutes({
          components: {
            members: Members,
            add: MemberAdd,
            update: MemberUpdate,
            'add-domain': MembersAddDomain,
          },
          extraProps: {
            organization,
            user,
            memberships,
            isAdmin,
            domains,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    );
  }

  return (
    <>
      <ManageNav user={user} organization={organization} />
      {content}
    </>
  );
}
