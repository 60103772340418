import React from 'react';
import {truncate} from '@core/lib/words';
import {Element} from '@core/style';
import {EpisodeImage} from '@core/ui/Image';

const EpisodeCell = ({data: {episode, verified, feed}}) => {
  return (
    <Element rules={() => ({display: 'flex', minWidth: '15rem'})}>
      <Element
        rules={() => ({
          flex: '0 0 4.6875rem',
          marginTop: '0.25rem',
          marginRight: '1rem',
        })}>
        <EpisodeImage episode={episode} feed={feed} verified={verified} />
      </Element>
      <Element rules={() => ({flex: 1})}>
        <Element
          rules={({theme}) => ({color: theme.textPrimary, fontWeight: 500})}>
          {episode.title}
        </Element>
        <Element
          rules={({theme}) => ({
            color: theme.textTertiary,
            fontSize: '.875rem',
            lineHeight: 1.4,
          })}>
          {truncate(episode.description, 70)}
        </Element>
      </Element>
    </Element>
  );
};

export {EpisodeCell};
