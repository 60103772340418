import {SpotifyLiftStateBadge} from '@core/components/LiftStateBadge';
import {FetchedUser} from '@core/graphql-api';
import {
  DataTableColumn,
  DataTableDotsDropdownMenuCell,
} from '@core/ui/DataTable';
import {DropdownMenuTitle} from '@core/ui/DropdownMenu';
import {Icon} from '@core/ui/Icon';
import {Stack} from '@core/ui/Layout';
import {Link} from '@core/ui/Link';
import {useOverlay} from '@core/ui/Overlay';
import {Text} from '@core/ui/Text';
import {Tooltip} from '@core/ui/Tooltip';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  FetchedSpotifyLiftReports,
} from '@analytics/graphql-api';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {BrandLiftPublishOverlay} from '../BrandLiftPublishOverlay';
import {canViewPublishSblReport} from '../helpers';
import {fmtDateRange, getLiftState} from './_helpers';

export const generateColumns = ({
  organization,
  user,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
}) =>
  [
    {
      accessor: 'name',
      title: 'Survey Name',
      type: 'string',
      Cell: ({d: {name}}) => (
        <Text variant='subtitle2' css={{minWidth: '12.5rem'}}>
          {name}
        </Text>
      ),
    },
    {
      accessor: 'state',
      title: 'Status',
      type: 'string',
      Cell: ({d}) => <SpotifyLiftStateBadge state={getLiftState(d)} />,
    },
    {
      accessor: 'campaigns',
      title: 'Campaigns Included',
      type: 'string',
      Cell: ({d: {campaigns}}) => (
        <Stack
          direction='column'
          spacing={1}
          css={`
            overflow: hidden;
            max-width: 12rem;
            min-width: 0;
          `}>
          {campaigns.length > 0 &&
            campaigns.map((c) => (
              <Tooltip
                key={c.id}
                description={c.name}
                css={`
                  text-overflow: ellipsis;
                  overflow: hidden;
                `}>
                <Link
                  key={c.id}
                  color='muted'
                  css={`
                    white-space: nowrap;
                  `}
                  onClick={(e) => e.stopPropagation()}
                  rel='noopener noreferrer'
                  target='_blank'
                  to={toCampaignUrl(organization, c)}>
                  {c.name}
                </Link>
              </Tooltip>
            ))}
        </Stack>
      ),
    },
    {
      accessor: 'startDate',
      title: 'Date Range',
      type: 'string',
      getValue: fmtDateRange,
    },
    ...(canViewPublishSblReport({user})
      ? [
          {
            accessor: 'published',
            title: 'Published',
            type: 'string',
            noSortable: true,
            Cell: ({d: {isPublished}}) => (
              <Icon
                icon={
                  isPublished
                    ? 'checkbox-marked-circle'
                    : 'close-circle-outline'
                }
                color={isPublished ? 'var(--success)' : 'var(--error)'}
              />
            ),
          },
          {
            accessor: '',
            title: '',
            type: 'string',
            noSortable: true,
            Cell: ({d}) => {
              const [opened, toggle] = useOverlay();
              if (!d.isPublished) {
                return (
                  <>
                    <DataTableDotsDropdownMenuCell
                      items={[
                        {
                          content: (
                            <DropdownMenuTitle>
                              Only visible to Spotify members:
                            </DropdownMenuTitle>
                          ),
                        },
                        {
                          title: 'Publish',
                          value: 'publish',
                          disabled: d.isPublished,
                        },
                      ]}
                      onSubmit={({value}) =>
                        value === 'publish' && toggle(true)
                      }
                    />
                    <BrandLiftPublishOverlay
                      liftReport={d}
                      opened={opened}
                      toggle={toggle}
                    />
                  </>
                );
              }
              return null;
            },
          },
        ]
      : []),
  ] as DataTableColumn<FetchedSpotifyLiftReports[number]>[];
