import { buttonStylesActive, buttonStylesBase, buttonStylesDisabled, buttonStylesHover, cssColorValue, focusBottomBorder, rootStyle, semanticColors } from "../../styles/index.js";
import { pxToInt } from "../../typeHelpers.js";
import styled, { css } from "../../styled-components.js";
export var Button = styled.button.withConfig({
  displayName: "Button",
  componentId: "sc-1yymw9-0"
})(["", ""], function (_ref) {
  var _ref$$semanticColor = _ref.$semanticColor,
    $semanticColor = _ref$$semanticColor === void 0 ? 'textSubdued' : _ref$$semanticColor,
    $isFocus = _ref.$isFocus,
    $isHover = _ref.$isHover,
    $isActive = _ref.$isActive,
    $useBrowserDefaultFocusStyle = _ref.$useBrowserDefaultFocusStyle,
    $isUsingKeyboard = _ref.$isUsingKeyboard;
  var buttonPadding = '8px'; /* TODO: can we use an encore token here? */

  var hoverStyles = css(["", " ", ""], buttonStylesHover, $semanticColor === 'textSubdued' && css(["color:", ";"], cssColorValue(semanticColors.textBase)));
  var activeStyles = css(["", " color:", ";"], buttonStylesActive, cssColorValue($semanticColor));
  var disabledStyles = css(["", " color:", ";&::after{border-color:transparent;}"], buttonStylesDisabled, cssColorValue($semanticColor));
  return css(["", ";", ";display:inline-flex;align-items:center;color:", ";padding:", ";", " &::after{left:", ";inline-size:calc(100% - ", "px);}&:hover{", "}", " &:active{", "}", " &[disabled],&[aria-disabled='true'],fieldset[disabled] &{", "}"], rootStyle(), buttonStylesBase('medium', $useBrowserDefaultFocusStyle), cssColorValue($semanticColor), buttonPadding, !$useBrowserDefaultFocusStyle && focusBottomBorder(pxToInt(buttonPadding), $isUsingKeyboard, $isFocus), buttonPadding, pxToInt(buttonPadding) * 2, hoverStyles, $isHover && hoverStyles, activeStyles, $isActive && activeStyles, disabledStyles);
});