import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignRouteProps} from '../_routes';
import {CampaignDynamics} from './CampaignDynamics';
import {CampaignDynamicIndex} from './dynamic';

export function CampaignDynamicsIndex({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/dynamics'
        campaign={campaign}
        user={user}
        organization={organization}
        component={CampaignDynamics}
      />
      <PropsRoute
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/dynamics/:campaignDynamicId'
        campaign={campaign}
        user={user}
        organization={organization}
        component={CampaignDynamicIndex}
      />
    </Switch>
  );
}
