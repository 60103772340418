import {useQuery} from '@apollo/client';
import {Redirect, Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import Loading from '@core/ui/Loading';
import ManageNav from '@analytics/components/nav/ManageNav';
import useSettings from '@analytics/components/settings/useSettings';
import {FetchedOrganization} from '@analytics/graphql-api';
import PODLINK_DOMAINS_QUERY from '@analytics/graphql-api/_old_queries/podlink/PodlinkDomainsQuery';
import {podlinkRoutes} from '@analytics/lib/routes/podlinkRoutes';
import {Podlink} from './Podlink';
import {PodlinkDomainCreate} from './PodlinkDomainCreate';
import {PodlinkPodcastDesigner} from './PodlinkPodcastDesigner';
import {PodlinkDomainIndex} from './domain';

type Props = {
  organization: FetchedOrganization;
  user: FetchedUser;
};

export type ManagePodlinkRouteProps = Props & {
  podcasts: TSFixMe[];
  podlinkDomains: TSFixMe[];
};

export function ManagePodlinkIndex({user, organization}: Props) {
  const [settings] = useSettings();
  const {loading, error, data} = useQuery(PODLINK_DOMAINS_QUERY, {
    variables: {
      organizationId: organization.id,
    },
  });

  if (loading) {
    return <Loading fixed />;
  }

  if (error) {
    return <Redirect to={createOrgPath(organization, 'manage')} />;
  }

  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, 'manage/podlink'),
    basePath: '/:part(b|p)/:slug/manage/podlink',
    routes: podlinkRoutes(),
  });

  const podlinkDomains = data?.me?.organization?.podlinkDomains ?? [];
  const podcasts = data?.me?.organization?.podcasts ?? [];

  return (
    <>
      {!settings.hideUi && (
        <ManageNav user={user} organization={organization} />
      )}
      <Switch>
        {renderRoutes({
          components: {
            podlink: Podlink,
            create: PodlinkDomainCreate,
            'podcast-designer': PodlinkPodcastDesigner,
            domain: PodlinkDomainIndex,
          },
          extraProps: {
            user,
            organization,
            podcasts,
            podlinkDomains,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
}
