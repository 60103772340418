import {graphql} from '@apollo/client/react/hoc';
import MapDownloads from '@core/components/MapDownloads';
import {findCountry} from '@core/lib/countryRegionData';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import {AttributionRegionsTable} from '@analytics/components/attribution/AttributionLocationTable';
import CampaignDownloadCountryLocationsQuery from '@analytics/graphql-api/_old_queries/CampaignDownloadCountryLocationsQuery';

const CampaignLocations = ({
  data: {loading, me},
  match: {
    url,
    params: {country},
  },
}) => {
  if (loading) {
    return <Loading centered />;
  }
  const countryMeta = findCountry(country);

  const {count, nodes, regions} = me.organization.campaign.downloads.locations;

  return (
    <>
      <CardHeader
        css={`
          margin: 0 var(--spacing-6) var(--spacing-6);
        `}>
        {countryMeta ? (
          <Element rules={() => ({alignItems: 'center', display: 'flex'})}>
            <Element
              tag='img'
              src={countryMeta.flag}
              rules={() => ({
                height: 'auto',
                marginRight: '0.625rem',
                width: '1.5rem',
              })}
            />
            {countryMeta.countryName} Impressions
          </Element>
        ) : (
          <> Impressions</>
        )}
      </CardHeader>
      <Card rules={() => ({padding: 0, overflow: 'hidden', border: 0})}>
        <MapDownloads
          height={500}
          locations={nodes.map(({latitude, longitude, total}) => ({
            count: total,
            latitude,
            longitude,
          }))}
        />
      </Card>
      <Card rules={() => ({marginTop: '1.5rem'})}>
        <AttributionRegionsTable
          country={country}
          regions={regions}
          count={count}
          onClickRowPath={({region}) => `${url.replace(/\/$/, '')}/${region}`}
        />
      </Card>
    </>
  );
};

export default graphql(CampaignDownloadCountryLocationsQuery, {
  options: ({
    organization,
    campaign: {id},
    match: {
      params: {country},
    },
  }) => {
    return {
      variables: {
        organizationId: organization.id,
        id,
        country,
      },
    };
  },
})(CampaignLocations);
