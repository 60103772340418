import {useState} from 'react';
import {ProgressCircle, Text, cssSpacing} from '@spotify-internal/encore-web';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import {t} from '@core/texts';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {DateRangeInput} from '@core/ui/DatePicker';
import EmptyMessage from '@core/ui/EmptyMessage';
import {FeedImage} from '@core/ui/Image';
import {Stack} from '@core/ui/Layout';
import {
  FetchedFutureEpisodes,
  useFutureEpisodesQuery,
} from '@analytics/graphql-api';
import {CampaignsRouteProps} from './_routes';

const TODAY = dayjs();

export function CampaignsIntegrity({organization}: CampaignsRouteProps) {
  const [beginRange, setBeginRange] = useState(TODAY.subtract(1, 'M'));

  const {loading, error, data} = useFutureEpisodesQuery({
    variables: {
      organizationId: organization.id,
      after: beginRange.utc().format('YYYY-MM-DD'),
      before: TODAY.utc().format('YYYY-MM-DD'),
    },
  });

  if (loading) {
    return <ProgressCircle aria-label='Loading missing campaign episodes' />;
  }

  if (error) {
    return <EmptyMessage>{t('errors.network')}</EmptyMessage>;
  }

  const futureEpisodes = parseFutureEpisodes({
    organization,
    futureEpisodes: data?.me?.organization?.futureEpisodes,
  });

  return (
    <>
      <Card>
        <CardHeader>Missed Campaign Episodes</CardHeader>
        <Text
          as='p'
          paddingBottom={cssSpacing('base', '16px')}
          variant='bodySmall'>
          These are campaign episodes where the expected published date has
          passed but they are not tied to an episode drop, as sometimes episodes
          are published late, early, or multiple in a day. Select an episode to
          view its detail, where you can manually select which drop it belongs
          to or remove from the campaign if no longer needed.
        </Text>
        <DataTable
          columns={
            [
              {
                title: 'Podcast',
                accessor: 'title',
                type: 'string',
                Cell: ({d}) => (
                  <Stack
                    alignItems='center'
                    gap={cssSpacing('tighter-2', '8px')}>
                    <FeedImage feed={d?.feed} width={35} />
                    <Stack direction='column'>
                      <Text variant='bodySmallBold'>{d?.title}</Text>
                      <Text variant='bodySmall'>{d?.feed?.description}</Text>
                    </Stack>
                  </Stack>
                ),
              },
              {
                title: 'Advertiser',
                accessor: 'advertiser',
                type: 'string',
              },
              {
                title: 'Expected Published',
                accessor: 'expectedPublished',
                type: 'date',
                fmt: (v) => dayjs(v).utc().format('MMM DD, YYYY'),
              },
            ] as DataTableColumn<FutureEpisode>[]
          }
          data={futureEpisodes}
          emptyMessageText='No missed episodes found.'
          headerRenderer={({searchContent}) => (
            <Stack alignItems='center'>
              {searchContent}
              <DateRangeInput
                startDate={beginRange}
                endDate={TODAY}
                isOutsideRange={(date: dayjs.Dayjs) =>
                  date.isSameOrAfter(TODAY)
                }
                onDatesChange={({startDate}: {startDate: dayjs.Dayjs}) =>
                  setBeginRange(startDate)
                }
              />
            </Stack>
          )}
          onClickRowPath={({url}) => url}
          orderBy='expectedPublished'
          searchKeys={['title']}
          searchPlaceholder='Search podcast'
        />
      </Card>
    </>
  );
}

type FutureEpisode = FetchedFutureEpisodes[number] & {
  advertiser: string;
  feed: NonNullable<FetchedFutureEpisodes[number]['podcast']>['feed'];
  title: string;
};

const parseFutureEpisodes = ({
  organization,
  futureEpisodes = [],
}: Pick<CampaignsRouteProps, 'organization'> & {
  futureEpisodes?: FetchedFutureEpisodes;
}) => {
  return futureEpisodes.flatMap((fe) => {
    if (fe?.campaignEpisodes && fe.campaignEpisodes.length > 0) {
      return fe.campaignEpisodes.map((episode) => ({
        ...fe,
        feed: fe?.podcast?.feed,
        title: fe.podcast?.feed?.title,
        advertiser: episode?.campaignPodcast?.campaign?.advertiser?.name,
        url: createOrgPath(
          organization,
          `/campaigns/${episode?.campaignPodcast?.campaign.slug}/podcasts/${episode?.campaignPodcast.id}/futures/${episode?.id}`
        ),
      }));
    }

    return [];
  });
};
