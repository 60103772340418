import {createOrgPath} from '@core/lib/organizations';
import {Nav} from '@core/ui/Nav';
import {
  CampaignRouteProps,
  toCampaignUrl,
} from '@analytics/screens/unified/campaigns/campaign/_routes';

export function CampaignApprovalsNav({
  organization,
  campaign,
}: Omit<CampaignRouteProps, 'user'>) {
  return (
    <Nav
      title={campaign.name}
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/measure/campaigns'),
          title: 'Campaigns',
        },
        {
          to: toCampaignUrl(organization, campaign),
          title: `${campaign.name}`,
        },
        {
          title: 'Approvals',
        },
      ]}
    />
  );
}
