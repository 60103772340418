import {gql} from '@apollo/client';
import CampaignTargetFragment from '@analytics/graphql-api/_old_queries/fragments/CampaignTargetFragment';

export default gql`
  mutation createCampaignTarget($input: CreateCampaignTargetInput!) {
    authed {
      success
    }
    createCampaignTarget(input: $input) {
      campaignTarget {
        ...CampaignTarget
      }
    }
  }
  ${CampaignTargetFragment}
`;
