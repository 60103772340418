import {gql} from '@apollo/client';
import AssetFragment from '../fragments/AssetFragment';
import PodlinkDomainFragment from '../fragments/PodlinkDomainFragment';

export default gql`
  query getPodlinkDomain($organizationId: ID!, $slug: String!) {
    me {
      id
      organization(id: $organizationId) {
        id
        podlinkDomain(slug: $slug) {
          ...PodlinkDomain

          backgroundColor
          fontColor
          primaryColor
          secondaryColor
          image {
            ...Asset
          }
          favicon {
            ...Asset
          }

          podcasts {
            id
            feed {
              id
              title
              description(words: 25)
              href
              state
              image {
                id
                href
                path
              }
            }
          }
        }
      }
    }
  }
  ${PodlinkDomainFragment}
  ${AssetFragment}
`;
