import {gql} from '@apollo/client';

export default gql`
  mutation removeCampaignEpisode($input: RemoveCampaignEpisodeInput!) {
    authed {
      success
    }
    removeCampaignEpisode(input: $input) {
      success
    }
  }
`;
