import {Controller} from 'react-hook-form';
import {Input, Label} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {FieldProps} from '../helpers';

export function NameField({control}: FieldProps) {
  return (
    <Stack direction='column'>
      <Label htmlFor='reportName'>Report Name</Label>
      <Controller
        name='name'
        control={control}
        rules={{required: 'Please provide a report name'}}
        render={({field, fieldState: {error}}) => (
          <Input
            id='reportName'
            defaultValue={field.value}
            invalid={!!error}
            onChange={field.onChange}
            placeholder='Report Name'
          />
        )}
      />
    </Stack>
  );
}
