import {graphql} from '@apollo/client/react/hoc';
import Episode from '@core/components/Episode';
import {createOrgPath} from '@core/lib/organizations';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';
import Feed from '@analytics/components/research/Feed';
import CompanySimilarCompanyQuery from '@analytics/graphql-api/_old_queries/research/CompanySimilarCompanyQuery';

const SimilarCompany = ({
  organization,
  company,
  data: {loading, error, me},
}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const {name, id, feeds, episodes} = me.organization.research.company;

  return (
    <Element>
      <CardHeader>
        {company.name} / {name}
      </CardHeader>
      <Card>
        <p>
          Where have{' '}
          <Link
            to={createOrgPath(organization, `/research/brands/${company.id}`)}>
            {company.name}
          </Link>{' '}
          and{' '}
          <Link to={createOrgPath(organization, `/research/brands/${id}`)}>
            {name}
          </Link>{' '}
          both advertised?
        </p>
      </Card>

      <CardHeader>Podcasts</CardHeader>
      <Card>
        {feeds.map((feed) => {
          return <Feed organization={organization} feed={feed} key={feed.id} />;
        })}
      </Card>

      <CardHeader>Episodes</CardHeader>
      <Card>
        {episodes.map((episode) => {
          return (
            <Episode
              key={episode.id}
              episode={episode}
              organization={organization}
              path={`/research/podcasts/${episode.feed.id}/episodes/${episode.id}`}
            />
          );
        })}
      </Card>
    </Element>
  );
};

export default graphql(CompanySimilarCompanyQuery, {
  options: ({
    organization,
    company: {id},
    match: {
      params: {secondaryCompanyId},
    },
  }) => ({
    variables: {
      organizationId: organization.id,
      id,
      companyId: secondaryCompanyId,
      limit: 50,
    },
  }),
})(SimilarCompany);
