import {gql} from '@apollo/client';

export default gql`
  query getCompanySimilarByEpisode(
    $organizationId: ID!
    $id: ID!
    $limit: Int
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          company(id: $id) {
            id
            similarByEpisode(limit: $limit) {
              id
              name
              description
              url
              image {
                id
                width
                height
                src
                path
              }
              episodesCount
            }
          }
        }
      }
    }
  }
`;
