import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import {t} from '@core/texts';
import DataTable, {
  DataTableColumn,
  DataTableDotsDropdownMenuCell,
} from '@core/ui/DataTable';
import {useOverlay} from '@core/ui/Overlay';
import {Snackbar} from '@core/ui/Snackbar';
import {campaignLiftReportURL} from '@analytics/components/nav/CampaignLiftReportNav';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedCampaignLiftReports,
  FetchedIncrementalLiftReports,
  FetchedOrganization,
  LiftReportState,
  useExportTokenUriMutation,
} from '@analytics/graphql-api';
import {generateLiftTableData} from './_helpers';
import {generateColumns} from './columns';

export function LiftReportTable({
  campaign,
  liftReports,
  organization,
  user,
  ...props
}: {
  liftReports: FetchedIncrementalLiftReports | FetchedCampaignLiftReports;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
  campaign?: FetchedCampaign;
}) {
  const [openedError, toggleError] = useOverlay();
  const [createExportTokenUri] = useExportTokenUriMutation();
  const data = generateLiftTableData({liftReports, campaign});
  const columns = [
    ...generateColumns({liftReports}),
    ...(user?.isAdmin
      ? [
          {
            rule: () => ({padding: 0}),
            Cell: ({d, ...props}) => (
              <DataTableDotsDropdownMenuCell
                items={[
                  {
                    title: `Export to CSV`,
                    value: 'export',
                    disabled: d.state !== LiftReportState.Live,
                  },
                ]}
                onSubmit={async ({value}) => {
                  if (value === 'export') {
                    try {
                      const exportUri = await createExportTokenUri({
                        kind: 'report',
                        objectType: 'lift',
                        organizationId: organization.id,
                        pk: d.id,
                        params: {
                          after: 0,
                          before: d.delta ?? 4,
                        },
                      });

                      window.open(exportUri, '_blank');
                    } catch (e) {
                      toggleError(true);
                    }
                  }
                }}
                {...props}
              />
            ),
          } as DataTableColumn<
            | FetchedIncrementalLiftReports[number]
            | FetchedCampaignLiftReports[number]
          >,
        ]
      : []),
  ];

  return (
    <>
      <DataTable
        data={data}
        columns={columns}
        orderBy='-createdAt'
        emptyMessageText='No lift reports found'
        searchKeys={['name']}
        onClickRowPath={({id}) =>
          campaign
            ? campaignLiftReportURL(organization, campaign, {id})
            : createOrgPath(
                organization,
                `measure/lift/incremental/reports/${id}`
              )
        }
        bulkActions={
          user?.isAdmin && !!data.find((d) => d.state === LiftReportState.Live)
            ? [
                {
                  title: 'Export to CSV',
                  onClick: async (rows) => {
                    try {
                      const exportUri = await createExportTokenUri({
                        objectType: 'lift',
                        kind: rows.length === 1 ? 'report' : 'multi_report',
                        organizationId: organization.id,
                        pk: rows.map(({id}) => id).join(','),
                      });

                      window.open(exportUri, '_blank');
                    } catch (e) {
                      toggleError(true);
                    }
                  },
                },
              ]
            : []
        }
        {...props}
      />
      <Snackbar
        autoHideDuration={4000}
        opened={openedError}
        severity='error'
        toggle={toggleError}>
        {t('errors.network')}
      </Snackbar>
    </>
  );
}
