import {Redirect} from 'react-router-dom';
import {
  canViewDAIAudienceInsights,
  canViewSAIAudienceInsights,
} from '@analytics/components/audience-insights/utils';
import {CampaignRouteProps, toCampaignUrl} from '../_routes';
import {InsightsDAI} from './InsightsDAI';
import {InsightsSAI} from './InsightsSAI';

export function CampaignInsights({organization, campaign}: CampaignRouteProps) {
  if (canViewSAIAudienceInsights({campaign})) {
    return <InsightsSAI campaign={campaign} organization={organization} />;
  }

  if (
    // only viewable for Spotify employees && feature flag (see canViewDAIAudienceInsights)
    canViewDAIAudienceInsights({campaign}) &&
    organization.slug === 'spotify'
  ) {
    return <InsightsDAI campaign={campaign} organization={organization} />;
  }

  return <Redirect to={toCampaignUrl(organization, campaign)} />;
}
