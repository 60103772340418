import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
  FetchedSpotifyLiftReport,
} from '@analytics/graphql-api';
import {MeasureRouteProps} from '../../..';

export type MeasureSpotifyLiftRouteProps = MeasureRouteProps & {
  liftReport: FetchedSpotifyLiftReport;
};

export const getSpotifyLiftReportRoutes = ({
  organization,
  liftReportId,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  liftReportId: string;
}) => {
  return useRoutes({
    baseUrl: createOrgPath(
      organization,
      `measure/lift/spotify/reports/${liftReportId}`
    ),
    basePath:
      '/:part(b|a|s|p)/:slug/measure/lift/spotify/reports/:liftReportId',
    routes: [{key: 'liftReport', title: 'Overview', exact: true, path: ''}],
  });
};
