import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {getSortDirection, getSortKey} from '@core/lib/sortBy';
import {Icon} from '@core/ui/Icon';
import {Stack} from '@core/ui/Layout';
import Select from '@core/ui/Select';
import {ExportTypeColumn, FieldsInputObject} from '@analytics/graphql-api';

const DIRECTION_OPTIONS = [
  {title: 'ASC', value: 'asc'},
  {title: 'DESC', value: 'desc'},
];

type SortItem = {
  direction: string;
  propName: string;
};

type Props = {
  defaultValue?: string[];
  columns: (ExportTypeColumn | FieldsInputObject)[];
  onChange?: (_: string[]) => void;
};

export function SortInput({defaultValue = [], columns = [], onChange}: Props) {
  const [sortList, setSortList] = useState(
    defaultValue.length > 0
      ? defaultValue.map((propName) => ({
          direction: getSortDirection(propName) === -1 ? 'desc' : 'asc',
          propName: getSortKey(propName),
        }))
      : []
  );

  const onAddField = () => {
    sortList.push({direction: 'desc', propName: ''});
    setSortList(sortList.slice(0));
  };

  const onRemoveField = (sortItem: SortItem) => {
    sortList.splice(sortList.indexOf(sortItem), 1);
    setSortList(sortList.slice(0));
  };

  const onColumnSelect = (sortItem: SortItem, column: ExportTypeColumn) => {
    sortItem.propName = column.id;
    setSortList(sortList.slice(0));
  };

  const onDirectionSelect = (sortItem: SortItem, direction: 'asc' | 'desc') => {
    sortItem.direction = direction;
    setSortList(sortList.slice(0));
  };

  useEffect(() => {
    onChange?.(
      sortList
        .filter(({propName}) => !!propName)
        .map(
          ({direction, propName}) =>
            `${direction === 'desc' ? '-' : ''}${propName}`
        )
    );
  }, [sortList, onChange]);

  return (
    <Stack direction='column' css={{fontSize: '0.875rem'}}>
      {sortList.map((sortItem, idx) => (
        <_SortItem key={idx}>
          <_SortButton onClick={() => onRemoveField(sortItem)}>
            <Icon icon='remove-circle-outline' />
            {idx === 0 ? 'Sort by' : 'and then'}
          </_SortButton>
          <Select
            key={String(sortList)}
            items={columns.filter(
              ({id}) =>
                sortItem.propName === id ||
                !sortList.find(({propName}) => getSortKey(propName) === id)
            )}
            placeholder='Select column'
            propertyForName='name'
            propertyForValue='id'
            defaultValue={sortItem.propName}
            outline
            onSelect={(column) => onColumnSelect(sortItem, column)}
          />
          <Select
            items={DIRECTION_OPTIONS}
            defaultValue={sortItem.direction}
            onSelect={({value}) => onDirectionSelect(sortItem, value)}
          />
        </_SortItem>
      ))}
      <_SortButton disabled={!columns.length} onClick={onAddField}>
        <Icon icon='add-circle-outline' />
        Add field
      </_SortButton>
    </Stack>
  );
}

const _SortItem = styled.div`
  align-items: center;
  display: grid;
  gap: 0.625rem;
  grid-template-columns: 5.75rem minmax(11.25rem, 1fr) 5rem;
  margin-bottom: 0.625rem;
`;

const _SortButton = styled.button`
  align-items: center;
  color: var(--icon-subtle);
  display: flex;
  gap: 0.625rem;
  padding: 0.25rem 0 0 0;
  width: max-content;
  :hover {
    color: var(--icon-default);
  }
  :hover > svg {
    color: var(--icon-default);
  }
`;

_SortButton.defaultProps = {
  type: 'button',
};
