import {gql} from '@apollo/client';

export interface IOrganizationResearchReviewers {
  me: {
    id: string;
    organization: {
      id: string;
      research: {
        reviewers: Array<{
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          type: string;
          createdAd: string;
          reviewsCount: number;
        }>;
      };
    };
  };
}

export interface IOrganizationResearchReviewersInput {
  organizationId: string;
}

export default gql`
  query OrganizationResearchReviewers($organizationId: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          reviewers {
            id
            firstName
            lastName
            email
            type
            createdAt
            reviewsCount
          }
        }
      }
    }
  }
`;
