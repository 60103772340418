import {createOrgPath} from '@core/lib/organizations';
import {DataTableServer, DataTableServerProps} from '@core/ui/DataTable';
import {
  CampaignsForPublisherReviewDocument,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {usePublisherReviewColumns} from './usePublisherReviewColumns';

export function PublisherReviewTable({
  emptyMessageText = 'No campaigns found',
  organization,
  ...props
}: Omit<
  DataTableServerProps,
  'columns' | 'data' | 'searchKeys' | 'toData' | 'toVariables' | 'query'
> & {
  organization: FetchedOrganization;
}) {
  const columns = usePublisherReviewColumns({organization});

  return (
    <DataTableServer
      columns={columns}
      emptyMessageText={emptyMessageText}
      fetchPolicy='cache-and-network'
      onClickRowPath={(campaign) =>
        createOrgPath(organization, `/campaigns/${campaign.slug}`)
      }
      orderBy='startAt'
      searchPlaceholder='Search campaign'
      searchKeys={['name']}
      toData={(data) => ({
        data: data?.me?.organization?.campaigns?.results ?? [],
        count: data?.me?.organization?.campaigns?.resultsCount ?? 0,
      })}
      toVariables={({rowsPerPage, rowStart, sortBy, query}) => ({
        organizationId: organization.id,
        limit: rowsPerPage,
        offset: rowStart,
        name: query,
        sort: sortBy ? [sortBy] : undefined,
      })}
      query={CampaignsForPublisherReviewDocument}
      {...props}
    />
  );
}
