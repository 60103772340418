import styled from 'styled-components';
import {toFixed} from '@core/lib/filters';
import {Stack} from '@core/ui/Layout';
import ProgressBar from '@core/ui/ProgressBar';
import {Text} from '@core/ui/Text';
import {Tooltip} from '@core/ui/Tooltip';
import {FetchedSpotifyLiftReport} from '@analytics/graphql-api';
import {
  CONTROL,
  EXPOSED,
  fmtPercentDiff,
  getSignificanceDisplay,
} from './helpers';
import {CardGrid, Grid, SignificanceIcon, Stat} from './shared';

export function BrandLiftStatsDetail({
  liftReport: {messages},
  ...props
}: {
  liftReport: FetchedSpotifyLiftReport;
}) {
  return (
    <CardGrid columns='repeat(3, 1fr)' {...props}>
      {messages.map(({id, messageTypeDisplay, result}) => {
        const {description} = getSignificanceDisplay(
          result?.overall.significance
        );

        return (
          <Stat
            key={id}
            title={messageTypeDisplay}
            value={
              <Tooltip
                key={result?.overall.significance}
                description={description}>
                <Stack alignItems='center' gap='var(--spacing-1)'>
                  <SignificanceIcon
                    significance={result?.overall.significance}
                  />
                  {fmtPercentDiff(result?.overall.percentChange ?? 0)}
                </Stack>
              </Tooltip>
            }>
            <Grid columns='1fr 2fr'>
              <Text css={{whiteSpace: 'nowrap'}} variant='body2'>
                Control ({toFixed(result?.overall.controlPercentage ?? 0, 1)}%)
              </Text>
              <_ProgressBar
                progressColor={CONTROL}
                max={result?.overall.controlTotal ?? 0}
                value={result?.overall.controlPositive ?? 0}
              />
              <Text css={{whiteSpace: 'nowrap'}} variant='body2'>
                Exposed ({toFixed(result?.overall.exposedPercentage ?? 0, 1)}%)
              </Text>
              <_ProgressBar
                progressColor={EXPOSED}
                max={result?.overall.exposedTotal ?? 0}
                value={result?.overall.exposedPositive ?? 0}
              />
            </Grid>
          </Stat>
        );
      })}
    </CardGrid>
  );
}

const _ProgressBar = styled(ProgressBar)`
  border-radius: 0.25rem;
  height: 0.475rem;
`;
