import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import AudioPlayButton from '@core/components/AudioPlayButton';
import {toAudioPath} from '@core/lib/audio';
import {toTimeString} from '@core/lib/time';
import {TRules} from '@core/style';
import {Element} from '@core/style';
import {GREEN, darken, lighten} from '@core/style';
import {Overlay, useOverlay} from '@core/ui/Overlay';

// randomize & keep uniform length
const getRandomWaveform = (width: number) =>
  Array.from({length: width}, () => ({
    value: Math.floor(Math.random() * 101),
  }));

const getDetails = (
  percent: number,
  placements: {
    ad: {
      company?: {
        name: string;
      };
    };
    startTime: number;
    endTime: number;
    fill?: string;
  }[],
  duration: number
) => {
  const details = {
    brand: null,
    fill: null,
  };

  placements.forEach(({startTime, endTime, fill, ad}) => {
    if (percent >= startTime / duration && percent <= endTime / duration) {
      details.fill = fill;
      details.brand = ad.company?.name;
    }
  });

  return details;
};

const AdOverlay = ({
  brand,
  fill,
  start,
  end,
}: {
  brand: string;
  fill: string;
  start: number;
  end: number;
}): JSX.Element => {
  const ref = useRef(null);
  const [horizontalAlign, setHorizontalAlign] = useState<'left' | 'right'>(
    'right'
  );
  const [opened, toggle] = useOverlay();

  const onMouseEnter = () => {
    if (ref.current) {
      ref.current.focus();
      toggle(true);
    }
  };

  useLayoutEffect(() => {
    const position = ref.current.getBoundingClientRect();
    if (
      (window.innerWidth || document.documentElement.clientWidth) -
        position.x <=
      200
    ) {
      setHorizontalAlign('left');
    }
  }, [opened]);

  return (
    <>
      <Element
        domRef={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => toggle(false)}
        rules={() => ({
          color: fill,
          height: '100%',
          left: `${start * 100}%`,
          position: 'absolute',
          width: `${(end - start) * 100}%`,
        })}
      />
      {opened && (
        <Overlay
          horizontalAlign={horizontalAlign}
          noHorizontalOverlap
          positionTarget={ref.current}
          opened={opened}
          toggle={toggle}
          verticalAlign='middle'
          withShadow
          css={`
            color: fill;
            font-size: 0.875rem;
            height: fit-content;
            width: fit-contet;
            min-width: fit-content;
            padding: 0.5rem;
          `}>
          {brand || 'Ad pending review'}
        </Overlay>
      )}
    </>
  );
};

const PlayerButton = ({
  disabled,
  playing,
  togglePlay,
}: {
  disabled: boolean;
  playing: boolean;
  togglePlay?: (to?: boolean) => void;
}): JSX.Element => {
  return (
    <Element
      rules={({theme}) => ({
        alignItems: 'center',
        background: theme.name === 'light' ? theme.gray9 : theme.gray1,
        borderRadius: '50%',
        display: 'flex',
        height: '1.5rem',
        justifyContent: 'center',
        width: '1.5rem',
        ...(!disabled && {
          ':hover': {
            background:
              theme.name === 'light'
                ? lighten(theme.gray9, 0.1)
                : darken(theme.gray1, 0.1),
          },
        }),
      })}>
      <AudioPlayButton
        playing={playing}
        onClick={togglePlay}
        rules={({theme}) => ({
          color: theme.name === 'light' ? theme.gray0 : theme.gray9,
          cursor: !disabled && 'pointer',
          width: '1rem',
        })}
      />
    </Element>
  );
};

const AudioBarWaveformPlayer = ({
  barColor = GREEN,
  barGap = 2,
  barWidth = 4,
  enclosure,
  placements = [],
  withTimeStamp = false,
  rules,
}: {
  barWidth?: number;
  barGap?: number;
  barColor?: string;
  enclosure: {
    mpegPath: string;
    duration: number;
  };
  placements?: {
    ad: {
      company?: {
        name: string;
      };
    };
    startTime: number;
    endTime: number;
    fill?: string;
  }[];
  playerHeight?: number;
  withTimeStamp?: boolean;
  rules?: TRules;
}): JSX.Element => {
  const audioRef = useRef<HTMLAudioElement>();
  const playerRef = useRef<HTMLDivElement>();
  const trackRef = useRef<HTMLDivElement>();
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number | null>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [waveform, setWaveform] = useState<
    {value: number; brand?: string; fill?: string}[]
  >([]);

  const disabled = !enclosure || !enclosure.mpegPath;

  if (disabled) {
    return (
      <Element rules={[() => ({alignItems: 'center', display: 'flex'}), rules]}>
        <PlayerButton disabled={disabled} playing={playing} />
        <Element
          rules={({theme}) => ({
            color: theme.textDisabled,
            fontSize: '0.875rem',
            marginLeft: '0.5rem',
          })}>
          Audio unavailable
        </Element>
      </Element>
    );
  }

  const onReady = ({target}) => {
    audioRef.current = target;
    setDuration(target.duration);
    audioRef.current.addEventListener('ended', () => {
      setPlaying(false);
    });
  };

  const onTrackClick = ({pageX}) => {
    const rect = trackRef.current.getBoundingClientRect();
    const myCurrentTime = ((pageX - rect.x) / rect.width) * enclosure.duration;

    if (!playing) {
      setPlaying(true);
      audioRef.current.play();
    }
    setTimeout(() => {
      audioRef.current.currentTime = myCurrentTime;
    }, 10);
  };

  const onTimeUpdate = ({target}) => {
    setCurrentTime(target.currentTime);
  };

  const togglePlay = () => {
    if (playing) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setPlaying((prev) => !prev);
  };

  const updateContainerWidth = () => {
    if (playerRef.current) {
      // 32px size of play button + gap
      const width = Math.floor(
        (playerRef.current.offsetWidth - 32) / (barWidth + barGap)
      );
      if (width !== containerWidth) {
        setContainerWidth(width);
      }
    }
  };

  useEffect(() => {
    updateContainerWidth();
  }, [playerRef?.current?.offsetWidth]);

  useEffect(() => {
    if (containerWidth) {
      let newWaveform;

      if (!waveform.length) {
        newWaveform = getRandomWaveform(containerWidth);
      } else {
        // if waveform exists, only calculate difference
        const diff = containerWidth - waveform.length;
        newWaveform = waveform.slice(0);
        if (diff < 0) {
          newWaveform.splice(diff, -diff);
        } else {
          newWaveform = newWaveform.concat(getRandomWaveform(diff));
        }
      }

      // if placements, add fill & brand
      if (placements.length) {
        newWaveform = newWaveform.map(({value}, i) => ({
          value,
          ...getDetails(i / containerWidth, placements, enclosure.duration),
        }));
      }

      setWaveform(newWaveform);
    }
  }, [containerWidth]);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateContainerWidth);
    updateContainerWidth();

    return () => window.removeEventListener('resize', updateContainerWidth);
  }, []);

  return (
    <Element
      domRef={playerRef}
      rules={[
        () => ({
          height: '100%',
          ...(withTimeStamp && {
            display: 'grid',
            gap: '0.125rem',
            gridTemplateRows: '1fr 1rem',
          }),
        }),
        rules,
      ]}>
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: '1.5rem 1fr',
          gap: '0.5rem',
          height: '100%',
        })}>
        <PlayerButton
          disabled={disabled}
          playing={playing}
          togglePlay={togglePlay}
        />
        <audio
          onCanPlayThrough={onReady}
          onTimeUpdate={onTimeUpdate}
          ref={audioRef}>
          <source src={toAudioPath(enclosure.mpegPath)} type='audio/flac' />
        </audio>
        <Element
          domRef={trackRef}
          onClick={onTrackClick}
          rules={() => ({
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            height: '100%',
            minHeight: '2rem',
            position: 'relative',
          })}>
          {waveform.map((w, i) => (
            <Element
              key={`${i}-${w.value}`}
              rules={({theme}) => ({
                borderRadius: '0.2rem',
                flex: '1 0 0%',
                height: `${w.value}%`,
                minHeight: '8px',
                minWidth: '2px',
                maxWidth: `${barWidth}px`,
                marginRight: `${barGap}px`,
                transition: 'background-color 0.5s ease',
                ...((i / waveform.length) * 100 <=
                (currentTime / duration) * 100
                  ? {
                      background: w.fill ? darken(w.fill, 0.4) : barColor,
                    }
                  : {
                      background: w.fill
                        ? w.fill
                        : theme.name === 'light'
                        ? '#e5e5e5'
                        : '#21262d',
                    }),
              })}
            />
          ))}
          {placements.length > 0
            ? placements.map(({ad, fill, startTime, endTime}) => (
                <AdOverlay
                  key={`${startTime + endTime}-${fill}`}
                  brand={ad.company?.name}
                  fill={fill}
                  start={startTime / enclosure.duration}
                  end={endTime / enclosure.duration}
                />
              ))
            : null}
        </Element>
      </Element>
      {withTimeStamp && (
        <Element
          rules={() => ({
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '2rem',
          })}>
          <Element
            rules={({theme}) => ({
              color: theme.textDisabled,
              fontSize: '0.725rem',
            })}>
            {toTimeString(currentTime)}
          </Element>
          <Element
            rules={({theme}) => ({
              color: theme.textDisabled,
              fontSize: '0.725rem',
            })}>
            -{toTimeString(duration - currentTime)}
          </Element>
        </Element>
      )}
    </Element>
  );
};

export default AudioBarWaveformPlayer;
