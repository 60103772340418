import {forwardRef, useImperativeHandle, useRef} from 'react';
import {DataTableColumn} from '@core/ui/DataTable';
import {MultipleSelect} from '@core/ui/Select';
import {FetchedIncrementalLiftReports} from '@analytics/graphql-api';

type Campaign = {
  id: string;
  name: string;
  slug: string;
};

const getUniqueCampaigns = (data: FetchedIncrementalLiftReports) => {
  const unique = data.reduce((acc, curr) => {
    curr.campaigns.forEach((c) => {
      if (!acc[c.id]) {
        acc[c.id] = c;
      }
    });

    return acc;
  }, {} as {[key: string]: Campaign});

  return Object.values(unique);
};

interface LiftReportTableCampaignsFilterProps {
  column: DataTableColumn;
  data: FetchedIncrementalLiftReports;
  defaultValue?: string[];
  onChange: (_props: {column: DataTableColumn; defaultValue: string[]}) => void;
}

export const CampaignsFilter = forwardRef(
  (
    {
      data,
      defaultValue,
      column,
      onChange,
      ...props
    }: LiftReportTableCampaignsFilterProps,
    ref
  ) => {
    const campaigns = getUniqueCampaigns(data);
    const selectedCampaignsRef = useRef(
      defaultValue
        ? campaigns.filter((c) => defaultValue.indexOf(c.id) > -1)
        : []
    );

    const handleOnChange = (campaigns: Campaign[]) => {
      selectedCampaignsRef.current = campaigns;
      onChange({column, defaultValue: campaigns.map((c) => c.id)});
    };

    useImperativeHandle(ref, () => ({
      filter: (filteredData: FetchedIncrementalLiftReports) => {
        if (
          selectedCampaignsRef.current &&
          selectedCampaignsRef.current.length
        ) {
          const ids = selectedCampaignsRef.current.map(({id}) => id);

          return filteredData.filter((d) =>
            d.campaigns.find(({id}) => ids.includes(id))
          );
        }
        return filteredData;
      },
      clear: () => {
        handleOnChange([]);
      },
    }));

    return (
      <MultipleSelect
        defaultValue={defaultValue}
        items={campaigns}
        onSelect={handleOnChange}
        outline
        placeholder='Campaigns'
        propertyForName='name'
        propertyForValue='id'
        searchable
        searchKeys={['name']}
        {...props}
      />
    );
  }
);
