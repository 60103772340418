import {Switch} from 'react-router-dom';
import {AdStudioAdAccount} from './AdStudioAdAccount';
import {AddAdStudioAdAccount} from './AddAdStudioAdAccount';
import { ManagePixelsPixelRouteProps } from '../_routes';
import useRoutes from '@core/lib/useRoutes';
import { createOrgPath } from '@core/lib/organizations';
import { ManagePixelsRouteProps } from '../../_routes';

export type ManageAdStudioRouteProps = ManagePixelsRouteProps & {
  pixel: TSFixMe;
};

export function ManageAdStudioIndex(props: ManagePixelsPixelRouteProps) {
  const { organization, pixel } = props;
  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, `manage/pixels/${pixel.key}/ad-studio`),
    basePath: '/:part(b|a|s|p)/:slug/manage/pixels/:key/ad-studio',
    routes: [
      {title: 'Ad Studio', key: 'ad-studio', path: '', exact: true},
      {key: 'add', exact: true},
    ],
  });

  return (
    <>
      <Switch>
        {renderRoutes({
          components: {
            add: AddAdStudioAdAccount,
            'ad-studio': AdStudioAdAccount,
          },
          extraProps: {
            organization,
            pixel,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
}
