import {gql} from '@apollo/client';

export default gql`
  mutation deleteActionMap($input: DeleteActionMapInput!) {
    authed {
      success
    }
    deleteActionMap(input: $input) {
      success
    }
  }
`;
