import {gql} from '@apollo/client';

export default gql`
  fragment ReviewerEpisode on EpisodeObject {
    id
    published
    title
    subtitle
    summary
    image {
      id
      href
      path
    }

    feed {
      id
      title
      image {
        id
        href
        path
      }
    }

    processed
    placementsCount
    enclosure {
      id
      length
    }
  }
`;
