import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {comma} from '@core/lib/filters';
import {stripHTML, truncateWords} from '@core/lib/words';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {FeedImage} from '@core/ui/Image';
import Loading from '@core/ui/Loading';
import RankingsQuery from '@analytics/graphql-api/_old_queries/research/RankingsQuery';

const RankingsTable = ({data: {loading, me}}) => {
  if (loading) {
    return <Loading centered />;
  }

  const data = me.organization.research.rankings.map(
    ({feed, num, id, episodesCount, placementsCount}) => {
      return {
        id,
        num,
        title: feed.title,
        episodesCount: episodesCount,
        placementsCount: placementsCount,
        feed,
      };
    }
  );

  const columns = [
    {
      title: '',
      accessor: 'num',
      type: 'number',
    },
    {
      title: 'Podcast',
      accessor: 'title',
      type: 'string',
      sort: (direction, a, b) => {
        return (a.num - b.num) * direction;
      },
      Cell: ({data: {feed}}) => {
        return (
          <Element rules={() => ({minWidth: '15.5rem', display: 'flex'})}>
            <FeedImage
              feed={feed}
              width={75}
              rules={() => ({width: '4.6875rem', marginRight: '1rem'})}
            />
            <Element>
              <Element
                rules={({theme}) => ({
                  fontWeight: '500',
                  color: theme.textPrimary,
                })}>
                {feed.title}
              </Element>
              <Element
                rules={({theme}) => ({
                  color: theme.textTertiary,
                  fontSize: '.875rem',
                })}>
                {truncateWords(
                  stripHTML(feed.subtitle || feed.summary || ''),
                  35
                )}
              </Element>
            </Element>
          </Element>
        );
      },
    },
    {
      title: 'Episodes',
      accessor: 'episodesCount',
      type: 'number',
      fmt: comma,
    },
    {
      title: 'Ads',
      accessor: 'placementsCount',
      type: 'number',
      fmt: comma,
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={data}
      orderBy='num'
      onClickRowPath={({feed}) => {
        return `/research/podcasts/${feed.id}`;
      }}
    />
  );
};

export default graphql(RankingsQuery, {
  options: ({organization, limit}) => {
    return {
      variables: {
        id: organization.id,
        limit,
      },
    };
  },
})(RankingsTable);
