import React, {useEffect, useRef, useState} from 'react';
import {toTimeString} from '@core/lib/time';
import {TrackGesture} from '@core/lib/useTrackGesture';
import {useTheme} from '@core/style';
import Button from '@core/ui/Button/LegacyButton';
import {Icon} from '@core/ui/Icon';

const toPath = (gs_path: string) => {
  const [_, bucket, path] = /gs:\/\/([-\w]+)\/(.*)/.exec(gs_path);
  return `https://storage.googleapis.com/${bucket}/${path}`;
};

const clampValue = (value: number, min = 0, max = 100) =>
  Math.floor(Math.max(min, Math.min(value, max)));

const SimpleAdvisorAudioPlayerTrack = ({
  audioRef,
  currentTime,
  duration,
  playing,
  setCurrentTime,
  togglePlay,
}: {
  audioRef: any;
  currentTime: number;
  duration: number;
  playing: boolean;
  setCurrentTime: (prev?: number | ((prev?: number) => void)) => void;
  togglePlay: (to?: boolean) => void;
}): JSX.Element => {
  const trackRef = useRef<HTMLDivElement>();
  const draggingHandle = useRef<HTMLDivElement>();
  const theme = useTheme();

  const onClick = ({pageX}: {pageX: number}) => {
    if (trackRef.current) {
      const rect = trackRef.current.getBoundingClientRect();
      const myCurrentTime = ((pageX - rect.x) / rect.width) * duration;

      if (!playing) {
        togglePlay();
      }

      setTimeout(() => {
        audioRef.current.currentTime = myCurrentTime;
      }, 10);
    }
  };

  useEffect(() => {
    let disposable;

    if (trackRef.current) {
      disposable = new TrackGesture({
        target: trackRef.current,
        onTrack: ({startX, dx, targetRect}) => {
          const left = clampValue(startX + dx, 0, targetRect.width);
          const x = clampValue(
            (left * duration) / targetRect.width,
            0,
            duration
          );

          setCurrentTime(() => {
            if (!playing) {
              togglePlay();
            }

            setTimeout(() => {
              audioRef.current.currentTime = x;
            }, 10);

            return x;
          });
        },
        onTrackEnd: () => {
          setCurrentTime((prev) => prev);
          draggingHandle.current = null;
        },
      });
    }

    return disposable;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      css={{
        alignItems: 'center',
        background: theme === 'light' ? '#333' : '#fff',
        borderRadius: '0.375rem',
        borderWidth: '0.125rem',
        color: theme === 'light' ? '#fff' : '#0d1117',
        display: 'flex',
        fontSize: '0.875rem',
        height: '2.125rem',
        padding: '0 0.75rem',
        width: '26rem',
      }}>
      {!playing ? (
        <Icon
          icon='play'
          onClick={() => togglePlay()}
          rules={() => ({
            cursor: 'pointer',
            marginRight: '0.5rem',
            width: '1rem',
          })}
        />
      ) : (
        <Icon
          icon='pause'
          onClick={() => togglePlay()}
          rules={() => ({
            cursor: 'pointer',
            marginRight: '0.5rem',
            width: '1rem',
          })}
        />
      )}
      <div
        ref={trackRef}
        onClick={onClick}
        css={{
          background: theme === 'light' ? '#fafbfc' : '#0d1117',
          borderRadius: '0.125rem',
          cursor: 'pointer',
          height: '2.95px',
          width: '20rem',
          position: 'relative',
        }}>
        <div
          css={{
            background: theme === 'light' ? '#fafbfc' : '#0d1117',
            borderRadius: '50%',
            height: '10px',
            marginLeft: `${((currentTime / duration) * 100).toFixed(5)}%`,
            marginTop: '-3.5px',
            position: 'absolute',
            width: '10px',
            ':hover': {
              transform: 'scale(1.15)',
            },
          }}
        />
        <div
          css={{
            background: 'var(--green)',
            position: 'absolute',
            width: `${((currentTime / duration) * 100).toFixed(5)}%`,
            height: '2.95px',
            left: 0,
          }}
        />
      </div>
      <div
        css={{
          color: theme === 'light' ? '#fafbfc' : '#0d1117',
          fontSize: '0.7rem',
          marginLeft: '1rem',
        }}>
        -{toTimeString(duration - currentTime)}
      </div>
    </div>
  );
};

const AdvisorSimpleAudioPlayer = ({
  ad,
}: {
  ad: {mpegPath: string; duration: number};
}): JSX.Element => {
  const audioRef = useRef<HTMLAudioElement>();
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<null | number>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [openTrailer, setOpenTrailer] = useState<boolean>(false);

  const onTimeUpdate = ({target}) => {
    setCurrentTime(target.currentTime);
  };

  const onReady = ({target}) => {
    audioRef.current = target;
    setDuration(target.duration);
    audioRef.current.addEventListener('ended', () => {
      setPlaying(false);
    });
  };

  const togglePlay = () => {
    if (playing) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setPlaying((prev) => !prev);
  };

  return (
    <div css={{alignItems: 'center', display: 'flex', marginTop: '0.75rem'}}>
      <audio
        onCanPlayThrough={onReady}
        onTimeUpdate={onTimeUpdate}
        ref={audioRef}>
        <source src={toPath(ad.mpegPath)} type='audio/flac' />
      </audio>
      {!openTrailer && (
        <Button
          style-dark
          style-small
          onClick={() => {
            setOpenTrailer(true);
            togglePlay();
          }}>
          <Icon
            icon='play'
            rules={() => ({width: '1rem', marginRight: '0.5rem'})}
          />
          Play Trailer
        </Button>
      )}
      {openTrailer && (
        <SimpleAdvisorAudioPlayerTrack
          audioRef={audioRef}
          currentTime={currentTime}
          duration={duration}
          playing={playing}
          setCurrentTime={setCurrentTime}
          togglePlay={togglePlay}
        />
      )}
    </div>
  );
};

export default AdvisorSimpleAudioPlayer;
