const demographics = {
  'Head of household age': {
    topic: 'Age',
    subtopic: 'Head of household',
    type: 'experian',
  },
  'Estimated household income': {
    topic: 'Income',
    subtopic: 'Head of household',
    type: 'experian',
  },
  'Family structure': {
    topic: 'Family Structure',
    subtopic: 'Complete household',
    type: 'experian',
  },
  Children: {
    topic: 'Children',
    subtopic: 'Complete household',
    type: 'experian',
  },
  'Head of household education': {
    topic: 'Education',
    subtopic: 'Head of household',
    type: 'experian',
  },
  ' Demographic - Age': {
    topic: 'Age',
    type: 'nielsen',
  },
  ' Demographic - Income Level': {
    topic: 'Income',
    type: 'nielsen',
  },
  ' Demographic - Marital Status': {
    topic: 'Family Structure',
    type: 'nielsen',
  },
  ' Demographic - Household - Presence of Children': {
    topic: 'Children',
    type: 'nielsen',
  },
  ' Demographic - Education': {
    topic: 'Education',
    type: 'nielsen',
  },
};

const experian_buckets = {
  Age: ['19–24', '25–30', '31–35', '36–45', '46–50', '51–65', '66–75', '76+'],
  Children: ['Presence of a child'],
  Education: [
    'Less than high school',
    'High school diploma',
    'Some college',
    "Bachelor's degree",
    "Graduate's degree",
  ],
  'Family Structure': ['Single', 'Married', 'Unknown'],
  Income: {
    '<75K': [
      'Less than $15,000',
      '$15,000–$24,999',
      '$25,000–$34,999',
      '$35,000–$49,999',
      '$50,000–$74,999',
    ],
    '75K-100K': ['$75,000–$99,999'],
    '100K-150K': ['$100,000–$124,999', '$125,000–$149,999'],
    '150K+': [
      '$150,000–$174,999',
      '$175,000–$199,999',
      '$200,000–$249,999',
      '$250,000+',
    ],
  },
};

const nielsen_buckets = {
  Age: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
  Children: ['With Children', 'Without Children'],
  Education: [
    'High School',
    'Vocational',
    'Some College',
    'College Grad',
    'Grad Degree',
  ],
  'Family Structure': ['Single', 'Married', 'Divorced'],
  Income: {
    '<75K': [
      'Under $20,000',
      '$20,000 to $29,999',
      '$30,000 to $39,999',
      '$40,000 to $49,999',
      '$50,000 to $59,999',
      '$60,000 to $74,999',
    ],
    '75K-100K': ['$75,000 to $99,999'],
    '100K-150K': ['$100,000 to $124,999', '$125,000 to $149,999'],
    '150K+': ['$150,000 Plus'],
  },
};

export {demographics, experian_buckets, nielsen_buckets};
