import {gql} from '@apollo/client';

export default gql`
  query getCampaignPermissions($organizationId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $id) {
          id
          permissions {
            id
            title
            description
            objectName
            organization {
              id
              name
            }
          }
        }
      }
    }
  }
`;
