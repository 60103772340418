import {Controller} from 'react-hook-form';
import {Label} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {MultipleSelect} from '@core/ui/Select';
import {InfoTooltip} from '@core/ui/Tooltip';
import {FieldProps} from '../helpers';

export function ActionsField({control, watch}: FieldProps) {
  return (
    <Stack direction='column'>
      <Label
        htmlFor='events'
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
          gap: '0.375rem',
        })}>
        Events
        <InfoTooltip
          title='Events'
          description='Verified website pixel events'
        />
      </Label>
      <Controller
        name='exportParameters.actions'
        control={control}
        render={({field}) => (
          <MultipleSelect
            key={watch('typeId')}
            buttonProps={{id: 'events'}}
            defaultValue={field.value ?? []}
            getSelectLabel={(items) =>
              items.map((item) => item.title).join(', ')
            }
            items={[
              {value: 'purchase', title: 'Purchase'},
              {value: 'lead', title: 'Lead'},
              {value: 'install', title: 'Install'},
              {value: 'addtocart', title: 'Add to Cart'},
              {value: 'checkout', title: 'Checkout'},
              {value: 'product', title: 'Product'},
              {
                value: 'init',
                title: 'Page View',
                description:
                  'Exporting page views can be a resource-intensive operation depending on the volume of your site traffic. If you run into issues, please select a shorter date range.',
              },
            ]}
            onSelect={(items) =>
              field.onChange(items.map((item) => item.value))
            }
            outline
            placeholder='Events'
          />
        )}
      />
    </Stack>
  );
}
