const PODLINK_HOST = 'https://pod.link';
// const PODLINK_HOST = 'http://podlink.local';

const getPodlinkData = async (href) => {
  const resp = await window.fetch(
    `${PODLINK_HOST}/id.json?href=${encodeURIComponent(href)}`
  );

  return await resp.json();
};

const getPodlinkEpsiodes = async (url) => {
  const resp = await window.fetch(`${url}.json`);
  return (await resp.json()).episodes;
};

export {PODLINK_HOST, getPodlinkData, getPodlinkEpsiodes};
