import {graphql} from '@apollo/client/react/hoc';
import {createOrgPath} from '@core/lib/organizations';
import {Card, CardHeader} from '@core/ui/Content';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';
import {CompanySimilarByEpisodeTable} from '@analytics/components/research/CompaniesTable';
import CompanySimilarByEpisodeQuery from '@analytics/graphql-api/_old_queries/research/CompanySimilarByEpisodeQuery';

const SimilarByEpisode = ({
  organization,
  company,
  data: {loading, error, me},
}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const {similarByEpisode} = me.organization.research.company;

  return (
    <div>
      <CardHeader>Similar companies by episodes</CardHeader>
      <Card>
        <div
          css={`
            display: flex;
          `}>
          <p
            css={`
              flex: 1;
            `}>
            The following companies have advertised on the same episode as{' '}
            {company.name}.
          </p>
          <div>
            <Link
              to={createOrgPath(
                organization,
                `/research/brands/${company.id}/similar`
              )}
              css={`
                color: var(--text-muted);
                font-weight: 500;
                text-decoration: none;
                :hover {
                  color: var(--blue);
                }
              `}>
              View by podcasts
            </Link>
          </div>
        </div>
        <div>
          <CompanySimilarByEpisodeTable
            organization={organization}
            company={company}
            companies={similarByEpisode}
            orderBy='-feedsCount'
          />
        </div>
      </Card>
    </div>
  );
};

export default graphql(CompanySimilarByEpisodeQuery, {
  options: ({organization, company: {id}}) => ({
    variables: {
      organizationId: organization.id,
      id,
      limit: 100,
    },
  }),
})(SimilarByEpisode);
