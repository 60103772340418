import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import {Element} from '@core/style';
import {BLUE} from '@core/style';
import Button from '@core/ui/Button/LegacyButton';
import {Icon} from '@core/ui/Icon';
import {LoadingSpinner} from '@core/ui/Loading';
import {Overlay} from '@core/ui/Overlay';
import {InfoTooltip} from '@core/ui/Tooltip';
import ORGANIZATION_GENERIC_TAGS from '@analytics/graphql-api/_old_queries/OrganizationGenericTags';
import TagsOverlayEditOrganizationTag from './TagsOverlayEditOrganizationTag';
import TagsOverlayTagList from './TagsOverlayTagList';

const TagsOverlayContent = ({
  instance,
  instanceTags,
  organization,
  setInstanceTags,
  organizationTags,
  refetch,
}) => {
  const [title, setTitle] = useState('Tags');
  const [editingTag, setEditingTag] = useState(null);

  const onAddOrganizationTag = async () => {
    await refetch();
    setEditingTag(null);
  };

  const onUpdateOrganizationTag = async (tag) => {
    setInstanceTags((prev) => {
      const match = prev.find(({id}) => id === tag.id);
      if (match) {
        Object.assign(match, tag);
      }
      return prev.slice(0);
    });

    await refetch();
    setEditingTag(null);
  };

  const onRemoveOrganizationTag = async (tagId) => {
    setInstanceTags((prev) => prev.filter(({id}) => id !== tagId));
    await refetch();
    setEditingTag(null);
  };

  const onAddRelationship = ({tagRelationShip, genericTag}) => {
    setInstanceTags((prev) =>
      prev.concat([
        Object.assign({}, genericTag, {
          genericTagRelationships: [tagRelationShip],
        }),
      ])
    );
  };

  const onRemoveRelationship = (tagRelationshipId) => {
    setInstanceTags((prev) =>
      prev.filter(({genericTagRelationships}) => {
        return !genericTagRelationships.find(
          ({id}) => id === tagRelationshipId
        );
      })
    );
  };

  return (
    <Element rules={() => ({display: 'flex', flexDirection: 'column'})}>
      <Element
        rules={({theme}) => ({
          alignItems: 'center',
          display: 'flex',
          fontWeight: 500,
          justifyContent: 'center',
          margin: '0 0 0.75rem',
          padding: '1rem 1rem 0',
          position: 'relative',
        })}>
        {editingTag ? (
          <Icon
            icon='chevron-back-outline'
            onClick={() => setEditingTag(null)}
            rules={() => ({
              boxSizing: 'content-box',
              cursor: 'pointer',
              height: '1.5rem',
              left: '0.75rem',
              margin: 'auto',
              padding: '0.125rem',
              position: 'absolute',
              top: '0.875rem',
              width: '1.5rem',
              ':hover': {
                color: BLUE,
              },
            })}
          />
        ) : null}
        {title}
        <InfoTooltip
          description='Only members of your organization will be able to see these tags.'
          rules={({theme}) => ({
            color: theme.textTertiary,
            display: 'flex',
            marginLeft: '0.375rem',
          })}
        />
      </Element>
      {editingTag ? (
        <Element rules={() => ({padding: '1rem'})}>
          <TagsOverlayEditOrganizationTag
            organization={organization}
            setTitle={setTitle}
            genericTag={editingTag}
            onAddOrganizationTag={onAddOrganizationTag}
            onUpdateOrganizationTag={onUpdateOrganizationTag}
            onRemoveOrganizationTag={onRemoveOrganizationTag}
          />
        </Element>
      ) : (
        <>
          <Element rules={() => ({maxHeight: '18.75rem', overflowY: 'auto'})}>
            <TagsOverlayTagList
              setTitle={setTitle}
              instance={instance}
              instanceTags={instanceTags}
              organizationTags={organizationTags}
              onClickEditOrganizationTag={(genericTag) => {
                setEditingTag(genericTag);
              }}
              onAddRelationship={onAddRelationship}
              onRemoveRelationship={onRemoveRelationship}
            />
          </Element>
          <Button
            onClick={() => {
              setEditingTag({isNew: true});
            }}
            rules={({theme}) => ({
              alignItems: 'center',
              borderColor:
                theme.name === 'dark' ? theme.borderPrimary : 'transparent',
              borderRadius: '0.375rem',
              borderWidth: '1px',
              display: 'flex',
              justifyContent: 'center',
              margin: '0.625rem auto 0.625rem 0.625rem',
              padding: '0 0.625rem',
            })}>
            <Icon icon='add' />
          </Button>
        </>
      )}
    </Element>
  );
};

const TagsOverlay = ({
  instance,
  instanceTags,
  organization,
  setInstanceTags,
  // Overlay props
  opened,
  positionTarget,
  toggle,
  overlayProps,
}) => {
  const {loading, error, data, refetch} = useQuery(ORGANIZATION_GENERIC_TAGS, {
    variables: {
      id: organization.id,
    },
  });

  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      withShadow
      withBackdrop
      transparentBackdrop
      animation='opacity'
      positionTarget={positionTarget}
      css={`
        width: 20rem;
        border: 1px solid var(--border-subtle);
      `}
      {...overlayProps}>
      {loading ? (
        <LoadingSpinner size='medium' centered />
      ) : (
        <TagsOverlayContent
          instance={instance}
          instanceTags={instanceTags}
          organization={organization}
          setInstanceTags={setInstanceTags}
          refetch={refetch}
          organizationTags={data.me.organization.genericTags}
        />
      )}
    </Overlay>
  );
};

export default TagsOverlay;
