import {Redirect, Route, Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import ManageNav from '@analytics/components/nav/ManageNav';
import {FetchedOrganization} from '@analytics/graphql-api';
import {Agencies} from './Agencies';
import {AgenciesAdd} from './AgenciesAdd';

export type ManageAgenciesRouteProps = {
  organization: FetchedOrganization;
  user: FetchedUser;
};

export function ManageAgenciesIndex({user, organization}) {
  if (organization.isManager) {
    return <Redirect to={createOrgPath(organization, '/manage')} />;
  }

  return (
    <Switch>
      {[
        {
          exact: true,
          path: '/:part(a|s|b|p)/:slug/manage/agencies',
          Component: Agencies,
        },
        {
          exact: true,
          path: '/:part(a|s|b|p)/:slug/manage/agencies/add',
          Component: AgenciesAdd,
        },
      ].map(({exact = false, path, Component}) => (
        <Route
          key={path}
          exact={exact}
          path={path}
          render={(routeProps) => (
            <>
              <ManageNav user={user} organization={organization} />
              <Component
                organization={organization}
                user={user}
                {...routeProps}
              />
            </>
          )}
        />
      ))}
    </Switch>
  );
}
