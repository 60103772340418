import {Element} from '@core/style';

const AudioPlayControls = ({rawScale, onScaleChange}) => {
  return (
    <Element
      tag='input'
      type='range'
      value={rawScale}
      step='5'
      onChange={onScaleChange}
    />
  );
};

export default AudioPlayControls;
