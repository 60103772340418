import {useEffect} from 'react';
import {loginViaSpotify} from '@core/components/SignInWithSpotify/auth';
import SpotifyBanner from '../../components/onboard/SpotifyBanner';
import {ContentContainer, PageContainer} from './AccountLayout';
import ConfirmationDialog from './ConfirmationDialog';
import {LoadingImage} from './LoadingImage';

const RedirectScreen = () => {
  useEffect(() => {
    loginViaSpotify();
  }, []);

  return (
    <PageContainer>
      <SpotifyBanner advertising />
      <ContentContainer>
        <ConfirmationDialog
          imageSrc={LoadingImage}
          boxTitle='You’re being redirected'
          footerText='Taking too long?'
          footerLinkText='Try again'
          onFooterLinkClick={() => loginViaSpotify()}
        />
      </ContentContainer>
    </PageContainer>
  );
};

export default RedirectScreen;
