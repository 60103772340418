import {
  CampaignDynamicObject,
  CampaignEpisodeObject,
  CampaignObject,
  FetchedCampaignLiftReports,
  FetchedIncrementalLiftReports,
} from '@analytics/graphql-api';
import {RunData} from './types';

type CanRunLiftReportFn = (_props: {
  runData: RunData;
  liftReport:
    | FetchedIncrementalLiftReports[number]
    | FetchedCampaignLiftReports[number];
}) => boolean;

export const canRunLiftReport: CanRunLiftReportFn = ({runData, liftReport}) => {
  const missingNoise = liftReport.useNoise && !runData.campaignNoises.length;
  const missingNodes = ![
    runData.campaignNoises.length,
    runData.baselineDynamics.length,
    runData.baselineEpisodes.length,
  ].some((a) => !!a);

  return !(missingNoise || missingNodes);
};

type LiftReportRunDataFn = (_campaigns: CampaignObject[]) => RunData;

export const liftReportRunData: LiftReportRunDataFn = (campaigns) => {
  return campaigns.reduce(
    (runData, campaign) => {
      if (!campaign || !campaign.id) return runData;

      if (campaign.campaignNoises) {
        const _campaignNoises = campaign.campaignNoises.map((noise) => ({
          ...noise,
          campaign,
        }));
        runData.campaignNoises.push(..._campaignNoises);
      }

      if (campaign.campaignPodcasts) {
        const _exposedEpisodes: CampaignEpisodeObject[] = [];
        const _baselineEpisodes: CampaignEpisodeObject[] = [];

        campaign.campaignPodcasts.forEach((cp) => {
          if (cp?.campaignEpisodes) {
            cp.campaignEpisodes.forEach((ce) => {
              if (ce.isBaseline) {
                _baselineEpisodes.push(ce);
              } else {
                _exposedEpisodes.push(ce);
              }
            });
          }
        });

        if (_exposedEpisodes.length > 0) {
          runData.exposedEpisodes.push({
            campaign,
            nodes: _exposedEpisodes,
          });
        }

        if (_baselineEpisodes.length > 0) {
          runData.baselineEpisodes.push({
            campaign,
            nodes: _baselineEpisodes,
          });
        }
      }

      if (campaign.campaignDynamics) {
        const _activeDynamics: CampaignDynamicObject[] = [];
        const _baselineDynamics: CampaignDynamicObject[] = [];

        campaign.campaignDynamics.forEach((cd) => {
          if (cd.isBaseline) _baselineDynamics.push(cd);
          else if (
            !cd.isBaseline &&
            typeof cd.downloadTotal === 'number' &&
            cd.downloadTotal > 0
          )
            _activeDynamics.push(cd);
        });

        if (_activeDynamics.length > 0) {
          runData.activeDynamics.push({
            campaign,
            nodes: _activeDynamics,
          });
        }

        if (_baselineDynamics.length > 0) {
          runData.baselineDynamics.push({
            campaign,
            nodes: _baselineDynamics,
          });
        }
      }

      return runData;
    },
    {
      campaignNoises: [],
      activeDynamics: [],
      baselineDynamics: [],
      baselineEpisodes: [],
      exposedEpisodes: [],
    } as RunData
  );
};
