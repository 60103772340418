import {gql} from '@apollo/client';
import CampaignDynamicEpisodeFragment from './fragments/CampaignDynamicEpisodeFragment';

export default gql`
  query getCampaignDynamicEpisode(
    $organizationId: ID!
    $campaignId: ID!
    $campaignDynamicId: ID!
    $campaignDynamicPodcastId: ID!
    $id: ID!
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          campaignDynamic(id: $campaignDynamicId) {
            id
            campaignDynamicPodcast(id: $campaignDynamicPodcastId) {
              id
              campaignDynamicEpisode(id: $id) {
                ...CampaignDynamicEpisode
              }
            }
          }
        }
      }
    }
  }
  ${CampaignDynamicEpisodeFragment}
`;
