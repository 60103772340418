import styled from 'styled-components';
import {Icon} from '@core/ui/Icon';
import {Link} from '@core/ui/Link';

export const CampaignInfoBadge = styled.div`
  background: var(--bg-muted);
  border-radius: 4px;
  font-size: var(--font-size-body-3);
  height: 1.875rem;
  padding: var(--spacing-1) var(--spacing-3);
`;

export const CampaignSalesOrderLink = ({name, to}: TSFixMe) => (
  <Link
    color='info'
    css={{
      alignItems: 'center',
      borderRadius: '0.225rem',
      cursor: 'pointer',
      display: 'flex',
      fontSize: 'var(--font-size-body-3)',
      fontWeight: 'var(--font-weight-medium)',
      gap: 'var(--spacing-1)',
      padding: '0.125rem var(--spacing-1)',
      width: 'fit-content',
      ':hover': {
        background: 'var(--blue-20)',
      },
    }}
    to={to}
    target='_blank'
    rel='noopener noreferrer'
    underline='none'>
    <Icon icon='external' css={{height: '1rem', width: '1rem'}} />
    {name}
  </Link>
);
