import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import Button from '@core/ui/Button/LegacyButton';
import {Separator} from '@core/ui/Content';
import {
  Form,
  FormFooter,
  LabelInput,
  LabelSelect,
  Submit,
} from '@core/ui/FormElements';

interface Item {
  url: string;
  value: string;
  action: string;
  type: string;
  category: string;
}

interface PixelConversionUrlFormProps {
  updateItem?: Item;
  onCancel: () => void;
  onSubmit: (item: Item) => void;
  loading: boolean;
}

const PixelConversionUrlForm = ({
  onSubmit,
  onCancel,
  loading,
  updateItem,
}: PixelConversionUrlFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: {errors, isDirty},
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      url: updateItem?.url || null,
      value: updateItem?.value || null,
      action: updateItem?.action || 'lead',
      type: updateItem?.type || null,
      category: updateItem?.category || null,
    },
  });

  const _onSubmit = (item: Item) => {
    if (updateItem && !isDirty) {
      onCancel();
    } else {
      onSubmit({
        ...item,
      });
    }
  };

  const action = watch('action');

  return (
    <Form errors={errors} onSubmit={handleSubmit(_onSubmit)}>
      <Controller
        name='url'
        control={control}
        rules={{required: 'Please provide a URL.'}}
        render={({field}) => (
          <LabelInput
            defaultValue={updateItem?.url || null}
            invalid={!!errors.url}
            label='URL'
            onChange={(value) => field.onChange(value)}
            rules={() => ({marginBottom: '1.5rem'})}
          />
        )}
      />
      <Controller
        name='value'
        control={control}
        rules={{required: 'Please provide a value.'}}
        render={({field}) => (
          <LabelInput
            defaultValue={updateItem?.value || null}
            invalid={!!errors.value}
            label='Value'
            onChange={(value) => field.onChange(value)}
            type='number'
            rules={() => ({marginBottom: '1.5rem'})}
          />
        )}
      />
      <Controller
        name='action'
        control={control}
        render={({field}) => (
          <LabelSelect
            label='Action'
            defaultValue={updateItem?.action || 'lead'}
            items={[
              {title: 'Lead', value: 'lead'},
              {title: 'Purchase', value: 'purchase'},
            ]}
            onSelect={({value}) => {
              if (value === 'purchase') {
                setValue('type', null);
                setValue('category', null);
              }
              field.onChange(value);
            }}
          />
        )}
      />

      {action === 'lead' && (
        <>
          <Separator />
          <Controller
            name='type'
            control={control}
            render={({field}) => (
              <LabelInput
                defaultValue={updateItem?.type || null}
                label='Lead Type (Optional)'
                onChange={(value) => field.onChange(value)}
                rules={() => ({marginBottom: '1.5rem'})}
              />
            )}
          />
          <Controller
            name='category'
            control={control}
            render={({field}) => (
              <LabelInput
                defaultValue={updateItem?.category || null}
                label='Lead Category (Optional)'
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </>
      )}
      <FormFooter>
        <Button type='button' disabled={loading} onClick={onCancel}>
          Cancel
        </Button>
        <Submit loading={loading} disabled={loading}>
          Save Conversion URL
        </Submit>
      </FormFooter>
    </Form>
  );
};

export default PixelConversionUrlForm;
