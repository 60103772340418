import React from 'react';
import dayjs from '@core/lib/dayjs';
import {firstChar, obfuscateEmail} from '@core/lib/filters';
import DataTable from '@core/ui/DataTable';
import {reviewersURL} from '@analytics/components/nav/ResearchNav';

const ReviewsTable = ({organization, reviews}) => {
  const columns = [
    {
      title: 'Reviewer',
      accessor: 'displayName',
      type: 'string',
    },
    {
      title: 'Reviewed At',
      accessor: 'createdAt',
      type: 'date',
      fmt: (v) => dayjs(v).utc().format('MM/DD/YYYY HH:MM'),
    },
    {
      title: 'Start State',
      accessor: 'startState',
      type: 'string',
    },
    {
      title: 'End State',
      accessor: 'endState',
      type: 'string',
    },
  ];

  const data = reviews.map(
    ({id, reviewer, startState, endState, createdAt, duration}) => ({
      id,
      reviewerEmail: reviewer.email,
      reviewer,
      url: `${reviewersURL(organization)}/${reviewer.id}`,
      startState,
      endState,
      createdAt,
      displayName: `${reviewer.firstName} ${firstChar(
        reviewer.lastName
      )} (${obfuscateEmail(reviewer.email)})`,
    })
  );

  return (
    <DataTable
      columns={columns}
      orderBy='createdAt'
      data={data}
      onClickRowPath={({url}) => url}
    />
  );
};

export default ReviewsTable;
