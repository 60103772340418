import {createOrgPath} from '@core/lib/organizations';
import {Card, CardHeader, PageTitle} from '@core/ui/Content';
import {Link} from '@core/ui/Link';
import {CampaignsTable} from '@analytics/components/campaigns/CampaignsTable';
import PodcastsTable from '@analytics/components/podcasts/PodcastsTable';
import {FetchedOrganization} from '@analytics/graphql-api';

type Props = {
  organization: FetchedOrganization;
};

export function SellSideHome({organization}: Props) {
  return (
    <>
      <PageTitle>{organization.name}</PageTitle>
      {!!organization.managing.length && (
        <>
          <CardHeader>Clients</CardHeader>
          <Card>
            The following organizations have given you permission to manage
            their accounts.
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                lineHeight: 1.8,
                marginTop: '.5rem',
              }}>
              {organization.managing.map((org) => {
                return (
                  <Link
                    key={org.id}
                    to={createOrgPath(org)}
                    color='link'
                    css={`
                      font-weight: 500;
                      text-decoration: none;
                    `}>
                    {org.name}
                  </Link>
                );
              })}
            </div>
          </Card>
        </>
      )}
      <Card>
        <CardHeader>Campaigns</CardHeader>
        <CampaignsTable organization={organization} />
      </Card>
      <Card>
        <CardHeader>Podcasts</CardHeader>
        <PodcastsTable organization={organization} />
      </Card>
    </>
  );
}
