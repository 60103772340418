import {useState} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {Form} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {FeedNav, researchURL} from '@analytics/components/nav/ResearchNav';
import ProcessFeed from '@analytics/graphql-api/_old_mutations/ProcessFeed';

const FeedProcess = ({organization, feed, processFeed}) => {
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [errors, setErrors] = useState([]);

  if (complete) {
    return (
      <>
        <FeedNav organization={organization} feed={feed} />
        <ResponsiveContent containerSize='medium'>
          <CardHeader>Processing Podcast</CardHeader>
          <Card>
            <p>
              We are processing this podcast. This can take a bit, so please
              check back later.
            </p>
            <Button to={researchURL(organization)}>Back to Research</Button>
          </Card>
        </ResponsiveContent>
      </>
    );
  }

  return (
    <>
      <FeedNav organization={organization} feed={feed} />
      <ResponsiveContent containerSize='medium'>
        <CardHeader>Process Podcast</CardHeader>
        <Card>
          <p>Generate Waveforms for every episode.</p>
          <Form
            loading={loading}
            onSubmit={() => {
              setLoading(true);
              processFeed()
                .then(() => {
                  setLoading(false);
                  setComplete(true);
                })
                .catch((resp) => {
                  setLoading(false);
                  setErrors(resp.graphQLErrors);
                });
            }}
            errors={errors}
            onClose={() => {
              setErrors([]);
            }}>
            <Submit>Process Podcast</Submit>
          </Form>
        </Card>
      </ResponsiveContent>
    </>
  );
};

export default graphql(ProcessFeed, {
  props: ({
    ownProps: {
      feed: {id},
    },
    mutate,
  }) => ({
    processFeed: () => {
      return mutate({
        variables: {
          input: {
            feedId: id,
          },
        },
      });
    },
  }),
})(FeedProcess);
