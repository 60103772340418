import {graphql} from '@apollo/client/react/hoc';
import {MiniPlayer} from '@core/components/AudioMiniPlayer';
import {toTimeString} from '@core/lib/time';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';
import AdCopy from '@analytics/components/ads/AdCopy';
import EpisodesTable from '@analytics/components/research/EpisodesTable';
import ReviewsTable from '@analytics/components/research/ReviewsTable';
import CompanyAdDetailQuery from '@analytics/graphql-api/_old_queries/research/CompanyAdDetailQuery';

const AdOverview = ({organization, ad, data: {loading, me}, match: {url}}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const {placements, reviews} = me.organization.research.company.ad;

  const episodes = placements.map(({episode}) => episode);

  return (
    <>
      <CardHeader>Ad detail</CardHeader>
      <Card>
        <Element
          rules={() => ({
            display: 'flex',
            padding: '0 20px',
            justifyContent: 'flex-end',
          })}>
          <Link to={`${url}/download`}>Download Audio</Link>
        </Element>

        <Element
          rules={() => ({
            alignItems: 'center',
            display: 'flex',
            padding: '10px',
          })}>
          <Element rules={() => ({flex: '1'})}>
            <MiniPlayer enclosure={ad} />
          </Element>
          <Element
            rules={() => ({
              color: '#666',
              fontSize: '12px',
              marginLeft: '5px',
            })}>
            {toTimeString(ad.duration)}
          </Element>
        </Element>
        <Element>
          <AdCopy words={ad.words} />
        </Element>
      </Card>

      <CardHeader>Appeared on</CardHeader>
      <Card>
        <EpisodesTable organization={organization} episodes={episodes} />
      </Card>

      <CardHeader>Audit Log</CardHeader>
      <Card>
        <ReviewsTable organization={organization} reviews={reviews} />
      </Card>
    </>
  );
};

export default graphql(CompanyAdDetailQuery, {
  options: ({
    organization,
    company,
    match: {
      params: {adId},
    },
  }) => {
    return {
      variables: {
        organizationId: organization.id,
        companyId: company.id,
        id: adId,
      },
    };
  },
})(AdOverview);
