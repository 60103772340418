import {gql} from '@apollo/client';

export default gql`
  query getBrandCampaignEpisodeOmniChart(
    $organizationId: ID!
    $campaignId: ID!
    $id: ID!
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id

          campaignEpisode(id: $id) {
            id

            episode {
              id
              title
              image {
                id
                path
              }
              published
            }

            attribution {
              users {
                hourlyVisitors {
                  count
                  hour
                }
              }
            }
            downloads {
              hourly {
                hour
                downloadClass
                count
              }
            }
          }
        }
      }
    }
  }
`;
