import {useQuery} from '@apollo/client';
import ChartTime from '@core/components/ChartTime';
import {IOrganizationCompany} from '@core/types';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import FeedsTable from '@analytics/components/research/FeedsTable';
import RecentBrandAdsTable from '@analytics/components/research/RecentBrandAdsTable';
import {FetchedOrganization} from '@analytics/graphql-api';
import COMPANY_DETAIL_QUERY from '@analytics/graphql-api/_old_queries/research/CompanyDetailQuery';

const CompanyOverview = ({
  organization,
  company,
}: {
  organization: FetchedOrganization;
  company: IOrganizationCompany;
}): JSX.Element => {
  const {data, loading, error} = useQuery(COMPANY_DETAIL_QUERY, {
    variables: {
      organizationId: organization.id,
      id: company.id,
    },
  });

  if (loading) {
    return <Loading fixed />;
  }

  if (error) {
    <Card>
      <EmptyMessage>Network error. Please try again.</EmptyMessage>
    </Card>;
  }

  const _company = data.me.organization.research.company;
  const series = {
    activity: _company.activity.map(({count, time}) => ({
      x: time,
      y: count,
    })),
  };

  return (
    <>
      <CardHeader>Activity</CardHeader>
      <Card>
        <div css={{fontSize: '0.9375rem'}}>Total ads placements per Week.</div>
        <ChartTime series={series} hideLegends interval='weekly' />
      </Card>
      <CardHeader
        cta={{
          title: 'View all',
          path: `/research/brands/${company.id}/ads`,
        }}>
        My Recent Ads
      </CardHeader>
      <Card>
        <RecentBrandAdsTable
          company={_company}
          organization={organization}
          orderBy='-firstPublished'
          limit={10}
        />
      </Card>
      <CardHeader
        cta={{
          title: 'View all',
          path: `/research/brands/${company.id}/podcasts`,
        }}>
        Podcasts
      </CardHeader>
      <Card>
        <FeedsTable company={_company} organization={organization} limit={10} />
      </Card>
    </>
  );
};

export default CompanyOverview;
