import {ButtonSecondary} from '@spotify-internal/encore-web';
import {useOverlay} from '@core/ui/Overlay';
import {BulkImportOverlay, ImportsType} from './BulkImportOverlay';

type Props = {
  onSubmit: (data: ImportsType) => void;
};

export const BulkImportButton = ({onSubmit}: Props) => {
  const [show, setShow] = useOverlay(false);

  return (
    <>
      <BulkImportOverlay opened={show} toggle={setShow} onSubmit={onSubmit} />
      <ButtonSecondary onClick={() => setShow(true)} size='small'>
        Import Line Items
      </ButtonSecondary>
    </>
  );
};
