import {createOrgPath} from '@core/lib/organizations';
import {DropdownMenuDivider, DropdownMenuTitle} from '@core/ui/DropdownMenu';
import {FeedImage} from '@core/ui/Image';
import {Nav} from '@core/ui/Nav';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';

const campaignPodcastURL = (organization, campaign, campaignPodcast) =>
  createOrgPath(
    organization,
    `/campaigns/${campaign.slug}/podcasts/${campaignPodcast.id}`
  );

const CampaignPodcastNav = ({
  user,
  organization,
  campaign,
  campaignPodcast,
}) => {
  const url = campaignPodcastURL(organization, campaign, campaignPodcast);

  return (
    <Nav
      title={campaignPodcast.name}
      description={campaignPodcast.feed.description}
      Image={() => <FeedImage feed={campaignPodcast.feed} width={150} />}
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/measure/campaigns'),
          title: 'Campaigns',
        },
        {
          to: toCampaignUrl(organization, campaign),
          title: campaign.name,
        },
        {
          title: 'Embedded Ad',
        },
      ]}
      moreDropdownItems={[
        {to: `${url}/update`, title: 'Update'},

        ...(user?.isAdmin
          ? [
              {content: <DropdownMenuDivider />},
              {
                content: (
                  <DropdownMenuTitle>
                    Only visible to Ad Analytics members:
                  </DropdownMenuTitle>
                ),
              },
              {to: `${url}/consistency`, title: 'Data Consistency'},
            ]
          : []),
      ]}
      moreDropdownOverlayProps={{
        horizontalAlign: 'right',
      }}
    />
  );
};

export {campaignPodcastURL};

export default CampaignPodcastNav;
