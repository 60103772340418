import {FormControlLabel, FormControlLabelProps} from '../Form New';
import {Checkbox, CheckboxProps} from './Checkbox.new';

interface CheckboxLabeledProps extends FormControlLabelProps {
  color?: CheckboxProps['color'];
}

export function CheckboxLabeled({
  color = 'primary',
  label = '',
  ...props
}: CheckboxLabeledProps) {
  return (
    <FormControlLabel
      label={label}
      color={color}
      {...props}
      control={<Checkbox />}
    />
  );
}
