import React, {useState} from 'react';
import {Query} from '@apollo/client/react/components';
import {createOrgPath} from '@core/lib/organizations';
import {stripHTML, truncateWords} from '@core/lib/words';
import {Element} from '@core/style';
import {GREEN} from '@core/style';
import Button from '@core/ui/Button/LegacyButton';
import EmptyMessage from '@core/ui/EmptyMessage';
import {FeedImage} from '@core/ui/Image';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import EpisodeQuery from '@analytics/graphql-api/_old_queries/research/EpisodeQuery';
import TranscriptCompanyProduct from './TranscriptCompanyProduct';

const compareWords = (a, b) => {
  return a.start === b.start && a.end === b.end;
};

const getTextWidth = (text) => {
  const t = document.createElement('div');
  t.innerText = text;
  t.style.visibility = 'none';
  t.style.display = 'inline';
  t.style.fontSize = '16px';
  document.body.appendChild(t);
  const width = t.getBoundingClientRect().width;
  document.body.removeChild(t);

  return (width / text.length) * (text.length + 1);
};

class WordInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: getTextWidth(props.defaultValue),
    };
  }

  componentDidMount() {
    this.input.focus();
  }

  onChange = ({target: {value}}) => {
    this.setState({
      width: getTextWidth(value),
    });
  };

  render() {
    const {defaultValue, onBlur} = this.props;
    const {width} = this.state;
    return (
      <Element
        tag='input'
        type='text'
        style={{
          width: `${width + 6}px`,
        }}
        rules={() => ({
          display: 'inline',
          height: '20px',
          fontSize: '16px',
          padding: '0 2px',
          border: 'none',
          borderBottom: '1px solid rgba(252,28,88,1)',
        })}
        defaultValue={defaultValue}
        onBlur={onBlur}
        domRef={(input) => {
          this.input = input;
        }}
        onChange={this.onChange}
      />
    );
  }
}

const Word = ({updateWord, word, highlight, currentTime, setCurrentTime}) => {
  const [editing, setEditing] = useState(false);

  const onClick = () => {
    setCurrentTime(word.start);
    if (updateWord) {
      setEditing(true);
    }
  };

  const onBlur = ({target: {value}}) => {
    if (value != word.word) {
      word.word = value;
      updateWord(word);
    }
    setEditing(false);
  };

  const isActive = word.start <= currentTime && currentTime <= word.end;

  return (
    <Element
      tag='span'
      style={{
        background: highlight
          ? 'rgba(247, 255, 0, 0.5)'
          : isActive
          ? GREEN
          : '',
      }}
      rules={() => ({
        ':hover': {
          background: GREEN,
        },
      })}>
      {editing ? (
        <WordInput
          tag='input'
          type='text'
          defaultValue={word.word}
          onBlur={onBlur}
          rules={() => ({display: 'inline', height: '16px'})}
        />
      ) : (
        <span onClick={onClick}>{word.word}</span>
      )}
    </Element>
  );
};

const TranscriptStatic = ({words, currentTime, setCurrentTime, highlights}) => {
  let array = words;

  if (typeof array === 'string') {
    array = JSON.parse(words);
  }

  let shouldHighlight = () => {};

  if (highlights && highlights.length) {
    shouldHighlight = (word) => highlights.indexOf(word.toLowerCase()) > -1;
  }

  return (
    <Element
      rules={() => ({
        fontSize: '0.9375rem',
        border: '1px solid #eee',
        borderRadius: '0.5rem',
        margin: '0 0 1.25rem 0',
        padding: '1rem',
      })}>
      {array.length
        ? array
            .map((word, n) => {
              return (
                <Word
                  key={n}
                  word={word}
                  highlight={shouldHighlight(word.word)}
                  currentTime={currentTime}
                  setCurrentTime={setCurrentTime}
                />
              );
            })
            .reduce((prev, curr) => [prev, ' ', curr])
        : ''}
    </Element>
  );
};

class Transcript extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitState(props.ad);
  }

  getInitState = (ad) => {
    const {words, company, product, sponsor} = ad;

    this.words = JSON.parse(words);

    return {
      savable: false,
      loading: false,
      showGuess: true,
      words: this.words,
      deltas: [],
      companyId: company ? company.id : null,
      companyName: company ? company.name : '',
      productId: product ? product.id : null,
      productName: product ? product.name : '',
      productUrl: product ? product.url : '',
      sponsorId: sponsor ? sponsor.id : null,
      sponsorName: sponsor ? sponsor.name : '',
    };
  };

  getWords = (props, deltas) => {
    return this.words.map((word) => {
      const updates = deltas.filter((w) => compareWords(w, word));

      if (updates.length) {
        return updates[0];
      }
      return word;
    });
  };

  updateWord = (word) => {
    const deltas = this.state.deltas
      .filter((w) => !compareWords(w, word))
      .concat(word);

    this.setState({
      savable: true,
      deltas: deltas,
      words: this.getWords(this.props, deltas),
    });
  };

  onCompanyChange = ({name, id}) => {
    this.setState({
      savable: true,
      companyId: id,
      companyName: name,
    });
  };

  onProductChange = ({name, id}) => {
    this.setState({
      savable: true,
      productId: id,
      productName: name,
    });
  };

  onProductUrlChange = (url) => {
    this.setState({
      productUrl: url,
    });
  };

  onSponsorChange = ({name, id}) => {
    this.setState({
      savable: true,
      sponsorId: id,
      sponsorName: name,
    });
  };

  updatePlacement = () => {
    const {onSave, ad} = this.props;
    const {deltas, companyId, companyName, productId, productName} = this.state;

    this.setState({
      loading: true,
    });

    onSave({
      id: ad.id,
      deltas: deltas.length ? JSON.stringify(deltas) : null,
      companyId,
      companyName,
      productId,
      productName,
    });
  };

  declineGuess = () => {
    this.setState({
      showGuess: false,
    });
  };
  acceptGuess = () => {
    const {
      guess: {companyId, companyName, productId, productName},
    } = this.props;

    const update = {
      savable: true,
      companyId,
      companyName,
    };

    if (productId) {
      update.productId = productId;
      update.productName = productName;
    }

    this.setState(update, () => {
      this.updatePlacement();
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.ad.id !== nextProps.ad.id) {
      this.setState(this.getInitState(nextProps.ad));
    }
  }

  render() {
    const {
      words,
      deltas,
      companyName,
      companyId,
      productName,
      savable,
      showGuess,
    } = this.state;
    const {guess, currentTime, setCurrentTime, organization, ad} = this.props;
    const isSavable = savable && companyName && companyName != 'None';

    return (
      <ResponsiveContent
        containerSize='medium'
        rules={() => ({fontSize: '0.9375rem', paddingBottom: '2rem'})}>
        <Element
          rules={({theme}) => ({
            background: theme.bgTertiary,
            borderRadius: '0.5rem',
            margin: '1.5rem 0',
            padding: '1rem',
          })}>
          {words.length
            ? words
                .map((word, n) => {
                  return (
                    <Word
                      key={n}
                      word={word}
                      currentTime={currentTime}
                      setCurrentTime={setCurrentTime}
                      updateWord={this.updateWord}
                    />
                  );
                })
                .reduce((prev, curr) => [prev, ' ', curr])
            : ''}
        </Element>
        {guess && showGuess ? (
          <>
            <Element
              rules={() => ({
                fontWeight: 500,
                marginBottom: '0.725rem',
              })}>
              Based on past ads, we think the company in the ad might be:
            </Element>
            <Element
              rules={() => ({
                border: `2px solid ${GREEN}`,
                borderRadius: '.5rem',
                padding: '1rem',
              })}>
              <Element
                rules={() => ({
                  alignItems: 'center',
                  display: 'grid',
                  fontSize: '1rem',
                  gridGap: '1rem',
                  gridTemplateColumns: '1fr 1fr max-content max-content',
                })}>
                <Element>
                  <Element
                    rules={({theme}) => ({
                      color: theme.textTertiary,
                      fontSize: '13px',
                    })}>
                    Company
                  </Element>
                  {guess.companyName}
                </Element>
                <Element>
                  <Element
                    rules={({theme}) => ({
                      fontSize: '13px',
                      color: theme.textTertiary,
                    })}>
                    Product
                  </Element>
                  {guess.productName ? guess.productName : '(none)'}
                </Element>
                <Button style-primary onClick={this.acceptGuess}>
                  Submit
                </Button>
                <Button onClick={this.declineGuess}>Edit</Button>
              </Element>
            </Element>
          </>
        ) : null}
        {(guess && !showGuess) || !guess ? (
          <Element
            rules={() => ({
              border: '1px solid #eee',
              borderRadius: '0.5rem',
              marginTop: '1.5rem',
              padding: '1.25rem',
            })}>
            <TranscriptCompanyProduct
              companyId={companyId}
              companyName={companyName}
              onCompanyChange={this.onCompanyChange}
              onProductChange={this.onProductChange}
              productName={productName}
            />
            <Element
              rules={() => ({
                marginTop: '2rem',
                textAlign: 'right',
              })}>
              <Button
                onClick={() =>
                  this.setState({
                    showGuess: true,
                  })
                }
                rules={() => ({marginRight: '1rem'})}>
                Cancel
              </Button>
              <Button
                style-dark
                onClick={this.updatePlacement}
                disabled={!isSavable}>
                Submit Changes
              </Button>
            </Element>
          </Element>
        ) : null}
        {ad.placements && ad.placements.length ? (
          <>
            <Element
              rules={() => ({
                fontWeight: 500,
                marginBottom: '0.725rem',
                marginTop: '1.5rem',
              })}>
              From episode:
            </Element>
            <Query
              query={EpisodeQuery}
              variables={{
                organizationId: organization.id,
                id: ad.placements[0].episode.id,
                feedId: ad.placements[0].episode.feed.id,
              }}>
              {({loading, data}) => {
                if (loading) {
                  return <Loading centered />;
                }

                let feed;
                try {
                  feed = data.me.organization.research.feed;
                } catch (err) {
                  return (
                    <EmptyMessage>
                      Could not find the episode data.
                    </EmptyMessage>
                  );
                }

                return (
                  <>
                    <Element
                      tag='a'
                      rules={({theme}) => ({
                        alignItems: 'start',
                        border: '1px solid #eee',
                        borderRadius: '0.5rem',
                        color: 'inherit',
                        display: 'flex',
                        padding: '1rem',
                        textDecoration: 'none',
                        ':hover': {
                          background: theme.bgTertiary,
                        },
                      })}
                      href={createOrgPath(
                        organization,
                        `research/podcasts/${feed.id}/episodes/${feed.episode.id}`
                      )}>
                      <FeedImage
                        feed={feed}
                        width={75}
                        rules={() => ({width: '75px'})}
                      />
                      <Element rules={() => ({marginLeft: '1rem'})}>
                        <Element rules={() => ({fontWeight: 500})}>
                          {feed.episode.title}
                        </Element>
                        <Element
                          rules={({theme}) => ({
                            margin: '4px 0',
                            color: theme.textTertiary,
                          })}>
                          {feed.title}
                        </Element>
                        <Element
                          rules={({theme}) => ({color: theme.textTertiary})}>
                          {truncateWords(
                            stripHTML(
                              feed.episode.summary || feed.episode.description
                            ),
                            30
                          )}
                        </Element>
                      </Element>
                    </Element>
                  </>
                );
              }}
            </Query>
          </>
        ) : null}
      </ResponsiveContent>
    );
  }
}

export {TranscriptStatic};

export default Transcript;
