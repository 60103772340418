import {ProgressCircle} from '@spotify-internal/encore-web';
import {useHistory, useParams} from 'react-router-dom';
import {useGetMeQuery} from '@core/graphql-api';
import PixelOnboardForm from '@analytics/components/pixels/PixelOnboardForm';
import {useOrganizationQuery} from '@analytics/graphql-api';
import SpotifyBanner from '../../components/onboard/SpotifyBanner';
import {PageContainer} from '../account/AccountLayout';

const OnboardPixel = () => {
  const {slug} = useParams<{slug: string}>();
  const history = useHistory();

  const {data: orgData, loading: orgLoading} = useOrganizationQuery({
    variables: {
      slug,
    },
  });
  const {data: userData, loading: userLoading} = useGetMeQuery();
  const organization = orgData?.me?.organization;
  const user = userData?.me;

  if (orgLoading || userLoading) {
    return (
      <PageContainer>
        <SpotifyBanner advertising progress={60} />
        <ProgressCircle
          aria-label='ProgressCircle'
          style={{alignSelf: 'center', marginTop: '100px'}}
        />
      </PageContainer>
    );
  }

  if (!organization || !user) {
    return null;
  }

  if (organization.keys?.length) {
    history.push(`/onboard/create-event/${slug}`);
    return null;
  }

  return (
    <PageContainer>
      <SpotifyBanner advertising progress={60} />
      <PixelOnboardForm user={user} organization={organization} />
    </PageContainer>
  );
};

export default OnboardPixel;
