import dayjs from '@core/lib/dayjs';
import {capitalize} from '@core/lib/filters';
import {exists} from '@core/lib/utils';
import {
  CreateExportInstanceInput,
  CreateExportInstanceMutationFn,
  ExportJobStatusStatus,
  FetchedExportTypes,
  FetchedSavedExports,
  SavedExportObject,
  SavedExportsDocument,
  SavedExportsQuery,
} from '@analytics/graphql-api';
import {
  ReportsFormData,
  _ExportInstanceParams,
  reportsFormDefaultValues,
} from './ReportsFormOverlay/helpers';

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const existsWithValue = (value: any) =>
  Array.isArray(value) ? value.length > 0 : exists(value);

// Remove dashes and capitalize each word
export const fmtExportType = (exportType?: string) =>
  (exportType ?? '').split('_').map(capitalize).join(' ');

export const reportFailedOrInProgress = ({
  savedExport,
}: {
  savedExport: FetchedSavedExports[number];
}) => ({
  failed:
    savedExport?.lastExportInstance?.status === 'failed' ||
    savedExport?.lastExportInstance?.exportJobStatus?.status ===
      ExportJobStatusStatus.Failed,
  inProgress:
    savedExport?.lastExportInstance?.status === 'requested' ||
    savedExport?.lastExportInstance?.exportJobStatus?.status ===
      ExportJobStatusStatus.Received ||
    savedExport?.lastExportInstance?.exportJobStatus?.status ===
      ExportJobStatusStatus.Started,
});

type LegacyStatus = 'received' | 'requested' | 'complete' | 'failed';

export const getReportStatus = ({
  exportInstance,
}: {
  exportInstance: {
    exportJobStatus?: {status: ExportJobStatusStatus};
    status?: ExportJobStatusStatus | LegacyStatus;
  };
}) => {
  const status =
    exportInstance?.exportJobStatus?.status ?? exportInstance?.status;

  switch (status) {
    case ExportJobStatusStatus.Received:
    case ExportJobStatusStatus.Started:
    case 'received':
    case 'requested':
      return {
        color: 'default',
        text: 'IN PROGRESS',
        variant: 'outlined',
      } as const;
    case ExportJobStatusStatus.Completed:
    case 'complete':
      return {
        color: 'secondary',
        text: 'READY',
        variant: 'filled',
      } as const;
    case ExportJobStatusStatus.Failed:
    case 'failed':
      return {
        color: 'error',
        text: 'FAILED',
        variant: 'outlined',
      } as const;
    default:
      return undefined as never;
  }
};

type ExportParametersTypeMap = {
  pk: string;
  actions?: string;
  after?: string;
  attr?: string;
  before?: string;
  campaign_ids?: string;
  feed_ids?: string;
  interval?: string;
  modelled?: string;
  publisher_ids?: string;
  spend_by_impressions?: string;
  trailing?: string;
};

const generateExportApiParams = ({params}: {params: _ExportInstanceParams}) => {
  return (Object.keys(params) as Array<keyof _ExportInstanceParams>).reduce(
    (export_acc, export_key) => {
      if (existsWithValue(params[export_key])) {
        switch (export_key) {
          case 'actions':
            return {
              ...export_acc,
              [camelToSnakeCase(export_key)]: params[export_key]?.join(','),
            };
          case 'instanceId':
            return {
              ...export_acc,
              pk: params[export_key] as string,
            };
          case 'reportPeriod': {
            const today = dayjs().format('YYYY-MM-DD');
            return {
              ...export_acc,
              before: today,
              after: dayjs(today)
                .subtract(params[export_key] as number, 'd')
                .format('YYYY-MM-DD'),
            };
          }
          case 'startDate': {
            return {
              ...export_acc,
              after: params[export_key]?.toString(),
            };
          }
          case 'endDate': {
            return {
              ...export_acc,
              before: params[export_key]?.toString(),
            };
          }
          default:
            return {
              ...export_acc,
              [camelToSnakeCase(export_key)]: params[export_key]?.toString(),
            };
        }
      }
      return export_acc;
    },
    {} as ExportParametersTypeMap
  );
};

const reportTypes = {
  organization_weekly_overview: 'weekly',
  organization_daily_overview: 'daily',
  organization_campaign_conversions: 'conversions',
  organization_list_all_ids: 'orgs',
  organization_list_campaign_ids: 'campaigns',
  campaign_daily_overview: 'daily',
} as const;

type ReportTypeMap = {
  kind: string;
  object_type: string;
  organization_id: string;
  fields?: string;
  order_by?: string;
} & ExportParametersTypeMap;

export const generateReportApiParams = ({
  savedExport,
}: {
  savedExport: FetchedSavedExports[number];
}) => {
  const values = reportsFormDefaultValues({savedExport});

  if (values) {
    return (
      Object.keys(values) as Array<keyof Omit<ReportsFormData, 'networkError'>>
    ).reduce(
      (acc, key) => {
        if (existsWithValue(values[key])) {
          switch (key) {
            case 'typeId': {
              const index = values[key].indexOf('_');
              return {
                ...acc,
                object_type: values[key].slice(0, values[key].indexOf('_')),
                kind:
                  reportTypes[values[key]] ??
                  values[key].slice(typeof index === 'number' ? index + 1 : 0),
              };
            }
            case 'fields':
              return {
                ...acc,
                [camelToSnakeCase(key)]: values[key]
                  .map(({id}) => id)
                  .join(','),
              };
            case 'orderBy':
              return {
                ...acc,
                [camelToSnakeCase(key)]: values[key].join(','),
              };
            case 'exportParameters':
              return {
                ...acc,
                ...generateExportApiParams({params: values[key]}),
              };
            default:
              return acc;
          }
        }

        return acc;
      },
      {
        organization_id: savedExport?.organization?.id,
      } as ReportTypeMap
    );
  }

  return undefined as never;
};

export const doCreateReportInstance = async ({
  createExportInstance,
  savedExport,
  onError,
  organizationId,
}: {
  createExportInstance: CreateExportInstanceMutationFn;
  savedExport: FetchedSavedExports[number];
  onError?: () => void;
  organizationId: string;
}) => {
  try {
    await createExportInstance({
      variables: {
        input: {
          savedExportId: savedExport?.id,
          organizationId,
          exportType: savedExport?.exportType.toLowerCase(),
        } as CreateExportInstanceInput,
      },
      update: (cache, {data}) => {
        const exportInstance = data?.createExportInstance?.exportInstance;
        if (exportInstance) {
          const query = {
            query: SavedExportsDocument,
            variables: {id: organizationId},
          };

          const response: SavedExportsQuery | null = cache.readQuery(query);

          if (response) {
            cache.writeQuery({
              ...query,
              data: {
                me: {
                  ...response?.me,
                  organization: {
                    ...response?.me?.organization,
                    exports: {
                      ...response?.me?.organization?.exports,
                      savedExports: (
                        response?.me?.organization?.exports?.savedExports ?? []
                      ).map((_export) => ({
                        ..._export,
                        ...(_export?.id === savedExport?.id
                          ? {
                              lastExportInstance: {
                                ...exportInstance,
                              },
                            }
                          : {}),
                      })),
                    },
                  },
                },
              },
            });
          }
        }
      },
    });
  } catch (e) {
    console.warn(e.message);
    onError?.();
  }
};

export const isExportDisabled = (
  savedExport: FetchedSavedExports[number],
  exportTypes: FetchedExportTypes
) =>
  !(exportTypes ?? []).find(
    ({id}) => id === savedExport?.exportType.toLowerCase()
  );
