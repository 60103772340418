import React, {FC} from 'react';
import dayjs from '@core/lib/dayjs';
import useQueryParams from '@core/lib/useQueryParams';
import {DateRangeInput} from '@core/ui/DatePicker';

interface InsightsDatePickerProps {
  defaultValue?: {after: string; before: string};
  isOutsideRange?: (_date: dayjs.Dayjs) => boolean;
  onChange?: (_props: {startDate: dayjs.Dayjs; endDate: dayjs.Dayjs}) => void;
}

const InsightsDatePicker: FC<InsightsDatePickerProps> = ({
  defaultValue,
  isOutsideRange,
  onChange,
}): JSX.Element => {
  const {
    params: {after, before},
    set,
  } = useQueryParams(['after', 'before'], defaultValue ? defaultValue : null);

  return (
    <DateRangeInput
      onDatesChange={({startDate, endDate}) => {
        if (startDate && endDate) {
          set('after', startDate.format('YYYY-MM-DD'));
          set('before', endDate.format('YYYY-MM-DD'));
          if (onChange) {
            onChange({startDate, endDate});
          }
        }
      }}
      startDate={dayjs(after)}
      endDate={dayjs(before)}
      isOutsideRange={
        isOutsideRange ? isOutsideRange : (date) => date.isAfter(dayjs())
      }
    />
  );
};

export default InsightsDatePicker;
