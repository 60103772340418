import {useEffect, useMemo, useState} from 'react';
import {useQuery} from '@apollo/client';
import styled from 'styled-components';
import {capitalize} from '@core/lib/filters';
import {Element} from '@core/style';
import {getColor} from '@core/style';
import EmptyMessage from '@core/ui/EmptyMessage';
import {LoadingSpinner} from '@core/ui/Loading';
import {
  FetchedAdvisorIndustries,
  FetchedAdvisorShow,
  FetchedBuySideOrganization,
  FetchedOrganization,
  PublisherAffinityScoreObject,
  usePublisherAffinityScoresQuery,
} from '@analytics/graphql-api';
import ADVISOR_PODCAST_INDUSTRIES_QUERY from '@analytics/graphql-api/_old_queries/advisor/AdvisorPodcastIndustriesQuery';
import AdvisorPodcastIndustriesChart from './AdvisorPodcastIndustriesChart';
import {PublisherAffinityTable} from './AdvisorPublisherAffinityTable';

const fmtIndustry = (str: string) =>
  str
    .split('-')
    .map((s) => (s !== 'and' && s !== 'or' ? capitalize(s) : s))
    .join(' ');

const TitleBadge = styled.div`
  padding: 8px 11px;
`;

const AdvisorPodcastIndustriesImpl = ({
  industries,
  affinityData,
}: {
  industries: FetchedAdvisorIndustries;
  affinityData: PublisherAffinityScoreObject[];
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const industriesData = useMemo(
    () =>
      industries
        .filter(({adCount}) => adCount > 0)
        .map((ad, i) => ({
          name: ad.industry === 'N/A' ? ad.industry : fmtIndustry(ad.industry),
          value: ad.adCount,
          percent: ad.percent,
          fill: ad.industry === 'N/A' ? 'var(--gray-5)' : getColor(i),
          total: ad.totalAds,
        })),
    [industries]
  );

  useEffect(() => {
    if (
      industriesData[activeIndex]?.name === 'N/A' &&
      industriesData.length > 1
    ) {
      setActiveIndex(activeIndex + 1);
    }
  }, [activeIndex, industriesData]);

  if (!industriesData.length) {
    return (
      <EmptyMessage css={{padding: '2.5rem'}}>No industries found</EmptyMessage>
    );
  }

  return (
    <Element
      rules={({theme}) => ({
        alignItems: 'center',
        background: theme.borderPrimary,
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '1px',
        justifyItems: 'center',
        minHeight: '20rem',
      })}>
      <Element
        rules={({theme}) => ({
          background: theme.bgPrimary,
          borderTopLefttRadius: 'inherit',
          padding: '0 1rem',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        })}>
        <TitleBadge>Industry Ranking</TitleBadge>
        <PublisherAffinityTable affinityData={affinityData} />
      </Element>
      <Element
        rules={({theme}) => ({
          alignItems: 'center',
          background: theme.bgPrimary,
          borderTopRightRadius: 'inherit',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          width: '100%',
        })}>
        <AdvisorPodcastIndustriesChart
          industries={industriesData}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      </Element>
    </Element>
  );
};

const AdvisorPodcastIndustries = ({
  organization,
  podcast,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  podcast: FetchedAdvisorShow;
}) => {
  const {data, loading, error} = useQuery(ADVISOR_PODCAST_INDUSTRIES_QUERY, {
    variables: {
      organizationId: organization.id,
      id: podcast!.id,
    },
  });

  const {
    loading: affinityScoreLoading,
    data: rawAffinityData,
    error: affinityError,
  } = usePublisherAffinityScoresQuery({
    variables: {
      orgId: organization.id,
      feedId: podcast!.id,
    },
  });

  if (loading || affinityScoreLoading)
    return (
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          padding: '2.5rem',
        }}>
        <LoadingSpinner />
      </div>
    );

  if (error || affinityError) {
    return (
      <EmptyMessage css={{padding: '2.5rem'}}>No industries found</EmptyMessage>
    );
  }
  const affinityData =
    rawAffinityData?.me?.organization?.advisor?.publisherAffinityScores ?? [];

  return (
    <AdvisorPodcastIndustriesImpl
      industries={
        data?.me?.organization?.advisor?.show?.industriesOverview ?? []
      }
      affinityData={affinityData}
    />
  );
};

export default AdvisorPodcastIndustries;
