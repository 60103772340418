import {Controller} from 'react-hook-form';
import {Label} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import Select from '@core/ui/Select';
import {FieldProps} from '../helpers';

export function IntervalField({control, watch}: FieldProps) {
  return (
    <Stack direction='column'>
      <Label htmlFor='interval'>Interval</Label>
      <Controller
        control={control}
        name='exportParameters.interval'
        render={({field}) => (
          <Select
            key={watch('typeId')}
            buttonProps={{id: 'interval'}}
            defaultValue={field.value ?? undefined}
            items={[
              {title: 'Daily', value: 'day'},
              {title: 'Weekly', value: 'week'},
              {title: 'Monthly', value: 'month'},
            ]}
            outline
            onSelect={({value}) => field.onChange(value)}
            placeholder='Interval'
            rules={() => ({padding: '0.675rem 1rem'})}
          />
        )}
      />
    </Stack>
  );
}
