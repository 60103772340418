import React from 'react';
import {createOrgPath} from '@core/lib/organizations';
import {EpisodeImage, FeedImage} from '@core/ui/Image';
import {Nav, NavItem} from '@core/ui/Nav';
import {podcastURL as researchPodcastURL} from '@analytics/components/nav/ResearchNav';

const podcastURL = (organization, podcast) =>
  createOrgPath(organization, `/podcasts/${podcast.id}`);

const podcastEpisodeURL = (organization, podcast, episode) =>
  createOrgPath(organization, `/podcasts/${podcast.id}/episodes/${episode.id}`);

const PodcastEpisodeNav = ({organization, podcast, episode}) => {
  const url = podcastEpisodeURL(organization, podcast, episode);

  return (
    <Nav
      title={episode.title}
      description={episode.description}
      Image={() => <EpisodeImage episode={episode} width={150} />}
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/podcasts'),
          title: 'Podcasts',
        },
        {
          to: podcastURL(organization, podcast),
          title: podcast.feed.title,
        },
        {
          title: 'Episode',
        },
      ]}>
      <NavItem to={url}>Overview</NavItem>
    </Nav>
  );
};

const PodcastNav = ({organization, podcast, user}) => {
  const url = podcastURL(organization, podcast);
  const {feed} = podcast;
  const {isSegmentsEnabled} = organization;

  return (
    <Nav
      title={feed.title}
      description={feed.description}
      Image={() => <FeedImage feed={feed} width={150} />}
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/podcasts'),
          title: 'Podcasts',
        },
      ]}
      moreDropdownItems={[
        {
          to: researchPodcastURL(organization, feed),
          title: 'View in Research',
        },
      ]}
      css={{position: 'relative'}}>
      <NavItem to={`${url}`}>Overview</NavItem>
      {(user.isAdmin || isSegmentsEnabled) && (
        <NavItem to={`${url}/audience`}>Audience</NavItem>
      )}
    </Nav>
  );
};

export {podcastURL, podcastEpisodeURL, PodcastNav, PodcastEpisodeNav};
