import {useHistory} from 'react-router-dom';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {
  advisorOnboardBrandURL,
  advisorURL,
} from '@analytics/lib/routes/useAdvisorRoutes';

export function useNavigateToAdvisor(
  organization: FetchedOrganization | FetchedBuySideOrganization
) {
  const history = useHistory();

  return (workspaceId?: any) =>
    history.push(
      `${advisorURL(organization)}${
        typeof workspaceId === 'string' ? `?workspace=${workspaceId}` : ''
      }`
    );
}

export function useNavigateToQuiz(
  organization: FetchedOrganization | FetchedBuySideOrganization
) {
  const history = useHistory();

  return () => history.push(`${advisorOnboardBrandURL(organization)}/quiz`);
}

export function useNavigateToReview(
  organization: FetchedOrganization | FetchedBuySideOrganization
) {
  const history = useHistory();

  return () => history.push(`${advisorOnboardBrandURL(organization)}/review`);
}
