import {DocumentNode} from 'graphql';
import MultipleDataSelector from '@core/ui/MultipleDataSelector';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';

interface Props {
  label: string;
  query: DocumentNode;
  defaultValues: {id: string; value: string}[];
  onChange: (_: {id: string; value: string}) => void;
  organization: FetchedOrganization | FetchedBuySideOrganization;
  selectDataFromResponse: (_resp: any) => any;
}

export default function AdvisorSelectFilter({
  label,
  query,
  defaultValues,
  onChange,
  organization,
  selectDataFromResponse,
}: Props) {
  return (
    <MultipleDataSelector
      dataQuery={query}
      placeholder={`Search for ${label}...`}
      queryVariables={{organizationId: organization.id}}
      propertyForName='title'
      fromResponse={selectDataFromResponse}
      onChange={onChange}
      defaultValues={defaultValues}
      autofocus
      renderItem={undefined}
      rules={[]}
    />
  );
}
