import {useEffect, useReducer, useState} from 'react';
import {useMutation} from '@apollo/client';
import Loading from '@core/ui/Loading';
import UpdatePodlinkPodcast from '@analytics/graphql-api/_old_mutations/UpdatePodlinkPodcast';
import {PODLINK_HOST, getPodlinkData} from '@analytics/lib/podlink';
import DesignContent, {DEFAULT_COLORS} from './DesignContent';

const slugify = (value) => {
  return value
    .replace(/[^a-zA-Z\d\s]/g, '')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase();
};

const DesignPodcast = ({organization, podcast, onSave, onCancel}) => {
  const [loading, setLoading] = useState(true);
  const [updatePodlinkPodcast] = useMutation(UpdatePodlinkPodcast);

  const {
    feed: {title, href},
  } = podcast;

  // config
  const [config, setConfig] = useReducer(
    (state, newState) => {
      return {...state, ...newState};
    },
    {
      id: null,
      href,
      slug: slugify(title),
      ...DEFAULT_COLORS,
    }
  );

  useEffect(() => {
    const getPodlinkConfig = async () => {
      const data = await getPodlinkData(href);
      const update = [
        'slug',
        'backgroundColor',
        'fontColor',
        'primaryColor',
        'secondaryColor',
      ].reduce((u, attr) => {
        if (data.opts.hasOwnProperty(attr) && data.opts[attr]) {
          u[attr] = data.opts[attr];
        }
        return u;
      }, {});

      update.id = data.id;

      setConfig(update);
      setLoading(false);
    };

    getPodlinkConfig();
  }, []);

  const _onSave = async () => {
    // This will call podlink.
    setLoading(true);

    const {id, slug, backgroundColor, fontColor, primaryColor, secondaryColor} =
      config;

    const {
      data: {
        updatePodlinkPodcast: {success},
      },
    } = await updatePodlinkPodcast({
      variables: {
        input: {
          id,
          podcastId: podcast.id,
          slug,
          backgroundColor,
          fontColor,
          primaryColor,
          secondaryColor,
        },
      },
    });

    setLoading(false);
    if (success) {
      onSave();
    }
  };

  if (loading) {
    return <Loading fixed />;
  }

  return (
    <DesignContent
      previewURL={`${PODLINK_HOST}/${config.id}`}
      organization={organization}
      kind='podcast'
      onSave={_onSave}
      config={config}
      setConfig={setConfig}
      onCancel={onCancel}
    />
  );
};

export default DesignPodcast;
