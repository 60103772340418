import {format} from 'd3-format';
import {TooltipProps} from 'recharts';
import styled from 'styled-components';
import dayjs from '@core/lib/dayjs';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';

export function UsageTooltip({
  active,
  payload,
  isCumulative,
  isMonthly,
}: TooltipProps<string, number> & {
  isCumulative: boolean;
  isMonthly: boolean;
}) {
  if (!active || !payload || !payload.length) return null;

  const {x, y} = payload[0].payload;
  const displayDate = dayjs(x).format(isMonthly ? 'MMM YYYY' : 'MMM D, YYYY');

  return (
    <Tooltip>
      <Text color='tooltip' variant='subtitle2'>
        {displayDate}
      </Text>
      <Text color='tooltip' variant='h3'>
        {format(',')(y)}
      </Text>
      <Text color='tooltip' variant='body3'>
        {isCumulative ? 'Impressions to date' : 'Total impressions'}
      </Text>
    </Tooltip>
  );
}

const Tooltip = styled(Stack)`
  background: var(--bg-tooltip);
  border-radius: var(--radius-1);
  box-shadow: var(--shadow-xl);
  flex-direction: column;
  padding: var(--spacing-2);
`;
