import React from 'react';
import {TRules} from '@core/style';
import {Element} from '@core/style';

const keyframes = {
  shimmer: () => ({
    '100%': {
      transform: 'translateX(100%)',
    },
  }),
};

const SkeletonLoading = ({rules}: {rules: TRules}): JSX.Element => {
  return (
    <Element
      keyframes={keyframes}
      rules={[
        ({theme, shimmer}) => ({
          background:
            theme.name === 'light' ? 'var(--gray-2)' : 'var(--bg-muted)',
          overflow: 'hidden',
          position: 'relative',
          '::after': {
            animation: `${shimmer} 1.75s infinite`,
            background:
              theme.name === 'light'
                ? `linear-gradient(90deg, rgba(250, 250 , 250, 0) 0, rgba(250, 250 , 250, 0.3) 20%, rgba(250, 250 , 250, 0.7) 60%, rgba(250, 250 , 250, 0))`
                : `linear-gradient(90deg, rgba(0, 0 , 0, 0) 0, rgba(0, 0 , 0, 0.5) 20%, rgba(0, 0 , 0, 1) 60%, rgba(0, 0 , 0, 0))`,
            bottom: 0,
            content: '""',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            transform: 'translateX(-100%)',
          },
        }),
        rules,
      ]}
    />
  );
};

export default SkeletonLoading;
