import {PropsWithChildren, ReactNode} from 'react';
import styled from 'styled-components';
import {FetchedUser} from '@core/graphql-api';
import {Icon} from '@core/ui/Icon';
import {breakpoints} from '@core/ui/ResponsiveContent';
import {SettingsContextValue} from '@analytics/components/settings/SettingsContext';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import IntroBottomSheet from '../intro-bottom-sheet/IntroBottomSheet';
import IntroModal from '../intro-modal/IntroModal';
import useSettings from '../settings/useSettings';
import LayoutLeftNav from './LayoutLeftNav';
import LayoutToolbar from './LayoutToolbar';

type Props = PropsWithChildren<{
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
}>;

export default function Layout({
  children,
  organization,
  user,
}: Props): ReactNode {
  const [settings] = useSettings();
  const slug = organization.slug;

  if (settings.hideUi) {
    return children;
  }

  return (
    <>
      <ToolbarContainer>
        <LayoutToolbar user={user} organization={organization} />
      </ToolbarContainer>
      <LeftNavContainer>
        <LayoutLeftNav organization={organization} user={user} />
        <div
          css={`
            padding: 1rem 0 1rem 1rem;
          `}>
          <Icon
            icon='spotifyAdvertising'
            css={`
              margin-top: var(--spacing-6);
              width: 100%;
            `}
          />
        </div>
      </LeftNavContainer>
      <MainContent settings={settings}>{children}</MainContent>
      <IntroModal kind={organization.kind} slug={slug} />
      <IntroBottomSheet kind={organization.kind} slug={slug} />
    </>
  );
}

const TOOLBAR_HEIGHT = '4.5rem';

const ToolbarContainer = styled.div`
  height: ${TOOLBAR_HEIGHT};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
`;

const LeftNavContainer = styled.div`
  background: var(--bg-default);
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  top: ${TOOLBAR_HEIGHT};
  width: var(--app-left-nav-width);
  z-index: 1;
`;

const MainContent = styled.div<{
  settings: SettingsContextValue;
}>`
  min-height: 100vh;
  min-width: ${breakpoints.xlarge}px;
  padding-bottom: 1.5rem;
  ${({settings}: any) => settings.layoutNoBottomPadding && 'padding-bottom: 0'};
  padding-left: var(--app-left-nav-width);
  padding-top: ${TOOLBAR_HEIGHT};
  width: 100%;
`;
