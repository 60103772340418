import {Query} from '@apollo/client/react/components';
import ChartTime from '@core/components/ChartTime';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import {useDateFilter} from '@analytics/components/DateFilterDropdown';
import {FeedNav} from '@analytics/components/nav/ResearchNav';
import EpisodesTable from '@analytics/components/research/EpisodesTable';
import FeedDayRankingsQuery from '@analytics/graphql-api/_old_queries/research/FeedDayRankingsQuery';
import FeedEpisodesQuery from '@analytics/graphql-api/_old_queries/research/FeedEpisodesQuery';

export default function Feed({organization, feed}) {
  const [chartDateRange, DateFilterDropdown] = useDateFilter();
  const {processing} = feed;

  const variables = {
    id: feed.id,
    organizationId: organization.id,
  };

  return (
    <>
      <FeedNav organization={organization} feed={feed} />
      <Element
        rules={() => ({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          margin: '2rem 1.5rem 1rem',
        })}>
        <CardHeader rules={() => ({margin: 0})}>
          Apple podcasts top 200 rankings
        </CardHeader>
        {DateFilterDropdown}
      </Element>
      <Card>
        <Query
          query={FeedDayRankingsQuery}
          variables={Object.assign(variables, {
            after: chartDateRange.startDate,
          })}>
          {({loading, data}) => {
            if (loading) {
              return <Loading centered />;
            }
            const {dayRankings} = data.me.organization.research.feed;

            if (dayRankings.length) {
              const series = {
                ranking: dayRankings.map(({day, rank}) => ({
                  x: day,
                  y: rank,
                })),
              };
              return (
                <ChartTime
                  series={series}
                  interval='daily'
                  hideLegends
                  reversedY
                />
              );
            }
            return <EmptyMessage>No data found</EmptyMessage>;
          }}
        </Query>
      </Card>

      {!processing ? (
        <Card
          rules={({theme}) => ({
            background: theme.bgTertiary,
            marginTop: '2rem',
            padding: '1rem',
          })}>
          Ad Analytics is not processing this podcast. There will be no ads or
          waveforms related to the following episodes.
        </Card>
      ) : (
        ''
      )}

      <Card>
        <CardHeader>Episodes</CardHeader>
        <Query query={FeedEpisodesQuery} variables={variables}>
          {({loading, data}) => {
            if (loading) {
              return <Loading centered />;
            }
            const {episodes} = data.me.organization.research.feed;
            return (
              <EpisodesTable
                organization={organization}
                feed={feed}
                episodes={episodes}
              />
            );
          }}
        </Query>
      </Card>
    </>
  );
}
