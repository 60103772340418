import {gql} from '@apollo/client';

export default gql`
  query OrganizationGenericTags($id: ID!) {
    me {
      id
      organization(id: $id) {
        id
        genericTags {
          id
          title
          color
        }
      }
    }
  }
`;
