import Badge from '@core/ui/Badge';
import {SpotifyLiftState} from '@analytics/components/lift/types';

const BADGE_PROPS_MAP = {
  PENDING: {
    color: 'warning',
    variant: 'filled',
  },
  COMPLETE: {
    color: 'success',
    variant: 'filled',
  },
  DEFAULT: {
    color: 'default',
    variant: 'outlined',
  },
} as const;

export const SpotifyLiftStateBadge = ({state}: {state: SpotifyLiftState}) => (
  <Badge {...(BADGE_PROPS_MAP[state] || BADGE_PROPS_MAP.DEFAULT)}>
    {state}
  </Badge>
);
