import {comma, toPercent} from '@core/lib/filters';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';

const longPercent = (v: number) => toPercent(v, 4);

const columns: DataTableColumn[] = [
  {
    title: 'Name',
    accessor: 'groupName',
    type: 'string',
  },
  {
    title: 'Households',
    accessor: 'groupHouseholds',
    type: 'number',
    fmt: comma,
  },

  {
    title: 'Total IPs',
    accessor: 'groupReach',
    type: 'number',
    fmt: comma,
  },

  {
    title: 'View Percentage',
    accessor: 'avgInitPercentage',
    type: 'number',
    fmt: longPercent,
  },
  {
    title: 'Lead Percentage',
    accessor: 'avgLeadPercentage',
    type: 'number',
    fmt: longPercent,
  },
  {
    title: 'Purchase Percentage',
    accessor: 'avgPurchasePercentage',
    type: 'number',
    fmt: longPercent,
  },
  {
    title: 'Install Percentage',
    accessor: 'avgInstalledPercentage',
    type: 'number',
    fmt: longPercent,
  },
];

export function LiftAveragesTable({data}) {
  return (
    <DataTable
      columns={columns}
      orderBy='-avgInitPercentage'
      data={data.map((d, idx: number) => Object.assign({id: idx}, d))}
    />
  );
}
