import {gql} from '@apollo/client';

export default gql`
  mutation removeMember($input: RemoveMemberInput!) {
    authed {
      success
    }
    removeMember(input: $input) {
      success
    }
  }
`;
