import React, {useState} from 'react';
import {useAddPixelAndClaimCompanyMutation} from '@admin/graphql-api';
import {useMutation} from '@apollo/client';
import {
  ButtonPrimary,
  ButtonTertiary,
  CollapseButton,
  FormInput,
  IconInformationAlt,
  ProgressCircle,
  TextLink,
  Tooltip,
  TooltipTrigger,
  Type,
} from '@spotify-internal/encore-web';
import {Controller, useForm} from 'react-hook-form';
import CompanySelect from '@core/components/CompanySelect';
import IndustrySelect from '@core/components/IndustrySelect';
import {isSelling} from '@core/lib/organizations';
import {getRoles} from '@core/lib/roles';
import {Card, Separator} from '@core/ui/Content';
import {
  FORM_ERRORS,
  Form,
  FormFooter,
  FormGroup,
  FormHelp,
  Label,
  LabelInput,
  LabelSelect,
} from '@core/ui/FormElements';
import Toggle from '@core/ui/Toggle';
import {InfoTooltip} from '@core/ui/Tooltip';
import PixelMethodMultipleSelect from '@analytics/components/pixels/PixelMethodMultipleSelect';
import ADD_MEMBER from '@analytics/graphql-api/_old_mutations/AddMember';
import ORGANIZATION_QUERY from '@analytics/graphql-api/_old_queries/OrganizationQuery';
import {FormContainer} from '../onboard/OnboardLayout';

const INSTALLERS = [
  {title: 'Me', value: 'me', description: 'Show me the code'},
  {
    title: 'A member of my organization',
    value: 'member',
    description: 'Invite a teammate to Ad Analytics',
  },
  // TODO: Add once permissions set for this role
  // {
  //   title: 'An external developer',
  //   value: 'external',
  //   description: 'Share the documentation with an external party',
  // },
];

interface IPixelBrandOnboardingFormProps {
  organization: {
    id: string;
    kind: string;
  };
  user: {
    email: string;
  };
}

interface IFormValues {
  alias: string;
  domain: string;
  email: string;
  firstName: string;
  hasAlias: boolean;
  installer: string;
  lastName: string;
  installations: string;
  policy: boolean;
  role: string;
  company: {
    companyName: string;
    companyId: string;
  };
  industry: string;
}

const PixelOnboardForm = ({
  organization,
}: IPixelBrandOnboardingFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showBrandTooltip, setShowBrandTooltip] = useState(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [showWebsiteTooltip, setShowWebsiteTooltip] = useState<boolean>(false);
  const [showAliasDescription, setShowAliasDescription] =
    useState<boolean>(false);
  const [addMember] = useMutation(ADD_MEMBER);
  const [addPixelAndClaimCompany] = useAddPixelAndClaimCompanyMutation({
    refetchQueries: [ORGANIZATION_QUERY],
    awaitRefetchQueries: true,
  });

  const {
    control,
    clearErrors,
    handleSubmit,
    setError,
    formState: {errors},
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      alias: '',
      domain: '',
      email: '',
      firstName: '',
      hasAlias: false,
      installer: '',
      lastName: '',
      installations: 'JAVASCRIPT',
      policy: false,
      role: '',
    },
  });

  const onSubmit = async ({
    alias,
    domain,
    email,
    firstName,
    hasAlias,
    installer,
    installations,
    lastName,
    role,
    company: {companyName, companyId},
    industry,
  }: IFormValues) => {
    setLoading(true);
    try {
      let memberResp;
      if (installer !== 'me') {
        memberResp = await addMember({
          variables: {
            input: {
              firstName,
              lastName,
              role,
              email,
              organizationId: organization.id,
              host: window.location.origin,
            },
          },
        });
      } else {
        memberResp = true;
      }
      const pixelResp = await addPixelAndClaimCompany({
        variables: {
          input: {
            domain,
            role: 'production',
            organizationId: organization.id,
            alias: hasAlias ? alias : '',
            installations,
            companyName,
            companyId,
            industry,
          },
        },
      });
      if (pixelResp && memberResp) {
        setSubmitted(true);
      }
    } catch (err) {
      setError(null, FORM_ERRORS.network);
    }
    setLoading(false);
  };

  const {hasAlias, installer, industry} = watch();

  return (
    <FormContainer>
      <Type
        variant='alto'
        as='h1'
        style={{textAlign: 'center', marginBottom: 24, marginTop: 70}}>
        Create a Spotify Pixel
      </Type>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginBottom: 48,
        }}>
        <Type
          variant='ballad'
          semanticColor='textSubdued'
          style={{marginBottom: 16}}>
          A Spotify Pixel is a small piece of JavaScript code that connects your
          ads on and off Spotify to actions or conversion events taken on your
          website. Pixels can help you better understand your ad attribution and
          measure the success of your Spotify campaigns.{' '}
          <TextLink
            href='https://help.adanalytics.spotify.com/about-the-spotify-pixel'
            target='_blank'>
            Learn more
          </TextLink>
        </Type>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
        <Card rules={() => ({margin: 0})}>
          {isSelling(organization) && (
            <p>
              If you do not have a website for your podcast, enter your
              podcast's name. A pixel is required for Campaign Links.
            </p>
          )}
          <FormGroup>
            <Label>
              Brand Name
              <TooltipTrigger
                overlay={
                  showBrandTooltip && (
                    <Tooltip id='notifications'>
                      This is the brand associated with the website we will
                      measure. The brand name can be the same as the
                      organization listed on the previous page or different.
                    </Tooltip>
                  )
                }
                placement={'end'}
                onClick={() => {}}
                onShow={() => setShowBrandTooltip(true)}
                onHide={() => setShowBrandTooltip(false)}>
                <ButtonTertiary
                  aria-labelledby='notifications'
                  iconOnly={IconInformationAlt}
                  size='small'
                  condensed
                  type='button'
                  style={{
                    marginLeft: '5px',
                  }}
                />
              </TooltipTrigger>
            </Label>
            <Controller
              name='company'
              control={control}
              rules={{
                validate: (value) => !!value || 'Please select a brand.',
              }}
              render={({field}) => (
                <CompanySelect
                  invalid={!!errors.company}
                  small={false}
                  onSelect={({id, name, industry}) => {
                    setValue('industry', industry);
                    field.onChange({
                      companyId: id,
                      companyName: name,
                    });
                  }}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label>Brand Industry</Label>
            <Controller
              name='industry'
              control={control}
              rules={{
                validate: (value) => !!value || 'Please select an industry.',
              }}
              render={({field}) => (
                <IndustrySelect
                  key={industry}
                  defaultValue={industry}
                  invalid={!!errors.industry}
                  onSelect={({industry}) => field.onChange(industry)}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Website URL{' '}
              <TooltipTrigger
                overlay={
                  showWebsiteTooltip && (
                    <Tooltip id='website'>
                      Your brand should be associated with a single website
                      domain.
                    </Tooltip>
                  )
                }
                placement={'end'}
                onClick={() => {}}
                onShow={() => setShowWebsiteTooltip(true)}
                onHide={() => setShowWebsiteTooltip(false)}>
                <ButtonTertiary
                  aria-labelledby='notifications'
                  iconOnly={IconInformationAlt}
                  size='small'
                  condensed
                  type='button'
                  style={{
                    marginLeft: '5px',
                  }}
                />
              </TooltipTrigger>
            </Label>
            <Controller
              name='domain'
              control={control}
              rules={{required: 'Please enter a website URL.'}}
              render={({field}) => (
                <FormInput
                  label={'Website URL'}
                  {...field}
                  placeholder='(e.g. www.example.com)'
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              <CollapseButton
                style={{marginLeft: -6}}
                expanded={showAliasDescription}
                onClick={() => setShowAliasDescription((prev) => !prev)}
                type='button'>
                Alias users
              </CollapseButton>
            </Label>
            {showAliasDescription && (
              <FormHelp>
                <Type variant='ballad' semanticColor='textSubdued'>
                  For advanced users, you can optionally pass us an internal
                  user ID along with each event, which we can pass back to you
                  when reporting on conversions. You can then match back the
                  conversions we atribute with your internal systems.
                </Type>
              </FormHelp>
            )}
            {hasAlias && (
              <>
                <Controller
                  name='alias'
                  control={control}
                  render={({field}) => (
                    <div css={{position: 'relative'}}>
                      <LabelInput
                        invalid={!!errors.alias}
                        label='Alias ID'
                        rules={() => ({marginTop: '0.5rem'})}
                        {...field}
                      />
                      <InfoTooltip
                        description="This field should be a variable - the value will
                        be different each time it's called. If you're not sure about
                        this field, check with your developer."
                        iconSize={14}
                        rules={() => ({
                          position: 'absolute',
                          top: '1.5rem',
                          right: '1rem',
                        })}
                      />
                    </div>
                  )}
                />
              </>
            )}
            <Controller
              name='hasAlias'
              control={control}
              render={({field}) => (
                <div style={{display: 'flex', alignItems: 'flex-end', gap: 8}}>
                  <Toggle
                    onChecked={(value) => {
                      field.onChange(value);
                      if (!value) clearErrors('alias');
                    }}
                    style={{marginTop: '0.725rem'}}
                  />{' '}
                  {field.value !== true && (
                    <Type variant='ballad' semanticColor='textSubdued'>
                      off
                    </Type>
                  )}
                </div>
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label>Pixel installation method</Label>
            <Controller
              name='installations'
              control={control}
              rules={{
                validate: (value) =>
                  !!value
                    ? true
                    : 'Please select at least one installation method.',
              }}
              render={({field}) => (
                <PixelMethodMultipleSelect
                  defaultValue={[field.value]}
                  invalid={!!errors.installations}
                  onSelect={(items) =>
                    field.onChange(
                      items.map(({value}: {value: string}) => value).join(',')
                    )
                  }
                  rules={() => ({marginTop: '0.5rem'})}
                />
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label>Pixel Installer</Label>
            <Controller
              name='installer'
              control={control}
              rules={{
                validate: (value) =>
                  !!value ? true : 'Please select an installer.',
              }}
              render={({field}) => (
                <LabelSelect
                  label='Installer'
                  singleSelection
                  items={INSTALLERS}
                  rules={() => ({marginTop: '0.725rem'})}
                  onSelect={(item) => field.onChange(item.value)}
                  invalid={!!errors.installer}
                />
              )}
            />
          </FormGroup>
          {installer && installer !== 'me' && (
            <>
              <Separator />
              <Label>Invite Member</Label>
              <div
                css={{
                  display: 'grid',
                  gridGap: '1rem',
                  gridTemplateColumns: '1fr 1fr',
                  marginBottom: '1rem',
                }}>
                <Controller
                  name='firstName'
                  control={control}
                  rules={{required: 'Please provide a first name.'}}
                  render={({field}) => (
                    <LabelInput
                      type='string'
                      label='First Name'
                      invalid={!!errors.firstName}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='lastName'
                  control={control}
                  rules={{required: 'Please provide a last name.'}}
                  render={({field}) => (
                    <LabelInput
                      type='string'
                      label='Last Name'
                      invalid={!!errors.lastName}
                      {...field}
                    />
                  )}
                />
              </div>
              <Controller
                name='email'
                control={control}
                rules={{required: 'Please provide an email.'}}
                render={({field}) => (
                  <LabelInput
                    type='email'
                    label='Email'
                    invalid={!!errors.email}
                    {...field}
                    rules={() => ({marginBottom: '1rem'})}
                  />
                )}
              />
              {installer === 'member' ? (
                <Controller
                  name='role'
                  control={control}
                  rules={{
                    validate: (value) =>
                      !!value ? true : 'Please select a role.',
                  }}
                  render={({field}) => (
                    <LabelSelect
                      label='Role'
                      singleSelection
                      items={getRoles(organization.kind)}
                      invalid={!!errors.role}
                      onSelect={(item) => {
                        field.onChange(item.value);
                      }}
                      {...field}
                    />
                  )}
                />
              ) : (
                <LabelInput value='External Developer' label='Role' readOnly />
              )}
            </>
          )}
        </Card>

        <FormFooter>
          <ButtonPrimary disabled={loading} style={{minWidth: 300}}>
            Next
            {loading && <ProgressCircle style={{marginLeft: 20}} />}
          </ButtonPrimary>
        </FormFooter>
      </Form>
    </FormContainer>
  );
};

export default PixelOnboardForm;
