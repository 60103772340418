import {createOrgPath} from '@core/lib/organizations';
import {Nav} from '@core/ui/Nav';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {CampaignStreamingRouteProps} from '@analytics/screens/unified/campaigns/campaign/streaming/_routes';

export const campaignStreamingURL = (
  organization: CampaignStreamingRouteProps['organization'],
  campaign: CampaignStreamingRouteProps['campaign'],
  campaignStreaming: CampaignStreamingRouteProps['campaignStreaming']
) =>
  createOrgPath(
    organization,
    `/campaigns/${campaign.slug}/streaming/${campaignStreaming?.id}`
  );

export default function CampaignStreamingNav({
  organization,
  campaign,
  campaignStreaming,
}: CampaignStreamingRouteProps) {
  return (
    <Nav
      title={campaignStreaming?.name}
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/measure/campaigns'),
          title: 'Campaigns',
        },
        {
          to: toCampaignUrl(organization, campaign),
          title: `${campaign.name}`,
        },
        {
          title: 'Streaming Ad',
        },
      ]}
    />
  );
}
