import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignRouteProps} from '../_routes';
import CampaignTargetCreate from './CampaignTargetCreate';
import {CampaignTargets} from './CampaignTargets';
import CampaignTargetIndex from './target';

export function CampaignTargetsIndex({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/targets'
        campaign={campaign}
        user={user}
        organization={organization}
        component={CampaignTargets}
      />
      <PropsRoute
        exact
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/targets/add'
        campaign={campaign}
        user={user}
        organization={organization}
        component={CampaignTargetCreate}
      />
      <PropsRoute
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/targets/:campaignTargetId'
        campaign={campaign}
        user={user}
        organization={organization}
        component={CampaignTargetIndex}
      />
    </Switch>
  );
}
