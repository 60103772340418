import {gql} from '@apollo/client';

export default gql`
  query getAdvisorPodcastEpisodes($organizationId: ID!, $id: ID!, $limit: Int) {
    me {
      id
      organization(id: $organizationId) {
        id
        advisor {
          show(id: $id) {
            id

            feed {
              id
              episodes(limit: $limit) {
                id

                title
                description
                published
                placementsCount

                enclosure {
                  id
                  duration
                }

                image {
                  id
                  href
                  path
                }
              }
            }
          }
        }
      }
    }
  }
`;
