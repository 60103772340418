import {useState} from 'react';
import Button, {ButtonIcon} from '@core/ui/Button';
import {Overlay} from '@core/ui/Overlay';
import SettingsAppearance from './SettingsAppearance';
import SettingsDataFilters from './SettingsDataFilters';

const NAV_ITEMS = [
  {
    title: 'Data',
    iconName: 'filter',
    Component: SettingsDataFilters,
  },
  {
    title: 'Appearance',
    iconName: 'color-pallete',
    Component: SettingsAppearance,
  },
] as const;

const OverlayContent = ({toggle, user}) => {
  const [tab, setTab] = useState<(typeof NAV_ITEMS)[number]['title']>(
    NAV_ITEMS[0].title
  );
  const TabComponent = NAV_ITEMS.find(({title}) => title === tab)!.Component;

  return (
    <div
      css={{
        display: 'grid',
        flex: 1,
        gridTemplateColumns: '15.625rem 1fr',
        height: '100%',
        overflow: 'hidden',
      }}>
      <div
        css={{
          borderRight: '1px solid var(--border-default)',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div
          css={{
            fontSize: '1.5rem',
            fontWeight: 500,
            lineHeight: 1,
            margin: '1.5rem',
          }}>
          Settings
        </div>
        <div
          css={{
            margin: '0 0.875rem 22px',
            display: 'flex',
            flexDirection: 'column',
          }}>
          {NAV_ITEMS.map(({title, iconName}) => {
            const active = tab === title;

            return (
              <Button
                key={title}
                variant='unstyled'
                onClick={() => setTab(title)}
                iconLeading={iconName}
                css={{
                  alignItems: 'center',
                  borderRadius: '.5rem',
                  color: active ? 'var(--blue)' : 'inherit',
                  display: 'flex',
                  flexGrow: 1,
                  padding: '0.625rem',
                  textAlign: 'left',
                  transition: 'background .2s linear',
                  whiteSpace: 'nowrap',
                  ':hover': {
                    background: active ? '' : 'rgba(195, 205, 255, 0.25)',
                    color: 'var(--blue)',
                  },
                }}>
                {title}
              </Button>
            );
          })}
        </div>
      </div>
      <div css={{display: 'flex', flexDirection: 'column'}}>
        <div
          css={{
            alignItems: 'center',
            display: 'flex',
            height: '3.75rem',
            justifyContent: 'flex-end',
            padding: '0 1rem',
          }}>
          <ButtonIcon onClick={() => toggle(false)} icon='close' />
        </div>
        <div
          css={{
            flex: 1,
            height: '100%',
            overflowY: 'auto',
            padding: '0.75rem 1.5rem',
          }}>
          <TabComponent user={user} />
        </div>
      </div>
    </div>
  );
};

const SettingsOverlay = ({opened, toggle, user, ...props}) => {
  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      horizontalAlign='center'
      closeOnEscKey
      withBackdrop
      withShadow
      animation='scale-in'
      css={`
        border-radius: var(--radius-2);
        border: 0;
        inset: 6rem;
        max-height: 45rem;
        max-width: 60rem;
      `}
      {...props}>
      <OverlayContent toggle={toggle} user={user} />
    </Overlay>
  );
};

export default SettingsOverlay;
