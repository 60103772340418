import {createOrgPath} from '@core/lib/organizations';
import {FutureEpisodeImage} from '@core/ui/Image';
import {Nav} from '@core/ui/Nav';
import {FetchedCampaign, FetchedOrganization} from '@analytics/graphql-api';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {campaignPodcastURL} from './CampaignPodcastNav';

const campaignFutureEpisodeURL = (
  organization: FetchedOrganization,
  campaign: FetchedCampaign,
  campaignPodcast: TSFixMe,
  campaignEpisode: TSFixMe
): string =>
  createOrgPath(
    organization,
    `/campaigns/${campaign.slug}/podcasts/${campaignPodcast.id}/futures/${campaignEpisode.id}`
  );

const CampaignFutureEpisodeNav = ({
  organization,
  campaign,
  campaignPodcast,
  campaignEpisode,
}: {
  organization: FetchedOrganization;
  campaign: FetchedCampaign;
  campaignPodcast: TSFixMe;
  campaignEpisode: TSFixMe;
}) => {
  const feed = campaignPodcast?.feed;
  const futureEpisode = campaignEpisode?.futureEpisode;

  // When we update an episode, the rendering is such that it's possible not to
  // have a futureEpisode briefly
  if (!futureEpisode) {
    return <></>;
  }

  return (
    <Nav
      title={futureEpisode.title}
      description={campaign.name}
      Image={() => (
        <FutureEpisodeImage
          feed={feed}
          width={150}
          expectedPublished={futureEpisode.expectedPublished}
        />
      )}
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/measure/campaigns'),
          title: 'Campaigns',
        },
        {
          to: toCampaignUrl(organization, campaign),
          title: `${campaign.name}`,
        },
        {
          to: campaignPodcastURL(organization, campaign, campaignPodcast),
          title: `${campaignPodcast.feed.title}`,
        },
        {
          title: 'Upcoming Episode',
        },
      ]}
    />
  );
};

export {campaignFutureEpisodeURL};

export default CampaignFutureEpisodeNav;
