import dayjs from '@core/lib/dayjs';
import {ICampaignEpisode} from '@analytics/graphql-api/_old_queries/OrganizationCampaignEpisodesQuery';

const logBase30 = (v: number) => Math.log(v) / Math.log(30);

const getDates = (
  episodes: ICampaignEpisode[],
  endDate: dayjs.Dayjs,
  interval: string
) => {
  let min: dayjs.Dayjs;
  const max = endDate.clone();

  episodes.forEach(({futureEpisode: {expectedPublished}}) => {
    const date = dayjs(expectedPublished).startOf('d').add(1, 'd');
    if (!min || date.isBefore(min)) min = date;
  });

  let day = min.clone();
  const dates: {
    x: string;
  }[] = [];

  while (day.isSameOrBefore(max)) {
    if (interval == 'daily') {
      dates.push({x: day.toString()});
      day = day.add(1, 'd').startOf('d');
    } else if (interval == 'weekly') {
      dates.push({x: day.toString()});
      day = day.add(1, 'w').startOf('d');
    }
  }

  return dates;
};

const useUpcomingEpisodes = (
  episodes: ICampaignEpisode[],
  endDate: dayjs.Dayjs,
  interval: string
) => {
  const chart = getDates(episodes, endDate, interval);
  const table: {
    feId: string;
    feed: object;
    goal: number;
    id: string;
    key: string;
    slug: string;
    start: string;
    title: string;
  }[] = [];

  const upcomingEpisodes = [...episodes].sort((a, b) =>
    dayjs(a.futureEpisode.expectedPublished).diff(
      dayjs(b.futureEpisode.expectedPublished)
    )
  );

  upcomingEpisodes.forEach(
    (
      {
        campaignPodcast: {
          id,
          campaign: {slug},
          feed,
        },
        futureEpisode,
        goal,
      },
      i
    ) => {
      table.push({
        feed,
        feId: futureEpisode.id,
        goal,
        id,
        key: `${i}`,
        slug,
        start: futureEpisode.expectedPublished,
        title: feed.title,
      });

      const start = dayjs(futureEpisode.expectedPublished)
        .startOf('d')
        .add(1, 'd');

      let curr = start.clone();
      let step = 10;
      // remaining downloads
      let r = goal;

      while (curr.isSameOrBefore(endDate)) {
        // downloads for current day
        const v = r - logBase30(step) * r;

        if (interval == 'daily') {
          // eslint-disable-next-line no-loop-func
          const day = chart.find(({x}) => dayjs(x).utc().isSame(curr));
          if (day && Math.round(v) > 10) day[i] = Math.round(v);
        } else if (interval == 'weekly') {
          const week = chart.find(
            // eslint-disable-next-line no-loop-func
            ({x}) =>
              curr.isSameOrAfter(dayjs(x)) &&
              curr.isBefore(dayjs(x).add(1, 'w').startOf('d'))
          );

          if (week && Math.round(v) > 10) {
            if (week[i]) week[i] += Math.round(v);
            else week[i] = Math.round(v);
          }
        }

        step += 0.15;
        r -= v;
        curr = curr.add(1, 'd').startOf('d');
      }
    }
  );

  return {chart, table};
};

export default useUpcomingEpisodes;
