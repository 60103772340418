import MultipleDataSelector from '@core/ui/MultipleDataSelector';
import {GetAdvisorQuizNielsenInterestsDocument} from '@analytics/graphql-api';
import {DropdownItem, Question, _QuestionHeader} from '../../shared';
import {getAdvisorColumnData} from '../helpers';
import {IQuestionProps} from '../types';

export const QuestionProductsServices = ({
  quizData: {nielsenInterests},
  updateQuizData,
  organization,
}: IQuestionProps) => (
  <Question>
    <_QuestionHeader>
      What products or services on your website are most popular?
    </_QuestionHeader>
    <MultipleDataSelector
      dataQuery={GetAdvisorQuizNielsenInterestsDocument}
      defaultValues={nielsenInterests}
      onChange={(items) => updateQuizData('nielsenInterests', items)}
      renderItem={(item) => <DropdownItem label={item.title} />}
      placeholder='Search product categories'
      queryVariables={{orgId: organization.id}}
      propertyForName='title'
      fromResponse={getAdvisorColumnData('nielsen', skipPrefix)}
    />
  </Question>
);

function skipPrefix(s: string) {
  return s.split(' - ').slice(1).join(' - ');
}
