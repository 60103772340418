import {Redirect, Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {createOrgPath} from '@core/lib/organizations';
import Loading from '@core/ui/Loading';
import {FetchedPodcast, useGetPodcastQuery} from '@analytics/graphql-api';
import {PodcastsRouteProps} from '..';
import {Podcast} from './Podcast';
import {PodcastEpisode} from './PodcastEpisode';
import AudienceIndex from './audience';
import NewIndex from './new';

export type PodcastRouteProps = PodcastsRouteProps & {
  podcast: FetchedPodcast;
};

export const PodcastIndex = ({
  organization,
  user,
  match: {
    params: {podcastId},
  },
}: PodcastsRouteProps & {
  match: {
    params: {
      podcastId: string;
    };
  };
}) => {
  const {data, loading, error} = useGetPodcastQuery({
    variables: {
      organizationId: organization.id,
      id: podcastId,
    },
  });

  if (loading) {
    return <Loading fixed />;
  }

  if (error) {
    return <Redirect to={`${createOrgPath(organization)}/podcasts`} />;
  }

  const podcast = data?.me?.organization.podcast;
  const isSegmentsEnabled = organization.isSegmentsEnabled;

  return (
    <>
      <Switch>
        <PropsRoute
          exact
          path='/:part(a|s|b|p)/:slug/podcasts/:podcastId'
          user={user}
          organization={organization}
          podcast={podcast}
          component={Podcast}
        />
        {(user.isAdmin || isSegmentsEnabled) && (
          <PropsRoute
            path='/:part(a|s|b|p)/:slug/podcasts/:podcastId/audience'
            user={user}
            organization={organization}
            podcast={podcast}
            component={AudienceIndex}
          />
        )}
        <PropsRoute
          path='/:part(a|s|b|p)/:slug/podcasts/:podcastId/new'
          user={user}
          organization={organization}
          podcast={podcast}
          component={NewIndex}
        />
        <PropsRoute
          path='/:part(a|s|b|p)/:slug/podcasts/:podcastId/episodes/:episodeId'
          user={user}
          organization={organization}
          podcast={podcast}
          component={PodcastEpisode}
        />
      </Switch>
    </>
  );
};
