import {gql} from '@apollo/client';

export default gql`
  query advisorOptionsCapabilitiesQuery($id: ID!) {
    me {
      id
      organization(id: $id) {
        id
        advisor {
          id
          options {
            id
            capabilities {
              id
              category
              title
            }
          }
        }

        capabilities {
          id
          category
          title
        }
      }
    }
  }
`;
