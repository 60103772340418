import {Card, CardHeader} from '@core/ui/Content';
import AdvisorPodcastBrandsTable from '@analytics/components/advisor/AdvisorPodcastBrandsTable';
import {AdvisorPodcastRouteProps} from './_routes';

export function AdvisorPodcastBrands({
  organization,
  podcast,
}: AdvisorPodcastRouteProps) {
  return (
    <Card>
      <CardHeader>Brands</CardHeader>
      <AdvisorPodcastBrandsTable
        organization={organization}
        podcast={podcast}
      />
    </Card>
  );
}
