import {useHistory} from 'react-router-dom';
import {Alert} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {BrandInviteForm} from '@analytics/components/forms/BrandInviteForm';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {LimitedCompany} from './_types';

export function CampaignCreateInviteBrand({
  brandExists = false,
  company,
  onCompletePath,
  organization,
}: {
  brandExists?: boolean;
  company: LimitedCompany | undefined;
  onCompletePath: string;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}) {
  const history = useHistory();

  if (brandExists)
    return (
      <Stack direction='column' gap='var(--spacing-2)'>
        <Text>
          <b>{company?.name}</b> is already a member of Ad Analytics.
        </Text>
        <Text>
          For security reasons, you cannot add members to this organization.
        </Text>
        <Stack justifyContent='end'>
          <Button color='primary' to={onCompletePath}>
            Continue
          </Button>
        </Stack>
      </Stack>
    );

  const onComplete = () => history.push(onCompletePath);

  return (
    <Stack direction='column' gap='var(--spacing-6)'>
      <Text>
        <b>{company?.name}</b> is not a member of Ad Analytics. Let's invite
        them to join.
      </Text>
      <Alert severity='info'>
        <b>Note</b>: Inviting members to Ad Analytics does not invite them to
        view this campaign immediately. Campaigns are created in 'Draft' mode,
        and the brand will only see the campaign once it's set live.
      </Alert>
      <BrandInviteForm
        organization={organization}
        companyId={company?.id}
        onComplete={onComplete}
      />
    </Stack>
  );
}
