import {gql} from '@apollo/client';

export default gql`
  query getCampaignFrequencyQuery($organizationId: ID!, $campaignId: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          impressions {
            frequency {
              max

              over {
                impressions
                reach
              }

              nodes {
                frequency
                total
              }
            }
          }
        }
      }
    }
  }
`;
