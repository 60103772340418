import {useMemo, useState} from 'react';
import searchFilter from '@core/lib/searchFilter';
import {Stack} from '@core/ui/Layout';
import {
  OrganizationObject,
  useGetAdvisorOverlapColumnGroupsQuery,
} from '@analytics/graphql-api';
import {AdvisorColumn} from '../types';
import {ColumnOptionsList} from './ColumnOptionsList';
import {LoadingOptions} from './LoadingOptions';
import {NoOptions} from './NoOptions';
import {columnGroupVariables, fromResponse} from './utils';

export interface AdvisorColumnsDropdownColumnsListProps {
  defaultActiveColumns?: AdvisorColumn[];
  columnGroupName?: string;
  queryImmediately?: boolean;
  onChange: (_columns: AdvisorColumn[]) => void;
  organization: OrganizationObject;
  searchValue?: string;
  sortable?: boolean;
}

export default function AdvisorColumnsDropdownColumnsList({
  defaultActiveColumns = [],
  columnGroupName,
  queryImmediately = false,
  onChange,
  organization,
  searchValue = '',
  sortable = false,
}: AdvisorColumnsDropdownColumnsListProps) {
  const [selections, setSelections] = useState(defaultActiveColumns);

  const {data, loading} = useGetAdvisorOverlapColumnGroupsQuery({
    variables: columnGroupVariables(
      organization.id,
      queryImmediately,
      searchValue,
      columnGroupName ? {[columnGroupName]: true} : {}
    ),
    skip: !queryImmediately && !searchValue,
    fetchPolicy: 'cache-first',
  });

  const columnOptions: AdvisorColumn[] = useMemo(() => {
    if (loading || !data) return [];
    const isAddedColumns = !columnGroupName;
    const cols = isAddedColumns
      ? selections
      : fromResponse({
          columnGroupName,
          data,
        })
          .slice()
          .sort((a, b) => a.title.localeCompare(b.title))
          .slice(0, 110);

    if (!queryImmediately) {
      return searchValue ? cols : selections;
    }

    return searchValue
      ? searchFilter({
          data: cols,
          query: searchValue,
          searchKeys: ['title'],
        })
      : cols;
  }, [
    columnGroupName,
    data,
    queryImmediately,
    searchValue,
    selections,
    loading,
  ]);

  return (
    <Stack direction='column' css={{height: '25rem', overflowY: 'auto'}}>
      {loading && <LoadingOptions />}

      {!loading && columnOptions.length > 0 && (
        <ColumnOptionsList
          setSelections={setSelections}
          sortable={sortable}
          onChange={onChange}
          columnOptions={columnOptions}
          searchValue={searchValue}
          selections={selections}
        />
      )}

      {!loading && columnOptions.length === 0 && (
        <NoOptions
          queryImmediately={queryImmediately}
          searchValue={searchValue}
        />
      )}
    </Stack>
  );
}
