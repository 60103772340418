import {Route, Switch} from 'react-router-dom';
import {UnAuthHome, UnAuthNoMatch} from '@core/app/Auth';
import Enter from '@core/app/Enter';
import RedirectTo from '@core/app/RedirectTo';
import SignInWithSpotify from '@core/components/SignInWithSpotify';
import AccountConfirm from './account/AccountConfirm';
import AccountMigration from './account/AccountMigration';
import RedirectScreen from './account/Redirect';
import Claim from './unified/Claim';

const UnAuthed = () => {
  return (
    <Switch>
      <Route exact path='/' component={UnAuthHome} />
      <Route
        exact
        path={['/signin', '/welcome']}
        component={(routeProps) => <SignInWithSpotify {...routeProps} />}
      />
      <Route exact path='/enter/:token' component={Enter} />
      <Route exact path='/account-migration' component={AccountMigration} />
      <Route exact path='/redirect' component={RedirectScreen} />
      <Route exact path='/to' component={RedirectTo} />
      <Route exact path='/claim/:payload' component={Claim} />
      <Route path='*' component={UnAuthNoMatch} />
    </Switch>
  );
};

export default UnAuthed;
