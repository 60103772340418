import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import Loading from '@core/ui/Loading';
import {AdvisorPodcastNav} from '@analytics/components/nav/AdvisorPodcastNav';
import {useAdvisorShowQuery} from '@analytics/graphql-api';
import {AdvisorRouteProps} from '../_routes';
import {AdvisorPodcastAudience} from './AdvisorPodcastAudience';
import {AdvisorPodcastBrands} from './AdvisorPodcastBrands';
import {AdvisorPodcastEpisodes} from './AdvisorPodcastEpisodes';
import {AdvisorPodcastIndustries} from './AdvisorPodcastIndustries';
import {AdvisorPodcastOverview} from './AdvisorPodcastOverview';
import {generateAdvisorPodcastRoutes} from './_routes';

type Props = AdvisorRouteProps & {
  match: {
    params: {
      podcastId: string;
    };
  };
};

export function AdvisorPodcastIndex({
  user,
  organization,
  match: {
    params: {podcastId},
  },
}: Props) {
  const {data, loading, error} = useAdvisorShowQuery({
    fetchPolicy: 'no-cache',
    variables: {
      organizationId: organization.id,
      id: podcastId,
      includeTestData: user.isAdmin,
    },
  });

  if (loading) {
    return <Loading fixed />;
  }

  if (error) {
    return <Redirect to={createOrgPath(organization, `/advisor`)} />;
  }

  const podcast = data?.me?.organization?.advisor?.show;

  const {renderRoutes} = generateAdvisorPodcastRoutes({
    organization,
    podcastId: podcastId,
  });

  return (
    <>
      <AdvisorPodcastNav organization={organization} podcast={podcast} />
      <Switch>
        {renderRoutes({
          components: {
            overview: AdvisorPodcastOverview,
            audience: AdvisorPodcastAudience,
            brands: AdvisorPodcastBrands,
            industries: AdvisorPodcastIndustries,
            episodes: AdvisorPodcastEpisodes,
          },
          extraProps: {
            user,
            organization,
            podcast,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
}
