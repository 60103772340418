import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {Button, ButtonProps} from '@core/ui/Button';
import {Stack, StackProps} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';

export const Row = styled.div<{noBorder?: boolean}>`
  border-top: ${({noBorder}) => !noBorder && '1px solid var(--bg-muted)'};
  font-size: 0.875rem;
  padding: ${({noBorder}) => (noBorder ? '0 0 2rem' : '2rem 0')};
`;

export const Grid = styled.div<{
  alignItems?: string;
  columns: string;
  margin?: string;
}>`
  align-items: ${({alignItems}) => alignItems ?? 'center'};
  display: grid;
  gap: var(--spacing-5);
  grid-template-columns: ${({columns}) => columns};
  margin: ${({margin}) => margin};
`;

export const CampaignPathButton = ({
  color = 'primary',
  children,
  toCampaignPath,
  variant = 'outlined',
  ...props
}: Omit<ButtonProps, 'buttonSize'> & {
  toCampaignPath?: string;
}) => {
  const {pathname} = useLocation();
  return (
    <Button
      buttonSize='sm'
      color={color}
      to={toCampaignPath && `${pathname}/${toCampaignPath}`}
      variant={variant}
      {...props}>
      {children}
    </Button>
  );
};

export const Title = ({
  children,
  rightContent,
  ...props
}: StackProps & {
  rightContent?: React.ReactNode;
}) => (
  <Stack
    alignItems='center'
    css={{marginBottom: 'var(--spacing-2)', position: 'relative'}}
    {...props}>
    <Text color='muted' variant='subtitle2'>
      {children}
    </Text>
    {rightContent && (
      <div css={{position: 'absolute', right: 0}}>{rightContent}</div>
    )}
  </Stack>
);
