import {isOrgBrand} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import {CampaignAdPlacementsTable} from '@analytics/components/campaigns/CampaignAdPlacementsTable';
import {CampaignDraft} from '@analytics/components/campaigns/CampaignDraft';
import CampaignLinksTable from '@analytics/components/campaigns/CampaignLinksTable';
import {CampaignTargetsTable} from '@analytics/components/campaigns/CampaignTargetsTable';
import {categorizeCampaignPlacements} from '@analytics/components/campaigns/_helpers';
import {CampaignOmniChart} from '@analytics/components/campaigns/charts';
import {CampaignStats} from '@analytics/components/campaigns/stats/CampaignStats';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import UpcomingEpisodesChart from '@analytics/components/upcoming-episodes/UpcomingEpisodesChart';
import {CampaignState} from '@analytics/graphql-api';
import {CampaignRouteProps, toCampaignUrl} from './_routes';

const CampaignContent = ({organization, campaign}: CampaignRouteProps) => {
  const {
    campaignDynamics,
    campaignLinks,
    campaignPodcasts,
    campaignStreamings,
    campaignTargets,
    kind,
  } = campaign;

  const {
    activeDynamics,
    activeCampaignPodcasts,
    activeStreamings,
    baselineDynamics,
    baselineStreamings,
    upcomingDynamics,
    upcomingCampaignPodcasts,
    upcomingStreamings,
  } = categorizeCampaignPlacements({
    campaignDynamics,
    campaignPodcasts,
    campaignStreamings,
  });

  const showPlacements =
    activeDynamics.length > 0 ||
    activeCampaignPodcasts.length > 0 ||
    activeStreamings.length > 0;
  const showUpcoming =
    upcomingDynamics.length > 0 ||
    upcomingCampaignPodcasts.length > 0 ||
    upcomingStreamings.length > 0;
  const showBaseline =
    baselineDynamics.length > 0 || baselineStreamings.length > 0;

  return (
    <>
      <CampaignStats campaign={campaign} />
      <Card
        css={{
          marginTop: 'var(--spacing-5)',
          [`@media (min-width: 1600px)`]: {
            marginTop: '2.375rem',
          },
        }}>
        <CampaignOmniChart organization={organization} campaign={campaign} />
      </Card>
      {kind === 'marketing' && (
        <>
          <Card>
            <CardHeader>Target Podcasts</CardHeader>
            {campaignTargets.length > 0 ? (
              <CampaignTargetsTable
                organization={organization}
                campaign={campaign}
              />
            ) : (
              <EmptyMessage>
                <p>
                  Please add the Podcast this Marketing campaign is targeting.
                </p>
                <Button
                  buttonSize='sm'
                  color='secondary'
                  to={`${toCampaignUrl(organization, campaign)}/targets/add`}>
                  Add Target Podcast
                </Button>
              </EmptyMessage>
            )}
          </Card>
          {campaignLinks.length > 0 && (
            <Card>
              <CardHeader>PodLink</CardHeader>
              <CampaignLinksTable
                organization={organization}
                campaign={campaign}
                campaignLinks={campaignLinks}
              />
            </Card>
          )}
        </>
      )}
      {showPlacements && (
        <CampaignAdPlacementsTable
          organization={organization}
          campaign={campaign}
          campaignPodcasts={activeCampaignPodcasts}
          campaignDynamics={activeDynamics}
          campaignStreamings={activeStreamings}
        />
      )}
      {isOrgBrand(organization) && (
        <Card>
          <CardHeader>Upcoming Episodes</CardHeader>
          <UpcomingEpisodesChart
            campaign={campaign}
            organization={organization}
          />
        </Card>
      )}
      {showUpcoming && (
        <CampaignAdPlacementsTable
          headerTitle='Upcoming Ads'
          organization={organization}
          campaign={campaign}
          campaignDynamics={upcomingDynamics}
          campaignPodcasts={upcomingCampaignPodcasts}
          campaignStreamings={upcomingStreamings}
        />
      )}
      {showBaseline && (
        <CampaignAdPlacementsTable
          headerTitle='Baseline Ads'
          organization={organization}
          campaign={campaign}
          campaignDynamics={baselineDynamics}
          campaignStreamings={baselineStreamings}
        />
      )}
    </>
  );
};

const Campaign = ({user, organization, campaign}: CampaignRouteProps) => {
  return (
    <>
      <CampaignNav
        user={user}
        organization={organization}
        campaign={campaign}
      />
      {campaign.state === CampaignState.Draft ||
      campaign.state === CampaignState.Ready ? (
        <CampaignDraft campaign={campaign} organization={organization} />
      ) : (
        <CampaignContent campaign={campaign} organization={organization} />
      )}
    </>
  );
};

export default Campaign;
