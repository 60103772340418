import {useState} from 'react';
import {copyToClipboard as copy} from '@core/lib/utils';
import {t} from '@core/texts';
import {ModalHeader} from '@core/ui/Content';
import {Submit} from '@core/ui/FormElements';
import {SubmitProps} from '@core/ui/FormElements/Submit';
import {Stack} from '@core/ui/Layout';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import {Snackbar} from '@core/ui/Snackbar';
import {ExportsByType, useExportTokenUriMutation} from '@analytics/graphql-api';

type ExportButtonProps = {
  noOpenOnDownload?: boolean;
  copyToClipboard?: boolean;
  onClick?: () => void;
} & ExportsByType &
  Omit<SubmitProps, 'onClick'>;

export const ExportButton = ({
  noOpenOnDownload = false,
  copyToClipboard = false,
  onClick,
  ...props
}: ExportButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [openedError, toggleError] = useOverlay();
  const [copied, toggleCopied] = useOverlay();
  const [createExportTokenUri] = useExportTokenUriMutation();

  const _onClick = async () => {
    onClick?.();
    setLoading(true);
    try {
      const exportUri = await createExportTokenUri({
        ...props,
      });

      if (!noOpenOnDownload) window.open(exportUri);
      if (copyToClipboard) {
        copy(exportUri);
        toggleCopied(true);
      }
    } catch (e) {
      console.warn(e);
      toggleError(true);
    }
    setLoading(false);
  };

  return (
    <>
      <Submit type='button' loading={loading} onClick={_onClick} {...props} />
      <Snackbar
        autoHideDuration={4000}
        opened={openedError}
        severity='error'
        toggle={toggleError}>
        {t('errors.network')}
      </Snackbar>
      <Snackbar
        autoHideDuration={4000}
        opened={copied}
        toggle={toggleCopied}
        severity='success'>
        Copied download link to clipboard
      </Snackbar>
    </>
  );
};

type Props = {
  opened: boolean;
  toggle: (to?: boolean) => void;
} & ExportsByType;

export default function ExportOverlay({opened, toggle, ...props}: Props) {
  return (
    <>
      <Overlay
        opened={opened}
        toggle={toggle}
        withShadow
        withBackdrop
        centered
        css={`
          min-width: 32.5rem;
          overflow: initial;
          padding: 2rem;
        `}>
        <ModalHeader toggle={toggle} withCloseIcon>
          Export
        </ModalHeader>
        <Stack direction='column' spacing='var(--spacing-4)'>
          <ExportButton {...props} copyToClipboard noOpenOnDownload>
            Copy Download Link
          </ExportButton>
          <ExportButton {...props}>Export to CSV</ExportButton>
        </Stack>
      </Overlay>
    </>
  );
}
