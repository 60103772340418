import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from '@core/lib/dayjs';
import {capitalize, comma} from '@core/lib/filters';
import {getSeriesRange, toTimeSeries} from '@core/lib/series';
import {Element} from '@core/style';
import {GREEN} from '@core/style';
import {DropdownMenu} from '@core/ui/DropdownMenu';
import {Icon} from '@core/ui/Icon';
import Loading from '@core/ui/Loading';
import DateFilterDropdown from '@analytics/components/DateFilterDropdown';
import EPISODE_MINUTES_BY_DAY from '@analytics/graphql-api/_old_queries/reviewers/EpisodeMinutesByDay';

const EpisodeMinutesByDay = ({organization, reviewerId}) => {
  const today = dayjs().utc();

  const ranges = [
    {
      name: 'Last 60 days',
      after: today.clone().subtract(60, 'days'),
    },
    {
      name: 'Last 90 days',
      after: today.clone().subtract(90, 'days'),
    },
    {
      name: 'Last 120 days',
      after: today.clone().subtract(120, 'days'),
    },
  ];

  const [selected, setSelected] = useState(ranges[1]);
  const [interval, setInterval] = useState('day');

  const {data, loading} = useQuery(EPISODE_MINUTES_BY_DAY, {
    variables: {
      organizationId: organization.id,
      reviewerId: reviewerId,
      after: selected.after.format('YYYY-MM-DD'),
    },
  });

  if (loading) {
    return <Loading centered />;
  }

  const {episodeMinutesByDay} = data.me.organization.research.reviewing;

  const _graph = episodeMinutesByDay.map(({day, minutes}) => ({
    x: dayjs(day).utc().valueOf(),
    y: parseInt(minutes),
  }));

  const [start, end] = getSeriesRange({data: _graph}, 'day');
  const graph = toTimeSeries(_graph, interval, start, today.valueOf());

  return (
    <Element rules={() => ({padding: '1rem'})}>
      <Element
        rules={() => ({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          alignItems: 'center',
          paddingLeft: '1rem',
        })}>
        <h4>Episode Minutes Processed By {capitalize(interval)}</h4>
        <Element
          rules={() => ({
            padding: '.625rem 1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          })}>
          <DateFilterDropdown
            dateRanges={ranges}
            selected={selected}
            onSubmit={(item) => {
              setSelected(item);
            }}
          />
          <DropdownMenu
            items={[
              {title: 'daily', value: 'day'},
              {title: 'weekly', value: 'week'},
            ]}
            selectedValue={interval}
            onSubmit={({value}) => setInterval(value)}
            overlayProps={{
              horizontalAlign: 'left',
            }}>
            <Element tag='b' rules={() => ({marginRight: '.25rem'})}>
              Interval:
            </Element>{' '}
            {interval}
            <Icon icon='arrow-right' rules={() => ({marginLeft: 'auto'})} />
          </DropdownMenu>
        </Element>
      </Element>

      <ResponsiveContainer width='100%' height={300}>
        <BarChart data={graph}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='x'
            tickFormatter={(v) => {
              return dayjs(v).utc().format('YYYY-MM-DD');
            }}
          />
          <YAxis />
          <Bar dataKey='y' fill={GREEN} />
          <Tooltip
            cursor={{fill: 'transparent'}}
            content={({payload}) => {
              if (!payload || payload.length < 1) {
                return null;
              }

              const {x, y} = payload[0].payload;

              return (
                <Element
                  rules={() => ({
                    background: '#fff',
                    borderRadius: '.5rem',
                    boxShadow: `0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)`,
                    padding: '1rem',
                  })}>
                  <Element
                    rules={() => ({
                      fontWeight: 700,
                      textTransform: 'capitalize',
                    })}>
                    {dayjs(x).utc().format('dddd, MMM DD')}
                  </Element>
                  <Element>Minutes Processed {comma(y)}</Element>
                </Element>
              );
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </Element>
  );
};

export default EpisodeMinutesByDay;
