import {Switch} from 'react-router-dom';
import useRoutes from '@core/lib/useRoutes';
import {CampaignPixelRouteProps} from '../_routes';
import {CampaignReferrer} from './Referrer';
import {CampaignReferrerDomains} from './ReferrersOverview';

export function CampaignReferrersIndex({
  user,
  organization,
  campaign,
  pixel,
  match: {url},
}: CampaignPixelRouteProps & {
  match: {url: string};
}) {
  const {renderRoutes} = useRoutes({
    baseUrl: url,
    basePath:
      '/:part(b|a|s|p)/:slug/campaigns/:campaignsSlug/pixels/:key/referrers',
    routes: [
      {
        key: 'overview',
        path: '',
        exact: true,
      },
      {
        key: 'referrer',
        path: ':domain',
        exact: true,
      },
    ],
  });

  return (
    <Switch>
      {renderRoutes({
        components: {
          overview: CampaignReferrerDomains,
          referrer: CampaignReferrer,
        },
        extraProps: {
          user,
          organization,
          pixel,
          campaign,
        },
      })}
    </Switch>
  );
}
