import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignEpisodeIndex} from './episode';

const CampaignEpisodesIndex = ({
  user,
  organization,
  campaign,
  campaignPodcast,
}) => {
  return (
    <Switch>
      <PropsRoute
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/podcasts/:campaignPodcastId/episodes/:campaignEpisodeId'
        user={user}
        organization={organization}
        campaign={campaign}
        campaignPodcast={campaignPodcast}
        component={CampaignEpisodeIndex}
      />
    </Switch>
  );
};

export default CampaignEpisodesIndex;
