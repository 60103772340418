import {gql} from '@apollo/client';

interface IHistoricalEvent {
  name: string;
  eventCounts: Array<{
    name: string;
    value: number;
  }>;
}

export interface IOrganizationKeyDebugHistorical {
  me: {
    id: string;
    organization: {
      id: string;
      key: {
        key: string;
        debug: {
          hasData: boolean;
          historical: Array<{
            hour: string;
            js: Array<IHistoricalEvent>;
            mobile: Array<IHistoricalEvent>;
            img: Array<IHistoricalEvent>;
          }>;
        };
      };
    };
  };
}

export interface IOrganizationKeyDebugHistoricalInput {
  organizationId: string;
  key: string;
  after: string;
  before: string;
}

export default gql`
  query OrganizationKeyDebugHistoricalQuery(
    $organizationId: ID!
    $key: ID!
    $after: String!
    $before: String!
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        key(key: $key) {
          key
          debug {
            hasData
            historical(after: $after, before: $before) {
              hour
              js {
                name
                eventCounts {
                  name
                  value
                }
              }
              mobile {
                name
                eventCounts {
                  name
                  value
                }
              }
              img {
                name
                eventCounts {
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;
