import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {MeasureRouteProps} from '..';
import {getLiftRoutes} from './_routes';
import {IncrementalLiftIndex} from './incremental';
import {SpotifyLiftIndex} from './spotify';

export function LiftIndex({organization, user}: MeasureRouteProps) {
  const {renderRoutes} = getLiftRoutes({organization});
  return (
    <Switch>
      {renderRoutes({
        components: {
          lift: () => (
            <Redirect
              to={createOrgPath(organization, 'measure/lift/spotify')}
            />
          ),
          incremental: IncrementalLiftIndex,
          spotify: SpotifyLiftIndex,
        },
        extraProps: {
          organization,
          user,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
