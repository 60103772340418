import {gql} from '@apollo/client';

export default gql`
  mutation updatePixel($input: UpdateKeyInput!) {
    authed {
      success
    }
    updatePixel(input: $input) {
      key {
        key
        domain
        alias
      }
    }
  }
`;
