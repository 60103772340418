import {createOrgPath} from '@core/lib/organizations';
import {truncate} from '@core/lib/words';
import {Element} from '@core/style';
import {EpisodeImage, FeedImage} from '@core/ui/Image';
import {Stack} from '@core/ui/Layout';
import {Nav, NavItem, NavLink} from '@core/ui/Nav';
import Search from '../research/Search';

function researchURL(organization) {
  return createOrgPath(organization, `/research`);
}

function reviewersURL(organization) {
  return createOrgPath(organization, `/research/reviewers`);
}

function podcastURL(organization, feed) {
  return createOrgPath(organization, `/research/podcasts/${feed.id}`);
}

function episodeURL(organization, feed, episode) {
  return createOrgPath(
    organization,
    `/research/podcasts/${feed.id}/episodes/${episode.id}`
  );
}

function companyURL(organization, company) {
  return createOrgPath(organization, `/research/brands/${company.id}`);
}

function companyAdURL(organization, company, ad) {
  return createOrgPath(
    organization,
    `/research/brands/${company.id}/ads/${ad.id}`
  );
}

function publisherURL(organization, publisher) {
  return createOrgPath(organization, `/research/publishers/${publisher.slug}`);
}

const FeedNav = ({organization, feed}) => {
  const url = podcastURL(organization, feed);
  const publisher =
    feed.podcast && (feed.podcast.publisher || feed.podcast.organization)
      ? feed.podcast.publisher || feed.podcast.organization
      : null;

  const dropdownMenuItems = [
    {
      title: 'Export',
      to: `${url}/export`,
    },
    {
      title: 'Share',
      to: `${url}/share`,
    },
  ];

  if (feed.processing && !feed.measure) {
    dropdownMenuItems.push({
      title: 'Measure',
      to: `${url}/measure`,
    });
  }

  return (
    <Nav
      title={feed.title}
      description={feed.description}
      Image={() => <FeedImage feed={feed} width={150} />}
      breadcrumbs={[
        {
          to: researchURL(organization),
          title: `Research`,
        },
        {
          title: 'Podcast',
        },
      ]}
      moreDropdownItems={dropdownMenuItems}>
      <NavItem to={`${url}`}>Overview</NavItem>
      <NavItem to={`${url}/ads`}>Ads</NavItem>
      <NavItem to={`${url}/brands`}>Brands</NavItem>
      {publisher && (
        <NavLink to={publisherURL(organization, publisher)}>
          Publisher: {publisher.name}
        </NavLink>
      )}
    </Nav>
  );
};

const EpisodeNav = ({organization, feed, episode}) => {
  const url = episodeURL(organization, feed, episode);

  return (
    <Nav
      title={episode.title}
      description={episode.description}
      Image={() => <EpisodeImage episode={episode} width={150} />}
      breadcrumbs={[
        {
          to: researchURL(organization),
          title: `Research`,
        },
        {
          to: podcastURL(organization, feed),
          title: `${feed.title}`,
        },
        {
          title: 'Episode',
        },
      ]}
      moreDropdownItems={[{name: 'Export', to: `${url}/export`}]}
    />
  );
};

const CompanyNav = ({organization, company}) => {
  const url = companyURL(organization, company);

  return (
    <Nav
      title={company.name}
      description={company.description}
      Image={
        company.image
          ? () => <img src={company.image.src} alt='Company' />
          : undefined
      }
      breadcrumbs={[
        {
          to: researchURL(organization),
          title: `Research`,
        },
        {
          title: 'Brand',
        },
      ]}
      moreDropdownItems={[
        {title: 'Export', to: `${url}/export`},
        {title: 'Edit', to: `${url}/edit`},
      ]}>
      <NavItem to={`${url}`}>Overview</NavItem>
      <NavItem to={`${url}/ads`}>Ads</NavItem>
      <NavItem to={`${url}/podcasts`}>Podcasts</NavItem>
      <NavItem
        to={`${url}/similar`}
        isActive={({path}) => {
          return path.indexOf(`${url}/similar`) === 0;
        }}>
        Similar
      </NavItem>
    </Nav>
  );
};

const CompanyAdNav = ({organization, company, ad}) => {
  const url = companyAdURL(organization, company, ad);

  return (
    <Nav
      title={truncate(ad.text, 55)}
      breadcrumbs={[
        {
          to: researchURL(organization),
          title: `Research`,
        },
        {
          to: companyURL(organization, company),
          title: company.name,
        },
        {
          title: 'Ad',
        },
      ]}
      moreDropdownItems={[{title: 'Download', to: `${url}/download`}]}
    />
  );
};

const PublisherNav = ({organization, publisher}) => {
  const url = publisherURL(organization, publisher);

  return (
    <Nav
      title={`${publisher.name}`}
      description={`${publisher.name} operates ${publisher.podcasts.length} podcasts. If you are
      interested in advertising on ${publisher.name} you can contact them
      through Ad Analytics.`}
      breadcrumbs={[
        {
          to: researchURL(organization),
          title: `Research`,
        },
        {
          title: 'Publisher',
        },
      ]}>
      <NavItem to={url}>Overview</NavItem>
      <NavItem to={`${url}/brands`}>Brands</NavItem>
      <NavItem to={`${url}/contact`}>Contact</NavItem>
    </Nav>
  );
};

const ResearchNav = ({user, organization}) => {
  const url = researchURL(organization);

  return (
    <Stack
      alignItems={'center'}
      css={{marginleft: '1.5rem', marginRight: '1.5rem'}}>
      <Nav
        moreDropdownItems={[{title: 'Add podcast', to: `${url}/podcasts/add`}]}
        css={{marginLeft: 0, marginRight: 'auto'}}>
        <NavItem to={`${url}`}>Research</NavItem>
        <NavItem to={`${url}/brands`}>Top Brands</NavItem>
        <NavItem to={`${url}/rankings`}>Top Podcasts</NavItem>
        {(user.isAdmin || user.reviewer) && (
          <NavItem to={`${url}/reviewers`}>Review</NavItem>
        )}
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
          }}
        />
      </Nav>
      <Search organization={organization} small />
    </Stack>
  );
};

const ReviewersNav = ({user, organization}) => {
  const url = reviewersURL(organization);

  return (
    <Nav
      title='Reviewers'
      breadcrumbs={[
        {
          to: researchURL(organization),
          title: `Research`,
        },
      ]}>
      {user.reviewer && (
        <>
          <NavItem to={`${url}`}>People</NavItem>
          <NavItem to={`${url}/episodes`}>Episodes</NavItem>
          <NavItem to={`${url}/ads`}>Ads</NavItem>
          <NavItem to={`${url}/classify`}>Classify</NavItem>
          <NavItem to={`${url}/companies`}>Companies</NavItem>
        </>
      )}
    </Nav>
  );
};

export {
  CompanyAdNav,
  CompanyNav,
  companyURL,
  companyAdURL,
  EpisodeNav,
  episodeURL,
  FeedNav,
  podcastURL,
  PublisherNav,
  publisherURL,
  ResearchNav,
  researchURL,
  ReviewersNav,
  reviewersURL,
};
