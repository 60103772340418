import styled from 'styled-components';
import {ButtonIcon} from '@core/ui/Button';
import {AdvisorColumn} from '../types';

export function SelectedListItem({
  item,
  onRemoveColumn,
}: {
  item: AdvisorColumn;
  onRemoveColumn: (item: AdvisorColumn) => void;
}) {
  return (
    <SelectedIcon
      onClick={() => onRemoveColumn(item)}
      color='secondary'
      icon='checkbox-marked-circle'
    />
  );
}

const SelectedIcon = styled(ButtonIcon)`
  padding: 0;
  color: var(--green);
  :focus svg {
    border-radius: 50%;
    color: var(--green);
    outline: 2px solid var(--blue-80);
  }
  :hover svg {
    color: var(--green);
    filter: brightness(0.9);
  }
  :active svg {
    color: var(--green);
    filter: brightness(1.1);
  }
`;
