import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Icon} from '@core/ui/Icon';
import {Stack} from '@core/ui/Layout';
import {Nav} from '@core/ui/Nav';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {SuperCampaign} from '../campaigns/spotify-orders';
import {CampaignInfoBadge, CampaignSalesOrderLink} from './_shared';

export function SpotifyOrderNav({
  organization,
  superCampaign,
}: {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  superCampaign: SuperCampaign;
}) {
  return (
    <Nav
      title={superCampaign.name}
      items={[{title: 'Overview', isActive: () => true}]}
      contentAfterTitle={
        <Stack
          direction='column'
          gap={4}
          css={{margin: '0 0 var(--spacing-4)'}}>
          <Stack alignItems='center' gap='var(--spacing-3)'>
            <CampaignInfoBadge
              css={`
                align-items: center;
                display: flex;
                gap: var(--spacing-1);
              `}>
              <Icon
                icon='date-alternative'
                css={`
                  width: 1rem;
                  height: 1rem;
                `}
              />
              {formatDate(superCampaign.expectedStartAt)}
              {' to '}
              {formatDate(superCampaign.expectedEndAt)}
            </CampaignInfoBadge>
            <CampaignInfoBadge
              css={`
                align-items: center;
                display: flex;
                gap: var(--spacing-1);
                text-transform: capitalize;
              `}>
              {(superCampaign.state === 'LIVE' ||
                superCampaign.state === 'AGENCY') && (
                <div
                  css={`
                    background: var(--green);
                    border-radius: 50%;
                    height: 0.5rem;
                    width: 0.5rem;
                  `}
                />
              )}
              {superCampaign.state.toLowerCase()}
            </CampaignInfoBadge>
            <CampaignInfoBadge
              css={`
                align-items: center;
                display: flex;
                gap: var(--spacing-2);
                text-transform: capitalize;
              `}>
              <Icon
                icon='measure-outline'
                css={`
                  width: 1rem;
                  height: 1rem;
                `}
              />
              {superCampaign.kind}
            </CampaignInfoBadge>
            <CampaignInfoBadge>
              Publisher: {superCampaign.organization?.name}
            </CampaignInfoBadge>
            <CampaignInfoBadge>
              Advertiser: {superCampaign.advertiser?.organization?.name ?? '—'}
            </CampaignInfoBadge>
          </Stack>
          <Alert compact severity='info-highlight'>
            <Stack direction='column' gap='var(--spacing-3)'>
              {t('spotify.orders.parent.description')}
              <Stack
                alignItems='center'
                gap='var(--spacing-1)'
                css={{flexWrap: 'wrap'}}>
                {(superCampaign.campaigns ?? []).map((c) => (
                  <CampaignSalesOrderLink
                    key={c.name}
                    name={c.name}
                    to={toCampaignUrl(organization, {slug: c.slug})}
                  />
                ))}
              </Stack>
            </Stack>
          </Alert>
        </Stack>
      }
      breadcrumbs={[
        {
          title: 'Home',
          to: createOrgPath(organization),
        },
        {
          title: 'Spotify Sales Order',
        },
      ]}
    />
  );
}

const formatDate = (v?: string) => (v ? dayjs(v).format('MMM DD, YYYY') : '?');
