const DebugScreen = () => {
  return (
    <div>
      Debug Screen:
      <p>Version: 1.0.6</p>
    </div>
  );
};

export default DebugScreen;
