import {GREEN, setOpacity} from '@core/style';

const AD_COLOR = '#FF0C12';
const DEFAULT_COLOR = setOpacity(GREEN, 0.6);
const SPEAKER_COLORS = [
  '#AE168E', // purple pink
  '#00CFFB', // light blue
  '#FDAE32', // orange
  '#311BA1', // dark blue
  '#FDFB00', // yellow

  '#DD3F88', // pink
  '#5CFF00', // light green
  '#8F00F2', // purple
  '#FFF670', // light yellow
].map((v) => setOpacity(v, 0.5));

const inRange = (ranges) => {
  return (value) => {
    for (let i = 0; i < ranges.length; i++) {
      if (ranges[i].start <= value && value <= ranges[i].end) {
        return true;
      }
    }
    return false;
  };
};

const getColorFunc = (labels, speakers) => {
  const isInLabel = inRange(labels);

  const speakerFuncs = speakers.map(({id, ranges}) => {
    const isSpeaker = inRange(ranges);
    return (v) => (isSpeaker(v) ? SPEAKER_COLORS[id] : null);
  });

  return (value) => {
    if (isInLabel(value)) {
      return AD_COLOR;
    }

    for (let i = 0; i < speakerFuncs.length; i++) {
      const color = speakerFuncs[i](value);

      if (color) {
        return color;
      }
    }
    return DEFAULT_COLOR;
  };
};

const useWaveform = (waveform, duration, asr) => {
  if (!waveform.length) {
    return [];
  }

  // Do we have ASR for this episode?
  let getColor = () => DEFAULT_COLOR;

  // Do the things.
  if (asr.transcript) {
    getColor = getColorFunc(asr.labels, asr.transcript.speakers);
  }

  // converts the waveform from an array for integers, to an array of maps.
  const increment = duration / waveform.length;

  return waveform.map((v, i) => ({
    value: v,
    time: increment * i,
    color: getColor(increment * i),
  }));
};

export default useWaveform;
