import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Stack} from '@core/ui/Layout';
import {
  BrandLiftMessageCard,
  BrandLiftStatsDetail,
} from '@analytics/components/lift/SpotifyBrandLift';
import {MeasureSpotifyLiftRouteProps} from './_routes';

export function SpotifyLiftReport({liftReport}: MeasureSpotifyLiftRouteProps) {
  return (
    <Stack direction='column' gap='var(--spacing-6)'>
      <Alert severity='spotify' css={{margin: '0 var(--spacing-6)'}}>
        {t('spotify.lift.description')}
      </Alert>
      <BrandLiftStatsDetail liftReport={liftReport} />
      {liftReport.messages.map((m) => (
        <BrandLiftMessageCard key={m.id} message={m} />
      ))}
    </Stack>
  );
}
