import DataTable from '@core/ui/DataTable';
import {columns} from './columns';
import {CampaignActivityLogEvents} from './types';

export const ActivityLogTable = ({
  auditLogEvents,
}: {
  auditLogEvents: CampaignActivityLogEvents;
}) => {
  return (
    <DataTable
      searchPlaceholder='Search by name, event, campaign, or field'
      searchKeys={[
        'name',
        'action',
        'campaignOrLineItemName',
        'eventType',
        'fieldName',
        'oldVal',
        'newVal',
      ]}
      emptyMessageText='This campaign has not had any activity yet.'
      columns={columns}
      data={auditLogEvents}
      orderBy='-dateTimeChanged'
      withSortFilter
    />
  );
};
