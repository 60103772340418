import {useState} from 'react';
import {DocumentNode, useMutation} from '@apollo/client';
import {ToastMessage} from '@core/ui/Alert';
import {Blockquote} from '@core/ui/Blockquote';
import {Card, CardHeader} from '@core/ui/Content';
import {Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {
  CreateApiKeyDocument,
  CreateServiceAccountApiKeyDocument,
} from '@analytics/graphql-api';
import {ManageApiKeysRouteProps} from '.';

export function AddApiKeys({organization}: ManageApiKeysRouteProps) {
  return (
    <ResponsiveContent containerSize='medium'>
      <Card>
        <CardHeader>API Key</CardHeader>
        <p>
          Once you generate your API key we will be{' '}
          <strong>unable to retrieve</strong> the secret. Please save your
          secret and ID in a safe location on your local machine.
        </p>
        <AddKey
          mutation={CreateApiKeyDocument}
          fromResponse={(resp) => {
            const key = resp?.data?.createApiKey?.apiKey?.key;
            const secret = resp?.data?.createApiKey?.apiKey?.secret;
            return {
              key,
              private: secret,
            };
          }}
        />
      </Card>
      <Card>
        <CardHeader>Service Account Key</CardHeader>
        <p>
          This key can be generated to make API calls for your organization
          without being tied to a single user. This key should be used for
          system to system integrations that need to access the API as an admin
          in your Organization.
        </p>
        <AddKey
          placeholder='Generate Service Key'
          mutation={CreateServiceAccountApiKeyDocument}
          mutationVariables={{
            input: {
              organizationId: organization.id,
            },
          }}
          fromResponse={(resp) => {
            const key = resp?.data?.createServiceAccountApiKey?.apiKey?.key;
            const secret =
              resp?.data?.createServiceAccountApiKey?.apiKey?.secret;
            return {
              key,
              private: secret,
            };
          }}
        />
      </Card>
    </ResponsiveContent>
  );
}

// We can't name it secret because it will trigger a Detectify alert.
type ApiKey = {key: string; private: string};

type AddKeyProps = {
  fromResponse: (_resp: any) => ApiKey;
  mutation: DocumentNode;
  mutationVariables?: object;
  placeholder?: string;
};

const AddKey = ({
  fromResponse,
  mutation,
  mutationVariables,
  placeholder = 'Generate API Key',
}: AddKeyProps) => {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<ApiKey | null>(null);
  const [generateApiKey] = useMutation(mutation, {
    variables: {...mutationVariables},
  });

  const onGenerateApiKey = async () => {
    setLoading(true);
    try {
      const resp = await generateApiKey();
      const _apiKey = fromResponse(resp);

      setApiKey(_apiKey);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <>
      {error && (
        <ToastMessage
          alertType='error'
          css={`
            margin: 1rem 0;
          `}>
          Unable to generate API key
        </ToastMessage>
      )}
      <div
        css={`
          margin: 1.5rem 0 0 0;
          text-align: left;
        `}>
        <Submit
          onClick={onGenerateApiKey}
          disabled={!!apiKey || loading}
          loading={loading}>
          {placeholder}
        </Submit>
      </div>
      {!!apiKey && (
        <>
          <h5
            css={`
              margin: 1.5rem 0 1rem 0;
            `}>
            Your Key and Secret
          </h5>
          <Blockquote>
            key: {apiKey.key}
            <br />
            secret: {apiKey.private}
          </Blockquote>
          <p>
            Please be sure to save your key and secret for future use. These
            cannot be retrieved and you will need both to access the API.
          </p>
        </>
      )}
    </>
  );
};
