import React, {useState} from 'react';
import {Element} from '@core/style';
import Button from '@core/ui/Button/LegacyButton';
import ColorPicker from '@core/ui/ColorPicker';
import {FormOutlineContainer, Input, Label, TextArea} from '@core/ui/Form';
import {Icon} from '@core/ui/Icon';
import Loading from '@core/ui/Loading';
import AssetSelector from '@analytics/components/assets/AssetSelector';

const ColorPickerSelect = ({
  title,
  defaultColor,
  setConfig,
  value,
  ...props
}) => {
  return (
    <Element
      rules={() => ({
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
      })}
      {...props}>
      <Element rules={() => ({flex: 1, width: '100%'})}>{title}</Element>
      <div>
        <ColorPicker
          defaultColor={defaultColor}
          onChange={(color) => {
            const update = {};
            update[value] = color;
            setConfig(update);
          }}
        />
      </div>
    </Element>
  );
};

const AssetSelectorWithPreview = ({
  label,
  name,
  organization,
  config,
  setConfig,
}) => {
  const [hovered, setHover] = useState(false);

  return (
    <FormOutlineContainer
      label={label}
      rules={() => ({display: 'flex', width: '100%'})}>
      <AssetSelector
        organization={organization}
        onSelect={(asset) => {
          const update = {};
          update[name] = asset;
          setConfig(update);
        }}
      />
      {config[name] ? (
        <Element
          rules={() => ({
            alignItems: 'center',
            display: 'flex',
            flex: '0 4.6875rem',
            marginLeft: '0.625rem',
          })}>
          <Element
            rules={() => ({position: 'relative'})}
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}>
            <img src={`${config[name].src}?width=75px`} />
            <Element
              style-hovered={hovered}
              rules={({hovered}) => ({
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: hovered
                  ? 'rgba(255,255,255, 0.8)'
                  : 'rgba(255,255,255, 0.2)',
              })}
              onClick={() => {
                const update = {};
                update[name] = null;
                setConfig(update);
              }}>
              {hovered ? (
                <Icon
                  icon='close'
                  rules={() => ({
                    width: '1.5rem',
                  })}
                />
              ) : (
                ''
              )}
            </Element>
          </Element>
        </Element>
      ) : null}
    </FormOutlineContainer>
  );
};

const DesignForm = ({
  organization,
  config,
  kind,
  setConfig,
  onSave,
  onCancel,
  error,
  loading,
}) => {
  const onChange = ({target: {name, value}}) => {
    const update = {};
    update[name] = value;
    setConfig(update);
  };

  const {backgroundColor, fontColor, primaryColor, secondaryColor} = config;

  return (
    <Element rules={() => ({display: 'flex', flexDirection: 'column'})}>
      <Element
        rules={({theme}) => ({
          borderBottom: `1px solid ${theme.bgTertiary}`,
          display: 'flex',
          padding: '1rem',
        })}>
        <Element rules={() => ({flex: 1})}>
          <Element
            rules={({theme}) => ({
              alignItems: 'center',
              background: theme.bgTertiary,
              borderRadius: '100%',
              cursor: 'pointer',
              display: 'flex',
              height: '2.375rem',
              justifyContent: 'center',
              width: '2.375rem',
              ':hover': {
                background: theme.stateHoverNav,
              },
            })}>
            <Icon
              icon='close'
              onClick={() => {
                if (typeof onCancel === 'function' && !loading) {
                  onCancel();
                }
              }}
              rules={() => ({
                width: '25px',
              })}
            />
          </Element>
        </Element>

        <Button
          style-primary
          onClick={onSave}
          disabled={loading}
          rules={() => ({marginRight: '0.625rem'})}>
          Save
        </Button>
      </Element>
      <Element
        rules={() => ({
          flex: 1,
          height: '100%',
          overflowY: 'scroll',
          padding: '1rem 1rem',
        })}>
        {loading ? (
          <Loading fixed />
        ) : (
          <>
            {kind == 'podcast' ? (
              <>
                <Label>Vanity URL</Label>
                <Input
                  type='text'
                  name='slug'
                  value={config.slug}
                  onChange={onChange}
                  style-error={error}
                  disabled={loading}
                  withMargin
                />
              </>
            ) : (
              ''
            )}

            {kind == 'domain' ? (
              <>
                <Label>Title</Label>
                <Input
                  type='text'
                  name='title'
                  required
                  defaultValue={config.title}
                  onChange={onChange}
                  placeholder={organization.name}
                  disabled={loading}
                  withMargin
                />

                <Label>Description</Label>
                <TextArea
                  type='text'
                  name='description'
                  value={config.description}
                  onChange={onChange}
                  placeholder='Brief promo text about the network.'
                  disabled={loading}
                  withMargin
                />
              </>
            ) : (
              ''
            )}

            <FormOutlineContainer label='Colors'>
              <Element
                rules={() => ({
                  display: 'grid',
                  gridGap: '0.625rem',
                  gridTemplateRows: 'repeat(4, auto)',
                })}>
                {[
                  {
                    title: 'Background',
                    defaultColor: backgroundColor,
                    value: 'backgroundColor',
                  },
                  {
                    title: 'Font',
                    defaultColor: fontColor,
                    value: 'fontColor',
                  },
                  {
                    title: 'Primary Color',
                    defaultColor: primaryColor,
                    value: 'primaryColor',
                  },
                  {
                    title: 'Secondary Color',
                    defaultColor: secondaryColor,
                    value: 'secondaryColor',
                  },
                ].map((obj) => {
                  return (
                    <ColorPickerSelect
                      key={obj.value}
                      setConfig={setConfig}
                      {...obj}
                    />
                  );
                })}
              </Element>
            </FormOutlineContainer>

            {kind == 'domain' ? (
              <>
                <AssetSelectorWithPreview
                  label='Favicon'
                  name='favicon'
                  organization={organization}
                  config={config}
                  setConfig={setConfig}
                />

                <AssetSelectorWithPreview
                  label='Logo'
                  name='image'
                  organization={organization}
                  config={config}
                  setConfig={setConfig}
                />
              </>
            ) : (
              ''
            )}
          </>
        )}
      </Element>
    </Element>
  );
};

export default DesignForm;
