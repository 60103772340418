import {isOrgBrand, isSelling} from '@core/lib/organizations';
import {CampaignState, Maybe, OrganizationKind} from '@analytics/graphql-api';

type OrgProps = {
  organization: {
    kind: OrganizationKind;
    meRole: Maybe<string>;
  };
};

type CampaignProps = {
  campaign: {
    isCreatedByBrand: boolean;
    isPublisherApproved: boolean;
    allowBrandEditAccess: boolean;
  };
};

/**
 *
 * Checks if an organization user is able to create a campaign
 *
 */
export const canCreateCampaign = ({organization}: OrgProps) =>
  (isSelling(organization) &&
    (organization.meRole === 'admin' || organization.meRole === 'ad-ops')) ||
  (isOrgBrand(organization) && organization.meRole === 'admin');

/**
 *
 * Checks if an organization user is able to update a campaign
 *
 */
export const canUpdateCampaign = ({
  organization,
  campaign,
}: OrgProps & CampaignProps) =>
  (isSelling(organization) ||
    (isOrgBrand(organization) && (campaign.isCreatedByBrand || campaign.allowBrandEditAccess)) ) &&
  (organization.meRole === 'admin' || organization.meRole === 'ad-ops');

/**
 *
 * Checks if an organization user is able to set a campaign live
 *
 */
export const canSetCampaignLive = ({
  organization,
  campaign,
}: OrgProps & CampaignProps) =>
  campaign.isPublisherApproved &&
  (isSelling(organization) ||
    (isOrgBrand(organization) && campaign.isCreatedByBrand));

/**
 *
 * Checks if a brand created campaign needs reviewed by the publisher
 *
 */
export const campaignNeedsReview = ({
  campaign,
}: CampaignProps & {campaign: {state: CampaignState}}) =>
  campaign.isCreatedByBrand &&
  !campaign.isPublisherApproved &&
  campaign.state !== CampaignState.Rejected;
