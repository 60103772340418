import {FetchedCampaign, FetchedCampaignDynamic} from '@analytics/graphql-api';
import {getPlacementsStr} from '@analytics/lib/placements';
import _CampaignStatsBase from './_CampaignStatsBase';
import {formatDate} from './helpers';

export const CampaignDynamicStats = ({
  campaign,
  campaignDynamic,
}: {
  campaign: FetchedCampaign;
  campaignDynamic: FetchedCampaignDynamic;
}) => {
  return (
    <_CampaignStatsBase
      instance={campaignDynamic}
      campaign={campaign}
      details={[
        {
          title: 'Started',
          value: campaignDynamic?.startAt
            ? formatDate(campaignDynamic.startAt)
            : '—',
        },
        {
          title: 'Group',
          value: campaignDynamic?.isBaseline ? 'Baseline' : 'Exposed',
        },
        {
          title: 'Roll Placement',
          value: getPlacementsStr(campaignDynamic),
        },
        {
          title: 'Discount Code',
          value: campaignDynamic?.discountCode || '—',
        },
        {
          title: 'Discount URL',
          value: campaignDynamic?.discountUrl || '—',
        },
        {
          title: 'Effective URL',
          value: campaignDynamic?.effectiveUrl || '—',
        },
      ]}
    />
  );
};
