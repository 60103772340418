import styled from 'styled-components';
import {Card} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {Campaign, Campaigns} from '../_types';

export const Footer = styled(Stack)`
  gap: var(--spacing-2);
  justify-content: flex-end;
  margin-top: var(--spacing-8);
`;

export const Header = styled(Text).attrs({variant: 'h3'})`
  margin-bottom: var(--spacing-4);
`;

const DetailsCard = styled(Card).attrs({variant: 'filled'})`
  border: 0;
  display: grid;
  gap: var(--spacing-2);
  grid-template-columns: 8rem 1fr;
  margin: 0;
  padding: var(--spacing-4);
`;

export function CampaignDetailsCard({
  campaign,
  ...props
}: {
  campaign: Campaign | Campaigns[number];
}) {
  return (
    <DetailsCard {...props}>
      <Text color='muted' variant='body2'>
        Campaign
      </Text>
      <Text color='subtle' variant='subtitle2'>
        {campaign.name}
      </Text>
      <Text color='muted' variant='body2'>
        Advertiser
      </Text>
      <Text color='subtle' variant='subtitle2'>
        {campaign.advertiser?.name}
      </Text>
    </DetailsCard>
  );
}
