import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {MeasureRouteProps} from '../..';

export const getSpotifyLiftRoutes = ({
  organization,
}: {
  organization: MeasureRouteProps['organization'];
}) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, '/measure/lift/spotify'),
    basePath: '/:part(b|a|s|p)/:slug/measure/lift/spotify',
    routes: [
      {key: 'reports', exact: true, path: ''},
      {key: 'report', path: 'reports/:liftReportId'},
    ],
  });
};
