import dayjs from 'dayjs';
import {fmtCurrency} from '@core/lib/filters';
import {
  CloudsenseBrandMappingAttemptConfidence,
  SpotifyOrderObject,
  UnmappedSpotifyOrderFragment,
  UnmappedSpotifyOrdersQueryResult,
} from '@analytics/graphql-api';

const fmtDateStr = (dateStr: string | undefined) =>
  dateStr ? dayjs(dateStr).format('MMM DD, YYYY') : '-';

export const unmappedSalesOrderMapper = (
  row: UnmappedSpotifyOrderFragment
): UnmappedSalesOrder => ({
  id: row.id,
  cloudsenseAdvertiserName:
    row.brandMappingAttempt?.cloudsenseAdvertiserName ?? null,
  companyId: row.brandMappingAttempt?.company?.id ?? null,
  cost: row.cost ? fmtCurrency(row.cost, 'USD') : '-',
  startAt: fmtDateStr(row.startAt),
  endAt: fmtDateStr(row.endAt),
  organizationName:
    row?.brandMappingAttempt?.company?.organization?.name ?? '-',
  hasActivePixel: row?.brandMappingAttempt?.company?.hasActivePixel
    ? 'Yes'
    : 'No',
  organizationSlug: row?.brandMappingAttempt?.company?.organization?.slug
    ? `/${row?.brandMappingAttempt?.company?.organization?.slug}`
    : '-',
  campaignCount:
    row?.brandMappingAttempt?.company?.campaignCount?.toString() ?? '-',
  confidence: row?.brandMappingAttempt?.confidence,
  domain:
    row?.brandMappingAttempt?.company?.organization?.keys?.find(
      (key) => key?.role === 'PRODUCTION'
    )?.domain ?? '-',
  advertiserWebsite:
    row.brandMappingAttempt?.advertiserWebsite ?? '-',
});

export type UnmappedSalesOrder = {
  id: string;
  cloudsenseAdvertiserName: string | null;
  cost: string;
  startAt: string;
  endAt: string;
  organizationName: string;
  hasActivePixel: string;
  organizationSlug: string;
  campaignCount: string;
  companyId: string | null;
  confidence?: CloudsenseBrandMappingAttemptConfidence;
  domain: string;
  advertiserWebsite: string;
};
