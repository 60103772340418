import {graphql} from '@apollo/client/react/hoc';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import {FeedNav} from '@analytics/components/nav/ResearchNav';
import CompaniesTable from '@analytics/components/research/CompaniesTable';
import FeedCompaniesQuery from '@analytics/graphql-api/_old_queries/research/FeedCompaniesQuery';

const FeedsCompanies = ({organization, feed, data: {loading, error, me}}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const {companies} = me.organization.research.feed;

  return (
    <>
      <FeedNav organization={organization} feed={feed} />
      <CardHeader>Companies</CardHeader>
      <Card>
        <Element
          tag='p'
          rules={() => ({
            fontSize: '0.9375rem',
          })}>
          {companies.length} brands have bought ads on {feed.title}
        </Element>
        <CompaniesTable
          organization={organization}
          companies={companies}
          feed={feed}
          orderBy='-adsCount'
        />
      </Card>
    </>
  );
};

export default graphql(FeedCompaniesQuery, {
  options: ({organization, feed: {id}}) => ({
    variables: {
      organizationId: organization.id,
      id,
      limit: 100,
      orderBy: '-ads_count',
    },
  }),
})(FeedsCompanies);
