import {gql} from '@apollo/client';

export default gql`
  query getBrandOrganizationIntervalReachQuery(
    $id: ID!
    $after: Date
    $before: Date
    $publisherIds: [ID]
    $campaignIds: [ID]
    $trailing: Int
    $interval: String
    $attr: String
  ) {
    me {
      id
      organization(id: $id) {
        id
        overview {
          impressions(
            after: $after
            before: $before
            publisherIds: $publisherIds
            campaignIds: $campaignIds
          ) {
            intervalReachOverlap(
              trailing: $trailing
              interval: $interval
              attr: $attr
            ) {
              interval
              impressions
              reach
              priorImpressions
              priorReach
              combinedReach
              existingReach
              newReach
              percentNew
              impressionsToNewReach
              impressionsToPriorReach
            }
          }
        }
      }
    }
  }
`;
