import {useHistory} from 'react-router-dom';
import {Card} from '@core/ui/Content';
import {CampaignKind} from '@analytics/components/campaigns/_types';
import {CampaignCreateSelectTypeForm} from '@analytics/components/campaigns/create';
import {CampaignCreateSteps} from '@analytics/components/campaigns/create/CampaignCreateSteps';
import {CampaignsRouteProps} from '../../_routes';
import {toBrandCreateCampaignBasePath} from './_routes';

export function BrandSelectCampaignType({organization}: CampaignsRouteProps) {
  const history = useHistory();

  const goToStep2 = ({kind}: {kind: CampaignKind}) => {
    history.push(`${toBrandCreateCampaignBasePath(organization)}/${kind}`);
  };

  return (
    <>
      <CampaignCreateSteps active='campaign-type' isBrand />
      <Card>
        <CampaignCreateSelectTypeForm onSubmit={goToStep2} />
      </Card>
    </>
  );
}
