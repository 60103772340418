import {graphql} from '@apollo/client/react/hoc';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import CompaniesTable from '@analytics/components/research/CompaniesTable';
import PublisherCompaniesQuery from '@analytics/graphql-api/_old_queries/research/PublisherCompaniesQuery';

const PublisherCompanies = ({
  organization,
  publisher,
  data: {loading, error, me},
}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const {companies} = me.organization.research.publisher;

  return (
    <Card>
      <CardHeader>Brands</CardHeader>
      <Element
        tag='p'
        rules={() => ({
          fontSize: '0.9375rem',
        })}>
        {companies.length} brands have bought ads on {publisher.name}
      </Element>
      <CompaniesTable
        organization={organization}
        companies={companies}
        orderBy='-adsCount'
      />
    </Card>
  );
};

export default graphql(PublisherCompaniesQuery, {
  options: ({organization, publisher: {slug}}) => ({
    variables: {
      organizationId: organization.id,
      publisherSlug: slug,
      limit: 100,
      orderBy: '-ads_count',
    },
  }),
})(PublisherCompanies);
