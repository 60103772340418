import {gql} from '@apollo/client';

export default gql`
  query getAdvisorData(
    $columns: [String]!
    $filters: [AdvisorFilter]
    $includeTestData: Boolean!
    $limit: Int!
    $offset: Int!
    $organizationId: ID!
    $query: String!
    $sort: [String]!
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        advisor {
          results(
            query: {
              columns: $columns
              filters: $filters
              includeTestData: $includeTestData
              limit: $limit
              offset: $offset
              query: $query
              sort: $sort
            }
          ) {
            limit
            offset
            count
            rows {
              id
              feed {
                id
                title
                subtitle
                summary
                href
                image {
                  href
                  id
                  path
                }
              }
              cells {
                id
                values {
                  type
                  value
                  filterId
                  objectId
                }
              }
            }
          }
        }
      }
    }
  }
`;
