import {useState} from 'react';
import styled from 'styled-components';
import {t} from '@core/texts';
import {ToastMessage} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Submit} from '@core/ui/FormElements';
import {LoadingSpinner} from '@core/ui/Loading';
import {Overlay} from '@core/ui/Overlay';
import Select from '@core/ui/Select';
import {
  CampaignDocument,
  CampaignDynamicObject,
  CampaignPodcastObject,
  CampaignStreamingObject,
} from '@analytics/graphql-api';
import {
  AdType,
  getDeleteReasons,
  useDeleteMutation,
  useDeleteReasonQuery,
} from './helpers';

type Item =
  | CampaignDynamicObject
  | CampaignPodcastObject
  | CampaignStreamingObject;

type SelectDeleteReasonProps = {
  adType: 'dynamic' | 'podcast';
  campaignId: string;
  item: Item;
  onSelect: (_deleteReason: string) => void;
  organizationId: string;
};

const SelectDeleteReason = ({
  adType,
  campaignId,
  item,
  onSelect,
  organizationId,
}: SelectDeleteReasonProps) => {
  const {data, loading, error} = useDeleteReasonQuery(adType)({
    variables: {
      organizationId,
      campaignId,
      id: item.id,
    },
  });

  if (loading)
    return (
      <_LoadingContainer>
        <LoadingSpinner
          size='small'
          centered
          css={`
            padding: var(--spacing-2) 0;
          `}
        />
      </_LoadingContainer>
    );

  if (error)
    return (
      <ToastMessage rules={() => ({margin: '0 0 1rem'})}>
        Error retrieving delete reasons
      </ToastMessage>
    );

  const deleteReasons = getDeleteReasons(data);

  return (
    <Select
      items={deleteReasons}
      disabled={!!error}
      placeholder='Select Reason'
      propertyForName='deleteReasonDisplay'
      propertyForValue='deleteReason'
      onSelect={({deleteReason}) => onSelect(deleteReason)}
      outline
      rules={() => ({marginBottom: '1rem'})}
    />
  );
};

type BulkAdDeleteOverlayProps = {
  adType: AdType;
  campaignId: string;
  item: Item;
  onError?: () => void;
  onSuccess?: (_item: Item) => void;
  opened: boolean;
  organizationId: string;
  toggle: (_to?: boolean) => void;
};

export default function BulkAdDeleteOverlay({
  adType,
  campaignId,
  item,
  onError,
  onSuccess,
  opened,
  organizationId,
  toggle,
}: BulkAdDeleteOverlayProps) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteReason, setDeleteReason] = useState('');
  const [deleteAd] = useDeleteMutation(adType)();

  const onDeleteClick = async () => {
    setLoading(true);
    try {
      await deleteAd({
        variables: {
          input: {
            id: item.id,
            ...(adType !== 'streaming'
              ? {
                  deleteReason,
                }
              : {}),
          },
        },
        refetchQueries: [CampaignDocument],
      });

      onSuccess?.(item);
      toggle(false);
    } catch (e) {
      setError(true);
      onError?.();
    }
    setLoading(false);
  };

  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      closeOnOutsideClick={false}
      withBackdrop
      centered
      css={`
        display: flex;
        flex-direction: column;
        padding: 2rem;
      `}>
      {error && (
        <ToastMessage alertType='error'>{t('errors.network')}</ToastMessage>
      )}
      <_Title>
        Are you sure you want to delete "
        {item.name ?? ('feed' in item && item.feed?.title) ?? 'this item'}"?
      </_Title>
      <_Description>
        This ad will be deleted permanently. You can't undo this action.
      </_Description>
      {adType !== 'streaming' && (
        <SelectDeleteReason
          adType={adType}
          campaignId={campaignId}
          item={item}
          onSelect={setDeleteReason}
          organizationId={organizationId}
        />
      )}
      <_Footer>
        <Button onClick={() => toggle(false)}>Cancel</Button>
        <Submit loading={loading} onClick={onDeleteClick} color='error'>
          Delete
        </Submit>
      </_Footer>
    </Overlay>
  );
}

const _Title = styled.div`
  font-size: 1.15rem;
  font-weight: 700;
`;

const _Description = styled.div`
  color: var(--text-muted);
  font-size: 0.875rem;
  margin: 0.575rem 0 1rem;
`;

const _Footer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 0.5rem;
`;

const _LoadingContainer = styled.div`
  border: 1px solid var(--border-default);
  border-radius: 0.275rem;
  background: var(--bg-default);
  margin-bottom: 1rem;
`;
