import useSettings from '@analytics/components/settings/useSettings';
import {FetchedCampaignStreaming} from '@analytics/graphql-api';
import {toModeled} from '@analytics/lib/utils';

const useCampaignStreamingStreams = ({
  campaignStreaming,
}: {
  campaignStreaming: FetchedCampaignStreaming;
}) => {
  const [settings] = useSettings();
  const results = [];
  const campaignStreamingStreams =
    campaignStreaming?.campaignStreamingStreams ?? [];

  for (const item of campaignStreamingStreams) {
    const {
      id,
      cost,
      costToDate,
      reachTotal,
      downloadTotal,
      downloads,
      attribution,
      targetDownloadTotal,
      targetReachTotal,
    } = item;

    const dl = downloads && downloads.count ? downloads.count : downloadTotal;

    // deal with attribution.
    const _userTotal = toModeled(item, 'user', settings.modelled);
    const _reachTotal = reachTotal;
    let _spend = settings.spendByImpressionsDelivered ? costToDate : cost;

    if (attribution) {
      // Ugh.
      _spend = (_spend / downloadTotal) * dl;
    } else if (campaignStreaming.costToDate >= campaignStreaming.cost) {
      _spend = (dl / campaignStreaming.downloadTotal) * campaignStreaming.cost;
    }

    results.push({
      id,
      campaignStreamingName: campaignStreaming.name,
      costToDate: _spend,
      reachTotal,
      downloadTotal: dl,
      attributionTotal: _userTotal,
      purchaseTotal: toModeled(item, 'purchase', settings.modelled),
      valueTotal: toModeled(item, 'value', settings.modelled),
      installedTotal: toModeled(item, 'installed', settings.modelled),
      leadTotal: toModeled(item, 'lead', settings.modelled),
      targetDownloadTotal,
      targetReachTotal,
      cr: _reachTotal ? _userTotal / _reachTotal : 0,
      cpm: dl ? (_spend / dl) * 1000 : 0,
      frequency: _reachTotal ? dl / _reachTotal : 0,
      cpv: _reachTotal && _userTotal ? _spend / _userTotal : 0,
    });
  }

  return results.sort((a, b) => {
    return b.downloadTotal - a.downloadTotal;
  });
};

export default useCampaignStreamingStreams;
