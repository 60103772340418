import {comma, toPercent} from '@core/lib/filters';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Stack} from '@core/ui/Layout';
import Loading from '@core/ui/Loading';
import {Text} from '@core/ui/Text';
import {
  CampaignObject,
  OrganizationObject,
  useGetBrandCampaignAttributionUrlsQuery,
} from '@analytics/graphql-api';
import {useCampaignPixelRoutes} from '@analytics/screens/unified/campaigns/campaign/pixels/pixel/_routes';

export function AttributionUrls({
  organization,
  campaign,
  pixel,
  limit,
}: {
  organization: OrganizationObject;
  campaign: CampaignObject;
  pixel: {
    key: string;
  };
  limit?: number;
}) {
  const {data, loading, error} = useGetBrandCampaignAttributionUrlsQuery({
    variables: {
      organizationId: organization.id,
      id: campaign.id,
      offset: 0,
      limit: limit ? limit : 100,
    },
  });

  const {getRouteUrl} = useCampaignPixelRoutes({
    organization,
    campaign,
    pixelKey: pixel.key,
  });

  if (loading) return <Loading centered />;
  if (error) return <Alert severity='error'>{t('errors.network')}</Alert>;

  const sum = data?.me?.organization?.campaign?.attribution?.users?.urls?.sum;
  const nodes = (
    data?.me?.organization?.campaign?.attribution?.users?.urls?.nodes ?? []
  )
    .slice(0)
    .map((node) => Object.assign({id: node?.url, sum}, node));

  return (
    <DataTable
      emptyMessageText='No urls found'
      data={nodes}
      columns={columns}
      orderBy='-count'
      onClickRowPath={({url}) =>
        `${getRouteUrl('users')}?url=${encodeURIComponent(url)}`
      }
    />
  );
}

const columns: DataTableColumn[] = [
  {
    title: 'URL',
    accessor: 'urlPath',
    type: 'string',
    rule: () => ({wordBreak: 'break-all'}),
  },
  {
    title: 'Count',
    accessor: 'count',
    type: 'number',
    Cell: ({d: {count, sum}}) => (
      <Stack
        gap='var(--spacing-2)'
        justifyContent='flex-end'
        css={{whiteSpace: 'nowrap'}}>
        {comma(count)}
        <Text color='muted'>({toPercent(count / sum)})</Text>
      </Stack>
    ),
  },
];
