import {gql} from '@apollo/client';

export default gql`
  query getCampaignDynamicPodcastOmniChart(
    $organizationId: ID!
    $campaignId: ID!
    $campaignDynamicId: ID!
    $id: ID!
    $after: String
    $before: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          campaignDynamic(id: $campaignDynamicId) {
            id
            campaignDynamicPodcast(id: $id) {
              id
              attribution(after: $after, before: $before) {
                users {
                  hourlyVisitors {
                    count
                    hour
                  }
                }
              }

              downloads(after: $after, before: $before) {
                hourly {
                  hour
                  downloadClass
                  count
                }
              }
            }
          }
        }
      }
    }
  }
`;
