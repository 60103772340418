import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {
  LiftReportDeleteForm,
  LiftReportRange,
  LiftReportRunForm,
  LiftReportUpdateForm,
} from '@analytics/components/lift/IncrementalBrandLift';
import {MeasureLiftReportNav} from '@analytics/components/nav/MeasureLiftNav';
import {MeasureIncrementalLiftRouteProps} from '../_routes';
import {getLiftReportRoutes} from './_routes';

type Props = MeasureIncrementalLiftRouteProps & {
  match: {
    url: string;
    params: {
      liftReportId: string;
    };
  };
};

export function LiftReportIndex({
  organization,
  user,
  liftReports,
  match: {
    url,
    params: {liftReportId},
  },
}: Props) {
  const liftReport = liftReports.find(({id}) => id === liftReportId);

  if (!liftReport) {
    return <Redirect to={createOrgPath(organization, 'measure/lift')} />;
  }

  const {renderRoutes, routes} = getLiftReportRoutes({
    organization,
    liftReport,
    user,
  });

  return (
    <>
      <MeasureLiftReportNav
        organization={organization}
        liftReport={liftReport}
        isAdmin={user.isAdmin}
        items={routes}
      />
      <Switch>
        {renderRoutes({
          components: {
            liftReport: () => <Redirect to={`${url}/0/${liftReport.delta}`} />,
            delete: LiftReportDeleteForm,
            range: LiftReportRange,
            run: LiftReportRunForm,
            update: LiftReportUpdateForm,
          },
          extraProps: {
            organization,
            user,
            liftReport,
          },
        })}
      </Switch>
    </>
  );
}
