export default {
  'Auto Buyers': [
    'Intent - Auto Buyers',
    'Intent - Auto Buyers - All - New',
    'Intent - Auto Buyers - All - New or Used',
    'Intent - Auto Buyers - All - Type',
    'Intent - Auto Buyers - Current Vehicle Age',
    'Intent - Auto Buyers - Make',
    'Intent - Auto Buyers - Make - Acura',
    'Intent - Auto Buyers - Make - Audi',
    'Intent - Auto Buyers - Make - BMW',
    'Intent - Auto Buyers - Make - Buick',
    'Intent - Auto Buyers - Make - Cadillac',
    'Intent - Auto Buyers - Make - Cadillac - CTS',
    'Intent - Auto Buyers - Make - Chevrolet',
    'Intent - Auto Buyers - Make - Chrysler',
    'Intent - Auto Buyers - Make - Dodge',
    'Intent - Auto Buyers - Make - Fiat',
    'Intent - Auto Buyers - Make - Fisker',
    'Intent - Auto Buyers - Make - Ford',
    'Intent - Auto Buyers - Make - Ford - Super Duty',
    'Intent - Auto Buyers - Make - GMC',
    'Intent - Auto Buyers - Make - Honda',
    'Intent - Auto Buyers - Make - Hyundai',
    'Intent - Auto Buyers - Make - Infiniti',
    'Intent - Auto Buyers - Make - Jaguar',
    'Intent - Auto Buyers - Make - Jeep',
    'Intent - Auto Buyers - Make - Kia',
    'Intent - Auto Buyers - Make - Land Rover',
    'Intent - Auto Buyers - Make - Lexus',
    'Intent - Auto Buyers - Make - Lincoln',
    'Intent - Auto Buyers - Make - Maserati',
    'Intent - Auto Buyers - Make - Mazda',
    'Intent - Auto Buyers - Make - Mercedes',
    'Intent - Auto Buyers - Make - Mini',
    'Intent - Auto Buyers - Make - Mitsubishi',
    'Intent - Auto Buyers - Make - Nissan',
    'Intent - Auto Buyers - Make - Porsche',
    'Intent - Auto Buyers - Make - RAM',
    'Intent - Auto Buyers - Make - Saab',
    'Intent - Auto Buyers - Make - Smart',
    'Intent - Auto Buyers - Make - Subaru',
    'Intent - Auto Buyers - Make - Tesla',
    'Intent - Auto Buyers - Make - Toyota',
    'Intent - Auto Buyers - Make - Volvo',
    'Intent - Auto Buyers - Make - VW',
    'Intent - Auto Buyers - Manufacturers',
    'Intent - Auto Buyers - Manufacturers - US',
  ],
  CPG: [
    'Intent - CPG',
    'Intent - CPG - Baby',
    'Intent - CPG - Baby - Baby Food',
    'Intent - CPG - Baby - Baby Formula',
    'Intent - CPG - Baby - Disposable Diapers and Training Pants',
    'Intent - CPG - Baking and Cooking',
    'Intent - CPG - Baking and Cooking - Betty Crocker',
    'Intent - CPG - Baking and Cooking - Breading and Stuffing',
    'Intent - CPG - Baking and Cooking - Breading and Stuffing - Kraft',
    'Intent - CPG - Baking and Cooking - Duncan Hines',
    'Intent - CPG - Baking and Cooking - Pillsbury',
    'Intent - CPG - Baking and Cooking - Shortening and Oils',
    'Intent - CPG - Baking and Cooking - Sugar',
    'Intent - CPG - Beverages',
    'Intent - CPG - Beverages - Beer and Wine',
    'Intent - CPG - Beverages - Bottled Water',
    'Intent - CPG - Beverages - Carbonated Beverages',
    'Intent - CPG - Beverages - Carbonated Beverages - A and W',
    'Intent - CPG - Beverages - Carbonated Beverages - Coca Cola',
    'Intent - CPG - Beverages - Carbonated Beverages - Dr Pepper',
    'Intent - CPG - Beverages - Carbonated Beverages - Pepsi',
    'Intent - CPG - Beverages - Coffee',
    'Intent - CPG - Beverages - Juice and non-Carbonated',
    'Intent - CPG - Beverages - New Age Beverage',
    'Intent - CPG - Beverages - Powdered Instant Drinks',
    'Intent - CPG - Beverages - Refrigerated Drinks',
    'Intent - CPG - Beverages - Sports Drinks',
    'Intent - CPG - Beverages - Teas',
    'Intent - CPG - Brands',
    'Intent - CPG - Candy and Gum',
    'Intent - CPG - Candy and Gum - Candy',
    'Intent - CPG - Candy and Gum - Chocolate',
    'Intent - CPG - Candy and Gum - Gum',
    'Intent - CPG - Candy and Gum - Gum - Cadbury Adams',
    'Intent - CPG - Candy and Gum - Gum - Wrigleys',
    'Intent - CPG - Candy and Gum - Hershey',
    'Intent - CPG - Candy and Gum - Hershey - Reeses',
    'Intent - CPG - Cereal',
    'Intent - CPG - Cereal - General Mills',
    'Intent - CPG - Cereal - Hot',
    'Intent - CPG - Cereal - Kelloggs',
    'Intent - CPG - Cereal - Post Brands',
    'Intent - CPG - Condiments and Sauces',
    'Intent - CPG - Condiments and Sauces - Meat and Fish Sauce',
    'Intent - CPG - Condiments and Sauces - Mexican Sauce',
    'Intent - CPG - Condiments and Sauces - Mustard',
    'Intent - CPG - Condiments and Sauces - Oriental Sauce',
    'Intent - CPG - Condiments and Sauces - Pasta Sauce',
    'Intent - CPG - Condiments and Sauces - Salad Dressing',
    'Intent - CPG - Consumer Electronics',
    'Intent - CPG - Consumer Electronics - Batteries',
    'Intent - CPG - Consumer Electronics - Batteries - Energizer',
    'Intent - CPG - Cookies and Crackers',
    'Intent - CPG - Cookies and Crackers - Cookies',
    'Intent - CPG - Cookies and Crackers - Cookies - Keebler',
    'Intent - CPG - Cookies and Crackers - Cookies - Nabisco',
    'Intent - CPG - Cookies and Crackers - Cookies - Nabisco - Oreo',
    'Intent - CPG - Cookies and Crackers - Cookies - Pepperidge Farms',
    'Intent - CPG - Cookies and Crackers - Cookies - Sunshine Brands',
    'Intent - CPG - Cookies and Crackers - Crackers',
    'Intent - CPG - Cookies and Crackers - Crackers - Keebler',
    'Intent - CPG - Cookies and Crackers - Crackers - Nabisco',
    'Intent - CPG - Cookies and Crackers - Crackers - Pepperidge Farms',
    'Intent - CPG - Cookies and Crackers - Crackers - Sunshine Brands',
    'Intent - CPG - Dairy',
    'Intent - CPG - Dairy - Butter and Other Spreads',
    'Intent - CPG - Dairy - Butter and Other Spreads - Butter',
    'Intent - CPG - Dairy - Butter and Other Spreads - Cream Cheese',
    'Intent - CPG - Dairy - Butter and Other Spreads - Margarine',
    'Intent - CPG - Dairy - Butter and Other Spreads - Mayonaise',
    'Intent - CPG - Dairy - Butter and Other Spreads - Mayonaise - Kraft',
    'Intent - CPG - Dairy - Butter and Other Spreads - Sour Cream',
    'Intent - CPG - Dairy - Cheese',
    'Intent - CPG - Dairy - Coffee Creamer',
    'Intent - CPG - Dairy - Coffee Creamer - Nestle',
    'Intent - CPG - Dairy - Cream',
    'Intent - CPG - Dairy - Cream Cheese',
    'Intent - CPG - Dairy - Ice Cream',
    'Intent - CPG - Dairy - Ice Cream - Blue Bell',
    'Intent - CPG - Dairy - Ice Cream - Dreyers',
    'Intent - CPG - Dairy - Ice Cream - Dreyers - Edys',
    'Intent - CPG - Dairy - Milk',
    'Intent - CPG - Dairy - Milk Modifiers',
    'Intent - CPG - Dairy - Whipped Cream',
    'Intent - CPG - Dairy - Whipped Cream - Kraft',
    'Intent - CPG - Dairy - Yogurt',
    'Intent - CPG - Dairy - Yogurt - Dannon',
    'Intent - CPG - Dairy - Yogurt - Yoplait',
    'Intent - CPG - Deli',
    'Intent - CPG - Deli - Lunchmeat',
    'Intent - CPG - Desserts',
    'Intent - CPG - Food and Beverage - Wholesome Snacks Category - General Mills Brands',
    'Intent - CPG - Fresh Produce',
    'Intent - CPG - Fresh Produce - Fruit',
    'Intent - CPG - Fresh Produce - Fruit - Del Monte',
    'Intent - CPG - Fresh Produce - Poultry',
    'Intent - CPG - Fresh Produce - Sausage',
    'Intent - CPG - Fresh Produce - Vegetable',
    'Intent - CPG - Frozen Foods',
    'Intent - CPG - Frozen Foods - Appetizers',
    'Intent - CPG - Frozen Foods - Baked Goods',
    'Intent - CPG - Frozen Foods - Breakfast',
    'Intent - CPG - Frozen Foods - Frozen Entree',
    'Intent - CPG - Frozen Foods - Frozen Entree - Healthy Choice',
    'Intent - CPG - Frozen Foods - Frozen Entree - Lean Cuisine',
    'Intent - CPG - Frozen Foods - Frozen Entree - Michelinas',
    'Intent - CPG - Frozen Foods - Frozen Entree - Weight Watchers',
    'Intent - CPG - Frozen Foods - Frozen Pizza',
    'Intent - CPG - Frozen Foods - Frozen Pizza - Digiorno',
    'Intent - CPG - Frozen Foods - Frozen Potatoes and Onion Rings',
    'Intent - CPG - Frozen Foods - Frozen Potatoes and Onion Rings - Ore Ida',
    'Intent - CPG - Frozen Foods - Frozen Sandwiches',
    'Intent - CPG - Frozen Foods - Frozen Sausage',
    'Intent - CPG - Frozen Foods - Frozen Seafood',
    'Intent - CPG - Frozen Foods - Frozen Sweet Goods',
    'Intent - CPG - Frozen Foods - Frozen Sweet Goods - Toaster Pastries',
    'Intent - CPG - Frozen Foods - Frozen Vegetables',
    'Intent - CPG - Frozen Foods - Frozen Vegetables - Birds Eye',
    'Intent - CPG - Frozen Foods - Frozen Vegetables - Green Giant',
    'Intent - CPG - Frozen Foods - Frozen Waffles Pancakes and French Toast',
    'Intent - CPG - Frozen Foods - Frozen Waffles Pancakes and French Toast - Kelloggs',
    'Intent - CPG - Grains, Pasta and Beans',
    'Intent - CPG - Grains, Pasta and Beans - Grain Cakes',
    'Intent - CPG - Grains, Pasta and Beans - Pasta',
    'Intent - CPG - Grains, Pasta and Beans - Rice',
    'Intent - CPG - Groceries',
    'Intent - CPG - Groceries - Bread',
    'Intent - CPG - Groceries - Bread - Brownberry Brands',
    'Intent - CPG - Groceries - Breakfast Meat',
    'Intent - CPG - Groceries - Breakfast Meat - Hormel',
    'Intent - CPG - Groceries - Condiments',
    'Intent - CPG - Groceries - Condiments - Catsup',
    'Intent - CPG - Groceries - Eggs',
    'Intent - CPG - Groceries - Flour',
    'Intent - CPG - Groceries - Hot Dogs',
    'Intent - CPG - Groceries - Hot Dogs - Oscar Meyer',
    'Intent - CPG - Groceries - Mexican Food',
    'Intent - CPG - Groceries - Novelty',
    'Intent - CPG - Groceries - Novelty - Nestle',
    'Intent - CPG - Groceries - Nutritional',
    'Intent - CPG - Groceries - Pickle and Relish',
    'Intent - CPG - Groceries - Ramen Noodles',
    'Intent - CPG - Groceries - Ramen Noodles - Maruchan',
    'Intent - CPG - Groceries - Ramen Noodles - Nissin',
    'Intent - CPG - Groceries - Refrigerated Baked Goods',
    'Intent - CPG - Groceries - Refrigerated Baked Goods - Nestle',
    'Intent - CPG - Groceries - Refrigerated Baked Goods - Pillsbury',
    'Intent - CPG - Groceries - Refrigerated Sides',
    'Intent - CPG - Groceries - Refrigerated Sides - Crystal Farms',
    'Intent - CPG - Groceries - Shelf Stable and Dry Potato Mix',
    'Intent - CPG - Groceries - Shelf Stable Dry Soup and Chili Mix',
    'Intent - CPG - Groceries - Shelf Stable Dry Soup and Chili Mix - Lipton',
    'Intent - CPG - Groceries - Shelf Stable Fish',
    'Intent - CPG - Groceries - Shelf Stable Fruit',
    'Intent - CPG - Groceries - Shelf Stable Gravy',
    'Intent - CPG - Groceries - Shelf Stable Liquid Soup',
    'Intent - CPG - Groceries - Shelf Stable Liquid Soup - Campbells',
    'Intent - CPG - Groceries - Shelf Stable Liquid Soup - Campbells - Chunky',
    'Intent - CPG - Groceries - Shelf Stable Liquid Soup - Progresso',
    'Intent - CPG - Groceries - Shelf Stable Liquid Soup - Swanson',
    'Intent - CPG - Groceries - Shelf Stable Meats',
    'Intent - CPG - Groceries - Shelf Stable Meats - Hormel',
    'Intent - CPG - Groceries - Shelf Stable Meats - Hunts',
    'Intent - CPG - Groceries - Shelf Stable Vegetable',
    'Intent - CPG - Groceries - Shelf Stable Vegetable - Bush Brands',
    'Intent - CPG - Groceries - Tomato Paste and Sauce',
    'Intent - CPG - Groceries - Vegetables and Herbs',
    'Intent - CPG - Groceries - Whipped Toppings',
    'Intent - CPG - Groceries - Wholesome Snacks',
    'Intent - CPG - Health and Beauty',
    'Intent - CPG - Health and Beauty - Antiperspirants and Deodorants',
    'Intent - CPG - Health and Beauty - Cosmetics',
    'Intent - CPG - Health and Beauty - Cough and Cold Relief',
    'Intent - CPG - Health and Beauty - Deodorizer and Freshener',
    'Intent - CPG - Health and Beauty - Feminine Hygiene',
    'Intent - CPG - Health and Beauty - Hair Care',
    'Intent - CPG - Health and Beauty - Hair Care - Hair Coloring',
    'Intent - CPG - Health and Beauty - Hair Care - Hair Styling',
    'Intent - CPG - Health and Beauty - Hair Care - Shampoo and Conditioner',
    'Intent - CPG - Health and Beauty - Oral Care',
    'Intent - CPG - Health and Beauty - Pain Relievers',
    'Intent - CPG - Health and Beauty - Shaving and Hair Removal',
    'Intent - CPG - Health and Beauty - Skin Care',
    'Intent - CPG - Health and Beauty - Skin Care - Facial',
    'Intent - CPG - Health and Beauty - Skin Care - Facial Tissues',
    'Intent - CPG - Health and Beauty - Vitamins',
    'Intent - CPG - Health Food',
    'Intent - CPG - Health Food - Dried Fruit',
    'Intent - CPG - Household Supplies',
    'Intent - CPG - Household Supplies - Bath Tissue',
    'Intent - CPG - Household Supplies - Bath Tissue - Charmin',
    'Intent - CPG - Household Supplies - Bath Tissue - Kleenex Brands',
    'Intent - CPG - Household Supplies - Charcoal Logs',
    'Intent - CPG - Household Supplies - Cleaning Supplies',
    'Intent - CPG - Household Supplies - Cleaning Supplies - Swiffer',
    'Intent - CPG - Household Supplies - Dish Detergent',
    'Intent - CPG - Household Supplies - Dish Detergent - Palmolive',
    'Intent - CPG - Household Supplies - Disposable Bags',
    'Intent - CPG - Household Supplies - Fabric Softener',
    'Intent - CPG - Household Supplies - Fabric Treatments',
    'Intent - CPG - Household Supplies - First Aid',
    'Intent - CPG - Household Supplies - Food Storage',
    'Intent - CPG - Household Supplies - Food Storage - Lunch Bag',
    'Intent - CPG - Household Supplies - Household Plastics',
    'Intent - CPG - Household Supplies - Kitchen Accessories',
    'Intent - CPG - Household Supplies - Laundry Detergent',
    'Intent - CPG - Household Supplies - Paper Supplies',
    'Intent - CPG - Household Supplies - Soap',
    'Intent - CPG - Infant Care',
    'Intent - CPG - News',
    'Intent - CPG - Paper and Plastic Products',
    'Intent - CPG - Pet Supplies',
    'Intent - CPG - Pet Supplies - Cat',
    'Intent - CPG - Pet Supplies - Cat - Cat Food',
    'Intent - CPG - Pet Supplies - Cat - Cat Food - Purina',
    'Intent - CPG - Pet Supplies - Cat - Cat Food - Whiskas',
    'Intent - CPG - Pet Supplies - Cat - Cat Litter',
    'Intent - CPG - Pet Supplies - Cat - Cat Litter - Purina',
    'Intent - CPG - Pet Supplies - Dog',
    'Intent - CPG - Pet Supplies - Dog - Dog Food',
    'Intent - CPG - Pet Supplies - Dog - Dog Food - Purina',
    'Intent - CPG - Prepared Foods',
    'Intent - CPG - Prepared Foods - Betty Crocker',
    'Intent - CPG - Prepared Foods - Knorr',
    'Intent - CPG - Prepared Foods - Kraft',
    'Intent - CPG - Prepared Foods - Prepared Beans',
    'Intent - CPG - Prepared Foods - Refrigerated Meals',
    'Intent - CPG - Prepared Foods - Velveeta',
    'Intent - CPG - Snack Desserts',
    'Intent - CPG - Snack Desserts - Desserts',
    'Intent - CPG - Snack Desserts - Little Debbie',
    'Intent - CPG - Snack Desserts - Ready to Eat Desserts',
    'Intent - CPG - Snack Desserts - Salty Snacks',
    'Intent - CPG - Snack Desserts - Salty Snacks - Dip',
    'Intent - CPG - Snack Desserts - Salty Snacks - Kelloggs',
    'Intent - CPG - Snack Desserts - Salty Snacks - Lays',
    'Intent - CPG - Snack Desserts - Salty Snacks - Nuts',
    'Intent - CPG - Snack Desserts - Toaster Pastries',
    'Intent - CPG - Spices and Seasonings',
    'Intent - CPG - Spices and Seasonings - Extracts',
    'Intent - CPG - Spices and Seasonings - McCormick',
    'Intent - CPG - Spreads, Jams, and Jellies',
    'Intent - CPG - Spreads, Jams, and Jellies - Pancake Syrup',
    'Intent - CPG - Spreads, Jams, and Jellies - Peanut Butter',
  ],
  'Finance and Insurance': [
    'Intent - Finance and Insurance',
    'Intent - Finance and Insurance - Auto Insurance',
    'Intent - Finance and Insurance - Banking',
    'Intent - Finance and Insurance - Credit Cards',
    'Intent - Finance and Insurance - Loans',
    'Intent - Finance and Insurance - Mortgages',
  ],
  Propensity: [
    'Intent - Propensity',
    'Intent - Propensity - Auto',
    'Intent - Propensity - Auto - Budget',
    'Intent - Propensity - Auto - Insurance',
    'Intent - Propensity - Finance',
    'Intent - Propensity - Finance - Insurance',
    'Intent - Propensity - Finance - Insurance - Insurance',
    'Intent - Propensity - Personal Tech',
    'Intent - Propensity - Personal Tech - Mobile',
    'Intent - Propensity - Retail',
    'Intent - Propensity - Travel',
    'Intent - Propensity - Travel - Cruise',
    'Intent - Propensity - Travel - International',
    'Intent - Propensity - Travel - Latin America',
    'Intent - Propensity - Travel - Likely Gamblers',
    'Intent - Propensity - Travel - Resort',
  ],
  Services: [
    'Intent - Services',
    'Intent - Services - Arts and Entertainment',
    'Intent - Services - Biz , Professional Service',
    'Intent - Services - Finance and Insurance',
    'Intent - Services - Finance and Insurance - Auto Insurance',
    'Intent - Services - Home and Garden',
    'Intent - Services - Personal Services and Care',
    'Intent - Services - Pets',
    'Intent - Services - Real Estate, Moving, Storage',
    'Intent - Services - Real Estate, Moving, Storage - House and Apartment Buying',
    'Intent - Services - Real Estate, Moving, Storage - House and Apartment Rentals',
    'Intent - Services - Restaurants',
    'Intent - Services - Telecommunications',
  ],
  Shopping: [
    'Intent - Shopping',
    'Intent - Shopping - Auto Parts and Repair',
    'Intent - Shopping - Entertainment and Media',
    'Intent - Shopping - Fashion',
    'Intent - Shopping - Fashion - Accessories',
    'Intent - Shopping - Fashion - Apparel',
    'Intent - Shopping - Fashion - Shoes',
    'Intent - Shopping - Fashion - Shoes - Sneakers',
    'Intent - Shopping - Home and Garden',
    'Intent - Shopping - Home and Garden - Appliances',
    'Intent - Shopping - Home and Garden - Furniture',
    'Intent - Shopping - Mobile - By Carrier',
    'Intent - Shopping - Mobile - By Make',
    'Intent - Shopping - Mobile - By Platform',
    'Intent - Shopping - Personal Tech',
    'Intent - Shopping - Personal Tech - Computers',
    'Intent - Shopping - Personal Tech - Video Games',
    'Intent - Shopping - Pets',
    'Intent - Shopping - Retail',
    'Intent - Shopping - Retail - Brands',
  ],
  Tickets: ['Intent - Tickets', 'Intent - Tickets - Online Purchase'],
  Travel: [
    'Intent - Travel',
    'Intent - Travel - Accommodation',
    'Intent - Travel - Car Rentals',
    'Intent - Travel - Cruises',
    'Intent - Travel - Departure',
    'Intent - Travel - Departure - Africa - Tunisia',
    'Intent - Travel - Departure - Europe - Belgium',
    'Intent - Travel - Departure - Europe - Czech Republic',
    'Intent - Travel - Departure - Europe - France',
    'Intent - Travel - Departure - Europe - Germany',
    'Intent - Travel - Departure - Europe - Ireland',
    'Intent - Travel - Departure - Europe - Netherlands',
    'Intent - Travel - Departure - Europe - Portugal',
    'Intent - Travel - Departure - Europe - Spain',
    'Intent - Travel - Departure - Europe - Sweden',
    'Intent - Travel - Departure - Europe - UK',
    'Intent - Travel - Departure - Middle East',
    'Intent - Travel - Departure - Middle East - Turkey',
    'Intent - Travel - Departure - North America - US',
    'Intent - Travel - Departure - North America - US - Arizona',
    'Intent - Travel - Departure - North America - US - California',
    'Intent - Travel - Departure - North America - US - Colorado',
    'Intent - Travel - Departure - North America - US - Florida',
    'Intent - Travel - Departure - North America - US - Georgia',
    'Intent - Travel - Departure - North America - US - Illinois',
    'Intent - Travel - Departure - North America - US - Minnesota',
    'Intent - Travel - Departure - North America - US - Missouri',
    'Intent - Travel - Departure - North America - US - Nevada',
    'Intent - Travel - Departure - North America - US - Texas',
    'Intent - Travel - Departure - North America - US - Washington',
    'Intent - Travel - Destination',
    'Intent - Travel - Destination - Africa',
    'Intent - Travel - Destination - Africa - Morocco',
    'Intent - Travel - Destination - Africa - Tunisia',
    'Intent - Travel - Destination - Asia',
    'Intent - Travel - Destination - Asia - China',
    'Intent - Travel - Destination - Asia - India',
    'Intent - Travel - Destination - Asia - India - Mumbai',
    'Intent - Travel - Destination - Asia - Indonesia',
    'Intent - Travel - Destination - Asia - Japan',
    'Intent - Travel - Destination - Asia - Malaysia',
    'Intent - Travel - Destination - Asia - Singapore',
    'Intent - Travel - Destination - Asia - South Korea',
    'Intent - Travel - Destination - Asia - Taiwan - Taipei',
    'Intent - Travel - Destination - Asia - Thailand',
    'Intent - Travel - Destination - Central America',
    'Intent - Travel - Destination - Europe',
    'Intent - Travel - Destination - Europe - Belgium',
    'Intent - Travel - Destination - Europe - Czech Republic',
    'Intent - Travel - Destination - Europe - France',
    'Intent - Travel - Destination - Europe - Germany',
    'Intent - Travel - Destination - Europe - Greece',
    'Intent - Travel - Destination - Europe - Ireland',
    'Intent - Travel - Destination - Europe - Netherlands',
    'Intent - Travel - Destination - Europe - Portugal',
    'Intent - Travel - Destination - Europe - Spain',
    'Intent - Travel - Destination - Europe - Sweden',
    'Intent - Travel - Destination - Europe - Turkey',
    'Intent - Travel - Destination - Europe - UK',
    'Intent - Travel - Destination - Middle East',
    'Intent - Travel - Destination - North America',
    'Intent - Travel - Destination - North America - Canada',
    'Intent - Travel - Destination - North America - Canada - Ontario',
    'Intent - Travel - Destination - North America - Canada - Quebec',
    'Intent - Travel - Destination - North America - US',
    'Intent - Travel - Destination - North America - US - Arizona',
    'Intent - Travel - Destination - North America - US - California',
    'Intent - Travel - Destination - North America - US - Colorado',
    'Intent - Travel - Destination - North America - US - Florida',
    'Intent - Travel - Destination - North America - US - Georgia',
    'Intent - Travel - Destination - North America - US - Illinois - Chicago',
    'Intent - Travel - Destination - North America - US - Maryland',
    'Intent - Travel - Destination - North America - US - Massachusetts',
    'Intent - Travel - Destination - North America - US - Michigan',
    'Intent - Travel - Destination - North America - US - Missouri',
    'Intent - Travel - Destination - North America - US - Nevada',
    'Intent - Travel - Destination - North America - US - New York',
    'Intent - Travel - Destination - North America - US - North Carolina',
    'Intent - Travel - Destination - North America - US - Oregon',
    'Intent - Travel - Destination - North America - US - Pennsylvania',
    'Intent - Travel - Destination - North America - US - Texas',
    'Intent - Travel - Destination - North America - US - Washington - Washington',
    'Intent - Travel - Hyper Intent',
    'Intent - Travel - Hyper Intent - Travellers',
    'Intent - Travel - Vacation Types',
    'Intent - Travel - Vacations Types',
    'Intent - Travel - Vaction Types',
  ],
};
