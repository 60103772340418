import {gql} from '@apollo/client';

export default gql`
  mutation createBrandInvites($input: CreateBrandInvitesInput!) {
    authed {
      success
    }
    createBrandInvites(input: $input) {
      success
      organizationId
    }
  }
`;
