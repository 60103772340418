import React from 'react';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {FeedImage} from '@core/ui/Image';
import Unclassified from '@analytics/components/Unclassified';
import campaignTableColumns from '@analytics/lib/campaignTableColumns';
import useCampaignDynamicPodcasts from '@analytics/lib/hooks/useCampaignDynamicPodcasts';

const PodcastCell = ({data: {campaignDynamicName, feed, id, url}}) => {
  return (
    <Element
      rules={() => ({
        display: 'flex',
        alignItems: 'center',
        minWidth: '15.625rem',
      })}>
      {feed ? (
        <>
          <Element
            rules={() => ({
              flex: '0 0 72px',
              marginRight: '0.625rem',
            })}>
            <FeedImage feed={feed} />
          </Element>
          <Element
            rules={({theme}) => ({
              color: theme.textPrimary,
              flex: 1,
              fontWeight: 500,
            })}>
            {feed.title}
          </Element>
        </>
      ) : (
        <Unclassified name={campaignDynamicName} format='dynamic' />
      )}
    </Element>
  );
};

const CampaignDynamicPodcastsTable = ({
  organization,
  campaign,
  campaignDynamic,
  campaignDynamicPodcasts,
}) => {
  const data = useCampaignDynamicPodcasts(campaignDynamic.campaignDynamicPodcasts, {
    campaignDynamic,
    organization,
    campaign,
  });

  const columns = [
    {
      title: 'Podcast',
      accessor: 'title',
      type: 'string',
      Cell: PodcastCell,
    },
  ].concat(campaignTableColumns(organization, campaign, data));

  return (
    <DataTable
      columns={columns}
      orderBy='-downloadTotal'
      data={data}
      emptyMessageText='No podcasts found.'
      onClickRowPath={({url}) => url}
    />
  );
};

export default CampaignDynamicPodcastsTable;
