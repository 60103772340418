import {getRandomGradient} from './helpers';

export type UserAvatarSize = 'large' | 'small';

export interface UserAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: UserAvatarSize;
  user: {
    email?: string;
    firstName: string;
    lastName: string;
    [x: string]: any;
  };
  withHoverOutline?: boolean;
}

export const UserAvatar = ({
  size = 'large',
  user,
  withHoverOutline = false,
  ...props
}: UserAvatarProps) => {
  return (
    <div
      aria-label={`User avatar: ${user.firstName} ${user.lastName}`}
      css={`
        align-items: center;
        background: ${getRandomGradient(user.firstName)};
        border-radius: 100%;
        box-shadow: inset 0 0 0 1px hsl(0deg 0% 100% / 20%);
        color: var(--white);
        display: flex;
        flex-shrink: 0;
        font-weight: 500;
        font-size: ${size === 'large' ? '0.6875rem' : '0.625rem'};
        height: ${size === 'large' ? '2rem' : '1.75rem'};
        justify-content: center;
        text-transform: uppercase;
        width: ${size === 'large' ? '2rem' : '1.75rem'};
        :hover {
          box-shadow: ${withHoverOutline
            ? '0 0 0 0.1875rem var(--bg-muted)'
            : ''};
        }
      `}
      {...props}>
      {user.firstName.charAt(0)}
      {user.lastName.charAt(0)}
    </div>
  );
};
