import styled from 'styled-components';
import {ButtonIcon} from '@core/ui/Button';

export const Column = styled.div<{withRightBorder?: boolean}>`
  border-right: ${(div) =>
    div.withRightBorder && '1px solid var(--border-default)'};
  padding: 0.625rem;
`;

export const ColumnContent = styled.div`
  max-height: 14rem;
  overflow-y: auto;
`;

export const ColumnItem = styled.div<{sortable?: boolean}>`
  align-items: center;
  background-color: var(--bg-overlay);
  border-radius: 0.375rem;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: ${(div) =>
    div.sortable ? 'max-content 1fr max-content' : '1fr max-content'};
  padding: ${(div) => (div.sortable ? '0.375rem' : '0.375rem 0.675rem')};
  :hover {
    background: var(--bg-default-hover);
  }
`;

export const SelectedIcon = styled(ButtonIcon)`
  color: var(--green);
  height: 100%;
  padding: 0;
  :hover svg {
    color: var(--green);
    filter: brightness(0.9);
  }
  :active svg {
    color: var(--green);
    filter: brightness(1.1);
  }
`;

export const RemoveIcon = styled(ButtonIcon)`
  color: var(--icon-subtle);
  padding: 0;
  pointer-events: auto;
  svg {
    height: 20px;
    :hover {
      filter: brightness(0.9);
    }
  }
`;

export const ColumnsInputContainer = styled.div<{invalid?: boolean}>`
  border: 1px solid
    ${(div) => (div.invalid ? 'var(--red-orange)' : 'var(--border-default)')};
  border-radius: 0.325rem;
  display: grid;
  font-size: 0.875rem;
  grid-template-columns: 1fr 1fr;
  height: 18rem;
`;

export const SearchContainer = styled.div`
  display: grid;
  padding: 0 0.375rem 0.375rem 0;
  grid-template-columns: 1fr max-content;
  gap: 0.5rem;
`;
