import {comma, toCampaignCurrency, toFixed, toPercent} from '@core/lib/filters';
import {t} from '@core/texts/';
import useSettings from '@analytics/components/settings/useSettings';
import {impressionsForPlacements} from '@analytics/lib/placements';
import {toModeled} from '@analytics/lib/utils';

const useCampaignStats = ({campaign, instance}) => {
  const [settings] = useSettings();
  const {multiplier} = campaign;
  const {reachTotal, cost, costToDate} = instance;

  const fmtCurrency = toCampaignCurrency(campaign.currency);

  // kill this when marketing dies.
  const valueForModelled = (value) => {
    return settings.modelled ? value * multiplier : value;
  };

  let downloadTotal =
    instance.downloads && instance.downloads.count
      ? instance.downloads.count
      : instance.downloadTotal;

  if (settings.impressionsByPlacements) {
    if (instance.podcast) {
      downloadTotal = impressionsForPlacements({
        value: downloadTotal,
        campaignPodcast: instance,
      });
    } else if (instance.campaignPodcasts) {
      downloadTotal = impressionsForPlacements({
        value: downloadTotal,
        campaign: instance,
      });
    }
  }

  const attributionTotal = settings.modelled
    ? instance.userTotalModeled
    : instance.userTotal;
  const purchaseTotal = toModeled(instance, 'purchase', settings.modelled);
  const valueTotal = toModeled(instance, 'value', settings.modelled);
  const installedTotal = toModeled(instance, 'installed', settings.modelled);

  const targetDownloadTotal = valueForModelled(instance.targetDownloadTotal);
  const targetReachTotal = valueForModelled(instance.targetReachTotal);

  const _cost = settings.spendByImpressionsDelivered ? costToDate : cost;

  const verifiedReach = instance.verifiedReach;
  const cac = purchaseTotal ? _cost / purchaseTotal : 0;
  const aov = purchaseTotal ? valueTotal / purchaseTotal : 0;
  const frequency = reachTotal ? downloadTotal / reachTotal : 0;
  const verifiedFrequency = verifiedReach ? downloadTotal / verifiedReach : 0;
  const isPpa = campaign?.isPpa;
  const isMegaphone = campaign?.isMegaphone? campaign?.isMegaphone : false;
  const cpm = isPpa ? 1 : downloadTotal ? (_cost / downloadTotal) * 1000 : 0;
  const cr = reachTotal ? attributionTotal / reachTotal : 0;
  const targetCr = targetReachTotal ? targetReachTotal / reachTotal : 0;

  return {
    aov: {
      value: aov,
      displayValue: fmtCurrency(aov),
      ...t('stats.aov'),
    },
    cac: {
      value: cac,
      displayValue: fmtCurrency(cac),
      ...t('stats.cac'),
    },
    cpm: {
      value: cpm,
      displayValue: fmtCurrency(cpm),
      ...t('stats.cpm', settings),
    },
    revenue: {
      value: valueTotal,
      displayValue: fmtCurrency(valueTotal),
      ...t('stats.revenue'),
    },
    reach: {
      value: reachTotal,
      displayValue: comma(reachTotal),
      ...t('stats.reach'),
    },
    visitors: {
      value: attributionTotal,
      displayValue: comma(attributionTotal),
      ...t('stats.visitors'),
    },
    spend: {
      value: _cost,
      displayValue: fmtCurrency(_cost),
      ...t('stats.spend', {
        spendByImpressionsDelivered: settings.spendByImpressionsDelivered,
      }),
    },
    cr: {
      value: cr,
      displayValue: toPercent(cr),
      ...t('stats.cr'),
    },
    impressions: {
      value: downloadTotal,
      displayValue: comma(downloadTotal),
      ...t('stats.impressions'),
    },
    frequency: {
      value: frequency,
      displayValue: toFixed(frequency),
      ...t('stats.frequency'),
    },
    installs: {
      value: installedTotal,
      displayValue: toFixed(installedTotal),
      ...t('stats.installs'),
    },
    purchases: {
      value: purchaseTotal,
      displayValue: toFixed(purchaseTotal),
      ...t('stats.purchases'),
    },
    targetDownloadTotal: {
      value: targetDownloadTotal,
      displayValue: toFixed(targetDownloadTotal),
      ...t('stats.targetDownload'),
    },
    targetReachTotal: {
      value: targetReachTotal,
      displayValue: toFixed(targetReachTotal),
      ...t('stats.targetReach'),
    },
    targetCr: {
      value: targetCr,
      displayValue: toPercent(targetCr),
      ...t('stats.targetCr'),
    },
    verifiedReach: {
      value: verifiedReach,
      displayValue: comma(verifiedReach),
      iconName: 'spotify',
      ...t('stats.verifiedReach'),
    },
    verifiedFrequency: {
      hidden: isMegaphone,
      value: verifiedFrequency,
      displayValue: toFixed(verifiedFrequency),
      iconName: 'spotify',
      ...t('stats.verifiedFrequency'),
    },
  };
};

export default useCampaignStats;
