import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignDynamicPodcastRouteProps} from '..';
import CampaignDynamicEpisodeIndex from './episode';

export function CampaignDynamicEpisodesIndex({
  user,
  organization,
  campaign,
  campaignDynamic,
  campaignDynamicPodcast,
}: CampaignDynamicPodcastRouteProps) {
  return (
    <Switch>
      <PropsRoute
        path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/dynamics/:campaignDynamicId/podcasts/:campaignDynamicPodcastId/episodes/:campaignDynamicEpisodeId'
        campaign={campaign}
        user={user}
        organization={organization}
        campaignDynamic={campaignDynamic}
        campaignDynamicPodcast={campaignDynamicPodcast}
        component={CampaignDynamicEpisodeIndex}
      />
    </Switch>
  );
}
