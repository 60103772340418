import {gql} from '@apollo/client';

export default gql`
  query getResearchPublishers($organizationId: ID!, $query: String!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          publishers(query: $query) {
            id
            name
          }
        }
      }
    }
  }
`;
