import {useState} from 'react';
import {
  Chip,
  IconExclamationCircle,
  IconSpotifyLogo,
  Image,
  Tooltip,
  TooltipTrigger,
} from '@spotify-internal/encore-web';
import CloudsenseImage from './cloudsense.svg';

const ComponentWithTooltip = ({children, tooltipText}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <TooltipTrigger
      overlay={
        showTooltip && <Tooltip colorSet='invertedDark'>{tooltipText}</Tooltip>
      }
      style={{verticalAlign: 'middle', whiteSpace: 'pre-wrap', marginRight: 30}}
      placement='end'
      onShow={() => setShowTooltip(true)}
      onHide={() => setShowTooltip(false)}>
      {children}
    </TooltipTrigger>
  );
};

export const ExclamationIcon = () => (
  <ComponentWithTooltip tooltipText='This line item requires review before being assigned to an organization in Spotify Ad Analytics'>
    <IconExclamationCircle size='medium' semanticColor='essentialNegative' />
  </ComponentWithTooltip>
);

export const CloudsenseIcon = () => (
  <ComponentWithTooltip tooltipText='This data in this column is coming from Cloudsense'>
    <Image
      src={CloudsenseImage}
      alt='cloudsense'
      style={{marginLeft: 5, verticalAlign: 'middle'}}
    />
  </ComponentWithTooltip>
);

export const SpotifyIcon = () => (
  <ComponentWithTooltip tooltipText='This data in this column is coming from Spotify Ad Analytics'>
    <IconSpotifyLogo
      size='small'
      style={{marginLeft: 5, verticalAlign: 'middle'}}
    />
  </ComponentWithTooltip>
);

export const InternalChip = () => (
  <ComponentWithTooltip tooltipText='This page is only viewable to internal Spotify employees.'>
    <Chip
      selected
      selectedColorSet='brightAccent'
      size='sm'
      style={{margin: 0}}>
      Internal
    </Chip>
  </ComponentWithTooltip>
);
