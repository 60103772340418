import {gql} from '@apollo/client';

export default gql`
  query getBrandCampaignPodcastOmniChart(
    $organizationId: ID!
    $campaignId: ID!
    $id: ID!
    $after: String
    $before: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          campaignPodcast(id: $id) {
            id
            episodes(after: $after, before: $before) {
              id
              title
              image {
                id
                path
              }
              published
            }

            attribution(after: $after, before: $before) {
              users {
                hourlyVisitors {
                  count
                  hour
                }
              }
            }

            downloads(after: $after, before: $before) {
              hourly {
                hour
                downloadClass
                count
              }
            }
          }
        }
      }
    }
  }
`;
