import data from 'country-region-data/dist/data-umd';

const findCountry = (iso2Code) => {
  if (typeof iso2Code === 'string') {
    const country = data.find((item) => {
      return item.countryShortCode === iso2Code.toUpperCase();
    });

    if (country) {
      country.flag = `https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.7/svg/${iso2Code.toLowerCase()}.svg`;
      return country;
    }
  }
  return null;
};

const findRegion = ({countryIso2Code, regionIso2Code}) => {
  const country = findCountry(countryIso2Code);

  if (country) {
    const region = country.regions.find((item) => {
      return item.shortCode === regionIso2Code.toUpperCase();
    });

    if (region) {
      region.country = country;
      return region;
    }
  }
  return null;
};

export {findCountry, findRegion};
