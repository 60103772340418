import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import stopwords from '@core/lib/stopwords';
import {getTimestamp} from '@core/lib/time';
import {Element} from '@core/style';
import {GREEN} from '@core/style';
import {Button} from '@core/ui/Button';
import Loading from '@core/ui/Loading';
import {Overlay} from '@core/ui/Overlay';
import AudioPlayer from '@analytics/components/audio/AudioPlayer';
import {TranscriptStatic} from '@analytics/components/transcript';
import RejectAd from './mutations/RejectAd';
import UnknownAd from './mutations/UnknownAd';
import UpdateAd from './mutations/UpdateAd';

// TODO
// add list of podcast names and episodes where an ad appears
// so we can figure out what the promo code or url is if unclear

class AcceptButton extends React.Component {
  state = {
    hover: false,
  };

  render() {
    const {title, value, onClick} = this.props;
    const {hover} = this.state;

    const disabled = !value;

    return (
      <Element
        style-hover={hover}
        style-disabled={disabled}
        rules={({hover, disabled}) => ({
          display: 'flex',
          marginBottom: '0.75rem',
          border: `1px solid ${disabled ? '#eee' : GREEN}`,
          padding: '0.625rem',
          cursor: disabled ? 'inherit' : 'pointer',
          background: disabled ? '#eee' : hover ? GREEN : '#fff',
          borderRadius: '0.25rem',
        })}
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
        onMouseEnter={() => {
          this.setState({
            hover: true,
          });
        }}
        onMouseLeave={() => {
          this.setState({
            hover: false,
          });
        }}>
        <Element rules={() => ({flex: 1})}>
          <Element
            style-hover={!disabled && hover}
            rules={({hover}) => ({
              fontSize: '12px',
              fontWeight: '700',
              color: hover ? '#eee' : '#999',
            })}>
            {title}
          </Element>
          <Element
            tag='b'
            style-hover={!disabled && hover}
            rules={({hover}) => ({
              color: hover ? '#fff' : '#333',
            })}>
            {value ? value : '(none)'}
          </Element>
        </Element>
        {disabled ? (
          ''
        ) : (
          <Element
            style-hover={hover}
            rules={({hover}) => ({
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              color: hover ? '#fff' : GREEN,
              fontSize: '20px',
              cursor: 'pointer',
            })}>
            {'\u2714'}
          </Element>
        )}
      </Element>
    );
  }
}

class ClassifyAd extends React.Component {
  state = {
    loading: false,
    canplay: false,
    playing: false,
    currentTime: 0,
    duration: null,
    audioSrc: null,
    confirm: false,
  };

  onTimeUpdate = ({target: {currentTime}}) => {
    this.setState({currentTime});
  };

  setCurrentTime = (value) => {
    this.audio.currentTime = value;
  };

  onReady = ({target}) => {
    const src = target.querySelectorAll('source')[0].src;

    if (this.state.audioSrc == src) {
      return true;
    }

    this.audio = target;

    this.setState({
      audioSrc: src,
      canplay: true,
      duration: target.duration,
      playing: !!this.props.autoplay,
    });

    this.audio.addEventListener('ended', () => {
      this.setState({
        playing: false,
      });
    });
  };

  togglePlay = () => {
    this.setState({
      playing: !this.state.playing,
    });
  };

  updateAd = (data) => {
    const {updateAd, onComplete} = this.props;
    const {loading} = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
    });
    data.reviewDuration = getTimestamp() - this.startTime;
    return updateAd(data).then(
      ({
        data: {
          updateAd: {ad},
        },
      }) => {
        this.setState({
          loading: false,
        });
        onComplete(ad);
      }
    );
  };

  unknownAd = () => {
    const {unknownAd, onComplete} = this.props;
    const {loading} = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    return unknownAd({
      reviewDuration: getTimestamp() - this.startTime,
    }).then(
      ({
        data: {
          unknownAd: {ad},
        },
      }) => {
        this.setState({
          loading: false,
        });
        onComplete(ad);
      }
    );
  };

  rejectConfirm = () => {
    this.setState({
      confirm: true,
    });
  };

  rejectAd = () => {
    const {rejectAd, onComplete, ad} = this.props;
    const {loading} = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    return rejectAd({
      reviewDuration: getTimestamp() - this.startTime,
    }).then(
      ({
        data: {
          rejectAd: {success},
        },
      }) => {
        this.setState({
          loading: false,
          confirm: false,
        });
        onComplete(ad);
      }
    );
  };

  componentDidMount() {
    this.startTime = getTimestamp();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.ad.id !== nextProps.ad.id) {
      this.setState({
        loading: false,
        canplay: false,
        playing: false,
        currentTime: 0,
        duration: null,
      });
    }
  }

  render() {
    const {ad, reviewerId, displayBackButton} = this.props;
    const {playing, currentTime, duration, loading, confirm} = this.state;

    if (loading) {
      return <Loading centered />;
    }

    const {guess} = ad;

    const highlights = guess
      ? guess.body
          .toLowerCase()
          .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
          .split(/\s+/)
          .filter((word) => stopwords.indexOf(word) == -1)
      : [];

    return (
      <div>
        <Element>
          {guess ? (
            <Element
              rules={() => ({
                textAlign: 'right',
                fontSize: '12px',
                color: '#666',
                padding: '2px 10px',
              })}>
              {guess.score.toFixed(2)} / {guess.maxScore.toFixed(2)} /{' '}
              {guess.duration.toFixed(2)}
            </Element>
          ) : (
            ''
          )}
          <Element
            rules={() => ({
              display: 'grid',
              gridGap: '2rem',
              gridTemplateColumns: '1fr 300px',
            })}>
            <Element>
              <AudioPlayer
                flacPath={ad.flacPath}
                mpegPath={ad.mpegPath}
                waveform={ad.waveform}
                onReady={this.onReady}
                togglePlay={this.togglePlay}
                onTimeUpdate={this.onTimeUpdate}
                playing={playing}
                currentTime={currentTime}
                setCurrentTime={this.setCurrentTime}
                duration={duration}
              />
              <Element rules={() => ({marginTop: '1rem'})}>
                <TranscriptStatic
                  words={ad.words}
                  currentTime={currentTime}
                  setCurrentTime={this.setCurrentTime}
                  highlights={highlights}
                />
              </Element>
            </Element>
            <Element>
              {guess ? (
                <>
                  <AcceptButton
                    title='company'
                    value={guess.companyName}
                    onClick={() => {
                      const {companyId, companyName} = guess;

                      this.updateAd({
                        companyId,
                        companyName,
                      });
                    }}
                  />
                  <AcceptButton
                    title='product'
                    value={guess.productName}
                    onClick={() => {
                      const {companyId, companyName, productId, productName} =
                        guess;

                      this.updateAd({
                        companyId,
                        companyName,
                        productId,
                        productName,
                      });
                    }}
                  />
                </>
              ) : (
                ''
              )}

              <Button fullWidth variant='outlined' onClick={this.unknownAd}>
                Skip
              </Button>
              {displayBackButton ? (
                // we want it to refresh the cache.
                <Button
                  fullWidth
                  variant='outlined'
                  css={`
                    margin-top: 0.75rem;
                  `}
                  onClick={(evt) => {
                    evt.preventDefault();
                    this.props.onBackClick();
                  }}>
                  Back
                </Button>
              ) : (
                ''
              )}
              <Button
                color='error'
                fullWidth
                css={`
                  margin-top: 0.75rem;
                `}
                onClick={this.rejectConfirm}>
                Reject
              </Button>
            </Element>
          </Element>
        </Element>

        {confirm ? (
          <Overlay
            opened={confirm}
            toggle={() => this.setState({confirm: false})}
            closeOnEscKey={false}
            closeOnOutsideClick={false}
            withBackdrop
            centered
            css={`
              padding: 1.5rem;
            `}>
            <p>
              <b>Are you 100% sure this is not an ad?</b> If it's two ads,
              please mark as unknown. Only reject ads that you are sure are not
              ads.
            </p>
            <Element rules={() => ({margin: '1rem 0 0', textAlign: 'right'})}>
              <Button
                onClick={() => {
                  this.setState({
                    confirm: false,
                  });
                }}
                css={`
                  margin-right: 0.625rem;
                `}>
                Cancel
              </Button>
              <Button color='secondary' onClick={this.rejectAd}>
                Yes, Reject Ad
              </Button>
            </Element>
          </Overlay>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default compose(
  graphql(UpdateAd, {
    props: ({
      ownProps: {
        reviewerId,
        ad: {id},
      },
      mutate,
    }) => ({
      updateAd: (input) => {
        input.id = id;
        input.reviewerId = reviewerId;
        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  }),
  graphql(UnknownAd, {
    props: ({
      ownProps: {
        reviewerId,
        ad: {id},
      },
      mutate,
    }) => ({
      unknownAd: (input) => {
        input.id = id;
        input.reviewerId = reviewerId;
        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  }),
  graphql(RejectAd, {
    props: ({
      ownProps: {
        reviewerId,
        ad: {id},
      },
      mutate,
    }) => ({
      rejectAd: (input) => {
        input.id = id;
        input.reviewerId = reviewerId;
        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  })
)(ClassifyAd);
