import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import CompanySelect from '@core/components/CompanySelect';
import IndustrySelect from '@core/components/IndustrySelect';
import {
  FORM_ERRORS,
  Form,
  FormFooter,
  FormGroup,
  Label,
  Submit,
} from '@core/ui/FormElements';
import {
  useCreateCompanyMutation,
  useUpdateCompanyIndustryMutation,
} from '@analytics/graphql-api';

type FormData = {
  company: {
    id: string;
    name: string;
  };
  industry: string;
  missingIndustry: boolean;
  networkError: boolean;
};

type Props = {
  onComplete?: (_id: string) => void;
};

export default function CompanyIndustryForm({onComplete}: Props) {
  const [loading, setLoading] = useState(false);
  const [createCompany] = useCreateCompanyMutation();
  const [updateCompanyIndustry] = useUpdateCompanyIndustryMutation();

  const {
    clearErrors,
    control,
    formState: {errors},
    handleSubmit,
    setError,
    setValue,
    watch,
  } = useForm<FormData>();

  const onSubmit = async ({
    company: {id, name},
    missingIndustry,
    industry,
  }: FormData) => {
    setLoading(true);
    if (errors.networkError) clearErrors('networkError');
    try {
      if (id) {
        if (missingIndustry) {
          await updateCompanyIndustry({
            variables: {
              input: {
                id,
                industry,
              },
            },
          });
        }
        onComplete?.(id);
      } else {
        const company = await createCompany({
          variables: {
            input: {
              name,
              industry,
            },
          },
        });
        const companyId = company?.data?.createCompany?.company?.id;
        if (companyId) onComplete?.(companyId);
      }
    } catch (err) {
      setError('networkError', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  const {missingIndustry, industry} = watch();

  return (
    <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
      <FormGroup>
        <Label htmlFor='brand-name'>Brand Name</Label>
        <Controller
          name='company'
          control={control}
          rules={{required: 'Please select a brand.'}}
          render={({field}) => (
            <CompanySelect
              inputProps={{id: 'brand-name'}}
              invalid={!!errors.company}
              small={false}
              onSelect={({id, name, industry}) => {
                setValue('missingIndustry', !industry);
                field.onChange({id, name});
              }}
            />
          )}
        />
      </FormGroup>
      {missingIndustry && (
        <FormGroup>
          <Label htmlFor='industry'>Brand Industry</Label>
          <Controller
            name='industry'
            control={control}
            rules={{required: 'Please select an industry.'}}
            render={({field}) => (
              <IndustrySelect
                key={industry}
                buttonProps={{id: 'industry'}}
                defaultValue={industry}
                invalid={!!errors.industry}
                onSelect={({industry}) => field.onChange(industry)}
              />
            )}
          />
        </FormGroup>
      )}
      <FormFooter>
        <Submit loading={loading} disabled={loading}>
          Continue
        </Submit>
      </FormFooter>
    </Form>
  );
}
