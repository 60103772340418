import {useRef} from 'react';
import {DataTableFilterButton} from '@core/ui/DataTable';
import {useOverlay} from '@core/ui/Overlay';
import {OrganizationObject} from '@analytics/graphql-api';
import {GroupBy} from '../../_types';
import {GroupInputOverlay} from './GroupInputOverlay';
import {getDisplayValue} from './_helpers';

export function UsageTableGroupInput({
  defaultValue = [],
  onChange,
  organization,
}: {
  defaultValue?: GroupBy[];
  onChange: (_value: GroupBy[]) => void;
  organization: OrganizationObject;
}) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [opened, toggle] = useOverlay();

  return (
    <>
      <DataTableFilterButton
        domRef={buttonRef}
        onClick={toggle as any}
        active={false}
        rules={() => ({
          background: 'var(--bg-default)',
          borderRadius: 'var(--radius-1)',
          color: defaultValue.length
            ? 'var(--text-default)'
            : 'var(--text-muted)',
          fontWeight: defaultValue.length
            ? 'var(--font-weight-medium)'
            : 'inherit',
          ':hover': {
            borderColor: 'var(--border-default) !important',
          },
          ':hover svg': {
            color: 'var(--icon-subtle) !important',
          },
        })}>
        {getDisplayValue(defaultValue)}
      </DataTableFilterButton>
      {opened && (
        <GroupInputOverlay
          defaultValue={defaultValue}
          onChange={onChange}
          opened={opened}
          organization={organization}
          positionTarget={buttonRef.current}
          toggle={toggle}
        />
      )}
    </>
  );
}
