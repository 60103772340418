import React from 'react';

const toPath = (gs_path) => {
  const [_, bucket, path] = /gs:\/\/([-\w]+)\/(.*)/.exec(gs_path);
  return `https://storage.googleapis.com/${bucket}/${path}`;
};

class AudioPlayer extends React.Component {
  componentWillReceiveProps(nextProps) {
    const {playing, playbackRate} = this.props;

    if (playing && !nextProps.playing) {
      this.audio.pause();
    } else if (!playing && nextProps.playing) {
      this.audio.play();
    }

    if (playbackRate != nextProps.playbackRate) {
      this.audio.playbackRate = nextProps.playbackRate;
    }
  }

  render() {
    const {enclosure, onReady, onTimeUpdate} = this.props;

    return (
      <audio
        ref={(audio) => {
          this.audio = audio;
        }}
        onCanPlayThrough={(evt) => {
          onReady(evt.target);
        }}
        onTimeUpdate={onTimeUpdate}>
        {enclosure.mpegPath ? (
          <source src={toPath(enclosure.mpegPath)} type='audio/mp3' />
        ) : (
          <source src={enclosure.href} type={enclosure.type} />
        )}
      </audio>
    );
  }
}

export default AudioPlayer;
