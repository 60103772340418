import styled from 'styled-components';
import {Stack} from '@core/ui/Layout';
import {LiftMessageCategory} from '../types';

export function LiftMessageNav({
  activeCategory,
  onClick,
  hideBreakdown = false,
  ...props
}: {
  activeCategory: LiftMessageCategory;
  onClick: (_: LiftMessageCategory) => void;
  hideBreakdown?: boolean;
}) {
  return (
    <Stack
      alignItems='center'
      gap='var(--spacing-2)'
      css={{margin: 'var(--spacing-4) 0 var(--spacing-6)'}}
      {...props}>
      {Object.values(LiftMessageCategory).map((category) => (
        <NavPill
          key={category}
          active={category === activeCategory}
          hidden={hideBreakdown && category === LiftMessageCategory.breakdown}
          onClick={() => onClick(category)}>
          {category}
        </NavPill>
      ))}
    </Stack>
  );
}

const NavPill = styled.button<{active?: boolean; hidden?: boolean}>`
  ${({active}) =>
    active
      ? `background: var(--bg-default-hover); color: var(--text-default);`
      : `background: var(--bg-default); color: var(--text-muted);`}
  border-radius: var(--radius-4);
  display: ${({hidden}) => (hidden ? 'none' : undefined)};
  font-size: var(--font-size-body-4);
  font-weight: var(--font-weight-bold);
  height: var(--spacing-8);
  letter-spacing: 0.0725rem;
  line-height: var(--line-height-md);
  padding: 0 var(--spacing-5);
  text-transform: uppercase;
  :hover {
    color: var(--text-default);
    transition: all 0.2s;
  }
`;
