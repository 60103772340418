import {useRef, useState} from 'react';
import useQueryParams from '@core/lib/useQueryParams';
import {Stack} from '@core/ui/Layout';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import {
  OrganizationObject,
  UserObject,
  useGetAdvisorColumnsLazyQuery,
} from '@analytics/graphql-api';
import {ActiveWorkspace} from '../AdvisorTool';
import {AdvisorColumn} from '../types';
import {DropdownFooter} from './DropdownFooter';
import {LeftColumn} from './LeftColumn';
import {OpenDropdownButton} from './OpenDropdownButton';
import {RightColumn} from './RightColumn';
import {
  SIDEBAR_NAV_ITEMS,
  useActiveWorkspaceIfAvailable,
  useDefaultWorkspaceIfNoSelection,
  useResetDropdownOnOpen,
  useResetSearchOnColumnSelection,
} from './utils';

interface Props {
  activeColumns: AdvisorColumn[];
  activeWorkspace?: ActiveWorkspace;
  onColumnsChange: (_props: {
    columns: AdvisorColumn[];
    fromUserAction?: boolean;
  }) => void;
  organization: OrganizationObject;
  user: UserObject;
}

export default function AdvisorColumnsDropdown({
  activeColumns,
  activeWorkspace,
  onColumnsChange,
  organization,
  user,
}: Props) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [selectedColumnGroup, setSelectedColumnGroup] = useState<
    (typeof SIDEBAR_NAV_ITEMS)[number]
  >(SIDEBAR_NAV_ITEMS[0]);
  const [opened, toggle] = useOverlay(false);
  const [currentActiveColumns, setCurrentActiveColumns] = useState(
    [] as AdvisorColumn[]
  );

  const {
    params: {workspace: workspaceId},
  } = useQueryParams(['workspace']);

  const [fetchColumns] = useGetAdvisorColumnsLazyQuery({
    fetchPolicy: 'cache-first',
    onError: (error) => console.error('Error fetching columns:', error),
    onCompleted: (data) => {
      const columns = (data?.me?.organization?.advisor?.columnGroups?.columns ??
        []) as AdvisorColumn[];

      setCurrentActiveColumns(columns);
      onColumnsChange({columns});
    },
  });

  useDefaultWorkspaceIfNoSelection(workspaceId, fetchColumns, organization);
  useResetSearchOnColumnSelection(setSearchValue, selectedColumnGroup);
  useResetDropdownOnOpen(opened, setSearchValue, setSelectedColumnGroup);
  useActiveWorkspaceIfAvailable(activeWorkspace, fetchColumns, organization);

  return (
    <>
      <OpenDropdownButton
        buttonRef={buttonRef}
        activeColumns={activeColumns}
        toggle={toggle}
      />
      <Overlay
        opened={opened}
        toggle={toggle}
        positionTarget={buttonRef.current}
        horizontalAlign='right'
        transparentBackdrop
        withBackdrop
        withShadow
        verticalOffset={2}
        animation='opacity'
        css={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: '0.875rem',
          maxWidth: 'initial',
          overflow: 'hidden',
        }}>
        <Stack>
          <LeftColumn
            user={user}
            currentActiveColumns={currentActiveColumns}
            selectedColumnGroup={selectedColumnGroup}
            setSelectedColumnGroup={setSelectedColumnGroup}
          />
          <RightColumn
            organization={organization}
            currentActiveColumns={currentActiveColumns}
            setCurrentActiveColumns={setCurrentActiveColumns}
            selectedColumnGroup={selectedColumnGroup}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </Stack>
        <DropdownFooter
          onCancelClick={() => {
            setCurrentActiveColumns(activeColumns);
            toggle(false);
          }}
          onSaveClick={() => {
            onColumnsChange({
              columns: currentActiveColumns,
              fromUserAction: true,
            });
            toggle(false);
          }}
        />
      </Overlay>
    </>
  );
}
