import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';
import {Text} from '@core/ui/Text';
import {
  FetchedOrganization,
  FetchedPodcast,
  useAudienceNielsenSegmentQuery,
} from '@analytics/graphql-api';
import AudienceChartCard from './AudienceChartCard';
import {getNielsenOccupationData} from './helpers_chartData';
import {AudienceCategory} from './types';

type Props = {
  podcast: FetchedPodcast;
  organization: FetchedOrganization;
  category: AudienceCategory;
  nielsenApproval?: boolean;
};

export default function AudienceNielsenOccupations({
  podcast,
  organization,
  category,
  nielsenApproval,
}: Props) {
  const {data, loading} = useAudienceNielsenSegmentQuery({
    variables: {
      organizationId: organization.id,
      podcastId: podcast.id,
      classifications: ['Demographic - Career'],
    },
  });

  const [index, percentage] = nielsenApproval
    ? getNielsenOccupationData(
        data?.me?.organization?.advisor?.podcast?.feed?.nielsenSegments
      )
    : [null, null];

  return (
    <>
      <CardHeader
        css={`
          margin: 0 var(--spacing-6) var(--spacing-5);
        `}>
        Occupation
      </CardHeader>
      <Card variant='filled'>
        <Text color='muted' variant='h4'>
          Understanding {category === 'index' ? 'Index' : 'Percentage'} Charts
        </Text>
        <Text color='muted' variant='body2'>
          {category === 'index'
            ? `The indices below express the difference between your podcast's
            audience and the US general population. An index of 100 indicates that
            the audience segment is in line with the US population. An index
            greater than 100 indicates that the audience segment is
            over-represented in your podcast compared to the US general
            population, and an index less than 100 indicates that your podcast's
            audience is under-represented compared to the US general population.`
            : `The percentages below indicate the percentage of your podcast's audience
            that makes up each segment. Percentages may add up to > 100% if the categories
            are not mutually exclusive. They may also be <100% if the data source (i.e. Experian
            or Nielsen) did not survey the entire population for this segment.`}
        </Text>
      </Card>
      <div css={{margin: '1.75rem 1.5rem'}}>
        {loading ? (
          <Loading centered />
        ) : index && percentage ? (
          <AudienceChartCard
            topic='Occupation'
            metrics={category === 'index' ? index : percentage}
            category={category}
            extended
            tooltip
          />
        ) : (
          <Card rules={() => ({margin: 0})}>
            <EmptyMessage>Occupation data unavailable.</EmptyMessage>
          </Card>
        )}
      </div>
    </>
  );
}
