import React, {useState} from 'react';
import {
  IconCheckAlt,
  IconChevronDown,
  IconChevronUp,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';
import {Link} from '@core/ui/Link';

interface DescriptionProps {
  isExpanded: boolean;
}

const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1em;
`;

const IconWrapper = styled.div`
  flex: 0 0 auto;
  margin-right: 1em;
  color: #b7b7b7;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6a6a6a;
`;

const Description = styled.p<DescriptionProps>`
  font-style: normal;
  font-weight: 400;
  max-height: ${({isExpanded}) => (isExpanded ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease; /* Add transition */

  font-size: 14px;
  line-height: 21px;
  color: #6a6a6a;
  margin: 0.5em 0 0 0;
`;

const BoldLink = styled.a`
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  color: #6a6a6a;
`;

const ContentContainer = styled.div`
  padding: 10px 0;
`;

const content = {
  brand: [
    {
      title: 'Ad Analytics overview',
      description: 'Learn the dashboard basics and how measurement works.',
      articleUrl: 'https://help.adanalytics.spotify.com/about',
    },
    {
      title: 'Invite campaign stakeholders',
      description:
        'Add your colleagues as members of your dashboard and grant your agency access.',
      articleUrl: 'https://help.adanalytics.spotify.com/manage-members',
    },
    {
      title: 'Install the Spotify pixel',
      description:
        'Install the Spotify pixel on your website to track conversion events on and off Spotify.',
      articleUrl:
        'https://help.adanalytics.spotify.com/install-the-spotify-pixel/',
    },
    {
      title: 'Measure your campaign',
      description:
        'Build and traffic the brand’s campaign, prepare for launch, and troubleshoot.',
      articleUrl: 'https://help.adanalytics.spotify.com/campaigns',
    },
  ],
  publisher: [
    {
      title: 'Ad Analytics overview',
      description: 'Learn the dashboard basics and how measurement works.',
      articleUrl: 'https://help.adanalytics.spotify.com/about',
    },
    {
      title: 'Invite campaign stakeholders',
      description:
        'Add your colleagues as members of your dashboard and invite your brand clients to sign up.',
      articleUrl: 'https://help.adanalytics.spotify.com/manage-members',
    },
    {
      title: 'Add your podcasts',
      description: 'Install Ad Analytic’s RSS prefix and add your podcasts.',
      articleUrl:
        'https://help.adanalytics.spotify.com/adding-podcasts-to-the-dashboard',
    },
    {
      title: 'Measure your campaign',
      description:
        'Build and traffic your campaign, prepare for launch, and troubleshoot.',
      articleUrl: 'https://help.adanalytics.spotify.com/campaigns',
    },
  ],
  agency: [
    {
      title: 'Ad Analytics overview',
      description: 'Learn the dashboard basics and how measurement works.',
      articleUrl: 'https://help.adanalytics.spotify.com/about',
    },
    {
      title: 'Invite campaign stakeholders',
      description:
        'Add your colleagues as members and invite your brand clients to sign up.',
      articleUrl: 'https://help.adanalytics.spotify.com/manage-members',
    },
    {
      title: 'Install the Spotify pixel',
      description:
        'Install the Spotify pixel on your client’s website to track conversion events on and off Spotify.',
      articleUrl:
        'https://help.adanalytics.spotify.com/install-the-spotify-pixel/',
    },
    {
      title: 'Measure your campaign',
      description:
        'Build and traffic the brand’s campaign, prepare for launch, and troubleshoot.',
      articleUrl: 'https://help.adanalytics.spotify.com/campaigns/',
    },
  ],
};

const IntroBottomSheetContent = ({kind, slug}) => {
  let contentItems = content.brand;
  if (kind === 'BUY_SIDE' || kind === 'SELL_SIDE') {
    contentItems = content.agency;
  } else if (kind === 'PODCAST') {
    contentItems = content.publisher;
  }

  return (
    <ContentContainer>
      {contentItems.map(({title, description, articleUrl}, index) => {
        const [isExpanded, setIsExpanded] = useState(false);

        return (
          <ItemWrapper key={index}>
            <IconWrapper>
              <IconCheckAlt size={'small'} />
            </IconWrapper>
            <div style={{flex: 1}}>
              <TitleWrapper onClick={() => setIsExpanded(!isExpanded)}>
                <Title>{title}</Title>
                <div>
                  {isExpanded ? (
                    <IconChevronUp size={'small'} semanticColor='textSubdued' />
                  ) : (
                    <IconChevronDown
                      size={'small'}
                      semanticColor='textSubdued'
                    />
                  )}
                </div>
              </TitleWrapper>
              <Description isExpanded={isExpanded}>
                {description}
                {articleUrl && (
                  <>
                    {' '}
                    <BoldLink
                      href={articleUrl}
                      target='_blank'
                      rel='noopener noreferrer'>
                      Read article
                    </BoldLink>
                  </>
                )}
              </Description>
            </div>
          </ItemWrapper>
        );
      })}
    </ContentContainer>
  );
};

export default IntroBottomSheetContent;
