import {
  AdvisorColumn,
  AdvisorColumnGroups,
  GetAllAdvisorQuizColumnGroupsQuery,
} from '@analytics/graphql-api';
import {
  AdvisorSavedSearchDTO,
  Clicks,
  Dollars,
  Impressions,
  Reach,
  SortMethod,
  Visitors,
  WorkspaceConfig,
} from './types';

export function toWorkspaceDTO(config: WorkspaceConfig): AdvisorSavedSearchDTO {
  return JSON.stringify(config) as AdvisorSavedSearchDTO;
}

export function defaultWorkspaceConfig({
  columns,
  filters,
  sort,
}: {
  columns?: string[];
  filters?: any[];
  sort?: SortMethod[];
} = {}): WorkspaceConfig {
  return {
    columns: [
      'publisher',
      'tags',
      'affinity',
      'newReach',
      'averageMonthlyImpressions',
      'averageMonthlyReach',
      'averageEpisodeLength',
      'adsSampled',
      'contentMaturity',
      'feedGrowth',
      ...(columns ?? []),
    ],
    filters: [...(filters ?? [])],
    sort: [
      {
        id: 'affinity',
        direction: 'desc',
      },
      ...(sort ?? []),
    ],
  };
}

export function estimateImpressionsFromBudget(budget: number) {
  const householdsToImpressions = 0.275;
  const CPM = (25 as Dollars) / (1000 as Impressions);
  const CR = (2 as Clicks) / (100 as Impressions);

  const impressions = ((budget as Dollars) / CPM) as Impressions;

  return {
    impressions,
    households: (impressions * householdsToImpressions) as Reach,
    visitors: (impressions * CR) as Visitors,
  } as const;
}

type ColumnGroup = Pick<
  AdvisorColumnGroups,
  | 'brands'
  | 'contentMaturity'
  | 'tags'
  | 'nielsen'
  | 'radioMarkets'
  | 'industries'
>;

export function getAdvisorColumnData(
  columnGroup: keyof ColumnGroup,
  mapTitle: (_s: string) => string = (s) => s
) {
  return function getUsingKey(response: GetAllAdvisorQuizColumnGroupsQuery) {
    try {
      const columns = (
        response.me?.organization.advisorQuiz?.columnGroups?.[columnGroup]
          ?.columns ?? []
      ).filter((col): col is AdvisorColumn => !!col);
      return [
        ...columns
          // Stick in hash table to eliminate columns with duplicate titles.
          // Some exelate columns have the same "main" title with different prefixes,
          // like "Exelate Tag - xx" vs "Exelate Recently Added Tag - xx".
          .reduce((cols, col) => {
            if (!col.title) return cols;
            const title = mapTitle(col.title);
            return cols.set(title, {...col, title});
          }, new Map<string, Partial<AdvisorColumn>>())
          .values(),
      ]
        .sort(ascending('title'))
        .slice(0, 20);
    } catch (_) {
      return [];
    }
  };
}

export function ascending(key: string) {
  return function comparator(a: {[key: string]: any}, b: {[key: string]: any}) {
    if (!a[key]) return -1;
    if (!b[key]) return 1;
    if (a[key] < b[key]) return -1;
    if (a[key] === b[key]) return 0;
    return 1;
  };
}
