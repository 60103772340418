import React, {useState} from 'react';
import {Query} from '@apollo/client/react/components';
import {AssetUploader} from '@core/components/Uploader';
import {Element} from '@core/style';
import {BLUE} from '@core/style';
import {ButtonIcon} from '@core/ui/Button';
import Loading from '@core/ui/Loading';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import AssetsQuery from '@analytics/graphql-api/_old_queries/AssetsQuery';

const OverlayContent = ({organization, onSelect, setOpened}) => {
  const [uploading, setUploading] = useState(false);

  const updateAssetsCache = (
    store,
    {
      data: {
        createAsset: {asset},
      },
    }
  ) => {
    // Stuff the new journey into the cache.
    const query = {
      query: AssetsQuery,
      variables: {
        id: organization.id,
      },
    };

    query.data = store.readQuery(query);
    const assets = query.data.me.organization.assets.slice(0);
    assets.push(asset);
    query.data.me.organization.assets = assets;
    store.writeQuery(query);
  };

  return (
    <Query query={AssetsQuery} variables={{id: organization.id}}>
      {({loading, refetch, data}) => {
        if (loading) {
          return <Loading absolute />;
        }
        const {assets} = data.me.organization;

        return (
          <>
            {uploading ? <Loading absolute /> : null}

            <Element
              rules={() => ({
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                pointerEvents: uploading ? 'none' : null,
                opacity: uploading ? '0.5' : null,
              })}>
              <Element
                rules={({theme}) => ({
                  alignItems: 'center',
                  borderBottom: `1px solid ${theme.borderPrimary}`,
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '1rem 2rem',
                })}>
                <Element
                  rules={() => ({
                    fontSize: '1.5rem',
                    fontWeight: 700,
                    flex: 1,
                  })}>
                  Files
                </Element>
                <ButtonIcon onClick={() => setOpened(false)} icon='close' />
              </Element>
              <Element
                rules={() => ({
                  alignItems: 'start',
                  display: 'grid',
                  flex: 1,
                  gridGap: '1rem',
                  gridTemplateColumns: 'repeat(auto-fit, 9.375rem)',
                  height: '100%',
                  padding: '2rem',
                })}>
                {assets.map((asset) => (
                  <Element
                    rules={({theme}) => ({
                      background: theme.bgTertiary,
                      borderRadius: '1rem',
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      height: 'auto',
                      minWith: 0,
                      padding: '0.625rem',
                      width: '100%',
                      ':hover': {
                        background: BLUE,
                        color: 'white',
                      },
                    })}
                    onClick={(evt) => {
                      evt.preventDefault();
                      setOpened(false);
                      onSelect(asset);
                    }}>
                    {asset.contentType == 'video/mp4' ? (
                      <Element
                        tag='video'
                        width='100%'
                        height='auto'
                        controls
                        preload='metadata'
                        rules={() => ({pointerEvents: 'none'})}>
                        <source src={asset.src} type='video/mp4' />
                        Your browser does not support the video tag.
                      </Element>
                    ) : (
                      <Element tag='img' src={`${asset.src}?width=260`} />
                    )}
                    <Element
                      rules={() => ({
                        fontSize: '0.875rem',
                        lineHeight: 1.2,
                        paddingTop: '0.375rem',
                      })}>
                      {asset.name}
                    </Element>
                  </Element>
                ))}
              </Element>
              <AssetUploader
                organization={organization}
                onChange={(action) => {
                  setUploading(action !== 'uploaded');
                  if (action === 'uploaded') {
                    refetch();
                  }
                }}
                onNew={updateAssetsCache}
              />
            </Element>
          </>
        );
      }}
    </Query>
  );
};

const AssetSelector = ({organization, onSelect, disabled, withMargin}) => {
  const [opened, setOpened] = useOverlay();

  return (
    <>
      <Element
        style-disabled={disabled}
        rules={({theme}) => ({
          alignItems: 'center',
          background: theme.bgTertiary,
          borderRadius: '0.375rem',
          color: theme.textTertiary,
          cursor: 'pointer',
          display: 'inline-flex',
          height: '2.375rem',
          lineHeight: 1,
          marginBottom: withMargin ? '1rem' : null,
          maxHeight: '100%',
          opacity: disabled ? 0.5 : null,
          padding: '0 1rem',
          pointerEvents: disabled ? 'none' : null,
          ':hover': {
            color: BLUE,
          },
        })}
        onClick={() => {
          if (!disabled) {
            setOpened(true);
          }
        }}>
        Select a File
      </Element>
      <Overlay
        opened={opened}
        toggle={setOpened}
        withShadow
        withBackdrop
        css={`
          inset: 6rem;
        `}>
        <OverlayContent
          organization={organization}
          onSelect={onSelect}
          setOpened={setOpened}
        />
      </Overlay>
    </>
  );
};

export default AssetSelector;
