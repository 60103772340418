import styled, {css} from 'styled-components';
import {Size} from './Size';

interface ResponsiveProps {
  size?: Size;
}

const headerBarSizes = {
  small: css`
    min-height: 180px;
  `,
  medium: css`
    min-height: 150px;
  `,
  smallest: css`
    min-height: 130px;
  `,
};

const flashContainerSizes = {
  small: css`
    left: 104px;
    top: 40px;
    transform: scale(0.73);
    transform-origin: top left;
  `,
  medium: css`
    right: 50px;
    top: 20px;
    transform: scale(1);
    transform-origin: top right;
    @media (max-width: 1024px) {
      transform: scale(0.73);
      right: 20px;
      top: 40px;
      transform-origin: top right;
    }
    @media (max-width: 768px) {
      display: none;
    }
  `,
  smallest: css`
    top: 30px;
    left: 70px;
    transform-origin: top left;
    transform: scale(0.5);
  `,
};

const bigStarSvgSizes = {
  small: css`
    transform: scale(0.55);
    top: 50px;
    left: 87px;
  `,
  medium: css`
    transform: scale(1);
    top: 20px;
    left: 150px;
    @media (max-width: 1024px) {
      transform: scale(1);
      top: 20px;
      left: 40px;
      transform-origin: top left;
    }
    @media (max-width: 768px) {
      display: none;
    }
  `,
  smallest: css`
    transform: scale(0.37);
    top: 20px;
    left: 45px;
    transform-origin: top left;
  `,
};

const smallStarSvgSizes = {
  small: css`
    transform: scale(0.62);
    top: 114px;
    left: 360px;
  `,
  medium: css`
    transform: scale(1);
    top: 20px;
    left: 50px;
    @media (max-width: 1024px) {
      transform: scale(0.62);
      transform-origin: top left;
      top: 20px;
      left: 20px;
    }
  `,
  smallest: css`
    transform: scale(0.42);
    right: 30px;
    top: 83px;
    transform-origin: top right;
  `,
};

export const HeaderBar = styled.div<ResponsiveProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px;
  gap: 16px;
  background-color: #cdf564;
  width: 100%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  ${({size}) => headerBarSizes[size || 'medium']}
`;

export const HeaderTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height

  {
  boxTitle ? (
    typeof boxTitle === 'string' ? (
      <BoxTitle centered={centered}>{boxTitle}</BoxTitle>
    ) : (
      boxTitle
    )
  ) : null
}: 36px;
  text-align: center;
  z-index: 1;
`;

export const HeaderSubtitle = styled.div`
  z-index: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  max-width: 70%;
`;

const FlashContainer = styled.svg<ResponsiveProps>`
  position: absolute;
  ${({size}) => flashContainerSizes[size || 'medium']}
`;

const BigStarSvg = styled.svg<ResponsiveProps>`
  position: absolute;
  transform-origin: top left;
  ${({size}) => bigStarSvgSizes[size || 'medium']}
`;

const SmallStarSvg = styled.svg<ResponsiveProps>`
  position: absolute;
  transform-origin: top left;
  ${({size}) => smallStarSvgSizes[size || 'medium']}
`;

const SmallStar = ({size}) => (
  <SmallStarSvg
    xmlns='http://www.w3.org/2000/svg'
    width='59'
    height='57'
    fill='none'
    size={size}>
    <path
      fill='url(#a)'
      d='M59 28.5c0-2.552-2.592-4.865-6.902-6.673-7.018-2.951-12.639-8.375-15.667-15.154C34.567 2.526 32.15 0 29.5 0c-2.65 0-5.038 2.5-6.93 6.673-3.059 6.78-8.65 12.203-15.668 15.154C2.592 23.635 0 25.974 0 28.5s2.592 4.865 6.902 6.673c7.018 2.951 12.639 8.375 15.667 15.154C24.433 54.474 26.85 57 29.5 57c2.65 0 5.038-2.5 6.93-6.673 3.059-6.78 8.65-12.203 15.668-15.154C56.408 33.365 59 31.026 59 28.5Z'
    />
    <defs>
      <linearGradient
        id='a'
        x1='29.5'
        x2='29.5'
        y1='0'
        y2='57'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset='1' stopColor='#fff' stopOpacity='.26' />
      </linearGradient>
    </defs>
  </SmallStarSvg>
);

const BigStar = ({size}) => (
  <BigStarSvg
    xmlns='http://www.w3.org/2000/svg'
    width={'115'}
    height={'111'}
    fill='none'
    size={size}>
    <path
      d='M115 55.5c0-4.97-5.052-9.474-13.453-12.995-13.679-5.747-24.634-16.308-30.538-29.51C67.377 4.918 62.665 0 57.5 0s-9.82 4.867-13.509 12.995c-5.96 13.202-16.858 23.764-30.538 29.51C5.052 46.026 0 50.582 0 55.5s5.052 9.474 13.453 12.995c13.68 5.747 24.635 16.308 30.538 29.51C47.623 106.082 52.335 111 57.5 111s9.82-4.867 13.509-12.995c5.96-13.202 16.858-23.764 30.538-29.51C109.948 64.974 115 60.418 115 55.5z'
      fill='url(#A)'
    />
    <defs>
      <linearGradient
        id='A'
        x1='57.5'
        y1='0'
        x2='57.5'
        y2='111'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset='1' stopColor='#fff' stopOpacity='.26' />
      </linearGradient>
    </defs>
  </BigStarSvg>
);

const FlashSvg = ({size}) => (
  <FlashContainer
    xmlns='http://www.w3.org/2000/svg'
    width='359'
    height='263'
    fill='none'
    size={size}>
    <path
      d='M28 235.476L165.865 104.875L214.296 144.607L331 28'
      stroke='url(#A)'
      strokeWidth='55'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <linearGradient
        id='A'
        x1='212.155'
        y1='-16.53'
        x2='212.155'
        y2='213.063'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset='1' stopColor='#fff' stopOpacity='0' />
      </linearGradient>
    </defs>
  </FlashContainer>
);
const SpotifyHeader = ({
  title,
  subTitle,
  size = 'medium',
  headerBarSize: headerBarSizeRaw,
}: {
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  size?: 'small' | 'medium' | 'smallest';
  headerBarSize?: 'small' | 'medium' | 'smallest';
}) => {
  const headerBarSize = headerBarSizeRaw || size;

  return (
    <HeaderBar size={headerBarSize}>
      <FlashSvg size={size} />
      <BigStar size={size} />
      <SmallStar size={size} />

      {title ? (
        typeof title === 'string' ? (
          <HeaderTitle>{title}</HeaderTitle>
        ) : (
          title
        )
      ) : null}

      {subTitle ? (
        typeof subTitle === 'string' ? (
          <HeaderSubtitle>{subTitle}</HeaderSubtitle>
        ) : (
          subTitle
        )
      ) : null}
    </HeaderBar>
  );
};

export default SpotifyHeader;
