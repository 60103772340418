import { opacity70 } from '@spotify-internal/encore-foundation';
import { Box as BaseBox } from "../Box/index.js";
import { padding } from "./SlotSizes.js";
import styled, { css } from "../../styled-components.js";
import { cssSpacing, isSpacer } from "../../styles/index.js";
export var Box = styled(BaseBox).withConfig({
  displayName: "Box",
  componentId: "sc-1njtxi4-0"
})(["", ""], function (_ref) {
  var $contentAlign = _ref.$contentAlign,
    $verticalGap = _ref.$verticalGap,
    $size = _ref.$size;
  return css(["display:inline-flex;flex-direction:column;row-gap:", ";&[disabled]{opacity:", ";}", ""], isSpacer($verticalGap) ? cssSpacing($verticalGap) : $verticalGap, opacity70, $contentAlign && css(["margin-inline-start:calc(-1 * ", ");margin-inline-end:calc(-1 * ", ");"], padding[$size], padding[$size]));
});