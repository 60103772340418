import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import AudioPlayButton from '@core/components/AudioPlayButton';
import {toTimeString} from '@core/lib/time';
import {BACKGROUND_MODIFIER_HOVER, BLUE, Element} from '@core/style';
import {ToastMessage} from '@core/ui/Alert';
import Button from '@core/ui/Button/LegacyButton';
import {Card} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import {Icon} from '@core/ui/Icon';
import CompletePlacements from '../mutations/CompletePlacements';
import NoPlacements from '../mutations/NoPlacements';
import UpsertPlacements from '../mutations/UpsertPlacements';
import AudioGrid from './AudioGrid';
import AudioMedia from './AudioMedia';
import AudioPlacementDetail from './AudioPlacementDetail';
import AudioPlayControls from './AudioPlayControls';
import AudioPlayback from './AudioPlayback';
import AudioScale from './AudioScale';
import AudioSkip from './AudioSkip';
import AudioTrack from './AudioTrack';
import AudioTrackPlacement from './AudioTrackPlacement';
import AudioTranscript from './AudioTranscript';
import useWaveform from './useWaveform';

// Put everything you don't want the reviewers to see behind this flag
const COLORS = [
  'rgba(255,165,0, 0.7)',
  'rgba(255,0,165, 0.7)',
  'rgba(165,0,165, 0.7)',
  'rgba(165,165,165, 0.7)',
  'rgba(165,255,165, 0.7)',
  'rgba(165,255,255, 0.7)',
  'rgba(165,255,0, 0.7)',
  'rgba(0,255,0, 0.7)',
  'rgba(255,255,0, 0.7)',
  'rgba(255,0,0, 0.7)',
  'rgba(0,0,255, 0.7)',
  'rgba(0,165,255, 0.7)',
  'rgba(165,165,255, 0.7)',
  'rgba(165,0,255, 0.7)',
];

const getColor = (index) => {
  return COLORS[index % COLORS.length];
};

const halfStepFloor = (num) => {
  return Math.floor(num * 2) / 2;
};

const halfStepCeil = (num) => {
  return Math.ceil(num * 2) / 2;
};

const halfStepRound = (num) => {
  return Math.round(num * 2) / 2;
};

class AudioReviewTool extends React.Component {
  constructor(props) {
    super(props);

    const {placements} = props.episode;

    this.state = {
      canComplete: false,

      playing: false,
      canplay: false,
      playbackRate: 1,
      currentTime: 0,
      duration: 0,
      scale: 2100,
      rawScale: 0,
      trackOffset: 0,
      trackWidth: 0,

      activePlacement: null,
      placements: (placements || []).map(
        ({id, startTime, endTime, company, product, ad}, i) => ({
          id,
          startTime,
          endTime,
          company,
          product,
          color: getColor(i),
          ad,
        })
      ),

      keywordConfidenceThreshold: 0.5,

      showAdMarkers: true,
      showKeywords: true,
      showDuplicates: true,
      showPlacements: true,

      keywordSliceWindow: 3,
      contentSliceStart: 0,
      contentSliceEnd: 0,
    };
  }

  onAudioReady = (audio) => {
    this.setState({
      canplay: true,
      duration: audio.duration,
    });

    audio.addEventListener('ended', () => {
      this.setState({
        playing: false,
      });
    });

    this.audio = audio;
  };

  onAudioTimeUpdate = ({target: {currentTime}}) => {
    const {endTime} = this.state;

    const update = {
      currentTime,
    };

    if (endTime && endTime <= currentTime) {
      update.playing = false;
      update.currentTime = endTime;
    }

    this.setState(update);
  };

  onSelectPlaybackRate = (rate) => {
    this.setState({
      playbackRate: rate,
    });
  };

  onSkip = (duration) => {
    this.audio.currentTime += duration;
  };

  togglePlay = () => {
    this.setState({
      playing: !this.state.playing,
    });
  };

  onScaleChange = ({target: {value}}) => {
    const {scale, trackOffset, trackWidth, currentTime, duration} = this.state;
    const newScale = parseInt(100 * ((100 - parseInt(value)) / 5 + 1));

    // We need to figure out the new offset based on the ratio change.
    const ratio = 1 - newScale / scale;

    const offsetDiff = ratio * trackOffset;
    const widthDiff = ratio * trackWidth;

    // let newTrackOffset = trackOffset - offsetDiff
    const newTrackWidth = trackWidth - widthDiff;

    // Scale to where the offset is.
    let newTrackOffset = -(currentTime / duration) * newTrackWidth;

    if (newTrackWidth + newTrackOffset < this.wrapper.offsetWidth) {
      newTrackOffset = this.wrapper.offsetWidth - newTrackWidth;
    }

    this.setState({
      scale: newScale,
      rawScale: parseInt(value),
      trackOffset: newTrackOffset,
    });
  };

  onContentSliceStartChange = ({target: {value}}) => {
    this.setState({
      contentSliceStart: Math.max(value, 0),
    });
  };

  onContentSliceEndChange = ({target: {value}}) => {
    const {duration} = this.state;
    this.setState({
      contentSliceEnd: Math.min(value, duration),
    });
  };

  updateTrackOffset = (diff) => {
    const {trackOffset} = this.state;

    let newOffset = trackOffset - diff;

    if (newOffset > 0) {
      newOffset = 0;
    }

    if (this.track.offsetWidth + newOffset < this.wrapper.offsetWidth) {
      newOffset = this.wrapper.offsetWidth - this.track.offsetWidth;
    }

    this.setState({
      trackOffset: newOffset,
      trackWidth: this.track.offsetWidth,
    });
  };

  setCurrentTime = (currentTime, endTime) => {
    const update = {endTime};

    if (!this.state.playing) {
      update.playing = true;
    }

    this.setState(update);

    setTimeout(() => {
      this.audio.currentTime = currentTime;
    }, 10);
  };

  setKeywordConfidenceThreshold = ({target: {name, value}}) => {
    value = value / 100;

    this.setState({
      keywordConfidenceThreshold: value,
    });
  };

  findKeywordConfidenceThreshold = () => {
    const {episode} = this.props;

    const maxKeywordsPerHour = 60;
    const duration = episode.enclosure.length;
    const keywords = episode.keywords || [];
    // const uniqueKeywords = [...new Set(...keywords.map(k => k.keyword))]
    // const keywordVersions = uniqueKeywords.reduce(
    //   (keywordVersions, keyword) => {
    //     keywordVersions[keyword] = Math.max(
    //       ...keywords.filter(k => k.keyword == keyword).map(k => k.version)
    //     )
    //   },
    //   {}
    // )
    const latestVersion = Math.max(...keywords.map((k) => k.version));

    const thresholds = [
      0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
      0.7, 0.75, 0.8, 0.85, 0.9, 0.95,
    ];

    thresholds.some((threshold) => {
      const keywordsAtThreshold = keywords.filter(
        (k) => k.confidence >= threshold && k.version == latestVersion
      );
      const keywordsPerHour = keywordsAtThreshold.length / (duration / 3600);
      if (this.props.user.isAdmin) {
        console.error(
          'keywords per hour at threshold',
          keywordsPerHour,
          threshold
        );
      }
      if (keywordsPerHour < maxKeywordsPerHour) {
        this.setState({
          keywordConfidenceThreshold: threshold,
        });
        return true;
      }
    });
  };

  createPlacement = ({startTime, endTime}) => {
    // Make sure we are in the correct order
    const {duration} = this.state;

    let start = startTime > endTime ? endTime : startTime;
    let end = startTime > endTime ? startTime : endTime;

    start = start < 0 ? 0 : start;
    end = end > duration ? duration : end;

    const placement = {
      id: `tmp:${parseInt(Math.random() * 40000).toString()}`,
      startTime: halfStepFloor(start),
      endTime: halfStepCeil(end),
      color: getColor(this.state.placements.length),
    };

    this.setState({
      canComplete: false,
      activePlacement: placement,
      placements: this.state.placements.concat(placement),
    });
  };

  updatePlacement = (placement) => {
    const {activePlacement, placements} = this.state;
    // make sure we are dealing with whole numbers.
    placement.startTime = halfStepRound(placement.startTime);
    placement.endTime = halfStepRound(placement.endTime);

    // Make sure we are greater than 0
    placement.startTime = placement.startTime < 0 ? 0 : placement.startTime;

    this.setState({
      canComplete: false,
      activePlacement:
        activePlacement && activePlacement.id == placement.id
          ? placement
          : activePlacement,
      placements: placements.map((p) => {
        return p.id == placement.id ? placement : p;
      }),
    });
  };

  setActivePlacement = (activePlacement) => {
    if (activePlacement) {
      const {duration, trackWidth, trackOffset} = this.state;

      this.setState({
        activePlacement,
      });

      this.updateTrackOffset(
        (activePlacement.startTime / duration) * trackWidth + trackOffset
      );
    } else {
      this.setState({
        activePlacement,
      });
    }
  };

  removePlacement = ({id}) => {
    const {activePlacement, placements} = this.state;
    this.setState({
      canComplete: false,
      activePlacement:
        activePlacement && activePlacement.id == id ? null : activePlacement,
      placements: placements.filter((p) => p.id != id),
    });
  };

  savePlacements = () => {
    const {onPlacements, upsertPlacements, reviewerId} = this.props;
    const {placements} = this.state;

    if (reviewerId == 'preview') {
      return;
    }

    this.setState({
      loading: true,
      activePlacement: null,
    });

    const data = placements.map(({id, startTime, endTime}) => ({
      id,
      startTime,
      endTime,
    }));

    if (onPlacements) {
      onPlacements(data);
      this.setState({
        canComplete: true,
        loading: false,
      });
    } else {
      upsertPlacements({
        placements: data,
      }).then(({data}) => {
        // Update the placement ids.
        this.setState({
          canComplete: true,
          loading: false,
          placements: data.upsertPlacements.placements.map(({id}, i) => {
            return Object.assign({}, placements[i], {id});
          }),
        });
      });
    }
  };

  donePlacements = () => {
    const {onSave, onComplete, completePlacements, reviewerId} = this.props;
    const {placements} = this.state;

    if (reviewerId == 'preview') {
      return;
    }

    if (onSave) {
      onSave(placements);
      onComplete({placements});
    } else {
      this.setState({
        loading: true,
        activePlacement: null,
      });

      completePlacements().then(() => {
        this.setState({
          loading: false,
        });

        onComplete({placements});
      });
    }
  };

  noPlacements = () => {
    const {onComplete, noPlacements, reviewerId} = this.props;

    if (reviewerId == 'preview') {
      return;
    }

    this.setState({
      loading: true,
      activePlacement: null,
    });

    noPlacements().then(() => {
      this.setState({
        loading: false,
      });

      onComplete();
    });
  };

  duplicatePlacements = () => {
    const {onComplete, processAdDuplicates} = this.props;

    this.setState({
      loading: true,
      activePlacement: null,
    });

    // Note: Query no longer in service.
    processAdDuplicates?.().then(() => {
      this.setState({
        loading: false,
      });

      onComplete();
    });
  };

  runKeywordSpotting = () => {
    const {onComplete, runKeywordSpotting} = this.props;
    const {loading} = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    // runKeywordSpotting query no longer in service
    runKeywordSpotting?.().then(() => {
      this.setState({
        loading: false,
      });
    });

    return true;
  };

  toggleAdMarkers = () => {
    this.setState({
      showAdMarkers: !this.state.showAdMarkers,
    });
  };

  toggleKeywords = () => {
    this.setState({
      showKeywords: !this.state.showKeywords,
    });
  };

  toggleDuplicates = () => {
    this.setState({
      showDuplicates: !this.state.showDuplicates,
    });
  };

  togglePlacements = () => {
    this.setState({
      showPlacements: !this.state.showPlacements,
    });
  };

  playKeyword = (keyword) => {
    const {
      duration,
      trackWidth,
      trackOffset,
      playing,
      keywordSliceWindow,
      currentTime,
    } = this.state;

    const nowPlaying =
      currentTime >= keyword.timestamp - keywordSliceWindow &&
      currentTime <= keyword.timestamp + keywordSliceWindow;

    this.setCurrentTime(keyword.timestamp - keywordSliceWindow);
    this.updateTrackOffset(
      ((keyword.timestamp - keywordSliceWindow - 10) / duration) * trackWidth +
        trackOffset
    );

    if (!playing || (playing && nowPlaying)) {
      this.togglePlay();
    }
  };

  componentDidMount() {
    if (this.props.user.isAdmin) {
      this.setState({
        showKeywords: true,
        showDuplicates: false,
        showAdMarkers: false,
        // showPlacements: false,
      });
    }
    setTimeout(() => {
      this.setState({
        trackWidth: this.track.offsetWidth,
      });
    }, 10);

    this.findKeywordConfidenceThreshold();
  }

  componentDidUpdate() {
    if (this.state.trackWidth != this.track.offsetWidth) {
      this.setState({
        trackWidth: this.track.offsetWidth,
      });
    }
  }

  render() {
    const {episode, onComplete, reviewerId, proxiedEnclosure, advertisers} =
      this.props;
    const {
      canComplete,
      playing,
      canplay,
      playbackRate,
      currentTime,
      duration,
      scale,
      rawScale,
      trackOffset,
      trackWidth,
      placements,
      activePlacement,
      keywordConfidenceThreshold,
      showDuplicates,
      showKeywords,
      showAdMarkers,
      showPlacements,
      keywordSliceWindow,
    } = this.state;

    const resolvedEnclosure = proxiedEnclosure || episode.enclosure;
    const waveform = useWaveform(
      resolvedEnclosure.waveform ? JSON.parse(resolvedEnclosure.waveform) : [],
      resolvedEnclosure.duration,
      episode.asr
    );

    window.waveform = waveform;
    const keywords = episode.keywords || [];
    const latestVersion = Math.max(...keywords.map((k) => k.version));
    const _visibleKeywords = [];

    for (let idx = 0; idx < keywords.length; idx++) {
      const k = keywords[idx];
      if (
        k.confidence >= keywordConfidenceThreshold &&
        k.version == latestVersion
      ) {
        if (
          keywords[idx - 1] &&
          Math.floor(keywords[idx - 1].timestamp) === Math.floor(k.timestamp)
        ) {
          // Ignore, same timestamp than previous
        } else {
          _visibleKeywords.push(k);
        }
      }
    }
    const visibleKeywords = _visibleKeywords.sort(
      (a, b) => a.timestamp - b.timestamp
    );

    return (
      <Element rules={this.props.rules || []}>
        {!!advertisers.length && (
          <ToastMessage alertType='info'>
            Please look careful for ads from the following brand
            {advertisers.length > 1 ? 's' : ''}:{' '}
            {advertisers.reduce(
              (a, brandName) => (a ? `${a}, ${brandName}` : brandName),
              ''
            )}
          </ToastMessage>
        )}
        <AudioMedia
          enclosure={resolvedEnclosure}
          playing={playing}
          playbackRate={playbackRate}
          onReady={this.onAudioReady}
          onTimeUpdate={this.onAudioTimeUpdate}
        />
        <Element
          rules={() => ({
            alignItems: 'center',
            display: 'grid',
            gridGap: '0.625rem',
            gridTemplateColumns: '1fr max-content max-content max-content',
            marginBottom: '0.625rem',
          })}>
          <AudioPlayControls
            playing={playing}
            currentTime={currentTime}
            duration={duration}
            togglePlay={this.togglePlay}
            isAdmin={this.props.user.isAdmin}
          />
          <AudioScale
            rawScale={rawScale}
            scale={scale}
            onScaleChange={this.onScaleChange}
          />
          <AudioPlayback
            playbackRate={playbackRate}
            onSelectPlaybackRate={this.onSelectPlaybackRate}
          />
          <AudioSkip onSkip={this.onSkip} />
        </Element>
        <Element
          domRef={(wrapper) => (this.wrapper = wrapper)}
          rules={() => ({
            background: '#fff',
            borderRadius: '.5rem',
            marginBottom: '4px',
            overflow: 'hidden',
            width: '100%',
          })}>
          <Element
            domRef={(track) => (this.track = track)}
            rules={() => ({
              marginLeft: `${trackOffset}px`,
              width: `${scale}%`,
            })}>
            <AudioGrid
              currentTime={currentTime}
              duration={duration}
              updateTrackOffset={this.updateTrackOffset}
              scale={scale}
            />
            <AudioTrack
              waveform={waveform}
              enclosure={resolvedEnclosure}
              playing={playing}
              currentTime={currentTime}
              duration={duration}
              setCurrentTime={this.setCurrentTime}
              trackWidth={trackWidth}
              createPlacement={this.createPlacement}
              updatePlacement={this.updatePlacement}
              setActivePlacement={this.setActivePlacement}
              placements={placements}
              duplicates={episode.duplicates}
              diarization={episode.diarization}
              keywords={visibleKeywords}
              adMarkers={episode.adMarkers}
              togglePlay={this.togglePlay}
              showAdMarkers={showAdMarkers}
              showDuplicates={showDuplicates}
              showKeywords={showKeywords}
              showPlacements={showPlacements}
              keywordSliceWindow={keywordSliceWindow}
            />
          </Element>
        </Element>
        {activePlacement ? (
          <AudioPlacementDetail
            playing={playing}
            removePlacement={this.removePlacement}
            updatePlacement={this.updatePlacement}
            placement={activePlacement}
            currentTime={currentTime}
            togglePlay={this.togglePlay}
            setCurrentTime={this.setCurrentTime}
            setActivePlacement={this.setActivePlacement}
          />
        ) : null}
        <AudioTrackPlacement
          scale={scale}
          trackOffset={trackOffset}
          trackWidth={trackWidth}
          placements={placements}
          currentTime={currentTime}
          duration={duration}
          onChange={(_trackOffset) =>
            this.setState({trackOffset: _trackOffset})
          }
          waveform={waveform}
        />

        <Element
          rules={() => ({
            alignItems: 'start',
            display: 'grid',
            fontSize: '15px',
            gridGap: '1rem',
            gridTemplateColumns: '2fr 1fr',
            marginTop: '2rem',
          })}>
          <Card
            rules={() => ({
              display: 'flex',
              flexDirection: 'column',
              margin: 0,
              padding: '0 0 1.5rem 0',
            })}>
            <Element
              rules={() => ({
                display: 'flex',
                justifyContent: 'flex-start',
              })}>
              <Element
                rules={() => ({
                  flex: 1,
                  fontWeight: 500,
                  fontSize: '1rem',
                  margin: '1.25rem 1.25rem 10px',
                })}>
                Ad Placements
              </Element>
            </Element>
            {placements.length ? (
              placements.map((placement, idx) => {
                return (
                  <Element
                    key={placement.id}
                    style-color={placement.color}
                    onClick={() => {
                      this.setActivePlacement(placement);
                    }}
                    rules={() => ({
                      cursor: 'pointer',
                      padding: '0 1.25rem',
                      ':hover': {
                        background: BACKGROUND_MODIFIER_HOVER,
                      },
                    })}>
                    <Element
                      rules={() => ({
                        alignItems: 'center',
                        borderBottom: '1px solid #eee',
                        display: 'grid',
                        gridTemplateColumns: '1.25rem auto 1fr auto',
                        padding: '1rem 0',
                      })}>
                      <Element>{idx + 1}</Element>
                      <Element
                        rules={() => ({
                          background: placement.color,
                          borderRadius: '1px',
                          height: '0.875rem',
                          marginRight: '1.5rem',
                          width: '0.875rem',
                        })}
                      />
                      <Element>
                        {toTimeString(placement.startTime)} -{' '}
                        {toTimeString(placement.endTime)}
                        {placement.ad && placement.ad.reviews ? (
                          <span>
                            {' '}
                            {placement.ad.id} found by:{' '}
                            {placement.ad.reviews[0].reviewer.email}
                            {placement.ad.reviews[0].reviewer.mturkWorkerId}
                          </span>
                        ) : null}
                      </Element>
                      <Element
                        onClick={(evt) => {
                          evt.stopPropagation();
                          this.removePlacement(placement);
                        }}
                        rules={({theme}) => ({
                          color: theme.textTertiary,
                          display: 'inline-flex',
                          marginRight: '0.25rem',
                          flexShrink: 0,
                          ':hover': {
                            color: BLUE,
                          },
                        })}>
                        <Icon icon='bin' />
                      </Element>
                    </Element>
                  </Element>
                );
              })
            ) : (
              <EmptyMessage
                title='Please find all the ads that appear in this podcast.'
                rules={() => ({margin: '2rem 0'})}>
                Click and drag on the track to select the ads in this podcast.
              </EmptyMessage>
            )}
            <Element
              rules={() => ({
                margin: '1.5rem 1.25rem 0',
                textAlign: 'right',
              })}>
              {placements.length < 1 ? (
                <Button
                  onClick={() => this.noPlacements()}
                  rules={() => ({marginRight: '1rem'})}>
                  No placements found
                </Button>
              ) : null}
              <Button
                style-secondary={!canComplete}
                onClick={this.savePlacements}>
                Submit changes
              </Button>
              {canComplete ? (
                <Button
                  style-dark
                  onClick={this.donePlacements}
                  rules={() => ({marginLeft: '5px'})}>
                  Complete
                </Button>
              ) : null}
            </Element>
          </Card>
          <Card
            rules={() => ({
              display: 'flex',
              flexDirection: 'column',
              margin: 0,
            })}>
            <Element
              rules={() => ({
                fontSize: '1rem',
                fontWeight: 500,
                marginBottom: '0.625rem',
              })}>
              Possible Ads
            </Element>
            {visibleKeywords.length ? (
              visibleKeywords.map((keyword, index) => {
                const nowPlaying =
                  currentTime >= keyword.timestamp - keywordSliceWindow &&
                  currentTime <= keyword.timestamp + keywordSliceWindow;

                return (
                  <Element
                    key={keyword.id}
                    rules={() => ({
                      alignItems: 'center',
                      display: 'flex',
                      padding: '6px 0',
                    })}>
                    <Element
                      onClick={(evt) => {
                        evt.preventDefault();
                        this.playKeyword(keyword);
                      }}
                      rules={({theme}) => ({
                        border: nowPlaying
                          ? `1px solid ${BLUE}`
                          : `1px solid ${theme.borderPrimary}`,
                        borderRadius: '6px',
                        cursor: 'pointer',
                        marginRight: '0.625rem',
                        padding: '0.125rem 0.3125rem',
                        ':hover > svg': {
                          color: BLUE,
                        },
                      })}>
                      <AudioPlayButton
                        playing={playing && nowPlaying}
                        rules={({theme}) => ({
                          color:
                            playing && nowPlaying ? BLUE : theme.textTertiary,
                          display: 'flex',
                          width: '1.125rem',
                        })}
                      />
                    </Element>
                    <Element
                      rules={() => ({flex: 1})}
                      onClick={() => {
                        this.playKeyword(keyword);
                      }}>
                      {toTimeString(keyword.timestamp)}
                    </Element>
                  </Element>
                );
              })
            ) : (
              <Element
                rules={() => ({
                  alignItems: 'center',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                })}>
                <EmptyMessage>We could not find any possible ad.</EmptyMessage>
              </Element>
            )}
          </Card>
        </Element>
        <AudioTranscript
          asr={episode.asr}
          setCurrentTime={this.setCurrentTime}
        />
      </Element>
    );
  }
}

export default compose(
  graphql(UpsertPlacements, {
    props: ({
      ownProps: {
        reviewerId,
        workerId,
        hitId,
        assignmentId,
        episode,
        startTime,
        proxiedEnclosure,
      },
      mutate,
    }) => ({
      upsertPlacements: (input) => {
        input.episodeId = episode.id;
        input.reviewerId = reviewerId;

        input.workerId = workerId;
        input.hitId = hitId;
        input.assignmentId = assignmentId;

        if (proxiedEnclosure) {
          input.proxiedEnclosureId = proxiedEnclosure.id;
        }

        if (startTime) {
          input.reviewDuration =
            Math.round(new Date().getTime() / 1000) - startTime;
        }

        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  }),
  graphql(CompletePlacements, {
    props: ({ownProps: {episode}, mutate}) => ({
      completePlacements: () => {
        return mutate({
          variables: {
            input: {
              episodeId: episode.id,
            },
          },
        });
      },
    }),
  }),
  graphql(NoPlacements, {
    props: ({
      ownProps: {startTime, reviewerId, workerId, hitId, assignmentId, episode},
      mutate,
    }) => ({
      noPlacements: () => {
        const input = {
          episodeId: episode.id,
          reviewerId,
          workerId,
          hitId,
          assignmentId,
        };

        if (startTime) {
          input.reviewDuration =
            Math.round(new Date().getTime() / 1000) - startTime;
        }

        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  })
)(AudioReviewTool);
