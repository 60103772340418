import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import dayjs from '@core/lib/dayjs';
import {createOrgPath} from '@core/lib/organizations';
import {
  encodeQueryParams,
  getLocationSearch,
  getQueryParams,
} from '@core/lib/urls';
import {jsonToCsv} from '@core/lib/utils';
import {Element} from '@core/style';
import {BLUE} from '@core/style';
import {buttonRule} from '@core/ui/Button/LegacyButton';
import {Card, CardHeader} from '@core/ui/Content';
import {DataTableHeaderColumn} from '@core/ui/DataTable/DataTableHeader';
import {DateRangeInput} from '@core/ui/DatePicker';
import {Help, Label} from '@core/ui/Form';
import {SearchInput} from '@core/ui/FormElements';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';
import BrandsOverviewQuery from '@analytics/graphql-api/_old_queries/research/BrandsOverviewQuery';
import Range from './CompaniesRange';

const adsMultipler = 4;
const episodesMultipler = 3;
const feedsMultipler = 2;

const withWait = (Component, timeout) => {
  let waitTimeout = null;

  return class Wait extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = props;
    }

    componentWillReceiveProps(nextProps) {
      clearTimeout(waitTimeout);

      waitTimeout = setTimeout(() => {
        this.setState(nextProps);
      }, timeout);
    }

    render() {
      return <Component {...this.state} />;
    }
  };
};

const isOutsideRange = (mnt) => {
  return mnt.isBefore(dayjs('2018-07-12')) || mnt.isAfter(dayjs());
};

const Companies = ({organization, data: {loading, me}, updateCompanies}) => {
  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan='5'>
            <Loading centered />
          </td>
        </tr>
      </tbody>
    );
  }

  // const { companies } = me.organization.research;
  const {results: companies} = me.organization.research.brand.brandsOverview;

  updateCompanies(companies);

  return (
    <tbody>
      {companies.map((company) => {
        return (
          <tr
            key={company.id}
            css={`
              padding: 10px 0;
            `}>
            <td>
              <Link
                to={createOrgPath(
                  organization,
                  `/research/brands/${company.id}`
                )}
                css={`
                  font-size: 0.875rem;
                `}>
                <b>{company.name}</b>
              </Link>
            </td>
            <td>{dayjs(company.adFirstPublished).format('MM/DD/YYYY')}</td>
            <td>{company.feedsCount}</td>
            <td>{company.episodesCount}</td>
            <td>{company.adsCount}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

const CompaniesBody = withWait(
  graphql(BrandsOverviewQuery, {
    options: ({
      organization: {id},
      query,
      limit,
      offset,
      orderBy,
      after,
      before,
      inactiveSince,
      minAds,
      maxAds,
      minPodcasts,
      maxPodcasts,
      minEpisodes,
      maxEpisodes,
      updateCompanies,
    }) => ({
      variables: {
        id: id,
        query,
        limit,
        offset,
        orderBy,
        after: after.format('YYYY-MM-DD'),
        before: before.format('YYYY-MM-DD'),
        inactiveSince: inactiveSince
          ? inactiveSince.format('YYYY-MM-DD')
          : null,
        minAds: minAds ? minAds * adsMultipler : null,
        maxAds: maxAds == 100 ? null : maxAds * adsMultipler,
        minPodcasts: minPodcasts ? minPodcasts * feedsMultipler : null,
        maxPodcasts: maxPodcasts == 100 ? null : maxPodcasts * feedsMultipler,
        minEpisodes: minEpisodes ? minEpisodes * episodesMultipler : null,
        maxEpisodes:
          maxEpisodes == 100 ? null : maxEpisodes * episodesMultipler,
      },
    }),
  })(Companies),
  300
);

const DateFilter = ({title, help, children}) => (
  <Element rules={() => ({flex: 1, marginBottom: '15px'})}>
    <Element
      rules={() => ({
        display: 'flex',
        flexDirection: 'column',
      })}>
      <Label
        rules={() => ({
          display: 'inline-block',
          margin: '0',
        })}>
        {title}
      </Label>
      <Help rules={() => ({margin: '0 0 1rem'})}>{help}</Help>
      <div>{children}</div>
    </Element>
  </Element>
);

const RangeFilter = ({title, start, end, multiplier, emitRange}) => {
  const timeout = null;

  return (
    <Element rules={() => ({marginBottom: '15px'})}>
      <Label rules={() => ({paddingLeft: '5px'})}>
        Number of {title}: {parseInt(start * multiplier)} -{' '}
        {parseInt(end * multiplier)}
        {end == 100 ? '+' : ''}
      </Label>
      <Range start={start} end={end} emitRange={emitRange} />
    </Element>
  );
};

class CompaniesFilter extends React.Component {
  constructor(props) {
    super(props);

    const search = getQueryParams();

    this.state = {
      loading: false,
      isLoadingCompanies: false,
      showAdvanced: false,
      query: search.query ? search.query : '',
      limit: 100,
      offset: 0,
      orderBy: search.orderBy ? search.orderBy : '-feedsCount',
      after: search.after ? dayjs(search.after) : dayjs('2018-07-12'),
      before: search.before ? dayjs(search.before) : dayjs(),
      inactiveSince: search.inactiveSince ? dayjs(search.inactiveSince) : null,
      minAds: search.minAds ? parseInt(search.minAds) : 0,
      maxAds: search.maxAds ? parseInt(search.maxAds) : 100,
      minPodcasts: search.minPodcasts ? parseInt(search.minPodcasts) : 0,
      maxPodcasts: search.maxPodcasts ? parseInt(search.maxPodcasts) : 100,
      minEpisodes: search.minEpisodes ? parseInt(search.minEpisodes) : 0,
      maxEpisodes: search.maxEpisodes ? parseInt(search.maxEpisodes) : 100,
      afterFocused: null,
      beforeFocused: null,
      inactiveFocused: null,
      companies: [],
    };
  }

  updateQueryParams = () => {
    const {
      orderBy,
      query,
      after,
      before,
      inactiveSince,
      minAds,
      maxAds,
      minPodcasts,
      maxPodcasts,
      minEpisodes,
      maxEpisodes,
    } = this.state;

    const newParms = encodeQueryParams({
      orderBy,
      query,
      after: after ? after.format('YYYY-MM-DD') : null,
      before: before ? before.format('YYYY-MM-DD') : null,
      inactiveSince: inactiveSince ? inactiveSince.format('YYYY-MM-DD') : null,
      minAds: minAds !== 0 ? minAds : null,
      maxAds: maxAds !== 100 ? maxAds : null,
      minPodcasts: minPodcasts !== 0 ? minPodcasts : null,
      maxPodcasts: maxPodcasts !== 100 ? maxPodcasts : null,
      minEpisodes: minEpisodes !== 0 ? minEpisodes : null,
      maxEpisodes: maxEpisodes !== 100 ? maxEpisodes : null,
    });

    if (getLocationSearch() != newParms) {
      history.replaceState({}, '', `${window.location.pathname}?${newParms}`);
    }

    this.setState({
      isLoadingCompanies: true,
    });
  };

  componentWillReceiveProps() {
    const {orderBy} = this.state;
    const search = getQueryParams();

    if (orderBy !== search.orderBy) {
      this.setState({
        orderBy: search.orderBy ? search.orderBy : '-feedsCount',
      });
    }
  }

  setCompanies = (companies) => {
    let csvBase64Href = '';
    let csv = [];
    try {
      if (companies.length > 0) {
        csv = jsonToCsv(
          companies.map((c) => {
            return {
              id: c.id,
              name: c.name,
              adsCount: c.adsCount,
              episodesCount: c.episodesCount,
              podcastsCount: c.podcastsCount,
              adFirstPublished: c.adFirstPublished,
            };
          })
        );
        csvBase64Href = `data:text/csv;base64,${btoa(csv)}`;

        this.setState({
          csvBase64Href: csvBase64Href,
          companies: companies,
          isLoadingCompanies: false,
        });
      } else {
        this.setState({
          csvBase64Href: '',
          companies: [],
          isLoadingCompanies: false,
        });
      }
    } catch (err) {
      console.warn(err);
    }
  };

  onSort = (orderBy) => {
    this.setState(
      {
        orderBy,
      },
      () => {
        this.updateQueryParams;
      }
    );
  };

  onQueryChange = ({target: {value}}) => {
    this.setState(
      {
        query: value,
      },
      this.updateQueryParams
    );
  };

  render() {
    const {organization} = this.props;
    const {
      showAdvanced,
      query,
      limit,
      offset,
      orderBy,
      after,
      before,
      inactiveSince,
      afterFocused,
      beforeFocused,
      inactiveFocused,
      minAds,
      maxAds,
      minPodcasts,
      maxPodcasts,
      minEpisodes,
      maxEpisodes,
    } = this.state;

    return (
      <>
        <CardHeader>Top Brands</CardHeader>
        <Card>
          <Element
            rules={() => ({
              padding: '10px',
            })}>
            <SearchInput
              type='text'
              placeholder='Search brand'
              value={query}
              onChange={this.onQueryChange}
            />
          </Element>
          <Element
            tag='a'
            href='#'
            rules={() => ({
              color: BLUE,
              display: 'inline-flex',
              fontSize: '14px',
              marginBottom: '0.625rem',
              padding: '0 0.625rem',
              textDecoration: 'none',
            })}
            onClick={(evt) => {
              evt.preventDefault();
              this.setState({
                showAdvanced: !this.state.showAdvanced,
              });
            }}>
            {showAdvanced ? 'Hide' : 'Show'} Advanced
          </Element>

          <Element
            style-show={showAdvanced}
            rules={({show}) => ({display: show ? 'flex' : 'none'})}>
            <Element
              rules={() => ({
                flex: 1,
                padding: '10px 15px 10px 10px',
                borderRight: '1px solid #ddd',
                marginRight: '10px',
              })}>
              <DateFilter
                title='Date Range'
                help='Only include brands who have ads in this date date.'>
                <DateRangeInput
                  startDate={after}
                  endDate={before}
                  onDatesChange={({startDate, endDate}) =>
                    this.setState(
                      {after: startDate, before: endDate},
                      this.updateQueryParams
                    )
                  }
                  isOutsideRange={isOutsideRange}
                />
              </DateFilter>
              {!this.state.isLoadingCompanies &&
              this.state.companies &&
              this.state.csvBase64Href ? (
                <Element
                  tag='a'
                  download='brands-export.csv'
                  href={this.state.csvBase64Href}
                  style-secondary
                  style-small
                  rules={[
                    buttonRule,
                    () => ({
                      marginLeft: 'auto',
                      marginTop: '20px',
                    }),
                  ]}>
                  Export to CSV
                </Element>
              ) : (
                <Element
                  tag='a'
                  href=''
                  style-dark
                  style-small
                  disabled='Export to CSV'
                  rules={[
                    buttonRule,
                    () => ({
                      marginLeft: 'auto',
                      marginTop: '20px',
                    }),
                  ]}>
                  Export to CSV
                </Element>
              )}
            </Element>
            <Element rules={() => ({flex: 1, padding: '10px'})}>
              <RangeFilter
                title='Ads'
                start={minAds}
                end={maxAds}
                multiplier={adsMultipler}
                emitRange={(start, end) => {
                  this.setState(
                    {
                      minAds: start,
                      maxAds: end,
                    },
                    this.updateQueryParams
                  );
                }}
              />
              <RangeFilter
                title='Podcasts'
                start={minPodcasts}
                end={maxPodcasts}
                multiplier={feedsMultipler}
                emitRange={(start, end) => {
                  this.setState(
                    {
                      minPodcasts: start,
                      maxPodcasts: end,
                    },
                    this.updateQueryParams
                  );
                }}
              />

              <RangeFilter
                title='Episodes'
                start={minEpisodes}
                end={maxEpisodes}
                multiplier={episodesMultipler}
                emitRange={(start, end) => {
                  this.setState(
                    {
                      minEpisodes: start,
                      maxEpisodes: end,
                    },
                    this.updateQueryParams
                  );
                }}
              />
            </Element>
          </Element>
          <table>
            <thead>
              <tr>
                <th>
                  <DataTableHeaderColumn
                    column={{
                      title: 'Brand',
                      accessor: 'name',
                      onSort: this.onSort,
                    }}
                    sortBy={orderBy}
                  />
                </th>
                <th>
                  <DataTableHeaderColumn
                    column={{
                      title: 'First Published',
                      accessor: 'adFirstPublished',
                      onSort: this.onSort,
                    }}
                    sortBy={orderBy}
                  />
                </th>
                <th>
                  <DataTableHeaderColumn
                    column={{
                      title: 'Podcasts',
                      accessor: 'feedsCount',
                      onSort: this.onSort,
                    }}
                    sortBy={orderBy}
                  />
                </th>
                <th>
                  <DataTableHeaderColumn
                    column={{
                      title: 'Episodes',
                      accessor: 'episodesCount',
                      onSort: this.onSort,
                    }}
                    sortBy={orderBy}
                  />
                </th>
                <th>
                  <DataTableHeaderColumn
                    column={{
                      title: 'Ads',
                      accessor: 'adsCount',
                      onSort: this.onSort,
                    }}
                    sortBy={orderBy}
                  />
                </th>
              </tr>
            </thead>

            <CompaniesBody
              updateCompanies={this.setCompanies}
              organization={organization}
              query={query}
              limit={limit}
              offset={offset}
              orderBy={orderBy}
              after={after}
              before={before}
              inactiveSince={inactiveSince}
              minAds={minAds}
              maxAds={maxAds}
              minPodcasts={minPodcasts}
              maxPodcasts={maxPodcasts}
              minEpisodes={minEpisodes}
              maxEpisodes={maxEpisodes}
            />
          </table>
        </Card>
      </>
    );
  }
}

export default CompaniesFilter;
