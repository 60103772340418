import {gql} from '@apollo/client';

export default gql`
  mutation updateGenericTag($input: UpdateGenericTagInput!) {
    authed {
      success
    }
    updateGenericTag(input: $input) {
      genericTag {
        id
        title
        color
      }
    }
  }
`;
