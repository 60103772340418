import {Switch} from 'react-router-dom';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import Loading from '@core/ui/Loading';
import {withLiftNav} from '@analytics/components/nav/LiftNav';
import {useLiftReportsQuery} from '@analytics/graphql-api';
import {MeasureRouteProps} from '../..';
import {LiftReports} from './LiftReports';
import {LiftRequest} from './LiftRequest';
import {getIncrementalLiftRoutes} from './_routes';
import {LiftReportIndex} from './report';

export function IncrementalLiftIndex({organization, user}: MeasureRouteProps) {
  const {data, loading, error} = useLiftReportsQuery({
    variables: {
      organizationId: organization.id,
    },
  });

  if (loading) {
    return <Loading fixed />;
  }

  if (error) {
    return <Alert severity='error'>{t('errors.network')}</Alert>;
  }

  const liftReports = data?.me?.organization?.liftReports ?? [];

  const {renderRoutes} = getIncrementalLiftRoutes({organization});

  return (
    <Switch>
      {renderRoutes({
        components: {
          reports: withLiftNav(LiftReports),
          request: LiftRequest,
          report: LiftReportIndex,
        },
        extraProps: {
          organization,
          liftReports,
          user,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
