import {FC, useState} from 'react';
import {copyToClipboard} from '@core/lib/utils';
import {TRules, css} from '@core/style';
import {IconButton} from '../Button/LegacyButton';
import {LabelInput} from '../FormElements';
import {Icon} from '../Icon';

export interface LabelInputCopyProps {
  label: string;
  onCopy?: () => void;
  text: string;
  rules?: TRules;
}

const LabelInputCopy: FC<LabelInputCopyProps> = ({
  label,
  onCopy,
  text,
  rules,
  ...inputProps
}): JSX.Element => {
  const [copied, setCopied] = useState<boolean>(false);

  const onClickCopy = () => {
    if (!copied) {
      copyToClipboard(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
      if (onCopy) {
        onCopy();
      }
    }
  };

  return (
    <div {...css([() => ({position: 'relative'}), rules])}>
      <LabelInput
        type='text'
        value={text}
        readOnly
        label={label}
        onFocus={(evt) => {
          evt.target.select();
        }}
        {...inputProps}
      />
      <IconButton
        type='button'
        rules={() => ({
          bottom: 0,
          cursor: copied ? 'default' : 'pointer',
          margin: 'auto',
          pointerEvents: copied ? 'none ' : null,
          position: 'absolute',
          right: '0.625rem',
          top: 0,
        })}
        onClick={onClickCopy}>
        {copied ? (
          <Icon
            icon='checkbox-marked-circle'
            rules={() => ({color: 'var(--green)'})}
          />
        ) : (
          <Icon icon='copy' />
        )}
      </IconButton>
    </div>
  );
};

export default LabelInputCopy;
