import {FC} from 'react';
import {toPercent} from '@core/lib/filters';
import {TRules, css} from '@core/style';
import {t} from '@core/texts';
import {Tooltip} from '@core/ui/Tooltip';
import {FetchedAdvisorShow} from '@analytics/graphql-api';
import {BrandStat, ShowDetails} from './tool/AdvisorPreviewPodcastPanel';

type AdvisorPodcastPanelProps = {
  podcast: FetchedAdvisorShow;
  rules?: TRules;
};

const AdvisorPodcastPanel: FC<AdvisorPodcastPanelProps> = ({
  podcast,
  rules,
}): JSX.Element => {
  const {affinity, percentNewReach} = podcast;
  const hasAffinity = typeof affinity === 'number';

  return (
    <div {...css([() => ({display: 'flex', flexDirection: 'column'}), rules])}>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '1rem',
          margin: '0 0 1rem',
        }}>
        <Tooltip
          active={!hasAffinity}
          {...t('advisor.affinityNotAvailable')}
          rules={() => ({
            opacity: hasAffinity ? null : 0.5,
          })}>
          <BrandStat
            iconName='affinity'
            title='Brand Affinity Score'
            value={hasAffinity ? `${affinity}` : '-'}
            elevated
            css={`
              background: var(--advisor-affinity-bg);
              color: var(--advisor-affinity-color);
            `}
          />
        </Tooltip>
        <BrandStat
          iconName='radio'
          title='New Reach'
          value={toPercent(percentNewReach)}
          css={`
            background: var(--advisor-reach-bg);
            color: var(--advisor-reach-color);
          `}
        />
      </div>
      <ShowDetails show={podcast} />
    </div>
  );
};

export default AdvisorPodcastPanel;
