import React from 'react';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {toTimeString} from '@core/lib/time';
import {stripHTML, truncateWords} from '@core/lib/words';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {EpisodeImage} from '@core/ui/Image';
import {episodeURL} from '@analytics/components/nav/ResearchNav';

const ReviewersEpisodesTable = ({
  organization,
  episodes,
  emptyMessageText = 'No episodes found',
}) => {
  const data = episodes.map((episode) => {
    return Object.assign(
      {
        prefixPodsights: !!episode.enclosure.prefixPodsights,
        duration: episode.enclosure.length,
        downloads: episode.enclosure.downloads,
      },
      episode
    );
  });
  const columns = [
    {
      title: 'Podcast',
      accessor: 'title',
      type: 'string',
      Cell: ({data}) => {
        return (
          <Element rules={() => ({display: 'flex', minWidth: '15rem'})}>
            <Element
              rules={() => ({
                flex: '0 0 4.6875rem',
                marginTop: '0.25rem',
                marginRight: '1rem',
              })}>
              <EpisodeImage episode={data} />
            </Element>
            <Element rules={() => ({flex: 1})}>
              <Element
                rules={({theme}) => ({
                  color: theme.textPrimary,
                  fontWeight: 500,
                })}>
                {data.title}
              </Element>
              <Element
                rules={({theme}) => ({
                  color: theme.textTertiary,
                  fontSize: '.875rem',
                  lineHeight: 1.4,
                })}>
                {truncateWords(
                  stripHTML(data.subtitle || data.summary || ''),
                  50
                )}
              </Element>
            </Element>
          </Element>
        );
      },
    },
    {
      title: 'Published',
      accessor: 'published',
      type: 'date',
      fmt: (v) => (v ? dayjs(v).utc().format('MM/DD/YYYY') : '-'),
    },
    {
      title: 'Duration',
      accessor: 'duration',
      type: 'number',
      fmt: toTimeString,
    },
    {
      title: 'Ads',
      accessor: 'placementsCount',
      type: 'number',
      fmt: comma,
    },
  ];

  return (
    <DataTable
      data={data}
      columns={columns}
      searchPlaceholder='Search podcast'
      searchKeys={['title']}
      emptyMessageText={emptyMessageText}
      onClickRowPath={(episode) =>
        `${episodeURL(organization, episode.feed, episode)}/review`
      }
    />
  );
};

export default ReviewersEpisodesTable;
