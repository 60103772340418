import React from 'react';
import dayjs from 'dayjs';
import {MiniPlayer} from '@core/components/AudioMiniPlayer';
import {createOrgPath} from '@core/lib/organizations';
import {toTimeString} from '@core/lib/time';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {Link} from '@core/ui/Link';
import AdCopy from './AdCopy';

const baseColumns = [
  {
    title: 'Ad',
    accessor: 'text',
    type: 'string',
    Cell: ({data}) => {
      return (
        <>
          <Element
            onClick={(evt) => evt.stopPropagation()}
            rules={() => ({paddingTop: '0.5rem'})}>
            <MiniPlayer enclosure={data} preload='none' />
          </Element>
          <AdCopy text={data.text} />
        </>
      );
    },
  },
  {
    title: 'Published',
    accessor: 'firstPublished',
    type: 'date',
    fmt: (firstPublished) => dayjs(firstPublished).format('MMM DD, YYYY'),
  },
  {
    title: 'Duration',
    accessor: 'duration',
    type: 'number',
    fmt: toTimeString,
  },
];

const fixFirstPublished = (ads, organization) =>
  ads.map((ad) => {
    ad.firstPublished = dayjs(ad.firstPublished).utc().toDate();
    ad.companyName = ad.company ? ad.company.name : '';
    ad.organization = organization;
    return ad;
  });

const AdsTable = ({organization, ads, company, orderBy}) => {
  const data = fixFirstPublished(ads, organization);

  return (
    <DataTable
      searchKeys={['text']}
      searchPlaceholder='Search by ad copy'
      columns={baseColumns}
      data={data}
      orderBy={orderBy ? orderBy : '-firstPublished'}
      onClickRowPath={({id, company}) =>
        company ? `/research/brands/${company.id}/ads/${id}` : null
      }
    />
  );
};

const FeedAdsTable = ({organization, ads, feed, orderBy}) => {
  const data = fixFirstPublished(ads, organization);

  const columns = baseColumns.slice();

  columns.splice(1, 0, {
    title: 'Company',
    accessor: 'companyName',
    type: 'string',
    Cell: ({data}) => {
      if (data.company) {
        return (
          <Link
            to={createOrgPath(
              organization,
              `/research/brands/${data.company.id}/podcasts/${feed.id}`
            )}
            css={`
              color: inherit;
              display: block;
              font-size: inherit;
              line-height: inherit;
              min-width: 12.5rem;
              text-decoration: none;
              :hover {
                color: var(--blue);
              }
            `}>
            {data.company.name}
          </Link>
        );
      }

      return <span />;
    },
  });

  return (
    <DataTable
      searchKeys={['companyName']}
      searchPlaceholder='Search by company'
      columns={columns}
      data={data}
      orderBy={orderBy ? orderBy : '-firstPublished'}
    />
  );
};

export {FeedAdsTable};

export default AdsTable;
