import {gql} from '@apollo/client';
import CompanyFragment from '@analytics/graphql-api/_old_queries/fragments/CompanyFragment';

export default gql`
  query getReviewerCompanies($organizationId: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          reviewing {
            companies {
              ...Company
              adsCount
            }
          }
        }
      }
    }
  }
  ${CompanyFragment}
`;
