import {useState} from 'react';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Card} from '@core/ui/Content';
import {Submit} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {
  FetchedOrganization,
  FetchedPodcast,
  useIsProcessingPodcastMutation,
} from '@analytics/graphql-api';

type Props = {
  organization: FetchedOrganization;
  podcast: FetchedPodcast;
};

export function VerifyProcessingPodcast({organization, podcast}: Props) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [isProcessingPodcast] = useIsProcessingPodcastMutation({
    update: (cache, {data}) => {
      const _processing = data?.isProcessingPodcast?.processing;
      if (_processing) {
        cache.modify({
          id: cache.identify(podcast),
          fields: {
            processingStartedAt() {
              return _processing;
            },
          },
        });
      }
    },
  });

  const onSubmit = async () => {
    if (error) setError(false);
    setLoading(true);
    try {
      const resp = await isProcessingPodcast({
        variables: {
          input: {
            organizationId: organization.id,
            podcastId: podcast.id,
          },
        },
      });

      setProcessing(!!resp?.data?.isProcessingPodcast?.processing);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  return (
    <Card>
      <Stack direction='column' gap={2}>
        {error && <Alert severity='error'>{t('errors.network')}</Alert>}
        <Text>
          {processing
            ? `If you have added the analytics prefix, check to see if we are
          receiving data.`
            : `We were unable to find downloads related to your feed. Please try
          again later.`}
        </Text>
        <form css={{textAlign: 'right'}} onSubmit={onSubmit}>
          <Submit disabled={loading}>Check Podcast</Submit>
        </form>
      </Stack>
    </Card>
  );
}
