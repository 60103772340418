import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import FeedAdd from './FeedAdd';
import FeedIndex from './feed';

const FeedsIndex = ({user, organization}) => {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/:part(a|s|b|p)/:slug/research/podcasts/add'
        user={user}
        organization={organization}
        component={FeedAdd}
      />
      <PropsRoute
        path='/:part(a|s|b|p)/:slug/research/podcasts/:feedId'
        user={user}
        organization={organization}
        component={FeedIndex}
      />
    </Switch>
  );
};

export default FeedsIndex;
