import {FeedMultipleImages} from '@core/ui/Image';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {CampaignDynamicPodcastObject, FeedObject} from '@analytics/graphql-api';

type Props = {
  d: {
    campaignDynamicPodcasts: CampaignDynamicPodcastObject[];
    feed: FeedObject;
    format: 'dynamic' | 'embedded' | 'streaming';
    name: string;
  };
};

export function LineItemCell({
  d: {feed, format, name, campaignDynamicPodcasts},
}: Props) {
  const feeds = campaignDynamicPodcasts
    ?.map(({feed}) => feed)
    .filter((feed) => !!feed) || [feed];

  return (
    <Stack
      alignItems='center'
      gap='var(--spacing-2)'
      css={{minWidth: '12.5rem'}}>
      <div
        css={`
          flex-shrink: 0;
        `}>
        {format !== 'streaming' && <FeedMultipleImages feeds={feeds} />}
      </div>
      <Text variant='subtitle2'>{name}</Text>
    </Stack>
  );
}
