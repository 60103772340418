import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import Button from '@core/ui/Button/LegacyButton';
import {Card, CardHeader} from '@core/ui/Content';
import {FORM_ERRORS, Form, FormFooter, Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import DELETE_PIXEL_EVENT_RECORD from '@analytics/graphql-api/_old_mutations/DeletePixelEventRecord';
import ORGANIZATION_KEY_QUERY from '@analytics/graphql-api/_old_queries/OrganizationKeyQuery';
import {ManagePixelsPixelEventsRouteProps} from '.';

const fmtStr = (s: string) => {
  return s
    .split(/[-_]/)
    .map((s) => s.toLowerCase())
    .join(' ');
};

export function PixelDeleteEvent({
  backPath,
  pixel,
  match: {
    params: {id},
  },
}: ManagePixelsPixelEventsRouteProps & {
  match: {params: {id: string}};
}) {
  const [loading, setLoading] = useState(false);
  const [complete, setCompleted] = useState(false);
  const [deleteEventRecord] = useMutation(DELETE_PIXEL_EVENT_RECORD, {
    refetchQueries: [ORGANIZATION_KEY_QUERY],
    awaitRefetchQueries: true,
  });
  const {
    handleSubmit,
    formState: {errors},
    clearErrors,
    setError,
  } = useForm();

  const event = pixel.pixelEventRecords.find((r) => r.id === id);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const data = await deleteEventRecord({
        variables: {
          input: {
            id: event.id,
          },
        },
      });
      if (data) {
        setCompleted(true);
      }
    } catch (e) {
      setError('graphql', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  const onCancel = () => {
    clearErrors();
    setCompleted(true);
  };

  if ((!event || complete) && !errors.graphql) {
    return <Redirect to={backPath} />;
  }

  return (
    <ResponsiveContent containerSize='small'>
      <CardHeader backPath={backPath}>Delete Event</CardHeader>
      <Card>
        <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
          <p>
            Are you sure you would like to delete{' '}
            {event.name ? `"${event.name}"` : 'this'} {fmtStr(event.eventType)}{' '}
            event?
          </p>
          <FormFooter>
            <Button type='button' onClick={onCancel} disabled={loading}>
              Cancel
            </Button>
            <Submit loading={loading} disabled={loading} color='error'>
              Delete Event
            </Submit>
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
}
