import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {CampaignRouteProps} from '../_routes';

export const useCampaignPixelsRoutes = ({
  campaign,
  organization,
}: Pick<CampaignRouteProps, 'organization' | 'campaign'>) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, `/campaigns/${campaign.slug}/pixels`),
    basePath: '/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/pixels',
    routes: [
      {
        key: 'redirect',
        path: '',
        exact: true,
      },
      {
        key: 'pixel',
        path: ':key',
      },
    ],
  });
};
