import {
  FC,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useMutation} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import {createOrgPath, isSelling} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import {Overlay} from '@core/ui/Overlay';
import {SaveBar} from '@core/ui/SaveBar';
import ApprovalsDynamicsTable from '@analytics/components/approvals/ApprovalsDynamicsTable';
import ApprovalsEmbeddedTable from '@analytics/components/approvals/ApprovalsEmbeddedTable';
import ApprovalsStreamingTable from '@analytics/components/approvals/ApprovalsStreamingTable';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
} from '@analytics/graphql-api';
import UPDATE_CAMPAIGN_APPROVALS_MUTATION from '@analytics/graphql-api/_old_mutations/UpdateCampaignApprovals';
import ME_APPROVALS_QUERY from '@analytics/graphql-api/_old_queries/MeApprovalsQuery';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {ApprovalsStateName, allItemsApproved, titleRule} from './helpers';

export const ApprovalsReportContext = createContext<{
  forceUpdateEdition: ApprovalsStateName;
}>({forceUpdateEdition: undefined});

export type ApprovalEditionItem = {
  data: any;
  state: {
    state: string;
    rejectedReason: string;
    comment: string;
  };
};

type ApprovalsConfirmReportProps = {
  campaign: FetchedCampaign;
  dynamicApprovals: any[];
  organization: FetchedOrganization | FetchedBuySideOrganization;
  podcastApprovals: any[];
  streamingApprovals: any[];
};

const ApprovalsConfirmReport: FC<ApprovalsConfirmReportProps> = ({
  campaign,
  dynamicApprovals,
  organization,
  podcastApprovals,
  streamingApprovals,
}): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [updateCampaignApprovals] = useMutation(
    UPDATE_CAMPAIGN_APPROVALS_MUTATION
  );
  const [forceUpdateEdition, setForceUpdateEdition] =
    useState<ApprovalsStateName>();
  const [discardCount, setDiscardCount] = useState<number>(0);
  const [edition, setEdition] = useState<ApprovalEditionItem[]>([]);
  const requested = useMemo(
    () => podcastApprovals.concat(dynamicApprovals).concat(streamingApprovals),
    [podcastApprovals, dynamicApprovals, streamingApprovals]
  );

  const onUpdateState = useCallback(({state, data}: ApprovalEditionItem) => {
    setEdition((_edition) => {
      const __edition = _edition.slice(0);
      const match = __edition.find(({data: d}) => data.id === d.id);

      if (match) {
        match.state = state;
      } else {
        __edition.push({state, data});
      }

      return __edition;
    });
  }, []);

  const saveReport = async () => {
    const campaignDynamicApprovalUpdates: any[] = [];
    const campaignPodcastApprovalUpdates: any[] = [];
    const campaignStreamingApprovalUpdates: any[] = [];

    edition.forEach(({state, data}) => {
      (data.campaignPodcast
        ? campaignPodcastApprovalUpdates
        : data.campaignDynamic
        ? campaignDynamicApprovalUpdates
        : campaignStreamingApprovalUpdates
      ).push({
        id: data.id,
        state: state.state.toLowerCase(),
        rejectedReason: state.rejectedReason,
        comment: state.comment,
      });
    });

    setLoading(true);

    try {
      await updateCampaignApprovals({
        variables: {
          input: {
            campaignId: campaign.id,
            organizationId: organization.id,
            campaignDynamicApprovalUpdates,
            campaignPodcastApprovalUpdates,
            campaignStreamingApprovalUpdates,
          },
        },
        refetchQueries: [ME_APPROVALS_QUERY],
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
    }

    setEdition([]);
    setLoading(false);

    // If all items are approved, send to tracking
    if (
      allItemsApproved(edition, requested) &&
      isSelling(organization) &&
      (campaign.state === 'DRAFT' || campaign.state === 'READY')
    ) {
      history.push(
        createOrgPath(
          organization,
          `/campaigns/${campaign.slug}/approvals/confirm/tracking`
        )
      );
    }
  };

  useEffect(() => {
    // TODO: Do this on the screens pages instead.
    // if no approvals, reroute to campaign
    if (
      dynamicApprovals.length < 1 &&
      podcastApprovals.length < 1 &&
      streamingApprovals.length < 1
    ) {
      history.push(toCampaignUrl(organization, campaign));
    }
  }, [
    campaign,
    dynamicApprovals,
    history,
    organization,
    podcastApprovals,
    streamingApprovals,
  ]);

  return (
    <>
      <Overlay
        opened={loading}
        centered
        withBackdrop
        css={`
          background: transparent;
          border: 0;
        `}>
        <Loading centered />
      </Overlay>
      <CardHeader
        rightContent={
          <div css={{display: 'flex', gap: '.625rem'}}>
            <Button onClick={() => setForceUpdateEdition('APPROVED')}>
              Approve All
            </Button>
            <Button onClick={() => setForceUpdateEdition('REJECTED')}>
              Reject All
            </Button>
          </div>
        }>
        Approval Report
      </CardHeader>
      <ApprovalsReportContext.Provider value={{forceUpdateEdition}}>
        <div css={titleRule}>Dynamics</div>
        <Card scrollable>
          <ApprovalsDynamicsTable
            key={discardCount}
            campaign={campaign}
            dynamicApprovals={dynamicApprovals}
            onUpdateState={onUpdateState}
          />
        </Card>
        <div css={titleRule}>Embedded</div>
        <Card scrollable css={{marginBottom: '3rem'}}>
          <ApprovalsEmbeddedTable
            key={`${discardCount}-embedded`}
            campaign={campaign}
            podcastApprovals={podcastApprovals}
            onUpdateState={onUpdateState}
          />
        </Card>
        <div css={titleRule}>Streaming</div>
        <Card scrollable css={{marginBottom: '3rem'}}>
          <ApprovalsStreamingTable
            key={`${discardCount}-streaming`}
            campaign={campaign}
            streamingApprovals={streamingApprovals}
            onUpdateState={onUpdateState}
          />
        </Card>
      </ApprovalsReportContext.Provider>
      <SaveBar
        changesCount={edition.length}
        changesText={`${edition.length} of ${
          dynamicApprovals.length +
          podcastApprovals.length +
          streamingApprovals.length
        }`}
        onCancel={() => {
          setEdition([]);
          setForceUpdateEdition(undefined);
          setDiscardCount(discardCount + 1);
        }}
        onSave={() => {
          saveReport();
        }}
      />
    </>
  );
};

export default ApprovalsConfirmReport;
