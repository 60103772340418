import {gql} from '@apollo/client';

export default gql`
  query getBrandOrganizationCampaignOverlapQuery(
    $id: ID!
    $after: Date
    $before: Date
  ) {
    me {
      id
      organization(id: $id) {
        id
        overview {
          impressions(after: $after, before: $before) {
            overlap {
              campaigns {
                baseDownloads
                baseReach
                baseCampaignId
                baseCampaign {
                  id
                  name
                  organization {
                    id
                    name
                  }
                }

                compDownloads
                compReach
                compCampaignId
                compCampaign {
                  id
                  name
                  organization {
                    id
                    name
                  }
                }
                overlap
                combined
              }
            }
          }
        }
      }
    }
  }
`;
