import {Switch} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import ManageNav from '@analytics/components/nav/ManageNav';
import {FetchedOrganization} from '@analytics/graphql-api';
import {AddApiKeys} from './AddApiKeys';

export type ManageApiKeysRouteProps = {
  organization: FetchedOrganization;
  user: FetchedUser;
};

export function ManageApiKeysIndex({
  user,
  organization,
}: ManageApiKeysRouteProps) {
  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, '/manage/api'),
    basePath: '/:part(b|a|s|p)/:slug/manage/api',
    routes: [{key: 'api', path: '', exact: true}],
  });

  return (
    <>
      <ManageNav user={user} organization={organization} />
      <Switch>
        {renderRoutes({
          components: {
            api: AddApiKeys,
          },
          extraProps: {
            user,
            organization,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
}
