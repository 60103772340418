import dayjs from '@core/lib/dayjs';
import {IncrementalBrandLiftReport} from '@analytics/components/lift/types';
import {CampaignObject, IabHourlyDownload} from '@analytics/graphql-api';

export {liftReportHardDates} from '../helpers';

export const defaultFormValues = ({
  liftReport,
}: {
  liftReport: IncrementalBrandLiftReport;
}) => {
  const impressionsStartAt = dayjs(liftReport.impressionsStartAt);
  const impressionsEndAt = dayjs(liftReport.impressionsEndAt);
  return {
    name:
      liftReport.name ??
      `Report ${impressionsStartAt.format(
        'YYYY-MM-DD'
      )} - ${impressionsEndAt.format('YYYY-MM-DD')}`,
    delta: liftReport.delta.toString(),
    dateRange: {
      impressionsStartAt,
      impressionsEndAt,
    },
    state: liftReport.state,
    useNoise: liftReport.useNoise,
    clampUrl: liftReport.clampUrl ?? undefined,
    networkError: false,
  };
};

type DailyDownloadsFn = (
  _hourly: IabHourlyDownload[]
) => {x: number; downloads: number}[];

const dailyDownloads: DailyDownloadsFn = (hourly) => {
  const downloadsByDay = Array.from(
    hourly
      .reduce((dailyMap, hourData) => {
        const timeString = dayjs(dayjs(hourData.hour).format('MMMM DD, YYYY'))
          .toDate()
          .getTime();

        const timeStringData = dailyMap.get(timeString);
        const oldDownloads = timeStringData?.downloads ?? 0;
        const newDownloads =
          (oldDownloads as number) + ((hourData.count ?? 0) as number);

        return dailyMap.set(timeString, {
          x: timeString,
          downloads: newDownloads,
        });
      }, new Map() as Map<number, {x: number; downloads: number}>)
      .values()
  );

  const maxDailyDownloads = downloadsByDay.reduce(
    (max, currentDay) =>
      max > currentDay.downloads ? max : currentDay.downloads,
    0
  );

  return downloadsByDay.map((day) => ({
    ...day,
    downloads: Math.round((day.downloads * 100) / maxDailyDownloads),
  }));
};

type LiftRequestChartDataFn = (_campaigns: Partial<CampaignObject>[]) => {
  x: string;
  downloads?: number;
  init?: number;
  installed?: number;
  lead?: number;
  purchase?: number;
}[];

export const liftRequestChartData: LiftRequestChartDataFn = (campaigns) => {
  return Array.from(
    campaigns
      .reduce((map, campaign) => {
        const hourly = campaign.downloads?.hourly ?? [];
        const historical = campaign.stateOverview?.pixel?.historical ?? [];

        historical.forEach((item) => {
          if (item) {
            const timeString = dayjs(dayjs(item.day).format('MMMM DD, YYYY'))
              .toDate()
              .getTime();
            map.set(timeString, {...item, x: timeString});
          }
        });

        const downloads = dailyDownloads(hourly as IabHourlyDownload[]);

        downloads.forEach((item) => {
          const timeSeriesData = map.get(item.x) ?? {};
          map.set(item.x, {...timeSeriesData, ...item});
        });

        return map;
      }, new Map())
      .values()
  );
};
