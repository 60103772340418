import {
  Divider as MuiDivider,
  type DividerProps as MuiDividerProps,
} from '@mui/material';

export type DividerProps = Pick<
  MuiDividerProps,
  | 'absolute'
  | 'children'
  | 'flexItem'
  | 'light'
  | 'orientation'
  | 'textAlign'
  | 'variant'
>;

export function Divider(props: DividerProps) {
  return <MuiDivider {...props} />;
}
