import {useHistory} from 'react-router-dom';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import CompanyIndustryForm from '@analytics/components/forms/CompanyIndustryForm';
import {ManageInviteRouteProps, toInviteCompanyUrl} from './_routes';

export function ManageInvite({organization}: ManageInviteRouteProps) {
  const history = useHistory();

  const onComplete = (id: string) =>
    history.push(toInviteCompanyUrl(organization, id));

  return (
    <ResponsiveContent containerSize='medium'>
      <Card>
        <CardHeader>Invite a Brand</CardHeader>
        <p>
          To invite a brand to Ad Analytics, we need their recognizable brand
          name. Ad Analytics has indexed podcast ads from thousands of brands,
          so we may already have an entry for the brand you are inviting.
        </p>
        <CompanyIndustryForm onComplete={onComplete} />
      </Card>
    </ResponsiveContent>
  );
}
