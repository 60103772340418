/* eslint-disable no-param-reassign */
import dayjs from '@core/lib/dayjs';

export const intervalsMap = {
  hourly: 'hour',
  daily: 'day',
  weekly: 'week',
};

export const intervalsDateFormat = {
  hourly: "MMM DD, 'YY · HH:00",
  daily: "MMM DD, 'YY",
  weekly: "MMM DD, 'YY",
};

/**
 * @param {Object} map - A map with chart data.
 *                       e.g. { 1620068400000: {impressions: 105, visits: 92, x: 1620068400000 } }
 * @param {number} minTime - Min datetime
 * @param {number} maxTime - Max datetime
 * @param {string} interval - {("hourly" | "daily" | "weekly")}
 *
 */
export const fillEmptyX = ({map, minTime, maxTime, interval, seriesKeys}) => {
  let currentDate = dayjs(minTime).utc().startOf(intervalsMap[interval]);
  const lastDate = dayjs(maxTime).utc().startOf(intervalsMap[interval]);

  while (currentDate.diff(lastDate) < 0) {
    const x = currentDate.toDate().getTime();

    if (map[x]) {
      seriesKeys.forEach((key) => {
        if (typeof map[x][key] !== 'number') {
          map[x][key] = 0;
        }
      });
    } else {
      map[x] = {
        x,
        date: currentDate.clone(),
      };

      seriesKeys.forEach((key) => {
        map[x][key] = 0;
      });
    }
    currentDate = currentDate.add(1, intervalsMap[interval]);
  }

  return map;
};
