import {gql} from '@apollo/client';

export default gql`
  query getCompanyAd($organizationId: ID!, $companyId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          company(id: $companyId) {
            id
            ad(id: $id) {
              id
              mpegPath
              flacPath
              waveform
              words
              text
              transcript
              duration
              state
              src

              product {
                id
                name
              }

              sponsor {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
