import {gql} from '@apollo/client';
import CampaignNoiseFragment from '@analytics/graphql-api/_old_queries/fragments/CampaignNoiseFragment';

export default gql`
  mutation createCampaignNoise($input: CreateCampaignNoiseInput!) {
    authed {
      success
    }
    createCampaignNoise(input: $input) {
      campaignNoise {
        ...CampaignNoise
      }
    }
  }
  ${CampaignNoiseFragment}
`;
