import {TooltipProps} from 'recharts';
import {comma} from '@core/lib/filters';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {LiftGroupSerie} from '../../types';

export function LiftGroupTooltip({
  activeSerie,
  payload,
  label,
}: TooltipProps<number, string> & {activeSerie: LiftGroupSerie}) {
  if (!payload || !payload.length) return null;
  return (
    <div
      css={{
        background: 'var(--bg-overlay)',
        border: '1px solid var(--border-default) ',
        borderRadius: '.5rem',
        boxShadow: 'var(--shadow-lg)',
        color: 'var(--text-default)',
        fontSize: '0.9375rem',
        padding: '1rem 1rem 1.5rem',
      }}>
      <Text variant='overline'>
        Week {label} {activeSerie}
      </Text>
      <Stack
        direction='column'
        gap='var(--spacing-2)'
        css={{marginTop: 'var(--spacing-2)'}}>
        {payload.map(({name, value, color}, idx: number) => (
          <div
            key={idx}
            css={{
              display: 'grid',
              gap: 'var(--spacing-2)',
              gridTemplateColumns: '2fr 1fr',
            }}>
            <div css={{color}}>
              {idx === 2 ? 'Average' : name?.split(' ')[0]}
            </div>
            <div css={{justifySelf: 'flex-end'}}>{comma(value ?? '')}</div>
          </div>
        ))}
      </Stack>
    </div>
  );
}
