import {FC} from 'react';
import {Icon} from '../Icon';
import {Tooltip, TooltipProps} from './Tooltip';

export interface InfoTooltipProps extends TooltipProps {
  iconSize?: number;
  marginLeft?: number;
  className?: string;
}

export const InfoTooltip: FC<InfoTooltipProps> = ({
  description,
  iconSize = 12,
  rules,
  title,
  ...props
}) => {
  if (!title && !description) return null;

  return (
    <Tooltip
      title={title}
      description={description}
      rules={[
        () => ({
          display: 'inline-flex',
        }),
        rules,
      ]}
      {...props}>
      <Icon
        icon='info-small'
        rules={() => ({
          color: 'var(--icon-muted)',
          width: `${iconSize}px`,
          height: `${iconSize}px`,
        })}
      />
    </Tooltip>
  );
};
