import MapDownloads from '@core/components/MapDownloads';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import {AttributionDownloadsTable} from '@analytics/components/attribution/AttributionDownloads';
import PixelEventsTable from '@analytics/components/pixels/PixelEventsTable';

const CampaignHousehold = ({data}) => {
  const {downloads, events} = data;
  const hasDownloads = downloads && downloads.count > 0;
  const hasEvents = events && events.count > 0;

  return (
    <>
      <CardHeader rules={() => ({margin: '0 1.5rem 1rem'})}>
        Downloads
      </CardHeader>
      {hasDownloads ? (
        <>
          <Card>
            <AttributionDownloadsTable downloads={downloads.nodes} />
          </Card>
          <CardHeader>Downloads Map</CardHeader>
          <Card rules={() => ({marginTop: '1rem'})}>
            <MapDownloads locations={downloads.nodes} />
          </Card>
        </>
      ) : (
        <Card>
          <EmptyMessage>Download happend at a different location</EmptyMessage>
        </Card>
      )}
      <CardHeader>Events</CardHeader>
      {hasEvents ? (
        <>
          <Card>
            <PixelEventsTable events={events.nodes} />
          </Card>
          <CardHeader>Events Map</CardHeader>
          <Card rules={() => ({marginTop: '1rem'})}>
            <MapDownloads locations={events.nodes} />
          </Card>
        </>
      ) : (
        <Card>
          <EmptyMessage>No events data</EmptyMessage>
        </Card>
      )}
    </>
  );
};

export default CampaignHousehold;
