import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import EmptyMessage from '@core/ui/EmptyMessage';
import {Submit} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';
import CampaignNoisesTable from '@analytics/components/campaigns/CampaignNoisesTable';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedCampaignLiftReports,
  FetchedIncrementalLiftReports,
  FetchedOrganization,
  useLiftReportPreRunQuery,
  useRunLiftReportMutation,
} from '@analytics/graphql-api';
import {campaignLiftURL} from '@analytics/screens/unified/campaigns/campaign/lift/_routes';
import {
  LiftFilterDynamicsTable,
  LiftFilterEpisodesTable,
} from './LiftFilterNodesTable';
import {canRunLiftReport, liftReportRunData} from './helpers';
import {RunData} from './types';

type LitReportRunFormImplProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign?: FetchedCampaign;
  campaignsData: [FetchedCampaign];
  liftReport:
    | NonNullable<FetchedIncrementalLiftReports[number]>
    | NonNullable<FetchedCampaignLiftReports[number]>;
  runData: RunData;
  user: FetchedUser;
};

const LiftReportRunFormImpl = ({
  organization,
  liftReport,
  campaign,
  campaignsData,
  runData,
  user,
}: LitReportRunFormImplProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [runLiftReport] = useRunLiftReportMutation();

  const onSubmit = async () => {
    setLoading(true);

    try {
      await runLiftReport({
        variables: {
          input: {
            liftReportId: liftReport.id,
          },
        },
      });

      history.push(
        campaign
          ? campaignLiftURL(organization, campaign)
          : createOrgPath(organization, 'measure/lift')
      );
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const canRun = canRunLiftReport({runData, liftReport});

  return (
    <div>
      {runData.campaignNoises.length > 0 && (
        <>
          <Card>
            <CardHeader>Household Groups</CardHeader>
            <CampaignNoisesTable
              organization={organization}
              campaignNoises={runData.campaignNoises}
            />
          </Card>
        </>
      )}
      {!liftReport.useNoise && runData.baselineDynamics.length > 0 && (
        <>
          <CardHeader>Baseline Campaign Dynamics</CardHeader>
          <Card>
            <LiftFilterDynamicsTable
              organization={organization}
              dynamicsData={runData.baselineDynamics}
            />
          </Card>
        </>
      )}
      {runData.activeDynamics.length > 0 && (
        <>
          <Card>
            <CardHeader>Exposed Campaign Dynamics</CardHeader>
            <LiftFilterDynamicsTable
              organization={organization}
              dynamicsData={runData.activeDynamics}
            />
          </Card>
        </>
      )}
      {!liftReport.useNoise && runData.baselineEpisodes.length > 0 && (
        <>
          <Card>
            <CardHeader>Baseline Campaign Episodes</CardHeader>
            <LiftFilterEpisodesTable
              organization={organization}
              episodesData={runData.baselineEpisodes}
              user={user}
            />
          </Card>
        </>
      )}
      {runData.exposedEpisodes.length > 0 && (
        <>
          <Card>
            <CardHeader>Exposed Campaign Episodes</CardHeader>
            <LiftFilterEpisodesTable
              organization={organization}
              episodesData={runData.exposedEpisodes}
              user={user}
            />
          </Card>
        </>
      )}
      <Card css={{textAlign: 'center', padding: '30px'}}>
        <CardHeader>Run Lift Report</CardHeader>
        {canRun ? (
          <>
            <EmptyMessage>Do you want to run this Lift Report?</EmptyMessage>
            <Submit loading={loading} onClick={onSubmit}>
              Run Lift Report
            </Submit>
            {error && <Alert severity='error'>{t('errors.network')}</Alert>}
          </>
        ) : (
          <>
            <Alert>You cannot run this lift report yet</Alert>
            <EmptyMessage title='Create at least one household group from the following campaign(s):'>
              <Stack direction='column' gap='var(--spacing-4)'>
                {campaignsData.map((c) => (
                  <Link
                    key={c.id}
                    to={`${campaignLiftURL(
                      organization,
                      c
                    )}/households/create`}>
                    {c.name}
                  </Link>
                ))}
              </Stack>
            </EmptyMessage>
          </>
        )}
      </Card>
    </div>
  );
};

type LiftReportRunFormProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign?: FetchedCampaign;
  liftReport:
    | NonNullable<FetchedIncrementalLiftReports[number]>
    | NonNullable<FetchedCampaignLiftReports[number]>;
  user: FetchedUser;
};

export function LiftReportRunForm({
  organization,
  campaign,
  liftReport,
  user,
}: LiftReportRunFormProps) {
  const {data, loading, error} = useLiftReportPreRunQuery({
    variables: {
      organizationId: organization.id,
      liftReportId: liftReport.id,
    },
  });

  if (loading) {
    return <Loading centered fixed />;
  }

  if (error) {
    return <Alert severity='error'>{t('errors.network')}</Alert>;
  }

  const liftReportData = data?.me?.organization?.liftReport;
  const campaigns = liftReportData?.campaigns?.length
    ? liftReportData.campaigns
    : [liftReportData?.campaign];

  return (
    <LiftReportRunFormImpl
      organization={organization}
      campaign={campaign}
      liftReport={liftReport}
      runData={liftReportRunData(campaigns)}
      campaignsData={campaigns}
      user={user}
    />
  );
}
