import {Card, CardHeader} from '@core/ui/Content';
import {ResearchNav} from '@analytics/components/nav/ResearchNav';
import RankingsTable from '@analytics/components/research/RankingsTable';

const Rankings = ({user, organization}) => {
  return (
    <>
      <ResearchNav user={user} organization={organization} />
      <CardHeader>Top Podcasts</CardHeader>
      <Card>
        <RankingsTable organization={organization} limit={100} />
      </Card>
    </>
  );
};

export default Rankings;
