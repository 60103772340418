import {useState} from 'react';
import styled from 'styled-components';
import {capitalize} from '@core/lib/filters';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import {InputCopy} from '@core/ui/Copy';
import {Label} from '@core/ui/FormElements';
import {LoadingSpinner} from '@core/ui/Loading';
import Select from '@core/ui/Select';
import {
  CampaignDynamicDocument,
  CampaignStreamingDocument,
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedCampaignDynamic,
  FetchedCampaignStreaming,
  FetchedOrganization,
  ProviderNameObject,
  UpdateCampaignDynamicInput,
  UpdateCampaignStreamingInput,
  useCampaignDynamicTrackingProvidersQuery,
  useCampaignStreamingTrackingProvidersQuery,
  useUpdateCampaignDynamicMutation,
  useUpdateCampaignStreamingMutation,
} from '@analytics/graphql-api';

type DynamicDropdownProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign: FetchedCampaign;
  campaignDynamic: FetchedCampaignDynamic;
  onError: () => void;
};

const DynamicDropdown = ({
  organization,
  campaign,
  campaignDynamic,
  onError,
}: DynamicDropdownProps) => {
  const [dynamicLoading, setDynamicLoading] = useState(false);
  const [updateCampaignDynamic] = useUpdateCampaignDynamicMutation();
  const {data, loading, error} = useCampaignDynamicTrackingProvidersQuery({
    variables: {
      organizationId: organization.id,
      campaignId: campaign.id,
      id: campaignDynamic?.id,
    },
  });

  const onSelect = async (item: ProviderNameObject) => {
    setDynamicLoading(true);
    try {
      await updateCampaignDynamic({
        variables: {
          input: {
            id: campaignDynamic?.id,
            providerName: item.providerName,
            organizationId: organization.id,
          } as UpdateCampaignDynamicInput,
        },
        refetchQueries: [CampaignDynamicDocument],
      });
    } catch (e) {
      onError();
    }
    setDynamicLoading(false);
  };

  if (dynamicLoading)
    return (
      <_LoadingContainer>
        <LoadingSpinner size='small' />
      </_LoadingContainer>
    );

  return (
    <Select
      key={`dynamic-${loading}-${campaignDynamic?.providerName}`}
      defaultValue={campaignDynamic?.providerName?.toLowerCase() ?? ''}
      disabled={loading || !!error}
      items={
        data?.me?.organization?.campaign?.campaignDynamic?.providerNames || []
      }
      onSelect={onSelect}
      outline
      placeholder='Select Provider'
      propertyForName='providerNameDisplay'
      propertyForValue='providerName'
    />
  );
};

type StreamingDropdownProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  campaign: FetchedCampaign;
  campaignStreaming: FetchedCampaignStreaming;
  onError: () => void;
};

const StreamingDropdown = ({
  organization,
  campaign,
  campaignStreaming,
  onError,
}: StreamingDropdownProps) => {
  const [streamingLoading, setStreamingLoading] = useState(false);
  const [updateCampaignStreaming] = useUpdateCampaignStreamingMutation();
  const {data, loading, error} = useCampaignStreamingTrackingProvidersQuery({
    variables: {
      organizationId: organization.id,
      campaignId: campaign.id,
      id: campaignStreaming?.id,
    },
  });

  const onSelect = async (item: ProviderNameObject) => {
    setStreamingLoading(true);
    try {
      await updateCampaignStreaming({
        variables: {
          input: {
            id: campaignStreaming?.id,
            providerName: item.providerName,
          } as UpdateCampaignStreamingInput,
        },
        refetchQueries: [CampaignStreamingDocument],
      });
    } catch (e) {
      onError();
    }
    setStreamingLoading(false);
  };

  if (streamingLoading)
    return (
      <_LoadingContainer>
        <LoadingSpinner size='small' />
      </_LoadingContainer>
    );

  return (
    <Select
      key={`streaming-${loading}-${campaignStreaming?.providerName}`}
      defaultValue={campaignStreaming?.providerName?.toLowerCase() ?? ''}
      disabled={loading || !!error}
      items={
        data?.me?.organization?.campaign?.campaignStreaming?.providerNames || []
      }
      onSelect={onSelect}
      outline
      placeholder='Select Provider'
      propertyForName='providerNameDisplay'
      propertyForValue='providerName'
    />
  );
};

type Props =
  | {
      organization: FetchedOrganization | FetchedBuySideOrganization;
      campaign: FetchedCampaign;
      campaignItem: FetchedCampaignDynamic;
      type: 'dynamic';
    }
  | {
      organization: FetchedOrganization | FetchedBuySideOrganization;
      campaign: FetchedCampaign;
      campaignItem: FetchedCampaignStreaming;
      type: 'streaming';
    };

export default function TrackingUrlsDropdown({
  organization,
  campaign,
  campaignItem,
  type,
}: Props) {
  const [error, setError] = useState(false);

  const onError = () => setError(true);

  return (
    <>
      <CardHeader>{capitalize(type)} Tracking URL</CardHeader>
      <Card>
        {error && (
          <ToastMessage alertType='error' rules={() => ({margin: '0 0 1rem'})}>
            Error updating provider
          </ToastMessage>
        )}
        <Label>Select a provider:</Label>
        {type === 'dynamic' ? (
          <DynamicDropdown
            organization={organization}
            campaign={campaign}
            campaignDynamic={campaignItem}
            onError={onError}
          />
        ) : (
          <StreamingDropdown
            organization={organization}
            campaign={campaign}
            campaignStreaming={campaignItem}
            onError={onError}
          />
        )}
        {campaignItem?.providerTrackingUrl && (
          <div css={{marginTop: '1.5rem'}}>
            <Label>
              Please copy the following tracking URL and add it to the{' '}
              {capitalize(campaignItem.providerName ?? '')} {type} insertion.
            </Label>
            <InputCopy text={campaignItem.providerTrackingUrl} />
          </div>
        )}
      </Card>
    </>
  );
}

const _LoadingContainer = styled.div`
  align-items: center;
  border: 1px solid var(--border-default);
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0.625rem;
  width: 8rem;
`;
