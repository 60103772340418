import {comma, financial, toFixed} from '@core/lib/filters';
import {t} from '@core/texts';
import {Grid} from '@core/ui/Content';
import {CardStat} from '@core/ui/Stat';
import useSettings from '@analytics/components/settings/useSettings';
import {FetchedCampaign} from '@analytics/graphql-api';

export const CampaignCheckoutStats = ({
  campaign,
}: {
  campaign: FetchedCampaign;
}) => {
  const [settings] = useSettings();
  const {reachTotal, costToDate} = campaign;

  const downloadTotal =
    campaign.downloads && campaign.downloads.count
      ? campaign.downloads.count
      : campaign.downloadTotal;

  const checkoutTotal =
    (settings.modelled
      ? campaign.checkoutTotalModeled
      : campaign.checkoutTotal) ?? 0;

  const costPerCheckout = checkoutTotal ? (costToDate ?? 0) / checkoutTotal : 0;

  return (
    <Grid
      cols={4}
      rules={() => ({
        gridGap: '1.5rem',
        margin: '0 1.5rem 0',
      })}>
      <CardStat value={comma(downloadTotal)} {...t('stats.impressions')} />
      <CardStat value={comma(reachTotal ?? 0)} {...t('stats.reach')} />
      <CardStat value={toFixed(checkoutTotal)} title='Checkouts' />
      <CardStat value={financial(costPerCheckout)} title='CPC' />
    </Grid>
  );
};
