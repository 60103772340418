import {FC, useState} from 'react';
import {useMutation} from '@apollo/client';
import {Controller, useForm} from 'react-hook-form';
import {ToastMessage} from '@core/ui/Alert';
import Button, {ButtonIcon} from '@core/ui/Button';
import DataTable from '@core/ui/DataTable';
import {
  FORM_ERRORS,
  Form,
  FormFooter,
  FormHelp,
  FormSeparator,
  Label,
  Submit,
} from '@core/ui/FormElements';
import {MultipleSelect} from '@core/ui/Select';
import Select from '@core/ui/Select';
import {
  FetchedBuySideOrganization,
  FetchedCampaign,
  FetchedOrganization,
} from '@analytics/graphql-api';
import CREATE_CAMPAIGN_APPROVALS_MUTATION from '@analytics/graphql-api/_old_mutations/CreateCampaignApprovals';
import CAMPAIGN_APPROVALS_QUERY from '@analytics/graphql-api/_old_queries/CampaignApprovalsQuery';
import ME_APPROVALS_QUERY from '@analytics/graphql-api/_old_queries/MeApprovalsQuery';

type ApprovalConfigQuery = {
  publisherMembers: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
  brandMembers: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
  buySideAgencies: {
    id: string;
    name: string;
    members: {
      id: string;
      firstName: string;
      lastName: string;
    }[];
  }[];
};

type AddMembersFormProps = {
  addedMembers: any;
  onAddMembers: (_members: any) => void;
  campaign: FetchedCampaign;
  approvalConfig: ApprovalConfigQuery;
};

const getAllOrgs = ({
  campaign,
  approvalConfig: {publisherMembers, brandMembers, buySideAgencies},
}: Pick<AddMembersFormProps, 'campaign' | 'approvalConfig'>) => {
  return [
    {
      organizationId: campaign.organization?.id,
      organizationName: campaign.organization?.name,
      members: publisherMembers,
    },
    !!campaign.advertiser?.organization?.id && {
      organizationId: campaign.advertiser.organization.id,
      organizationName: campaign.advertiser.organization.name,
      members: brandMembers,
    },
    ...buySideAgencies.map(({id, name, members}) => ({
      organizationId: id,
      organizationName: name,
      members,
    })),
  ]
    .filter(Boolean)
    .map(({members, organizationName, organizationId}) => ({
      organizationName,
      organizationId,
      members: members.map((member) => ({
        organizationName,
        organizationId,
        memberId: member.id,
        memberName: `${member.firstName} ${member.lastName}`,
      })),
    }));
};

const AddMembersForm: FC<AddMembersFormProps> = ({
  addedMembers,
  onAddMembers,
  campaign,
  approvalConfig,
}): JSX.Element => {
  const [updates, setUpdates] = useState<number>(0);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const orgs = getAllOrgs({campaign, approvalConfig});
  const [selectedOrganization, setSelectedOrganization] = useState(orgs[0]);

  if (!campaign.advertiser?.organization?.id) {
    return (
      <ToastMessage alertType='warning' rules={() => ({margin: '0'})}>
        No advertiser found.
      </ToastMessage>
    );
  }

  return (
    <>
      <div
        css={{
          display: 'grid',
          gridGap: '1.5rem',
          gridTemplateColumns: '1fr 1fr',
        }}>
        <div>
          <Label>Organization</Label>
          <Select
            items={orgs}
            defaultValue={orgs[0] ? orgs[0].organizationId : null}
            propertyForName='organizationName'
            propertyForValue='organizationId'
            onSelect={(org) => setSelectedOrganization(org)}
            rules={() => ({width: '100%'})}
          />
          <FormHelp>
            Select the organization that you wish to receive the approval from.
          </FormHelp>
        </div>
        <div>
          <Label>Members</Label>
          <MultipleSelect
            key={selectedOrganization?.organizationId + updates}
            items={
              selectedOrganization?.members.filter(
                ({memberId}) =>
                  !addedMembers.find((m) => memberId === m.memberId)
              ) || []
            }
            onSelect={(members) => setSelectedMembers(members)}
            disabled={!selectedOrganization}
            placeholder='Members'
            propertyForName='memberName'
            propertyForValue='memberId'
            searchKeys={['memberName', 'memberId']}
            searchable
            rules={() => ({width: '100%'})}
          />
          <FormHelp>
            Request from an individual or multiple people at an organization. If
            you leave this blank, anyone at the organization can approve this
            request.
          </FormHelp>
        </div>
      </div>
      <div css={{margin: '1.5rem 0 0 0', textAlign: 'right'}}>
        <Button
          type='button'
          color='primary'
          onClick={(evt) => {
            evt.stopPropagation();
            const newMembers = [
              ...addedMembers,
              ...(selectedMembers.length > 0
                ? selectedMembers
                : selectedOrganization.members
              ).filter(
                ({memberId}) =>
                  !addedMembers.find((m) => m.memberId === memberId)
              ),
            ];

            onAddMembers(newMembers);
            setUpdates(updates + 1);
          }}>
          Add to list
        </Button>
      </div>
    </>
  );
};

const ApprovalsRequestForm = ({
  approvalConfig,
  campaign,
  onComplete,
  organization,
}: {
  approvalConfig: ApprovalConfigQuery;
  campaign: FetchedCampaign;
  onComplete: (_input?: any) => void;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}): JSX.Element => {
  const [createCampaignApprovals] = useMutation(
    CREATE_CAMPAIGN_APPROVALS_MUTATION,
    {
      refetchQueries: [CAMPAIGN_APPROVALS_QUERY, ME_APPROVALS_QUERY],
    }
  );
  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    setValue,
    handleSubmit,
    setError,
    watch,
    formState: {errors},
  } = useForm();

  const onSubmit = async (data) => {
    const {members} = data;
    const requests = Object.values(
      members.reduce((acc, obj) => {
        if (acc[obj.organizationId]) {
          acc[obj.organizationId].requesteeIds.push(obj.memberId);
        } else {
          acc[obj.organizationId] = {
            requesteeOrganizationId: obj.organizationId,
            requesteeIds: [obj.memberId],
          };
        }
        return acc;
      }, {})
    );

    setLoading(true);

    try {
      const input = {
        campaignId: campaign.id,
        organizationId: organization.id,
        requests,
      };
      await createCampaignApprovals({
        variables: {
          input,
        },
      });
      if (typeof onComplete === 'function') {
        onComplete(input);
      }
    } catch (err) {
      setError(null, FORM_ERRORS.network);
    }
    setLoading(false);
  };

  const addedMembers = watch('members') || [];

  return (
    <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
      <Controller
        name='members'
        control={control}
        render={({field}) => {
          return (
            <AddMembersForm
              campaign={campaign}
              approvalConfig={approvalConfig}
              addedMembers={addedMembers}
              onAddMembers={(members) => {
                field.onChange(members);
              }}
              {...field}
            />
          );
        }}
      />
      <FormSeparator />
      <p>Request approval from the following members:</p>
      <div css={{border: '1px solid var(--bg-muted)', borderRadius: '.5rem'}}>
        <DataTable
          emptyMessageText='Select an organization and member from above.'
          data={addedMembers}
          columns={[
            {
              title: 'Member',
              accessor: 'memberName',
              type: 'string',
            },
            {
              title: 'Organization',
              accessor: 'organizationName',
              type: 'string',
            },
            {
              title: '',
              Cell: ({data}) => (
                <ButtonIcon
                  icon='bin'
                  onClick={() => {
                    setValue(
                      'members',
                      addedMembers.filter(
                        ({memberId}) => memberId !== data.memberId
                      )
                    );
                  }}
                />
              ),
            },
          ]}
        />
      </div>
      <FormFooter>
        <Submit loading={loading}>Request Approvals</Submit>
      </FormFooter>
    </Form>
  );
};

export default ApprovalsRequestForm;
