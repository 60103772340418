import {
  Box,
  ButtonTertiary,
  DialogConfirmation,
  Image,
  Type,
} from '@spotify-internal/encore-web';
import CompletedImage from './completed.svg';

export const ApprovedDialogue = ({onClose, approvalDialogueState}) => (
  <DialogConfirmation
    aria-label='Approved Dialogue'
    body={
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 16,
        }}>
        <Image
          src={CompletedImage}
          alt='completed'
          style={{
            height: 118,
            width: 126,
          }}
        />
        <Type variant='cello'>Approval Submitted </Type>
        <Type variant='mesto'>Your mapping suggestion has been submitted.</Type>
        <ButtonTertiary onClick={onClose}>Close</ButtonTertiary>
      </Box>
    }
  />
);
