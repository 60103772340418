import React from 'react';
import CampaignConsistency from '@analytics/components/campaigns/CampaignConsistency';
import CampaignPodcastNav from '@analytics/components/nav/CampaignPodcastNav';

const CampaignPodcastConsistency = ({
  organization,
  campaign,
  campaignPodcast,
}) => {
  return (
    <>
      <CampaignPodcastNav
        organization={organization}
        campaign={campaign}
        campaignPodcast={campaignPodcast}
      />
      <CampaignConsistency
        organizationId={organization.id}
        campaignId={campaign.id}
        campaignPodcastId={campaignPodcast.id}
      />
    </>
  );
};

export default CampaignPodcastConsistency;
