import {Controller} from 'react-hook-form';
import {Label} from '@core/ui/FormElements';
import {Stack} from '@core/ui/Layout';
import {FieldProps} from '../../helpers';
import {DateInput} from './DateInput';
import {RangeInput} from './RangeInput';
import {defaultValues, validateDatesField} from './helpers';

type Props = FieldProps & {
  showRange: boolean;
};

export function DatesField({control, setValue, showRange, watch}: Props) {
  const {
    exportParameters: {startDate, endDate, reportPeriod},
    typeId,
  } = watch();

  const onSelectRange = ({startDate, endDate, reportPeriod}) => {
    setValue('exportParameters.reportPeriod', reportPeriod);
    setValue('exportParameters.startDate', startDate);
    setValue('exportParameters.endDate', endDate);
  };

  return (
    <Stack direction='column'>
      <Label htmlFor='dates'>Date(s)</Label>
      <Controller
        name='exportParameters.startDate'
        control={control}
        rules={{
          validate: () =>
            validateDatesField({
              endDate,
              reportPeriod,
              showRange,
              startDate,
              typeId,
            }),
        }}
        render={({field, fieldState: {error}}) =>
          showRange ? (
            <RangeInput
              key={typeId}
              defaultValues={defaultValues({startDate, endDate, reportPeriod})}
              invalid={!!error}
              onSelectRange={onSelectRange}
              typeId={typeId}
            />
          ) : (
            <DateInput
              key={typeId}
              invalid={!!error}
              onSelect={field.onChange}
            />
          )
        }
      />
    </Stack>
  );
}
