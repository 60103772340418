import {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import Select from '@core/ui/Select';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignRouteProps} from '../../_routes';

export function CampaignLinkSelect({
  user,
  organization,
  campaign,
  match: {url},
}: CampaignRouteProps & {
  match: {
    url: string;
  };
}) {
  const [selected, onSelect] = useState(null);

  const items = campaign.campaignTargets.map(({id, feed: {title}}) => ({
    title,
    value: id,
  }));

  if (selected || items.length === 1) {
    return <Redirect to={`${url}/${selected || items[0].value}`} />;
  }

  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <ResponsiveContent containerSize='medium'>
        <CardHeader>Create a Link</CardHeader>
        <Card>
          <p>What Podcast will this link be promoting?</p>
          <Select
            items={items}
            onSelect={({value}) => {
              onSelect(value);
            }}
            rules={() => ({width: '100%'})}
            placeholder='Select a podcast'
            withMargin
          />
        </Card>
      </ResponsiveContent>
    </>
  );
}
