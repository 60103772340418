import {useLayoutEffect, useRef, useState} from 'react';
import {
  ButtonTertiary,
  IconHelpCircle,
  Tooltip as TooltipContent,
  TooltipTrigger,
  Type,
  cssColorValue,
  greenBlue100,
  semanticColors,
  spacer8,
  spacer16,
  spacer24,
} from '@spotify-internal/encore-web';
import {format} from 'd3-format';
import {upperFirst} from 'lodash';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Text,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {comma} from '@core/lib/filters';
import {Stack} from '@core/ui/Layout';
import {DEFAULT_METRIC, METRIC_CHART_LABELS} from '../constants';
import {AudienceInsights, InsightsMetric} from '../types';
import {crIndexToPercent, parseInsightsBarChartData} from '../utils';
import {InsightsEmptyState} from './InsightsEmptyState';
import {LegendIndicator} from './styled';

type Props = {
  audience: AudienceInsights;
  color?: string;
  height?: number;
  metric?: InsightsMetric;
};

export function InsightsBarChart({
  audience,
  color = greenBlue100,
  height = 300,
  metric = DEFAULT_METRIC,
}: Props) {
  const widthRef = useRef<HTMLDivElement>(null);
  const [labelWidth, setLabelWidth] = useState(DEFAULT_LABEL_WIDTH);

  const data = parseInsightsBarChartData(audience, metric);

  useLayoutEffect(() => {
    const updateLabelWidth = () => {
      setLabelWidth(
        Math.max(
          DEFAULT_LABEL_WIDTH,
          (widthRef.current?.getBoundingClientRect()?.width ?? 0) /
            (data.length || 1)
        )
      );
    };

    window.addEventListener('resize', updateLabelWidth);
    updateLabelWidth();

    return () => window.removeEventListener('resize', updateLabelWidth);
  }, [data.length]);

  if (!data.length) {
    return <InsightsEmptyState />;
  }

  return (
    <div ref={widthRef}>
      <ResponsiveContainer height={height} width='100%'>
        <BarChart data={data}>
          <YAxis
            axisLine={false}
            dataKey={metric}
            stroke={AXIS_COLOR}
            tick={{
              fill: LABEL_COLOR,
              fontSize: LABEL_SIZE,
            }}
            tickFormatter={(val) => {
              if (metric === InsightsMetric.CONVERSIONS) {
                return crIndexToPercent(val);
              }

              return format('.1s')(val);
            }}
            tickLine={false}
            yAxisId='left'
          />
          <XAxis
            dataKey='segment'
            interval={0}
            stroke={AXIS_COLOR}
            tick={(props) => (
              <InsightsCustomTick labelWidth={labelWidth} {...props} />
            )}
            type='category'
          />
          <CartesianGrid
            stroke={AXIS_COLOR}
            strokeDasharray='0 0'
            vertical={false}
          />
          <Bar
            dataKey={metric}
            yAxisId='left'
            fill={color}
            maxBarSize={BAR_SIZE}
          />
          <Tooltip
            content={<InsightsChartTooltip metric={metric} />}
            cursor={false}
          />
        </BarChart>
      </ResponsiveContainer>
      <InsightsChartLegend color={color} metric={metric} />
    </div>
  );
}

const AXIS_COLOR = cssColorValue(semanticColors.decorativeSubdued);
const LABEL_COLOR = cssColorValue(semanticColors.textSubdued);
const BAR_SIZE = 40;
const LABEL_SIZE = 'var(--font-size-body-3)';
const DEFAULT_LABEL_WIDTH = BAR_SIZE + 5;

const InsightsChartTooltip = ({
  payload,
  metric,
}: {metric: InsightsMetric} & TooltipProps<string, string>) => {
  if (!payload || !payload.length) return null;
  return (
    <TooltipContent colorSet='invertedDark'>
      <Stack gap={spacer16}>
        <Type>{upperFirst(payload[0].payload.segment)}</Type>
        <Type>
          {metric === InsightsMetric.CONVERSIONS
            ? crIndexToPercent(Number(payload[0].value ?? 0))
            : comma(payload[0].value ?? '')}
        </Type>
      </Stack>
    </TooltipContent>
  );
};

const InsightsCustomTick = ({payload, x, y, labelWidth}) => {
  if (typeof payload.value !== 'string') return <></>;
  return (
    <Text
      fill={LABEL_COLOR}
      fontSize={LABEL_SIZE}
      maxLines={3}
      textAnchor='middle'
      verticalAnchor='start'
      width={labelWidth}
      x={x}
      y={y}>
      {upperFirst(payload.value)}
    </Text>
  );
};

const InsightsChartLegend = ({color, metric}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <Stack alignItems='center' gap={spacer8} css={{marginTop: spacer24}}>
      <LegendIndicator color={color} />
      <Type semanticColor={semanticColors.textSubdued} variant='mesto'>
        {METRIC_CHART_LABELS[metric]}
      </Type>
      {metric === InsightsMetric.CONVERSIONS && (
        <TooltipTrigger
          overlay={
            showTooltip && (
              <TooltipContent
                id={METRIC_CHART_LABELS[metric]}
                colorSet='invertedDark'>
                Conversion rate index is the conversion rate per audience
                segment divided by the overall conversion rate for the campaign.
              </TooltipContent>
            )
          }
          placement='end'
          onShow={() => setShowTooltip(true)}
          onHide={() => setShowTooltip(false)}>
          <ButtonTertiary
            aria-labelledby={METRIC_CHART_LABELS[metric]}
            condensed
            css={{padding: 0}}
            iconOnly={IconHelpCircle}
            size='small'
          />
        </TooltipTrigger>
      )}
    </Stack>
  );
};
