import { cssBorderWidth, cssColorValue, cssControlSize, focusBorderWidth, overlay, semanticColors } from "../../styles/index.js";
import { TypeList as ListComponent } from "../TypeList/index.js";
import styled, { css } from "../../styled-components.js";

/* Show up to 5.5 items, then resort to scrolling the rest.
 * We show a "half" item so that it is obvious to the user that there is more below the fold.
 * 32px (item height) * 5.5 + 1px (border height) = 177px
 * */

var focusStyles = function focusStyles(isUsingKeyboard) {
  return css(["outline:none;border:", " solid ", ";", ""], cssBorderWidth('hairline', '1px'), cssColorValue(semanticColors.essentialBase), isUsingKeyboard && css(["border:", "px solid ", ";"], focusBorderWidth, cssColorValue(semanticColors.essentialBase)));
};
export var List = styled(ListComponent).attrs({
  condensedAll: true,
  listStyleReset: true
}).withConfig({
  displayName: "List",
  componentId: "sc-mygaxj-0"
})(["", ";display:block;pointer-events:all;width:100%;max-block-size:calc((", " * 5.5) + 1px);overflow:auto;padding-block-start:0;padding-inline-end:0;&:focus{", ";}", ";"], overlay({
  maxWidth: 'none'
}), cssControlSize('smaller', '32px'), function (props) {
  return !props.useBrowserDefaultFocusStyle && focusStyles(props.isUsingKeyboard);
}, function (props) {
  return props.isFocus && !props.useBrowserDefaultFocusStyle && focusStyles(props.isUsingKeyboard);
});