import {exists} from '@core/lib/utils';
import {MessageResultGroupObject} from '@analytics/graphql-api';
import {toNum, toString} from '../helpers';
import {
  BarLiftData,
  PieLiftData,
  SpotifyBrandLiftMessageContent,
  SpotifyBrandLiftMessageResult,
} from '../types';

const displayResult = (
  groupName: string,
  result: MessageResultGroupObject | null | undefined,
  total?: number
) => {
  if (result && result.controlTotal >= 50 && result.exposedTotal >= 50) {
    return [
      {
        group: groupName,
        ...result,
        ...(exists(total) && {total}),
      },
    ];
  }
  return [];
};

const getMessageAnswers = ({
  messageContent: {answer1, answer2, answer3, answer4, answer5},
}: {
  messageContent: SpotifyBrandLiftMessageContent;
}) => ({
  answer1: toString(answer1),
  answer2: toString(answer2),
  answer3: toString(answer3),
  answer4: toString(answer4),
  answer5: toString(answer5),
});

const fmtAndSumAnswers = (answers: (number | undefined | null)[]) =>
  answers.reduce(
    (totals, answer, i) => {
      const num = toNum(answer);
      return {
        ...totals,
        [`answer${i + 1}`]: num,
        groupTotal: totals.groupTotal + num,
      };
    },
    {groupTotal: 0} as {
      groupTotal: number;
      answer1: number;
      answer2: number;
      answer3: number;
      answer4: number;
      answer5: number;
    }
  );

type GenerateSBLChartDataFn = ({
  message,
}: {
  message: SpotifyBrandLiftMessageResult;
}) => {
  age: BarLiftData[];
  control: PieLiftData[];
  exposed: PieLiftData[];
  gender: (BarLiftData & PieLiftData)[];
};

export const generateSBLChartData: GenerateSBLChartDataFn = ({
  message: {result, messageContents},
}) => {
  const {answer1, answer2, answer3, answer4, answer5} = getMessageAnswers({
    messageContent: messageContents[0],
  });

  const controlTotals = fmtAndSumAnswers([
    result?.controlAnswer1,
    result?.controlAnswer2,
    result?.controlAnswer3,
    result?.controlAnswer4,
    result?.controlAnswer5,
  ]);

  const exposedTotals = fmtAndSumAnswers([
    result?.exposedAnswer1,
    result?.exposedAnswer2,
    result?.exposedAnswer3,
    result?.exposedAnswer4,
    result?.exposedAnswer5,
  ]);

  return {
    age: [
      ...displayResult('13-17', result?.age13To17),
      ...displayResult('18-24', result?.age18To24),
      ...displayResult('25-34', result?.age25To34),
      ...displayResult('35-44', result?.age35To44),
      ...displayResult('45+', result?.age45Plus),
    ],
    gender: [
      ...displayResult(
        'Female',
        result?.female,
        (result?.female.controlTotal ?? 0) + (result?.female.exposedTotal ?? 0)
      ),
      ...displayResult(
        'Male',
        result?.male,
        (result?.male.controlTotal ?? 0) + (result?.male.exposedTotal ?? 0)
      ),
    ],
    ...[answer1, answer2, answer3, answer4, answer5].reduce(
      (totals, answer, i) => ({
        control: [
          ...totals.control,
          {
            group: answer,
            total: controlTotals[`answer${i + 1}`],
            groupTotal: controlTotals.groupTotal,
          },
        ],
        exposed: [
          ...totals.exposed,
          {
            group: answer,
            total: exposedTotals[`answer${i + 1}`],
            groupTotal: controlTotals.groupTotal,
          },
        ],
      }),
      {control: [] as PieLiftData[], exposed: [] as PieLiftData[]}
    ),
  };
};
