import { arrayReduce } from 'fast-loops';
import getFontUrl from './getFontUrl';
import getFontFormat from './getFontFormat';
export default function generateFontSource() {
  var files = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var fontLocals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var localSource = arrayReduce(fontLocals, function (src, local, index) {
    var prefix = index > 0 ? ',' : '';
    var localUrl = getFontUrl(local);
    return "".concat(src).concat(prefix, "local(").concat(localUrl, ")");
  }, '');
  var urlSource = arrayReduce(files, function (src, fileSource, index) {
    var prefix = index > 0 ? ',' : '';
    var fileFormat = getFontFormat(fileSource);
    var fileUrl = getFontUrl(fileSource);
    return "".concat(src).concat(prefix, "url(").concat(fileUrl, ") format('").concat(fileFormat, "')");
  }, '');
  var delimiter = localSource.length > 0 && urlSource.length > 0 ? ',' : '';
  return "".concat(localSource).concat(delimiter).concat(urlSource);
}