import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Stack} from '@core/ui/Layout';
import {LoadingSpinner} from '@core/ui/Loading';
import {useCampaignStateQuery} from '@analytics/graphql-api';
import {CampaignDraftProps} from '..';
import {Row, Title} from '../_shared';
import {DisplayStatus} from './_shared';

export function CampaignPixelDetails({
  organization,
  campaign,
}: CampaignDraftProps) {
  const {data, loading, error} = useCampaignStateQuery({
    variables: {
      id: campaign.id,
      organizationId: organization.id,
    },
  });

  if (campaign.kind === 'reporting') {
    return null;
  }

  const pixelHasData =
    data?.me?.organization?.campaign?.stateOverview?.pixel?.hasData ?? false;

  return (
    <>
      <Row>
        <Title
          rightContent={
            <Button
              buttonSize='sm'
              href='https://help.adanalytics.spotify.com/install-the-spotify-pixel'
              target='_blank'
              variant='outlined'>
              Learn more
            </Button>
          }>
          Pixel Installed
        </Title>
        <Stack>
          {loading ? (
            <LoadingSpinner size='small' />
          ) : error ? (
            <Alert severity='error'>{t('errors.network')}</Alert>
          ) : (
            <DisplayStatus success={pixelHasData} />
          )}
        </Stack>
      </Row>
      {campaign.kind === 'attribution' && pixelHasData && (
        <Row>
          <Title>Attribution has run</Title>
          <Stack>
            <DisplayStatus
              success={campaign.lastBuilt}
              statusDetails={
                !campaign.lastBuilt &&
                `Ad Analytics has not run attribution on this campaign. The attribution job
                 runs nightly, so please check back tomorrow for results.`
              }
            />
          </Stack>
        </Row>
      )}
    </>
  );
}
