import {Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {pixelEditRoutes} from '@analytics/lib/routes/pixelsRoutes';
import {ManagePixelsPixelRouteProps} from '../_routes';
import {PixelAddMapping} from './PixelAddMapping';
import {PixelDeleteMapping} from './PixelDeleteMapping';
import {PixelMappingsOverview} from './PixelMappingsOverview';
import {PixelUpdateMapping} from './PixelUpdateMapping';

export type ManagePixelsPixelMappingsRouteProps =
  ManagePixelsPixelRouteProps & {
    backPath: string;
  };

export function PixelMappingsIndex({
  organization,
  pixel,
}: ManagePixelsPixelRouteProps) {
  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, `manage/pixels/${pixel.key}`),
    basePath: '/:part(b|a|s|p)/:slug/manage/pixels/:key',
    routes: pixelEditRoutes({baseRoute: 'mappings'}),
  });

  const backPath = createOrgPath(
    organization,
    `manage/pixels/${pixel.key}/mappings`
  );

  return (
    <Switch>
      {renderRoutes({
        components: {
          overview: PixelMappingsOverview,
          create: PixelAddMapping,
          update: PixelUpdateMapping,
          delete: PixelDeleteMapping,
        },
        extraProps: {
          organization,
          pixel,
          backPath,
        },
        redirects: [{from: '*', to: 'mappings'}],
      })}
    </Switch>
  );
}
