import {HTMLProps} from 'react';
import styled from 'styled-components';
import {Icon} from '@core/ui/Icon';
import {Tooltip} from '@core/ui/Tooltip';
import {SortBy} from '../_types';

export const CellGrid = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: max-content 1fr;
`;

export const TableHeader = styled.th<{active?: boolean}>`
  border-bottom: 0.0625rem solid var(--table-border-row);
  color: ${(th) => th.active && 'var(--blue)'};
  cursor: ${(th) => th.active && 'pointer'};
  padding: 0.375rem 1rem;
  vertical-align: middle;
  width: 2rem;
`;

type RowProps = {
  depth?: number;
  expandable?: boolean;
  parentCollapsed: boolean;
};

export const TableRow = styled.tr<RowProps>`
  background: ${(tr) =>
    tr.expandable
      ? !tr.depth
        ? 'var(--bg-muted)'
        : 'var(--bg-subtle)'
      : 'inherit'};
  cursor: ${(tr) => (tr.expandable ? 'pointer' : null)};
  font-weight: ${(tr) => (tr.expandable ? 500 : 'inherit')};
  :hover {
    background: ${(tr) => (tr.expandable ? 'var(--bg-default-hover)' : null)};
  }
  > td {
    border: ${(tr) => (tr.parentCollapsed ? 0 : null)};
    padding-bottom: ${(tr) => (tr.parentCollapsed ? 0 : null)};
    padding-top: ${(tr) => (tr.parentCollapsed ? 0 : null)};
    height: ${(tr) => (tr.parentCollapsed ? 0 : 'auto')};
    overflow: hidden;
    transition: height 0.1s ease-in-out, padding 0.1s ease-in-out;
    > div {
      height: ${(tr) => (tr.parentCollapsed ? 0 : 'auto')};
    }
  }
`;

export const TableCell = styled.td<{depth?: number; isImpressions?: boolean}>`
  border-bottom: 0.0625rem solid var(--table-border-row);
  padding: ${(td) =>
    `0.75rem 1rem 0.75rem ${
      td.isImpressions ? 2.275 : td.depth ? td.depth * 2.75 : 1
    }rem`};
  vertical-align: middle;
  white-space: nowrap;
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.85rem;
  font-weight: 500;
  letter-spacing: -0.5px;
  user-select: none;
  white-space: nowrap;
`;

export const SortIcon = styled(Icon).attrs({icon: 'arrow-up'})<{
  sortBy: SortBy;
}>`
  height: 1rem;
  margin-right: var(--spacing-1);
  transform: ${({sortBy}) => (sortBy === 'desc' ? 'rotate(180deg)' : '')};
  transition: transform 0.2s linear;
  width: 1rem;
`;

type ButtonProps = Omit<HTMLProps<HTMLButtonElement>, 'type'>;

export const ExpandButton = ({
  expandAll,
  ...props
}: ButtonProps & {expandAll: boolean}) => (
  <Tooltip
    description={`${expandAll ? 'Collapse' : 'Expand'} all`}
    css={{
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      height: '1.5rem',
      justifyContent: 'center',
      marginRight: '0.5rem',
      width: '1.5rem',
    }}>
    <button
      type='button'
      css={{
        alignItems: 'center',
        color: 'var(--icon-muted)',
        display: 'flex',
        ':hover': {
          color: 'var(--icon-default)',
        },
      }}
      {...props}>
      <Icon
        icon={expandAll ? 'chevron-contract' : 'chevron-expand'}
        css={{
          height: '1rem',
          width: '1rem',
        }}
      />
    </button>
  </Tooltip>
);
