import {FC, useMemo} from 'react';
import dayjs from '@core/lib/dayjs';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {FetchedCampaign} from '@analytics/graphql-api';
import ApprovalsStateFormCell from './ApprovalsStateFormCell';
import {DetailCell, rollPlacementsToString} from './helpers';

type ApprovalsDynamicsTableProps = {
  dynamicApprovals: any[];
  campaign: FetchedCampaign;
  onUpdateState: (_obj: any) => void;
};

const ApprovalsDynamicsTable: FC<ApprovalsDynamicsTableProps> = ({
  dynamicApprovals,
  campaign,
  onUpdateState,
}): JSX.Element => {
  const data = useMemo(
    () =>
      dynamicApprovals.map((item) => {
        const campaignDynamic = campaign.campaignDynamics.find(
          ({id}) => id === item.campaignDynamic.id
        );

        const rollPlacement = rollPlacementsToString(campaignDynamic);

        return {
          ...item,
          lineItem: campaignDynamic?.name,
          campaignDynamic: {
            ...campaignDynamic,
            rollPlacement,
          },
        };
      }),
    [dynamicApprovals, campaign]
  );

  const columns: DataTableColumn[] = useMemo(
    () => [
      {
        title: 'Line Item',
        accessor: 'lineItem',
        type: 'string',
        rule: () => ({width: '30%'}),
      },
      {
        title: 'Detail',
        noSortable: true,
        Cell: ({d}) => <DetailCell d={d.campaignDynamic} />,
      },
      {
        title: 'Updated',
        accessor: 'updatedAt',
        fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
      },
      {
        title: 'State',
        accessor: 'state',
        type: 'string',
        noSortable: true,
        rule: () => ({
          width: '25rem',
        }),
        Cell: ({d}) => (
          <ApprovalsStateFormCell data={d} onUpdate={onUpdateState} />
        ),
      },
    ],
    [onUpdateState]
  );

  return (
    <DataTable
      emptyMessageText='No approvals have been requested.'
      columns={columns}
      data={data}
      rules={() => ({'& tbody': {verticalAlign: 'top'}})}
    />
  );
};

export default ApprovalsDynamicsTable;
