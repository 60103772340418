import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Redirect} from 'react-router-dom';
import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import PixelActionMapForm from '@analytics/components/pixels/PixelActionMapForm';
import UPDATE_ACTION_MAP_MUTATION from '@analytics/graphql-api/_old_mutations/UpdateActionMap';
import ORGANIZATION_KEY_QUERY from '@analytics/graphql-api/_old_queries/OrganizationKeyQuery';
import {ManagePixelsPixelMappingsRouteProps} from '.';

export function PixelUpdateMapping({
  backPath,
  pixel,
  match: {
    params: {id},
  },
}: ManagePixelsPixelMappingsRouteProps & {
  match: {
    params: {
      id: string;
    };
  };
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [complete, setCompleted] = useState(false);

  const [updateActionMap] = useMutation(UPDATE_ACTION_MAP_MUTATION, {
    refetchQueries: [ORGANIZATION_KEY_QUERY],
    awaitRefetchQueries: true,
  });

  const actionMap = pixel.actionMaps.find((map) => map.id === id);

  const onSubmit = async (item) => {
    setError(false);
    setLoading(true);
    try {
      const data = await updateActionMap({
        variables: {
          input: {
            actionMapId: actionMap.id,
            ...item,
          },
        },
      });

      if (data) {
        setCompleted(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const onCancel = () => {
    setError(false);
    setCompleted(true);
  };

  if ((!actionMap || complete) && !error) {
    return <Redirect to={backPath} />;
  }

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={backPath}>Update Event Mapping</CardHeader>
      <Card>
        {error && (
          <ToastMessage
            rules={() => ({margin: '0 0 1.5rem'})}
            alertType='error'>
            Network Error. Please Try again.
          </ToastMessage>
        )}
        <PixelActionMapForm
          onCancel={onCancel}
          onSubmit={onSubmit}
          loading={loading}
          updateItem={actionMap}
        />
      </Card>
    </ResponsiveContent>
  );
}
