import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {loginViaSpotify} from '@core/components/SignInWithSpotify/auth';
import SpotifyBanner from '@analytics/components/onboard/SpotifyBanner';
import {useUserInfoQuery} from '@analytics/graphql-api';
import {
  ContentContainer,
  PageContainer,
} from '@analytics/screens/account/AccountLayout';
import ConfirmationDialog from '@analytics/screens/account/ConfirmationDialog';
import {LoadingImage} from '@analytics/screens/account/LoadingImage';

const RedirectTo = () => {
  const location = useLocation();
  const {
    data: userData,
    loading,
    error,
  } = useUserInfoQuery({
    fetchPolicy: 'network-only',
  });

  const searchParams = new URLSearchParams(window.location.search);

  const stateParam = searchParams.get('state');
  const history = useHistory();

  useEffect(() => {
    if (!loading) {
      const searchParams = new URLSearchParams(location.search);
      const toParam = searchParams.get('url');
      if (stateParam) {
        const decodedState = decodeURIComponent(stateParam);
        // If state is present, we know that silent login failed, so we should retry
        loginViaSpotify({to: decodedState, alreadyRetried: true, history});
      } else {
        if (toParam) {
          const decodedTo = decodeURIComponent(toParam);
          loginViaSpotify({to: decodedTo, history});
        } else {
          loginViaSpotify();
        }
      }
    }
  }, [location.search, userData, loading]);

  return (
    <PageContainer>
      <SpotifyBanner advertising />
      <ContentContainer>
        <ConfirmationDialog
          imageSrc={LoadingImage}
          boxTitle='You’re being redirected'
        />
      </ContentContainer>
    </PageContainer>
  );
};

export default RedirectTo;
