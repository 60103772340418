import {useEffect, useLayoutEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import Button from '../Button';
import {Submit} from '../FormElements';
import {Stack} from '../Layout';
import {Overlay, useOverlay} from '../Overlay';
import {Tooltip} from '../Tooltip';

export interface SaveBarProps {
  changesCount: number;
  changesText?: string;
  disabled?: boolean;
  disabledText?: string;
  loading?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
}

export const SaveBar = ({
  changesCount,
  changesText,
  disabled,
  disabledText,
  loading,
  onCancel,
  onSave,
}: SaveBarProps) => {
  const [opened, toggle] = useOverlay(false);
  const [animateIn, setAnimateIn] = useState(false);

  useEffect(() => {
    toggle(changesCount > 0);
  }, [changesCount, toggle]);

  useLayoutEffect(() => {
    setAnimateIn(opened);
  }, [opened]);

  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      closeOnEscKey={false}
      closeOnOutsideClick={false}
      withBackdrop={loading}
      fixed
      css={`
        background: none;
        border-radius: 0;
        border: 0;
        bottom: 0;
        left: var(--app-left-nav-width, 1.5rem);
        overflow: hidden;
        pointer-events: none;
        position: fixed;
        right: 1.5rem;
      `}>
      <CSSTransition in={animateIn} timeout={100} classNames='slide-up'>
        <div
          css={`
            align-items: center;
            background: var(--inverse);
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            color: var(--text-on-inverse);
            display: flex;
            font-size: 0.9375rem;
            justify-content: space-between;
            padding: 0.75rem 1.25rem 1.25rem;
            pointer-events: all;
            transition: transform 0.4s cubic-bezier(0.25, 1, 0.5, 1);
            white-space: nowrap;
            will-change: transform;
            &.slide-up-enter {
              transform: translateY(4.375rem);
            }
            &.slide-up-enter-active {
              transform: translateY(0);
            }
          `}>
          <div>{changesText ? changesText : `${{changesCount}} changes`}</div>
          <Stack direction='row' gap={4}>
            <Button
              onClick={onCancel}
              disabled={loading}
              variant='outlined'
              css={`
                color: var(--text-inverse);
                :hover {
                  color: var(--text-inverse);
                }
              `}>
              Discard
            </Button>
            <Tooltip
              active={disabled && !!disabledText}
              description={disabledText}
              overlayProps={{
                horizontalAlign: 'left',
                verticalAlign: 'top',
                verticalOffset: 4,
              }}>
              <Submit
                onClick={onSave}
                loading={loading}
                disabled={disabled}
                color='secondary'
                value='Save Changes'
              />
            </Tooltip>
          </Stack>
        </div>
      </CSSTransition>
    </Overlay>
  );
};
