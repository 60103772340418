import {
  ButtonSecondary,
  ButtonTertiary,
  Table,
  TableCell,
  TableContainer,
  TableHeaderCell,
  TableRow,
  Tag,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';
import {CloudsenseIcon, ExclamationIcon, SpotifyIcon} from './SalesOrderIcons';
import {SalesOrderReviewPagination} from './SalesOrderReviewPagination';
import {UnmappedSalesOrder} from './mapRowToData';

const TableHeaderContainer = styled(TableHeaderCell)`
  padding-right: 0px;
`;

const NumericCell = styled(TableCell)`
  padding-right: 30px;
`;

const ButtonContainer = styled(TableCell)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  text-wrap: nowrap;
`;

const MappingConfidence = ({level}) => {
  const confidenceLevels = {
    H: {
      text: 'High',
      colorSet: 'positive',
    },
    L: {
      text: 'Low',
      colorSet: 'warning',
    },
    M: {
      text: 'Medium',
      colorSet: 'warning',
    },
  };

  // Default values when level is not found or empty
  const defaultValues = {
    text: 'No match',
    colorSet: 'negative',
  };

  const displayValues = confidenceLevels[level] || defaultValues;

  return <Tag colorSet={displayValues.colorSet}>{displayValues.text}</Tag>;
};

interface SalesOrderReviewTableProps {
  onApprove: (id: string) => void;
  onEdit: (id: string) => void;
  onDefer: (id: string) => void;
  unmappedSpotifyOrders?: UnmappedSalesOrder[];
  onPaginationChange;
  pagination;
  total;
}
export const SalesOrderReviewTable = ({
  unmappedSpotifyOrders,
  onEdit,
  onApprove,
  onDefer,
  onPaginationChange,
  pagination,
  total,
}: SalesOrderReviewTableProps) => {
  const handleSelect = (selectedPage: number): void => {
    onPaginationChange({...pagination, offset: selectedPage});
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    onPaginationChange({...pagination, limit: newPageSize});
  };
  return (
    <>
      <TableContainer>
        <Table>
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <TableRow style={{whiteSpace: 'nowrap'}} select>
              <TableHeaderCell align='center' />
              <TableHeaderCell>
                Spotify Order ID
                <CloudsenseIcon />
              </TableHeaderCell>
              <TableHeaderCell>
                Organization name <CloudsenseIcon />
              </TableHeaderCell>
              <TableHeaderCell>
                SO total spend <CloudsenseIcon />
              </TableHeaderCell>
              <TableHeaderCell>
                Start date <CloudsenseIcon />
              </TableHeaderCell>
              <TableHeaderCell>
                End date
                <CloudsenseIcon />
              </TableHeaderCell>
              <TableHeaderCell>
                Website
                <CloudsenseIcon />
              </TableHeaderCell>
              <TableHeaderCell>
                Organization name
                <SpotifyIcon />
              </TableHeaderCell>
              <TableHeaderCell>
                Pixel active? <SpotifyIcon />
              </TableHeaderCell>
              <TableHeaderCell>
                Pixel Domain <SpotifyIcon />
              </TableHeaderCell>
              <TableHeaderCell>
                Organization link <SpotifyIcon />
              </TableHeaderCell>
              <TableHeaderContainer>
                Count of campaigns <SpotifyIcon />
              </TableHeaderContainer>
              <TableHeaderCell>Mapping confidence</TableHeaderCell>
              <TableHeaderCell>&nbsp;</TableHeaderCell>
            </TableRow>
          </thead>
          <tbody>
            {unmappedSpotifyOrders?.map(
              ({
                id,
                cloudsenseAdvertiserName,
                cost,
                startAt,
                endAt,
                advertiserWebsite,
                organizationName,
                organizationSlug,
                hasActivePixel,
                confidence,
                campaignCount,
                domain,
              }) => (
                <TableRow key={id}>
                  <TableCell numerical align='center'>
                    <ExclamationIcon />
                  </TableCell>
                  <TableCell>{id}</TableCell>
                  <TableCell>{cloudsenseAdvertiserName}</TableCell>
                  <NumericCell numerical align='right'>
                    {cost}
                  </NumericCell>
                  <TableCell style={{whiteSpace: 'nowrap'}}>
                    {startAt}
                  </TableCell>
                  <TableCell style={{whiteSpace: 'nowrap'}}>{endAt}</TableCell>
                  <TableCell>{advertiserWebsite}</TableCell>
                  <TableCell>{organizationName}</TableCell>
                  <TableCell>{hasActivePixel}</TableCell>
                  <TableCell>{domain}</TableCell>
                  <TableCell>{organizationSlug}</TableCell>
                  <NumericCell numerical align='right'>
                    {campaignCount}
                  </NumericCell>
                  <TableCell>
                    <MappingConfidence level={confidence} />
                  </TableCell>
                  {(confidence === 'L' || confidence === 'N') && (
                    <ButtonContainer>
                      <ButtonTertiary size='small' onClick={() => onEdit(id)}>
                        Edit
                      </ButtonTertiary>
                      <ButtonSecondary
                        size='small'
                        onClick={() => onDefer(id)}
                        style={{whiteSpace: 'nowrap'}}>
                        Submit a ticket
                      </ButtonSecondary>
                    </ButtonContainer>
                  )}
                  {confidence !== 'L' && confidence !== 'N' && (
                    <ButtonContainer>
                      <ButtonTertiary size='small' onClick={() => onEdit(id)}>
                        Edit
                      </ButtonTertiary>
                      <ButtonSecondary
                        size='small'
                        onClick={() => onApprove(id)}>
                        Approve
                      </ButtonSecondary>
                    </ButtonContainer>
                  )}
                </TableRow>
              )
            )}
          </tbody>
        </Table>
      </TableContainer>
      <SalesOrderReviewPagination
        offset={pagination.offset}
        limit={Number(pagination.limit)}
        handleSelect={handleSelect}
        handlePageSizeChange={handlePageSizeChange}
        total={total ?? 0}
      />
    </>
  );
};
