import {useQuery} from '@apollo/client';
import {t} from '@core/texts';
import {ToastMessage} from '@core/ui/Alert';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import {reviewersURL} from '@analytics/components/nav/ResearchNav';
import EpisodeMinutesByDay from '@analytics/components/research/EpisodeMinutesByDay';
import ReviewersTable from '@analytics/components/research/ReviewersTable';
import ORGANIZATION_RESEARCH_REVIWERS_QUERY from '@analytics/graphql-api/_old_queries/OrganizationResearchReviewersQuery';

export default function Reviewers({user, organization}) {
  const {data, loading, error} = useQuery(
    ORGANIZATION_RESEARCH_REVIWERS_QUERY,
    {
      variables: {organizationId: organization.id},
    }
  );

  if (loading) return <Loading centered />;

  if (error) {
    return <ToastMessage alertType='error'>{t('errors.network')}</ToastMessage>;
  }

  const {reviewers} = data.me.organization.research;

  return (
    <>
      <EpisodeMinutesByDay organization={organization} />
      <CardHeader
        rightContent={
          user.isAdmin && (
            <Button
              to={`${reviewersURL(organization)}/create`}
              color='primary'
              buttonSize='sm'
              iconLeading='plus'>
              Add Reviewer
            </Button>
          )
        }>
        Reviewers
      </CardHeader>
      <Card>
        <ReviewersTable organization={organization} reviewers={reviewers} />
      </Card>
    </>
  );
}
