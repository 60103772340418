import {forwardRef} from 'react';
import styled from 'styled-components';
import {DataTableColumn} from '@core/ui/DataTable';
import AudienceMultipleSelectFilter from './AudienceMultipleSelectFilter';
import AudienceCell, {EmptyCell, PodcastCell} from './AudienceTableCell';

const dropdownItems = {
  Age: {
    experian: [
      {title: '19-24', value: '19-24'},
      {title: '25-30', value: '25-30'},
      {title: '31-35', value: '31-35'},
      {title: '36-45', value: '36-45'},
      {title: '46-50', value: '46-50'},
      {title: '51-65', value: '51-65'},
      {title: '66-75', value: '66-75'},
      {title: '76+', value: '76+'},
    ],
    nielsen: [
      {title: '18-24', value: '18-24'},
      {title: '25-34', value: '25-34'},
      {title: '35-44', value: '35-44'},
      {title: '45-54', value: '45-54'},
      {title: '55-64', value: '55-64'},
      {title: '65+', value: '65+'},
    ],
  },
  Income: {
    experian: [
      {title: '<75K', value: '<75k'},
      {title: '75K-100K', value: '75k-100k'},
      {title: '100K-150K', value: '100k-150k'},
      {title: '150K+', value: '150k+'},
    ],
    nielsen: [
      {title: '<75K', value: '<75k'},
      {title: '75K-100K', value: '75k-100k'},
      {title: '100K-150K', value: '100k-150k'},
      {title: '150K+', value: '150k+'},
    ],
  },
  Education: {
    experian: [
      {title: 'Less than high school', value: 'less than high school'},
      {title: 'High school diploma', value: 'high school diploma'},
      {title: 'Some college', value: 'some college'},
      {title: "Bachelor's degree", value: "bachelor's degree"},
      {title: "Graduate's degree", value: "graduate's degree"},
    ],
    nielsen: [
      {title: 'High School', value: 'high school'},
      {title: 'Vocational', value: 'vocational'},
      {title: 'Some college', value: 'some college'},
      {title: 'College Grad', value: 'college grad'},
      {title: 'Grad Degree', value: 'grad degree'},
    ],
  },
};

const Label = styled.div`
  font-size: var(--font-size-body-2);
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--spacing-3);
  padding: 0 0.125rem;
`;

export default function getColumns(
  type: 'experian' | 'nielsen'
): DataTableColumn[] {
  return [
    {
      title: 'Podcast',
      accessor: 'title',
      type: 'string',
      Cell: ({
        d: {
          id,
          title,
          image,
          organization,
          // Charts
          Age,
          Education,
          Income,
        },
      }) => {
        return (
          <>
            <PodcastCell
              podcast={title}
              image={image}
              id={id}
              organization={organization}
            />
            <div
              css={{
                alignItems: 'start',
                border: '1px solid var(--border-subtle)',
                borderRadius: '.5rem',
                display: 'grid',
                gridGap: '2rem',
                gridTemplateColumns: 'repeat(3, 1fr)',
                marginBottom: '0.75rem',
                padding: '1rem',
              }}>
              <div>
                <Label>Age</Label>
                {Age?.length > 0 ? (
                  <AudienceCell data={Age} topic='Age' />
                ) : (
                  <EmptyCell />
                )}
              </div>
              <div>
                <Label>Income</Label>
                {Income?.length > 0 ? (
                  <AudienceCell data={Income} topic='Income' />
                ) : (
                  <EmptyCell />
                )}
              </div>
              <div>
                <Label>Education</Label>
                {Education?.length > 0 ? (
                  <AudienceCell data={Education} topic='Education' />
                ) : (
                  <EmptyCell />
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: 'Age',
      description: 'Head of household',
      type: 'number',
      accessor: 'AgeMax',
      hidden: true,
      Filter: forwardRef(({...props}, ref) => (
        <AudienceMultipleSelectFilter
          ref={ref}
          searchable={false}
          accessor='AgeIdx'
          items={dropdownItems.Age[type]}
          {...props}
        />
      )),
    },
    {
      title: 'Income',
      description: 'Head of household',
      type: 'number',
      accessor: 'IncomeMax',
      hidden: true,
      Filter: forwardRef(({...props}, ref) => (
        <AudienceMultipleSelectFilter
          ref={ref}
          searchable={false}
          accessor='IncomeIdx'
          items={dropdownItems.Income[type]}
          {...props}
        />
      )),
    },
    {
      title: 'Education',
      description: 'Head of household',
      type: 'number',
      accessor: 'EducationMax',
      hidden: true,
      Filter: forwardRef(({...props}, ref) => (
        <AudienceMultipleSelectFilter
          ref={ref}
          searchable={false}
          accessor='EducationIdx'
          items={dropdownItems.Education[type]}
          {...props}
        />
      )),
    },
  ];
}
