import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import MembershipForm from '@analytics/components/members/MembershipForm';
import ADD_MEMBER_MUTATION from '@analytics/graphql-api/_old_mutations/AddMember';
import MEMBERS_QUERY from '@analytics/graphql-api/_old_queries/MembersQuery';
import {ManageMembersRouteProps} from '.';

export function MemberAdd({organization}: ManageMembersRouteProps) {
  const [addMember] = useMutation(ADD_MEMBER_MUTATION, {
    refetchQueries: [MEMBERS_QUERY],
  });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const onSubmit = async ({firstName, lastName, email, role}) => {
    if (!email || !firstName || !lastName) {
      return;
    }

    setErrors([]);
    setLoading(true);
    try {
      await addMember({
        variables: {
          input: {
            firstName,
            lastName,
            role,
            email,
            organizationId: organization.id,
            host: window.location.origin,
          },
        },
      });
      setLoading(false);
      history.push(createOrgPath(organization, `/manage/members`));
    } catch (error) {
      setLoading(false);
      setErrors([error]);
    }
  };

  return (
    <ResponsiveContent containerSize='medium'>
      <MembershipForm
        onSubmit={onSubmit}
        loading={loading}
        errors={errors}
        organization={organization}
      />
    </ResponsiveContent>
  );
}
