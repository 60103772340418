import React from 'react';
import {Button} from '@core/ui/Button';
import GettingStartedSteps from './GettingStartedSteps';

const PodcasterGettingStarted = ({organization, children}) => {
  const steps = [
    {
      label: 'Add a Podcast',
      completed:
        organization.podcastsCount > 0 || organization.campaignsCount > 0,
      renderContent: () => {
        return (
          <>
            <h1>Add a podcast to get started</h1>
            <p>
              We base the rest of your Ad Analytics experience on the podcasts
              you add. From the ads we pull to the campaigns you run will be
              based on these podcasts.
            </p>
            <Button
              color='secondary'
              css={`
                margin-top: 1rem;
              `}
              to={`/p/${organization.slug}/manage/podcasts`}>
              Add Podcast
            </Button>
          </>
        );
      },
    },
  ];

  return <GettingStartedSteps steps={steps}>{children}</GettingStartedSteps>;
};

export default PodcasterGettingStarted;
