import {useCallback} from 'react';
import {createOrgPath} from '@core/lib/organizations';
import {DataTableServer, DataTableServerProps} from '@core/ui/DataTable';
import useSettings from '@analytics/components/settings/useSettings';
import {CampaignsDocument, FetchedOrganization} from '@analytics/graphql-api';
import {useCampaignsTableColumns} from './useCampaignsTableColumns';

export function CampaignsTable({
  emptyMessageText = 'No campaigns found',
  organization,
  ...props
}: Omit<
  DataTableServerProps,
  'columns' | 'data' | 'searchKeys' | 'toData' | 'toVariables' | 'query'
> & {
  organization: FetchedOrganization;
}) {
  const [settings] = useSettings();

  const columns = useCampaignsTableColumns({organization, settings});

  return (
    <DataTableServer
      columns={columns}
      emptyMessageText={emptyMessageText}
      fetchPolicy='cache-and-network'
      onClickRowPath={(campaign) =>
        createOrgPath(organization, `/campaigns/${campaign.slug}`)
      }
      orderBy='-downloadTotal'
      searchPlaceholder='Search campaign'
      searchKeys={['name']}
      toData={(data) => ({
        data: data?.me?.organization?.campaigns?.results ?? [],
        count: data?.me?.organization?.campaigns?.resultsCount ?? 0,
      })}
      toVariables={useCallback(
        ({filters, rowStart, rowsPerPage, sortBy, query}) => ({
          organizationId: organization.id,
          query: {
            limit: rowsPerPage,
            offset: rowStart,
            name: query,
            sort: sortBy ? [sortBy] : undefined,
            // filters (tied to column accessor):
            after: filters?.createdAt?.[0],
            before: filters?.createdAt?.[1],
            states: filters?.state ?? [],
            tags: filters?.name ?? [],
            types: filters?.kind ?? [],
            impressionsMin: filters?.downloadTotal?.[0],
            impressionsMax: filters?.downloadTotal?.[1],
          },
        }),
        [organization.id]
      )}
      query={CampaignsDocument}
      {...props}
    />
  );
}
