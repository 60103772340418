import {Button} from '@core/ui/Button';
import {Stack} from '@core/ui/Layout';
import {CampaignDraftProps} from '..';
import {Row, Title} from '../_shared';
import {parsePrefixStatusDisplay} from './_helpers';
import {DisplayStatus} from './_shared';

export function CampaignPrefixDetails({
  campaign,
  organization,
}: CampaignDraftProps) {
  if (!(campaign.campaignPodcasts ?? []).length) return null;

  const {success, statusText, statusDetails} = parsePrefixStatusDisplay({
    campaign,
    organization,
  });

  return (
    <Row>
      <Title
        rightContent={
          <Button
            buttonSize='sm'
            href='https://help.adanalytics.spotify.com/install-the-spotify-ad-analytics-podcast-prefix'
            target='_blank'
            variant='outlined'>
            Learn more
          </Button>
        }>
        Prefixes Installed
      </Title>
      <Stack>
        <DisplayStatus
          success={success}
          statusText={statusText}
          statusDetails={statusDetails}
        />
      </Stack>
    </Row>
  );
}
