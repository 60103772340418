import Steps from '@core/ui/Steps';
import {BRAND_STEPS, PUB_STEPS} from './_helpers';
import {BrandStep, PubStep} from './_types';

type Props =
  | {
      active: BrandStep;
      isBrand: true;
    }
  | {
      active: PubStep;
      isBrand?: false;
    };

export function CampaignCreateSteps({active, isBrand = false}: Props) {
  const STEPS = isBrand ? BRAND_STEPS : PUB_STEPS;
  return (
    <Steps
      active={active}
      steps={STEPS}
      css={{margin: '2rem 1.5rem 1.75rem'}}
    />
  );
}
