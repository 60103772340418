import styled from 'styled-components';
import {ButtonIcon} from '@core/ui/Button';

export function ListSortIcon({searchValue}: {searchValue: string}) {
  return (
    <StyledSortIcon
      searchValue={!!searchValue}
      data-sortable-button
      icon='order'
    />
  );
}

const StyledSortIcon = styled(ButtonIcon)<{searchValue: boolean}>`
  color: var(--icon-muted);
  opacity: ${(p) => (p.searchValue ? 0.5 : null)};
  padding: 0;
  pointer-events: ${(p) => (p.searchValue ? 'none' : null)};
  :focus svg {
    outline: 2px solid var(--blue-80);
  }
`;
