import {gql} from '@apollo/client';
import CampaignLinkFragment from './fragments/CampaignLinkFragment';

export default gql`
  query getCampaignLink($organizationId: ID!, $campaignId: ID!, $id: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $campaignId) {
          id
          campaignLink(id: $id) {
            ...CampaignLink

            target {
              id
              feed {
                id
                href
              }
            }

            attribution {
              marketing {
                linkHourly {
                  campaignLinkId
                  hour
                  action
                  total
                }

                linkClicks {
                  campaignLinkId
                  type
                  category
                  total
                }

                linkAttributionTargetEpisodes {
                  count
                  nodes {
                    id
                    downloads
                    reach
                    episode {
                      id
                      title
                      description(words: 50)
                      published
                      image {
                        id
                        href
                        path
                      }
                      enclosure {
                        id
                        length
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CampaignLinkFragment}
`;
