import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {
  DocumentNode,
  WatchQueryFetchPolicy,
  useLazyQuery,
} from '@apollo/client';
import {truncate} from '@core/lib/words';
import {TRules, css} from '@core/style';
import {Icon} from '../Icon';
import {LoadingSpinner} from '../Loading';
import {Overlay, OverlayProps} from '../Overlay';

export interface IMultipleDataSelectorProps {
  /**
   * If true focus the input on first render
   */
  autofocus?: boolean;
  /**
   * Default name value
   */
  defaultValues?: any[];
  /**
   * Cache fetch Policy
   */
  fetchPolicy?: WatchQueryFetchPolicy;
  /**
   * Search query
   */
  dataQuery: DocumentNode;
  /**
   * Function that parses de response and return the select items.
   */
  fromResponse?: (_response: any) => any;
  /**
   * Overlay horizontal align
   */
  horizontalAlign?: string;
  /**
   * Function called when the user selects an item.
   */
  onChange?: (_items: any) => void;
  overlayProps?: OverlayProps;
  /**
   * Search input placeholder
   */
  placeholder?: string;
  /**
   * Property name for the option label.
   */
  propertyForName?: string;
  /**
   * Variables for the graph query
   */
  queryVariables?: {[key: string]: any};
  /**
   * Component render function for select items.
   */
  renderItem?: (_item: any) => JSX.Element;
  rules?: TRules;
}

const MultipleDataSelector: FC<IMultipleDataSelectorProps> = ({
  autofocus = false,
  dataQuery,
  defaultValues = [],
  fetchPolicy = 'network-only',
  fromResponse,
  onChange,
  overlayProps = {
    verticalAlign: 'bottom',
    verticalOffset: 2,
    horizontalAlign: 'right',
  },
  placeholder = 'Search',
  propertyForName = 'name',
  queryVariables = {},
  renderItem,
  rules,
}): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const [opened, setOpened] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [results, setResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState(defaultValues);
  const [executeQuery, {loading}] = useLazyQuery(dataQuery, {
    variables: {
      query: inputValue,
      ...queryVariables,
    },
    fetchPolicy,
    onCompleted: (data) => {
      setResults(
        typeof fromResponse === 'function' ? fromResponse(data) : data
      );
    },
  });

  const onInputChange = useCallback(
    (evt: any) => {
      const value = evt.target.value.trim();
      const hasValue = value.length > 0;

      setInputValue(value);
      setOpened(hasValue);

      if (hasValue) {
        executeQuery();
      }
    },
    [executeQuery]
  );

  const onContainerClick = () => {
    requestAnimationFrame(() => {
      inputRef.current?.focus();
    });
  };

  const onAddItemClick = (item: any) => {
    const items = selectedItems.slice(0);
    items.push(item);
    setSelectedItems(items);
    setOpened(false);
    requestAnimationFrame(() => {
      inputRef.current.value = '';
      inputRef.current?.focus();
    });
    if (typeof onChange === 'function') {
      onChange(items);
    }
  };

  const onRemoveItemClick = (item: any) => {
    const idx = selectedItems.indexOf(item);
    selectedItems.splice(idx, 1);
    const items = selectedItems.slice(0);
    setSelectedItems(items);
    if (typeof onChange === 'function') {
      onChange(items);
    }
  };

  useEffect(() => {
    if (autofocus) {
      requestAnimationFrame(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      });
    }
  }, [autofocus]);

  return (
    <>
      <div
        aria-hidden
        ref={containerRef}
        onClick={onContainerClick}
        {...css([
          () => ({
            border: '1px solid var(--border-default)',
            borderRadius: '0.375rem',
            minHeight: '1.5rem',
            padding: '0.375rem',
          }),
          rules,
        ])}>
        {selectedItems.map((item) => (
          <div
            key={item[propertyForName]}
            css={{
              alignItems: 'center',
              border: '0.0625rem solid var(--blue-80)',
              borderRadius: '5rem',
              color: 'var(--blue)',
              display: 'inline-flex',
              lineHeight: 1.3,
              margin: '0.125rem',
              padding: '0.25rem 0.5rem 0.25rem 0.625rem',
              pointerEvents: 'none',
              userSelect: 'none',
            }}>
            {truncate(item[propertyForName], 25)}
            <Icon
              icon='close'
              onClick={() => onRemoveItemClick(item)}
              rules={() => ({
                borderRadius: '4rem',
                color: 'var(--blue)',
                cursor: 'pointer',
                flexShrink: 0,
                height: '1.125rem',
                marginLeft: '0.25rem',
                padding: '0.125rem',
                pointerEvents: 'all',
                width: '1.125rem',
                ':hover': {
                  background: 'var(--blue)',
                  color: 'var(--white)',
                },
              })}
            />
          </div>
        ))}
        <input
          ref={inputRef}
          placeholder={selectedItems.length > 0 ? '' : placeholder}
          onInput={onInputChange}
          css={{
            border: 0,
            boxShadow: 'none',
            display: 'inline-block',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 1.3,
            marginLeft: '0.25rem',
            minWidth: selectedItems.length > 0 ? '0.625rem' : '100%',
            outline: 0,
            padding: '0.25rem 0',
          }}
        />
      </div>
      {opened ? (
        <Overlay
          domRef={overlayRef}
          opened={opened}
          toggle={setOpened}
          positionTarget={containerRef.current}
          beforeCloseOnOutsideClick={() => {
            inputRef.current.value = '';
            setInputValue('');
          }}
          withShadow
          withBackdrop
          transparentBackdrop
          css={`
            padding: 0.375rem;
            min-width: ${containerRef.current
              ? containerRef.current.getBoundingClientRect().width
              : 0}px;
          `}
          {...overlayProps}>
          {loading ? (
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                padding: '0.25rem 0',
              }}>
              <LoadingSpinner size='small' />
            </div>
          ) : results.length > 0 ? (
            <div
              css={{
                height: '100%',
                maxHeight: '25rem',
                minHeight: 0,
                overflowY: 'auto',
              }}>
              {results
                .filter((item) => !selectedItems.find(({id}) => id === item.id))
                .map((item) => (
                  <div
                    aria-hidden
                    key={item[propertyForName]}
                    onClick={() => onAddItemClick(item)}
                    css={{
                      borderRadius: '0.375rem',
                      cursor: 'pointer',
                      padding: '.5rem 1rem',
                      ':hover': {
                        background: 'var(--bg-muted)',
                      },
                    }}>
                    {typeof renderItem === 'function'
                      ? renderItem(item)
                      : item[propertyForName]}
                  </div>
                ))}
            </div>
          ) : (
            <div
              css={{
                color: 'var(--text-muted)',
                padding: '0.625rem 0',
                textAlign: 'center',
              }}>
              No results found.
            </div>
          )}
        </Overlay>
      ) : null}
    </>
  );
};

export default MultipleDataSelector;
