const getApprovalsByState = ({
  campaign,
  dynamicApprovals,
  podcastApprovals,
  streamingApprovals,
}) => {
  let rejected = [];
  let requested = [];
  const campaignAdsIds = [
    ...campaign.campaignPodcasts.map(({id}) => id),
    ...campaign.campaignDynamics.map(({id}) => id),
    ...campaign.campaignStreamings.map(({id}) => id),
  ];

  if (dynamicApprovals && podcastApprovals && streamingApprovals) {
    const approvals = Object.values(
      dynamicApprovals
        .concat(podcastApprovals)
        .concat(streamingApprovals)
        .reduce((acc, obj) => {
          const ad =
            obj.campaignPodcast || obj.campaignDynamic || obj.campaignStreaming;

          if (campaignAdsIds.find((id) => id === ad.id)) {
            if (
              acc[ad.id] &&
              new Date(acc[ad.id].updatedAt).getTime() >
                new Date(obj.updatedAt).getTime()
            ) {
              // Keep the newest approval
            } else {
              acc[ad.id] = obj;
            }
          }
          return acc;
        }, {})
    );

    rejected = approvals.filter(({state}) => state === 'REJECTED');
    requested = approvals.filter(({state}) => state === 'REQUESTED');
  }

  return {
    rejected,
    requested,
  };
};

export {getApprovalsByState};
