import React from 'react';
import {createStyledElement} from '@core/style';
import {Element} from '@core/style';
import {BLUE} from '@core/style';

const rangeWrapperRule = () => ({
  display: 'block',
  position: 'relative',
  width: '100%',
  height: '20px',
  padding: '0 10px',
});

const rangeInnerRule = () => ({
  display: 'block',
  position: 'relative',
  height: '20px',
});

const rangeBackgroundRule = ({active}) => ({
  display: 'block',
  height: '4px',
  background: active ? BLUE : '#ddd',
  position: 'absolute',
  top: '50%',
  marginTop: '-2px',
  right: '0',
});

const Circle = createStyledElement('div', ({active, left}) => ({
  display: 'inline-block',
  borderRadius: '100%',
  border: '1px solid var(--blue)',
  background: 'var(--blue)',
  position: 'absolute',
  cursor: 'cursor',
  transition: 'width 0.15s, height 0.15s, margin-left 0.15s, top 0.15s',
  width: `${active ? '20px' : '12px'}`,
  height: `${active ? '20px' : '12px'}`,
  marginLeft: `${active ? '-10px' : '-6px'}`,
  top: `${active ? '0' : '4px'}`,
  left: `${left ? `${left}%` : undefined}`,
}));

class Range extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      start: typeof props.start === 'number' ? props.start : 0,
      end: typeof props.end === 'number' ? props.end : 100,
      moving: null,
    };
  }

  onMouseDown = (handle) => {
    this.setState({
      moving: handle,
    });

    this._mouseup = (evt) => {
      document.removeEventListener('mouseup', this._mouseup);
      document.removeEventListener('mousemove', this._mousemove);
      this.setState({
        moving: null,
      });
    };

    this._mousemove = (evt) => {
      const rect = this.inner.getBoundingClientRect();

      let diff = ((evt.pageX - rect.left) / rect.width) * 100;

      if (diff < 0) {
        diff = 0;
      }
      if (diff > 100) {
        diff = 100;
      }

      if (handle === 'start' && diff > this.state.end) {
        return;
      }

      if (handle === 'end' && diff < this.state.start) {
        return;
      }

      const move = {};
      move[handle] = diff;

      this.setState(move);
    };

    document.addEventListener('mouseup', this._mouseup);
    document.addEventListener('mousemove', this._mousemove);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.start != prevState.start ||
      this.state.end != prevState.end
    ) {
      if (this.props.emitRange) {
        this.props.emitRange(this.state.start, this.state.end);
      }
    }
  }

  render() {
    const {start, end, moving} = this.state;
    const {single} = this.props;
    const width = end - start;

    return (
      <Element rules={rangeWrapperRule}>
        <Element
          rules={rangeInnerRule}
          domRef={(inner) => {
            this.inner = inner;
          }}>
          <Element
            rules={rangeBackgroundRule}
            style-start={0}
            style-end={100}
            style={{
              width: `100%`,
              left: `0%`,
            }}
          />
          <Element
            rules={rangeBackgroundRule}
            style-start={start}
            style-end={end}
            style-active
            style={{
              width: `${width}%`,
              left: `${start}%`,
            }}
          />

          {single ? (
            ''
          ) : (
            <Circle
              style-active={moving === 'start'}
              style-left={start}
              onMouseDown={() => {
                this.onMouseDown('start');
              }}
            />
          )}
          <Circle
            style-active={moving === 'end'}
            style-left={end}
            onMouseDown={() => {
              this.onMouseDown('end');
            }}
          />
        </Element>
      </Element>
    );
  }
}

export default Range;
