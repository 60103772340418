import {css} from 'styled-components';
import {comma, financial} from '@core/lib/filters';
import {ApprovalEditionItem} from './ApprovalsConfirmReport';

export type ApprovalsStateName = 'APPROVED' | 'REQUESTED' | 'REJECTED';

export const COMMON_REJECT_REASONS = [
  {
    value: 'opt_out',
    title: 'Out Put',
    description: 'Show has opted out of using Ad Analytics',
  },
  {
    value: 'platform',
    title: 'Platform',
    description: `Show is hosted on a platform Ad Analytics doesn't support`,
  },
  {
    value: 'scope',
    title: 'Scope',
    description: 'Show is not in scope for campaign',
  },
  {
    value: 'other',
    title: 'Other',
  },
];

export const DYNAMIC_REJECT_REASONS = [
  ...COMMON_REJECT_REASONS.slice(0, 3),
  {
    value: 'flight',
    title: 'Flight',
    description: 'Flight is incorrect',
  },
  {
    value: 'placement',
    title: 'Placement',
    description: 'Placement or placement duration is incorrect',
  },
  {
    value: 'match',
    title: 'Match',
    description: 'Creative ad copy count does not match IO',
  },
  ...COMMON_REJECT_REASONS.slice(3),
];

export const rollPlacements = [
  {title: 'Pre', value: 'isPreRoll'},
  {title: 'Mid', value: 'isMidRoll'},
  {title: 'Post', value: 'isPostRoll'},
];

export const rollPlacementsToString = (item: any) =>
  rollPlacements
    .filter(({value}) => !!item[value])
    .map(({title}) => title)
    .join(', ');

export const detailsProperties = [
  {accessor: 'goal', title: 'Impressions Goal', fmt: comma},
  {accessor: 'cost', title: 'Cost', fmt: financial},
  {accessor: 'duration', title: 'Duration', fmt: comma},
  {accessor: 'rollPlacement', title: 'Roll placement'},
  {accessor: 'discountCode', title: 'Discount Code'},
  {accessor: 'effectiveUrl', title: 'Effective URL'},
];

export const DetailCell = ({d}: any): JSX.Element => {
  return (
    <div css={{whiteSpace: 'nowrap'}}>
      {detailsProperties.map((col) => (
        <div
          key={col.accessor}
          css={{display: 'grid', gridTemplateColumns: '8rem 1fr'}}>
          <div css={{color: 'var(--text-muted)'}}>{col.title}: </div>
          <div>{col.fmt ? col.fmt(d[col.accessor]) : d[col.accessor]}</div>
        </div>
      ))}
    </div>
  );
};

export const allItemsApproved = (
  edition: ApprovalEditionItem[],
  requested: any[]
) => {
  return (
    !edition.find(({state}) => state.state.toLowerCase() !== 'approved') &&
    edition.length === requested.length
  );
};

export const titleRule = css({
  fontSize: '1rem',
  fontWeight: 700,
  margin: '2rem 1.5rem 1rem',
});
