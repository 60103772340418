import {Redirect, Switch} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {useAdvisorOnboardPublisherRoutes} from '@analytics/lib/routes/useAdvisorRoutes';
import {AdvisorRouteProps} from '../../_routes';
import {AdvisorOnboardPublisher} from './AdvisorOnboardPublisher';
import {AdvisorOnboardPublisherCapabilities} from './AdvisorOnboardPublisherCapabilities';
import {AdvisorOnboardPublisherPodcasts} from './AdvisorOnboardPublisherPodcasts';

export function AdvisorOnboardPublisherIndex({
  organization,
  user,
}: AdvisorRouteProps) {
  const {renderRoutes} = useAdvisorOnboardPublisherRoutes({organization});

  if ('isAdvisor' in organization && organization.isAdvisor) {
    return <Redirect to={createOrgPath(organization, '/advisor')} />;
  }

  return (
    <ResponsiveContent containerSize='medium'>
      <Switch>
        {renderRoutes({
          components: {
            index: AdvisorOnboardPublisher,
            podcasts: AdvisorOnboardPublisherPodcasts,
            capabilities: AdvisorOnboardPublisherCapabilities,
          },
          extraProps: {
            user,
            organization,
          },
        })}
      </Switch>
    </ResponsiveContent>
  );
}
