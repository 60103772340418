import {
  DeleteCampaignDynamicReasonsQuery,
  DeleteCampaignPodcastReasonsQuery,
  useDeleteCampaignDynamicMutation,
  useDeleteCampaignDynamicReasonsQuery,
  useDeleteCampaignPodcastMutation,
  useDeleteCampaignPodcastReasonsQuery,
  useDeleteCampaignStreamingMutation,
} from '@analytics/graphql-api';

export type AdType = 'dynamic' | 'podcast' | 'streaming';

export const useDeleteMutation = (adType: AdType) => {
  switch (adType) {
    case 'dynamic':
      return useDeleteCampaignDynamicMutation;
    case 'podcast':
      return useDeleteCampaignPodcastMutation;
    case 'streaming':
      return useDeleteCampaignStreamingMutation;
    default:
      return '_unknown_' as never;
  }
};
export const useDeleteReasonQuery = (adType: 'dynamic' | 'podcast') => {
  switch (adType) {
    case 'dynamic':
      return useDeleteCampaignDynamicReasonsQuery;
    case 'podcast':
      return useDeleteCampaignPodcastReasonsQuery;
    default:
      return '_unknown_' as never;
  }
};

export const getDeleteReasons = (
  data:
    | DeleteCampaignPodcastReasonsQuery
    | DeleteCampaignDynamicReasonsQuery
    | undefined
) => {
  const campaign = data?.me?.organization?.campaign ?? {};
  return 'campaignDynamic' in campaign
    ? campaign.campaignDynamic?.deleteReasons ?? []
    : 'campaignPodcast' in campaign
    ? campaign.campaignPodcast?.deleteReasons ?? []
    : [];
};
