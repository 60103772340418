import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {CampaignRouteProps} from '../../_routes';
import {CampaignLinkCreate} from './CampaignLinkCreate';
import {CampaignLinkSelect} from './CampaignLinkSelect';

export function CampaignCreateLinkIndex({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  return (
    <>
      <Switch>
        <PropsRoute
          exact
          path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/links/create'
          user={user}
          organization={organization}
          campaign={campaign}
          component={CampaignLinkSelect}
        />
        <PropsRoute
          exact
          path='/:part(b|a|s|p)/:slug/campaigns/:campaignSlug/links/create/:campaignTargetId'
          user={user}
          organization={organization}
          campaign={campaign}
          component={CampaignLinkCreate}
        />
      </Switch>
    </>
  );
}
