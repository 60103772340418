import React from 'react';
import {findCountry, findRegion} from '@core/lib/countryRegionData';
import {comma, toPercent} from '@core/lib/filters';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';

const AttributionCountriesTable = ({
  countries,
  count,
  onClickRowPath = null,
}) => {
  const data = countries.map(({country, total}) => {
    const countryMeta = findCountry(country);

    return {
      id: country,
      country,
      countryName: countryMeta ? countryMeta.countryName : '',
      flag: countryMeta ? countryMeta.flag : '',
      total,
      percentage: total / count,
    };
  });

  const columns = [
    {
      title: 'Country',
      type: 'string',
      accessor: 'countryName',
      Cell: ({data: {country, countryName, flag}}) => (
        <Element rules={() => ({display: 'flex', alignItems: 'center'})}>
          <Element
            tag='img'
            src={flag}
            rules={() => ({
              height: 'auto',
              marginRight: '0.625rem',
              width: '1.5rem',
            })}
          />
          {countryName || country}
        </Element>
      ),
    },
    {
      title: 'Impressions',
      type: 'number',
      accessor: 'total',
      Cell: ({data: {total, percentage}}) => (
        <Element
          rules={() => ({
            display: 'flex',
            justifyContent: 'flex-end',
            whiteSpace: 'nowrap',
          })}>
          <strong>{comma(total)}</strong>
          <Element
            rules={({theme}) => ({
              color: theme.textTertiary,
              marginLeft: '0.1875rem',
            })}>
            ({toPercent(percentage)})
          </Element>
        </Element>
      ),
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      orderBy='-total'
      searchKeys={['countryName']}
      searchPlaceholder='Find country'
      emptyMessageText='No impressions found.'
      onClickRowPath={onClickRowPath}
    />
  );
};

const AttributionRegionsTable = ({
  regions,
  country,
  count,
  onClickRowPath = null,
}) => {
  const data = regions.map(({region, total}) => {
    const meta = findRegion({
      countryIso2Code: country,
      regionIso2Code: region,
    });

    return {
      id: region,
      region,
      regionName: meta ? meta.name : region,
      total,
      percentage: total / count,
    };
  });

  const columns = [
    {
      title: 'Region',
      type: 'string',
      accessor: 'regionName',
    },
    {
      title: 'Impressions',
      type: 'number',
      accessor: 'total',
      Cell: ({data: {total, percentage}}) => (
        <Element
          rules={() => ({
            display: 'flex',
            justifyContent: 'flex-end',
            whiteSpace: 'nowrap',
          })}>
          <strong>{comma(total)}</strong>
          <Element
            rules={({theme}) => ({
              color: theme.textTertiary,
              marginLeft: '0.1875rem',
            })}>
            ({toPercent(percentage)})
          </Element>
        </Element>
      ),
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      orderBy='-total'
      searchKeys={['regionName']}
      searchPlaceholder='Find region'
      emptyMessageText='No impressions found.'
      onClickRowPath={onClickRowPath}
    />
  );
};

const AttributionCitiesTable = ({cities, count}) => {
  const data = cities.map((city) => {
    return Object.assign({percentage: city.total / count}, city);
  });
  const columns = [
    {
      title: 'City',
      type: 'string',
      accessor: 'city',
      rule: () => ({textTransform: 'capitalize'}),
    },
    {
      title: 'Impressions',
      type: 'number',
      accessor: 'total',
      Cell: ({data: {total, percentage}}) => (
        <Element
          rules={() => ({
            display: 'flex',
            justifyContent: 'flex-end',
            whiteSpace: 'nowrap',
          })}>
          <strong>{comma(total)}</strong>
          <Element
            rules={({theme}) => ({
              color: theme.textTertiary,
              marginLeft: '0.1875rem',
            })}>
            ({toPercent(percentage)})
          </Element>
        </Element>
      ),
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      orderBy='-total'
      searchKeys={['city']}
      emptyMessageText='No impressions found.'
      searchPlaceholder='Find city'
    />
  );
};

export {
  AttributionCountriesTable,
  AttributionRegionsTable,
  AttributionCitiesTable,
};
