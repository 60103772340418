import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import {Controller, useForm} from 'react-hook-form';
import Checkbox from '@core/ui/Checkbox';
import {Separator} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {
  FORM_ERRORS,
  Form,
  FormFooter,
  FormHelp,
  Label,
  Submit,
} from '@core/ui/FormElements';
import PixelEventSelect from '@analytics/components/pixels/PixelEventSelect';
import CREATE_PIXEL_EVENT_RECORD from '@analytics/graphql-api/_old_mutations/CreatePixelEventRecord';
import ORGANIZATION_QUERY from '@analytics/graphql-api/_old_queries/OrganizationQuery';
import {fmtPixelEvent, getDefaultEvents, pixelEventTypes} from './helpers';

interface IPixelEventOnboardFormProps {
  organization: {
    keys: Array<{
      key: string;
      installations: string;
    }>;
  };
  onSuccess: () => void;
}

interface IFormValues {
  events: Array<{
    name: string;
    type: string;
    installation?: string;
  }>;
}

const PixelEventOnboardForm = ({
  organization,
  onSuccess,
}: IPixelEventOnboardFormProps): JSX.Element => {
  const [resetEvent, setResetEvent] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [createPixelEventRecord] = useMutation(CREATE_PIXEL_EVENT_RECORD, {
    refetchQueries: [ORGANIZATION_QUERY],
  });

  const {
    control,
    handleSubmit,
    formState: {errors},
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      events: getDefaultEvents(organization.keys[0]),
    },
  });

  const onSubmit = async ({events}: IFormValues) => {
    setLoading(true);
    try {
      for (let i = 0; i < events.length; i++) {
        const name = events[i].name;
        const eventType = events[i].type.toUpperCase();

        await createPixelEventRecord({
          variables: {
            input: {
              key: organization.keys[0].key,
              name,
              eventType,
              category: eventType === 'LEAD' ? name : '',
              installation: events[i].installation
                ? events[i].installation
                : 'JAVASCRIPT',
            },
          },
        });
      }
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      setError(null, FORM_ERRORS.network);
    }
    setLoading(false);
  };

  const events = watch('events');

  const columns: DataTableColumn[] = [
    {
      title: '',
      accessor: 'name',
      type: 'string',
      Cell: ({data: {name, isDefault}}) => {
        const withDefault =
          (name === 'Page View' || name === 'Mobile Install') && isDefault;
        return withDefault ? (
          <div css={{height: '1.125rem', width: '1.125rem'}} />
        ) : (
          <div css={{display: 'flex', justifyContent: 'center'}}>
            <Checkbox
              key={name}
              defaultValue
              disabled={withDefault}
              onChecked={(checked) => {
                if (!checked)
                  setValue(
                    'events',
                    [...events].filter((e) => e.isDefault || e.name !== name)
                  );
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Event Name',
      accessor: 'name',
      type: 'string',
    },
    {
      title: 'Action',
      accessor: 'type',
      type: 'string',
      fmt: (v) => fmtPixelEvent(v),
    },
  ];

  // Filter out default events
  const eventItems = pixelEventTypes.filter(
    ({name}) => name !== 'Mobile Install' && name !== 'Page View'
  );

  return (
    <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
      <Label>What kind of events would you like to track?</Label>
      <Controller
        name='events'
        control={control}
        render={({field}) => (
          <PixelEventSelect
            key={resetEvent}
            items={eventItems}
            onSelect={({name, type}) => {
              field.onChange([...events, {name, type: type || 'lead'}]);
              setResetEvent(resetEvent + 1);
            }}
          />
        )}
      />
      <FormHelp>
        Suggested events might include: newsletter signups, free trials, or
        requests for quote.
      </FormHelp>
      {!!events?.length && (
        <>
          <Separator />
          <DataTable data={events} columns={columns} />
        </>
      )}
      <FormFooter>
        <Submit disabled={loading} loading={loading}>
          Add {events.length} Required Event{events.length > 1 && 's'}
        </Submit>
      </FormFooter>
    </Form>
  );
};

export default PixelEventOnboardForm;
