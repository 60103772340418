import {useState} from 'react';
import {
  ButtonPrimary,
  ButtonTertiary,
  FormGroup,
  FormHelpText,
  FormInput,
  IconInformationAlt,
  LoadingIndicator,
  ProgressCircle,
  Tooltip,
  TooltipTrigger,
} from '@spotify-internal/encore-web';
import {CurrencyDropdownSelector} from '@spotify-internal/web-i18n-components';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {CreateOrganizationInput, useGetMeQuery} from '@core/graphql-api';
import {Form} from '@core/ui/FormElements';
import SpotifyBanner from '@analytics/components/onboard/SpotifyBanner';
import {
  OrganizationCurrency,
  OrganizationLocation,
  useCreateOrganizationMutation,
  useLocationsQuery,
} from '@analytics/graphql-api';
import OnboardDropdown from './OnboardDropdown';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 64px 0px;
  width: 100%;
  justify-content: center;
`;

const FormContainer = styled.div`
  min-width: 759px;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  gap: 24px;
`;

const labelMap = {
  brand: 'Brand Name',
  podcast: 'Publisher Name',
  'sell-side': 'Agency Name',
  'buy-side': 'Agency Name',
};

const knowledgeLevels = [
  {text: 'Novice', id: 'novice'},
  {text: 'Proficient', id: 'proficient'},
  {text: 'Expert', id: 'expert'},
];

const currencies = Object.entries(OrganizationCurrency).map(([, text]) => ({
  id: text,
  text,
}));

const currenciesArray = Object.entries(OrganizationCurrency).map(
  ([, text]) => text
);

const OnboardCreate = ({
  match: {
    params: {kind},
  },
}) => {
  const navigation = useHistory();
  const {refetch} = useGetMeQuery({variables: {service: 'analytics'}});
  const [createOrganization] = useCreateOrganizationMutation();
  const [pageLoading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const {data, loading} = useLocationsQuery();

  const locations =
    data?.locations?.map((location) => ({
      id: location?.locationName,
      text: location?.locationNameDisplay,
    })) || [];

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: {errors},
    setValue, // Add this line
  } = useForm({
    defaultValues: {
      knowledgeLevel: knowledgeLevels[0].id,
      location: 'USA',
      currency: currencies[0].id,
    },
  });

  const name = watch('name');

  if (loading) {
    return <ProgressCircle />;
  }

  const onSubmit = async ({
    name,
    knowledgeLevel,
    location,
    currency,
  }: CreateOrganizationInput) => {
    let shouldRedirect = true;
    let newOrg;
    setLoading(true);
    try {
      newOrg = await createOrganization({
        variables: {
          input: {
            name,
            kind,
            knowledgeLevel,
            location,
            currency,
          },
        },
      });

      const slug = newOrg.data.createOrganization.organization.slug;

      if (shouldRedirect && slug) {
        await refetch();
        if (kind === 'brand') {
          navigation.push(`/onboard/create-pixel/${slug}`);
        } else if (kind === 'podcast' || kind === 'sell-side') {
          navigation.push(`/onboard/ad-insertion/${slug}`);
        } else {
          navigation.push(`/onboard/terms/${slug}`);
        }
      }
    } catch (err) {
      if (err.message.includes('already exists')) {
        shouldRedirect = false;
        setError('name', {
          type: 'manual',
          message: `Organization '${name}' already exists! Please choose a different name.`,
        });
      } else {
        setError('createOrganization', {
          type: 'manual',
          message: 'Something went wrong. Please try again.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const DashboardCurrencyTooltip = () => (
    <TooltipTrigger
      overlay={
        showTooltip && (
          <Tooltip id='website'>
            Your brand should be associated with a single website domain.
          </Tooltip>
        )
      }
      placement={TooltipTrigger.right}
      onClick={() => {}}
      onShow={() => setShowTooltip(true)}
      onHide={() => setShowTooltip(false)}>
      <ButtonTertiary
        aria-labelledby='notifications'
        iconOnly={IconInformationAlt}
        buttonSize='sm'
        condensed
        type='button'
        style={{
          marginLeft: '5px',
        }}
      />
    </TooltipTrigger>
  );

  return (
    <PageContainer>
      <SpotifyBanner advertising progress={50} />
      <Header>Tell us about your organization</Header>

      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)} onChange={() => clearErrors()}>
          <Controller
            name='name'
            control={control}
            rules={{required: 'Please provide an organization name.'}}
            render={({field}) => (
              <FormGroup
                label='Organization Name'
                labelFor={'organization-name'}>
                <FormInput
                  label={'organization-name'}
                  {...field}
                  placeholder={labelMap[kind]}
                  style={{
                    borderColor: 'red',
                  }}
                />
                <FormHelpText
                  maxChars={120}
                  style={{fontFeatureSettings: 'normal'}}>
                  {name ? 120 - name.length : 120} characters remaining
                </FormHelpText>
                {errors.name && (
                  <FormHelpText error>{errors.name.message}</FormHelpText>
                )}
              </FormGroup>
            )}
          />
          <Controller
            name='location'
            control={control}
            rules={{
              validate: (value) => !!value || 'Please provide a location.',
            }}
            render={({field}) => (
              <OnboardDropdown
                label={'Organization Location'}
                labelFor={'organization-location'}
                items={locations}
                field={field}
              />
            )}
          />
          <Controller
            name='knowledgeLevel'
            control={control}
            rules={{
              validate: (value) =>
                !!value || 'Please select a knowledge level.',
            }}
            render={({field}) => (
              <OnboardDropdown
                label={'Organization Knowledge Level'}
                labelFor={'organization-knowledge'}
                items={knowledgeLevels}
                field={field}
              />
            )}
          />
        </Form>

        {kind === 'brand' && (
          <FormGroup
            label={
              <>
                Dashboard Currency <DashboardCurrencyTooltip />
              </>
            }>
            <CurrencyDropdownSelector
              currencies={currenciesArray}
              label={''}
              callback={(currency) => setValue('currency', currency.code)}
            />
          </FormGroup>
        )}
      </FormContainer>
      <ButtonsContainer>
        <ButtonPrimary
          type='button'
          style={{minWidth: 300, marginTop: 60}}
          disabled={pageLoading || !name}
          onClick={handleSubmit(onSubmit)}>
          {pageLoading ? <LoadingIndicator /> : 'Next'}
        </ButtonPrimary>
      </ButtonsContainer>
    </PageContainer>
  );
};

export default OnboardCreate;
