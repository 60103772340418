import {useHistory} from 'react-router-dom';
import {Card} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import {Text} from '@core/ui/Text';
import {CampaignKind} from '@analytics/components/campaigns/_types';
import {CampaignCreateSteps} from '@analytics/components/campaigns/create/CampaignCreateSteps';
import CompanyIndustryForm from '@analytics/components/forms/CompanyIndustryForm';
import {CampaignsRouteProps} from '../../_routes';
import {toPubCreateCampaignBasePath} from './_routes';

export function PublisherSelectBrandDetails({
  organization,
  match: {
    params: {kind},
  },
}: CampaignsRouteProps & {
  match: {
    params: {
      kind: CampaignKind;
    };
  };
}) {
  const history = useHistory();

  const goToStep3 = (id: string) => {
    history.push(`${toPubCreateCampaignBasePath(organization)}/${kind}/${id}`);
  };

  return (
    <>
      <CampaignCreateSteps active='brand-details' />
      <Card>
        <Stack direction='column' gap='var(--spacing-4)'>
          <Text>
            Please select the brand that is advertising in this campaign.
          </Text>
          <CompanyIndustryForm onComplete={goToStep3} />
        </Stack>
      </Card>
    </>
  );
}
