import {FC, useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {Redirect} from 'react-router-dom';
import {FetchedUser} from '@core/graphql-api';
import {createOrgPath} from '@core/lib/organizations';
import Button from '@core/ui/Button/LegacyButton';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import {FetchedOrganization} from '@analytics/graphql-api';
import LOCK_END_MUTATION from './LockEndMutation';
import LOCK_START_MUTATION from './LockStartMutation';

interface IWrappedProps {
  episode: TSFixMe;
  organization: FetchedOrganization;
  user: FetchedUser;
}

/*
 * Locks an episode so no one else works on it.
 */
const withLock = (WrappedComponent: any): FC => {
  return ((props: IWrappedProps): JSX.Element => {
    const [count, setCount] = useState<number>(3600);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [openedError, toggleError] = useOverlay(false);
    const [createEpisodeLock] = useMutation(LOCK_START_MUTATION);
    const [removeEpisodeLock] = useMutation(LOCK_END_MUTATION);

    useEffect(() => {
      const {episode, user} = props;
      const isActive = !!episode.id;

      const onLock = async () => {
        try {
          const resp = await createEpisodeLock({
            variables: {
              input: {
                episodeId: episode.id,
                userId: user.id,
              },
            },
          });

          if (!resp.data.lockStart.success) {
            toggleError(true);
          }
        } catch (e) {
          toggleError(true);
        }
      };

      if (isActive) {
        onLock();
      }

      const _interval =
        isActive &&
        setInterval(() => {
          if (!count) {
            toggleError(true);
          } else {
            setCount((val) => val - 1);
          }
        }, 1000);

      return () => {
        if (isActive) {
          const offLock = async () => {
            try {
              await removeEpisodeLock({
                variables: {
                  input: {
                    episodeId: episode.id,
                    userId: user.id,
                  },
                },
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.warn(err);
            }
          };

          clearInterval(_interval);
          offLock();
        }
      };
    }, []);

    if (redirect) {
      return (
        <Redirect
          to={createOrgPath(props.organization, '/research/reviewers/episodes')}
        />
      );
    }

    return (
      <div css={{position: 'relative'}}>
        <WrappedComponent {...props} />
        <Overlay
          centered
          closeOnEscKey={false}
          closeOnOutsideClick={false}
          opened={openedError}
          toggle={toggleError}
          withBackdrop
          css={`
            padding: 1.5rem;
          `}>
          <p>
            Another user is already working on this episode, please go back and
            select a different episode.
          </p>
          <div css={{margin: '1rem 0 0', textAlign: 'right'}}>
            <Button style-secondary onClick={() => setRedirect(true)}>
              Select New Episode
            </Button>
          </div>
        </Overlay>
        {/* <b
          css={{position: 'absolute',
            bottom: '0',
            left: '0',
            padding: '5px',
            background: '#fff',
            borderTop: '1px solid #ddd',
            borderRight: '1px solid #ddd',
          }}
        >
          {toTimeString(count)}
        </b> */}
      </div>
    );
  }) as FC;
};

export default withLock;
