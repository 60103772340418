import dayjs from '@core/lib/dayjs';

export const getLiftState = ({isComplete}) =>
  isComplete ? 'COMPLETE' : 'PENDING';

export const fmtDateRange = ({
  d: {startDate, endDate},
}: {
  d: {startDate: string | null; endDate: string | null};
}) =>
  startDate && endDate
    ? `${dayjs(startDate).format('M/D/YYYY')} to ${dayjs(endDate).format(
        'M/D/YYYY'
      )}`
    : '-';
