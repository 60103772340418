import {ReactNode} from 'react';
import {
  Type,
  semanticColors,
  spacer4,
  spacer24,
} from '@spotify-internal/encore-web';
import {capitalize} from 'lodash';
import {Card} from '@core/ui/Content';
import {CardProps} from '@core/ui/Content/Card';
import {Stack} from '@core/ui/Layout';

export function InsightsDisplayCard({
  title,
  subtitle,
  content,
  children,
  ...props
}: Omit<CardProps, 'content'> & {
  title: string;
  subtitle?: string;
  content?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
}) {
  return (
    <Card css={{margin: 0}} {...props}>
      <Stack direction='column' gap={spacer24}>
        <Stack direction='column' gap={spacer4}>
          <Type weight='bold' variant='celloCanon'>
            {capitalize(title)}
          </Type>
          {subtitle && (
            <Type semanticColor={semanticColors.textSubdued} variant='mesto'>
              {subtitle}
            </Type>
          )}
        </Stack>
        {content || children}
      </Stack>
    </Card>
  );
}
