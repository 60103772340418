import React, {useState} from 'react';
import {
  Cell,
  Label,
  LabelProps,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  SectorProps,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {comma, toPercent} from '@core/lib/filters';
import {Element} from '@core/style';
import {darken} from '@core/style';

const IndustriesCenterLabel = ({
  viewBox,
  total,
}: LabelProps & {total: number}): JSX.Element => {
  const {cx, cy} = viewBox;
  return (
    <>
      <text
        x={cx}
        y={cy - 5}
        fill='var(--text-muted)'
        textAnchor='middle'
        dominantBaseline='central'>
        <tspan alignmentBaseline='middle' fontSize='28'>
          {comma(total)}
        </tspan>
      </text>
      <text
        x={cx}
        y={cy + 15}
        fill='var(--text-muted)'
        textAnchor='middle'
        dominantBaseline='central'>
        <tspan fontSize='14'>Total Ads</tspan>
      </text>
    </>
  );
};

const IndustriesTooltip = ({
  active,
  payload,
}: TooltipProps<number, string>): JSX.Element => {
  if (!active || !payload || !payload.length) return null;
  return (
    <Element
      rules={({theme}) => ({
        alignItems: 'center',
        background: theme.bgPrimary,
        border: `1px solid ${theme.borderPrimary}`,
        borderRadius: '0.5rem',
        boxShadow: theme.shadowLarge,
        color:
          payload[0].payload.name === 'N/A'
            ? theme.textPrimary
            : payload[0].payload.fill,
        display: 'flex',
        fontSize: '0.875rem',
        fontWeight: 500,
        justifyContent: 'space-between',
        padding: '0.5rem',
      })}>
      <div>{payload[0].name}</div>
      <Element rules={() => ({marginLeft: '0.5rem'})}>
        {toPercent(payload[0].payload.percent, 1)}
      </Element>
    </Element>
  );
};

const renderActiveShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
}: SectorProps): JSX.Element => {
  return (
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius - 8}
      outerRadius={outerRadius + 8}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  );
};

const AdvisorPodcastIndustriesChart = ({
  industries,
  height = 215,
  activeIndex,
  setActiveIndex,
}: {
  industries: {
    name: string;
    value: number;
    percent: string | number;
    fill: string;
    total: number;
  }[];
  height?: number;
  activeIndex?: number;
  setActiveIndex?: (i: number) => void;
}): JSX.Element => {
  const [focusSlice, setFocusSlice] = useState<number | null>(null);

  return (
    <>
      <ResponsiveContainer width='99%' height={height}>
        <PieChart
          margin={{
            top: 45,
            right: 25,
            bottom: 45,
            left: 25,
          }}>
          <Pie
            activeIndex={setActiveIndex ? activeIndex : null}
            activeShape={setActiveIndex ? renderActiveShape : null}
            cy='50%'
            cx='50%'
            data={industries}
            dataKey='value'
            innerRadius={70}
            minAngle={2}
            onClick={setActiveIndex ? (props, i) => setActiveIndex(i) : null}
            outerRadius={125}
            onMouseEnter={
              setActiveIndex ? (props, i) => setFocusSlice(i) : null
            }
            onMouseLeave={setActiveIndex ? () => setFocusSlice(null) : null}
            paddingAngle={1.25}
            stroke='var(--bg-default)'
            style={{cursor: setActiveIndex ? 'pointer' : 'inherit'}}>
            {setActiveIndex &&
              industries.map((entry, i) => (
                <Cell
                  key={entry.fill}
                  fill={
                    focusSlice === i && focusSlice !== activeIndex
                      ? darken(entry.fill, 0.2)
                      : entry.fill
                  }
                />
              ))}
            <Label
              width={30}
              position='center'
              content={<IndustriesCenterLabel total={industries[0].total} />}>
              Total
            </Label>
          </Pie>
          <Tooltip content={<IndustriesTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default AdvisorPodcastIndustriesChart;
