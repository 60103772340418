import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import Button from '@core/ui/Button/LegacyButton';
import {
  Form,
  FormFooter,
  FormGroup,
  LabelInput,
  LabelSelect,
  Submit,
} from '@core/ui/FormElements';

interface Item {
  action: string;
  eventName: string;
}

interface PixelActionMapFormProps {
  updateItem?: Item;
  onCancel: () => void;
  onSubmit: (item: Item) => void;
  loading: boolean;
}

const PixelActionMapForm = ({
  updateItem = null,
  onCancel,
  onSubmit,
  loading,
}: PixelActionMapFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: {errors, isDirty},
  } = useForm({
    defaultValues: updateItem && {
      eventName: updateItem.eventName,
      action: updateItem.action,
    },
  });

  const _onSubmit = ({eventName, action}) => {
    if (updateItem && !isDirty) {
      onCancel();
    } else {
      onSubmit({
        eventName,
        action,
      });
    }
  };

  return (
    <Form errors={errors} onSubmit={handleSubmit(_onSubmit)}>
      <FormGroup>
        <Controller
          name='eventName'
          control={control}
          rules={{required: 'Please provide an event name.'}}
          render={({field}) => (
            <LabelInput
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              invalid={!!errors.eventName}
              label='Event Name'
              defaultValue={updateItem ? updateItem.eventName : null}
              onChange={(value) => field.onChange(value)}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name='action'
          control={control}
          rules={{
            validate: (value) =>
              !!value ? true : 'Please select an event action.',
          }}
          render={({field}) => (
            <LabelSelect
              label='Event Action'
              defaultValue={updateItem?.action.toLowerCase() || null}
              invalid={!!errors.action}
              items={[
                {title: 'Lead', value: 'lead'},
                {title: 'Purchase', value: 'purchase'},
                {title: 'Product', value: 'product'},
                {title: 'Add To Cart', value: 'addtocart'},
                {title: 'Checkout', value: 'checkout'},
                {title: 'Install', value: 'install'},
              ]}
              onSelect={({value}) => field.onChange(value)}
            />
          )}
        />
      </FormGroup>
      <FormFooter>
        <Button type='button' disabled={loading} onClick={onCancel}>
          Cancel
        </Button>
        <Submit loading={loading} disabled={loading}>
          Save Event Mapping
        </Submit>
      </FormFooter>
    </Form>
  );
};

export default PixelActionMapForm;
