import {gql} from '@apollo/client';

export default gql`
  mutation upsertPlacements($input: UpsertPlacementsInput!) {
    upsertPlacements(input: $input) {
      placements {
        id
        createdAt
      }
    }
  }
`;
