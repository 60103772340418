import {useRef, useState} from 'react';
import {Icon} from '@core/ui/Icon';
import {useOverlay} from '@core/ui/Overlay';
import TagsOverlay from './TagsOverlay';

const TagBadge = ({tag, rules}: TSFixMe) => {
  return (
    <div
      css={`
        background: ${tag.color};
        border-radius: 3rem;
        border: 0.125rem solid var(--bg-surface);
        color: #fff;
        display: inline-block;
        font-size: 0.8125rem;
        margin: 0 0 0 -1rem;
        overflow: hidden;
        padding: 3px 0.625rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      `}>
      {tag ? tag.title : null}
    </div>
  );
};

const TagsContainer = ({
  domRef,
  instanceTags,
  showEditButtonIfEmpty,
  toggleOverlay,
  ...props
}: TSFixMe) => {
  return (
    <div
      ref={domRef}
      onClick={(evt) => {
        evt.stopPropagation();
        toggleOverlay(true);
      }}
      css={`
        cursor: pointer;
        display: flex;
        :hover {
          filter: brightness(90%);
        }
      `}
      {...props}>
      {instanceTags.length > 0 ? (
        <div
          css={`
            align-items: center;
            display: grid;
            grid-template-columns: 1fr max-content;
          `}>
          <div
            css={`
              display: flex;
              margin-left: 1rem;
              min-width: 3rem;
            `}>
            {instanceTags.slice(0, 3).map((tag) => {
              return <TagBadge key={tag.id} tag={tag} />;
            })}
          </div>
          {instanceTags.length > 3 ? (
            <div
              css={`
                color: var(--text-muted);
                font-size: 0.875rem;
                margin: 0 0.25rem;
              `}>
              {instanceTags.length}
            </div>
          ) : null}
        </div>
      ) : showEditButtonIfEmpty ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href='#'
          onClick={(evt) => {
            evt.preventDefault();
          }}
          css={`
            align-items: center;
            background: var(--bg-muted);
            border-radius: 4px;
            color: var(--text-default);
            display: inline-flex;
            font-size: var(--font-size-body-3);
            gap: 0.25rem;
            padding: var(--spacing-1) var(--spacing-3);
            text-decoration: none;
            :hover {
              color: var(--blue);
            }
            :hover > svg {
              color: var(--blue);
            }
          `}>
          <Icon
            icon='tag'
            css={`
              color: var(--icon-primary);
              display: flex;
              height: 1rem;
              width: 1rem;
            `}
          />{' '}
          Add tags
        </a>
      ) : null}
    </div>
  );
};

export default function Tags({
  contentType,
  instance,
  organization,
  showEditButtonIfEmpty = false,
  overlayProps,
  ...props
}: TSFixMe) {
  const ref = useRef();
  const [opened, toggle] = useOverlay();
  const [instanceTags, setInstanceTags] = useState(instance?.genericTags || []);

  return (
    <>
      <TagsContainer
        domRef={ref}
        toggleOverlay={toggle}
        instanceTags={instanceTags}
        showEditButtonIfEmpty={showEditButtonIfEmpty}
        {...props}
      />
      {opened ? (
        <TagsOverlay
          opened={opened}
          toggle={toggle}
          instance={instance}
          instanceTags={instanceTags}
          setInstanceTags={setInstanceTags}
          organization={organization}
          positionTarget={ref.current}
          overlayProps={overlayProps}
        />
      ) : null}
    </>
  );
}
