import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Element} from '@core/style';
import {ToastMessage} from '@core/ui/Alert';
import Button from '@core/ui/Button/LegacyButton';
import {Card, CardHeader} from '@core/ui/Content';
import {FormFooter, Submit} from '@core/ui/FormElements';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import {
  FetchedBuySideOrganization,
  FetchedMembersMemberships,
  FetchedOrganization,
} from '@analytics/graphql-api';
import REMOVE_MEMBER_MUTATION from '@analytics/graphql-api/_old_mutations/RemoveMember';
import MEMBERS_QUERY from '@analytics/graphql-api/_old_queries/MembersQuery';

interface IMembershipDeleteFormProps {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  membership: FetchedMembersMemberships[number];
}

const MembershipDeleteForm = ({
  organization,
  membership,
}: IMembershipDeleteFormProps) => {
  const [removeMember] = useMutation(REMOVE_MEMBER_MUTATION, {
    refetchQueries: [MEMBERS_QUERY],
  });
  const history = useHistory();
  const [opened, toggle] = useOverlay();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    setError(false);
    try {
      await removeMember({
        variables: {
          input: {
            id: membership.id,
          },
        },
      });
      setLoading(false);
      history.push(createOrgPath(organization, 'manage/members'));
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(true);
    }
  };

  return (
    <>
      <Card>
        <CardHeader>Delete Member</CardHeader>
        <p>Remove this member from your organization.</p>
        <Button style-destructive style-outline onClick={() => toggle(true)}>
          Delete
        </Button>
      </Card>
      {opened ? (
        <Overlay
          opened={opened}
          toggle={toggle}
          withBackdrop
          withShadow
          centered
          css={`
            padding: 1.25rem;
            min-width: 18.75rem;
          `}>
          <form onSubmit={onSubmit}>
            <Element
              tag='h3'
              rules={() => ({
                fontSize: '1.125rem',
                fontWeight: 700,
                margin: '0 0 0.75rem 0',
              })}>
              Delete {membership.user?.email}?
            </Element>
            <p>This action cannot be undone.</p>
            {error ? (
              <ToastMessage
                alertType='error'
                rules={() => ({margin: '0 0 1.5rem'})}>
                Network error. Please try again later.
              </ToastMessage>
            ) : null}
            <FormFooter>
              <Button onClick={() => toggle(false)}>Cancel</Button>
              <Submit color='error' loading={loading}>
                Delete
              </Submit>
            </FormFooter>
          </form>
        </Overlay>
      ) : null}
    </>
  );
};

export default MembershipDeleteForm;
