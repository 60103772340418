import React from 'react';
import {
  Control,
  ControllerProps,
  FieldValues,
  Path,
  useController,
} from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '../Form New';
import {Checkbox} from './Checkbox.new';

type CheckboxValue = string;

export type CheckboxGroupControlledProps<T extends FieldValues> = {
  color?: 'primary' | 'secondary';
  control?: Control<T>;
  disabled?: boolean;
  helperText?: string;
  label?: string;
  name: Path<T>;
  onChange?: (_: CheckboxValue[]) => void;
  options: {value: CheckboxValue; label: string}[];
  required?: boolean;
  row?: boolean;
  rules?: ControllerProps['rules'];
};

export function CheckboxGroupControlled<T extends FieldValues>({
  color = 'primary',
  control,
  disabled,
  helperText: helperTextProp,
  label,
  name,
  options,
  required = false,
  row,
  rules = {},
  ...props
}: CheckboxGroupControlledProps<T>) {
  if (required && !rules.required) {
    rules.required = 'This field is required';
  }

  const isRequired = required || !!rules?.required;

  const {
    field: {value: checkedBoxes = [] as CheckboxValue[], onChange},
    fieldState: {error},
  } = useController({name, rules: rules, control});

  const helperText = error?.message ?? helperTextProp;

  const _onChange = React.useCallback(
    (checkbox: CheckboxValue) => (_e: any, _chkd: any) => {
      const updatedValues = checkedBoxes.includes(checkbox)
        ? checkedBoxes.filter((v) => v !== checkbox)
        : [...checkedBoxes, checkbox];

      onChange(updatedValues);
      props.onChange?.(updatedValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedBoxes, onChange, props.onChange, props.onChange]
  );

  const checked = React.useCallback(
    (value: CheckboxValue) => checkedBoxes.includes(value),
    [checkedBoxes]
  );

  return (
    <FormControl error={!!error} required={isRequired}>
      {label && <FormLabel error={!!error}>{label}</FormLabel>}
      <FormGroup row={row}>
        {options.map(({value, label}) => (
          <FormControlLabel
            control={
              <Checkbox
                color={color}
                value={value}
                checked={checked(value)}
                disabled={disabled}
                onChange={_onChange(value)}
              />
            }
            key={value}
            label={label}
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
