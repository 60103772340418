import {useHistory} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {CardHeader} from '@core/ui/Content';
import {Container} from '@core/ui/Layout';
import {LiftReportForm} from '@analytics/components/lift/IncrementalBrandLift';
import {MeasureIncrementalLiftRouteProps} from './_routes';

export function LiftRequest({organization}: MeasureIncrementalLiftRouteProps) {
  const history = useHistory();
  const liftUrl = createOrgPath(organization, 'measure/lift');

  return (
    <Container maxWidth='md'>
      <CardHeader backPath={liftUrl} css={{marginLeft: 0, marginRight: 0}}>
        New Lift Report
      </CardHeader>
      <LiftReportForm
        organization={organization}
        onCancel={() => history.push(liftUrl)}
      />
    </Container>
  );
}
