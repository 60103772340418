import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import Loading from '@core/ui/Loading';
import ReviewAd from '@analytics/components/ads/ReviewAd';
import {reviewersURL} from '@analytics/components/nav/ResearchNav';
import ReviewerAdQuery from '@analytics/graphql-api/_old_queries/reviewers/ReviewerAdQuery';

class ReviewerAd extends React.Component {
  state = {
    redirect: false,
  };

  onComplete = (data) => {
    this.setState({
      redirect: true,
    });
  };

  render() {
    const {
      reviewerId,
      organization,
      data: {loading, me},
    } = this.props;

    const {redirect} = this.state;

    if (loading) {
      return <Loading centered />;
    }
    if (redirect) {
      return <Redirect to={`${reviewersURL(organization)}/ads`} />;
    }

    const {ad} = me.organization.research.reviewing;

    return (
      <ReviewAd
        ad={ad}
        organization={organization}
        reviewerId={reviewerId}
        onComplete={this.onComplete}
      />
    );
  }
}

export default graphql(ReviewerAdQuery, {
  options: ({
    organization,
    match: {
      params: {adId},
    },
  }) => ({
    variables: {
      organizationId: organization.id,
      id: adId,
    },
  }),
})(ReviewerAd);
