import {Card, CardHeader, PageTitle} from '@core/ui/Content';
import AgencyCompaniesTable from '@analytics/components/companies/AgencyCompaniesTable';
import {FetchedBuySideOrganization} from '@analytics/graphql-api';

type Props = {
  organization: FetchedBuySideOrganization;
};

export function BuySideHome({organization}: Props) {
  const companies = organization.companies ?? [];

  return (
    <>
      <PageTitle>{organization.name}</PageTitle>
      <Card>
        <CardHeader>Brands ({companies.length})</CardHeader>
        <AgencyCompaniesTable
          organization={organization}
          companies={companies}
        />
      </Card>
    </>
  );
}
