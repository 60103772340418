import {gql} from '@apollo/client';

export default gql`
  query getFeedCompanies(
    $organizationId: ID!
    $id: ID!
    $limit: Int
    $orderBy: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        research {
          feed(id: $id) {
            id
            companies(after: "2018-07-12", limit: $limit, orderBy: $orderBy) {
              id
              name
              description
              url
              image {
                id
                width
                height
                src
                path
              }

              adsCount(after: "2018-07-12", feedId: $id)
              adFirstPublished(after: "2018-07-12", feedId: $id)
            }
          }
        }
      }
    }
  }
`;
