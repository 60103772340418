import {gql} from '@apollo/client';

export default gql`
  mutation deleteGenericTagRelationship(
    $input: DeleteGenericTagRelationshipInput!
  ) {
    authed {
      success
    }
    deleteGenericTagRelationship(input: $input) {
      success
    }
  }
`;
