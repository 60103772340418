import { cssSpacing } from "../../styles/index.js";
import { Type } from "../Type/index.js";
import styled from "../../styled-components.js";

/* 1. padding-inline-end = 24px (IconX) + 24px (extra space) */

export var Title = styled(Type).attrs({
  forwardedAs: 'h1',
  variant: 'cello'
}).withConfig({
  displayName: "Title",
  componentId: "sc-bugxpe-0"
})(["padding-block-end:", ";padding-inline-end:", ";"], cssSpacing('tighter-2', '8px'), cssSpacing('looser-3', '48px'));