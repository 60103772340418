import {
  CampaignDynamicObject,
  CampaignPodcastObject,
  CampaignStreamingObject,
  Maybe,
} from '@analytics/graphql-api';

export const CAMPAIGN_TYPE_SELECT = [
  {title: 'Attribution Campaign', value: 'attribution'},
  {title: 'Reporting Only Campaign', value: 'reporting'},
] as const;

interface Placement {
  downloadTotal: Maybe<number>;
  isBaseline?: boolean;
}

function groupPlacements<T extends Placement>(placements: T[]) {
  return placements.reduce(
    (acc, curr) => {
      if ((curr.downloadTotal ?? 0) > 0) {
        if (curr.isBaseline) {
          return {
            ...acc,
            baseline: [...acc.baseline, curr],
          };
        }
        return {
          ...acc,
          active: [...acc.active, curr],
        };
      }
      return {
        ...acc,
        upcoming: [...acc.upcoming, curr],
      };
    },
    {active: [] as T[], baseline: [] as T[], upcoming: [] as T[]}
  );
}

export function categorizeCampaignPlacements({
  campaignDynamics,
  campaignPodcasts,
  campaignStreamings,
}: {
  campaignDynamics: CampaignDynamicObject[];
  campaignPodcasts: CampaignPodcastObject[];
  campaignStreamings: CampaignStreamingObject[];
}) {
  const {
    active: activeDynamics,
    baseline: baselineDynamics,
    upcoming: upcomingDynamics,
  } = groupPlacements<CampaignDynamicObject>(campaignDynamics);

  const {active: activeCampaignPodcasts, upcoming: upcomingCampaignPodcasts} =
    groupPlacements<CampaignPodcastObject>(campaignPodcasts);

  const {
    active: activeStreamings,
    baseline: baselineStreamings,
    upcoming: upcomingStreamings,
  } = groupPlacements<CampaignStreamingObject>(campaignStreamings);

  return {
    activeDynamics,
    activeCampaignPodcasts,
    activeStreamings,
    baselineDynamics,
    baselineStreamings,
    upcomingDynamics,
    upcomingCampaignPodcasts,
    upcomingStreamings,
  };
}
