import {FetchedCampaign} from '@analytics/graphql-api';
import {getPlacementsStr} from '@analytics/lib/placements';
import _CampaignStatsBase from './_CampaignStatsBase';
import {formatDate} from './helpers';

export const CampaignEmbeddedStats = ({
  campaign,
  campaignPodcast,
}: {
  campaign: FetchedCampaign;
  campaignPodcast: TSFixMe;
}) => {
  return (
    <_CampaignStatsBase
      instance={campaignPodcast}
      campaign={campaign}
      details={[
        {
          title: 'Started',
          value: campaignPodcast.startAt
            ? formatDate(campaignPodcast.startAt)
            : '—',
        },
        {
          title: 'Group',
          value: campaignPodcast.isBaseline ? 'Baseline' : 'Exposed',
        },
        {
          title: 'Roll Placement',
          value: getPlacementsStr(campaignPodcast),
        },
        {
          title: 'Discount Code',
          value: campaignPodcast.discountCode || '—',
        },
        {
          title: 'Discount URL',
          value: campaignPodcast.discountUrl || '—',
        },
        {
          title: 'Effective URL',
          value: campaignPodcast.effectiveUrl || '—',
        },
      ]}
    />
  );
};
