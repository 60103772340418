import {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import styled from 'styled-components';
import {isProd} from '@core/lib/environment';
import {
  SITE_ACCESS_ID,
  injectPactSafeSnippet,
  isSnippetLoaded,
  waitForSnippet,
} from '@core/lib/pactsafeSnippet';

const restartGroupKey = (groupKey: string): void => {
  if (window._ps.getByKey?.(groupKey)) {
    window._ps.getByKey(groupKey).resetData();

    if (window._ps.getByKey(groupKey).rendered) {
      window._ps.getByKey(groupKey).rendered = false;
    }
  }
};

// This is a context object with properties about the signer
// https://clickwrap-developer.ironcladapp.com/docs/clickwrapgroup-object#properties
interface ClickwrapGroup {}

// https://clickwrap-developer.ironcladapp.com/docs/global_ps-object
interface CbParams {
  contracts: any;
  versions: any;
  group: any;
}

interface Props {
  groupKey: string;
  invalid?: boolean;
  onChecked?: (_checked: boolean) => void;
  sendData: boolean;
  signerId?: string;
  signerIdSelector?: string;
  agreementEventCb?: (_parameters?: CbParams, _ctx?: ClickwrapGroup) => void;
  rejectionEventCb?: (_parameters?: CbParams, _ctx?: ClickwrapGroup) => void;
  persistAgreementCb?: (_success?: boolean) => void;
}

export default function PactsafeClickwrap({
  groupKey,
  invalid = false,
  onChecked,
  sendData,
  signerId,
  signerIdSelector,
  agreementEventCb,
  rejectionEventCb,
  persistAgreementCb,
  ...props
}: Props) {
  const [checked, setChecked] = useState(false);

  useLayoutEffect(() => {}, []);

  useEffect(() => {
    onChecked?.(checked);
  }, [checked]);

  useEffect(() => {
    (async () => {
      if (!isSnippetLoaded()) {
        injectPactSafeSnippet();
      } else {
        await waitForSnippet();
      }
      // Log events to console
      window._ps.debug = !isProd;
      // Creates a Site object with the a PactSafe Site Access ID.
      window._ps('create', SITE_ACCESS_ID, {
        // Disable automatic sending with the JavaScript snippet.
        disable_sending: true,
        test_mode: !isProd,
      });
      window._ps.on('error', (message: any) => {
        // eslint-disable-next-line no-console
        console.warn('Pactsafe ERROR', message);
      });
      if (signerId) {
        window._ps('set', 'signer_id', signerId);
      }
      // Load a Clickwrap group into the page.
      window._ps('load', groupKey, {
        container_selector: 'pactsafeContainer',
        allow_disagreed: true,
        display_all: true,
        ...(signerIdSelector
          ? {signer_id_selector: signerIdSelector}
          : {signer_id: signerId}),
      });

      window._ps.on(
        'valid',
        function onValidCb(parameters: CbParams, ctx: ClickwrapGroup) {
          agreementEventCb?.(parameters, ctx);
          setChecked(true);
        }
      );

      window._ps.on(
        'invalid',
        function onInvalidCb(parameters: CbParams, ctx: ClickwrapGroup) {
          rejectionEventCb?.(parameters, ctx);
          setChecked(false);
        }
      );
    })();

    return () => {
      if (window._ps) {
        window._ps.off();
        restartGroupKey(groupKey);
      }
    };
  }, [
    signerId,
    signerIdSelector,
    groupKey,
    agreementEventCb,
    rejectionEventCb,
  ]);

  useEffect(() => {
    if (sendData && checked) {
      window._ps(`${groupKey}:send`, 'agreed', {
        disable_sending: false,
        event_callback: function eventCb(
          err?: any,
          _eventType?: any,
          _group?: any,
          _request?: any
        ) {
          let success = false;
          if (err) {
            // Problem with sending the agreement to the server
            console.error(err);
            // User agreement was persisted successfully
          } else success = true;
          persistAgreementCb?.(success);
        },
      });
    }
  }, [sendData, checked, groupKey, persistAgreementCb]);

  return (
    <_PacktsafeClickWrapContainer {...props}>
      <_PSContainer id='pactsafeContainer' />
    </_PacktsafeClickWrapContainer>
  );
}

const _PacktsafeClickWrapContainer = styled.div`
  margin: 0.375rem 0;
  position: relative;
`;

const _PSContainer = styled.div`
  & a {
    color: var(--blue);
    text-decoration: none;
  }
  & label {
    color: var(--text-default);
    font-size: 1rem;
    margin: 0 !important;
    padding: 0 0 0 1rem;
  }
  & div.ps-contract {
    padding: 0 !important;
  }
  /*   & input[type='checkbox'] {
    height: 1.25rem;
    border: 2px solid var(--border-default);
    width: 1.25rem !important;
  } */
`;

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    _ps: any;
  }
}
