import {createOrgPath} from '@core/lib/organizations';
import {DropdownMenuDivider, DropdownMenuTitle} from '@core/ui/DropdownMenu';
import {EpisodeImage} from '@core/ui/Image';
import {Nav} from '@core/ui/Nav';
import {toCampaignUrl} from '@analytics/screens/unified/campaigns/campaign/_routes';
import {campaignPodcastURL} from './CampaignPodcastNav';

const campaignEpisodeURL = (
  organization,
  campaign,
  campaignPodcast,
  campaignEpisode
) =>
  createOrgPath(
    organization,
    `/campaigns/${campaign.slug}/podcasts/${campaignPodcast.id}/episodes/${campaignEpisode.id}`
  );

const CampaignEpisodeNav = ({
  user,
  organization,
  campaign,
  campaignPodcast,
  campaignEpisode,
}) => {
  const url = campaignEpisodeURL(
    organization,
    campaign,
    campaignPodcast,
    campaignEpisode
  );

  const {feed} = campaignPodcast;

  return (
    <Nav
      title={campaignEpisode.episode.title}
      description={campaignEpisode.episode.description}
      Image={() => (
        <EpisodeImage
          episode={campaignEpisode.episode}
          feed={feed}
          verified={user.isAdmin && campaignEpisode.verified}
          width={150}
        />
      )}
      breadcrumbs={[
        {
          to: createOrgPath(organization, '/measure/campaigns'),
          title: 'Campaigns',
        },
        {
          to: toCampaignUrl(organization, campaign),
          title: `${campaign.name}`,
        },
        {
          to: campaignPodcastURL(organization, campaign, campaignPodcast),
          title: `${campaignPodcast.feed.title}`,
        },
        {
          title: 'Episode',
        },
      ]}
      moreDropdownItems={[
        ...(user && user.isAdmin
          ? [
              {content: <DropdownMenuDivider />},
              {
                content: (
                  <DropdownMenuTitle>
                    Only visible to Ad Analytics members:
                  </DropdownMenuTitle>
                ),
              },
              {to: `${url}/consistency`, title: 'Data Consistency'},
            ]
          : []),
      ]}
      moreDropdownOverlayProps={{
        horizontalAlign: 'right',
      }}
    />
  );
};

export {campaignEpisodeURL};

export default CampaignEpisodeNav;
