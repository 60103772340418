import {Card, PageTitle} from '@core/ui/Content';
import PodcastsTable from '@analytics/components/podcasts/PodcastsTable';
import {PodcastsRouteProps} from '.';

const Podcasts = ({organization}: PodcastsRouteProps) => {
  return (
    <>
      <PageTitle>Podcasts</PageTitle>
      <Card>
        <PodcastsTable organization={organization} />
      </Card>
    </>
  );
};

export default Podcasts;
