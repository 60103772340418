import {useState} from 'react';
import styled from 'styled-components';
import {createOrgPath} from '@core/lib/organizations';
import {RouteType} from '@core/lib/useRoutes';
import {Icon, IconName} from '@core/ui/Icon';
import {CleanLink} from '@core/ui/Link';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';

export interface MainNavItem {
  activeIcon: IconName;
  disabledText?: string;
  href?: string;
  icon: IconName;
  isDisabled?: boolean;
  label: string;
  path?: string;
  routes?: RouteType[];
}

type Props = MainNavItem & {
  currentPath: string;
  organization: FetchedOrganization | FetchedBuySideOrganization;
};

export default function LayoutLeftNavItem({
  activeIcon,
  currentPath,
  href,
  icon,
  isDisabled = false,
  label,
  organization,
  path,
  routes,
}: Props) {
  const hasSubmenu = Array.isArray(routes) && routes.length > 0;
  const isSubmenuRouteActive =
    hasSubmenu &&
    routes.some(({to}) => to && window.location.pathname.indexOf(to) === 0);
  const [submenuOpened, setSubmenuOpened] =
    useState<boolean>(isSubmenuRouteActive);
  const isActive = hasSubmenu
    ? false
    : path === '/'
    ? !currentPath
    : !!path && currentPath.indexOf(path) === 0;

  return (
    <>
      <_NavItem
        as={href ? 'a' : CleanLink}
        key={path}
        {...(hasSubmenu
          ? {
              to: '#',
            }
          : path
          ? {to: createOrgPath(organization, path)}
          : null)}
        {...(href && {href})}
        $submenuOpened={submenuOpened}
        $isDisabled={isDisabled}
        onClick={(evt) => {
          if (hasSubmenu) {
            evt.preventDefault();
            setSubmenuOpened(!submenuOpened);
          }
        }}>
        <_NavItemContent
          $isActive={isActive}
          $isSubmenuRouteActive={isSubmenuRouteActive}
          $isDisabled={isDisabled}>
          <Icon alt={label} icon={isActive ? activeIcon : icon} />
          <_NavItemLabel>{label}</_NavItemLabel>
          {hasSubmenu && (
            <_ChevronDownIcon
              icon={'chevron-down'}
              $submenuOpened={submenuOpened}
            />
          )}
        </_NavItemContent>
      </_NavItem>
      {hasSubmenu && submenuOpened ? (
        <div
          css={`
            margin: 0 0 var(--spacing-2) 0;
          `}>
          {routes.map(({title, to}) => (
            <CleanLink
              key={to}
              to={to}
              css={`
                border-radius: 4rem;
                display: inline-flex;
                margin-left: 2.5rem;
                padding: 0.5625rem 0.75rem;
                transition: color 0.15s linear;
                color: ${window.location.pathname.indexOf(to as string) === 0
                  ? 'var(--blue)'
                  : 'var(--text-muted)'};
                :hover {
                  color: var(--blue);
                  text-decoration: none;
                }
              `}>
              {title}
            </CleanLink>
          ))}
        </div>
      ) : null}
    </>
  );
}

const _NavItem = styled.div<{
  $submenuOpened: boolean;
  $isDisabled: boolean;
}>`
  display: flex;
  padding: ${(p) =>
    p.$submenuOpened ? '0.4375rem 0 0.125rem' : '0.4375rem 0'};
  pointer-events: ${(p) => (p.$isDisabled ? 'none' : '')};
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  :hover > div {
    background: var(--gray-1);
    color: var(--blue);
  }
  :active > div {
    background: var(--gray-3);
  }
`;

const _NavItemContent = styled.div<{
  $isDisabled: boolean;
  $isSubmenuRouteActive: boolean;
  $isActive: boolean;
}>`
  align-items: center;
  background: ${(p) => (p.$isSubmenuRouteActive ? 'var(--gray-2)' : '')};
  border-radius: 0.375rem;
  color: ${(p) =>
    p.$isDisabled
      ? 'var(--text-muted)'
      : p.$isActive || p.$isSubmenuRouteActive
      ? 'var(--blue)'
      : 'var(--text-muted)'};
  display: flex;
  height: 2.75rem;
  line-height: 1;
  padding: 0 0.75rem 0.0625rem;
  transition: background 0.2s linear;
`;

const _NavItemLabel = styled.div`
  flex: 1;
  font-size: 1rem;
  margin: 0 1rem;
`;

const _ChevronDownIcon = styled(Icon)<{$submenuOpened: boolean}>`
  transform: ${(p) => (p.$submenuOpened ? 'rotate(-180deg)' : '')};
  transition: transform 0.2s ease;
`;
