import dayjs from '@core/lib/dayjs';
import {distributedCopy} from '@core/lib/utils';

export type CampaignOmniChartInterval = 'hourly' | 'daily' | 'weekly';

export const INTERVALS: {title: string; value: CampaignOmniChartInterval}[] = [
  {title: 'Hourly', value: 'hourly'},
  {title: 'Daily', value: 'daily'},
  {title: 'Weekly', value: 'weekly'},
];

export const getXAxisTicks = (
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
  max = 7
) => {
  const ticks: dayjs.Dayjs[] = [];
  let currentDate = startDate.clone();
  while (currentDate.isBefore(endDate)) {
    ticks.push(currentDate);
    currentDate = currentDate.clone().add(1, 'd');
  }
  const diff = endDate.diff(startDate, 'days') + 1;
  return distributedCopy(ticks, diff < max ? diff : max);
};

export const mergeSuperCampaignData = (campaigns: TSFixMe) => {
  return campaigns.reduce(
    (acc, curr) => ({
      attribution:
        acc.attribution || curr.attribution
          ? {
              users: {
                hourlyVisitors: [
                  ...(acc?.attribution?.users?.hourlyVisitors ?? []),
                  ...(curr?.attribution?.users?.hourlyVisitors ?? []),
                ],
              },
            }
          : null,
      campaignPodcasts: [
        ...acc.campaignPodcasts,
        ...(curr.campaignPodcasts ?? []),
      ],
      downloads: {
        hourly: [...acc.downloads.hourly, ...(curr.downloads?.hourly ?? [])],
      },
    }),
    {
      attribution: null,
      campaignPodcasts: [],
      downloads: {
        hourly: [],
      },
    }
  );
};
