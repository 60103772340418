import {gql} from '@apollo/client';

export default gql`
  fragment Asset on AssetObject {
    id
    name
    contentType
    width
    height
    size
    src
  }
`;
