import React from 'react';
import dayjs from '@core/lib/dayjs';
import {Element} from '@core/style';
import {BLUE} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {Link} from '@core/ui/Link';
import {companyURL} from '@analytics/components/nav/ResearchNav';

const fixFirstPublished = (companies) =>
  companies.map((company) => {
    company.adFirstPublished = dayjs(company.adFirstPublished).toDate();
    return company;
  });

const CompaniesTable = ({
  organization,
  companies,
  feed,
  orderBy,
  onClickRowPath,
}) => {
  const data = fixFirstPublished(companies);

  const columns = [
    {
      title: 'Company',
      accessor: 'name',
      type: 'string',
      rule: ({theme}) => ({fontWeight: 500, color: theme.textPrimary}),
    },
    {
      title: 'Ads Count',
      accessor: 'adsCount',
      type: 'number',
    },
  ];

  return (
    <DataTable
      searchKeys={['name']}
      columns={columns}
      data={data}
      orderBy={orderBy ? orderBy : '-adsCount'}
      onClickRowPath={(data) => {
        if (onClickRowPath) {
          return onClickRowPath(organization, data);
        }

        let to = companyURL(organization, data);

        if (feed) {
          to += `/podcasts/${feed.id}`;
        }

        return to;
      }}
    />
  );
};

const CompanySimilarByFeedTable = ({
  company,
  organization,
  companies,
  orderBy,
}) => {
  const columns = [
    {
      title: 'Company',
      accessor: 'name',
      type: 'string',
      Cell: ({data}) => {
        return (
          <Link
            to={companyURL(organization, data)}
            css={`
              color: inherit;
              font-weight: 500;
              text-decoration: none;
              :hover {
                color: var(--blue);
              }
            `}>
            {data.name}
          </Link>
        );
      },
    },
    {
      title: 'Feeds Count',
      accessor: 'feedsCount',
      type: 'number',
    },
    {
      title: '',
      accessor: 'id',
      type: 'string',
      Cell: ({data}) => {
        return (
          <Element rules={() => ({textAlign: 'right'})}>
            <Link
              to={`${companyURL(organization, company)}/similar/${data.id}`}
              color='link'>
              Compare
            </Link>
          </Element>
        );
      },
    },
  ];

  return (
    <DataTable
      searchPlaceholder='Search company'
      searchKeys={['name']}
      columns={columns}
      data={companies}
      orderBy={orderBy ? orderBy : '-feedsCount'}
    />
  );
};

const CompanySimilarByEpisodeTable = ({
  company,
  organization,
  companies,
  orderBy,
}) => {
  const columns = [
    {
      title: 'Company',
      accessor: 'text',
      type: 'string',
      Cell: ({data}) => {
        return (
          <Link
            to={companyURL(organization, company)}
            css={`
              color: var(--text-default);
              font-weight: 500;
              text-decoration: none;
              :hover {
                color: var(--blue);
              }
            `}>
            {data.name}
          </Link>
        );
      },
    },
    {
      title: 'Episodes Count',
      accessor: 'episodesCount',
      type: 'number',
    },
    {
      title: '',
      accessor: 'id',
      type: 'string',
      Cell: ({data}) => {
        return (
          <Element rules={() => ({textAlign: 'right'})}>
            <Link
              to={`${companyURL(organization, company)}/similar/${data.id}`}
              color='link'>
              Compare
            </Link>
          </Element>
        );
      },
    },
  ];

  return (
    <DataTable
      searchPlaceholder='Search company'
      searchKeys={['name']}
      columns={columns}
      data={companies}
      orderBy={orderBy ? orderBy : '-episodesCount'}
    />
  );
};

export {CompanySimilarByFeedTable, CompanySimilarByEpisodeTable};

export default CompaniesTable;
