import {CardHeader} from '@core/ui/Content';
import {Container} from '@core/ui/Layout';
import {LiftReportForm} from '@analytics/components/lift/IncrementalBrandLift';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import {CampaignLiftRouteProps, campaignLiftURL} from './_routes';

export function CampaignRequestLift({
  organization,
  campaign,
  user,
}: CampaignLiftRouteProps) {
  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <Container maxWidth='md'>
        <CardHeader backPath={campaignLiftURL(organization, campaign)}>
          New Lift Report
        </CardHeader>
        <div
          css={`
            margin: 0 var(--spacing-6);
          `}>
          <LiftReportForm organization={organization} campaign={campaign} />
        </div>
      </Container>
    </>
  );
}
