import {Switch} from 'react-router-dom';
import {CampaignsRouteProps} from '../../_routes';
import {BrandSelectCampaignType} from './Step1_SelectCampaignType';
import {BrandSelectPublisherDetails} from './Step2_PublisherDetails';
import {BrandCampaignDetails} from './Step3_CampaignDetails';
import {getBrandCreateCampaignRoutes} from './_routes';

export function BrandCampaignCreateIndex({
  user,
  organization,
}: CampaignsRouteProps) {
  const {renderRoutes} = getBrandCreateCampaignRoutes({organization});
  return (
    <Switch>
      {renderRoutes({
        components: {
          campaignType: BrandSelectCampaignType,
          pubDetails: BrandSelectPublisherDetails,
          campaignDetails: BrandCampaignDetails,
        },
        extraProps: {
          organization,
          user,
        },
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
}
