import EmptyMessage from '@core/ui/EmptyMessage';

export function NoOptions({
  queryImmediately,
  searchValue,
}: {
  queryImmediately: boolean;
  searchValue: string;
}) {
  const searchable = !queryImmediately && !searchValue;

  return (
    <EmptyMessage rules={() => ({padding: '2rem 1.25rem 2rem'})}>
      {searchable ? 'Search to find new columns to add.' : 'No results found.'}
    </EmptyMessage>
  );
}
