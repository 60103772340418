import {createOrgPath} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {withInsightsImpressionsQuery} from '@analytics/components/insights/InsightsImpressionsQueryWrapper';
import {MeasureInsightsRouteProps} from '..';
import {InsightsLocationsCountry} from './InsightsLocationsCountry';
import {InsightsLocationsOverview} from './InsightsLocationsOverview';
import {InsightsLocationsRegion} from './InsightsLocationsRegion';

export type MeasureInsightsLocationsRouteProps = MeasureInsightsRouteProps & {
  campaigns: any[];
  publishers: any[];
};

const InsightsLocationsIndex = ({
  after,
  before,
  campaigns,
  organization,
  publishers,
}: MeasureInsightsLocationsRouteProps) => {
  const {renderRoutes} = useRoutes({
    baseUrl: createOrgPath(organization, '/measure/overview/locations'),
    basePath: '/:part(b|a|s|p)/:slug/measure/overview/locations',
    routes: [
      {
        key: 'overview',
        path: '',
        exact: true,
      },
      {
        key: 'country',
        path: ':country',
        exact: true,
      },
      {
        key: 'region',
        path: ':country/:region',
        exact: true,
      },
    ],
  });

  return (
    <>
      {renderRoutes({
        components: {
          overview: InsightsLocationsOverview,
          country: InsightsLocationsCountry,
          region: InsightsLocationsRegion,
        },
        extraProps: {
          organization,
          after,
          before,
          campaigns,
          publishers,
        },
        /* redirects: [{ from: '*', to: '' }], */
      })}
    </>
  );
};

export default withInsightsImpressionsQuery(InsightsLocationsIndex, {
  withCampaigns: true,
  withPublishers: true,
});
