function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

import { objectReduce } from 'fast-loops';
import getNodeSibling from './getNodeSibling';
export default function createNode(attributes) {
  var targetDocument = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;
  var sortMediaQuery = arguments.length > 2 ? arguments[2] : undefined;
  var styleNodeAttributes = arguments.length > 3 ? arguments[3] : undefined;
  var head = targetDocument.head || {};
  var type = attributes.type,
      media = attributes.media,
      support = attributes.support;
  var node = targetDocument.createElement('style');
  node.setAttribute('data-fela-type', type);
  node.type = 'text/css';

  if (support) {
    node.setAttribute('data-fela-support', 'true');
  }

  if (media) {
    node.media = media;
  } // applying custom style tag attributes


  for (var attribute in styleNodeAttributes) {
    node.setAttribute(attribute, styleNodeAttributes[attribute]);
  } // also apply attributes set globally with window.FelaConfig


  if ((typeof window === "undefined" ? "undefined" : _typeof(window)) !== undefined && window.FelaConfig) {
    for (var _attribute in window.FelaConfig.styleNodeAttributes) {
      node.setAttribute(_attribute, window.FelaConfig.styleNodeAttributes[_attribute]);
    }
  }

  var nodes = head.querySelectorAll('[data-fela-type]');
  var sibling = getNodeSibling(_toConsumableArray(nodes), attributes, sortMediaQuery);

  if (sibling) {
    head.insertBefore(node, sibling);
  } else {
    head.appendChild(node);
  }

  return node;
}