import {FC, useMemo} from 'react';
import dayjs from '@core/lib/dayjs';
import DataTable, {
  DataTableColumn,
  MultipleSelectFilter,
} from '@core/ui/DataTable';
import {FetchedCampaign} from '@analytics/graphql-api';
import ApprovalsStateBadge from './ApprovalsStateBadge';

const _toName = (by: {firstName: string; lastName: string}) =>
  by ? `${by.firstName} ${by.lastName}` : '';

type ApprovalsTableProps = {
  campaign: FetchedCampaign;
  dynamicApprovals: any[];
  podcastApprovals: any[];
  streamingApprovals: any[];
};

const ApprovalsTable: FC<ApprovalsTableProps> = ({
  campaign,
  dynamicApprovals,
  podcastApprovals,
  streamingApprovals,
}): JSX.Element => {
  const approvals = (dynamicApprovals as any[])
    .concat(podcastApprovals)
    .concat(streamingApprovals);

  const columns: DataTableColumn[] = useMemo(
    () => [
      {
        title: 'Name',
        accessor: 'adName',
        type: 'string',
      },
      {
        title: 'State',
        accessor: 'state',
        type: 'string',
        Cell: ({d}) => <ApprovalsStateBadge state={d.state} />,
      },
      {
        title: 'Rejected Reason',
        accessor: 'rejectedReason',
        type: 'string',
      },
      {
        title: 'Comment',
        accessor: 'comment',
        type: 'string',
      },
      {
        title: 'Updated At',
        accessor: 'updatedAt',
        type: 'date',
        fmt: (v) => dayjs(v).format('MM/DD/YY'),
      },
      {
        title: 'Requestee',
        accessor: 'requesteeName',
        type: 'string',
        Filter: MultipleSelectFilter,
        Cell: ({d}) => (
          <div>
            <span>{d.requesteeName}</span>
            <div css={{color: 'var(--text-muted)'}}>
              {d.requesteeOrganizationName}
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      approvals
        .map((d) => {
          const ad = d.campaignDynamic
            ? campaign.campaignDynamics.find(
                ({id}) => d.campaignDynamic.id === id
              )
            : d.campaignPodcast
            ? (campaign.campaignPodcasts ?? []).find(
                (cp) => d.campaignPodcast.id === cp?.id
              )
            : campaign.campaignStreamings.find(
                ({id}) => d.campaignStreaming.id === id
              );

          if (ad) {
            return {
              requestedByName: _toName(d.requestedBy),
              requestedByOrganizationName: d.requestedByOrganization.name,
              requesteeName: _toName(d.requestee),
              requesteeOrganizationName: d.requesteeOrganization.name,
              approvedByName: _toName(d.approvedBy),
              rejectedByName: _toName(d.rejectedBy),
              adName: ad.name,
              ad,
              ...d,
            };
          }
          return null;
        })
        .filter((val) => !!val),
    []
  );

  return (
    <DataTable
      emptyMessageText='Currently no confirmations have been requested.'
      columns={columns}
      data={data}
      orderBy='-updatedAt'
    />
  );
};

export default ApprovalsTable;
