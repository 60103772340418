import Button from '@core/ui/Button';
import {Stack} from '@core/ui/Layout';
import {LoadingSpinner} from '@core/ui/Loading';
import {useOverlay} from '@core/ui/Overlay';
import {Snackbar} from '@core/ui/Snackbar';
import {SnackbarProps} from '@core/ui/Snackbar/Snackbar';
import {Text} from '@core/ui/Text';
import {Tooltip} from '@core/ui/Tooltip';
import {toReportsUrl} from '@analytics/components/nav/ReportsNav';
import {
  ExportJobStatusStatus,
  FetchedBuySideOrganization,
  FetchedOrganization,
  SavedExportObject,
} from '@analytics/graphql-api';
import {useExportsContext} from './ExportsNotificationContext';

interface NotificationsExportSnackbarProps
  extends Omit<SnackbarProps, 'opened' | 'toggle'> {
  title: string;
  subtitle: string;
  success?: boolean;
  downloadUrl?: string | false;
  onClick?: () => void;
  organization: FetchedOrganization | FetchedBuySideOrganization;
}

const NotificationsExportSnackbar = ({
  title,
  subtitle,
  success,
  downloadUrl,
  onClick,
  organization,
  ...props
}: NotificationsExportSnackbarProps) => {
  const [opened, toggle] = useOverlay(true);

  const _onClick = () => {
    onClick?.();
    toggle(false);
  };

  return (
    <div role='button' tabIndex={0} onClick={_onClick} onKeyDown={onClick}>
      <Snackbar
        opened={opened}
        toggle={toggle}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        severity={
          success !== undefined ? (success ? 'success' : 'error') : 'info'
        }
        {...props}>
        <Stack
          gap='var(--spacing-4)'
          css={{alignItems: 'center', marginLeft: 'var(--spacing-3)'}}>
          <Stack direction='column'>
            <Text variant='subtitle1' color='tooltip'>
              {title}
            </Text>
            <Text variant='body3' color='tooltip'>
              {subtitle}
            </Text>
          </Stack>
          <Stack gap='var(--spacing-2)'>
            {downloadUrl && (
              <Tooltip description='Download'>
                <Button
                  iconLeading='reports'
                  color='text-success'
                  buttonSize='sm'
                  href={downloadUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                />
              </Tooltip>
            )}
            {success !== undefined && (
              <Button
                color={success ? 'text-success' : 'text-error'}
                buttonSize='sm'
                to={toReportsUrl(organization)}>
                Go to Reports
              </Button>
            )}
          </Stack>
        </Stack>
      </Snackbar>
    </div>
  );
};

type Props = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  completedExports: SavedExportObject[];
};

export const ExportsNotification = ({
  completedExports,
  organization,
}: Props) => {
  const {pollExports, removeExport} = useExportsContext();

  const _pollExports = pollExports.filter(
    (exportId) => !completedExports.find(({id}) => exportId === id)
  );

  return (
    <>
      {_pollExports.map((id) => (
        <NotificationsExportSnackbar
          key={id}
          customImage={<LoadingSpinner size='small' />}
          organization={organization}
          title='Generating report...'
          subtitle='This may take a few moments. We will alert you when your report is ready.'
        />
      ))}
      {completedExports.map((savedExport) => {
        const onClick = () => removeExport(savedExport.id as ExportId);

        const success =
          savedExport.lastExportInstance?.exportJobStatus?.status ===
          ExportJobStatusStatus.Completed;

        const downloadUrl =
          success && (savedExport.lastExportInstance?.downloadUrl ?? '');

        return (
          <NotificationsExportSnackbar
            key={savedExport.id}
            downloadUrl={downloadUrl}
            organization={organization}
            onClick={onClick}
            title={
              success
                ? 'Report created successfully'
                : 'There was an error generating your report'
            }
            subtitle={`${savedExport.name} ${success ? 'is ready' : 'failed'}`}
            success={success}
          />
        );
      })}
    </>
  );
};
