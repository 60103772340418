import {createOrgPath} from '@core/lib/organizations';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {CampaignUpdateForm} from '@analytics/components/campaigns/forms';
import {CampaignRouteProps} from './_routes';

export function CampaignUpdate({organization, campaign}: CampaignRouteProps) {
  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader
        backPath={createOrgPath(organization, `/campaigns/${campaign.slug}`)}>
        Edit Campaign
      </CardHeader>
      <Card>
        <CampaignUpdateForm campaign={campaign} organization={organization} />
      </Card>
    </ResponsiveContent>
  );
}
