import {
  createOrgPath,
  isOrgAgency,
  isSelling,
  organizationCanViewBrandData,
} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {InsightsNavTitle} from '@analytics/components/audience-insights';
import {canViewAudienceInsights} from '@analytics/components/audience-insights/utils';
import {isCampaignValidForLift} from '@analytics/components/lift/IncrementalBrandLift';
import {
  CampaignState,
  FetchedCampaign,
  OrganizationKind,
} from '@analytics/graphql-api';
import {canUpdateCampaign} from '@analytics/lib/campaigns';
import {CampaignsRouteProps} from '../_routes';

export type CampaignRouteProps = CampaignsRouteProps & {
  campaign: FetchedCampaign;
};

export const toCampaignUrl = (
  {
    kind,
    slug,
  }: {
    kind: OrganizationKind;
    slug: string;
  },
  {slug: campaignSlug}: {slug: string}
) => createOrgPath({kind, slug}, `/campaigns/${campaignSlug}`);

export const useCampaignRoutes = ({
  user,
  campaign,
  organization,
}: CampaignRouteProps) => {
  const canViewBrandData = organizationCanViewBrandData(organization, campaign);
  const isAgency = isOrgAgency(organization);
  const publisher = campaign.organization;
  const advertiser = campaign.advertiser?.organization;
  const noDraft = campaign.state !== CampaignState.Draft;
  const noReady = campaign.state !== CampaignState.Ready;

  return useRoutes({
    baseUrl: createOrgPath(organization, `/campaigns/${campaign.slug}`),
    basePath: '/:part(b|a|s|p)/:slug/campaigns/:campaignSlug',
    routes: [
      {
        key: 'overview',
        title: 'Overview',
        path: '',
        exact: true,
      },
      {
        key: 'insights',
        title: <InsightsNavTitle />,
        isAvailable: canViewAudienceInsights({campaign}),
      },
      {
        key: 'revenue',
        title: 'Revenue',
        isAvailable:
          noDraft &&
          noReady &&
          canViewBrandData &&
          (campaign.purchaseTotal || 0) > 0,
      },
      {
        key: 'pixels',
        title: 'Conversions',
        isAvailable:
          noDraft &&
          noReady &&
          canViewBrandData &&
          campaign.kind === 'attribution' &&
          process.env.API_ENV !== 'demo',
      },
      {
        key: 'overlap',
        title: 'Overlap',
        isAvailable:
          noDraft &&
          noReady &&
          (campaign.campaignDynamics.length > 1 ||
            (campaign.campaignPodcasts || []).length > 1 ||
            (campaign.campaignStreamings || []).length > 1),
      },
      {
        key: 'placements',
        title: 'Ad Placements',
        isAvailable:
          noDraft &&
          noReady &&
          (campaign.campaignDynamics.length > 0 ||
            (campaign.campaignPodcasts || []).length > 0 ||
            campaign.campaignStreamings.length > 0),
      },
      {
        key: 'dynamics',
        title: 'Dynamic Ads',
        isAvailable: campaign.campaignDynamics.length > 0,
      },
      {
        key: 'locations',
        title: 'Locations',
        isAvailable: noDraft && noReady,
      },
      {
        key: 'players',
        title: 'Players',
        isAvailable: noDraft && noReady,
      },
      {
        key: 'frequency',
        title: 'Frequency',
        exact: true,
        isAvailable: noDraft && noReady,
      },
      {
        key: 'lift',
        title: 'Lift',
        isAvailable:
          noDraft &&
          noReady &&
          isCampaignValidForLift(campaign) &&
          !isAgency &&
          process.env.API_ENV !== 'demo',
      },
      /*      {
        key: 'links',
        title: 'pod.link',
        isAvailable:
          campaign.kind === 'marketing' ||
          (!!advertiser && publisher?.id === advertiser.id),
      }, */
      {
        key: 'update',
        title: 'Edit Campaign',
        exact: true,
        isAvailable: canUpdateCampaign({organization, campaign}),
      },
      {
        key: 'bulk',
        title: 'Edit Ads',
        exact: true,
        isAvailable: canUpdateCampaign({organization, campaign}),
      },
      {
        key: 'permissions',
        title: 'Permissions',
        exact: true,
        isAvailable: isSelling(organization),
      },
      {
        key: 'delete',
        title: 'Delete',
        exact: true,
        isAvailable:
          (campaign.state === CampaignState.Draft ||
            campaign.state === CampaignState.Ready ||
            campaign.state === CampaignState.Rejected) &&
          isSelling(organization),
      },
      {
        key: 'podcasts',
      },
      {
        key: 'ingest',
      },
      {
        key: 'approvals',
        title: 'Approvals',
        isAvailable:
          isSelling(organization) ||
          ('isManager' in organization && organization.isManager),
      },
      {
        key: 'targets',
      },
      {
        key: 'debug',
        exact: true,
        isAvailable: user && user.isAdmin,
      },
      {
        key: 'state',
        exact: true,
      },
      {
        key: 'streaming',
        title: 'Streaming',
        path: 'streaming/:campaignStreamingId',
        isAvailable: campaign.campaignStreamings.length > 0,
      },
      {
        key: 'activity',
        title: 'Activity Log',
        exact: true,
      },
    ],
  });
};
