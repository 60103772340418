"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InternSet = exports.InternMap = void 0;

class InternMap extends Map {
  constructor(entries, key = keyof) {
    super();
    Object.defineProperties(this, {
      _intern: {
        value: new Map()
      },
      _key: {
        value: key
      }
    });
    if (entries != null) for (const [key, value] of entries) this.set(key, value);
  }

  get(key) {
    return super.get(intern_get(this, key));
  }

  has(key) {
    return super.has(intern_get(this, key));
  }

  set(key, value) {
    return super.set(intern_set(this, key), value);
  }

  delete(key) {
    return super.delete(intern_delete(this, key));
  }

}

exports.InternMap = InternMap;

class InternSet extends Set {
  constructor(values, key = keyof) {
    super();
    Object.defineProperties(this, {
      _intern: {
        value: new Map()
      },
      _key: {
        value: key
      }
    });
    if (values != null) for (const value of values) this.add(value);
  }

  has(value) {
    return super.has(intern_get(this, value));
  }

  add(value) {
    return super.add(intern_set(this, value));
  }

  delete(value) {
    return super.delete(intern_delete(this, value));
  }

}

exports.InternSet = InternSet;

function intern_get({
  _intern,
  _key
}, value) {
  const key = _key(value);

  return _intern.has(key) ? _intern.get(key) : value;
}

function intern_set({
  _intern,
  _key
}, value) {
  const key = _key(value);

  if (_intern.has(key)) return _intern.get(key);

  _intern.set(key, value);

  return value;
}

function intern_delete({
  _intern,
  _key
}, value) {
  const key = _key(value);

  if (_intern.has(key)) {
    value = _intern.get(key);

    _intern.delete(key);
  }

  return value;
}

function keyof(value) {
  return value !== null && typeof value === "object" ? value.valueOf() : value;
}