import {gql} from '@apollo/client';
import PodcastFragment from '../fragments/PodcastFragment';
import PodlinkDomainFragment from '../fragments/PodlinkDomainFragment';

export default gql`
  query getPodlinkDomains($organizationId: ID!) {
    me {
      id
      organization(id: $organizationId) {
        id
        podlinkDomains {
          ...PodlinkDomain
        }
        podcasts {
          ...Podcast
        }
      }
    }
  }
  ${PodlinkDomainFragment}
  ${PodcastFragment}
`;
