import {Dispatch, SetStateAction} from 'react';
import {gtag} from '@core/lib/gtag';
import {SearchInput} from '@core/ui/FormElements';
import {Icon} from '@core/ui/Icon';
import {OrganizationObject} from '@analytics/graphql-api';
import AdvisorColumnsDropdownColumnsList from '../AdvisorColumnsDropdownColumnList';
import {columnGroupsConfig} from '../columns';
import {AdvisorColumn} from '../types';
import {SIDEBAR_NAV_ITEMS, getColumnGroupId} from './utils';

type Props = {
  organization: OrganizationObject;
  currentActiveColumns: AdvisorColumn[];
  setCurrentActiveColumns: Dispatch<SetStateAction<AdvisorColumn[]>>;
  selectedColumnGroup: (typeof SIDEBAR_NAV_ITEMS)[number];
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
};

export function RightColumn({
  organization,
  currentActiveColumns,
  setCurrentActiveColumns,
  selectedColumnGroup,
  searchValue,
  setSearchValue,
}: Props) {
  return (
    <div css={{width: '25rem'}}>
      <div
        css={{
          margin: '1rem 1rem 0.25rem',
          position: 'relative',
        }}>
        <SearchInput
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onInput={({target}) => setSearchValue(target.value.trim())}
          small
          value={searchValue}
          placeholder={
            columnGroupsConfig.find(
              ({columnGroupId}) =>
                columnGroupId === selectedColumnGroup.columnGroupId
            )?.searchPlaceholder || 'Search'
          }
          inputRules={() => ({
            background: 'var(--bg-overlay)',
            fontSize: '0.875rem',
            width: '100%',
          })}
        />
        {searchValue && (
          <Icon
            icon='close'
            onClick={() => {
              setSearchValue('');
            }}
            rules={() => ({
              borderRadius: '5rem',
              bottom: 0,
              color: 'var(--icon-subtle)',
              curspor: 'pointer',
              height: '1.125rem',
              margin: 'auto',
              padding: '0.0625rem',
              position: 'absolute',
              right: '0.625rem',
              top: '0',
              width: '1.125rem',
              ':hover': {
                background: 'var(--icon-subtle)',
                color: 'var(--bg-default)',
              },
            })}
          />
        )}
      </div>
      <AdvisorColumnsDropdownColumnsList
        key={selectedColumnGroup.columnGroupId}
        organization={organization}
        defaultActiveColumns={
          selectedColumnGroup.columnGroupId
            ? currentActiveColumns.filter(
                ({id}) =>
                  selectedColumnGroup.columnGroupId === getColumnGroupId(id)
              )
            : [...currentActiveColumns]
        }
        searchValue={searchValue}
        columnGroupName={selectedColumnGroup.columnGroupName}
        queryImmediately={
          ('queryImmediately' in selectedColumnGroup &&
            selectedColumnGroup.queryImmediately) ||
          !selectedColumnGroup.columnGroupId
        }
        sortable={!selectedColumnGroup.columnGroupName}
        onChange={(columns) => {
          if (!selectedColumnGroup.columnGroupId) {
            setCurrentActiveColumns([...columns]);
            return;
          }

          const newColumns = columns.filter(
            ({id}) =>
              !currentActiveColumns.find(
                ({id: activeColumnId}) => activeColumnId === id
              )
          );
          setCurrentActiveColumns(
            currentActiveColumns
              .filter(
                ({id: activeColumnId}) =>
                  getColumnGroupId(activeColumnId) !==
                    selectedColumnGroup.columnGroupId ||
                  !!columns.find(({id}) => activeColumnId === id)
              )
              .concat(newColumns)
          );
          gtag('event', 'add_columns', {
            event_category: 'advisor',
            event_label: newColumns.map((c) => c.id).join(','),
          });
        }}
      />
    </div>
  );
}
