import {gql} from '@apollo/client';
import CampaignPodcastFragment from '@analytics/graphql-api/_old_queries/fragments/CampaignPodcastFragment';

export default gql`
  mutation checkCampaignPodcastPrefix(
    $input: CheckCampaignPodcastPrefixInput!
  ) {
    authed {
      success
    }
    checkCampaignPodcastPrefix(input: $input) {
      campaignPodcast {
        ...CampaignPodcast
      }
    }
  }
  ${CampaignPodcastFragment}
`;
