import {gql} from '@apollo/client';

export default gql`
  query getMembers($id: ID!) {
    me {
      id
      organization(id: $id) {
        id
        isAdmin
        domains {
          id
          domain
          createdAt
        }
        memberships {
          id
          role
          roleDisplay
          createdAt
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;
