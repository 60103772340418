import {gql} from '@apollo/client';

export default gql`
  query getCampaignDownloadRegionLocations(
    $organizationId: ID!
    $id: ID!
    $country: String
    $region: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $id) {
          id
          downloads {
            locations(country: $country, region: $region) {
              count
              nodes(limit: 2000) {
                country
                region
                city
                latitude
                longitude
                total
              }
              cities(limit: 200) {
                country
                region
                city
                total
              }
            }
          }
        }
      }
    }
  }
`;
