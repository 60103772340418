/**
 * Encore Foundation - 5.32.2
 *
 * The contents of this file is owned and generated by the Encore team.
 * If you need to make changes to the values stored here please reach out
 * to #encore on Slack.
 *
 * Links:
 *
 * - Source & Documentation: https://ghe.spotify.net/encore/foundation
 *
 * Tack!
 *
 * Encore
 * encore.spotify.net
 */
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Aquamarine 180
 */
export var aquamarine = '#9bf0e1';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Aubergine 46
 */
export var aubergine = '#503750';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Azure 135
 */
export var azure = '#509bf5';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Bole 123
 */
export var bole = '#c87d55';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Bright Red 100
 */
export var brightRed = '#e61e32';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Chocolate 75
 */
export var chocolate = '#7d4b32';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Citric 186
 */
export var citric = '#cdf564';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Electric Seafoam 167
 */
export var electricSeafoam = '#19e68c';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Factory Yellow 184
 */
export var factoryYellow = '#fae62d';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Forest 75
 */
export var forest = '#006450';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Pink 100
 */
export var fuchsia = '#dc148c';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Gold 155
 */
export var gold = '#f59b23';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Klein Blue 61
 */
export var kleinBlue = '#4100f5';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Lavender 135
 */
export var lavender = '#b49bc8';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Maroon 55
 */
export var maroon = '#8c1932';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Midnight 35
 */
export var midnight = '#1e3264';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Neon Green 176
 */
export var neonGreen = '#5ff550';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Pumpkin 135
 */
export var orange = '#ff6437';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Rose 180
 */
export var pink = '#ffcdd2';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Violet Desat 75
 */
export var plum = '#705378';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Powder Green 185
 */
export var powderGreen = '#c3f0c8';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Royal Blue 55
 */
export var royalBlue = '#2d46b9';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Salmon 135
 */
export var salmon = '#f573a0';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Green Blue 100
 */
export var spearmint = '#27856a';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Storm 155
 */
export var storm = '#a0c3d2';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Sunflower 172
 */
export var sunflower = '#ffc864';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Tan 135
 */
export var tan = '#c39687';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Red Orange 100
 */
export var tangerine = '#e3230e';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated, consider moving to Violet 84
 */
export var violet = '#af2896';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var gray7 = '#121212';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var gray10 = '#181818';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var gray15 = '#282828';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var gray20 = '#333333';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var gray30 = '#535353';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var gray50 = '#7f7f7f';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var gray70 = '#b3b3b3';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var gray80 = '#cccccc';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var gray85 = '#d9d9d9';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var gray90 = '#eeeeee';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var gray95 = '#f8f8f8';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityBlack0 = 'rgba(0, 0, 0, 0)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityBlack10 = 'rgba(0, 0, 0, 0.1)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityBlack30 = 'rgba(0, 0, 0, 0.3)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityBlack50 = 'rgba(0, 0, 0, 0.5)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityBlack60 = 'rgba(0, 0, 0, 0.6)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityBlack70 = 'rgba(0, 0, 0, 0.7)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityBlack90 = 'rgba(0, 0, 0, 0.9)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityWhite0 = 'rgba(255, 255, 255, 0)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityWhite10 = 'rgba(255, 255, 255, 0.1)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityWhite30 = 'rgba(255, 255, 255, 0.3)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityWhite50 = 'rgba(255, 255, 255, 0.5)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityWhite60 = 'rgba(255, 255, 255, 0.6)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityWhite70 = 'rgba(255, 255, 255, 0.7)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var opacityWhite90 = 'rgba(255, 255, 255, 0.9)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity Black 0
 */
export var black0 = 'rgba(0, 0, 0, 0)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity Black 10
 */
export var black10 = 'rgba(0, 0, 0, 0.1)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity Black 30
 */
export var black30 = 'rgba(0, 0, 0, 0.3)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity Black 50
 */
export var black50 = 'rgba(0, 0, 0, 0.5)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity Black 60
 */
export var black60 = 'rgba(0, 0, 0, 0.6)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity Black 70
 */
export var black70 = 'rgba(0, 0, 0, 0.7)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity Black 90
 */
export var black90 = 'rgba(0, 0, 0, 0.9)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity White 0
 */
export var white0 = 'rgba(255, 255, 255, 0)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity White 10
 */
export var white10 = 'rgba(255, 255, 255, 0.1)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity White 30
 */
export var white30 = 'rgba(255, 255, 255, 0.3)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity White 50
 */
export var white50 = 'rgba(255, 255, 255, 0.5)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity White 60
 */
export var white60 = 'rgba(255, 255, 255, 0.6)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity White 70
 */
export var white70 = 'rgba(255, 255, 255, 0.7)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity White 90
 */
export var white90 = 'rgba(255, 255, 255, 0.9)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to either Opacity Black 10 or Opacity Black 30
 */
export var black20 = 'rgba(0, 0, 0, 0.2)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to either Opacity Black 30 or Opacity Black 50
 */
export var black40 = 'rgba(0, 0, 0, 0.4)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to Opacity White 10
 */
export var white5 = 'rgba(255, 255, 255, 0.05)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to either Opacity White 10 or Opacity White 30
 */
export var white20 = 'rgba(255, 255, 255, 0.2)';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This opacity is deprecated, consider moving to either Opacity White 30 or Opacity White 50
 */
export var white40 = 'rgba(255, 255, 255, 0.4)';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var solidBlack0 = '#000000';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var solidWhite200 = '#ffffff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var spotifyBlack7 = '#121212';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var midnight35 = '#202f72';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var aubergine46 = '#503659';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var maroon55 = '#8a202b';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var royalBlue55 = '#283ea3';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var kleinBlue61 = '#4100f4';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var forest75 = '#056952';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var chocolate75 = '#834f34';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violet84 = '#b02897';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brightRed100 = '#e91429';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var spearmint114 = '#4b917d';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var fuchsia115 = '#f036a4';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var tangerine120 = '#ff4834';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var bole123 = '#c87d55';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var salmon135 = '#ef77a1';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var lavender135 = '#af93c4';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var azure135 = '#59a0f6';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pumpkin135 = '#ff7439';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var tan135 = '#c19384';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var storm155 = '#a5bbd1';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var gold155 = '#ffa42b';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var spotifyGreen157 = '#1ed760';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var sunflower172 = '#ffc862';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var rose180 = '#ffd2d7';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var aquamarine180 = '#91eeda';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var factoryYellow184 = '#fce54f';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var powderGreen185 = '#c5f0c9';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var citric186 = '#cff56a';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var neonGreen176 = '#5ff550';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var electricSeafoam167 = '#19e68c';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redOrange25 = '#560f00';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redOrange55 = '#911600';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redOrange75 = '#b71800';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redOrange100 = '#e81b00';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redOrange120 = '#ff4834';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redOrange135 = '#ff7163';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redOrange155 = '#ff9e95';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redOrange180 = '#ffd3cf';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brown25 = '#3d2418';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brown55 = '#683e2a';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brown75 = '#834f34';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brown100 = '#a96440';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brown123 = '#c87d55';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brown135 = '#d38e67';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brown155 = '#e5ac8a';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brown180 = '#f7d7c3';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownDesat25 = '#3b251d';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownDesat55 = '#653f32';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownDesat75 = '#805040';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownDesat100 = '#a36652';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownDesat135 = '#c19384';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownDesat155 = '#d2b1a6';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownDesat180 = '#eadad5';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var orange25 = '#4f1800';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var orange55 = '#862800';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var orange75 = '#a83200';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var orange100 = '#d64000';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var orange135 = '#ff7439';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var orange155 = '#ffa178';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var orange180 = '#ffd4c2';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellow25 = '#491e00';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellow55 = '#793500';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellow75 = '#964400';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellow100 = '#bc5900';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellow135 = '#f47e00';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellow155 = '#ffa42b';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellow172 = '#ffc862';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellow180 = '#ffd97e';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellowDesat25 = '#39260e';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellowDesat55 = '#624218';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellowDesat75 = '#7c531e';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellowDesat100 = '#9f6b27';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellowDesat135 = '#d0913f';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellowDesat155 = '#deb076';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var brownYellowDesat180 = '#efdabf';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellow25 = '#352901';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellow55 = '#594602';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellow75 = '#705903';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellow100 = '#8e7203';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellow135 = '#bd9a03';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellow155 = '#dab503';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellow184 = '#fce54f';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreen25 = '#222e03';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreen55 = '#3b4f05';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreen75 = '#4a6406';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreen100 = '#5f8008';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreen135 = '#80ac0b';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreen155 = '#95c90d';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreen186 = '#cff56a';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreenDesat25 = '#262d11';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreenDesat55 = '#414d1e';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreenDesat75 = '#526226';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreenDesat100 = '#697d30';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreenDesat135 = '#8da841';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreenDesat155 = '#a9c261';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var yellowGreenDesat180 = '#d7e3b5';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var green25 = '#073116';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var green55 = '#0c5426';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var green75 = '#0f6a2f';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var green100 = '#13873c';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var green135 = '#19b551';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var green157 = '#1ed760';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var green180 = '#96f0b6';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenDesat25 = '#182f21';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenDesat55 = '#265135';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenDesat75 = '#2d6740';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenDesat100 = '#34854d';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenDesat135 = '#3db35e';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenDesat155 = '#5cce77';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenDesat185 = '#c5f0c9';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlue25 = '#013126';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlue55 = '#035341';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlue75 = '#056952';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlue100 = '#088569';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlue135 = '#10b28e';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlue155 = '#16d0a6';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlue180 = '#91eeda';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlueDesat25 = '#182e28';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlueDesat55 = '#294f44';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlueDesat75 = '#346557';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlueDesat100 = '#43816f';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlueDesat114 = '#4b917d';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlueDesat135 = '#5eac96';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlueDesat155 = '#8bc3b3';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var greenBlueDesat180 = '#c9e3db';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blue25 = '#052a56';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blue55 = '#084791';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blue75 = '#0a59b8';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blue100 = '#0d72ea';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blue135 = '#59a0f6';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blue155 = '#89bcf8';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blue180 = '#c8e0fc';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueDesat25 = '#1d2b3a';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueDesat55 = '#324b64';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueDesat75 = '#405e7e';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueDesat100 = '#5179a1';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueDesat135 = '#82a1c0';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueDesat155 = '#a5bbd1';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueDesat180 = '#d4dfe9';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueViolet25 = '#26008d';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueViolet55 = '#3c00e4';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueViolet61 = '#4100f4';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueViolet75 = '#5925ff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueViolet100 = '#7358ff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueViolet135 = '#9691ff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueViolet155 = '#afb1ff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueViolet180 = '#d7dbff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueVioletDesat25 = '#1b2658';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueVioletDesat35 = '#202f72';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueVioletDesat55 = '#283ea3';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueVioletDesat75 = '#334fcd';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueVioletDesat100 = '#576dd6';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueVioletDesat135 = '#8a9ae3';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueVioletDesat155 = '#aab6ea';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var blueVioletDesat180 = '#d7dcf5';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violet25 = '#400073';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violet55 = '#6900ba';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violet75 = '#8400e7';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violet100 = '#a833ff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violet135 = '#c87dff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violet155 = '#d8a3ff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violet180 = '#edd4ff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetDesat25 = '#35243a';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetDesat46 = '#503659';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetDesat75 = '#734d85';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetDesat100 = '#8e65a9';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetDesat135 = '#af93c4';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetDesat155 = '#c4b1d4';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetDesat180 = '#e3daeb';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPink25 = '#4c1141';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPink55 = '#801d6d';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPink75 = '#a1258a';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPink84 = '#b02897';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPink100 = '#cc2faf';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPink135 = '#e079cd';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPink155 = '#e9a1dc';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPink180 = '#f5d3ef';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPinkDesat25 = '#3c1c4c';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPinkDesat55 = '#673081';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPinkDesat67 = '#773796';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPinkDesat100 = '#9e57bf';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPinkDesat135 = '#bb8dd0';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPinkDesat155 = '#cdaddc';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var violetPinkDesat180 = '#e7d9ed';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pink25 = '#550635';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pink55 = '#8d0b58';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pink75 = '#b00d6e';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pink100 = '#df118b';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pink115 = '#f036a4';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pink135 = '#f46ebe';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pink155 = '#f79bd2';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pink180 = '#fcd2eb';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pinkDesat25 = '#4c172a';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pinkDesat55 = '#871f43';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pinkDesat75 = '#ad2152';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pinkDesat100 = '#de2666';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pinkDesat135 = '#ef77a1';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pinkDesat155 = '#f1a2bc';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var pinkDesat180 = '#f4d6df';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var red25 = '#590810';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var red55 = '#930d1a';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var red75 = '#b81021';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var red100 = '#e91429';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var red135 = '#f97481';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var red155 = '#fe9da7';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var red180 = '#ffd2d7';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redDesat25 = '#521319';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redDesat55 = '#8a202b';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redDesat75 = '#ad2836';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redDesat100 = '#d33f4e';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redDesat135 = '#e2828c';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redDesat155 = '#eaa6ad';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var redDesat180 = '#f5d6d9';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated. Implement third-party brand colors like Facebook locally and consider using their logo/icon instead for login scenarios. If you have any questions, please reach out in #encore
 */
export var blueFacebook100 = '#2374e1';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var fluorescentRed109 = '#ff001a';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var fluorescentOrange144 = '#ff8b1f';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var fluorescentYellow194 = '#f3ff46';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var fluorescentGreen176 = '#5ff550';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var fluorescentGreenBlue167 = '#19e68c';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var fluorescentCyan185 = '#00ffff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var fluorescentBlueViolet69 = '#2d28ff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var fluorescentViolet111 = '#c13fff';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var fluorescentPink119 = '#ff1abf';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated. You can achieve similar results with the bright accent or positive colors and color sets in color themes. Learn more at https://encore.spotify.net/foundation/basics/color-themes
 */
export var green = '#1db954';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated. You can achieve similar results with the bright accent or positive colors and color sets in color themes. Learn more at https://encore.spotify.net/foundation/basics/color-themes
 */
export var greenLight = '#1ed760';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated. You can achieve similar results with the bright accent or positive colors and color sets in color themes. Learn more at https://encore.spotify.net/foundation/basics/color-themes
 */
export var greenFocus = '#1da64d';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated. You can achieve similar results with the bright accent or positive colors and color sets in color themes. Learn more at https://encore.spotify.net/foundation/basics/color-themes
 */
export var greenDark = '#14833b';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var black = '#000000';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 */
export var white = '#ffffff';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated. You can achieve similar results with the negative colors and color sets in color themes. Learn more at https://encore.spotify.net/foundation/basics/color-themes
 */
export var red = '#cd1a2b';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated. You can achieve similar results with the negative colors and color sets in color themes. Learn more at https://encore.spotify.net/foundation/basics/color-themes
 */
export var redLight = '#e22134';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated. You can achieve similar results with the negative colors and color sets in color themes. Learn more at https://encore.spotify.net/foundation/basics/color-themes
 */
export var redDark = '#941320';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated. You can achieve similar results with the announcement colors and color sets in color themes. Learn more at https://encore.spotify.net/foundation/basics/color-themes
 */
export var blue = '#2e77d0';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated. You can achieve similar results with the announcement colors and color sets in color themes. Learn more at https://encore.spotify.net/foundation/basics/color-themes
 */
export var blueLight = '#4687d6';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use blue-facebook-100 instead. This version of the facebook-blue has been subtly tweaked to have accessible contrast with white text. If you need a different shade, please reach out in #encore
 */
export var blueFacebook = '#1877f2';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated. You can achieve similar results with the announcement colors and color sets in color themes. Learn more at https://encore.spotify.net/foundation/basics/color-themes
 */
export var blueDark = '#1d4c85';
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color is deprecated. You can achieve similar results with the warning colors and color sets in color themes. Learn more at https://encore.spotify.net/foundation/basics/color-themes
 */
export var orangeLight = '#ff5722';
