import {ReactNode} from 'react';
import styled from 'styled-components';
import {CardHeader} from '@core/ui/Content';
import {Slider, SliderProps} from '@core/ui/Slider';

interface Props {
  body?: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  sliderProps: SliderProps;
  subheader?: ReactNode;
  value: number | [number, number];
}
export const SliderBox = ({
  body,
  footer,
  header,
  sliderProps,
  subheader,
  value,
}: Props) => (
  <_SliderContainer>
    {!!header && <h3 css={{fontSize: '2rem', fontWeight: 700}}>{header}</h3>}
    {!!subheader && (
      <CardHeader
        description={subheader}
        css={`
          margin: 0;
          margin-bottom: 0.75rem;
        `}
      />
    )}
    {body}
    <Slider min={0} max={100} value={value} {...sliderProps} />
    {!!footer && (
      <CardHeader
        description={footer}
        css={`
          margin: 0;
          margin-top: 0.75rem;
        `}
      />
    )}
  </_SliderContainer>
);

const _SliderContainer = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  border: 1px solid var(--border-default);
  border-radius: 0.375rem;
`;
