import {
  IconAd,
  IconDevices,
  IconGroup,
  IconHeadphones,
  IconPlaylist,
  IconPodcasts,
  IconStar,
  IconUser,
} from '@spotify-internal/encore-web';
import {
  AudienceGroup,
  AudienceInsights,
  DAIAudienceCard,
  InsightsMetric,
  SAIAudienceCard,
} from './types';

export const DEFAULT_METRIC = InsightsMetric.IMPRESSIONS;

export const EMPTY_AUDIENCES: Record<AudienceGroup, AudienceInsights> = {
  [AudienceGroup.AGE]: [],
  [AudienceGroup.PODCAST]: [],
  [AudienceGroup.DEVICE]: [],
  [AudienceGroup.FORMAT]: [],
  [AudienceGroup.GENDER]: [],
  [AudienceGroup.PLAYLIST]: [],
  [AudienceGroup.GENRE]: [],
  [AudienceGroup.SEGMENT]: [],
};

export const DAI_TOP_AUDIENCES = [
  AudienceGroup.PODCAST,
  AudienceGroup.AGE,
  AudienceGroup.GENDER,
  AudienceGroup.GENRE,
] as const;

export const SAI_TOP_AUDIENCES = [
  AudienceGroup.GENRE,
  AudienceGroup.PLAYLIST,
  AudienceGroup.SEGMENT,
  AudienceGroup.AGE,
  AudienceGroup.GENDER,
  AudienceGroup.DEVICE,
  AudienceGroup.FORMAT,
] as const;

export const DAI_TABLES = [
  {
    group: AudienceGroup.AGE,
    title: 'Age',
    categoryTitle: 'Age group',
  },
  {
    group: AudienceGroup.GENDER,
    title: 'Gender',
    categoryTitle: 'Gender group',
  },
];

export const SAI_TABLES = [
  ...DAI_TABLES,
  {
    group: AudienceGroup.DEVICE,
    title: 'Devices',
    categoryTitle: 'Device types',
  },
  {
    group: AudienceGroup.FORMAT,
    title: 'Ad format',
    categoryTitle: 'Ad asset',
  },
];

export const SAI_CHARTS = [
  {
    group: AudienceGroup.GENRE,
    title: 'Music genres',
  },
  {
    group: AudienceGroup.PLAYLIST,
    title: 'Music playlists',
  },
  {
    group: AudienceGroup.SEGMENT,
    title: 'User segments',
  },
];

export const TOP_AUDIENCE_CONFIG = {
  [AudienceGroup.AGE]: {
    Icon: IconGroup,
    title: 'Top age group',
  },
  [AudienceGroup.DEVICE]: {
    Icon: IconDevices,
    title: 'Top device',
  },
  [AudienceGroup.FORMAT]: {
    Icon: IconAd,
    title: 'Top ad format',
  },
  [AudienceGroup.GENDER]: {
    Icon: IconUser,
    title: 'Top gender group',
  },
  [AudienceGroup.GENRE]: {
    Icon: IconHeadphones,
    title: 'Top music genre',
  },
  [AudienceGroup.PODCAST]: {
    Icon: IconPodcasts,
    title: 'Top podcast category',
  },
  [AudienceGroup.PLAYLIST]: {
    Icon: IconPlaylist,
    title: 'Top music playlist',
  },
  [AudienceGroup.SEGMENT]: {
    Icon: IconStar,
    title: 'Top user segment',
  },
};

export const METRIC_CATEGORY_TITLES: Record<InsightsMetric, string> = {
  [InsightsMetric.IMPRESSIONS]: 'Percentage',
  [InsightsMetric.CONVERSIONS]: 'Conversion rate index',
};

export const METRIC_CHART_LABELS: Record<InsightsMetric, string> = {
  [InsightsMetric.IMPRESSIONS]: 'Impressions',
  [InsightsMetric.CONVERSIONS]: 'Conversion rate index',
};

const SAI_SUBTITLE = 'Based on streamed impressions in campaign';

export const SAI_SUBTITLES: Record<SAIAudienceCard, string> = {
  OVERVIEW: 'Based on streamed impressions',
  [AudienceGroup.AGE]: SAI_SUBTITLE,
  [AudienceGroup.DEVICE]: SAI_SUBTITLE,
  [AudienceGroup.FORMAT]: SAI_SUBTITLE,
  [AudienceGroup.GENDER]: SAI_SUBTITLE,
  [AudienceGroup.GENRE]:
    'Based on audience associated with streamed impressions in campaign',
  [AudienceGroup.PLAYLIST]: SAI_SUBTITLE,
  [AudienceGroup.SEGMENT]: SAI_SUBTITLE,
};

const DAI_IMPRESSIONS_SUBTITLE = 'Based on downloaded impressions in campaign';
const DAI_CONVERSIONS_SUBTITLE =
  'Based on conversion data associated with downloaded impressions in campaign';

export const DAI_SUBTITLES: Record<
  InsightsMetric,
  Record<DAIAudienceCard, string>
> = {
  [InsightsMetric.IMPRESSIONS]: {
    OVERVIEW: 'Based on downloaded impressions',
    [AudienceGroup.AGE]: DAI_IMPRESSIONS_SUBTITLE,
    [AudienceGroup.GENDER]: DAI_IMPRESSIONS_SUBTITLE,
    [AudienceGroup.PODCAST]: DAI_IMPRESSIONS_SUBTITLE,
    [AudienceGroup.GENRE]:
      'Based on audience affiliated with downloaded impressions in campaign',
  },
  [InsightsMetric.CONVERSIONS]: {
    OVERVIEW: 'Based on conversion data',
    [AudienceGroup.AGE]: DAI_CONVERSIONS_SUBTITLE,
    [AudienceGroup.GENDER]: DAI_CONVERSIONS_SUBTITLE,
    [AudienceGroup.PODCAST]: DAI_CONVERSIONS_SUBTITLE,
    [AudienceGroup.GENRE]:
      'Based on the conversion data from audience affiliated with downloaded impressions',
  },
};
