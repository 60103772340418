import React, {FC, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {MiniPlayer} from '@core/components/AudioMiniPlayer';
import {
  capitalize,
  comma,
  contentMaturityRating,
  toPercent,
} from '@core/lib/filters';
import {gtag} from '@core/lib/gtag';
import {toTimeString} from '@core/lib/time';
import {useTheme} from '@core/style';
import {t} from '@core/texts';
import {Alert} from '@core/ui/Alert';
import {Button, ButtonIcon} from '@core/ui/Button';
import {CollapsableText} from '@core/ui/CollapsableText';
import {Card} from '@core/ui/Content';
import {Icon, IconName} from '@core/ui/Icon';
import {Img} from '@core/ui/Image';
import {SkeletonLoading} from '@core/ui/Loading';
import {Tooltip} from '@core/ui/Tooltip';
import {
  FetchedAdvisorShow,
  OrganizationObject,
  UserObject,
  useAdvisorShowQuery,
} from '@analytics/graphql-api';

const DetailBadge = styled.div`
  background: var(--bg-default);
  border: 1px solid var(--border-default);
  border-radius: 0.5rem;
  display: inline-flex;
  margin: 0 0.125rem 0.125rem 0;
  padding: 0.125rem 0.375rem;
`;

const Separator = styled.div`
  background: var(--border-default);
  height: 0.0625rem;
  margin: 0.75rem 0;
`;

const tableCss = css({
  fontSize: '0.8125rem',
  lineHeight: 1.4,
  '& tr td': {
    padding: '0.3125rem 0',
    verticalAlign: 'top',
  },
  '& tr td:first-child': {
    color: 'var(--text-muted)',
    maxWidth: '14rem',
    paddingRight: '0.625rem',
    whiteSpace: 'nowrap',
    width: '15rem',
  },
});

const affinityStatCss = css({
  cursor: 'default',
  background: 'var(--highlighter-40)',
  color: 'var(--green)',
});

const reachStatCss = (theme: 'light' | 'dark') =>
  css({
    cursor: 'default',
    background: theme === 'light' ? '#f2f4ff' : 'var(--gray-5)',
    color: 'var(--blue)',
  });

type BrandStatProps = {
  elevated?: boolean;
  iconName?: IconName;
  title: string;
  value: string | number;
} & React.HTMLAttributes<HTMLDivElement>;

const BrandStat = ({
  elevated,
  iconName,
  title,
  value,
  ...props
}: BrandStatProps) => {
  return (
    <div
      css={`
        border-radius: 0.75rem;
        box-shadow: ${elevated ? 'var(--shadow-lg)' : ''};
        padding: 1rem;
      `}
      {...props}>
      {!!iconName && (
        <Icon
          icon={iconName}
          css={`
            width: 1.75rem;
          `}
        />
      )}
      <div
        css={`
          font-size: 1.75rem;
          font-weight: 700;
          margin: 0.375rem 0 0.25rem 0;
        `}>
        {value}
      </div>
      <div
        css={`
          font-size: 0.875rem;
          font-weight: 500;
        `}>
        {title}
      </div>
    </div>
  );
};

const ShowDetails = ({
  show: {
    adsDurationMean,
    averageEpisodeLength,
    averageWeeklyImpressions,
    averageMonthlyImpressions,
    averageWeeklyReach,
    averageMonthlyReach,
    cadence,
    capabilities,
    contentMaturity,
    feed,
    publisher,
  },
}: {
  show: FetchedAdvisorShow;
}): JSX.Element => {
  const placements: {id: string; title: string}[] = [];
  const ads: {id: string; title: string}[] = [];
  const adCapabilities: {id: string; title: string}[] = [];

  capabilities.forEach(({id, title, category}: {[key: string]: string}) => {
    if (category === 'placements') {
      placements.push({id, title});
    }
    if (category === 'ads') {
      ads.push({id, title});
    }
    if (category === 'hosting') {
      adCapabilities.push({id, title});
    }
  });

  return (
    <div
      css={{
        background: 'var(--bg-muted)',
        borderRadius: '.5rem',
        margin: '1rem 0 0.625rem 0',
        padding: '1rem',
      }}>
      <table css={tableCss}>
        <tbody>
          <tr>
            <td>Average Episode Length</td>
            <td>{toTimeString(averageEpisodeLength)}</td>
          </tr>
          <tr>
            <td>Average Weekly Impressions</td>
            <td>{comma(averageWeeklyImpressions)}</td>
          </tr>
          <tr>
            <td>Average Monthly Impressions</td>
            <td>{comma(averageMonthlyImpressions)}</td>
          </tr>
          <tr>
            <td>Average Weekly Reach</td>
            <td>{comma(averageWeeklyReach)}</td>
          </tr>
          <tr>
            <td>Average Monthly Reach</td>
            <td>{comma(averageMonthlyReach)}</td>
          </tr>
        </tbody>
      </table>
      <Separator />
      <table css={tableCss}>
        <tbody>
          <tr>
            <td>Publisher</td>
            <td>{publisher.name}</td>
          </tr>
          <tr>
            <td>Cadence</td>
            <td>{capitalize(cadence)}</td>
          </tr>
          <tr>
            <td>Content Maturity</td>
            <td>{contentMaturityRating(contentMaturity)}</td>
          </tr>
          <tr>
            <td>Tags</td>
            <td>
              {feed.tags.map(({id, term}: {[key: string]: string}) => (
                <DetailBadge key={id}>{term}</DetailBadge>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
      <Separator />
      <table css={tableCss}>
        <tbody>
          <tr>
            <td>Average Ad Length</td>
            <td>{toTimeString(adsDurationMean)}</td>
          </tr>
          <tr>
            <td>Placements</td>
            <td>
              {placements.map(({id, title}) => (
                <DetailBadge key={id}>{title}</DetailBadge>
              ))}
            </td>
          </tr>
          <tr>
            <td>Ad Type</td>
            <td>
              {ads.map(({id, title}) => (
                <DetailBadge key={id}>{title}</DetailBadge>
              ))}
            </td>
          </tr>
          <tr>
            <td>Capabilities</td>
            <td>
              {adCapabilities.map(({id, title}) => (
                <DetailBadge key={id}>{title}</DetailBadge>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

interface AdvisorPreviewPodcastPanelProps {
  feedId: string;
  onClickClose: () => void;
  organization: OrganizationObject;
  user: UserObject;
}

const AdvisorPreviewPodcastPanel: FC<AdvisorPreviewPodcastPanelProps> = ({
  feedId,
  onClickClose,
  organization,
  user,
}): JSX.Element => {
  const theme = useTheme();
  const {data, loading, error} = useAdvisorShowQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: feedId,
      includeTestData: user.isAdmin,
      organizationId: organization.id,
    },
  });

  useEffect(() => {
    gtag('event', 'podcast', {
      event_category: 'advisor',
    });
  }, []);

  let content: React.ReactNode;
  if (loading) {
    content = (
      <>
        <div
          css={{alignItems: 'stretch', display: 'flex', marginBottom: '1rem'}}>
          <SkeletonLoading
            rules={() => ({
              borderRadius: '0.75rem',
              height: '15.5rem',
              marginRight: '1rem',
              width: '15.5rem',
            })}
          />
          <div css={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
            <SkeletonLoading
              rules={() => ({
                borderRadius: '.75rem',
                flex: 1,
                width: '9.6875rem',
              })}
            />

            <SkeletonLoading
              rules={() => ({
                borderRadius: '.75rem',
                flex: 1,
                width: '9.6875rem',
              })}
            />
          </div>
        </div>
        <SkeletonLoading
          rules={() => ({
            borderRadius: '.375rem',
            height: '2.125rem',
            width: '58%',
            marginBottom: '1rem',
          })}
        />
        <SkeletonLoading
          rules={() => ({
            borderRadius: '.375rem',
            height: '7.5rem',
            width: '100%',
          })}
        />
      </>
    );
  } else if (error) {
    content = (
      <Alert
        severity='error'
        css={`
          margin: 0;
        `}>
        Unable to load podcast. Please wait a moment and try again.
      </Alert>
    );
  } else {
    const show = data?.me?.organization?.advisor?.show;
    const hasAffinity = typeof show?.affinity === 'number';
    const contactEmail = show?.feed.contactEmail ?? show?.feed.publisherEmail;

    content = (
      <>
        <div
          css={{
            display: 'grid',
            gridGap: '1rem',
            gridTemplateRows: 'repeat(3, max-content)',
          }}>
          <div css={{display: 'flex'}}>
            <Img
              width={248}
              image={show?.feed?.image}
              rules={() => ({
                borderRadius: '0.75rem',
                height: '15.5rem',
                width: '15.5rem',
                marginRight: '1rem',
              })}
            />

            <div
              css={`
                display: flex;
                flex-direction: column;
                font-size: 0.875rem;
              `}>
              <Tooltip
                active={!hasAffinity}
                css={`
                  opacity: ${hasAffinity ? 'null' : 0.5};
                `}
                {...t('advisor.affinityNotAvailable')}>
                <BrandStat
                  iconName='affinity'
                  title='Brand Affinity Score'
                  value={
                    hasAffinity && show && show.affinity ? show.affinity : '-'
                  }
                  css={`
                    flex: 1;
                    background: var(--advisor-affinity-bg);
                    border: 1px solid var(--advisor-affinity-color);
                    color: var(--advisor-affinity-color);
                  `}
                />
              </Tooltip>
              <BrandStat
                iconName='radio'
                title='New Reach'
                value={
                  show && show.percentNewReach
                    ? toPercent(show.percentNewReach)
                    : '-'
                }
                css={`
                  margin-top: 1rem;
                  flex: 1;
                  background: var(--advisor-reach-bg);
                  color: var(--advisor-reach-color);
                `}
              />
            </div>
          </div>
          <div
            css={`
              font-size: 1.5rem;
              font-weight: 700;
              line-height: 1.2;
              margin-top: 0.5rem;
              position: relative;
            `}>
            {show?.feed.title}
          </div>
          <div css={{display: 'flex', gap: '0.75rem'}}>
            <Button
              variant='outlined'
              iconLeading='external'
              target='_blank'
              to={`podcast/${show?.feed.id}`}
              css={`
                > svg {
                  width: 1.25rem;
                  margin-left: -0.325rem;
                }
              `}>
              Full Profile
            </Button>
            {contactEmail && (
              <Button
                variant='outlined'
                iconLeading='mail-outline'
                target='_blank'
                to={{pathname: `mailto:${contactEmail}`}}
                css={`
                  > svg {
                    width: 1.25rem;
                    margin-left: -0.325rem;
                  }
                `}>
                Contact
              </Button>
            )}
          </div>
        </div>
        <Card
          css={`
            margin: 1rem 0 0 0;
            font-size: 0.875rem;
          `}>
          <div
            css={`
              font-weight: 500;
              line-height: 1;
              margin: 0 0 0.5rem 0;
            `}>
            Summary
          </div>
          <CollapsableText
            text={show?.feed.summary || ''}
            noCollapseButton
            css={`
              color: var(--text-muted);
            `}
          />
        </Card>

        <Card
          css={`
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1;
            margin: 1rem 0 0.5rem 0;
          `}>
          <div
            css={`
              font-weight: 500;
              margin: 0 0 0.75rem 0;
            `}>
            Preview Ad
          </div>
          <MiniPlayer enclosure={show?.ad} />
        </Card>

        <ShowDetails show={show} />
      </>
    );
  }

  return (
    <div css={{position: 'relative'}}>
      <ButtonIcon
        icon='close'
        onClick={onClickClose}
        css={`
          position: absolute;
          right: 0;
          top: 0;
          z-index: 10;
          > svg {
            color: var(--icon-subtle);
            height: 1.625rem;
            width: 1.625rem;
          }
        `}
      />
      {content}
    </div>
  );
};

export {affinityStatCss, BrandStat, DetailBadge, reachStatCss, ShowDetails};
export default AdvisorPreviewPodcastPanel;
