import {Controller} from 'react-hook-form';
import {t} from '@core/texts';
import {Stack} from '@core/ui/Layout';
import {Switch} from '@core/ui/Switch';
import {Text} from '@core/ui/Text';
import {FieldProps} from '../helpers';
import {Label} from '../shared';

export function SpendByImpressionsField({control}: FieldProps) {
  return (
    <Stack direction='column' spacing={1}>
      <Controller
        control={control}
        name='exportParameters.spendByImpressions'
        render={({field}) => (
          <Stack alignItems='center' direction='row' spacing={2}>
            <Label htmlFor='spendByImpressions'>
              <Switch
                id='spendByImpressions'
                defaultChecked={field.value ?? true}
                onChange={field.onChange}
              />
              Spend By Impressions Delivered
            </Label>
          </Stack>
        )}
      />
      <Text variant='body2' color='muted'>
        {t('settings.data.spendByImpressionsDelivered').longDescription}
      </Text>
    </Stack>
  );
}
