import React, {useEffect, useRef} from 'react';
import {toPercent} from '@core/lib/filters';
import {computedCSSVariable} from '@core/lib/utils';
import {useTheme} from '@core/style';
import {Tooltip} from '@core/ui/Tooltip';

const colorsMap = {
  nielsen: 'var(--green)',
  radioMarkets: 'var(--pink)',
  default: 'var(--blue-80)',
};

const tooltipTextMap = {
  feedGrowth: `month over month growth of downloads for a podcast.`,
  radioMarkets: `of listeners fall within this market.`,
  experian: `of the listeners of this podcast fall within this Experian segment.`,
  clearbit: `of the listeners of this podcast fall within this Clearbit segment.`,
  default: `of the listeners of this podcast fall within this Nielsen segment.`,
};

const getPercentile = (index, stddev, value) => {
  // z == number of standard deviations from the mean
  const z = (value - index) / stddev;
  // if z is greater than 6.5 standard deviations from the mean
  // the number of significant digits will be outside of a reasonable
  // range
  if (z < -6.5) return 0.0;
  if (z > 6.5) return 1.0;

  let factK = 1;
  let sum = 0;
  let term = 1;
  let k = 0;
  const loopStop = Math.exp(-23);
  while (Math.abs(term) > loopStop) {
    term =
      (((0.3989422804 * Math.pow(-1, k) * Math.pow(z, k)) /
        (2 * k + 1) /
        Math.pow(2, k)) *
        Math.pow(z, k + 1)) /
      factK;
    sum += term;
    k++;
    factK *= k;
  }
  sum += 0.5;

  return parseInt(String(sum * 100));
};

interface NielsenCellCanvasProps {
  color: string;
  filterIndex: number;
  filterMax: number;
  filterMin: number;
  filterStddev: number;
  percentile: number;
  tooltipText: string;
  value: number;
}

const NielsenCellCanvas = ({
  color,
  filterIndex,
  filterMax,
  filterMin,
  filterStddev,
  percentile,
  tooltipText,
  value,
}: NielsenCellCanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const theme = useTheme();

  useEffect(() => {
    if (canvasRef.current) {
      const getY = (n) => {
        return Math.exp(
          // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
          -0.9189385332046727418 -
            Math.log(filterStddev) -
            Math.pow(n - filterIndex, 2) / (2 * filterStddev * filterStddev)
        );
      };

      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const w = canvas.offsetWidth;
      const h = canvas.offsetHeight;
      const computedColor = computedCSSVariable(color);
      ctx.canvas.width = w;
      ctx.canvas.height = h;

      if (ctx) {
        ctx.clearRect(0, 0, w, h);

        let n = filterMin;
        const data: Array<{y: number; x: number}> = [];
        let maxY = 0;

        while (n < filterMax) {
          const y = getY(n);
          maxY = Math.max(maxY, y);
          data.push({
            y,
            x: n,
          });
          n += 0.001;
        }

        const dotD = data.length
          ? data
              .slice(0)
              .sort((a, b) => Math.abs(value - a.x) - Math.abs(value - b.x))[0]
          : [];

        ctx.fillStyle = computedColor;

        for (let i = 0; i < data.length; i++) {
          const {y, x} = data[i];
          const xP = (x - filterMin) / (filterMax - filterMin);
          const yP = (y - 0) / (maxY - 0);
          const xPx = xP * w;
          const yPy = yP * h;

          if (dotD.x === x) {
            ctx.fillStyle =
              theme === 'light'
                ? computedCSSVariable('var(--gray-2)')
                : computedCSSVariable('var(--gray-5)');
          }

          ctx.beginPath();
          ctx.moveTo(xPx, 0);
          ctx.lineTo(xPx, yPy);
          ctx.lineTo(xPx + 2, yPy);
          ctx.lineTo(xPx + 2, 0);
          ctx.closePath();
          ctx.fill();
        }

        const xP = (dotD.x - filterMin) / (filterMax - filterMin);
        const xPx = xP * w;
        // Xaxis 1/2
        ctx.fillStyle = computedColor;
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(0, 1);
        ctx.lineTo(xPx, 1);
        ctx.lineTo(xPx, 0);
        ctx.closePath();
        ctx.fill();

        // Xaxis 2/2
        ctx.fillStyle =
          theme === 'light'
            ? computedCSSVariable('var(--gray-2)')
            : computedCSSVariable('var(--gray-5)');
        ctx.beginPath();
        ctx.moveTo(xPx, 0);
        ctx.lineTo(xPx, 1);
        ctx.lineTo(w, 1);
        ctx.lineTo(w, 0);
        ctx.closePath();
        ctx.fill();
        // value line
        ctx.fillStyle =
          theme === 'light'
            ? computedCSSVariable('var(--gray-7)')
            : computedCSSVariable('var(--gray-2)');
        ctx.beginPath();
        ctx.moveTo(xPx, 0);
        ctx.lineTo(xPx, h);
        ctx.lineTo(xPx + 1, h);
        ctx.lineTo(xPx + 1, 0);
        ctx.closePath();
        ctx.fill();
      }
    }
  }, [color, filterIndex, filterMax, filterMin, filterStddev, theme, value]);

  return (
    <div
      css={`
        color: var(--text-muted);
        line-height: 1.2;
      `}>
      {!isNaN(percentile) && (
        <canvas
          ref={canvasRef}
          css={`
            display: flex;
            height: 2rem;
            transform: scale(1, -1);
            width: 10rem;
          `}
        />
      )}
      <div
        css={`
          align-items: center;
          display: grid;
          grid-gap: 0.375rem;
          grid-template-columns: repeat(3, max-content);
          margin-top: 0.375rem;
        `}>
        <Tooltip
          description={`${toPercent(value)} ${tooltipText}`}
          overlayProps={{
            verticalOffset: 4,
            horizontalAlign: 'right',
          }}>
          <span
            css={`
              background: ${color};
              border-radius: 2rem;
              color: var(--white);
              display: inline-flex;
              font-weight: 500;
              padding: 0.125rem 0.5rem;
            `}>
            {toPercent(value)}
          </span>
        </Tooltip>
        {!isNaN(percentile) && <div>·</div>}
        {!isNaN(percentile) && <div>{percentile} Percentile</div>}
      </div>
    </div>
  );
};

interface Props {
  cellName: string;
  filterIndex: number;
  filterMax: number;
  filterMin: number;
  filterStddev: number;
  value: string;
}

export default function NielsenCell({
  cellName,
  filterIndex,
  filterMax,
  filterMin,
  filterStddev,
  value,
}: Props) {
  if (filterIndex === null || typeof filterIndex !== 'number') {
    return null;
  }

  const floatValue = parseFloat(value);
  const percentile = getPercentile(filterIndex, filterStddev, floatValue);
  const color = colorsMap[cellName] || colorsMap.default;
  const tooltipText = tooltipTextMap[cellName] || tooltipTextMap.default;

  return (
    <NielsenCellCanvas
      percentile={percentile}
      color={color}
      tooltipText={tooltipText}
      value={floatValue}
      filterMax={filterMax}
      filterIndex={filterIndex}
      filterStddev={filterStddev}
      filterMin={filterMin}
    />
  );
}
