import {Query} from '@apollo/client/react/components';
import {BLUE, Element, GREEN} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {CampaignNav} from '@analytics/components/nav/CampaignNav';
import CampaignPermissionsQuery from '@analytics/graphql-api/_old_queries/CampaignPermissionsQuery';
import {CampaignRouteProps, toCampaignUrl} from '../_routes';

export function CampaignPermissions({
  user,
  organization,
  campaign,
}: CampaignRouteProps) {
  return (
    <>
      <CampaignNav
        organization={organization}
        campaign={campaign}
        user={user}
      />
      <ResponsiveContent containerSize='medium'>
        <Query
          query={CampaignPermissionsQuery}
          variables={{
            organizationId: organization.id,
            id: campaign.id,
          }}>
          {({loading, data}) => {
            if (loading) {
              return <Loading centered />;
            }

            const permissions =
              data?.me?.organization?.campaign?.permissions ?? [];

            return (
              <>
                <CardHeader backPath={toCampaignUrl(organization, campaign)}>
                  Campaign Permissions
                </CardHeader>
                {permissions.length > 0 ? (
                  <Card>
                    <p>The brand has granted you the following permissions:</p>
                    {permissions.map(({id, title, description}) => {
                      return (
                        <Element
                          key={id}
                          rules={() => ({
                            alignItems: 'start',
                            display: 'flex',
                            padding: '0.5rem 2rem 0.5rem 3rem',
                          })}>
                          <Element
                            rules={() => ({
                              background: GREEN,
                              borderRadius: '100%',
                              flexShrink: 0,
                              height: '0.75rem',
                              margin: '0.375rem 0.625rem 0 0',
                              width: '0.75rem',
                            })}
                          />
                          <div>
                            <Element
                              rules={() => ({
                                fontWeight: 500,
                                marginBottom: '.125rem',
                              })}>
                              {title}
                            </Element>
                            <Element
                              rules={({theme}) => ({
                                fontSize: '0.875rem',
                                color: theme.textTertiary,
                              })}>
                              {description}
                            </Element>
                          </div>
                        </Element>
                      );
                    })}
                  </Card>
                ) : (
                  <Card>
                    This campaign has no special permissions. If you would like
                    to add one, please contact{' '}
                    <Element
                      tag='a'
                      href='mailto:support-adanalytics@spotify.com'
                      rules={() => ({color: BLUE})}>
                      support-adanalytics@spotify.com
                    </Element>
                  </Card>
                )}
              </>
            );
          }}
        </Query>
      </ResponsiveContent>
    </>
  );
}
