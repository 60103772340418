import React from 'react';
import dayjs from '@core/lib/dayjs';
import {toFixed} from '@core/lib/filters';
import DataTable from '@core/ui/DataTable';
import {EpisodeCell} from '@analytics/components/campaigns/CampaignTableCells';
import useSettings from '@analytics/components/settings/useSettings';

const CampaignTargetEpisodesTable = ({campaign, campaignTargetEpisodes}) => {
  const [settings] = useSettings();
  const {multiplier} = campaign;

  const data = campaignTargetEpisodes
    .slice(0)
    .map(({id, episode, downloads}) => {
      return {
        id,
        published: episode.published,
        episode,
        downloadTotal: settings.modelled ? downloads * multiplier : downloads,
      };
    })
    .sort((a, b) => {
      return b.downloadTotal - a.downloadTotal;
    });

  const columns = [
    {
      title: 'Episode',
      accessor: 'id',
      type: 'string',
      Cell: EpisodeCell,
    },
    {
      title: 'Published',
      accessor: 'published',
      type: 'date',
      fmt: (v) => (v ? dayjs(v).utc().format('MM/DD/YYYY') : '-'),
    },
    {
      title: 'Attributed Downloads',
      accessor: 'downloadTotal',
      type: 'number',
      fmt: toFixed,
    },
  ];

  return (
    <DataTable
      columns={columns}
      orderBy='-downloadTotal'
      data={data}
      emptyMessageText='No episodes found.'
      onClickRowPath={({url}) => url}
    />
  );
};

export default CampaignTargetEpisodesTable;
