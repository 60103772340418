import {gql} from '@apollo/client';

export interface IOrganizationKeyDebugNodes {
  me: {
    id: string;
    organization: {
      id: string;
      key: {
        key: string;
        domain: string;
        createdAt: string;
        debug: {
          query: {
            count: number;
            nodes: Array<{
              key: string;
              latitude: number;
              longitude: number;
              countru: string;
              region: string;
              city: string;
              time: string;
              action: string;
              referrer: string;
              referrerPath: string;

              url: string;
              urlPath: string;
              urlDomain: string;

              userAgent: {
                os: {
                  family: string;
                };
                device: {
                  family: string;
                };
              };

              value: number;
              currency: string;
              productId: string;
              productName: string;
              productType: string;
              productVendor: string;
              discountCode: string;
              newCustomer: string;
              orderId: string;
              quantity: number;
              lineItems: string;
              type: string;
              category: string;

              alias: string;
              eventName: string;
            }>;
          };
        };
      };
    };
  };
}

export interface IOrganizationKeyDebugNodesInput {
  organizationId: string;
  key: string;
  action: string;
  showMobileOnly: boolean;
}

export default gql`
  query OrganizationKeyDebugNodesQuery(
    $organizationId: ID!
    $key: ID!
    $action: String
    $showMobileOnly: Boolean
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        key(key: $key) {
          key
          domain
          createdAt
          debug {
            query(action: $action, showMobileOnly: $showMobileOnly) {
              count
              nodes {
                key
                time
                action
                referrer
                referrerPath

                url
                urlPath
                urlDomain

                userAgent {
                  os {
                    family
                  }
                  device {
                    family
                  }
                }

                value
                currency
                productId
                productName
                productType
                productVendor
                discountCode
                newCustomer
                orderId
                quantity
                lineItems
                type
                category

                alias
                eventName
              }
            }
          }
        }
      }
    }
  }
`;
