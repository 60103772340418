import {gql} from '@apollo/client';

export default gql`
  query getCampaignDownloadCountryLocations(
    $organizationId: ID!
    $id: ID!
    $country: String
  ) {
    me {
      id
      organization(id: $organizationId) {
        id
        campaign(id: $id) {
          id
          downloads {
            locations(country: $country) {
              count
              nodes(limit: 2000) {
                country
                region
                city
                latitude
                longitude
                total
              }
              regions(limit: 200) {
                country
                region
                total
              }
            }
          }
        }
      }
    }
  }
`;
